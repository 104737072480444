import {ModalResolution} from '@maia/modals';

export const INSURING_PARTY_INFO_TABLE_COL_HEADERS = [
  'EGN / LNCH / EIK',
  'Name',
  'City',
  'Related Parties',
];

export const MOCK_MODAL_RESULT = {
  resolution: ModalResolution.CONFIRMED,
};
