import {Component, ComponentFactory, ComponentFactoryResolver, OnInit} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {DateKey, DateUtils} from '@atlas/businesstypes';
import {Logger} from '@atlas/logger';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {Subscription} from 'rxjs';

import {
  ACCOUNTS_ACTIVATION_CONFIRMATION,
  ACTIVATE,
  ACTIVATION_CONFIRMATION_ACCOUNTS_COUNT,
  CONFIRM,
} from '../../../constants/account-management';
import {UserAccountDetails} from '../../../models/UserAccountDetails';
import {UserActivateDeactivatePopup} from '../../../models/UserActivateDeactivatePopup';
import {AccountActivateDeactivateService} from '../../../services/account-activate-deactivate.service';
import {CommonMessagePopupComponent} from '../common-message-popup/common-message-popup.component';

@UntilDestroy()
@Component({
  selector: 'nje-activate-account-popup',
  templateUrl: './activate-account-popup.component.html',
  styleUrls: ['./activate-account-popup.component.scss'],
})
export class ActivateAccountPopupComponent extends ModalContentComponent<void> implements OnInit {
  public minDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 365);
  public activationDate: DateUtils;
  public accountsData: UserActivateDeactivatePopup;
  public popupDetailsSubscription: Subscription;
  public commonMessagePopupFactory: ComponentFactory<CommonMessagePopupComponent>;
  // @ts-ignore
  private _logger: Logger;

  public constructor(
    public control: ModalControl<void>,
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _accountActivateDeactivateService: AccountActivateDeactivateService,
  ) {
    super();
    this.popupDetailsSubscription = this._accountActivateDeactivateService
      .getActivatePopupDetails()
      .subscribe((data: UserActivateDeactivatePopup) => {
        this.accountsData = data;
      });
  }

  public ngOnInit(): void {
    this.activationDate = this.minDate;
    this._logger = this.loggerFactory.createLogger('CommmonMessagePopupComponent');
    this.commonMessagePopupFactory = this.componentFactoryResolver.resolveComponentFactory(
      CommonMessagePopupComponent,
    );
  }

  public activateAccount(): void {
    this.control.confirm();
  }

  public getConfirmButtonText(): string {
    return this.accountsData.individualAccount ? ACTIVATE : CONFIRM;
  }

  public closePopup(): void {
    this._clearSubscriptions();
    this.control.cancel();
  }

  public getUserFullname(account: UserAccountDetails): string {
    return `${account.firstNameEn} ${account.middleNameEn} ${account.lastNameEn}`;
  }

  public getUserPID(account: UserAccountDetails): string {
    return `${account.pin}`;
  }

  public getAccountType(account: UserAccountDetails): string {
    return `${account.userTypeId}`;
  }

  public getUserName(account: UserAccountDetails): string {
    return account.userName ? `${account.userName}` : `${account.emailId}`;
  }

  public getConfirmationMessage(): string {
    return ACCOUNTS_ACTIVATION_CONFIRMATION.replace(
      ACTIVATION_CONFIRMATION_ACCOUNTS_COUNT,
      `${this.accountsData.accounts.length}`,
    );
  }

  private _clearSubscriptions(): void {
    if (this.popupDetailsSubscription) {
      this.popupDetailsSubscription.unsubscribe();
    }
  }
}
