import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {MtplComponent} from './mtpl.component';
import {MTPLHomeComponent} from './screens/home/mtpl-home.component';

import {MtplRoutePaths} from './helper/mtpl-route-paths';
import {StepResolverService} from './mtpl-core/services/step.resolver';
import {OwnerInsurerDetailsComponent} from './screens/owner-insurer-details/owner-insurer-details.component';
import {InstalmentsComponent} from './screens/instalments/instalments.component';
import {AdditionalCoversRisksComponent} from './screens/additional-covers-risks/additional-covers-risks.component';
import {PolicyDetailsComponent} from './screens/policy-details/policy-details.component';
import {ProposalComponent} from './screens/proposal/proposal.component';
import {MtplVehicleDetailsComponent} from './screens/vehicle-details/mtpl-vehicle-details.component';
/**
 * All the routes of the flow should be defined here, we consume this then inside the exported
 * constant `ROUTES` which injects the flow as a lazy loaded route.
 *
 */
export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'mtpl',
    pathMatch: 'full',
  },
  {
    path: 'mtpl',
    component: MtplComponent,
    data: {
      njeB2BTitle: 'MTPL',
    },
    children: [
      {
        path: '',
        redirectTo: MtplRoutePaths.MTPL_PICK_TERMS,
        pathMatch: 'full',
      },
      {
        path: MtplRoutePaths.MTPL_PICK_TERMS,
        component: MTPLHomeComponent,
        data: {
          isStepperVisible: false,
          stepId: MtplRoutePaths.MTPL_PICK_TERMS,
          maiaFlowProgressId: MtplRoutePaths.MTPL_PICK_TERMS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: MtplRoutePaths.MTPL_OWNER_INSURERDETAILS,
        component: OwnerInsurerDetailsComponent,
        data: {
          stepId: MtplRoutePaths.MTPL_OWNER_INSURERDETAILS,
          maiaFlowProgressId: MtplRoutePaths.MTPL_OWNER_INSURERDETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: MtplRoutePaths.MTPL_INSTALMENTS,
        component: InstalmentsComponent,
        data: {
          stepId: MtplRoutePaths.MTPL_INSTALMENTS,
          maiaFlowProgressId: MtplRoutePaths.MTPL_INSTALMENTS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS,
        component: AdditionalCoversRisksComponent,
        data: {
          stepId: MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS,
          maiaFlowProgressId: MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: MtplRoutePaths.MTPL_POLICY_DETAILS,
        component: PolicyDetailsComponent,
        data: {
          stepId: MtplRoutePaths.MTPL_POLICY_DETAILS,
          maiaFlowProgressId: MtplRoutePaths.MTPL_POLICY_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: MtplRoutePaths.MTPL_VEHICLE_DETAILS,
        component: MtplVehicleDetailsComponent,
        data: {
          stepId: MtplRoutePaths.MTPL_VEHICLE_DETAILS,
          maiaFlowProgressId: MtplRoutePaths.MTPL_VEHICLE_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: MtplRoutePaths.MTPL_PROPOSAL,
        component: ProposalComponent,
        data: {
          stepId: MtplRoutePaths.MTPL_PROPOSAL,
          maiaFlowProgressId: MtplRoutePaths.MTPL_PROPOSAL,
        },
        resolve: {
          step: StepResolverService,
        },
      },
    ],
  },
];

/**
 * The purpose of this private module is to:
 * - Define the routes once in the package wich will be nested inside an application;
 * - Declaire intern components or consume intern private modules of the package;
 * - Import the dependencies they dashboard requires;
 *
 * The puropose of this class is to be consumed inside the constant `ROUTE` which lazy
 * loads this private module.
 *
 * The class needs to be exported so it can be lazy loaded in the exported constant ROUTE.
 *
 * We don't export the first screen component because it shouldn't be consumed outside this flow.
 *
 * We import the second screen through it's private module for preventing this module to
 * become to bloated. This doesn't mean that the dependencies are lazy loaded.
 *
 */
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class MTPLRoutingModule {}

/**
 * The route we export is an empty path with the private module lazy loaded.
 */
export const MTPL_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./mtpl.routing.module').then(module => module.MTPLRoutingModule),
};
