import {Component, OnInit} from '@angular/core';
import {MtplInsuringPartyDto} from '@b2b-frontend/core/src/core-helper/dto/mtpl-dto/summary-dto/MtplInsuringPartyDto';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-mtpl-insuring-party-slidein',
  templateUrl: './mtpl-insuring-party-slidein.component.html',
  styleUrls: ['./mtpl-insuring-party-slidein.component.scss'],
})
export class MtplInsuringPartySlideinComponent
  extends ModalContentComponent<MtplInsuringPartyDto, MtplInsuringPartyDto>
  implements OnInit
{
  public constructor(public control: ModalControl<MtplInsuringPartyDto, MtplInsuringPartyDto>) {
    super();
  }

  public ngOnInit(): void {}
}
