import {
  CoversDiscountTypes,
  SelectableInputType,
  ImprovementTypes,
  AdditionalCoversDataInterface,
} from '../contracts/property-insurance.interface';
import {Boolean} from '@atlas/businesstypes';

export const POLICY_TYPE_LIST: SelectableInputType[] = [
  {
    label: 'Annual',
    value: 'Annual',
  },
  {
    label: 'Termless',
    value: 'Termless',
  },
];
export const INSURED_BUILDING_TYPE_LIST: SelectableInputType[] = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];
export const REQUIRED_DOCS_AVAILABILITY_LIST: SelectableInputType[] = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];
export const PROPERTY_DAMAGE_TYPE_LIST: SelectableInputType[] = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];
export const BUILDING_INHABITANCY_TYPE_LIST: SelectableInputType[] = [
  {
    label: '24-hour/all year round',
    value: '24-hour/all year round',
  },
  {
    label: 'Seasonally/periodically',
    value: 'Seasonally/periodically',
  },
  {
    label: 'Under Supervision',
    value: 'Under Supervision',
  },
];
export const FIRESAFETY_AVAILABILITY_TYPE_LIST: SelectableInputType[] = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];
export const FIRE_SAFETY_TYPE_LIST: SelectableInputType[] = [
  {
    label: 'Sprinkler installation',
    value: 'Sprinkler installation',
  },
  {
    label: 'Fire-extinguishers',
    value: 'Fire-extinguishers',
  },
  {
    label: 'Fire alarm system',
    value: 'Fire alarm system',
  },
  {
    label: 'Additional water source',
    value: 'Additional water source',
  },
  {
    label: 'Smart home system',
    value: 'Smart home system',
  },
];
export const SECURITY_MEASURES_LIST: SelectableInputType[] = [
  {
    label: 'Physical security',
    value: 'Physical security',
  },
  {
    label: 'Panel signal-security system',
    value: 'Panel signal-security system',
  },
  {
    label: 'Local signal-security equipment',
    value: 'Local signal-security equipment',
  },
  {
    label: 'Special Locking Features',
    value: 'Special Locking Features',
  },
];
export const CURRENCY_LIST: SelectableInputType[] = [
  {
    label: 'BGN',
    value: 'BGN',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'USD',
    value: 'USD',
  },
];

export const BLANK_VALUE = '';
export const ALL = 'All';
export const YES = 'Yes';
export const NO = 'No';
export const ANNUAL = 'Annual';
export const TERMLESS = 'Termless';
export const TRUE = true;
export const FALSE = false;
export const WARNING = 'Warning';
export const REMOVE_INSURED_PARTY_CONFIRMATION_MSG =
  'Are you sure you want to remove this insured party?';
export const REMOVE_PROPERTY_CONFIRMATION_MSG =
  'Are you sure you want to remove this property from the property list';
export const REMOVE_PROPERTY_COVERS_CONFIRMATION_MSG =
  'Are you sure  to remove this cover from the list?';
export const REMOVE_PROPERTY_COVERS_ADDITIONA_MSG_AFTER_PREMIUM_CALCULATION =
  'Please note: This wil result in premium recalculation!';
export const REMOVE_FROM_SUPPLEMENTARY_LIST_CONFIRMATION_MSG =
  'Are you sure to remove this from supplementary list';
export const REMOVE_PROPERTY_WITH_FEATURES_FOOTER_NOTE =
  'Please note: Removing this property will also remove other dependable properties';
export const ADD_INSURED_PARTY = 'Add Insured Party';
export const ADD_PROPERTY_HOUSEHOLD = 'Add Property / Household';
export const EDIT_PROPERTY_HOUSEHOLD = 'Edit Property / Household';
export const ADD_FEATURES = 'Add Features';
export const EDIT_FEATURES = 'Edit Features';
export const PROPERTIES_INCLUDED = 'Properties Included';
export const VILLA = 'Villa';
export const HOUSE = 'House';
export const HOUSEHOLD_PROPERTY = 'Household Property';
export const APARTMENT = 'Apartment';
export const GARAGE_AS_A_PART_OF_THE_BUILDING = 'Garage as a part of the building';
export const GARAGE_AS_A_SEPARATE_BUILDING = 'Garage as a separate building';
export const EXTERNAL_POOL = 'External Pool';
export const FENCE = 'Fence';
export const SOLAR_THERMAL_COLLECTOR = 'Solar Thermal Collector';
export const IMPROVEMENTS = 'Improvements';
export const PIECES_OF_ART = 'Pieces of Art';
export const ADD_SUPPLEMENTARY_OBJECT = 'Add Supplementary Object';
export const EDIT_SUPPLEMENTARY_OBJECT = 'Edit Supplementary Object';
export const COVERS_USER_PID = '1112';
export const INSR_TYPE = 1112;
export const OBJECT_TYPE = 112;
export const SOS_HOME = 'SOS Home';
export const MIN_AGGREGATE_VALUE = 20000;
export const MIN_INSURED_VALUE = '2000';
export const DEFAULT_PREMIUM_AMOUNT = 2300;

export const INSURED_BUILDING_NOT_MASSIVE_WARNING =
  'We cannot proceed furthur if the property isnt Semi-massive or Massive';
export const DOCS_REQUIRED_WARNING = 'We cannot proceed furthur without required documents';

export const UNDERWRITING_LIMIT = 'underwriting-limits';
export const IV_BASE_OPTIONS = 'iv-base-options';
export const OBJECT_TYPE_PARAMETER = 'object-types';
export const ADDITIONAL_COVERS = 'Additional Covers';
export const PROPERTY_COVERS = 'Property Covers';
export const EDIT_ADDITIONAL_COVER = 'Edit Additional Cover';
export const ADD_ADDITIONAL_COVER = 'Add Additional Cover';
export const EDIT_COVER = 'Edit Cover';
export const ADD_COVER = 'Add Cover';
export const UNDERWRITINGS_LIMITS = 'Underwritings Limits';
export const COLUMN = 'column';
export const PROPERTY = 'Property';
export const MOCK_MAX_DISCOUNT_APPLIED = 25;
export const MOCK_AUTOMATIC_DISCOUNT_VALUE = 15;
export const INTERNALVALUE = 'internalValue';

export const PROPERTY_INSURANCE_PROPOSAL = {
  POLICY_TERM: 'Policy Term',
  POLICY_START_DATE: 'Policy Start Date',
  POLICY_END_DATE: 'Policy End Date',
  POLICY_VALIDITY_DURATION: 'Policy Validity Duration',
  POLICY_DESCRIPTION: 'Policy Description',
  INSURING_PARTY: 'Insuring Party',
  INSURED_PEOPLE: 'Insured People',
  INSTALLMENTS: 'Installments',
  DISCOUNT_AND_LOADINGS: 'Discount and Loadings',
  COVERS: 'Covers',
};

export const PROPERTY_POLICY_SUCCESS = {
  PAYMENT_SUCCESS_MSG: 'Policy Created',
};

export const PROPERTY_POLICY_ERROR = {
  PAYMENT_ERROR_MSG: 'Policy Not Created',
};

export const PROPERTY_LIST: SelectableInputType[] = [
  {
    label: 'Villa',
    value: 'Villa',
  },
  {
    label: 'House',
    value: 'House',
  },
  {
    label: 'Apartment',
    value: 'Apartment',
  },
  {
    label: 'Household Property',
    value: 'Household Property',
  },
];

export const BANK_LIST: SelectableInputType[] = [
  {
    label: 'LENA Bank',
    value: 'LENA Bank',
  },
  {
    label: 'DENA Bank',
    value: 'DENA Bank',
  },
  {
    label: 'YES Bank',
    value: 'YES Bank',
  },
  {
    label: 'NO Bank',
    value: 'NO Bank',
  },
];

export const FEATURE_LIST: SelectableInputType[] = [
  {
    label: 'Garage as a part of building',
    value: 'Garage as a part of building',
  },
  {
    label: 'Garage as a separate building',
    value: 'Garage as a separate building',
  },
  {
    label: 'External Pool',
    value: 'External Pool',
  },
  {
    label: 'Fence',
    value: 'Fence',
  },
  {
    label: SOLAR_THERMAL_COLLECTOR,
    value: SOLAR_THERMAL_COLLECTOR,
  },
  {
    label: 'Improvements',
    value: 'Improvements',
  },
  {
    label: 'Pieces of Art',
    value: 'Pieces of Art',
  },
];

export const PROPERTY_OWNERSHIP_TYPE: SelectableInputType[] = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];
export const INSTALLMENT_OPTION_LIST: SelectableInputType[] = [
  {
    label: 'One Installments',
    value: 'One Installments',
  },
  {
    label: 'Two Installments',
    value: 'Two Installments',
  },
  {
    label: 'Four Installments',
    value: 'Four Installments',
  },
  {
    label: 'Twelve Installments',
    value: 'Twelve Installments',
  },
];

export const DISCOUNT_TITLE: CoversDiscountTypes[] = [
  {
    id: '1',
    discountType: 'Single Pay Discount',
    discountValue: null,
    typeSelected: Boolean.FALSE,
  },
  {
    id: '2',
    discountType: 'New Client Discount',
    discountValue: null,
    typeSelected: Boolean.FALSE,
  },
  {
    id: '3',
    discountType: 'Several Kinds Property Discount',
    discountValue: null,
    typeSelected: Boolean.FALSE,
  },
  {
    id: '4',
    discountType: 'Creditor Discount',
    discountValue: null,
    typeSelected: Boolean.FALSE,
  },
  {
    id: '5',
    discountType: 'Comprehensive Discount',
    discountValue: null,
    typeSelected: Boolean.FALSE,
  },
  {
    id: '6',
    discountType: 'High IV Discount',
    discountValue: null,
    typeSelected: Boolean.FALSE,
  },
];
export const PAYMENT_DOCUMENT_TYPE_LIST: SelectableInputType[] = [
  {
    label: 'Bill',
    value: 'Bill',
  },
  {
    label: 'Debit Letter',
    value: 'Debit Letter',
  },
];

export const IMPROVEMENTS_LIST: ImprovementTypes[] = [
  {
    id: '',
    label: 'Overall Improvement',
    value: false,
  },
  {
    id: '',
    label: 'Shade Sunblind',
    value: false,
  },
  {
    id: '',
    label: 'Tents',
    value: false,
  },
  {
    id: '',
    label: 'Jacuzzi',
    value: false,
  },
  {
    id: '',
    label: 'Built-up Fireplace',
    value: false,
  },
  {
    label: 'Sauna',
    value: false,
  },
];

export const MOCK_ADDITIONAL_COVERS_DATA: AdditionalCoversDataInterface[] = [
  {
    id: 1,
    additionaCoverName: 'Earthquake',
    additionaCoverSelected: false,
  },
  {
    id: 2,
    additionaCoverName: 'Theft',
    additionaCoverSelected: false,
  },
];

export enum PolicySelectableTypesList {
  INHABITANCY_TYPES = 'INHABITANCY_TYPES',
  FIRE_PROTECTION_TYPES = 'FIRE_PROTECTION_TYPES',
  SECURITY_MEASURES_TYPES = 'SECURITY_MEASURES_TYPES',
  CURRENCY_LIST = 'CURRENCY_LIST',
}
