<div *ngIf="!isFixedCovers">
  <div class="b2b-kdl-font-medium-bold"> Included </div>

  <svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
  <p class="description b2b-kdl-font-normal"> Medical expenses - up to 0 EUR </p>
  <p class="sub-description kdl-font-small-light">
    Avail our cashless treatment facility during a medical emergency.
  </p>

  <svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
  <p class="description b2b-kdl-font-normal"> Accident Cover - up to 0 EUR </p>
  <p class="sub-description kdl-font-small-light">
    In case of an unfortunate event overseas we can compensate your Family/Nominee.
  </p>

  <div class="b2b-kdl-font-medium-bold"> Not Included </div>

  <svg b2bFrontendNavigationFuncCross class="navigation-cross"></svg>
  <p class="description b2b-kdl-font-normal"> Urgent Dental Treatment </p>
  <p class="sub-description kdl-font-small-light">
    Treat your dental worries with your cashless treatment.
  </p>

  <svg b2bFrontendNavigationFuncCross class="navigation-cross"></svg>
  <p class="description b2b-kdl-font-normal"> Search and rescue exspenses. </p>
  <p class="sub-description kdl-font-small-light">
    If caught in an unfortunate event, we will compensate for the distress.
  </p>
</div>

<div *ngIf="isFixedCovers">
  <div *ngIf="isFixedCovers" class="b2b-kdl-font-medium-bold"> Included </div>

  <label *ngFor="let cover of includeList.includedCoversList">
    <svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
    <p class="description b2b-kdl-font-normal">
      {{ cover.title }}
    </p>

    <p class="sub-description kdl-font-small-light">
      {{ cover.subTitle }}
    </p>
  </label>

  <div *ngIf="includeList?.coversName == 'Lite'" class="b2b-kdl-font-medium-bold">
    Not Included
  </div>

  <label *ngFor="let cover of includeList.notIncludedCoversList">
    <svg b2bFrontendNavigationFuncCross class="navigation-cross"></svg>
    <p class="description b2b-kdl-font-normal">
      {{ cover.title }}
    </p>

    <p class="sub-description kdl-font-small-light">
      {{ cover.subTitle }}
    </p>
  </label>
</div>
