<form class="verify-auth-form-wrapper form" [formGroup]="verifyAuthForm">
  <maia-form-group class="verify-auth-form">
    <svg b2bFrontendInformationExprPhoneLock class="icon-adjust"></svg>
    <maia-label class="b2b-kdl-font-grand-serif title-dark-color step-in-txt">
      Verify from Authenticator</maia-label
    >
    <maia-form-element
      [options]="{validationInstant: true}"
      [hideOptionalIndicator]="true"
      class="input-column"
      label="Enter the 6 digit code you see in the app"
      multi
    >
      <maia-form-subelement>
        <input maiaInput type="text" maxlength="6" formControlName="digitCode" />
      </maia-form-subelement>
    </maia-form-element>

    <div>
      <maia-button-group>
        <button
          class="m-t-b-24"
          maiaButton
          maiaButtonType="primary"
          id="twofaCheck"
          [disabled]="verifyAuthForm.invalid"
          (click)="onContinue()"
        >
          Verify
        </button>
        <button maiaButton maiaButtonType="secondary" (click)="onProcessBack()">Setup Again</button>
      </maia-button-group>
    </div>
  </maia-form-group>
</form>
