import {Component, Input} from '@angular/core';

import {B2BAccountDetailsTitles, BLANK_STRING} from '../../../constants/account-management';
import {AccountDetails} from '../../../models/AccountDetails';
import {UserAccountDetails} from '../../../models/b2b/UserAccountDetails';

@Component({
  selector: 'nje-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent {
  public accountDetails: AccountDetails[] = [];

  @Input()
  public set userDetails(data: UserAccountDetails) {
    this._prepareUserData(data);
  }

  private _prepareUserData(userDetails: UserAccountDetails): void {
    this.accountDetails = [
      {
        title: B2BAccountDetailsTitles.ACCOUNT_TYPE,
        value: `${userDetails.emailId}`,
        icon: BLANK_STRING,
      },
      {
        title: B2BAccountDetailsTitles.ACCOUNT_NAME,
        value: `${userDetails.firstNameEn} ${userDetails.middleNameEn} ${userDetails.lastNameEn}`,
        icon: BLANK_STRING,
      },
      {
        title: B2BAccountDetailsTitles.EGN_LNCH_EIK,
        value: `${userDetails.pid}`,
        icon: BLANK_STRING,
      },
      {
        title: B2BAccountDetailsTitles.AGENCY,
        value: userDetails.emailId,
        icon: BLANK_STRING,
      },
      {
        title: B2BAccountDetailsTitles.AGENT_NO,
        value: userDetails.emailId,
        icon: BLANK_STRING,
      },
      {
        title: B2BAccountDetailsTitles.EMAIL_ID,
        value: `${userDetails.emailId}`,
        icon: BLANK_STRING,
      },
    ];
  }
}
