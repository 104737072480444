import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprCarNewSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationExprCarNew]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 4.8 4.8)"><svg:path d="M37.7 1.864l2.615 5.3 5.849.849-4.234 4.127 1 5.824-5.23-2.75-5.231 2.75 1-5.824-4.235-4.127 5.849-.849z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-color="color-2"></svg:path><svg:path d="M25.06 9.044H9.28a1.968 1.968 0 0 0-1.95 1.709L5.593 23.808" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path d="M13.467 40.54v5.906H4.608V40.54" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path d="M42.01 40.54v5.906h-8.858V40.54" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path d="M44.963 40.54H1.656V30.11a3.935 3.935 0 0 1 .863-2.46L5.6 23.808h35.423L44.1 27.65a3.938 3.938 0 0 1 .863 2.46z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:circle cx="37.089" cy="32.667" r="2.952" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:circle><svg:circle cx="9.53" cy="32.667" r="2.952" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:circle><svg:path d="M20.356 32.667h5.906" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path d="M41.025 23.808l-.34-2.553" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprCarNewSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprCarNewSvgComponent,
  ],
  exports: [
    TransportationExprCarNewSvgComponent,
  ],
})
export class TransportationExprCarNewSvgModule {}
