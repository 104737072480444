export enum PartyType {
  PERSON = 'person',
  COMPANY = 'company',
  FOREIGNER = 'foreigner',
}

export enum PartyTypeValue {
  EGN = 'EGN',
  EIK = 'EIK',
  LNCH = 'LNCH',
}
