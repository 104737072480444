import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesFuncHouseSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendPlacesFuncHouse]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M3.2 11.2L12 4l8.8 7.2" stroke-width="1.04"></svg:path><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M10.4 20.8V16h3.2v4.8" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M5.6 12.8v8h12.8v-8" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesFuncHouseSvgComponent {
}

@NgModule({
  declarations: [
    PlacesFuncHouseSvgComponent,
  ],
  exports: [
    PlacesFuncHouseSvgComponent,
  ],
})
export class PlacesFuncHouseSvgModule {}
