import {StepResolverService} from './casco-core/services/step.resolver';
import {CascoPolicyService} from './casco-core/services/casco-policy.service';
import {CascoProcessService} from './casco-core/services/casco-process.service';
import {CascoRoutingModule} from './casco-routing.module';
import {BASE_ROUTE, CascoStarterService} from './casco-core/services/starter.service';
import {CascoHomeComponent} from './casco-home.component';
import {CoversDiscountsComponent} from './screens/covers-discounts/covers-discounts.component';
import {JourneychoiceComponent} from './screens/journeychoice/journeychoice.component';
import {ProposalComponent} from './screens/proposal/proposal.component';
import {VehicleDetailsComponent} from './screens/vehicle-details/vehicle-details.component';
import {PremiumScreenComponent} from './screens/premium-screen/premium-screen.component';
import {PriceOfferVehicleDetailsComponent} from './screens/price-offer-vehicle-details/price-offer-vehicle-details.component';
import {EmailPopupComponent} from './screens/email-popup/email-popup.component';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {CommonModule, DatePipe} from '@angular/common';
import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {FormsModule as AngularFormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {B2bCoreModule} from '@b2b-frontend/core/src/b2b-core.module';
import {TalonScanningModule} from '@b2b-frontend/core/src/talon-scanning';
import {μExampleSvgModule} from '@b2b-frontend/icons';
import {ActionTileModule as MaiaActionTileModule} from '@maia/action-tile';
import {ActionsModule as MaiaActionsModule} from '@maia/actions';
import {AdditionalInformationModule as MaiaAdditionalInformationModule} from '@maia/additional-information';
import {AvatarsModule as MaiaAvatarsModule} from '@maia/avatars';
import {AccordionListsModule as MaiaAccordionListsModule} from '@maia/accordion-lists';
import {ButtonsModule as MaiaButtonsModule} from '@maia/buttons';
import {DatePickersModule as MaiaDatePickersModule} from '@maia/datepickers';
import {DividerModule as MaiaDividerModule} from '@maia/divider';
import {DisplaysModule as MaiaDisplaysModule} from '@maia/displays';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {InputAutocompleteModule as MaiaInputAutocompleteModule} from '@maia/input-autocomplete';
import {InputPhonenumberModule as MaiaInputPhonenumberModule} from '@maia/input-phonenumber';
import {InputSelectModule as MaiaInputSelectModule} from '@maia/input-select';
import {LayoutModule as MaiaLayoutModule} from '@maia/layout';
import {MessagesModule as MaiaMessagesModule} from '@maia/messages';
import {PriceBlockModule as MaiaPriceBlockModule} from '@maia/price-block';
import {PopUpsModule as MaiaPopUpsModule} from '@maia/pop-ups';
import {RadioButtonsModule as MaiaRadioButtonsModule} from '@maia/radio-buttons';
import {SearchBarModule as MaiaSearchBarModule} from '@maia/search-bar';
import {SlideInsModule as MaiaSlideInsModule} from '@maia/slide-ins';
import {SwitchesModule as MaiaSwitchesModule} from '@maia/switches';
import {SelectModule as MaiaSelectModuke} from '@maia/select';
import {TablesModule as MaiaTablesModule} from '@maia/tables';
import {TilesModule as MaiaTilesModule} from '@maia/tiles';

import {NjfRadioButtonsModule} from '@njf-frontend-angular/radio-buttons';
import {
  ExpandedFlowProgressWithRoutingModule,
  FlowProgressModule,
} from '@njf-frontend-angular/flow-progress';
import {PeopleDetailsComponent} from './screens/people-details/people-details.component';
import {PolicyDetailsComponent} from './screens/policy-details/policy-details.component';
import {PeopleDetailsSlideinComponent} from './screens/people-details/people-details-slidein/people-details-slidein.component';
import {IsPersonPipe} from './pipes/is-person.pipe';

import {PricePremiumComponent} from './screens/price-premium/price-premium.component';
import {PriceCoversAdditionalRisksSlideinComponent} from './screens/price-covers-and-addons/price-covers-additional-risks-slidein/price-covers-additional-risks-slidein.component';
import {PeopleDetailsDeletePopupComponent} from './screens/people-details/people-details-delete-popup/people-details-delete-popup.component';
import {SumInsuredComponent} from './screens/sum-insured/sum-insured.component';
import {SumInsuredSlideinComponent} from './screens/sum-insured/sum-insured-slidein/sum-insured-slidein.component';
import {SumInsuredSecondSlideinComponent} from './screens/sum-insured/sum-insured-second-slidein/sum-insured-second-slidein.component';
import {VehicleUserInputComponent} from './screens/vehicle-details/vehicle-user-input/vehicle-user-input.component';
import {PriceCoversAddonsSlideinComponent} from './screens/price-covers-and-addons/price-covers-addons-slidein/price-covers-addons-slidein.component';
import {PriceCoversDeductibleSlideinComponent} from './screens/price-covers-and-addons/price-covers-deductible-slidein/price-covers-deductible-slidein.component';
import {PriceCoversAndAddonsComponent} from './screens/price-covers-and-addons/price-covers-and-addons.component';
import {PriceOfferProposalComponent} from './screens/price-offer-proposal/price-offer-proposal.component';

@NgModule({
  providers: [
    CascoStarterService,
    StepResolverService,
    CascoPolicyService,
    CascoProcessService,
    DatePipe,
  ],
  declarations: [
    CascoHomeComponent,
    CoversDiscountsComponent,
    EmailPopupComponent,
    JourneychoiceComponent,
    PremiumScreenComponent,
    PriceOfferVehicleDetailsComponent,
    ProposalComponent,
    VehicleDetailsComponent,
    PriceOfferProposalComponent,
    PeopleDetailsComponent,
    PolicyDetailsComponent,
    SumInsuredComponent,
    PeopleDetailsSlideinComponent,
    PriceCoversAndAddonsComponent,
    IsPersonPipe,
    PriceCoversAddonsSlideinComponent,
    PricePremiumComponent,
    PeopleDetailsDeletePopupComponent,
    PriceCoversDeductibleSlideinComponent,
    PriceCoversAdditionalRisksSlideinComponent,
    SumInsuredComponent,
    SumInsuredSlideinComponent,
    SumInsuredSecondSlideinComponent,
    VehicleUserInputComponent,
  ],
  imports: [
    //ANGULAR
    AngularFormsModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    //MAIA
    MaiaActionsModule,
    MaiaAccordionListsModule,
    MaiaActionTileModule,
    MaiaAdditionalInformationModule,
    MaiaAvatarsModule,
    MaiaButtonsModule,
    MaiaDatePickersModule,
    MaiaDisplaysModule,
    MaiaDividerModule,
    MaiaFormsModule,
    MaiaInputAutocompleteModule,
    MaiaInputPhonenumberModule,
    MaiaInputSelectModule,
    MaiaLayoutModule,
    MaiaMessagesModule,
    MaiaPopUpsModule,
    MaiaPriceBlockModule,
    MaiaRadioButtonsModule,
    MaiaSearchBarModule,
    MaiaSelectModuke,
    MaiaSlideInsModule,
    MaiaSwitchesModule,
    MaiaTablesModule,
    MaiaTilesModule,
    //KDL ANGULAR COMPONENTS
    ExpandedFlowProgressWithRoutingModule,
    FlowProgressModule,
    NjfRadioButtonsModule,
    //APP PACKAGES
    μExampleSvgModule,
    BusinessTypesModule,
    CascoRoutingModule,
    B2bCoreModule,
    TalonScanningModule,
  ],
})
export class CascoModule {
  public static forPath(path: string): ModuleWithProviders<CascoModule> {
    return {
      ngModule: CascoModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error('Do not import CascoModule directly, use CascoModule.forPath() instead');
    }
  }
}
