export const PAYMENTS_OVERVIEW_TABLE_LIST = {
  data: [
    {
      instalment: 'Instalment 2',
      dueDate: '04/01/2020',
      status: 'Due',
      amount: '324.02',
      currency: 'BGN',
    },
  ],
};

export const PAYMENTS_OVERVIEW_TABLE_HEADERS = [
  'Instalment',
  'Due Date',
  'Status',
  'Amount',
  'Currency',
];
