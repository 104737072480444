import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FlagUtilCzechRepublicSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFlagUtilCzechRepublic]',
  template: '<svg:defs><svg:path id="svgwg99kl8BOJMRax9amsVyF6nqu+AASqL/dnqGTiFFj7k=" d="M0 0h48v48H0z"></svg:path></svg:defs><svg:clipPath id="svgDm04yG6EpjyV4taKtQVU9z9BRKhdWfZQX5o3SimJnxg="><svg:use href="#svgwg99kl8BOJMRax9amsVyF6nqu+AASqL/dnqGTiFFj7k=" overflow="visible"></svg:use></svg:clipPath><svg:g clip-path="url(#svgDm04yG6EpjyV4taKtQVU9z9BRKhdWfZQX5o3SimJnxg=)"><svg:path fill="#FFFFFF" d="M0 0h48v48H0z"></svg:path><svg:path fill="#D80027" d="M0 0h48v48H0z"></svg:path><svg:path fill="#F0F0F0" d="M0 0h48v24H0z"></svg:path><svg:path fill="#0052B4" d="M0 48l24-24L0 0v48z"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'xlink': 'http://www.w3.org/1999/xlink',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagUtilCzechRepublicSvgComponent {
}

@NgModule({
  declarations: [
    FlagUtilCzechRepublicSvgComponent,
  ],
  exports: [
    FlagUtilCzechRepublicSvgComponent,
  ],
})
export class FlagUtilCzechRepublicSvgModule {}
