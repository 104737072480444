import {Injectable} from '@angular/core';
import {Config, LocaleConfig} from '../constant/localization-config';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private readonly _localeConfigs: LocaleConfig;

  get localeConfigs(): LocaleConfig {
    return this._localeConfigs;
  }

  constructor() {
    this._localeConfigs = Config;
  }
}
