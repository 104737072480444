<maia-contextual-message type="informative" class="banner-style"
  >Note:Seperate e-receipt we bill be issued for selected insurance policies, one per policy.
</maia-contextual-message>
<maia-fluid-fluid-layout>
  <maia-label class="b2b-kdl-font-small title-dark-color m-t-24">Select Instalment</maia-label>
  <div class="filter-icon-circle" (click)="applyFilter()">
    <svg class="align-svg-right" b2bFrontendActionFuncFilterCircle></svg>
  </div>
</maia-fluid-fluid-layout>
<div>
  <maia-tag-container (closeAll)="handleClearAll()" class="tag-container-background-color--white">
    <maia-tag
      *ngFor="let tag of regularTagList"
      [displayedValue]="tag.displayedValue"
      (close)="tag.close()"
    ></maia-tag>
  </maia-tag-container>
</div>
<div>
  <div class="m-t-24">
    <maia-condition>
      <maia-checkbox
        [(ngModel)]="isSelectAllDue"
        (click)="selectAllDue(isSelectAllDue)"
        class="mb-0 custom-due-border"
        >Select all due instalments
      </maia-checkbox>
    </maia-condition>
  </div>
  <div class="table-div" [ngClass]="{'m-l-r-0': itsFor === 'USER_DUE_INSTALMENT'}">
    <maia-panel
      *ngFor="let list of instalmentsData; let policyIndex = index"
      class="maia-panel m-t-24"
      id="maia-panel-title-icon"
    >
      <maia-panel-title class="panel-icon-adjust">
        <maia-fluid-fluid-layout alignContentTop>
          <div>
            <label class="kdl-font-large-bold insurance-tile">{{ list.policyName }}</label> <br />
            <label class="kdl-font-small-bold insurance-no">{{ list.policyNo }}</label>
          </div>
          <div *ngIf="list.productCode == productCode.propertyCode">
            <maia-avatar-display class="home-car-icon-adjust">
              <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
                <svg b2bFrontendPlacesExprHouse class="home-icon"></svg>
              </maia-avatar-svg>
            </maia-avatar-display>
          </div>
          <div *ngIf="list.productCode == productCode.motorCode">
            <maia-avatar-display class="home-car-icon-adjust">
              <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
                <svg b2bFrontendTransportationExprCarNew class="new-car-icon"></svg>
              </maia-avatar-svg>
            </maia-avatar-display>
          </div>
        </maia-fluid-fluid-layout>
      </maia-panel-title>
      <maia-table *ngIf="list.instalmentList.length > 0">
        <maia-table-header
          *ngFor="let row of list.instalmentList | slice: 0:1; index as rowIndex"
          class="custom-heading"
        >
          <maia-table-row *ngFor="let row of list.instalmentList | slice: 0:1; index as rowIndex">
            <maia-table-cell class="custom-heading">
              <maia-checkbox
                [disabled]="checkDisableStatus(policyIndex, rowIndex)"
                [(ngModel)]="headerSelected[policyIndex].headerSelected"
                (click)="selectAll(policyIndex)"
              ></maia-checkbox>
            </maia-table-cell>
            <maia-table-cell
              *ngFor="let column of tableColumns"
              class="demo-kdl-font-normal-bold insurance-no"
            >
              {{ column.headerName }}
            </maia-table-cell>
          </maia-table-row>
        </maia-table-header>
        <maia-table-body>
          <maia-table-row
            *ngFor="let row of list.instalmentList | slice: 0:5; index as rowIndex"
            [selected]="getSelectedRow(rowsSelected[policyIndex][rowIndex].rowSelected)"
          >
            <maia-table-cell class="custom-flex-style">
              <maia-checkbox
                [disabled]="checkDisableStatus(policyIndex, rowIndex)"
                [(ngModel)]="rowsSelected[policyIndex][rowIndex].rowSelected"
                (click)="selectRow(policyIndex, rowIndex)"
              ></maia-checkbox>
            </maia-table-cell>
            <maia-table-cell
              *ngFor="let column of tableColumns"
              class="demo-kdl-font-normal-light {{ row.status.toLowerCase() }}-{{
                checkDisableStatus(policyIndex, rowIndex)
              }}"
              class="custom-fontweight-tablecell"
            >
              {{ getCellValue(row, column, rowIndex) }}
            </maia-table-cell>
          </maia-table-row>
          <!--WEB Show More and less -->
          <maia-additional-information
            *ngIf="list.instalmentList != null && list.instalmentList.length > 5"
            id="add-info-coll-4"
            openLinkText="Show more"
            closeLinkText="Show less"
            maiaContentType="content"
            collapsible
          >
            <maia-table-row
              *ngFor="
                let row of list.instalmentList | slice: 5:list.instalmentList.length;
                index as rowIndex
              "
              [selected]="getSelectedRow(rowsSelected[policyIndex][rowIndex + 5].rowSelected)"
            >
              <maia-table-cell class="custom-flex-style">
                <maia-checkbox
                  [disabled]="checkDisableStatus(policyIndex, rowIndex + 5)"
                  [(ngModel)]="rowsSelected[policyIndex][rowIndex + 5].rowSelected"
                  (click)="selectRow(policyIndex, rowIndex + 5)"
                ></maia-checkbox>
              </maia-table-cell>
              <maia-table-cell
                *ngFor="let column of tableColumns"
                class="demo-kdl-font-normal-light {{ row.status.toLowerCase() }}-{{
                  checkDisableStatus(policyIndex, rowIndex + 5)
                }}"
                class="custom-fontweight-tablecell"
              >
                {{ getCellValue(row, column, rowIndex + 5) }}
              </maia-table-cell>
            </maia-table-row>
          </maia-additional-information>
        </maia-table-body>
      </maia-table>
    </maia-panel>
    <br />
    <div
      class="step-form-button-bar no-divider no-top-space"
      [ngClass]="{
        'm-l-r-t-20px-0px': itsFor === 'USER_DUE_INSTALMENT',
        'm-l-r-24px': itsFor !== 'USER_DUE_INSTALMENT'
      }"
    >
      <button class="m-20" maiaButton maiaButtonType="primary" (click)="saveAndContinue()"
        >Continue
      </button>
    </div>
  </div>
</div>
