export const enum ApiEndpoints {
  AdminLibrary = 'cms/admin-library',
  AdminLibraryDocumentSearch = 'cms/admin-library-document',
  UploadDocument = 'cms/document',
  UserTypes = 'cms/user-type',
}

export const ADD_LIBRARY_SLIDEIN_TITLE: string = 'Add New Library';

export const EDIT_LIBRARY_SLIDEIN_TITLE: string = 'Edit Library';

export const BLANK_VALUE = '';

export const ADMIN_DOCUMENT_UPLOAD_TYPE = {
  PDF: 'pdf',
  VIDEO: 'mp4',
  PPT: 'vnd.ms-powerpoint',
  PPTX: 'presentation',
  XLS: 'vnd.ms-excel',
  XLSX: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  IMAGE: 'jpeg',
  DOC: 'msword',
  DOCX: 'vnd.openxmlformats-officedocument.wordprocessingml.document',
};
