export const searchParameters = ['Module Number', 'SubModule Name'];

export const ModuleTableColumnHeadings = [
  'Module Number',
  'Module Name',
  'Sub Module Name',
  'Action',
];

export const B2B_MODULE_TABLE = {
  DEFAULT_COMPLEX_COLUMNS: 4,
  DEFAULT_COMPLEX_ROWS: 1,
};

export const PAGINATION_CONSTANTS = {
  TOTAL_PAGES: 2,
  CURRENT_PAGE_NUMBER: 1,
};

export const ModulesList = [
  {
    id: 1,
    moduleNumber: 123872,
    moduleName: 'CASCO',
    subModuleName: 'Quick Quote',
  },
  {
    id: 2,
    moduleNumber: 123872,
    moduleName: 'CASCO',
    subModuleName: 'PriceOffer',
  },
  {
    id: 3,
    moduleNumber: 123872,
    moduleName: 'CASCO',
    subModuleName: '91011',
  },
  {
    id: 4,
    moduleNumber: 123872,
    moduleName: 'CASCO',
    subModuleName: '12131',
  },
  {
    id: 5,
    moduleNumber: 123872,
    moduleName: 'Payment',
    subModuleName: 'Issue E-Reciept',
  },
  {
    id: 6,
    moduleNumber: 123872,
    moduleName: 'Payment',
    subModuleName: 'Issue Debit Note',
  },
  {
    id: 7,
    moduleNumber: 123872,
    moduleName: 'Payment',
    subModuleName: 'Issuing Blank for MTPL',
  },
  {
    id: 8,
    moduleNumber: 123872,
    moduleName: 'Property',
    subModuleName: 'Property Home Insurance ',
  },
  {
    id: 9,
    moduleNumber: 123872,
    moduleName: 'Property',
    subModuleName: 'Property Home Insurance ',
  },
  {
    id: 10,
    moduleNumber: 123872,
    moduleName: 'Property',
    subModuleName: 'Property Home Insurance ',
  },
];

export const addedModulesList = [
  {
    id: 1,
    moduleNumber: 123872,
    moduleName: 'CASCO',
    subModuleName: 'Quick Quote',
  },
  {
    id: 2,
    moduleNumber: 123872,
    moduleName: 'CASCO',
    subModuleName: 'PriceOffer',
  },
  {
    id: 5,
    moduleNumber: 123872,
    moduleName: 'Payment',
    subModuleName: 'Issue E-Reciept',
  },
  {
    id: 6,
    moduleNumber: 123872,
    moduleName: 'Payment',
    subModuleName: 'Issue Debit Note',
  },
  {
    id: 9,
    moduleNumber: 123872,
    moduleName: 'Property',
    subModuleName: 'Property Home Insurance ',
  },
];
