import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LoggerFactory} from '@atlas-angular/logger';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {DELETE_POPUP_HEADINGS} from '../../../../constants/resource-management/library';
import {localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {RoutePaths} from '../../../../constants/route-paths';
import {
  Library,
  Product,
  ProductDto,
  ProductPortfolio,
} from '../../../../contracts/library.interface';
import {CmsNavigationService} from '../../../../services/cms-navigation.service';
import {LibrarySessionService} from '../../../../services/library-session.service';
import {LibraryService} from '../../../../services/library.service';
import {AddProductComponent} from '../add-product/add-product.component';
import {DeletePopupComponent} from '../delete-popup/delete-popup.component';

type MarkFunctionProperties<Component> = {
  [Key in keyof Component]: Key;
};
type ExcludeFunctionPropertyNames<ProductPortfolioComponent> =
  MarkFunctionProperties<ProductPortfolioComponent>[keyof ProductPortfolioComponent];
type ExcludeFunctions<ProductPortfolioComponent> = Pick<
  ProductPortfolioComponent,
  ExcludeFunctionPropertyNames<ProductPortfolioComponent>
>;
export type NgChanges<Component, Props = ExcludeFunctions<Component>> = {
  [Key in keyof Props]: {
    previousValue: Props[Key];
    currentValue: Props[Key];
    firstChange: boolean;
    isFirstChange(): boolean;
  };
};
@Component({
  selector: 'nje-library-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent extends ScreenStepComponent implements OnInit {
  public productList: Product[] = [];

  public showHideCustomAmoutDiv: boolean = false;
  public selectedProductId: number = 0;
  public readonly formsFactory: ComponentFactory<any>;
  public popup: boolean = false;
  public lang: string | null;

  @Input()
  public selectProductPortfolio: ProductPortfolio;

  @Input()
  public selectLibrary: Library;

  @Output()
  public readonly selectedProduct: EventEmitter<Product> = new EventEmitter<Product>();

  public readonly emitSelected = new EventEmitter<Product>();
  private readonly _popUpDeleteFactory: ComponentFactory<DeletePopupComponent>;

  public constructor(
    public librarySessionService: LibrarySessionService,
    private readonly _cdRef: ChangeDetectorRef,
    loggerFactory: LoggerFactory,
    private readonly _libraryService: LibraryService,
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    componentFactoryResolver: ComponentFactoryResolver,
    private readonly _cmsNavigationService: CmsNavigationService,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    public router: ActivatedRoute,
  ) {
    super(router);
    this.formsFactory = componentFactoryResolver.resolveComponentFactory(AddProductComponent);
    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(DeletePopupComponent);
  }

  public OnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ngOnInit(): void {
    this._getProductFromDB();
  }

  public ngOnChanges(changes: NgChanges<ProductComponent>): void {
    this._getProductFromDB();
  }

  private _getProductFromDB(): void {
    if (this.selectLibrary && this.selectProductPortfolio) {
      const libraryId = this.selectLibrary.id;
      const productPortfolioId = this.selectProductPortfolio.id;
      if (libraryId !== undefined && productPortfolioId !== undefined) {
        this._libraryService
          .getProductList(libraryId, productPortfolioId)
          .subscribe((response: Product[]) => {
            this.productList = response;
            this._cdRef.detectChanges();
          });
      }
    }
  }

  public selectProduct(product: Product, index: number): void {
    this.productList.map((product: Product) => (product.selectedStatus = false));
    this.productList[index].selectedStatus = true;
    this.selectedProduct.emit(product);
  }

  protected saveMtplData(): void {
    const selectedProductObj: Product | undefined = this.productList.find(
      (pProduct: Product) => pProduct.selectedStatus,
    );
    if (selectedProductObj) {
      const productDto: ProductDto = {
        selectedProduct: selectedProductObj,
      };
      this.librarySessionService.setProduct(productDto);
    }
    this.librarySessionService.setLibrary(this.selectLibrary);
    this.librarySessionService.setPortFolio(this.selectProductPortfolio);
  }

  public navigateToAddProduct(): void {
    this.saveMtplData();
    this._cmsNavigationService.nextScreen(RoutePaths.AddProduct);
  }

  public navigateToEditProduct(productId: number): void {
    this.saveMtplData();
    this._cmsNavigationService.nextScreen(RoutePaths.EditProduct, String(productId));
  }

  public deleteProduct(product: Product): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: '', footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: new localeValPipe(new LocalizationService()).transform(
            DELETE_POPUP_HEADINGS.PRODUCT,
            this.lang,
          ),
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          if (this.selectLibrary.id && this.selectProductPortfolio.id) {
            this._libraryService
              .deleteProduct(this.selectLibrary.id, this.selectProductPortfolio.id, product.id)
              .subscribe(() => {
                this._getProductFromDB();
              });
          }
          this._cdRef.detectChanges();
        }
      });
  }
}
