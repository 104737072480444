export const TEMP_CITIZIENHIP = 'AB';

export const REASON_FOR_TRAVEL_TITLE = 'Reason for travel';

export const TRAVEL_PROPOSAL = {
  POLICY_DESCRIPTION: 'Policy Description',
  INSURING_PARTY: 'Insuring Party',
  INSURED_PEOPLE: 'Insured People',
  ADDONS: 'Covers and Add Ons',
  DISCOUNT: 'Discount',
};

export const TRAVEL_POLICY_SUCCESS = {
  PAYMENT_SUCCESS_MSG: 'Policy Created',
};

export const TRAVEL_POLICY_ERROR = {
  PAYMENT_ERROR_MSG: 'Policy Not Created',
};

export const TRAVEL_INSURED_PEOPLE = {
  INSURED_PEOPLE_TITLE: 'Insured Person',
};

export enum Covers {
  lite = 'Lite',
  standard = 'Standard',
  visa = 'Visa',
  premium = 'Premium',
}

export enum Coverage {
  liteCoverage = 'Lite Main Coverage',
  standardCoverage = 'Standard Main Coverage',
  visaCoverage = 'Visa Main Coverage',
  premiumCoverage = 'Premium Main Coverage',
  customCoverage = 'Custom Main Coverage',
}

export const TRAVEL_RENEW_POLICY = {
  new: 'NEW',
  existing: 'EXISTING',
};

export const COUNTRY_CODE = {
  BG: '+359',
};

export enum NewTile {
  newTileId = 1,
}

export enum TotalPeople {
  peopleCount = 150,
}

export enum FileSize {
  size = 208,
}

export enum MinMaxValue {
  min = 0,
  max = 5,
}

export const TRAVEL_PROPOSAL_SAVE_OFFER = {
  TITLE: 'Success!',
};
