import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprPaymentSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprPayment]',
  template: '<svg:g class="nc-icon-wrapper"><svg:g transform="matrix(.6 0 0 .6 8.64 8.64)"><svg:ellipse class="st0" cx="21.6" cy="5.6" rx="11.9" ry="5.4" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:ellipse><svg:path class="st0" d="M33.5 5.6v7.6c0 3-5.3 5.4-11.9 5.4S9.7 16.2 9.7 13.2V5.6" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M29.2 24.9c-2.1.8-4.7 1.2-7.6 1.2-6.6 0-11.9-2.4-11.9-5.4v-7.6" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M29.2 32.5c-2.1.8-4.7 1.2-7.6 1.2-6.6 0-11.9-2.4-11.9-5.4v-7.6" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M29.2 40.1c-2.1.8-4.7 1.2-7.6 1.2-6.6 0-11.9-2.4-11.9-5.4v-7.6" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:ellipse class="st0" cx="41.1" cy="19.7" rx="11.9" ry="5.4" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:ellipse><svg:path class="st0" d="M53 19.7v7.6c0 3-5.3 5.4-11.9 5.4s-11.9-2.4-11.9-5.4v-7.6" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M53 27.2v7.6c0 3-5.3 5.4-11.9 5.4s-11.9-2.4-11.9-5.4v-7.6" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M53 34.8v7.6c0 3-5.3 5.4-11.9 5.4s-11.9-2.4-11.9-5.4v-7.6" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path></svg:g><svg:path class="st0" d="M5.44 24h5.6" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprPaymentSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprPaymentSvgComponent,
  ],
  exports: [
    InformationExprPaymentSvgComponent,
  ],
})
export class InformationExprPaymentSvgModule {}
