import {Component, OnInit} from '@angular/core';
import {bg, en} from './constants';

@Component({
  selector: 'nje-b2b-app',
  template: '<router-outlet></router-outlet><ng-container maiaModalContainer></ng-container>',
  styles: [':host {display: block}'],
})
export class AppComponent implements OnInit {
  public path: string;

  public ngOnInit(): void {
    this.updateLang();
  }

  public updateLang() {
    const locationStrArray = window.location.href.toString().split('/');
    if (locationStrArray.length > 3) {
      this.path = locationStrArray[3];
      if (this.path === en) {
        sessionStorage.setItem('lang', en);
      } else {
        sessionStorage.setItem('lang', bg);
      }
    }
  }
}
