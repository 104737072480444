import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprWheelSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationExprWheel]',
  template: '<svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M24 18.82v-8.64"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M19.07 22.4l-8.21-2.67"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M20.95 28.19l-5.07 6.99"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M27.05 28.19l5.07 6.99"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M28.93 22.4l8.21-2.67"></svg:path><svg:circle cx="24" cy="24" r="19" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none"></svg:circle><svg:circle cx="24" cy="24" r="13.82" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none"></svg:circle><svg:circle cx="24" cy="24" r="5.18" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none"></svg:circle>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprWheelSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprWheelSvgComponent,
  ],
  exports: [
    TransportationExprWheelSvgComponent,
  ],
})
export class TransportationExprWheelSvgModule {}
