import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprUmbrellaSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprUmbrella]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 4.8 4.8)"><svg:path d="M24 22.875v19a4.776 4.776 0 0 1-9.551 0" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-color="color-2"></svg:path><svg:path d="M38.27 18.525a9.507 9.507 0 0 0-7.149 3.24 9.507 9.507 0 0 0-14.3 0 9.507 9.507 0 0 0-7.149-3.24 10.215 10.215 0 0 0-6.507 2.234C4.4 11.05 12.755 4.227 23.972 4.227c11.319 0 19.753 6.916 20.861 16.793a11.088 11.088 0 0 0-6.563-2.495z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path d="M24 1.351v2.876" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprUmbrellaSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprUmbrellaSvgComponent,
  ],
  exports: [
    InformationExprUmbrellaSvgComponent,
  ],
})
export class InformationExprUmbrellaSvgModule {}
