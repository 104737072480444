import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Date as AtlasDate} from '@atlas/businesstypes';
import {DateFormatterService} from '@b2b-frontend/core';

const dateFormatterService = new DateFormatterService();

export function pastDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date1 = control.value as AtlasDate;
    const date2 = new Date();
    const dateDiff =
      date1 instanceof AtlasDate ? dateFormatterService.isoDateDifference(date1, date2) : undefined;
    if (dateDiff !== undefined && dateDiff !== null && dateDiff > 0) {
      return {pastDate: true};
    }
    return null;
  };
}

export function sameDates(date1: AtlasDate, date2: AtlasDate | Date = new Date()): boolean {
  if (
    date1 instanceof AtlasDate &&
    (date2 instanceof AtlasDate || date2 instanceof Date) &&
    dateFormatterService.isoDateDifference(date1, date2) === 0
  ) {
    return true;
  }
  return false;
}
