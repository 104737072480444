import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncCameraPlusSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncCameraPlus]',
  template: '<svg:circle cx="10.83" cy="11.83" r="3.78" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle><svg:path d="M10 18.64H4a1.52 1.52 0 0 1-1.5-1.51V7.29A1.52 1.52 0 0 1 4 5.77h3L8.56 3.5h4.54l1.51 2.27h3a1.52 1.52 0 0 1 1.51 1.51h0v3.78" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M18 14.5v7" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M14.49 18h7" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncCameraPlusSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncCameraPlusSvgComponent,
  ],
  exports: [
    InformationFuncCameraPlusSvgComponent,
  ],
})
export class InformationFuncCameraPlusSvgModule {}
