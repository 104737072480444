/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {Pipe, PipeTransform} from '@angular/core';
import {NewsEvents} from '../../../contracts/NewsEvents';

@Pipe({
  name: 'SearchPipe',
})
export class SearchPipe implements PipeTransform {
  public transform(list: NewsEvents[], filterText: string): any {
    return list?.filter(item => {
      const englishTextMatched = item.i18n!.en
        ? item.i18n!.en.title!.search(new RegExp(filterText, 'i')) > -1
        : false;
      const bulgarianTextMatched = item.i18n!.bg
        ? item.i18n!.bg.title!.search(new RegExp(filterText, 'i')) > -1
        : false;
      return englishTextMatched || bulgarianTextMatched;
    });
  }
}
