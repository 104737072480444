<systemcode-webcam-snapshot
  *ngIf="showWebCam"
  [loopCaptureImage]="loopCaptureImage"
  (backToScanOptions)="backToScanOptions($event)"
  (emitCapturedImage)="capturedImage($event)"
></systemcode-webcam-snapshot>

<div *ngIf="!showWebCam">
  <maia-label class="title-color-size m-b-16" i18n="@@nje-b2b-frontend-talonscan-talonsample"
    >Talon Sample</maia-label
  >
  <maia-fluid-fluid-layout class="m-b-48">
    <img src="../en/assets/img/talon.svg" />
  </maia-fluid-fluid-layout>
  <div class="m-t-24px">
    <maia-label class="label-color-size" i18n="@@nje-b2b-frontend-talonscan-upload-content"
      >Please upload file containing the MRZ information</maia-label
    >

    <input
      class="hide-input-file"
      #imageInput
      type="file"
      accept="image/*"
      (change)="uploadInputChange($event, 'upload')"
    />
    <maia-fluid-fluid-layout>
      <maia-tile
        class="show-hand upload-tile m-t-8"
        [height]="currentHeight"
        (click)="imageInput.click()"
      >
        <maia-tile-items horizontal>
          <span class="show-hand" i18n="@@nje-b2b-frontend-talonscan-upload-talon"
            >Upload Talon</span
          >
          <maia-tile-item contentAlign="right">
            <maia-tile-button-action-icon>
              <svg class="icon-color" b2bFrontendFileFuncCloudUpload></svg>
            </maia-tile-button-action-icon>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
    </maia-fluid-fluid-layout>
    <div *ngIf="this.showErroMsg && modeOfUploading === 'upload'">
      <maia-fluid-fluid-layout>
        <maia-contextual-message
          class="contextual-message"
          type="error"
          i18n="@@nje-b2b-frontend-talonscan-invalid-image"
        >
          Invalid Image
        </maia-contextual-message>
      </maia-fluid-fluid-layout>
    </div>
  </div>
  <maia-fluid-fluid-layout>
    <maia-enriched-divider>
      <span i18n="@@nje-b2b-frontend-talonscan-or">Or</span>
    </maia-enriched-divider>
  </maia-fluid-fluid-layout>
  <div class="m-t-24px">
    <maia-label class="label-color-size" i18n="@@nje-b2b-frontend-talonscan-takepic-content"
      >Please take a picture of the side containing the MRZ information</maia-label
    >
    <maia-fluid-fluid-layout>
      <maia-tile
        *ngIf="!isMobileDevice"
        class="upload-tile m-t-8 show-hand"
        [height]="currentHeight"
        (click)="showWebCamera()"
      >
        <maia-tile-items horizontal>
          <span i18n="@@nje-b2b-frontend-talonscan-take-pic">Take a picture</span>
          <maia-tile-item contentAlign="right">
            <maia-tile-button-action-icon>
              <svg class="icon-color" b2bFrontendInformationFuncCameraPlus></svg>
            </maia-tile-button-action-icon>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
    </maia-fluid-fluid-layout>
    <div *ngIf="isMobileDevice">
      <input
        class="hide-input-file"
        #picInput
        capture="user"
        type="file"
        accept="image/*"
        (change)="uploadInputChange($event, 'camera')"
      />

      <maia-tile
        class="show-hand upload-tile m-t-8"
        [height]="currentHeight"
        (click)="picInput.click()"
      >
        <maia-tile-items horizontal>
          <span class="show-hand" i18n="@@nje-b2b-frontend-talonscan-take-pic">Take a picture</span>
          <maia-tile-item contentAlign="right">
            <maia-tile-button-action-icon>
              <svg class="icon-color" systemcodeInformationFuncCameraPlus></svg>
            </maia-tile-button-action-icon>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
      <div *ngIf="this.showErroMsg && modeOfUploading === 'camera'">
        <maia-contextual-message
          class="contextual-message"
          type="error"
          i18n="@@nje-b2b-frontend-talonscan-invalid-image"
        >
          Invalid Image
        </maia-contextual-message>
      </div>
    </div>
  </div>
</div>
