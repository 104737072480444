import {ChangeDetectionStrategy, Component, HostBinding, Input, NgModule, ViewEncapsulation} from '@angular/core';
import {coerceBooleanPrimitive} from '@atlas-angular/cdk/coercion';

/**
 * @ngModule NavigationFuncChevronSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendNavigationFuncChevron]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M9.44 16.8l5.12-4.8-5.12-4.8"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
    
    ':host(.maia-icon--down) {transform: rotate(90deg);} :host(.maia-icon--left) {transform: rotate(180deg);} :host(.maia-icon--up) {transform: rotate(-90deg);}',
  ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncChevronSvgComponent {
  @Input()
  @HostBinding('class.maia-icon--right')
  @coerceBooleanPrimitive()
  public right = true;

  @Input()
  @HostBinding('class.maia-icon--down')
  @coerceBooleanPrimitive()
  public down = false;

  @Input()
  @HostBinding('class.maia-icon--left')
  @coerceBooleanPrimitive()
  public left = false;

  @Input()
  @HostBinding('class.maia-icon--up')
  @coerceBooleanPrimitive()
  public up = false;
}

@NgModule({
  declarations: [
    NavigationFuncChevronSvgComponent,
  ],
  exports: [
    NavigationFuncChevronSvgComponent,
  ],
})
export class NavigationFuncChevronSvgModule {}
