import {
  Component,
  OnInit,
  ComponentFactory,
  Injector,
  ComponentFactoryResolver,
  ChangeDetectorRef,
} from '@angular/core';
import {FormControl, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text, Date, DateKey, DateUtils, Boolean} from '@atlas/businesstypes';
import {
  bgPhoneNumberValidator,
  CityDto,
  CityApiResponse,
  companyName,
  CoreApiService,
  DataService,
  NationalityDto,
  PartyIdService,
  PartyType,
  personName,
} from '@b2b-frontend/core';
import {DatepickerVisibleView} from '@maia/datepickers';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {SlideInController} from '@maia/slide-ins';

import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';

import {map} from 'rxjs/operators';
import {CascoWorkflow} from '../../casco-core/casco-workflow/CascoWorkflow';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';
import {PEOPLE_DETAILS_TITLES} from '../../constants/price-people-details-constants';
import {PeopleDetailsDeletePopupComponent} from './people-details-delete-popup/people-details-delete-popup.component';
import {PeopleDetailsSlideinComponent} from './people-details-slidein/people-details-slidein.component';
import {RelatedParty} from './people-details-slidein/__tests__/people-details-slidein-interface';
import {
  formCheckboxData,
  InsuringFormData,
  VehicleFormData,
} from './__tests__/insuring-party.interface';

@Component({
  selector: 'nje-people-details',
  templateUrl: './people-details.component.html',
  styleUrls: ['./people-details.component.scss'],
})
@UntilDestroy()
export class PeopleDetailsComponent extends ScreenFormComponent implements OnInit {
  public isSame = false;
  public inputText: Text;
  public checked: boolean = true;
  public isEGN: boolean = true;
  public isLNCH: boolean = false;
  public isEIK: boolean = false;
  public isEGNVo: boolean = true;
  public isLNCHVo: boolean = false;
  public isEIKVo: boolean = false;
  public isrepLNCH: boolean = false;
  public isrepEGN: boolean = true;
  public isContinue: boolean = false;
  public continueClicked: boolean = true;
  public disabled = false;
  public searchEgn: Text;
  public repId: Text;
  public textSearched: Text;
  public insurancePartyType: PartyType | undefined;
  public vehicleOwnerPartyType: PartyType | undefined;
  private readonly _peopleDetailsSlideInFactory: ComponentFactory<PeopleDetailsSlideinComponent>;
  private readonly _popUpDeleteFactory: ComponentFactory<PeopleDetailsDeletePopupComponent>;
  public addedRelatedPartyArray: RelatedParty[] = [];
  public insuringPartyForm: FormGroupManager<InsuringFormData> =
    this._formFactory.createFormManager<InsuringFormData>({
      idNumber: new FormControl(undefined, [
        Validators.required,
        this._insuringPartyIdNumberValidator.bind(this),
      ]),
      firstNameEn: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      middleNameEn: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      lastNameEn: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      firstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      middleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      lastName: new FormControl(undefined, [Validators.required, personName('bg')]),
      companyName: new FormControl(undefined, [Validators.required, companyName('bg')]),
      nationality: new FormControl(undefined, Validators.required),
      citizenship: new FormControl(undefined, [Validators.required]),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
      quarter: new FormControl(undefined, [Validators.required]),
      street: new FormControl(undefined),
      streetNumber: new FormControl(undefined),
      block: new FormControl(undefined),
      entrance: new FormControl(undefined),
      floor: new FormControl(undefined),
      apartment: new FormControl(undefined),
      dob: new FormControl(undefined, [Validators.required]),
      phoneNumber: new FormControl(undefined, [bgPhoneNumberValidator({allowZero: true})]),
      emailId: new FormControl(undefined),
      repFirstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      repMiddleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      repLastName: new FormControl(undefined, [Validators.required, personName('bg')]),
      repFirstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      repMiddleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      repLastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      repCitizenship: new FormControl(undefined, [Validators.required]),
      repDob: new FormControl(undefined, [Validators.required]),
    });

  public formCheckbox: FormGroupManager<formCheckboxData> =
    this._formFactory.createFormManager<formCheckboxData>({
      checkValue: new FormControl(undefined),
    });

  public vehicleOwnerForm: FormGroupManager<VehicleFormData> =
    this._formFactory.createFormManager<VehicleFormData>({
      idNumberVo: new FormControl(undefined, [
        Validators.required,
        this._vehicleOwnerIdNumberValidator.bind(this),
      ]),
      firstNameEnVo: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      middleNameEnVo: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      lastNameEnVo: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      firstNameVo: new FormControl(undefined, [Validators.required, personName('bg')]),
      middleNameVo: new FormControl(undefined, [Validators.required, personName('bg')]),
      lastNameVo: new FormControl(undefined, [Validators.required, personName('bg')]),
      companyNameVo: new FormControl(undefined, [Validators.required, companyName('bg')]),
      citizenshipVo: new FormControl(undefined, Validators.required),
      cityVo: new FormControl(undefined, [Validators.required]),
      postCodeVo: new FormControl(undefined, [Validators.required]),
      quarterVo: new FormControl(undefined, [Validators.required]),
      streetVo: new FormControl(undefined),
      streetNumberVo: new FormControl(undefined),
      blockVo: new FormControl(undefined),
      entranceVo: new FormControl(undefined),
      floorVo: new FormControl(undefined),
      apartmentVo: new FormControl(undefined),
      dobVo: new FormControl(undefined, [Validators.required]),
      phoneNumberVo: new FormControl(undefined, [bgPhoneNumberValidator({allowZero: true})]),
      emailIdVo: new FormControl(undefined),
    });

  public visibleDateView?: DatepickerVisibleView = undefined;
  public minDate: Date = new Date('01011900');
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public birthDatePicker = this.insuringPartyForm.createDatePicker('dob');

  public cpcController = this.insuringPartyForm.createCityAndPostCode('city', 'postCode');
  public cityAutocomplete =
    this.insuringPartyForm.createAutocompleteWithServersideFiltering<CityDto>('city', (q: string) =>
      this._coreApiService
        .getPlaceOfResidence(q, true)
        .pipe(map((res: CityApiResponse) => res.data)),
    );

  public citizenshipAutocomplete =
    this.insuringPartyForm.createAutocompleteWithServersideFiltering<NationalityDto>(
      'citizenship',
      (q: string) => this._coreApiService.getNationalities(q, true).pipe(map(res => res.data)),
    );

  public repCitizenshipAutocomplete =
    this.insuringPartyForm.createAutocompleteWithServersideFiltering<NationalityDto>(
      'repCitizenship',
      (q: string) => this._coreApiService.getNationalities(q, true).pipe(map(res => res.data)),
    );

  public constructor(
    private readonly _process: CascoProcessService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _partyIdService: PartyIdService,
    private readonly _coreApiService: CoreApiService,
    private readonly _partyId: PartyIdService,
    loggerFactory: LoggerFactory,
    route: ActivatedRoute,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _dataService: DataService,
    private readonly _popUpCtrl: PopUpController,
  ) {
    super(route);
    this._process.currentWorkflow = CascoWorkflow.PRICE_OFFER;
    this._peopleDetailsSlideInFactory = this._componentFactoryResolver.resolveComponentFactory(
      PeopleDetailsSlideinComponent,
    );
    this._popUpDeleteFactory = this._componentFactoryResolver.resolveComponentFactory(
      PeopleDetailsDeletePopupComponent,
    );
  }

  protected saveMtplData(): void {
    this.continueClicked = true;
  }

  public ngOnInit(): void {
    this._isSamePerson();
  }

  private _isSamePerson(): void {
    this.formCheckbox.controls.checkValue.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: Boolean) => {
        if (value) {
          if (value.equals(Boolean.TRUE)) {
            this.vehicleOwnerForm.formGroup.disable();
            this.isSame = true;
            this.patchVehicleDetails();
            const idNumberValue = this.insuringPartyForm.controls.idNumber.value as string;
            const type = idNumberValue ? this._partyIdService.getType(idNumberValue) : null;

            switch (type) {
              case PartyType.PERSON:
                this.isEGNVo = true;
                this.isEIKVo = false;
                this.isLNCHVo = false;
                break;
              case PartyType.FOREIGNER:
                this.isEGNVo = false;
                this.isEIKVo = false;
                this.isLNCHVo = true;
                break;
              case PartyType.COMPANY:
                this.isEGNVo = false;
                this.isEIKVo = true;
                this.isLNCHVo = false;
                break;
            }
          } else if (value.equals(Boolean.FALSE)) {
            this.vehicleOwnerForm.formGroup.reset();
            this.vehicleOwnerForm.formGroup.enable();
            this.isSame = false;
          }
        }
      });
  }

  public patchVehicleDetails(): void {
    this.vehicleOwnerForm.patchValue({
      idNumberVo: this.insuringPartyForm.controls.idNumber.value,
      firstNameEnVo: this.insuringPartyForm.controls.firstNameEn.value,
      middleNameEnVo: this.insuringPartyForm.controls.middleNameEn.value,
      lastNameEnVo: this.insuringPartyForm.controls.lastNameEn.value,
      firstNameVo: this.insuringPartyForm.controls.firstName.value,
      middleNameVo: this.insuringPartyForm.controls.middleName.value,
      lastNameVo: this.insuringPartyForm.controls.lastName.value,
      companyNameVo: this.insuringPartyForm.controls.companyName.value,
      citizenshipVo: this.insuringPartyForm.controls.nationality.value,
      cityVo: this.insuringPartyForm.controls.city.value,
      postCodeVo: this.insuringPartyForm.controls.postCode.value,
      quarterVo: this.insuringPartyForm.controls.quarter.value,
      streetVo: this.insuringPartyForm.controls.street.value,
      streetNumberVo: this.insuringPartyForm.controls.streetNumber.value,
      blockVo: this.insuringPartyForm.controls.block.value,
      entranceVo: this.insuringPartyForm.controls.entrance.value,
      floorVo: this.insuringPartyForm.controls.floor.value,
      apartmentVo: this.insuringPartyForm.controls.apartment.value,
      dobVo: this.insuringPartyForm.controls.dob.value,
      phoneNumberVo: this.insuringPartyForm.controls.phoneNumber.value,
      emailIdVo: this.insuringPartyForm.controls.emailId.value,
    });
  }

  public fillVehicleForm(): void {
    const value: Boolean | undefined = this.formCheckbox.controls.checkValue.value;
    if (value && value.equals(Boolean.TRUE)) {
      this.patchVehicleDetails();
    }
  }

  private _insuringPartyIdNumberValidator(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const idNumberType = this._partyIdService.getType(control.value);
      switch (idNumberType) {
        case PartyType.PERSON:
          this.isEGN = true;
          this.isEIK = false;
          this.isLNCH = false;
          return null;

        case PartyType.FOREIGNER:
          this.isEGN = false;
          this.isEIK = false;
          this.isLNCH = true;
          return null;
        case PartyType.COMPANY:
          this.isEGN = false;
          this.isEIK = true;
          this.isLNCH = false;
          return null;
        default:
          this.isEGN = true;
          this.isEIK = false;
          this.isLNCH = false;
          return {invalidIdNumber: true};
      }
    }
    return null;
  }

  private _vehicleOwnerIdNumberValidator(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const idNumberType = this._partyIdService.getType(control.value);
      switch (idNumberType) {
        case PartyType.PERSON:
          this.isEGNVo = true;
          this.isEIKVo = false;
          this.isLNCHVo = false;
          return null;
        case PartyType.FOREIGNER:
          this.isEGNVo = false;
          this.isEIKVo = false;
          this.isLNCHVo = true;
          return null;
        case PartyType.COMPANY:
          this.isEGNVo = false;
          this.isEIKVo = true;
          this.isLNCHVo = false;
          return null;
        default:
          this.isEGNVo = true;
          this.isEIKVo = false;
          this.isLNCHVo = false;
          return {invalidIdNumber: true};
      }
    }
    return null;
  }

  public addRelatedParties(data?: any, index?: number): void {
    this._slideInCtrl
      .prepare(
        this._peopleDetailsSlideInFactory,
        this._injector,
        {title: PEOPLE_DETAILS_TITLES.SLIDEINTITLE},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: data,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<RelatedParty>) => {
        if (response.resolution == ModalResolution.CONFIRMED) {
          const insuringPersonData = response.result;
          if ((response && index) || index == 0) {
            this.addedRelatedPartyArray[index] = insuringPersonData;
          } else {
            this.addedRelatedPartyArray.push(insuringPersonData);
          }
          if (
            (insuringPersonData.idNumber === undefined || insuringPersonData.idNumber === null) &&
            (index || index === 0)
          ) {
            this.addedRelatedPartyArray.splice(index, 1);
          }
        }
        this._cdRef.detectChanges();
      });
  }

  public editRelatedPartyDetails(data: RelatedParty, index: number): void {
    this.addRelatedParties(data, index);
  }

  public handleInputText(id: Text) {
    this.inputText = id;
    if (null != id && 8 <= id.asString().length) {
      this.insurancePartyType = this._partyId.getType(id.asString());
      this.isEGN = this.insurancePartyType === PartyType.PERSON;
      this.isEIK = this.insurancePartyType === PartyType.COMPANY;
      this.isLNCH = this.insurancePartyType === PartyType.FOREIGNER;
      this.insuringPartyForm.patchValue({
        idNumber: this.inputText.asString(),
      });
    }
  }

  public handleInputTextVehicleOwner(id: Text) {
    this.inputText = id;
    if (null != id && 8 <= id.asString().length) {
      this.vehicleOwnerPartyType = this._partyId.getType(id.asString());
      this.isEGNVo = this.vehicleOwnerPartyType === PartyType.PERSON;
      this.isEIKVo = this.vehicleOwnerPartyType === PartyType.COMPANY;
      this.isLNCHVo = this.vehicleOwnerPartyType === PartyType.FOREIGNER;
      this.vehicleOwnerForm.patchValue({
        idNumberVo: this.inputText.asString(),
      });
    }
  }

  public vehicleOwnerDetails(): boolean {
    this._dataService.setDetails({
      formValidation: this.insuringPartyForm.valid,
      sameDetails: this.insuringPartyForm,
      address: this.insuringPartyForm.value,
    });
    return this.isSame;
  }

  public handleRepresentative(repPersonId: Text) {
    this.repId = repPersonId;
    if (null != repPersonId && 8 <= repPersonId.asString().length) {
      this.insurancePartyType = this._partyId.getType(repPersonId.asString());
      this.isrepEGN = this.insurancePartyType === PartyType.PERSON;
      this.isrepLNCH = this.insurancePartyType === PartyType.FOREIGNER;
    }
  }

  public handleSearchedText(text: Text) {
    this.textSearched = text;
  }

  public removeRelatedParty(index: number): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: PEOPLE_DETAILS_TITLES.DELETETITLE, size: 'large'},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this.addedRelatedPartyArray.splice(index, 1);
        }
        this._cdRef.detectChanges();
      });
  }
}
