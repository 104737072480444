<form maiaForm [formGroup]="stepForm1.formGroup">
  <maia-label>
    <div class="b2b-kdl-font-small title-dark-color"> Insured Party </div>
  </maia-label>
  <maia-fluid-fluid-layout alignContentTop *ngIf="!isEReceipt">
    <div class="m-r-16">
      <maia-form-element
        hideOptionalIndicator
        class="input-column"
        label="EGN / LNCH / EIK"
        [options]="{validationInstant: true}"
      >
        <input
          maiaInput
          name="EGN / LNCH / EIK"
          formControlName="uniqueId"
          placeholder="EGN / LNCH / EIK"
        />
        <maia-validation
          *ngIf="
            stepForm1.hasToShowErrors('uniqueId') && stepForm1.hasError('uniqueId', 'required')
          "
        >
          EGN/LNCH/EIK required
        </maia-validation>
        <maia-validation
          *ngIf="
            stepForm1.hasToShowErrors('uniqueId') && stepForm1.hasError('uniqueId', 'invalidNumber')
          "
        >
          Invalid EGN or LNCH or EIK number.
        </maia-validation>
      </maia-form-element> </div
    ><div></div>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout alignContentTop *ngIf="!isEIK">
    <div class="fluid m-r-16">
      <maia-form-element
        [options]="{validationInstant: true}"
        hideOptionalIndicator
        class="multi-input-row"
        label="Full Name"
        multi
      >
        <maia-form-subelement>
          <input maiaInput formControlName="firstName" name="firstName" placeholder="First Name" />
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('firstName') && stepForm1.hasError('firstName', 'required')
            "
            >First name required</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('firstName') &&
              stepForm1.hasError('firstName', 'nonLatinLetters')
            "
            >First name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('firstName') && stepForm1.hasError('firstName', 'nonBlank')
            "
            >Invalid first name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('firstName') && stepForm1.hasError('firstName', 'maxlength')
            "
            >First name is too long (maximum is
            {{ stepForm1.controls['firstName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            formControlName="middleName"
            name="middleName"
            placeholder="Middle Name"
          />

          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('middleName') &&
              stepForm1.hasError('middleName', 'required')
            "
            >Middle name required</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('middleName') &&
              stepForm1.hasError('middleName', 'nonLatinLetters')
            "
            >Middle name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('middleName') &&
              stepForm1.hasError('middleName', 'nonBlank')
            "
            >Invalid middle name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('middleName') &&
              stepForm1.hasError('middleName', 'maxlength')
            "
            >Middle name is too long (maximum is
            {{ stepForm1.controls['middleName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput formControlName="lastName" name="lastName" placeholder="Last Name" />

          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('lastName') && stepForm1.hasError('lastName', 'required')
            "
            >Last name required</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('lastName') &&
              stepForm1.hasError('lastName', 'nonLatinLetters')
            "
            >Last name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('lastName') && stepForm1.hasError('lastName', 'nonBlank')
            "
            >Invalid last name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('lastName') && stepForm1.hasError('lastName', 'maxlength')
            "
            >Last name is too long (maximum is
            {{ stepForm1.controls['lastName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-subelement>
      </maia-form-element>
    </div>
    <div class="fluid">
      <maia-form-element
        hideOptionalIndicator
        [options]="{validationInstant: true}"
        class="multi-input-row"
        label="Full Name in English"
        multi
      >
        <maia-form-subelement>
          <input
            maiaInput
            formControlName="firstNameEn"
            name="firstNameEn"
            placeholder="First Name"
          />

          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('firstNameEn') &&
              stepForm1.hasError('firstNameEn', 'required')
            "
            >First name required</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('firstNameEn') &&
              stepForm1.hasError('firstNameEn', 'nonCyrillicLetters')
            "
            >First name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('firstNameEn') &&
              stepForm1.hasError('firstNameEn', 'nonBlank')
            "
            >Invalid first name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('firstNameEn') &&
              stepForm1.hasError('firstNameEn', 'maxlength')
            "
            >First name is too long (maximum is
            {{ stepForm1.controls['firstNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            formControlName="middleNameEn"
            name="middleNameEn"
            placeholder="Middle Name"
          />

          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('middleNameEn') &&
              stepForm1.hasError('middleNameEn', 'required')
            "
            >Middle name required</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('middleNameEn') &&
              stepForm1.hasError('middleNameEn', 'nonCyrillicLetters')
            "
            >Middle name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('middleNameEn') &&
              stepForm1.hasError('middleNameEn', 'nonBlank')
            "
            >Invalid middle name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('middleNameEn') &&
              stepForm1.hasError('middleNameEn', 'maxlength')
            "
            >Middle name is too long (maximum is
            {{ stepForm1.controls['middleNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput formControlName="lastNameEn" name="lastNameEn" placeholder="Last Name" />

          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('lastNameEn') &&
              stepForm1.hasError('lastNameEn', 'required')
            "
            >Last name required</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('lastNameEn') &&
              stepForm1.hasError('lastNameEn', 'nonCyrillicLetters')
            "
            >Last name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('lastNameEn') &&
              stepForm1.hasError('lastNameEn', 'nonBlank')
            "
            >Invalid last name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm1.hasToShowErrors('lastNameEn') &&
              stepForm1.hasError('lastNameEn', 'maxlength')
            "
            >Last name is too long (maximum is
            {{ stepForm1.controls['lastNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout alignContentTop *ngIf="isEIK">
    <div class="fluid m-r-16">
      <maia-fluid-fluid-layout alignContentTop>
        <div class="m-r-16">
          <maia-form-element
            hideOptionalIndicator
            class="input-column"
            label="Company Name"
            [options]="{validationInstant: true}"
          >
            <input
              maiaInput
              formControlName="companyName"
              placeholder="Company Name"
              name="company name"
            />
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('companyName') &&
                stepForm1.hasError('companyName', 'required')
              "
            >
              Company name required
            </maia-validation>
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('companyName') &&
                stepForm1.hasError('companyName', 'nonLatinLetters')
              "
              >Company name - only cyrillic letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('companyName') &&
                stepForm1.hasError('companyName', 'nonBlank')
              "
              >Invalid company name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('companyName') &&
                stepForm1.hasError('companyName', 'maxlength')
              "
              >Company name is too long (maximum is
              {{ stepForm1.controls['companyName'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>
    </div>
    <div class="fluid">
      <maia-fluid-fluid-layout alignContentTop>
        <div class="m-r-16">
          <maia-form-element
            hideOptionalIndicator
            class="input-column"
            label="Company name in English"
            [options]="{validationInstant: true}"
          >
            <input
              maiaInput
              formControlName="companyNameEn"
              placeholder="Company Name in English"
              name="company name in english"
            />
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('companyNameEn') &&
                stepForm1.hasError('companyNameEn', 'required')
              "
            >
              Company name in latin required
            </maia-validation>
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('companyNameEn') &&
                stepForm1.hasError('companyNameEn', 'nonCyrillicLetters')
              "
              >Company name - only latin letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('companyNameEn') &&
                stepForm1.hasError('companyNameEn', 'nonBlank')
              "
              >Invalid company name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('companyNameEn') &&
                stepForm1.hasError('companyNameEn', 'maxlength')
              "
              >Company name is too long (maximum is
              {{ stepForm1.controls['companyNameEn'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>
    </div>
  </maia-fluid-fluid-layout>

  <!-- load the below template dynamically based on instalments policy type -->

  <div formArrayName="policyDetails">
    <div *ngFor="let policy of policyDetailsArray.controls; let i = index">
      <div [formGroup]="policy" *ngIf="i == 0">
        <div class="grey-box-div">
          <maia-fluid-fluid-layout alignContentTop>
            <maia-label class="b2b-kdl-font-small-bold title-dark-color" sublabel="85420000001238"
              >Property Insurance</maia-label
            >

            <div>
              <maia-avatar-display class="home-car-icon-adjust">
                <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
                  <svg b2bFrontendPlacesExprHouse class="home-icon"></svg>
                </maia-avatar-svg>
              </maia-avatar-display>
            </div>
          </maia-fluid-fluid-layout>
          <maia-divider></maia-divider>
          <maia-fluid-fluid-layout alignContentTop>
            <div class="fluid m-r-16">
              <maia-form-element
                [options]="{validationInstant: true}"
                hideOptionalIndicator
                class="multi-input-row"
                label="Property Type"
                multi
              >
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="propertyType"
                    name="propertyType"
                    placeholder="Villa"
                  />
                  <maia-validation
                    *ngIf="
                      policy.get('propertyType').hasError('required') &&
                      policy.get('propertyType').dirty
                    "
                  >
                    Property type is required
                  </maia-validation>
                </maia-form-subelement>
              </maia-form-element>
            </div>
            <div class="fluid">
              <maia-form-element
                [options]="{validationInstant: true}"
                hideOptionalIndicator
                class="multi-input-row"
                label="Area of Property"
                multi
              >
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="propertyArea"
                    name="areaOfProperty"
                    placeholder="1500 Sq.m."
                  />
                  <maia-validation
                    *ngIf="
                      policy.get('propertyArea').hasError('required') &&
                      policy.get('propertyArea').dirty
                    "
                  >
                    Property area is required
                  </maia-validation>
                </maia-form-subelement>
              </maia-form-element>
            </div>
          </maia-fluid-fluid-layout>
          <maia-fluid-fluid-layout alignContentTop>
            <div class="fluid m-r-16">
              <maia-form-element
                [options]="{validationInstant: true}"
                hideOptionalIndicator
                class="multi-input-row"
                label="City & Zip"
                multi
              >
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="city"
                    name="cityZip"
                    class="custom-cityzip-width"
                    placeholder="Sofia"
                    required
                  />
                  <maia-validation
                    *ngIf="policy.get('city').hasError('required') && policy.get('city').dirty"
                  >
                    City is required
                  </maia-validation>
                </maia-form-subelement>
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="zip"
                    name="1000"
                    class="custom-number-width"
                    placeholder="1000"
                  />
                </maia-form-subelement>
              </maia-form-element>
            </div>
            <div class="fluid">
              <maia-form-element
                [options]="{validationInstant: true}"
                hideOptionalIndicator
                class="multi-input-row"
                label="Quarter"
                multi
              >
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="quarter"
                    name="quarter"
                    placeholder="Quarter"
                    required
                  />
                  <maia-validation
                    *ngIf="
                      policy.get('quarter').hasError('required') && policy.get('quarter').dirty
                    "
                  >
                    Quarter is required
                  </maia-validation>
                </maia-form-subelement>
              </maia-form-element>
            </div>
          </maia-fluid-fluid-layout>
          <maia-fluid-fluid-layout alignContentTop>
            <div class="fluid m-r-16">
              <maia-form-element
                [options]="{validationInstant: true}"
                hideOptionalIndicator
                class="multi-input-row"
                label="Street & Number"
                multi
              >
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="streetName"
                    name="streetNumber"
                    class="custom-cityzip-width"
                    placeholder="Nikoa Vaptzarow Blvd"
                    required
                  />
                  <maia-validation
                    *ngIf="
                      policy.get('streetName').hasError('required') &&
                      policy.get('streetName').dirty
                    "
                  >
                    Street Name is required
                  </maia-validation>
                </maia-form-subelement>
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="streetNumber"
                    name="number"
                    placeholder="Number"
                  />
                  <maia-validation
                    *ngIf="
                      policy.get('streetNumber').hasError('required') &&
                      policy.get('streetNumber').dirty
                    "
                  >
                    Street Number is required
                  </maia-validation>
                </maia-form-subelement>
              </maia-form-element>
            </div>
            <div class="fluid">
              <maia-form-element
                [hideOptionalIndicator]="false"
                class="multi-input-row"
                label="Block, Entrance, Floor & Apartment"
                multi
              >
                <maia-form-subelement>
                  <input
                    maiaInput
                    type="text"
                    formControlName="block"
                    name="block"
                    placeholder="Block"
                  />
                </maia-form-subelement>
                <maia-form-subelement>
                  <input
                    maiaInput
                    type="text"
                    formControlName="entrance"
                    name="ent"
                    placeholder="Ent."
                  />
                </maia-form-subelement>
                <maia-form-subelement>
                  <input
                    maiaInput
                    type="text"
                    formControlName="floor"
                    name="floor"
                    placeholder="Floor"
                  />
                </maia-form-subelement>
                <maia-form-subelement>
                  <input
                    maiaInput
                    type="text"
                    formControlName="apartment"
                    name="apartment"
                    placeholder="Apart."
                  />
                </maia-form-subelement>
              </maia-form-element>
            </div>
          </maia-fluid-fluid-layout>
        </div>
      </div>

      <div [formGroup]="policy" *ngIf="i == 1">
        <div class="grey-box-div">
          <maia-fluid-fluid-layout alignContentTop>
            <maia-label
              class="b2b-kdl-font-small-bold title-dark-color"
              sublabel="'BG/06/120000001238'"
              >Motor Third Party Liability Insurance</maia-label
            >

            <div>
              <maia-avatar-display class="home-car-icon-adjust">
                <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
                  <svg b2bFrontendTransportationExprCarNew class="new-car-icon"></svg>
                </maia-avatar-svg>
              </maia-avatar-display>
            </div>
          </maia-fluid-fluid-layout>
          <maia-divider></maia-divider>
          <maia-fluid-fluid-layout alignContentTop>
            <div class="fluid m-r-16">
              <maia-form-element
                hideOptionalIndicator
                [options]="{validationInstant: true}"
                class="multi-input-row"
                label="Vehicle Registration Number"
                multi
              >
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="vehicleRegNo"
                    name="vehicleRegNo"
                    placeholder="H9357BC"
                  />
                  <maia-validation
                    *ngIf="
                      policy.get('vehicleRegNo').hasError('invalidNumber') &&
                      policy.get('vehicleRegNo').dirty
                    "
                    >Please enter correct data for registration number
                  </maia-validation>
                  <maia-validation
                    *ngIf="
                      policy.get('vehicleRegNo').hasError('required') &&
                      policy.get('vehicleRegNo').dirty
                    "
                  >
                    Please enter Vehicle registration No.
                  </maia-validation>
                </maia-form-subelement>
              </maia-form-element>
            </div>
            <div class="fluid">
              <maia-form-element
                [options]="{validationInstant: true}"
                hideOptionalIndicator
                class="multi-input-row"
                label="Vehicle Purpose"
                multi
              >
                <maia-form-subelement>
                  <input
                    maiaInput
                    formControlName="vehiclePurpose"
                    name="vehiclePurpose"
                    placeholder="Personal Use"
                    required
                  />
                  <maia-validation
                    *ngIf="
                      policy.get('vehiclePurpose').hasError('required') &&
                      policy.get('vehiclePurpose').dirty
                    "
                  >
                    Vehicle Purpose is required
                  </maia-validation>
                </maia-form-subelement>
              </maia-form-element>
            </div>
          </maia-fluid-fluid-layout>

          <div *ngIf="isEReceipt">
            <div class="b2b-kdl-font-small-bold title-dark-color m-t-24"
              ><maia-label> Sticker: </maia-label>
            </div>
            <maia-fluid-fluid-layout alignContentTop>
              <div class="sticker-section m-r-16">
                <maia-form-element
                  [options]="{validationInstant: true}"
                  hideOptionalIndicator
                  class="input-column"
                  label="Blank Serial No."
                  [options]="{validationInstant: true}"
                >
                  <input maiaInput formControlName="stickerBlankSlNo" name="Blank Serial No." />
                  <maia-validation
                    *ngIf="
                      policy.get('stickerBlankSlNo').hasError('required') &&
                      policy.get('stickerBlankSlNo').dirty
                    "
                  >
                    Blank Serial No is required
                  </maia-validation>
                </maia-form-element>
                <maia-form-element
                  [options]="{validationInstant: true}"
                  hideOptionalIndicator
                  class="input-column"
                  label="Blank No."
                  [options]="{validationInstant: true}"
                >
                  <input maiaInput formControlName="stickerBlankNo" name="Blank No." />
                  <maia-validation
                    *ngIf="
                      policy.get('stickerBlankNo').hasError('required') &&
                      policy.get('stickerBlankNo').dirty
                    "
                  >
                    Blank No is required
                  </maia-validation>
                </maia-form-element>
              </div>
              <div class="sticker-section">
                <maia-form-element
                  [options]="{validationInstant: true}"
                  hideOptionalIndicator
                  class="input-column"
                  [options]="{validationInstant: true}"
                >
                  <maia-label>
                    <div class="b2b-kdl-font-small title-dark-color"> Start Date </div>
                  </maia-label>
                  <maia-input-date
                    [disableWeekends]="disableWeekends"
                    [visibleView]="visibleDateView"
                    [minimum]="minDate"
                    [maximum]="maxDate"
                    formControlName="stickerStartDate"
                    name="startDate"
                  ></maia-input-date>
                  <maia-validation
                    *ngIf="
                      policy.get('stickerStartDate').hasError('required') &&
                      policy.get('stickerStartDate').dirty
                    "
                  >
                    StartDate is required
                  </maia-validation>
                </maia-form-element>
                <maia-form-element
                  [options]="{validationInstant: true}"
                  hideOptionalIndicator
                  class="input-column"
                  [options]="{validationInstant: true}"
                >
                  <maia-label>
                    <div class="b2b-kdl-font-small title-dark-color"> End Date </div>
                  </maia-label>
                  <maia-input-date
                    [disableWeekends]="disableWeekends"
                    [visibleView]="visibleDateView"
                    [minimum]="minDate"
                    [maximum]="maxDate"
                    formControlName="stickerEndDate"
                    name="endDate"
                  ></maia-input-date>
                  <maia-validation
                    *ngIf="
                      policy.get('stickerEndDate').hasError('required') &&
                      policy.get('stickerEndDate').dirty
                    "
                  >
                    End Date is required
                  </maia-validation>
                </maia-form-element>
              </div>
            </maia-fluid-fluid-layout>

            <maia-divider></maia-divider>

            <div class="b2b-kdl-font-small-bold title-dark-color m-t-24"
              ><maia-label> Green Card: </maia-label>
            </div>
            <maia-fluid-fluid-layout alignContentTop>
              <div class="green-card-section m-r-16">
                <maia-form-element
                  [options]="{validationInstant: true}"
                  hideOptionalIndicator
                  class="input-column"
                  label="Blank Serial No."
                  [options]="{validationInstant: true}"
                >
                  <input maiaInput formControlName="cardBlankSlNo" name="Blank Serial No." />
                  <maia-validation
                    *ngIf="
                      policy.get('cardBlankSlNo').hasError('required') &&
                      policy.get('cardBlankSlNo').dirty
                    "
                  >
                    Blank Serial No is required
                  </maia-validation>
                </maia-form-element>
                <maia-form-element
                  [options]="{validationInstant: true}"
                  hideOptionalIndicator
                  class="input-column"
                  label="Blank No."
                  [options]="{validationInstant: true}"
                >
                  <input maiaInput formControlName="cardBlankNo" name="Blank No." />
                  <maia-validation
                    *ngIf="
                      policy.get('cardBlankNo').hasError('required') &&
                      policy.get('cardBlankNo').dirty
                    "
                  >
                    Blank No is required
                  </maia-validation>
                </maia-form-element>
              </div>
              <div class="green-card-section">
                <maia-form-element
                  [options]="{validationInstant: true}"
                  hideOptionalIndicator
                  class="input-column"
                  [options]="{validationInstant: true}"
                >
                  <maia-label>
                    <div class="b2b-kdl-font-small title-dark-color"> Start Date </div>
                  </maia-label>
                  <maia-input-date
                    [disableWeekends]="disableWeekends"
                    [visibleView]="visibleDateView"
                    [minimum]="minDate"
                    [maximum]="maxDate"
                    formControlName="cardStartDate"
                    name="startDate"
                  ></maia-input-date>
                  <maia-validation
                    *ngIf="
                      policy.get('cardStartDate').hasError('required') &&
                      policy.get('cardStartDate').dirty
                    "
                  >
                    Start Date is required
                  </maia-validation>
                </maia-form-element>
                <maia-form-element
                  [options]="{validationInstant: true}"
                  hideOptionalIndicator
                  class="input-column"
                  [options]="{validationInstant: true}"
                >
                  <maia-label>
                    <div class="b2b-kdl-font-small title-dark-color"> End Date </div>
                  </maia-label>
                  <maia-input-date
                    [disableWeekends]="disableWeekends"
                    [visibleView]="visibleDateView"
                    [minimum]="minDate"
                    [maximum]="maxDate"
                    formControlName="cardEndDate"
                    name="endDate"
                  ></maia-input-date>
                  <maia-validation
                    *ngIf="
                      policy.get('cardEndDate').hasError('required') &&
                      policy.get('cardEndDate').dirty
                    "
                  >
                    End Date is required
                  </maia-validation>
                </maia-form-element>
              </div>
            </maia-fluid-fluid-layout>
          </div>
        </div>
      </div>
    </div>
  </div>

  <maia-divider></maia-divider>

  <div *ngIf="isEReceipt">
    <div class="payer-details-title">
      <maia-label>
        <div class="b2b-kdl-font-small title-dark-color"> Payer Details </div>
      </maia-label>
    </div>

    <div>
      <maia-checkbox formControlName="payerSameCheckbox">
        The Payer is the same as the Insured / Insuring person.
      </maia-checkbox>
    </div>

    <maia-fluid-fluid-layout alignContentTop *ngIf="!isPayerSame">
      <div class="fluid m-r-16">
        <maia-form-element
          [options]="{validationInstant: true}"
          hideOptionalIndicator
          class="multi-input-row"
          label="Full Name"
          multi
        >
          <maia-form-subelement>
            <input
              maiaInput
              formControlName="payerFirstName"
              name="payerFirstName"
              placeholder="First Name"
            />
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerFirstName') &&
                stepForm1.hasError('payerFirstName', 'required')
              "
              >First name required</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerFirstName') &&
                stepForm1.hasError('payerFirstName', 'nonLatinLetters')
              "
              >First name - only cyrillic letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerFirstName') &&
                stepForm1.hasError('payerFirstName', 'nonBlank')
              "
              >Invalid first name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerFirstName') &&
                stepForm1.hasError('payerFirstName', 'maxlength')
              "
              >First name is too long (maximum is
              {{ stepForm1.controls['payerFirstName'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              formControlName="payerMiddleName"
              name="payerMiddleName"
              placeholder="Middle Name"
            />
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerMiddleName') &&
                stepForm1.hasError('payerMiddleName', 'required')
              "
              >Middle name required</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerMiddleName') &&
                stepForm1.hasError('payerMiddleName', 'nonLatinLetters')
              "
              >Middle name - only cyrillic letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerMiddleName') &&
                stepForm1.hasError('payerMiddleName', 'nonBlank')
              "
              >Invalid middle name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerMiddleName') &&
                stepForm1.hasError('payerMiddleName', 'maxlength')
              "
              >Middle name is too long (maximum is
              {{ stepForm1.controls['payerMiddleName'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              formControlName="payerLastName"
              name="payerLastName"
              placeholder="Last Name"
            />
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerLastName') &&
                stepForm1.hasError('payerLastName', 'required')
              "
              >Last name required</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerLastName') &&
                stepForm1.hasError('payerLastName', 'nonLatinLetters')
              "
              >Last name - only cyrillic letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerLastName') &&
                stepForm1.hasError('payerLastName', 'nonBlank')
              "
              >Invalid last name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerLastName') &&
                stepForm1.hasError('payerLastName', 'maxlength')
              "
              >Last name is too long (maximum is
              {{ stepForm1.controls['lastName'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-subelement>
        </maia-form-element>
      </div>
      <div class="fluid">
        <maia-form-element
          [options]="{validationInstant: true}"
          hideOptionalIndicator
          class="multi-input-row"
          label="Full Name in English"
          multi
        >
          <maia-form-subelement>
            <input
              maiaInput
              formControlName="payerFirstNameEn"
              name="payerFirstNameEn"
              placeholder="First Name"
            />
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerFirstNameEn') &&
                stepForm1.hasError('payerFirstNameEn', 'required')
              "
              >First name required</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerFirstNameEn') &&
                stepForm1.hasError('payerFirstNameEn', 'nonCyrillicLetters')
              "
              >First name - only latin letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerFirstNameEn') &&
                stepForm1.hasError('payerFirstNameEn', 'nonBlank')
              "
              >Invalid first name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerFirstNameEn') &&
                stepForm1.hasError('payerFirstNameEn', 'maxlength')
              "
              >First name is too long (maximum is
              {{ stepForm1.controls['payerFirstNameEn'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              formControlName="payerMiddleNameEn"
              name="payerMiddleNameEn"
              placeholder="Middle Name"
            />
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerMiddleNameEn') &&
                stepForm1.hasError('payerMiddleNameEn', 'required')
              "
              >Middle name required</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerMiddleNameEn') &&
                stepForm1.hasError('payerMiddleNameEn', 'nonCyrillicLetters')
              "
              >Middle name - only latin letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerMiddleNameEn') &&
                stepForm1.hasError('payerMiddleNameEn', 'nonBlank')
              "
              >Invalid middle name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerMiddleNameEn') &&
                stepForm1.hasError('payerMiddleNameEn', 'maxlength')
              "
              >Middle name is too long (maximum is
              {{ stepForm1.controls['payerMiddleNameEn'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              formControlName="payerLastNameEn"
              name="payerLastNameEn"
              placeholder="Last Name"
            />
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerLastNameEn') &&
                stepForm1.hasError('payerLastNameEn', 'required')
              "
              >Last name required</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerLastNameEn') &&
                stepForm1.hasError('payerLastNameEn', 'nonCyrillicLetters')
              "
              >Last name - only latin letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerLastNameEn') &&
                stepForm1.hasError('payerLastNameEn', 'nonBlank')
              "
              >Invalid last name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm1.hasToShowErrors('payerLastNameEn') &&
                stepForm1.hasError('payerLastNameEn', 'maxlength')
              "
              >Last name is too long (maximum is
              {{ stepForm1.controls['payerLastNameEn'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-subelement>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-divider></maia-divider>
  </div>
</form>

<button
  class="m-r-24 m-t-b-24"
  [disabled]="!stepForm1.valid"
  maiaButton
  maiaButtonType="primary"
  (click)="saveAndContinue()"
  >Continue</button
>
