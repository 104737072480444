// TODO: This is temporary purpose and it will be removed after integratiom with API
export interface instalment {
  id: number;
  instalment1: number;
  offerNo: number;
  amount: number;
}

export interface TablePremiumHead {
  id: number;
  premiumHeading: string;
}

export interface TablePremiumList {
  id: number;
  row1: string;
  row2: string;
  row3: string;
  row4: string;
  row5: string;
}

export interface TableInstalmentHead {
  id: number;
  instalmentHeading: string;
}

export interface TableInstalmentList {
  id: number;
  Instalmentrow: string;
  Instalmentsrow: string;
}

export interface RadiooptionList {
  id: number;
  name: string;
  selected: boolean;
}

export interface policyDetailsList {
  id: number;
  startDate: string;
  endDate: string;
  length: string;
  use: string;
  side: string;
  driven: string;
  acquired: string;
  regNo: string;
  identificationNo: number;
  physicalPerson: string;
  address: string;
  email: string;
  noemail: string;
  phoneno: number;
  nophone: string;
  vehicleType: string;
  vehicleSubType: number;
  make: string;
  model: string;
  dateOfReg: string;
  regType: string;
  weight: string;
  volume: string;
  fuelType: string;
  enginePower: string;
  seatNo: number;
  colour: string;
}

export interface defineDocumentList {
  id: number;
  defineDoc: string;
}

export const MOCK_INSTALMENT: instalment[] = [
  {id: 1, instalment1: 338.66, offerNo: 12312312123, amount: 608.59},
];

export const MOCK_HEAD_PREMIUM: TablePremiumHead[] = [
  {id: 1, premiumHeading: 'Premium and Taxes'},
  {id: 2, premiumHeading: 'Premium'},
  {id: 3, premiumHeading: 'Corrections'},
  {id: 4, premiumHeading: 'Total'},
];

export const MOCK_PREMIUM_DATA_LIST: TablePremiumList[] = [
  {
    id: 1,

    row1: 'Main Cover',
    row2: 'Additional Cover',
    row3: 'Insurance Premium Tax',
    row4: 'GF Taxes',
    row5: 'Total Premium',
  },
  {
    id: 2,

    row1: '680.00BGN',
    row2: '55.00BGN',
    row3: '-',
    row4: '-',
    row5: '-',
  },
  {
    id: 3,

    row1: '+10%',
    row2: '-10%',
    row3: '-',
    row4: '-',
    row5: '-',
  },
  {
    id: 4,

    row1: '664.30 BGN',
    row2: '50.30 BGN',
    row3: '14.29 BGN',
    row4: '13.00 BGN',
    row5: '714.30 BGN',
  },
];

export const MOCK_HEAD_INSTALMENT: TableInstalmentHead[] = [
  {id: 1, instalmentHeading: 'Instalments'},
  {id: 2, instalmentHeading: 'Premium'},
  {id: 3, instalmentHeading: 'Insurance Premium Tax'},
  {id: 4, instalmentHeading: 'GF Taxes'},
  {id: 5, instalmentHeading: 'Total'},
];

export const MOCK_INSTALMENT_LIST: TableInstalmentList[] = [
  {id: 1, Instalmentrow: '30.07.2021', Instalmentsrow: '30.01.2022'},
  {id: 2, Instalmentrow: '300.64 BGN', Instalmentsrow: '300.64 BGN'},
  {id: 3, Instalmentrow: '25.00 BGN', Instalmentsrow: '25.00 BGN'},
  {id: 5, Instalmentrow: '13.00 BGN', Instalmentsrow: '-'},
  {id: 6, Instalmentrow: '338.66 BGN', Instalmentsrow: '325.64 BGN'},
];

export const MOCK_OPTION_LIST: RadiooptionList[] = [
  {id: 1, name: 'Bill', selected: true},
  {id: 2, name: 'Debit Issue', selected: false},
];

export const MOCK_POLICYDETAILS_LIST: policyDetailsList[] = [
  {
    id: 1,
    startDate: '22-07-2021',
    endDate: '22-07-2022',
    length: '12 Months',
    use: 'Personal Use',
    side: 'Left Side',
    driven: 'No',
    acquired: 'Yes',
    regNo: 'H9357BC',
    identificationNo: 892384579,
    physicalPerson: '9208086838 Борис Петроврисов',
    address: 'София 1000, бул. Никола Вапцаров, България 51 Б',
    email: 'boris@gmail.com',
    noemail: 'Email Unavailable',
    phoneno: +3598979901245,
    nophone: 'Mobile Unavailable',

    vehicleType: 'Car',
    vehicleSubType: 6,
    make: 'Nissan',
    model: 'June Tekna',
    dateOfReg: '25-05-2018',
    regType: 'Registration 1',
    weight: 'Total Weight',
    volume: 'Volume(ccm)',
    fuelType: 'Petrol',
    enginePower: '117 Hp 87.2469 Kw',
    seatNo: 5,
    colour: 'Black',
  },
];

export const MOCK_DEFINE_DOCUMENT_LIST: defineDocumentList[] = [
  {
    id: 1,
    defineDoc: 'Invoice',
  },
  {
    id: 2,
    defineDoc: 'Bill',
  },
];
