<p class="popup-content" i18n="@@nje-b2b-frontend-resource-articles-delete-deleteconfirm">
  Deleting this category will move all the tagged news items into general category. Are you sure you
  want to delete?</p
>
<div *maiaPopUpFooter>
  <button
    maiaButton
    maiaButtonType="secondary"
    (click)="cancel()"
    i18n="@@nje-b2b-frontend-resource-contacts-cancel"
    >Cancel</button
  >
  <button
    maiaButton
    maiaButtonType="delete"
    class="padding-button"
    (click)="delete()"
    i18n="@@nje-b2b-frontend-resource-library-list-delete"
    >Delete</button
  >
</div>
