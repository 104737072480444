<h2 class="b2b-kdl-font-grand-serif" i18n="@@nje-casco-chooseJourney-pageTitle"
  >Select the option</h2
>
<maia-fluid-fluid-layout>
  <maia-tile class="m-r-24 tile-size">
    <button maiaActionWithIcon class="text-black" (click)="toQuickQuote()">
      <maia-tile-items class="container">
        <maia-tile-title>
          <maia-tile-title-icon>
            <svg class="icon-size-color" b2bFrontendFileExprDocument></svg>
          </maia-tile-title-icon>
        </maia-tile-title>
        <maia-tile-item class="m-t-16"> Quick Quote </maia-tile-item>
      </maia-tile-items>
    </button>
  </maia-tile>
  <maia-tile class="tile-size">
    <button maiaActionWithIcon class="text-black" (click)="toPriceOffer()">
      <maia-tile-items class="container">
        <maia-tile-title>
          <maia-tile-title-icon>
            <svg class="icon-size-color" b2bFrontendFileExprDocumentAdd></svg>
          </maia-tile-title-icon>
        </maia-tile-title>
        <maia-tile-item class="m-t-16"> Price Offer </maia-tile-item>
      </maia-tile-items>
    </button>
  </maia-tile>
</maia-fluid-fluid-layout>
