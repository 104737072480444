import {NgModule} from '@angular/core';
import {RouterModule, Routes, Route} from '@angular/router';

import {PaymentsComponent} from './payments.component';
import {INSTALMENT_PAYMENTS} from './screens/instalment-payments/instalment-payments.routing.module';

export const ROUTES: Routes = [
  {
    path: '',
    component: PaymentsComponent,
    children: [
      {
        path: 'instalment-payment',
        children: [INSTALMENT_PAYMENTS],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class PaymentsRoutingModule {}

export const INSTALMENT_PAYMENTS_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./payments.routing.module').then(module => module.PaymentsRoutingModule),
};
