import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprHandCoinsSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprHandCoins]',
  template: '<svg:circle cx="30.57" cy="21.26" r="3.27" fill="none" stroke-width="1.6"></svg:circle><svg:circle cx="20.34" cy="15.13" r="3.27" fill="none" stroke-width="1.6"></svg:circle><svg:circle cx="29.55" cy="8.98" r="3.27" fill="none" stroke-width="1.6"></svg:circle><svg:path d="M35.23 30.44L39 28.28a2.64 2.64 0 0 1 3.6 1v.05a2.72 2.72 0 0 1-.6 3.43l-7 5.78a3.46 3.46 0 0 1-1.45.71l-13.3 3a3.5 3.5 0 0 1-2.67-.5L9.33 36.2a3.46 3.46 0 0 0-1.92-.58H5V25.26l6.13-.68a6.91 6.91 0 0 1 3.19.39L19 26.74a6.77 6.77 0 0 0 1.32.35l8.83 1.44a2.78 2.78 0 0 1 2.59 2.77h0a2.79 2.79 0 0 1-2.59 2.78l-9.5.68" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprHandCoinsSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprHandCoinsSvgComponent,
  ],
  exports: [
    InformationExprHandCoinsSvgComponent,
  ],
})
export class InformationExprHandCoinsSvgModule {}
