<div class="pay-error-div">
  <svg b2bFrontendAlertUtilConfirmationError class="confirmation-error"></svg>
  <label class="text kdl-font-grand-bold-label">{{ paymentErrorMsg }}</label>
  <label class="desciption b2b-kdl-font-normal-light"
    >Travel Insurance Policy was not successfully created</label
  >

  <maia-button-group class="button kdl-font-normal">
    <button maiaButton maiaButtonType="primary">Create New Policy</button>
  </maia-button-group>
</div>
