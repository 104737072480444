import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {StepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {Observable, ReplaySubject} from 'rxjs';
import {takeUntil, filter, startWith, map} from 'rxjs/operators';

import {TravelPolicyService} from './travel-core/services/travel-policy.service';
import {TravelProcessService} from './travel-core/services/travel-process.service';
import {TravelWorkflow} from './travel-core/travel-workflow/TravelWorkflow';
import {TravelSessionService} from './travel-core/services/travel-session.service';
import {
  MOCK_PREMIUM_PRICE,
  MOCK_ITEMS,
  MOCK_TITLE,
} from './screens/travel-addons/__tests__/travel-addons.mocks';
import {Amount, Item} from './screens/travel-addons/travel-addons.component';

@Component({
  selector: 'travel-insurance',
  templateUrl: './travel-insurance.component.html',
  styleUrls: ['./travel-insurance.component.scss'],
})
export class TravelInsuranceComponent implements OnInit {
  public steps: Observable<StepperProcessStep[]> = this.process.onStepperWorkflowSteps;
  public isEditing: Observable<boolean> = this.process.stepEditing;
  private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isStepperVisible: boolean = true;
  public insurancePremiumAmount: Amount = MOCK_PREMIUM_PRICE;
  public items: Item[] = MOCK_ITEMS;
  public title = MOCK_TITLE.TITLE;

  public constructor(
    public process: TravelProcessService,
    public travel: TravelPolicyService,
    private readonly router: Router,
    public route: ActivatedRoute,
    public travelSessionService: TravelSessionService,
  ) {
    this.process.currentWorkflow = TravelWorkflow.TRAVEL_POLICY;
  }

  public ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: any) => event instanceof NavigationEnd),
        startWith(null),
        map(() => {
          let currentRoute = this.route;
          while (currentRoute.firstChild != null) {
            currentRoute = currentRoute.firstChild;
          }
          let currentRouteS = currentRoute.snapshot;
          while (currentRouteS.parent != null) {
            if ('isStepperVisible' in currentRouteS.data) {
              return currentRouteS.data.isStepperVisible as boolean;
            }
            currentRouteS = currentRouteS.parent;
          }
          return true;
        }),
      )
      .subscribe((val: any) => (this.isStepperVisible = val));
  }
}
