<div>
  <maia-label class="b2b-kdl-font-medium-bold"
    >The following risks are included by default in Standard Risks</maia-label
  >

  <svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
  <p class="description b2b-kdl-font-normal">Risk for loyal DZI client</p>

  <svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
  <p class="description b2b-kdl-font-normal">Risk of the owner's company</p>

  <svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
  <p class="description b2b-kdl-font-normal">Risk for riskless client profile</p>

  <svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
  <p class="description b2b-kdl-font-normal">Risk for driven km</p>
</div>

<!-- <maia-checkbox-group>
  <maia-label class="b2b-kdl-font-medium-bold">Additional Risks</maia-label>
  <maia-checkbox [(ngModel)]="checkNum" (ngModelChange)="saveRisk()" *ngFor="let slideIn of slideInGroup" >
    {{slideIn.description}}
    <maia-amount-display [value]="slideIn.amount" [currency]="slideIn.currency" class="display-price-top" ></maia-amount-display>
  </maia-checkbox>
</maia-checkbox-group> -->

<maia-checkbox-group>
  <maia-label class="b2b-kdl-font-medium-bold">Additional Risks</maia-label>
  <maia-checkbox [(ngModel)]="checkNum" (ngModelChange)="saveRisk()">
    Emergency unlocking of the vehicle
    <maia-amount-display
      [value]="transferAmount"
      currency="BGN"
      class="display-price-top"
    ></maia-amount-display>
  </maia-checkbox>
  <maia-checkbox [(ngModel)]="checkNum1" (ngModelChange)="saveRisk1()" name="checkbox-placeholder">
    Costs for parking after traffic accident
    <maia-amount-display
      [value]="transferAmount"
      currency="BGN"
      class="display-price-first"
    ></maia-amount-display>
  </maia-checkbox>
  <maia-checkbox [(ngModel)]="checkNum2" (ngModelChange)="saveRisk2()" name="checkbox-placeholder">
    Costs for stay in hotel of the driver after traffic accident
    <maia-amount-display
      [value]="transferAmount"
      currency="BGN"
      class="display-price-third"
    ></maia-amount-display>
  </maia-checkbox>
  <maia-checkbox [(ngModel)]="checkNum3" (ngModelChange)="saveRisk3()" name="checkbox-placeholder">
    Costs for pulling of motor vehicle
    <maia-amount-display
      [value]="transferAmount"
      currency="BGN"
      class="display-price-fourth"
    ></maia-amount-display>
  </maia-checkbox>
  <maia-checkbox [(ngModel)]="checkNum4" (ngModelChange)="saveRisk4()" name="checkbox-placeholder">
    Costs for medical transportation of the persons
    <maia-amount-display
      [value]="transferAmount"
      currency="BGN"
      class="display-price"
    ></maia-amount-display>
  </maia-checkbox>
  <maia-checkbox [(ngModel)]="checkNum5" (ngModelChange)="saveRisk5()" name="checkbox-placeholder">
    Costs for medical examinations, investigations and manipulations &nbsp;
    <maia-amount-display [value]="transferAmount" currency="BGN"></maia-amount-display>
  </maia-checkbox>
  <maia-checkbox [(ngModel)]="checkNum6" (ngModelChange)="saveRisk6()" name="checkbox-placeholder">
    Costs for transportation of passangers and baggage
    <maia-amount-display
      [value]="transferAmount"
      currency="BGN"
      class="display-price-last"
    ></maia-amount-display>
  </maia-checkbox>
</maia-checkbox-group>
<maia-divider></maia-divider>

<button class="active-button" maiaButton maiaButtonType="primary" (click)="save()">Save</button>
