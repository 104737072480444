import {Component} from '@angular/core';
import {SearchedParameters} from '../../../models/SearchBy';

@Component({
  selector: 'nje-account-statuses',
  templateUrl: './account-statuses.component.html',
  styleUrls: ['./account-statuses.component.scss'],
})
export class AccountStatusesComponent {
  public tabIndex: number = 0;

  public searchAccounts(event: SearchedParameters): void {
    //TODO
  }
}
