<div class="pay-success-div">
  <svg b2bFrontendAlertUtilConfirmationCheck class="confirmation-check"></svg>
  <label class="success-text kdl-font-grand-bold-label">{{ paymentSuccessMsg }}</label>
  <label class="success-description b2b-kdl-font-normal-light"
    >Travel Insurance Policy was successfully created and sent to customer email.</label
  >

  <maia-button-group class="create-policy-button">
    <button maiaButton maiaButtonType="primary">Create New Policy</button>
  </maia-button-group>

  <div class="pay-receip-div m-t-b-32">
    <div>
      <div class="mb-24">
        <label class="label-color demo-kdl-font-medium-bold">DZI Non-Life Insurance</label>
      </div>

      <maia-fluid-fluid-layout>
        <div class="expr-icon mb-24">
          <div class="text-format">
            <label class="kdl-font-small-light question">Insurance</label>
            <label class="label-colour demo-kdl-font-normal-bold"
              >Custom Coverage Travel Insurance</label
            >
            <div class="information">
              <maia-avatar-display>
                <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
                  <svg b2bFrontendInformationExprFastlane class="info-icon"></svg>
                </maia-avatar-svg>
              </maia-avatar-display>
            </div>
          </div>
        </div>
      </maia-fluid-fluid-layout>

      <maia-fluid-fluid-layout class="grid-cell-aligment">
        <div class="text-format">
          <maia-name-value-collection-group class="mb-0">
            <maia-name-value-collection class="mb-cell" key="Policy Number">
              <maia-name-value-collection-item value>
                <label class="policy-number kdl-font-small">12345678901</label>
              </maia-name-value-collection-item>
            </maia-name-value-collection>
          </maia-name-value-collection-group>
        </div>
      </maia-fluid-fluid-layout>
      <maia-fluid-fluid-layout>
        <div class="text-format">
          <maia-name-value-collection-group class="mb-0">
            <maia-name-value-collection class="mb-cell" key="Start date">
              <maia-name-value-collection-item value>
                {{ startDate }}
              </maia-name-value-collection-item>
            </maia-name-value-collection>
          </maia-name-value-collection-group>
        </div>
        <div class="text-format">
          <maia-name-value-collection-group class="mb-0">
            <maia-name-value-collection class="mb-cell" key="End date:">
              <maia-name-value-collection-item value>
                {{ endDate }}
              </maia-name-value-collection-item>
            </maia-name-value-collection>
          </maia-name-value-collection-group>
        </div>
      </maia-fluid-fluid-layout>
      <maia-fluid-fluid-layout>
        <div class="text-format">
          <maia-name-value-collection-group class="mb-0">
            <maia-name-value-collection class="mb-cell" key="People">
              <maia-name-value-collection-item value> 3 </maia-name-value-collection-item>
            </maia-name-value-collection>
          </maia-name-value-collection-group>
        </div>
        <div class="text-format">
          <maia-name-value-collection-group class="mb-0">
            <maia-name-value-collection class="mb-cell" key="Number of Days">
              <maia-name-value-collection-item value> 5 Days </maia-name-value-collection-item>
            </maia-name-value-collection>
          </maia-name-value-collection-group>
        </div>
      </maia-fluid-fluid-layout>
    </div>
  </div>

  <div class="issues-button">
    <maia-button-group>
      <button maiaButton maiaButtonType="secondary">Issue Bill</button>

      <button maiaButton maiaButtonType="secondary">Issue Debit Bill</button>
    </maia-button-group>
  </div>
  <h1 class="title-wrapper__title b2b-kdl-font-medium-bold">Print Documents</h1>
  <div class="tile-shadow">
    <maia-fluid-fluid-layout>
      <maia-form-element class="input-column" hideOptionalIndicator>
        <maia-tile *ngFor="let document of documents" class="m-t-b-24 tile_size">
          <maia-tile-items>
            <maia-tile-item>
              <div class="document-details">
                <div class="document-icon">
                  <maia-avatar-display>
                    <maia-avatar-svg
                      class="avatar-background"
                      avatarSize="48"
                      maia-avatar-display-icon
                    >
                      <svg b2bFrontendFileExprDocument class="tile-demo__icon"></svg>
                    </maia-avatar-svg>
                  </maia-avatar-display>
                </div>
                <div>
                  <label class="text b2b-kdl-font-small">{{ document }}</label>
                </div>
              </div>
            </maia-tile-item>
          </maia-tile-items>
        </maia-tile>
      </maia-form-element>
    </maia-fluid-fluid-layout>
  </div>
</div>
