export const CONTACT_TYPE = {
  CONTACT: 'contact',
  INCIDENT: 'incident',
  CONTACT_URL: 'contact_url',
  BLANK_VALUE: '',
  ADD_CONTACT: 'Add Contact',
  ADD_INCIDENT: 'Add Incident',
};

export const CONTACT_PROPERTY = {
  URL: 'url',
};

export const POPUP_PROPERTY = {
  TITLE: 'Warning',
};

export const NEWS_EVENTS = {
  PUBLISHED: 'published',
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  UPDATE_PIN: 'Update Pin',
  PIN: 'Pin',
  FILTER: 'Filter',
  CATEGORY: 'Category',
  USER_TYPE: 'User Type',
  ENGLISH: 'en',
  BULGARIAN: 'bg',
  EVENTS: 'Events',
  NEWS: 'News',
  SELECT_ACCOUNT: 'Select Account',
  DELETE_CATEGORY: 'Delete Category',
  BLANK_VALUE: '',
  EDIT_FILTER: 'Edit Filters',
  LANG: 'lang',
  ADD_MORE_INFO: '(More Info)',
  PRODUCT_CATEGORY_EDIT_FILTER: 'Edit Filter',
};

export const POPUP_NEWS_EVENTS = {
  PUBLISH: 'Publish',
  SCHEDULE: 'Schedule',
  SAVE_AS_DRAFT: 'Save as Draft',
  BLANK_VALUE: '',
  PUBLISH_NEWS_EVENTS: 'Are you sure you want to publish this item?',
  SCHEDULE_NEWS_EVENTS: 'Are you sure you want to schedule this item?',
  SAVE_AS_DRAFT_NEWS_EVENTS: 'Are you sure you want to save as draft this item?',
  WARNING: 'Warning',
  CANCEL_NEWS_EVENTS: 'Are you sure you want to cancel this?',
};

export const CATEGORY_TYPE = {
  PRODUCT: 'product',
};

export const EDIT_CONTACT = 'Edit Contact';

export const UPLOAD_FILE_SIZE = 10 * 1024 * 1024;
export const UPLOAD_FILE_CONSTRAINT_TEXT = 'Maximum file size should be 10 MB';

export const GENERAL = 'General';
