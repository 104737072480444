/*eslint-disable @typescript-eslint/restrict-plus-operands*/
import {Pipe, PipeTransform} from '@angular/core';
import {UserType} from '../contracts/admin-documents.interface';
import {Language} from '../constants/lang';

@Pipe({
  name: 'userType',
})
export class UserTypePipe implements PipeTransform {
  public lang: string | null = sessionStorage.getItem('lang');
  public userTypeView: string = '';
  public num: number;

  public transform(value: UserType[]): string {
    this.num = value.length - 3;
    if (value.length <= 3) {
      for (let i = 0; i < value.length; i++) {
        if (i < value.length - 1) {
          this.userTypeView += this._getUserTypeTitle(value[i]) + ' | ';
        } else {
          this.userTypeView += this._getUserTypeTitle(value[i]);
        }
      }
    } else {
      this.userTypeView = `${this._getUserTypeTitle(value[0])} | ${this._getUserTypeTitle(
        value[1],
      )} | ${this._getUserTypeTitle(value[3])} +${this.num}`;
    }
    return this.userTypeView;
  }

  private _getUserTypeTitle(value?: UserType): string | undefined {
    return this._isLanguageEnglish() && value?.i18n ? value?.i18n?.en.title : value?.i18n?.bg.title;
  }

  private _isLanguageEnglish(): boolean {
    return this.lang === Language.English;
  }
}
