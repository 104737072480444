<p class="b2b-kdl-font-normal align">{{ heading }}</p>
<div *maiaPopUpFooter>
  <button
    maiaButton
    maiaButtonType="primary"
    (click)="delete()"
    i18n="@@nje-b2b-frontend-resource-library-yes"
    >Yes</button
  >
  <button
    maiaButton
    maiaButtonType="tertiary"
    (click)="control.cancel()"
    i18n="@@nje-b2b-frontend-resource-library-cancel"
    >Cancel</button
  >
</div>
