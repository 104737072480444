import {PriceCoversAdditionalRisksSlideinComponent} from './price-covers-additional-risks-slidein/price-covers-additional-risks-slidein.component';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {FormArray, FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {SlideInController} from '@maia/slide-ins';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';
import {Observable, of} from 'rxjs';
import {CascoWorkflow} from '../../casco-core/casco-workflow/CascoWorkflow';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';
import {
  ADDONS_SLIDEIN_TITLE,
  DEDUCTIBLE_SLIDEIN_TITLE,
  ADDITIONAL_RISKS_SLIDEIN_TITLE,
} from '../../constants/price-covers-constants';
import {PriceCoversAddonsSlideinComponent} from './price-covers-addons-slidein/price-covers-addons-slidein.component';
import {PriceCoversDeductibleSlideinComponent} from './price-covers-deductible-slidein/price-covers-deductible-slidein.component';
import {DeductibleSlideInOutput} from './price-covers-deductible-slidein/__tests__/price-covers-deductible-slidein-mock-interface';
import {AdditonalRisksSlideInOutput} from './price-covers-additional-risks-slidein/__tests__/price-covers-additional-risks-mock-interface';
import {
  MOCK_ADDITIONAL_LIST,
  MOCK_ADDONS_LIST,
  MOCK_MAIN_COVERS_LIST,
  MOCK_RA_COVERS_LIST,
  MockAdvanceBonus,
  MOCK_READ_MORE_LINK,
} from './__tests__/price-covers-mock-data';
import {CheckBoxItems, CoverForm, CoverItems} from './__tests__/price-covers-mock-interface';

@Component({
  selector: 'nje-price-covers-and-addons',
  templateUrl: './price-covers-and-addons.component.html',
  styleUrls: ['./price-covers-and-addons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class PriceCoversAndAddonsComponent extends ScreenFormComponent implements OnInit {
  public clicked: boolean = false;
  public readMoreLink = MOCK_READ_MORE_LINK;
  public disableButton: boolean = false;
  public fullDeductibleAmount: number;
  public superDeductibleAmount: number;
  public additionalRisksAmount: number;

  public mainCoversList$: Observable<CoverItems[]>;
  public raCoversList$: Observable<CoverItems[]>;
  public addOnsList: CheckBoxItems[];
  public additionalConditionsList: CheckBoxItems[];

  public readonly slideInOneFactory: ComponentFactory<PriceCoversAddonsSlideinComponent>;
  public readonly slideInTwoFactory: ComponentFactory<PriceCoversDeductibleSlideinComponent>;
  public readonly slideInThreeFactory: ComponentFactory<PriceCoversAdditionalRisksSlideinComponent>;

  public coverForm: FormGroupManager<CoverForm>;

  public constructor(
    route: ActivatedRoute,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _slideInCtrl: SlideInController,
    componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _process: CascoProcessService,
    private readonly _changeDetection: ChangeDetectorRef,
  ) {
    super(route);
    this._process.currentWorkflow = CascoWorkflow.PRICE_OFFER;
    this.slideInOneFactory = componentFactoryResolver.resolveComponentFactory(
      PriceCoversAddonsSlideinComponent,
    );
    this.slideInTwoFactory = componentFactoryResolver.resolveComponentFactory(
      PriceCoversDeductibleSlideinComponent,
    );
    this.slideInThreeFactory = componentFactoryResolver.resolveComponentFactory(
      PriceCoversAdditionalRisksSlideinComponent,
    );
    this.coverForm = this._formFactory.createFormManager<CoverForm>({
      mainCover: new FormControl(undefined),
      raCover: new FormControl(undefined),
      addOnsArray: new FormArray([]),
      additionalConditionsArray: new FormArray([]),
      advanceBonus: new FormControl(MockAdvanceBonus.NO),
    });
  }

  public ngOnInit(): void {
    this.initializeApiCalls();
    this.disableAdvanceBonus();
  }

  public initializeApiCalls(): void {
    this.mainCoversList$ = this.getMainCoversList();
    this.raCoversList$ = this.getRACoversList();
    this.getAddonsList()
      .pipe(takeUntilDestroyed(this))
      .subscribe((data: CheckBoxItems[]) => {
        this.addOnsList = data;
        this.bindAddonsFormArray();
      });
    this.getAdditionalConditionsList()
      .pipe(takeUntilDestroyed(this))
      .subscribe((data: CheckBoxItems[]) => {
        this.additionalConditionsList = data;
        this.bindAdditionalConditionsArray();
      });
  }

  public get addOnsArray(): FormArray {
    return this.coverForm.controls.addOnsArray as FormArray;
  }

  public get additionalConditionsArray(): FormArray {
    return this.coverForm.controls.additionalConditionsArray as FormArray;
  }

  public bindAddonsFormArray(): void {
    this.addOnsList.forEach(() => {
      this.addOnsArray.push(new FormControl(undefined));
    });
  }

  public bindAdditionalConditionsArray(): void {
    this.additionalConditionsList.forEach(() => {
      this.additionalConditionsArray.push(new FormControl(undefined));
    });
  }

  public openAddonsSlideIn(): void {
    this._slideInCtrl
      .prepare(
        this.slideInOneFactory,
        this._injector,
        {
          title: ADDONS_SLIDEIN_TITLE,
        },
        {
          withClickableBackdrop: true,
          withVisibleBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  public openAdditionalRisksSlideIn(): void {
    this._slideInCtrl
      .prepare(
        this.slideInThreeFactory,
        this._injector,
        {
          title: ADDITIONAL_RISKS_SLIDEIN_TITLE,
        },
        {
          withClickableBackdrop: true,
          withVisibleBackdrop: true,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<AdditonalRisksSlideInOutput>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this.additionalRisksAmount = response.result.amount;
        }
        this._changeDetection.markForCheck();
      });
  }

  public openDeductibleSlideIn(id: number): void {
    this._slideInCtrl
      .prepare(
        this.slideInTwoFactory,
        this._injector,
        {
          title: DEDUCTIBLE_SLIDEIN_TITLE,
        },
        {
          withClickableBackdrop: true,
          withVisibleBackdrop: true,
          input: id,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<DeductibleSlideInOutput>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          if (response.result.id === 0) {
            this.fullDeductibleAmount = response.result.amount;
          } else if (response.result.id === 1) {
            this.superDeductibleAmount = response.result.amount;
          }
        }
        this._changeDetection.markForCheck();
      });
  }

  protected saveMtplData(): void {
    this.clicked = true;
  }

  public clearSelection(): void {
    this.coverForm.controls.raCover.reset();
  }

  public disableAdvanceBonus() {
    this.coverForm.controls.mainCover.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: CoverItems) => {
        if (value) {
          if (value.id === 2 || value.id === 3) {
            this.disableButton = true;
          } else {
            this.disableButton = false;
          }
        }
      });
  }

  public openReadMore(): void {
    window.open(this.readMoreLink);
  }

  public getMainCoversList(): Observable<CoverItems[]> {
    return of(MOCK_MAIN_COVERS_LIST);
  }

  public getRACoversList(): Observable<CoverItems[]> {
    return of(MOCK_RA_COVERS_LIST);
  }

  public getAddonsList(): Observable<CheckBoxItems[]> {
    return of(MOCK_ADDONS_LIST);
  }

  public getAdditionalConditionsList(): Observable<CheckBoxItems[]> {
    return of(MOCK_ADDITIONAL_LIST);
  }
}
