import {IStepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {RoutePaths} from '../../constants/route-paths';

export const steps: IStepperProcessStep[] = [
  {
    id: RoutePaths.SearchPolicy,
    title: 'Search Policy',
    routePath: `/dashboard/payments/${RoutePaths.InstalmentPayment}/${RoutePaths.SearchPolicy}`,
  },
  {
    id: RoutePaths.Instalment,
    title: 'Instalment',
    routePath: `/dashboard/payments/${RoutePaths.InstalmentPayment}/${RoutePaths.Instalment}`,
  },
  {
    id: RoutePaths.InsuredParty,
    title: 'Insured Party',
    routePath: `/dashboard/payments/${RoutePaths.InstalmentPayment}/${RoutePaths.InsuredParty}`,
  },
  {
    id: RoutePaths.Overview,
    title: 'Overview',
    routePath: `/dashboard/payments/${RoutePaths.InstalmentPayment}/${RoutePaths.Overview}`,
  },
  {
    id: RoutePaths.Payment,
    title: 'Issuing e-receipt',
    routePath: `/dashboard/payments/${RoutePaths.InstalmentPayment}/${RoutePaths.Payment}`,
  },
  {
    id: RoutePaths.PaymentDebitNote,
    title: 'Issuing Debit Note',
    routePath: `/dashboard/payments/${RoutePaths.InstalmentPayment}/${RoutePaths.PaymentDebitNote}`,
  },
];
