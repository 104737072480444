<form maiaForm [formGroup]="addNewInsuringForm.formGroup">
  <maia-form-group>
    <maia-label class="b2b-kdl-font-small title-dark-color"> Related Parties </maia-label>
    <maia-form-element [hideOptionalIndicator]="true" class="slidein-input-column">
      <maia-input-select name="relatedParty" formControlName="relatedParty" ngDefaultControl>
        <input
          maiaInput
          placeholder="Select"
          [value]="this.addNewInsuringForm.controls.relatedParty.value"
        />
        <maia-option *ngFor="let i of mockRelatedParty" [value]="i.name">
          {{ i.name }}
        </maia-option>
      </maia-input-select>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color"> EGN / LNCH / EIK </maia-label>
    <maia-search-bar
      *ngIf="isNewDetails"
      class="search-box"
      [placeholder]="'EGN / LNCH / EIK'"
      (inputText)="handleInputText($event)"
      (searchText)="handleSearchedText($event)"
      [(ngModel)]="searchEgn"
      ngDefaultControl
      [ngModelOptions]="{standalone: true}"
    >
    </maia-search-bar>
    <maia-form-element
      [options]="{validationInstant: true}"
      [hideOptionalIndicator]="true"
      class="slidein-input-column title"
    >
      <maia-validation *ngIf="personNotFound" class="error-message">
        Person is not retrieved by PID / EIK. You can proceed with manual data entry.
      </maia-validation>
      <div *ngIf="!isNewDetails" class="input-box">
        <input
          maiaInput
          name="EGN / LNCH / EIK"
          placeholder="EGN / LNCH / EIK"
          formControlName="idNumber"
          (ngModelChange)="idNumberValidator($event)"
        />
        <button
          *ngIf="!isEditable"
          class="edit-btn"
          maiaButton
          maiaButtonType="secondary"
          (click)="enableInputFieldEdit()"
          >Edit</button
        >
        <button
          *ngIf="isEditable"
          class="edit-btn"
          maiaButton
          maiaButtonType="primary"
          (click)="searchInsurerDetails(this.addNewInsuringForm.controls.idNumber.value)"
          >Search</button
        >
        <div class="validation-container" *ngIf="addNewInsuringForm.hasToShowErrors('idNumber')">
          <maia-validation *ngIf="addNewInsuringForm.hasError('idNumber', 'required')"
            >EGN / LNCH / EIK is required</maia-validation
          >
        </div>
      </div>
    </maia-form-element>

    <div *ngIf="isEIK">
      <maia-label class="b2b-kdl-font-small title-dark-color"> Company Name </maia-label>
      <maia-form-element
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="slidein-input-column"
      >
        <input maiaInput placeholder="Company Name" formControlName="companyName" />
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('companyName') &&
            addNewInsuringForm.hasError('companyName', 'required')
          "
        >
          Company name required
        </maia-validation>
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('companyName') &&
            addNewInsuringForm.hasError('companyName', 'nonLatinLetters')
          "
          >Company name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('companyName') &&
            addNewInsuringForm.hasError('companyName', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('companyName') &&
            addNewInsuringForm.hasError('companyName', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ addNewInsuringForm.controls['companyName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
    <div *ngIf="!isEIK">
      <maia-label class="b2b-kdl-font-small title-dark-color"> Full Name </maia-label>
      <maia-form-element [options]="{validationInstant: true}" multi class="slidein-input-column">
        <maia-form-subelement>
          <input maiaInput type="text" placeholder="Първо име" formControlName="firstName" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput type="text" placeholder="Презиме" formControlName="middleName" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput type="text" placeholder="Фамилия" formControlName="lastName" />
        </maia-form-subelement>
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('firstName') &&
            addNewInsuringForm.hasError('firstName', 'required')
          "
          >First name required</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('firstName') &&
            addNewInsuringForm.hasError('firstName', 'nonLatinLetters')
          "
          >First name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('firstName') &&
            addNewInsuringForm.hasError('firstName', 'nonBlank')
          "
          >Invalid first name</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('firstName') &&
            addNewInsuringForm.hasError('firstName', 'maxlength')
          "
          >First name is too long (maximum is
          {{ addNewInsuringForm.controls['firstName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('middleName') &&
            addNewInsuringForm.hasError('middleName', 'required')
          "
          >Middle name required</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('middleName') &&
            addNewInsuringForm.hasError('middleName', 'nonLatinLetters')
          "
          >Middle name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('middleName') &&
            addNewInsuringForm.hasError('middleName', 'nonBlank')
          "
          >Invalid middle name</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('middleName') &&
            addNewInsuringForm.hasError('middleName', 'maxlength')
          "
          >Middle name is too long (maximum is
          {{ addNewInsuringForm.controls['middleName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('lastName') &&
            addNewInsuringForm.hasError('lastName', 'required')
          "
          >Last name required</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('lastName') &&
            addNewInsuringForm.hasError('lastName', 'nonLatinLetters')
          "
          >Last name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('lastName') &&
            addNewInsuringForm.hasError('lastName', 'nonBlank')
          "
          >Invalid last name</maia-validation
        >
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('lastName') &&
            addNewInsuringForm.hasError('lastName', 'maxlength')
          "
          >Last name is too long (maximum is
          {{ addNewInsuringForm.controls['lastName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
    <maia-label class="b2b-kdl-font-small title-dark-color"> City & Zip </maia-label>
    <maia-form-element
      [hideOptionalIndicator]="true"
      multi
      class="slidein-input-column city-and-postcode"
    >
      <maia-form-subelement>
        <maia-input-autocomplete
          name="city"
          formControlName="city"
          ngDefaultControl
          (typeAhead)="cityAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let cityObj of cityArray" [value]="cityObj">
            <maia-option-highlight [label]="cityObj.city"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('city') &&
            addNewInsuringForm.hasError('city', 'required')
          "
          >City required</maia-validation
        >
      </maia-form-subelement>
      <maia-form-subelement>
        <input
          maiaInput
          type="text"
          class="zip-style"
          placeholder="Zip"
          formControlName="postCode"
          readonly
        />
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('postCode') &&
            addNewInsuringForm.hasError('postCode', 'required')
          "
          >Post Code required</maia-validation
        >
      </maia-form-subelement>
    </maia-form-element>

    <maia-label class="b2b-kdl-font-small title-dark-color"> Quarter </maia-label>
    <maia-form-element class="slidein-input-column" multi>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Quartile" formControlName="quarter" />
      </maia-form-subelement>
      <maia-validation
        *ngIf="
          addNewInsuringForm.hasToShowErrors('quarter') &&
          addNewInsuringForm.hasError('quarter', 'required')
        "
        >Quarter is required</maia-validation
      >
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color"> Street & Number </maia-label>
    <maia-form-element class="slidein-input-column city-and-postcode" multi>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Street" formControlName="street" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Number" formControlName="streetNumber" />
      </maia-form-subelement>
      <maia-validation
        *ngIf="
          addNewInsuringForm.hasToShowErrors('street') &&
          addNewInsuringForm.hasError('street', 'required')
        "
      >
        Street required
      </maia-validation>
      <maia-validation
        *ngIf="
          addNewInsuringForm.hasToShowErrors('streetNumber') &&
          addNewInsuringForm.hasError('streetNumber', 'required')
        "
      >
        Street number required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">
      Block, Entrance, Floor & Apartment
    </maia-label>
    <maia-form-element class="slidein-input-column" multi>
      <maia-form-subelement>
        <input maiaInput type="text" name="block" placeholder="Block" formControlName="block" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input
          maiaInput
          type="text"
          name="entrance"
          placeholder="Ent.."
          formControlName="entrance"
        />
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floor" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input
          maiaInput
          type="text"
          name="apartment"
          placeholder="Apart.."
          formControlName="apartment"
        />
      </maia-form-subelement>
    </maia-form-element>
    <div *ngIf="isLNCH">
      <maia-label class="b2b-kdl-font-small title-dark-color"> Citizenship </maia-label>
      <maia-form-element
        class="slidein-input-column"
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
      >
        <maia-input-autocomplete
          name="citizenship"
          formControlName="citizenship"
          (typeAhead)="filterCitizenshipOptions($event)"
        >
          <input autocomplete="dzi-off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let c of filteredCitizenshipOptionsList | async" [value]="c.id">
            <maia-option-highlight [label]="c.i18n.en.title"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('citizenship') &&
            addNewInsuringForm.hasError('citizenship', 'required')
          "
        >
          Citizenship required
        </maia-validation>
      </maia-form-element>
      <maia-label class="b2b-kdl-font-small title-dark-color"> Date of Birth </maia-label>
      <maia-form-element
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="slidein-input-column"
      >
        <maia-input-date
          name="dob"
          [(ngModel)]="birthDatePicker.value"
          (ngModelChange)="birthDatePicker.change($event)"
          (blur)="birthDatePicker.touch()"
          [minimum]="minDate"
          [maximum]="maxDate"
          [ngModelOptions]="{standalone: true}"
          [visibleView]="visibleDateView"
        ></maia-input-date>
        <maia-validation
          *ngIf="
            addNewInsuringForm.hasToShowErrors('dob') &&
            addNewInsuringForm.hasError('dob', 'required')
          "
        >
          Date of birth required
        </maia-validation>
      </maia-form-element>
    </div>
    <nje-input-phone-number
      [retrievedAddInsuringPhone]="retrievedAddInsuringDetails"
      [retrievedSlideInPhone]="retrievedAddInsuringPartyCompanyDetails"
      [phoneNumber]="slideinPhoneNumber"
      [inputPhoneNumber]="phone"
      [flexDirection]="flexDirection"
      (selectPhoneNumber)="selectedPhoneNumber($event)"
    ></nje-input-phone-number>
    <maia-label class="b2b-kdl-font-small title-dark-color"> Email ID </maia-label>
    <maia-form-element class="slidein-input-column">
      <input
        type="email"
        maiaInput
        atlasEmailAddress
        placeholder="Email ID"
        formControlName="emailId"
      />
      <maia-validation
        *ngIf="
          addNewInsuringForm.hasToShowErrors('emailId') &&
          addNewInsuringForm.hasError('emailId', 'required')
        "
      >
        Email required
      </maia-validation>
      <maia-validation
        *ngIf="
          (addNewInsuringForm.hasToShowErrors('emailId') &&
            addNewInsuringForm.hasError('emailId', 'atlasInvalidLocalPart')) ||
          (addNewInsuringForm.hasToShowErrors('emailId') &&
            addNewInsuringForm.hasError('emailId', 'atlasInvalidDomain')) ||
          (addNewInsuringForm.hasToShowErrors('emailId') &&
            addNewInsuringForm.hasError('emailId', 'atlasInvalidMultipleAt'))
        "
        >Enter your e-mail address in the following format: name@domain.com</maia-validation
      >
      <maia-validation
        *ngIf="
          addNewInsuringForm.hasToShowErrors('emailId') &&
          addNewInsuringForm.hasError('emailId', 'atlasMaxLength')
        "
        >Email too long</maia-validation
      >
    </maia-form-element>
  </maia-form-group>
</form>
<maia-divider class="divider"></maia-divider>
<div class="step-form-button-bar">
  <maia-button-group *ngIf="isNewDetails">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!isFormDetailsValid()"
      (click)="saveInsuringPartyDetails()"
      >Add</button
    >
  </maia-button-group>

  <maia-button-group *ngIf="!isNewDetails">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      (click)="saveInsuringPartyDetails()"
      >Save</button
    >
    <button class="m-t-b-24" maiaButton maiaButtonType="delete" (click)="deleteInsuringParty()"
      >Delete</button
    >
  </maia-button-group>
</div>
