import {CommonModule} from '@angular/common';
import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {RouterModule} from '@angular/router';
import {B2bCoreModule} from '@b2b-frontend/core';
import {μExampleSvgModule} from '@b2b-frontend/icons';
import {ActionTileModule as MaiaActionTileModule} from '@maia/action-tile';
import {ActionsModule} from '@maia/actions';
import {
  AdvancedSearchModule as MaiaAdvancedSearchModule,
  AdvancedSearchOptionAmountModule,
  AdvancedSearchOptionDateModule,
  AdvancedSearchOptionFreeModule,
} from '@maia/advanced-search';
import {AvatarsModule as MaiaAvatarsModule} from '@maia/avatars';
import {BadgeModule as MaiaBadgeModule} from '@maia/badge';
import {DatePickersModule} from '@maia/datepickers';
import {DisplaysModule as MaiaDisplaysModule} from '@maia/displays';
import {DividerModule as MaiaDividerModule} from '@maia/divider';
import {DropdownsModule} from '@maia/dropdowns';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {InputSelectModule} from '@maia/input-select';
import {LayoutModule as MaiaLayoutModule} from '@maia/layout';
import {PopUpsModule as MaiaPopUpsModule} from '@maia/pop-ups';
import {RadioButtonsModule as MaiaRadioButtonsModule} from '@maia/radio-buttons';
import {SearchBarModule as MaiaSearchBarModule} from '@maia/search-bar';
import {SwitchesModule} from '@maia/switches';
import {TabsModule} from '@maia/tabs';
import {TilesModule} from '@maia/tiles';
import {LayoutModule} from '@njf-frontend-angular/layout';
import {SidebarModule} from '@njf-frontend-angular/sidebar';

import {DashbordComponent} from './screen/dashbord/dashbord.component';
import {HomeComponent} from './screen/home/home.component';
import {AnnexationComponent} from './screen/tabs/annexation/annexation.component';
import {CancellationComponent} from './screen/tabs/cancellation/cancellation.component';
import {DuplicatesComponent} from './screen/tabs/duplicates/duplicates.component';
import {NewPolicyComponent} from './screen/tabs/new-policy/new-policy.component';
import {RenewalComponent} from './screen/tabs/renewal/renewal.component';
import {BASE_ROUTE, DashboardStarterService} from './services/starter.service';

@NgModule({
  declarations: [
    DashbordComponent,
    HomeComponent,
    NewPolicyComponent,
    RenewalComponent,
    AnnexationComponent,
    CancellationComponent,
    DuplicatesComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule,
    SidebarModule,
    B2bCoreModule,
    MaiaLayoutModule,
    MaiaAvatarsModule,
    TilesModule,
    MaiaDisplaysModule,
    MaiaFormsModule,
    LayoutModule,
    μExampleSvgModule,
    ActionsModule,
    MaiaAvatarsModule,
    MaiaRadioButtonsModule,
    MaiaSearchBarModule,
    MaiaPopUpsModule,
    MaiaDividerModule,
    MaiaAdvancedSearchModule,
    AdvancedSearchOptionAmountModule,
    AdvancedSearchOptionDateModule,
    AdvancedSearchOptionFreeModule,
    DatePickersModule,
    DropdownsModule,
    SwitchesModule,
    InputSelectModule,
    TabsModule,
    MaiaActionTileModule,
    MaiaBadgeModule,
  ],
  providers: [DashboardStarterService],
})
export class DashbordModule {
  // Static method for injecting the module with a base route provided.
  public static forPath(path: string): ModuleWithProviders<DashbordModule> {
    return {
      ngModule: DashbordModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error(
        'Do not import DashbordModule directly, use DashbordModule.forPath() instead',
      );
    }
  }
}
