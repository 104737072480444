<div class="admin-documents-wrapper">
  <maia-fluid-fluid-layout>
    <div>
      <maia-search-bar
        class="search search-box m-t-b-24"
        placeholder="Search"
        i18n-placeholder="@@nje-b2b-frontend-resource-admin-search"
        (searchText)="handleSearchedTextApp($event)"
        (inputText)="handleInputText($event)"
        [(ngModel)]="searchWord"
        ngDefaultControl
        [ngModelOptions]="{standalone: true}"
        (search)="searchDocuments()"
      >
      </maia-search-bar>
    </div>
    <div>
      <!--   The div is used to make search half in fluid-fluid layout-->
    </div>
  </maia-fluid-fluid-layout>

  <nje-admin-library-list
    (selectedAdminLibrary)="OnSelectedAdminLibrary($event)"
    [hasSearchTriggered]="hasSearchTriggered"
    [searchResultCount]="searchResultCount"
  >
  </nje-admin-library-list>

  <nje-admin-document-documents
    [selectedLibrary]="selectedAdminLibrary"
    [searchWord]="searchWord"
    [hasSearchTriggered]="hasSearchTriggered"
    [libraryDocumentsSearchResult]="searchResult"
  >
  </nje-admin-document-documents>
</div>
