import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprPhoneLockSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprPhoneLock]',
  template: '<svg:g class="nc-icon-wrapper"><svg:g transform="matrix(.6 0 0 .6 8.64 8.64)"><svg:path class="st0" d="M4.1 3.2h39.8" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path class="st0" d="M4.1 43h39.8" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path class="st0" d="M39.4 52.4H8.9c-2.6 0-4.7-2.1-4.7-4.7V.3c0-2.6 2.1-4.7 4.7-4.7h30.3c2.6 0 4.7 2.1 4.7 4.7v47.4c.1 2.5-1.8 4.6-4.3 4.7h-.2z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></svg:path></svg:g><svg:path class="st1" d="M19.04 23.68h10.24v6.4H19.04z" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st1" d="M21.6 23.68v-3.84c0-1.44 1.12-2.56 2.56-2.56s2.56 1.12 2.56 2.56v3.84" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprPhoneLockSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprPhoneLockSvgComponent,
  ],
  exports: [
    InformationExprPhoneLockSvgComponent,
  ],
})
export class InformationExprPhoneLockSvgModule {}
