import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Decimal} from '@atlas/businesstypes';
import {
  LabelEditDto,
  TravelRoutePaths,
  ProposalDto,
  TripDetailDto,
  DateFormatterService,
  NameAddressParsingService,
  SingleTripFormData,
  MultiTripFormData,
  RadioButtonDto,
  TravelToUSAorCanada,
  MaximumDaysAbroad,
  TwelveMonths,
  TravelCoversDto,
  AddonsDiscountsDto,
  CoversStep,
  InsuringPartyDto,
  PartyType,
  EgnLnchData,
  EikData,
  Individual,
  InsuredPerson,
  ASSET_PATH,
} from '@b2b-frontend/core';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {TravelProcessService} from '../../travel-core/services/travel-process.service';
import {TravelSessionService} from '../../travel-core/services/travel-session.service';
import {MOCK_TRAVELUS_CANADA_OPTION_LIST} from '../travel-trip-details/__tests__/travel-trip-details.mocks';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {REASON_FOR_TRAVEL_TITLE, TRAVEL_PROPOSAL} from '../../constants/constant';
import {TravelPolicyService} from '../../travel-core/services/travel-policy.service';
import {TravelSlideInService} from '../../travel-core/services/travel-slide-in.service';
import {ModalConfirmedResult} from '@maia/modals';

@Component({
  selector: 'travel-proposal',
  templateUrl: './travel-proposal.component.html',
  styleUrls: ['./travel-proposal.component.scss'],
})
@UntilDestroy()
export class TravelProposalComponent extends ScreenStepComponent implements OnInit {
  public policyDescPath: string = `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRIP_DETAILS}`;
  public addonsPath: string = `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_ADD_ONS}`;
  public coversPath: string = `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRAVEL_COVERS}`;
  public insuringPartyPath: string = `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_INSURING_PARTY}`;
  public insuredPeoplePath: string = `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_INSURED_PEOPLE}`;
  public policyDescHeading: LabelEditDto = {
    id: 1,
    name: TRAVEL_PROPOSAL.POLICY_DESCRIPTION,
    navigationPath: this.policyDescPath,
  };

  public insuringPartyHeading: LabelEditDto = {
    id: 2,
    name: TRAVEL_PROPOSAL.INSURING_PARTY,
    navigationPath: this.insuringPartyPath,
  };

  public insuredPeopleHeading: LabelEditDto = {
    id: 3,
    name: TRAVEL_PROPOSAL.INSURED_PEOPLE,
    navigationPath: this.insuredPeoplePath,
  };

  public addonsHeading: LabelEditDto = {
    id: 4,
    name: TRAVEL_PROPOSAL.ADDONS,
    navigationPath: this.coversPath,
  };
  public discountHeading: LabelEditDto = {
    id: 5,
    name: TRAVEL_PROPOSAL.DISCOUNT,
    navigationPath: this.addonsPath,
  };
  public travelUsCanadOptionList: RadioButtonDto[] = [...MOCK_TRAVELUS_CANADA_OPTION_LIST];
  public amountInDecimal = new Decimal('12345.67');
  public interest1 = new Decimal('2');
  public tripDetailData: TripDetailDto;
  public travelCoverDto: TravelCoversDto;
  public addonDiscountDto: AddonsDiscountsDto;
  public selectedAddon: CoversStep[] | undefined;
  public selectedDiscounts: CoversStep[] | undefined;
  public insuringPartyDto: InsuringPartyDto;
  public tripTypeName: string;
  public totalNoOfPeople: number = 0;
  public reasonType: string;
  public workType: string;
  public singleTripStartDate: string | undefined;
  public singleTripEndDate: string | undefined;
  public tripStartEndDate: string | undefined;
  public multiTripStartDate: string | undefined;
  public multiTripEndDate: string | undefined;
  public multiTripStartEndDate: string | undefined;
  public travelAbroadTitle: string | undefined;
  public travelAbroadSubTitle: string | undefined;
  public noOfTripDays: string | undefined;
  public coversName: string;
  public coverSubtitle: string;
  public insuringPartyIdNumber: string;
  public insuringPartyType: PartyType | undefined;
  public insuringPartyEgnData: EgnLnchData;
  public insuringPartyEikData: EikData;
  public insuringPartyTypeNoDisplayValue: string;
  public insuringPartyTypeIndividalName: string;
  public insuringPartyEmail: string;
  public insurPartyPhone: string;
  public insurePeopleList: InsuredPerson[] = [];
  public noSelectedDiscounts: boolean | undefined;
  public noSelectedAddons: boolean | undefined;

  protected saveMtplData(): void {
    this.router.navigateByUrl(
      `dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRAVEL_SUCCESS}`,
    );
  }

  public constructor(
    activatedRoute: ActivatedRoute,
    public process: TravelProcessService,
    public travelSessionService: TravelSessionService,
    public travelPolicyService: TravelPolicyService,
    public dateFormaterService: DateFormatterService,
    public nameAddressParsingService: NameAddressParsingService,
    private readonly _travelSlideInService: TravelSlideInService,
    public router: Router,
  ) {
    super(activatedRoute);
    this.process.exitStepEdit();
  }

  public editScreen(screenDetail: LabelEditDto): void {
    this.travelSessionService.updateAddonDiscountObj(screenDetail.id);
    const showPriceAccordian: boolean = screenDetail.id === 1;
    this.travelPolicyService.showPriceAccordion(showPriceAccordian);
    const step = this.process.getStepByRoute(screenDetail.navigationPath);
    if (step) {
      step.edit(this.step);
    }
  }

  public ngOnInit(): void {
    this.travelPolicyService.showPriceAccordion(false);
    const data: ProposalDto | undefined = this.travelSessionService.getTravelData();
    if (data) {
      if (data.tripDetailDto) {
        this.tripDetailData = data.tripDetailDto;
        this.tripDataParsing();
      }
      if (data.travelCoverDto) {
        this.travelCoverDto = data.travelCoverDto;
        this.coverDataParsing();
      }
      if (data.addonDiscountDto) {
        this.addonDiscountDto = data.addonDiscountDto;
        this.addOnsDiscountParsing();
      }
      if (data.insuringPartyDto) {
        this.insuringPartyDto = data.insuringPartyDto;
        this.insuringPartyParsing();
      }
      if (data.insuredPersonDto) {
        this.insurePeopleList = data.insuredPersonDto.addedInsuredPerson;
      }
    }
  }

  public insuringPartyParsing(): void {
    this.insuringPartyIdNumber = this.insuringPartyDto.idNumber;
    this.insuringPartyType = this.insuringPartyDto.partyType;
    if (
      (this.insuringPartyType === PartyType.PERSON ||
        this.insuringPartyType === PartyType.FOREIGNER) &&
      this.insuringPartyDto.insuringPartyEgnData
    ) {
      this.insuringPartyEgnData = this.insuringPartyDto.insuringPartyEgnData;
      this.insuringPartyTypeNoDisplayValue = Individual + ' -' + this.insuringPartyIdNumber;
      this.insuringPartyTypeIndividalName = this.nameAddressParsingService.nameNullCheck(
        this.insuringPartyEgnData.firstName,
        this.insuringPartyEgnData.middleName,
        this.insuringPartyEgnData.lastName,
      );
      this.insuringPartyEmail = this.insuringPartyEgnData.emailId;

      this.insurPartyPhone =
        this.insuringPartyEgnData.phoneNumber !== null &&
        this.insuringPartyEgnData.phoneNumber !== undefined
          ? this.insuringPartyEgnData.phoneNumber.prefix +
            '-' +
            this.insuringPartyEgnData.phoneNumber.number
          : '';

      this.addOnsDiscountParsing();
    }
  }

  public addOnsDiscountParsing(): void {
    this.selectedAddon = this.addonDiscountDto.addons;
    this.selectedDiscounts = this.addonDiscountDto.discounts;
    this.noSelectedAddons = this.selectedAddon && this.selectedAddon.length === 0;
    this.noSelectedDiscounts = this.selectedDiscounts && this.selectedDiscounts.length === 0;
  }

  public coverDataParsing(): void {
    this.coversName = this.travelCoverDto.selectedCover.coversName;
    this.coverSubtitle = `Main Coverage - ${this.travelCoverDto.selectedCover.coverSubtitle}`;
  }

  public tripDataParsing(): void {
    this.tripTypeName = this.tripDetailData.tripRadioGroup.tripName;
    this.reasonType = this.tripDetailData.travelReason.objectName;
    this.workType = this.tripDetailData.workType
      ? this.tripDetailData.workType.fieldValueName
      : REASON_FOR_TRAVEL_TITLE;
    this.totalNoOfPeople = this.tripDetailData.travelInsuredPeopleCount;
    if (this.tripDetailData.singleTrip) {
      const singleTrip: SingleTripFormData = this.tripDetailData.singleTrip;
      this.singleTripStartDate = this.dateFormaterService.getDDMMYYYYFormatDate(
        singleTrip.startEndData.start,
      );
      this.singleTripEndDate = this.dateFormaterService.getDDMMYYYYFormatDate(
        singleTrip.startEndData.end,
      );
      this.noOfTripDays = `${this.dateFormaterService.isoDateDifference(
        singleTrip.startEndData.start,
        singleTrip.startEndData.end,
      )} Days`;
      this.tripStartEndDate = this.singleTripStartDate + ' - ' + this.singleTripEndDate;
      this.travelAbroadTitle = TravelToUSAorCanada;
      this.travelAbroadSubTitle = this.travelUsCanadOptionList.find(
        t => t.id === singleTrip.travelAbroadRadioGroup,
      )?.name;
    }
    if (this.tripDetailData.multiTrip) {
      const multiTrip: MultiTripFormData = this.tripDetailData.multiTrip;
      this.multiTripStartDate = this.dateFormaterService.getDDMMYYYYFormatDate(
        multiTrip.multipleStartDate,
      );
      this.multiTripEndDate = this.dateFormaterService.getDDMMYYYYFormatDate(
        multiTrip.multipleEndDate,
      );
      this.tripStartEndDate = this.multiTripStartDate + ' - ' + this.multiTripEndDate;
      this.noOfTripDays = TwelveMonths;
      this.travelAbroadTitle = `Up to ${multiTrip.daysAbroad} Days`;
      this.travelAbroadSubTitle = MaximumDaysAbroad;
    }
  }

  public openSlideInList(): void {
    this._travelSlideInService
      .openProposalSlideIn()
      .subscribe((res: ModalConfirmedResult<void>) => {
        // logic
      });
  }

  public openPDFFile(): void {
    window.open(ASSET_PATH.IPID_DOC_PDF);
  }
}
