<div class="covers-primary-bg-light" [ngClass]="{'white-bg': isProposalScreen}">
  <maia-fluid-fluid-layout alignContentTop>
    <div class="address-div">
      <div>
        <svg class="place-icon" b2bFrontendPlacesExprHouseTree></svg>
      </div>
      <div class="address-value">
        <button maiaButton maiaButtonType="tertiary" (click)="editPropertyAddress(propertyAddress)"
          >{{ propertyAddress.streetNumberEn }}, {{ propertyAddress.streetEn }},
          {{ propertyAddress.quarterEn }}, {{ propertyAddress.cityEn }},
          {{ propertyAddress.postCodeEn }}</button
        >
        <div class="grey-text"
          >{{ propertyAddress.firstNameEn }} {{ propertyAddress.middleNameEn }}
          {{ propertyAddress.lastNameEn }}, {{ propertyAddress.idNumber }}</div
        >
      </div>
      <div *ngIf="premiumAmountVisibility" class="currency-div">
        <div class="currency-sub-div">
          <div class="currency-value">809,03 {{ currencySelected }}</div>
          <div class="grey-text">Total Premium for this address (Incl. of taxes)</div>
        </div>
      </div>
    </div>
  </maia-fluid-fluid-layout>
  <maia-divider></maia-divider>
  <div class="b2b-kdl-font-small-bold blue-text"> Covers on Address Level </div>
  <maia-fluid-fluid-layout alignContentTop>
    <div
      class="cover-address-level-container"
      *ngFor="let mainCover of additionalCoversInfoList; let i = index"
    >
      <div class="cover-address-level-div" *ngIf="mainCover.mandatory">
        <div class="check-icon-container">
          <svg class="check-icon" b2bFrontendAlertFuncCheck></svg>
        </div>
        <div class="b2b-kdl-font-small blue-text">
          <p>{{ mainCover.coverName }}</p>
          <p *ngIf="premiumAmountVisibility"
            ><span class="b2b-kdl-font-small-light gray-text">Premium Amount </span>0,00
            {{ currencySelected }}</p
          >
        </div>
      </div>
    </div>
  </maia-fluid-fluid-layout>
  <maia-divider class="m-t-b-24"></maia-divider>
  <div class="add-cover" *ngIf="!isProposalScreen">
    <div class="page-info b2b-kdl-font-small title-dark-color">
      Additional Covers on Address Level
    </div>
    <div class="add-cover-icon">
      <svg class="add-icon" b2bFrontendActionFuncPlusCircle></svg>
    </div>
    <div class="add-cover-button">
      <button maiaButton maiaButtonType="tertiary" (click)="addAdditionalCover()"
        >Add Additional Cover</button
      >
    </div>
  </div>
  <maia-fluid-fluid-layout alignContentTop>
    <div class="table-div">
      <maia-table class="cover-details-table" [ngClass]="{'white-bg': isProposalScreen}">
        <maia-table-header>
          <maia-table-row class="blue-bg-table-row" [ngClass]="{'white-bg': isProposalScreen}">
            <maia-table-cell
              class="b2b-kdl-font-small subtitle-light-color"
              *ngFor="let column of additionalCoversColumnHeaders; let i = index"
            >
              {{ column }}
            </maia-table-cell>
            <maia-table-cell
              *ngIf="premiumAmountVisibility"
              class="b2b-kdl-font-small subtitle-light-color"
            >
              Premium Amount
            </maia-table-cell>
            <maia-table-cell
              *ngIf="additionalCoversInfoList.length > 4"
              class="icon-container"
              maiaTableHeaderHiddenOnSmall
            >
            </maia-table-cell>
          </maia-table-row>
        </maia-table-header>
        <maia-table-body>
          <div *ngFor="let additionalCoversInfo of additionalCoversInfoList; let i = index">
            <maia-table-row
              *ngIf="
                !additionalCoversInfo.mandatory || additionalCoversInfo.mandatory === undefined
              "
              class="blue-bg-table-row"
              [ngClass]="{'white-bg': isProposalScreen}"
            >
              <maia-table-cell>
                <span
                  *ngIf="
                    additionalCoversInfoList.length <= 3 &&
                    additionalCoversInfo.coverName === sosHomeValue
                  "
                  >{{ additionalCoversInfo.coverName }}</span
                >
                <span
                  *ngIf="
                    additionalCoversInfoList.length > 3 &&
                    additionalCoversInfo.coverName === sosHomeValue
                  "
                >
                  {{ additionalCoversInfo.coverName }}
                </span>
                <span
                  *ngIf="
                    additionalCoversInfoList.length > 3 &&
                    additionalCoversInfo.coverName !== sosHomeValue
                  "
                >
                  <maia-inline-action
                    type="button"
                    (click)="addAdditionalCover(additionalCoversInfo)"
                  >
                    {{ additionalCoversInfo.coverName }}
                  </maia-inline-action>
                </span>
              </maia-table-cell>
              <maia-table-cell>
                {{ additionalCoversInfo.aggregateLimit?.default | atlasText }}
                {{ currencySelected }}
              </maia-table-cell>
              <maia-table-cell>
                <span *ngIf="additionalCoversInfo.limitPerOccurence !== undefined"
                  >{{ additionalCoversInfo.limitPerOccurence.default | atlasText }}
                  {{ currencySelected }}</span
                >
                <span *ngIf="additionalCoversInfo.limitPerOccurence === undefined">-</span>
              </maia-table-cell>
              <maia-table-cell *ngIf="premiumAmountVisibility">
                {{ additionalCoversInfo.premiumAmount }} {{ currencySelected }}
              </maia-table-cell>
              <maia-table-cell class="left" *ngIf="deleteIconDisplayOfCovers()">
                <svg
                  class="delete-icon"
                  b2bFrontendActionFuncBin
                  (click)="removeCover(i, additionalCovers)"
                ></svg>
              </maia-table-cell>
            </maia-table-row>
          </div>
        </maia-table-body>
      </maia-table>
    </div>
  </maia-fluid-fluid-layout>
  <maia-divider></maia-divider>
  <div class="b2b-kdl-font-small-bold blue-text"> Property covers </div>
  <maia-fluid-fluid-layout alignContentTop>
    <div class="table-div">
      <maia-table class="cover-details-table" [ngClass]="{'white-bg': isProposalScreen}">
        <maia-table-header>
          <maia-table-row class="blue-bg-table-row" [ngClass]="{'white-bg': isProposalScreen}">
            <maia-table-cell
              class="b2b-kdl-font-small subtitle-light-color"
              *ngFor="let column of propertyCoversColumnHeaders; let i = index"
            >
              {{ column }}
            </maia-table-cell>
            <maia-table-cell
              *ngIf="premiumAmountVisibility"
              class="b2b-kdl-font-small subtitle-light-color"
            >
              Premium Amount
            </maia-table-cell>
            <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall> </maia-table-cell>
          </maia-table-row>
        </maia-table-header>
        <maia-table-body>
          <maia-table-row
            *ngFor="let propertyCoversInfo of propertyCoversInfoList; let i = index"
            class="blue-bg-table-row"
            [ngClass]="{'white-bg': isProposalScreen}"
          >
            <maia-table-cell>
              <span *ngIf="propertyCoversInfo.basisOfInsurance !== undefined">
                <maia-inline-action type="button" (click)="addEditCover(propertyCoversInfo, i)">
                  {{ propertyCoversInfo.propertyType }}
                </maia-inline-action>
              </span>
              <span *ngIf="propertyCoversInfo.basisOfInsurance === undefined">
                {{ propertyCoversInfo.propertyType }}
              </span>
            </maia-table-cell>
            <maia-table-cell>
              <span *ngIf="propertyCoversInfo.basisOfInsurance !== undefined">
                {{ propertyCoversInfo.basisOfInsurance }}
              </span>
              <span *ngIf="propertyCoversInfo.basisOfInsurance === undefined">-</span>
            </maia-table-cell>
            <maia-table-cell>
              <span *ngIf="propertyCoversInfo.insuredValue !== undefined">
                {{ propertyCoversInfo.insuredValue | atlasText }} {{ currencySelected }}
              </span>
              <span *ngIf="propertyCoversInfo.insuredValue === undefined">-</span>
            </maia-table-cell>
            <maia-table-cell>
              <span *ngIf="propertyCoversInfo.mainCovers !== undefined">
                {{ propertyCoversInfo.mainCovers }}
              </span>
              <span *ngIf="propertyCoversInfo.mainCovers === undefined">-</span>
            </maia-table-cell>
            <maia-table-cell>
              <span *ngIf="propertyCoversInfo.additionalCovers !== undefined">
                <span *ngFor="let additionalCover of propertyCoversInfo.additionalCovers">
                  <div>{{ additionalCover }}</div>
                </span>
              </span>
              <span
                *ngIf="
                  propertyCoversInfo.additionalCovers === undefined ||
                  propertyCoversInfo.additionalCovers === null
                "
                >-</span
              >
            </maia-table-cell>
            <maia-table-cell>
              <span *ngIf="propertyCoversInfo.aggregateLimit !== undefined">
                <span *ngFor="let aggregateLimit of propertyCoversInfo.aggregateLimit">
                  <div *ngIf="aggregateLimit !== 0"
                    >{{ aggregateLimit }} {{ currencySelected }}</div
                  >
                </span>
              </span>
              <span *ngIf="propertyCoversInfo.aggregateLimit === undefined">-</span>
            </maia-table-cell>
            <maia-table-cell *ngIf="premiumAmountVisibility">
              {{ propertyCoversInfo.premiumAmount }} {{ currencySelected }}
            </maia-table-cell>
            <maia-table-cell
              *ngIf="!isProposalScreen"
              class="left icon-container"
              maiaTableHeaderHiddenOnSmall
            >
              <div class="add-cover-icon">
                <svg
                  class="add-icon"
                  *ngIf="propertyCoversInfo.basisOfInsurance === undefined"
                  b2bFrontendActionFuncPlusCircle
                  (click)="addEditCover(propertyCoversInfo, i)"
                ></svg>
                <svg
                  *ngIf="propertyCoversInfo.basisOfInsurance !== undefined"
                  class="delete-icon"
                  b2bFrontendActionFuncBin
                  (click)="removeCover(i, propertyCovers)"
                ></svg>
              </div>
            </maia-table-cell>
          </maia-table-row>
        </maia-table-body>
      </maia-table>
    </div>
  </maia-fluid-fluid-layout>
</div>
