import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationFuncMailSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendCommunicationFuncMail]',
  template: '<svg:path d="M2.5 12.86v5.19a1.72 1.72 0 0 0 1.73 1.72h15.54a1.72 1.72 0 0 0 1.73-1.72v-5.19" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M21.5 8.55V6a1.72 1.72 0 0 0-1.73-1.72H4.23A1.72 1.72 0 0 0 2.5 6v2.6l9.5 5.13z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationFuncMailSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationFuncMailSvgComponent,
  ],
  exports: [
    CommunicationFuncMailSvgComponent,
  ],
})
export class CommunicationFuncMailSvgModule {}
