export enum PropertyRoutePaths {
  PROPERTY = 'property',
  CONFIRMATION = 'confirmation',
  PROPERTY_INSURANCE = 'property-insurance',
  PROPERTY_POLICY_DETAILS = 'property-policy-details',
  PROPERTY_INSURING_PARTY = 'property-insuring-party',
  PROPERTY_DETAILS = 'property-details',
  PROPERTY_COVERS_AND_DISCOUNTS = 'property-covers-and-discounts',
  PROPERTY_SUPPLEMENTARY_LIST = 'property-supplementary-list',
  PROPERTY_PROPOSAL = 'property-proposal',
  PROPERTY_POLICY_SUCCESS = 'property-policy-success',
  PROPERTY_POLICY_ERROR = 'property-policy-error',
  PROPERTY_POLICY_RENEW_POLICY = 'property-renew-policy',
}
