import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';

import {B2B_ROUTE} from './screens/b2b/b2b-routing.module';
import {B2C_ROUTE} from './screens/b2c/b2c-routing.module';
import {HOME_ROUTE} from './screens/home/home-routing.module';
import {UamComponent} from './uam.component';

const routes: Routes = [
  {
    path: '',
    component: UamComponent,
    children: [
      {
        path: '',
        children: [HOME_ROUTE],
      },
      {
        path: 'b2c',
        children: [B2C_ROUTE],
      },
      {
        path: 'b2b',
        children: [B2B_ROUTE],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UamRoutingModule {}

/**
 * The route we export is anempty path with the private module lazy loaded.
 */
export const UAM_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./uam-routing.module').then(module => module.UamRoutingModule),
};
