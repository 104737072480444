import {DeductibleRadio} from './price-covers-deductible-slidein-mock-interface';

export const MOCK_DEDUCTIBLE_OPTIONS: DeductibleRadio[] = [
  {
    id: 0,
    desc_en: '150',
  },
  {
    id: 1,
    desc_en: '300',
  },
  {
    id: 2,
    desc_en: 'Other',
  },
];
