<div class="add-edit-supplementary-object-form">
  <maia-label class="icon-primary-color b2b-kdl-font-small-bold">Selected Agents</maia-label>
  <maia-table class="selectedTable-wrapper">
    <maia-table-header>
      <maia-table-row class="b2b-kdl-color-background-light">
        <maia-table-cell
          class="b2b-kdl-font-small subtitle-light-color column-width b2b-kdl-font-normal"
          *ngFor="let column of tableColumns"
        >
          {{ columnHeadings[column] }}
        </maia-table-cell>
        <maia-table-cell maiaTableHeaderHiddenOnSmall></maia-table-cell>
      </maia-table-row>
    </maia-table-header>

    <maia-table-body>
      <maia-table-row
        class="b2b-kdl-color-background-light row-container"
        *ngFor="let agent of selectedAgents"
      >
        <maia-table-cell
          class="title-dark-color b2b-kdl-font-normal columns-width"
          [maiaTableHeaderValue]="'Header'"
        >
          {{ agent?.agencyNumber }}
        </maia-table-cell>
        <maia-table-cell
          class="title-dark-color b2b-kdl-font-normal columns-width"
          [maiaTableHeaderValue]="'Header'"
        >
          {{ agent?.agencyName }}
        </maia-table-cell>
        <maia-table-cell
          class="title-dark-color b2b-kdl-font-normal columns-width"
          [maiaTableHeaderValue]="'Header'"
        >
          {{ agent?.agentNumber }}
        </maia-table-cell>
        <maia-table-cell
          class="title-dark-color b2b-kdl-font-normal columns-width"
          [maiaTableHeaderValue]="'Header'"
        >
          {{ agent?.agentName }}
        </maia-table-cell>
        <maia-table-cell class="icon-container" alignRight alignRightOnSmall>
          <svg ivyMaiaActionFuncBin></svg>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
</div>
