<header-with-breadcrumbs title="Payment of Instalment"></header-with-breadcrumbs>
<div class="max-width-body">
  <div class="m-t-24" *ngIf="isStepperVisible">
    <maia-expanded-flow-progress *ngIf="!(isEditing | async)">
      <maia-expanded-flow-progress-step
        *ngFor="let step of steps | async"
        [title]="step.title"
        [routeId]="step.id"
        [route]="step.routePath"
      >
      </maia-expanded-flow-progress-step>
    </maia-expanded-flow-progress>
  </div>

  <div class="screen-margin">
    <router-outlet></router-outlet>
    <njf-covers-accordion
      [totalInsuranceAmount]="totalPaymentAmount"
      [title]="title"
    ></njf-covers-accordion>
  </div>
</div>
