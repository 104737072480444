<form maiaForm class="form-style" [formGroup]="covers.formGroup">
  <div class="header-info">
    <div>
      <div *ngIf="!autoprocess" class="si-style">
        <maia-label
          class="label-font"
          i18n="@@nje-b2b-frontend-casco-label-suminsured"
          i18n-sublabel="@@nje-b2b-frontend-casco-sublabel-methodofsi"
          sublabel="Method of SI determination: Renewal"
          >Sum Insured</maia-label
        >
      </div>
      <div *ngIf="autoprocess" class="si-style">
        <maia-label
          class="label-font"
          i18n="@@nje-b2b-frontend-casco-label-suminsured"
          i18n-sublabel="@@nje-b2b-frontend-casco-sublabel-manualsi"
          sublabel="SI range cannot be determined. Please enter SI manually"
          >Sum Insured</maia-label
        >
      </div>

      <div class="si-input-box">
        <maia-input-group>
          <input
            maiaInputNumber
            i18n-placeholder="@@nje-b2b-frontend-casco-placeholder-suminsuredenter"
            name="inputNumber 1"
            placeholder="Please enter SI"
            formControlName="sumInsured"
          />
          <maia-input-group-suffix>BGN</maia-input-group-suffix>
        </maia-input-group>
      </div>

      <div *ngIf="warning">
        <maia-label i18n="@@nje-b2b-frontend-casco-label-sioutsiderange" class="range-warning-text"
          >Entered SI is outside range. You can still continue</maia-label
        >
      </div>
    </div>

    <div *ngIf="!autoprocess" class="range-div">
      <div class="range-div-inside">
        <maia-label i18n="@@nje-b2b-frontend-casco-label-minmax" b2b-kdl-font-normal>
          <p class="max-min1">Minimum SI: <br />Maximum SI: </p>
        </maia-label>
      </div>
      <div *ngIf="!autoprocess" class="range-div-inside">
        <maia-label i18n="@@nje-b2b-frontend-casco-label-range">
          <p class="max-min2" b2b-kdl-font-grand-bold>10 000 BGN<br />25 000 BGN </p>
        </maia-label>
      </div>
    </div>

    <div class="m-20 head-auto">
      <div class=".readmore-icon">
        <a
          id="maia-action-icon-4"
          maiaDocumentAction
          rel="noopener noreferrer"
          href="https://www.kbc.be/particulieren/nl.html"
          target="_blank"
          i18n="@@nje-b2b-frontend-casco-hyperlink-readmore"
          >Read more</a
        >
      </div>
    </div>
  </div>

  <maia-divider></maia-divider>
  <div class="header-info">
    <label class="label-font" i18n="@@nje-b2b-frontend-casco-covers-label-title">Covers</label>
  </div>
  <maia-radio-group name="fullRadio" formControlName="fullRadio" [(ngModel)]="casco">
    <maia-fluid-fluid-layout>
      <maia-tile
        class="m-t-b-24 addons-tile-unselected-1 m-r-16"
        [ngClass]="{'addons-tile-selected': casco === 1}"
      >
        <maia-radio-button i18n="@@nje-b2b-frontend-casco-covers-radio1-fullcasco" [value]="1"
          >Full Casco
        </maia-radio-button>
      </maia-tile>

      <maia-tile
        class="m-t-b-24 addons-tile-unselected-1 m-r-16"
        [ngClass]="{'addons-tile-selected': casco === 2}"
      >
        <maia-radio-button i18n="@@nje-b2b-frontend-casco-covers-radio2-supercasco" [value]="2">
          Super Casco</maia-radio-button
        >
      </maia-tile>
      <div></div>
    </maia-fluid-fluid-layout>
  </maia-radio-group>

  <label i18n="@@nje-b2b-frontend-casco-extension-label-title" class="label-font">
    Extension Pack
  </label>

  <maia-radio-group [(ngModel)]="extension" [ngModelOptions]="{standalone: true}">
    <maia-fluid-fluid-layout>
      <maia-tile
        class="m-t-b-24 addons-tile-unselected extension-tile m-r-16"
        [ngClass]="{'addons-tile-selected': extension === 1}"
      >
        <maia-radio-button i18n="@@nje-b2b-frontend-casco-extension-radio1-preabrd" [value]="1"
          >Premium + Abroad</maia-radio-button
        >
        <button class="readmore-btn" maiaActionWithIcon size="small">
          <svg class="readmore-icon" b2bFrontendNavigationFuncOpenInNew maia-action-icon></svg>
          <span
            i18n="@@nje-b2b-frontend-extension-button1-readmore1"
            class="b2b-kdl-font-small cover-readmore"
            >Read more</span
          >
        </button>
      </maia-tile>

      <maia-tile
        class="m-t-b-24 addons-tile-unselected extension-tile m-r-16"
        [ngClass]="{'addons-tile-selected': extension === 2}"
      >
        <maia-radio-button i18n="@@nje-b2b-frontend-casco-extension-radio2-vipabrd" [value]="2"
          >VIP + Abroad</maia-radio-button
        >
        <button class="readmore-btn" maiaActionWithIcon size="small">
          <svg class="readmore-icon" b2bFrontendNavigationFuncOpenInNew maia-action-icon></svg>
          <span
            i18n="@@nje-b2b-frontend-extension-button2-readmore2"
            class="b2b-kdl-font-small cover-readmore"
            >Read more</span
          >
        </button>
      </maia-tile>

      <maia-tile
        class="m-t-b-24 addons-tile-unselected"
        [ngClass]="{'addons-tile-selected': extension === 3}"
      >
        <maia-radio-button i18n="@@nje-b2b-frontend-casco-extension-radio3-noassi" [value]="3"
          >No assistance</maia-radio-button
        >
      </maia-tile>
    </maia-fluid-fluid-layout>
  </maia-radio-group>

  <maia-divider></maia-divider>
  <div class="header-info m-b-16">
    <label class="label-font" i18n="@@nje-b2b-frontend-casco-discounts-label-title">
      Discounts
    </label>
    <a
      id="maia-action-icon-4"
      maiaDocumentAction
      rel="noopener noreferrer"
      href="https://www.kbc.be/particulieren/nl.html"
      target="_blank"
      i18n="@@nje-b2b-frontend-casco-discounts-hyperlink-tariff"
      >Tariff</a
    >
  </div>
  <maia-checkbox-group>
    <div class="discount-box">
      <div class="extension-tile">
        <maia-tile
          class="checkbox-btn-margin-1"
          [ngClass]="{'addons-check-selected': mtplChk | atlasBoolean}"
        >
          <maia-checkbox
            (onValueChange)="changeSelection(1)"
            [(ngModel)]="mtplChk"
            [ngModelOptions]="{standalone: true}"
            i18n="@@nje-b2b-frontend-casco-discounts-chkbox1-mtpldisc"
            >Valid MTPL discount</maia-checkbox
          >
        </maia-tile>

        <maia-tile *ngIf="activateMTPLPercBox" class="addons-coverage-amount-box-wrapper">
          <div>
            <maia-label i18n="@@nje-b2b-frontend-casco-discounts-extbox1-mtpldisc"
              >Type 0-5%</maia-label
            >
            <maia-input-group>
              <input maiaInput class="input-box-perc" />
              <maia-input-group-suffix>%</maia-input-group-suffix>
            </maia-input-group>
          </div>
        </maia-tile>
      </div>
      <div class="extension-tile">
        <maia-tile
          class="checkbox-btn-margin-1"
          [ngClass]="{'addons-check-selected': insChk | atlasBoolean}"
        >
          <maia-checkbox
            (onValueChange)="changeSelection(2)"
            [(ngModel)]="insChk"
            [ngModelOptions]="{standalone: true}"
            i18n="@@nje-b2b-frontend-casco-discounts-chkbox2-newinsvhcl"
            >New Insured Vehicle</maia-checkbox
          >
        </maia-tile>

        <maia-tile
          *ngIf="activateNewInsuredVehiclePercBox"
          class="addons-coverage-amount-box-wrapper tile-class"
        >
          <div>
            <maia-label i18n="@@nje-b2b-frontend-casco-discounts-extbox2-newinsvhcl"
              >Type 3-5%</maia-label
            >
            <maia-input-group>
              <input maiaInput class="input-box-perc" />
              <maia-input-group-suffix>%</maia-input-group-suffix>
            </maia-input-group>
          </div>
        </maia-tile>
      </div>
      <div class="extension-tile">
        <maia-tile
          class="checkbox-btn-margin-1"
          [ngClass]="{'addons-check-selected': regChk | atlasBoolean}"
        >
          <maia-checkbox
            (onValueChange)="changeSelection(3)"
            [(ngModel)]="regChk"
            [ngModelOptions]="{standalone: true}"
            i18n="@@nje-b2b-frontend-casco-discounts-chkbox3-newregvhcl"
            >New Register Vehicle</maia-checkbox
          >
        </maia-tile>

        <maia-tile
          *ngIf="activateNewRegisterVehiclePercBox"
          class="addons-coverage-amount-box-wrapper tile-class"
        >
          <div>
            <maia-label i18n="@@nje-b2b-frontend-casco-discounts-extbox3-newregvhcl"
              >Type 0-10%</maia-label
            >
            <maia-input-group>
              <input maiaInput class="input-box-perc" />
              <maia-input-group-suffix>%</maia-input-group-suffix>
            </maia-input-group>
          </div>
        </maia-tile>
      </div>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': ins3VehChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-discounts-chkbox4-ins3VehChk"
          [(ngModel)]="ins3VehChk"
          [ngModelOptions]="{standalone: true}"
          >Insurance of more than 3 vehicles</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': sPayChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-discounts-chkbox5-sPayChk"
          [(ngModel)]="sPayChk"
          [ngModelOptions]="{standalone: true}"
          >Single Payment</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': insZAKChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-discounts-chkbox6-insZAKChk"
          [(ngModel)]="insZAKChk"
          [ngModelOptions]="{standalone: true}"
          >Valid Property or ZAKRILA Insurance</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': expEvalChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-discounts-chkbox7-expEvalChk"
          [(ngModel)]="expEvalChk"
          [ngModelOptions]="{standalone: true}"
          >Expert Evaluation</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': own45Chk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-discounts-chkbox8-own45Chk"
          [(ngModel)]="own45Chk"
          [ngModelOptions]="{standalone: true}"
          [disabled]="true"
          >Owner aged above 45 years</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': rgnlDisChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-discounts-chkbox9-rgnlDisChk"
          [(ngModel)]="rgnlDisChk"
          [ngModelOptions]="{standalone: true}"
          [disabled]="true"
          >Regional Discount</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': bonMal1Chk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-discounts-chkbox10-bonMal1Chk"
          [(ngModel)]="bonMal1Chk"
          [ngModelOptions]="{standalone: true}"
          [disabled]="true"
          >Bonus Malus</maia-checkbox
        >
      </maia-tile>
    </div>
    <div i18n="@@nje-b2b-frontend-casco-discounts-text-info" class="discounts-label"
      >The sum of some discounts is limited to 25%</div
    >
    <maia-divider></maia-divider>
    <label class="loadings-title label-font" i18n="@@nje-b2b-frontend-casco-loadings-label-title"
      >Loadings</label
    >
    <div class="loadings-warp">
      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': wrtEvntDecChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-loadings-chkbox11-wrtEvntDecChk"
          [(ngModel)]="wrtEvntDecChk"
          [ngModelOptions]="{standalone: true}"
          >Unlimited written event declaration</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': rprAbrdChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-loadings-chkbox12-rprAbrdChk"
          [(ngModel)]="rprAbrdChk"
          [ngModelOptions]="{standalone: true}"
          >Repair Abroad</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': rskMkMdlChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-loadings-chkbox13-rskMkMdlChk"
          [(ngModel)]="rskMkMdlChk"
          [ngModelOptions]="{standalone: true}"
          [disabled]="true"
          >Risky Makes and Models</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': ownAgeChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-loadings-chkbox14-ownAgeChk"
          [(ngModel)]="ownAgeChk"
          [ngModelOptions]="{standalone: true}"
          [disabled]="true"
          >Age of the owner</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-btn-margin"
        [ngClass]="{'addons-check-selected': bonMal2Chk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-loadings-chkbox15-bonMal2Chk"
          [(ngModel)]="bonMal2Chk"
          [ngModelOptions]="{standalone: true}"
          [disabled]="true"
          >Bonus Malus</maia-checkbox
        >
      </maia-tile>
      <div class="checkbox-btn-margin"></div>
    </div>
  </maia-checkbox-group>
  <maia-divider></maia-divider>
  <div class="header-info m-b-16">
    <label class="label-font" i18n="@@nje-b2b-frontend-casco-riskques-label-title">
      Risk Questions
    </label>
    <a
      id="maia-action-icon-4"
      maiaDocumentAction
      rel="noopener noreferrer"
      href="https://www.kbc.be/particulieren/nl.html"
      target="_blank"
      i18n="@@nje-b2b-frontend-casco-riskque-hyperlink-help"
      >Help</a
    >
  </div>

  <maia-label
    i18n="@@nje-b2b-frontend-casco-riskque-text-addsecuredevice"
    class="label-font sec-que"
  >
    Are you adding any Security device?
  </maia-label>
  <maia-radio-group class="njf-radio-size" name="secRadio" formControlName="secRadio" horizontal>
    <njf-radio-button value="With Device" i18n="@@nje-b2b-frontend-casco-riskque-radio1-withdevice">
      With Device</njf-radio-button
    >
    <njf-radio-button
      value="No Device"
      i18n="@@nje-b2b-frontend-casco-riskque-radio2-withoutdevice"
    >
      No Device</njf-radio-button
    >
  </maia-radio-group>

  <maia-divider></maia-divider>

  <div class="calculate-button">
    <button
      i18n="@@nje-b2b-frontend-casco-button-calculate"
      maiaButton
      maiaButtonType="primary"
      (click)="saveAndContinue()"
      >Calculate</button
    >
  </div>
</form>
