<form maiaForm [formGroup]="DocumentSlideinForm">
  <maia-form-group>
    <maia-label
      class="b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-resource-admin-usertype"
    >
      User Type
    </maia-label>
    <maia-form-element hideOptionalIndicator class="slidein-input-column">
      <maia-input-group>
        <input
          class="box-size b2b-kdl-font-small"
          maiaInput
          formControlName="userTypes"
          [(ngModel)]="selectedUsers"
          placeholder="Select user type"
          i18n-placeholder="@@nje-b2b-frontend-resource-admin-selectuser"
        />
        <button
          maiaButton
          class="selectBtn b2b-kdl-font-extra-small"
          maiaButtonType="tertiary"
          (click)="openUsertype()"
          >{{ selectedUsers ? edit : select }}</button
        >
      </maia-input-group>
    </maia-form-element>
    <maia-label
      class="b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-resource-admin-title"
    >
      Title
    </maia-label>
    <maia-form-element
      [options]="{validationInstant: true}"
      hideOptionalIndicator
      class="slidein-input-column"
    >
      <maia-text-area-limit [maxLength]="titleLimit">
        <textarea
          id="title"
          maiaTextArea
          [atlasMaxLength]="titleLimit"
          class="title-desc-input"
          name="title"
          i18n-placeholder="@@nje-b2b-frontend-resource-library-enterdoctitle"
          placeholder="Enter document title"
          formControlName="title"
        >
        </textarea>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-library-product-titlereq"
          *ngIf="
            DocumentSlideinForm?.controls['title'].touched &&
            DocumentSlideinForm?.controls['title'].hasError('required')
          "
        >
          Title required
        </maia-validation>
      </maia-text-area-limit>
    </maia-form-element>
    <maia-label
      class="b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-resource-library-product-desc"
    >
      Description
    </maia-label>
    <maia-form-element
      [options]="{validationInstant: true}"
      hideOptionalIndicator
      class="slidein-input-column"
    >
      <maia-text-area-limit [maxLength]="descriptionLimit">
        <textarea
          id="description"
          maiaTextArea
          [atlasMaxLength]="descriptionLimit"
          class="title-desc-input b2b-kdl-font-small"
          name="description"
          type="text"
          i18n-placeholder="@@nje-b2b-frontend-resource-library-enterdocdesc"
          placeholder="Enter document description"
          formControlName="description"
        >
        </textarea>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-library-product-descreq"
          *ngIf="
            DocumentSlideinForm.controls['description'].touched &&
            DocumentSlideinForm.controls['description'].hasError('required')
          "
        >
          Description required
        </maia-validation>
      </maia-text-area-limit>
    </maia-form-element>
  </maia-form-group>
  <hermes-file-uploader-tile
    class="_uploaderBtn b2b-kdl-font-small slidein-input-column"
    name="file-uploader-basic"
    formControlName="fileUploader"
    [(ngModel)]="uploadedFilesBasic"
    [slideInConfig]="slideInConfig"
    [filesToUpload]="filesToUpload"
    (click)="updateFlag(documents)"
  >
  </hermes-file-uploader-tile>
</form>
<div class="doc-form-button-bar">
  <maia-button-group *ngIf="isNewDocumentList()">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="isSubmitButtonDisabled()"
      (click)="addOrUpdateDocumentList()"
      i18n="@@nje-b2b-frontend-resource-admin-update"
      >Update</button
    >

    <button
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-contacts-cancel"
      >Cancel</button
    >
  </maia-button-group>

  <maia-button-group *ngIf="!isNewDocumentList()">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="isSubmitButtonDisabled()"
      (click)="addOrUpdateDocumentList()"
      i18n="@@nje-b2b-frontend-resource-library-product-add"
      >Add</button
    >

    <button
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-product-cancel"
      >Cancel</button
    >
  </maia-button-group>
</div>
