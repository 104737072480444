<maia-price-block amount="200" currency="BGN">
  <svg class="icon-white-color" b2bFrontendTransportationExprCarNew maia-price-icon></svg>
  <div maia-price-title>Casco Insurance</div>
  <div maia-price-subtitle>Total Premium Amount with Tax</div>
</maia-price-block>

<div class="grey-boxes-div">
  <label class="b2b-kdl-font-small title-dark-color text"> Vehicle Details</label>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationIndiVehicleLicensePlate></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Vehicle Registration Number'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> H9357BC </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Vehicle Identification Number'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 892384579 </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <div></div>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprTransporter></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Type of Vehicle'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 3 </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprCar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Subtype of Vehicle'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 6 </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesFuncFactory></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Make'">
          <label class="b2b-kdl-font-small title-dark-color"> Nissan </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprCarNew></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Model'">
          <label class="b2b-kdl-font-small title-dark-color">Juke Tekna</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprHayMaker></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Usage'">
          <label class="b2b-kdl-font-small title-dark-color">1</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncClock></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Registration Type'"
        >
          <label class="b2b-kdl-font-small title-dark-color">Registration 1</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendSocialFuncPeopleDuo></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Date of Registration'"
        >
          <label class="b2b-kdl-font-small title-dark-color">26-05-2018</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Production Year'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 2017 </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Production Type'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Production </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationFuncWheel></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Tonnage'">
          <label class="b2b-kdl-font-small title-dark-color">3.8</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprCarCrash></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Carbody Type'"
        >
          <label class="b2b-kdl-font-small title-dark-color">5 Door SUV</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprWheel></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Steering Position'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Left </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationIndiVehicleOrdered></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Armored Vehicle'"
        >
          <label class="b2b-kdl-font-small title-dark-color">No</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Vehicle Registration in 30 days'"
        >
          <label class="b2b-kdl-font-small title-dark-color">Yes</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Drive Type'">
          <label class="b2b-kdl-font-small title-dark-color">Drive</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendDevicesIndiMobileFuel></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Fuel Type'">
          <label class="b2b-kdl-font-small title-dark-color">Petrol</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendFileFuncNewspaper></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Mileage per year'"
        >
          <label class="b2b-kdl-font-small title-dark-color">1244</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Engine Type'"
        >
          <label class="b2b-kdl-font-small title-dark-color"
            >Turbocharged 1.6 lt four cylinder</label
          >
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Engine Power'"
        >
          <label class="b2b-kdl-font-small title-dark-color">117 Hp / 87.2469 Kw</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendDevicesFuncMobile></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Gear Box'">
          <label class="b2b-kdl-font-small title-dark-color">Automatic</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Seats Number'"
        >
          <label class="b2b-kdl-font-small title-dark-color">5</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendNavigationFuncArrow></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Door Number'"
        >
          <label class="b2b-kdl-font-small title-dark-color">4</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'CCM'">
          <label class="b2b-kdl-font-small title-dark-color">Automatic</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Color'">
          <label class="b2b-kdl-font-small title-dark-color">Black</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Modification'"
        >
          <label class="b2b-kdl-font-small title-dark-color">Combi 2.0 TDi CR Active</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
  <div></div>
  <div></div>
</div>

<div class="grey-boxes-div p-t-8">
  <label class="m-r-16 text-color b2b-kdl-font-small">Sum Insured</label>
  <button class="text-size" maiaButton maiaButtonType="tertiary">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationExprInvoice></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Vehicle Sum Insured Value'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 600 BGN </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <div></div>
    <div></div>
  </maia-fluid-fluid-layout>

  <maia-table class="maia-table">
    <maia-table-header>
      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color">
          Type of Equipment
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color">Make</maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color">
          Price as New
        </maia-table-cell>
      </maia-table-row>
    </maia-table-header>
    <maia-table-body>
      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small table-body-color"
          >Xenon Headlight</maia-table-cell
        >
        <maia-table-cell class="b2b-kdl-font-small table-body-color"
          >VisionTech VT 2.0
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small table-body-color">20 BGN</maia-table-cell>
      </maia-table-row>

      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small table-body-color"
          >Aluminium Wheels</maia-table-cell
        >
        <maia-table-cell class="b2b-kdl-font-small table-body-color">
          WheelBase WBXYRHD
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small table-body-color">150 BGN</maia-table-cell>
      </maia-table-row>

      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small table-body-color"
          >Xenon Headlight</maia-table-cell
        >
        <maia-table-cell class="b2b-kdl-font-small table-body-color">
          UltraVision X73
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small table-body-color">200 BGN</maia-table-cell>
      </maia-table-row>

      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small table-body-color">Audio Audio</maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small table-body-color"> Sony S96 </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small table-body-color">200 BGN</maia-table-cell>
      </maia-table-row>

      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small table-body-color">Video Video</maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small table-body-color"> LG D38 </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small table-body-color">500 BGN</maia-table-cell>
      </maia-table-row>
      <maia-divider class="m-t-3"></maia-divider>
    </maia-table-body>
  </maia-table>
</div>

<div class="grey-boxes-div p-t-8">
  <label class="m-r-16 text-color b2b-kdl-font-small">Owner Details</label>
  <button class="text-size" maiaButton maiaButtonType="tertiary">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Sithi Nadesh Kumar'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Сити Надеш Кумар </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesExprHouseTree></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'City & Zip'">
          <label class="b2b-kdl-font-small title-dark-color"> Sofia 100 </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendCommunicationFuncMail></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Street and Number'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Deseta Str. 51B </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-boxes-div p-t-8">
  <label class="m-r-16 text-color b2b-kdl-font-small">Insuring Party</label>
  <button class="text-size" maiaButton maiaButtonType="tertiary">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Insuring Party'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Kaavya Shakunthala </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Lesse'">
          <label class="b2b-kdl-font-small title-dark-color"> Balaji Chandran </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Driver'">
          <label class="b2b-kdl-font-small title-dark-color"> Hemalatha Janakiraman </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-boxes-div p-t-8">
  <label class="m-r-16 text-color b2b-kdl-font-small">Covers</label>
  <button class="text-size" maiaButton maiaButtonType="tertiary">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Bonus Malus Type'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Bonus Malus Type </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprTruckShield></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Bonus Malus Level'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Bonus Malus Level </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <div></div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Policy Start date and Time'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 30-07-2021 : 12:46</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprTruckShield></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Policy End Date and Time'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 29-07-2022 : 23:59 </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprTruckShield></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Installment Type'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Single </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Policy Duration'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> One year</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Covers'">
          <label class="b2b-kdl-font-small title-dark-color">
            Full Casco + RA Premium + Abroad</label
          >
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Add ons'">
          <label class="b2b-kdl-font-small title-dark-color"> Two</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Liquidation Conditions'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> Official Garage</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Advance Bonus'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> No </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <div></div>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-boxes-div p-t-8">
  <label class="m-r-16 text-color b2b-kdl-font-small">Discounts and Loadings</label>
  <button class="text-size" maiaButton maiaButtonType="tertiary">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color">
          <label class="b2b-kdl-font-small title-dark-color"> Discount </label>
        </maia-label>

        <maia-additional-information
          openLinkText="View more"
          closeLinkText="View less"
          maiaContentType="content"
          collapsible
        >
          <div class="m-t--13">
            <p>
              Valid MTPL discount-5% <br />
              Motor vehicle owned by person above 45yrs old - 2%<br />
            </p>
          </div>
        </maia-additional-information>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color">
          <label class="b2b-kdl-font-small title-dark-color"> Loadings </label>
        </maia-label>

        <maia-additional-information
          openLinkText="View more"
          closeLinkText="View less"
          maiaContentType="content"
          collapsible
        >
          <div class="m-t--13">
            <p>
              Risky Makes and Models <br />
              Earthquake Risk Coverage - 5% <br />
            </p>
          </div>
        </maia-additional-information>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-boxes-div p-t-8">
  <label class="m-r-16 text-color b2b-kdl-font-small">Premium</label>
  <button class="text-size" maiaButton maiaButtonType="tertiary">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="' Tariff'">
          <label class="b2b-kdl-font-small title-dark-color"> 1000 Fixed Premium </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="' Instalment Type'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 4 Instalment </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="' Every 3 Months'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 50,00 BGN </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="' Total Premium Amount'"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 200 BGN </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<maia-divider class="m-t-b-24"></maia-divider>

<div class="button-align m-l-24">
  <button maiaButton maiaButtonType="primary" class="button1-size m-r-24" (click)="onClick()"
    >Create Policy</button
  >
  <button maiaButton maiaButtonType="secondary" (click)="onClick()" class="m-r-24 button2-size"
    >Save Summary</button
  >
  <button maiaButton class="button3-size" maiaButtonType="secondary" (click)="openEmailPopUp()"
    >Email Summary</button
  >
</div>
