export enum RoutePaths {
  Auth = 'auth/',
  ChangePassword = 'change-password',
  Dashboard = 'dashboard',
  Login = 'login',
  Success = 'success',
  TwoFa = 'two-fa',
  SetupGoogle = 'setup-google',
  VerifyAuth = 'verify-auth',
  VerifyScan = 'scan',
}
