import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprTruckShieldSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationExprTruckShield]',
  template: '<svg:path d="M38.38 7.62l-5.58 2.44c0 3.9 1.67 7.89 5.58 9.56C42.29 18 44 14 44 10.06z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M36.21 13.07l2.13 2.19 2.76-2.83" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M29.08 35.62H17.45"></svg:path><svg:path d="M41.53 24v11.62h-4.15" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M27.42 14.87h2.08"></svg:path><svg:path d="M9.15 35.62H5V8.22h22.42v19.1" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:circle cx="33.23" cy="35.62" r="4.15" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle><svg:circle cx="13.3" cy="35.62" r="4.15" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle><svg:path d="M32.64 18.82V24h5.18" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprTruckShieldSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprTruckShieldSvgComponent,
  ],
  exports: [
    TransportationExprTruckShieldSvgComponent,
  ],
})
export class TransportationExprTruckShieldSvgModule {}
