import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {StatisticsReportingComponent} from './statistics-reporting.component';

const lang: string | null = sessionStorage.getItem('lang');

const routes: Routes = [
  {
    path: '',
    component: StatisticsReportingComponent,
    children: [
      {
        path: '',
        component: StatisticsReportingComponent,
        data: {
          njeB2BTitle: new localeValPipe(new LocalizationService()).transform(
            'Statistics reporting',
            lang,
          ),
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StatisticsReportingRoutingModule {}

export const CMS_STATISTICS_REPORTING_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./statistics-reporting.module').then(module => module.StatisticsReportingModule),
};
