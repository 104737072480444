import {NgModule} from '@angular/core';
import {RouterModule, Routes, Route} from '@angular/router';
import {PropertyRoutePaths} from '@b2b-frontend/core';
import {StepResolverService} from './property-core/services/step.resolver';
import {PropertyInsuranceComponent} from './property-insurance.component';
import {ConfirmationComponent} from './screens/confirmation/confirmation.component';
import {PropertyCoversAndDiscountsComponent} from './screens/property-covers-and-discounts/property-covers-and-discounts.component';
import {PropertyDetailsComponent} from './screens/property-details/property-details.component';
import {PropertyInsuringPartyComponent} from './screens/property-insuring-party/property-insuring-party.component';
import {PropertyPolicyDetailsComponent} from './screens/property-policy-details/property-policy-details.component';
import {PropertyProposalComponent} from './screens/property-proposal/property-proposal.component';
import {PropertySupplementaryListComponent} from './screens/property-supplementary-list/property-supplementary-list.component';
import {PropertyPolicySuccessComponent} from './screens/property-policy-success/property-policy-success.component';
import {PropertyPolicyErrorComponent} from './screens/property-policy-error/property-policy-error.component';
import {PROPERTY} from './constants/property-insurance-constants';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: PropertyRoutePaths.PROPERTY,
    pathMatch: 'full',
  },
  {
    path: PropertyRoutePaths.PROPERTY,
    component: PropertyInsuranceComponent,
    data: {
      njeB2BTitle: PROPERTY,
    },
    children: [
      {
        path: '',
        redirectTo: PropertyRoutePaths.PROPERTY_POLICY_DETAILS,
        pathMatch: 'full',
      },
      {
        path: PropertyRoutePaths.PROPERTY_POLICY_SUCCESS,
        component: PropertyPolicySuccessComponent,
      },
      {
        path: PropertyRoutePaths.PROPERTY_POLICY_ERROR,
        component: PropertyPolicyErrorComponent,
      },
      {
        path: PropertyRoutePaths.PROPERTY_POLICY_DETAILS,
        component: PropertyPolicyDetailsComponent,
        data: {
          stepId: PropertyRoutePaths.PROPERTY_POLICY_DETAILS,
          maiaFlowProgressId: PropertyRoutePaths.PROPERTY_POLICY_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: PropertyRoutePaths.PROPERTY_INSURING_PARTY,
        component: PropertyInsuringPartyComponent,
        data: {
          stepId: PropertyRoutePaths.PROPERTY_INSURING_PARTY,
          maiaFlowProgressId: PropertyRoutePaths.PROPERTY_INSURING_PARTY,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: PropertyRoutePaths.PROPERTY_DETAILS,
        component: PropertyDetailsComponent,
        data: {
          stepId: PropertyRoutePaths.PROPERTY_DETAILS,
          maiaFlowProgressId: PropertyRoutePaths.PROPERTY_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: PropertyRoutePaths.PROPERTY_COVERS_AND_DISCOUNTS,
        component: PropertyCoversAndDiscountsComponent,
        data: {
          stepId: PropertyRoutePaths.PROPERTY_COVERS_AND_DISCOUNTS,
          maiaFlowProgressId: PropertyRoutePaths.PROPERTY_COVERS_AND_DISCOUNTS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: PropertyRoutePaths.PROPERTY_SUPPLEMENTARY_LIST,
        component: PropertySupplementaryListComponent,
        data: {
          stepId: PropertyRoutePaths.PROPERTY_SUPPLEMENTARY_LIST,
          maiaFlowProgressId: PropertyRoutePaths.PROPERTY_SUPPLEMENTARY_LIST,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: PropertyRoutePaths.PROPERTY_PROPOSAL,
        component: PropertyProposalComponent,
        data: {
          stepId: PropertyRoutePaths.PROPERTY_PROPOSAL,
          maiaFlowProgressId: PropertyRoutePaths.PROPERTY_PROPOSAL,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: PropertyRoutePaths.CONFIRMATION,
        component: ConfirmationComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class PropertyInsuranceRoutingModule {}

export const PROPERTY_INSURANCE_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./property-insurance.routing.module').then(
      module => module.PropertyInsuranceRoutingModule,
    ),
};
