import {LanguageInterface} from './contactsInterface';

export interface NewsEvents {
  articleCategory?: number;
  typeId?: number;
  categoryId?: number;
  category?: IdInterface;
  createdAt?: string;
  eventEndDate?: string;
  eventStartDate?: string;
  gallery?: any[];
  i18n?: ArticleLanguageInterface;
  id?: number;
  pinEndDate?: string;
  pinStartDate?: string;
  pinned?: boolean;
  publishStartDate?: string;
  published?: boolean;
  type?: IdInterface;
  updatedAt?: string;
  userTypes?: any[];
  eventStartTime?: string;
  eventEndTime?: string;
  title?: string;
  description?: string;
  location?: string;
  organizer?: string;
  addMediaFile?: string;
  scheduleLater?: boolean;
  dateOfPublishing?: string;
  timeOfPublishing?: string;
  disableArticle?: boolean;
  publishedDate?: number;
  profileImageValue?: any;
}

export interface ArticleLanguageInterface {
  bg?: LanguageValuesInterface;
  en?: LanguageValuesInterface;
}

export interface LanguageValuesInterface {
  title?: string;
  description?: string;
  location?: string;
  organizer?: string;
}

export interface IdInterface {
  id?: number;
}

export interface Category {
  id?: number;
  category_name?: string;
}

export interface UserType {
  id?: number;
  userTypeId?: string;
  i18n?: ArticleLanguageInterface;
}

export interface SlideInOutput {
  filterData: string;
}

export interface FilterInputInterface {
  newsEvents?: NewsEvents[];
  editFilter?: string;
  categoryList?: CategoryInterface[];
  userTypeList?: UserType[];
}

export interface CategoryInterface {
  id?: number;
  i18n?: LanguageInterface;
}

export interface ArticleFileUploadResponse {
  id?: string;
  name?: string;
  type?: string;
  action?: string;
  imageSide?: string;
}

export interface ArtticleMediaType {
  id?: number;
  filename?: string;
  type?: string;
  content?: string;
}

export interface PopUpContent {
  title: string;
  message: string;
  footerNote: string;
}

export interface PopUpContent {
  title: string;
  message: string;
  footerNote: string;
}

export interface PopUpContent {
  title: string;
  message: string;
  footerNote: string;
}

export interface filterResponseInterface {
  userTypeList: UserType[];
  categoryList: CategoryInterface[];
}

export interface PopUpContent {
  title: string;
  message: string;
  footerNote: string;
}

export interface ImagePreviewInterface {
  id: number;
  image: any;
  type: string;
}

export enum Time {
  Hour = 'hour',
  Minute = 'minute',
}
