<form maiaForm [formGroup]="form" (submit)="onContinue()">
  <div>
    <h2 class="header-style b2b-kdl-font-grand-serif">Select an option</h2>
    <div class="container">
      <maia-contextual-message type="informative" class="full-width">
        If you want to make combined MTPL and CASCO insurance, Proceed to
        <a href="" class="link">continue</a> here</maia-contextual-message
      >
    </div>
    <br />
    <div class="container">
      <div>
        <a
          id="maia-action-icon-1"
          maiaDocumentAction
          rel="noopener noreferrer"
          href="./"
          target="_blank"
          class="link-button"
          >IPID Document</a
        >
      </div>
      <div class="rightBox">
        <a
          id="maia-action-icon-2"
          maiaDocumentAction
          rel="noopener noreferrer"
          href="./"
          target="_blank"
          class="link-button"
          >Read More</a
        >
      </div>
    </div>
    <div class="container">
      <div class="box1">
        <br />
        <maia-form-group id="form-group-1">
          <maia-form-element
            [options]="{validationInstant: true}"
            class="input-column"
            label="Vehicle Registration Number"
          >
            <input maiaInput formControlName="vehicleRegistrationNumber" placeholder="XX0000XX" />
            <maia-validation
              *ngIf="
                form.controls['vehicleRegistrationNumber'].dirty &&
                !form.controls['vehicleRegistrationNumber'].value
              "
            >
              Please enter the vehicle registration number
            </maia-validation>
            <maia-validation
              *ngIf="form.controls['vehicleRegistrationNumber'].errors?.invalidNumber"
            >
              Please enter a valid Vehicle Registration Number
            </maia-validation>
            <maia-validation
              *ngIf="
                form.controls['vehicleRegistrationNumber'].errors?.valid &&
                !form.controls['vehicleRegistrationNumber'].errors?.invalidNumber
              "
            >
              Vehicle Registration number is incorrect
            </maia-validation>
          </maia-form-element>
        </maia-form-group>
        <div class="line-break"></div>
        <maia-form-group id="form-group-2">
          <div>
            <maia-form-element [options]="{validationInstant: true}" class="input-column">
              <div>
                <label class="b2b-kdl-font-small title-dark-color">
                  Vehicle Certificate Number
                </label>
              </div>

              <div><a class="ahref-link"> Where can I find this number?</a></div>
              <input maiaInput formControlName="certificateNumber" placeholder="000000000" />
              <maia-validation
                *ngIf="
                  form.controls['certificateNumber'].dirty &&
                  !form.controls['certificateNumber'].value
                "
              >
                Please enter the number of Vehicle Registration Certificate (coupon)
              </maia-validation>
            </maia-form-element>
          </div>
        </maia-form-group>
      </div>
      <div class="box2">
        <div class="verticalDivider">
          <maia-divider vertical></maia-divider>
        </div>
        <div>
          <maia-label>Or</maia-label>
        </div>
        <div style="margin-top: 5%" class="verticalDivider">
          <maia-divider vertical></maia-divider>
        </div>
      </div>
      <div class="box3">
        <div class="scan-talon-div" (click)="openTalonSlideIn()">
          <maia-tile class="scan-talon-tile">
            <svg b2bFrontendDevicesIndiQrScan maia-tile-icon class="scan-icon"></svg>
            <span maia-tile-label class="kdl-font-normal" class="p-t-20px">Scan Talon</span>
          </maia-tile>
        </div>
      </div>
    </div>
    <br />
    <div>
      <maia-label class="b2b-kdl-font-small b2b-kdl-color-primary-dark">
        If the vehicle is not registered in the traffic police, Proceed to
        <a (click)="navigateToVehicle()" class="link">continue</a>
        here
      </maia-label>
    </div>
    <maia-divider class="m-t-b-24"></maia-divider>
    <div class="container" *ngIf="mismatchData">
      <maia-contextual-message type="error" class="full-width"
        >Vehicle Registration Number and Vehicle Certificate Number is not matching. Please enter
        correct details.</maia-contextual-message
      >
    </div>

    <button
      md-button
      class="m-t-b-24"
      id="continueBtn"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!form.valid"
    >
      Continue</button
    >
  </div>
</form>
