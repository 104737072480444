import {Component, ComponentFactory, ComponentFactoryResolver, Injector} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Decimal} from '@atlas/businesstypes';
import {BLANK_VALUE, LabelEditDto} from '@b2b-frontend/core';
import {PopUpController} from '@maia/pop-ups';

import {ScreenFormComponent} from '@njf-frontend-angular/flow-progress';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';
import {EmailPopupComponent} from '../email-popup/email-popup.component';

@Component({
  selector: 'nje-price-offer-proposal',
  templateUrl: './price-offer-proposal.component.html',
  styleUrls: ['./price-offer-proposal.component.scss'],
})
@UntilDestroy()
export class PriceOfferProposalComponent extends ScreenFormComponent {
  public clicked: boolean = false;
  private readonly _emailPopupFactory: ComponentFactory<EmailPopupComponent>;

  public constructor(
    route: ActivatedRoute,
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _process: CascoProcessService,
  ) {
    super(route);
    this._emailPopupFactory = componentFactoryResolver.resolveComponentFactory(EmailPopupComponent);
  }

  public editScreen(screenDetail: LabelEditDto): void {
    const step = this._process.getStepByRoute(screenDetail.navigationPath);
    if (step) {
      step.edit(this.step);
    }
  }

  public getAmoutValue(amount: string): Decimal {
    return new Decimal(amount);
  }

  // To do
  public onClick(): void {
    this.clicked = true;
  }

  public openEmailPopUp(): void {
    this._popUpCtrl
      .prepare(
        this._emailPopupFactory,
        this._injector,
        {title: BLANK_VALUE, size: 'large'},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  protected saveMtplData(): void {
    this.clicked = true;
  }
}
