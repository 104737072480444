import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PaymentsProcessService} from '../../payments-core/services/payments-process.service';
import {filter, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {StepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {PaymentsSessionService} from '../../payments-core/services/payments-session.service';
import {Amount} from '../../contracts/instalment-payment/instalment';
import {MOCK_PAYMENT_AMOUNT, PAYMENT_AMOUNT_TITLE} from '../../constants/constant';

@Component({
  selector: 'nje-b2b-instalment-payments',
  templateUrl: './instalment-payments.component.html',
  styleUrls: ['./instalment-payments.component.scss'],
})
@UntilDestroy()
export class InstalmentPaymentsComponent implements OnInit {
  public isStepperVisible: boolean = true;
  public steps: Observable<StepperProcessStep[]> = this._process.onStepperWorkflowSteps;
  public isEditing: Observable<boolean> = this._process.stepEditing;
  public totalPaymentAmount: Amount = MOCK_PAYMENT_AMOUNT;
  public title: string = PAYMENT_AMOUNT_TITLE;

  public constructor(
    private readonly _process: PaymentsProcessService,
    private readonly _router: Router,
    public route: ActivatedRoute,
    public paymentsSessionService: PaymentsSessionService,
  ) {}

  public ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        startWith(null),
        map(() => {
          let currentRoute = this.route;
          while (currentRoute.firstChild != null) {
            currentRoute = currentRoute.firstChild;
          }
          let currentRouteS = currentRoute.snapshot;
          while (currentRouteS.parent != null) {
            if ('isStepperVisible' in currentRouteS.data) {
              return currentRouteS.data.isStepperVisible as boolean;
            }
            currentRouteS = currentRouteS.parent;
          }
          return true;
        }),
      )
      .subscribe((val: boolean) => (this.isStepperVisible = val));
  }
}
