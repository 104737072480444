import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {
  StepperProcessService as StepperProcessServiceBase,
  StepperProcessStep,
} from '@njf-frontend-angular/flow-progress';

import {CascoWorkflow} from '../casco-workflow/CascoWorkflow';
import {steps} from '../casco-workflow/steps';
import {workflows} from '../casco-workflow/workflows';

const CASCO_SESSION_KEY = 'casco-process';

@Injectable({
  providedIn: 'root',
})
export class CascoProcessService
  extends StepperProcessServiceBase<CascoWorkflow>
  implements OnDestroy
{
  public constructor(router: Router) {
    super(router, steps, workflows, CascoWorkflow.QUICK_QUOTE, CASCO_SESSION_KEY);
  }

  protected getWorkflowStepsFromWorkflowAndStep(
    wf: CascoWorkflow,
    step: StepperProcessStep,
  ): StepperProcessStep[] {
    return this.getWorkflowSteps(wf);
  }

  protected getCurrentWorkflowStepsList(): string[] {
    return this.workflows[this.currentWorkflow];
  }
}
