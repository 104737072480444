export interface PersonsAdded {
  id: number;
  name: string;
}

export const MOCK_PERSONS_ADDED: PersonsAdded[] = [
  {
    id: 1,
    name: 'Vehicle Owner',
  },
  {
    id: 2,
    name: 'Insuring Party',
  },
];
