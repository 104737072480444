import {PhoneNumberValue} from '@maia/input-phonenumber';
import {Date as Date_2} from '@atlas/businesstypes';
import {City} from '@njf-frontend-angular/flow-progress';

export interface PeopleDetailsRelatedParty {
  id: number;
  partyName: string;
}
export const MOCK_RELATED_PARTY: PeopleDetailsRelatedParty[] = [
  {
    id: 1,
    partyName: 'New Owner',
  },
  {
    id: 2,
    partyName: 'Lessee',
  },
];

export interface RelatedParty {
  relatedParty: string;
  idNumber: string;
  firstName: string;
  middleName: string;
  lastName: string;
  city: City;
  postCode: string;
  quarter: string;
  street: string;
  streetNumber: string;
  block: string;
  entrance: string;
  floor: string;
  apartment: string;
  companyName: string;
  citizenship: string;
  dob: Date_2;
  phoneNumber: PhoneNumberValue;
  emailId: string;
}

export interface CityInterface {
  city?: string;
  zip?: string;
}
