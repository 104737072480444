import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FlagUtilBelgiumSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFlagUtilBelgium]',
  template: '<svg:defs><svg:path id="svgDhV1Xo+JrtHAqMlyzZ1y7I2a0JCLM54tNwUg2IKZzFw=" d="M0 0h48v48H0z"></svg:path></svg:defs><svg:clipPath id="svg27CXn+xXVYPdnMXsJPA9ad83u4WV5JJdvozHwJrV3a4="><svg:use href="#svgDhV1Xo+JrtHAqMlyzZ1y7I2a0JCLM54tNwUg2IKZzFw=" overflow="visible"></svg:use></svg:clipPath><svg:g clip-path="url(#svg27CXn+xXVYPdnMXsJPA9ad83u4WV5JJdvozHwJrV3a4=)"><svg:path fill="#FFFFFF" d="M0 0h48v48H0z"></svg:path><svg:path fill="#FFDA44" d="M0 0h48v48H0z"></svg:path><svg:path d="M0 0h16v48H0z"></svg:path><svg:path fill="#D80027" d="M32 0h16v48H32z"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'xlink': 'http://www.w3.org/1999/xlink',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagUtilBelgiumSvgComponent {
}

@NgModule({
  declarations: [
    FlagUtilBelgiumSvgComponent,
  ],
  exports: [
    FlagUtilBelgiumSvgComponent,
  ],
})
export class FlagUtilBelgiumSvgModule {}
