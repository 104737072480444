import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {MOCK_TRAVEL_SAVEOFFER_LIST} from './__tests__/travel-proposal-slidein.mock';

@Component({
  selector: 'nje-travel-proposal-slidein',
  templateUrl: './travel-proposal-slidein.component.html',
  styleUrls: ['./travel-proposal-slidein.component.scss'],
})
export class TravelProposalSlideinComponent extends ModalContentComponent<void> {
  public constructor(public control: ModalControl<void>) {
    super();
  }

  public documents: string[] = MOCK_TRAVEL_SAVEOFFER_LIST;
}
