import {Component, AfterViewInit, ViewChild} from '@angular/core';
import {ModalControl, ModalContentComponent} from '@maia/modals';
import {
  InsurancePartyDetailsComponent,
  InsurancePartyDetails,
  PartyType,
  FALSE,
  TRUE,
} from '@b2b-frontend/core';
import {FormGroupManager} from '@njf-frontend-angular/flow-progress';
import {InsuredPartyListDetails} from '../../contracts/property-insurance.interface';

@Component({
  selector: 'nje-property-insured-party-slidein',
  templateUrl: './property-insured-party-slidein.component.html',
  styleUrls: ['./property-insured-party-slidein.component.scss'],
})
export class PropertyInsuredPartySlideinComponent
  extends ModalContentComponent<InsuredPartyListDetails, InsuredPartyListDetails>
  implements AfterViewInit
{
  public flexDirection: string = 'column';
  public insuringPartyType: PartyType | undefined;
  public insurancePartyDetailsForm: FormGroupManager<InsurancePartyDetails>;
  public insurancePartyDetailsList: InsurancePartyDetails[];
  public index: number;

  @ViewChild(InsurancePartyDetailsComponent)
  public insurancePartyDetailsComponent: InsurancePartyDetailsComponent;

  public constructor(
    public control: ModalControl<InsuredPartyListDetails, InsuredPartyListDetails>,
  ) {
    super();
  }

  public ngAfterViewInit(): void {
    const data = this.control.input;
    if (null != data) {
      this.index = data.index;
      this.insurancePartyDetailsList = data.insurancePartyDetailsList;

      if (this.index >= 0) {
        const insurancePartyDetails: InsurancePartyDetails =
          this.insurancePartyDetailsList[this.index];
        this.insurancePartyDetailsComponent.setInsurancePartyDetails(insurancePartyDetails);
      }
    }
  }

  public addInsuranceData(): boolean {
    return 0 > this.control?.input?.index;
  }

  public cancel(): void {
    this.control.cancel();
  }

  public isInsurancePartyInfoValid(): boolean {
    if (null != this.insurancePartyDetailsComponent) {
      this.insurancePartyDetailsForm =
        this.insurancePartyDetailsComponent.getInsurancePartyDetails();
      return this.insurancePartyDetailsForm.valid;
    } else return FALSE;
  }

  public saveInsuranceData(): void {
    if (null != this.insurancePartyDetailsComponent) {
      this.insurancePartyDetailsForm =
        this.insurancePartyDetailsComponent.getInsurancePartyDetails();
    }
    const insurancePartyDetails: InsurancePartyDetails = this.insurancePartyDetailsForm?.value;

    const insuredPartyListDetails: InsuredPartyListDetails = {
      insurancePartyDetailsList: this.insurancePartyDetailsList,
      index: this.index,
    };

    if (this.index >= 0) {
      this.insurancePartyDetailsList[this.index] = insurancePartyDetails;
      this.control.confirm(insuredPartyListDetails);
    } else {
      const isIdNumberAdded = this.insurancePartyDetailsList.some(
        insurancePartyDetails =>
          insurancePartyDetails.idNumber === this.insurancePartyDetailsForm.controls.idNumber.value,
      );
      if (isIdNumberAdded) {
        this.insurancePartyDetailsComponent.isIdNumberAdded = TRUE;
      } else {
        this.insurancePartyDetailsList.push(insurancePartyDetails);
        this.control.confirm(insuredPartyListDetails);
      }
    }
  }
}
