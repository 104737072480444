import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {ConnectorModule, PATH_RESOLVER_TOKEN} from '@atlas-angular/connector';
import {LoggerModule} from '@atlas-angular/logger';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {AuthModule} from '@b2b-frontend-flows/auth';
import {CascoModule} from '@b2b-frontend-flows/casco';
import {B2bCmsModule} from '@b2b-frontend-flows/cms';
import {DashbordModule} from '@b2b-frontend-flows/dashboard';
import {InstalmentPaymentsModule} from '@b2b-frontend-flows/instalment-payments';
import {PropertyInsuranceModule} from '@b2b-frontend-flows/property-insurance';
import {TravelInsuranceModule} from '@b2b-frontend-flows/travel-insurance';
import {UamModule} from '@b2b-frontend-flows/uam';
import {ZakrilaModule} from '@b2b-frontend-flows/zakrila';
import {B2bCoreModule, BackendConfiguration, PathResolver} from '@b2b-frontend/core';
import {
  ConfigurationModule as HermesConfigurationModule,
  provideConfiguration,
} from '@hermes/configuration';
import {CoreModule as HermesCoreModule} from '@hermes/core';
import {CoreServicesModule as MaiaCoreServicesModule} from '@maia/core';
import {ModalsModule as MaiaModalsModule} from '@maia/modals';

import {environment} from '../environments/environment';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routing.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // ANGULAR
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    // MAIA MODULES
    MaiaCoreServicesModule.forRoot(),
    MaiaModalsModule,
    // Setup Atlas
    LoggerModule,
    ConnectorModule,
    //Hermes Modules
    HermesCoreModule.forCompany(environment.company),
    HermesConfigurationModule,
    //FLOWS
    AuthModule.forPath('auth'),
    TravelInsuranceModule.forPath('travel-insurance'),
    PropertyInsuranceModule.forPath('property-insurance'),
    DashbordModule.forPath('dashboard'),
    B2bCmsModule.forPath('b2b-cms'),
    UamModule.forPath('uam'),
    B2bCoreModule.forRoot(environment),
    CascoModule.forPath('casco'),
    InstalmentPaymentsModule.forPath('payments'),
    ZakrilaModule.forPath('zakrila'),
    //AppRouting
    AppRoutingModule,
  ],
  providers: [
    provideConfiguration('./assets/configuration.json', BackendConfiguration),
    {provide: PATH_RESOLVER_TOKEN, useClass: PathResolver, multi: true},
  ],
  bootstrap: [AppComponent],
})
@UntilDestroy()
export class AppModule {}
