import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprHandPersonSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprHandPerson]',
  template: '<svg:path d="M35.23 30.63L39 28.47a2.64 2.64 0 0 1 3.6 1h0A2.72 2.72 0 0 1 42 33l-7 5.78a3.46 3.46 0 0 1-1.45.71l-13.3 3a3.5 3.5 0 0 1-2.67-.5l-8.25-5.6a3.55 3.55 0 0 0-1.92-.58H5V25.45l6.13-.68a6.91 6.91 0 0 1 3.19.39L19 26.93a6.79 6.79 0 0 0 1.32.36l8.83 1.43a2.79 2.79 0 0 1 2.59 2.78h0a2.78 2.78 0 0 1-2.59 2.77l-9.5.68" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M31 9.61a3.89 3.89 0 0 1 7.78-.39v.39c0 2.14-1.75 4.76-3.89 4.76S31 11.75 31 9.61z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M31.91 15.66a11.3 11.3 0 0 1 3-.41 8.24 8.24 0 0 1 4.44 1.1 2.58 2.58 0 0 1 1.18 2.14v5h-5.59" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M22.26 14.83a3.46 3.46 0 0 1 6.92-.34 2.29 2.29 0 0 1 0 .37c0 1.92-1.55 3.89-3.46 3.89s-3.46-2-3.46-3.92z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M30.91 22a1.71 1.71 0 0 0-1.08-1.6 11.24 11.24 0 0 0-8.23 0 1.71 1.71 0 0 0-1.07 1.6v1.53h10.38z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprHandPersonSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprHandPersonSvgComponent,
  ],
  exports: [
    InformationExprHandPersonSvgComponent,
  ],
})
export class InformationExprHandPersonSvgModule {}
