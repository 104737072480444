import {Pipe, PipeTransform} from '@angular/core';
import {InsuredPerson, BLANK_VALUE} from '@b2b-frontend/core';

@Pipe({
  name: 'TravelInsuredPeopleSearchPipe',
})
export class TravelInsuredPeopleSearchPipe implements PipeTransform {
  public transform(list: InsuredPerson[], filterText: string): any {
    return list
      ? list.filter(policy => {
          return (
            (policy != undefined &&
              policy != null &&
              policy.idNumber != undefined &&
              policy.idNumber != null &&
              `${policy.idNumber}${BLANK_VALUE}`.search(new RegExp(filterText, 'i')) > -1) ||
            (policy != undefined &&
              policy != null &&
              policy.firstName != undefined &&
              policy.firstName != null &&
              policy.firstName.search(new RegExp(filterText, 'i')) > -1) ||
            (policy != undefined &&
              policy != null &&
              policy.middleName != undefined &&
              policy.middleName != null &&
              policy.middleName.search(new RegExp(filterText, 'i')) > -1) ||
            (policy &&
              policy.lastName &&
              policy.lastName.search(new RegExp(filterText, 'i')) > -1) ||
            (policy != undefined &&
              policy != null &&
              policy.firstNameEn != undefined &&
              policy.firstNameEn != null &&
              policy.firstNameEn.search(new RegExp(filterText, 'i')) > -1) ||
            (policy != undefined &&
              policy != null &&
              policy.middleNameEn != undefined &&
              policy.middleNameEn != null &&
              policy.middleNameEn.search(new RegExp(filterText, 'i')) > -1) ||
            (policy &&
              policy.lastNameEn &&
              policy.lastNameEn.search(new RegExp(filterText, 'i')) > -1)
          );
        })
      : [];
  }
}
