<maia-input-phonenumber>
  <maia-phonenumber-country
    [prefix]="country?.prefix"
    [name]="country?.name"
    [id]="country?.name"
    *ngFor="let country of countryPhoneNumberPrefix"
  >
    <ng-template #flag>
      <svg [id]="country?.prefix" b2bFrontendFlagUtilBulgaria></svg>
    </ng-template>
  </maia-phonenumber-country>
</maia-input-phonenumber>
