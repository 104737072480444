import {TravelRenewDetails} from '@b2b-frontend/core/src/core-helper/dto/travel-dto/TravelRenewPolicy';

export const MOCK_RENEW_POLICY_DETAILS: TravelRenewDetails[] = [
  {
    id: 1,
    value: 'new',
  },
  {
    id: 2,
    value: 'existing',
  },
];
