import {TravelCovers} from '@b2b-frontend/core';

const urgentDetails: string = 'Urgent Dental Treatment - up to 400 EUR';
const subTitle: string = 'Avail our cashless treatment facility during a medical emergency.';
const urgentSubTitleDetails: string = 'Treat your dental worries with your cashless treatment.';
const searchSubTitle: string =
  'If caught in an unfortunate event, we will compensate for the distress.';
const coversSubTitle: string =
  'In case of an unfortunate event overseas we can compensate your Family/Nominee.';
const accidentDetails: string = ' Accident - up to 5 000 EUR';

export const CUSTOM_COVER_ID: number = 5;

export const CUSTOM_COVER_DATA: TravelCovers = {
  coverId: 5,
  coversName: 'Custom',
  coverSubtitle: 'Price will be calculated after you enter amount',
  amount: '0',
  amountForDay: '',
  readMore: 'Read more',
  selectedStatus: false,
  includedCoversList: [
    {id: 1, title: ' Medical expenses - up to 10000 EUR', subTitle: subTitle},
    {id: 2, title: urgentDetails, subTitle: urgentSubTitleDetails},
    {id: 3, title: ' Search and rescue expenses - up to 2000 EUR', subTitle: searchSubTitle},
  ],
  notIncludedCoversList: [{id: 4, title: 'Accident Cover', subTitle: coversSubTitle}],
};

export const MOCK_TRAVEL_COVER_LIST: TravelCovers[] = [
  {
    coverId: 1,
    coversName: 'Lite',
    coverSubtitle: 'Up to 10 000 EUR Per Person',
    amount: '370',
    amountForDay: '3 Peoples for 5 Days',
    readMore: 'Read more',
    selectedStatus: false,
    includedCoversList: [
      {id: 1, title: ' Medical expenses - up to 10000 EUR', subTitle: subTitle},
      {id: 2, title: urgentDetails, subTitle: urgentSubTitleDetails},
      {id: 3, title: ' Search and rescue expenses - up to 2000 EUR', subTitle: searchSubTitle},
    ],
    notIncludedCoversList: [{id: 4, title: 'Accident Cover', subTitle: coversSubTitle}],
  },
  {
    coverId: 2,
    coversName: 'Standard',
    coverSubtitle: 'Up to 20 000 EUR Per Person',
    amount: '715',
    amountForDay: '3 Peoples for 5 Days',
    readMore: 'Read more',
    selectedStatus: false,
    includedCoversList: [
      {id: 2, title: ' Medical expenses - up to 15 000 EUR', subTitle: subTitle},
      {id: 2, title: urgentDetails, subTitle: urgentSubTitleDetails},
      {id: 2, title: ' Search and rescue exspenses - up to 2 000 EUR', subTitle: searchSubTitle},
      {id: 2, title: accidentDetails, subTitle: coversSubTitle},
    ],
    notIncludedCoversList: [],
  },
  {
    coverId: 3,
    coversName: 'Visa',
    coverSubtitle: 'Up to 30 000 EUR Per Person',
    amount: '960',
    amountForDay: '3 Peoples for 5 Days',
    readMore: 'Read more',
    selectedStatus: false,
    includedCoversList: [
      {id: 2, title: ' Medical expenses - up to 30 000 EUR', subTitle: subTitle},
      {id: 3, title: urgentDetails, subTitle: urgentSubTitleDetails},
      {id: 3, title: ' Search and rescue expenses - up to 2 000 EUR', subTitle: searchSubTitle},
      {id: 3, title: accidentDetails, subTitle: coversSubTitle},
    ],
    notIncludedCoversList: [],
  },
  {
    coverId: 4,
    coversName: 'Premium',
    coverSubtitle: 'Up to 40 000 EUR Per Person',
    amount: '1080',
    amountForDay: '3 Peoples for 5 Days',
    readMore: 'Read more',
    selectedStatus: false,
    includedCoversList: [
      {id: 5, title: ' Medical expenses - up to 50 000 EUR', subTitle: subTitle},
      {id: 5, title: urgentDetails, subTitle: urgentSubTitleDetails},
      {id: 5, title: ' Search and rescue expenses - up to 2 000 EUR', subTitle: searchSubTitle},
      {id: 5, title: accidentDetails, subTitle: coversSubTitle},
    ],
    notIncludedCoversList: [],
  },
];
