import {Component, ComponentFactoryResolver, Injector, OnInit} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {DateKey, DateUtils} from '@atlas/businesstypes';
import {DateFormatterService} from '@b2b-frontend/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {Subscription} from 'rxjs';

import {AccountActivationDeactivation} from '../../../models/b2b/AccountActivationDeactivation';
import {UserAccountDetails} from '../../../models/b2b/UserAccountDetails';
import {UserActivateDeactivatePopup} from '../../../models/b2b/UserActivateDeactivatePopup';
import {AccountActivateDeactivateService} from '../../../services/account-activate-deactivate.service';

@UntilDestroy()
@Component({
  selector: 'nje-deactivate-account-popup',
  templateUrl: './deactivate-account-popup.component.html',
  styleUrls: ['./deactivate-account-popup.component.scss'],
})
export class DeactivateAccountPopupComponent extends ModalContentComponent<void> implements OnInit {
  public accountsData: UserActivateDeactivatePopup;
  public minDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 365);
  public deactivationDate: Date;
  private readonly _popupDetailsSubscription: Subscription;

  public constructor(
    public control: ModalControl<any>,
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    public readonly _injector: Injector,
    private readonly accountActivateDeactivateService: AccountActivateDeactivateService,
    private readonly _dateFormatterService: DateFormatterService,
  ) {
    super();
    this._popupDetailsSubscription = this.accountActivateDeactivateService
      .getDeactivatePopupDetails()
      .subscribe((data: UserActivateDeactivatePopup) => {
        this.accountsData = data;
      });
  }

  public ngOnInit(): void {}

  public deactivateAccount(): void {
    const deactivationDate = this._dateFormatterService.dateToISO(this.deactivationDate);
    const deactivateData: AccountActivationDeactivation = {
      userData: this.accountsData,
      actionDate: deactivationDate,
    };
    this.control.confirm(deactivateData);
  }

  public closePopup(): void {
    this._clearSubscriptions();
    this.control.cancel();
  }

  public getUserFullname(account: UserAccountDetails): string {
    return `${account.firstNameEn} ${account.lastNameEn} ${account.middleNameEn}`;
  }

  public getUserPID(account: UserAccountDetails): string {
    return `${account.pid}`;
  }

  public getAccountType(account: UserAccountDetails): string {
    return `${account.userTypeId}`;
  }

  public getUserName(account: UserAccountDetails): string {
    return account.userName ? `${account.userName}` : `${account.emailId}`;
  }

  private _clearSubscriptions(): void {
    if (this._popupDetailsSubscription) {
      this._popupDetailsSubscription.unsubscribe();
    }
  }
}
