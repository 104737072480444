import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationExprBookStackSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendCommunicationExprBookStack]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M11.05 13.64h6.9"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M23.14 24h-6.91"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M11.05 34.36h6.9"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M29.18 8.45v10.37"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M34.36 18.82v10.36"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M29.18 29.18v10.37"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5 8.45h32.82v10.36H5z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M10.18 18.82H43v10.36H10.18z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5 29.18h32.82v10.36H5z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationExprBookStackSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationExprBookStackSvgComponent,
  ],
  exports: [
    CommunicationExprBookStackSvgComponent,
  ],
})
export class CommunicationExprBookStackSvgModule {}
