import {Injectable} from '@angular/core';
import {
  ApiService,
  API_END_POINT,
  CityDto,
  CompanyDataDto,
  MtplPersonalData,
} from '@b2b-frontend/core';
import {Observable} from 'rxjs';
import {MtplVehicleOwnerDetails} from '../../models/MtplVehicleOwnerData';
import {Offices, PolicyLength, TariffRate, VehiclePurpose} from '../../models/PolicyFormModel';
import {
  RegistrationType,
  VehicleColor,
  VehicleFuelTypes,
  VehicleMake,
  VehicleModel,
  VehicleSubType,
  VehicleType,
} from '../../models/VehicleDetailsModelForm';
import {MtplRelatedParty} from '../../screens/owner-insurer-details/mtpl-add-insuring-party-slidein/_tests_/mtpl-related-party';

@Injectable({
  providedIn: 'root',
})
export class MtplApiService {
  public message: Observable<any>;

  public constructor(private readonly _apiService: ApiService) {}

  //TODO: Type <any> will be replaced in future
  public getContactsList(): Observable<any> {
    return this._apiService.get(API_END_POINT.CONTACT_API);
  }

  public getPolicyLengths(): Observable<PolicyLength[]> {
    return this._apiService.get<PolicyLength[]>(API_END_POINT.POLICY_DETAILS_API);
  }

  public getVehiclePurposes(): Observable<VehiclePurpose[]> {
    return this._apiService.get(API_END_POINT.VEHCILE_PURPOSES);
  }

  public getTariffRates(): Observable<TariffRate[]> {
    return this._apiService.get(API_END_POINT.TARIFF_RATES);
  }

  public getOffices(): Observable<Offices[]> {
    return this._apiService.get(API_END_POINT.OFFICES);
  }

  public getStartdateOfPolicy(vin: string, activeDate: string): Observable<any> {
    return this._apiService.get(`${API_END_POINT.STARTDATE}?vin=${vin}&activeDate=${activeDate}`);
  }

  public getVehicleDetails(
    registrationNo: string,
    certificateNumber: string,
  ): Observable<MtplVehicleOwnerDetails> {
    return this._apiService.get(
      `${API_END_POINT.VEHICLE_DETAILS}?regNo=${registrationNo}&certNo=${certificateNumber}`,
    );
  }

  public getPersonDetails(id: string): Observable<MtplPersonalData> {
    return this._apiService.get(`${API_END_POINT.PEOPLE_DATA}/${id}`);
  }

  public getCityDetails(): Observable<CityDto[]> {
    return this._apiService.get(API_END_POINT.CITY_ZIP);
  }

  public getCompanyDetails(id: string): Observable<CompanyDataDto> {
    return this._apiService.get(`${API_END_POINT.COMPANY_DATA}/${id}`);
  }

  public getRelatedParty(): Observable<MtplRelatedParty[]> {
    return this._apiService.get(API_END_POINT.RELATED_PARTIES_URL);
  }

  public getVehicleType(): Observable<VehicleType[]> {
    return this._apiService.get<VehicleType[]>(API_END_POINT.VEHICLE_TYPES);
  }

  public getVehicleSubType(carType: number): Observable<VehicleSubType[]> {
    return this._apiService.get(`${API_END_POINT.VEHICLE_TYPES}/${carType}/car-subtypes`);
  }

  public getVehicleMake(carType: number): Observable<VehicleMake[]> {
    return this._apiService.get(`${API_END_POINT.VEHICLE_TYPES}/${carType}/car-makes`);
  }

  public getVehicleModel(carType: string, carMake: string): Observable<VehicleModel[]> {
    return this._apiService.get(
      `${API_END_POINT.VEHICLE_TYPES}/${carType}/car-makes/${carMake}/car-models`,
    );
  }

  public getRegistrationTypes(): Observable<RegistrationType[]> {
    return this._apiService.get(API_END_POINT.VEHILCE_REGISTRATION_TYPES);
  }

  public getVehicleColors(): Observable<VehicleColor[]> {
    return this._apiService.get(API_END_POINT.VEHICLE_COLORS);
  }

  public getVehcileFuelType(): Observable<VehicleFuelTypes[]> {
    return this._apiService.get(API_END_POINT.VEHICLE_FUEL_TYPE);
  }
}
