<form maiaForm [formGroup]="stepForm.formGroup">
  <maia-form-group>
    <maia-fluid-fluid-layout alignContentTop>
      <div class="m-r-16">
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Company Name"
        >
          <input maiaInput type="text" placeholder="Company Name" formControlName="companyName" />
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('companyName') &&
              stepForm.hasError('companyName', 'required')
            "
          >
            Company name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('companyName') &&
              stepForm.hasError('companyName', 'nonLatinLetters')
            "
            >Company name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('companyName') &&
              stepForm.hasError('companyName', 'nonBlank')
            "
            >Invalid company name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('companyName') &&
              stepForm.hasError('companyName', 'maxlength')
            "
            >Company name is too long (maximum is
            {{ stepForm.controls['companyName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="City & Zip"
          multi
        >
          <maia-form-subelement>
            <maia-input-autocomplete
              name="city"
              formControlName="city"
              (typeAhead)="cityAutocomplete.filterTypeahead($event)"
            >
              <input autocomplete="dzi-off" maiaInput placeholder="City" />

              <maia-option *ngFor="let c of cityAutocomplete.list | async" [value]="c">
                <maia-option-highlight [label]="getCityTitle(c)"></maia-option-highlight>
              </maia-option>
            </maia-input-autocomplete>
          </maia-form-subelement>
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="Zip" formControlName="postCode" readonly />
          </maia-form-subelement>
          <!-- <maia-validation
            *ngIf="
              stepForm.hasError('city', 'required') && stepForm.formGroup.get('city').touched
            "
          >
            City and Zipcode Required
          </maia-validation> -->
          <!-- <maia-validation
            *ngIf="
              stepForm.hasError('city', 'required') && stepForm.formGroup.get('city').touched
            "
          >
            ZipCode Required
          </maia-validation> -->
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Quarter"
        >
          <input maiaInput type="text" placeholder="Quarter" formControlName="quarter" />
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('quarter') && stepForm.hasError('quarter', 'required')"
          >
            Quarter required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('quarter') && stepForm.hasError('quarter', 'nonLatinLetters')
            "
            >Quarter - only cyrillic letters allowed</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Street & Number"
          multi
        >
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="Street" formControlName="street" />
          </maia-form-subelement>
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="Number" formControlName="streetNumber" />
          </maia-form-subelement>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('street') && stepForm.hasError('street', 'required')"
          >
            Street required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('streetNumber') &&
              stepForm.hasError('streetNumber', 'required')
            "
          >
            Street number required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('street') && stepForm.hasError('street', 'nonLatinLetters')
            "
            >Street - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('streetNumber') &&
              stepForm.hasError('streetNumber', 'nonLatinLetters')
            "
            >Street number - only cyrillic letters allowed</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          class="input-column"
          [options]="{validationInstant: true}"
          label="Block, Entrance, Floor & Apartment"
          multi
        >
          <maia-form-subelement>
            <input maiaInput type="text" name="block" placeholder="Block" formControlName="block" />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              name="entrance"
              placeholder="Entrance"
              formControlName="entrance"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floor" />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              name="apartment"
              placeholder="Apartment"
              formControlName="apartment"
            />
          </maia-form-subelement>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('block') && stepForm.hasError('block', 'required')"
          >
            Block required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('entrance') && stepForm.hasError('entrance', 'required')
            "
          >
            Entrance required
          </maia-validation>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('floor') && stepForm.hasError('floor', 'required')"
          >
            Floor required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('apartment') && stepForm.hasError('apartment', 'required')
            "
          >
            Apartment required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('block') && stepForm.hasError('block', 'nonLatinLetters')
            "
            >Block - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('entrance') &&
              stepForm.hasError('entrance', 'nonLatinLetters')
            "
            >Entrance - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('floor') && stepForm.hasError('floor', 'nonLatinLetters')
            "
            >Floor - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('apartment') &&
              stepForm.hasError('apartment', 'nonLatinLetters')
            "
            >Apartment - only cyrillic letters allowed</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Phone Number"
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('phoneNumber')"
          >
            <maia-input-phonenumber
              name="phone"
              appDigitOnly
              appLimitTo="10"
              formControlName="phoneNumber"
              #phoneInput
              class="disable-country-code"
            >
              <maia-phonenumber-country prefix="+359" name="Bulgaria">
                <ng-template #flag>
                  <svg b2bFrontendFlagUtilBulgaria></svg>
                </ng-template>
              </maia-phonenumber-country>
            </maia-input-phonenumber>
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('phoneNumber')">
              <maia-validation *ngIf="stepForm.hasError('phoneNumber', 'required')">
                Phone number required
              </maia-validation>
              <maia-validation *ngIf="stepForm.hasError('phoneNumber', 'invalid')">
                Phone number invalid
              </maia-validation>
            </div>
          </div>
        </maia-form-element>
      </div>
      <div>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Company Name with latin letters"
        >
          <input maiaInput placeholder="Company Name" formControlName="companyNameEn" />
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('companyNameEn') &&
              stepForm.hasError('companyNameEn', 'required')
            "
          >
            Company name in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('companyNameEn') &&
              stepForm.hasError('companyNameEn', 'nonCyrillicLetters')
            "
            >Company name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('companyNameEn') &&
              stepForm.hasError('companyNameEn', 'nonBlank')
            "
            >Invalid company name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('companyNameEn') &&
              stepForm.hasError('companyNameEn', 'maxlength')
            "
            >Company name is too long (maximum is
            {{ stepForm.controls['companyNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="City & Zip with latin letters"
          multi
        >
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="City" formControlName="cityEn" />
          </maia-form-subelement>
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="Zip" formControlName="postCodeEn" />
          </maia-form-subelement>
          <!-- <maia-validation
            *ngIf="stepForm.hasToShowErrors('cityEn') && stepForm.hasError('cityEn', 'required')"
          >
            City in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('postCodeEn') &&
              stepForm.hasError('postCodeEn', 'required')
            "
          >
            Zipcode in latin required
          </maia-validation> -->
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Quarter with latin letters"
        >
          <input maiaInput placeholder="Quarter" formControlName="quarterEn" />
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('quarterEn') && stepForm.hasError('quarterEn', 'required')
            "
          >
            Quarter in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('quarterEn') &&
              stepForm.hasError('quarterEn', 'nonCyrillicLetters')
            "
            >Quarter - only latin letters allowed</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Street & Number with latin letters"
          multi
        >
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="Street" formControlName="streetEn" />
          </maia-form-subelement>
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="Number" formControlName="streetNumberEn" />
          </maia-form-subelement>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('streetEn') && stepForm.hasError('streetEn', 'required')
            "
          >
            Street in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('streetNumberEn') &&
              stepForm.hasError('streetNumberEn', 'required')
            "
          >
            Street number in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('streetEn') &&
              stepForm.hasError('streetEn', 'nonCyrillicLetters')
            "
            >Street - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('streetNumberEn') &&
              stepForm.hasError('streetNumberEn', 'nonCyrillicLetters')
            "
            >Street Number - only latin letters allowed</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          class="input-column"
          label="Block, Entrance, Floor & Apartment with latin letters"
          multi
        >
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              name="block"
              placeholder="Block"
              formControlName="blockEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              name="entrance"
              placeholder="Entrance"
              formControlName="entranceEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              name="floor"
              placeholder="Floor"
              formControlName="floorEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              name="apartment"
              placeholder="Apartment"
              formControlName="apartmentEn"
            />
          </maia-form-subelement>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('blockEn') && stepForm.hasError('blockEn', 'required')"
          >
            Block in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('entranceEn') && stepForm.hasError('entranceEn', 'required')
            "
          >
            Entrance in latin required
          </maia-validation>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('floorEn') && stepForm.hasError('floorEn', 'required')"
          >
            Floor in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('apartmentEn') &&
              stepForm.hasError('apartmentEn', 'required')
            "
          >
            Apartment in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('blockEn') &&
              stepForm.hasError('blockEn', 'nonCyrillicLetters')
            "
            >Block - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('entranceEn') &&
              stepForm.hasError('entranceEn', 'nonCyrillicLetters')
            "
            >Entrance - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('floorEn') &&
              stepForm.hasError('floorEn', 'nonCyrillicLetters')
            "
            >Floor - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('apartmentEn') &&
              stepForm.hasError('apartmentEn', 'nonCyrillicLetters')
            "
            >Apartment - only latin letters allowed</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Email ID"
        >
          <input
            maiaInput
            type="email"
            atlasEmailAddress
            placeholder="Email ID"
            formControlName="emailId"
          />
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('emailId') && stepForm.hasError('emailId', 'required')"
          >
            Email ID required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasError('emailId', 'atlasInvalidLocalPart') ||
              stepForm.hasError('emailId', 'atlasInvalidDomain') ||
              stepForm.hasError('emailId', 'atlasInvalidMultipleAt')
            "
            i18n="@@systemcode-mtpl-contact-emailformat"
            >Enter your e-mail address in the following format: name@domain.com</maia-validation
          >
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-divider class="m-t-b-24"></maia-divider>

    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-label>
          <label class="b2b-kdl-font-small title-dark-color">Representative </label>
        </maia-label>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="EGN / LNCH"
        >
          <input
            maiaInput
            name="EGN / LNCH"
            formControlName="repIdNumber"
            placeholder="EGN / LNCH"
          />
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repIdNumber') &&
              stepForm.hasError('repIdNumber', 'required')
            "
          >
            EGN/LNCH required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repIdNumber') &&
              stepForm.hasError('repIdNumber', 'repInvalidNumber')
            "
          >
            Invalid EGN or LNCH number.
          </maia-validation>
        </maia-form-element>
      </div>
      <div></div>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Full Name"
          multi
        >
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="First Name" formControlName="repFirstName" />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="Middle Name"
              formControlName="repMiddleName"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="Last Name" formControlName="repLastName" />
          </maia-form-subelement>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repFirstName') &&
              stepForm.hasError('repFirstName', 'required')
            "
          >
            First name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repMiddleName') &&
              stepForm.hasError('repMiddleName', 'required')
            "
          >
            Middle name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repLastName') &&
              stepForm.hasError('repLastName', 'required')
            "
          >
            Last name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repFirstName') &&
              stepForm.hasError('repFirstName', 'nonLatinLetters')
            "
            >First name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repMiddleName') &&
              stepForm.hasError('repMiddleName', 'nonLatinLetters')
            "
            >Middle name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repLastName') &&
              stepForm.hasError('repLastName', 'nonLatinLetters')
            "
            >Last name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repFirstName') &&
              stepForm.hasError('repFirstName', 'nonBlank')
            "
            >Invalid first name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repMiddleName') &&
              stepForm.hasError('repMiddleName', 'nonBlank')
            "
            >Invalid middle name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repLastName') &&
              stepForm.hasError('repLastName', 'nonBlank')
            "
            >Invalid last name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repFirstName') &&
              stepForm.hasError('repFirstName', 'maxlength')
            "
            >First name is too long (maximum is
            {{ stepForm.controls['repFirstName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repMiddleName') &&
              stepForm.hasError('repMiddleName', 'maxlength')
            "
            >Middle name is too long (maximum is
            {{ stepForm.controls['repMiddleName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repLastName') &&
              stepForm.hasError('repLastName', 'maxlength')
            "
            >Last name is too long (maximum is
            {{ stepForm.controls['repLastName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          *ngIf="isRepForeigner"
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Citizenship"
        >
          <maia-input-autocomplete
            name="repCitizenship"
            formControlName="repCitizenship"
            (typeAhead)="repCitizenshipAutocomplete.filterTypeahead($event)"
          >
            <input
              autocomplete="dzi-off"
              maiaInput
              i18n-placeholder="@@systemcode-mtpl-placeholder-select"
              placeholder="Select"
            />

            <maia-option *ngFor="let c of repCitizenshipAutocomplete.list | async" [value]="c.id">
              <maia-option-highlight [label]="getCitizenshipTitle(c)"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repCitizenship') &&
              stepForm.hasError('repCitizenship', 'required')
            "
          >
            Citizenship required
          </maia-validation>
        </maia-form-element>
      </div>
      <div>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Full Name with latin letters"
          multi
        >
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="First Name"
              formControlName="repFirstNameEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="Middle Name"
              formControlName="repMiddleNameEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input maiaInput type="text" placeholder="Last Name" formControlName="repLastNameEn" />
          </maia-form-subelement>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repFirstNameEn') &&
              stepForm.hasError('repFirstNameEn', 'required')
            "
          >
            First name in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repMiddleNameEn') &&
              stepForm.hasError('repMiddleNameEn', 'required')
            "
          >
            Middle name in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repLastNameEn') &&
              stepForm.hasError('repLastNameEn', 'required')
            "
          >
            Last name in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repFirstNameEn') &&
              stepForm.hasError('repFirstNameEn', 'nonCyrillicLetters')
            "
            >First name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repMiddleNameEn') &&
              stepForm.hasError('repMiddleNameEn', 'nonCyrillicLetters')
            "
            >Middle name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repLastNameEn') &&
              stepForm.hasError('repLastNameEn', 'nonCyrillicLetters')
            "
            >last name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repFirstNameEn') &&
              stepForm.hasError('repFirstNameEn', 'nonBlank')
            "
            >Invalid first name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repMiddleNameEn') &&
              stepForm.hasError('repMiddleNameEn', 'nonBlank')
            "
            >Invalid middle name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repLastNameEn') &&
              stepForm.hasError('repLastNameEn', 'nonBlank')
            "
            >Invalid last name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repFirstNameEn') &&
              stepForm.hasError('repFirstNameEn', 'maxlength')
            "
            >First name is too long (maximum is
            {{ stepForm.controls['repFirstNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repMiddleNameEn') &&
              stepForm.hasError('repMiddleNameEn', 'maxlength')
            "
            >Middle name is too long (maximum is
            {{ stepForm.controls['repMiddleNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('repLastNameEn') &&
              stepForm.hasError('repLastNameEn', 'maxlength')
            "
            >Last name is too long (maximum is
            {{ stepForm.controls['repLastNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          *ngIf="isRepForeigner"
          [hideOptionalIndicator]="true"
          [options]="{validationInstant: true}"
          class="input-column"
          label="Date of Birth"
        >
          <maia-input-date
            name="repDob"
            [(ngModel)]="repBirthDatePicker.value"
            (ngModelChange)="repBirthDatePicker.change($event)"
            (blur)="repBirthDatePicker.touch()"
            [minimum]="minDate"
            [maximum]="maxDate"
            [ngModelOptions]="{standalone: true}"
            [visibleView]="visibleDateView"
          ></maia-input-date>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('repDob') && stepForm.hasError('repDob', 'required')"
          >
            Date of birth required
          </maia-validation>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('repDob') && stepForm.hasError('repDob', 'invalid')"
          >
            Date of birth invalid
          </maia-validation>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('repDob') && stepForm.hasError('repDob', 'atlasBefore')"
          >
            Birth date should not be future date.
          </maia-validation>
          <maia-validation
            *ngIf="stepForm.hasToShowErrors('repDob') && stepForm.hasError('repDob', 'atlasAfter')"
          >
            Birth date should not be less than 150 years.
          </maia-validation>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-divider class="m-t-b-24"></maia-divider>

    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-label>
          <label class="b2b-kdl-font-small title-dark-color">Contact Person </label>
        </maia-label>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="false"
          class="input-column"
          label="EGN / LNCH"
        >
          <input
            maiaInput
            name="EGN / LNCH"
            formControlName="contactIdNumber"
            placeholder="EGN / LNCH"
          />
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactIdNumber') &&
              stepForm.hasError('contactIdNumber', 'required')
            "
          >
            EGN/LNCH required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactIdNumber') &&
              stepForm.hasError('contactIdNumber', 'contactInvalidNumber')
            "
          >
            Invalid EGN or LNCH number.
          </maia-validation>
        </maia-form-element>
      </div>
      <div> </div>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="false"
          class="input-column"
          label="Full Name"
          multi
        >
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="First Name"
              formControlName="contactFirstName"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="Middle Name"
              formControlName="contactMiddleName"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="Last Name"
              formControlName="contactLastName"
            />
          </maia-form-subelement>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstName') &&
              stepForm.hasError('contactFirstName', 'required')
            "
          >
            First name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleName') &&
              stepForm.hasError('contactMiddleName', 'required')
            "
          >
            Middle name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastName') &&
              stepForm.hasError('contactLastName', 'required')
            "
          >
            Last name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstName') &&
              stepForm.hasError('contactFirstName', 'nonLatinLetters')
            "
            >First name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleName') &&
              stepForm.hasError('contactMiddleName', 'nonLatinLetters')
            "
            >Middle name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastName') &&
              stepForm.hasError('contactLastName', 'nonLatinLetters')
            "
            >Last name - only cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstName') &&
              stepForm.hasError('contactFirstName', 'nonBlank')
            "
            >Invalid first name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleName') &&
              stepForm.hasError('contactMiddleName', 'nonBlank')
            "
            >Invalid middle name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastName') &&
              stepForm.hasError('contactLastName', 'nonBlank')
            "
            >Invalid last name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstName') &&
              stepForm.hasError('contactFirstName', 'maxlength')
            "
            >First name is too long (maximum is
            {{ stepForm.controls['contactFirstName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleName') &&
              stepForm.hasError('contactMiddleName', 'maxlength')
            "
            >Middle name is too long (maximum is
            {{ stepForm.controls['contactMiddleName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastName') &&
              stepForm.hasError('contactLastName', 'maxlength')
            "
            >Last name is too long (maximum is
            {{ stepForm.controls['contactLastName'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          *ngIf="isContactForeigner"
          [options]="{validationInstant: true}"
          class="input-column"
          label="Citizenship"
        >
          <maia-input-autocomplete
            name="contactCitizenship"
            formControlName="contactCitizenship"
            (typeAhead)="contactCitizenshipAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="dzi-off" maiaInput placeholder="Select" />

            <maia-option
              *ngFor="let c of contactCitizenshipAutocomplete.list | async"
              [value]="c.id"
            >
              <maia-option-highlight [label]="getCitizenshipTitle(c)"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>

          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactCitizenship') &&
              stepForm.hasError('contactCitizenship', 'required')
            "
          >
            Citizenship required
          </maia-validation>
        </maia-form-element>
      </div>
      <div>
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="false"
          class="input-column"
          label="Full Name with latin letters"
          multi
        >
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="First Name"
              formControlName="contactFirstNameEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="Middle Name"
              formControlName="contactMiddleNameEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="Last Name"
              formControlName="contactLastNameEn"
            />
          </maia-form-subelement>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstNameEn') &&
              stepForm.hasError('contactFirstNameEn', 'required')
            "
          >
            First name in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleNameEn') &&
              stepForm.hasError('contactMiddleNameEn', 'required')
            "
          >
            Middle name in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastNameEn') &&
              stepForm.hasError('contactLastNameEn', 'required')
            "
          >
            Last name in latin required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstNameEn') &&
              stepForm.hasError('contactFirstNameEn', 'nonCyrillicLetters')
            "
            >First name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleNameEn') &&
              stepForm.hasError('contactMiddleNameEn', 'nonCyrillicLetters')
            "
            >Middle name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastNameEn') &&
              stepForm.hasError('contactLastNameEn', 'nonCyrillicLetters')
            "
            >last name - only latin letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstNameEn') &&
              stepForm.hasError('contactFirstNameEn', 'nonBlank')
            "
            >Invalid first name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleNameEn') &&
              stepForm.hasError('contactMiddleNameEn', 'nonBlank')
            "
            >Invalid middle name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastNameEn') &&
              stepForm.hasError('contactLastNameEn', 'nonBlank')
            "
            >Invalid last name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstNameEn') &&
              stepForm.hasError('contactFirstNameEn', 'maxlength')
            "
            >First name is too long (maximum is
            {{ stepForm.controls['contactFirstNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleNameEn') &&
              stepForm.hasError('contactMiddleNameEn', 'maxlength')
            "
            >Middle name is too long (maximum is
            {{ stepForm.controls['contactMiddleNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastNameEn') &&
              stepForm.hasError('contactLastNameEn', 'maxlength')
            "
            >Last name is too long (maximum is
            {{ stepForm.controls['contactLastNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          *ngIf="isContactForeigner"
          [hideOptionalIndicator]="false"
          [options]="{validationInstant: true}"
          class="input-column"
          label="Date of Birth"
        >
          <maia-input-date
            name="contactDob"
            [(ngModel)]="contactBirthDatePicker.value"
            (ngModelChange)="contactBirthDatePicker.change($event)"
            (blur)="contactBirthDatePicker.touch()"
            [minimum]="minDate"
            [maximum]="maxDate"
            [ngModelOptions]="{standalone: true}"
            [visibleView]="visibleDateView"
          ></maia-input-date>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactDob') && stepForm.hasError('contactDob', 'required')
            "
          >
            Date of birth required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactDob') && stepForm.hasError('contactDob', 'invalid')
            "
          >
            Date of birth invalid
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactDob') &&
              stepForm.hasError('contactDob', 'atlasBefore')
            "
          >
            Birth date should not be future date.
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactDob') &&
              stepForm.hasError('contactDob', 'atlasAfter')
            "
          >
            Birth date should not be less than 150 years.
          </maia-validation>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
    <maia-divider class="m-t-b-24"></maia-divider>
    <button
      *ngIf="!(step.inEdit | async)"
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      (click)="save()"
      >Continue</button
    >

    <div *ngIf="step.inEdit | async">
      <button class="m-r-24 m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveEdit()"
        >Save</button
      >
      <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
    </div>
  </maia-form-group>
</form>
