import {InsuredPerson} from '@b2b-frontend/core';

export const PHONE_NUMBER = {
  prefix: '359',
  number: '8979901245',
};

export const MOCK_INSURED_PERSON = {
  //idNumber: '23579435340',
  idNumber: '1000546060',
  firstName: 'Борис',
  middleName: 'Петров',
  lastName: 'Борисов',
  firstNameEn: 'Boris',
  middleNameEn: 'Petrov',
  lastNameEn: 'Borisov',
  phoneNumber: PHONE_NUMBER,
  citizenship: 'Bulgarian',
  dob: '21-06-1992',
};

export const MOCK_INSURED_PERSON_LIST: InsuredPerson[] = [
  {
    idNumber: '7907276486',
    firstName: 'БОЖИДАР',
    middleName: 'ДОБРЕВ',
    lastName: 'ДИНЧЕВ',
    firstNameEn: 'BOZHIDAR',
    middleNameEn: 'DOBREV',
    lastNameEn: 'DINCHEV',
    phoneNumber: '8976543210',
    citizenship: '',
    dob: '',
  },
  // {
  //   idNumber: '6901244005',
  //   firstName: 'ДИМЧО',
  //   middleName: 'ИВАНОВ',
  //   lastName: 'ЧАВДАРОВ',
  //   firstNameEn: 'DIMCHO',
  //   middleNameEn: 'IVANOV',
  //   lastNameEn: 'CHAVDAROV',
  //   phoneNumber: '8979901245',
  //   citizenship: '',
  //   dob: '',
  // },
  // {
  //   idNumber: '4805045787',
  //   firstName: 'КОЙЧО',
  //   middleName: 'ГОСПОДИНОВ',
  //   lastName: 'ДИНЧЕВ',
  //   firstNameEn: 'KOYCHO',
  //   middleNameEn: 'GOSPODINOV',
  //   lastNameEn: 'DINCHEV',
  //   phoneNumber: '8976601342',
  //   citizenship: '',
  //   dob: '',
  // },
  // {
  //   idNumber: '6106040643',
  //   firstName: 'ДЖЕЙ',
  //   middleName: 'ПОЙНТ',
  //   lastName: 'ООД',
  //   firstNameEn: 'DZHEY',
  //   middleNameEn: 'POYNT',
  //   lastNameEn: 'OOD',
  //   phoneNumber: '8123576097',
  //   citizenship: '',
  //   dob: '',
  // },
  // {
  //   idNumber: '9108267164',
  //   firstName: 'КАРТЛИДЖ',
  //   middleName: 'АЛЪН',
  //   lastName: 'ДИНЧЕВ',
  //   firstNameEn: 'KARTLIDZH',
  //   middleNameEn: 'ALAN',
  //   lastNameEn: 'DINCHEV',
  //   phoneNumber: '8246897320',
  //   citizenship: '',
  //   dob: '',
  // },
  // {
  //   idNumber: '1004634624',
  //   firstName: 'КАРТЛИДЖ',
  //   middleName: 'АЛЪН',
  //   lastName: 'ДИНЧЕВ',
  //   firstNameEn: 'KARTLIDZH',
  //   middleNameEn: 'ALAN',
  //   lastNameEn: 'DINCHEV',
  //   phoneNumber: '8246897320',
  //   citizenship: 'Bulgarian',
  //   dob: '11-02-2022',
  // },
  // {
  //   idNumber: '1000546060',
  //   firstName: 'ПЛАМЕН',
  //   middleName: 'ГЕОРГИЕВ',
  //   lastName: 'КОСТАДИНОВ',
  //   firstNameEn: 'PLAMEN',
  //   middleNameEn: 'GEORGIEV',
  //   lastNameEn: 'KOSTADINOV',
  //   phoneNumber: '8744970531',
  //   citizenship: 'Bulgarian',
  //   dob: '21-06-1992',
  // },
  {
    idNumber: '1123169263',
    firstName: 'БОЖИДАР',
    middleName: 'ДОБРЕВ',
    lastName: 'ДИНЧЕВ',
    firstNameEn: 'BOZHIDAR',
    middleNameEn: 'DOBREV',
    lastNameEn: 'DINCHEV',
    phoneNumber: '8976543210',
    citizenship: 'Bulgarian',
    dob: '03-11-1991',
  },
];

export const MOCK_TABLE_HEADERS = [
  'EGN / LNCH',
  'Full Name',
  'Full Name with Latin Letters',
  'Contact',
  'Citizenship',
  'Date of Birth',
];

export const MOCK_MESSAGES_CLOSE_ICON = [
  {name: 'messageError1', nameType: 'error', show: false, showCloseButton: false},
  {
    name: 'messageError2',
    nameType: 'error',
    show: false,
    showCloseButton: false,
    text: 'hi',
    count: 0,
  },
];
