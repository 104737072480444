import {Component, Input} from '@angular/core';

@Component({
  selector: 'header-with-breadcrumbs',
  templateUrl: './header-with-breadcrumbs.component.html',
  styleUrls: ['./header-with-breadcrumbs.component.scss'],
})
export class HeaderWithBreadcrumbsComponent {
  @Input() public title: string;
}
