import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ApiService} from '../backend/api.service';
import {API_END_POINT} from '../core-helper/api-endpoint/ApiEndPoint';
import {CitizenshipDto, NationalityDto} from '../form/model/dto/NationalityDto';
import {CityApiResponse} from '../core-helper/dto/travel-dto/CityApiResponse';
import {CityApiData} from '../core-helper/dto/property-insurance-dto/insurance-party-details-dto';
import {
  ApiCompanyData,
  ApiPersonalData,
} from '../core-helper/dto/property-insurance-dto/summary-dto/insurance-party-details-api-dto';
import {City} from '@njf-frontend-angular/flow-progress';

@Injectable({
  providedIn: 'root',
})
export class CoreApiService {
  constructor(private readonly _apiService: ApiService) {}

  // for time being added as any
  public getPlaceOfResidence(q?: string, skipLoader: boolean = false): Observable<CityApiResponse> {
    let url = API_END_POINT.CITY_API_URL;
    if (q) {
      url += '?q=' + q;
    }
    return this._apiService.get(url, undefined, undefined, skipLoader);
  }

  public getCity(query?: string, skipLoader: boolean = false): Observable<CityApiData[]> {
    let url = API_END_POINT.CITY_ZIP;
    if (query) {
      url += '?query=' + query;
    }
    return this._apiService.get(url, undefined, undefined, skipLoader);
  }

  public getCityZip(query?: string, skipLoader: boolean = false): Observable<City[]> {
    let url = API_END_POINT.CITYZIP;
    if (query) {
      url += '?query=' + query;
    }
    return this._apiService.get(url, undefined, undefined, skipLoader);
  }

  public getNationalities(
    q?: string,
    skipLoader: boolean = false,
  ): Observable<{data: NationalityDto[]}> {
    let url = 'mtpl/get-nationalities';
    if (q) {
      url += '?q=' + q;
    }
    return this._apiService.get(url, undefined, undefined, skipLoader);
  }

  public getCitizenship(): Observable<CitizenshipDto[]> {
    return this._apiService.get(API_END_POINT.CITIZENSHIP_API);
  }

  public getPersonData(id: string): Observable<ApiPersonalData> {
    return this._apiService.get(`${API_END_POINT.PEOPLE_DATA}/${id}`);
  }

  public getCompanyData(id: string): Observable<ApiCompanyData> {
    return this._apiService.get(`${API_END_POINT.COMPANY_DATA}/${id}`);
  }
}
