import {Component} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ExtraTextControlValidators} from '@atlas-angular/businesstypes';
import {LoggerFactory} from '@atlas-angular/logger';
import {EmailAddress} from '@atlas/businesstypes';
import {Logger} from '@atlas/logger';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.scss'],
})
export class EmailPopupComponent extends ModalContentComponent<void> {
  public readonly _logger: Logger;
  public mailForm: FormGroup;
  public email: string;
  public clientEmail: EmailAddress;
  public showEmailContainer: boolean = true;

  public constructor(
    public control: ModalControl<void>,
    public loggerFactory: LoggerFactory,
    private readonly _formBuilder: FormBuilder,
  ) {
    super();
    this._logger = loggerFactory.createLogger('EmailPopupComponent');

    this.mailForm = this._formBuilder.group({
      email: new FormControl(undefined, [
        Validators.required,
        ExtraTextControlValidators.maxLength(50),
      ]),
      emailsFormArray: this._formBuilder.array([]),
    });
  }

  public get emailsFormArray(): FormArray {
    return this.mailForm.get('emailsFormArray') as FormArray;
  }

  public newEmails(): FormGroup {
    return this._formBuilder.group({
      mails: new FormControl(undefined, [
        Validators.required,
        ExtraTextControlValidators.maxLength(50),
      ]),
    });
  }

  public addEmails(): void {
    return this.emailsFormArray.push(this.newEmails());
  }

  public removeEmail(i: number): void {
    return this.emailsFormArray.removeAt(i);
  }

  public openSuccessContainer(): boolean {
    return (this.showEmailContainer = false);
  }
}
