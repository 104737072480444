import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FlagUtilAndorraSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFlagUtilAndorra]',
  template: '<svg:path fill="#FFDA44" d="M0 0h48v48H0z"></svg:path><svg:path fill="#0052B4" d="M0 0h16v48H0z"></svg:path><svg:path fill="#D80027" d="M32 0h16v48H32z"></svg:path><svg:path fill="#D80027" d="M24 29.1v-5.8h4.4v2.2c0 .4-.7 1.8-2.5 2.9-.8.4-1.5.5-1.9.7z"></svg:path><svg:path fill="#D80027" d="M19.6 18.9H24v4.4h-4.4z"></svg:path><svg:path fill="#FF9811" d="M26.2 17.5c0-.8-.6-1.5-1.4-1.5-.3 0-.5.1-.7.2-.7-.4-1.6-.2-2 .5-.1.2-.2.5-.2.7h-3.6v7.3c0 2.7 2.2 4.3 3.8 5.1-.4.7-.2 1.6.5 2 .4.3 1 .3 1.5 0 .7.4 1.6.2 2-.5.3-.5.3-1 0-1.5 1.7-.8 3.8-2.4 3.8-5.1v-7.3l-3.7.1zm1.4 7.2c0 .4 0 1.5-1.8 2.7-.6.4-1.2.6-1.8.9-.6-.2-1.3-.5-1.8-.9-1.8-1.1-1.8-2.3-1.8-2.7v-5.1h7.3l-.1 5.1z"></svg:path>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagUtilAndorraSvgComponent {
}

@NgModule({
  declarations: [
    FlagUtilAndorraSvgComponent,
  ],
  exports: [
    FlagUtilAndorraSvgComponent,
  ],
})
export class FlagUtilAndorraSvgModule {}
