<maia-label
  class="label-font info-msg-text"
  i18n="@@nje-b2b-frontend-casco-priceoffer-coversandaddons-additionalrisksslidin-labelhead"
  >The following risks are included by default in Premium Risks</maia-label
>
<div *ngFor="let option of additionalRisksTicksOptions | async" class="row-warp tick-align"
  ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
  <maia-label class="label-text-font-info-box info-msg-text">{{ option.ticks }}</maia-label></div
>

<maia-label
  class="label-font info-msg-text"
  i18n="
    @@nje-b2b-frontend-casco-priceoffer-coversandaddons-additionalrisksslidin-additionalriskshead"
  >Additional Risks</maia-label
>
<maia-checkbox-group>
  <div *ngFor="let option of additionalRisksOptions | async" class="row-warp">
    <maia-checkbox (onValueChange)="chkSelected(option.id, option.selectedStatus, option.price)">{{
      option.risks
    }}</maia-checkbox>
    <maia-amount-display
      class="amount-align"
      [value]="getAmountValue(option.price)"
      size="medium"
      currency="BGN"
    ></maia-amount-display>
  </div>
</maia-checkbox-group>
<maia-divider></maia-divider>
<div class="m-t-24 row-warp">
  <button
    i18n="@@nje-b2b-frontend-casco-priceoffer-coversandaddons-additionalrisksslidin-save"
    maiaButton
    maiaButtonType="primary"
    (click)="onSave()"
    >Save</button
  >
  <button
    maiaButton
    class="cancel-button m-l-24"
    maiaButtonType="secondary"
    i18n="@@nje-b2b-frontend-casco-priceoffer-coversandaddons-additionalrisksslidin-cancel"
    (click)="cancelSlideIn()"
    >Cancel</button
  ></div
>
