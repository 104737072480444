/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/unbound-method */
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateKey, DateUtils, Boolean} from '@atlas/businesstypes';
import {DatepickerVisibleView} from '@maia/datepickers';
import {
  ScreenFormComponent,
  FormGroupManagerFactoryService,
  FormGroupManager,
} from '@njf-frontend-angular/flow-progress';

import {PaymentsSessionService} from '../../../payments-core/services/payments-session.service';
import {InsuredPartyScreenStep} from '../../../contracts/instalment-payment/instalment';
import {
  NameAddressTranslationMapService,
  PartyType,
  PartyIdService,
  vehicleRegistrationNumberValidator,
  personName,
  companyName,
  partyIdValidator,
} from '@b2b-frontend/core';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'nje-b2b-instalment-payment-insured-party',
  templateUrl: './insured-party.component.html',
  styleUrls: ['./insured-party.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsuredPartyComponent extends ScreenFormComponent implements OnInit {
  public insuredPartyType: string;
  public isEIK: boolean = false;
  public isPayerSame: boolean = false;
  public isEReceipt: boolean = true;

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Day, 1);
  public maxDate = DateUtils.add(this.minDate, DateKey.Day, 59);
  public visibleDateView?: DatepickerVisibleView = undefined;
  public disableWeekends = false;

  public stepForm1: FormGroupManager<any> = this._formFactory.createFormManager<any>({
    uniqueId: new FormControl(undefined, partyIdValidator(this._partyId)),
    firstName: new FormControl(undefined, [Validators.required, personName('bg')]),
    middleName: new FormControl(undefined, [Validators.required, personName('bg')]),
    lastName: new FormControl(undefined, [Validators.required, personName('bg')]),
    firstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
    middleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
    lastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
    companyName: new FormControl(undefined, [companyName('bg')]),
    companyNameEn: new FormControl(undefined, [companyName('en')]),
    policyDetails: this.formBuilder.array([], [Validators.required]),
    payerSameCheckbox: new FormControl(false, [Validators.required]),
    payerFirstName: new FormControl(undefined, [Validators.required, personName('bg')]),
    payerMiddleName: new FormControl(undefined, [Validators.required, personName('bg')]),
    payerLastName: new FormControl(undefined, [Validators.required, personName('bg')]),
    payerFirstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
    payerMiddleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
    payerLastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
  });

  public constructor(
    route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private readonly _nameAddressTrasMapService: NameAddressTranslationMapService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _paymentSessionService: PaymentsSessionService,
    private readonly _partyId: PartyIdService,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    this._loadPaymentMethodDetails();
    this._loadInsuredPartyDetails(PartyType.PERSON);
    this._loadPolicyDetails();
    this._initializeValueChanges();
  }

  private _initializeValueChanges(): void {
    this.stepForm1.controls.payerSameCheckbox.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result: Boolean) => {
        if (result instanceof Boolean && result.equals(Boolean.TRUE)) {
          this.isPayerSame = true;
          this.updatePayerDetailsValidators(this.isPayerSame);
        } else {
          this.isPayerSame = false;
          this.updatePayerDetailsValidators(this.isPayerSame);
        }
      });

    this.stepForm1.controls.uniqueId.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result: string) => {
        const partyType = this._partyId.getType(result);
        if (partyType) {
          this._loadInsuredPartyDetails(partyType);
        }
      });

    this.updateMtplDetailsValidators(this.isEReceipt);
  }

  public addPropertyPolicyFormGroup(): FormGroup {
    return this.formBuilder.group({
      propertyType: new FormControl(undefined, [Validators.required]),
      propertyArea: new FormControl(undefined, [Validators.required]),
      city: new FormControl(undefined, [Validators.required]),
      zip: new FormControl({value: undefined, disabled: true}),
      quarter: new FormControl(undefined, [Validators.required]),
      streetName: new FormControl(undefined, [Validators.required]),
      streetNumber: new FormControl(undefined, [Validators.required]),
      block: new FormControl(undefined),
      entrance: new FormControl(undefined),
      floor: new FormControl(undefined),
      apartment: new FormControl(undefined),
    });
  }

  public addMtplPolicyFormGroup(): FormGroup {
    return this.formBuilder.group({
      vehicleRegNo: new FormControl(undefined, [
        Validators.required,
        vehicleRegistrationNumberValidator(),
      ]),
      vehiclePurpose: new FormControl(undefined, [Validators.required]),
      stickerBlankSlNo: new FormControl(undefined, [Validators.required]),
      stickerBlankNo: new FormControl(undefined, [Validators.required]),
      stickerStartDate: new FormControl(undefined, [Validators.required]),
      stickerEndDate: new FormControl(undefined, [Validators.required]),
      cardBlankSlNo: new FormControl(undefined, [Validators.required]),
      cardBlankNo: new FormControl(undefined, [Validators.required]),
      cardStartDate: new FormControl(undefined, [Validators.required]),
      cardEndDate: new FormControl(undefined, [Validators.required]),
    });
  }

  public get policyDetailsArray(): FormArray {
    return this.stepForm1.controls.policyDetails as FormArray;
  }

  public pushPropertyPolicyFormGroup(): void {
    this.policyDetailsArray.push(this.addPropertyPolicyFormGroup());
  }

  public pushMtplPolicyFormGroup(): void {
    this.policyDetailsArray.push(this.addMtplPolicyFormGroup());
  }

  private _loadInsuredPartyDetails(partyType: string): void {
    this.insuredPartyType = partyType;
    if (this.insuredPartyType === PartyType.COMPANY) {
      this.isEIK = true;
      this.updatePartyNamesValidators(this.isEIK);
    } else {
      this.isEIK = false;
      this.updatePartyNamesValidators(this.isEIK);
    }
  }

  private _loadPaymentMethodDetails(): void {
    const data = this._paymentSessionService.getData();
    if (data !== null && data.searchPolicyStep && data.searchPolicyStep.searchPolicyFormData) {
      const paymentMethod = data.searchPolicyStep.searchPolicyFormData?.paymentMethods;
      if (paymentMethod.id === 1) {
        this.isEReceipt = true;
      } else {
        this.isEReceipt = false;
      }
    }
  }

  private _loadPolicyDetails(): void {
    this.pushPropertyPolicyFormGroup();
    this.pushMtplPolicyFormGroup();
    this.stepForm1.formGroup.updateValueAndValidity();
    this._nameAddressTrasMapService.updateNameFieldTranslation(this.stepForm1.formGroup);
    this._nameAddressTrasMapService.updatePayerNameFieldTranslation(this.stepForm1.formGroup);
    this._nameAddressTrasMapService.updateCompanyFieldTranslation(this.stepForm1.formGroup);
  }

  public updatePayerDetailsValidators(isPayerSame: boolean): void {
    if (isPayerSame) {
      this.stepForm1.controls.payerFirstName.setValidators(null);
      this.stepForm1.controls.payerMiddleName.setValidators(null);
      this.stepForm1.controls.payerLastName.setValidators(null);
      this.stepForm1.controls.payerFirstNameEn.setValidators(null);
      this.stepForm1.controls.payerMiddleNameEn.setValidators(null);
      this.stepForm1.controls.payerLastNameEn.setValidators(null);
    } else {
      this.stepForm1.controls.payerFirstName.setValidators([Validators.required, personName('bg')]);
      this.stepForm1.controls.payerMiddleName.setValidators([
        Validators.required,
        personName('bg'),
      ]);
      this.stepForm1.controls.payerLastName.setValidators([Validators.required, personName('bg')]);
      this.stepForm1.controls.payerFirstNameEn.setValidators([
        Validators.required,
        personName('en'),
      ]);
      this.stepForm1.controls.payerMiddleNameEn.setValidators([
        Validators.required,
        personName('en'),
      ]);
      this.stepForm1.controls.payerLastNameEn.setValidators([
        Validators.required,
        personName('en'),
      ]);
    }

    this.stepForm1.controls.payerFirstName.updateValueAndValidity();
    this.stepForm1.controls.payerMiddleName.updateValueAndValidity();
    this.stepForm1.controls.payerLastName.updateValueAndValidity();
    this.stepForm1.controls.payerFirstNameEn.updateValueAndValidity();
    this.stepForm1.controls.payerMiddleNameEn.updateValueAndValidity();
    this.stepForm1.controls.payerLastNameEn.updateValueAndValidity();
  }

  public updateMtplDetailsValidators(isEReceipt: boolean): void {
    if (!isEReceipt) {
      const policyFormGroupArray = this.policyDetailsArray.controls;
      const policyFormGroup = policyFormGroupArray[1] as FormGroup;

      policyFormGroup.controls.stickerBlankSlNo.setValidators(null);
      policyFormGroup.controls.stickerBlankNo.setValidators(null);
      policyFormGroup.controls.stickerStartDate.setValidators(null);
      policyFormGroup.controls.stickerEndDate.setValidators(null);

      policyFormGroup.controls.cardBlankSlNo.setValidators(null);
      policyFormGroup.controls.cardBlankNo.setValidators(null);
      policyFormGroup.controls.cardStartDate.setValidators(null);
      policyFormGroup.controls.cardEndDate.setValidators(null);

      policyFormGroup.controls.stickerBlankSlNo.updateValueAndValidity();
      policyFormGroup.controls.stickerBlankNo.updateValueAndValidity();
      policyFormGroup.controls.stickerStartDate.updateValueAndValidity();
      policyFormGroup.controls.stickerEndDate.updateValueAndValidity();

      policyFormGroup.controls.cardBlankSlNo.updateValueAndValidity();
      policyFormGroup.controls.cardBlankNo.updateValueAndValidity();
      policyFormGroup.controls.cardStartDate.updateValueAndValidity();
      policyFormGroup.controls.cardEndDate.updateValueAndValidity();

      this.stepForm1.controls.payerSameCheckbox.setValidators(null);
      this.stepForm1.controls.payerSameCheckbox.updateValueAndValidity();
      this.updatePayerDetailsValidators(true);
    }
  }

  public updatePartyNamesValidators(isEIK: boolean): void {
    if (isEIK) {
      this.stepForm1.controls.firstName.setValidators(null);
      this.stepForm1.controls.middleName.setValidators(null);
      this.stepForm1.controls.lastName.setValidators(null);
      this.stepForm1.controls.firstNameEn.setValidators(null);
      this.stepForm1.controls.middleNameEn.setValidators(null);
      this.stepForm1.controls.lastNameEn.setValidators(null);
      this.stepForm1.controls.companyName.setValidators([Validators.required, companyName('bg')]);
      this.stepForm1.controls.companyNameEn.setValidators([Validators.required, companyName('en')]);
    } else {
      this.stepForm1.controls.firstName.setValidators([Validators.required, personName('bg')]);
      this.stepForm1.controls.middleName.setValidators([Validators.required, personName('bg')]);
      this.stepForm1.controls.lastName.setValidators([Validators.required, personName('bg')]);
      this.stepForm1.controls.firstNameEn.setValidators([Validators.required, personName('en')]);
      this.stepForm1.controls.middleNameEn.setValidators([Validators.required, personName('en')]);
      this.stepForm1.controls.lastNameEn.setValidators([Validators.required, personName('en')]);
      this.stepForm1.controls.companyName.setValidators(null);
      this.stepForm1.controls.companyNameEn.setValidators(null);
    }

    this.stepForm1.controls.firstName.updateValueAndValidity();
    this.stepForm1.controls.middleName.updateValueAndValidity();
    this.stepForm1.controls.lastName.updateValueAndValidity();
    this.stepForm1.controls.firstNameEn.updateValueAndValidity();
    this.stepForm1.controls.middleNameEn.updateValueAndValidity();
    this.stepForm1.controls.lastNameEn.updateValueAndValidity();
    this.stepForm1.controls.companyName.updateValueAndValidity();
    this.stepForm1.controls.companyNameEn.updateValueAndValidity();
  }

  protected saveMtplData(): void {
    this._saveToSession();
  }

  private _saveToSession(): void {
    const data: InsuredPartyScreenStep = {
      insuredPartyFormData: this.stepForm1.value as FormData,
    };
    this._paymentSessionService.setInsuredPartyScreenData(data);
  }
}
