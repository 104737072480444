<div class="readMore-slidein-style">
  <button (click)="openReadMoreSlideIn()" maiaDocumentAction>Read More</button>
</div>

<maia-price-block [amount]="premiumAmount" currency="BGN">
  <svg class="icon-white-color" b2bFrontendTransportationExprCarNew maia-price-icon></svg>
  <div maia-price-title>Motor Third Party Liability Policy</div>
  <div maia-price-subtitle>First Installment {{ installment1 }} BGN</div>
</maia-price-block>
<br />

<maia-radio-group name="additionalCoverRiskGroup">
  <div class="addons-title-wrapper">
    <maia-label class="b2b-kdl-font-medium label-title"> Add Ons Group 1 </maia-label>
    <button
      class="active-button"
      maiaButton
      maiaButtonType="tertiary-delete"
      (click)="reset_filter_group1()"
    >
      Clear Selection</button
    >
  </div>
  <maia-tile
    *ngFor="let additionalCover of addsOneGroup1"
    class="m-t-b-24 tile-box"
    [ngClass]="{
      'addons-tile-selected': additionalCover.selected
    }"
  >
    <maia-radio-button
      [value]="additionalCover.id"
      (click)="updateSelectedCover(additionalCover)"
      [active]="additionalCover.selected"
    >
      <div class="payment-box">
        <label class="b2b-kdl-font-small">{{ additionalCover.additionalCoverRisk }}</label>
        <maia-radio-button-sublabel>Description of the cover here</maia-radio-button-sublabel>
        <maia-radio-button-sublabel>Description of the cover here</maia-radio-button-sublabel>
        <maia-amount-display
          class="b2b-kdl-font-medium-bold radio-tile"
          [value]="getAmoutValue(additionalCover.amount)"
          size="medium-bold"
          currency="{{ additionalCover.currency }}"
          [decimals]="2"
        ></maia-amount-display>
      </div>
    </maia-radio-button>
  </maia-tile>
</maia-radio-group>

<maia-divider></maia-divider>

<maia-radio-group name="additionalGroup">
  <div class="addons-title-wrapper">
    <maia-label class="b2b-kdl-font-medium label-title"> Add Ons Group 2 </maia-label>
    <div *ngIf="showHideCustomAmoutDiv">
      <button
        class="active-button"
        maiaButton
        maiaButtonType="tertiary-delete"
        (click)="reset_filter_group2()"
      >
        Clear Selection</button
      >
    </div>
  </div>
  <div class="coverTile">
    <maia-tile
      *ngFor="let additionalCover of addsOneGroup2"
      class="m-t-b-24 tile-box-group"
      [ngClass]="{'addons-tile-selected': additionalCover.selected}"
    >
      <maia-radio-button
        [value]="additionalCover.id"
        (click)="showHideCustomAmoutDiv = true; selectTile(additionalCover)"
        [active]="additionalCover.selected"
      >
        <div class="payment-box">
          <label class="b2b-kdl-font-small">{{ additionalCover.additionalCoverRisk }}</label>
          <maia-radio-button-sublabel>Description of the cover here</maia-radio-button-sublabel>
          <maia-radio-button-sublabel>Description of the cover here</maia-radio-button-sublabel>
          <maia-amount-display
            class="b2b-kdl-font-medium-bold radio-tile"
            [value]="getAmoutValue(additionalCover.amount)"
            size="medium-bold"
            currency="{{ additionalCover.currency }}"
            [decimals]="2"
          >
          </maia-amount-display>

          <button class="readmore-btn" maiaActionWithIcon size="small" (click)="openOneSlideIn()">
            <span class="b2b-kdl-font-small add-risks-slidein-style"
              >{{ additionalCover.additional }}
            </span>
            <span
              class="b2b-kdl-font-small add-risks-slidein-style"
              *ngIf="additionalCover.additional && additionalCover.selected && addPrem"
            >
              {{ addPrem }}&nbsp;{{ currency }}
            </span>
          </button>
        </div>
      </maia-radio-button>
    </maia-tile>
  </div>

  <br />

  <div *ngIf="showHideCustomAmoutDiv">
    <maia-tile
      class="tile-box"
      *ngFor="let checkBox of checkGroup"
      [ngClass]="{
        'addons-tile-selected': checkBox.checked
      }"
    >
      <maia-checkbox-group>
        <maia-checkbox id="checkbox" (click)="selectCheckTile(checkBox)">
          <label class="b2b-kdl-font-small">Abroad</label><br />
          <maia-label class="b2b-kdl-font-small-light subtitle-light-color alignContentTop"
            >Description of the cover here</maia-label
          >
          <maia-label class="b2b-kdl-font-small-light subtitle-light-color alignContentTop"
            >Description of the cover here</maia-label
          >
          <maia-amount-display
            class="b2b-kdl-font-medium-bold check-tile"
            [value]="getAmoutValue(checkBox.price)"
            size="medium-bold"
            currency="{{ checkBox.currency }}"
            [decimals]="2"
          ></maia-amount-display>
        </maia-checkbox>
      </maia-checkbox-group>
    </maia-tile>
  </div>
</maia-radio-group>

<maia-divider></maia-divider>

<maia-radio-group name="RiskGroup">
  <div class="addons-title-wrapper">
    <maia-label class="b2b-kdl-font-medium label-title"> Add Ons Group 3 </maia-label>
    <div *ngIf="showHideCustomAmout">
      <button
        class="active-button"
        maiaButton
        maiaButtonType="tertiary-delete"
        (click)="reset_filter_group3()"
      >
        Clear Selection</button
      >
    </div>
  </div>
  <maia-tile
    *ngFor="let additionalCover of addsOneGroup3"
    class="m-t-b-24 tile-box"
    [ngClass]="{
      'addons-tile-selected': additionalCover.selected
    }"
  >
    <maia-radio-button
      [value]="additionalCover.id"
      (click)="showHideCustomAmout = true; selectTile(additionalCover)"
      [active]="additionalCover.selected"
    >
      <div class="payment-box">
        <label class="b2b-kdl-font-small">{{ additionalCover.additionalCoverRisk }}</label>
        <maia-radio-button-sublabel>Description of the cover here</maia-radio-button-sublabel>
        <maia-radio-button-sublabel>Description of the cover here</maia-radio-button-sublabel>
        <maia-amount-display
          class="b2b-kdl-font-medium-bold radio-tile"
          [value]="getAmoutValue(additionalCover.amount)"
          size="medium-bold"
          currency="{{ additionalCover.currency }}"
          [decimals]="2"
        ></maia-amount-display>
      </div>
    </maia-radio-button>
  </maia-tile>

  <div class="blue-box" *ngIf="showHideCustomAmout">
    <maia-fluid-fluid-layout alignContentTop>
      <div class="fluid">
        <maia-label class="b2b-kdl-font-small cover-title m-b-8">Insuring Amount</maia-label>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color alignContentTop"
          >All seats are reserved
        </maia-label>
        <maia-input-select [ngModelOptions]="{standalone: true}" [(ngModel)]="valueCoverAmount">
          <input maiaInput placeholder="Select" [value]="valueCoverAmount || null" />
          <maia-option *ngFor="let c of coverAmount" [value]="c.value">{{ c.name }}</maia-option>
        </maia-input-select>
      </div>
    </maia-fluid-fluid-layout>
  </div>
</maia-radio-group>

<maia-divider></maia-divider>

<div>
  <button class="m-t-b-24" maiaButton maiaButtonType="primary" (click)="onContinue()">
    Continue </button
  >&nbsp;

  <button class="m-t-b-24" maiaButton maiaButtonType="primary" disabled *ngIf="!valueCoverAmount">
    Calculate Premium
  </button>
  <button
    class="m-t-b-24"
    maiaButton
    maiaButtonType="secondary"
    (click)="calPrem()"
    *ngIf="valueCoverAmount"
  >
    Calculate Premium
  </button>
</div>
