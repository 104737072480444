import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'hyperlink'})
export class HyperlinkPipe implements PipeTransform {
  public transform(value: string | undefined): string {
    if (value != undefined && value != null) {
      const reg = /((https|http):\/\/[^\s]+)/g;
      return value.replace(
        reg,
        (matchingString: string) => '[URL ' + matchingString + ']' + matchingString + '[/URL]',
      );
    }
    return '';
  }
}
