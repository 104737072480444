import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Logger} from '@atlas/logger';
import {B2C_ACTIVATE_ACCOUNT_TABLE, PAGINATION} from '@b2b-frontend/core';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController, PopUpOptions} from '@maia/pop-ups';
import {tap} from 'rxjs/operators';

import {
  ACCOUNT_ACTIVATED,
  AccountStatus,
  ACTIVATE_ACCOUNT,
  ALL_ACCOUNT_ACTIVATED,
  APIParams,
  B2CTableColumnHeadings,
  BLANK_STRING,
  CONFIRM,
  NumberConst,
  POPUP_SIZE_LARGE,
  UserLanguage,
} from '../../../constants/account-management';
import {ActivateDeactivateAccountsPayload} from '../../../models/ActivateDeactivateAccountsPayload';
import {CommonMessage} from '../../../models/CommonMessage';
import {SearchedParameters} from '../../../models/SearchBy';
import {UserAccountDetails} from '../../../models/UserAccountDetails';
import {UserActivateDeactivatePopup} from '../../../models/UserActivateDeactivatePopup';
import {UsersList} from '../../../models/UsersList';
import {AccountActivateDeactivateService} from '../../../services/account-activate-deactivate.service';
import {AccountManagementService} from '../../../services/account-management.service';
import {CommonService} from '../../../services/common.service';
import {CommonMessagePopupComponent} from '../../b2b/common-message-popup/common-message-popup.component';
import {ActivateAccountPopupComponent} from '../activate-account-popup/activate-account-popup.component';

@UntilDestroy()
@Component({
  selector: 'nje-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit {
  public currentPageNumber = PAGINATION.DEFAULT_PAGE;
  public totalPages = PAGINATION.PAGES;
  public tableRows: number[] = [];
  public tableColumns: number[] = [];
  public accountsList: UserAccountDetails[] = [];
  public isDataLoaded: boolean = false;

  public columnHeadings: string[] = B2CTableColumnHeadings;
  public activateAccountFactory: ComponentFactory<ActivateAccountPopupComponent>;
  public commonMessagePopupFactory: ComponentFactory<CommonMessagePopupComponent>;
  public _logger: Logger;
  public searchedTerm: string;

  public constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    public loggerFactory: LoggerFactory,
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    private readonly _accountManagementService: AccountManagementService,
    private readonly _accountActivateDeactivateService: AccountActivateDeactivateService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _commonService: CommonService,
  ) {}

  public ngOnInit(): void {
    this.initializeDefaultTable();
    this.getAccountsList();

    this._logger = this.loggerFactory.createLogger('ActivateAccountPopupComponent');
    this.activateAccountFactory = this.componentFactoryResolver.resolveComponentFactory(
      ActivateAccountPopupComponent,
    );
    this.commonMessagePopupFactory = this.componentFactoryResolver.resolveComponentFactory(
      CommonMessagePopupComponent,
    );
  }

  public initializeDefaultTable(): void {
    this._tableArray(this.tableRows, B2C_ACTIVATE_ACCOUNT_TABLE.DEFAULT_COMPLEX_ROWS);
    this._tableArray(this.tableColumns, B2C_ACTIVATE_ACCOUNT_TABLE.DEFAULT_COMPLEX_COLUMNS);
  }

  private _tableArray(simple: number[], numElements: number): void {
    for (let i = 0; i < numElements; i++) {
      simple.push(i);
    }
  }

  public searchAccounts(event: SearchedParameters): void {
    this.searchedTerm = event.searchTerm;
    this._setDefaultState();
    this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
    const queryParameters = `${APIParams.TYPE}${event.searchBy}${APIParams.AMPERSAND}${APIParams.IS_ACTIVE}${AccountStatus.DEACTIVATED}${APIParams.AMPERSAND}${APIParams.VALUE}${event.searchTerm}${APIParams.AMPERSAND}${APIParams.LANG}${UserLanguage.EN}`;

    this._accountManagementService
      .getSearchB2CAccountsToActivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this._prepareAccountsList([result.users[0]]);
          },
          (error: any) => {
            this.isDataLoaded = true;
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.accountsList.length = NumberConst.ARRAY_LENGTH_ZERO;
            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  public getAccountsList(): void {
    this.searchedTerm = BLANK_STRING;
    this._setDefaultState();
    const offset = (this.currentPageNumber - 1) * PAGINATION.PAGE_SIZE;
    const queryParameters = `${APIParams.IS_ACTIVE}${AccountStatus.DEACTIVATED}${APIParams.AMPERSAND}${APIParams.OFFSET}${offset}${APIParams.AMPERSAND}${APIParams.LIMIT}${PAGINATION.PAGE_SIZE}`;

    this._accountManagementService
      .getB2CAccountsToActivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this.totalPages = Math.ceil(result.count / PAGINATION.PAGE_SIZE);
            this._prepareAccountsList(result.users);
          },
          (error: any) => {
            this.isDataLoaded = true;
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.accountsList.length = NumberConst.ARRAY_LENGTH_ZERO;
            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  private _setDefaultState(): void {
    this.isDataLoaded = false;
    this.accountsList.length = NumberConst.ARRAY_LENGTH_ZERO;
    this.totalPages = PAGINATION.DEFAULT_PAGE;
  }

  private _prepareAccountsList(accountsData: UserAccountDetails[]): void {
    this.accountsList = accountsData;
    this._cdr.detectChanges();
  }

  public pageChange(event: number): void {
    if (event <= this.totalPages) {
      this.currentPageNumber = event;
      this.getAccountsList();
    }
  }

  public lastPage(): boolean {
    return this.currentPageNumber >= this.totalPages;
  }

  public activateAllAccounts(): void {
    const activateAccountPopupDetails: UserActivateDeactivatePopup = {
      accounts: this.accountsList,
      individualAccount: false,
    };
    this._accountActivateDeactivateService.setActivatePopupDetails(activateAccountPopupDetails);
    this.openPopup({title: CONFIRM, size: POPUP_SIZE_LARGE}, activateAccountPopupDetails);
  }

  public activateAccount(account: UserAccountDetails): void {
    const activateAccountPopupDetails: UserActivateDeactivatePopup = {
      accounts: [account],
      individualAccount: true,
    };
    this._accountActivateDeactivateService.setActivatePopupDetails(activateAccountPopupDetails);
    this.openPopup({title: ACTIVATE_ACCOUNT, size: POPUP_SIZE_LARGE}, activateAccountPopupDetails);
  }

  public openPopup(
    options: PopUpOptions,
    activateAccountPopupDetails: UserActivateDeactivatePopup,
  ): void {
    this._popUpCtrl
      .prepare(this.activateAccountFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: false,
        input: [],
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          activateAccountPopupDetails.individualAccount
            ? this._activiateIndividualAccount(activateAccountPopupDetails)
            : this._activateAllUserAccounts(activateAccountPopupDetails);
        }
      });
  }

  private _activiateIndividualAccount(activateAccountDetails: UserActivateDeactivatePopup): void {
    this._accountManagementService
      .activateB2CAccount(activateAccountDetails.accounts[0].id)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          () => {
            this.openSuccessContainer(activateAccountDetails);
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.totalPages = PAGINATION.DEFAULT_PAGE;
            this.getAccountsList();
          },
          (error: any) => {
            console.log('error :: ', error);
          },
        ),
      )
      .subscribe();
  }

  private _activateAllUserAccounts(activateAccountDetails: UserActivateDeactivatePopup): void {
    const activateAllAccountsPayload: ActivateDeactivateAccountsPayload = {
      userIds: activateAccountDetails.accounts.map(data => data.id),
    };
    this._accountManagementService
      .activateAllB2CAccount(activateAllAccountsPayload)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          () => {
            this.openSuccessContainer(activateAccountDetails);
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.totalPages = PAGINATION.DEFAULT_PAGE;
            this.getAccountsList();
          },
          (error: any) => {
            console.log('error :: ', error);
          },
        ),
      )
      .subscribe();
  }

  public openSuccessContainer(activateAccountDetails: UserActivateDeactivatePopup): void {
    const commonMessage: CommonMessage = {
      messageBody: activateAccountDetails.individualAccount
        ? ACCOUNT_ACTIVATED
        : ALL_ACCOUNT_ACTIVATED,
    };
    this._commonService.setcommonMessage(commonMessage);
    this.openSuccessPopup({title: BLANK_STRING, size: POPUP_SIZE_LARGE});
  }

  public openSuccessPopup(options: PopUpOptions): void {
    this._popUpCtrl
      .prepare(this.commonMessagePopupFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: false,
        input: [],
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        this._cdr.detectChanges();
      });
  }
}
