<!-- TODO: it will be modified at later stage -->
<maia-label>
  <label class="b2b-kdl-font-medium-bold">Lorum ipsum</label>
</maia-label>

<svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
<p class="description b2b-kdl-font-normal">Lorum ipsum - 10 000</p>
<p class="sub-description kdl-font-small-light"
  >Lorum ipsum dolor sit amet, consectetur adipiscing elit</p
>

<svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
<p class="description b2b-kdl-font-normal">Lorum ipsum - 400</p>
<p class="sub-description kdl-font-small-light"
  >Lorum ipsum dolor sit amet, consectetur adipiscing elit</p
>

<svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>
<p class="description b2b-kdl-font-normal">Lorum ipsum - 2000</p>
<p class="sub-description kdl-font-small-light"
  >Lorum ipsum dolor sit amet, consectetur adipiscing elit</p
>

<maia-label>
  <label class="b2b-kdl-font-medium-bold">Lorum ipsum</label>
</maia-label>

<svg b2bFrontendNavigationFuncCross class="navigation-cross"></svg>
<p class="description b2b-kdl-font-normal">Lorum ipsum</p>
<p class="sub-description kdl-font-small-light"
  >Lorum ipsum dolor sit amet, consectetur adipiscing elit</p
>
