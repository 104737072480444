import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileFuncDocumentSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFileFuncDocument]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M14.4 3.2V8h4.8"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M14.4 3.2H4.8v17.6h14.4V8l-4.8-4.8z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M8 8h3.2" data-color="color-2"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M8 12h8" data-color="color-2"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M8 16h8" data-color="color-2"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFuncDocumentSvgComponent {
}

@NgModule({
  declarations: [
    FileFuncDocumentSvgComponent,
  ],
  exports: [
    FileFuncDocumentSvgComponent,
  ],
})
export class FileFuncDocumentSvgModule {}
