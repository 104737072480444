import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

import {
  SelectedAgents,
  SelectedAgentsColumnHeadings,
  SimpleNumberArray,
} from '../../../../constants/account-creation';
import {RowsSelected} from '../../../../models/b2b/RowsSelected';
import {SelectedAgentsDetails} from '../../../../models/b2b/SelectedAgentsDetails';

const DEFAULT_COMPLEX_COLUMNS = 4;
const DEFAULT_COMPLEX_ROWS = 4;

@Component({
  selector: 'nje-selected-agents',
  templateUrl: './selected-agents.component.html',
  styleUrls: ['./selected-agents.component.scss'],
})
export class SelectedAgentsComponent implements OnInit {
  public tableRows: number[] = [];
  public tableColumns: number[] = [];
  public simpleNumberArray = SimpleNumberArray;
  public rowsSelected: RowsSelected[] = [];
  public reactiveFormBody: FormGroup;
  public reactiveFormFooter: FormGroup;
  public selectedAgents: SelectedAgentsDetails[] = [];

  public columnHeadings: string[] = SelectedAgentsColumnHeadings;

  public getAccountsList(): void {
    this.prepareAccountsList();
  }

  public prepareAccountsList(): void {
    this.selectedAgents = SelectedAgents;
  }

  public constructor() {
    this._tableArray(this.tableRows, DEFAULT_COMPLEX_ROWS);
    this._tableArray(this.tableColumns, DEFAULT_COMPLEX_COLUMNS);
    for (const value of this.simpleNumberArray) {
      this.rowsSelected.push({rowIndex: value, rowSelected: false});
    }
  }

  private _tableArray(simple: number[], numElements: number): void {
    for (let i = 0; i < numElements; i++) {
      simple.push(i);
    }
  }

  public ngOnInit(): void {
    this.getAccountsList();
    this.reactiveFormBody = new FormGroup({
      tableBody: new FormControl(),
    });
    this.reactiveFormFooter = new FormGroup({
      tableFooter: new FormControl(),
    });
  }
}
