import {Injectable} from '@angular/core';

import {IPartyIdValidator} from '../IPartyIdValidator';

@Injectable({
  providedIn: 'root',
})
export class LnchValidatorService implements IPartyIdValidator {
  private readonly pattern = /^\d{10}$/;

  private readonly LNCH_WEIGHTS = [21, 19, 17, 13, 11, 9, 7, 3, 1];

  private isValidControl(lnch: string): boolean {
    const digits = Array.from(lnch)
      .map(char => parseInt(char, 10))
      .slice(0, 9);
    const controlDigit = parseInt(Array.from(lnch)[9], 10);
    const weightedDigits = digits.map((d, index) => d * this.LNCH_WEIGHTS[index]);
    const weightedSum = weightedDigits.reduce((sum, d) => sum + d, 0);

    let calculatedControlDigit = weightedSum % 10;

    if (calculatedControlDigit === 10) {
      calculatedControlDigit = 0;
    }

    return calculatedControlDigit === controlDigit;
  }

  public isValidFormat(val: string): boolean {
    return this.pattern.exec(val) !== null;
  }

  public isValid(val: string): boolean {
    if (!this.isValidFormat(val)) {
      return false;
    }

    if (!this.isValidControl(val)) {
      return false;
    }

    return true;
  }
}
