import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncSortSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendActionFuncSort]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M5.09 9.41h13.82"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M2.5 4.23h19"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M7.68 14.59h8.64"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M10.27 19.77h3.46"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncSortSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncSortSvgComponent,
  ],
  exports: [
    ActionFuncSortSvgComponent,
  ],
})
export class ActionFuncSortSvgModule {}
