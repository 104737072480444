<njf-loader [hidden]="!showLoadingIcon"></njf-loader>
<form maiaForm>
  <maia-form-element class="m-b-0">
    <maia-input-group [ngClass]="{'user-type-selected': selectAllSelected | atlasBoolean}">
      <maia-fake-input class="user-type-select">
        <maia-checkbox
          class="user-type-checkbox"
          name="checkBoxAllForm"
          [(ngModel)]="selectAllSelected"
          (ngModelChange)="checkUncheckAll($event)"
          i18n="@@nje-b2b-frontend-resource-library-usertype-selectAll"
          >Select all</maia-checkbox
        >
      </maia-fake-input>
    </maia-input-group>
  </maia-form-element>
</form>

<form maiaForm [formGroup]="userTypeForm">
  <maia-form-group>
    <div *ngFor="let checkBoxPage of checkBoxPageList.controls; index as i">
      <ng-container [formGroup]="checkBoxPage">
        <maia-form-element class="m-b-0">
          <maia-input-group
            [ngClass]="{'user-type-selected': userTypesCheckBoxList[i] | atlasBoolean}"
          >
            <maia-fake-input maiaInput class="user-type-select" name="userTypes">
              <maia-checkbox
                class="user-type-checkbox"
                name="checkboxAloneForm"
                formControlName="checkBoxClicked"
                (onValueChange)="isAllUserTypesSelected($event, i)"
              >
                <span> {{ getTitle(userTypes[i]) }} </span>
              </maia-checkbox>
            </maia-fake-input>
          </maia-input-group>
        </maia-form-element>
      </ng-container>
    </div>
  </maia-form-group>
  <div class="step-form-button-bar">
    <maia-button-group>
      <button
        maiaButton
        maiaButtonType="primary"
        type="button"
        (click)="submitSelectedUserType()"
        i18n="@@nje-b2b-frontend-resource-library-usertype-submit"
        [disabled]="formDisabled"
        >Submit</button
      >
      <button
        maiaButton
        maiaButtonType="secondary"
        type="button"
        (click)="clearAllUserTypesSelection()"
        [disabled]="formDisabled"
        i18n="@@nje-b2b-frontend-resource-library-usertype-clear"
        >Clear</button
      >
      <button
        maiaButton
        maiaButtonType="secondary"
        (click)="cancelSlideIn()"
        type="button"
        i18n="@@nje-b2b-frontend-resource-library-usertype-cancel"
        >Cancel</button
      >
    </maia-button-group>
  </div>
</form>
