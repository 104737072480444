/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Boolean, Decimal} from '@atlas/businesstypes';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {MtplRoutePaths} from '../../helper/mtpl-route-paths';
import {PolicyInstalmentMode} from '../../models/PolicyInstalmentMode';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SlideInController} from '@maia/slide-ins';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';
import {InstalmentsSlideinComponent} from './instalments-slidein/instalments-slidein.component';
const instalmnt = 'First Instalment';

@Component({
  selector: 'nje-instalments',
  templateUrl: './instalments.component.html',
  styleUrls: ['./instalments.component.scss'],
})
@UntilDestroy()
export class InstalmentsComponent extends ScreenStepComponent implements OnInit {
  private readonly _logger: Logger;
  public displayDiscounts = false;
  public displayDiscountsWarning = false;
  public displayInputPercentage = false;
  public enableCascoInsurance = true;
  public isCascoInsurenceSelected = false;
  public disableContinueButton = false;
  public discountPercentageVal: number = 5;
  public policyInstalmentModes: PolicyInstalmentMode[];
  public val1: string = '(5%)';
  public val2: string = '(50%)';
  public val3: string = '(20%)';
  public discount1: string = '(5%)';
  public discount2: string = '(25%)';
  public discount3: string = '(15%)';
  public disableButtons: boolean = true;
  public instalmentsForm: FormGroup;
  private readonly _slideInOneFactory: ComponentFactory<InstalmentsSlideinComponent>;
  public checkStyle = '';
  public disabledCheckStyle = '';
  public checkboxx: Boolean = Boolean.FALSE;
  public disabledcheckbox: Boolean = Boolean.FALSE;

  public constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly _slideInCtrl: SlideInController,
    loggerFactory: LoggerFactory,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
  ) {
    super(activatedRoute);
    this._logger = loggerFactory.createLogger('InstalmentsSlideinComponent');
    this._slideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      InstalmentsSlideinComponent,
    );
  }

  public openSlideIn(): void {
    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {
          title: 'Lorem ipsum',
        },
        {
          withVisibleBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe({error: this._logger.error});
  }

  public toogleTag(): void {
    this.displayDiscounts = !this.displayDiscounts;
  }

  public updateDiscountPercentage(event: any) {
    return (this.discountPercentageVal = event.target.value);
  }

  public ngOnInit(): void {
    this.policyInstalmentModes = [
      {
        // TODO: this hardcoded data shall be removed during API integration
        id: '1',
        paymentMode: 'Single Payment',
        amount: '541.42',
        firstInstalmentAmount: '591.42',
        instalmentText: instalmnt,
        currency: 'BGN',
        selected: true,
      },
      {
        id: '2',
        paymentMode: 'Two Payments',
        amount: '614.30',
        instalmentText: instalmnt,
        firstInstalmentAmount: '313.66',
        currency: 'BGN',
        selected: false,
      },
      {
        id: '3',
        paymentMode: 'Four Payments',
        amount: '628.26',
        instalmentText: instalmnt,
        firstInstalmentAmount: '166.82',
        currency: 'BGN',
        selected: false,
      },
    ];
    this.instalmentsForm = this.formBuilder.group({
      declareDiscounts: [''],
      cascoInsurance: [''],
      discountPercentageCheck: ['', Validators.required],
      discountPercentage: ['5'],
    });
  }

  protected saveMtplData(): void {}

  public onContinue(): void {
    this.router.navigate(['../', MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS], {
      relativeTo: this.activatedRoute,
    });
  }

  public updateSelectedPaymentMode(id: string): void {
    this.policyInstalmentModes.forEach(instalmentMode => {
      instalmentMode.id === id
        ? (instalmentMode.selected = true)
        : (instalmentMode.selected = false);
    });
  }

  public getAmoutValue(amount: string): Decimal {
    return new Decimal(amount);
  }

  public onDeclareDiscounts(e: any): void {
    const isDeclareDiscounts = e.internalValue as boolean;
    this.displayDiscountsWarning = isDeclareDiscounts;
    this.enableCascoInsurance = !isDeclareDiscounts;
    this.disableContinueButton = isDeclareDiscounts;
    this.instalmentsForm.controls.cascoInsurance.setValue(isDeclareDiscounts);
    if (!isDeclareDiscounts) {
      this.instalmentsForm.controls.discountPercentageCheck.setValue(isDeclareDiscounts);
    }
  }

  public onInputPercentageCheck(e: any): void {
    this.displayInputPercentage = e.internalValue;
  }

  public stylingCheck(): void {
    if (this.checkboxx.equals(Boolean.TRUE)) {
      this.checkStyle = 'addons-tile-selected';
    } else {
      this.checkStyle = 'tile-unselected';
    }
  }

  public stylingdisabledCheck(): void {
    if (this.disabledcheckbox.equals(Boolean.TRUE)) {
      this.disabledCheckStyle = 'addons-tile-selected';
    } else {
      this.disabledCheckStyle = 'tile-disabled';
    }
  }

  public onCalculatePremium(): void {
    this.disableContinueButton = false;
  }
}
