import {NgModule} from '@angular/core';

import { AlertUtilConfirmationCheckSvgModule } from './@kdl/illustrations/alert-util-confirmation-check.component';
import { AlertUtilConfirmationErrorSvgModule } from './@kdl/illustrations/alert-util-confirmation-error.component';
import { FlagUtilAndorraSvgModule } from './@kdl/illustrations/flag-util-andorra.component';
import { FlagUtilBelgiumSvgModule } from './@kdl/illustrations/flag-util-belgium.component';
import { FlagUtilBulgariaSvgModule } from './@kdl/illustrations/flag-util-bulgaria.component';
import { FlagUtilCzechRepublicSvgModule } from './@kdl/illustrations/flag-util-czech-republic.component';
import { FlagUtilFranceSvgModule } from './@kdl/illustrations/flag-util-france.component';
import { FlagUtilGermanySvgModule } from './@kdl/illustrations/flag-util-germany.component';
import { FlagUtilHungarySvgModule } from './@kdl/illustrations/flag-util-hungary.component';
import { FlagUtilSlovakiaSvgModule } from './@kdl/illustrations/flag-util-slovakia.component';
import { ActionExprDoorInSvgModule } from './@kdl/icons/action-expr-door-in.component';
import { ActionExprDownloadSvgModule } from './@kdl/icons/action-expr-download.component';
import { ActionExprPdfSvgModule } from './@kdl/icons/action-expr-pdf.component';
import { ActionExprXlsSvgModule } from './@kdl/icons/action-expr-xls.component';
import { ActionFuncBinSvgModule } from './@kdl/icons/action-func-bin.component';
import { ActionFuncCalendarCheckSvgModule } from './@kdl/icons/action-func-calendar-check.component';
import { ActionFuncCalendarSvgModule } from './@kdl/icons/action-func-calendar.component';
import { ActionFuncClockSvgModule } from './@kdl/icons/action-func-clock.component';
import { ActionFuncFilterCircleSvgModule } from './@kdl/icons/action-func-filter-circle.component';
import { ActionFuncPlusCircleSvgModule } from './@kdl/icons/action-func-plus-circle.component';
import { ActionFuncSortSvgModule } from './@kdl/icons/action-func-sort.component';
import { ActionIndiVehicleDeliveryDateSvgModule } from './@kdl/icons/action-indi-vehicle-delivery-date.component';
import { AfiExprCardStackedSvgModule } from './@kdl/icons/afi-expr-card-stacked.component';
import { AlertExprCheckCircleSvgModule } from './@kdl/icons/alert-expr-check-circle.component';
import { AlertExprExclamationCircleSvgModule } from './@kdl/icons/alert-expr-exclamation-circle.component';
import { AlertFuncCheckCircleSvgModule } from './@kdl/icons/alert-func-check-circle.component';
import { AlertFuncCheckSvgModule } from './@kdl/icons/alert-func-check.component';
import { AlertFuncExclamationCircleSvgModule } from './@kdl/icons/alert-func-exclamation-circle.component';
import { AlertFuncExclamationTriangleSvgModule } from './@kdl/icons/alert-func-exclamation-triangle.component';
import { AlertFuncInfoCircleSvgModule } from './@kdl/icons/alert-func-info-circle.component';
import { AlertFuncQuestionCircleSvgModule } from './@kdl/icons/alert-func-question-circle.component';
import { CommunicationExprBookOpenSvgModule } from './@kdl/icons/communication-expr-book-open.component';
import { CommunicationExprBookStackSvgModule } from './@kdl/icons/communication-expr-book-stack.component';
import { CommunicationExprMailSvgModule } from './@kdl/icons/communication-expr-mail.component';
import { CommunicationFuncBookStackSvgModule } from './@kdl/icons/communication-func-book-stack.component';
import { CommunicationFuncImageSvgModule } from './@kdl/icons/communication-func-image.component';
import { CommunicationFuncMailSvgModule } from './@kdl/icons/communication-func-mail.component';
import { CommunicationFuncPhoneSvgModule } from './@kdl/icons/communication-func-phone.component';
import { DevicesFuncMobileSvgModule } from './@kdl/icons/devices-func-mobile.component';
import { DevicesIndiMobileFuelSvgModule } from './@kdl/icons/devices-indi-mobile-fuel.component';
import { DevicesIndiQrScanSvgModule } from './@kdl/icons/devices-indi-qr-scan.component';
import { FileExprDocumentAddSvgModule } from './@kdl/icons/file-expr-document-add.component';
import { FileExprDocumentUploadSvgModule } from './@kdl/icons/file-expr-document-upload.component';
import { FileExprDocumentSvgModule } from './@kdl/icons/file-expr-document.component';
import { FileFuncCloudUploadSvgModule } from './@kdl/icons/file-func-cloud-upload.component';
import { FileFuncDocumentSignedSvgModule } from './@kdl/icons/file-func-document-signed.component';
import { FileFuncDocumentSvgModule } from './@kdl/icons/file-func-document.component';
import { FileFuncNewspaperSvgModule } from './@kdl/icons/file-func-newspaper.component';
import { FileIndiDocumentsSwitchSvgModule } from './@kdl/icons/file-indi-documents-switch.component';
import { InformationExprCardsArrowsSvgModule } from './@kdl/icons/information-expr-cards-arrows.component';
import { InformationExprFallSvgModule } from './@kdl/icons/information-expr-fall.component';
import { InformationExprFastlaneSvgModule } from './@kdl/icons/information-expr-fastlane.component';
import { InformationExprHandCoinsSvgModule } from './@kdl/icons/information-expr-hand-coins.component';
import { InformationExprHandPersonSvgModule } from './@kdl/icons/information-expr-hand-person.component';
import { InformationExprHandsCrossSvgModule } from './@kdl/icons/information-expr-hands-cross.component';
import { InformationExprInvoiceSvgModule } from './@kdl/icons/information-expr-invoice.component';
import { InformationExprPaymentSvgModule } from './@kdl/icons/information-expr-payment.component';
import { InformationExprPhoneLockSvgModule } from './@kdl/icons/information-expr-phone-lock.component';
import { InformationExprQrSvgModule } from './@kdl/icons/information-expr-qr.component';
import { InformationExprShieldLockSvgModule } from './@kdl/icons/information-expr-shield-lock.component';
import { InformationExprStarListSvgModule } from './@kdl/icons/information-expr-star-list.component';
import { InformationExprUmbrellaSvgModule } from './@kdl/icons/information-expr-umbrella.component';
import { InformationExprWalletAccountSvgModule } from './@kdl/icons/information-expr-wallet-account.component';
import { InformationFuncCameraPlusSvgModule } from './@kdl/icons/information-func-camera-plus.component';
import { InformationFuncCardStackedSvgModule } from './@kdl/icons/information-func-card-stacked.component';
import { InformationFuncCoinsMinusSvgModule } from './@kdl/icons/information-func-coins-minus.component';
import { InformationFuncCoinsPlusSvgModule } from './@kdl/icons/information-func-coins-plus.component';
import { InformationFuncCoinsSvgModule } from './@kdl/icons/information-func-coins.component';
import { InformationFuncFaxPrinterSvgModule } from './@kdl/icons/information-func-fax-printer.component';
import { InformationFuncHandCoinsSvgModule } from './@kdl/icons/information-func-hand-coins.component';
import { InformationFuncHandsCrossSvgModule } from './@kdl/icons/information-func-hands-cross.component';
import { InformationFuncPercentageCoinsSvgModule } from './@kdl/icons/information-func-percentage-coins.component';
import { InformationFuncPinSvgModule } from './@kdl/icons/information-func-pin.component';
import { InformationFuncSearchSvgModule } from './@kdl/icons/information-func-search.component';
import { InformationFuncStarListSvgModule } from './@kdl/icons/information-func-star-list.component';
import { NavigationFuncArrowSvgModule } from './@kdl/icons/navigation-func-arrow.component';
import { NavigationFuncBriefcaseSvgModule } from './@kdl/icons/navigation-func-briefcase.component';
import { NavigationFuncChevronSvgModule } from './@kdl/icons/navigation-func-chevron.component';
import { NavigationFuncCrossSvgModule } from './@kdl/icons/navigation-func-cross.component';
import { NavigationFuncHomeSvgModule } from './@kdl/icons/navigation-func-home.component';
import { NavigationFuncLiveHelpSvgModule } from './@kdl/icons/navigation-func-live-help.component';
import { NavigationFuncOpenInNewSvgModule } from './@kdl/icons/navigation-func-open-in-new.component';
import { PlacesExprFactorySvgModule } from './@kdl/icons/places-expr-factory.component';
import { PlacesExprHouseInteriorSvgModule } from './@kdl/icons/places-expr-house-interior.component';
import { PlacesExprHouseTreeSvgModule } from './@kdl/icons/places-expr-house-tree.component';
import { PlacesExprHouseSvgModule } from './@kdl/icons/places-expr-house.component';
import { PlacesExprLandTreesSvgModule } from './@kdl/icons/places-expr-land-trees.component';
import { PlacesFuncFactorySvgModule } from './@kdl/icons/places-func-factory.component';
import { PlacesFuncHouseSvgModule } from './@kdl/icons/places-func-house.component';
import { SocialExprGroupSvgModule } from './@kdl/icons/social-expr-group.component';
import { SocialExprPeopleSvgModule } from './@kdl/icons/social-expr-people.component';
import { SocialExprPersonTieSvgModule } from './@kdl/icons/social-expr-person-tie.component';
import { SocialExprPersonSvgModule } from './@kdl/icons/social-expr-person.component';
import { SocialFuncPeopleDuoSvgModule } from './@kdl/icons/social-func-people-duo.component';
import { SocialFuncPersonArrowInSvgModule } from './@kdl/icons/social-func-person-arrow-in.component';
import { SocialFuncPersonReturnArrowSvgModule } from './@kdl/icons/social-func-person-return-arrow.component';
import { SocialFuncPersonTieSvgModule } from './@kdl/icons/social-func-person-tie.component';
import { TransportationExprCarCrashSvgModule } from './@kdl/icons/transportation-expr-car-crash.component';
import { TransportationExprCarNewSvgModule } from './@kdl/icons/transportation-expr-car-new.component';
import { TransportationExprCarSvgModule } from './@kdl/icons/transportation-expr-car.component';
import { TransportationExprHayMakerSvgModule } from './@kdl/icons/transportation-expr-hay-maker.component';
import { TransportationExprPlaneSvgModule } from './@kdl/icons/transportation-expr-plane.component';
import { TransportationExprSemitrailerEmptySvgModule } from './@kdl/icons/transportation-expr-semitrailer-empty.component';
import { TransportationExprTransporterSvgModule } from './@kdl/icons/transportation-expr-transporter.component';
import { TransportationExprTruckShieldSvgModule } from './@kdl/icons/transportation-expr-truck-shield.component';
import { TransportationExprWheelSvgModule } from './@kdl/icons/transportation-expr-wheel.component';
import { TransportationFuncCarSvgModule } from './@kdl/icons/transportation-func-car.component';
import { TransportationFuncPlaneSvgModule } from './@kdl/icons/transportation-func-plane.component';
import { TransportationFuncWheelSvgModule } from './@kdl/icons/transportation-func-wheel.component';
import { TransportationIndiVehicleLicensePlateSvgModule } from './@kdl/icons/transportation-indi-vehicle-license-plate.component';
import { TransportationIndiVehicleOrderedSvgModule } from './@kdl/icons/transportation-indi-vehicle-ordered.component';

@NgModule({
  exports: [
    InformationExprWalletAccountSvgModule,
    SocialExprGroupSvgModule,
    SocialExprPeopleSvgModule,
    AlertExprCheckCircleSvgModule,
    InformationExprShieldLockSvgModule,
    DevicesIndiQrScanSvgModule,
    TransportationExprTruckShieldSvgModule,
    AlertFuncQuestionCircleSvgModule,
    InformationFuncHandCoinsSvgModule,
    CommunicationFuncMailSvgModule,
    InformationExprHandPersonSvgModule,
    SocialFuncPeopleDuoSvgModule,
    ActionFuncClockSvgModule,
    TransportationExprHayMakerSvgModule,
    ActionFuncCalendarSvgModule,
    PlacesFuncFactorySvgModule,
    TransportationExprTransporterSvgModule,
    TransportationFuncCarSvgModule,
    TransportationIndiVehicleLicensePlateSvgModule,
    ActionExprPdfSvgModule,
    FileFuncDocumentSvgModule,
    CommunicationFuncBookStackSvgModule,
    CommunicationExprBookOpenSvgModule,
    FileFuncDocumentSignedSvgModule,
    CommunicationExprBookStackSvgModule,
    InformationExprStarListSvgModule,
    InformationFuncStarListSvgModule,
    NavigationFuncBriefcaseSvgModule,
    NavigationFuncOpenInNewSvgModule,
    NavigationFuncChevronSvgModule,
    ActionFuncBinSvgModule,
    ActionFuncPlusCircleSvgModule,
    FileExprDocumentUploadSvgModule,
    InformationExprFastlaneSvgModule,
    TransportationFuncPlaneSvgModule,
    SocialFuncPersonTieSvgModule,
    InformationFuncCoinsSvgModule,
    InformationFuncCoinsPlusSvgModule,
    InformationFuncCoinsMinusSvgModule,
    FileFuncCloudUploadSvgModule,
    NavigationFuncLiveHelpSvgModule,
    CommunicationFuncPhoneSvgModule,
    FileExprDocumentSvgModule,
    AlertUtilConfirmationErrorSvgModule,
    AlertUtilConfirmationCheckSvgModule,
    ActionFuncSortSvgModule,
    ActionFuncFilterCircleSvgModule,
    AlertFuncCheckSvgModule,
    AlertFuncCheckCircleSvgModule,
    NavigationFuncCrossSvgModule,
    ActionExprXlsSvgModule,
    FileExprDocumentAddSvgModule,
    FlagUtilBelgiumSvgModule,
    FlagUtilHungarySvgModule,
    FlagUtilGermanySvgModule,
    FlagUtilAndorraSvgModule,
    FlagUtilFranceSvgModule,
    FlagUtilCzechRepublicSvgModule,
    FlagUtilSlovakiaSvgModule,
    FlagUtilBulgariaSvgModule,
    NavigationFuncHomeSvgModule,
    AlertExprExclamationCircleSvgModule,
    InformationFuncCameraPlusSvgModule,
    TransportationExprCarSvgModule,
    TransportationExprCarNewSvgModule,
    PlacesExprFactorySvgModule,
    TransportationExprSemitrailerEmptySvgModule,
    TransportationExprPlaneSvgModule,
    InformationFuncHandsCrossSvgModule,
    InformationExprUmbrellaSvgModule,
    TransportationFuncWheelSvgModule,
    TransportationExprCarCrashSvgModule,
    TransportationExprWheelSvgModule,
    TransportationIndiVehicleOrderedSvgModule,
    DevicesIndiMobileFuelSvgModule,
    FileFuncNewspaperSvgModule,
    DevicesFuncMobileSvgModule,
    NavigationFuncArrowSvgModule,
    InformationExprInvoiceSvgModule,
    PlacesExprHouseTreeSvgModule,
    AlertFuncInfoCircleSvgModule,
    FileIndiDocumentsSwitchSvgModule,
    InformationFuncSearchSvgModule,
    PlacesExprHouseInteriorSvgModule,
    ActionFuncCalendarCheckSvgModule,
    CommunicationExprMailSvgModule,
    InformationFuncPercentageCoinsSvgModule,
    ActionIndiVehicleDeliveryDateSvgModule,
    InformationFuncPinSvgModule,
    CommunicationFuncImageSvgModule,
    InformationExprPaymentSvgModule,
    PlacesFuncHouseSvgModule,
    PlacesExprLandTreesSvgModule,
    InformationFuncCardStackedSvgModule,
    AfiExprCardStackedSvgModule,
    SocialExprPersonSvgModule,
    SocialExprPersonTieSvgModule,
    PlacesExprHouseSvgModule,
    InformationExprHandCoinsSvgModule,
    InformationExprCardsArrowsSvgModule,
    InformationFuncFaxPrinterSvgModule,
    AlertFuncExclamationCircleSvgModule,
    ActionExprDownloadSvgModule,
    ActionExprDoorInSvgModule,
    AlertFuncExclamationTriangleSvgModule,
    InformationExprPhoneLockSvgModule,
    InformationExprQrSvgModule,
    SocialFuncPersonArrowInSvgModule,
    SocialFuncPersonReturnArrowSvgModule,
    InformationExprHandsCrossSvgModule,
    InformationExprFallSvgModule,
  ],
})
export class μExampleSvgModule {
}
@NgModule({
  exports: [
    ActionFuncFilterCircleSvgModule,
    PlacesExprHouseSvgModule,
    TransportationExprCarNewSvgModule,
    InformationExprPaymentSvgModule,
    PlacesFuncHouseSvgModule,
    PlacesExprLandTreesSvgModule,
    PlacesExprHouseTreeSvgModule,
    TransportationIndiVehicleLicensePlateSvgModule,
    TransportationFuncCarSvgModule,
    InformationFuncCardStackedSvgModule,
    AfiExprCardStackedSvgModule,
    SocialExprPersonSvgModule,
    SocialExprPersonTieSvgModule,
    InformationExprHandCoinsSvgModule,
    InformationExprCardsArrowsSvgModule,
    AlertUtilConfirmationCheckSvgModule,
    InformationFuncFaxPrinterSvgModule,
    ActionExprDownloadSvgModule,
  ],
})
export class InstalmentPaymentsSvgModule {
}
