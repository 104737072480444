import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileExprDocumentUploadSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFileExprDocumentUpload]',
  template: '<svg:path d="M15 37v1.73c0 3.84-4.28 4.27-4.28 4.27h25.91A4.31 4.31 0 0 0 41 38.68V37z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M25.43 5H6.4v33.68A4.32 4.32 0 0 0 10.72 43" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M13.31 13.64H25.3"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M13.31 20.55H25.3"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M13.31 27.45h15.55"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M35.55 22.85V5.58"></svg:path><svg:path d="M29.51 11.63l6-6.05 6.05 6.05" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileExprDocumentUploadSvgComponent {
}

@NgModule({
  declarations: [
    FileExprDocumentUploadSvgComponent,
  ],
  exports: [
    FileExprDocumentUploadSvgComponent,
  ],
})
export class FileExprDocumentUploadSvgModule {}
