/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {CategoryInterface} from '../../../../contracts/NewsEvents';
import {Boolean} from '@atlas/businesstypes';
import {NEWS_EVENTS} from '../../../../constants/resource-management/contacts';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BLANK_VALUE} from '@b2b-frontend/core';

import {LibraryService} from '../../../../services/library.service';

export type checkboxType = 'checkboxForm';

export interface CheckboxInterface {
  value: Boolean;
}

export interface ITestPageCheckbox {
  checkboxForm: CheckboxInterface;
}

@Component({
  selector: 'add-edit-categories-slidein',
  templateUrl: './add-edit-categories-slidein.component.html',
  styleUrls: ['./add-edit-categories-slidein.component.scss'],
})
export class AddEditCategoriesSlideinComponent
  extends ModalContentComponent<CategoryInterface[], CategoryInterface[]>
  implements OnInit
{
  public categoryList: CategoryInterface[] = [];
  public finalCategoryList: CategoryInterface[] = [];
  public lang = sessionStorage.getItem(NEWS_EVENTS.LANG);
  public addCategory: boolean = false;
  public newCategory: string;
  public filterInputData: CategoryInterface[];

  public filterForm = new FormGroup({
    newCategory: new FormControl(undefined, [Validators.required]),
  });

  public constructor(
    public control: ModalControl<CategoryInterface[], CategoryInterface[]>,
    private readonly _chRef: ChangeDetectorRef,
    private readonly _libraryService: LibraryService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.filterInputData = this.control.input;
    this.categoryList = this.filterInputData;
  }

  public ifEnglishLang(): boolean {
    if (this.lang === NEWS_EVENTS.ENGLISH) {
      return true;
    } else if (this.lang === NEWS_EVENTS.BULGARIAN) {
      return false;
    }
    return false;
  }

  public cancelFilter(): void {
    this.control.cancel();
  }

  public applyFilter(): void {
    this.control.confirm(this.finalCategoryList);
  }

  public addNewCategory(): void {
    this.addCategory = true;
  }

  public addNewCategoryToList(): void {
    let categoryTitleEn: string = BLANK_VALUE;
    let categoryTitleBg: string = BLANK_VALUE;
    if (this.ifEnglishLang()) {
      categoryTitleEn = this.filterForm.controls.newCategory.value as string;
    } else {
      categoryTitleBg = this.filterForm.controls.newCategory.value as string;
    }
    const categoryObj: CategoryInterface = {
      i18n: {
        en: {
          title: categoryTitleEn,
        },
        bg: {
          title: categoryTitleBg,
        },
      },
    };
    this._libraryService.addProductCategory(categoryObj).subscribe(() => {
      this._getCategoryListFromDB();
    });
    this._chRef.detectChanges();
    this.filterForm.reset();
  }

  private _getCategoryListFromDB(): void {
    this._libraryService.getCategoryList().subscribe(response => {
      this.categoryList = response;
      this._chRef.detectChanges();
    });
  }

  public onKeyUp(category: CategoryInterface, value: any, i: number, id: number) {
    const updateCategoryObj = this.categoryList.filter(
      (cat: CategoryInterface) => cat.id === category.id,
    );
    if (updateCategoryObj[0].i18n) {
      if (this.ifEnglishLang()) {
        updateCategoryObj[0].i18n.en.title = value;
      } else {
        updateCategoryObj[0].i18n.bg.title = value;
      }
    }
    //check if the updated object already exist in the finalCategoryList
    const categoryFound = this.finalCategoryList.find(
      (value: CategoryInterface) => value.id === id,
    );
    if (categoryFound) {
      this.finalCategoryList = this.finalCategoryList.filter(
        (value: CategoryInterface) => value.id !== id,
      );
      this.finalCategoryList.push(updateCategoryObj[0]);
    } else {
      this.finalCategoryList.push(updateCategoryObj[0]);
    }
  }

  public getCategoryTitle(value: CategoryInterface): string | undefined {
    return this.ifEnglishLang() ? value.i18n?.en.title : value.i18n?.bg.title;
  }
}
