<form maiaForm [formGroup]="accountSuspensionForm" class="account-suspension-wrapper">
  <div class="date-selection-wrapper">
    <div class="start-date-wrapper">
      <maia-label class="b2b-kdl-font-small"> Start Date of Suspension </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <maia-input-date
          [minimum]="minDate"
          [maximum]="maxDate"
          name="suspensionStartDate"
          formControlName="suspensionStartDate"
        ></maia-input-date>
      </maia-form-element>
    </div>
    <div class="end-date-wrapper">
      <maia-label class="b2b-kdl-font-small"> End Date of Suspension </maia-label>
      <maia-form-element class="input-column m-t-8">
        <maia-input-date
          [minimum]="minDate"
          [maximum]="maxDate"
          name="suspensionEndDate"
          formControlName="suspensionEndDate"
        ></maia-input-date>
      </maia-form-element>
    </div>
  </div>
  <div class="reason-wrapper m-t-b-24">
    <div class="suspension-reason">
      <maia-label class="b2b-kdl-font-small m-t-24"> Reason of Suspension </maia-label>
      <maia-form-element class="input-column">
        <input maiaInput placeholder="Reason" class="m-t-8" formControlName="suspensionReason" />
      </maia-form-element>
    </div>
  </div>
</form>
