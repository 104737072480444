import {Injectable} from '@angular/core';
import {ApiService, API_END_POINT} from '@b2b-frontend/core';
import {Observable, Subject} from 'rxjs';
import {CategoryInterface, NewsEvents, UserType, ArtticleMediaType} from '../contracts/NewsEvents';
import {PinDates} from '../screens/resource-management/articles/news-and-events-pin-slidein/NewsAndEventsPinDates';

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  public emitter = new Subject<boolean>();

  public constructor(private readonly _apiService: ApiService) {}

  public getArticleList(): Observable<NewsEvents[]> {
    return this._apiService.get(API_END_POINT.ARTICLE_API);
  }

  public deleteArticle(id: number): Observable<NewsEvents> {
    return this._apiService.delete(`${API_END_POINT.ARTICLE_API}/${id}`);
  }

  public getCategoryList(): Observable<CategoryInterface[]> {
    return this._apiService.get(`${API_END_POINT.ARTICLE_CATEGORY}`);
  }

  public getUserTypeList(): Observable<UserType[]> {
    return this._apiService.get(`${API_END_POINT.USER_TYPE}`);
  }

  public addArticle(articleObj: NewsEvents): Observable<NewsEvents> {
    return this._apiService.postFiles(API_END_POINT.ARTICLE_API, articleObj);
  }

  public postArticlePin(pinDate: PinDates, id?: number): Observable<PinDates[]> {
    return this._apiService.postFiles(`${API_END_POINT.ARTICLE_API}/${id}/${'pinned'}`, pinDate);
  }

  public deletePinArticle(id: number): Observable<PinDates> {
    return this._apiService.delete(`${API_END_POINT.ARTICLE_API}/${id}/${'pinned'}`);
  }

  public updateNewCategory(categoryObj: CategoryInterface): Observable<CategoryInterface> {
    return this._apiService.postFiles(API_END_POINT.ARTICLE_CATEGORY, categoryObj);
  }

  public editCategory(categoryObj: CategoryInterface): Observable<CategoryInterface> {
    return this._apiService.patch(
      `${API_END_POINT.ARTICLE_CATEGORY}/${categoryObj.id}`,
      categoryObj,
    );
  }

  public deleteCategory(CategoryId: number): Observable<CategoryInterface> {
    return this._apiService.delete(`${API_END_POINT.ARTICLE_CATEGORY}/${CategoryId}`);
  }

  public editArticle(articleObj: NewsEvents): Observable<NewsEvents> {
    return this._apiService.patch(`${API_END_POINT.ARTICLE_API}/${articleObj.id}`, articleObj);
  }

  public getMediaFile(id: number): Observable<ArtticleMediaType> {
    return this._apiService.get(`${API_END_POINT.MEDIA}/${id}`);
  }
}
