import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {CASCO_ROUTE} from '@b2b-frontend-flows/casco';
import {CMS_ROUTE} from '@b2b-frontend-flows/cms';
import {MTPL_ROUTE} from '@b2b-frontend-flows/mtpl';
import {INSTALMENT_PAYMENTS_ROUTE} from '@b2b-frontend-flows/instalment-payments';
import {PROPERTY_INSURANCE_ROUTE} from '@b2b-frontend-flows/property-insurance';
import {TRAVEL_ROUTE} from '@b2b-frontend-flows/travel-insurance';
import {UAM_ROUTE} from '@b2b-frontend-flows/uam';
import {ZAKRILA_ROUTE} from '@b2b-frontend-flows/zakrila';

import {RoutePaths} from './constants/route-paths';
import {DashbordComponent} from './screen/dashbord/dashbord.component';
import {HomeComponent} from './screen/home/home.component';
import {AnnexationComponent} from './screen/tabs/annexation/annexation.component';
import {CancellationComponent} from './screen/tabs/cancellation/cancellation.component';
import {DuplicatesComponent} from './screen/tabs/duplicates/duplicates.component';
import {NewPolicyComponent} from './screen/tabs/new-policy/new-policy.component';
import {RenewalComponent} from './screen/tabs/renewal/renewal.component';

/**
 * All the routes of the flow should be defined here, we consume this then inside the exported
 * constant `ROUTES` which injects the flow as a lazy loaded route.
 *
 */
export const ROUTES: Routes = [
  {
    path: '',
    component: DashbordComponent,
    children: [
      {
        path: RoutePaths.HOME,
        component: HomeComponent,
        children: [
          {
            path: '',
            redirectTo: RoutePaths.NEW_POLICY,
          },
          {
            path: RoutePaths.NEW_POLICY,
            component: NewPolicyComponent,
          },
          {
            path: RoutePaths.RENEWAL,
            component: RenewalComponent,
          },
          {
            path: RoutePaths.ANNEXATION,
            component: AnnexationComponent,
          },
          {
            path: RoutePaths.CANCELLATION,
            component: CancellationComponent,
          },
          {
            path: RoutePaths.DUPLICATES,
            component: DuplicatesComponent,
          },
        ],
      },
      {
        path: RoutePaths.POLICIES,
        children: [
          {path: '', children: [TRAVEL_ROUTE]},
          {path: '', children: [PROPERTY_INSURANCE_ROUTE]},
          {path: '', children: [CASCO_ROUTE]},
          {path: '', children: [MTPL_ROUTE]},
          {path: '', children: [ZAKRILA_ROUTE]},
        ],
      },
      {
        path: RoutePaths.PROPERTY,
        children: [PROPERTY_INSURANCE_ROUTE],
      },
      {
        path: RoutePaths.ADMIN_CMS,
        children: [CMS_ROUTE],
      },
      {
        path: RoutePaths.CASCO,
        children: [CASCO_ROUTE],
      },
      {
        path: RoutePaths.ZAKRILA,
        children: [ZAKRILA_ROUTE],
      },
      {
        path: RoutePaths.PAYMENTS,
        children: [INSTALMENT_PAYMENTS_ROUTE],
      },
      {
        path: RoutePaths.MTPL,
        children: [MTPL_ROUTE],
      },
      {
        path: RoutePaths.ADMIN_UAM,
        children: [UAM_ROUTE],
      },
      {
        path: '',
        redirectTo: RoutePaths.HOME,
        pathMatch: 'full',
      },
    ],
  },
];

/**
 * The purpose of this private module is to:
 * - Define the routes once in the package which will be nested inside an application;
 * - Declare intern components or consume intern private modules of the package;
 * - Import the dependencies they dashboard requires;
 *
 * The purpose of this class is to be consumed inside the constant `ROUTE` which lazy
 * loads this private module.
 *
 * The class needs to be exported so it can be lazy loaded in the exported constant ROUTE.
 *
 * We don't export the first screen component because it shouldn't be consumed outside this flow.
 *
 * We import the second screen through it's private module for preventing this module to
 * become to bloated. This doesn't mean that the dependencies are lazy loaded.
 *
 */
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}

/**
 * The route we export is an empty path with the private module lazy loaded.
 */
export const DASHBORD_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./dashboard.routing.module').then(module => module.DashboardRoutingModule),
};
