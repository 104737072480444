import {Component} from '@angular/core';

import {RoutePaths} from '../../constants/route-paths';
import {AuthNavigationService} from '../../services/auth-navigation.service';

@Component({
  selector: 'nje-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss'],
})
export class ScanComponent {
  public constructor(private readonly _AuthNavigationService: AuthNavigationService) {}

  public onContinue(): void {
    this._AuthNavigationService.nextScreen(RoutePaths.VerifyAuth);
  }
}
