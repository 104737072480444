/* eslint-disable @typescript-eslint/no-unsafe-member-access*/
/* eslint-disable @typescript-eslint/no-unsafe-call*/
/* eslint-disable @typescript-eslint/no-non-null-assertion*/
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {Boolean} from '@atlas/businesstypes';
import {ArticleService} from '../../../../services/article.service';
import {UserType} from '../../../../contracts/NewsEvents';
import {NEWS_EVENTS} from '../../../../constants/resource-management/contacts';
import {FormArray, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'nje-usertype-selection-slidein',
  templateUrl: './usertype-selection-slidein.component.html',
  styleUrls: ['./usertype-selection-slidein.component.scss'],
})
export class UsertypeSelectionSlideinComponent
  extends ModalContentComponent<UserType[], UserType[]>
  implements OnInit
{
  public userTypes: UserType[];
  public showLoadingIcon: boolean = false;
  public selectAllSelected: Boolean = Boolean.FALSE;
  public selectedUsers: UserType[] = [];
  public lang = sessionStorage.getItem(NEWS_EVENTS.LANG);
  public userTypesCheckBoxList: Boolean[] = [];

  public userTypeForm = new FormGroup({
    checkBoxPageList: new FormArray([]),
  });

  public constructor(
    public control: ModalControl<UserType[], UserType[]>,
    private readonly _articleService: ArticleService,
    private readonly _chRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.showLoadingIcon = true;
    this.selectedUsers = this.control.input;
    this._getUserType();
  }

  private _getUserType(): void {
    this._articleService.getUserTypeList().subscribe(
      response => {
        this.userTypes = response;
        this.userTypes.forEach(element => {
          const userTypeSelected = this.selectedUsers.find(user => user.id === element.id);
          const checkBoxClicked = userTypeSelected ? Boolean.TRUE : Boolean.FALSE;
          this._addCheckBoxPageList(checkBoxClicked);
        });

        this.isAllUserTypesSelected();
        this.showLoadingIcon = false;
        this._chRef.detectChanges();
      },
      () => {
        this.showLoadingIcon = false;
      },
    );
  }

  public get checkBoxPageList(): FormArray {
    return this.userTypeForm.controls.checkBoxPageList as FormArray;
  }

  private _addCheckBoxPageList(checkBoxClicked: Boolean): void {
    const newCheckBox = new FormGroup({
      checkBoxClicked: new FormControl(checkBoxClicked),
    });
    this.userTypesCheckBoxList.push(checkBoxClicked);
    this.checkBoxPageList.push(newCheckBox);
  }

  public submitSelectedUserType(): void {
    this.selectedUsers = this.userTypes.filter((usertype, index) => {
      return this.checkBoxPageList.controls[index].value.checkBoxClicked.equals(Boolean.TRUE);
    });
    this.control.confirm(this.selectedUsers);
  }

  public cancelSlideIn(): void {
    this.control.cancel();
  }

  public ifEnglishLang(): boolean {
    return this.lang === NEWS_EVENTS.ENGLISH;
  }

  public getTitle(userType: UserType): string | undefined {
    return this.ifEnglishLang() && userType.i18n
      ? userType.i18n.en?.title
      : userType.i18n?.bg?.title;
  }

  public get formDisabled(): boolean {
    return this.checkBoxPageList.controls.every((formGroup: FormGroup) =>
      formGroup.value.checkBoxClicked.equals(Boolean.FALSE),
    );
  }

  public checkUncheckAll(checkEvent: Boolean): void {
    this.userTypesCheckBoxList = Array<Boolean>(this.userTypes.length).fill(checkEvent);
    this.checkBoxPageList.controls.forEach((formGroup: FormGroup) =>
      formGroup.controls.checkBoxClicked.setValue(this.selectAllSelected),
    );
  }

  public isAllUserTypesSelected(checkEvent?: Boolean, index?: number): void {
    if (checkEvent !== undefined) {
      this.userTypesCheckBoxList[index!] = checkEvent;
    }
    this.selectAllSelected =
      this.userTypes.filter((usertype, index) => {
        return this.checkBoxPageList.controls[index].value.checkBoxClicked.equals(Boolean.FALSE);
      }).length > 0
        ? Boolean.FALSE
        : Boolean.TRUE;
  }

  public clearAllUserTypesSelection(): void {
    this.selectAllSelected = Boolean.FALSE;
    this.userTypesCheckBoxList = [];
    this.checkBoxPageList.controls.forEach((formGroup: FormGroup) =>
      formGroup.controls.checkBoxClicked.setValue(Boolean.FALSE),
    );
  }
}
