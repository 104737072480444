import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Logger} from '@atlas/logger';
import {B2B_DEACTIVATE_ACCOUNT_TABLE, PAGINATION} from '@b2b-frontend/core';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController, PopUpOptions} from '@maia/pop-ups';
import {tap} from 'rxjs/operators';

import {
  ACCOUNT_DEACTIVATED,
  APIParams,
  B2BTableColumnHeadings,
  BLANK_STRING,
  DEACTIVATE_ACCOUNT,
  NumberConst,
  POPUP_SIZE_LARGE,
  UserLanguage,
  UserStatus,
} from '../../../constants/account-management';
import {ActivateDeactivateAccountsPayload} from '../../../models/ActivateDeactivateAccountsPayload';
import {AccountActivationDeactivation} from '../../../models/b2b/AccountActivationDeactivation';
import {UserAccountDetails} from '../../../models/b2b/UserAccountDetails';
import {UserActivateDeactivatePopup} from '../../../models/b2b/UserActivateDeactivatePopup';
import {UsersList} from '../../../models/b2b/UsersList';
import {CommonMessage} from '../../../models/CommonMessage';
import {SearchedParameters} from '../../../models/SearchBy';
import {AccountActivateDeactivateService} from '../../../services/account-activate-deactivate.service';
import {AccountManagementService} from '../../../services/account-management.service';
import {CommonService} from '../../../services/common.service';
import {CommonMessagePopupComponent} from '../common-message-popup/common-message-popup.component';
import {DeactivateAccountPopupComponent} from '../deactivate-account-popup/deactivate-account-popup.component';

@UntilDestroy()
@Component({
  selector: 'nje-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.scss'],
})
export class DeactivateAccountComponent implements OnInit {
  public currentPageNumber = PAGINATION.DEFAULT_PAGE;
  public totalPages = PAGINATION.PAGES;
  public tableRows: number[] = [];
  public tableColumns: number[] = [];
  public accountsList: UserAccountDetails[] = [];
  public isDataLoaded: boolean = false;

  public columnHeadings: string[] = B2BTableColumnHeadings;

  public deactivateAccountFactory: ComponentFactory<DeactivateAccountPopupComponent>;
  public commonMessagePopupFactory: ComponentFactory<CommonMessagePopupComponent>;
  public _logger: Logger;
  public searchedTerm: string;

  public constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    public loggerFactory: LoggerFactory,
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    private readonly _accountManagementService: AccountManagementService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _accountActivateDeactivateService: AccountActivateDeactivateService,
    private readonly _commonService: CommonService,
  ) {}

  public ngOnInit(): void {
    this.initializeDefaultTable();
    this.getAccountsList();
    this._logger = this.loggerFactory.createLogger('DeactivateAccountPopupComponent');
    this.deactivateAccountFactory = this.componentFactoryResolver.resolveComponentFactory(
      DeactivateAccountPopupComponent,
    );
    this.commonMessagePopupFactory = this.componentFactoryResolver.resolveComponentFactory(
      CommonMessagePopupComponent,
    );
  }

  public initializeDefaultTable(): void {
    this._tableArray(this.tableRows, B2B_DEACTIVATE_ACCOUNT_TABLE.DEFAULT_COMPLEX_ROWS);
    this._tableArray(this.tableColumns, B2B_DEACTIVATE_ACCOUNT_TABLE.DEFAULT_COMPLEX_COLUMNS);
  }

  private _tableArray(simple: number[], numElements: number): void {
    for (let i = 0; i < numElements; i++) {
      simple.push(i);
    }
  }

  public searchAccounts(event: SearchedParameters): void {
    this.searchedTerm = event.searchTerm;
    this._setDefaultState();
    this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
    const queryParameters = event.isUserSearch
      ? `${APIParams.TYPE}${event.searchBy}${APIParams.AMPERSAND}${APIParams.USER_STATUS}${UserStatus.ACTIVE}${APIParams.AMPERSAND}${APIParams.VALUE}${event.searchTerm}${APIParams.AMPERSAND}${APIParams.LANG}${UserLanguage.EN}`
      : `${APIParams.TYPE}${event.searchBy}${APIParams.AMPERSAND}${APIParams.USER_STATUS}${UserStatus.ACTIVE}${APIParams.AMPERSAND}${APIParams.VALUE}${event.searchTerm}${APIParams.AMPERSAND}${APIParams.LANG}${UserLanguage.EN}${APIParams.AMPERSAND}${APIParams.OFFSET}${PAGINATION.PAGES}${APIParams.AMPERSAND}${APIParams.LIMIT}${PAGINATION.PAGE_SIZE}`;

    event.isUserSearch
      ? this._userSearch(queryParameters)
      : this._organizationSearch(queryParameters);
  }

  private _userSearch(queryParameters: string): void {
    this._accountManagementService
      .getSearchB2BAccountsToDeactivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this._prepareAccountsList([result.users[0]]);
          },
          (error: any) => {
            this.isDataLoaded = true;
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.accountsList.length = NumberConst.ARRAY_LENGTH_ZERO;
            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  private _organizationSearch(queryParameters: string): void {
    this._accountManagementService
      .getOrganizationSearchB2BAccountsToDeactivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this._prepareAccountsList([result.users[0]]);
          },
          (error: any) => {
            this.isDataLoaded = true;
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.accountsList.length = NumberConst.ARRAY_LENGTH_ZERO;
            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  public getAccountsList(): void {
    this.searchedTerm = BLANK_STRING;
    this._setDefaultState();
    const offset = (this.currentPageNumber - 1) * PAGINATION.PAGE_SIZE;
    const queryParameters = `${APIParams.USER_STATUS}${UserStatus.ACTIVE}${APIParams.AMPERSAND}${APIParams.OFFSET}${offset}${APIParams.AMPERSAND}${APIParams.LIMIT}${PAGINATION.PAGE_SIZE}`;
    this._accountManagementService
      .getB2BAccountsToDeactivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this.totalPages = Math.ceil(result.count / PAGINATION.PAGE_SIZE);
            this._prepareAccountsList(result.users);
          },
          (error: any) => {
            this.isDataLoaded = true;
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.accountsList.length = NumberConst.ARRAY_LENGTH_ZERO;
            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  private _setDefaultState(): void {
    this.isDataLoaded = false;
    this.accountsList.length = NumberConst.ARRAY_LENGTH_ZERO;
    this.totalPages = PAGINATION.DEFAULT_PAGE;
  }

  private _prepareAccountsList(accountsData: UserAccountDetails[]): void {
    this.accountsList = accountsData;
    this._cdr.detectChanges();
  }

  public pageChange(event: number): void {
    if (event <= this.totalPages) {
      this.getAccountsList();
    }
  }

  public lastPage(): boolean {
    return this.currentPageNumber >= this.totalPages;
  }

  public deactivateAccount(account: UserAccountDetails): void {
    const deactivateAccountPopupDetails: UserActivateDeactivatePopup = {
      accounts: [account],
    };
    this._accountActivateDeactivateService.setDeactivatePopupDetails(deactivateAccountPopupDetails);
    this.openPopup({title: DEACTIVATE_ACCOUNT, size: POPUP_SIZE_LARGE}, account);
  }

  public openPopup(options: PopUpOptions, account: UserAccountDetails): void {
    this._popUpCtrl
      .prepare(this.deactivateAccountFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: false,
        input: [],
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<AccountActivationDeactivation>) => {
        if (response.resolution === ModalResolution.CONFIRMED && response.result) {
          this._deactivateIndividualAccount(response.result);
        }
      });
  }

  private _deactivateIndividualAccount(deactivationData: AccountActivationDeactivation): void {
    const deactivateAccountPayload: ActivateDeactivateAccountsPayload = {
      userIds: [deactivationData.userData.accounts[0].id],
      deactivationDate: deactivationData.actionDate,
    };
    this._accountManagementService
      .deactivateB2BAccount(deactivateAccountPayload)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          () => {
            this._openSuccessContainer();
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.totalPages = PAGINATION.DEFAULT_PAGE;
            this.getAccountsList();
          },
          (error: any) => {
            console.log('error :: ', error);
          },
        ),
      )
      .subscribe();
  }

  private _openSuccessContainer(): void {
    const commonMessage: CommonMessage = {
      messageBody: ACCOUNT_DEACTIVATED,
    };
    this._commonService.setcommonMessage(commonMessage);
    this.openSuccessPopup({title: BLANK_STRING, size: POPUP_SIZE_LARGE});
  }

  public openSuccessPopup(options: PopUpOptions): void {
    this._popUpCtrl
      .prepare(this.commonMessagePopupFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: false,
        input: [],
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        this._cdr.detectChanges();
      });
  }
}
