import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationFuncImageSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendCommunicationFuncImage]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path fill="none" stroke-miterlimit="10" d="M2 2h20v20H2z"></svg:path><svg:path data-color="color-2" data-stroke="none" stroke="none" d="M5 17l3-5 3 3 4-6 4 8H5z"></svg:path><svg:circle data-color="color-2" data-stroke="none" cx="9.5" cy="7.5" r="1.5" stroke="none"></svg:circle></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationFuncImageSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationFuncImageSvgComponent,
  ],
  exports: [
    CommunicationFuncImageSvgComponent,
  ],
})
export class CommunicationFuncImageSvgModule {}
