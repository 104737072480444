<div class="result-table">
  <maia-table>
    <maia-table-header>
      <maia-table-row>
        <maia-table-cell
          class="b2b-kdl-font-small-bold subtitle-light-color"
          *ngFor="let columnHeading of columnHeadings"
        >
          {{ columnHeading }}
        </maia-table-cell>
      </maia-table-row>
    </maia-table-header>
    <maia-table-body blueOnSmall>
      <maia-table-row *ngFor="let module of modulesList">
        <maia-table-cell
          class="b2b-kdl-font-small title-dark-color"
          [maiaTableHeaderValue]="'Module Number'"
        >
          {{ module?.moduleNumber }}
        </maia-table-cell>
        <maia-table-cell
          class="b2b-kdl-font-small title-dark-color"
          [maiaTableHeaderValue]="'Module Name'"
        >
          {{ module?.moduleName }}
        </maia-table-cell>
        <maia-table-cell
          class="b2b-kdl-font-small title-dark-color"
          [maiaTableHeaderValue]="'Sub module Name'"
        >
          {{ module?.subModuleName }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          <button maiaButton maiaButtonType="tertiary" (click)="addModule(module)">Add</button>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
</div>

<div class="pagination-wrapper" *ngIf="totalPages > 1">
  <maia-pagination
    general
    [(page)]="currentPageNumber"
    [totalPages]="totalPages"
    [lastPage]="lastPage()"
    (pageChange)="pageChange($event)"
  ></maia-pagination>
</div>
