import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FindDeviceService {
  constructor() {}

  public checkIsItMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
      ? true
      : false;
  }
}
