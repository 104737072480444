import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprPlaneSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationExprPlane]',
  template: '<svg:path d="M22.55 26.84l-2.77 13.81h5.35l7.11-17" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M25.59 13.59l-9.38-6.24-4.76 1.58 6.26 7.3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M42.8 16.33a3.56 3.56 0 0 0-4.46-2.24h0l-24.63 8.13L9 19.46l-4 1.36 3.85 7.34h9.78l22-7.32a3.57 3.57 0 0 0 2.23-4.46z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprPlaneSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprPlaneSvgComponent,
  ],
  exports: [
    TransportationExprPlaneSvgComponent,
  ],
})
export class TransportationExprPlaneSvgModule {}
