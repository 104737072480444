import {Component} from '@angular/core';
import {PROPERTY_POLICY_ERROR} from '../../constants/property-insurance-constants';

@Component({
  selector: 'nje-property-policy-error',
  templateUrl: './property-policy-error.component.html',
  styleUrls: ['./property-policy-error.component.scss'],
})
export class PropertyPolicyErrorComponent {
  public paymentErrorMsg: string = PROPERTY_POLICY_ERROR.PAYMENT_ERROR_MSG;
}
