import {CityApiData} from '@b2b-frontend/core';
import {Owner, QuickVehicleAndOwnerDTO} from '../../../contracts/quick-vehicle-interface';
import {MockCityPostCode, MockVehicle, MockVinRegDTO} from './vehicle-details-mockInterface';

export const MOCK_VEHICLE_TYPE: MockVehicle[] = [
  {id: 1, name: 'SUV'},
  {
    id: 2,
    name: 'Sedan',
  },
  {
    id: 3,
    name: 'Coupe',
  },
];

export const MAKE_LIST: MockVehicle[] = [
  {id: 1, name: 'BMW'},
  {
    id: 2,
    name: 'Ford',
  },
  {
    id: 3,
    name: 'Nissan',
  },
];

export const BMW_LIST: MockVehicle[] = [
  {id: 1, name: 'BMW X1'},
  {
    id: 2,
    name: 'BMW 3 Series',
  },
  {
    id: 3,
    name: 'BMW X7',
  },
];

export const FORD_LIST: MockVehicle[] = [
  {id: 1, name: 'Ford EcoSport'},
  {
    id: 2,
    name: 'Ford Aspire',
  },
  {
    id: 3,
    name: 'Ford Endeavour',
  },
];

export const NISSAN_LIST: MockVehicle[] = [
  {id: 1, name: 'Nissan 350Z'},
  {
    id: 2,
    name: 'Nissan Armada',
  },
  {
    id: 3,
    name: 'Nissan GT-R',
  },
];

export const PERSON_TYPE_LIST: MockVehicle[] = [
  {id: 1, name: ' Physical entity'},
  {
    id: 2,
    name: ' Legal entity',
  },
];

export const CITY_AUOTOCOMPLETE_LIST: MockCityPostCode[] = [
  {id: 1, title: 'Sofia', postCode: '1000'},
  {id: 2, title: 'Novi Iskar', postCode: '1280'},
  {id: 3, title: 'Bankya', postCode: '1320'},
  {id: 4, title: 'Buhovo', postCode: '1830'},
];

export enum Manufactures {
  BMW = 'BMW',
  FORD = 'Ford',
  NISSAN = 'Nissan',
}

export const IPID_LINK =
  'https://www.dzi.bg/attachments/Product/30/download/8104-Singleitrip-IPID-0.pdf';
export const READ_MORE_LINK =
  'https://www.dzi.bg/attachments/Product/30/download/8104-Singleitrip-IPID-0.pdf';

export const MOCK_MAKE: string = 'BMW';

export const MOCK_INVALID_IDNUMBER: string = '1123169263';

export const MOCK_VALID_EGN_NUMBER: string = '8209296425';

export const MOCK_VALID_EIK_NUMBER: string = '523169263';

export const MOCK_VIN_DATA: QuickVehicleAndOwnerDTO = {
  vehicle: {
    identificationNo: '123456',
    chassis: 'mock_value',
    engineNumber: 'mock_value',
    carType: 'Sedan',
    carSubType: 'mock_value',
    make: 'Nissan',
    model: 'Juke Tenna',
    category: 'mock_value',
    firstRegDate: '2019-01-06',
    prodYear: 'mock_value',
    regType: 'mock_value',
    prodType: 'mock_value',
    totalWeight: 'mock_value',
    engineCapacity: 'mock_value',
    fuelType: 'mock_value',
    enginePower: '93',
    doorNum: '4',
    seatsNum: '5',
    carColor: 'grey',
    paintType: 'mock_value',
    windowColor: 'mock_value',
    carUsage: 'mock_value',
    dataSource: 'mock_value',
  },
  owner: {
    personalData: {
      personType: 'Person',
      personRoleType: '7523169263',
      name: 'mock_value',
      age: '29',
      pinType: 'mock_value',
      pin: '1000',
      address: {
        country: 'mock_value',
        city: 'Sofia',
        quarter: 'mock_value',
        street: 'mock_value',
        streetNo: 'mock_value',
        block: 'mock_value',
        entrance: 'mock_value',
        floor: 'mock_value',
        apartment: 'mock_value',
        phoneNumber: 'mock_value',
        email: 'mock_value',
        addressType: 'mock_value',
        notes: 'mock_value',
      },
    },
    dataSource: 'mock_value',
    customerId: 'mock_value',
  },
};

export const MOCK_VRN_DATA: QuickVehicleAndOwnerDTO = {
  vehicle: {
    identificationNo: '123456',
    chassis: 'mock_value',
    engineNumber: 'mock_value',
    carType: 'SUV',
    carSubType: 'mock_value',
    make: 'BMW',
    model: 'BMW X1',
    category: 'mock_value',
    firstRegDate: '2021-09-10',
    prodYear: 'mock_value',
    regType: 'mock_value',
    prodType: 'mock_value',
    totalWeight: 'mock_value',
    engineCapacity: 'mock_value',
    fuelType: 'mock_value',
    enginePower: '85',
    doorNum: '4',
    seatsNum: '4',
    carColor: 'gold',
    paintType: 'mock_value',
    windowColor: 'mock_value',
    carUsage: 'mock_value',
    dataSource: 'mock_value',
  },
  owner: {
    personalData: {
      personType: 'Company',
      personRoleType: '523169263',
      name: 'mock_value',
      pinType: 'mock_value',
      pin: '1000',
      address: {
        country: 'mock_value',
        city: 'Sofia',
        quarter: 'mock_value',
        street: 'mock_value',
        streetNo: 'mock_value',
        block: 'mock_value',
        entrance: 'mock_value',
        floor: 'mock_value',
        apartment: 'mock_value',
        phoneNumber: 'mock_value',
        email: 'mock_value',
        addressType: 'mock_value',
        notes: 'mock_value',
      },
    },
    dataSource: 'mock_value',
    customerId: 'mock_value',
  },
};
export const MOCK_EGN_OWNER_DATA: Owner = {
  personalData: {
    personType: 'Person',
    personRoleType: '7523169263',
    name: 'mock_value',
    age: '29',
    pinType: 'mock_value',
    pin: '1000',
    address: {
      country: 'mock_value',
      city: 'Sofia',
      quarter: 'mock_value',
      street: 'mock_value',
      streetNo: 'mock_value',
      block: 'mock_value',
      entrance: 'mock_value',
      floor: 'mock_value',
      apartment: 'mock_value',
      phoneNumber: 'mock_value',
      email: 'mock_value',
      addressType: 'mock_value',
      notes: 'mock_value',
    },
  },
  dataSource: 'mock_value',
  customerId: 'mock_value',
};

export const MOCK_EIK_OWNER_DATA: Owner = {
  personalData: {
    personType: 'Company',
    personRoleType: '523169263',
    name: 'mock_value',
    pinType: 'mock_value',
    pin: '1000',
    address: {
      country: 'mock_value',
      city: 'Sofia',
      quarter: 'mock_value',
      street: 'mock_value',
      streetNo: 'mock_value',
      block: 'mock_value',
      entrance: 'mock_value',
      floor: 'mock_value',
      apartment: 'mock_value',
      phoneNumber: 'mock_value',
      email: 'mock_value',
      addressType: 'mock_value',
      notes: 'mock_value',
    },
  },
  dataSource: 'mock_value',
  customerId: 'mock_value',
};

export const MOCK_VEHICLE_ID_NO: string = 'CBF12345';

export const MOCK_VEHICLE_REG_NO: string = 'CBF12345';

export const MOCK_EGN_NUMBER: string = '8209296425';

export const MOCK_LNCH_NUMBER: string = '1123169263';

export const MOCK_VIN_REG_NUMBERS: MockVinRegDTO[] = [
  {regNo: 'CA1111CA', idNo: '126111'},
  {regNo: 'mock_value', idNo: 'mock_value'},
  {regNo: 'CA3333CA', idNo: '126333'},
  {regNo: 'CA4444CA', idNo: '126444'},
];

export enum MockTypeMake {
  Type = '1',
  Make = 'AUDI',
}

export const MOCK_NUMBER: number = 1;

export const MOCK_STRING: string = '';

export const MOCK_CITY_DATA: CityApiData[] = [
  {
    city: 'Sofia',
    zip: '1000',
  },
];

export const SOFIA: string = 'Sofia';
