import {AbstractControl, ValidationErrors} from '@angular/forms';

export function nonLatinCyrillicLetters(control: AbstractControl): ValidationErrors | null {
  // eslint-disable-next-line
  const inputText = null == control.value ? '' : control.value;
  const isValid = /[a-zA-Z]/.test(inputText) === true || /[а-яА-Я]/.test(inputText) === true;
  // eslint-disable-next-line
  if (!isValid || inputText.indexOf('Є') > -1) {
    return {
      nonLatinCyrillicLetters: true,
    };
  } else {
    return null;
  }
}
