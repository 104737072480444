import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprCarSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationExprCar]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 4.8 4.8)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M42 20L40.231 6.736A2 2 0 0 0 38.249 5H9.751a2 2 0 0 0-1.982 1.736L6 20"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M14 37v6H5v-6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M43 37v6h-9v-6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M46 37H2V26.403c0-.908.309-1.79.877-2.499L6 20h36l3.123 3.904A4 4 0 0 1 46 26.403V37z"></svg:path><svg:circle data-color="color-2" fill="none" stroke-miterlimit="10" cx="38" cy="29" r="3"></svg:circle><svg:circle data-color="color-2" fill="none" stroke-miterlimit="10" cx="10" cy="29" r="3"></svg:circle><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M21 29h6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprCarSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprCarSvgComponent,
  ],
  exports: [
    TransportationExprCarSvgComponent,
  ],
})
export class TransportationExprCarSvgModule {}
