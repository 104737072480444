import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-price-covers-addons-slidein',
  templateUrl: './price-covers-addons-slidein.component.html',
  styleUrls: ['./price-covers-addons-slidein.component.scss'],
})
export class PriceCoversAddonsSlideinComponent extends ModalContentComponent<void> {
  public constructor(public control: ModalControl<void>) {
    super();
  }
}
