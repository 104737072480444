import {UntilDestroy} from '@atlas-angular/rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '@b2b-frontend/core';

import {Observable} from 'rxjs';
import {
  FindAllClientPoliciesInput,
  FindAllClientPoliciesOutput,
  FindIntermediaryAccountOutput,
  InsuranceType,
  PaymentMethod,
  SellerId,
} from '../../contracts/instalment-payment/instalment';
import {ApiEndpoints} from '../../constants/api-endpoints';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class PaymentsService {
  public constructor(private readonly _apiService: ApiService) {}

  public getPaymentMethods(): Observable<PaymentMethod[]> {
    return this._apiService.get(ApiEndpoints.PaymentMethods);
  }

  public getInsuranceTypes(): Observable<InsuranceType[]> {
    return this._apiService.get(ApiEndpoints.InsuranceTypes);
  }

  public getSellerIds(userId: string): Observable<SellerId[]> {
    return this._apiService.get(`${ApiEndpoints.SellerIds}/${userId}`);
  }

  public getClientPolicies(
    input: FindAllClientPoliciesInput,
  ): Observable<FindAllClientPoliciesOutput> {
    return this._apiService.post(ApiEndpoints.FindAllClientPolicies, input);
  }

  public getAgentBankAccountDetails(agentId: string): Observable<FindIntermediaryAccountOutput> {
    return this._apiService.get(
      `${ApiEndpoints.FindIntermediaryBankAccountDetails}/${agentId}/agent-bank-account-detail`,
    );
  }
}
