<div class="title">
  <div class="pay-success-div">
    <div class="confirmation-checks">
      <svg b2bFrontendAlertUtilConfirmationCheck class="confirmation-check"></svg>
    </div>
    <label class="success-text b2b-kdl-font-grand-bold title-dark-color"
      >Successful issuing of debit note</label
    >
    <div class="e-Receipt-button">
      <maia-button-group>
        <button maiaButton maiaButtonType="secondary">Download all Debit note</button>
        <button maiaButton maiaButtonType="primary">Print all Debit note</button>
      </maia-button-group>
    </div>
    <div class="home-button">
      <maia-button-group>
        <button maiaButton maiaButtonType="tertiary" (click)="navigateToHome()"
          >back to home</button
        >
      </maia-button-group>
    </div>
  </div>
  <div class="adjust">
    <div class="grey-box-div">
      <div class="icons-adjust">
        <img src="assets/img/logos-kbc.svg" />
        <div class="icons-right">
          <maia-avatar-display class="printer-icon-adjust">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg b2bFrontendInformationFuncFaxPrinter class="home-icon"></svg>
            </maia-avatar-svg>
          </maia-avatar-display>
        </div>
        <div>
          <maia-avatar-display class="download-icon-adjust">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg b2bFrontendActionExprDownload class="home-icon"></svg>
            </maia-avatar-svg>
          </maia-avatar-display>
        </div>
      </div>

      <div class="b2b-kdl-font-grand-bold b2b-kdl-color-primary-light">
        Debit note Receipt № 123456789012345 / 30.11.2020
      </div>

      <maia-divider></maia-divider>

      <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Insured Details </div>
      <div class="details">
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"> Insurer </div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">
            DZI General Insurance JSC</div
          >
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Email</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"> office@dzi.bg</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Pin</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">1234567890</div>
        </maia-fluid-fluid-layout>
        <maia-divider></maia-divider>
        <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Client </div>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Address</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >Sofia, 6 Alexander Alexandrov street</div
          >
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">E-Mail </div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">ivan.ivanov@abv.bg</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">PIN</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">1234567890</div>
        </maia-fluid-fluid-layout>

        <maia-divider></maia-divider>

        <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Insured </div>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Insured</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">,,PCP’’E</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">E-Mail </div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">office@company.bg</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">PIN</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">1234567890</div>
        </maia-fluid-fluid-layout>

        <maia-divider></maia-divider>

        <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Insurance Type </div>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Insurance Type</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Casco</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Policy №.</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >440118215017545 / 20.12.2020 r</div
          >
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Object</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">CB4337AA</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">No. of Installment</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">1</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Completed Installments</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"> 5/12 and 6/12</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Total due amount</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">1234.56 Bgn.</div>
        </maia-fluid-fluid-layout>
        <maia-divider></maia-divider>

        <div class="msg b2b-kdl-font-small-light subtitle-light-color">
          The total due amount according the BNB fixing of the payment date to all policies of this
          Debit Letter have to be transferred to the following current bank account.
        </div>

        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Receiver</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >DZI General Insurance” JSC</div
          >
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">IBAN</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"> BG94UBBS........</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"> BIC</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">UBBSBGSF</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Currency</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">BGN</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"> Due Date </div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"> 26.12.2018 r.</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"> Payment reason</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">
            Policy Number...............</div
          >
        </maia-fluid-fluid-layout>
        <maia-divider></maia-divider>

        <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Intermediary </div>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"> Intermediary</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">,,Uniconsult”JSC </div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"> Issuer</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"> ACO1416</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Agent Number </div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"> 12345678</div>
        </maia-fluid-fluid-layout>

        <maia-divider></maia-divider>

        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"> Date </div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"> 30.11.2020</div>
        </maia-fluid-fluid-layout>
      </div>
    </div>
  </div>
</div>
