<div class="message-box b2b-kdl-font-small m-b-24" *ngIf="step.inEdit | async">
  Changing values will result in premium recalulation
</div>

<form maiaForm>
  <maia-radio-group [(ngModel)]="selectedCoversId" name="cover">
    <maia-label>
      <label class="b2b-kdl-font-small title-dark-color"> Custom Covers </label>
    </maia-label>
    <div class="m-r-16 tile-row">
      <maia-tile
        class="m-t-b-24 addons-tile-unselected"
        [ngClass]="{'addons-tile-selected': showHideCustomAmoutDiv}"
      >
        <div class="tile-size">
          <maia-radio-button [value]="customCoverId" (click)="selectCustom()">
            <div class="box-div">
              <label class="b2b-kdl-font-small-light cover-title">{{
                customCoverData.coversName
              }}</label>
              <label class="b2b-kdl-font-small-light cover-sub-title">{{
                customCoverData.coverSubtitle
              }}</label>
              <maia-amount-display
                class="b2b-kdl-font-small-bold cover-amount-title"
                [value]="getAmoutValue(customCoverAmount)"
                size="medium-bold"
                currency="BGN"
                [decimals]="2"
              ></maia-amount-display>
              <label class="b2b-kdl-font-extra-small-light cover-plan-title">{{
                getAmountForDay('3 Peoples for 5 Days')
              }}</label>
            </div>
          </maia-radio-button>
          <button class="readmore-btn" maiaActionWithIcon size="small" (click)="openSlideIn()">
            <svg class="readmore-icon" b2bFrontendNavigationFuncOpenInNew maia-action-icon></svg>
            <span class="b2b-kdl-font-small cover-readmore">Read more</span>
          </button>
        </div>
      </maia-tile>

      <div class="blue-box" *ngIf="showHideCustomAmoutDiv">
        <maia-fluid-fluid-layout alignContentTop>
          <div class="fluid">
            <maia-label class="b2b-kdl-font-small cover-title m-b-8">Cover amount</maia-label>
            <maia-input-select [ngModelOptions]="{standalone: true}" [(ngModel)]="valueCoverAmount">
              <input maiaInput [value]="getCustomCoverAmount() || null" />
              <maia-option *ngFor="let c of coverAmount" [value]="c.value">{{
                c.name
              }}</maia-option>
            </maia-input-select>
          </div>
        </maia-fluid-fluid-layout>

        <div>
          <button class="m-t-b-24" maiaButton maiaButtonType="primary" (click)="enablePremium()"
            >Calculate</button
          >
        </div>
      </div>
    </div>

    <maia-label>
      <label class="b2b-kdl-font-small title-dark-color"> Fixed Covers </label>
    </maia-label>
    <maia-tile
      *ngFor="let cover of travelCoversList; let i = index"
      class="m-t-b-24 tile-box"
      [ngClass]="{
        'addons-tile-selected': travelCoversList[i].selectedStatus && !showHideCustomAmoutDiv
      }"
    >
      <div class="tile-size">
        <maia-radio-button [value]="cover.coverId" (click)="updateSelectedCover(i)">
          <div class="box-div">
            <label class="b2b-kdl-font-small-light cover-title">{{ cover.coversName }}</label>
            <label class="b2b-kdl-font-small-light cover-sub-title">{{
              cover.coverSubtitle
            }}</label>
            <maia-amount-display
              class="b2b-kdl-font-small-bold cover-amount-title"
              [value]="getAmoutValue(cover.amount)"
              size="medium-bold"
              currency="BGN"
              [decimals]="2"
            ></maia-amount-display>
            <label class="b2b-kdl-font-extra-small-light cover-plan-title">{{
              getAmountForDay(cover.amountForDay)
            }}</label>
          </div>
        </maia-radio-button>
        <button class="readmore-btn1" maiaActionWithIcon size="small" (click)="openSlideIn(cover)">
          <svg class="readmore-icon" b2bFrontendNavigationFuncOpenInNew maia-action-icon></svg>
          <span class="b2b-kdl-font-small cover-readmore">{{ cover.readMore }}</span>
        </button>
      </div>
    </maia-tile>
  </maia-radio-group>
  <button
    *ngIf="!(step.inEdit | async)"
    class="m-t-b-24"
    maiaButton
    maiaButtonType="primary"
    [disabled]="enableButton"
    (click)="saveAndContinue()"
    >Continue</button
  >
  <button
    *ngIf="!(step.inEdit | async)"
    class="m-l-24"
    maiaButton
    maiaButtonType="secondary"
    [disabled]="premiumButton"
    (click)="showAccordian()"
    >Calculate premium</button
  >

  <div *ngIf="this.step.inEdit | async">
    <maia-divider class="m-t-b-24"></maia-divider>
  </div>

  <div *ngIf="this.step.inEdit | async">
    <travel-addons (emitSelectedAddons)="emitSelectedAddons($event)"> </travel-addons>

    <div *ngIf="this.step.inEdit | async">
      <button class="m-r-24 m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveEdit()"
        >Save</button
      >
      <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
    </div>
  </div>
</form>
