import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  DESCRIPTION_LENGTH,
  DESCRIPTION_PATTERN,
  TITLE_LENGTH,
  TITLE_PATTERN,
} from '../../../../constants/resource-management/constant';

import {ProductPortfolio} from '../../../../contracts/library.interface';

/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
@Component({
  selector: 'nje-add-product-portfolio-slidein',
  templateUrl: './add-product-portfolio-slidein.component.html',
  styleUrls: ['./add-product-portfolio-slidein.component.scss'],
})
export class AddProductPortfolioSlideinComponent
  extends ModalContentComponent<ProductPortfolio, ProductPortfolio>
  implements OnInit
{
  public productPortfolioSlideinForm: FormGroup;
  public portfoloiObj: ProductPortfolio;
  public id: number;

  public titleLimit: number = TITLE_LENGTH;
  public descriptionLimit: number = DESCRIPTION_LENGTH;

  public constructor(
    private readonly _formBuilder: FormBuilder,
    public control: ModalControl<ProductPortfolio, ProductPortfolio>,
  ) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this.portfoloiObj = data;
    this.productPortfolioSlideinForm = this._initializeProductPortfolioSlideinForm(data);
  }

  public isNewProductPortfolio(): boolean {
    return this.control.input !== undefined;
  }

  private _initializeProductPortfolioSlideinForm(formdata?: ProductPortfolio): FormGroup {
    return (this.productPortfolioSlideinForm = this._formBuilder.group({
      portfolioId: new FormControl(formdata?.id),
      portfolioName: new FormControl(formdata?.i18n?.en?.title, [
        Validators.required,
        Validators.maxLength(TITLE_LENGTH),
        Validators.pattern(TITLE_PATTERN),
      ]),
      portfolioSubtitle: new FormControl(formdata?.i18n?.en?.description, [
        Validators.required,
        Validators.maxLength(DESCRIPTION_LENGTH),
        Validators.pattern(DESCRIPTION_PATTERN),
      ]),
      selectedStatus: new FormControl(formdata?.selectedStatus),
      libraryId: new FormControl(formdata?.libraryId),
    }));
  }

  public addOrUpdateProductPortfolio(): void {
    let productPortfolioData: ProductPortfolio = this.productPortfolioSlideinForm
      .value as ProductPortfolio;
    if (
      productPortfolioData.portfolioId === undefined ||
      productPortfolioData.portfolioId === null
    ) {
      const newPortfolioObj: ProductPortfolio = {
        i18n: {
          bg: {
            title: productPortfolioData.portfolioName!,
            description: productPortfolioData.portfolioSubtitle!,
          },
          en: {
            title: productPortfolioData.portfolioName!,
            description: productPortfolioData.portfolioSubtitle!,
          },
        },
        libraryId: this.id,
      };
      productPortfolioData = newPortfolioObj;
    } else {
      this.portfoloiObj.i18n!.en.title = productPortfolioData.portfolioName!;
      this.portfoloiObj.i18n!.en.description = productPortfolioData.portfolioSubtitle!;
      this.portfoloiObj.id = productPortfolioData.portfolioId;
      productPortfolioData = this.portfoloiObj;
    }
    this.control.confirm(productPortfolioData);
  }

  public cancel(): void {
    this.control.cancel();
  }
}
