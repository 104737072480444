import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {SlideInController} from '@maia/slide-ins';
import {
  NEWS_EVENTS,
  UPLOAD_FILE_CONSTRAINT_TEXT,
  UPLOAD_FILE_SIZE,
} from '../../../../constants/resource-management/contacts';
import {
  CreateProductCategoryDetailsDto,
  CreateProductDto,
  FileUploadType,
  Library,
  MediaDto,
  ProductPortfolio,
  ProductResponseDto,
  UserType,
} from '../../../../contracts/library.interface';
import {UsertypeSelectionSlideinComponent} from '../../shared/usertype-selection-slidein/usertype-selection-slidein.component';
import {LibraryService} from '../../../../services/library.service';
import {CategoryInterface} from '../../../../contracts/NewsEvents';
import {FileUploaderSlideInConfig, Reference} from '@hermes/file-uploader';
import {CmsNavigationService} from '../../../../services/cms-navigation.service';
import {RoutePaths} from '../../../../constants/route-paths';
import {LibrarySessionService} from '../../../../services/library-session.service';
import {ApiService, localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {CMS_FILE_UPLOADER} from '../../../../constants/resource-management/file-uploader';
import {Hidden, Text} from '@atlas/businesstypes';
import {ActivatedRoute} from '@angular/router';
import {FormData, ProductCategory} from './form-data.model';
import {AddEditCategoriesSlideinComponent} from '../../shared/add-edit-categories-slidein/add-edit-categories-slidein.component';
import {
  DESCRIPTION_LENGTH,
  DESCRIPTION_PATTERN,
  TITLE_LENGTH,
  TITLE_PATTERN,
} from '../../../../constants/resource-management/constant';

const lang: string | null = sessionStorage.getItem('lang');
const documentConstraintText = new localeValPipe(new LocalizationService()).transform(
  UPLOAD_FILE_CONSTRAINT_TEXT,
  lang,
);
const imageConstraintText = new localeValPipe(new LocalizationService()).transform(
  'Only JPG. max. 10MB',
  lang,
);
const uploadDocumentTitle = new localeValPipe(new LocalizationService()).transform(
  'Upload Document',
  lang,
);
const uploadMediaTitle = new localeValPipe(new LocalizationService()).transform(
  'Upload Image',
  lang,
);

@Component({
  selector: 'nje-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
})
@UntilDestroy()
export class AddProductComponent implements OnInit {
  public size = 'normal';
  public categoryList: CategoryInterface[] = [];
  public lang: string | null;
  public selectedLibrary: Library;
  public selectedProductPortfolio: ProductPortfolio;
  public editProductId: number;

  private readonly _userTypeSelectSlideInFactory: ComponentFactory<UsertypeSelectionSlideinComponent>;
  public selectedUsers: string | undefined;
  public selectedUserTypeList: UserType[] = [];

  public productDocuments = CMS_FILE_UPLOADER.PRODUCT_DOCUMENT;
  public productMedia = CMS_FILE_UPLOADER.PRODUCT_MEDIA;
  public uploadedFiles: Reference[] = [];
  public imageSlideInConfig: FileUploaderSlideInConfig = {
    title: uploadMediaTitle,
    maxTotalFileSize: UPLOAD_FILE_SIZE,
    mimeTypes: new RegExp(/image\/jpeg/),
    dropZones: [
      {
        constraintText: imageConstraintText,
      },
    ],
    afterDownload: (reference: Reference) => {},
    afterUpload: (references: Reference[]) => {},
    afterDelete: (reference: Reference) => {},
  };

  public documentSlideInConfig: FileUploaderSlideInConfig = {
    title: uploadDocumentTitle,
    mimeTypes: new RegExp(
      /(image\/jpeg)|(application\/pdf)|(application\/msword)|(application\/vnd.openxmlformats-officedocument.wordprocessingml.document)|(application\/vnd.ms-excel)|(application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet)|(application\/vnd.ms-powerpoint)|(application\/vnd.openxmlformats-officedocument.presentationml.presentation)/,
    ),
    maxTotalFileSize: UPLOAD_FILE_SIZE,
    dropZones: [
      {
        constraintText: documentConstraintText,
      },
    ],
    afterDownload: (reference: Reference) => {},
    afterUpload: (references: Reference[]) => {},
    afterDelete: (reference: Reference) => {},
  };

  private readonly _categoriesFilterSlideInFactory: ComponentFactory<AddEditCategoriesSlideinComponent>;

  public newProductForm: FormGroup = new FormGroup({
    userType: new FormControl([], [Validators.required]),
    productTitle: new FormControl(undefined, [
      Validators.required,
      Validators.maxLength(TITLE_LENGTH),
      Validators.pattern(TITLE_PATTERN),
    ]),
    productDescription: new FormControl(undefined, [
      Validators.required,
      Validators.maxLength(DESCRIPTION_LENGTH),
      Validators.pattern(DESCRIPTION_PATTERN),
    ]),
    productImage: new FormControl(undefined, [Validators.required]),
    productCategory: new FormArray([]),
  });

  public titleLimit: number = TITLE_LENGTH;
  public descriptionLimit: number = DESCRIPTION_LENGTH;

  public constructor(
    private readonly _cmsNavigationService: CmsNavigationService,
    private readonly _librarySessionService: LibrarySessionService,
    private readonly _libraryService: LibraryService,
    private readonly _apiService: ApiService,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _activatedRoute: ActivatedRoute,
  ) {
    this._userTypeSelectSlideInFactory = this._componentFactoryResolver.resolveComponentFactory(
      UsertypeSelectionSlideinComponent,
    );
    this._categoriesFilterSlideInFactory = this._componentFactoryResolver.resolveComponentFactory(
      AddEditCategoriesSlideinComponent,
    );
  }

  public ngOnInit(): void {
    this._getCategoryListFromDB();
    this.lang = sessionStorage.getItem('lang');

    const productId = this._activatedRoute.snapshot.paramMap.get('productId');

    const productData = this._librarySessionService.getData();
    if (productData.library && productData.portfolio) {
      this.selectedLibrary = productData.library;
      this.selectedProductPortfolio = productData.portfolio;
    }
    if (productId) {
      this.editProductId = Number(productId);
      this._getProductDetailsFromDB();
    }
  }

  public ifEnglishLang(): boolean {
    return this.lang === NEWS_EVENTS.ENGLISH;
  }

  public get productCategory(): FormArray {
    return this.newProductForm.controls.productCategory as FormArray;
  }

  public get filteredCategoryList(): CategoryInterface[] {
    return this.categoryList.filter(category => {
      const foundCategory = this.productCategory.value.find(
        (productCategory: any) => productCategory.categoryId === category.id,
      );
      return foundCategory === undefined;
    });
  }

  public addProductCategory(): void {
    const productCategoryForm = new FormGroup({
      categoryId: new FormControl(undefined, [Validators.required]),
      categoryDescription: new FormControl(undefined, [
        Validators.required,
        Validators.maxLength(DESCRIPTION_LENGTH),
        Validators.pattern(DESCRIPTION_PATTERN),
      ]),
      documents: new FormControl(undefined, [Validators.required]),
    });
    this.productCategory.push(productCategoryForm);
  }

  public removeProductCategory(index: number): void {
    this.productCategory.removeAt(index);
  }

  public selectUsertype(): void {
    this._slideInCtrl
      .prepare(
        this._userTypeSelectSlideInFactory,
        this._injector,
        {
          title: new localeValPipe(new LocalizationService()).transform(
            NEWS_EVENTS.SELECT_ACCOUNT,
            this.lang,
          ),
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.selectedUserTypeList,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<UserType[]>) => {
        if (res.resolution === ModalResolution.CONFIRMED) {
          this.selectedUserTypeList = res.result;
          this.selectedUsers = this.getUserTypeValue();
          this.newProductForm.controls.userType.patchValue(this.selectedUserTypeList);
          this._cdRef.detectChanges();
        }
      });
  }

  public editCategories(): void {
    this._slideInCtrl
      .prepare(
        this._categoriesFilterSlideInFactory,
        this._injector,
        {
          title: new localeValPipe(new LocalizationService()).transform(
            NEWS_EVENTS.PRODUCT_CATEGORY_EDIT_FILTER,
            this.lang,
          ),
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.categoryList,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<CategoryInterface[]>) => {
        if (res.resolution === ModalResolution.CONFIRMED) {
          if (res.result.length > 0) {
            res.result.forEach((category: CategoryInterface) => {
              this._libraryService.updateProductCategory(category).subscribe();
            });
          }
        }
        this._getCategoryListFromDB();
        this._cdRef.detectChanges();
      });
  }

  public saveProductData(): void {
    const productReqData = this._frameReqData();
    const libraryId = this.selectedLibrary.id;
    const productPortfolioId = this.selectedProductPortfolio.id;
    if (libraryId !== undefined && productPortfolioId !== undefined) {
      if (this.editProductId === undefined) {
        this._libraryService
          .addNewProduct(libraryId, productPortfolioId, productReqData)
          .subscribe(data => {
            this.navigateToAddProduct();
          });
      } else {
        this._libraryService
          .updateProduct(libraryId, productPortfolioId, this.editProductId, productReqData)
          .subscribe(data => {
            this.navigateToAddProduct();
          });
      }
    }
  }

  private _getCategoryListFromDB(): void {
    this._libraryService.getCategoryList().subscribe((response: CategoryInterface[]) => {
      this.categoryList = response;
      this._cdRef.detectChanges();
    });
  }

  private _getProductDetailsFromDB(): void {
    if (
      this.selectedLibrary &&
      this.selectedLibrary.id &&
      this.selectedProductPortfolio &&
      this.selectedProductPortfolio.id &&
      this.editProductId
    ) {
      this._libraryService
        .getProductById(
          this.selectedLibrary.id,
          this.selectedProductPortfolio.id,
          this.editProductId,
        )
        .subscribe((res: ProductResponseDto) => {
          const formVal = this._framePatchValue(res);
          this.newProductForm.patchValue(formVal);
          this._cdRef.detectChanges();
        });
    }
  }

  private _frameReqData(): CreateProductDto {
    const formVal = this.newProductForm.value;

    const productI18nData = {
      title: formVal.productTitle,
      description: formVal.productDescription,
    };
    const productCategories: CreateProductCategoryDetailsDto[] = formVal.productCategory.map(
      (category: any) => {
        const productCategoryDetailsI18n = {
          desciption: category.categoryDescription,
        };
        return {
          i18n: {
            en: productCategoryDetailsI18n,
            bg: productCategoryDetailsI18n,
          },
          documents: this._getDocumentIds(category),
          categoryId: category.categoryId,
          description: category.categoryDescription,
        };
      },
    );
    return {
      i18n: {
        en: productI18nData,
        bg: productI18nData,
      },
      gallery: this._getImageIds(formVal),
      userTypes: formVal.userType.map((type: UserType) => type.id),
      categories: productCategories,
      title: productI18nData.title,
      description: productI18nData.description,
    };
  }

  private _framePatchValue(productResponse: ProductResponseDto): FormData {
    const productdetail = this.ifEnglishLang() ? productResponse.i18n.en : productResponse.i18n.bg;
    const productCategories: ProductCategory[] = productResponse.categories.map(category => {
      this.addProductCategory();
      const documentFilePatches = this._frameFileUploadPatchData(category.documents as MediaDto[]);
      return {
        categoryId: category.categoryId,
        categoryDescription: '',
        documents: documentFilePatches,
      };
    });
    this.selectedUserTypeList = productResponse.userTypes as UserType[];
    this.selectedUsers = this.getUserTypeValue();
    return {
      userType: this.selectedUserTypeList,
      productTitle: productdetail.title,
      productDescription: productdetail.description,
      productImage: this._frameFileUploadPatchData(productResponse.gallery),
      productCategory: productCategories,
    };
  }

  private _frameFileUploadPatchData(fileData: MediaDto[]): FileUploadType[] {
    return fileData.map(res => {
      const idRef: Hidden = new Hidden(String(res.id));
      const nameRef: Text = new Text(res.filename);
      const typeRef: Text = new Text(res.type);
      return {
        id: idRef,
        name: nameRef,
        type: typeRef,
      };
    });
  }

  private _getDocumentIds(productCategory: ProductCategory): number[] {
    return productCategory.documents.map((docReference: Reference) => {
      const idRef: Hidden = docReference.id;
      return Number(idRef.toString().match(/\d+/g));
    });
  }

  private _getImageIds(productForm: FormData): number[] {
    return productForm.productImage.map((imageReference: Reference) => {
      const idRef: Hidden = imageReference.id;
      return Number(idRef.toString().match(/\d+/g));
    });
  }

  public checkProductCategoryLimit(): boolean {
    return this.categoryList.length > this.productCategory.length;
  }

  public navigateToAddProduct(): void {
    this._cmsNavigationService.nextScreen(RoutePaths.Library);
  }

  public updateFlag(flag: string): void {
    this._apiService.chooseFileSubject.next(flag);
  }

  public getValue(value: number): string | undefined {
    const respectiveCategory = this.categoryList
      ? this.categoryList.find(options => options.id == value)
      : null;
    if (respectiveCategory) {
      return this.ifEnglishLang()
        ? respectiveCategory.i18n?.en.title
        : respectiveCategory.i18n?.bg.title;
    }
    return '';
  }

  public getUserTypeValue(): string | undefined {
    return this.selectedUserTypeList.length > 1
      ? `${this.selectedUserTypeList.length} Accounts Selected`
      : this.ifEnglishLang()
      ? this.selectedUserTypeList[0]?.i18n?.en?.title
      : this.selectedUserTypeList[0]?.i18n?.bg?.title;
  }
}
