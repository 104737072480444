import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  DESCRIPTION_LENGTH,
  DESCRIPTION_PATTERN,
  TITLE_LENGTH,
  TITLE_PATTERN,
} from '../../../../constants/resource-management/constant';

import {Library} from '../../../../contracts/library.interface';

/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
@Component({
  selector: 'nje-librarylist-slidein',
  templateUrl: './librarylist-slidein.component.html',
  styleUrls: ['./librarylist-slidein.component.scss'],
})
export class LibrarylistSlideinComponent
  extends ModalContentComponent<Library, Library>
  implements OnInit
{
  public LibraryListSlideinForm: FormGroup;
  public libraryObj: Library;

  public titleLimit: number = TITLE_LENGTH;
  public descriptionLimit: number = DESCRIPTION_LENGTH;

  public constructor(
    private readonly _formBuilder: FormBuilder,
    public control: ModalControl<Library, Library>,
  ) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this.libraryObj = data;
    this.LibraryListSlideinForm = this._initializeLibraryListSlideinForm(data);
  }

  public isNewLibraryList(): boolean {
    return this.control.input !== undefined;
  }

  private _initializeLibraryListSlideinForm(formdata?: Library): FormGroup {
    return (this.LibraryListSlideinForm = this._formBuilder.group({
      libraryId: new FormControl(formdata?.id),
      libraryName: new FormControl(formdata?.i18n?.en?.title, [
        Validators.required,
        Validators.maxLength(TITLE_LENGTH),
        Validators.pattern(TITLE_PATTERN),
      ]),
      librarySubtitle: new FormControl(formdata?.i18n?.en?.description, [
        Validators.required,
        Validators.maxLength(DESCRIPTION_LENGTH),
        Validators.pattern(DESCRIPTION_PATTERN),
      ]),
      selectedStatus: new FormControl(formdata?.selectedStatus),
    }));
  }

  public addOrUpdateLibraryList(): void {
    let libraryListData = this.LibraryListSlideinForm.value as Library;
    if (libraryListData.libraryId === undefined || libraryListData.libraryId === null) {
      const newLibraryObj: Library = {
        i18n: {
          bg: {
            title: libraryListData.libraryName!,
            description: libraryListData.librarySubtitle!,
          },
          en: {
            title: libraryListData.libraryName!,
            description: libraryListData.librarySubtitle!,
          },
        },
      };
      libraryListData = newLibraryObj;
    } else {
      this.libraryObj.i18n!.en.title = libraryListData.libraryName!;
      this.libraryObj.i18n!.en.description = libraryListData.librarySubtitle!;
      this.libraryObj.id = libraryListData.libraryId;
      libraryListData = this.libraryObj;
    }

    this.control.confirm(libraryListData);
  }

  public cancel(): void {
    this.control.cancel();
  }
}
