export const MENUS_FOR_BREADCRUMBS: any[] = [
  {
    id: '1',
    name: 'Home',
    filterPath: './dashboard',
    fullPath: './dashboard',
    children: [
      {
        id: '5',
        name: 'Policies',
        filterPath: './policies',
        fullPath: 'dashboard/policies',
        children: [
          {
            id: '51',
            name: 'Travel Insurence',
            filterPath: './travel',
            fullPath: 'dashboard/policies/travel',
            children: [],
          },
        ],
      },
    ],
  },
];

export const MOCK_ROUTER_PATH_LIST = ['dashboard', 'policies', 'travel'];
export const MOCK_BREAD_CRUMBS_LIST = [
  {
    id: '1',
    name: 'Home',
    filterPath: './dashboard',
    fullPath: './dashboard',
  },
  {
    id: '5',
    name: 'Policies',
    filterPath: './policies',
    fullPath: 'dashboard/policies',
  },
  {
    id: '51',
    name: 'Travel Insurence',
    filterPath: './travel',
    fullPath: 'dashboard/policies/travel',
  },
];

export const MENUS_FOR_MTPL_BREADCRUMBS: any[] = [
  {
    id: '1',
    name: 'Policies',
    filterPath: './dashboard',
    fullPath: './dashboard',
    children: [
      {
        id: '5',
        name: 'New Policy',
        filterPath: './policies',
        fullPath: 'dashboard/policies',
        children: [
          {
            id: '51',
            name: 'MTPL',
            filterPath: './mtpl',
            fullPath: 'dashboard/policies/mtpl',
            children: [],
          },
        ],
      },
    ],
  },
];

export const MENUS_FOR_PROPERTY_BREADCRUMBS: any[] = [
  {
    id: '1',
    name: 'Policies',
    filterPath: './dashboard',
    fullPath: './dashboard',
    children: [
      {
        id: '5',
        name: 'New Policy',
        filterPath: './policies',
        fullPath: 'dashboard/policies',
        children: [
          {
            id: '51',
            name: 'Property Insurance',
            filterPath: './property',
            fullPath: 'dashboard/policies/property',
            children: [],
          },
        ],
      },
    ],
  },
];

export const MENUS_FOR_STATISTICS_BREADCRUMBS: any[] = [
  {
    id: '1',
    name: 'Home',
    filterPath: './dashboard',
    fullPath: 'dashboard/admin/cms',
    children: [
      {
        id: '5',
        name: 'Statistics reporting',
        filterPath: './statistics',
        fullPath: 'dashboard/admin/cms/statistics',
      },
    ],
  },
];
