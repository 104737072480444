<div [ngClass]="loderShowCss">
  <div class="loader">
    <div class="loader-div">
      <maia-spinner
        [ngClass]="{
          'spinner-normal': loadingMsg === loaderSizeCss,
          'spinner-big': loadingMsg !== loaderSizeCss
        }"
      ></maia-spinner>
      <label class="demo-kdl-font-normal-light">{{ loadingMsg }}</label>
    </div>
  </div>
</div>
