import {Injectable} from '@angular/core';
import {API_END_POINT, ApiService} from '@b2b-frontend/core';
import {Observable} from 'rxjs';

import {
  IV_BASE_OPTIONS,
  OBJECT_TYPE_PARAMETER,
  PolicySelectableTypesList,
  UNDERWRITING_LIMIT,
} from '../../constants/property-insurance-constants';
import {
  AdditionalCoversAddressLevelInterface,
  BankList,
  CoversLimits,
  FeatureList,
  InstallmentOptions,
  IvBaseInterface,
  PolicySelectableType,
  PropertyTypes,
} from '../../contracts/property-insurance.interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyApiService {
  public constructor(private readonly _apiService: ApiService) {}

  public getCoversAddress(insrType: string): Observable<CoversLimits[]> {
    return this._apiService.get(`${API_END_POINT.COVERS_LIMITS}/${insrType}/${UNDERWRITING_LIMIT}`);
  }

  public getCoversOnAddressLevel(): Observable<AdditionalCoversAddressLevelInterface[]> {
    return this._apiService.get(API_END_POINT.COVERS_ON_ADDRESS_LEVEL);
  }

  public getPropertyTypes(): Observable<PropertyTypes[]> {
    return this._apiService.get(`${API_END_POINT.PROPERTY_TYPE}`);
  }

  public getInstallmentOptions(): Observable<InstallmentOptions[]> {
    return this._apiService.get(`${API_END_POINT.INSTALLMENT_OPTIONS}`);
  }

  public getFeatureList(): Observable<FeatureList[]> {
    return this._apiService.get(`${API_END_POINT.FEATURE_LIST}`);
  }

  public getPolicySelectableType(apiURL: string): Observable<PolicySelectableType[]> {
    return this._apiService.get(API_END_POINT[apiURL as keyof typeof PolicySelectableTypesList]);
  }

  public getBankList(): Observable<BankList[]> {
    return this._apiService.get(`${API_END_POINT.BANK_LIST}`);
  }

  public getIvBaseOptions(insrType: number, objectType: number): Observable<IvBaseInterface[]> {
    return this._apiService.get(
      `${API_END_POINT.IV_BASE_OPTIONS}/${insrType}/${OBJECT_TYPE_PARAMETER}/${objectType}/${IV_BASE_OPTIONS}`,
    );
  }

  public getCurrencyRate(): Observable<any> {
    return this._apiService.get(`${API_END_POINT.CURRENCY_RATE_CONVERSION}`);
  }
}
