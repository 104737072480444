import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {SlideInController} from '@maia/slide-ins';

import {Library, ProductPortfolio} from '../../../../contracts/library.interface';
import {LibraryService} from '../../../../services/library.service';
import {AddProductPortfolioSlideinComponent} from '../add-product-portfolio-slidein/add-product-portfolio-slidein.component';
import {DeletePopupComponent} from '../delete-popup/delete-popup.component';
import {localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {LibrarySessionService} from '../../../../services/library-session.service';
import {DELETE_POPUP_HEADINGS} from '../../../../constants/resource-management/library';

type MarkFunctionProperties<Component> = {
  [Key in keyof Component]: Key;
};
type ExcludeFunctionPropertyNames<ProductPortfolioComponent> =
  MarkFunctionProperties<ProductPortfolioComponent>[keyof ProductPortfolioComponent];
type ExcludeFunctions<ProductPortfolioComponent> = Pick<
  ProductPortfolioComponent,
  ExcludeFunctionPropertyNames<ProductPortfolioComponent>
>;
export type NgChanges<Component, Props = ExcludeFunctions<Component>> = {
  [Key in keyof Props]: {
    previousValue: Props[Key];
    currentValue: Props[Key];
    firstChange: boolean;
    isFirstChange(): boolean;
  };
};
@Component({
  selector: 'nje-product-portfolio',
  templateUrl: './product-portfolio.component.html',
  styleUrls: ['./product-portfolio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ProductPortfolioComponent implements OnChanges {
  @Input()
  public selectLibrary: Library;

  public lang: string | null = sessionStorage.getItem('lang');

  @Output()
  public readonly selectedProductPortfolio: EventEmitter<ProductPortfolio> = new EventEmitter<ProductPortfolio>();

  public productPortfolioList: ProductPortfolio[] = [];
  public productPortfolio: ProductPortfolio;
  private readonly _popUpDeleteFactory: ComponentFactory<DeletePopupComponent>;
  private readonly _addProductPortfolioSlideInFactory: ComponentFactory<AddProductPortfolioSlideinComponent>;
  public portfolioList: number;
  public editedPortfolio: number;
  public lib: number;
  public constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _libraryService: LibraryService,
    private readonly _librarySessionService: LibrarySessionService,
    private readonly _popUpCtrl: PopUpController,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._addProductPortfolioSlideInFactory =
      this._componentFactoryResolver.resolveComponentFactory(AddProductPortfolioSlideinComponent);
    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(DeletePopupComponent);
  }

  public ngOnChanges(changes: NgChanges<ProductPortfolioComponent>): void {
    if (changes.selectLibrary.currentValue != changes.selectLibrary.previousValue) {
      this.lib = changes.selectLibrary.currentValue.id as number;
    }
    this._getPortfolioFromDB();
  }

  public addEditProductPortfolio(productPortfolio?: ProductPortfolio, index?: number): void {
    const slideinTitle = productPortfolio
      ? new localeValPipe(new LocalizationService()).transform(
          'Update Product Portfolio',
          this.lang,
        )
      : new localeValPipe(new LocalizationService()).transform(
          'Add New Product Portfolio',
          this.lang,
        );
    this._slideInCtrl
      .prepare(
        this._addProductPortfolioSlideInFactory,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: productPortfolio,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<ProductPortfolio>) => {
        if (response.resolution === ModalResolution.CONFIRMED && response.result.id !== undefined) {
          // update scenario
          this.productPortfolioList[index!] = response.result;
          this._libraryService.updateProductPortfolio(response.result).subscribe();
          this._cdRef.detectChanges();
        } else if (
          response.resolution === ModalResolution.CONFIRMED &&
          (response.result.id === null || response.result.id === undefined)
        ) {
          //add scenario
          response.result.libraryId = this.lib;
          this._libraryService.AddProductPortfolio(response.result).subscribe(() => {
            this._getPortfolioFromDB();
          });
        }
        this._cdRef.detectChanges();
      });
  }

  public deleteProductPortfolio(index: number, id: number): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: '', footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: new localeValPipe(new LocalizationService()).transform(
            DELETE_POPUP_HEADINGS.PRODUCT_PORTFOLIO,
            this.lang,
          ),
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          // handle delete scenario
          void this._libraryService.deleteProductPortfolio(this.lib, id).subscribe(() => {
            this._getPortfolioFromDB();
            this.selectedProductPortfolio.emit();
            this._cdRef.detectChanges();
          });
        }
      });
  }

  private _getPortfolioFromDB(): void {
    if (this.lib) {
      this._libraryService
        .getProductPortfolioList(this.lib)
        .subscribe((response: ProductPortfolio[]) => {
          this.productPortfolioList = response;
          this._updateProductPortfolioFromSession();
          this._cdRef.detectChanges();
        });
    }
  }

  public selectProductPortfolio(index: number): void {
    this.productPortfolioList.map(
      (productPortfolio: ProductPortfolio) => (productPortfolio.selectedStatus = false),
    );
    this.productPortfolioList[index].selectedStatus = true;
    this.selectedProductPortfolio.emit(this.productPortfolioList[index]);
  }

  private _updateProductPortfolioFromSession(): void {
    const productPortfolioSessionData = this._librarySessionService.getData().portfolio;
    if (productPortfolioSessionData) {
      const productPortfolioIndex = this.productPortfolioList.findIndex(
        portfolioData => portfolioData.id === productPortfolioSessionData.id,
      );
      if (productPortfolioIndex !== -1) {
        this.productPortfolio = this.productPortfolioList[productPortfolioIndex];
        this.selectProductPortfolio(productPortfolioIndex);
      }
    }
  }
}
