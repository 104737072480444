import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule DevicesFuncMobileSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendDevicesFuncMobile]',
  template: '<svg:g><svg:path d="M17.2 21.5H6.8a1.8 1.8 0 0 1-1.7-1.7h0V4.2a1.8 1.8 0 0 1 1.7-1.7h10.4a1.8 1.8 0 0 1 1.7 1.7v15.6a1.8 1.8 0 0 1-1.7 1.7z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M5.1 18h13.8"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M5.1 6h13.8"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesFuncMobileSvgComponent {
}

@NgModule({
  declarations: [
    DevicesFuncMobileSvgComponent,
  ],
  exports: [
    DevicesFuncMobileSvgComponent,
  ],
})
export class DevicesFuncMobileSvgModule {}
