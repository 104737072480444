import {Injectable} from '@angular/core';
import {PartyType} from '../core-helper/constants/PartyType';
import {EgnValidatorService} from '../core-helper/validator/insureparty-type/egn/egn-validator.service';
import {EikValidatorService} from '../core-helper/validator/insureparty-type/eik/eik-validator.service';
import {LnchValidatorService} from '../core-helper/validator/insureparty-type/lnch/lnch-validator.service';
import {BLANK_VALUE} from '../core-helper/constants/Config';

@Injectable({
  providedIn: 'root',
})
export class PartyIdService {
  public constructor(
    private readonly egn: EgnValidatorService,
    private readonly eik: EikValidatorService,
    private readonly lnch: LnchValidatorService,
  ) {}

  public getType(val: string): PartyType | undefined {
    val = `${val}${BLANK_VALUE}`;
    if (val.replace(/0/g, '').trim() === '') {
      return undefined;
    }

    if (this.egn.isValidFormat(val)) {
      if (this.egn.isValid(val)) {
        return PartyType.PERSON;
      } else if (this.lnch.isValidFormat(val) && this.lnch.isValid(val)) {
        return PartyType.FOREIGNER;
      }
    } else if (this.eik.isValidFormat(val)) {
      if (this.eik.isValid(val)) {
        return PartyType.COMPANY;
      }
    } else if (this.lnch.isValidFormat(val) && this.lnch.isValid(val)) {
      return PartyType.FOREIGNER;
    }

    return undefined;
  }
}
