<form maiaForm [formGroup]="vehicleOwnerForm.formGroup">
  <maia-fluid-fluid-layout alignContentTop>
    <div class="fluid m-r-16">
      <maia-label class="b2b-kdl-font-small title-dark-color"> EGN / LNCH / EIK </maia-label>
      <maia-search-bar
        *ngIf="!isSame.asBoolean()"
        class="search-box"
        [placeholder]="'EGN / LNCH / EIK'"
        (inputText)="handleVehicleOwner($event)"
        (searchText)="handleSearchedText($event)"
      >
      </maia-search-bar>
      <maia-form-element
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="input-column"
      >
        <div *ngIf="isSame.asBoolean()">
          <input
            maiaInput
            name="EGN / LNCH / EIK"
            placeholder="EGN / LNCH / EIK"
            formControlName="ownerIdNumber"
            (ngModelChange)="idNumberValidator($event)"
          />
        </div>
        <maia-validation *ngIf="personNotFound" class="error-message">
          Person is not retrieved by PID / EIK. You can proceed with manual data entry.
        </maia-validation>
      </maia-form-element>
    </div>
    <div class="fluid m-r-16"></div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout alignContentTop>
    <div class="fluid m-r-16">
      <maia-form-element
        *ngIf="isVehicleOwnerEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Company Name"
      >
        <input maiaInput placeholder="Company Name" formControlName="companyName" />
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyName') &&
            vehicleOwnerForm.hasError('companyName', 'required')
          "
        >
          Company name required
        </maia-validation>
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyName') &&
            vehicleOwnerForm.hasError('companyName', 'nonLatinLetters')
          "
          >Company name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyName') &&
            vehicleOwnerForm.hasError('companyName', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyName') &&
            vehicleOwnerForm.hasError('companyName', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ vehicleOwnerForm.controls['companyName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
    <div class="fluid">
      <maia-form-element
        *ngIf="isVehicleOwnerEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Company Name with latin letters"
      >
        <input maiaInput placeholder="Company Name" formControlName="companyNameEn" />
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyNameEn') &&
            vehicleOwnerForm.hasError('companyNameEn', 'required')
          "
        >
          Company name required
        </maia-validation>
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyNameEn') &&
            vehicleOwnerForm.hasError('companyNameEn', 'nonCyrillicLetters')
          "
        >
          Company name in latin required</maia-validation
        >
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyNameEn') &&
            vehicleOwnerForm.hasError('companyNameEn', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyNameEn') &&
            vehicleOwnerForm.hasError('companyNameEn', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ vehicleOwnerForm.controls['companyNameEn'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout>
    <div class="fluid m-r-16">
      <maia-form-element
        *ngIf="!isVehicleOwnerEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Full Name"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('firstName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('firstName')
            "
          >
            <input maiaInput type="text" placeholder="First Name" formControlName="firstName" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('firstName')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstName', 'required')"
                >First name required</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstName', 'nonLatinLetters')"
                >First name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstName', 'nonBlank')"
                >Invalid first name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstName', 'maxlength')"
                >First name is too long (maximum is
                {{ vehicleOwnerForm.controls['firstName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('middleName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('middleName')
            "
          >
            <input maiaInput type="text" placeholder="Middle Name" formControlName="middleName" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('middleName')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleName', 'required')"
                >Middle name required</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleName', 'nonLatinLetters')"
                >Middle name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleName', 'nonBlank')"
                >Invalid middle name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleName', 'maxlength')"
                >Middle name is too long (maximum is
                {{ vehicleOwnerForm.controls['middleName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('lastName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('lastName')
            "
          >
            <input maiaInput type="text" placeholder="Surname" formControlName="lastName" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('lastName')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastName', 'required')"
                >Last name required</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastName', 'nonLatinLetters')"
                >Last name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastName', 'nonBlank')"
                >Invalid last name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastName', 'maxlength')"
                >Last name is too long (maximum is
                {{ vehicleOwnerForm.controls['lastName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
    <div class="fluid">
      <maia-form-element
        *ngIf="!isVehicleOwnerEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Full Name with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('firstNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('firstNameEn')
            "
          >
            <input maiaInput type="text" placeholder="First Name" formControlName="firstNameEn" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('firstNameEn')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameEn', 'required')"
                >First name required</maia-validation
              >
              <maia-validation
                *ngIf="vehicleOwnerForm.hasError('firstNameEn', 'nonCyrillicLetters')"
                >First name - only latin letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameEn', 'nonBlank')"
                >Invalid first name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameEn', 'maxlength')"
                >First name is too long (maximum is
                {{ vehicleOwnerForm.controls['firstNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('middleNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('middleNameEn')
            "
          >
            <input maiaInput type="text" placeholder="Middle Name" formControlName="middleNameEn" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('middleNameEn')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameEn', 'required')"
                >Middle name required</maia-validation
              >
              <maia-validation
                *ngIf="vehicleOwnerForm.hasError('middleNameEn', 'nonCyrillicLetters')"
                >Middle name - only latin letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameEn', 'nonBlank')"
                >Invalid middle name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameEn', 'maxlength')"
                >Middle name is too long (maximum is
                {{ vehicleOwnerForm.controls['middleNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('lastNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('lastNameEn')
            "
          >
            <input maiaInput type="text" placeholder="Surname" formControlName="lastNameEn" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('lastNameEn')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameEn', 'required')"
                >Last name required</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameEn', 'nonCyrillicLetters')"
                >Last name - only latin letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameEn', 'nonBlank')"
                >Invalid last name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameEn', 'maxlength')"
                >Last name is too long (maximum is
                {{ vehicleOwnerForm.controls['lastName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>
  <div>
    <maia-contextual-message class="b2b-kdl-font-normal message"
      >Please check the data below. Please enter the address from the certificate.
    </maia-contextual-message>
  </div>
  <div *ngIf="isSame.asBoolean()">
    <div class="fluid m-r-16 checkbox">
      <maia-checkbox
        class="tile-checkbox"
        [(ngModel)]="isSameAddress"
        [ngModelOptions]="{standalone: true}"
        (ngModelChange)="vehicleOwnerAddress()"
      >
        <maia-label class="b2b-kdl-font-normal-light"
          >The address on the certificate is the same as the adress of the insuring
          party.</maia-label
        >
      </maia-checkbox>
    </div>
  </div>
  <maia-fluid-fluid-layout>
    <div class="fluid m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row city-and-postcode"
        label="City & Zip"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="city"
            formControlName="city"
            ngDefaultControl
            (typeAhead)="cityAutoFill.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="City" />

            <maia-option *ngFor="let cityObj of cityArray" [value]="cityObj">
              <maia-option-highlight [label]="cityObj.city"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('city')">
            <maia-validation *ngIf="vehicleOwnerForm.hasError('city', 'required')"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input
              maiaInput
              type="text"
              class="zip-style"
              placeholder="Zip"
              formControlName="postCode"
              readonly
            />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('postCode')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('postCode', 'required')"
                >Post Code required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Quarter" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('quarter')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('quarter')
            "
          >
            <input maiaInput type="text" placeholder="Quarter" formControlName="quarter" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('quarter')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('quarter', 'required')"
                >Quarter is required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row city-and-postcode" label="Street & Number" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="vehicleOwnerForm.hasToShowErrors('street')"
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('street')
            "
          >
            <input maiaInput type="text" placeholder="Street" formControlName="street" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('street')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('street', 'required')"
                >Street required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('streetNumber')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('streetNumber')
            "
          >
            <input maiaInput type="text" placeholder="Number" formControlName="streetNumber" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('streetNumber')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('streetNumber', 'required')"
                >Street number required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Block, Entrance, Floor & Apartment" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="vehicleOwnerForm.hasToShowErrors('block')"
        >
          <div
            [class.p-maia-form-element--show-validation]="vehicleOwnerForm.hasToShowErrors('block')"
          >
            <input maiaInput type="text" name="block" placeholder="Block" formControlName="block" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('block')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('block', 'required')"
                >Block required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('entrance')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('entrance')
            "
          >
            <input
              maiaInput
              type="text"
              name="entrance"
              placeholder="Ent.."
              formControlName="entrance"
            />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('entrance')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('entrance', 'required')"
                >Entrance required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="vehicleOwnerForm.hasToShowErrors('floor')"
        >
          <div
            [class.p-maia-form-element--show-validation]="vehicleOwnerForm.hasToShowErrors('floor')"
          >
            <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floor" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('floor')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('floor', 'required')"
                >Floor required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('apartment')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('apartment')
            "
          >
            <input
              maiaInput
              type="text"
              name="apartment"
              placeholder="Apart.."
              formControlName="apartment"
            />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('apartment')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('apartment', 'required')"
                >Apartment required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        *ngIf="isVehicleOwnerLNCH"
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Citizenship"
      >
        <maia-input-autocomplete
          name="citizenship"
          formControlName="citizenship"
          (typeAhead)="citizenShipAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="dzi-off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let c of citizenShipAutocomplete.list | async" [value]="c.id">
            <maia-option-highlight [label]="c.i18n.en.title"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>

        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('citizenship') &&
            vehicleOwnerForm.hasError('citizenship', 'required')
          "
        >
          Citizenship required
        </maia-validation>
      </maia-form-element>
      <nje-input-phone-number
        [retrievedVehicleOwnerPhone]="retrievedVehicleOwnerDetails"
        [retrievedVehicleCompanyPhone]="retrievedCompanyDetails"
        [sameDetails]="isSameAddress"
        [inputPhoneNumber]="phone"
        (selectPhoneNumber)="selectedPhoneNumber($event)"
      ></nje-input-phone-number> </div
    ><div class="fluid">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row city-and-postcode"
        label="City & Zip with latin letters"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="cityEn"
            formControlName="cityEn"
            ngDefaultControl
            (typeAhead)="cityAutoFill.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="City" />

            <maia-option *ngFor="let cityObj of cityArray" [value]="cityObj">
              <maia-option-highlight [label]="cityObj.city"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('cityEn')">
            <maia-validation *ngIf="vehicleOwnerForm.hasError('cityEn', 'required')"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input
              maiaInput
              type="text"
              class="zip-style"
              placeholder="Zip"
              formControlName="postCodeEn"
              readonly
            />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('postCodeEn')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('postCodeEn', 'required')"
                >Post Code required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Quartile with latin letters" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('quarterEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('quarterEn')
            "
          >
            <input maiaInput placeholder="Quarter" formControlName="quarterEn" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('quarterEn')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('quarterEn', 'required')"
                >Quarter is required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        class="multi-input-row city-and-postcode"
        label="Street & Number with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('streetEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('streetEn')
            "
          >
            <input maiaInput type="text" placeholder="Street" formControlName="streetEn" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('street')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('street', 'required')"
                >Street required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('streetNumberEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('streetNumberEn')
            "
          >
            <input maiaInput type="text" placeholder="Number" formControlName="streetNumberEn" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('streetNumberEn')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('streetNumberEn', 'required')"
                >Street number required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        class="multi-input-row"
        label="Block, Entrance, Floor & Apartment with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('blockEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('blockEn')
            "
          >
            <input
              maiaInput
              type="text"
              name="block"
              placeholder="Block"
              formControlName="blockEn"
            />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('blockEn')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('blockEn', 'required')"
                >Block required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('entranceEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('entranceEn')
            "
          >
            <input
              maiaInput
              type="text"
              name="entrance"
              placeholder="Ent.."
              formControlName="entranceEn"
            />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('entranceEn')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('entranceEn', 'required')"
                >Entrance required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('floorEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('floorEn')
            "
          >
            <input
              maiaInput
              type="text"
              name="floor"
              placeholder="Floor"
              formControlName="floorEn"
            />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('floorEn')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('floorEn', 'required')"
                >Floor required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('apartmentEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('apartmentEn')
            "
          >
            <input
              maiaInput
              type="text"
              name="apartment"
              placeholder="Apart.."
              formControlName="apartmentEn"
            />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('apartmentEn')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('apartmentEn', 'required')"
                >Apartment required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        *ngIf="isVehicleOwnerLNCH"
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="multi-input-row"
        label="Date of Birth"
      >
        <maia-input-date
          name="dob"
          formControlName="dob"
          [minimum]="minDate"
          [maximum]="maxDate"
        ></maia-input-date>
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('dob') && vehicleOwnerForm.hasError('dob', 'required')
          "
        >
          Date of birth required
        </maia-validation>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Email ID" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('emailId')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('emailId')
            "
          >
            <input
              type="email"
              maiaInput
              atlasEmailAddress
              placeholder="Email ID"
              formControlName="emailId"
            />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('emailId')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('emailId', 'required')"
                >Email required</maia-validation
              >
              <maia-validation
                *ngIf="
                  vehicleOwnerForm.hasError('emailId', 'atlasInvalidLocalPart') ||
                  vehicleOwnerForm.hasError('emailId', 'atlasInvalidDomain') ||
                  vehicleOwnerForm.hasError('emailId', 'atlasInvalidMultipleAt')
                "
                >Enter your e-mail address in the following format: name@domain.com</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('emailId', 'atlasMaxLength')"
                >Email too long</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>
  <maia-divider></maia-divider>
  <maia-fluid-fluid-layout alignContentTop>
    <div class="fluid m-r-16 selectParty">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Which persons data will be added on the green card?"
      >
        <maia-radio-group horizontal formControlName="personsAdded">
          <njf-radio-button
            *ngFor="let option of mockPersonsAdded"
            [value]="option.id"
            class="b2b-kdl-font-normal-light"
          >
            {{ option.name }}
          </njf-radio-button>
        </maia-radio-group>
      </maia-form-element>
    </div>
    <div class="fluid"></div>
  </maia-fluid-fluid-layout>
  <maia-divider class="divider"></maia-divider>
</form>
