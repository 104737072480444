<form maiaForm [formGroup]="stepForm.formGroup">
  <maia-fluid-fluid-layout
    *ngIf="!onlyAddressForm"
    [ngClass]="{'insurance-party-details': flexDirection === 'column'}"
  >
    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="EGN / LNCH / EIK"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            stepForm.hasToShowErrors('idNumber') || isIdNumberAdded
          "
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('idNumber')">
            <input
              maiaInput
              name="EGN / LNCH / EIK"
              placeholder="EGN / LNCH / EIK"
              formControlName="idNumber"
              (ngModelChange)="idNumberValidator($event)"
            />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('idNumber')">
              <maia-validation *ngIf="stepForm.hasError('idNumber', 'required')"
                >EGN / LNCH / EIK is required</maia-validation
              >
            </div>
            <div class="validation-container" *ngIf="isIdNumberAdded">
              <maia-validation>
                ID Number is already added in the Insured Party Information, please use another
                ID</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>

    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        *ngIf="!isEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Full Name"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            stepForm.hasToShowErrors('firstNameEn') && stepForm.controls.firstNameEn.dirty
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('firstNameEn')"
          >
            <input maiaInput type="text" placeholder="First Name" formControlName="firstNameEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('firstNameEn')">
              <maia-validation
                *ngIf="stepForm.hasError('firstNameEn', 'required')"
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-fnameenreq"
                >First name required</maia-validation
              >
              <maia-validation
                *ngIf="
                  stepForm.hasError('firstNameEn', 'nonLatinCyrillicLetters') &&
                  stepForm.controls.firstNameEn.dirty
                "
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-fnameenlatin"
                >First name - only latin or cyrillic letters allowed</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('firstNameEn', 'nonBlank')"
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-fnameeninvalid"
                >Invalid first name</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('firstNameEn', 'maxlength')"
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-fnameenlength"
                >First name is too long (maximum is
                {{ stepForm.controls['firstNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            stepForm.hasToShowErrors('middleNameEn') && stepForm.controls.middleNameEn.dirty
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('middleNameEn')"
          >
            <input maiaInput type="text" placeholder="Middle Name" formControlName="middleNameEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('middleNameEn')">
              <maia-validation
                *ngIf="stepForm.hasError('middleNameEn', 'required')"
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-mnameenreq"
                >Middle name required</maia-validation
              >
              <maia-validation
                *ngIf="
                  stepForm.hasError('middleNameEn', 'nonLatinCyrillicLetters') &&
                  stepForm.controls.middleNameEn.dirty
                "
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-mnameenlatin"
                >Middle name - only latin or cyrillic letters allowed</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('middleNameEn', 'nonBlank')"
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-mnameeninvalid"
                >Invalid middle name</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('middleNameEn', 'maxlength')"
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-mnameenlenth"
                >Middle name is too long (maximum is
                {{ stepForm.controls['middleNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            stepForm.hasToShowErrors('lastNameEn') && stepForm.controls.lastNameEn.dirty
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('lastNameEn')"
          >
            <input maiaInput type="text" placeholder="Last Name" formControlName="lastNameEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('lastNameEn')">
              <maia-validation
                *ngIf="stepForm.hasError('lastNameEn', 'required')"
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-lnameenreq"
                >Last name required</maia-validation
              >
              <maia-validation
                *ngIf="
                  stepForm.hasError('lastNameEn', 'nonLatinCyrillicLetters') &&
                  stepForm.controls.lastNameEn.dirty
                "
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-lnameenlatin"
                >Last name - only latin or cyrillic letters allowed</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('lastNameEn', 'nonBlank')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnameeninvalid"
                >Invalid last name</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('lastNameEn', 'maxlength')"
                i18n="@@nje-b2b-frontend-property-Insurance-insuringparty-lnameenlength"
                >Last name is too long (maximum is
                {{ stepForm.controls['lastName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>

      <maia-form-element
        *ngIf="isEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Company Name"
      >
        <input maiaInput placeholder="Company Name" formControlName="companyNameEn" />
        <maia-validation
          *ngIf="
            stepForm.hasToShowErrors('companyNameEn') &&
            stepForm.hasError('companyNameEn', 'required')
          "
        >
          Company name required
        </maia-validation>
        <maia-validation
          *ngIf="
            stepForm.hasToShowErrors('companyNameEn') &&
            stepForm.hasError('companyNameEn', 'nonLatinCyrillicLetters')
          "
          >Company name - only latin or cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            stepForm.hasToShowErrors('companyNameEn') &&
            stepForm.hasError('companyNameEn', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            stepForm.hasToShowErrors('companyNameEn') &&
            stepForm.hasError('companyNameEn', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ stepForm.controls['companyNameEn'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout [ngClass]="{'insurance-party-details': flexDirection === 'column'}">
    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        *ngIf="isLNCH"
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Citizenship"
      >
        <maia-input-autocomplete
          name="citizenship"
          formControlName="citizenship"
          (typeAhead)="citizenshipAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="dzi-off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let c of citizenshipAutocomplete.list | async" [value]="c.id">
            <maia-option-highlight [label]="c.title"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>

        <maia-validation
          *ngIf="
            stepForm.hasToShowErrors('citizenship') && stepForm.hasError('citizenship', 'required')
          "
        >
          Citizenship required
        </maia-validation>
      </maia-form-element>
    </div>

    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        *ngIf="isLNCH"
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="multi-input-row"
        label="Date of Birth"
      >
        <maia-input-date
          name="dob"
          [(ngModel)]="birthDatePicker.value"
          (ngModelChange)="birthDatePicker.change($event)"
          (blur)="birthDatePicker.touch()"
          [minimum]="minDate"
          [maximum]="maxDate"
          [ngModelOptions]="{standalone: true}"
          [visibleView]="visibleDateView"
        ></maia-input-date>
        <maia-validation
          *ngIf="stepForm.hasToShowErrors('dob') && stepForm.hasError('dob', 'required')"
        >
          Date of birth required
        </maia-validation>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout [ngClass]="{'insurance-party-details': flexDirection === 'column'}">
    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="City & Zip"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="cityEn"
            formControlName="cityEn"
            (typeAhead)="cityAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="Select" />

            <maia-option *ngFor="let cityObj of city" [value]="cityObj">
              <maia-option-highlight [label]="cityObj.city"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="stepForm.hasToShowErrors('cityEn')">
            <maia-validation
              *ngIf="stepForm.hasError('cityEn', 'required')"
              i18n="@@systemcode-property-Insurance-contact-cityreq"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input maiaInput type="text" placeholder="Zip" formControlName="postCodeEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('postCodeEn')">
              <maia-validation
                *ngIf="stepForm.hasError('postCodeEn', 'required')"
                i18n="@@systemcode-property-Insurance-contact-postcodereq"
                >Post Code required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>

    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Quarter"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('quarterEn')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('quarterEn')">
            <input
              (ngModelChange)="onQuarterValueChange()"
              maiaInput
              placeholder="Quarter"
              formControlName="quarterEn"
            />

            <div
              class="validation-container"
              *ngIf="stepForm.hasToShowErrors('quarterEn')"
              ng-show="stepForm.quarterEn.Invalid && stepForm.quarterEn.$dirty"
            >
              <maia-validation *ngIf="stepForm.hasError('quarterEn', 'required')">
                Quarter is required
              </maia-validation>
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout [ngClass]="{'insurance-party-details': flexDirection === 'column'}">
    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Street & Number"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('streetEn')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('streetEn')">
            <input
              (ngModelChange)="onStreeValueChange()"
              maiaInput
              type="text"
              placeholder="Street"
              formControlName="streetEn"
            />

            <div
              class="validation-container"
              *ngIf="stepForm.hasToShowErrors('streetEn')"
              ng-show="stepForm.streetEn.Invalid && stepForm.streetEn.$dirty"
            >
              <maia-validation
                *ngIf="stepForm.hasError('streetEn', 'required')"
                i18n="@@systemcode-property-Insurance-contact-streetreq"
                >Street required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('streetNumberEn')"
        >
          <div
            [class.p-maia-form-element--show-validation]="
              stepForm.hasToShowErrors('streetNumberEn')
            "
          >
            <input
              (ngModelChange)="onStreeValueChange()"
              maiaInput
              type="text"
              placeholder="Number"
              formControlName="streetNumberEn"
            />

            <div
              class="validation-container"
              *ngIf="stepForm.hasToShowErrors('streetNumberEn')"
              ng-show="stepForm.streetEn.Invalid && stepForm.streetNumberEn.$dirty"
            >
              <maia-validation
                *ngIf="stepForm.hasError('streetNumberEn', 'required')"
                i18n="@@systemcode-property-Insurance-contact-streetnoreq"
                >Street number required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>

    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        class="multi-input-row"
        label="Block, Entrance, Floor & Apartment"
        multi
        [hideOptionalIndicator]="true"
      >
        <maia-form-subelement>
          <input maiaInput type="text" name="block" placeholder="Block" formControlName="blockEn" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="entrance"
            placeholder="Entrance"
            formControlName="entranceEn"
          />
        </maia-form-subelement>

        <maia-form-subelement>
          <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floorEn" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="apartment"
            placeholder="Apartment"
            formControlName="apartmentEn"
          />
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout
    *ngIf="!onlyAddressForm"
    [ngClass]="{'insurance-party-details': flexDirection === 'column'}"
  >
    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Phone Number"
      >
        <maia-input-phonenumber
          name="phoneNumber"
          appDigitOnly
          appLimitTo="10"
          formControlName="phoneNumber"
          #phoneInput
          class="disable-country-code"
        >
          <maia-phonenumber-country prefix="+359" name="Bulgaria">
            <ng-template #flag><svg systemcodeFlagUtilBulgaria></svg></ng-template>
          </maia-phonenumber-country>
        </maia-input-phonenumber>
      </maia-form-element>
    </div>

    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element
        [hideOptionalIndicator]="false"
        class="multi-input-row"
        label="Email ID"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('emailId')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('emailId')">
            <input maiaInput placeholder="Email ID" formControlName="emailId" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('emailId')">
              <maia-validation
                *ngIf="stepForm.hasError('emailId', 'required')"
                i18n="@@systemcode-property-Insurance-contact-emailreq"
                >Email required</maia-validation
              >
              <maia-validation
                *ngIf="
                  stepForm.hasError('emailId', 'atlasInvalidLocalPart') ||
                  stepForm.hasError('emailId', 'atlasInvalidDomain') ||
                  stepForm.hasError('emailId', 'atlasInvalidMultipleAt')
                "
                i18n="@@systemcode-property-Insurance-contact-emailformat"
                >Enter your e-mail address in the following format: name@domain.com</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('emailId', 'atlasMaxLength')"
                i18n="@@systemcode-property-Insurance-contact-emaillength"
                >Email too long</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <div *ngIf="isEIK">
    <maia-divider class="m-t-b-24"></maia-divider>

    <div *ngIf="isRepParty">
      <div class="m-r-16">
        <maia-label>
          <label class="b2b-kdl-font-small title-dark-color">Representative </label>
        </maia-label>
      </div>

      <maia-fluid-fluid-layout [ngClass]="{'insurance-party-details': flexDirection === 'column'}">
        <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
          <maia-form-element
            [options]="{validationInstant: true}"
            [hideOptionalIndicator]="true"
            class="input-column"
            label="EGN / LNCH"
          >
            <input maiaInput name="EGN / LNCH" formControlName="repIdNumber" />
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repIdNumber') &&
                stepForm.hasError('repIdNumber', 'required')
              "
            >
              EGN/LNCH required
            </maia-validation>
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repIdNumber') &&
                stepForm.hasError('repIdNumber', 'repInvalidNumber')
              "
            >
              Invalid EGN or LNCH number.
            </maia-validation>
          </maia-form-element>
        </div>

        <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
          <maia-form-element
            [options]="{validationInstant: true}"
            [hideOptionalIndicator]="true"
            class="input-column"
            label="Full Name"
            multi
          >
            <maia-form-subelement>
              <input
                maiaInput
                type="text"
                placeholder="First Name"
                formControlName="repFirstNameEn"
              />
            </maia-form-subelement>
            <maia-form-subelement>
              <input
                maiaInput
                type="text"
                placeholder="Middle Name"
                formControlName="repMiddleNameEn"
              />
            </maia-form-subelement>
            <maia-form-subelement>
              <input
                maiaInput
                type="text"
                placeholder="Last Name"
                formControlName="repLastNameEn"
              />
            </maia-form-subelement>
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repFirstNameEn') &&
                stepForm.hasError('repFirstNameEn', 'required')
              "
            >
              First name required
            </maia-validation>
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repMiddleNameEn') &&
                stepForm.hasError('repMiddleNameEn', 'required')
              "
            >
              Middle name required
            </maia-validation>
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repLastNameEn') &&
                stepForm.hasError('repLastNameEn', 'required')
              "
            >
              Last name required
            </maia-validation>
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repFirstNameEn') &&
                stepForm.hasError('repFirstNameEn', 'nonLatinCyrillicLetters')
              "
              >First name - only latin or cyrillic letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repMiddleNameEn') &&
                stepForm.hasError('repMiddleNameEn', 'nonLatinCyrillicLetters')
              "
              >Middle name - only latin or cyrillic letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repLastNameEn') &&
                stepForm.hasError('repLastNameEn', 'nonLatinCyrillicLetters')
              "
              >last name - only latin or cyrillic letters allowed</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repFirstNameEn') &&
                stepForm.hasError('repFirstNameEn', 'nonBlank')
              "
              >Invalid first name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repMiddleNameEn') &&
                stepForm.hasError('repMiddleNameEn', 'nonBlank')
              "
              >Invalid middle name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repLastNameEn') &&
                stepForm.hasError('repLastNameEn', 'nonBlank')
              "
              >Invalid last name</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repFirstNameEn') &&
                stepForm.hasError('repFirstNameEn', 'maxlength')
              "
              >First name is too long (maximum is
              {{ stepForm.controls['repFirstNameEn'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repMiddleNameEn') &&
                stepForm.hasError('repMiddleNameEn', 'maxlength')
              "
              >Middle name is too long (maximum is
              {{ stepForm.controls['repMiddleNameEn'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repLastNameEn') &&
                stepForm.hasError('repLastNameEn', 'maxlength')
              "
              >Last name is too long (maximum is
              {{ stepForm.controls['repLastNameEn'].errors?.maxlength.requiredLength }}
              characters)</maia-validation
            >
          </maia-form-element>
          <maia-form-element
            *ngIf="isRepForeigner"
            [options]="{validationInstant: true}"
            [hideOptionalIndicator]="true"
            class="input-column"
            label="Citizenship"
          >
            <maia-input-autocomplete
              name="repCitizenship"
              formControlName="repCitizenship"
              (typeAhead)="repCitizenshipAutocomplete.filterTypeahead($event)"
            >
              <input
                autocomplete="dzi-off"
                maiaInput
                i18n-placeholder="@@systemcode-property-Insurance-placeholder-select"
                placeholder="Select"
              />

              <maia-option *ngFor="let c of repCitizenshipAutocomplete.list | async" [value]="c.id">
                <maia-option-highlight [label]="c.title"></maia-option-highlight>
              </maia-option>
            </maia-input-autocomplete>
            <maia-validation
              *ngIf="
                stepForm.hasToShowErrors('repCitizenship') &&
                stepForm.hasError('repCitizenship', 'required')
              "
            >
              Citizenship required
            </maia-validation>
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>

      <maia-divider class="m-t-b-24"></maia-divider>
    </div>

    <div class="m-r-16">
      <maia-label>
        <label class="b2b-kdl-font-small title-dark-color">Contact Person </label>
      </maia-label>
    </div>

    <maia-fluid-fluid-layout [ngClass]="{'insurance-party-details': flexDirection === 'column'}">
      <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="EGN / LNCH"
        >
          <input maiaInput name="EGN / LNCH" formControlName="contactIdNumber" />
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactIdNumber') &&
              stepForm.hasError('contactIdNumber', 'required')
            "
          >
            EGN/LNCH required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactIdNumber') &&
              stepForm.hasError('contactIdNumber', 'contactInvalidNumber')
            "
          >
            Invalid EGN or LNCH number.
          </maia-validation>
        </maia-form-element>
      </div>

      <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Full Name"
          multi
        >
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="First Name"
              formControlName="contactFirstNameEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="Middle Name"
              formControlName="contactMiddleNameEn"
            />
          </maia-form-subelement>
          <maia-form-subelement>
            <input
              maiaInput
              type="text"
              placeholder="Last Name"
              formControlName="contactLastNameEn"
            />
          </maia-form-subelement>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstNameEn') &&
              stepForm.hasError('contactFirstNameEn', 'required')
            "
          >
            First name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleNameEn') &&
              stepForm.hasError('contactMiddleNameEn', 'required')
            "
          >
            Middle name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastNameEn') &&
              stepForm.hasError('contactLastNameEn', 'required')
            "
          >
            Last name required
          </maia-validation>
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstNameEn') &&
              stepForm.hasError('contactFirstNameEn', 'nonLatinCyrillicLetters')
            "
            >First name - only latin or cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleNameEn') &&
              stepForm.hasError('contactMiddleNameEn', 'nonLatinCyrillicLetters')
            "
            >Middle name - only latin or cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastNameEn') &&
              stepForm.hasError('contactLastNameEn', 'nonLatinCyrillicLetters')
            "
            >last name - only latin or cyrillic letters allowed</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstNameEn') &&
              stepForm.hasError('contactFirstNameEn', 'nonBlank')
            "
            >Invalid first name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleNameEn') &&
              stepForm.hasError('contactMiddleNameEn', 'nonBlank')
            "
            >Invalid middle name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastNameEn') &&
              stepForm.hasError('contactLastNameEn', 'nonBlank')
            "
            >Invalid last name</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactFirstNameEn') &&
              stepForm.hasError('contactFirstNameEn', 'maxlength')
            "
            >First name is too long (maximum is
            {{ stepForm.controls['contactFirstNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactMiddleNameEn') &&
              stepForm.hasError('contactMiddleNameEn', 'maxlength')
            "
            >Middle name is too long (maximum is
            {{ stepForm.controls['contactMiddleNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactLastNameEn') &&
              stepForm.hasError('contactLastNameEn', 'maxlength')
            "
            >Last name is too long (maximum is
            {{ stepForm.controls['contactLastNameEn'].errors?.maxlength.requiredLength }}
            characters)</maia-validation
          >
        </maia-form-element>
        <maia-form-element
          *ngIf="isContactForeigner"
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Citizenship"
        >
          <maia-input-autocomplete
            name="contactCitizenship"
            formControlName="contactCitizenship"
            (typeAhead)="contactCitizenshipAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="dzi-off" maiaInput placeholder="Select" />

            <maia-option
              *ngFor="let c of contactCitizenshipAutocomplete.list | async"
              [value]="c.id"
            >
              <maia-option-highlight [label]="c.title"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>

          <maia-validation
            *ngIf="
              stepForm.hasToShowErrors('contactCitizenship') &&
              stepForm.hasError('contactCitizenship', 'required')
            "
          >
            Citizenship required
          </maia-validation>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
  </div>
</form>
