<maia-fluid-fluid-layout alignContentTop>
  <insurance-party-details
    [flexDirection]="flexDirection"
    class="insured-party-details-slider"
  ></insurance-party-details>
</maia-fluid-fluid-layout>

<maia-divider></maia-divider>

<maia-button-group class="insured-party-slidein-btn-group">
  <button
    *ngIf="addInsuranceData()"
    class="m-t-b-24"
    maiaButton
    maiaButtonType="primary"
    (click)="saveInsuranceData()"
    >Add</button
  >
  <button
    *ngIf="!addInsuranceData()"
    class="m-t-b-24"
    maiaButton
    maiaButtonType="primary"
    (click)="saveInsuranceData()"
    >Save</button
  >
  <button class="m-t-b-24" maiaButton maiaButtonType="secondary" (click)="cancel()">Cancel</button>
</maia-button-group>
<!-- [disabled]="!isInsurancePartyInfoValid()" -->
