import {Component, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {TravelCovers, TravelCoversSlideIn} from '@b2b-frontend/core';
import {MOCK_TRAVEL_SLIDE_IN} from './_test_/travel-cover-slidein.mocks';

@Component({
  selector: 'travel-cover-slidein',
  templateUrl: './travel-cover-slidein.component.html',
  styleUrls: ['./travel-cover-slidein.component.scss'],
})
export class TravelCoverSlideinComponent
  extends ModalContentComponent<TravelCovers, TravelCovers>
  implements OnInit
{
  public travelCoversSlideIn: TravelCoversSlideIn[] = [...MOCK_TRAVEL_SLIDE_IN];
  public isCovers: boolean = false;
  public isFixedCovers: boolean = false;
  public includeList: TravelCovers = this.control.input;

  public constructor(public control: ModalControl<TravelCovers, TravelCovers>) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    if (data != undefined) {
      this.covers();
    }
  }

  public covers() {
    if (
      this.includeList.coversName == 'Lite' ||
      this.includeList.coversName == 'Standard' ||
      this.includeList.coversName == 'Visa' ||
      this.includeList.coversName == 'Premium'
    ) {
      this.isFixedCovers = true;
    }
  }
}
