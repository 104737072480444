<div class="pay-error-div">
  <svg b2bFrontendAlertUtilConfirmationError class="confirmation-check"></svg>
  <label class="error-text kdl-font-grand-bold-label">{{ paymentErrorMsg }}</label>
  <label class="error-description b2b-kdl-font-normal-light"
    >Property insurance policy was not succesfully created.
  </label>

  <maia-button-group class="create-policy-button">
    <button maiaButton maiaButtonType="primary">Create New Policy</button>
  </maia-button-group>
</div>
