<form [formGroup]="commonInfoForm" class="common-info-wrapper">
  <maia-label class="title-dark-color common-info m-t-b-24"> Common Info </maia-label>

  <div class="intermediary-data-wrapper">
    <div class="intermediary-egn-lnch-eik-wrapper">
      <maia-label class="title-dark-color common-info-title-label">
        Intermediary EGN / LNCH / EIK
      </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <nje-auto-complete></nje-auto-complete>
      </maia-form-element>
    </div>
    <div class="intermediary-name-wrapper">
      <maia-label class="title-dark-color common-info-title-label"> Intermediary Name </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <input
          maiaInput
          maiaInputType="text"
          placeholder="Intermediary Name"
          formControlName="intermediaryName"
        />
      </maia-form-element>
    </div>
  </div>

  <div class="phone-email-wrapper m-t-b-24">
    <div class="phone-number-wrapper">
      <maia-label class="title-dark-color common-info-title-label"> Phone Number </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <nje-phone-number-input></nje-phone-number-input>
      </maia-form-element>
    </div>
    <div class="email-id-wrapper">
      <maia-label class="title-dark-color common-info-title-label"> Email ID </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <input maiaInput maiaInputType="text" placeholder="Email ID" formControlName="emailId" />
      </maia-form-element>
    </div>
  </div>
</form>
