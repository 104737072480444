import {Injector} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DateKey, DateUtils} from '@atlas/businesstypes';

import {DateFormatterService} from '../../services/date-formatter.service';

export function onceDateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const injector = Injector.create([{provide: DateFormatterService}]);
    const service = injector.get(DateFormatterService);

    const value = control.value;

    let result: ValidationErrors | null = null;

    if (value !== null && value !== undefined) {
      if (result === null) {
        result = {};
      }
      const onceStartMinDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
      const onceStartMaxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 59);

      const d1 = service.normalizeDate(onceStartMinDate);
      const d2 = service.normalizeDate(onceStartMaxDate);
      const d3 = service.normalizeDate(value.start);

      if (d1 && d2 && d3 && d3 >= d1 && d3 <= d2) {
        console.log(' selected start date is inbetween the first 60 days');
      } else {
        console.log(' selected start date is not in between the first 60 days');
        (result as any).invalid = true;
      }
    }
    return result;
  };
}
