<form maiaForm [formGroup]="urlForm.formGroup">
  <maia-form-group>
    <maia-label>
      <label
        i18n="@@nje-b2b-frontend-resource-contacts-contacturl"
        class="b2b-kdl-font-small title-dark-color url-alignment"
        >www url contact</label
      >
    </maia-label>
    <maia-form-element class="slidein-input-column url-edit-input">
      <input
        maiaInput
        name="urlEntered"
        [(ngModel)]="urlObj.url"
        placeholder="Enter URL"
        i18n-placeholder="@@nje-b2b-frontend-resource-contacts-url"
        formControlName="url"
        hideOptionalIndicator
      />
    </maia-form-element>
  </maia-form-group>
</form>
<div class="step-form-button-bar">
  <maia-button-group style="text-align: center">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      (click)="updateUrl()"
      [disabled]="!urlForm.valid"
      i18n="@@nje-b2b-frontend-resource-contacts-submit"
      >Submit</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancelSlideIn()"
      i18n="@@nje-b2b-frontend-resource-contacts-cancel"
      >Cancel</button
    >
  </maia-button-group>
</div>
