import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  DateKey,
  DateUtils,
  Decimal,
  DecimalUtils,
  ExtraDateValidators,
  DecimalFormatter,
  Text as Text_2,
} from '@atlas/businesstypes';
import {
  dateValidator,
  MultiTripFormData,
  onceDateValidator,
  RadioButtonDto,
  SingleTripFormData,
  Travel,
  TravelInsuredPeople,
  TravelReasonDto,
  TravelTripTypeDto,
  TravelWorkType,
  TripDetailDto,
  ProposalDto,
  TravelCovers,
  TravelCoversDto,
  InsuredPerson,
  DateFormatterService,
  BLANK_VALUE,
  FileData,
} from '@b2b-frontend/core';
import {
  ScreenFormComponent,
  FormGroupManagerFactoryService,
  FormGroupManager,
} from '@njf-frontend-angular/flow-progress';
import {takeUntil} from 'rxjs/operators';

import {TravelPolicyService} from '../../travel-core/services/travel-policy.service';
import {TravelSessionService} from '../../travel-core/services/travel-session.service';
import {
  MOCK_TEMP_WORK_ABORD_IDS,
  MOCK_TRAVEL_INSURED_PEOPLE_TYPE_LIST,
  MOCK_TRIP_LIST,
  MOCK_TRAVELUS_CANADA_OPTION_LIST,
  MOCK_DAYSIN_ABROAD_LIST,
  MOCK_TRAVEL_REASON,
  MOC_WORK_TYPE,
  TripNumber,
} from './__tests__/travel-trip-details.mocks';
import {NewTile, TotalPeople} from '../../constants/constant';
import * as XLSX from 'xlsx';
import {DateRange} from '@maia/datepickers';
import {TravelSlideInService} from '../../travel-core/services/travel-slide-in.service';
import {ModalConfirmedResult} from '@maia/modals';

export const DEFAULT_DEMO_STEPPER_MIN_VALUE = DecimalUtils.ZERO;
export const DEFAULT_DEMO_STEPPER_MAX_VALUE = new Decimal('21.5');

@Component({
  selector: 'travel-trip-details',
  templateUrl: './travel-trip-details.component.html',
  styleUrls: ['./travel-trip-details.component.scss'],
})
export class TravelTripDetailsComponent extends ScreenFormComponent implements OnInit {
  public showSingleTripForm: boolean = false;
  public showMultipleTripForm: boolean = false;
  public multipleHightlightedDays = false;
  public visibleDateView = undefined;
  public disableWeekends = false;
  public showWorkType: boolean = false;
  public countDecimal: Decimal;
  public maximumDecimal: Decimal;
  public minimumDecimal: Decimal;
  public defaultStartDate = DateUtils.add(DateUtils.today(), DateKey.Day, 1);
  public minDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 365);
  public minDateMultiple = DateUtils.add(DateUtils.today(), DateKey.Day, 1);
  public maxDateMultiple = DateUtils.add(this.minDateMultiple, DateKey.Day, 59);
  public singleTrip: number = Travel.SINGLE_TRIP;
  public multipleTrip: number = Travel.MULTI_TRIP;
  public selectedTrip: number = 0;
  public showHideBlueDiv: boolean = false;
  public showHideDiv: boolean = true;
  public inEditMode: boolean = true;
  public dropDownDeafultId: number = 0;
  public showNewUploaderPanel: boolean = true;
  public showReuploaderPanel: boolean = false;
  public showRemoveFilePanel: boolean = false;
  public fileUploaded: boolean = false;
  public disableTime: boolean = true;
  public arrayBuffer: any;
  public file: File;
  public isValidFile: boolean = true;

  public temporaryWorkAbroadIds: string[] = [...MOCK_TEMP_WORK_ABORD_IDS];
  public travelInsuredPeopleTypeList: TravelInsuredPeople[] = [
    ...MOCK_TRAVEL_INSURED_PEOPLE_TYPE_LIST,
  ];

  public travelTripList: TravelTripTypeDto[] = [...MOCK_TRIP_LIST];
  public travelUsCanadOptionList: RadioButtonDto[] = [...MOCK_TRAVELUS_CANADA_OPTION_LIST];
  public daysInAbroadList: RadioButtonDto[] = [...MOCK_DAYSIN_ABROAD_LIST];
  public mockTravelReason: TravelReasonDto[] = [...MOCK_TRAVEL_REASON];
  public mockWorkType: TravelWorkType[] = [...MOC_WORK_TYPE];
  public updatedTravelCover: TravelCovers;
  public addedInsuredPersonArray: InsuredPerson[] = [];
  public insuredPeopleStateValue: string;

  public stepForm1: FormGroupManager<any> = this.formFactory.createFormManager<any>({
    totalPeople: new FormControl(0, Validators.min(1)),
    tripRadioGroup: new FormControl(this.dropDownDeafultId, Validators.required),
    tripFormArray: this._formBuilder.array([this.bindSingleTripFormGroup()]),
    travelReason: new FormControl(undefined, [Validators.required]),
    workType: new FormControl(undefined),
  });

  public insuredPeopleForm: FormGroup;

  public bindSingleTripFormGroup(): FormGroup {
    return this._formBuilder.group({
      startEndData: new FormControl(this.defaultStartDate, [
        Validators.required,
        onceDateValidator(),
      ]),
      time: new FormControl(undefined, [Validators.required]),
      travelAbroadRadioGroup: new FormControl(this.dropDownDeafultId, [Validators.required]),
    });
  }

  public updateDynamicValidation(): void {
    const singleTripData = this.tripFormArray.controls[0] as FormGroup;
    const startEndDate = singleTripData.controls.startEndData.value;
    if (startEndDate.start?.equals(DateUtils.today())) {
      singleTripData.controls.time.setValidators(Validators.required);
    } else {
      singleTripData.controls.time.setValidators(null);
    }
    singleTripData.controls.time.updateValueAndValidity();
  }

  public bindMultiTripFormGroup(): FormGroup {
    return this._formBuilder.group({
      multipleStartDate: new FormControl(this.defaultStartDate, [
        Validators.required,
        dateValidator(),
        control =>
          ExtraDateValidators.before(DateUtils.add(this.maxDateMultiple, DateKey.Day, 1))(
            control.value,
          ),
        control =>
          ExtraDateValidators.after(DateUtils.add(this.minDateMultiple, DateKey.Day, -1))(
            control.value,
          ),
      ]),
      multipleEndDate: new FormControl({value: this.maxDate, disabled: true}, [
        Validators.required,
      ]),
      daysAbroad: new FormControl(this.dropDownDeafultId, [Validators.required]),
    });
  }

  constructor(
    private readonly _formBuilder: FormBuilder,
    activatedRoute: ActivatedRoute,
    public travelSessionService: TravelSessionService,
    public travelPolicyService: TravelPolicyService,
    private readonly formFactory: FormGroupManagerFactoryService,
    public dateFormateService: DateFormatterService,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _travelSlideInService: TravelSlideInService,
    public router: Router,
  ) {
    super(activatedRoute);
    this.countDecimal = DEFAULT_DEMO_STEPPER_MIN_VALUE;
    this.maximumDecimal = DEFAULT_DEMO_STEPPER_MAX_VALUE;
    this.minimumDecimal = DEFAULT_DEMO_STEPPER_MIN_VALUE;

    this.insuredPeopleForm = new FormGroup({
      insuredPeopleArray: new FormArray([]),
    });
    this.insuredPeopleStateValue = this.router.getCurrentNavigation()?.extras.state?.insuredPeople;
  }

  public ngOnInit(): void {
    this.hideSingleTrip();
    this.initializeValueChanges();
    this.prePopulateData();
    this.travelPolicyService.showPriceAccordion(false);
    this.updateDynamicValidation();
  }

  public changeEvent(event: DateRange): void {
    if (event.start?.equals(DateUtils.today())) {
      this.disableTime = false;
      const startHr: number = event.start?.equals(DateUtils.today())
        ? this.dateFormateService.getHr() + 3
        : 0;

      const hr: string = startHr > 10 ? `${startHr}${BLANK_VALUE}` : `0${startHr}`;
      this.tripFormArray.controls[0].patchValue({
        time: new Text_2(`${hr}00`),
      });
    } else {
      this.disableTime = true;
      this.updateDynamicValidation();
    }
  }

  public prePopulateData(): void {
    if (this.travelSessionService.data) {
      const data: ProposalDto = this.travelSessionService.data;
      if (data.travelRenewPolicyDto?.travelRenewDetails && !data.tripDetailDto) {
        this.hideSingleTrip();
      } else if (data.tripDetailDto) {
        const tripDetailDto: TripDetailDto = data.tripDetailDto;
        const tripId = tripDetailDto.tripRadioGroup.tripId;
        this.stepForm1.patchValue({
          tripRadioGroup: tripId,
          travelReason: tripDetailDto.travelReason.ID,
          workType: tripDetailDto.workType ? tripDetailDto.workType.fieldValue : undefined,
        });
        //Time being its commented
        // if (
        //   tripDetailDto.travelInsuredPeople != null &&
        //   tripDetailDto.travelInsuredPeople.length > 0
        // ) {
        //   this.travelInsuredPeopleTypeList = [...tripDetailDto.travelInsuredPeople];
        // }

        this.showHideSingleMultipleTripBox(tripId);
        tripId === TripNumber.singleTrips
          ? this.tripFormArray.controls[0].patchValue(tripDetailDto.singleTrip)
          : this.tripFormArray.controls[0].patchValue(tripDetailDto.multiTrip);
      }

      if (data.insuredPersonDto && data.tripDetailDto && data.tripDetailDto.isUploadFile) {
        this.addedInsuredPersonArray = data.insuredPersonDto.addedInsuredPerson;
        this.updateFormArray();
        this.stepForm1.controls.totalPeople.setValue(this.addedInsuredPersonArray.length);
      }
      if (this.insuredPeopleStateValue != null) {
        this.addedInsuredPersonArray =
          this.insuredPeopleStateValue != null ? JSON.parse(this.insuredPeopleStateValue) : [];
        if (this.addedInsuredPersonArray != null && this.addedInsuredPersonArray.length > 0) {
          this.updateInsurePersonSessionArray();
          this.updateFormArray();
        }
        this.stepForm1.controls.totalPeople.setValue(this.addedInsuredPersonArray.length);
      }
    }
  }

  public showHideSingleMultipleTripBox(tripId: number): void {
    this.disableTime = true;
    this.tripFormArray.clear();
    this.showHideBlueDiv = true;
    if (tripId === TripNumber.multipleTrips) {
      this.showHideDiv = false;
      this.pushMultiTripFormGroup();
    } else {
      const data: ProposalDto = this.travelSessionService.data;
      if (data.travelRenewPolicyDto?.travelRenewDetails) {
        this.showHideDiv = false;
        this.pushMultiTripFormGroup();
      } else {
        if (tripId === TripNumber.singleTrips) {
          this.showHideDiv = true;
          this.pushSingTripFormGroup();
        } else {
          this.showHideDiv = false;
          this.pushMultiTripFormGroup();
        }
      }
    }
  }

  public setDisabled(i: number): boolean {
    const data: ProposalDto = this.travelSessionService.data;

    if (data.travelRenewPolicyDto?.travelRenewDetails) {
      return i == 0;
    } else {
      return false;
    }
  }
  public getValueTravelReason(value: any): string | null {
    const o = this.mockTravelReason
      ? this.mockTravelReason.find((options: any) => options.ID == value)
      : null;
    return o ? o.objectName : null;
  }

  public getValueWorkType(value: any): string | null {
    const o = this.mockWorkType
      ? this.mockWorkType.find((options: any) => options.fieldValue == value)
      : null;
    return o ? o.fieldValueName : null;
  }

  get tripFormArray(): FormArray {
    return this.stepForm1.controls.tripFormArray as FormArray;
  }

  public pushSingTripFormGroup(): void {
    this.tripFormArray.push(this.bindSingleTripFormGroup());
  }

  public pushMultiTripFormGroup(): void {
    this.tripFormArray.push(this.bindMultiTripFormGroup());
    this.endDateUpdater();
  }

  public endDateUpdater(): void {
    const multipleFormGroup = this.tripFormArray.controls[0] as FormGroup;
    multipleFormGroup.controls.multipleStartDate.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result: any) => {
        if (multipleFormGroup.controls.multipleStartDate.valid) {
          const endDate = DateUtils.add(
            multipleFormGroup.controls.multipleStartDate.value,
            DateKey.Day,
            364,
          );
          multipleFormGroup.patchValue({
            multipleEndDate: endDate,
          });
        }
        multipleFormGroup.controls.multipleEndDate.updateValueAndValidity();
      });
  }

  public hideSingleTrip(): void {
    const data: ProposalDto = this.travelSessionService.data;
    if (data.travelRenewPolicyDto?.travelRenewDetails) {
      if (data.travelRenewPolicyDto.travelRenewDetails[0].id === NewTile.newTileId) {
        const tripId = TripNumber.multipleTrips;
        this.stepForm1.patchValue({
          tripRadioGroup: tripId,
        });
        this.showHideSingleMultipleTripBox(tripId);
      }
    }
  }

  protected saveMtplData(): void {
    const insuredPeopleList: TravelInsuredPeople[] = this.travelInsuredPeopleTypeList;
    const val = this.stepForm1.value;
    if (val) {
      const tripId = val.tripRadioGroup;
      const travelTripObj = this.travelTripList.find(tTrip => tTrip.tripId === tripId);

      const travelReasonId = val.travelReason;
      const travelReasonObj = this.mockTravelReason.find(reason => reason.ID === travelReasonId);

      const workType = val.workType;
      const workTypeObj = this.mockWorkType.find(work => work.fieldValue === workType);
      const totalNumberOfPeople: number = this.stepForm1.controls.totalPeople.value as number;

      if (travelTripObj && travelReasonObj) {
        const tripDetail: TripDetailDto = {
          travelInsuredPeople: insuredPeopleList,
          travelInsuredPeopleCount: totalNumberOfPeople,
          tripRadioGroup: travelTripObj,
          travelReason: travelReasonObj,
          singleTrip: tripId === TripNumber.singleTrips ? this.getSingleTripData() : undefined,
          multiTrip: tripId !== TripNumber.singleTrips ? this.getMultiTripData() : undefined,
          workType: workTypeObj,
          isUploadFile: this.fileUploaded,
        };
        this.travelSessionService.setTripDetailDto(tripDetail);
      }

      if (this.updatedTravelCover) {
        const traveleCoverDto: TravelCoversDto = {
          selectedCover: this.updatedTravelCover,
        };
        this.travelSessionService.setTravelCovers(traveleCoverDto);
      }
    }

    this.updateInsurePersonSessionArray();
  }

  public updateInsurePersonSessionArray(): void {
    if (this.addedInsuredPersonArray != null) {
      const insuredPersonDto = {
        addedInsuredPerson: this.addedInsuredPersonArray,
      };
      this.travelSessionService.setInsuredPersonData(insuredPersonDto);
    }
  }

  public getMultiTripData(): MultiTripFormData {
    const multiTripFormGroup = this.tripFormArray.controls[0] as FormGroup;
    return {
      multipleStartDate: multiTripFormGroup.controls.multipleStartDate.value,
      multipleEndDate: multiTripFormGroup.controls.multipleEndDate.value,
      daysAbroad: multiTripFormGroup.controls.daysAbroad.value,
    };
  }

  public getSingleTripData(): SingleTripFormData {
    const singeTripFormGroup = this.tripFormArray.controls[0] as FormGroup;
    return {
      startEndData: singeTripFormGroup.controls.startEndData.value,
      time: singeTripFormGroup.controls.time.value,
      travelAbroadRadioGroup: singeTripFormGroup.controls.travelAbroadRadioGroup.value,
    };
  }

  public viewDetails(): void {
    this._travelSlideInService
      .openTripDetailsSlideIn()
      .subscribe((res: ModalConfirmedResult<void>) => {
        // logic
      });
  }

  public isInvalidDate(formControl: AbstractControl): boolean {
    if (!formControl) {
      return true;
    }
    if (!formControl.errors) {
      return false;
    }
    return formControl.hasError('invalid');
  }

  public initializeValueChanges(): void {
    this.stepForm1.controls.travelReason.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result: any) => {
        if (this.temporaryWorkAbroadIds.indexOf(result) > -1) {
          this.showWorkType = true;
          this.stepForm1.controls.workType.setValidators([Validators.required]);
        } else {
          this.showWorkType = false;
          this.stepForm1.controls.workType.setValidators(null);
          this.stepForm1.patchValue({
            workType: undefined,
          });
        }
        this.stepForm1.controls.workType.updateValueAndValidity();
      });
  }

  public getMax(i: number): number {
    const specificCount: Decimal = this.travelInsuredPeopleTypeList[i].numberOfPeople;
    return (
      TotalPeople.peopleCount +
      Number(DecimalFormatter.formatDecimal(specificCount, 0)) -
      this.stepForm1.controls.totalPeople.value
    );
  }

  public isMax(): boolean {
    const decimalSum = DecimalUtils.plus(
      this.travelInsuredPeopleTypeList[0].numberOfPeople,
      this.travelInsuredPeopleTypeList[1].numberOfPeople,
      this.travelInsuredPeopleTypeList[2].numberOfPeople,
      this.travelInsuredPeopleTypeList[3].numberOfPeople,
    );

    const total: number = Number(DecimalFormatter.formatDecimal(decimalSum, 0));
    this.stepForm1.controls.totalPeople.setValue(total);
    return total === TotalPeople.peopleCount;
  }

  public enableContinueBtn(): boolean {
    const totalNumberOfPeople: number = this.stepForm1.controls.totalPeople.value as number;
    return totalNumberOfPeople < TotalPeople.peopleCount ? !this.stepForm1.valid : true;
  }

  public reuploadFile(): void {
    this.showRemoveFilePanel = true;
    this.showReuploaderPanel = false;
  }

  public removeFile(): void {
    this.showRemoveFilePanel = false;
    this.showNewUploaderPanel = true;
    this.fileUploaded = false;
    this.addedInsuredPersonArray = [];
    this.isValidFile = true;
    this.resetTravelInsuredPeopleTypeList();
  }

  public resetTravelInsuredPeopleTypeList(): void {
    this.travelInsuredPeopleTypeList.forEach((type: TravelInsuredPeople) => {
      type.numberOfPeople = DecimalUtils.ZERO;
    });
  }

  public onFileChange(event: any): void {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    this.travelPolicyService.setUploadedFile(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary', cellDates: true});

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this._cdRef.markForCheck();
      console.log(data); // its need for timebeing, bcoz we are reading xls data's
      if (data && data.length > 0) {
        const filteredList = data.filter(
          (book: FileData) =>
            book["Insured person's PID"] &&
            book["Insured person's PID"] !== undefined &&
            book["Insured person's PID"] != null,
        );
        if (filteredList && filteredList.length > 0) {
          this.isValidFile = true;
          this.validateFileData(filteredList);
        } else {
          this.isValidFile = false;
        }
      } else {
        this.isValidFile = false;
      }
    };
  }

  public get tripList(): FormArray {
    return this.insuredPeopleForm.controls.insuredPeopleArray as FormArray;
  }

  public validateFileData(filteredList: unknown[]) {
    const fileList = this.travelPolicyService.parseInusredPeopleFilterList(filteredList);
    this.addedInsuredPersonArray = [...fileList];
    this.updateFormArray();
  }

  public updateFormArray(): void {
    this.addedInsuredPersonArray.forEach((element: InsuredPerson) => {
      const formGroup = this.travelPolicyService.updateInsuredPeopleArray(element);
      this.tripList.push(formGroup);
    });
    this.fileUploaded = this.insuredPeopleForm.valid;
    this.fileUploaded ? this.updateNumberOfPeople() : BLANK_VALUE;
    this._cdRef.markForCheck();
  }

  public updateNumberOfPeople(): void {
    this.travelInsuredPeopleTypeList.forEach((type: TravelInsuredPeople) => {
      const numberOfPeople = this.addedInsuredPersonArray.filter((p: InsuredPerson) =>
        p.age != null ? p.age >= type.start && p.age < type.end : 0,
      );
      type.numberOfPeople = new Decimal(`${numberOfPeople.length}${BLANK_VALUE}`);
    });
  }
}
