<div class="account-password-reset-wrapper">
  <div class="title-wrapper">
    <maia-label class="b2b-kdl-font-medium-bold title-dark-color">
      B2B Account Password Reset
    </maia-label>
    <nje-search-box (searchData)="searchAccounts($event)"></nje-search-box>
  </div>

  <div class="account-details-wrapper" *ngIf="showAccountDetails">
    <maia-divider class="m-t-b-24"></maia-divider>
    <nje-account-details [userDetails]="userAccountDetails"> </nje-account-details>
    <maia-button-group class="button-wrapper m-t-24 reset-button">
      <button maiaButton maiaButtonType="primary" (click)="resetPassword()">
        Reset Password
      </button>
      <button maiaButton maiaButtonType="secondary">Cancel</button>
    </maia-button-group>
  </div>
</div>
