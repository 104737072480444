import {CheckBoxItems, CoverItems} from './price-covers-mock-interface';

export const MOCK_READ_MORE_LINK =
  'https://www.dzi.bg/attachments/Product/30/download/8104-Singleitrip-IPID-0.pdf';

export enum MockAdvanceBonus {
  YES = 'yes',
  NO = 'no',
}

export const MOCK_DEDUCTIBLE_ID: number = 1;

export const MOCK_MAIN_COVERS_LIST: CoverItems[] = [
  {id: 0, desc_en: 'Full Casco'},
  {id: 1, desc_en: 'Super Casco'},
  {id: 2, desc_en: 'Total Loss'},
  {id: 3, desc_en: 'Theft & Robbery of Whole Vehicle'},
];

export const MOCK_RA_COVERS_LIST: CoverItems[] = [
  {id: 0, desc_en: 'RA Premium + Abroad'},
  {id: 1, desc_en: 'RA VIP + Abroad'},
  {id: 2, desc_en: 'Additional Assistance Services for Bulgaria'},
];

export const MOCK_ADDONS_LIST: CheckBoxItems[] = [
  {id: 0, desc_en: 'Replacing vehicle'},
  {id: 1, desc_en: 'Repair Abroad'},
  {id: 2, desc_en: 'Special agreement for construction, agricultural and forestry machines'},
  {id: 3, desc_en: 'Additional Territorial Coverage'},
  {id: 4, desc_en: 'Additional conditions in case of third party'},
];

export const MOCK_ADDITIONAL_LIST: CheckBoxItems[] = [
  {id: 0, desc_en: 'No limit for damages per declaration'},
  {id: 1, desc_en: 'Risk coverage Theft and robbery before installing a security device'},
  {id: 2, desc_en: 'Coverage before inspection'},
  {id: 3, desc_en: 'Longer period for filling damages'},
  {id: 4, desc_en: 'Change of limits for payment of damages'},
  {id: 5, desc_en: 'Payment of 100% in case of total damage'},
  {id: 6, desc_en: 'Reduction of the term for payment of damages'},
  {id: 7, desc_en: 'Coverage of risks excluded from GTC'},
  {id: 8, desc_en: 'No Limitation for written event declaration for Trusted Service settlement'},
  {id: 9, desc_en: 'Coverage of the earthquake risk and its consequences'},
];
