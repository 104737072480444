import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ScreenFormComponent} from '@njf-frontend-angular/flow-progress';
import {CascoWorkflow} from '../../casco-core/casco-workflow/CascoWorkflow';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';

@Component({
  selector: 'nje-journey-choice',
  templateUrl: './journeychoice.component.html',
  styleUrls: ['./journeychoice.component.scss'],
})
export class JourneychoiceComponent extends ScreenFormComponent {
  public continueClicked: boolean;

  public constructor(route: ActivatedRoute, private readonly _process: CascoProcessService) {
    super(route);
  }

  public toQuickQuote(): void {
    this._process.currentWorkflow = CascoWorkflow.QUICK_QUOTE;
    this.saveAndContinue();
  }

  public toPriceOffer(): void {
    this._process.currentWorkflow = CascoWorkflow.PRICE_OFFER;
    this.saveAndContinue();
  }

  protected saveMtplData(): void {
    this.continueClicked = true;
  }
}
