<form maiaForm [formGroup]="RelatedPartyForm.formGroup">
  <maia-form-group>
    <maia-label class="b2b-kdl-font-small title-dark-color">Related Parties </maia-label>
    <maia-form-element [hideOptionalIndicator]="true" class="slidein-input-column">
      <maia-input-select name="relatedParty" formControlName="relatedParty" ngDefaultControl>
        <input
          maiaInput
          placeholder="Select"
          [value]="getRelatedParty(RelatedPartyForm.controls.relatedParty.value)"
        />
        <maia-option *ngFor="let i of mockRelatedParty" [value]="i.partyName">
          {{ i.partyName }}
        </maia-option>
      </maia-input-select>
    </maia-form-element>

    <maia-label class="b2b-kdl-font-small title-dark-color">EGN / LNCH / EIK </maia-label>
    <maia-form-element
      [options]="{validationInstant: true}"
      [hideOptionalIndicator]="true"
      class="slidein-input-column"
    >
      <div *ngIf="!isNewDetails" class="input-box">
        <input
          maiaInput
          name="EGN / LNCH / EIK"
          placeholder="EGN / LNCH / EIK"
          formControlName="idNumber"
          (ngModelChange)="idNumberValidator($event)"
        />
      </div>
    </maia-form-element>
    <maia-search-bar
      *ngIf="isNewDetails"
      class="search-box"
      [placeholder]="'EGN / LNCH / EIK'"
      (inputText)="handleInputText($event)"
      [(ngModel)]="searchEgn"
      ngDefaultControl
      [ngModelOptions]="{standalone: true}"
    >
    </maia-search-bar>
    <div *ngIf="isEIK">
      <maia-label class="b2b-kdl-font-small title-dark-color">Company Name </maia-label>
      <maia-form-element
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="slidein-input-column"
      >
        <input maiaInput placeholder="Company Name" formControlName="companyName" />
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('companyName') &&
            RelatedPartyForm.hasError('companyName', 'required')
          "
        >
          Company name required
        </maia-validation>
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('companyName') &&
            RelatedPartyForm.hasError('companyName', 'nonLatinCyrillicLetters')
          "
          >Company name - only latin or cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('companyName') &&
            RelatedPartyForm.hasError('companyName', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('companyName') &&
            RelatedPartyForm.hasError('companyName', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ RelatedPartyForm.controls['companyName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
    <div *ngIf="!isEIK">
      <maia-label class="b2b-kdl-font-small title-dark-color">Full Name </maia-label>
      <maia-form-element [options]="{validationInstant: true}" multi class="slidein-input-column">
        <maia-form-subelement>
          <input maiaInput type="text" placeholder="First Name" formControlName="firstName" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput type="text" placeholder="Middle Name" formControlName="middleName" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput type="text" placeholder="Last Name" formControlName="lastName" />
        </maia-form-subelement>
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('firstName') &&
            RelatedPartyForm.hasError('firstName', 'required')
          "
          >First name required</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('firstName') &&
            RelatedPartyForm.hasError('firstName', 'nonLatinLetters')
          "
          >First name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('firstName') &&
            RelatedPartyForm.hasError('firstName', 'nonBlank')
          "
          >Invalid first name</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('firstName') &&
            RelatedPartyForm.hasError('firstName', 'maxlength')
          "
          >First name is too long (maximum is
          {{ RelatedPartyForm.controls['firstName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('middleName') &&
            RelatedPartyForm.hasError('middleName', 'required')
          "
          >Middle name required</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('middleName') &&
            RelatedPartyForm.hasError('middleName', 'nonLatinLetters')
          "
          >Middle name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('middleName') &&
            RelatedPartyForm.hasError('middleName', 'nonBlank')
          "
          >Invalid middle name</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('middleName') &&
            RelatedPartyForm.hasError('middleName', 'maxlength')
          "
          >Middle name is too long (maximum is
          {{ RelatedPartyForm.controls['middleName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('lastName') &&
            RelatedPartyForm.hasError('lastName', 'required')
          "
          >Last name required</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('lastName') &&
            RelatedPartyForm.hasError('lastName', 'nonLatinLetters')
          "
          >Last name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('lastName') &&
            RelatedPartyForm.hasError('lastName', 'nonBlank')
          "
          >Invalid last name</maia-validation
        >
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('lastName') &&
            RelatedPartyForm.hasError('lastName', 'maxlength')
          "
          >Last name is too long (maximum is
          {{ RelatedPartyForm.controls['lastName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
    <maia-label class="b2b-kdl-font-small title-dark-color">City & Zip </maia-label>
    <maia-form-element
      [hideOptionalIndicator]="true"
      multi
      class="slidein-input-column city-and-postcode"
    >
      <maia-form-subelement>
        <maia-input-autocomplete
          name="city"
          formControlName="city"
          ngDefaultControl
          (typeAhead)="cityAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let cityObj of cityAutocomplete.list | async" [value]="cityObj">
            <maia-option-highlight [label]="cityObj.city"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('city') &&
            RelatedPartyForm.hasError('city', 'required')
          "
          >City required</maia-validation
        >
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Zip" formControlName="postCode" readonly />
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('postCode') &&
            RelatedPartyForm.hasError('postCode', 'required')
          "
          >Post Code required</maia-validation
        >
      </maia-form-subelement>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Quarter </maia-label>
    <maia-form-element [hideOptionalIndicator]="true" class="slidein-input-column" multi>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Quarter" formControlName="quarter" />
      </maia-form-subelement>
      <maia-validation
        *ngIf="
          RelatedPartyForm.hasToShowErrors('quarter') &&
          RelatedPartyForm.hasError('quarter', 'required')
        "
        >Quarter is required</maia-validation
      >
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Street & Number </maia-label>
    <maia-form-element class="slidein-input-column city-and-postcode" multi>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Street" formControlName="street" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Number" formControlName="streetNumber" />
      </maia-form-subelement>
      <maia-validation
        *ngIf="
          RelatedPartyForm.hasToShowErrors('street') &&
          RelatedPartyForm.hasError('street', 'required')
        "
      >
        Street required
      </maia-validation>
      <maia-validation
        *ngIf="
          RelatedPartyForm.hasToShowErrors('streetNumber') &&
          RelatedPartyForm.hasError('streetNumber', 'required')
        "
      >
        Street number required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color"
      >Block, Entrance, Floor & Apartment
    </maia-label>
    <maia-form-element class="slidein-input-column" multi>
      <maia-form-subelement>
        <input maiaInput type="text" name="block" placeholder="Block" formControlName="block" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input
          maiaInput
          type="text"
          name="entrance"
          placeholder="Entrance"
          formControlName="entrance"
        />
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floor" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input
          maiaInput
          type="text"
          name="apartment"
          placeholder="Apartment"
          formControlName="apartment"
        />
      </maia-form-subelement>
    </maia-form-element>
    <div *ngIf="isLNCH">
      <maia-label class="b2b-kdl-font-small title-dark-color">Citizenship </maia-label>
      <maia-form-element
        class="slidein-input-column"
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
      >
        <maia-input-autocomplete
          name="citizenship"
          formControlName="citizenship"
          (typeAhead)="citizenshipAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="dzi-off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let c of citizenshipAutocomplete.list | async" [value]="c.id">
            <maia-option-highlight [label]="c.title"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('citizenship') &&
            RelatedPartyForm.hasError('citizenship', 'required')
          "
        >
          Citizenship required
        </maia-validation>
      </maia-form-element>
      <maia-label class="b2b-kdl-font-small title-dark-color">Date of Birth </maia-label>
      <maia-form-element
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="slidein-input-column"
      >
        <maia-input-date
          name="dob"
          [(ngModel)]="birthDatePicker.value"
          (ngModelChange)="birthDatePicker.change($event)"
          (blur)="birthDatePicker.touch()"
          [minimum]="minDate"
          [maximum]="maxDate"
          [ngModelOptions]="{standalone: true}"
          [visibleView]="visibleDateView"
        ></maia-input-date>
        <maia-validation
          *ngIf="
            RelatedPartyForm.hasToShowErrors('dob') && RelatedPartyForm.hasError('dob', 'required')
          "
        >
          Date of birth required
        </maia-validation>
      </maia-form-element>
    </div>
    <maia-label class="b2b-kdl-font-small title-dark-color">Phone Number </maia-label>
    <maia-form-element class="slidein-input-column">
      <maia-input-phonenumber
        name="phone"
        formControlName="phoneNumber"
        #phoneInput
        placeholder="0000000000"
        ngDefaultControl
        class="disable-country-code"
      >
        <maia-phonenumber-country prefix="+359" name="Bulgaria">
          <ng-template #flag>
            <svg b2bFrontendFlagUtilBulgaria></svg>
          </ng-template>
        </maia-phonenumber-country>
      </maia-input-phonenumber>
      <maia-validation
        *ngIf="
          RelatedPartyForm.hasToShowErrors('phoneNumber') &&
          RelatedPartyForm.hasError('phoneNumber', 'required')
        "
      >
        Phone number required
      </maia-validation>
    </maia-form-element>

    <maia-label class="b2b-kdl-font-small title-dark-color">Email ID </maia-label>
    <maia-form-element class="slidein-input-column">
      <input maiaInput placeholder="Email ID" formControlName="emailId" />
    </maia-form-element>
  </maia-form-group>
</form>
<div class="step-form-button-bar">
  <maia-button-group>
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      (click)="saveInsuringPartyDetails()"
      >Add</button
    >
    <button maiaButton maiaButtonType="secondary" (click)="cancel()">Cancel</button>
  </maia-button-group>
</div>
