import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'nje-uam',
  templateUrl: './uam.component.html',
  styleUrls: ['./uam.component.css'],
})
export class UamComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
