<div *maiaPopUpFooter>
  <div class="status-align">
    <svg class="status" b2bFrontendAlertExprCheckCircle></svg>
  </div>

  <p class="b2b-kdl-font-medium-bold" i18n="@@nje-b2b-frontend-statistics-report-download"
    >Downloaded</p
  >

  <div class="step-form-button-bar">
    <maia-button-group>
      <button
        maiaButton
        maiaButtonType="primary"
        (click)="control.cancel()"
        i18n="@@nje-b2b-frontend-statistics-report-close"
        >Close</button
      >
    </maia-button-group>
  </div>
</div>
