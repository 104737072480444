// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.uat.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  company: {code: '0001', name: 'KBC'}, // default company
  language: {name: 'English', code: 'en'}, // default language
  API_BASE_URL: 'https://mudf53bxqj.execute-api.eu-central-1.amazonaws.com',
  FE_BASE_URL: 'https://devb2b.dzi.bg',
  API_URL: '/api/v1/',
};
