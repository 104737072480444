<form maiaForm [formGroup]="LibraryListSlideinForm">
  <maia-form-group>
    <maia-label>
      <label
        class="b2b-kdl-font-large title-dark-color slidein-heading"
        i18n="@@nje-b2b-frontend-resource-library-list-libtitle"
      >
        Library Title
      </label>
    </maia-label>
    <maia-form-element
      [options]="{validationInstant: true}"
      class="slidein-input-column"
      hideOptionalIndicator
    >
      <maia-text-area-limit [maxLength]="titleLimit">
        <textarea
          name="libraryName"
          id="title"
          maiaTextArea
          [atlasMaxLength]="titleLimit"
          i18n-placeholder="@@nje-b2b-frontend-resource-library-entertitle"
          name="libraryName"
          placeholder="Enter library title"
          formControlName="libraryName"
          class="textarea-style"
        >
        </textarea>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-library-list-titlereq"
          *ngIf="
            LibraryListSlideinForm.controls['libraryName'].touched &&
            LibraryListSlideinForm.controls['libraryName'].hasError('required')
          "
        >
          Title required
        </maia-validation>
      </maia-text-area-limit>
    </maia-form-element>
    <maia-label>
      <label
        class="b2b-kdl-font-large title-dark-color slidein-heading"
        i18n="@@nje-b2b-frontend-resource-library-list-libdesc"
      >
        Library Description
      </label>
    </maia-label>
    <maia-form-element
      [options]="{validationInstant: true}"
      class="slidein-input-column"
      hideOptionalIndicator
    >
      <maia-text-area-limit [maxLength]="descriptionLimit">
        <textarea
          name="librarySubtitle"
          id="description"
          maiaTextArea
          [atlasMaxLength]="descriptionLimit"
          i18n-placeholder="@@nje-b2b-frontend-resource-library-enterdesc"
          name="librarySubtitle"
          placeholder="Enter library description"
          formControlName="librarySubtitle"
          class="textarea-style"
        >
        </textarea>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-library-list-descreq"
          *ngIf="
            LibraryListSlideinForm.controls['librarySubtitle'].touched &&
            LibraryListSlideinForm.controls['librarySubtitle'].hasError('required')
          "
        >
          Description required
        </maia-validation>
      </maia-text-area-limit>
    </maia-form-element>
  </maia-form-group>
</form>
<div class="step-form-button-bar">
  <maia-button-group *ngIf="isNewLibraryList()">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!LibraryListSlideinForm.valid"
      (click)="addOrUpdateLibraryList()"
      i18n="@@nje-b2b-frontend-resource-admin-update"
      >Update</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-list-libcancel"
      >Cancel</button
    >
  </maia-button-group>

  <maia-button-group *ngIf="!isNewLibraryList()">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!LibraryListSlideinForm.valid"
      (click)="addOrUpdateLibraryList()"
      i18n="@@nje-b2b-frontend-resource-library-list-add"
      >Add</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-list-listcancel"
      >Cancel</button
    >
  </maia-button-group>
</div>
