<nje-library-list (selectedLibraryList)="OnSelectedLibraryList($event)"> </nje-library-list>

<nje-product-portfolio
  [selectLibrary]="selectedLibrary"
  (selectedProductPortfolio)="OnSelectedProductPortfolio($event)"
></nje-product-portfolio>

<nje-library-product
  [selectProductPortfolio]="selectedProductPortfolioTile"
  [selectLibrary]="selectedLibrary"
  (selectedProduct)="OnSelectedProduct($event)"
></nje-library-product>
