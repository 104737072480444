import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {Subscription} from 'rxjs';

import {CommonMessage} from '../../../models/CommonMessage';
import {CommonService} from '../../../services/common.service';

@Component({
  selector: 'nje-common-message-popup',
  templateUrl: './common-message-popup.component.html',
  styleUrls: ['./common-message-popup.component.scss'],
})
export class CommonMessagePopupComponent extends ModalContentComponent<void> {
  public commonMessage: CommonMessage;
  public commonMessageSubscription: Subscription;

  public constructor(
    public control: ModalControl<void>,
    private readonly _commonService: CommonService,
  ) {
    super();
    this.commonMessageSubscription = this._commonService
      .getcommonMessage()
      .subscribe((data: CommonMessage) => {
        this.commonMessage = data;
        this.closePopup();
      });
    this._clearSubscriptions();
  }

  public closePopup(): void {
    this._clearSubscriptions();
    setTimeout(() => {
      this.control.confirm();
    }, 1000);
  }

  private _clearSubscriptions(): void {
    if (this.commonMessageSubscription) {
      this.commonMessageSubscription.unsubscribe();
    }
  }
}
