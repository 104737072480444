import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-download-report-popup',
  templateUrl: './download-report-popup.component.html',
  styleUrls: ['./download-report-popup.component.scss'],
})
export class DownloadReportPopupComponent extends ModalContentComponent<void> {
  public constructor(public control: ModalControl<void>) {
    super();
  }

  public close(): void {
    this.control.confirm();
  }
}
