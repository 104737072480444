import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialExprGroupSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendSocialExprGroup]',
  template: '<svg:path d="M19.31 12.44a4.55 4.55 0 1 1 9.1 0h0c0 2.51-2 5.56-4.55 5.56s-4.55-3-4.55-5.56z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M20.32 21a12.11 12.11 0 0 1 3.54-.49 9.46 9.46 0 0 1 4 .69" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M31.88 11.17a4.55 4.55 0 1 1 9.1 0h0c0 2.52-2 5.56-4.55 5.56s-4.55-3.04-4.55-5.56z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M32.89 19.75a12.11 12.11 0 0 1 3.54-.49 9.53 9.53 0 0 1 5.19 1.29A3 3 0 0 1 43 23.09v5.78h-5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M7 13.45a4.55 4.55 0 0 1 9.1 0h0c0 2.51-2 5.56-4.55 5.56S7 16 7 13.45z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M11.57 21.54a9.39 9.39 0 0 1 4.55.95" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M10.57 31.14H5v-5.77a3 3 0 0 1 1.38-2.54 9.53 9.53 0 0 1 5.19-1.29" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M14.51 29.75a4 4 0 0 1 8.08-.1v.1c0 2.24-1.81 4.55-4 4.55s-4.08-2.3-4.08-4.55z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M24.61 39.61a2 2 0 0 0-1.25-1.88 13.3 13.3 0 0 0-9.62 0 2 2 0 0 0-1.26 1.88v1.77h12.13z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M26.18 28a4 4 0 1 1 8.08-.11V28c0 2.23-1.81 4.55-4 4.55s-4.08-2.36-4.08-4.55z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M27.7 39.59h8.58v-1.78A2 2 0 0 0 35 35.94a13.24 13.24 0 0 0-9.24-.14" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialExprGroupSvgComponent {
}

@NgModule({
  declarations: [
    SocialExprGroupSvgComponent,
  ],
  exports: [
    SocialExprGroupSvgComponent,
  ],
})
export class SocialExprGroupSvgModule {}
