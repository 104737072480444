/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/strict-boolean-expressions*/
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {
  policyNumberValidator,
  PolicyNumberValidatorService,
  vehicleRegistrationNumberValidator,
  PartyIdService,
  partyIdValidator,
} from '@b2b-frontend/core';
import {SELLER_UBB, SELLER, USER_ID, LOGGED_IN_USER} from '../../../constants/users';
import {PaymentsService} from '../../../payments-core/services/payments.service';
import {
  FindAllClientPoliciesInput,
  FindAllClientPoliciesOutput,
  PaymentMethod,
  SearchPolicyScreenStep,
  SellerId,
} from '../../../contracts/instalment-payment/instalment';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {INSTALMENT_PAYMENT} from '../../../constants/constant';
import {concatMap, tap} from 'rxjs/operators';
import {PaymentsSessionService} from '../../../payments-core/services/payments-session.service';
import {PaymentWorkflow} from '../../../payments-core/payments-workflow/payment-workflow';
import {PaymentsProcessService} from '../../../payments-core/services/payments-process.service';

@Component({
  selector: 'nje-b2b-search-policy',
  templateUrl: './search-policy.component.html',
  styleUrls: ['./search-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class SearchPolicyComponent extends ScreenStepComponent {
  public paymentMethodList: PaymentMethod[] = [];
  public searchForm: FormGroup;
  public directDebit: boolean;
  public sellerIdList: SellerId[] = [];
  public sellerIdLabel: string = SELLER;
  public loggedInUser: SellerId = LOGGED_IN_USER;

  public constructor(
    route: ActivatedRoute,
    private readonly _searchFormBuilder: FormBuilder,
    private readonly _policyNumberId: PolicyNumberValidatorService,
    private readonly _partyId: PartyIdService,
    private readonly _paymentService: PaymentsService,
    private readonly _paymentSessionService: PaymentsSessionService,
    private readonly _processService: PaymentsProcessService,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    this._getPaymentMethodAndSellerIds();
    this._initialiseSearchForm();

    this.searchForm.controls.paymentMethods.valueChanges.subscribe(
      (paymentMethod: PaymentMethod) => {
        this.sellerIdLabel =
          INSTALMENT_PAYMENT.PAYMENT_METHOD.UBB === paymentMethod.id ? SELLER_UBB : SELLER;
        this.directDebit = INSTALMENT_PAYMENT.PAYMENT_METHOD.DIRECT_DEBIT === paymentMethod.id;
      },
    );
  }

  public getSellerIdAndName(input?: SellerId): string | null {
    if (input) {
      return input?.name + INSTALMENT_PAYMENT.BLANK_VALUE + '(' + input?.id + ')';
    } else {
      return null;
    }
  }

  public isFormValid(): boolean {
    return USER_ID !== LOGGED_IN_USER.id ? this._sameIssuers() : this._differentIssuers();
  }

  private _getPaymentMethodAndSellerIds(): void {
    this._paymentService
      .getPaymentMethods()
      .pipe(
        tap((response: PaymentMethod[]) => {
          this.paymentMethodList = response;
        }),
        concatMap(() => {
          return this._paymentService.getSellerIds(this.loggedInUser.id).pipe(
            takeUntilDestroyed(this),
            tap((response: SellerId[]) => {
              this.sellerIdList = response;
            }),
          );
        }),
      )
      .subscribe();
  }

  private _initialiseSearchForm(): void {
    this.searchForm = this._searchFormBuilder.group({
      paymentMethods: new FormControl(this.paymentMethodList[0], Validators.required),
      uniqueId: new FormControl('', partyIdValidator(this._partyId)),
      vehicleRegistrationNumber: new FormControl('', vehicleRegistrationNumberValidator()),
      policyNumber: new FormControl('', policyNumberValidator(this._policyNumberId)),
      sellerId: new FormControl(''),
    });
  }

  private _sameIssuers(): boolean {
    return !!(
      (this.searchForm.get('uniqueId')?.value &&
        this.searchForm.get('vehicleRegistrationNumber')?.value) ||
      (this.searchForm.get('policyNumber')?.value &&
        this.searchForm.get('vehicleRegistrationNumber')?.value) ||
      (this.searchForm.get('uniqueId')?.value && this.searchForm.get('policyNumber')?.value) ||
      (this.searchForm.get('uniqueId')?.value && this.searchForm.get('sellerId')?.value) ||
      (this.searchForm.get('vehicleRegistrationNumber')?.value &&
        this.searchForm.get('sellerId')?.value) ||
      (this.searchForm.get('policyNumber')?.value && this.searchForm.get('sellerId')?.value)
    );
  }

  private _differentIssuers(): boolean {
    return !!(
      this.searchForm.get('uniqueId')?.value ||
      this.searchForm.get('vehicleRegistrationNumber')?.value ||
      this.searchForm.get('policyNumber')?.value ||
      this.searchForm.get('sellerId')?.value
    );
  }

  public navigateToNext(): void {
    this._updateCurrentWorkflow();
    const requestInput: FindAllClientPoliciesInput = {
      pid: this.searchForm.controls.uniqueId.value as number,
      vehicleRegNo: this.searchForm.controls.vehicleRegistrationNumber.value as string,
      policyNo: this.searchForm.controls.policyNumber.value as string,
      sellerID: (this.searchForm.controls.sellerId.value as SellerId).id,
      userID: this.loggedInUser.id,
    };
    this._paymentService
      .getClientPolicies(requestInput)
      .subscribe((response: FindAllClientPoliciesOutput) => {
        if (response.success === true) {
          this._saveToSession(response);
          this.saveAndContinue();
        } else {
          //ToDo - error message or move to fallback screen
        }
      });
  }

  public saveMtplData(): void {
    //
  }

  private _saveToSession(input: FindAllClientPoliciesOutput): void {
    const data: SearchPolicyScreenStep = {
      searchPolicyOutput: input,
      searchPolicyFormData: this.searchForm.value,
    };
    this._paymentSessionService.setSearchPolicyScreenData(data);
  }

  private _updateCurrentWorkflow(): void {
    switch ((this.searchForm.controls.paymentMethods.value as PaymentMethod).id) {
      case 1:
        this._processService.currentWorkflow = PaymentWorkflow.POLICY_PAYMENT_E_RECEIPT;
        break;
      case 2:
        this._processService.currentWorkflow = PaymentWorkflow.POLICY_PAYMENT_DEBIT_NOTE;
        break;
      case 3:
        this._processService.currentWorkflow = PaymentWorkflow.ISSUING_BLANKS_FOR_MTPL;
        break;
      case 4:
        this._processService.currentWorkflow = PaymentWorkflow.DIRECT_DEBIT;
        break;
      case 5:
        this._processService.currentWorkflow = PaymentWorkflow.POS_PAYMENT;
        break;
      case 6:
        this._processService.currentWorkflow = PaymentWorkflow.UBB;
        break;
      default:
        this._processService.currentWorkflow = PaymentWorkflow.POLICY_PAYMENT_E_RECEIPT;
        break;
    }
  }
}
