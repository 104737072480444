import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionIndiVehicleDeliveryDateSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendActionIndiVehicleDeliveryDate]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M11.5 43.15h93"></svg:path><svg:rect x="11.5" y="22.08" width="93" height="80.31" rx="8.48" stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round" fill="none"></svg:rect><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M32.57 13.61V26.2"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M83.43 13.61V26.2"></svg:path><svg:path stroke-width="2.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M72.53 57.2h14.53v14.53H72.53z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 116 116',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionIndiVehicleDeliveryDateSvgComponent {
}

@NgModule({
  declarations: [
    ActionIndiVehicleDeliveryDateSvgComponent,
  ],
  exports: [
    ActionIndiVehicleDeliveryDateSvgComponent,
  ],
})
export class ActionIndiVehicleDeliveryDateSvgModule {}
