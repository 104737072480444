<form maiaForm [formGroup]="stepForm.formGroup">
  <maia-fluid-fluid-layout alignContentTop
    ><div class="fluid m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Full Name"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('firstName')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('firstName')">
            <input maiaInput type="text" placeholder="First Name" formControlName="firstName" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('firstName')">
              <maia-validation
                *ngIf="stepForm.hasError('firstName', 'required')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-firstnamereq"
                >First name required</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('firstName', 'nonLatinLetters')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-fnamecyrillic"
                >First name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('firstName', 'nonBlank')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-invalidfname"
                >Invalid first name</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('firstName', 'maxlength')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-fnamelength"
                >First name is too long (maximum is
                {{ stepForm.controls['firstName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('middleName')"
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('middleName')"
          >
            <input maiaInput type="text" placeholder="Middle Name" formControlName="middleName" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('middleName')">
              <div *ngIf="isEGN">
                <maia-validation
                  *ngIf="stepForm.hasError('middleName', 'required')"
                  i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-mnamereq"
                  >Middle name required</maia-validation
                >
                <maia-validation
                  *ngIf="stepForm.hasError('middleName', 'nonLatinLetters')"
                  i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-mnamecyrillic"
                  >Middle name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation
                  *ngIf="stepForm.hasError('middleName', 'nonBlank')"
                  i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-mnameinvalid"
                  >Invalid middle name</maia-validation
                >
                <maia-validation
                  *ngIf="stepForm.hasError('middleName', 'maxlength')"
                  i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-mnamelength"
                  >Middle name is too long (maximum is
                  {{ stepForm.controls['middleName'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('lastName')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('lastName')">
            <input maiaInput type="text" placeholder="Last Name" formControlName="lastName" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('lastName')">
              <maia-validation
                *ngIf="stepForm.hasError('lastName', 'required')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnamereq"
                >Last name required</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('lastName', 'nonLatinLetters')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnamecyrillic"
                >Last name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('lastName', 'nonBlank')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnameinvalid"
                >Invalid last name</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('lastName', 'maxlength')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnamelength"
                >Last name is too long (maximum is
                {{ stepForm.controls['lastName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="City & Zip"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="city"
            formControlName="city"
            (typeAhead)="cityAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="City" />

            <maia-option *ngFor="let cityObj of cityAutocomplete.list | async" [value]="cityObj">
              <maia-option-highlight [label]="getCityTitle(cityObj)"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="stepForm.hasToShowErrors('city')">
            <maia-validation
              *ngIf="stepForm.hasError('city', 'required')"
              i18n="@@systemcode-mtpl-contact-cityreq"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input maiaInput type="text" placeholder="Zip" formControlName="postCode" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('postCode')">
              <maia-validation
                *ngIf="stepForm.hasError('postCode', 'required')"
                i18n="@@systemcode-mtpl-contact-postcodereq"
                >Post Code required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Quarter"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('quarter')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('quarter')">
            <input maiaInput type="text" placeholder="Quarter" formControlName="quarter" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('quarter')">
              <maia-validation *ngIf="stepForm.hasError('quarter', 'required')"
                >Quarter is required</maia-validation
              >
              <maia-validation *ngIf="stepForm.hasError('quarter', 'nonLatinLetters')">
                Quarter - only cyrillic letters are allowed
              </maia-validation>
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Street & Number"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('street')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('street')">
            <input maiaInput type="text" placeholder="Street" formControlName="street" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('street')">
              <maia-validation
                *ngIf="stepForm.hasError('street', 'required')"
                i18n="@@systemcode-mtpl-contact-streetreq"
                >Street required</maia-validation
              >
              <maia-validation *ngIf="stepForm.hasError('street', 'nonLatinLetters')">
                Street - only cyrillic letters are allowed
              </maia-validation>
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('streetNumber')"
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('streetNumber')"
          >
            <input maiaInput type="text" placeholder="Number" formControlName="streetNumber" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('streetNumber')">
              <maia-validation
                *ngIf="stepForm.hasError('streetNumber', 'required')"
                i18n="@@systemcode-mtpl-contact-streetnoreq"
                >Street number required</maia-validation
              >
              <maia-validation *ngIf="stepForm.hasError('streetNumber', 'nonLatinLetters')">
                Street Number invalid
              </maia-validation>
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Block, Entrance, Floor & Apartment" multi>
        <maia-form-subelement>
          <input maiaInput type="text" name="block" placeholder="Block" formControlName="block" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="entrance"
            placeholder="Entrance"
            formControlName="entrance"
          />
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floor" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="apartment"
            placeholder="Apartment"
            formControlName="apartment"
          />
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        *ngIf="isLNCH"
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Citizenship"
      >
        <maia-input-autocomplete
          name="citizenship"
          formControlName="citizenship"
          (typeAhead)="citizenshipAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="dzi-off" maiaInput placeholder="Select" />

          <maia-option
            *ngFor="let c of citizenshipAutocomplete.list | async"
            [value]="getCitizenshipId(c)"
          >
            <maia-option-highlight [label]="getCitizenshipTitle(c)"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>

        <maia-validation
          *ngIf="
            stepForm.hasToShowErrors('citizenship') && stepForm.hasError('citizenship', 'required')
          "
        >
          Citizenship required
        </maia-validation>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="multi-input-row"
        label="Phone Number"
      >
        <!-- <input
          maiaInput
          type="text"
          placeholder="Phone Number"
          formControlName="phoneNumber"
        /> -->
        <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('phoneNumber')">
          <maia-input-phonenumber
            name="phoneNumber"
            appDigitOnly
            appLimitTo="10"
            formControlName="phoneNumber"
            #phoneInput
            class="disable-country-code"
          >
            <maia-phonenumber-country prefix="+359" name="Bulgaria">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilBulgaria></svg>
              </ng-template>
            </maia-phonenumber-country>
          </maia-input-phonenumber>
          <div class="validation-container" *ngIf="stepForm.hasToShowErrors('phoneNumber')">
            <maia-validation *ngIf="stepForm.hasError('phoneNumber', 'required')">
              Phone number required
            </maia-validation>
            <maia-validation *ngIf="stepForm.hasError('phoneNumber', 'invalid')">
              Phone number invalid
            </maia-validation>
          </div>
        </div>
      </maia-form-element></div
    ><div class="fluid">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Full Name with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('firstNameEn')"
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('firstNameEn')"
          >
            <input maiaInput type="text" placeholder="First Name" formControlName="firstNameEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('firstNameEn')">
              <maia-validation
                *ngIf="stepForm.hasError('firstNameEn', 'required')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-fnameenreq"
                >First name required</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('firstNameEn', 'nonCyrillicLetters')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-fnameenlatin"
                >First name - only latin letters allowed</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('firstNameEn', 'nonBlank')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-fnameeninvalid"
                >Invalid first name</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('firstNameEn', 'maxlength')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-fnameenlength"
                >First name is too long (maximum is
                {{ stepForm.controls['firstNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('middleNameEn')"
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('middleNameEn')"
          >
            <input maiaInput type="text" placeholder="Middle Name" formControlName="middleNameEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('middleNameEn')">
              <maia-validation
                *ngIf="stepForm.hasError('middleNameEn', 'required')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-mnameenreq"
                >Middle name required</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('middleNameEn', 'nonCyrillicLetters')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-mnameenlatin"
                >Middle name - only latin letters allowed</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('middleNameEn', 'nonBlank')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-mnameeninvalid"
                >Invalid middle name</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('middleNameEn', 'maxlength')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-mnameenlenth"
                >Middle name is too long (maximum is
                {{ stepForm.controls['middleNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('lastNameEn')"
        >
          <div
            [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('lastNameEn')"
          >
            <input maiaInput type="text" placeholder="Last Name" formControlName="lastNameEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('lastNameEn')">
              <maia-validation
                *ngIf="stepForm.hasError('lastNameEn', 'required')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnameenreq"
                >Last name required</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('lastNameEn', 'nonCyrillicLetters')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnameenlatin"
                >Last name - only latin letters allowed</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('lastNameEn', 'nonBlank')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnameeninvalid"
                >Invalid last name</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('lastNameEn', 'maxlength')"
                i18n="@@nje-b2b-frontend-travelInsurance-insuringparty-lnameenlength"
                >Last name is too long (maximum is
                {{ stepForm.controls['lastName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="City & Zip with latin letters"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="cityEn"
            formControlName="cityEn"
            (typeAhead)="cityAutocompleteEn.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="City" />

            <maia-option *ngFor="let cityObj of cityAutocompleteEn.list | async" [value]="cityObj">
              <maia-option-highlight [label]="getCityTitle(cityObj)"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="stepForm.hasToShowErrors('cityEn')">
            <maia-validation
              *ngIf="stepForm.hasError('cityEn', 'required')"
              i18n="@@systemcode-mtpl-contact-cityreq"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input maiaInput type="text" placeholder="Zip" formControlName="postCodeEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('postCodeEn')">
              <maia-validation
                *ngIf="stepForm.hasError('postCodeEn', 'required')"
                i18n="@@systemcode-mtpl-contact-postcodereq"
                >Post Code required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Quarter with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('quarterEn')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('quarterEn')">
            <input maiaInput placeholder="Quarter" formControlName="quarterEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('quarterEn')">
              <maia-validation *ngIf="stepForm.hasError('quarterEn', 'required')"
                >Quarter is required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Street & Number with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('streetEn')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('streetEn')">
            <input maiaInput type="text" placeholder="Street" formControlName="streetEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('streetEn')">
              <maia-validation
                *ngIf="stepForm.hasError('streetEn', 'required')"
                i18n="@@systemcode-mtpl-contact-streetreq"
                >Street required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('streetNumberEn')"
        >
          <div
            [class.p-maia-form-element--show-validation]="
              stepForm.hasToShowErrors('streetNumberEn')
            "
          >
            <input maiaInput type="text" placeholder="Number" formControlName="streetNumberEn" />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('streetNumberEn')">
              <maia-validation
                *ngIf="stepForm.hasError('streetNumberEn', 'required')"
                i18n="@@systemcode-mtpl-contact-streetnoreq"
                >Street number required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        class="multi-input-row"
        label="Block, Entrance, Floor & Apartment with latin letters"
        multi
      >
        <maia-form-subelement>
          <input maiaInput type="text" name="block" placeholder="Block" formControlName="blockEn" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="entrance"
            placeholder="Entrance"
            formControlName="entranceEn"
          />
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floorEn" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="apartment"
            placeholder="Apartment"
            formControlName="apartmentEn"
          />
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        *ngIf="isLNCH"
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="multi-input-row"
        label="Date of Birth"
      >
        <maia-input-date
          name="dob"
          [(ngModel)]="birthDatePicker.value"
          (ngModelChange)="birthDatePicker.change($event)"
          (blur)="birthDatePicker.touch()"
          [minimum]="minDate"
          [maximum]="maxDate"
          [ngModelOptions]="{standalone: true}"
          [visibleView]="visibleDateView"
        ></maia-input-date>
        <maia-validation
          *ngIf="stepForm.hasToShowErrors('dob') && stepForm.hasError('dob', 'required')"
        >
          Date of birth required
        </maia-validation>
        <maia-validation
          *ngIf="stepForm.hasToShowErrors('dob') && stepForm.hasError('dob', 'invalid')"
        >
          Date of birth invalid
        </maia-validation>
        <maia-validation
          *ngIf="stepForm.hasToShowErrors('dob') && stepForm.hasError('dob', 'atlasBefore')"
        >
          Birth date should not be future date.
        </maia-validation>
        <maia-validation
          *ngIf="stepForm.hasToShowErrors('dob') && stepForm.hasError('dob', 'atlasAfter')"
        >
          Birth date should not be less than 150 years.
        </maia-validation>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Email ID"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="stepForm.hasToShowErrors('emailId')"
        >
          <div [class.p-maia-form-element--show-validation]="stepForm.hasToShowErrors('emailId')">
            <input
              maiaInput
              type="email"
              atlasEmailAddress
              placeholder="Email ID"
              formControlName="emailId"
            />
            <div class="validation-container" *ngIf="stepForm.hasToShowErrors('emailId')">
              <maia-validation
                *ngIf="stepForm.hasError('emailId', 'required')"
                i18n="@@systemcode-mtpl-contact-emailreq"
                >Email required</maia-validation
              >
              <maia-validation
                *ngIf="
                  stepForm.hasError('emailId', 'atlasInvalidLocalPart') ||
                  stepForm.hasError('emailId', 'atlasInvalidDomain') ||
                  stepForm.hasError('emailId', 'atlasInvalidMultipleAt')
                "
                i18n="@@systemcode-mtpl-contact-emailformat"
                >Enter your e-mail address in the following format: name@domain.com</maia-validation
              >
              <maia-validation
                *ngIf="stepForm.hasError('emailId', 'atlasMaxLength')"
                i18n="@@systemcode-mtpl-contact-emaillength"
                >Email too long</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-divider class="m-t-b-24"></maia-divider>

  <maia-fluid-fluid-layout alignContentTop>
    <div class="fluid m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Is the insuring party going to be covered in the policy?"
      >
        <maia-radio-group horizontal name="insuringPartyRadio" formControlName="insuringPartyRadio">
          <njf-radio-button value="yes">Yes</njf-radio-button>
          <njf-radio-button value="no">No</njf-radio-button>
        </maia-radio-group>
      </maia-form-element></div
    >
    <div class="fluid"></div>
  </maia-fluid-fluid-layout>

  <maia-divider class="m-t-b-24"></maia-divider>

  <button
    *ngIf="!(step.inEdit | async)"
    class="m-t-b-24"
    maiaButton
    maiaButtonType="primary"
    (click)="save()"
    >Continue</button
  >

  <div *ngIf="step.inEdit | async">
    <button class="m-r-24 m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveEdit()"
      >Save</button
    >
    <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
  </div>
</form>
