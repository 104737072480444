<maia-input-autocomplete
  id="autocomplete_one"
  name="selectedValue"
  [(ngModel)]="selectedValue"
  [disabled]="isDisabled | atlasBoolean"
>
  <input maiaInput placeholder="EGN / LNCH / EIK" />
  <maia-option [id]="data?.value" [value]="data?.value" *ngFor="let data of autoCompleteOptions">
    <maia-option-highlight [label]="data?.displayValue"></maia-option-highlight>
  </maia-option>
</maia-input-autocomplete>
