import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationFuncWheelSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationFuncWheel]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M12 9.41V5.09"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M9.54 11.2L5.43 9.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M10.48 14.09l-2.54 3.5"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.53 14.09l2.53 3.5"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M14.47 11.2l4.1-1.34"></svg:path><svg:circle cx="12" cy="12" r="9.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle><svg:circle cx="12" cy="12" r="6.91" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle><svg:circle cx="12" cy="12" r="2.59" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationFuncWheelSvgComponent {
}

@NgModule({
  declarations: [
    TransportationFuncWheelSvgComponent,
  ],
  exports: [
    TransportationFuncWheelSvgComponent,
  ],
})
export class TransportationFuncWheelSvgModule {}
