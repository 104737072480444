import {Injectable} from '@angular/core';
import {CascoProcessService} from './casco-process.service';
import {CascoWorkflow} from '../casco-workflow/CascoWorkflow';

@Injectable()
export class CascoPolicyService {
  public constructor(public process: CascoProcessService) {
    this.process.currentWorkflow = CascoWorkflow.QUICK_QUOTE;
  }
}
