import {Component, OnInit} from '@angular/core';
import {FormControl, ValidationErrors, Validators} from '@angular/forms';
import {CoreApiService, NationalityDto, PartyIdService, PartyType} from '@b2b-frontend/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {map} from 'rxjs/operators';
import {DateKey, DateUtils, Text} from '@atlas/businesstypes';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';
import {
  CityInterface,
  MOCK_RELATED_PARTY,
  PeopleDetailsRelatedParty,
  RelatedParty,
} from './__tests__/people-details-slidein-interface';
import {DatepickerVisibleView} from '@maia/datepickers';

@Component({
  selector: 'nje-people-details-slidein',
  templateUrl: './people-details-slidein.component.html',
  styleUrls: ['./people-details-slidein.component.scss'],
})
export class PeopleDetailsSlideinComponent
  extends ModalContentComponent<RelatedParty, RelatedParty>
  implements OnInit
{
  public isEGN: boolean = true;
  public isLNCH: boolean = false;
  public isEIK: boolean = false;
  public inputText: Text;
  public isNewDetails: boolean = true;
  public insurancePartyType: PartyType | undefined;
  public mockRelatedParty: PeopleDetailsRelatedParty[] = [...MOCK_RELATED_PARTY];
  public searchEgn: Text;
  public cityArray: CityInterface[] = [];

  public RelatedPartyForm: FormGroupManager<RelatedParty> =
    this._formFactory.createFormManager<RelatedParty>({
      idNumber: new FormControl(undefined, [
        Validators.required,
        this.idNumberValidator.bind(this),
      ]),
      relatedParty: new FormControl(undefined, [Validators.required]),
      firstName: new FormControl(undefined, Validators.required),
      middleName: new FormControl(undefined, Validators.required),
      lastName: new FormControl(undefined, Validators.required),
      companyName: new FormControl(undefined, [Validators.required]),
      citizenship: new FormControl(undefined, Validators.required),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
      quarter: new FormControl(undefined, [Validators.required]),
      street: new FormControl(undefined, [Validators.required]),
      streetNumber: new FormControl(undefined, [Validators.required]),
      block: new FormControl(undefined, [Validators.required]),
      entrance: new FormControl(undefined, [Validators.required]),
      floor: new FormControl(undefined, [Validators.required]),
      apartment: new FormControl(undefined, [Validators.required]),
      dob: new FormControl(undefined, [Validators.required]),
      phoneNumber: new FormControl(undefined, [Validators.required]),
      emailId: new FormControl(undefined, [Validators.required]),
    });

  public cityAutocomplete =
    this.RelatedPartyForm.createAutocompleteWithServersideFiltering<CityInterface>(
      'city',
      (q: string) =>
        this._coreApiService.getCity(q, true).pipe(
          map((res: CityInterface[]) => {
            this.cityArray = res;
            return res;
          }),
        ),
    );

  public citizenshipAutocomplete =
    this.RelatedPartyForm.createAutocompleteWithServersideFiltering<NationalityDto>(
      'citizenship',
      (q: string) => this._coreApiService.getNationalities(q, true).pipe(map(res => res.data)),
    );

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Year, -150);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, -1);
  public birthDatePicker = this.RelatedPartyForm.createDatePicker('dob');
  public visibleDateView?: DatepickerVisibleView = undefined;

  public constructor(
    private readonly _formFactory: FormGroupManagerFactoryService,
    public control: ModalControl<RelatedParty, RelatedParty>,
    private readonly _partyId: PartyIdService,
    private readonly _coreApiService: CoreApiService,
  ) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    if (data) {
      this.isNewDetails = false;
    }
    if (!this.isNewDetails) {
      this.handleInputText(new Text(data?.idNumber));
      this.RelatedPartyForm.patchValue({
        relatedParty: data?.relatedParty,
        idNumber: data?.idNumber,
        companyName: data?.companyName,
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        street: data?.street,
        streetNumber: data?.streetNumber,
        city: data?.city,
        block: data?.block,
        entrance: data?.entrance,
        quarter: data?.quarter,
        floor: data?.floor,
        phoneNumber: data?.phoneNumber,
        apartment: data?.apartment,
        emailId: data?.emailId,
        citizenship: data?.citizenship,
      });
      this.birthDatePicker.value = data?.dob;
    }
  }

  public getRelatedParty(value: string): string | null {
    const data = this.mockRelatedParty
      ? this.mockRelatedParty.find((options: any) => options.partyName == value)
      : null;
    return data ? data.partyName : null;
  }

  public handleInputText(id: Text): void {
    this.inputText = id;
    if (null != id && 8 <= id.asString().length) {
      this.insurancePartyType = this._partyId.getType(id.asString());
      this.isEGN = this.insurancePartyType === PartyType.PERSON;
      this.isEIK = this.insurancePartyType === PartyType.COMPANY;
      this.isLNCH = this.insurancePartyType === PartyType.FOREIGNER;
      this.RelatedPartyForm.patchValue({
        idNumber: this.inputText.asString(),
      });
    }
  }

  public cancel(): void {
    this.control.cancel();
  }

  public saveInsuringPartyDetails(): void {
    const data: RelatedParty = this.RelatedPartyForm.value;
    this.control.confirm(data);
  }

  public idNumberValidator(control: FormControl): ValidationErrors | null {
    if (null != control.value && 8 <= control.value.length) {
      this.insurancePartyType = this._partyId.getType(control.value);
      this.isEGN = this.insurancePartyType === PartyType.PERSON;
      this.isEIK = this.insurancePartyType === PartyType.COMPANY;
      this.isLNCH = this.insurancePartyType === PartyType.FOREIGNER;
    }
    if (control.value && !this._partyId.getType(control.value)) {
      return {invalidNumber: true};
    }
    return null;
  }
}
