import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';

import {USER_MANAGEMENT} from '../../constants/account-management';
import {RoutePaths} from '../../constants/route-paths';
import {ActivateAccountComponent} from './activate-account/activate-account.component';
import {DeactivateAccountComponent} from './deactivate-account/deactivate-account.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: RoutePaths.ACTIVATE_ACCOUNT,
        component: ActivateAccountComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: RoutePaths.DEACTIVATE_ACCOUNT,
        component: DeactivateAccountComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: RoutePaths.RESET_PASSWORD,
        component: ResetPasswordComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: '',
        redirectTo: RoutePaths.ACTIVATE_ACCOUNT,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class B2cRoutingModule {}

export const B2C_ROUTE: Route = {
  path: '',
  loadChildren: async () => await import('./b2c.module').then(module => module.B2cModule),
};
