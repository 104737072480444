import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileFuncNewspaperSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFileFuncNewspaper]',
  template: '<svg:path d="M6.43 2.83V19.2a1.92 1.92 0 0 1-1.88 2h15a1.92 1.92 0 0 0 2-1.89h0V2.83z" fill="none" stroke-miterlimit="10" stroke-width="1"></svg:path><svg:path fill="none" stroke-miterlimit="10" stroke-width="1" d="M9.71 6.11h8.51v4.58H9.71z"></svg:path><svg:path fill="none" stroke-miterlimit="10" stroke-width="1" d="M18.22 17.24H9.71"></svg:path><svg:path fill="none" stroke-miterlimit="10" stroke-width="1" d="M18.22 13.96H9.71"></svg:path><svg:path d="M3.81 13.31H2.5v5.89a1.93 1.93 0 0 0 1.89 2h.15" fill="none" stroke-miterlimit="10"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFuncNewspaperSvgComponent {
}

@NgModule({
  declarations: [
    FileFuncNewspaperSvgComponent,
  ],
  exports: [
    FileFuncNewspaperSvgComponent,
  ],
})
export class FileFuncNewspaperSvgModule {}
