<div id="htmlData">
  <maia-price-block amount="15.43">
    <svg class="icon-white-color" b2bFrontendInformationExprFastlane maia-price-icon></svg>
    <div maia-price-title>Total Insurance Premium</div>
    <div maia-price-subtitle>{{ totalNoOfPeople }} people for {{ noOfTripDays }}</div>
  </maia-price-block>

  <div class="grey-box-div">
    <maia-label class="b2b-kdl-font-small title-dark-color">Policy Description</maia-label>
    <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
      <maia-avatar-display>
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendInformationExprFastlane></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <maia-label
            class="b2b-kdl-font-small-light subtitle-light-color"
            [sublabel]="tripTypeName"
          >
            <label class="b2b-kdl-font-small title-dark-color">
              {{ totalNoOfPeople }} People
            </label>
          </maia-label>
        </div>
      </maia-avatar-display>

      <maia-avatar-display id="avatar-display-svg">
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendActionFuncClock></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <maia-label
            class="b2b-kdl-font-small-light subtitle-light-color"
            [sublabel]="tripStartEndDate"
          >
            <label class="b2b-kdl-font-small title-dark-color"> {{ noOfTripDays }} </label>
          </maia-label>
        </div>
      </maia-avatar-display>

      <maia-avatar-display id="avatar-display-svg">
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendTransportationFuncPlane></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <maia-label
            class="b2b-kdl-font-small-light subtitle-light-color"
            [sublabel]="travelAbroadSubTitle"
          >
            <label class="b2b-kdl-font-small title-dark-color"> {{ travelAbroadTitle }} </label>
          </maia-label>
        </div>
      </maia-avatar-display>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
      <maia-avatar-display id="avatar-display-svg">
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendSocialFuncPersonTie></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="reasonType">
            <label class="b2b-kdl-font-small title-dark-color"> {{ workType }} </label>
          </maia-label>
        </div>
      </maia-avatar-display>

      <maia-avatar-display id="avatar-display-svg">
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendInformationFuncCoins></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <maia-label
            class="b2b-kdl-font-small-light subtitle-light-color"
            [sublabel]="coverSubtitle"
          >
            <label class="b2b-kdl-font-small title-dark-color"> {{ coversName }} Coverage </label>
          </maia-label>
        </div>
      </maia-avatar-display>
      <div></div>
    </maia-fluid-fluid-layout>
  </div>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <div class="grey-boxes-div m-l-24 m-r-24" *ngIf="disableAddonsBoxes">
      <maia-label class="b2b-kdl-font-small title-dark-color">Add Ons</maia-label>
      <maia-avatar-display id="avatar-display-svg" class="m-t-24">
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <div class="b2b-kdl-font-small title-dark-color">Add Ons</div>
          <ng-container *ngIf="this.selectedAddon">
            <div
              maiaTextListItem
              *ngFor="let aConditions of this.selectedAddon | slice: 0:2; let i = index"
            >
              <div class="b2b-kdl-font-extra-small-light subtitle-light-color"
                >{{ aConditions.title }} -
                <maia-number-display
                  [value]="amountInDecimal"
                  size="extra-small"
                  currency="BGN"
                ></maia-number-display>
              </div>
            </div>
          </ng-container>
          <maia-additional-information
            coverlink
            *ngIf="selectedAddon && selectedAddon.length > 2"
            openLinkText="View more"
            closeLinkText="View less"
            maiaContentType="content"
            collapsible
          >
            <ng-container *ngIf="this.selectedAddon">
              <div
                maiaTextListItem
                *ngFor="
                  let aConditions of this.selectedAddon | slice: 2:this.selectedAddon.length;
                  let i = index
                "
              >
                <div class="b2b-kdl-font-extra-small-light subtitle-light-color"
                  >{{ aConditions.title }} -
                  <maia-number-display
                    [value]="amountInDecimal"
                    size="extra-small"
                    currency="BGN"
                  ></maia-number-display>
                </div>
              </div>
            </ng-container>
          </maia-additional-information>
        </div>
      </maia-avatar-display>
    </div>
    <div class="grey-boxes-div m-l-24 m-r-24" *ngIf="disableDiscountBoxes">
      <maia-label class="b2b-kdl-font-small title-dark-color">Discount</maia-label>
      <maia-avatar-display id="avatar-display-svg" class="m-t-24">
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsMinus></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <ng-container *ngIf="this.selectedDiscounts">
            <div
              maiaTextListItem
              *ngFor="let discount of this.selectedDiscounts | slice: 0:2; let i = index"
            >
              <div class="b2b-kdl-font-extra-small-light subtitle-light-color"
                >{{ discount.title }} -
                <maia-number-display
                  [value]="amountInDecimal"
                  size="extra-small"
                  currency="BGN"
                ></maia-number-display>
              </div>
            </div>
          </ng-container>
          <maia-additional-information
            coverlink
            *ngIf="selectedDiscounts && selectedDiscounts.length > 2"
            openLinkText="View more"
            closeLinkText="View less"
            maiaContentType="content"
            collapsible
          >
            <ng-container *ngIf="this.selectedDiscounts">
              <div
                maiaTextListItem
                *ngFor="
                  let discount of this.selectedDiscounts | slice: 2:this.selectedDiscounts.length;
                  let i = index
                "
              >
                <div class="b2b-kdl-font-extra-small-light subtitle-light-color"
                  >{{ discount.title }} -
                  <maia-number-display
                    [value]="amountInDecimal"
                    size="extra-small"
                    currency="BGN"
                  ></maia-number-display>
                </div>
              </div>
            </ng-container>
          </maia-additional-information>
        </div>
      </maia-avatar-display>
    </div>
  </maia-fluid-fluid-layout>
</div>

<div class="m-t-b-24 m-l-24">
  <button maiaButton maiaButtonType="primary" (click)="openPdf()">Save as PDF</button>
  <button maiaButton maiaButtonType="secondary" (click)="print()" class="m-l-b-24">Print</button>
  <button maiaButton maiaButtonType="secondary" (click)="openPopup()">Email</button>
</div>
