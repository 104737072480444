import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'nje-common-info',
  templateUrl: './common-info.component.html',
  styleUrls: ['./common-info.component.scss'],
})
export class CommonInfoComponent implements OnInit {
  public commonInfoForm: FormGroup;

  public constructor(private readonly _fb: FormBuilder) {}

  public ngOnInit(): void {
    this.commonInfoForm = this._fb.group({
      intermediaryEgnLnchEik: new FormControl(undefined, [Validators.required]),
      intermediaryName: new FormControl(undefined, [Validators.required]),
      phoneNumber: new FormControl(undefined, [Validators.required]),
      emailId: new FormControl(undefined, [Validators.required]),
    });
  }
}
