import {ValidatorFn, Validators} from '@angular/forms';
import {nonBlank, nonCyrillicLetters, nonLatinLetters} from '@b2b-frontend/core';
import {nonLatinCyrillicLetters} from '../language/non-latin-cyrillic-letters.validator';

export function personName(lang?: 'bg' | 'en' | 'bgoren'): ValidatorFn {
  let result: ValidatorFn | null;

  switch (lang) {
    case 'bg':
      result = Validators.compose([nonBlank, nonLatinLetters, Validators.maxLength(100)]);
      break;
    case 'en':
      result = Validators.compose([nonBlank, nonCyrillicLetters, Validators.maxLength(100)]);
      break;
    case 'bgoren':
      result = Validators.compose([nonBlank, nonLatinCyrillicLetters, Validators.maxLength(100)]);
      break;
    default:
      result = Validators.compose([nonBlank, Validators.maxLength(100)]);
      break;
  }

  if (result !== null) {
    return result;
  } else {
    return () => null;
  }
}
