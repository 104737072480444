/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {SlideInController} from '@maia/slide-ins';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {
  coversDiscountCompleteData,
  CoversDiscountsDetails,
  InsurancePartyDetails,
  PropertyAddressDetailsDto,
  PropertyInsuranceProposalDto,
  CoversDiscountTypes,
} from '@b2b-frontend/core';
import {
  CURRENCY_LIST,
  DISCOUNT_TITLE,
  HOUSEHOLD_PROPERTY,
  INSTALLMENT_OPTION_LIST,
  PIECES_OF_ART,
  UNDERWRITINGS_LIMITS,
  MIN_AGGREGATE_VALUE,
  MIN_INSURED_VALUE,
  MOCK_AUTOMATIC_DISCOUNT_VALUE,
  MOCK_MAX_DISCOUNT_APPLIED,
  INTERNALVALUE,
} from '../../constants/property-insurance-constants';
import {
  AdditionalCoversAddressLevelInterface,
  Amount,
  InstallmentOptions,
  Item,
  PropertyCoversInformation,
  SelectableInputType,
} from '../../contracts/property-insurance.interface';
import {UnderwritingLimitsSlideinComponent} from '../underwriting-limits-slidein/underwriting-limits-slidein.component';
import {
  DISCOUNT_LIST,
  LOADING_LIST,
  MOCK_ITEMS,
  MOCK_PREMIUM_PRICE,
  MOCK_PROPERTY_TYPE_LIST1,
  MOCK_PROPERTY_TYPE_LIST2,
  BGN,
  ACCORDIAN_TITLE,
} from './__tests__/property-covers-and-discounts.mocks';
import {PropertyPolicyService} from '../../property-core/services/property-policy.service';
import {PropertyApiService} from '../../property-core/services/property-api.service';
import {PropertySessionService} from '../../property-core/services/property-session.service';
import {PropertyProcessService} from '../../property-core/services/property-process.service';
import {PropertyWorkflow} from '../../property-core/property-workflow/property-workflow';
import {Boolean} from '@atlas/businesstypes';

@Component({
  selector: 'property-addons-and-discounts',
  templateUrl: './property-covers-and-discounts.component.html',
  styleUrls: ['./property-covers-and-discounts.component.scss'],
})
@UntilDestroy()
export class PropertyCoversAndDiscountsComponent extends ScreenStepComponent implements OnInit {
  public showLoadingIcon: boolean = false;
  public currencyList: SelectableInputType[] = [...CURRENCY_LIST];
  public installment_option_list: SelectableInputType[] = [...INSTALLMENT_OPTION_LIST];
  public additionalCoversInfoList1: AdditionalCoversAddressLevelInterface[] = [];
  public additionalCoversList: AdditionalCoversAddressLevelInterface[] = [];

  public propertyCoversInfoList1: PropertyCoversInformation[] = MOCK_PROPERTY_TYPE_LIST1;
  public propertyAddress1: InsurancePartyDetails;

  public propertyCoversInfoList2: PropertyCoversInformation[] = MOCK_PROPERTY_TYPE_LIST2;
  public propertyAddress2: InsurancePartyDetails;
  public discounts: CoversDiscountTypes[] = [...DISCOUNT_TITLE];
  public currencyChanged: Boolean = Boolean.FALSE;
  public premiumAmountVisibility: boolean = false;
  public currencySelected: string = BGN;

  public insurancePremiumAmount: Amount = MOCK_PREMIUM_PRICE;
  public items: Item[] = MOCK_ITEMS;
  public title = ACCORDIAN_TITLE;
  public discountVisible: boolean = false;
  public ViewMore: boolean = false;
  public installmentOptionSelected: string;
  public calculatePremiumEnable: boolean = false;
  public enableContinueButton: boolean = false;
  public installmentslist: InstallmentOptions[] = [];

  public loadingList = [...LOADING_LIST];

  public discountList = [...DISCOUNT_LIST];

  public coversDiscountsDetailsForm: FormGroup;

  private readonly _slideInOneFactory: ComponentFactory<UnderwritingLimitsSlideinComponent>;

  public coversDiscountsDetailsList: CoversDiscountsDetails[] = [];

  public coversDiscountLayoutInfoPassed: CoversDiscountsDetails[] | undefined = [];
  public piecesData: PropertyCoversInformation;
  public householdData: PropertyCoversInformation;
  public propertyData: PropertyInsuranceProposalDto;
  public propertyAddressDetailsList: PropertyAddressDetailsDto[] = [];
  public moveToSupplementary: boolean = false;
  public discountCountValue: number = MOCK_AUTOMATIC_DISCOUNT_VALUE;
  public invalidDiscountEntered: boolean = false;
  public invalidDiscountObj: CoversDiscountTypes | undefined;
  public maxDiscountApplied: number = MOCK_MAX_DISCOUNT_APPLIED;
  public manualDiscountSelected: Boolean[] = [];

  public constructor(
    route: ActivatedRoute,
    private readonly _formBuilder: FormBuilder,
    private readonly _slideInCtrl: SlideInController,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    public property: PropertyPolicyService,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _propertyApiService: PropertyApiService,
    private readonly _propertySessionService: PropertySessionService,
    private readonly _process: PropertyProcessService,
  ) {
    super(route);
    this._slideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      UnderwritingLimitsSlideinComponent,
    );
    this.manualDiscountSelected = Array<Boolean>(this.discounts.length).fill(Boolean.FALSE);
  }

  public ngOnInit(): void {
    this._initializeCoversDiscountForm();
    this.installmentApi();
    this.propertyData = this._propertySessionService.getPropertyData();
    if (this.propertyData?.coversDiscountsListDto !== undefined) {
      this.discountVisible = true;
      this.enableContinueButton = true;
      this.coversDiscountLayoutInfoPassed = this.propertyData.coversDiscountsListDto.coversDetails!;
      this.currencySelected = this.propertyData.coversDiscountsListDto.currencySelected!;
      this.coversDiscountsDetailsForm.controls.installment_option.patchValue(
        this.propertyData.coversDiscountsListDto.installmentOptionSelected,
      );
      this.discounts = this.propertyData.coversDiscountsListDto.discountData!;
      this.discounts.forEach(ele => {
        if (ele.typeSelected![INTERNALVALUE] === true) {
          this.manualDiscountSelected[parseInt(ele.id!) - 1] = Boolean.TRUE;
        }
      });
    } else {
      // eslint-disable-next-line
      this.propertyAddressDetailsList = this.propertyData?.propertyAddressDetailsDtoList!;
      this.showLoadingIcon = true;
      this._propertyApiService.getCoversOnAddressLevel().subscribe(res => {
        this.additionalCoversList = res;
        let idValue = 0;
        this.propertyAddressDetailsList?.forEach(ele => {
          const propertyDataList: PropertyCoversInformation[] = [];
          ele.propertyDetailsList.forEach(ele => {
            const propertyDataObj: PropertyCoversInformation = {
              propertyType: ele.propertyType,
            };
            propertyDataList.push(propertyDataObj);
          });
          this.additionalCoversInfoList1 = this.additionalCoversList.filter(
            (ele: AdditionalCoversAddressLevelInterface) => ele.default === true,
          );
          const eachObj: CoversDiscountsDetails = {
            id: ++idValue,
            additionalCoverList: this.additionalCoversList,
            additionalCoversInfoList: this.additionalCoversInfoList1,
            propertyCoversInfoList: propertyDataList,
            propertyAddress: ele.insurancePartyDetails,
          };
          this.coversDiscountLayoutInfoPassed?.push(eachObj);
        });
        this.showLoadingIcon = false;
        this._cdRef.detectChanges();
      });
    }
  }

  public installmentApi(): void {
    this._propertyApiService.getInstallmentOptions().subscribe(res => {
      this.installmentslist = res;
      this._cdRef.detectChanges();
    });
  }

  private _initializeCoversDiscountForm(): void {
    this.coversDiscountsDetailsForm = this._formBuilder.group({
      currency: new FormControl(this.currencyList[0].value, Validators.required),
      installment_option: new FormControl(undefined, Validators.required),
    });
  }

  public changeDiscountSelection(i: number): void {
    this.discounts[i].discountValue = null;
    this.calculatePremiumEnable = true;
    this.enableContinueButton = false;
  }

  public onDiscountValueEntered(): void {
    this.discountCountValue = MOCK_AUTOMATIC_DISCOUNT_VALUE;
    this.discounts.forEach(ele => {
      if (
        this.discountCountValue === MOCK_MAX_DISCOUNT_APPLIED &&
        this.invalidDiscountEntered === true
      ) {
        this.invalidDiscountEntered = false;
        this.invalidDiscountObj = undefined;
      }
      if (
        this.discountCountValue > MOCK_MAX_DISCOUNT_APPLIED &&
        this.invalidDiscountEntered === false
      ) {
        this.invalidDiscountEntered = true;
      }
      if (this.discountCountValue < MOCK_MAX_DISCOUNT_APPLIED) {
        this.invalidDiscountEntered = false;
      }
      if (ele.typeSelected && ele.discountValue !== null) {
        this.discountCountValue = this.discountCountValue + parseInt(ele.discountValue!.toString());
        this.invalidDiscountObj = ele;
      }
    });
  }

  public disableTile(cover: CoversDiscountTypes): boolean {
    return cover.typeSelected === Boolean.FALSE && this.discountCountValue === 25 ? true : false;
  }

  public displayErrorMsg(cover: CoversDiscountTypes): boolean {
    return this.invalidDiscountEntered === true && this.invalidDiscountObj?.id === cover.id
      ? true
      : false;
  }

  public isBtnEnable(): boolean {
    return !this.coversDiscountsDetailsForm.valid;
  }

  public calculatePremium(): void {
    this.coversDiscountLayoutInfoPassed?.forEach(addressObj => {
      addressObj.additionalCoversInfoList.forEach(covers => {
        covers.premiumAmount = 2300;
      });
      addressObj.propertyCoversInfoList.forEach(covers => {
        covers.premiumAmount = 2300;
      });
    });
    this.enableContinueButton = true;
    this.premiumAmountVisibility = true;
    this.property.showPriceAccordion(true);
    this.discountVisible = true;
    this.calculatePremiumEnable = false;
    this._cdRef.detectChanges();
  }

  public changeCurrenySelection(): void {
    this.currencyChanged = new Boolean(true);
    this.currencySelected = this.coversDiscountsDetailsForm.controls.currency.value as string;
    this.insurancePremiumAmount.currency = this.currencySelected;
  }

  public underwritingLimitsSlidein(): void {
    const slideinTitle = UNDERWRITINGS_LIMITS;
    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.currencySelected,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  public view_more(): void {
    this.ViewMore = !this.ViewMore;
  }

  public onChangeOfInstallmentOption(): void {
    this.calculatePremiumEnable = true;
    this.coversDiscountLayoutInfoPassed?.forEach(ele => {
      const propertyDetails = ele.propertyCoversInfoList;
      propertyDetails.forEach(element => {
        if (element.basisOfInsurance === undefined) {
          this.calculatePremiumEnable = false;
        }
      });
    });
    this._cdRef.detectChanges();
  }

  protected saveMtplData(): void {
    //ToDo save the premium data
  }

  public isSupplementaryScreenRequired(): void {
    this.coversDiscountLayoutInfoPassed?.forEach(ele => {
      ele.propertyCoversInfoList.forEach(cover => {
        if (cover.propertyType === HOUSEHOLD_PROPERTY && cover.aggregateLimit !== null) {
          cover.aggregateLimit!.forEach(aggregatevalue => {
            if (aggregatevalue > MIN_AGGREGATE_VALUE || aggregatevalue === MIN_AGGREGATE_VALUE) {
              this.moveToSupplementary = true;
              this._process.currentWorkflow = PropertyWorkflow.PropertySupplementaryPolicy;
              this.saveAndContinue();
            }
          });
        } else if (
          cover.propertyType === PIECES_OF_ART &&
          cover.insuredValue! > MIN_INSURED_VALUE
        ) {
          this.moveToSupplementary = true;
          this._process.currentWorkflow = PropertyWorkflow.PropertySupplementaryPolicy;
          this.saveAndContinue();
        }
      });
    });
  }

  public saveCoversDiscountsData(): void {
    this.discounts.forEach(ele => {
      ele.typeSelected = this.manualDiscountSelected[parseInt(ele.id!) - 1];
    });
    const coversDiscountData: coversDiscountCompleteData = {
      coversDetails: this.coversDiscountLayoutInfoPassed,
      currencySelected: this.currencySelected,
      discountData: this.discounts,
      installmentOptionSelected: this.coversDiscountsDetailsForm.controls.installment_option.value,
    };
    this.isSupplementaryScreenRequired();
    this._propertySessionService.setCoversDiscountDto(coversDiscountData);
    this.saveAndContinue();
  }
}
