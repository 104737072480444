import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprFallSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprFall]',
  template: '<svg:path d="M18.17 37.86a13.64 13.64 0 0 1-2-.19 10.47 10.47 0 0 1-3.94-1.48 6.87 6.87 0 0 1-.83-.66 3.33 3.33 0 0 1-.66-.75 3.33 3.33 0 0 1-.5-.74 4.28 4.28 0 0 1-.34-.72 5.33 5.33 0 0 1-.31-1.16c-.06-.27-.08-.45-.08-.45a.94.94 0 0 0-.06-.24 1.72 1.72 0 0 0-3.24 1.13v0a17.58 17.58 0 0 0 .93 2.25 5.82 5.82 0 0 0 .67 1 4.81 4.81 0 0 0 .92 1 7.4 7.4 0 0 0 1.14.92 6.92 6.92 0 0 0 1.27.72 13.35 13.35 0 0 0 5 1.14h1.67c.42 0 .69-.08.66-.06a.83.83 0 0 0 .72-1 .86.86 0 0 0-1.02-.71z" fill="none" stroke-miterlimit="10" stroke-width="1.6"></svg:path><svg:path d="M29.4 11.4a1.69 1.69 0 0 0-.91.26L14.9 19.81a.28.28 0 0 1-.13 0h-.06l-7.24-3.06a1.64 1.64 0 0 0-.69-.14 1.79 1.79 0 0 0-1.65 1.08 1.88 1.88 0 0 0 0 1.36 1.7 1.7 0 0 0 1 1l8.14 3.43a1.8 1.8 0 0 0 .69.13 1.88 1.88 0 0 0 .93-.25l1.71-1h.11a.16.16 0 0 1 .07.07v0l3.63 8.26.07.14h0a1.64 1.64 0 0 0 1.24.84l7 1a.24.24 0 0 1 .15.1h0A.19.19 0 0 1 30 33l-2.16 4a1.91 1.91 0 0 0-.13 1.34 1.67 1.67 0 0 0 .88 1.09 1.77 1.77 0 0 0 .82.2 1.82 1.82 0 0 0 1.59-.87l3.51-6.54a1.79 1.79 0 0 0 0-1.64 1.83 1.83 0 0 0-1.33-1l-5.54-.83c-.12 0-.18-.07-.19-.14v-.06a.17.17 0 0 1 .09-.19l6.18-3.85a.19.19 0 0 1 .13 0h.06l7 1.33a1.59 1.59 0 0 0 .34 0A1.79 1.79 0 0 0 43 24.42a1.83 1.83 0 0 0-.34-1.42 1.59 1.59 0 0 0-1.1-.67l-7.78-1.49h-.34a1.69 1.69 0 0 0-.95.29l-4.29 2.68a.3.3 0 0 1-.13 0 .16.16 0 0 1-.14-.07l-3.95-5a.43.43 0 0 1-.05-.15.43.43 0 0 1 .12-.18l6.28-3.74a1.82 1.82 0 0 0 .82-1.11 1.75 1.75 0 0 0-.21-1.34 1.79 1.79 0 0 0-1.54-.85z" fill="none" stroke-miterlimit="10" stroke-width="1.6"></svg:path><svg:path d="M16.21 8.32a3.57 3.57 0 1 0 3.56 3.56 3.57 3.57 0 0 0-3.56-3.56z" fill="none" stroke-miterlimit="10" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprFallSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprFallSvgComponent,
  ],
  exports: [
    InformationExprFallSvgComponent,
  ],
})
export class InformationExprFallSvgModule {}
