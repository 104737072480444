<form maiaForm [formGroup]="sumInsuredSlideinForm.formGroup">
  <maia-form-group>
    <maia-label class="b2b-kdl-font-small title-dark-color">Type Of Equipments </maia-label>
    <maia-form-element hideOptionalIndicator class="slidein-input-column">
      <maia-input-select formControlName="typeOfEquipment">
        <input
          [value]="sumInsuredSlideinForm.controls['typeOfEquipment'].value"
          maiaInput
          placeholder="Select"
        />
      </maia-input-select>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Make </maia-label>
    <maia-form-element [hideOptionalIndicator]="true" class="slidein-input-column" multi>
      <maia-form-subelement>
        <input maiaInput type="text" formControlName="make" placeholder="Select" />
      </maia-form-subelement>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Model </maia-label>
    <maia-form-element [hideOptionalIndicator]="true" class="slidein-input-column" multi>
      <maia-form-subelement>
        <input maiaInput type="text" formControlName="model" placeholder="Select" />
      </maia-form-subelement>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Price as New </maia-label>
    <maia-form-element [hideOptionalIndicator]="true" class="slidein-input-column" multi>
      <maia-form-subelement>
        <maia-input-group>
          <input formControlName="priceAsNew" placeholder="Select" maiaInputNumber />
          <maia-input-group-suffix>BGN</maia-input-group-suffix>
        </maia-input-group>
      </maia-form-subelement>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Provider </maia-label>
    <maia-form-element [hideOptionalIndicator]="true" class="slidein-input-column" multi>
      <maia-form-subelement>
        <input maiaInput type="text" formControlName="provider" placeholder="Select" />
      </maia-form-subelement>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Date Of Invoice </maia-label>
    <maia-form-element [hideOptionalIndicator]="true" class="slidein-input-column" multi>
      <maia-form-subelement>
        <input maiaInput type="text" formControlName="dateOfInvioce" placeholder="Select" />
      </maia-form-subelement>
    </maia-form-element>
  </maia-form-group>
</form>
<div class="step-form-button-bar">
  <maia-button-group>
    <button class="m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveEquipment()"
      >Add Equipments</button
    >
    <button maiaButton maiaButtonType="secondary" (click)="cancel()">Cancel</button>
  </maia-button-group>
</div>
