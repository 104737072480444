import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesExprLandTreesSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendPlacesExprLandTrees]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path d="M5.6 38.714h36.8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M18.808 33.951V22.268"></svg:path><svg:path d="M22.084 17.27a6.463 6.463 0 0 0 .618-2.79c0-2.868-1.743-5.194-3.894-5.194s-3.894 2.326-3.894 5.194a6.475 6.475 0 0 0 .619 2.791 7.151 7.151 0 0 0-1.92 4.998c0 3.584 2.325 6.49 5.192 6.49S24 25.852 24 22.268a7.151 7.151 0 0 0-1.916-4.998z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M29.194 33.951V22.268"></svg:path><svg:path d="M26.491 27.785a4.427 4.427 0 0 0 2.703.973c2.867 0 5.192-2.906 5.192-6.49a7.151 7.151 0 0 0-1.92-4.998 6.475 6.475 0 0 0 .622-2.79c0-2.867-1.744-5.193-3.894-5.193a3.656 3.656 0 0 0-3.072 2.036" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesExprLandTreesSvgComponent {
}

@NgModule({
  declarations: [
    PlacesExprLandTreesSvgComponent,
  ],
  exports: [
    PlacesExprLandTreesSvgComponent,
  ],
})
export class PlacesExprLandTreesSvgModule {}
