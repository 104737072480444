export enum RoutePaths {
  ADMIN_UAM = 'admin/uam',
  ADMIN_CMS = 'admin/cms',
  ANNEXATION = 'annexation',
  CANCELLATION = 'cancellation',
  CASCO = 'casco',
  DUPLICATES = 'duplicates',
  HOME = 'home',
  MTPL = 'mtpl',
  NEW_POLICY = 'newpolicy',
  PAYMENTS = 'payments',
  POLICIES = 'policies',
  PROPERTY = 'property',
  RENEWAL = 'renewal',
  ZAKRILA = 'zakrila',
}
