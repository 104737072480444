<div class="m-t-18">
  <label class="b2b-kdl-font-grand-bold title-dark-color m-l-35"> {{ title }} </label>
  <ul id="menu">
    <li
      *ngFor="let item of breadcrumbList; let i = index"
      [class.active]="i === breadcrumbList.length - 1"
    >
      <label
        (click)="navigateToScreen(item.fullPath)"
        *ngIf="i !== breadcrumbList.length - 1"
        class="b2b-kdl-font-small subtitle-light-color show-hand"
      >
        {{ item.name }}
      </label>

      <label class="arrow-icon" *ngIf="i !== breadcrumbList.length - 1"> > </label>

      <label
        class="b2b-kdl-font-small subtitle-light-color"
        *ngIf="i === breadcrumbList.length - 1"
        >{{ item.name }}</label
      >
    </li>
  </ul>
</div>
