import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PropertyRoutePaths} from '@b2b-frontend/core';

@Component({
  selector: 'new-policy',
  templateUrl: './new-policy.component.html',
  styleUrls: ['./new-policy.component.scss'],
})
export class NewPolicyComponent implements OnInit {
  public constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit(): void {}

  public onMTPLTileClick(): void {
    this.router.navigate(['../../policies/mtpl'], {relativeTo: this.activatedRoute});
  }

  public onPropertyForHomeTileClick(): void {
    this.router.navigateByUrl(
      `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_POLICY_DETAILS}`,
    );
  }

  public onCascoTileClick(): void {
    this.router.navigate(['../../policies/casco'], {relativeTo: this.activatedRoute});
  }

  public onZakrilaTileClick(): void {
    void this.router.navigate(['../../policies/zakrila'], {relativeTo: this.activatedRoute});
  }
}
