<form class="m-t-custom" maiaForm [formGroup]="coverForm.formGroup">
  <div class="label-btn-wrapper">
    <label class="b2b-kdl-font-small title-dark-color"> Main Covers </label>
    <button (click)="openReadMore()" maiaActionWithIcon class="btn-container">
      <svg b2bFrontendFileFuncDocument class="file-func-doc-size" maia-action-icon></svg>
      <span class="b2b-kdl-font-small">Read More</span>
    </button>
  </div>
  <maia-radio-group name="vehicleRegisterRadio" formControlName="mainCover">
    <div class="container-grid">
      <maia-tile
        *ngFor="let cover of mainCoversList$ | async"
        [ngClass]="{
          'radio-tile-selected': this.coverForm.controls['mainCover'].value?.id === cover.id
        }"
        class="flex-column-container p-custom"
      >
        <maia-radio-button [value]="cover" class="border-none m-l--10">
          <label class="radio-text b2b-kdl-font-small-light">{{ cover.desc_en }}</label>
        </maia-radio-button>
        <button
          (click)="openDeductibleSlideIn(cover.id)"
          *ngIf="cover.id === 0 && !fullDeductibleAmount"
          maiaButton
          class="add-dedductible-btn"
          maiaButtonType="tertiary"
        >
          <div class="b2b-kdl-font-extra-small">Add Deductible</div>
        </button>
        <button
          (click)="openDeductibleSlideIn(cover.id)"
          *ngIf="cover.id === 1 && !superDeductibleAmount"
          maiaButton
          class="add-dedductible-btn"
          maiaButtonType="tertiary"
        >
          <div class="b2b-kdl-font-extra-small">Add Deductible</div>
        </button>
        <button
          (click)="openDeductibleSlideIn(cover.id)"
          *ngIf="cover.id === 0 && fullDeductibleAmount"
          maiaButton
          class="added-dedductible-btn"
          maiaButtonType="tertiary"
        >
          <div class="b2b-kdl-font-extra-small">Deductible Added-{{ fullDeductibleAmount }}BGN</div>
        </button>
        <button
          (click)="openDeductibleSlideIn(cover.id)"
          *ngIf="cover.id === 1 && superDeductibleAmount"
          maiaButton
          class="added-dedductible-btn"
          maiaButtonType="tertiary"
        >
          <div class="b2b-kdl-font-extra-small"
            >Deductible Added-{{ superDeductibleAmount }}BGN</div
          >
        </button>
      </maia-tile>
    </div>
  </maia-radio-group>
  <maia-divider></maia-divider>
  <div class="label-btn-wrapper">
    <label class="b2b-kdl-font-small title-dark-color"> Road Assistance Covers</label>
    <button
      maiaButton
      maiaButtonType="tertiary"
      class="btn-underline-color"
      (click)="clearSelection()"
    >
      <div class="b2b-kdl-font-small clear-btn-color"> Clear Selection</div>
    </button>
  </div>
  <maia-radio-group name="vehicleRegisterRadio" formControlName="raCover">
    <div class="container-grid1">
      <maia-tile
        *ngFor="let cover of raCoversList$ | async"
        [ngClass]="{
          'radio-tile-selected': this.coverForm.controls['raCover'].value?.id === cover.id
        }"
        class="flex-column-container p-custom"
      >
        <maia-radio-button [value]="cover" class="border-none m-l--10">
          <label class="radio-text b2b-kdl-font-small-light">{{ cover.desc_en }}</label>
        </maia-radio-button>
        <button
          *ngIf="cover.id === 0 && !additionalRisksAmount"
          class="additional-risks-btn"
          maiaButton
          maiaButtonType="tertiary"
          (click)="openAdditionalRisksSlideIn()"
        >
          <div class="b2b-kdl-font-extra-small"> Additional Risks</div>
        </button>
        <button
          *ngIf="cover.id === 0 && additionalRisksAmount"
          class="added-additional-risks-btn"
          maiaButton
          maiaButtonType="tertiary"
          (click)="openAdditionalRisksSlideIn()"
        >
          <div class="b2b-kdl-font-extra-small"
            >Additional Risks - {{ additionalRisksAmount }} BGN</div
          >
        </button>
      </maia-tile>
    </div>
  </maia-radio-group>
  <maia-divider></maia-divider>
  <label class="b2b-kdl-font-small title-dark-color"> Add Ons </label>
  <div formArrayName="addOnsArray" class="checkbox-wrapper">
    <div class="m-t-24 checkbox-size" *ngFor="let addon of addOnsArray.controls; let i = index">
      <maia-checkbox [formControlName]="i">
        <div class="checkbox-container">
          <span class="b2b-kdl-font-small-light radio-text m-t-3">{{ addOnsList[i].desc_en }}</span>
          <button *ngIf="i === 3" maiaButton class="m-t--13" maiaButtonType="tertiary">
            <div class="b2b-kdl-font-extra-small">Add more details</div>
          </button></div
        >
      </maia-checkbox>
    </div>
  </div>
  <maia-divider></maia-divider>
  <label class="b2b-kdl-font-small title-dark-color"> Additional and Special Conditions</label>
  <div formArrayName="additionalConditionsArray" class="additional-conditions-wrapper">
    <div class="m-t-24" *ngFor="let addon of additionalConditionsArray.controls; let i = index">
      <maia-checkbox [formControlName]="i">
        <span class="b2b-kdl-font-small-light radio-text m-t-3">{{
          additionalConditionsList[i].desc_en
        }}</span>
      </maia-checkbox>
    </div>
  </div>
  <maia-divider></maia-divider>
  <maia-fluid-fluid-layout class="m-t--26">
    <maia-form-element class="multi-input-row" label="Advance Bonus" hideOptionalIndicator>
      <maia-radio-group
        name="advanceBonus"
        class="m-t--12"
        formControlName="advanceBonus"
        horizontal
      >
        <njf-radio-button [disabled]="disableButton" value="yes">Yes</njf-radio-button>
        <njf-radio-button [disabled]="disableButton" value="no">No</njf-radio-button>
      </maia-radio-group>
    </maia-form-element>
    <div></div>
  </maia-fluid-fluid-layout>
  <maia-divider class="m-t-6"></maia-divider>
  <button
    *ngIf="!(step.inEdit | async)"
    class="m-b-24 m-t-24"
    maiaButton
    maiaButtonType="primary"
    (click)="saveAndContinue()"
    >Continue</button
  >
  <div *ngIf="step.inEdit | async">
    <button class="m-r-24 m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveEdit()"
      >Save Changes</button
    >
    <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
  </div>
</form>
