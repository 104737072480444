import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncPlusCircleSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendActionFuncPlusCircle]',
  template: '<svg:path d="M14.56 12H9.44" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path><svg:circle cx="12" cy="12" r="9.01" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:circle><svg:path d="M12 14.56V9.44" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncPlusCircleSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncPlusCircleSvgComponent,
  ],
  exports: [
    ActionFuncPlusCircleSvgComponent,
  ],
})
export class ActionFuncPlusCircleSvgModule {}
