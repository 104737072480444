import {ActivatedRoute} from '@angular/router';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';
import {CascoWorkflow} from '../../casco-core/casco-workflow/CascoWorkflow';
import {Component, OnInit} from '@angular/core';
import {Decimal} from '@atlas/businesstypes';
import {FormControl, Validators} from '@angular/forms';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';
import {mockData} from './__tests__/mockInterface';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {tariffRateMock, READ_MORE_LINK, InstalOpts} from './__tests__/input-select-mock';

@Component({
  selector: 'nje-price-premium',
  templateUrl: './price-premium.component.html',
  styleUrls: ['./price-premium.component.scss'],
})
@UntilDestroy()
export class PricePremiumComponent extends ScreenFormComponent implements OnInit {
  public premium: number = 0;

  public autoLoadViewMore: boolean = true;

  public authorization: boolean = false;
  public calPre: boolean = true;
  public tariffPremium: boolean = false;

  public activatePreMTPLPercBox: boolean = false;
  public activateErthqcPercBox: boolean = false;
  public installment: string | null = sessionStorage.getItem('installment');

  //related to stepper control
  public continueClicked: boolean;

  public tariffRateMock: mockData[] = tariffRateMock;
  public readMore = READ_MORE_LINK;

  public premiumForm: FormGroupManager<any> = this._formFactory.createFormManager<any>({
    tariffRate: new FormControl('Fixed Premium', [Validators.required]),
    instlmntOpt: new FormControl(undefined),
    tariffRateValue: new FormControl(undefined),
  });

  public mtplPreChk: boolean = false;
  public insPreChk: boolean = false;
  public regPreChk: boolean = false;
  public veh45Chk: boolean = false;
  public expEvalPreChk: boolean = false;
  public drctSaleChk: boolean = false;
  public rskMkMdlChk: boolean = false;
  public vehUsgChk: boolean = false;
  public nrtAmrcaVehCh: boolean = false;
  public agriForstChk: boolean = false;
  public rprAbrdPreChk: boolean = false;
  public erthqcCvrgChk: boolean = false;

  public constructor(
    route: ActivatedRoute,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _process: CascoProcessService,
  ) {
    super(route);
    this._process.currentWorkflow = CascoWorkflow.PRICE_OFFER;
  }

  public ngOnInit(): void {
    this.instalAutoSelect();
    this.suffixChanger();
  }

  public suffixChanger(): void {
    this.premiumForm.controls.tariffRate.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: string) => {
        value === 'Fixed Tariff' ? (this.tariffPremium = true) : (this.tariffPremium = false);
      });
  }

  public instalAutoSelect(): void {
    switch (this.installment) {
      case '1':
        this.premiumForm.controls.instlmntOpt.setValue(InstalOpts.SINGLE);
        break;
      case '2':
        this.premiumForm.controls.instlmntOpt.setValue(InstalOpts.DOUBLE);
        break;
      case '3':
        this.premiumForm.controls.instlmntOpt.setValue(InstalOpts.FOUR);
        break;
      case '4':
        this.premiumForm.controls.instlmntOpt.setValue(InstalOpts.TWELVE);
        break;
    }
  }

  public clickOnHere(): void {
    this.autoLoadViewMore = !this.autoLoadViewMore;
  }

  public enableExtensionBox(choice: number): void {
    switch (choice) {
      case 1:
        this.activatePreMTPLPercBox = !this.activatePreMTPLPercBox;
        break;
      case 2:
        this.activateErthqcPercBox = !this.activateErthqcPercBox;
        break;
    }
  }

  public getAmountValue(amount: string): Decimal {
    return new Decimal(amount);
  }

  protected saveMtplData(): void {
    this.continueClicked = true;
  }
}
