<maia-fluid-fixed-layout class="slide-input">
  <form [formGroup]="agentForm">
    <maia-input-select formControlName="agentOption">
      <input maiaInput placeholder="Select" [value]="agentForm.controls['agentOption'].value" />
      <maia-option *ngFor="let option of agentsbyNumberOptions" [value]="option.title">
        {{ option.title }}
      </maia-option>
    </maia-input-select>
  </form>
</maia-fluid-fixed-layout>

<div class="m-t-16" *ngIf="selectedAgentType?.id == agentsNumber.GROUP_OF_AGENT_NUMBER">
  <nje-add-agent></nje-add-agent>
</div>

<div
  class="m-t-16 b2b-kdl-color-background-light"
  *ngIf="selectedAgentType?.id == agentsNumber.SINGLE_AGENT_NUMBER"
>
  <div class="m-l-12 m-r-16 m-t-16">
    <nje-add-single-agent></nje-add-single-agent>
  </div>
  <button maiaButton class="m-l-12 m-b-16 m-t-16" disabled>Add</button>
  <button maiaButton class="m-l-12 m-b-16 m-t-16">Cancel</button>
</div>
