import {Injectable} from '@angular/core';
import {API_END_POINT, ApiService} from '@b2b-frontend/core';
import {Observable, of, Subject} from 'rxjs';

import {
  CreateProductDto,
  Library,
  Product,
  ProductPortfolio,
  ProductResponseDto,
} from '../contracts/library.interface';
import {MOCK_PRODUCT_LIST} from '../screens/resource-management/library/product/_tests_/products.mocks';
import {CategoryInterface, NewsEvents} from '../contracts/NewsEvents';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  public emitter = new Subject<boolean>();
  public constructor(private readonly _apiService: ApiService) {}

  public getLibraryLists(): Observable<Library[]> {
    return this._apiService.get(API_END_POINT.LIBRARY_API);
  }

  public deleteLibraryList(id: number): Observable<any> {
    return this._apiService.delete(`${API_END_POINT.LIBRARY_API}/${id}`);
  }

  public updateLibraryList(libraryList: Library): Observable<Library> {
    return this._apiService.patch(`${API_END_POINT.LIBRARY_API}/${libraryList.id}`, libraryList);
  }

  public AddLibraryList(libraryList: Library): Observable<Library> {
    return this._apiService.postFiles(API_END_POINT.LIBRARY_API, libraryList);
  }

  public getLibraryList(id: number): Observable<Library> {
    return this._apiService.get(`${API_END_POINT.LIBRARY_API}/${id}`);
  }

  public getProductPortfolioList(id: number): Observable<ProductPortfolio[]> {
    return this._apiService.get(`${'cms/library'}/${id}/${'portfolio'}`);
  }

  public deleteProductPortfolio(libraryid: number, id: number): Observable<ProductPortfolio> {
    return this._apiService.delete(`cms/${'library'}/${libraryid}/${'portfolio'}/${id}`);
  }

  public updateProductPortfolio(productPortfolio: ProductPortfolio): Observable<ProductPortfolio> {
    return this._apiService.patch(
      `${API_END_POINT.PORTFOLIO_API}/${productPortfolio.id}`,
      productPortfolio,
    );
  }

  public AddProductPortfolio(productPortfolio: ProductPortfolio): Observable<ProductPortfolio> {
    return this._apiService.postFiles(API_END_POINT.PORTFOLIO_API, productPortfolio);
  }

  public getMockProductList(): Observable<Product[]> {
    return of(MOCK_PRODUCT_LIST);
  }

  public getProductList(libraryId: number, portfolioId: number): Observable<ProductPortfolio[]> {
    return this._apiService.get(
      `${API_END_POINT.LIBRARY_API}/${libraryId}/portfolio/${portfolioId}/product`,
    );
  }

  public addNewProduct(
    libraryId: number,
    portfolioId: number,
    product: CreateProductDto,
  ): Observable<Product> {
    return this._apiService.postFiles(
      `${API_END_POINT.LIBRARY_API}/${libraryId}/portfolio/${portfolioId}/product`,
      product,
    );
  }

  public deleteProduct(
    libraryId: number,
    portfolioId: number,
    productId: number,
  ): Observable<Product> {
    return this._apiService.delete(
      `cms/library/${libraryId}/portfolio/${portfolioId}/product/${productId}`,
    );
  }

  public updateProduct(
    libraryId: number,
    portfolioId: number,
    productId: number,
    product: CreateProductDto,
  ): Observable<Product> {
    return this._apiService.patch(
      `${API_END_POINT.LIBRARY_API}/${libraryId}/portfolio/${portfolioId}/product/${productId}`,
      product,
    );
  }

  public getCategoryList(): Observable<CategoryInterface[]> {
    return this._apiService.get(`${API_END_POINT.PRODUCT_CATEGORY}`);
  }

  public updateProductCategory(categoryObj: CategoryInterface): Observable<CategoryInterface> {
    return this._apiService.patch(
      `${API_END_POINT.PRODUCT_CATEGORY}/${categoryObj.id}`,
      categoryObj,
    );
  }

  public addProductCategory(categoryObj: CategoryInterface): Observable<CategoryInterface> {
    return this._apiService.postFiles(API_END_POINT.PRODUCT_CATEGORY, categoryObj);
  }

  public deleteProductCategory(CategoryId: number): Observable<CategoryInterface> {
    return this._apiService.delete(`${API_END_POINT.PRODUCT_CATEGORY}/${CategoryId}`);
  }

  public getArticleList(): Observable<NewsEvents[]> {
    return this._apiService.get(API_END_POINT.ARTICLE_API);
  }

  public getProductById(
    libraryId: number,
    portfolioId: number,
    productId: number,
  ): Observable<ProductResponseDto> {
    return this._apiService.get(
      `${API_END_POINT.LIBRARY_API}/${libraryId}/portfolio/${portfolioId}/product/${productId}`,
    );
  }
}
