import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {
  StepperProcessService as StepperProcessServiceBase,
  StepperProcessStep,
} from '@njf-frontend-angular/flow-progress';

import {PropertyWorkflow} from '../property-workflow/property-workflow';
import {steps} from '../property-workflow/steps';
import {workflows} from '../property-workflow/workflows';

const PROPERTY_SESSION_KEY = 'property-process';

@Injectable({
  providedIn: 'root',
})
export class PropertyProcessService
  extends StepperProcessServiceBase<PropertyWorkflow>
  implements OnDestroy
{
  public constructor(router: Router) {
    super(router, steps, workflows, PropertyWorkflow.PropertyHomePolicy, PROPERTY_SESSION_KEY);
  }

  protected getWorkflowStepsFromWorkflowAndStep(
    wf: PropertyWorkflow,
    step: StepperProcessStep,
  ): StepperProcessStep[] {
    return this.getWorkflowSteps(wf);
  }

  protected getCurrentWorkflowStepsList(): string[] {
    return this.workflows[this.currentWorkflow];
  }
}
