<div class="readMore-slidein-style">
  <button (click)="openOneSlideIn()" maiaDocumentAction class="font-size">Read More</button>
</div>

<div class="max-width-body">
  <form maiaForm [formGroup]="newPolicyForm.formGroup">
    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true">
          <maia-label for="policyIssueDate" class="b2b-kdl-font-small title-dark-color m-b-8">
            Policy Issue Date : {{ today }}
          </maia-label>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label for="policyLength" class="b2b-kdl-font-small title-dark-color m-b-16">
            Policy Length
          </maia-label>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16" *ngIf="labelFlag">
        <maia-form-element hideOptionalIndicator class="input-column">
          <maia-label
            class="b2b-kdl-font-small title-dark-color m-b-8"
            sublabel="Start time of the policy is 30 minutes ahead from now"
          >
            Start date
          </maia-label>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16" *ngIf="!labelFlag">
        <maia-form-element hideOptionalIndicator class="input-column">
          <maia-label
            class="b2b-kdl-font-small title-dark-color m-b-8"
            sublabel="Policy will be started at 00.00 on the selected date"
          >
            Start date
          </maia-label>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label
            class="b2b-kdl-font-small title-dark-color m-b-8"
            sublabel="Policy will be ended at 23.59 on the selected date "
          >
            End date
          </maia-label>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-input-select name="policyLength" formControlName="policyLength">
            <input
              maiaInput
              placeholder="Select"
              [value]="getPolicyLengths(newPolicyForm.controls.policyLength.value)"
            />
            <maia-option
              *ngFor="let policyLength of policyLengthList"
              [value]="policyLength.duration"
              >{{ policyLength.duration + ' ' + policyLength.dimension }}</maia-option
            >
          </maia-input-select>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16" *ngIf="labelFlag">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-input-date
            name="startDate"
            [minimum]="minDate"
            [maximum]="maxDate"
            formControlName="startDate"
          ></maia-input-date>
          <maia-validation
            *ngIf="
              newPolicyForm.controls['startDate'].dirty &&
              newPolicyForm.controls['startDate'].value < minDate &&
              newPolicyForm.controls['startDate'].value > maxDate
            "
          >
            The beginning of the policy cannot be more than 60 days ahead or cannot be in the past.
          </maia-validation>
        </maia-form-element>
      </div>
      <div class="fluid m-r-16" *ngIf="!labelFlag">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-input-date
            name="startDate"
            [minimum]="minDate"
            [maximum]="maxDate"
            formControlName="startDate"
          ></maia-input-date>
          <maia-validation
            *ngIf="
              newPolicyForm.controls['startDate'].dirty &&
              newPolicyForm.controls['startDate'].value < minDate &&
              newPolicyForm.controls['startDate'].value > maxDate
            "
          >
            The beginning of the policy cannot be more than 60 days ahead or cannot be in the past.
          </maia-validation>
        </maia-form-element>
      </div>
      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-input-date
            [minimum]="minDate"
            [maximum]="maxDate"
            [disabled]="true"
            name="endDate"
            formControlName="endDate"
          ></maia-input-date>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>

    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small m-b-8 title-dark-color">
            Are you driving outside Bulgarian teritory with this vehicle for more than 60 days?
          </maia-label>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color">
            What purpose does this vehicle serve?
          </maia-label>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color">
            On which side is the steering wheel?
          </maia-label>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout>
      <ng-container>
        <div class="fluid m-r-16">
          <maia-form-element class="input-column">
            <maia-radio-group
              formControlName="travelOutsideBulgaria"
              horizontal
              class="radio-group-margins"
            >
              <njf-radio-button
                *ngFor="let option of mockPolicyRadioList"
                class="white-bg-color"
                [value]="option.id"
                >{{ option.name }}</njf-radio-button
              >
            </maia-radio-group>
          </maia-form-element>
        </div>

        <div class="fluid m-r-16">
          <maia-form-element class="input-column down-size multi-input-row">
            <maia-input-select name="purposeOfVehicleServe" formControlName="purposeOfVehicleServe">
              <input
                maiaInput
                placeholder="Select"
                [value]="getVehiclePurposes(newPolicyForm.controls.purposeOfVehicleServe.value)"
              />
              <maia-option
                *ngFor="let vehiclePurpose of vehiclePurposeList"
                [value]="vehiclePurpose.id"
                >{{ vehiclePurpose.name }}</maia-option
              >
            </maia-input-select>
          </maia-form-element>
        </div>

        <div class="fluid m-r-16">
          <maia-form-element class="input-column">
            <maia-radio-group
              formControlName="steeringWheelSide"
              horizontal
              class="b2b-kdl-font-tiny"
            >
              <njf-radio-button *ngFor="let option of mockSteeringWheelSide" [value]="option.id">{{
                option.name
              }}</njf-radio-button>
            </maia-radio-group>
          </maia-form-element>
        </div>
      </ng-container>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16" *ngIf="!isScenarioFour">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color">
            Was the vehicle new when acquired?
          </maia-label>
          <maia-radio-group horizontal formControlName="newVehicleorNot">
            <njf-radio-button value="yes">Yes</njf-radio-button>
            <njf-radio-button value="no">No</njf-radio-button>
          </maia-radio-group>
        </maia-form-element>
      </div>
      <div class="fluid m-r-16" *ngIf="!isScenarioFour"></div>
      <div class="fluid m-r-16" *ngIf="!isScenarioFour"></div>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>

    <maia-fluid-fluid-layout alignContentTop>
      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color m-b-8"> Office </maia-label>
          <maia-input-select name="officeList" formControlName="officeList">
            <input
              maiaInput
              placeholder="Select"
              [value]="getOffices(newPolicyForm.controls.officeList.value)"
            />
            <maia-option *ngFor="let office of officeList" [value]="office.name">{{
              office.name
            }}</maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color m-b-8">
            Agency / UIC / Precinct
          </maia-label>
          <maia-input-autocomplete
            id="autocomplete_one"
            name="simple"
            slideInTitle="Simple autocomplete"
            formControlName="agencyNumer"
          >
            <input
              autocomplete="off"
              id="input_one"
              maiaInput
              placeholder="Agency / UIC / Precinct"
              [value]="getAgenyCode(newPolicyForm.controls.agencyNumer.value)"
            />
            <maia-option *ngFor="let agencyCode of agencyCodeList" [value]="agencyCode.id">
              <maia-option-highlight [label]="agencyCode.agencyCode"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
        </maia-form-element>
      </div>
      <div class="fluid m-r-16"> </div>
    </maia-fluid-fluid-layout>

    <maia-divider> </maia-divider>

    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color m-b-8">
            Special Authorisation Code
          </maia-label>
          <input
            maiaInput
            name="specialAuthorisationCode"
            id="specialAuthorisationCode"
            [disabled]=""
            placeholder="000000000"
            formControlName="specialAuthorisationCode"
          />
        </maia-form-element>
      </div>

      <div class="fluid m-r-16" *ngIf="authorizationCode">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color m-b-8"> TariffRate </maia-label>
          <maia-input-select name="tariffRate" formControlName="tariffRate">
            <input
              maiaInput
              placeholder="Select"
              [value]="getTariffRate(newPolicyForm.controls.tariffRate.value)"
            />
            <maia-option *ngFor="let tariffRate of tariffRateList" [value]="tariffRate.id">{{
              tariffRate.name
            }}</maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16" *ngIf="authorizationCode">
        <maia-form-element
          label="Tariff Rate Value"
          [hideOptionalIndicator]="true"
          class="input-column"
        >
          <maia-label class="b2b-kdl-font-small title-dark-color m-b-8">
            TariffRate Value
          </maia-label>
          <maia-input-group>
            <input
              maiaInputNumber
              atlasDecimal
              placeholder="Enter an amount"
              formControlName="tariff"
            />
            <maia-input-group-suffix>BGN</maia-input-group-suffix>
          </maia-input-group>
        </maia-form-element>
      </div>
      <div class="fluid m-r-16" *ngIf="!authorizationCode"> </div>
      <div class="fluid m-r-16" *ngIf="!authorizationCode"> </div>
    </maia-fluid-fluid-layout>
    <maia-divider class="m-t-b-24"> </maia-divider>
    <div class="flex-div banner-style">
      <maia-contextual-message id="contextual-message-4" type="error"
        >The vehicle owner data is not the same as the data declared in the vehicle talon document.
        Please
        <button maiaButton maiaButtonType="tertiary" class="banner-action-btn">click here</button>
        to edit the information
      </maia-contextual-message>
    </div>
    <button
      class="m-t-b-24"
      id="continueBtn"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!newPolicyForm.valid"
      (click)="onContinue()"
    >
      Continue
    </button>
  </form>
</div>
