import {Component} from '@angular/core';
import {TRAVEL_POLICY_SUCCESS} from '../../constants/constant';

@Component({
  selector: 'nje-travel-policy-success',
  templateUrl: './travel-policy-success.component.html',
  styleUrls: ['./travel-policy-success.component.scss'],
})
export class TravelPolicySuccessComponent {
  public paymentSuccessMsg: string = TRAVEL_POLICY_SUCCESS.PAYMENT_SUCCESS_MSG;
  public startDate: string = '12/2/2021';
  public endDate: string = '17/2/2021';

  public documents: string[] = [
    'IPID Document 1',
    'Print Document 1',
    'Print Document 2',
    'Print Document 3',
    'Print Document 4',
    'Print Document 5',
  ];
}
