import {Component} from '@angular/core';
import {PropertyRoutePaths} from '@b2b-frontend/core';
import {PropertyProcessService} from '../../property-core/services/property-process.service';
import {PropertyNavigationService} from '../../property-core/services/property-navigation.service';
import {PROPERTY_POLICY_SUCCESS} from '../../constants/property-insurance-constants';
import {PROPERTY_PROPOSAL_DOCUMENTS} from './__tests__/property-policy-success.mock';

@Component({
  selector: 'nje-property-policy-success',
  templateUrl: './property-policy-success.component.html',
  styleUrls: ['./property-policy-success.component.scss'],
})
export class PropertyPolicySuccessComponent {
  public paymentSuccessMsg: string = PROPERTY_POLICY_SUCCESS.PAYMENT_SUCCESS_MSG;
  public startDate: string = '12/2/2021';
  public endDate: string = '17/2/2021';
  public documents: string[] = [...PROPERTY_PROPOSAL_DOCUMENTS];

  public constructor(
    private readonly _propertyNavigationService: PropertyNavigationService,
    public process: PropertyProcessService,
  ) {
    this.process.exitStepEdit();
  }

  public createPolicy(): void {
    this._propertyNavigationService.nextScreen(PropertyRoutePaths.PROPERTY_POLICY_DETAILS);
  }
}
