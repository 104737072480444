import {PropertyPackage} from '../../../contracts/property-insurance.interface';

const aggregate_limit: string = 'Aggregate Limit';
export const ADD_VILLA: string = 'Villa';
export const IMMOVABLE_PROPERTY: string = 'Immovable Property';
export const MOVABLE_PROPERTY: string = 'Movable Property';
export const HOUSEHOLD_PROPERTY: string = 'Household Property';
export const PIECES_OF_ART: string = 'Pieces of Art';
export const ADD_BUTTON_VALUE: string = 'ADD';
export const SAVE_BUTTON_VALUE: string = 'SAVE';
export const MAIN_COVERS: string = 'Main Covers';
export const ADD_COVERS: string = 'Additonal Covers';
export const INCLUDED: string = 'Included';
export const EARTHQUAKE: string = 'Earthquake';
export const THEFT: string = 'Theft';
export const AGGREGATE_LIMIT_VALUE: number = 56000;
export const VIEW_LESS = 'less';
export const VIEW_MORE = 'more';
export const PACKAGE_VALUE: string = 'Package ';
export const MIN_VALUE: string = 'Minimum Value is 1000 ';

export const ADDITIONAL_COVERS_INFO_TABLE_COL_HEADERS1: string[] = [
  'Covers',
  aggregate_limit,
  'Limit per Occurance',
  'Premium Account',
];

export const ADDITIONAL_COVERS_INFO_TABLE_COL_HEADERS2: string[] = [
  'Covers',
  aggregate_limit,
  'Limit per Occurance',
];

export const PROPERTY_COVERS_INFO_TABLE_COL_HEADERS: string[] = [
  'Property Type',
  'Basis of Insurance',
  'Insured Value',
  'Main Covers',
  'Additional Covers',
  aggregate_limit,
];

export const ADDITIONAL_COVERS_LIST: string[] = [
  'TPL',
  'Glass Breakage',
  'Sea Waves',
  'Sound Wave',
  'Loss of Rent',
  'Portable Electronics',
  'Accidents',
];

export const BASIS_OF_INSURANCE: string[] = ['Replacement Value', 'Limit/First Risk'];

export const PROPERTY_PACKAGE_HEADERS: PropertyPackage[] = [
  {
    id: 1,
    packageTile: 'Package Platinum',
    selectedStatus: false,
    packageValue: 'Platinum',
  },
  {
    id: 2,
    packageTile: 'Package Gold',
    selectedStatus: false,
    packageValue: 'Gold',
  },
  {
    id: 3,
    packageTile: 'Package Silver',
    selectedStatus: false,
    packageValue: 'Silver',
  },
  {
    id: 4,
    packageTile: 'Package Bronze',
    selectedStatus: false,
    packageValue: 'Bronze',
  },
];
