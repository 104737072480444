import {Component} from '@angular/core';
import {ModalControl} from '@maia/modals';
import {NewsEvents} from '../../../../contracts/NewsEvents';

@Component({
  selector: 'nje-delete-popup-news',
  templateUrl: './delete-popup-news.component.html',
  styleUrls: ['./delete-popup-news.component.scss'],
})
export class DeletePopupNewsComponent {
  public draftList: NewsEvents[];

  public constructor(public control: ModalControl<void>) {}

  public delete(): void {
    this.control.confirm();
  }

  public cancel(): void {
    this.control.cancel();
  }
}
