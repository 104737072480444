<div class="account-operations-wrapper">
  <p class="b2b-kdl-font-normal title-dark-color operations-txt">Operations</p>
  <p class="b2b-kdl-font-normal title-dark-color account-operations-txt"> Account operations </p>

  <maia-fluid-fluid-layout class="blue-box b2b-kdl-color-primary-dark">
    <div
      class="oprations-button-wrapper"
      (click)="routeToPage(card)"
      *ngFor="let card of cards; let i = index"
    >
      <div class="operation-content">
        <maia-avatar-svg avatarSize="48" maia-avatar-display-icon [ngSwitch]="i">
          <svg
            *ngSwitchCase="0"
            class="b2b-kdl-color-primary-light"
            b2bFrontendInformationExprWalletAccount
          ></svg>
          <svg
            *ngSwitchCase="1"
            class="b2b-kdl-color-primary-light"
            b2bFrontendSocialFuncPersonArrowIn
          ></svg>
          <svg
            *ngSwitchCase="2"
            class="b2b-kdl-color-primary-light"
            b2bFrontendSocialFuncPersonReturnArrow
          ></svg>
          <svg
            *ngSwitchCase="3"
            class="b2b-kdl-color-primary-light"
            b2bFrontendSocialFuncPersonReturnArrow
          ></svg>
          <svg
            *ngSwitchCase="4"
            class="b2b-kdl-color-primary-light"
            b2bFrontendTransportationExprCarNew
          ></svg>
          <svg
            *ngSwitchCase="5"
            class="b2b-kdl-color-primary-light"
            b2bFrontendTransportationExprSemitrailerEmpty
          ></svg>
          <svg
            *ngSwitchCase="6"
            class="b2b-kdl-color-primary-light"
            b2bFrontendTransportationExprPlane
          ></svg>
          <svg
            *ngSwitchCase="7"
            class="b2b-kdl-color-primary-light"
            b2bFrontendPlacesExprHouseTree
          ></svg>
          <svg
            *ngSwitchCase="8"
            class="b2b-kdl-color-primary-light"
            b2bFrontendPlacesExprFactory
          ></svg>
          <svg
            *ngSwitchCase="9"
            class="b2b-kdl-color-primary-light"
            b2bFrontendInformationExprHandsCross
          ></svg>
          <svg
            *ngSwitchCase="10"
            class="b2b-kdl-color-primary-light"
            b2bFrontendInformationExprHandsCross
          ></svg>
        </maia-avatar-svg>

        <p class="operation-txt">{{ card?.title }}</p>
      </div>
    </div>
  </maia-fluid-fluid-layout>
</div>
