<form maiaForm *ngIf="selectProductPortfolio && selectLibrary" name="portfolio" ngDefaultControl>
  <maia-radio-group name="productLibrary">
    <div class="product-title-wrapper left-mar">
      <maia-label
        class="b2b-kdl-font-medium title-dark-color"
        i18n="@@nje-b2b-frontend-resource-library-portfolio-product"
        >Product Portfolio:
        <label class="b2b-kdl-font-medium-bold">{{
          selectProductPortfolio?.i18n?.en?.title
        }}</label>
      </maia-label></div
    >
    <maia-label class="product-sub-title prod-port-desc">
      <maia-rich-text
        [value]="selectProductPortfolio?.i18n?.en?.description | hyperlink"
      ></maia-rich-text> </maia-label
    ><br />

    <maia-label>
      <label
        class="b2b-kdl-font-medium title-dark-color left-mar library-heading-style"
        i18n="@@nje-b2b-frontend-resource-library-portfolio-productlist"
      >
        List of Products
      </label>
    </maia-label>
    <maia-tile
      *ngFor="let product of productList; let i = index"
      class="m-t-b-24 tile-box"
      [ngClass]="{'product-tile-selected': productList[i].selectedStatus}"
    >
      <maia-radio-button [value]="product" (click)="selectProduct(product, i)">
        <div class="box-div">
          <label
            class="b2b-kdl-font-small-light title-dark-color productLibrary-title line-clamp-1"
          >
            {{ product?.i18n?.en?.title }}
          </label>
          <label class="b2b-kdl-font-small-light productLibrary-sub-title module line-clamp-3">
            <maia-rich-text [value]="product?.i18n?.en?.description | hyperlink"></maia-rich-text>
          </label>
          <label>
            <maia-button-group class="align-left">
              <button
                maiaButton
                maiaButtonType="tertiary"
                (click)="navigateToEditProduct(product.id)"
                i18n="@@nje-b2b-frontend-resource-library-portfolio-edit"
                >Edit</button
              >
              <button
                maiaButton
                maiaButtonType="tertiary-delete"
                (click)="deleteProduct(product)"
                i18n="@@nje-b2b-frontend-resource-library-portfolio-delete"
                >Delete</button
              >
            </maia-button-group>
          </label>
        </div>
      </maia-radio-button>
    </maia-tile>
    <div class="action-with-icon" (click)="navigateToAddProduct()">
      <svg class="action-func-plus-circle" b2bFrontendActionFuncPlusCircle></svg>
      <button
        maiaButton
        maiaButtonType="tertiary"
        class="add-btn"
        i18n="@@nje-b2b-frontend-resource-library-portfolio-newproduct"
        >Add New Product</button
      >
    </div>
    <maia-divider class="maia-divider border-margin"></maia-divider>
  </maia-radio-group>
</form>
