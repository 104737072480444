import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {
  MENUS_FOR_BREADCRUMBS,
  MENUS_FOR_MTPL_BREADCRUMBS,
  MENUS_FOR_PROPERTY_BREADCRUMBS,
  MENUS_FOR_STATISTICS_BREADCRUMBS,
} from './_tests_/breadcrumbs.mocks';
import {TitleService} from '@njf-frontend-angular/title';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

@Component({
  selector: 'njf-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
@UntilDestroy()
export class BreadcrumbsComponent implements OnInit {
  public name: string;
  public menu: any[] = [...MENUS_FOR_BREADCRUMBS];
  public routerList: any[];
  public breadcrumbList: any[] = [];
  public navigatePath: string;
  public title: string;

  public constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _titleService: TitleService,
    private readonly _router: Router,
  ) {}

  public ngOnInit(): void {
    this._titleService.title$.pipe(takeUntilDestroyed(this)).subscribe((title: string) => {
      this.title = title;
      this._changeDetectorRef.markForCheck();
    });

    this.listenRouting();
  }

  public listenRouting(): void {
    let routerUrl: string, target: any;
    this._router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        target = this.menu;
        this.breadcrumbList.length = 0;

        this.routerList = [...routerUrl.slice(1).split('/')];
        const mtpl = this.routerList.find((value: any) => {
          return value === 'mtpl';
        });
        if (mtpl) {
          this.menu = MENUS_FOR_MTPL_BREADCRUMBS;
          target = this.menu;
        }
        const property = this.routerList.find((value: any) => {
          return value === 'property';
        });
        if (property) {
          this.menu = MENUS_FOR_PROPERTY_BREADCRUMBS;
          target = this.menu;
        }
        const cms = this.routerList.find((value: any) => {
          return value === 'statistics';
        });
        if (cms) {
          this.menu = MENUS_FOR_STATISTICS_BREADCRUMBS;
          target = this.menu;
        }
        this.routerList.forEach((router: any, index: number) => {
          if (target && target.length > 0) {
            target = target.find((page: any) => page.filterPath.slice(2) === router);
            if (target) {
              this.breadcrumbList.push({
                name: target.name,
                fullPath: target.fullPath,
                filterPath:
                  index === 0
                    ? target.filterPath
                    : `${this.breadcrumbList[index - 1].filterPath}/${target.filterPath.slice(2)}`,
              });
              if (index + 1 !== this.routerList.length) {
                target = target.children;
              }
            }
          }
        });
      }
    });
  }

  public navigateToScreen(path: string) {
    this.navigatePath = path;
    this._router.navigate([this.navigatePath]);
  }
}
