import {ElementRef, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FocusManagerService {
  private readonly _focusedOnce = new Map<string, string[]>();

  public attachControl(stepName: string, controlEl: ElementRef): void {
    if (controlEl) {
      const htmlEl = controlEl.nativeElement as HTMLElement;
      const name = htmlEl.getAttribute('formcontrolname');

      if (
        name &&
        (!this._focusedOnce.has(stepName) || this._focusedOnce.get(stepName)!.indexOf(name) === -1)
      ) {
        setTimeout(() => {
          (controlEl.nativeElement as HTMLElement).focus();
          if (!this._focusedOnce.has(stepName)) {
            this._focusedOnce.set(stepName, []);
          }
          this._focusedOnce.get(stepName)!.push(name);
        });
      }
    }
  }
}
