import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DateKey, DateUtils} from '@atlas/businesstypes';
import {DateFormatterService, dateValidator} from '@b2b-frontend/core';
import {Subscription} from 'rxjs';

import {SuspendAccount} from '../../../../models/b2b/SuspendAccount';

@Component({
  selector: 'nje-account-suspension-form',
  templateUrl: './account-suspension-form.component.html',
  styleUrls: ['./account-suspension-form.component.scss'],
})
export class AccountSuspensionFormComponent implements OnInit, OnDestroy {
  public minDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 365);
  public accountSuspensionForm: FormGroup;
  private _suspensionSubscription: Subscription;

  @Output() public isAccountSuspensionFormValid = new EventEmitter<boolean>();

  public constructor(
    private readonly _fb: FormBuilder,
    private readonly _dateFormatterService: DateFormatterService,
  ) {}

  public ngOnInit(): void {
    this.accountSuspensionForm = this._fb.group({
      suspensionStartDate: [undefined, [Validators.required, dateValidator()]],
      suspensionEndDate: [undefined, [dateValidator()]],
      suspensionReason: [undefined, [Validators.required]],
    });

    this._suspensionSubscription = this.accountSuspensionForm.valueChanges.subscribe(() => {
      this.isAccountSuspensionFormValid.emit(this.accountSuspensionForm.valid);
    });
  }

  public getAccountSuspensionData(): SuspendAccount {
    const suspensionStartDate = this._dateFormatterService.dateToISO(
      this.accountSuspensionForm.get('suspensionStartDate')?.value,
    );
    const suspensionEndDate = this._dateFormatterService.dateToISO(
      this.accountSuspensionForm.get('suspensionEndDate')?.value,
    );

    return {
      suspensionStartDate,
      suspensionEndDate,
      suspensionReason: this.accountSuspensionForm.get('suspensionReason')?.value,
    };
  }

  public ngOnDestroy(): void {
    if (this._suspensionSubscription) {
      this._suspensionSubscription.unsubscribe();
    }
  }
}
