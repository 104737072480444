<maia-label class="b2b-kdl-font-small title-dark-color"> Property Address </maia-label>

<form maiaForm [formGroup]="insuredPartyForm">
  <maia-form-group>
    <maia-fluid-fluid-layout>
      <div class="m-t-16 m-r-16">
        <maia-form-element hideOptionalIndicator class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color m-b-8 m-t-16">
            Insured Party
          </maia-label>
          <maia-input-autocomplete
            class="m-r-16"
            id="insuredParty"
            name="insuredParty"
            formControlName="insuredParty"
            (ngModelChange)="onChangeOfInsuranceParty()"
          >
            <input
              autocomplete="off"
              id="input_one_suggested"
              maiaInput
              placeholder="Select value"
            />

            <maia-option
              *ngFor="let insuranceParty of insurancePartyList; let index = index"
              id="insuranceParty{{ index }}"
              [value]="insuranceParty"
            >
              <maia-option-highlight [label]="insuranceParty"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <maia-validation *ngIf="insuredPartyForm.hasError('insuredParty', 'required')"
            >Insured Party required
          </maia-validation>
        </maia-form-element>
      </div>
      <div> </div>
    </maia-fluid-fluid-layout>
  </maia-form-group>
</form>

<maia-fluid-fluid-layout>
  <div class="m-b-24 m-r-16">
    <insurance-party-details [onlyAddressForm]="true"></insurance-party-details>
  </div>
</maia-fluid-fluid-layout>

<maia-divider></maia-divider>

<div class="property-feature-details">
  <div class="property-details-table-header">
    <div class="page-info b2b-kdl-font-small title-dark-color"> Property / Household Property </div>

    <div class="add-new-button-container">
      <maia-inline-action class="add-new-icon" [disabled]="!enableAddProperty">
        <svg b2bFrontendActionFuncPlusCircle (click)="addEditProperty()"></svg>
      </maia-inline-action>

      <div class="add-new-button">
        <button
          maiaButton
          maiaButtonType="tertiary"
          (click)="addEditProperty()"
          [disabled]="!enableAddProperty"
        >
          Add Property/Household Property
        </button>
      </div>
    </div>
  </div>

  <maia-fluid-fluid-layout>
    <maia-table class="property-details-table">
      <maia-table-header>
        <maia-table-row class="blue-bg-table-row">
          <maia-table-cell
            class="b2b-kdl-font-small subtitle-light-color"
            *ngFor="let column of columnHeaders; let i = index"
          >
            {{ column }}
          </maia-table-cell>
          <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall>
            <div> &nbsp; </div>
          </maia-table-cell>
        </maia-table-row>
      </maia-table-header>
      <maia-table-body>
        <maia-table-row class="no-property-features-added" *ngIf="propertyDetailsList.length <= 0"
          ><maia-table-cell>No properties added</maia-table-cell>
        </maia-table-row>
        <maia-table-row
          class="blue-bg-table-row"
          *ngFor="let propertyDetails of propertyDetailsList; let i = index"
        >
          <maia-table-cell>
            <maia-inline-action
              class="b2b-kdl-font-small property-type-link"
              type="button"
              (click)="addEditProperty(propertyDetails, i)"
              >{{ propertyDetails.propertyType }}
            </maia-inline-action>
          </maia-table-cell>
          <maia-table-cell>
            {{
              null != propertyDetails.areaOfProperty
                ? (propertyDetails.areaOfProperty | atlasDecimal) + ' Sq. mt'
                : '-'
            }}
          </maia-table-cell>
          <maia-table-cell>
            {{
              null != propertyDetails.yearOfConstruction ? propertyDetails.yearOfConstruction : '-'
            }}
          </maia-table-cell>
          <maia-table-cell>
            {{
              null != propertyDetails.isPropertyCoOwnedByBank
                ? true === propertyDetails.isPropertyCoOwnedByBank
                  ? 'Yes'
                  : 'No'
                : '-'
            }}
          </maia-table-cell>
          <maia-table-cell>
            {{ null != propertyDetails.bank ? propertyDetails.bank : '-' }}
          </maia-table-cell>
          <maia-table-cell>
            {{
              null != propertyDetails.amountOwedToTheBank
                ? (propertyDetails.amountOwedToTheBank | atlasDecimal)
                : '-'
            }}
          </maia-table-cell>
          <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall>
            <div class="delete-icon">
              <svg b2bFrontendActionFuncBin (click)="removeProperty(i)"></svg>
            </div>
          </maia-table-cell>
        </maia-table-row>
      </maia-table-body>
    </maia-table>
  </maia-fluid-fluid-layout>
</div>

<div class="property-feature-details">
  <div class="property-details-table-header">
    <div class="page-info b2b-kdl-font-small title-dark-color"> Features </div>

    <div class="add-new-button-container">
      <maia-inline-action class="add-new-icon" [disabled]="0 >= propertyDetailsList.length">
        <svg b2bFrontendActionFuncPlusCircle (click)="addEditFeature()"></svg>
      </maia-inline-action>

      <div class="add-new-button">
        <button
          maiaButton
          maiaButtonType="tertiary"
          (click)="addEditFeature()"
          [disabled]="0 >= propertyDetailsList.length"
        >
          Add Feature
        </button>
      </div>
    </div>
  </div>

  <maia-fluid-fluid-layout>
    <maia-table class="property-details-table">
      <maia-table-header>
        <maia-table-row class="blue-bg-table-row">
          <maia-table-cell
            class="b2b-kdl-font-small subtitle-light-color"
            *ngFor="let column of columnHeaders; let i = index"
          >
            {{ column }}
          </maia-table-cell>
          <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall>
            <div> &nbsp; </div>
          </maia-table-cell>
        </maia-table-row>
      </maia-table-header>
      <maia-table-body>
        <maia-table-row class="no-property-features-added" *ngIf="featureDetailsList.length <= 0"
          ><maia-table-cell>No features added</maia-table-cell>
        </maia-table-row>
        <maia-table-row
          class="blue-bg-table-row"
          *ngFor="let featureDetails of featureDetailsList; let i = index"
        >
          <maia-table-cell>
            <maia-inline-action
              class="b2b-kdl-font-small property-type-link"
              type="button"
              (click)="addEditFeature(featureDetails, i)"
              >{{ featureDetails.propertyType }}
            </maia-inline-action>
          </maia-table-cell>
          <maia-table-cell>
            {{
              null != featureDetails.areaOfProperty
                ? (featureDetails.areaOfProperty | atlasDecimal) + ' Sq. mt'
                : '-'
            }}
          </maia-table-cell>
          <maia-table-cell>
            {{
              null != featureDetails.yearOfConstruction ? featureDetails.yearOfConstruction : '-'
            }}
          </maia-table-cell>
          <maia-table-cell>
            {{
              null != featureDetails.isPropertyCoOwnedByBank
                ? true === featureDetails.isPropertyCoOwnedByBank
                  ? 'Yes'
                  : 'No'
                : '-'
            }}
          </maia-table-cell>
          <maia-table-cell>
            {{ null != featureDetails.bank ? featureDetails.bank : '-' }}
          </maia-table-cell>
          <maia-table-cell>
            {{
              null != featureDetails.amountOwedToTheBank
                ? (featureDetails.amountOwedToTheBank | atlasDecimal)
                : '-'
            }}
          </maia-table-cell>
          <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall>
            <div class="delete-icon">
              <svg b2bFrontendActionFuncBin (click)="removeProperty(i, true)"></svg>
            </div>
          </maia-table-cell>
        </maia-table-row>
      </maia-table-body>
    </maia-table>
  </maia-fluid-fluid-layout>
</div>

<div class="save-details-button-container">
  <div class="add-new-icon">
    <svg b2bFrontendActionFuncPlusCircle></svg>
  </div>

  <div class="add-new-button">
    <button
      maiaButton
      maiaButtonType="tertiary"
      (click)="saveAddressDetails(-1)"
      [disabled]="isSaveAddressBtnDisabled()"
    >
      Save details for this address
    </button>
  </div>
</div>

<maia-fluid-fluid-layout>
  <div *ngFor="let propertyAddressDetails of propertyAddressDetailsList; let i = index">
    <maia-tile class="m-t-24 saved-address-tile" withStamp>
      <maia-stamp type="highlight" class="stamp" darkStamp
        >Features and Household Property Included</maia-stamp
      >
      <maia-tile-items>
        <maia-tile-item class="total-properties">
          {{ mainPropertyType }} +
          {{
            propertyAddressDetails.propertyDetailsList.length +
              propertyAddressDetails.featureDetailsList.length
          }}
          Properties
        </maia-tile-item>
        <maia-tile-item class="property-type">
          {{ isImmovableProperty ? 'Immovable Property' : 'Movable Property' }}
        </maia-tile-item>
        <maia-tile-item class="insured-party">
          {{ propertyAddressDetails.insurancePartyDetails.firstNameEn }}
          {{ propertyAddressDetails.insurancePartyDetails.middleNameEn }}
          {{ propertyAddressDetails.insurancePartyDetails.lastNameEn }}
        </maia-tile-item>
        <maia-tile-item class="insured-party">
          ( {{ propertyAddressDetails.insurancePartyDetails.idNumber }} )
        </maia-tile-item>
        <maia-tile-item class="address">
          {{ propertyAddressDetails.insurancePartyDetails.streetNumberEn }},
          {{ propertyAddressDetails.insurancePartyDetails.streetEn }},
          {{ propertyAddressDetails.insurancePartyDetails.quarterEn }},
        </maia-tile-item>
        <maia-tile-item class="address">
          {{ propertyAddressDetails.insurancePartyDetails.cityEn.city }},
          {{ propertyAddressDetails.insurancePartyDetails.postCodeEn }}
        </maia-tile-item>
        <maia-tile-item class="properties-details-link">
          <div class="button-align m-t-8">
            <button
              maiaButton
              maiaButtonType="tertiary"
              (click)="seePropertiesIncluded(propertyAddressDetails)"
              >See Properties Included</button
            >
          </div>
        </maia-tile-item>
        <maia-tile-item>
          <div class="button-align m-t-8">
            <button
              *ngIf="propertyAddressDetails.isAddressDetailsEditable"
              maiaButton
              class="m-r-16"
              maiaButtonType="secondary"
              (click)="editAddressDetails(propertyAddressDetails)"
              >Edit</button
            >
            <button
              *ngIf="!propertyAddressDetails.isAddressDetailsEditable"
              maiaButton
              class="m-r-16"
              maiaButtonType="primary"
              (click)="saveAddressDetails(i)"
              >Save</button
            >
            <button
              maiaButton
              maiaButtonType="tertiary-delete"
              (click)="removeAddressDetails(propertyAddressDetails)"
              >Remove</button
            ></div
          >
        </maia-tile-item>
      </maia-tile-items>
      <div class="upload-icon">
        <svg b2bFrontendNavigationFuncHome></svg>
      </div>
    </maia-tile>
  </div>
</maia-fluid-fluid-layout>

<maia-divider></maia-divider>
<button
  class="m-t-b-24"
  maiaButton
  maiaButtonType="primary"
  (click)="saveAndContinue()"
  [disabled]="!isAddressSaved!"
  >Continue</button
>
