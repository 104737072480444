import {Offices, PolicyLength, TariffRate, VehiclePurpose} from '../../../models/PolicyFormModel';

// TODO: This is temporary file and it will be removed during the API integration
export interface RadioButtonDto {
  id: number;
  name: string;
}

export interface SteeringWheelSideList {
  id: number;
  name: string;
}

export interface MockTariffRate {
  id: number;
  tariffRate: string;
}

export interface MockAgencyCode {
  id: number;
  agencyCode: string;
}

export const MOCK_POLICY_LENGTH: PolicyLength[] = [
  {
    duration: 30,
    dimension: 'Days',
  },
];

export const MOCK_DRVING_OUTSIDE_BULGARIA: RadioButtonDto[] = [
  {
    id: 1,
    name: 'Yes',
  },
  {
    id: 2,
    name: 'No',
  },
];

export const MOCK_PURPOSE_OF_VEHICLE_SERVE: VehiclePurpose[] = [
  {
    id: 1,
    name: 'Personal Use',
  },
  {
    id: 2,
    name: 'Official Use',
  },
];

export const STEERING_WHEEL_SIDE: SteeringWheelSideList[] = [
  {
    id: 1,
    name: 'Left Side',
  },
  {
    id: 2,
    name: 'Right Side',
  },
];

export const MOCK_OFFICE: Offices[] = [
  {
    id: '1',
    name: 'Main Agency Blagoevgrad',
  },
  {
    id: '2',
    name: 'Main',
  },
];

export const MOCK_TARIFF_RATE: TariffRate[] = [
  {
    id: '1',
    name: 'Fixed Premium',
  },
];

export const MOCK_AGENCY_CODE: MockAgencyCode[] = [
  {
    id: 1,
    agencyCode: 'test',
  },
  {
    id: 2,
    agencyCode: 'test1',
  },
];
