import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationFuncBookStackSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendCommunicationFuncBookStack]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M14.59 4.23v5.18"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M17.18 9.41v5.18"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M14.59 14.59v5.18"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M2.5 4.23h16.41v5.18H2.5z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M5.09 9.41H21.5v5.18H5.09z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M2.5 14.59h16.41v5.18H2.5z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 24 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationFuncBookStackSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationFuncBookStackSvgComponent,
  ],
  exports: [
    CommunicationFuncBookStackSvgComponent,
  ],
})
export class CommunicationFuncBookStackSvgModule {}
