import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationFuncPlaneSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationFuncPlane]',
  template: '<svg:g><svg:path d="M11.28 13.42l-1.39 6.91h2.68l3.56-8.52" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M15.16 8.37l-7-4.7-2.39.79 3.08 3.65" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M21.41 8.16a1.79 1.79 0 0 0-2.24-1.11h0L6.86 11.11 4.48 9.74l-2 .68 1.92 3.67h4.9l11-3.66a1.78 1.78 0 0 0 1.11-2.23z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationFuncPlaneSvgComponent {
}

@NgModule({
  declarations: [
    TransportationFuncPlaneSvgComponent,
  ],
  exports: [
    TransportationFuncPlaneSvgComponent,
  ],
})
export class TransportationFuncPlaneSvgModule {}
