import {Injectable, InjectionToken, Inject} from '@angular/core';
import {Router} from '@angular/router';

export const BASE_ROUTE = new InjectionToken<string>('@b2b-frontend-flows/cms base route');

@Injectable({
  providedIn: 'root',
})
export class StarterService {
  public constructor(
    @Inject(BASE_ROUTE) private readonly _baseRoute: string,
    private readonly _router: Router,
  ) {}

  public start(): void {
    void this._router.navigate([this._baseRoute]);
  }
}
