<maia-label>
  <label class="b2b-kdl-font-small title-dark-color"> Insuring Party Information </label>
</maia-label>
<maia-fluid-fluid-layout alignContentTop>
  <div class="m-b-24 m-r-16">
    <insurance-party-details></insurance-party-details>
  </div>
</maia-fluid-fluid-layout>

<maia-divider></maia-divider>

<maia-fluid-fluid-layout alignContentTop>
  <div class="fluid m-r-16">
    <maia-checkbox
      [(ngModel)]="isAddedToInsuredPartyList"
      class="discounts-tile-checkbox"
      [disabled]="!isInsurancePartyInfoValid()"
      (ngModelChange)="addToInsuredPartyList()"
    >
      <maia-label class="discount-tile-content-title"
        >Add Insuring Party to the Insured Party List</maia-label
      >
    </maia-checkbox>
  </div>
</maia-fluid-fluid-layout>

<maia-divider></maia-divider>

<div class="header-info">
  <div class="page-info b2b-kdl-font-small title-dark-color"> Insured Party Information </div>

  <div class="upload-icon">
    <svg b2bFrontendFileExprDocumentUpload></svg>
  </div>

  <div class="upload-button">
    <button
      maiaButton
      maiaButtonType="tertiary"
      [disabled]="(!isAddedToInsuredPartyDisabled | atlasBoolean) || true"
      >Upload file</button
    >
  </div>

  <div class="add-new-icon">
    <svg b2bFrontendActionFuncPlusCircle (click)="addNewPerson()"></svg>
  </div>

  <div class="add-new-button">
    <button maiaButton maiaButtonType="tertiary" (click)="addNewPerson()">Add New</button>
  </div>
</div>

<maia-fluid-fluid-layout alignContentTop>
  <maia-table>
    <maia-table-header>
      <maia-table-row>
        <maia-table-cell
          class="b2b-kdl-font-small-bold subtitle-light-color"
          *ngFor="let column of columnHeaders; let i = index"
        >
          {{ column }}
        </maia-table-cell>
        <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall>
          <div> &nbsp; </div>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-header>
    <maia-table-body>
      <maia-table-row class="no-insured-party-added" *ngIf="insurancePartyDetailsList.length <= 0"
        ><maia-table-cell class="b2b-kdl-font-small">No insured party added</maia-table-cell>
      </maia-table-row>
      <maia-table-row
        *ngFor="let insurancePartyDetails of insurancePartyDetailsList; let i = index"
      >
        <maia-table-cell>
          <maia-inline-action
            *ngIf="!insurancePartyDetails.isInsuringParty"
            class="b2b-kdl-font-small property-type-link"
            type="button"
            (click)="editPerson(insurancePartyDetails, i)"
            >{{ insurancePartyDetails?.idNumber }}
          </maia-inline-action>
          <div *ngIf="insurancePartyDetails.isInsuringParty">{{
            insurancePartyDetails?.idNumber
          }}</div>
        </maia-table-cell>
        <maia-table-cell>
          {{
            insurancePartyDetails?.companyNameEn
              ? insurancePartyDetails?.companyNameEn
              : insurancePartyDetails?.firstNameEn +
                ' ' +
                insurancePartyDetails?.middleNameEn +
                ' ' +
                insurancePartyDetails?.lastNameEn
          }}
        </maia-table-cell>
        <maia-table-cell>
          {{ insurancePartyDetails?.streetNumberEn }}, {{ insurancePartyDetails?.streetEn }},
          {{ insurancePartyDetails?.quarterEn }}, {{ insurancePartyDetails?.cityEn.title }}
        </maia-table-cell>
        <maia-table-cell>
          {{ insurancePartyDetails.phoneNumber | atlasPhoneNumber }}
        </maia-table-cell>
        <maia-table-cell>
          {{ insurancePartyDetails?.emailId }}
        </maia-table-cell>
        <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall>
          <div class="delete-icon">
            <svg b2bFrontendActionFuncBin (click)="removePerson(i)"></svg>
          </div>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
</maia-fluid-fluid-layout>

<maia-divider></maia-divider>

<maia-pagination
  [(page)]="page"
  [lastPage]="lastPage()"
  [totalPages]="pages"
  (pageChange)="pageChange($event)"
  general
></maia-pagination>

<button
  *ngIf="!(step.inEdit | async)"
  class="m-t-b-24"
  maiaButton
  maiaButtonType="primary"
  [disabled]="!isInsurancePartyInfoValid() && insurancePartyDetailsList.length >= 1"
  [disabled]="QuarterandStreetValid()"
  (click)="saveInsuranceData()"
  >Continue</button
>
<div *ngIf="step.inEdit | async">
  <button class="m-r-24 m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveEdit()"
    >Save Changes</button
  >
  <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
</div>
