import {Component, OnInit} from '@angular/core';

interface Menu {
  id: string;
  menuName: string;
  menuLink: string;
  iconName?: string;
}

@Component({
  selector: 'dashbord',
  templateUrl: './dashbord.component.html',
  styleUrls: ['./dashbord.component.scss'],
})
export class DashbordComponent implements OnInit {
  public menus: Menu[] = [
    {menuLink: './home', menuName: 'Home', id: '1'},
    {menuLink: './payments', menuName: 'Payments', id: '2'},
    {menuLink: './financialreporting', menuName: 'Financial Reporting', id: '3'},
    {menuLink: './reporting', menuName: 'Reporting', id: '4'},
    {menuLink: './policies', menuName: 'Policies', id: '5'},
    {menuLink: './requests', menuName: 'Requests', id: '6'},
    {menuLink: './issuecertificates', menuName: 'Issue Certificates', id: '7'},
    {menuLink: './annualtechnicalinspection', menuName: 'Annual Technical Inspection', id: '8'},
    {menuLink: './findgarages', menuName: 'Find Garages', id: '9'},
    {menuLink: './documents', menuName: 'Documents', id: '10'},
    {menuLink: './admin/uam', menuName: 'User Management', id: '11'},
    {menuLink: './admin/cms/resource', menuName: 'Resource Management', id: '12'},
    {menuLink: './admin/cms/statistics', menuName: 'Statistics Reporting', id: '13'},
    {menuLink: './admin/auth', menuName: 'Login', id: '14'},
  ];

  constructor() {}

  ngOnInit(): void {}
}
