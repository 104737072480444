/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Logger} from '@atlas/logger';
import {localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {SlideInController} from '@maia/slide-ins';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {Language} from '../../../constants/lang';
import {
  CONTACT_TYPE,
  EDIT_CONTACT,
  POPUP_PROPERTY,
} from '../../../constants/resource-management/contacts';
import {Contact} from '../../../contracts/contactsInterface';
import {ContactsService} from '../../../services/contact.service';
import {AddContactSlideInComponent} from './add-contact-slide-in/add-contact-slide-in.component';
import {RemovePopupComponent} from './remove-popup/remove-popup.component';
import {UrlEditSlideinComponent} from './url-edit-slidein/url-edit-slidein.component';

@Component({
  selector: 'nje-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
@UntilDestroy()
export class ContactsComponent implements OnInit, OnDestroy {
  public showLoadingIcon: boolean = false;
  public contactUrls: Contact[];
  public url?: string;
  public urlObj: Contact;
  public contacts: Contact[];
  public slideinTitle: string;
  public technicalSupports: Contact[];
  public contactIndex: number;
  public incidentIndex: number;
  public subscription: Subscription;
  public contactId: number;
  public incidentId: number;
  public removeType: string;
  public lang: string | null;

  private readonly _logger: Logger;
  private readonly _urlEditFactory: ComponentFactory<UrlEditSlideinComponent>;
  private readonly _addContactFactory: ComponentFactory<AddContactSlideInComponent>;
  private readonly _removePopupFactory: ComponentFactory<RemovePopupComponent>;

  public forkJoinedval: Observable<any>;

  public constructor(
    private readonly _popUpCtrl: PopUpController,
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _contactService: ContactsService,
    private readonly _changeDetection: ChangeDetectorRef,
    private readonly _slideInCtrl: SlideInController,
  ) {
    this._logger = loggerFactory.createLogger('PopUpsComponent');
    this._urlEditFactory =
      componentFactoryResolver.resolveComponentFactory(UrlEditSlideinComponent);
    this._addContactFactory = componentFactoryResolver.resolveComponentFactory(
      AddContactSlideInComponent,
    );

    this._removePopupFactory =
      componentFactoryResolver.resolveComponentFactory(RemovePopupComponent);
  }

  public ngOnInit(): void {
    this.lang = sessionStorage.getItem('lang');
    this._getContactsFromPortalDB();
    this.subscription = this._contactService.emitter.subscribe((value: boolean) => {
      if (this.removeType === CONTACT_TYPE.CONTACT) {
        this.removeContact(this.contactIndex, this.contactId);
        this.removeType = CONTACT_TYPE.BLANK_VALUE;
      } else if (this.removeType === CONTACT_TYPE.INCIDENT) {
        this.removeIncident(this.incidentIndex, this.incidentId);
        this.removeType = CONTACT_TYPE.BLANK_VALUE;
      }
    });
  }

  public isLanguageEnglish(): boolean {
    return this.lang === Language.English;
  }

  private _getContactsFromPortalDB(): void {
    this.showLoadingIcon = true;
    this._contactService
      .getContactsList()
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: Contact[]) => {
            this.contacts = result.filter(
              contact => contact.type?.toLowerCase() === CONTACT_TYPE.CONTACT.toLowerCase(),
            );
            this.technicalSupports = result.filter(
              contact => contact.type?.toLowerCase() === CONTACT_TYPE.INCIDENT.toLowerCase(),
            );
            this.contactUrls = result.filter(
              contact => contact.type?.toLowerCase() === CONTACT_TYPE.CONTACT_URL.toLowerCase(),
            );
            if (this.contactUrls.length > 0 && this.contactUrls[0].url !== undefined) {
              this.urlObj = this.contactUrls[0];
              this.url = this.contactUrls[0].url || CONTACT_TYPE.BLANK_VALUE;
            }
            this.showLoadingIcon = false;
            this._changeDetection.detectChanges();
          },
          (error: any) => {
            console.log(error);
            this.showLoadingIcon = false;
          },
        ),
      )
      .subscribe();
  }

  public removeContact(index: number, id: number): void {
    this.contacts.splice(index, 1);
    void this._contactService.deleteContact(id).toPromise();
    this._changeDetection.detectChanges();
  }

  public removeIncident(index: number, id: number): void {
    this.technicalSupports.splice(index, 1);
    void this._contactService.deleteContact(id).toPromise();
    this._changeDetection.detectChanges();
  }

  public openRemovePopup(index: number, id: number, type: string): void {
    this.removeType = type;
    if (type === CONTACT_TYPE.CONTACT) {
      this.contactIndex = index;
      this.contactId = id;
    } else if (type === CONTACT_TYPE.INCIDENT) {
      this.incidentId = id;
      this.incidentIndex = index;
    }
    this._popUpCtrl
      .prepare(
        this._removePopupFactory,
        this._injector,
        {
          title: new localeValPipe(new LocalizationService()).transform(
            POPUP_PROPERTY.TITLE,
            this.lang,
          ),
          size: 'large',
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
        },
      )
      .subscribe({error: this._logger.error});
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public editUrl(): void {
    this.urlEditSlideIn(this.urlObj);
  }

  // slideIn to Edit Url
  public urlEditSlideIn(Contact?: Contact): void {
    this._slideInCtrl
      .prepare(
        this._urlEditFactory,
        this._injector,
        {title: new localeValPipe(new LocalizationService()).transform(EDIT_CONTACT, this.lang)},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: Contact,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<Contact>) => {
        this._contactService.updateEditedUrl(res.result, this.urlObj.id).subscribe(() => {
          this._getContactsFromPortalDB();
        });
        this._changeDetection.detectChanges();
      });
  }

  // slideIn to Add Contact
  public addContact(type?: string, contact?: Contact, index?: number): void {
    if (type === CONTACT_TYPE.INCIDENT) {
      this.slideinTitle = new localeValPipe(new LocalizationService()).transform(
        contact ? 'Edit Incident' : 'Add Incident',
        this.lang,
      );
    } else {
      this.slideinTitle = new localeValPipe(new LocalizationService()).transform(
        contact ? 'Edit Contact' : 'Add Contact',
        this.lang,
      );
    }
    this._slideInCtrl
      .prepare(
        this._addContactFactory,
        this._injector,
        {title: this.slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: [contact, type],
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<Contact[]>) => {
        if (
          response.resolution === ModalResolution.CONFIRMED &&
          response.result[0].id !== undefined
        ) {
          // update scenario
          this.contacts[index!] = response.result[0];
          this._contactService.editContact(response.result[0]).subscribe();
        } else if (
          response.resolution === ModalResolution.CONFIRMED &&
          (response.result[0].id === undefined || response.result[0].id === null)
        ) {
          //add scenario
          const contacts = response.result.map(contact =>
            this._contactService.addContacts(contact),
          );
          forkJoin(contacts)
            .pipe(catchError(err => of(err)))
            .subscribe(() => {
              this._getContactsFromPortalDB();
            });
        }
        this._changeDetection.detectChanges();
      });
  }
}
