import {mockData} from './data-mockInterface';

export const policyLenMock: mockData[] = [
  {
    id: 1,
    name: '12 Months',
  },
  {
    id: 2,
    name: '24 Months',
  },
  {
    id: 3,
    name: '36 Months',
  },
  {
    id: 4,
    name: '48 Months',
  },
  {
    id: 5,
    name: '60 Months',
  },
  {
    id: 6,
    name: '72 Months',
  },
  {
    id: 7,
    name: 'Custom Duration',
  },
];

export const installmentsMock: mockData[] = [
  {
    id: 1,
    name: 'Single Payment',
  },
  {
    id: 2,
    name: 'Double Payment',
  },
  {
    id: 3,
    name: '4 Months Payment',
  },
  {
    id: 4,
    name: '12 Months Payment',
  },
];

export const secureDeviceMock: mockData[] = [
  {
    id: 1,
    name: 'Secure Device 1',
  },
  {
    id: 2,
    name: 'A Secure Device is Installed',
  },
];

export const originCountryMock: mockData[] = [
  {
    id: 1,
    name: 'India',
  },
  {
    id: 2,
    name: 'Bulgaria',
  },
  {
    id: 3,
    name: 'Germany',
  },
  {
    id: 4,
    name: 'USA',
  },
];

export const claimTypeMock: mockData[] = [
  {
    id: 1,
    name: 'Accident',
  },
  {
    id: 2,
    name: 'Severly Damaged',
  },
];

export const agencyMock: mockData[] = [
  {
    id: 1,
    name: '7376354378',
  },
];

export const sellerCodeMock: mockData[] = [
  {
    id: 1,
    name: '484836333',
  },
];

export enum RadioDefaults {
  NO = 'no',
}

export enum PolicyLengths {
  CUSTOM = 'Custom Duration',
  TWELVE = '12 Months',
  TWENTY4 = '24 Months',
  THIRTY6 = '36 Months',
  FOURTY8 = '48 Months',
  SIXTY = '60 Months',
  SEVENTY2 = '72 Months',
}
