import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialFuncPersonArrowInSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendSocialFuncPersonArrowIn]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M8.83 7.91V7c.02-2.49 2.04-4.5 4.53-4.5h0c2.49 0 4.51 2.01 4.52 4.5v.93c0 2.5-2.02 5.43-4.52 5.43h0c-2.5 0-4.53-2.93-4.53-5.45z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M6.12 16.07l2.71 2.72-2.71 2.71"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M8.83 18.79H2.5"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M11.5 20.6h10V17c0-1.16-.73-2.19-1.83-2.56-2.03-.7-4.16-1.06-6.31-1.08-1.53.01-3.05.2-4.53.57"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 24 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialFuncPersonArrowInSvgComponent {
}

@NgModule({
  declarations: [
    SocialFuncPersonArrowInSvgComponent,
  ],
  exports: [
    SocialFuncPersonArrowInSvgComponent,
  ],
})
export class SocialFuncPersonArrowInSvgModule {}
