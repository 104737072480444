import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationFuncPhoneSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendCommunicationFuncPhone]',
  template: '<svg:path d="M15.14 14.09l-2 2a16.48 16.48 0 0 1-2.94-2.25A17.07 17.07 0 0 1 8 10.9l2-2a.87.87 0 0 0 .18-1L8 3a.89.89 0 0 0-1-.5l-3.84 1a.9.9 0 0 0-.65.87 18.36 18.36 0 0 0 5.21 11.9 18.31 18.31 0 0 0 11.86 5.23.87.87 0 0 0 .87-.65l1-3.83a.86.86 0 0 0-.5-1l-4.85-2.11a.87.87 0 0 0-1 .18z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationFuncPhoneSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationFuncPhoneSvgComponent,
  ],
  exports: [
    CommunicationFuncPhoneSvgComponent,
  ],
})
export class CommunicationFuncPhoneSvgModule {}
