import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PAGINATION} from '@b2b-frontend/core';

import {
  B2B_MODULE_TABLE,
  ModulesList,
  ModuleTableColumnHeadings,
  PAGINATION_CONSTANTS,
} from '../../../../constants/groups-and-modules';
import {ModulesDetails} from '../../../../models/b2b/ModulesDetails';

@Component({
  selector: 'nje-modules-table',
  templateUrl: './modules-table.component.html',
  styleUrls: ['./modules-table.component.scss'],
})
export class ModulesTableComponent implements OnInit {
  @Output()
  addModuleEmitter = new EventEmitter<ModulesDetails>();

  public currentPageNumber = PAGINATION.DEFAULT_PAGE;
  public totalPages = PAGINATION_CONSTANTS.TOTAL_PAGES;
  public tableRows: number[] = [];
  public tableColumns: number[] = [];
  public modulesList: ModulesDetails[];
  public columnHeadings: string[] = ModuleTableColumnHeadings;

  public ngOnInit(): void {
    this.initializeDefaultTable();
    this._getModulesList();
  }

  public initializeDefaultTable(): void {
    this._tableArray(this.tableRows, B2B_MODULE_TABLE.DEFAULT_COMPLEX_ROWS);
    this._tableArray(this.tableColumns, B2B_MODULE_TABLE.DEFAULT_COMPLEX_COLUMNS);
  }

  private _tableArray(simple: number[], numElements: number): void {
    for (let i = 0; i < numElements; i++) {
      simple.push(i);
    }
  }

  public addModule(module: ModulesDetails): void {
    this.addModuleEmitter.emit(module);
  }

  private _getModulesList(): void {
    this.modulesList = ModulesList;
  }

  public pageChange(event: number): void {
    this._getModulesList();
  }

  public lastPage(): boolean {
    return this.currentPageNumber >= this.totalPages;
  }
}
