import {CascoWorkflow} from './CascoWorkflow';
import {CascoRoutePaths} from '../../constants/route-paths';

export const workflows: {[name: string]: string[]} = {
  [CascoWorkflow.QUICK_QUOTE]: [
    CascoRoutePaths.CI_JOURNEY_CHOICE,
    CascoRoutePaths.CI_VEHICLE_AND_OWNER_DETAILS,
    CascoRoutePaths.CI_COVERS,
    CascoRoutePaths.CI_PREMIUM,
    CascoRoutePaths.CI_PROPOSAL,
  ],
  [CascoWorkflow.PRICE_OFFER]: [
    CascoRoutePaths.CI_JOURNEY_CHOICE,
    CascoRoutePaths.CI_PRICE_VEHICLE_DETAILS,
    CascoRoutePaths.CI_PRICE_SUM_INSURED,
    CascoRoutePaths.CI_PRICE_OWNER_AND_INSURER_DETAILS,
    CascoRoutePaths.CI_PRICE_POLICY_DETAILS,
    CascoRoutePaths.CI_PRICE_COVERS_AND_ADDONS,
    CascoRoutePaths.CI_PRICE_PREMIUM,
    CascoRoutePaths.CI_PRICE_PROPOSAL,
  ],
};
