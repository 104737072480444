import {AbstractControl, ValidationErrors} from '@angular/forms';

export function nonLatinLetters(control: AbstractControl): ValidationErrors | null {
  const inputText = control.value === null || control.value === undefined ? '' : control.value;
  const isValid = /[a-zA-Z]/.test(inputText) === false;
  if (!isValid || inputText.indexOf('Є') > -1) {
    return {
      nonLatinLetters: true,
    };
  } else {
    return null;
  }
}
