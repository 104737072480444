import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {UserActivateDeactivatePopup as B2BUserActivateDeactivatePopup} from '../models/b2b/UserActivateDeactivatePopup';
import {UserActivateDeactivatePopup as B2CUserActivateDeactivatePopup} from '../models/UserActivateDeactivatePopup';

@Injectable({
  providedIn: 'root',
})
export class AccountActivateDeactivateService {
  public popupDetails = new BehaviorSubject<any>({});

  public constructor() {}

  public setActivatePopupDetails(
    popupDetails: B2CUserActivateDeactivatePopup | B2BUserActivateDeactivatePopup,
  ): void {
    this.popupDetails.next(popupDetails);
  }

  public getActivatePopupDetails(): Observable<
    B2CUserActivateDeactivatePopup | B2BUserActivateDeactivatePopup
  > {
    return this.popupDetails.asObservable();
  }

  public setDeactivatePopupDetails(
    popupDetails: B2CUserActivateDeactivatePopup | B2BUserActivateDeactivatePopup,
  ): void {
    this.popupDetails.next(popupDetails);
  }

  public getDeactivatePopupDetails(): Observable<
    B2CUserActivateDeactivatePopup | B2BUserActivateDeactivatePopup
  > {
    return this.popupDetails.asObservable();
  }
}
