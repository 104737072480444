import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncStarListSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncStarList]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M9.74 18.38l-3.8 1.99 1.08-6.24L2.5 9.78l6.24-.99 2.81-5.61 2.8 5.61 6.24.99-2.71 2.63"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M21.5 20.82h-8.14"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M21.5 16.3h-8.14"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncStarListSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncStarListSvgComponent,
  ],
  exports: [
    InformationFuncStarListSvgComponent,
  ],
})
export class InformationFuncStarListSvgModule {}
