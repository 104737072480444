import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';

import {AccountOperationsComponent} from './account-operations/account-operations.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'account-operations',
      },
      {
        path: 'account-operations',
        component: AccountOperationsComponent,
        data: {
          njeB2CTitle: 'User management',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}

export const HOME_ROUTE: Route = {
  path: '',
  loadChildren: async () => await import('./home.module').then(module => module.HomeModule),
};
