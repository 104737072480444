import {Component} from '@angular/core';

@Component({
  selector: 'account-creation',
  templateUrl: './account-creation.component.html',
  styleUrls: ['./account-creation.component.scss'],
})
export class AccountCreationComponent {
  public accountType: string;
}
