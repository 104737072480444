import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialExprPeopleSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendSocialExprPeople]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M24 13.6c0-3.977 3.223-7.2 7.2-7.2s7.2 3.223 7.2 7.2-3.223 8.8-7.2 8.8-7.2-4.823-7.2-8.8z" stroke-width="1.6"></svg:path><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M25.6 27.174c1.524-.456 3.382-.774 5.6-.774 3.989 0 6.606 1.029 8.218 2.042 1.376.866 2.182 2.4 2.182 4.025V41.6H31.2" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M9.6 23.2a6.4 6.4 0 0 1 12.8 0c0 3.534-2.866 7.2-6.4 7.2s-6.4-3.666-6.4-7.2z" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M25.6 38.795c0-1.302-.782-2.48-1.99-2.964-1.664-.665-4.297-1.431-7.61-1.431s-5.946.766-7.61 1.431a3.185 3.185 0 0 0-1.99 2.964V41.6h19.2v-2.805z" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialExprPeopleSvgComponent {
}

@NgModule({
  declarations: [
    SocialExprPeopleSvgComponent,
  ],
  exports: [
    SocialExprPeopleSvgComponent,
  ],
})
export class SocialExprPeopleSvgModule {}
