import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprFastlaneSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprFastlane]',
  template: '<svg:path d="M21.7 26.4l-4.4 13.3-4.5-1.5 2.8-8.4 4.3 1.4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M31.7 26.8l5.1 4.5a1.7 1.7 0 0 1 .5 1.2v6.1a1.7 1.7 0 0 1-1.7 1.7h0a1.7 1.7 0 0 1-1.7-1.7h0v-5.4l-3.5-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:circle cx="32.6" cy="10.6" r="3.6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle><svg:path d="M27 21.3l-1.9.2-1.1 3.3a1.8 1.8 0 0 1-2.2 1.1h0a1.8 1.8 0 0 1-1.1-2.2h0l1.8-4.7a1.69 1.69 0 0 1 1.2-1l5.9-1.3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M43.4 21.6a1.6 1.6 0 0 0-1.9-1.22h-.1 0l-3.8 1-5.3-4.3a3.1 3.1 0 0 0-4.3.6 4.54 4.54 0 0 0-.6 1.5L24.6 38.4a1.6 1.6 0 0 0 1.24 1.89H26a1.79 1.79 0 0 0 1.9-1.1l5-16.9 3.6 2.2a2.47 2.47 0 0 0 1.7.3l4.1-1a2.19 2.19 0 0 0 1.1-2.19z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M11.3 7.5h16"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M9 15.7h12"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5 24.4h8"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprFastlaneSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprFastlaneSvgComponent,
  ],
  exports: [
    InformationExprFastlaneSvgComponent,
  ],
})
export class InformationExprFastlaneSvgModule {}
