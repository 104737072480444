import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {LabelEditDto} from '../../core-helper/dto/LabelEditDto';

@Component({
  selector: 'label-with-edit',
  templateUrl: './label-with-edit.component.html',
  styleUrls: ['./label-with-edit.component.scss'],
})
export class LabelWithEditComponent implements OnInit {
  @Input() public nameEditPath: LabelEditDto;

  @Output() public editScreen = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public emitEditFunction(): void {
    this.editScreen.emit(this.nameEditPath);
  }
}
