/* eslint-disable @typescript-eslint/no-unused-expressions*/
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

const registrationNumberPatterns: RegExp[] = [
  /^[A-Za-z]{1,2}\s?\d{4}\s?[A-Za-z]{1,2}$/, // normal
  /^(C|CC|CT|c|cc|ct)\s?\d{4}$/, // diplomacy
  /^(CP|cp)\s?\d{2}\s?\d{3}$/, // civil safety
  /^(BA|ba)\s?\d{6}$/, // military
  /^(XX|XH|xx|xh)\s?\d{4}$/, // other special
];

export function vehicleRegistrationNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;
    const result: ValidationErrors | null = null;
    if (value && registrationNumberPatterns.findIndex(regexp => regexp.exec(value)) === -1) {
      return {invalidNumber: true};
    }
    return result;
  };
}
