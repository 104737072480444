import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncPinSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncPin]',
  template: '<svg:path d="M12 2.4c-3.52 0-7.2 2.72-7.2 7.2 0 4.24 6.4 10.72 6.64 10.96.16.16.32.24.56.24s.4-.08.56-.24c.24-.24 6.64-6.72 6.64-10.96 0-4.48-3.68-7.2-7.2-7.2zm0 9.6c-1.36 0-2.4-1.04-2.4-2.4s1.04-2.4 2.4-2.4 2.4 1.04 2.4 2.4S13.36 12 12 12z"></svg:path>',

  styles: [
    ':host { fill: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncPinSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncPinSvgComponent,
  ],
  exports: [
    InformationFuncPinSvgComponent,
  ],
})
export class InformationFuncPinSvgModule {}
