<header-with-breadcrumbs title="MTPL"></header-with-breadcrumbs>
<div class="max-width-body; page-align">
  <div class="m-t-24" *ngIf="isStepperVisible">
    <maia-expanded-flow-progress>
      <maia-expanded-flow-progress-step
        *ngFor="let step of steps | async"
        [title]="step.title"
        [routeId]="step.id"
        [route]="step.routePath"
        [done]="true"
      >
      </maia-expanded-flow-progress-step>
    </maia-expanded-flow-progress>
  </div>
  <div class="screen-margin">
    <router-outlet></router-outlet>
  </div>
</div>
