import {Component, Input, OnInit} from '@angular/core';

import {AccountDetailsTitles, BLANK_STRING} from '../../../constants/account-management';
import {AccountDetails} from '../../../models/AccountDetails';
import {UserAccountDetails} from '../../../models/UserAccountDetails';

@Component({
  selector: 'nje-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  public accountDetails: AccountDetails[] = [];

  @Input()
  public set userDetails(data: UserAccountDetails) {
    this._prepareUserData(data);
  }

  public constructor() {}

  public ngOnInit(): void {}

  private _prepareUserData(userDetails: UserAccountDetails) {
    this.accountDetails = [
      {
        title: AccountDetailsTitles.ACCOUNT_NAME,
        value: `${userDetails.firstNameEn} ${userDetails.middleNameEn} ${userDetails.lastNameEn}`,
        icon: BLANK_STRING,
      },
      {
        title: AccountDetailsTitles.EGN_LNCH_EIK,
        value: userDetails.pin,
        icon: BLANK_STRING,
      },
      {
        title: AccountDetailsTitles.BIRTH_DATE,
        value: userDetails.birthDate,
        icon: BLANK_STRING,
      },
      {
        title: AccountDetailsTitles.CITY,
        value: userDetails.city,
        icon: BLANK_STRING,
      },
      {
        title: AccountDetailsTitles.ADDRESS,
        value: `${userDetails.address.apartment}, ${userDetails.address.street}`,
        icon: BLANK_STRING,
      },
      {
        title: AccountDetailsTitles.EMAIL_ID,
        value: userDetails.emailId,
        icon: BLANK_STRING,
      },
      {
        title: AccountDetailsTitles.PHONE_NUMBER,
        value: userDetails.phone,
        icon: BLANK_STRING,
      },
    ];
  }
}
