import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprTransporterSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationExprTransporter]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path class="st0" d="M12.96 27.12c1.76 0 3.28 1.44 3.28 3.28 0 1.76-1.44 3.28-3.28 3.28s-3.28-1.52-3.28-3.28c0-1.76 1.52-3.28 3.28-3.28z" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M32.48 27.44c1.6 0 2.96 1.36 2.96 2.96s-1.36 2.96-2.96 2.96-2.96-1.36-2.96-2.96 1.36-2.96 2.96-2.96z" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st1" d="M35.36 30.32h4.96c.4 0 .8-.16 1.04-.4.56-.56.88-1.28.88-2.16 0-2.48-4.24-5.36-8.56-5.36l-3.12-3.28c-1.12-1.28-2.56-2.32-4.16-3.04-1.6-.72-3.28-1.12-5.04-1.12H8.88c-3.2 0-3.2 5.04-3.2 10.08v3.52c0 .96.8 1.76 1.76 1.76h2.24m6.56 0h13.28m4.24-7.92c-1.12-.08-8.64-.32-8.64-.32-.8 0-1.44-.64-1.44-1.44l-.4-5.52" fill="none" stroke-width="1.6" stroke-miterlimit="10"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprTransporterSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprTransporterSvgComponent,
  ],
  exports: [
    TransportationExprTransporterSvgComponent,
  ],
})
export class TransportationExprTransporterSvgModule {}
