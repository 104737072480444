import {CommonModule} from '@angular/common';
import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {FormsModule as AngularFormsModule, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {B2bCoreModule} from '@b2b-frontend/core';
import {μExampleSvgModule} from '@b2b-frontend/icons';
import {AvatarsModule as MaiaAvatarsModule} from '@maia/avatars';
import {ButtonsModule as MaiaButtonsModule} from '@maia/buttons';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {LayoutModule as MaiaLayoutModule} from '@maia/layout';
import {RadioButtonsModule as MaiaRadioButtonsModule} from '@maia/radio-buttons';
import {NjfRadioButtonsModule} from '@njf-frontend-angular/radio-buttons';

import {AuthRoutingModule} from './auth-routing.module';
import {AuthComponent} from './auth.component';
import {ChangePasswordComponent} from './screens/change-password/change-password.component';
import {LoginComponent} from './screens/login/login.component';
import {SetupGoogleComponent} from './screens/setup-google/setup-google.component';
import {SuccessScreenComponent} from './screens/success-screen/success-screen.component';
import {TwoFaComponent} from './screens/two-fa/two-fa.component';
import {VerifyAuthComponent} from './screens/verify-auth/verify-auth.component';
import {BASE_ROUTE} from './services/starter.service';
import {ScanComponent} from './screens/scan/scan.component';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ChangePasswordComponent,
    SetupGoogleComponent,
    TwoFaComponent,
    VerifyAuthComponent,
    SuccessScreenComponent,
    ScanComponent,
  ],
  imports: [
    B2bCoreModule,
    CommonModule,
    AuthRoutingModule,
    AngularFormsModule,
    FormsModule,
    ReactiveFormsModule,
    MaiaFormsModule,
    MaiaButtonsModule,
    μExampleSvgModule,
    MaiaLayoutModule,
    MaiaAvatarsModule,
    NjfRadioButtonsModule,
    MaiaRadioButtonsModule,
  ],
})
export class AuthModule {
  // Static method for injecting the module with a base route provided.
  public static forPath(path: string): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error('Do not import AuthModule directly, use AuthModule.forPath() instead');
    }
  }
}
