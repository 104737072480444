import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AgentNumberOptions} from '../../../../constants/account-creation';

@Component({
  selector: 'nje-add-single-agent',
  templateUrl: './add-single-agent.component.html',
  styleUrls: ['./add-single-agent.component.scss'],
})
export class AddSingleAgentComponent implements OnInit {
  public agentForm: FormGroup;

  public value: string;
  @Output() agentNumber = new EventEmitter<string>();

  public options = AgentNumberOptions;

  public constructor(private readonly _fb: FormBuilder) {}

  public ngOnInit(): void {
    this.agentForm = this._fb.group({
      agentNumber: [undefined, [Validators.required]],
    });
    this.agentForm.controls.agentNumber.valueChanges.subscribe(value => {
      this.agentNumber.emit(value);
    });
  }
}
