/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {BLANK_VALUE, FALSE, TRUE} from '@b2b-frontend/core';
import {
  AddAdditionalCoversSlideInInput,
  AdditionalCoversAddressLevelInterface,
} from '../../contracts/property-insurance.interface';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';
import {
  ADD_BUTTON_VALUE,
  SAVE_BUTTON_VALUE,
} from '../property-cover-discount-layout/__tests__/property-covers-discount-layout.mocks';

@Component({
  selector: 'nje-property-covers-add-edit-slidein',
  templateUrl: './property-covers-add-edit-slidein.component.html',
  styleUrls: ['./property-covers-add-edit-slidein.component.scss'],
})
export class PropertyCoversAddEditSlideinComponent
  extends ModalContentComponent<
    AdditionalCoversAddressLevelInterface,
    AddAdditionalCoversSlideInInput
  >
  implements OnInit
{
  public additional_cover_list: AdditionalCoversAddressLevelInterface[] = [];
  public additionalCoversDetailsForm: FormGroupManager<AdditionalCoversAddressLevelInterface>;
  public existingCoversList: AdditionalCoversAddressLevelInterface[];
  public existingCover: Boolean = false;
  public currencySelected: string = BLANK_VALUE;
  public propertyCoversInfo: AdditionalCoversAddressLevelInterface;
  public defaultObj: AdditionalCoversAddressLevelInterface | undefined;
  public addButton: string = ADD_BUTTON_VALUE;
  public saveButton: string = SAVE_BUTTON_VALUE;

  public constructor(
    public control: ModalControl<
      AdditionalCoversAddressLevelInterface,
      AddAdditionalCoversSlideInInput
    >,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this.additional_cover_list = data.defaultObj.filter(ele => ele.default === false);
    this.propertyCoversInfo = data.propertyCoverInfo;
    if (this.propertyCoversInfo !== undefined) {
      data.defaultObj.forEach(ele => {
        if (ele.coverName === this.propertyCoversInfo.coverName) {
          this.defaultObj = ele;
        }
      });
    }
    this.additionalCoversDetailsForm = this._initializePropertyCoversSlideinForm(
      data.propertyCoverInfo,
    );
    if (this.defaultObj !== undefined) {
      this.setAggregateLimitMinMaxValue();
    }
    this.existingCoversList = data.existingPropertyCover!;
    this.currencySelected = data.currencySelected;
    this._cdRef.detectChanges();
  }

  private _initializePropertyCoversSlideinForm(
    CoversInfo?: AdditionalCoversAddressLevelInterface,
  ): FormGroupManager<AdditionalCoversAddressLevelInterface> {
    return (this.additionalCoversDetailsForm =
      this._formFactory.createFormManager<AdditionalCoversAddressLevelInterface>({
        coverName: new FormControl(CoversInfo?.coverName, [Validators.required]),
        aggregateLimit: new FormControl(CoversInfo?.aggregateLimit?.default, [
          Validators.required,
          Validators.min(100),
          Validators.max(1000),
        ]),
        limitPerOccurence: new FormControl(CoversInfo?.limitPerOccurence?.default, [
          Validators.required,
          Validators.min(100),
          Validators.max(1000),
        ]),
      }));
  }

  public addOrUpdatePropertyCovers(): void {
    const coverDetails: AdditionalCoversAddressLevelInterface = {
      coverName: this.additionalCoversDetailsForm.controls.coverName.value as string,
      aggregateLimit: {
        default: this.additionalCoversDetailsForm.controls.aggregateLimit.value as number,
      },
      limitPerOccurence: {
        default: this.additionalCoversDetailsForm.controls.limitPerOccurence.value as number,
      },
    };
    this.control.confirm(coverDetails);
  }

  public changeCoversType(): void {
    this.existingCover = false;
    this.existingCoversList.forEach(element => {
      if (element.coverName === this.additionalCoversDetailsForm.controls.coverName.value) {
        this.existingCover = true;
      } else {
        if (
          this.propertyCoversInfo !== undefined &&
          this.propertyCoversInfo.coverName ===
            this.additionalCoversDetailsForm.controls.coverName.value
        ) {
          this.existingCover = false;
        }
        this.additional_cover_list.forEach(ele => {
          if (ele.coverName === this.additionalCoversDetailsForm.controls.coverName.value) {
            this.defaultObj = ele;
            this.setAggregateLimitMinMaxValue();
            this._cdRef.detectChanges();
          }
        });
      }
    });
  }

  public setAggregateLimitMinMaxValue(): void {
    this.additionalCoversDetailsForm.controls?.aggregateLimit.setValidators([
      Validators.required,
      Validators.min(this.defaultObj!.aggregateLimit!.min!),
      Validators.max(this.defaultObj!.aggregateLimit!.max!),
    ]);
    this.additionalCoversDetailsForm.controls?.limitPerOccurence.setValidators([
      Validators.required,
      Validators.min(this.defaultObj!.limitPerOccurence!.min!),
      Validators.max(this.defaultObj!.limitPerOccurence!.max!),
    ]);
    this.additionalCoversDetailsForm.controls?.aggregateLimit.updateValueAndValidity();
    this.additionalCoversDetailsForm.controls?.limitPerOccurence.updateValueAndValidity();
  }

  public getAggregateLimitFieldErrors(): boolean {
    if (
      this.additionalCoversDetailsForm.controls?.aggregateLimit?.dirty &&
      this.additionalCoversDetailsForm.controls?.aggregateLimit?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getLimitPerOccurenceFieldErrors(): boolean {
    if (
      this.additionalCoversDetailsForm.controls?.limitPerOccurence?.dirty &&
      this.additionalCoversDetailsForm.controls?.limitPerOccurence?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public cancelSlideIn(): void {
    this.control.cancel();
  }
}
