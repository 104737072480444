<ng-container *ngIf="showTemplate">
  <div class="adjust-label">
    <maia-label class="b2b-kdl-font-medium-bold">Upload file</maia-label>
    <p class="b2b-kdl-font-normal-light">Do you have the Insure people file template ?</p>
  </div>

  <div *maiaPopUpFooter>
    <button maiaButton maiaButtonType="primary" (click)="showDownloadTemplate()">No</button>
    <button maiaButton maiaButtonType="secondary" (click)="confirmAndClose(false)">Yes</button>
  </div>
</ng-container>

<ng-container *ngIf="!showTemplate">
  <div class="adjust-label">
    <maia-label class="b2b-kdl-font-medium-bold">Download Template</maia-label>
    <p class="b2b-kdl-font-normal-light"
      >Please use the template to fill data for insured people.</p
    >
  </div>

  <div *maiaPopUpFooter>
    <button maiaButton maiaButtonType="primary" (click)="confirmAndClose(true)">Download</button>
    <button maiaButton maiaButtonType="secondary" (click)="control.cancel()">Cancel</button>
  </div>
</ng-container>
