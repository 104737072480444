/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  personName,
  companyName,
  CoreApiService,
  CitizenshipDto,
  PartyIdService,
  PartyType,
  DateFormatterService,
  MtplPersonalData,
  CompanyDataDto,
  CityApiData,
  CitizenshipEn,
  CitizenEn,
} from '@b2b-frontend/core';
import {
  ModalConfirmedResult,
  ModalContentComponent,
  ModalControl,
  ModalResolution,
} from '@maia/modals';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';
import {concatMap, map, takeUntil, tap} from 'rxjs/operators';
import {AddInsuringPartyForm} from '../../../models/AddInsuringPartyFormModel';
import {DatepickerVisibleView} from '@maia/datepickers';
import {DateKey, DateUtils, Text} from '@atlas/businesstypes';
import {MtplRelatedParty} from './_tests_/mtpl-related-party';
import {MtplInsuredParty} from '@b2b-frontend/core/src/core-helper/dto/mtpl-dto/MtplInsuringPartyDetails';
import {PopUpController} from '@maia/pop-ups';
import {RelatedPartyDeletePopupComponent} from '../delete-popup/delete-popup.component';
import {PhoneNumberValue} from '@maia/input-phonenumber';
import {
  BLANK_VALUE,
  CITY,
  COUNTRY_CODE,
  INSURING_DETAILS,
} from '../../../mtpl-constants.ts/constant';
import {ExtraTextControlValidators} from '@atlas-angular/businesstypes';
import {MtplApiService} from '../../../mtpl-core/services/mtpl-api.service';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {AsyncSubject, BehaviorSubject, combineLatest, ReplaySubject} from 'rxjs';

@Component({
  selector: 'nje-mtpl-add-insuring-party-slidein',
  templateUrl: './mtpl-add-insuring-party-slidein.component.html',
  styleUrls: ['./mtpl-add-insuring-party-slidein.component.scss'],
})
@UntilDestroy()
export class MtplAddInsuringPartySlideinComponent
  extends ModalContentComponent<MtplInsuredParty, MtplInsuredParty>
  implements OnInit, OnDestroy
{
  public inputText: Text;
  public insurancePartyType: PartyType | undefined;
  protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isLNCH: boolean = false;
  public isEGN: boolean = true;
  public isEIK: boolean = false;
  public isNewDetails: boolean = true;
  public isEditable: boolean = false;
  private readonly _popUpDeleteFactory: ComponentFactory<RelatedPartyDeletePopupComponent>;
  public textSearched: Text;
  public flexDirection: string = INSURING_DETAILS.COLUMN;
  public slideinPhoneNumber: PhoneNumberValue | string = this.control.input?.phoneNumber;
  public retrievedAddInsuringDetails: MtplPersonalData;
  public retrievedAddInsuringPartyCompanyDetails: CompanyDataDto;
  public selectedCity: CityApiData;
  public searchEgn: Text;
  public mockRelatedParty: MtplRelatedParty[] = [];
  public addInsuringPartySlideinForm: FormGroup;
  public cityArray: CityApiData[] = [];
  public personNotFound: boolean = false;
  public phone: PhoneNumberValue;
  public citizenshipOptionsFilter = new BehaviorSubject<string>('');
  public citizenshipOptionsList = new AsyncSubject<CitizenshipDto[]>();
  public citizenshipOptions: CitizenshipDto[];
  public citizenships: CitizenshipDto[] = [];
  public filteredCitizenshipOptionsList = new BehaviorSubject<CitizenshipDto[]>([]);
  public citizenshipId: string;

  public addNewInsuringForm: FormGroupManager<AddInsuringPartyForm> =
    this._formFactory.createFormManager<AddInsuringPartyForm>({
      relatedParty: new FormControl(undefined, [Validators.required]),
      idNumber: new FormControl(undefined, [
        Validators.required,
        this.idNumberValidator.bind(this),
      ]),
      firstName: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      middleName: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      lastName: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
      quarter: new FormControl(undefined),
      street: new FormControl(undefined),
      streetNumber: new FormControl(undefined),
      block: new FormControl(undefined),
      entrance: new FormControl(undefined),
      floor: new FormControl(undefined),
      apartment: new FormControl(undefined),
      companyName: new FormControl(undefined, [Validators.required, companyName('bg')]),
      citizenship: new FormControl(undefined, [Validators.required]),
      dob: new FormControl(undefined, [Validators.required]),
      phoneNumber: new FormControl(undefined),
      emailId: new FormControl(undefined, [ExtraTextControlValidators.maxLength(100)]),
    });

  public cpcController = this.addNewInsuringForm.createCityAndPostCode('city', 'postCode');
  public cityAutocomplete =
    this.addNewInsuringForm.createAutocompleteWithServersideFiltering<CityApiData>(
      'city',
      (q: string) =>
        this._coreApiService.getCity(q, true).pipe(
          map((res: CityApiData[]) => {
            this.cityArray = res;
            return res;
          }),
        ),
    );

  public citizenshipAutocomplete =
    this.addNewInsuringForm.createAutocompleteWithServersideFiltering<CitizenshipDto>(
      'citizenship',
      () => this._coreApiService.getCitizenship().pipe(map((res: CitizenshipDto[]) => res)),
    );

  public visibleDateView?: DatepickerVisibleView = undefined;
  public disableWeekends: boolean = false;

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Year, -150);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, -1);

  public birthDatePicker = this.addNewInsuringForm.createDatePicker('dob');

  public constructor(
    public control: ModalControl<MtplInsuredParty, MtplInsuredParty>,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _coreApiService: CoreApiService,
    private readonly _partyId: PartyIdService,
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _cdRef: ChangeDetectorRef,
    public dateFormaterService: DateFormatterService,
    private readonly _mtplApiService: MtplApiService,
  ) {
    super();

    this._popUpDeleteFactory = this._componentFactoryResolver.resolveComponentFactory(
      RelatedPartyDeletePopupComponent,
    );
  }

  public ngOnInit(): void {
    this._prepopulateCityAndRelatedParties();

    const data = this.control.input;
    if (data) {
      this.isNewDetails = false;
    }
    if (!this.isNewDetails) {
      this.citizenshipId = data.citizenship;
      this.cityArray.push(data?.city);
      this.handleInputText(new Text(data?.idNumber));
      this.addNewInsuringForm.controls.idNumber.disable();
      this.addNewInsuringForm.patchValue({
        relatedParty: data?.relatedParty,
        idNumber: data?.idNumber,
        companyName: data?.companyName,
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        street: data?.street,
        streetNumber: data?.streetNumber,
        city: data?.city,
        block: data?.block,
        entrance: data?.entrance,
        quarter: data?.quarter,
        floor: data?.floor,
        phoneNumber: data?.phoneNumber,
        apartment: data?.apartment,
        emailId: data?.emailId,
      });
      this.birthDatePicker.value = data?.dob;
    }

    this.addNewInsuringForm.controls.city.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((val: CityApiData) => {
        if (val !== null && val !== undefined) {
          this.addNewInsuringForm.patchValue({
            postCode: val.zip,
          });
        }
      });

    this._coreApiService.getCitizenship().subscribe(
      res => {
        this.citizenshipOptionsList.next(res);
        this.citizenshipOptionsList.complete();
        this.citizenshipOptions = res;
      },
      error => {
        console.log(error);
      },
    );
    this._initializeCitizenship();
  }

  public filterCitizenshipOptions(typeAhead: string) {
    this.citizenshipOptionsFilter.next(typeAhead);
  }

  private _initializeCitizenship(): void {
    let isCitizenship = true;
    combineLatest(
      this.citizenshipOptionsList.pipe(tap(list => (this.citizenships = list))),
      this.citizenshipOptionsFilter,
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([list, filter]) => {
        if (filter === '') {
          this.filteredCitizenshipOptionsList.next(list);
        } else {
          const lowercase = filter.toLowerCase();
          this.filteredCitizenshipOptionsList.next(
            list.filter((citizenship: CitizenshipDto) =>
              citizenship.i18n.filter((data: CitizenshipEn) => {
                data.en.filter(
                  (value: CitizenEn) => value.title.toLowerCase().indexOf(lowercase) >= 0,
                );
              }),
            ),
          );
        }
        if (isCitizenship) {
          isCitizenship = false;
          setTimeout(() => {
            if (this.citizenshipId != undefined) {
              this.addNewInsuringForm.controls.citizenship.patchValue(this.citizenshipId, {
                emitEvent: true,
              });
            }
          });
        }
      });
  }

  private _prepopulateCityAndRelatedParties(): void {
    this._mtplApiService
      .getRelatedParty()
      .pipe(
        tap((response: MtplRelatedParty[]) => {
          this.mockRelatedParty = response;
          this.addNewInsuringForm.patchValue({
            relatedParty: this.mockRelatedParty[0]?.name,
          });
        }),
        concatMap(() => {
          return this._coreApiService.getCity(INSURING_DETAILS.CITY_NAME).pipe(
            takeUntilDestroyed(this),
            tap((response: CityApiData[]) => {
              this.cityArray = response;
            }),
          );
        }),
      )
      .subscribe();
  }

  public selectedPhoneNumber(data: any): void {
    this.addNewInsuringForm.controls.phoneNumber.setValue(data);
  }

  public handleInputText(id: Text) {
    this.inputText = id;
    if (null != id && 8 <= id.asString().length) {
      this.insurancePartyType = this._partyId.getType(id.asString());
      this.isEGN = this.insurancePartyType === PartyType.PERSON;
      this.isEIK = this.insurancePartyType === PartyType.COMPANY;
      this.isLNCH = this.insurancePartyType === PartyType.FOREIGNER;
      this.addNewInsuringForm.patchValue({
        idNumber: this.inputText.asString(),
      });
    } else if (id == null) {
      this.addNewInsuringForm.reset();
      this.addNewInsuringForm.patchValue({
        relatedParty: this.mockRelatedParty[0]?.name,
      });
      this.phone = {number: BLANK_VALUE, prefix: COUNTRY_CODE};
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public searchInsurerDetails(id: string) {
    const data = this.control.input;
    if (this.isEGN || this.isLNCH) {
      if (data.companyName) {
        this.addNewInsuringForm.patchValue({
          companyName: BLANK_VALUE,
        });
      }
      this._mtplApiService.getPersonDetails(id).subscribe(
        (response: MtplPersonalData) => {
          this.retrievedAddInsuringDetails = response;
          this.populateRetrievedEGNLNCHDetails(response);
        },
        error => {
          this.personNotFound = true;
          console.log('Api Error', error);
        },
      );
    } else if (this.isEIK) {
      if (data.firstName && data.middleName && data.lastName) {
        this.addNewInsuringForm.patchValue({
          firstName: BLANK_VALUE,
          middleName: BLANK_VALUE,
          lastName: BLANK_VALUE,
        });
      }
      this._mtplApiService.getCompanyDetails(id).subscribe(
        (response: CompanyDataDto) => {
          this.populateRetrievedEIKDetails(response);
        },
        error => {
          this.personNotFound = true;
          console.log('Api Error', error);
        },
      );
    }
  }

  public enableInputFieldEdit() {
    this.isEditable = true;
    this.addNewInsuringForm.controls.idNumber.enable();
  }

  public handleSearchedText(text: Text) {
    this.textSearched = text;
    if (this.isEGN || this.isLNCH) {
      this._mtplApiService.getPersonDetails(text.asString()).subscribe(
        (response: MtplPersonalData) => {
          this.retrievedAddInsuringDetails = response;
          this.populateRetrievedEGNLNCHDetails(response);
        },
        error => {
          this.personNotFound = true;
          console.log('Api Error', error);
        },
      );
    } else if (this.isEIK) {
      this._mtplApiService.getCompanyDetails(text.asString()).subscribe(
        (response: CompanyDataDto) => {
          this.retrievedAddInsuringPartyCompanyDetails = response;
          this.populateRetrievedEIKDetails(response);
        },
        error => {
          this.personNotFound = true;
          console.log('Api Error', error);
        },
      );
    }
  }

  public populateRetrievedEGNLNCHDetails(data: MtplPersonalData): void {
    const phone: PhoneNumberValue = data.personalData.addresses
      ? typeof data.personalData.addresses[0].phoneNumber === 'string'
        ? {number: data.personalData.addresses[0].phoneNumber, prefix: COUNTRY_CODE}
        : BLANK_VALUE
      : this.addNewInsuringForm.controls.phoneNumber.value;
    const city: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].city
      : BLANK_VALUE;
    const cityData: CityApiData | undefined = this.cityArray.find(element => element.city === city);
    this.addNewInsuringForm.patchValue({
      firstName: data.personalData.firstName,
      middleName: data.personalData.middleName,
      lastName: data.personalData.surname,
      block: data.personalData.addresses ? data.personalData.addresses[0].block : BLANK_VALUE,
      entrance: data.personalData.addresses ? data.personalData.addresses[0].entrance : BLANK_VALUE,
      floor: data.personalData.addresses ? data.personalData.addresses[0].floor : BLANK_VALUE,
      apartment: data.personalData.addresses
        ? data.personalData.addresses[0].apartment
        : BLANK_VALUE,
      city: cityData,
      street: data.personalData.addresses ? data.personalData.addresses[0].street : BLANK_VALUE,
      streetNumber: data.personalData.addresses
        ? data.personalData.addresses[0].streetNo
        : BLANK_VALUE,
      quarter: data.personalData.addresses ? data.personalData.addresses[0].quarter : BLANK_VALUE,
      emailId: data.personalData.addresses ? data.personalData.addresses[0].email : BLANK_VALUE,
      phoneNumber: phone,
    });
  }

  public populateRetrievedEIKDetails(data: CompanyDataDto): void {
    let city: string | undefined = data.address?.settlement;
    if (data.address?.settlement === CITY) {
      city = INSURING_DETAILS.CITY_NAME;
    }
    const cityData: CityApiData | undefined = this.cityArray.find(element => element.city === city);
    this.addNewInsuringForm.patchValue({
      companyName: data.name,
      street: data.address?.street,
      streetNumber: data.address?.streetNumber,
      city: cityData,
      block: data.address?.block,
      entrance: data.address?.entrance,
      floor: data.address?.floor,
      apartment: data.address?.apartment,
      emailId: data.email,
    });
  }

  public idNumberValidator(control: FormControl) {
    if (null != control.value && 8 <= control.value.length) {
      this.insurancePartyType = this._partyId.getType(control.value);
      this.isEGN = this.insurancePartyType === PartyType.PERSON;
      this.isEIK = this.insurancePartyType === PartyType.COMPANY;
      this.isLNCH = this.insurancePartyType === PartyType.FOREIGNER;
    }
    if (control.value && !this._partyId.getType(control.value)) {
      return {invalidNumber: true};
    }
    return null;
  }

  public getRelatedParty(value: string): string | null {
    const data = this.mockRelatedParty
      ? this.mockRelatedParty.find((options: MtplRelatedParty) => options.name == value)
      : null;
    return data ? data.name : null;
  }

  public saveInsuringPartyDetails(): void {
    const data: MtplInsuredParty = this.addNewInsuringForm.value;
    if (!this.isEditable) {
      this.control.confirm(this.addNewInsuringForm.formGroup.getRawValue());
    } else {
      this.control.confirm(data);
    }
  }

  public deleteInsuringParty(): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: INSURING_DETAILS.DELETE_DATA, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this.addNewInsuringForm.reset();
          const deleteData: AddInsuringPartyForm = this.addNewInsuringForm.value;
          this.control.confirm(deleteData);
        }
        this._cdRef.detectChanges();
      });
  }

  public isFormDetailsValid(): boolean {
    if (this.addNewInsuringForm != null) {
      if (this.isEGN) {
        this.addNewInsuringForm.controls.companyName?.setErrors(null);
        this.addNewInsuringForm.controls.citizenship?.setErrors(null);
        this.addNewInsuringForm.controls.dob?.setErrors(null);
      } else if (this.isLNCH) {
        this.addNewInsuringForm.controls.companyName?.setErrors(null);
      } else if (this.isEIK) {
        this.addNewInsuringForm.controls.citizenship?.setErrors(null);
        this.addNewInsuringForm.controls.dob?.setErrors(null);
        this.addNewInsuringForm.controls.firstName?.setErrors(null);
        this.addNewInsuringForm.controls.middleName?.setErrors(null);
        this.addNewInsuringForm.controls.lastName?.setErrors(null);
      }
      return this.addNewInsuringForm.valid;
    }
    return false;
  }
}
