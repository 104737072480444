import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule DevicesIndiMobileFuelSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendDevicesIndiMobileFuel]',
  template: '<svg:path d="M37.47 52.81V19.23A7.73 7.73 0 0 1 45 11.5h30.63a7.52 7.52 0 0 1 7.49 7.5v62.06a3 3 0 0 0 1.45 2.66l1.93 1.45a4.58 4.58 0 0 1 1.94 4.12v4.82H57.27" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M70.08 35.65H51a2.16 2.16 0 0 1-2.18-2.15h0V25A2.16 2.16 0 0 1 51 22.86h19.09A2.14 2.14 0 0 1 72.25 25v8.46a2 2 0 0 1-1.82 2.16h-.11z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M83.36 33.24h4.35a2.41 2.41 0 0 1 2.41 2.41v34.79a3 3 0 0 0 2.95 3.14.67.67 0 0 0 .2 0h2.41a3 3 0 0 0 3.15-2.94v-43c0-.72-.24-1.69-1-1.93l-4.59-4.83" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M17.17 71.17h30.2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M17.17 96.05h30.2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M43.51 104.5H21a3.82 3.82 0 0 1-3.87-3.77V66.82a3.83 3.83 0 0 1 3.76-3.88h22.62a3.82 3.82 0 0 1 3.86 3.77v33.91c0 1.95-1.93 3.64-3.86 3.88z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M50.27 46.77A13.76 13.76 0 0 1 64 60.53h0" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M47.85 55.22a7.53 7.53 0 0 1 7.49 7.49" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 116 116',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesIndiMobileFuelSvgComponent {
}

@NgModule({
  declarations: [
    DevicesIndiMobileFuelSvgComponent,
  ],
  exports: [
    DevicesIndiMobileFuelSvgComponent,
  ],
})
export class DevicesIndiMobileFuelSvgModule {}
