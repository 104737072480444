import {Component, Input} from '@angular/core';
import {LoaderDto} from '../../core-helper/dto/LoaderDto';
import {LOADER} from '../../core-helper/constants/Config';

@Component({
  selector: 'njf-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  public loadingMsg: string = LOADER.LOADING_MSG;
  public loaderSizeCss: string = LOADER.LOADER_SIZE_NORMAL;
  public loderShowCss: string = LOADER.LOADER_SHOW_CSS;

  @Input()
  public set loaderMesage(loaderMesage: LoaderDto) {
    this.loadingMsg = loaderMesage.loader_message;
    this.loaderSizeCss = loaderMesage.loader_css;
    this.loderShowCss = loaderMesage.loader_shown_css;
  }
}
