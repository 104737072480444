<form maiaForm [formGroup]="insuringPartyForm.formGroup">
  <div alignContentTop class="icon-align">
    <label class="style-header" i18n="@@nje-b2b-frontend-casco-price-peopledetails-heading">
      Insuring Party Details</label
    >
    <button maiaDocumentAction i18n="@@nje-b2b-frontend-casco-price-peopledetails-readmore"
      >Read More</button
    >
  </div>
  <maia-fluid-fluid-layout class="spacing">
    <div class="m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column"
        label="EGN / LNCH / EIK"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-idno"
      >
      </maia-form-element>

      <maia-search-bar
        class="search"
        [placeholder]="'EGN / LNCH / EIK'"
        (inputText)="handleInputText($event)"
        [(ngModel)]="searchEgn"
        ngDefaultControl
        [ngModelOptions]="{standalone: true}"
        (keyup)="fillVehicleForm()"
      >
      </maia-search-bar>
    </div>

    <div>
      <maia-form-element
        *ngIf="isLNCH"
        class="input-column"
        label="Full Name"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-fullname-lnch"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('firstNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('firstNameEn')
            "
          >
            <input
              maiaInput
              type="text"
              placeholder="First Name"
              formControlName="firstNameEn"
              (Keyup)="fillVehicleForm()"
              i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-firstname-lnch"
            />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('firstNameEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstNameEn', 'required')"
                >First name required</maia-validation
              >

              <maia-validation *ngIf="insuringPartyForm.hasError('firstNameEn', 'nonBlank')"
                >Invalid first name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstNameEn', 'maxlength')"
                >First name is too long (maximum is
                {{ insuringPartyForm.controls['firstNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>

        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('middleNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('middleNameEn')
            "
          >
            <input
              maiaInput
              type="text"
              placeholder="Middle Name"
              formControlName="middleNameEn"
              (keyup)="fillVehicleForm()"
              i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-middlename-lnch"
            />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('middleNameEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleNameEn', 'required')"
                >Middle name required</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleNameEn', 'nonLatinLetters')"
                >Middle name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleNameEn', 'nonBlank')"
                >Invalid middle name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleNameEn', 'maxlength')"
                >Middle name is too long (maximum is
                {{ insuringPartyForm.controls['middleNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>

        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('lastNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('lastNameEn')
            "
          >
            <input
              maiaInput
              type="text"
              placeholder="Last Name"
              formControlName="lastNameEn"
              (keyup)="fillVehicleForm()"
              i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-lastname-lnch"
            />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('lastNameEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastNameEn', 'required')"
                >Last name required</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastNameEn', 'nonLatinLetters')"
                >Last name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastNameEn', 'nonBlank')"
                >Invalid last name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastNameEn', 'maxlength')"
                >Last name is too long (maximum is
                {{ insuringPartyForm.controls['lastNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        *ngIf="isEGN"
        class="input-column"
        label="Full Name"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-fullname-egn"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('firstName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('firstName')
            "
          >
            <input
              maiaInput
              type="text"
              placeholder="First Name"
              formControlName="firstName"
              (keyup)="fillVehicleForm()"
              i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-firstname-egn"
            />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('firstName')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstName', 'required')"
                >First name required</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstName', 'nonLatinLetters')"
                >First name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstName', 'nonBlank')"
                >Invalid first name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstName', 'maxlength')"
                >First name is too long (maximum is
                {{ insuringPartyForm.controls['firstName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>

        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('middleName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('middleName')
            "
          >
            <input
              maiaInput
              type="text"
              placeholder="Middle Name"
              formControlName="middleName"
              (keyup)="fillVehicleForm()"
              i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-middlename-egn"
            />

            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('middleName')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleName', 'required')"
                >Middle name required</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleName', 'nonLatinLetters')"
                >Middle name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleName', 'nonBlank')"
                >Invalid middle name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleName', 'maxlength')"
                >Middle name is too long (maximum is
                {{ insuringPartyForm.controls['middleName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>

        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('lastName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('lastName')
            "
          >
            <input
              maiaInput
              type="text"
              placeholder="Last Name"
              formControlName="lastName"
              (keyup)="fillVehicleForm()"
              i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-lastname-egn"
            />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('lastName')">
              <maia-validation *ngIf="insuringPartyForm.hasError('lastName', 'required')"
                >Last name required</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastName', 'nonLatinLetters')"
                >Last name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastName', 'nonBlank')"
                >Invalid last name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastName', 'maxlength')"
                >Last name is too long (maximum is
                {{ insuringPartyForm.controls['lastName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>

      <maia-form-element
        *ngIf="isEIK"
        class="input-column"
        label="Company Name"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-companyname-eik"
      >
        <input
          maiaInput
          type="text"
          placeholder="Company Name"
          formControlName="companyName"
          (keyup)="fillVehicleForm()"
          i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-companyname-eik"
        />
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyName') &&
            insuringPartyForm.hasError('companyName', 'required')
          "
        >
          Company name required
        </maia-validation>
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyName') &&
            insuringPartyForm.hasError('companyName', 'nonLatinLetters')
          "
          >Company name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyName') &&
            insuringPartyForm.hasError('companyName', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyName') &&
            insuringPartyForm.hasError('companyName', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ insuringPartyForm.controls['companyName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout class="spacing">
    <div class="m-r-16">
      <maia-form-element
        label="Nationality"
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="input-column"
        *ngIf="isLNCH"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-nationality-lnch"
      >
        <maia-input-autocomplete
          name="nationality"
          formControlName="nationality"
          (keyup)="fillVehicleForm()"
          (typeAhead)="citizenshipAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="dzi-off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let c of citizenshipAutocomplete.list | async" [value]="c.id">
            <maia-option-highlight [label]="c.title"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>

        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('nationality') &&
            insuringPartyForm.hasError('nationality', 'required')
          "
        >
          Nationality required
        </maia-validation>
      </maia-form-element>
    </div>
    <div>
      <maia-form-element
        label="Birth date"
        [hideOptionalIndicator]="true"
        class="input-column"
        *ngIf="isLNCH"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-birthdate-lnch"
      >
        <maia-input-date
          name="birthDate"
          (blur)="birthDatePicker.touch()"
          [minimum]="minDate"
          [maximum]="maxDate"
          [visibleView]="visibleDateView"
          formControlName="dob"
          (keyup)="fillVehicleForm()"
        ></maia-input-date>
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('dob') &&
            insuringPartyForm.hasError('dob', 'required')
          "
        >
          Date of birth required
        </maia-validation>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout>
    <div class="m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column city-and-postcode"
        label="City & Zip"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-city-zip"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="city"
            formControlName="city"
            (keyup)="fillVehicleForm()"
            ngDefaultControl
            (typeAhead)="cityAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="City" />

            <maia-option *ngFor="let cityObj of cityAutocomplete.list | async" [value]="cityObj">
              <maia-option-highlight [label]="cityObj.title"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('city')">
            <maia-validation *ngIf="insuringPartyForm.hasError('city', 'required')"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input
              maiaInput
              type="text"
              placeholder="Zip"
              i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-zip"
              readonly
              formControlName="postCode"
              (keyup)="fillVehicleForm()"
            />
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>

    <div>
      <maia-form-element
        [hideOptionalIndicator]="false"
        class="input-column"
        label="Quarter"
        (keyup)="fillVehicleForm()"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-quarter"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('quarter')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('quarter')
            "
          >
            <input maiaInput type="text" placeholder="Quarter" formControlName="quarter" />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('quarter')">
              <maia-validation *ngIf="insuringPartyForm.hasError('quarter', 'required')"
                >Quarter is required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout>
    <div class="m-r-16">
      <maia-form-element
        class="multi-input-row city-and-postcode"
        label="Street & Number"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-street-number"
        multi
      >
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            placeholder="Street"
            formControlName="street"
            (keyup)="fillVehicleForm()"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-street"
          />
        </maia-form-subelement>

        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            placeholder="Number"
            formControlName="streetNumber"
            (keyup)="fillVehicleForm()"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-steetnumber"
          />
        </maia-form-subelement>
      </maia-form-element>
    </div>
    <div>
      <maia-form-element
        [hideOptionalIndicator]="!isEIK"
        class="multi-input-row"
        label="Block, Entrance, Floor & Apartment"
        i18n-label="
          @@nje-b2b-frontend-casco-price-peopledetails-ip-label-block-entrance-floor-apartment"
        multi
      >
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="block"
            placeholder="Block"
            formControlName="block"
            (keyup)="fillVehicleForm()"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-block"
          />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="entrance"
            placeholder="Entrance"
            formControlName="entrance"
            (keyup)="fillVehicleForm()"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-entrance"
          />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="floor"
            placeholder="Floor"
            formControlName="floor"
            (keyup)="fillVehicleForm()"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-floor"
          />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="apartment"
            placeholder="Apartment"
            formControlName="apartment"
            (keyup)="fillVehicleForm()"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-apartment"
          />
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="phone-margin">
    <div class="m-r-16">
      <maia-form-element
        class="multi-input-row"
        label="Phone Number"
        [hideOptionalIndicator]="!isLNCH"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-phonenumber"
      >
        <maia-input-phonenumber
          appDigitOnly
          appLimitTo="10"
          name="phone"
          #phoneInput
          class="disable-country-code"
          formControlName="phoneNumber"
          (keyup)="fillVehicleForm()"
        >
          <maia-phonenumber-country prefix="+359" name="Bulgaria">
            <ng-template #flag>
              <svg b2bFrontendFlagUtilBulgaria></svg>
            </ng-template>
          </maia-phonenumber-country>
        </maia-input-phonenumber>
        <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('phoneNumber')">
          <maia-validation *ngIf="insuringPartyForm.hasError('phoneNumber', 'required')"
            >Phone required</maia-validation
          >
          <maia-validation *ngIf="insuringPartyForm.hasError('phoneNumber', 'invalid')"
            >Phone invalid</maia-validation
          >
        </div>
      </maia-form-element>
    </div>

    <div>
      <maia-form-element
        class="multi-input-row"
        label="Email ID"
        [hideOptionalIndicator]="!isLNCH"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-emailid"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('emailId')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('emailId')
            "
          >
            <input
              type="email"
              maiaInput
              atlasEmailAddress
              placeholder="Email ID"
              formControlName="emailId"
              (keyup)="fillVehicleForm()"
            />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('emailId')">
              <maia-validation *ngIf="insuringPartyForm.hasError('emailId', 'required')"
                >Email required</maia-validation
              >
              <maia-validation
                *ngIf="
                  insuringPartyForm.hasError('emailId', 'atlasInvalidLocalPart') ||
                  insuringPartyForm.hasError('emailId', 'atlasInvalidDomain') ||
                  insuringPartyForm.hasError('emailId', 'atlasInvalidMultipleAt')
                "
                >Enter your e-mail address in the following format: name@domain.com</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('emailId', 'atlasMaxLength')"
                >Email too long</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout *ngIf="isEIK">
    <div class="m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column"
        label="Main Activity"
        i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-mainactivity-eik"
      >
        <input maiaInput />
      </maia-form-element>
    </div>
    <div class="m-r-16"></div>
  </maia-fluid-fluid-layout>
  <br />

  <div *ngIf="isEIK">
    <label class="style-header">Representative Details</label>

    <maia-fluid-fluid-layout class="insurance-party-details">
      <div class="m-r-16">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column"
          label="EGN / LNCH"
          i18n-label="@@nje-b2b-frontend-casco-price-peopledetails-ip-label-representative-egn-lnch"
        >
        </maia-form-element>
        <maia-search-bar
          class="search-box"
          [placeholder]="'EGN / LNCH'"
          (inputText)="handleRepresentative($event)"
          (searchText)="handleSearchedText($event)"
          [(ngModel)]="searchEgn"
          ngDefaultControl
          [ngModelOptions]="{standalone: true}"
          i18n-placeholder="@@nje-b2b-frontend-casco-price-peopledetails-ip-ph-idno"
        >
        </maia-search-bar>
      </div>

      <div>
        <maia-form-element
          *ngIf="isrepEGN"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Full Name"
          i18n-label="
            @@nje-b2b-frontend-casco-price-peopledetails-ip-label-representative-fullname-egn"
          multi
        >
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repFirstName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repFirstName')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="First Name"
                i18n-placeholder="
                  @@nje-b2b-frontend-casco-price-peopledetails-ip-ph-representative-firstname-egn"
                formControlName="repFirstName"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repFirstName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstName', 'required')"
                  >First name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repFirstName', 'nonLatinLetters')"
                  >First name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstName', 'nonBlank')"
                  >Invalid first name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstName', 'maxlength')"
                  >First name is too long (maximum is
                  {{ insuringPartyForm.controls['repFirstName'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>

          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repMiddleName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repMiddleName')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Middle Name"
                formControlName="repMiddleName"
                i18n-placeholder="
                  @@nje-b2b-frontend-casco-price-peopledetails-ip-ph-representative-middlename-egn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repMiddleName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleName', 'required')"
                  >Middle name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repMiddleName', 'nonLatinLetters')"
                  >Middle name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleName', 'nonBlank')"
                  >Invalid middle name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleName', 'maxlength')"
                  >Middle name is too long (maximum is
                  {{ insuringPartyForm.controls['repMiddleName'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>

          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repLastName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repLastName')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Last Name"
                formControlName="repLastName"
                i18n-placeholder="
                  @@nje-b2b-frontend-casco-price-peopledetails-ip-ph-representative-lastname-egn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repLastName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastName', 'required')"
                  >Last name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repLastName', 'nonLatinLetters')"
                  >Last name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastName', 'nonBlank')"
                  >Invalid last name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastName', 'maxlength')"
                  >Last name is too long (maximum is
                  {{ insuringPartyForm.controls['repLastName'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
        </maia-form-element>
        <maia-form-element
          *ngIf="isrepLNCH"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Full Name"
          i18n-label="
            @@nje-b2b-frontend-casco-price-peopledetails-ip-label-representative-fullname-lnch"
          multi
        >
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repFirstNameEn')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repFirstNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="First Name"
                formControlName="repFirstNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repFirstName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstNameEn', 'required')"
                  >First name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repFirstNameEn', 'nonLatinLetters')"
                  >First name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstNameEn', 'nonBlank')"
                  >Invalid first name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstNameEn', 'maxlength')"
                  >First name is too long (maximum is
                  {{
                    insuringPartyForm.controls['repFirstNameEn'].errors?.maxlength.requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>

          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repMiddleNameEn')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repMiddleNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Middle Name"
                formControlName="repMiddleNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repMiddleNameEn')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleNameEn', 'required')"
                  >Middle name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repMiddleNameEn', 'nonLatinLetters')"
                  >Middle name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleNameEn', 'nonBlank')"
                  >Invalid middle name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleNameEn', 'maxlength')"
                  >Middle name is too long (maximum is
                  {{
                    insuringPartyForm.controls['repMiddleNameEn'].errors?.maxlength.requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>

          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repLastName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repLastNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Last Name"
                formControlName="repLastNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repLastNameEn')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastNameEn', 'required')"
                  >Last name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repLastNameEn', 'nonLatinLetters')"
                  >Last name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastNameEn', 'nonBlank')"
                  >Invalid last name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastNameEn', 'maxlength')"
                  >Last name is too long (maximum is
                  {{ insuringPartyForm.controls['repLastNameEn'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout *ngIf="isrepLNCH">
      <div class="m-r-16">
        <maia-form-element
          *ngIf="isrepLNCH"
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="multi-input-row"
          label="Citizenship"
        >
          <maia-input-autocomplete
            name="repCitizenship"
            formControlName="repCitizenship"
            (typeAhead)="repCitizenshipAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="dzi-off" maiaInput placeholder="Select" />

            <maia-option *ngFor="let c of repCitizenshipAutocomplete.list | async" [value]="c.id">
              <maia-option-highlight [label]="c.title"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>

          <maia-validation
            *ngIf="
              insuringPartyForm.hasToShowErrors('repCitizenship') &&
              insuringPartyForm.hasError('repCitizenship', 'required')
            "
          >
            Citizenship required
          </maia-validation>
        </maia-form-element>
      </div>
      <div>
        <maia-form-element
          [hideOptionalIndicator]="true"
          [options]="{validationInstant: true}"
          class="multi-input-row"
          label="Date of Birth"
        >
          <maia-input-date
            name="repDob"
            [minimum]="minDate"
            [maximum]="maxDate"
            [visibleView]="visibleDateView"
            formControlName="repDob"
          ></maia-input-date>
          <maia-validation
            *ngIf="
              insuringPartyForm.hasToShowErrors('repDob') &&
              insuringPartyForm.hasError('repDob', 'required')
            "
          >
            Date of birth required
          </maia-validation>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
  </div>
</form>
<maia-divider></maia-divider>
<div class="m-r-16 m-t-24 vehicle-head-align" alignContentTop>
  <label class="style-header"> Vehicle Owner Details</label>
</div>
<form maiaForm [formGroup]="formCheckbox.formGroup">
  <maia-checkbox name="disabled" formControlName="checkValue" class="m-t-24"
    >The Vehicle owner is same as the Insuring Party</maia-checkbox
  >
</form>
<form maiaForm [formGroup]="vehicleOwnerForm.formGroup">
  <maia-fluid-fluid-layout class="insurance-party-details spacing">
    <div class="m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column"
        label="EGN / LNCH / EIK"
      >
        <div *ngIf="isSame">
          <input
            maiaInput
            name="EGN / LNCH / EIK"
            placeholder="EGN / LNCH / EIK"
            formControlName="idNumberVo"
          />
        </div>
      </maia-form-element>
      <div *ngIf="!isSame">
        <maia-search-bar
          class="search"
          [placeholder]="'EGN / LNCH / EIK'"
          (inputText)="handleInputTextVehicleOwner($event)"
          [(ngModel)]="searchEgn"
          ngDefaultControl
          [ngModelOptions]="{standalone: true}"
        >
        </maia-search-bar>
      </div>
    </div>

    <div>
      <maia-form-element *ngIf="isEGNVo" class="input-column" label="Full Name" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('firstNameVo')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('firstNameVo')
            "
          >
            <input maiaInput type="text" placeholder="First Name" formControlName="firstNameVo" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('firstNameVo')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameVo', 'required')"
                >First name required</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameVo', 'nonLatinLetters')"
                >First name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameVo', 'nonBlank')"
                >Invalid first name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameVo', 'maxlength')"
                >First name is too long (maximum is
                {{ vehicleOwnerForm.controls['firstNameVo'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>

        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('middleNameVo')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('middleNameVo')
            "
          >
            <input maiaInput type="text" placeholder="Middle Name" formControlName="middleNameVo" />

            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('middleNameVo')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameVo', 'required')"
                >Middle name required</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameVo', 'nonLatinLetters')"
                >Middle name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameVo', 'nonBlank')"
                >Invalid middle name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameVo', 'maxlength')"
                >Middle name is too long (maximum is
                {{ vehicleOwnerForm.controls['middleNameVo'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>

        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('lastNameVo')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('lastNameVo')
            "
          >
            <input maiaInput type="text" placeholder="Last Name" formControlName="lastNameVo" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('lastNameVo')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameVo', 'required')"
                >Last name required</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameVo', 'nonLatinLetters')"
                >Last name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameVo', 'nonBlank')"
                >Invalid last name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameVo', 'maxlength')"
                >Last name is too long (maximum is
                {{ vehicleOwnerForm.controls['lastNameVo'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>

      <maia-form-element *ngIf="isLNCHVo" class="input-column" label="Full Name" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('firstNameEnVo')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('firstNameEnVo')
            "
          >
            <input maiaInput type="text" placeholder="First Name" formControlName="firstNameEnVo" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('firstNameEnVo')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameEnVo', 'required')"
                >First name required</maia-validation
              >

              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameEnVo', 'nonBlank')"
                >Invalid first name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('firstNameEnVo', 'maxlength')"
                >First name is too long (maximum is
                {{ vehicleOwnerForm.controls['firstNameEnVo'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>

        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('middleNameEnVo')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('middleNameEnVo')
            "
          >
            <input
              maiaInput
              type="text"
              placeholder="Middle Name"
              formControlName="middleNameEnVo"
            />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('middleNameEnVo')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameEnVo', 'required')"
                >Middle name required</maia-validation
              >
              <maia-validation
                *ngIf="vehicleOwnerForm.hasError('middleNameEnVo', 'nonLatinLetters')"
                >Middle name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameEnVo', 'nonBlank')"
                >Invalid middle name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('middleNameEnVo', 'maxlength')"
                >Middle name is too long (maximum is
                {{ vehicleOwnerForm.controls['middleNameEnVo'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>

        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('lastNameEnVo')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('lastNameEnVo')
            "
          >
            <input maiaInput type="text" placeholder="Last Name" formControlName="lastNameEnVo" />
            <div
              class="validation-container"
              *ngIf="vehicleOwnerForm.hasToShowErrors('lastNameEnVo')"
            >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameEnVo', 'required')"
                >Last name required</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameEnVo', 'nonLatinLetters')"
                >Last name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameEnVo', 'nonBlank')"
                >Invalid last name</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('lastNameEnVo', 'maxlength')"
                >Last name is too long (maximum is
                {{ vehicleOwnerForm.controls['lastNameEnVo'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>

      <maia-form-element *ngIf="isEIKVo" class="input-column" label="Company Name">
        <input maiaInput type="text" placeholder="Full Name" formControlName="companyNameVo" />
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyNameVo') &&
            vehicleOwnerForm.hasError('companyNameVo', 'required')
          "
        >
          Company name required
        </maia-validation>
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyNameVo') &&
            vehicleOwnerForm.hasError('companyNameVo', 'nonLatinLetters')
          "
          >Company name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyNameVo') &&
            vehicleOwnerForm.hasError('companyNameVo', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('companyNameVo') &&
            vehicleOwnerForm.hasError('companyNameVo', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ vehicleOwnerForm.controls['companyNameVo'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="spacing">
    <div class="m-r-16">
      <maia-form-element
        hideOptionalIndicator
        label="Nationality"
        class="input-column"
        *ngIf="isLNCHVo"
      >
        <input maiaInput placeholder="Select" formControlName="citizenshipVo" />
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('citizenshipVo') &&
            vehicleOwnerForm.hasError('citizenshipVo', 'required')
          "
        >
          Nationality required
        </maia-validation>
      </maia-form-element>
    </div>
    <div>
      <maia-form-element
        label="Birth date"
        [hideOptionalIndicator]="true"
        class="input-column"
        *ngIf="isLNCHVo"
      >
        <maia-input-date
          name="birthDate"
          [minimum]="minDate"
          [maximum]="maxDate"
          [visibleView]="visibleDateView"
          formControlName="dobVo"
        ></maia-input-date>
        <maia-validation
          *ngIf="
            vehicleOwnerForm.hasToShowErrors('dobVo') &&
            vehicleOwnerForm.hasError('dobVo', 'required')
          "
        >
          Date of birth required
        </maia-validation>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="spacing">
    <div class="m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column city-and-postcode"
        label="City & Zip"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="city"
            formControlName="cityVo"
            ngDefaultControl
            (typeAhead)="cityAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="City" />

            <maia-option *ngFor="let cityObj of cityAutocomplete.list | async" [value]="cityObj">
              <maia-option-highlight [label]="cityObj.title"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('cityVo')">
            <maia-validation *ngIf="vehicleOwnerForm.hasError('cityVo', 'required')"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input maiaInput type="text" placeholder="Zip" readonly formControlName="postCodeVo" />
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>

    <div>
      <maia-form-element [hideOptionalIndicator]="true" class="input-column" label="Quarter" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('quarterVo')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('quarterVo')
            "
          >
            <input maiaInput type="text" placeholder="Quarter" formControlName="quarterVo" />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('quarterVo')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('quarterVo', 'required')"
                >Quarter is required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout>
    <div class="m-r-16">
      <maia-form-element class="input-column city-and-postcode" label="Street & Number" multi>
        <maia-form-subelement>
          <input maiaInput type="text" placeholder="Street" formControlName="streetVo" />
        </maia-form-subelement>

        <maia-form-subelement>
          <input maiaInput type="text" placeholder="Number" formControlName="streetNumberVo" />
        </maia-form-subelement>
      </maia-form-element>
    </div>
    <div>
      <maia-form-element
        [hideOptionalIndicator]="!isEIKVo"
        class="input-column"
        label="Block, Entrance, Floor & Apartment"
        multi
      >
        <maia-form-subelement>
          <input maiaInput type="text" name="block" placeholder="Block" formControlName="blockVo" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="entrance"
            placeholder="Entrance"
            formControlName="entranceVo"
          />
        </maia-form-subelement>
        <maia-form-subelement>
          <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floorVo" />
        </maia-form-subelement>
        <maia-form-subelement>
          <input
            maiaInput
            type="text"
            name="apartment"
            placeholder="Apartment"
            formControlName="apartmentVo"
          />
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout>
    <div class="m-r-16">
      <maia-form-element
        class="input-column"
        label="Phone Number"
        [hideOptionalIndicator]="!isLNCHVo"
      >
        <maia-input-phonenumber
          appDigitOnly
          appLimitTo="10"
          name="phone"
          #phoneInput
          class="disable-country-code"
          formControlName="phoneNumberVo"
        >
          <maia-phonenumber-country prefix="+359" name="Bulgaria">
            <ng-template #flag>
              <svg b2bFrontendFlagUtilBulgaria></svg>
            </ng-template>
          </maia-phonenumber-country>
        </maia-input-phonenumber>
        <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('phoneNumberVo')">
          <maia-validation *ngIf="vehicleOwnerForm.hasError('phoneNumberVo', 'required')"
            >Phone required</maia-validation
          >
          <maia-validation *ngIf="vehicleOwnerForm.hasError('phoneNumberVo', 'invalid')"
            >Phone invalid</maia-validation
          >
        </div>
      </maia-form-element>
    </div>

    <div>
      <maia-form-element
        class="input-column"
        label="Email ID"
        [hideOptionalIndicator]="!isLNCHVo"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            vehicleOwnerForm.hasToShowErrors('emailIdVo')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              vehicleOwnerForm.hasToShowErrors('emailIdVo')
            "
          >
            <input
              type="email"
              maiaInput
              atlasEmailAddress
              placeholder="Email ID"
              formControlName="emailIdVo"
            />
            <div class="validation-container" *ngIf="vehicleOwnerForm.hasToShowErrors('emailIdVo')">
              <maia-validation *ngIf="vehicleOwnerForm.hasError('emailIdVo', 'required')"
                >Email required</maia-validation
              >
              <maia-validation
                *ngIf="
                  vehicleOwnerForm.hasError('emailIdVo', 'atlasInvalidLocalPart') ||
                  vehicleOwnerForm.hasError('emailIdVo', 'atlasInvalidDomain') ||
                  vehicleOwnerForm.hasError('emailIdVo', 'atlasInvalidMultipleAt')
                "
                >Enter your e-mail address in the following format: name@domain.com</maia-validation
              >
              <maia-validation *ngIf="vehicleOwnerForm.hasError('emailIdVo', 'atlasMaxLength')"
                >Email too long</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <br />
</form>

<maia-divider class="m-t-b-24"></maia-divider>

<div class="list">
  <maia-label class="style-header">Related Parties List </maia-label>
  <div class="btn-style">
    <svg
      class="action-func-plus-circle"
      b2bFrontendActionFuncPlusCircle
      (click)="addRelatedParties()"
    ></svg>
    <button maiaButton maiaButtonType="tertiary" class="add-btn" (click)="addRelatedParties()"
      >Add New</button
    >
  </div>
</div>
<div *ngIf="false">
  <maia-contextual-message class="message-content"
    >Lessee details are mandatory. Kindly add data to proceed
  </maia-contextual-message>
</div>
<div class="table-style">
  <maia-table class="table1">
    <maia-table-header>
      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small subtitle-light-color">
          EGN / LNCH / EIK
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small subtitle-light-color"> Name </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small subtitle-light-color"> City </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small subtitle-light-color">
          Related Parties
        </maia-table-cell>
      </maia-table-row>
    </maia-table-header>
    <maia-table-body>
      <maia-table-row *ngFor="let data of addedRelatedPartyArray; let i = index" class="table-row1">
        <maia-table-cell>
          <button maiaButton maiaButtonType="tertiary" (click)="editRelatedPartyDetails(data, i)">{{
            data.idNumber
          }}</button>
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ data?.firstName ? data?.firstName : '-' }} {{ data.middleName }} {{ data.lastName }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ data.city }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ data.relatedParty }}
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
  <maia-table class="table2">
    <maia-table-header>
      <maia-table-row style="border: none">
        <maia-table-cell></maia-table-cell>
      </maia-table-row>
    </maia-table-header>
    <maia-table-body>
      <maia-table-row class="table-row2" *ngFor="let data of addedRelatedPartyArray; let i = index">
        <maia-table-cell>
          <div class="left">
            <svg b2bFrontendActionFuncBin class="delete-icon" (click)="removeRelatedParty(i)"></svg>
          </div>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
</div>
<maia-divider *ngIf="addedRelatedPartyArray.length <= 0"></maia-divider>
<maia-fluid-fluid-layout>
  <maia-contextual-message *ngIf="addedRelatedPartyArray.length <= 0" class="table-content">
    No related party records available
  </maia-contextual-message>
</maia-fluid-fluid-layout>
<maia-divider *ngIf="addedRelatedPartyArray.length > 0" class="table-divider"></maia-divider>
<button
  class="continue"
  maiaButton
  maiaButtonType="primary"
  [disabled]="isContinue"
  *ngIf="!(step.inEdit | async)"
  (click)="saveAndContinue()"
  >Continue
</button>
<div *ngIf="step.inEdit | async">
  <button class="m-r-24 m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveEdit()"
    >Save Changes</button
  >
  <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
</div>
