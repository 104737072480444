import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  OnInit,
  Output,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {SlideInController} from '@maia/slide-ins';
import {DELETE_POPUP_HEADINGS} from '../../../../constants/resource-management/library';

import {Library} from '../../../../contracts/library.interface';
import {LibrarySessionService} from '../../../../services/library-session.service';
import {LibraryService} from '../../../../services/library.service';
import {DeletePopupComponent} from '../delete-popup/delete-popup.component';
import {LibrarylistSlideinComponent} from '../librarylist-slidein/librarylist-slidein.component';

@Component({
  selector: 'nje-library-list',
  templateUrl: './library-list.component.html',
  styleUrls: ['./library-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class LibraryListComponent implements OnInit {
  public showLoadingIcon: boolean = false;
  public lang: string | null;
  @Output()
  public readonly selectedLibraryList: EventEmitter<Library> = new EventEmitter<Library>();

  public librarylistData: Library[] = [];
  public libraryList: Library;
  private readonly _popUpDeleteFactory: ComponentFactory<DeletePopupComponent>;
  private readonly _libraryListSlideInFactory: ComponentFactory<LibrarylistSlideinComponent>;
  public constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _libraryService: LibraryService,
    private readonly _librarySessionService: LibrarySessionService,
    private readonly _popUpCtrl: PopUpController,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._libraryListSlideInFactory = this._componentFactoryResolver.resolveComponentFactory(
      LibrarylistSlideinComponent,
    );
    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(DeletePopupComponent);
  }

  public ngOnInit(): void {
    this.lang = sessionStorage.getItem('lang');
    this._getLibraryListfromDB();
  }
  public addEditLibraryList(library?: Library, index?: number): void {
    const slideinTitle = library
      ? new localeValPipe(new LocalizationService()).transform('Update Library List', this.lang)
      : new localeValPipe(new LocalizationService()).transform('Add New Library', this.lang);
    this._slideInCtrl
      .prepare(
        this._libraryListSlideInFactory,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: library,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<Library>) => {
        if (response.resolution === ModalResolution.CONFIRMED && response.result.id !== undefined) {
          // update scenario
          this.librarylistData[index!] = response.result;
          this._libraryService.updateLibraryList(response.result).subscribe();
          this._cdRef.detectChanges();
        } else if (
          response.resolution === ModalResolution.CONFIRMED &&
          (response.result.id === undefined || response.result.id === null)
        ) {
          // add scenario
          this._libraryService.AddLibraryList(response.result).subscribe(() => {
            this._getLibraryListfromDB();
          });

          this._cdRef.detectChanges();
        }
      });
  }

  public deleteLibraryList(index: number, id: number): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: '', footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: new localeValPipe(new LocalizationService()).transform(
            DELETE_POPUP_HEADINGS.LIBRARY,
            this.lang,
          ),
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          // handle delete scenario
          void this._libraryService.deleteLibraryList(id).subscribe(() => {
            this._getLibraryListfromDB();
            this.selectedLibraryList.emit();
            this._cdRef.detectChanges();
          });
        }
      });
  }

  private _getLibraryListfromDB(): void {
    this.showLoadingIcon = true;
    this._libraryService.getLibraryLists().subscribe(
      (response: Library[]) => {
        this.librarylistData = response;
        this._updateLibraryFromSession();
        this.showLoadingIcon = false;
        this._cdRef.detectChanges();
      },
      error => {
        this.showLoadingIcon = false;
      },
    );
  }

  public selectLibraryList(index: number, library: Library): void {
    this.librarylistData.map((libraryList: Library) => (libraryList.selectedStatus = false));
    this.librarylistData[index].selectedStatus = true;
    this.selectedLibraryList.emit(library);
  }

  private _updateLibraryFromSession(): void {
    const librarySessionData = this._librarySessionService.getData().library;
    if (librarySessionData) {
      const libraryIndex = this.librarylistData.findIndex(
        libData => libData.id === librarySessionData.id,
      );
      if (libraryIndex !== -1) {
        this.libraryList = this.librarylistData[libraryIndex];
        this.selectLibraryList(libraryIndex, this.librarylistData[libraryIndex]);
      }
    }
  }
}
