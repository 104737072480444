import {CommonModule} from '@angular/common';
import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {FormsModule as AngularFormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {B2bCoreModule} from '@b2b-frontend/core';
import {μExampleSvgModule} from '@b2b-frontend/icons';
import {ActionsModule} from '@maia/actions';
import {AdditionalInformationModule as MaiaAdditionalInformationModule} from '@maia/additional-information';
import {
  AdvancedSearchModule as MaiaAdvancedSearchModule,
  AdvancedSearchOptionAmountModule,
  AdvancedSearchOptionDateModule,
  AdvancedSearchOptionFreeModule,
} from '@maia/advanced-search';
import {AvatarsModule as MaiaAvatarsModule} from '@maia/avatars';
import {ButtonsModule as MaiaButtonsModule} from '@maia/buttons';
import {DatePickersModule} from '@maia/datepickers';
import {DisplaysModule as MaiaDisplaysModule} from '@maia/displays';
import {DividerModule as MaiaDividerModule} from '@maia/divider';
import {DropdownsModule} from '@maia/dropdowns';
import {FlexGridModule as MaiaFlexGridModule} from '@maia/flex-grid';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {InputAutocompleteModule as MaiaInputAutocompleteModule} from '@maia/input-autocomplete';
import {InputPhonenumberModule as MaiaInputPhonenumberModule} from '@maia/input-phonenumber';
import {InputSelectModule as MaiaInputSelectModule} from '@maia/input-select';
import {InputStepperModule as MaiaInputStepperModule} from '@maia/input-stepper';
import {LayoutModule as MaiaLayoutModule} from '@maia/layout';
import {MessagesModule as MaiaMessages} from '@maia/messages';
import {NameValueCollectionsModule as MaiaNameValueCollectionsModule} from '@maia/name-value-collections';
import {PaginationModule as MaiaPaginationModule} from '@maia/pagination';
import {PanelsModule as MaiaPanelsModule} from '@maia/panels';
import {PopUpsModule as MaiaPopUpsModule} from '@maia/pop-ups';
import {PriceBlockModule as MaiaPriceBlockModule} from '@maia/price-block';
import {RadioButtonsModule as MaiaRadioButtonsModule} from '@maia/radio-buttons';
import {SlideInsModule as MaiaSlideInsModule} from '@maia/slide-ins';
import {SwitchesModule} from '@maia/switches';
import {TablesModule as MaiaTablesModule} from '@maia/tables';
import {TilesModule as MaiaTilesModule} from '@maia/tiles';
import {ActionTileModule as MaiaActionTileModule} from '@maia/action-tile';
import {TimepickersModule as MaiaTimepickersModule} from '@maia/timepickers';
import {CoversAccordionModule} from '@njf-frontend-angular/covers-accordion';
import {
  ExpandedFlowProgressWithRoutingModule,
  FlowProgressModule,
} from '@njf-frontend-angular/flow-progress';
import {NjfRadioButtonsModule} from '@njf-frontend-angular/radio-buttons';
import {TitlesModule as MaiaTitlesModule} from '@maia/titles';
import {SearchBarModule as MaiaSearchBarModule} from '@maia/search-bar';
import {TravelInsuredPeopleSearchPipe} from './pipes/searchfilter.pipe';
import {TravelAddonsComponent} from './screens/travel-addons/travel-addons.component';
import {TravelCoverSlideinComponent} from './screens/travel-cover-slidein/travel-cover-slidein.component';
import {TravelCoversComponent} from './screens/travel-covers/travel-covers.component';
import {TravelInsuredPeopleSlideinComponent} from './screens/travel-insured-people-slidein/travel-insured-people-slidein.component';
import {TravelInsuredPeopleComponent} from './screens/travel-insured-people/travel-insured-people.component';
import {TravelInsuringPartyComponent} from './screens/travel-insuring-party/travel-insuring-party.component';
import {TravelPolicyErrorComponent} from './screens/travel-policy-error/travel-policy-error.component';
import {TravelPolicySuccessComponent} from './screens/travel-policy-success/travel-policy-success.component';
import {TravelProposalEmailComponent} from './screens/travel-proposal-email/travel-proposal-email.component';
import {TravelProposalSlideinComponent} from './screens/travel-proposal-slidein/travel-proposal-slidein.component';
import {TravelProposalComponent} from './screens/travel-proposal/travel-proposal.component';
import {TravelTripDetailsSlideinComponent} from './screens/travel-trip-details-slidein/travel-trip-details-slidein.component';
import {TravelTripDetailsComponent} from './screens/travel-trip-details/travel-trip-details.component';
import {BASE_ROUTE, TravelStarterService} from './travel-core/services/starter.service';
import {StepResolverService} from './travel-core/services/step.resolver';
import {TravelPolicyService} from './travel-core/services/travel-policy.service';
import {TravelProcessService} from './travel-core/services/travel-process.service';
import {TravelInsuranceComponent} from './travel-insurance.component';
import {TravelInsuranceRoutingModule} from './travel-insurance.routing.module';
import {TravelRenewPolicyComponent} from './screens/travel-renew-policy/travel-renew-policy.component';
import {TravelInsuredPeopleSlideinChildComponent} from './screens/travel-insured-people-slidein/travel-insured-people-slidein-child/travel-insured-people-slidein-child.component';
import {TravelInsuredPeoplePopupComponent} from './screens/travel-insured-people-popup/travel-insured-people-popup.component';
import {TravelAddonsSlideinComponent} from './screens/travel-addons-slidein/travel-addons-slidein.component';
import {TravelInsuredPeopleDeletePopupComponent} from './screens/travel-insured-people-delete-popup/travel-insured-people-delete-popup.component';
import {HttpClientModule} from '@angular/common/http';
/**
 * This is the public module of the example flow.
 *
 * It provides a starter service to encapsulate the flow and start the route.
 *
 * An important note is that we don't inject the dependencies of the flow here but instead do this
 * inside a private module which is defined in the `example-flow.routing.module.ts` so these
 * dependencies are by default lazy loaded when the flow is injected into an application.
 *
 */

@NgModule({
  providers: [TravelStarterService, StepResolverService, TravelPolicyService, TravelProcessService],
  declarations: [
    TravelCoversComponent,
    TravelAddonsComponent,
    TravelInsuringPartyComponent,
    TravelInsuredPeopleComponent,
    TravelProposalComponent,
    TravelTripDetailsComponent,
    TravelInsuranceComponent,
    TravelCoverSlideinComponent,
    TravelInsuredPeopleSlideinComponent,
    TravelTripDetailsSlideinComponent,
    TravelPolicySuccessComponent,
    TravelPolicyErrorComponent,
    TravelProposalEmailComponent,
    TravelProposalSlideinComponent,
    TravelRenewPolicyComponent,
    TravelInsuredPeopleSearchPipe,
    TravelInsuredPeopleSlideinChildComponent,
    TravelInsuredPeoplePopupComponent,
    TravelAddonsSlideinComponent,
    TravelInsuredPeopleDeletePopupComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    BusinessTypesModule,
    AngularFormsModule,
    ReactiveFormsModule,
    MaiaButtonsModule,
    MaiaFormsModule,
    MaiaRadioButtonsModule,
    MaiaTilesModule,
    MaiaFlexGridModule,
    MaiaDisplaysModule,
    MaiaPanelsModule,
    MaiaSlideInsModule,
    MaiaDividerModule,
    MaiaAdditionalInformationModule,
    ActionsModule,
    MaiaNameValueCollectionsModule,
    DatePickersModule,
    MaiaTimepickersModule,
    MaiaInputSelectModule,
    MaiaAvatarsModule,
    DropdownsModule,
    NjfRadioButtonsModule,
    SwitchesModule,
    MaiaInputStepperModule,
    FlowProgressModule,
    ExpandedFlowProgressWithRoutingModule,
    TravelInsuranceRoutingModule,
    CoversAccordionModule,
    B2bCoreModule,
    MaiaLayoutModule,
    μExampleSvgModule,
    BrowserModule,
    MaiaPriceBlockModule,
    MaiaAvatarsModule,
    MaiaPaginationModule,
    MaiaTablesModule,
    MaiaAdvancedSearchModule,
    AdvancedSearchOptionAmountModule,
    AdvancedSearchOptionDateModule,
    AdvancedSearchOptionFreeModule,
    MaiaInputAutocompleteModule,
    MaiaInputPhonenumberModule,
    MaiaPopUpsModule,
    MaiaMessages,
    MaiaTablesModule,
    MaiaSearchBarModule,
    MaiaActionTileModule,
    MaiaTitlesModule,
    HttpClientModule,
  ],
  entryComponents: [TravelProposalComponent],
})
export class TravelInsuranceModule {
  // Static method for injecting the module with a base route provided.
  public static forPath(path: string): ModuleWithProviders<TravelInsuranceModule> {
    return {
      ngModule: TravelInsuranceModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error(
        'Do not import TravelInsuranceModule directly, use TravelInsuranceModule.forPath() instead',
      );
    }
  }
}
