<div class="blue-box">
  <maia-action-group>
    <button maiaActionTile id="actionTile_1" (click)="onMTPLTileClick()">
      <ng-container maia-tile-icon>
        <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendTransportationExprCar></svg>
        </maia-avatar-svg>
        <br />
      </ng-container>
      <span maia-tile-label>MTPL Insurance</span>
    </button>
    <button maiaActionTile id="actionTile_2" (click)="onCascoTileClick()">
      <ng-container maia-tile-icon>
        <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendTransportationExprCarNew></svg>
        </maia-avatar-svg>
        <br />
      </ng-container>
      <span maia-tile-label>Car Insurance</span>
    </button>
    <button maiaActionTile id="actionTile_3" (click)="onPropertyForHomeTileClick()">
      <ng-container maia-tile-icon>
        <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendPlacesExprHouseTree></svg>
        </maia-avatar-svg>
        <br />
      </ng-container>
      <span maia-tile-label>Property for the Home</span>
    </button>
    <button maiaActionTile id="actionTile_4">
      <ng-container maia-tile-icon>
        <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendPlacesExprFactory></svg>
        </maia-avatar-svg>
        <br />
      </ng-container>
      <span maia-tile-label>Property for the Business</span>
    </button>
    <button maiaActionTile id="actionTile_5">
      <ng-container maia-tile-icon>
        <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendTransportationExprSemitrailerEmpty></svg>
        </maia-avatar-svg>
        <br />
      </ng-container>
      <span maia-tile-label>Road Assistance</span>
    </button>
    <button maiaActionTile id="actionTile_6">
      <ng-container maia-tile-icon>
        <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendTransportationFuncPlane></svg>
        </maia-avatar-svg>
        <br />
      </ng-container>
      <span maia-tile-label>Travel Insurance</span>
    </button>
    <button maiaActionTile id="actionTile_7">
      <ng-container maia-tile-icon>
        <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendInformationFuncHandsCross></svg>
        </maia-avatar-svg>
        <br />
      </ng-container>
      <span maia-tile-label>Best Doctors</span>
    </button>
    <button maiaActionTile id="actionTile_8" (click)="onZakrilaTileClick()">
      <ng-container maia-tile-icon>
        <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendInformationExprFall></svg>
        </maia-avatar-svg>
        <br />
      </ng-container>
      <span maia-tile-label>Zakrila</span>
    </button>
  </maia-action-group>
</div>
