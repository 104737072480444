/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ComponentFactory,
  Injector,
  ComponentFactoryResolver,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {Language} from '../../../../constants/lang';

import {AdminLibrary, AdminLibrarySlideinInput} from '../../../../contracts/adminInterface';
import {AdminLibraryService} from '../../../../services/admin-library.service';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {LibrarySlideinComponent} from '../library-slidein/library-slidein.component';
import {SlideInController} from '@maia/slide-ins';
import {PopUpController} from '@maia/pop-ups';
import {DeleteAdminLibraryPopupComponent} from '../delete-popup/delete-popup.component';
import {
  ADD_LIBRARY_SLIDEIN_TITLE,
  BLANK_VALUE,
  EDIT_LIBRARY_SLIDEIN_TITLE,
} from '../../../../constants/resource-management/admin-documents';
import {localeValPipe, LocalizationService} from '@b2b-frontend/core';

@Component({
  selector: 'nje-admin-library-list',
  templateUrl: './admin-library-list.component.html',
  styleUrls: ['./admin-library-list.component.scss'],
})
@UntilDestroy()
export class AdminLibraryListComponent implements OnInit {
  public showLoadingIcon: boolean = false;
  public libraryList: AdminLibrary[] = [];
  public selectedAdminId: number = 0;
  public showAdmin: boolean = false;
  public selectedLibrary: AdminLibrary;

  public lang: string | null = sessionStorage.getItem('lang');
  private readonly _librarySlideInFactory: ComponentFactory<LibrarySlideinComponent>;
  private readonly _popUpLibraryDeleteFactory: ComponentFactory<DeleteAdminLibraryPopupComponent>;

  @Output()
  public readonly selectedAdminLibrary: EventEmitter<AdminLibrary> = new EventEmitter<AdminLibrary>();

  @Input()
  public searchResultCount: number;

  @Input()
  public hasSearchTriggered: boolean;

  public constructor(
    private readonly _libraryService: AdminLibraryService,
    private readonly _changeDetection: ChangeDetectorRef,
    private readonly _popUpCtrl: PopUpController,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this._librarySlideInFactory =
      this._componentFactoryResolver.resolveComponentFactory(LibrarySlideinComponent);
    this._popUpLibraryDeleteFactory = this._componentFactoryResolver.resolveComponentFactory(
      DeleteAdminLibraryPopupComponent,
    );
  }

  public ngOnInit(): void {
    this.showLoadingIcon = true;
    this._libraryService.getAdminLibraryList().subscribe(
      response => {
        this.libraryList = response;
        this.showLoadingIcon = false;
        this._changeDetection.detectChanges();
      },
      error => {
        this.showLoadingIcon = false;
      },
    );
  }

  public isLanguageEnglish(): boolean {
    return this.lang === Language.English;
  }

  public addEditLibrary(library?: AdminLibrary, index?: number): void {
    const slideinTitle = library
      ? new localeValPipe(new LocalizationService()).transform(
          EDIT_LIBRARY_SLIDEIN_TITLE,
          this.lang,
        )
      : new localeValPipe(new LocalizationService()).transform(
          ADD_LIBRARY_SLIDEIN_TITLE,
          this.lang,
        );
    this._slideInCtrl
      .prepare(
        this._librarySlideInFactory,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: library ? this.frameLibrarySlideinInput(library) : undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<AdminLibrary>) => {
        if (response.resolution === ModalResolution.CONFIRMED && response.result.id !== undefined) {
          // update scenario
          this.libraryList[index!] = response.result;
          this._libraryService.updateAdminLibrary(response.result).subscribe();
          this._changeDetection.detectChanges();
        } else if (
          response.resolution === ModalResolution.CONFIRMED &&
          (response.result.id === undefined || response.result.id === null)
        ) {
          // add scenario
          const createAdminLibraryInput = this._createAdminLibraryRequestObject(response.result);
          this._libraryService.createAdminLibrary(createAdminLibraryInput).subscribe(() => {
            this._getLibraryListFromDB();
          });

          this._changeDetection.detectChanges();
        }
      });
  }

  public deleteLibrary(id: number): void {
    this._popUpCtrl
      .prepare(
        this._popUpLibraryDeleteFactory,
        this._injector,
        {title: BLANK_VALUE, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          void this._libraryService.deleteAdminLibrary(id).subscribe(res => {
            this._getLibraryListFromDB();
            this.selectedAdminLibrary.emit();
            this._changeDetection.detectChanges();
          });
        }
      });
  }

  public isAdminLibrarySectionShown(): boolean {
    return this.searchResultCount === 0 && !this.hasSearchTriggered;
  }

  public selectAdminLibrary(index: number, library: AdminLibrary): void {
    this.showAdmin = false;
    this.libraryList.map((library: AdminLibrary) => (library.selectedStatus = false));
    this.libraryList[index].selectedStatus = true;
    this.selectedLibrary = library;
    this.selectedAdminLibrary.emit(library);
  }

  private _createAdminLibraryRequestObject(input: AdminLibrary): AdminLibrary {
    return {
      i18n: {
        en: {
          title: input.title !== undefined ? input.title : BLANK_VALUE,
          description: input.description !== undefined ? input.description : BLANK_VALUE,
        },
        bg: {
          title: input.title !== undefined ? input.title : BLANK_VALUE,
          description: input.description !== undefined ? input.description : BLANK_VALUE,
        },
      },
      title: input.title,
      description: input.description,
    } as AdminLibrary;
  }

  private _getLibraryListFromDB(): void {
    this._libraryService.getAdminLibraryList().subscribe((response: AdminLibrary[]) => {
      this.libraryList = response;
      this._changeDetection.detectChanges();
    });
  }

  public getLibraryTitles(): string[] {
    return this.libraryList.map(lib => {
      return this.isLanguageEnglish() ? lib.i18n!.en.title : lib.i18n!.bg.title;
    });
  }

  public frameLibrarySlideinInput(library?: AdminLibrary): AdminLibrarySlideinInput {
    const requiredLibraryTitles = this.getLibraryTitles();
    if (library && library.i18n) {
      //removing the current title from list for edit scenario
      const title = this.isLanguageEnglish() ? library.i18n.en.title : library.i18n.bg.title;
      const index = requiredLibraryTitles.indexOf(title);
      if (index !== -1) {
        requiredLibraryTitles.splice(index, 1);
      }
    }
    return {
      selectedLibrary: library,
      existingLibraryListTitles: requiredLibraryTitles,
    };
  }
}
