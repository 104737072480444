<div>
  <!--Addons section starts here-->
  <div *ngIf="!(this.step.inEdit | async) || showAddonDiv">
    <div class="addons-title-wrapper">
      <maia-label i18n="@@b2b-fronend-travelInsurance-travelAddons-addOnsTitle">Add Ons</maia-label>
      <maia-checkbox
        [(ngModel)]="masterSelected"
        (onValueChange)="checkUncheckAllAddOns($event)"
        class="addons-select-all-checkboxes"
        i18n="@@b2b-fronend-travelInsurance-travelAddons-selectAllLabel"
        >Select All
      </maia-checkbox>
    </div>
    <div class="check-grid">
      <div
        *ngFor="let cover of additionalCovers; let i = index"
        style="display: flex; flex-direction: column"
      >
        <maia-tile
          [ngClass]="{'addons-tile-selected': additionalCoversSelected[i] | atlasBoolean}"
          [width]="currentWidth2"
          [height]="currentHeight2"
          class="addons-tile-wrapper tile-class"
        >
          <maia-checkbox
            [(ngModel)]="additionalCoversSelected[i]"
            (onValueChange)="changeAddonsSelection(i, cover)"
            class="addons-tile-checkbox"
          >
            <maia-label class="b2b-kdl-font-small-light addons-tile-cover-title">{{
              cover.title
            }}</maia-label>
            <div>
              <maia-amount-display
                class="b2b-kdl-font-small-bold"
                [value]="convertAmount(cover)"
                [currency]="cover.insuredAmountCurrency"
                [showPositiveSignSymbol]="true"
                size="extra-large"
              >
              </maia-amount-display>
              <div class="b2b-kdl-font-extra-small-light addons-tile-cover-people-days-label">
                <span *ngIf="noOfPeople" i18n="@@b2b-frontend-travelInsurance-travelAddons-people"
                  >{{ noOfPeople }} people
                </span>
                <span *ngIf="noOfDays" i18n="@@b2b-fronend-travelInsurance-travelAddons-days"
                  >for {{ noOfDays }} days</span
                >
              </div>
            </div>
          </maia-checkbox>
        </maia-tile>
        <maia-tile
          *ngIf="cover.showCoverageAmountBox | atlasBoolean"
          [width]="currentWidth2"
          [height]="currentHeight2"
          class="addons-coverage-amount-box-wrapper tile-class"
        >
          <div>
            <maia-label class="discount-tile-content-title">{{
              cover.coverageDetail?.title
            }}</maia-label>
            <maia-input-select [(ngModel)]="valueCoverAmountAccident">
              <input
                maiaInput
                placeholder="Select"
                [value]="valueCoverAmountAccident ? valueCoverAmountAccident + ' BGN' : null"
              />
              <maia-option *ngFor="let c of coverAmountAccident" [value]="c.value">{{
                c.name
              }}</maia-option>
            </maia-input-select>
          </div>
        </maia-tile>
      </div>
    </div>
  </div>

  <div *ngIf="showDiscount">
    <div class="blue-box b2b-kdl-font-small m-b-24" *ngIf="step.inEdit | async">
      Changing values will result in premium recalulation
    </div>
  </div>

  <!--  Discounts section starts here-->
  <div *ngIf="!(this.step.inEdit | async) || showDiscountDiv">
    <maia-label class="discounts-title-wrapper">Discounts</maia-label>
    <div class="check-grid">
      <div
        *ngFor="let cover of discounts; let i = index"
        style="display: flex; flex-direction: column"
      >
        <maia-tile
          [ngClass]="{'addons-tile-selected': discountTileSelcted[i] | atlasBoolean}"
          [width]="currentWidth2"
          [height]="currentHeight2"
          class="discounts-tile-wrapper tile-class"
        >
          <maia-checkbox
            [(ngModel)]="discountTileSelcted[i]"
            (onValueChange)="changeDiscountSelection(i, cover)"
            class="discounts-tile-checkbox"
          >
            <maia-label class="b2b-kdl-font-small-light discount-tile-content-title">{{
              cover.title
            }}</maia-label>
            <maia-label class="b2b-kdl-font-small-light discount-tile-content-subtitle">{{
              cover.subtitle
            }}</maia-label>
          </maia-checkbox>
        </maia-tile>

        <maia-tile
          *ngIf="cover.showCoverageAmountBox | atlasBoolean"
          [width]="currentWidth2"
          [height]="currentHeight2"
          class="addons-coverage-amount-box-wrappers tile-class"
        >
          <div>
            <maia-label
              class="discount-tile-content-title"
              i18n="@@b2b-fronend-travelInsurance-travelAddons-groupDiscountEnterManual"
              >Type 0-5%</maia-label
            >
            <maia-input-group>
              <input
                class="input-adjust"
                maiaInput
                type="number"
                [min]="minValue"
                [max]="maxValue"
              />
              <maia-input-group-suffix>%</maia-input-group-suffix>
            </maia-input-group>
          </div>
        </maia-tile>
      </div>
    </div>
    <maia-divider class="m-t-b-24"></maia-divider>
  </div>
  <div *ngIf="!(this.step.inEdit | async)">
    <maia-form-element class="form-element-custom-width">
      <div class="form-element-custom-div-margin">
        <label
          class="form-element-custom-label"
          i18n="@@b2b-fronend-travelInsurance-travelAddons-specialAuthorisationCodeLabel"
        >
          Special authorisation code</label
        >
      </div>
      <input
        maiaInput
        i18n-placeholder="
          @@b2b-fronend-travelInsurance-travelAddons-specialAuthorisationCodePlaceholder"
        placeholder="Special authorisation code"
      />
    </maia-form-element>
    <maia-divider class="m-t-b-24"></maia-divider>
  </div>
</div>

<div *ngIf="!(this.step.inEdit | async)" class="continue-button">
  <button
    maiaButton
    maiaButtonType="primary"
    i18n="@@b2b-fronend-travelInsurance-travelAddons-continueButton"
    (click)="saveAndContinue()"
    [disabled]="enableBut"
    >Continue</button
  >
  <button
    class="m-l-24"
    maiaButton
    maiaButtonType="secondary"
    [disabled]="premiumButton"
    (click)="showAccordian()"
    >Calculate premium</button
  >
  <button class="m-l-24" maiaButton maiaButtonType="secondary" (click)="getQuote()"
    >Get Quote</button
  >
</div>

<div *ngIf="(step.inEdit | async) && showDiscount">
  <button class="m-r-24 m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveEdit()"
    >Save</button
  >
  <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
</div>
