import {LabelEditDto} from '@b2b-frontend/core';
import {CascoRoutePaths} from './route-paths';

export const VEHICLE_DETAILS_PATH: LabelEditDto = {
  id: 1,
  name: 'Vehicle Details',
  navigationPath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_VEHICLE_AND_OWNER_DETAILS}`,
};

export const OWNER_DETAILS_PATH: LabelEditDto = {
  id: 2,
  name: 'Owner Details',
  navigationPath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_VEHICLE_AND_OWNER_DETAILS}`,
};

export const COVERS_PATH: LabelEditDto = {
  id: 3,
  name: 'Covers',
  navigationPath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_COVERS}`,
};

export const INSTALMENT_PATH: LabelEditDto = {
  id: 4,
  name: 'Instalment',
  navigationPath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PREMIUM}`,
};

export const DISCOUNT_LOADING_PATH: LabelEditDto = {
  id: 5,
  name: 'Discount and Loadings',
  navigationPath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_COVERS}`,
};

export const BLANK_VALUE = '';
