/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {Component} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {
  AdditionalCoversAddressLevelInterface,
  CoversDiscountsDetails,
  InsurancePartyDetails,
  LabelEditDto,
  PropertyInsuranceProposalDto,
  PropertyRoutePaths,
} from '@b2b-frontend/core';
import {
  PROPERTY_INSURANCE_PROPOSAL,
  PAYMENT_DOCUMENT_TYPE_LIST,
} from '../../constants/property-insurance-constants';
import {
  INSURED_PARTY_INFO_TABLE_COL_HEADERS,
  INSTALLMENTS_TABLE_COL_HEADERS,
  INSTALLMENTS_DETAILS_LIST,
} from '../property-insuring-party/__tests__/property-insuring-party.mock';
import {
  SelectableInputType,
  PropertyCoversInformation,
} from '../../contracts/property-insurance.interface';
import {PropertyProcessService} from '../../property-core/services/property-process.service';
import {PropertyNavigationService} from '../../property-core/services/property-navigation.service';
import {
  ADDITIONAL_COVERS_SOS_INFO_LIST1,
  ADDITIONAL_COVERS_SOS_INFO_LIST2,
  MOCK_PROPERTY_TYPE_LIST1,
  MOCK_PROPERTY_TYPE_LIST2,
  BGN,
} from '../property-covers-and-discounts/__tests__/property-covers-and-discounts.mocks';
import {PropertySessionService} from '../../property-core/services/property-session.service';

@Component({
  selector: 'property-proposal',
  templateUrl: './property-proposal.component.html',
  styleUrls: ['./property-proposal.component.scss'],
})
export class PropertyProposalComponent extends ScreenStepComponent {
  public policyDetailsPath: string = `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_POLICY_DETAILS}`;
  public insuringPartyPath: string = `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_INSURING_PARTY}`;
  public propertyDetailsPath: string = `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_DETAILS}`;
  public coverAndDiscountPath: string = `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_COVERS_AND_DISCOUNTS}`;

  public policyDetailsHeading: LabelEditDto = {
    id: 1,
    name: PROPERTY_INSURANCE_PROPOSAL.POLICY_VALIDITY_DURATION,
    navigationPath: this.policyDetailsPath,
  };

  public insuringPartyHeading: LabelEditDto = {
    id: 2,
    name: PROPERTY_INSURANCE_PROPOSAL.INSURING_PARTY,
    navigationPath: this.insuringPartyPath,
  };

  public propertyDetailsHeading: LabelEditDto = {
    id: 3,
    name: PROPERTY_INSURANCE_PROPOSAL.INSURED_PEOPLE,
    navigationPath: this.propertyDetailsPath,
  };

  public coversAndDiscountHeading: LabelEditDto = {
    id: 3,
    name: PROPERTY_INSURANCE_PROPOSAL.COVERS,
    navigationPath: this.coverAndDiscountPath,
  };

  public installments: LabelEditDto = {
    id: 5,
    name: PROPERTY_INSURANCE_PROPOSAL.INSTALLMENTS,
    navigationPath: this.coverAndDiscountPath,
  };

  public discountAndLoadings: LabelEditDto = {
    id: 6,
    name: PROPERTY_INSURANCE_PROPOSAL.DISCOUNT_AND_LOADINGS,
    navigationPath: this.coverAndDiscountPath,
  };

  public policyTerm: string = PROPERTY_INSURANCE_PROPOSAL.POLICY_TERM;
  public policyStartDate: string = PROPERTY_INSURANCE_PROPOSAL.POLICY_START_DATE;
  public policyEndDate: string = PROPERTY_INSURANCE_PROPOSAL.POLICY_END_DATE;

  public columnHeaders: string[] = INSURED_PARTY_INFO_TABLE_COL_HEADERS;
  public insurancePartyDetailsList: InsurancePartyDetails[] = [];

  public installmentsColHeaders: string[] = INSTALLMENTS_TABLE_COL_HEADERS;
  public installmentsDetailsList = INSTALLMENTS_DETAILS_LIST.data;

  public paymentDocumentTypeList: SelectableInputType[] = [...PAYMENT_DOCUMENT_TYPE_LIST];

  public additionalCoversInfoList1: AdditionalCoversAddressLevelInterface[] =
    ADDITIONAL_COVERS_SOS_INFO_LIST1;

  public propertyCoversInfoList1: PropertyCoversInformation[] = MOCK_PROPERTY_TYPE_LIST1;
  public propertyAddress1: InsurancePartyDetails;
  public additionalCoversInfoList2: PropertyCoversInformation[] =
    ADDITIONAL_COVERS_SOS_INFO_LIST2.data;

  public propertyCoversInfoList2: PropertyCoversInformation[] = MOCK_PROPERTY_TYPE_LIST2;
  public propertyAddress2: InsurancePartyDetails;

  public premiumAmountVisibility: boolean = false;
  public currencySelected: string = BGN;
  public propertyData: PropertyInsuranceProposalDto;
  public coversDiscountLayoutInfoPassed: CoversDiscountsDetails[] | undefined = [];

  public propertyProposalForm: FormGroup = this._formBuilder.group({
    paymentDocumentType: new FormControl(
      this.paymentDocumentTypeList[0].value,
      // eslint-disable-next-line
      Validators.required,
    ),
  });

  public constructor(
    private readonly _propertyNavigationService: PropertyNavigationService,
    private readonly _formBuilder: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public process: PropertyProcessService,
    private readonly _propertySessionService: PropertySessionService,
  ) {
    super(activatedRoute);
    this.process.exitStepEdit();
    this.propertyData = this._propertySessionService.getPropertyData();
    this.coversDiscountLayoutInfoPassed = this.propertyData?.coversDiscountsListDto!.coversDetails;
  }
  protected saveMtplData(): void {}
  public editScreen(screenDetail: LabelEditDto): void {
    const step = this.process.getStepByRoute(screenDetail.navigationPath);
    if (step) {
      step.edit(this.step);
    }
  }

  public createPolicy(): void {
    this._propertyNavigationService.nextScreen(PropertyRoutePaths.PROPERTY_POLICY_SUCCESS);
  }
}
