import {MockVehicle} from './vehicle-details-mockInterface';

export const MOCK_VEHICLE_TYPES: MockVehicle[] = [
  {id: 1, name: 'SUV'},
  {
    id: 2,
    name: 'Sedan',
  },
  {
    id: 3,
    name: 'Coupe',
  },
];

export const MOCK_VEHICLE_SUBTYPES: MockVehicle[] = [
  {id: 1, name: 'Electric Vehicle'},
  {
    id: 2,
    name: 'Freight Vehicle',
  },
];

export const MAKE_LIST: MockVehicle[] = [
  {id: 1, name: 'BMW'},
  {
    id: 2,
    name: 'Ford',
  },
  {
    id: 3,
    name: 'Nissan',
  },
];

export const BMW_LIST: MockVehicle[] = [
  {id: 1, name: 'BMW X1'},
  {
    id: 2,
    name: 'BMW 3 Series',
  },
  {
    id: 3,
    name: 'BMW X7',
  },
];

export const FORD_LIST: MockVehicle[] = [
  {id: 1, name: 'Ford EcoSport'},
  {
    id: 2,
    name: 'Ford Aspire',
  },
  {
    id: 3,
    name: 'Ford Endeavour',
  },
];

export const NISSAN_LIST: MockVehicle[] = [
  {id: 1, name: 'Nissan 350Z'},
  {
    id: 2,
    name: 'Nissan Armada',
  },
  {
    id: 3,
    name: 'Nissan GT-R',
  },
];

export const REGISTER_TYPE_LIST: MockVehicle[] = [
  {id: 1, name: 'Antique'},
  {
    id: 2,
    name: 'Combo',
  },
  {
    id: 3,
    name: 'Commercial',
  },
];

export const USAGE_LIST: MockVehicle[] = [
  {id: 1, name: 'Low'},
  {
    id: 2,
    name: 'Medium',
  },
  {
    id: 3,
    name: 'High',
  },
];

export const COLOR_LIST: MockVehicle[] = [
  {id: 1, name: 'White'},
  {
    id: 2,
    name: 'Black',
  },
  {
    id: 3,
    name: 'Gray',
  },
  {
    id: 4,
    name: 'Silver',
  },
];

export const CAR_BODY_LIST: MockVehicle[] = [
  {id: 1, name: 'Wagon'},
  {
    id: 2,
    name: 'Hatchback',
  },
  {
    id: 3,
    name: 'Convertible',
  },
];

export const FUEL_LIST: MockVehicle[] = [
  {id: 1, name: 'Diesel'},
  {
    id: 2,
    name: 'Electric',
  },
  {
    id: 3,
    name: 'CNG',
  },
  {
    id: 4,
    name: 'Hybrid',
  },
];

export const ENGINE_LIST: MockVehicle[] = [
  {id: 1, name: 'Four-Cyliner'},
  {
    id: 2,
    name: 'Six-Cylider',
  },
  {
    id: 3,
    name: 'Eight+ Cylinder',
  },
];

export const GEAR_LIST: MockVehicle[] = [
  {id: 1, name: 'Manual'},
  {
    id: 2,
    name: 'CVT',
  },
  {
    id: 3,
    name: 'Automatic',
  },
];

export const DRIVE_TYPE_LIST: MockVehicle[] = [
  {id: 1, name: 'Front-Wheel'},
  {
    id: 2,
    name: 'Rear-Wheel',
  },
];

export enum Manufactures {
  BMW = 'BMW',
  FORD = 'Ford',
  NISSAN = 'Nissan',
}

export enum RadioDefaults {
  LEFT = 'left',
  RIGHT = 'right',
  YES = 'yes',
  NO = 'no',
}

export const MIN_YEAR = 1900;
export const MAX_YEAR = 2021;
export const IPID_LINK =
  'https://www.dzi.bg/attachments/Product/30/download/8104-Singleitrip-IPID-0.pdf';
export const READ_MORE_LINK =
  'https://www.dzi.bg/attachments/Product/30/download/8104-Singleitrip-IPID-0.pdf';

export const MOCK_MAKE: string = 'BMW';

export enum PowerConverionFactors {
  ZERO = 0,
  UNIT_FACTOR = 1.36,
}
