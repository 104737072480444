import {PaymentWorkflow} from './payment-workflow';
import {RoutePaths} from '../../constants/route-paths';

export const workflows: {[name: string]: string[]} = {
  [PaymentWorkflow.POLICY_PAYMENT_E_RECEIPT]: [
    RoutePaths.SearchPolicy,
    RoutePaths.Instalment,
    RoutePaths.InsuredParty,
    RoutePaths.Overview,
    RoutePaths.Payment,
  ],
  [PaymentWorkflow.POLICY_PAYMENT_DEBIT_NOTE]: [
    RoutePaths.SearchPolicy,
    RoutePaths.Instalment,
    RoutePaths.InsuredParty,
    RoutePaths.Overview,
    RoutePaths.PaymentDebitNote,
  ],
  [PaymentWorkflow.ISSUING_BLANKS_FOR_MTPL]: [
    RoutePaths.SearchPolicy,
    RoutePaths.InsuredParty,
    RoutePaths.Payment,
  ],
  [PaymentWorkflow.DIRECT_DEBIT]: [
    RoutePaths.SearchPolicy,
    RoutePaths.InsuredParty,
    RoutePaths.Overview,
    RoutePaths.Payment,
  ],
  [PaymentWorkflow.POS_PAYMENT]: [
    RoutePaths.SearchPolicy,
    RoutePaths.Instalment,
    RoutePaths.InsuredParty,
    RoutePaths.Overview,
    RoutePaths.Payment,
  ],
  [PaymentWorkflow.UBB]: [
    RoutePaths.SearchPolicy,
    RoutePaths.Instalment,
    RoutePaths.InsuredParty,
    RoutePaths.Overview,
    RoutePaths.Payment,
  ],
};
