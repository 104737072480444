import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationExprMailSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendCommunicationExprMail]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path data-color="color-2" d="M6.4 21.6v13.6a3.2 3.2 0 0 0 3.2 3.2h28.8a3.2 3.2 0 0 0 3.2-3.2V21.6" fill="none" stroke-miterlimit="10" stroke-width="1.6"></svg:path><svg:path d="M41.6 16v-3.2a3.2 3.2 0 0 0-3.2-3.2H9.6a3.2 3.2 0 0 0-3.2 3.2V16L24 27.2z" fill="none" stroke-miterlimit="10" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationExprMailSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationExprMailSvgComponent,
  ],
  exports: [
    CommunicationExprMailSvgComponent,
  ],
})
export class CommunicationExprMailSvgModule {}
