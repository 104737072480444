import {mockData} from './mockInterface';

export const tariffRateMock: mockData[] = [
  {
    id: 1,
    name: 'Fixed Premium',
  },
  {
    id: 2,
    name: 'Fixed Tariff',
  },
];

export const READ_MORE_LINK = 'https://www.kbc.be/particulieren/nl.html';

export enum InstalOpts {
  SINGLE = 'Single Payment',
  DOUBLE = 'Double Payment',
  FOUR = 'Four Payment',
  TWELVE = 'Twelve Payment',
}
