export const INSURANCE_PARTY_DETAILS_LIST = {
  data: [
    {
      idNumber: '28974878237',

      firstNameEn: 'Sithi',
      middleNameEn: 'Nadesh',
      lastNameEn: 'Kumar',
      cityEn: {
        id: 1000,
        postal_code: '1000',
        title: 'Sofia',
      },
      postCodeEn: '1000',
      quarterEn: 'Jarvik Solo',
      streetEn: 'Teslo Vaptzarov Blvd',
      streetNumberEn: '24B',
      blockEn: '72',
      entranceEn: '9',
      floorEn: '33',
      apartmentEn: 'LMN',

      citizenship: '',
      dob: '',

      phoneNumber: {
        prefix: '391',
        number: '9799901245',
      },
      emailId: 'sithi@gmail.com',

      companyNameEn: '',
      repIdNumber: '',
      repFirstNameEn: '',
      repMiddleNameEn: '',
      repLastNameEn: '',

      repCitizenship: '',
      repDob: '',

      contactIdNumber: '',
      contactFirstNameEn: '',
      contactMiddleNameEn: '',
      contactLastNameEn: '',

      contactCitizenship: '',
      contactDob: '',
      isInsuringParty: false,
    },
    {
      idNumber: '28974878238',

      firstNameEn: 'Borish',
      middleNameEn: '',
      lastNameEn: 'Andreev',
      cityEn: {
        id: 1000,
        postal_code: '1000',
        title: 'Sofia',
      },
      postCodeEn: '1000',
      quarterEn: 'Kranso Solo',
      streetEn: 'Dolni Bogrov',
      streetNumberEn: '49D',
      blockEn: '12',
      entranceEn: '8',
      floorEn: '22',
      apartmentEn: 'XYZ',

      citizenship: '',
      dob: '',

      phoneNumber: {
        prefix: '391',
        number: '9799901245',
      },
      emailId: 'boris@gmail.com',

      companyNameEn: '',
      repIdNumber: '',
      repFirstNameEn: '',
      repMiddleNameEn: '',
      repLastNameEn: '',

      repCitizenship: '',
      repDob: '',

      contactIdNumber: '',
      contactFirstNameEn: '',
      contactMiddleNameEn: '',
      contactLastNameEn: '',

      contactCitizenship: '',
      contactDob: '',
      isInsuringParty: false,
    },
  ],
};

export const PROPERTY_DETAILS_TABLE_COL_HEADERS = [
  'Property Type',
  'Area of Property',
  'Year of Construction',
  'Co-Owned by Bank',
  'Bank',
  'Loan Amount',
];

export const PROPERTY_DETAILS_LIST = {
  data: [
    {
      propertyType: 'Household Property',
      specifications: '',
      improvements: undefined,
      areaOfProperty: '2800,08',
      yearOfConstruction: '1996',
      isPropertyCoOwnedByBank: true,
      bank: 'Dena Bank',
      amountOwedToTheBank: 10000,
      isFeature: false,
      currency: 'BGN',
    },
    {
      propertyType: 'Villa',
      specifications: '',
      improvements: undefined,
      areaOfProperty: '4200,00',
      yearOfConstruction: '2021',
      isPropertyCoOwnedByBank: false,
      bank: 'Lena Bank',
      amountOwedToTheBank: 99000,
      isFeature: false,
      currency: 'BGN',
    },
  ],
};

export const FEATURE_DETAILS_LIST = {
  data: [
    {
      propertyType: 'Garage as a part of building',
      specifications: '',
      improvements: undefined,
      areaOfProperty: '1500,00',
      yearOfConstruction: '2021',
      isPropertyCoOwnedByBank: true,
      bank: 'Dena Bank',
      amountOwedToTheBank: 12345,
      isFeature: true,
      currency: 'BGN',
    },
    {
      propertyType: 'Solar Thermal Collector',
      specifications: '',
      improvements: undefined,
      areaOfProperty: '3309,00',
      yearOfConstruction: '2018',
      isPropertyCoOwnedByBank: true,
      bank: 'Lena Bank',
      amountOwedToTheBank: 12699,
      isFeature: true,
      currency: 'BGN',
    },
  ],
};
