import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'nje-add-agent',
  templateUrl: './add-agent.component.html',
  styleUrls: ['./add-agent.component.scss'],
})
export class AddAgentComponent implements OnInit {
  public orderForm: FormGroup;
  public items: FormArray;

  public constructor(private readonly _formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.orderForm = new FormGroup({
      items: new FormArray([]),
    });
    this.addItem();
  }

  private _createItem(): FormGroup {
    return this._formBuilder.group({
      agentNumber: '',
    });
  }

  public addItem(): void {
    this.items = this.orderForm.get('items') as FormArray;
    this.items.push(this._createItem());
  }

  public getValues(): void {
    this.orderForm.get('items') as FormArray;
  }

  public selectedAgent(event: Event, i: number): void {
    const myForm = (<FormArray>this.orderForm.get('items')).at(i);
    myForm.patchValue({
      agentNumber: event,
    });
  }
}
