<div class="title">
  <div class="pay-success-div">
    <div class="confirmation-checks">
      <svg b2bFrontendAlertUtilConfirmationCheck class="confirmation-check"></svg>
    </div>
    <label class="success-text kdl-font-grand-bold-label"
      >Successful issuing of e-receipts for total amount 648.04BGN</label
    >
    <div class="e-Receipt-button">
      <maia-button-group>
        <button maiaButton maiaButtonType="secondary">Download all e-Receipt</button>
        <button maiaButton maiaButtonType="primary">Print all e-Receipt</button>
      </maia-button-group>
    </div>
    <div class="home-button">
      <maia-button-group>
        <button maiaButton maiaButtonType="tertiary" routerLink="../search-policy"
          >back to home</button
        >
      </maia-button-group>
    </div>
  </div>
  <div class="adjust">
    <div class="grey-box-div">
      <div class="icons-adjust">
        <img src="assets/img/logos-kbc.svg" />
        <div class="icons-right">
          <maia-avatar-display class="printer-icon-adjust">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg b2bFrontendInformationFuncFaxPrinter class="home-icon"></svg>
            </maia-avatar-svg>
          </maia-avatar-display>
        </div>
        <div>
          <maia-avatar-display class="download-icon-adjust">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg b2bFrontendActionExprDownload class="home-icon"></svg>
            </maia-avatar-svg>
          </maia-avatar-display>
        </div>
      </div>

      <div class="b2b-kdl-font-grand-bold b2b-kdl-color-primary-light">
        A7 Receipt № 123456789012345 / 30.11.2020
      </div>
      <div class="receipt b2b-kdl-font-small-bold b2b-kdl-color-primary-dark">
        For Paid insurance premium for policy № 85420000001238 / 12.04.2020
      </div>
      <maia-divider></maia-divider>
      <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Insured Details </div>
      <div class="details">
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Insured / Insurer Person</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >Boris Petrov Borisov</div
          >
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Payer Name</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Manu Vijayan</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">User ID</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Hristo </div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">PIN</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">1234567890</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Object data</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">CA8412MP</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Reason</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Insurance Premium</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Number of Installment</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">2</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Completed Installments</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">2/12</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Date from</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">16.01.2020</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Date to</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">15.04.2020</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Insurance Type</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Property Insurance</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Insurance premium</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">324.02 Bgn.</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Tax 2%</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">324.02 Bgn.</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">GF , SF</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">324.02 Bgn.</div>
        </maia-fluid-fluid-layout>
      </div>
      <maia-divider></maia-divider>
      <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Payment Details </div>
      <div class="details">
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Total due amount for the receipt</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">324.02 Bgn.v</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">№. of the sticker</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">C067694544</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Serial №. of Green Card Certificate</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">A^520392</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">№ of GCC from GF</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >BG/06/118002118014/03</div
          >
        </maia-fluid-fluid-layout>

        <maia-divider></maia-divider>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Seller ID</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >Manu Vijayan (28159520)</div
          >
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Date of Payment</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">DD/MM/YYYY</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Payment Way</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Cash</div>
        </maia-fluid-fluid-layout>
      </div>
    </div>
  </div>

  <div class="adjust-receipt">
    <div class="grey-box-div">
      <div class="icons-adjust">
        <img src="assets/img/logos-kbc.svg" />
        <div class="icons-right">
          <maia-avatar-display class="printer-icon-adjust">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg b2bFrontendInformationFuncFaxPrinter class="home-icon"></svg>
            </maia-avatar-svg>
          </maia-avatar-display>
        </div>
        <div>
          <maia-avatar-display class="download-icon-adjust">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg b2bFrontendActionExprDownload class="home-icon"></svg>
            </maia-avatar-svg>
          </maia-avatar-display>
        </div>
      </div>

      <div class="b2b-kdl-font-grand-bold b2b-kdl-color-primary-light">
        A7 Receipt № 123456789012345 / 30.11.2020
      </div>
      <div class="receipt b2b-kdl-font-small-bold b2b-kdl-color-primary-dark">
        For Paid insurance premium for policy № 85420000001238 / 12.04.2020
      </div>
      <maia-divider></maia-divider>
      <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Insured Details </div>
      <div class="details">
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Insured / Insurer Person</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >Boris Petrov Borisov</div
          >
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Payer Name</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Manu Vijayan</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">User ID</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Hristo </div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">PIN</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">1234567890</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Object data</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">CA8412MP</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Reason</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Insurance Premium</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Number of Installment</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">2</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Completed Installments</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">2/12</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Date from</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">16.01.2020</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Date to</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">15.04.2020</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Insurance Type</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Property Insurance</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Insurance premium</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">324.02 Bgn.</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Tax 2%</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">324.02 Bgn.</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">GF , SF</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">324.02 Bgn.</div>
        </maia-fluid-fluid-layout>
      </div>
      <maia-divider></maia-divider>
      <div class="b2b-kdl-font-small-bold b2b-kdl-color-primary-dark"> Payment Details </div>
      <div class="details">
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Total due amount for the receipt</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">324.02 Bgn.v</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">№. of the sticker</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">C067694544</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark"
            >Serial №. of Green Card Certificate</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">A^520392</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">№ of GCC from GF</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >BG/06/118002118014/03</div
          >
        </maia-fluid-fluid-layout>

        <maia-divider></maia-divider>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Seller ID</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark"
            >Manu Vijayan (28159520)</div
          >
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Date of Payment</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">DD/MM/YYYY</div>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout alignContentTop>
          <div class="b2b-kdl-font-small-light b2b-kdl-color-primary-dark">Payment Way</div
          ><div class="fluid b2b-kdl-font-small b2b-kdl-color-primary-dark">Cash</div>
        </maia-fluid-fluid-layout>
      </div>
    </div>
  </div>
</div>
