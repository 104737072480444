import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {ResourceManagementComponent} from './resource-management.component';
import {AdminDocumentsComponent} from './admin-documents/admin-documents.component';
import {ContactsComponent} from './contacts/contacts.component';
import {ArticlesComponent} from './articles/articles.component';
import {LibraryComponent} from './library/library.component';
import {HomeComponent} from './home/home.component';
import {RoutePaths} from '../../constants/route-paths';
import {AddProductComponent} from './library/add-product/add-product.component';
import {ProductComponent} from './library/product/product.component';
import {localeValPipe, LocalizationService} from '@b2b-frontend/core';

const lang: string | null = sessionStorage.getItem('lang');

const routes: Routes = [
  {
    path: '',
    component: ResourceManagementComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          njeB2BTitle: new localeValPipe(new LocalizationService()).transform(
            'Resource management',
            lang,
          ),
        },
      },
      {
        path: RoutePaths.AdminDocuments,
        component: AdminDocumentsComponent,
        data: {
          njeB2BTitle: new localeValPipe(new LocalizationService()).transform(
            'Admin documents',
            lang,
          ),
        },
      },
      {
        path: RoutePaths.Contacts,
        component: ContactsComponent,
        data: {
          njeB2BTitle: new localeValPipe(new LocalizationService()).transform('Contacts', lang),
        },
      },
      {
        path: RoutePaths.Articles,
        component: ArticlesComponent,
        data: {
          njeB2BTitle: new localeValPipe(new LocalizationService()).transform(
            'News & Events',
            lang,
          ),
        },
      },
      {
        path: RoutePaths.Library,
        component: LibraryComponent,
        data: {
          njeB2BTitle: new localeValPipe(new LocalizationService()).transform('Library', lang),
        },
      },
      {
        path: RoutePaths.Product,
        component: ProductComponent,
      },
      {
        path: RoutePaths.AddProduct,
        component: AddProductComponent,
      },
      {
        path: `${RoutePaths.EditProduct}/:productId`,
        component: AddProductComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResourceManagementRoutingModule {}

export const CMS_RESOURCE_MANAGEMENT_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./resource-management.module').then(module => module.ResourceManagementModule),
};
