<div class="blue-box m-t-16 m-r-16">
  <maia-fluid-fluid-layout>
    <maia-tile class="m-t-b-24 m-r-16">
      <button maiaActionWithIcon class="text-black" (click)="toNewsAndEvents()">
        <maia-tile-items>
          <maia-tile-title>
            <maia-tile-title-icon>
              <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
                <svg class="icon-size-color" b2bFrontendInformationFuncStarList></svg>
              </maia-avatar-svg>
            </maia-tile-title-icon>
          </maia-tile-title>
          <maia-tile-item
            class="m-t-16 b2b-kdl-font-small"
            i18n="@@nje-b2b-frontend-resource-home-newsevents"
          >
            News & events
          </maia-tile-item>
        </maia-tile-items>
      </button>
    </maia-tile>
    <maia-tile class="m-t-b-24 m-r-16">
      <button maiaActionWithIcon class="text-black" (click)="toLibrary()">
        <maia-tile-items>
          <maia-tile-title>
            <maia-tile-title-icon>
              <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
                <svg class="icon-size-color" b2bFrontendCommunicationFuncBookStack></svg>
              </maia-avatar-svg>
            </maia-tile-title-icon>
          </maia-tile-title>
          <maia-tile-item
            class="m-t-16 b2b-kdl-font-small"
            i18n="@@nje-b2b-frontend-resource-home-library"
          >
            Library
          </maia-tile-item>
        </maia-tile-items>
      </button>
    </maia-tile>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout>
    <maia-tile class="m-r-16">
      <button maiaActionWithIcon class="text-black" (click)="toAdminDocuments()">
        <maia-tile-items>
          <maia-tile-title>
            <maia-tile-title-icon>
              <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
                <svg class="icon-size-color" b2bFrontendFileFuncDocument></svg>
              </maia-avatar-svg>
            </maia-tile-title-icon>
          </maia-tile-title>
          <maia-tile-item
            class="m-t-16 b2b-kdl-font-small"
            i18n="@@nje-b2b-frontend-resource-home-admin"
          >
            Admin documents
          </maia-tile-item>
        </maia-tile-items>
      </button>
    </maia-tile>
    <maia-tile class="m-r-16">
      <button maiaActionWithIcon class="text-black" (click)="toContact()">
        <maia-tile-items>
          <maia-tile-title>
            <maia-tile-title-icon>
              <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
                <svg class="icon-size-color" b2bFrontendCommunicationExprBookOpen></svg>
              </maia-avatar-svg>
            </maia-tile-title-icon>
          </maia-tile-title>
          <maia-tile-item
            class="m-t-16 b2b-kdl-font-small"
            i18n="@@nje-b2b-frontend-resource-home-contact"
          >
            Contact
          </maia-tile-item>
        </maia-tile-items>
      </button>
    </maia-tile>
  </maia-fluid-fluid-layout>
</div>
