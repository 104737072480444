<ng-container>
  <div class="common-message-wrapper">
    <div class="image-wrapper">
      <svg class="circle-check-image" b2bFrontendAlertFuncCheckCircle></svg>
    </div>
    <div class="txt-wrapper">
      <label class="common-txt kdl-font-normal">{{ commonMessage?.messageBody }}</label>
    </div>
  </div>
</ng-container>
