import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule NavigationFuncHomeSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendNavigationFuncHome]',
  template: '<svg:path d="M2.5 9.79l8.69-6.24a1.5 1.5 0 0 1 1.76 0l8.55 6.24" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M4 8.73v11a.94.94 0 0 0 .88 1H9a.73.73 0 0 0 .71-.73v-4.48a2.55 2.55 0 0 1 2.09-2.63 2.39 2.39 0 0 1 2.57 2.2 1.31 1.31 0 0 1 0 .28V20a.74.74 0 0 0 .72.76h4.17a.93.93 0 0 0 .92-.95.09.09 0 0 0 0-.05V8.82" fill="none" stroke-miterlimit="10"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncHomeSvgComponent {
}

@NgModule({
  declarations: [
    NavigationFuncHomeSvgComponent,
  ],
  exports: [
    NavigationFuncHomeSvgComponent,
  ],
})
export class NavigationFuncHomeSvgModule {}
