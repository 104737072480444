import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprCardsArrowsSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprCardsArrows]',
  template: '<svg:path d="M30.09 12.53h5a4.3 4.3 0 0 1 4.3 4.3h0v1.43" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M33.67 8.94l-3.58 3.59 3.58 3.58" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M18.32 35.47h-5A4.3 4.3 0 0 1 9 31.17v-1.43" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M14.74 39.06l3.58-3.59-3.58-3.58" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M19.73 24.29H7a2 2 0 0 1-2-2v-9.62a2 2 0 0 1 2-2h16.08a2 2 0 0 1 2 2v7" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M13.6 19.99H9.3"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M25.08 14.97H5"></svg:path><svg:rect x="22.92" y="22.84" width="20.08" height="13.62" rx="2" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none"></svg:rect><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M31.53 32.16h-4.3"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M43 27.14H22.92"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprCardsArrowsSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprCardsArrowsSvgComponent,
  ],
  exports: [
    InformationExprCardsArrowsSvgComponent,
  ],
})
export class InformationExprCardsArrowsSvgModule {}
