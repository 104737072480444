/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/unbound-method */

import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {
  ModalConfirmedResult,
  ModalContentComponent,
  ModalControl,
  ModalResolution,
} from '@maia/modals';
import {
  CategoryInterface,
  FilterInputInterface,
  filterResponseInterface,
  NewsEvents,
  UserType,
} from '../../../../contracts/NewsEvents';
import {Boolean} from '@atlas/businesstypes';
import {NEWS_EVENTS, GENERAL} from '../../../../constants/resource-management/contacts';
import {DeletePopupNewsComponent} from '../delete-popup-news/delete-popup-news.component';
import {PopUpController} from '@maia/pop-ups';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ArticleService} from '../../../../services/article.service';
import {BLANK_VALUE, TRUE, FALSE} from '@b2b-frontend/core';

export type checkboxType = 'filterListForm';

export interface CheckboxInterface {
  value: Boolean;
}

export interface ITestPageCheckbox {
  filterListForm: CheckboxInterface;
}

@Component({
  selector: 'nje-filter-slidein',
  templateUrl: './filter-slidein.component.html',
  styleUrls: ['./filter-slidein.component.scss'],
})
export class FilterSlideinComponent
  extends ModalContentComponent<filterResponseInterface, FilterInputInterface>
  implements OnInit
{
  public checkBoxCategoryList: ITestPageCheckbox[] = [];
  public checkBoxUserTypeList: ITestPageCheckbox[] = [];
  public receivedNewsEventObj: NewsEvents[] = [];
  public categoryList: CategoryInterface[] = [];
  public userTypeList: UserType[] = [];
  public disabledclear: boolean = true;
  public isEditFilter: boolean = false;
  public existingCategoryList: CategoryInterface[] = [];
  public existingUserTypeList: UserType[] = [];
  public filteredNewsEventsObj: filterResponseInterface;
  public finalCategoryList: CategoryInterface[] = [];
  public finalUserTypeList: UserType[] = [];
  public lang = sessionStorage.getItem(NEWS_EVENTS.LANG);
  public disableUserType = Boolean.TRUE;
  public addCategory: boolean = false;
  public newCategory: string;
  public filterInputData: FilterInputInterface;
  public inputValue: string = '';
  public categoryListValues: any[] = [];

  public isCategoryChange: boolean = false;
  public editButtonVisibility: Boolean[] = [];
  public editCategory: boolean = false;
  public editArray: CategoryInterface[] = [];
  public categoryListIndex: number;

  private readonly _popUpDeleteFactory: ComponentFactory<DeletePopupNewsComponent>;

  public filterForm = new FormGroup({
    newCategory: new FormControl(undefined, [Validators.required]),
    previousCategory: new FormControl(undefined, Validators.required),
  });

  public constructor(
    public control: ModalControl<filterResponseInterface, FilterInputInterface>,
    private readonly _chRef: ChangeDetectorRef,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _popUpCtrl: PopUpController,
    private readonly _articleService: ArticleService,
  ) {
    super();
    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(DeletePopupNewsComponent);
  }

  public ngOnInit(): void {
    this.filterInputData = this.control.input;
    this.categoryList = this.filterInputData.categoryList!;
    this.userTypeList = this.filterInputData.userTypeList!;
    this.receivedNewsEventObj = this.filterInputData.newsEvents!;
    if (this.filterInputData.editFilter === NEWS_EVENTS.EDIT_FILTER) {
      this.editFilter();
    }
    this.receivedNewsEventObj?.forEach((element: NewsEvents) => {
      this.categoryListValues = [];
      this.categoryList.forEach(ele => {
        this.inputValue = ele.i18n?.en?.title == undefined ? BLANK_VALUE : ele.i18n?.en?.title;
        this.categoryListValues.push({
          category: this.inputValue,
        });

        if (element.category?.id === ele.id && this.existingCategoryList.indexOf(ele) === -1) {
          this.existingCategoryList.push(ele);
        }
      });
      this.userTypeList.forEach(ele => {
        element.userTypes?.forEach((usertype: UserType) => {
          if (usertype.id === ele.id && this.existingUserTypeList.indexOf(ele) === -1) {
            this.existingUserTypeList.push(ele);
          }
        });
      });
    });
    this._assignCategoryUsertypeList();
    this.initializeCategoryCheckbox(
      this.categoryList,
      this.existingCategoryList,
      this.checkBoxCategoryList,
    );
    this.initializeUserTypeCheckbox(
      this.userTypeList,
      this.existingUserTypeList,
      this.checkBoxUserTypeList,
    );
  }

  private _assignCategoryUsertypeList(): void {
    this.existingCategoryList?.forEach(() => {
      const temp = {
        filterListForm: {
          value: Boolean.FALSE,
        } as CheckboxInterface,
      };
      this.checkBoxCategoryList.push(temp);
      this._chRef.detectChanges();
    });
    this.existingUserTypeList?.forEach(() => {
      const temp = {
        filterListForm: {
          value: Boolean.FALSE,
        } as CheckboxInterface,
      };
      this.checkBoxUserTypeList.push(temp);
      this._chRef.detectChanges();
    });
  }

  public onChange(internalValue: boolean, i: number, listType: string): void {
    if (internalValue) {
      if (listType == NEWS_EVENTS.CATEGORY) {
        this.finalCategoryList.push(this.existingCategoryList[i]);
      } else if (listType == NEWS_EVENTS.USER_TYPE) {
        this.finalUserTypeList.push(this.existingUserTypeList[i]);
      }
    } else {
      if (listType == NEWS_EVENTS.CATEGORY) {
        this.finalCategoryList = this.finalCategoryList.filter(
          ele => ele.id != this.existingCategoryList[i].id,
        );
      } else if (listType == NEWS_EVENTS.USER_TYPE) {
        this.finalUserTypeList = this.finalUserTypeList.filter(
          ele => ele.userTypeId != this.existingUserTypeList[i].userTypeId,
        );
      }
    }
  }

  public initializeCategoryCheckbox(
    filterList: CategoryInterface[],
    existingList: CategoryInterface[],
    checkBoxList: ITestPageCheckbox[],
  ): void {
    filterList?.forEach((element: {id: number}) => {
      const index = existingList.findIndex(ele => element.id == ele?.id);
      this._chRef.detectChanges();
      checkBoxList[index] = {
        filterListForm: {
          value: Boolean.FALSE,
        } as CheckboxInterface,
      };
      this._chRef.detectChanges();
    });
  }

  public initializeUserTypeCheckbox(
    UserTypeList: UserType[],
    ExistingUserTypeList: UserType[],
    checkBoxList: ITestPageCheckbox[],
  ): void {
    UserTypeList?.forEach((element: {id: number}) => {
      const index = ExistingUserTypeList.findIndex(ele => element.id == ele?.id);
      this._chRef.detectChanges();
      checkBoxList[index] = {
        filterListForm: {
          value: Boolean.FALSE,
        } as CheckboxInterface,
      };
      this._chRef.detectChanges();
    });
  }

  public ifEnglishLang(): boolean {
    if (this.lang === NEWS_EVENTS.ENGLISH) {
      return true;
    } else if (this.lang === NEWS_EVENTS.BULGARIAN) {
      return false;
    }
    return false;
  }

  public clearFilter(): void {
    this.initializeCategoryCheckbox(
      this.categoryList,
      this.existingCategoryList,
      this.checkBoxCategoryList,
    );
    this.initializeUserTypeCheckbox(
      this.userTypeList,
      this.existingUserTypeList,
      this.checkBoxUserTypeList,
    );
  }

  public editFilter(): void {
    this.isEditFilter = !this.isEditFilter;
    this._chRef.detectChanges();
  }

  public cancelFilter(): void {
    if (this.filterInputData.editFilter === NEWS_EVENTS.EDIT_FILTER) {
      this.control.cancel();
    } else {
      this.isEditFilter = !this.isEditFilter;
    }
    this._chRef.detectChanges();
  }

  public applyFilter(): void {
    this.isCategoryChange = true;
    const updateCategoryList = this.categoryListValues.filter(
      cat => cat.isEditted === true && (cat.isAdded === undefined || cat.isAdded === false),
    );

    if (updateCategoryList && updateCategoryList.length > 0) {
      updateCategoryList.forEach(category => {
        const categoryObj = this.getCategoryObject(category.category);
        categoryObj.id = category.id;
        this.onCategoryUpdate(categoryObj);
        category.isEditted = false;
      });
    }

    const addCategoryList = this.categoryListValues.filter(cat => cat.isAdded === true);
    if (addCategoryList && addCategoryList.length > 0) {
      addCategoryList.forEach(cat => {
        const newCategoryObj = this.getCategoryObject(cat.category);
        this.addNewCategoryToList(newCategoryObj);
        cat.isAdded = false;
      });
    }

    this.filteredNewsEventsObj = {
      categoryList: this.finalCategoryList,
      userTypeList: this.finalUserTypeList,
    };
    this.control.confirm(this.filteredNewsEventsObj);
  }

  public addNewCategory(): void {
    const categoryObj = this.getCategoryObject(BLANK_VALUE);

    const max = this.categoryList.reduce((prev, current) =>
      prev.id && current.id && prev.id > current.id ? prev : current,
    );
    categoryObj.id = max.id ? max.id + 1 : 0;
    this.categoryList.push(categoryObj);

    this.categoryListValues.push({category: BLANK_VALUE, isAdded: true});
  }

  public addNewCategoryToList(categoryObj: CategoryInterface): void {
    this._articleService.updateNewCategory(categoryObj).subscribe((res: CategoryInterface) => {
      if (res.id && res.i18n) {
        const index = this.categoryList.findIndex(cat => cat.id === categoryObj.id);
        if (index > -1) {
          this.categoryList[index] = res;
        }
        this._chRef.detectChanges();
      }
    });
  }

  public removeCategory(categoryId: number): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: NEWS_EVENTS.DELETE_CATEGORY, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this._articleService.deleteCategory(categoryId).subscribe((res: CategoryInterface) => {
            const deletionIndex = this.categoryList.findIndex(category => category.id === res.id);
            if (deletionIndex >= -1) {
              this.categoryList.splice(deletionIndex, 1);
              this._chRef.detectChanges();
            }
          });
        }
      });
  }

  public get applyFilterDisabledCheck(): boolean {
    const categorySelected = this.checkBoxCategoryList.find(checkBoxPage =>
      checkBoxPage.filterListForm.value.asBoolean(),
    );
    const userTypeSelected = this.checkBoxUserTypeList.find(checkBoxPage =>
      checkBoxPage.filterListForm.value.asBoolean(),
    );
    return !(categorySelected || userTypeSelected);
  }

  public isGeneralCategory(category: string): boolean {
    return GENERAL === category ? TRUE : FALSE;
  }
  public onCategoryUpdate(categoryObj: CategoryInterface): void {
    // categoryObj!.i18n!.en!.title = this.categoryListValues[i];
    this._articleService.editCategory(categoryObj).subscribe((res: CategoryInterface) => {
      if (res.id && res.i18n) {
        const categoryIndex = this.categoryList.findIndex(cat => cat.id == categoryObj.id);
        this.categoryList[categoryIndex].i18n = res.i18n;
        this._chRef.detectChanges();
      } else console.log('response is empty');
    });
  }

  public onKeyUp(value: string, i: number, id: number) {
    this.categoryListValues[i].category = value;
    if (id === 0) {
      this.categoryListValues[i].isAdded = true;
    } else {
      this.categoryListValues[i].isEditted = true;
      this.categoryListValues[i].id = id;
    }
  }

  private getCategoryObject(value: string): CategoryInterface {
    const categoryTitleEn: string = value;
    const categoryTitleBg: string = value;
    return {
      i18n: {
        en: {
          title: categoryTitleEn,
        },
        bg: {
          title: categoryTitleBg,
        },
      },
    };
  }

  public clearCategory(): void {
    this.filterForm.reset();
    this.addCategory = false;
  }
}
