<njf-loader [hidden]="!showLoadingIcon"></njf-loader>
<header-with-breadcrumbs></header-with-breadcrumbs>
<div class="max-width-body">
  <div class="screen-content-wrapper">
    <form maiaForm [formGroup]="policyFilterForm">
      <maia-fluid-fluid-layout>
        <div class="m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column">
            <maia-label
              class="b2b-kdl-font-small-medium m-b-8 m-t-16 title-dark-color"
              i18n="@@nje-b2b-frontend-statistics-reporttype"
            >
              Report Type
            </maia-label>
            <maia-input-select name="reportType" formControlName="reportType">
              <input
                maiaInput
                placeholder="Select"
                i18n-placeholder="@@nje-b2b-frontend-statistics-select"
                [value]="policyFilterForm.controls.reportType.value?.title"
              />
              <maia-option *ngFor="let report of reportTypeList" [value]="report">{{
                report.title
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>

        <div class="m-r-16" *ngIf="showProductTypeFilter">
          <maia-form-element hideOptionalIndicator class="input-column">
            <maia-label
              class="b2b-kdl-font-small-medium m-b-8 m-t-16 title-dark-color"
              i18n="@@nje-b2b-frontend-statistics-producttype"
            >
              Product Type
            </maia-label>
            <maia-input-select name="productType" formControlName="productType">
              <input
                maiaInput
                [value]="getProductType(policyFilterForm.controls.productType.value)"
              />
              <maia-option *ngFor="let product of productTypeList" [value]="product">
                {{ this.getProductType(product) }}
              </maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>

        <div class="m-r-16">
          <maia-form-element
            [options]="{validationInstant: true}"
            hideOptionalIndicator
            class="input-column"
          >
            <maia-label
              class="b2b-kdl-font-small-medium m-b-8 m-t-16 title-dark-color"
              i18n="@@nje-b2b-frontend-statistics-fromdate"
              >From date</maia-label
            >
            <maia-input-date
              name="multipleStartDate"
              [minimum]="undefined"
              [maximum]="maxFromDate"
              formControlName="fromDate"
              (ngModelChange)="onChangeOfFromDate()"
              class="button-width"
            ></maia-input-date>
            <maia-validation
              type="error"
              *ngIf="
                policyFilterForm.controls['fromDate'].hasError('atlasBefore') ||
                policyFilterForm.controls['fromDate'].hasError('atlasInvalidCharacters') ||
                policyFilterForm.controls['fromDate'].hasError('atlasInvalidLength') ||
                policyFilterForm.controls['fromDate'].hasError('atlasInvalidYear') ||
                policyFilterForm.controls['fromDate'].hasError('atlasInvalidMonth') ||
                policyFilterForm.controls['fromDate'].hasError('atlasInvalidDay')
              "
              i18n="@@nje-b2b-frontend-statistics-validfromdate"
              >Please enter a valid From Date.</maia-validation
            >
          </maia-form-element>
        </div>

        <div class="m-r-16">
          <maia-form-element
            [options]="{validationInstant: true}"
            hideOptionalIndicator
            class="input-column"
          >
            <maia-label
              class="b2b-kdl-font-small-medium m-b-8 m-t-16 title-dark-color"
              i18n="@@nje-b2b-frontend-statistics-enddate"
            >
              To date
            </maia-label>
            <maia-input-date
              name="multipleStartDate"
              [minimum]="minToDate"
              [maximum]="maxToDate"
              formControlName="toDate"
              class="button-width"
            ></maia-input-date>
            <maia-validation
              type="error"
              *ngIf="
                policyFilterForm.controls['toDate'].hasError('atlasAfter') ||
                policyFilterForm.controls['toDate'].hasError('atlasInvalidCharacters') ||
                policyFilterForm.controls['toDate'].hasError('atlasInvalidLength') ||
                policyFilterForm.controls['toDate'].hasError('atlasInvalidYear') ||
                policyFilterForm.controls['toDate'].hasError('atlasInvalidMonth') ||
                policyFilterForm.controls['toDate'].hasError('atlasInvalidDay') ||
                policyFilterForm.controls['toDate'].hasError('atlasBefore')
              "
              i18n="@@nje-b2b-frontend-statistics-validafterdate"
            >
              Please enter a valid To Date.
            </maia-validation>
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>
    </form>
    <maia-divider class="m-t-b-24"></maia-divider>
    <div class="submit-btn">
      <maia-button-group>
        <button
          maiaButton
          maiaButtonType="primary"
          [disabled]="isSubmitButtonDisabled()"
          (click)="getReportDetails(reportFetchMode.VIA_SUBMIT_BUTTON)"
          i18n="@@nje-b2b-frontend-statistics-submit"
          >Submit
        </button>
      </maia-button-group>
    </div>
    <div *ngIf="showReportTableSection">
      <maia-fluid-fluid-layout>
        <div class="b2b-kdl-font-grand-bold title-dark-color"> {{ reportCountLabel }} </div>
        <div class="header-info">
          <div class="add-new-icon download-xls-icon" (click)="downloadReport()">
            <svg class="excel-svg" b2bFrontendActionExprXls></svg>
          </div>
        </div>
      </maia-fluid-fluid-layout>
      <div class="search-row">
        <maia-search-bar
          class="search-box"
          id="search"
          [placeholder]="searchBarPlaceholder"
          (searchText)="handleSearchText($event)"
          (inputText)="handleInputText($event)"
          (search)="searchTable()"
        >
        </maia-search-bar>

        <maia-input-select
          name="sort"
          class="search-dropdown text-width"
          [(ngModel)]="sortingDirection"
          (ngModelChange)="sortTable()"
        >
          <svg class="sort-icon" b2bFrontendActionFuncSort></svg>
          <input maiaInput [value]="getSortingList(sortingDirection || sortingDirectionList[0])" />
          <maia-option
            *ngFor="let sortingDirection of sortingDirectionList"
            [value]="sortingDirection"
          >
            {{ this.getSortingList(sortingDirection) }}</maia-option
          >
        </maia-input-select>
        <div *ngIf="isFilterButtonShown" class="filter-icon-circle" (click)="applyFilter()">
          <svg class="filter-svg" b2bFrontendActionFuncFilterCircle></svg>
        </div>
      </div>
      <maia-divider></maia-divider>

      <ng-container [ngSwitch]="selectedReportType">
        <div *ngSwitchCase="reportTypes.PolicyActivity">
          <maia-table allowHorizontalScroll allowVerticalScroll>
            <maia-table-header>
              <maia-table-row>
                <maia-table-cell
                  class="b2b-kdl-font-small subtitle-light-color"
                  *ngFor="let column of columnHeaders; let i = index"
                >
                  {{ this.getTableHeaders(column) }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-header>
            <maia-table-body>
              <maia-table-row
                *ngFor="let data of policyActivityReportData; let i = index"
                class="table-row-word-wrap"
              >
                <maia-table-cell>
                  {{ data.policy_no }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.created_date }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.product_code }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.transaction_no }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.invoice_no }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.amount }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.agent_no }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.insuring_party_name }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.insuring_party_email }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.delivery_address }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.insuring_party_phone }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.registered_user | localeVal: lang }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-body>
          </maia-table>
        </div>
        <div *ngSwitchCase="reportTypes.OfferActivity">
          <maia-table allowHorizontalScroll allowVerticalScroll>
            <maia-table-header>
              <maia-table-row>
                <maia-table-cell
                  class="b2b-kdl-font-small subtitle-light-color"
                  *ngFor="let column of columnHeaders; let i = index"
                >
                  {{ this.getTableHeaders(column) }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-header>
            <maia-table-body>
              <maia-table-row
                *ngFor="let data of offerActivityReportData; let i = index"
                class="table-row-word-wrap"
              >
                <maia-table-cell>
                  {{ data.offer_id }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.created_date }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.product_code }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.registered_user | localeVal: lang }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.policy_created | localeVal: lang }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.policy_number }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-body>
          </maia-table>
        </div>
        <div *ngSwitchCase="reportTypes.ClaimActivity">
          <maia-table allowHorizontalScroll allowVerticalScroll>
            <maia-table-header>
              <maia-table-row>
                <maia-table-cell
                  class="b2b-kdl-font-small subtitle-light-color"
                  *ngFor="let column of columnHeaders; let i = index"
                >
                  {{ this.getTableHeaders(column) }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-header>
            <maia-table-body>
              <maia-table-row
                *ngFor="let data of claimActivityReportData; let i = index"
                class="table-row-word-wrap"
              >
                <maia-table-cell>
                  {{ data.claim_id }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.created_date | date: 'dd-MM-yyyy' }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.policy_number }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.product_code }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.name }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.email_id }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.phone }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.registration_number }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-body>
          </maia-table>
        </div>
        <div *ngSwitchCase="reportTypes.UserActivity">
          <maia-table allowHorizontalScroll allowVerticalScroll>
            <maia-table-header>
              <maia-table-row>
                <maia-table-cell
                  class="b2b-kdl-font-small subtitle-light-color"
                  *ngFor="let column of columnHeaders; let i = index"
                >
                  {{ this.getTableHeaders(column) }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-header>
            <maia-table-body>
              <maia-table-row
                *ngFor="let data of userActivityReportData; let i = index"
                class="table-row-word-wrap"
              >
                <maia-table-cell>
                  {{ data.name }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.email }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.pid }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.phone }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.post_code }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.city }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.created_date | date: 'dd-MM-yyyy' }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.last_login_date | date: 'dd-MM-yyyy' }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.registered_user | localeVal: lang }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.has_direct_marketing_consent ? 'Yes' : ('No' | localeVal: lang) }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-body>
          </maia-table>
        </div>
        <div *ngSwitchCase="reportTypes.PaymentActivity">
          <maia-table allowHorizontalScroll allowVerticalScroll class="table-width">
            <maia-table-header>
              <maia-table-row>
                <maia-table-cell
                  class="b2b-kdl-font-small subtitle-light-color"
                  *ngFor="let column of columnHeaders; let i = index"
                >
                  {{ this.getTableHeaders(column) }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-header>
            <maia-table-body>
              <maia-table-row
                *ngFor="let data of paymentActivityReportData; let i = index"
                class="table-row-word-wrap"
              >
                <maia-table-cell>
                  {{ data.policy_number }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.installment_number }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.installment_amount }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.product_code }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.email }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.recipient_name }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.delivery_address }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.delivery_phone }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.registered_user | localeVal: lang }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.payment_provider | localeVal: lang }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.transaction_number }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.invoice_number }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.payment_date | date: 'dd-MM-yyyy' }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.status | localeVal: lang }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.payment_type | localeVal: lang }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.insis_source | localeVal: lang }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.covered_to }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.seller_code }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.seller_code?.substring(0, 3) }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.green_card }}
                </maia-table-cell>
                <maia-table-cell>
                  {{ data.sticker_no }}
                </maia-table-cell>
              </maia-table-row>
            </maia-table-body>
          </maia-table>
        </div>
      </ng-container>
      <maia-divider></maia-divider>
      <maia-pagination
        *ngIf="pages"
        [(page)]="page"
        [lastPage]="lastPage()"
        [totalPages]="pages"
        (pageChange)="pageChange($event)"
        general
      ></maia-pagination>
    </div>
  </div>
</div>
