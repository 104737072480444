import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesExprHouseInteriorSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendPlacesExprHouseInterior]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5 21.41L24 5.86l19 15.55"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M9.32 24v18.14h29.36V24"></svg:path><svg:path d="M32.33 31.2v-1.94a1.16 1.16 0 0 0-1.16-1.16h-5.81a1.16 1.16 0 0 0-1.17 1.16v1.94" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M30.78 32.75v.77h-5v-.77a1.54 1.54 0 0 0-1.55-1.55h0a1.55 1.55 0 0 0-1.55 1.55v3.87h11.2v-3.87a1.54 1.54 0 0 0-1.55-1.55h0a1.54 1.54 0 0 0-1.55 1.55z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M24.19 36.62v1.55"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M32.33 36.62v1.55"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M16.83 38.17v-9.74"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M18.38 21.12h-3.1L14.12 25v.77h5.42V25l-1.16-3.88z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M18.38 38.17h-3.1"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesExprHouseInteriorSvgComponent {
}

@NgModule({
  declarations: [
    PlacesExprHouseInteriorSvgComponent,
  ],
  exports: [
    PlacesExprHouseInteriorSvgComponent,
  ],
})
export class PlacesExprHouseInteriorSvgModule {}
