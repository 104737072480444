import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {
  ScreenFormComponent,
  FormGroupManagerFactoryService,
  PhoneNumberValueController,
  SHOW_ERROR_CHECK,
  DatePickerController,
} from '@njf-frontend-angular/flow-progress';
import {
  CoreApiService,
  NameAddressTranslationMapService,
  PartyIdService,
  PartyType,
  NationalityDto,
  personName,
  InsuredPerson,
  DateFormatterService,
  dateValidator,
  EgnValidatorService,
  BLANK_VALUE,
} from '@b2b-frontend/core';
import {ActivatedRoute} from '@angular/router';
import {DatepickerVisibleView} from '@maia/datepickers';
import {DateUtils, DateKey, ValidationErrors, ExtraDateValidators} from '@atlas/businesstypes';
import {map} from 'rxjs/operators';
import {InputPhonenumberComponent, PhoneNumberValue} from '@maia/input-phonenumber';
import {FormControl, Validators} from '@angular/forms';
import {FormData} from '../form-data.model';
import {COUNTRY_CODE, TEMP_CITIZIENHIP} from '../../../constants/constant';
import {ServerSideAutocompleteController} from '@njf-frontend-angular/flow-progress/types/form-manage/component/controller/ServerSideAutocompleteController';
import {ApiPersonalData} from '@b2b-frontend/core/src/core-helper/dto/property-insurance-dto/summary-dto/insurance-party-details-api-dto';
import {TravelPolicyService} from '../../../travel-core/services/travel-policy.service';

@Component({
  selector: 'nje-travel-insured-people-slidein-child',
  templateUrl: './travel-insured-people-slidein-child.component.html',
  styleUrls: ['./travel-insured-people-slidein-child.component.scss'],
})
export class TravelInsuredPeopleSlideinChildComponent
  extends ScreenFormComponent
  implements OnInit
{
  public isForeigner: boolean = false;
  public isNewPerson: boolean;

  public insuringPartyType: PartyType | undefined;

  public visibleDateView?: DatepickerVisibleView = undefined;
  public disableWeekends: boolean = false;

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Year, -150);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, -1);

  public MIN_BIRTH_DATE_AFTER = DateUtils.add(this.minDate, DateKey.Day, -1);
  public MAX_BIRTH_DATE_BEFORE = DateUtils.add(this.maxDate, DateKey.Day, 1);

  public citizenshipAutocomplete: ServerSideAutocompleteController<NationalityDto>;

  public birthDatePicker: DatePickerController;
  private phoneNumberController: PhoneNumberValueController;
  public data: InsuredPerson;
  public savedPersonIdNumber: string;

  @ViewChild('phoneInput', {static: false})
  protected set phoneInput(val: InputPhonenumberComponent) {
    setTimeout(
      () =>
        (this.phoneNumberController = this.stepForm.createPhoneNumberValue('phone', val, {
          disableCountryCode: true,
        })),
    );
  }

  @Output() public readonly updateInsuredPerson = new EventEmitter<InsuredPerson>();
  @Output() public readonly cancel = new EventEmitter();

  @Input()
  public set personData(personData: InsuredPerson) {
    this.data = {...personData};
  }

  public constructor(
    private readonly _coreApiService: CoreApiService,
    private readonly _nameAddressTrasMapService: NameAddressTranslationMapService,
    private readonly _partyId: PartyIdService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    public dateFormaterService: DateFormatterService,
    public _coreService: CoreApiService,
    route: ActivatedRoute,
    private readonly _egnAgeService: EgnValidatorService,
    private readonly _travelPolicyService: TravelPolicyService,
  ) {
    super(route);

    this.stepForm = this._formFactory.createFormManager<FormData>({
      idNumber: new FormControl(undefined, [
        Validators.required,
        this.idNumberValidator.bind(this),
      ]),
      firstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      middleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      lastName: new FormControl(undefined, [Validators.required, personName('bg')]),
      firstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      middleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      lastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      phone: new FormControl(undefined, [Validators.required]),
      citizenship: new FormControl(undefined, [Validators.required]),
      dob: new FormControl(undefined, [Validators.required]),
    });

    this.birthDatePicker = this.stepForm.createDatePicker('dob');
    this.citizenshipAutocomplete =
      this.stepForm.createAutocompleteWithServersideFiltering<NationalityDto>(
        'citizenship',
        (q: string) => this._coreApiService.getNationalities(q, true).pipe(map(res => res.data)),
      );

    this.stepForm.setControlShowErrorCheck('phone', SHOW_ERROR_CHECK.TOUCHED);
  }

  public ngOnInit(): void {
    this.isNewPerson = Object.keys(this.data).length === 0;
    if (!this.isNewPerson) {
      this.inputDisable();
      this._mapSessionData();
      this.savedPersonIdNumber = this.data.idNumber;
    }
    this._nameAddressTrasMapService.updateNameFieldTranslation(this.stepForm.formGroup);
  }

  private _mapSessionData(): void {
    const data = this.data;
    if (data) {
      const phoneNumberValue: PhoneNumberValue =
        typeof data.phoneNumber === 'string'
          ? {number: data.phoneNumber, prefix: COUNTRY_CODE.BG}
          : data.phoneNumber;
      this.stepForm.patchValue({
        idNumber: data.idNumber,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        firstNameEn: data.firstNameEn,
        middleNameEn: data.middleNameEn,
        lastNameEn: data.lastNameEn,
        phone: phoneNumberValue,
        dob:
          data.dob !== undefined && data.dob !== null && typeof data.dob === 'string'
            ? this.dateFormaterService.getAtlasDate(data.dob)
            : data.dob,
      });
      this.insuringPartyType = this._partyId.getType(data.idNumber);
      this.updateDynamicValidation();
    }
  }

  public idNumberValidator(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const insuringPartyType = this._partyId.getType(control.value);

      if (!insuringPartyType || insuringPartyType === PartyType.COMPANY) {
        this.isForeigner = false;
        return {invalidNumber: true};
      }

      if (insuringPartyType) {
        this.insuringPartyType = insuringPartyType;
        this.updateDynamicValidation();
        this.savedPersonIdNumber !== control.value
          ? this.fetchDataFromApi(control.value)
          : this._mapSessionData();
      }
    }
    return null;
  }

  public fetchDataFromApi(idNumber: string): void {
    this._coreService.getPersonData(idNumber).subscribe((result: ApiPersonalData) => {
      this.populatePersonDataDetailFromApi(result);
    });
  }

  public populatePersonDataDetailFromApi(res: ApiPersonalData): void {
    this.stepForm.patchValue({
      firstNameEn: res.personalData.firstName,
      middleNameEn: res.personalData.middleName,
      lastNameEn: res.personalData.surname,
      phone: {number: res.personalData.addresses[0].phoneNumber, prefix: COUNTRY_CODE.BG},
    });
  }

  public updateDynamicValidation(): void {
    this.isForeigner = this.insuringPartyType === PartyType.FOREIGNER ? true : false;
    if (this.insuringPartyType === PartyType.PERSON) {
      this.stepForm.controls.citizenship.setValidators(null);
      this.stepForm.controls.dob.setValidators(null);
    } else if (this.insuringPartyType === PartyType.FOREIGNER) {
      this.stepForm.controls.citizenship.setValidators([Validators.required]);
      this.stepForm.controls.dob.setValidators([
        Validators.required,
        dateValidator(),
        control => ExtraDateValidators.before(this.MAX_BIRTH_DATE_BEFORE)(control.value),
        control => ExtraDateValidators.after(this.MIN_BIRTH_DATE_AFTER)(control.value),
      ]);
    }
    this.stepForm.controls.citizenship.updateValueAndValidity();
    this.stepForm.controls.dob.updateValueAndValidity();
  }

  public saveMtplData(): void {
    this.savedPersonIdNumber =
      this.savedPersonIdNumber != null
        ? this.savedPersonIdNumber
        : this.stepForm.controls.idNumber.value;
    let citizenship: string = BLANK_VALUE;
    let age: number = 0;
    let dobString: string | undefined = BLANK_VALUE;
    if (this.insuringPartyType === PartyType.PERSON) {
      const birthdate = this._egnAgeService.getDateFromEGN(this.savedPersonIdNumber);
      dobString = this.dateFormaterService.getDDMMYYYYFormatDate(birthdate);
      age = this._travelPolicyService.calculateAge(birthdate);
    } else if (
      typeof this.birthDatePicker.value !== 'string' &&
      this.birthDatePicker.value != null
    ) {
      citizenship = TEMP_CITIZIENHIP;
      const birthdate = this.dateFormaterService.normalizeDate(this.birthDatePicker.value);
      if (birthdate) {
        dobString = this.dateFormaterService.getDDMMYYYYFormatDate(birthdate);
        age = this._travelPolicyService.calculateAge(birthdate);
      }
    }

    const insuredPersonData: InsuredPerson = {
      ...this.stepForm.value,
      idNumber: this.savedPersonIdNumber,
      phoneNumber: this.phoneNumberController.value,
      partyType: this.insuringPartyType,
      citizenship: citizenship,
      age: age,
      dob: dobString,
    };
    this.updateInsuredPerson.emit(insuredPersonData);
  }

  public cancelSlideIn(): void {
    this.cancel.emit();
  }

  public inputDisable(): void {
    this.stepForm.controls.idNumber.disable();
    this.stepForm.controls.dob.disable();
  }
}
