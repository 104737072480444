import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';

import {InstalmentPaymentsComponent} from './instalment-payments.component';
import {SearchPolicyComponent} from './search-policy/search-policy.component';
import {InstalmentComponent} from './instalment/instalment.component';
import {InsuredPartyComponent} from './insured-party/insured-party.component';
import {OverviewComponent} from './overview/overview.component';
import {PaymentComponent} from './payment/payment.component';
import {PaymentResultComponent} from './payment-result/payment-result.component';
import {RoutePaths} from '../../constants/route-paths';
import {StepResolverService} from '../../payments-core/services/step.resolver';
import {DebitPaymentSuccessComponent} from './debit-payment-success/debit-payment-success.component';

const routes: Routes = [
  {
    path: '',
    component: InstalmentPaymentsComponent,
    data: {
      njeB2BTitle: 'Payment of Instalment',
    },
    children: [
      {
        path: '',
        redirectTo: RoutePaths.SearchPolicy,
      },
      {
        path: RoutePaths.SearchPolicy,
        component: SearchPolicyComponent,
        data: {
          isStepperVisible: false,
          isHeaderVisible: false,
          stepId: RoutePaths.SearchPolicy,
          maiaFlowProgressId: RoutePaths.SearchPolicy,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: RoutePaths.Instalment,
        component: InstalmentComponent,
        data: {
          stepId: RoutePaths.Instalment,
          maiaFlowProgressId: RoutePaths.Instalment,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: RoutePaths.InsuredParty,
        component: InsuredPartyComponent,
        data: {
          stepId: RoutePaths.InsuredParty,
          maiaFlowProgressId: RoutePaths.InsuredParty,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: RoutePaths.Overview,
        component: OverviewComponent,
        data: {
          stepId: RoutePaths.Overview,
          maiaFlowProgressId: RoutePaths.Overview,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: RoutePaths.Payment,
        component: PaymentComponent,
        data: {
          stepId: RoutePaths.Payment,
          maiaFlowProgressId: RoutePaths.Payment,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: RoutePaths.PaymentDebitNote,
        component: PaymentComponent,
        data: {
          stepId: RoutePaths.PaymentDebitNote,
          maiaFlowProgressId: RoutePaths.PaymentDebitNote,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: RoutePaths.PaymentResult,
        component: PaymentResultComponent,
        data: {
          isStepperVisible: false,
        },
      },

      {
        path: RoutePaths.DebitPaymentSuccess,
        component: DebitPaymentSuccessComponent,
        data: {
          isStepperVisible: false,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InstalmentPaymentsRoutingModule {}

export const INSTALMENT_PAYMENTS: Route = {
  path: '',
  loadChildren: async () =>
    await import('./instalment-payments.module').then(module => module.InstalmentPaymentsModule),
};
