/* eslint-disable @typescript-eslint/unbound-method */
/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {DateKey, DateUtils, Boolean, Text} from '@atlas/businesstypes';
import {DatepickerVisibleView} from '@maia/datepickers';
import {
  DataService,
  PartyType,
  companyName,
  personName,
  CitizenshipDto,
  CoreApiService,
  NameAddressTranslationMapService,
  PartyIdService,
  DateFormatterService,
  MtplPersonalData,
  CompanyDataDto,
  CityApiData,
} from '@b2b-frontend/core';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';
import {map} from 'rxjs/operators';
import {VehicleOwnerFormData} from '../../../models/VehicleOwnerFormData';
import {Subscription} from 'rxjs';
import {PhoneNumberValue} from '@maia/input-phonenumber';
import {MOCK_PERSONS_ADDED, PersonsAdded} from './_tests_/mock-person-data';
import {MtplApiService} from '../../../mtpl-core/services/mtpl-api.service';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  BLANK_VALUE,
  CITY,
  COUNTRY_CODE,
  INSURING_DETAILS,
} from '../../../mtpl-constants.ts/constant';

@Component({
  selector: 'nje-vehicle-owner-layout',
  templateUrl: './vehicle-owner-layout.component.html',
  styleUrls: ['./vehicle-owner-layout.component.scss'],
})
@UntilDestroy()
export class VehicleOwnerLayoutComponent implements OnInit, OnDestroy {
  public isVehicleOwnerLNCH: boolean = false;
  public isSame = Boolean.FALSE;
  public isSameAddress = Boolean.FALSE;
  public isVehicleOwnerEIK: boolean = false;
  public searchEgn: Text;
  public vehicleOwnerId: Text;
  public isVehicleOwnerEGN: boolean = true;
  public insurancePartyType: PartyType | undefined;
  public sessionedFormValue: any;
  public initialForm: any;
  public textSearched: Text;
  public mockPersonsAdded: PersonsAdded[] = [...MOCK_PERSONS_ADDED];
  public phone: PhoneNumberValue;
  public retrievedVehicleOwnerDetails: MtplPersonalData;
  public retrievedCompanyDetails: CompanyDataDto;
  public cityArray: CityApiData[] = [];
  public personNotFound: boolean = false;
  public pattern: RegExp = /[a-zA-Z]/;

  public vehicleOwnerForm: FormGroupManager<VehicleOwnerFormData> =
    this._formFactory.createFormManager<VehicleOwnerFormData>({
      ownerIdNumber: new FormControl(undefined, [
        Validators.required,
        this.idNumberValidator.bind(this),
      ]),
      firstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      middleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      lastName: new FormControl(undefined, [Validators.required, personName('bg')]),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
      quarter: new FormControl(undefined, [personName('bg')]),
      street: new FormControl(undefined),
      streetNumber: new FormControl(undefined),
      block: new FormControl(undefined, [personName('bg')]),
      entrance: new FormControl(undefined, [personName('bg')]),
      floor: new FormControl(undefined, [personName('bg')]),
      apartment: new FormControl(undefined, [personName('bg')]),
      companyName: new FormControl(undefined, [Validators.required, companyName('bg')]),

      firstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      middleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      lastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      cityEn: new FormControl(undefined, [Validators.required]),
      postCodeEn: new FormControl(undefined, [Validators.required]),
      quarterEn: new FormControl(undefined, [personName('en')]),
      streetEn: new FormControl(undefined),
      streetNumberEn: new FormControl(undefined),
      blockEn: new FormControl(undefined, [personName('en')]),
      entranceEn: new FormControl(undefined, [personName('en')]),
      floorEn: new FormControl(undefined, [personName('en')]),
      apartmentEn: new FormControl(undefined, [personName('en')]),
      companyNameEn: new FormControl(undefined, [Validators.required, companyName('en')]),

      citizenship: new FormControl(undefined, [Validators.required]),
      dob: new FormControl(undefined, [Validators.required]),
      phoneNumber: new FormControl(undefined),
      emailId: new FormControl(undefined),
      personsAdded: new FormControl(1, [Validators.required]),
    });

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Year, -150);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, -1);
  public visibleDateView?: DatepickerVisibleView = undefined;

  public cpccontroller = this.vehicleOwnerForm.createCityAndPostCode('city', 'postCode');
  public cityAutoFill =
    this.vehicleOwnerForm.createAutocompleteWithServersideFiltering<CityApiData>(
      'city',
      (q: string) =>
        this._coreApiService.getCity(q, true).pipe(
          map((res: CityApiData[]) => {
            this.cityArray = res;
            return res;
          }),
        ),
    );

  public citizenShipAutocomplete =
    this.vehicleOwnerForm.createAutocompleteWithServersideFiltering<CitizenshipDto>(
      'citizenship',
      () => this._coreApiService.getCitizenship().pipe(map((res: CitizenshipDto[]) => res)),
    );

  public birthdatePicker = this.vehicleOwnerForm.createDatePicker('dob');
  public subscription: Subscription;

  public constructor(
    private readonly _coreApiService: CoreApiService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _nameAddressTrasMapService: NameAddressTranslationMapService,
    private readonly _partyId: PartyIdService,
    private readonly _dataService: DataService,
    public dateFormaterService: DateFormatterService,
    private readonly _mtplApiService: MtplApiService,
  ) {}

  public ngOnInit(): void {
    this._prepopulateCityField();

    this.initialForm = this.vehicleOwnerForm.value;
    this.subscription = this._dataService
      .getDetails()
      .pipe(takeUntilDestroyed(this))
      .subscribe(data => {
        this.isSame = data?.sameDetails;
        if (this.isSame.equals(Boolean.TRUE)) {
          this.vehicleOwnerForm.patchValue({
            ownerIdNumber: data.address.insuringIdNumber,
            firstName: data.address.firstName,
            middleName: data.address.middleName,
            lastName: data.address.lastName,
            firstNameEn: data.address.firstNameEn,
            middleNameEn: data.address.middleNameEn,
            lastNameEn: data.address.lastNameEn,
            companyName: data.address.companyName,
            companyNameEn: data.address.companyNameEn,
          });
          this.vehicleOwnerForm.controls.firstName.disable();
          this.vehicleOwnerForm.controls.middleName.disable();
          this.vehicleOwnerForm.controls.lastName.disable();
          this.vehicleOwnerForm.controls.firstNameEn.disable();
          this.vehicleOwnerForm.controls.middleNameEn.disable();
          this.vehicleOwnerForm.controls.lastNameEn.disable();
          this.vehicleOwnerForm.controls.companyName.disable();
          this.vehicleOwnerForm.controls.companyNameEn.disable();
          this.vehicleOwnerForm.controls.ownerIdNumber.disable();
        } else if (this.isSame.equals(Boolean.FALSE)) {
          this.vehicleOwnerForm.formGroup.enable();
          this.isSameAddress = Boolean.FALSE;
          this.phone = {number: BLANK_VALUE, prefix: COUNTRY_CODE};
          this.vehicleOwnerForm.reset();
          this.vehicleOwnerForm.controls.personsAdded.setValue(1);
        }
        this.sessionedFormValue = data.address;
      });

    this._nameAddressTrasMapService.updateNameFieldTranslation(this.vehicleOwnerForm.formGroup);
    this._nameAddressTrasMapService.updateAddressFieldTraslation(this.vehicleOwnerForm.formGroup);
    this._nameAddressTrasMapService.updateCompanyFieldTranslation(this.vehicleOwnerForm.formGroup);

    this.vehicleOwnerForm.controls.city.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((val: CityApiData) => {
        if (val !== null && val.city !== undefined && val.zip) {
          this.vehicleOwnerForm.patchValue({
            cityEn: val,
            postCode: val.zip,
            postCodeEn: val.zip,
          });
        }
      });

    this.vehicleOwnerForm.controls.cityEn.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((val: CityApiData) => {
        if (val !== null && val !== undefined) {
          this.vehicleOwnerForm.patchValue({
            postCodeEn: val.zip,
          });
        }
      });
  }

  private _prepopulateCityField(): void {
    this._coreApiService
      .getCity(INSURING_DETAILS.CITY_NAME)
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        (response: CityApiData[]) => {
          this.cityArray = response;
        },
        error => {
          console.log('Api Error', error);
        },
      );
  }

  public selectedPhoneNumber(data: any): void {
    this.vehicleOwnerForm.controls.phoneNumber.setValue(data);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public handleVehicleOwner(vehicleOwnerId: Text) {
    this.vehicleOwnerId = vehicleOwnerId;
    if (null != vehicleOwnerId && 8 <= vehicleOwnerId.asString().length) {
      this.insurancePartyType = this._partyId.getType(vehicleOwnerId.asString());
      this.isVehicleOwnerEGN = this.insurancePartyType === PartyType.PERSON;
      this.isVehicleOwnerEIK = this.insurancePartyType === PartyType.COMPANY;
      this.isVehicleOwnerLNCH = this.insurancePartyType === PartyType.FOREIGNER;
    } else if (vehicleOwnerId == null) {
      this.vehicleOwnerForm.reset();
      this.vehicleOwnerForm.controls.personsAdded.setValue(1);
      this.phone = {number: BLANK_VALUE, prefix: COUNTRY_CODE};
    }
    this.vehicleOwnerForm.controls.ownerIdNumber.setValue(vehicleOwnerId.asString());
  }

  public handleSearchedText(text: Text) {
    this.textSearched = text;
    if (this.isVehicleOwnerEGN || this.isVehicleOwnerLNCH) {
      this._mtplApiService
        .getPersonDetails(text.asString())
        .pipe(takeUntilDestroyed(this))
        .subscribe(
          (response: MtplPersonalData) => {
            this.retrievedVehicleOwnerDetails = response;
            this.populateRetrievedEGNLNCHDetails(response);
          },
          error => {
            this.personNotFound = true;
            console.log('Api Error', error);
          },
        );
    } else if (this.isVehicleOwnerEIK) {
      this._mtplApiService
        .getCompanyDetails(text.asString())
        .pipe(takeUntilDestroyed(this))
        .subscribe(
          (response: CompanyDataDto) => {
            this.retrievedCompanyDetails = response;
            this.populateRetrievedEIKDetails(response);
          },
          error => {
            this.personNotFound = true;
            console.log('Api Error', error);
          },
        );
    }
  }

  public populateRetrievedEGNLNCHDetails(data: MtplPersonalData): void {
    const phone: PhoneNumberValue = data.personalData.addresses
      ? typeof data.personalData.addresses[0].phoneNumber === 'string'
        ? {number: data.personalData.addresses[0].phoneNumber, prefix: COUNTRY_CODE}
        : BLANK_VALUE
      : this.vehicleOwnerForm.controls.phoneNumber.value;
    const city: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].city
      : BLANK_VALUE;
    const cityData: CityApiData | undefined = this.cityArray.find(element => element.city === city);
    if (
      data.personalData.firstName?.match(this.pattern) ||
      data.personalData.middleName?.match(this.pattern) ||
      data.personalData.surname?.match(this.pattern)
    ) {
      this.vehicleOwnerForm.patchValue({
        firstNameEn: data.personalData.firstName,
        middleNameEn: data.personalData.middleName,
        lastNameEn: data.personalData.surname,
      });
    } else {
      this.vehicleOwnerForm.patchValue({
        firstName: data.personalData.firstName,
        middleName: data.personalData.middleName,
        lastName: data.personalData.surname,
      });
    }
    this.populateAddressDetails(data);
    this.vehicleOwnerForm.patchValue({
      cityEn: cityData,
      emailId: data.personalData.addresses ? data.personalData.addresses[0].email : BLANK_VALUE,
      phoneNumber: phone,
    });
  }

  public populateAddressDetails(data: MtplPersonalData): void {
    const block: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].block
      : BLANK_VALUE;
    const entrance: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].entrance
      : BLANK_VALUE;
    const floor: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].floor
      : BLANK_VALUE;
    const apartment: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].apartment
      : BLANK_VALUE;
    const street: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].street
      : BLANK_VALUE;
    const streetNumber: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].streetNo
      : BLANK_VALUE;
    const quarter: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].quarter
      : BLANK_VALUE;
    if (
      block?.match(this.pattern) ||
      entrance?.match(this.pattern) ||
      floor?.match(this.pattern) ||
      street?.match(this.pattern) ||
      streetNumber?.match(this.pattern) ||
      quarter?.match(this.pattern)
    ) {
      this.vehicleOwnerForm.patchValue({
        blockEn: block,
        entranceEn: entrance,
        floorEn: floor,
        apartmentEn: apartment,
        streetEn: street,
        streetNumberEn: streetNumber,
        quarterEn: quarter,
      });
    } else {
      this.vehicleOwnerForm.patchValue({
        block: block,
        entrance: entrance,
        floor: floor,
        apartment: apartment,
        street: street,
        streetNumber: streetNumber,
        quarter: quarter,
      });
    }
  }

  public populateRetrievedEIKDetails(data: CompanyDataDto): void {
    let city: string | undefined = data.address?.settlement;
    if (data.address?.settlement === CITY) {
      city = INSURING_DETAILS.CITY_NAME;
    }
    const cityData: CityApiData | undefined = this.cityArray.find(element => element.city === city);
    this.populateCompanyAddress(data);
    this.vehicleOwnerForm.patchValue({
      city: cityData,
      emailId: data.email,
    });
  }

  public populateCompanyAddress(data: CompanyDataDto): void {
    const companyName: string | undefined = data.name;
    const block: string | undefined = data.address?.block;
    const entrance: string | undefined = data.address?.entrance;
    const floor: string | undefined = data.address?.floor;
    const apartment: string | undefined = data.address?.apartment;
    const street: string | undefined = data.address?.street;
    const streetNumber: string | undefined = data.address?.streetNumber;
    if (
      block?.match(this.pattern) ||
      entrance?.match(this.pattern) ||
      floor?.match(this.pattern) ||
      companyName?.match(this.pattern) ||
      apartment?.match(this.pattern) ||
      street?.match(this.pattern) ||
      streetNumber?.match(this.pattern)
    ) {
      this.vehicleOwnerForm.patchValue({
        companyNameEn: companyName,
        streetEn: street,
        streetNumberEn: streetNumber,
        blockEn: block,
        entranceEn: entrance,
        floorEn: floor,
        apartmentEn: apartment,
      });
    } else {
      this.vehicleOwnerForm.patchValue({
        companyName: companyName,
        street: street,
        streetNumber: streetNumber,
        block: block,
        entrance: entrance,
        floor: floor,
        apartment: apartment,
      });
    }
  }

  public idNumberValidator(control: FormControl) {
    if (null != control.value && 8 <= control.value.length) {
      this.insurancePartyType = this._partyId.getType(control.value);
      this.isVehicleOwnerEGN = this.insurancePartyType === PartyType.PERSON;
      this.isVehicleOwnerEIK = this.insurancePartyType === PartyType.COMPANY;
      this.isVehicleOwnerLNCH = this.insurancePartyType === PartyType.FOREIGNER;
    }
    if (control.value && !this._partyId.getType(control.value)) {
      return {invalidNumber: true};
    }
    return null;
  }

  public vehicleOwnerAddress(): boolean {
    if (this.isSame.equals(Boolean.TRUE) && this.isSameAddress.equals(Boolean.TRUE)) {
      if (this.sessionedFormValue.city === null) {
        this.sessionedFormValue.city = BLANK_VALUE;
      }
      const cityData: CityApiData | undefined = this.cityArray.find(
        element => element.city === this.sessionedFormValue.city.city,
      );
      this.phone = this.sessionedFormValue.phoneNumber;
      this.vehicleOwnerForm.patchValue({
        city: cityData,
        cityEn: cityData,
        quarter: this.sessionedFormValue.quarter,
        quarterEn: this.sessionedFormValue.quarterEn,
        street: this.sessionedFormValue.street,
        streetEn: this.sessionedFormValue.streetEn,
        streetNumber: this.sessionedFormValue.streetNumber,
        streetNumberEn: this.sessionedFormValue.streetNumberEn,
        block: this.sessionedFormValue.block,
        blockEn: this.sessionedFormValue.blockEn,
        entrance: this.sessionedFormValue.entrance,
        entranceEn: this.sessionedFormValue.entranceEn,
        floor: this.sessionedFormValue.floor,
        floorEn: this.sessionedFormValue.floorEn,
        apartment: this.sessionedFormValue.apartment,
        apartmentEn: this.sessionedFormValue.apartmentEn,
        emailId: this.sessionedFormValue.emailId,
        citizenship: this.sessionedFormValue.citizenship,
        dob: this.sessionedFormValue.dob,
      });
      this.disableInputs();
    } else if (this.isSameAddress.equals(Boolean.FALSE)) {
      this.vehicleOwnerForm.patchValue({
        city: this.initialForm.city,
        cityEn: this.initialForm.cityEn,
        postCode: BLANK_VALUE,
        postCodeEn: BLANK_VALUE,
        quarter: BLANK_VALUE,
        quarterEn: BLANK_VALUE,
        street: BLANK_VALUE,
        streetEn: BLANK_VALUE,
        streetNumber: BLANK_VALUE,
        streetNumberEn: BLANK_VALUE,
        block: BLANK_VALUE,
        blockEn: BLANK_VALUE,
        entrance: BLANK_VALUE,
        entranceEn: BLANK_VALUE,
        floor: BLANK_VALUE,
        floorEn: BLANK_VALUE,
        apartment: BLANK_VALUE,
        apartmentEn: BLANK_VALUE,
        emailId: BLANK_VALUE,
        citizenship: BLANK_VALUE,
        dob: BLANK_VALUE,
      });
      this.phone = {number: BLANK_VALUE, prefix: COUNTRY_CODE};
      this.disableInputs();
    } else if (this.isSame.equals(Boolean.FALSE)) {
      this.vehicleOwnerForm.reset();
    }
    return this.isSameAddress.asBoolean();
  }

  public disableInputs() {
    if (this.isSameAddress.equals(Boolean.TRUE)) {
      this.vehicleOwnerForm.controls.city.disable();
      this.vehicleOwnerForm.controls.cityEn.disable();
      this.vehicleOwnerForm.controls.postCode.disable();
      this.vehicleOwnerForm.controls.postCodeEn.disable();
      this.vehicleOwnerForm.controls.quarter.disable();
      this.vehicleOwnerForm.controls.quarterEn.disable();
      this.vehicleOwnerForm.controls.street.disable();
      this.vehicleOwnerForm.controls.streetEn.disable();
      this.vehicleOwnerForm.controls.streetNumber.disable();
      this.vehicleOwnerForm.controls.streetNumberEn.disable();
      this.vehicleOwnerForm.controls.block.disable();
      this.vehicleOwnerForm.controls.blockEn.disable();
      this.vehicleOwnerForm.controls.entrance.disable();
      this.vehicleOwnerForm.controls.entranceEn.disable();
      this.vehicleOwnerForm.controls.floor.disable();
      this.vehicleOwnerForm.controls.floorEn.disable();
      this.vehicleOwnerForm.controls.apartment.disable();
      this.vehicleOwnerForm.controls.apartmentEn.disable();
      this.vehicleOwnerForm.controls.phoneNumber.disable();
      this.vehicleOwnerForm.controls.emailId.disable();
      this.vehicleOwnerForm.controls.citizenship.disable();
      this.vehicleOwnerForm.controls.dob.disable();
    } else {
      this.vehicleOwnerForm.controls.city.enable();
      this.vehicleOwnerForm.controls.cityEn.enable();
      this.vehicleOwnerForm.controls.postCode.enable();
      this.vehicleOwnerForm.controls.postCodeEn.enable();
      this.vehicleOwnerForm.controls.quarter.enable();
      this.vehicleOwnerForm.controls.quarterEn.enable();
      this.vehicleOwnerForm.controls.street.enable();
      this.vehicleOwnerForm.controls.streetEn.enable();
      this.vehicleOwnerForm.controls.streetNumber.enable();
      this.vehicleOwnerForm.controls.streetNumberEn.enable();
      this.vehicleOwnerForm.controls.block.enable();
      this.vehicleOwnerForm.controls.blockEn.enable();
      this.vehicleOwnerForm.controls.entrance.enable();
      this.vehicleOwnerForm.controls.entranceEn.enable();
      this.vehicleOwnerForm.controls.floor.enable();
      this.vehicleOwnerForm.controls.floorEn.enable();
      this.vehicleOwnerForm.controls.apartment.enable();
      this.vehicleOwnerForm.controls.apartmentEn.enable();
      this.vehicleOwnerForm.controls.phoneNumber.enable();
      this.vehicleOwnerForm.controls.emailId.enable();
      this.vehicleOwnerForm.controls.citizenship.enable();
      this.vehicleOwnerForm.controls.dob.enable();
    }
  }

  public getVehicleOwnerDetails(): FormGroupManager<VehicleOwnerFormData> {
    if (this.vehicleOwnerForm != null) {
      if (this.isVehicleOwnerEGN) {
        this.vehicleOwnerForm.controls.companyName?.setErrors(null);
        this.vehicleOwnerForm.controls.companyNameEn?.setErrors(null);
        this.vehicleOwnerForm.controls.citizenship?.setErrors(null);
        this.vehicleOwnerForm.controls.dob?.setErrors(null);
      } else if (this.isVehicleOwnerLNCH) {
        this.vehicleOwnerForm.controls.companyName?.setErrors(null);
        this.vehicleOwnerForm.controls.companyNameEn?.setErrors(null);
      } else if (this.isVehicleOwnerEIK) {
        this.vehicleOwnerForm.controls.citizenship?.setErrors(null);
        this.vehicleOwnerForm.controls.dob?.setErrors(null);
        this.vehicleOwnerForm.controls.firstName?.setErrors(null);
        this.vehicleOwnerForm.controls.firstNameEn?.setErrors(null);
        this.vehicleOwnerForm.controls.middleName?.setErrors(null);
        this.vehicleOwnerForm.controls.middleNameEn?.setErrors(null);
        this.vehicleOwnerForm.controls.lastName?.setErrors(null);
        this.vehicleOwnerForm.controls.lastNameEn?.setErrors(null);
      }
    }
    return this.vehicleOwnerForm;
  }

  public setVehicleOwnerDetails(formData: VehicleOwnerFormData): void {
    this.vehicleOwnerForm.patchValue(formData);
  }
}
