/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

import {InsuringFormData} from '../../models/InsuringFormData';
import {VehicleOwnerFormData} from '../../models/VehicleOwnerFormData';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {SlideInController} from '@maia/slide-ins';
import {FormGroupManager, ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {MtplRoutePaths} from '../../helper/mtpl-route-paths';
import {MtplAddInsuringPartySlideinComponent} from './mtpl-add-insuring-party-slidein/mtpl-add-insuring-party-slidein.component';
import {MtplInsuringPartySlideinComponent} from './mtpl-insuring-party-slidein/mtpl-insuring-party-slidein.component';
import {INSURING_PARTY_INFO_TABLE_COL_HEADERS} from './__tests__/insuring-party.mock';
import {InsuringPartyLayoutComponent} from './insuring-party-layout/insuring-party-layout.component';
import {VehicleOwnerLayoutComponent} from './vehicle-owner-layout/vehicle-owner-layout.component';
import {DataService, MtplInsuredParty, MtplInsuringPartyDto} from '@b2b-frontend/core';
import {PopUpController} from '@maia/pop-ups';
import {RelatedPartyDeletePopupComponent} from './delete-popup/delete-popup.component';
import {Boolean} from '@atlas/businesstypes';
import {INSURING_DETAILS} from '../../mtpl-constants.ts/constant';

@Component({
  selector: 'nje-owner-insurer-details',
  templateUrl: './owner-insurer-details.component.html',
  styleUrls: ['./owner-insurer-details.component.scss'],
})
@UntilDestroy()
export class OwnerInsurerDetailsComponent
  extends ScreenStepComponent
  implements AfterViewInit, OnInit
{
  public columnHeaders: string[] = INSURING_PARTY_INFO_TABLE_COL_HEADERS;
  public insuringPartyDetailsForm: FormGroupManager<InsuringFormData>;
  public vehicleOwnerDetailsForm: FormGroupManager<VehicleOwnerFormData>;
  private readonly _slideInOneFactory: ComponentFactory<MtplInsuringPartySlideinComponent>;
  private readonly _addSlideInOneFactory: ComponentFactory<MtplAddInsuringPartySlideinComponent>;
  public addedInsuringPartyArray: MtplInsuredParty[] = [];
  private readonly _popUpDeleteFactory: ComponentFactory<RelatedPartyDeletePopupComponent>;
  public insuringPartyDto: MtplInsuringPartyDto;
  public addInsuringPartyDetails: MtplInsuredParty;
  public isSame: Boolean;

  @ViewChild(InsuringPartyLayoutComponent)
  public insuringPartyDetails: InsuringPartyLayoutComponent;

  @ViewChild(VehicleOwnerLayoutComponent)
  public vehicleOwnerDetails: VehicleOwnerLayoutComponent;

  public constructor(
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _popUpCtrl: PopUpController,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _dataService: DataService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
    super(activatedRoute);
    this._slideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      MtplInsuringPartySlideinComponent,
    );

    this._addSlideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      MtplAddInsuringPartySlideinComponent,
    );

    this._popUpDeleteFactory = this._componentFactoryResolver.resolveComponentFactory(
      RelatedPartyDeletePopupComponent,
    );
  }

  public ngAfterViewInit(): void {
    this.insuringPartyDetailsForm = this.insuringPartyDetails?.getInsuringPartyDetails();
    this.vehicleOwnerDetailsForm = this.vehicleOwnerDetails?.getVehicleOwnerDetails();
  }

  public ngOnInit(): void {
    this._dataService.getDetails().subscribe(data => {
      this.isSame = data.sameDetails;
    });
  }

  public openSlideIn(): void {
    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {title: INSURING_DETAILS.TEMPORARY_DATA}, //TODO: It will replaced in future
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.insuringPartyDto,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<MtplInsuringPartyDto>) => {
        if (res.resolution == ModalResolution.CONFIRMED) {
          const insuringPersonData = res.result;
          console.log(insuringPersonData);
        }
      });
  }

  public addNewDetails(): void {
    this.openAddInsuringPartySlideIn();
  }

  public editInsuringPartyDetails(data: MtplInsuredParty, index: number): void {
    this.openAddInsuringPartySlideIn(data, index);
  }

  public removeInsuringParty(index: number): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: INSURING_DETAILS.DELETE_DATA, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this.addedInsuringPartyArray.splice(index, 1);
        }
        this._cdRef.detectChanges();
      });
  }

  public openAddInsuringPartySlideIn(data?: MtplInsuredParty, index?: number): void {
    const slideInTitle = data ? INSURING_DETAILS.EDIT_DETAILS : INSURING_DETAILS.ADD_NEW_DEATILS;
    this._slideInCtrl
      .prepare(
        this._addSlideInOneFactory,
        this._injector,
        {title: slideInTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: data,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<MtplInsuredParty>) => {
        if (response.resolution == ModalResolution.CONFIRMED) {
          const insuringPersonData = response.result;
          if (insuringPersonData.relatedParty == INSURING_DETAILS.LESSEE) {
            this.isSame = Boolean.FALSE;
          }
          if ((response && index) || index == 0) {
            this.addedInsuringPartyArray[index] = insuringPersonData;
          } else {
            this.addedInsuringPartyArray.push(insuringPersonData);
          }
          if (
            (insuringPersonData.idNumber === null || insuringPersonData.idNumber === undefined) &&
            (index || index === 0)
          ) {
            this.addedInsuringPartyArray.splice(index, 1);
          }
        }
        this._cdRef.detectChanges();
      });
  }

  public onContinue(): void {
    this.router.navigate(['../', MtplRoutePaths.MTPL_POLICY_DETAILS], {
      relativeTo: this.activatedRoute,
    });
  }

  public isInsuringPartyDetailsValid(): boolean {
    if (null != this.insuringPartyDetails && null != this.vehicleOwnerDetails) {
      this.insuringPartyDetailsForm = this.insuringPartyDetails.getInsuringPartyDetails();
      this.vehicleOwnerDetailsForm = this.vehicleOwnerDetails.getVehicleOwnerDetails();
      return this.insuringPartyDetailsForm.valid && this.vehicleOwnerDetailsForm.valid;
    } else return false;
  }

  public saveInsuringPartyData(): void {
    const insuringPartyData: InsuringFormData = this.insuringPartyDetailsForm?.value;
    if (
      (this.vehicleOwnerDetails.isSame.equals(Boolean.TRUE) &&
        this.vehicleOwnerDetails.isSameAddress.equals(Boolean.TRUE)) ||
      (this.vehicleOwnerDetails.isSame.equals(Boolean.TRUE) &&
        this.vehicleOwnerDetails.isSameAddress.equals(Boolean.FALSE))
    ) {
      const vehicleOwnerData: VehicleOwnerFormData =
        this.vehicleOwnerDetailsForm?.formGroup.getRawValue();
      //TODO: statement as it will be removed as part of the service integration
      console.log(vehicleOwnerData, insuringPartyData);
    } else {
      const vehicleOwnerData: VehicleOwnerFormData = this.vehicleOwnerDetailsForm?.value;
      //TODO: console statement as it will be removed as part of the service integration
      console.log(vehicleOwnerData, insuringPartyData);
    }
    this.saveAndContinue();
  }

  protected saveMtplData(): void {}
}
