<p class="b2b-kdl-font-medium-bold">Travel Once</p>

<p class="b2b-kdl-font-normal">
  As the name suggests, a single trip travel insurance policy provides coverage for one domestic or
  international trip. The policy will last until you return home from your trip. All insurance
  companies have a cap on the number of days that are to be counted as a single trip.
</p>

<p class="b2b-kdl-font-normal">
  If you are planning to take only one vacation in a year, then a single trip insurance plan is the
  best fit. If you are planning to travel frequently, you should go for multi-trip insurance. An
  annual travel insurance policy will offer your coverage for an entire year from the date your
  policy starts.
</p>

<maia-divider class="m-t-b-24"></maia-divider>

<p class="b2b-kdl-font-medium-bold">Travel Multiple Times</p>

<p class="b2b-kdl-font-normal">
  If you love to travel or your work requires frequent travelling, an Frequent travel cover could
  save you both time and money. Buying annual Multi travel insurance is certainly a better choice,
  than buying a single travel insurance policy every time you travel abroad. This kind of a policy
  can cover you for all your trips (up to a maximum of 30 days) in a year with the same
  comprehensive level of cover.
</p>

<p class="b2b-kdl-font-normal">
  Multi travel insurance gives you the ability of travel with peace of mind, knowing that you have
  your policy to take care of any kind of eventuality while you travel each time.
</p>
