<div class="groups-and-modules-wrapper">
  <div class="title-wrapper">
    <h3 class="b2b-kdl-font-normal b2b-kdl-color-primary-dark-120"> Groups And Modules </h3>
  </div>
  <div class="action-group-wrapper">
    <maia-action-group>
      <button maiaActionTile class="tile-size" routerLink="../new-group">
        <svg b2bFrontendSocialExprPeople maia-tile-icon class="icon-color"></svg>
        <span maia-tile-label>Add New Group </span>
      </button>
      <button maiaActionTile class="tile-size" (click)="editExistingGroup()">
        <svg b2bFrontendSocialExprGroup maia-tile-icon class="icon-color m-l-24"></svg>
        <span maia-tile-label>Edit Existing Group </span>
      </button>
    </maia-action-group>
  </div>
</div>
