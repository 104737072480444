export enum RoutePaths {
  B2B = 'b2b',
  B2C = 'b2c',
  ACCOUNT_STATUS = 'account-status',
  ACTIVATE_ACCOUNT = 'activate-account',
  CREATE_ACCOUNT = 'create-account',
  EDIT_ACCOUNT = 'edit-account',
  DEACTIVATE_ACCOUNT = 'deactivate-account',
  RESET_PASSWORD = 'reset-password',
  SUSPEND_ACCOUNT = 'suspend-account',
  GROUPS_AND_MODULES = 'groups-and-modules',
}
