<form [formGroup]="phoneNumberForm.formGroup">
  <maia-fluid-fluid-layout [ngClass]="{'insurance-party-details': flexDirection === 'column'}">
    <div [ngClass]="{'fluid m-r-16': flexDirection === 'row'}">
      <maia-form-element class="multi-input-row" label="Phone Number">
        <div
          [class.p-maia-form-element--show-validation]="
            phoneNumberForm.hasToShowErrors('phoneNumber')
          "
        >
          <maia-input-phonenumber
            name="phone"
            formControlName="phoneNumber"
            #phoneInput
            ngDefaultControl
            class="disable-country-code"
          >
            <maia-phonenumber-country prefix="+359" name="Bulgaria">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilBulgaria></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+32" name="Belgium">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilBelgium></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+36" name="Czech Republic">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilCzechRepublic></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+420" name="Slovakia">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilSlovakia></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+421" name="Hungary">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilHungary></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+49" name="Germany">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilGermany></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+33" name="France">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilFrance></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+31" name="The Netherlands">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilNetherlands></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+93" name="Afghanistan">
              <ng-template #flag>
                <svg b2bFrontendInformationFuncFlag></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+355" name="Albania">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilAlbania></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+213" name="Algeria">
              <ng-template #flag>
                <svg b2bFrontendInformationFuncFlag></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+376" name="Andorra">
              <ng-template #flag>
                <svg b2bFrontendFlagUtilAndorra></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+244" name="Angola">
              <ng-template #flag>
                <svg b2bFrontendInformationFuncFlag></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+672" name="Antarctica">
              <ng-template #flag>
                <svg b2bFrontendInformationFuncFlag></svg>
              </ng-template>
            </maia-phonenumber-country>
            <maia-phonenumber-country prefix="+54" name="Argentina">
              <ng-template #flag>
                <svg b2bFrontendInformationFuncFlag></svg>
              </ng-template>
            </maia-phonenumber-country>
          </maia-input-phonenumber>
          <!-- <div class="validation-container" *ngIf="phoneNumberForm.hasToShowErrors('phoneNumber')">
            <maia-validation *ngIf="bgValidation"> Phone number invalid </maia-validation>
          </div> -->
        </div>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>
</form>
