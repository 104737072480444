import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PathResolver as AtlasPathResolver} from '@atlas-angular/connector';
import {LoggerFactory} from '@atlas-angular/logger';
import {Call} from '@atlas/convertor';
import {Logger} from '@atlas/logger';
import {Configuration} from '@hermes/configuration';
import {isAuthenticatedHermesCall, isContextualHermesCall, isLogonHermesCall} from '@hermes/core';

import {isNJECall} from './path-identifier';

const DEFAULT_VERSION = '1';

export class BackendConfiguration extends Configuration {
  public serviceUrl: string;
  public saiUrl: string;
}

@Injectable()
export class PathResolver implements AtlasPathResolver {
  private readonly _logger: Logger;
  public constructor(
    private readonly _configuration: BackendConfiguration,
    private readonly _loggerFactory: LoggerFactory,
  ) {
    this._logger = this._loggerFactory.createLogger('PathResolver');
  }

  public resolve(call: Call<any, any>): HttpRequest<null> | null {
    const {identifier} = call;
    this._logger.info('resolving', identifier);
    if (
      isNJECall(identifier) ||
      isAuthenticatedHermesCall(identifier) ||
      isContextualHermesCall(identifier)
    ) {
      return this._createRequest(
        this._configuration.serviceUrl,
        identifier.service,
        identifier.version,
      );
    } else if (isLogonHermesCall(identifier)) {
      return this._createRequest(this._configuration.saiUrl, identifier.service);
    }
    return null;
  }

  private _createRequest(
    baseUrl: string,
    service?: string,
    version: string = DEFAULT_VERSION,
  ): HttpRequest<null> {
    const url = `${baseUrl}${service}/${version}?cb=${Date.now()}`;
    return new HttpRequest('POST', url, null);
  }
}
