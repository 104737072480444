/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Hidden, Text} from '@atlas/businesstypes';
import {ApiService, localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {FileUploaderController, FileUploaderSlideInConfig, Reference} from '@hermes/file-uploader';
import {
  ModalConfirmedResult,
  ModalContentComponent,
  ModalControl,
  ModalResolution,
} from '@maia/modals';
import {SlideInController} from '@maia/slide-ins';

import {EDIT, Language, SELECT} from '../../../../constants/lang';
import {
  DESCRIPTION_LENGTH,
  DESCRIPTION_PATTERN,
  TITLE_LENGTH,
  TITLE_PATTERN,
} from '../../../../constants/resource-management/constant';
import {
  UPLOAD_FILE_CONSTRAINT_TEXT,
  UPLOAD_FILE_SIZE,
} from '../../../../constants/resource-management/contacts';
import {CMS_FILE_UPLOADER} from '../../../../constants/resource-management/file-uploader';
import {
  AdminLibraryDocumentDto,
  CreateOrUpdateAdminLibraryDocumentInput,
  UserType,
} from '../../../../contracts/admin-documents.interface';
import {AdminDocumentUploadResponse} from '../../../../model/admin-document/AdminDocumentUploadResponse';
import {UsertypeSelectionSlideinComponent} from '../../shared/usertype-selection-slidein/usertype-selection-slidein.component';

const lang: string | null = sessionStorage.getItem('lang');
const constraintText = new localeValPipe(new LocalizationService()).transform(
  UPLOAD_FILE_CONSTRAINT_TEXT,
  lang,
);
const uploadFileTitle = new localeValPipe(new LocalizationService()).transform(
  'Upload Document',
  lang,
);
const DEFAULT_MIME_TYPES =
  /^image\/jpeg|^video\/mp4|^application\/pdf|^application\/vnd.openxmlformats-officedocument.wordprocessingml.document|^application\/msword|^application\/vnd.ms-excel|^application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet|^application\/vnd.ms-powerpoint|^application\/vnd.openxmlformats-officedocument.presentationml.presentation$/;

@Component({
  selector: 'document-slidein',
  templateUrl: './document-slidein.component.html',
  styleUrls: ['./document-slidein.component.scss'],
  providers: [FileUploaderController],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class DocumentSlideinComponent
  extends ModalContentComponent<CreateOrUpdateAdminLibraryDocumentInput, AdminLibraryDocumentDto>
  implements OnInit
{
  public DocumentSlideinForm: FormGroup;
  public adminLibraryObj: AdminLibraryDocumentDto;
  public DocId: number;
  public selectedUsers: string | undefined = '';
  public selectedUserList: UserType[] = [];
  public lang: string | null = sessionStorage.getItem('lang');
  public edit: string = new localeValPipe(new LocalizationService()).transform(EDIT, this.lang);
  public select: string = new localeValPipe(new LocalizationService()).transform(SELECT, this.lang);
  private readonly _userTypeSlideinFactory: ComponentFactory<UsertypeSelectionSlideinComponent>;
  public filesToUpload = 1;
  public documents: string = CMS_FILE_UPLOADER.ADMIN_DOCUMENT;
  public uploadedFilesBasic: Reference[] = [];
  public slideInConfig: FileUploaderSlideInConfig = {
    title: new Text(uploadFileTitle),
    mimeTypes: DEFAULT_MIME_TYPES,

    maxNumberOfFiles: 1,
    maxFileSize: UPLOAD_FILE_SIZE,
    dropZones: [
      {
        constraintText,
      },
    ],
    afterDownload: (reference: Reference) => {},
    afterUpload: (references: Reference[]) => {
      if (references && references.length > 0) {
        const idRef: Hidden = references[0].id;
        const nameRef: Text = references[0].name;
        const typeRef: Text = references[0].type;
        const adminDocumentUploadResponse: AdminDocumentUploadResponse = {
          action: CMS_FILE_UPLOADER.UPLOAD,
          imageSide: this.documents,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        };
        this.uploadedFilesBasic.push({id: idRef, name: nameRef, type: typeRef});
        this.DocId = adminDocumentUploadResponse.id as unknown as number;
      }
    },
    afterDelete: (reference: Reference) => {
      if (reference) {
        const idRef: Hidden = reference.id;
        const nameRef: Text = reference.name;
        const typeRef: Text = reference.type;
        const adminDocumentUploadResponse: AdminDocumentUploadResponse = {
          action: CMS_FILE_UPLOADER.DELETE,
          imageSide: this.documents,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        };
        this.uploadedFilesBasic.pop();
        this.DocId = adminDocumentUploadResponse.id as unknown as number;
      }
    },
  };

  public titleLimit: number = TITLE_LENGTH;
  public descriptionLimit: number = DESCRIPTION_LENGTH;

  public constructor(
    private readonly _formBuilder: FormBuilder,
    public control: ModalControl<CreateOrUpdateAdminLibraryDocumentInput, AdminLibraryDocumentDto>,
    private readonly _slideInCtrl: SlideInController,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly chRef: ChangeDetectorRef,
    private readonly _apiService: ApiService,
  ) {
    super();
    this._userTypeSlideinFactory = this._componentFactoryResolver.resolveComponentFactory(
      UsertypeSelectionSlideinComponent,
    );
  }

  public openUsertype(): void {
    this._slideInCtrl
      .prepare(
        this._userTypeSlideinFactory,
        this._injector,
        {
          title: new localeValPipe(new LocalizationService()).transform(
            'Select Account',
            this.lang,
          ),
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.selectedUserList,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<UserType[]>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this.selectedUserList = response.result;
          this.selectedUsers = this.getUserTypeValue();
          this.chRef.detectChanges();
        }
      });
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this.adminLibraryObj = data;
    if (this.adminLibraryObj !== undefined) {
      this.adminLibraryObj.userTypes!.forEach(ele => {
        if (ele.i18n) {
          this.selectedUserList.push(ele);
        }
      });
      this.selectedUsers = this.getUserTypeValue();
      const idRef: Hidden = new Hidden(this.adminLibraryObj.document!.id!.toString());
      const nameRef: Text = new Text(this.adminLibraryObj.document!.filename!);
      const typeRef: Text = new Text(this.adminLibraryObj.document!.type!);
      this.uploadedFilesBasic.push({id: idRef, name: nameRef, type: typeRef});
    }
    this.DocumentSlideinForm = this._initializeDocumentSlideinForm(data);
  }

  public selectedUserTypeListFunc(): number[] {
    const returnableList: number[] = [];
    this.selectedUserList.forEach(element => {
      returnableList.push(element.id!);
    });
    return returnableList;
  }

  public isNewDocumentList(): boolean {
    return this.control.input !== undefined;
  }

  public isLanguageEnglish(): boolean {
    return this.lang === Language.English;
  }

  public addOrUpdateDocumentList(): void {
    let documentListData: CreateOrUpdateAdminLibraryDocumentInput = this.DocumentSlideinForm
      .value as CreateOrUpdateAdminLibraryDocumentInput;

    if (documentListData.id === undefined || documentListData.id === null) {
      documentListData = {
        i18n: {
          bg: {
            title: documentListData.title!,
            description: documentListData.description!,
          },
          en: {
            title: documentListData.title!,
            description: documentListData.description!,
          },
        },
        documentId: this.DocId,
        userTypes: this.selectedUserTypeListFunc(),
        title: documentListData.title!,
        description: documentListData.description!,
        id: documentListData.id,
      } as CreateOrUpdateAdminLibraryDocumentInput;
    } else if (
      this.isLanguageEnglish() &&
      (documentListData.id !== undefined || documentListData.id !== null)
    ) {
      documentListData = {
        i18n: {
          en: {
            title: documentListData.title!,
            description: documentListData.description!,
          },
        },
        documentId: this.DocId,
        userTypes: this.selectedUserTypeListFunc(),
        title: documentListData.title!,
        description: documentListData.description!,
        id: documentListData.id,
      } as CreateOrUpdateAdminLibraryDocumentInput;
    } else if (
      !this.isLanguageEnglish() &&
      (documentListData.id !== undefined || documentListData.id !== null)
    ) {
      documentListData = {
        i18n: {
          bg: {
            title: documentListData.title!,
            description: documentListData.description!,
          },
        },
        documentId: this.DocId,
        userTypes: this.selectedUserTypeListFunc(),
        title: documentListData.title!,
        description: documentListData.description!,
        id: documentListData.id,
      } as CreateOrUpdateAdminLibraryDocumentInput;
    }
    this.control.confirm(documentListData);
  }

  public isSubmitButtonDisabled(): boolean {
    return this.DocumentSlideinForm.invalid || this.selectedUsers == '';
  }

  public cancel(): void {
    this.control.cancel();
  }

  public updateFlag(flag: string) {
    this._apiService.chooseFileSubject.next(flag);
  }

  private _initializeDocumentSlideinForm(formdata?: AdminLibraryDocumentDto): FormGroup {
    if (this.isLanguageEnglish()) {
      return (this.DocumentSlideinForm = this._formBuilder.group({
        id: new FormControl(formdata?.id),
        userTypes: new FormControl(formdata?.userTypes),
        title: new FormControl(formdata?.i18n?.en.title, [
          Validators.required,
          Validators.maxLength(TITLE_LENGTH),
          Validators.pattern(TITLE_PATTERN),
        ]),
        description: new FormControl(formdata?.i18n?.en.description, [
          Validators.required,
          Validators.maxLength(DESCRIPTION_LENGTH),
          Validators.pattern(DESCRIPTION_PATTERN),
        ]),
        fileUploader: [],
      }));
    } else {
      return (this.DocumentSlideinForm = this._formBuilder.group({
        id: new FormControl(formdata?.id),
        userTypes: new FormControl(formdata?.userTypes),
        title: new FormControl(formdata?.i18n?.bg.title, [
          Validators.required,
          Validators.maxLength(TITLE_LENGTH),
          Validators.pattern(TITLE_PATTERN),
        ]),
        description: new FormControl(formdata?.i18n?.bg.description, [
          Validators.required,
          Validators.maxLength(DESCRIPTION_LENGTH),
          Validators.pattern(DESCRIPTION_PATTERN),
        ]),
        fileUploader: [],
      }));
    }
  }

  public getUserTypeValue(): string | undefined {
    return this.selectedUserList.length > 1
      ? `${this.selectedUserList.length} Accounts Selected`
      : this.isLanguageEnglish()
      ? this.selectedUserList[0]?.i18n?.en?.title
      : this.selectedUserList[0]?.i18n?.bg?.title;
  }
}
