import {NgModule, ModuleWithProviders, Inject, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ZakrilaStarterService, BASE_ROUTE} from './services/starter.service';
import {InsuringPartyComponent} from './screens/insuring-party/insuring-party.component';
import {ZakrilaComponent} from './zakrila.component';
import {InsuredPartyComponent} from './screens/insured-party/insured-party.component';
import {CoversAndPremiumComponent} from './screens/covers-and-premium/covers-and-premium.component';
import {ProposalComponent} from './screens/proposal/proposal.component';
import {AddBeneficiarySlideinComponent} from './screens/insured-party/add-beneficiary-slidein/add-beneficiary-slidein.component';
import {ConfirmationComponent} from './screens/confirmation/confirmation.component';

@NgModule({
  declarations: [
    ZakrilaComponent,
    ConfirmationComponent,
    InsuringPartyComponent,
    InsuredPartyComponent,
    CoversAndPremiumComponent,
    ProposalComponent,
    AddBeneficiarySlideinComponent,
  ],
  imports: [CommonModule, RouterModule],
  providers: [ZakrilaStarterService],
})
export class ZakrilaModule {
  // Static method for injecting the module with a base route provided.
  public static forPath(path: string): ModuleWithProviders<ZakrilaModule> {
    return {
      ngModule: ZakrilaModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error(
        'Do not import ZakrilaFlowModule directly, use ZakrilaFlowModule.forPath() instead',
      );
    }
  }
}
