import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Decimal} from '@atlas/businesstypes';
import {Logger} from '@atlas/logger';
import {
  AddonsDiscountsDto,
  CoversStep,
  DateFormatterService,
  MaximumDaysAbroad,
  MultiTripFormData,
  ProposalDto,
  RadioButtonDto,
  SingleTripFormData,
  TravelCoversDto,
  TravelToUSAorCanada,
  TripDetailDto,
  TwelveMonths,
} from '@b2b-frontend/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {PopUpController, PopUpOptions} from '@maia/pop-ups';
import {TravelSessionService} from '../../travel-core/services/travel-session.service';
import {TravelProposalEmailComponent} from '../travel-proposal-email/travel-proposal-email.component';
import {MOCK_TRAVELUS_CANADA_OPTION_LIST} from '../travel-trip-details/__tests__/travel-trip-details.mocks';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import {FileSize, REASON_FOR_TRAVEL_TITLE} from '../../constants/constant';

@Component({
  selector: 'travel-addons-slidein',
  templateUrl: './travel-addons-slidein.component.html',
  styleUrls: ['./travel-addons-slidein.component.scss'],
})
@UntilDestroy()
export class TravelAddonsSlideinComponent extends ModalContentComponent<void> implements OnInit {
  public travelUsCanadOptionList: RadioButtonDto[] = [...MOCK_TRAVELUS_CANADA_OPTION_LIST];
  public amountInDecimal = new Decimal('12345.67');
  public tripTypeName: string;
  public noOfTripDays: string | undefined;
  public travelAbroadTitle: string | undefined;
  public travelAbroadSubTitle: string | undefined;
  public tripStartEndDate: string | undefined;
  public multiTripStartDate: string | undefined;
  public multiTripEndDate: string | undefined;
  public reasonType: string;
  public workType: string;
  public totalNoOfPeople: number = 0;
  public tripDetailData: TripDetailDto;
  public singleTripStartDate: string | undefined;
  public singleTripEndDate: string | undefined;
  public coversName: string;
  public coverSubtitle: string;
  public travelCoverDto: TravelCoversDto;
  public selectedAddon: CoversStep[] | undefined;
  public selectedDiscounts: CoversStep[] | undefined;
  public addonDiscountDto: AddonsDiscountsDto;
  public readonly travelProposalEmailFactory: ComponentFactory<TravelProposalEmailComponent>;
  public readonly _logger: Logger;
  public disableDiscountBoxes: boolean = true;
  public disableAddonsBoxes: boolean = true;

  @ViewChild('htmlData')
  public htmlData: ElementRef;

  public constructor(
    public dateFormaterService: DateFormatterService,
    public travelSessionService: TravelSessionService,
    private readonly _popUpCtrl: PopUpController,
    public readonly _injector: Injector,
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    public control: ModalControl<void>,
  ) {
    super();
    this._logger = loggerFactory.createLogger('TravelAddonsSlideinComponent');
    this.travelProposalEmailFactory = componentFactoryResolver.resolveComponentFactory(
      TravelProposalEmailComponent,
    );
  }

  public ngOnInit(): void {
    const data: ProposalDto | undefined = this.travelSessionService.getTravelData();
    if (data) {
      if (data.tripDetailDto) {
        this.tripDetailData = data.tripDetailDto;
        this.tripDataParsing();
      }
      if (data.travelCoverDto) {
        this.travelCoverDto = data.travelCoverDto;
        this.coverDataParsing();
      }
      if (data.addonDiscountDto) {
        this.addonDiscountDto = data.addonDiscountDto;
        this.addOnsDiscountParsing();
      }
    }
  }

  public addOnsDiscountParsing(): void {
    this.selectedAddon = this.addonDiscountDto.addons;
    this.selectedDiscounts = this.addonDiscountDto.discounts;
    this.disableAddonsBoxes = !(this.selectedAddon && this.selectedAddon.length === 0);
    this.disableDiscountBoxes = !(this.selectedDiscounts && this.selectedDiscounts.length === 0);
  }

  public coverDataParsing(): void {
    this.coversName = this.travelCoverDto.selectedCover.coversName;
    this.coverSubtitle = `Main Coverage - ${this.travelCoverDto.selectedCover.coverSubtitle}`;
  }

  public tripDataParsing(): void {
    this.tripTypeName = this.tripDetailData.tripRadioGroup.tripName;
    this.reasonType = this.tripDetailData.travelReason.objectName;
    this.workType = this.tripDetailData.workType
      ? this.tripDetailData.workType.fieldValueName
      : REASON_FOR_TRAVEL_TITLE;
    this.totalNoOfPeople = this.tripDetailData.travelInsuredPeopleCount;
    if (this.tripDetailData.singleTrip) {
      const singleTrip: SingleTripFormData = this.tripDetailData.singleTrip;
      this.singleTripStartDate = this.dateFormaterService.getDDMMYYYYFormatDate(
        singleTrip.startEndData.start,
      );
      this.singleTripEndDate = this.dateFormaterService.getDDMMYYYYFormatDate(
        singleTrip.startEndData.end,
      );
      this.noOfTripDays = `${this.dateFormaterService.isoDateDifference(
        singleTrip.startEndData.start,
        singleTrip.startEndData.end,
      )} Days`;
      this.tripStartEndDate = this.singleTripStartDate + ' - ' + this.singleTripEndDate;
      this.travelAbroadTitle = TravelToUSAorCanada;
      this.travelAbroadSubTitle = this.travelUsCanadOptionList.find(
        t => t.id === singleTrip.travelAbroadRadioGroup,
      )?.name;
    }
    if (this.tripDetailData.multiTrip) {
      const multiTrip: MultiTripFormData = this.tripDetailData.multiTrip;
      this.multiTripStartDate = this.dateFormaterService.getDDMMYYYYFormatDate(
        multiTrip.multipleStartDate,
      );
      this.multiTripEndDate = this.dateFormaterService.getDDMMYYYYFormatDate(
        multiTrip.multipleEndDate,
      );
      this.tripStartEndDate = this.multiTripStartDate + ' - ' + this.multiTripEndDate;
      this.noOfTripDays = TwelveMonths;
      this.travelAbroadTitle = `Up to ${multiTrip.daysAbroad} Days`;
      this.travelAbroadSubTitle = MaximumDaysAbroad;
    }
  }

  //To print quote
  public print(): void {
    window.print();
  }

  //save pdf
  public openPdf(): void {
    const data = document.getElementById('htmlData');
    if (data) {
      html2canvas(data)
        .then(canvas => {
          const fileWidth = FileSize.size;
          const fileHeight = (canvas.height * fileWidth) / canvas.width;
          const FILEURI = canvas.toDataURL('image/png');
          const PDF = new jsPDF('p', 'mm', 'a4');
          const position = 0;
          PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
          PDF.save('quote.pdf');
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  //popup
  private _openPoup(options: PopUpOptions) {
    return this._popUpCtrl
      .prepare(this.travelProposalEmailFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe({error: this._logger.error});
  }

  public openPopup(): void {
    this._openPoup({title: '', size: 'large'});
  }
}
