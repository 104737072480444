import {NgModule, ModuleWithProviders, Optional, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {B2bCmsRoutingModule} from './b2b-cms-routing.module';
import {B2bCmsComponent} from './b2b-cms.component';
import {BASE_ROUTE} from './services/starter.service';

@NgModule({
  declarations: [B2bCmsComponent],
  imports: [CommonModule, B2bCmsRoutingModule],
})
export class B2bCmsModule {
  // Static method for injecting the module with a base route provided.
  public static forPath(path: string): ModuleWithProviders<B2bCmsModule> {
    return {
      ngModule: B2bCmsModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error('Do not import B2bCmsModule directly, use B2bCmsModule.forPath() instead');
    }
  }
}
