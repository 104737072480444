<div class="b2b-kdl-color-background-light">
  <div class="m-l-12 m-r-16">
    <form [formGroup]="orderForm">
      <div
        formArrayName="items"
        *ngFor="let item of orderForm.get('items')?.controls; let i = index"
      >
        <div [formGroupName]="i">
          <nje-add-single-agent (agentNumber)="selectedAgent($event, i)"></nje-add-single-agent>
        </div>
      </div>
    </form>

    <div class="action-with-icon m-t-16">
      <svg
        class="b2b-kdl-color-primary-light action-func-plus-circle"
        b2bFrontendActionFuncPlusCircle
      ></svg>
      <maia-label class="b2b-kdl-color-primary-light add-btn" (click)="addItem()"
        >Add agent</maia-label
      >
    </div>

    <maia-button-group class="m-t-16 buttons-wrapper">
      <button maiaButton class="m-b-16" (click)="getValues()" maiaButtonType="primary">Add</button>
      <button maiaButton class="m-b-16">Cancel</button>
    </maia-button-group>
  </div>
</div>
