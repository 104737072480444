import {Component, Input, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  BLANK_VALUE,
  WARNING,
  REMOVE_PROPERTY_CONFIRMATION_MSG,
} from '../../constants/property-insurance-constants';
import {PopUpContent} from '../../contracts/property-insurance.interface';

@Component({
  selector: 'nje-remove-popup',
  templateUrl: './remove-popup.component.html',
  styleUrls: ['./remove-popup.component.scss'],
})
export class RemovePopupComponent extends ModalContentComponent<void> implements OnInit {
  @Input() public title = WARNING;
  @Input() public message = REMOVE_PROPERTY_CONFIRMATION_MSG;
  @Input() public footerNote = BLANK_VALUE;

  public constructor(public control: ModalControl<void>) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this._initializePopUpContent(data);
  }

  private _initializePopUpContent(popUpContent?: PopUpContent): void {
    this.title = popUpContent?.title ?? BLANK_VALUE;
    this.message = popUpContent?.message ?? BLANK_VALUE;
    this.footerNote = popUpContent?.footerNote ?? BLANK_VALUE;
  }

  public cancel(): void {
    this.control.cancel();
  }

  public confirm(): void {
    this.control.confirm();
  }
}
