import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FlagUtilGermanySvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFlagUtilGermany]',
  template: '<svg:defs><svg:path id="svgBsBzbJ96zVE5eAroNJlGr4WGqwe3K6GV43T039cEUi4=" d="M0 0h48v48H0z"></svg:path></svg:defs><svg:clipPath id="svgyJ+42GtIHnMZxYPTnrT8TWw9TH35FzK18rD8TpPS7ng="><svg:use href="#svgBsBzbJ96zVE5eAroNJlGr4WGqwe3K6GV43T039cEUi4=" overflow="visible"></svg:use></svg:clipPath><svg:g clip-path="url(#svgyJ+42GtIHnMZxYPTnrT8TWw9TH35FzK18rD8TpPS7ng=)"><svg:path fill="#FFFFFF" d="M0 0h48v48H0z"></svg:path><svg:path fill="#D80027" d="M0 0h48v48H0z"></svg:path><svg:path d="M0 0h48v13.6H0z"></svg:path><svg:path fill="#FFDA44" d="M0 34.4h48V48H0z"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'xlink': 'http://www.w3.org/1999/xlink',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagUtilGermanySvgComponent {
}

@NgModule({
  declarations: [
    FlagUtilGermanySvgComponent,
  ],
  exports: [
    FlagUtilGermanySvgComponent,
  ],
})
export class FlagUtilGermanySvgModule {}
