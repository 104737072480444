import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncHandsCrossSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncHandsCross]',
  template: '<svg:g><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M17.91 21.5v-1.68l3-3.76c.24-.3.37-.67.37-1.06v-4.89c0-.7-.57-1.27-1.27-1.27H20h0a1.25 1.25 0 0 0-1.26 1.24v3.02"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.69 21.5v-4.36c0-.45.18-.88.5-1.2l2.88-2.88c.47-.46 1.21-.46 1.68 0h0c.46.47.46 1.22 0 1.69h0l-2.53 2.53"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M6.09 21.5v-1.68l-3-3.76c-.24-.3-.37-.67-.37-1.06v-4.89c0-.7.57-1.27 1.27-1.27H4c.69-.01 1.25.55 1.26 1.24v.02h0v3"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M10.31 21.5v-4.36c0-.45-.18-.88-.5-1.2l-2.88-2.88c-.47-.46-1.21-.46-1.68 0h0c-.46.47-.46 1.22 0 1.69h0l2.53 2.53"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M16.25 5.2h-2.7V2.5h-3.1v2.7h-2.7v3.1h2.7V11h3.1V8.3h2.7z"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncHandsCrossSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncHandsCrossSvgComponent,
  ],
  exports: [
    InformationFuncHandsCrossSvgComponent,
  ],
})
export class InformationFuncHandsCrossSvgModule {}
