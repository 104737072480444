/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*eslint-disable @typescript-eslint/restrict-plus-operands*/
/* eslint-disable  @typescript-eslint/prefer-readonly */
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MtplRoutePaths} from '../../helper/mtpl-route-paths';
import {FormControl, Validators} from '@angular/forms';
import {Date, DateKey, DateUtils} from '@atlas/businesstypes';
import {DateFormatterService} from '@b2b-frontend/core';
import {
  DatePickerController,
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenStepComponent,
} from '@njf-frontend-angular/flow-progress';

import {
  RadioButtonDto,
  MOCK_DRVING_OUTSIDE_BULGARIA,
  SteeringWheelSideList,
  STEERING_WHEEL_SIDE,
  MockAgencyCode,
  MOCK_AGENCY_CODE,
} from './__tests__/mockPolicyLength';
import {
  Offices,
  PolicyFormModel,
  PolicyLength,
  TariffRate,
  VehiclePurpose,
} from '../../models/PolicyFormModel';
import {SlideInController} from '@maia/slide-ins';
import {PolicyDetailsSlideinComponent} from './policy-details-slidein/policy-details-slidein.component';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';
import {MtplApiService} from '../../mtpl-core/services/mtpl-api.service';
import {SharedService} from '../../mtpl-core/services/shared.service';

@Component({
  selector: 'nje-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss'],
})
@UntilDestroy()
export class PolicyDetailsComponent extends ScreenStepComponent implements OnInit {
  public readonly slideInOneFactory: ComponentFactory<PolicyDetailsSlideinComponent>;
  private readonly _logger: Logger;

  public currentDate = DateUtils.today();
  public policyLengthList: PolicyLength[];
  public vehiclePurposeList: VehiclePurpose[];
  public officeList: Offices[];
  public tariffRateList: TariffRate[];

  public mockPolicyRadioList: RadioButtonDto[] = [...MOCK_DRVING_OUTSIDE_BULGARIA];
  public mockSteeringWheelSide: SteeringWheelSideList[] = [...STEERING_WHEEL_SIDE];
  public agencyCodeList: MockAgencyCode[] = [...MOCK_AGENCY_CODE];
  public minDate = DateUtils.subtract(DateUtils.today(), DateKey.Day, 0);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 60);
  public startDatePicker: DatePickerController;
  public endDatePicker: DatePickerController;
  public today: string | undefined;
  public start: DateUtils;
  public response: any;
  public dateResponse: Date;
  public labelFlag: boolean = true;
  public matcher: string | undefined;
  public authorizationCode: boolean = false;
  public isScenarioFour: boolean = false;
  public isScenarioTwo: boolean = false;

  public newPolicyForm: FormGroupManager<PolicyFormModel> =
    this._formFactory.createFormManager<PolicyFormModel>({
      policyLength: new FormControl(12, [Validators.required]),
      startDate: new FormControl(undefined),
      endDate: new FormControl({value: undefined, disabled: true}, [Validators.required]),
      travelOutsideBulgaria: new FormControl(undefined, [Validators.required]),
      purposeOfVehicleServe: new FormControl(1, [Validators.required]),
      steeringWheelSide: new FormControl(1, [Validators.required]),
      newVehicleorNot: new FormControl(undefined, [Validators.required]),
      officeList: new FormControl(undefined, [Validators.required]),
      agencyNumer: new FormControl(undefined, [Validators.required]),
      specialAuthorisationCode: new FormControl(undefined),
      tariffRate: new FormControl(undefined),
      tariff: new FormControl(undefined),
    });

  public constructor(
    private _sharedServices: SharedService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _dateFormatterService: DateFormatterService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _mtplApiService: MtplApiService,
  ) {
    super(_activatedRoute);
    this.slideInOneFactory = componentFactoryResolver.resolveComponentFactory(
      PolicyDetailsSlideinComponent,
    );
    this._logger = loggerFactory.createLogger('PolicyDetailsComponent');
  }

  protected saveMtplData(): void {
    //TODO: it should be added at later stage
  }

  public ngOnInit(): void {
    this.initApiForDropDowns();
    if (this._sharedServices.isScenarioFour || this._sharedServices.isScenarioTwo)
      this.isScenarioFour = true;

    this.newPolicyForm.controls.endDate.setValue(
      DateUtils.add(DateUtils.today(), DateKey.Day, 365),
    );

    this.startDatePicker = this.newPolicyForm.createDatePicker('startDate', this.minDate);
    this.today = this._dateFormatterService.getDDMMYYYYFormatDate(this.currentDate);
    this.newPolicyForm.controls.policyLength.valueChanges.subscribe((result: Date) => {
      if (this.newPolicyForm.controls.policyLength.value <= 12) {
        const endDate1 = DateUtils.add(
          this.newPolicyForm.controls.startDate.value,
          DateKey.Month,
          this.newPolicyForm.controls.policyLength.value,
        );
        this.newPolicyForm.patchValue({
          endDate: endDate1,
        });
      } else {
        const endDate1 = DateUtils.add(
          this.newPolicyForm.controls.startDate.value,
          DateKey.Day,
          this.newPolicyForm.controls.policyLength.value,
        );
        this.newPolicyForm.patchValue({
          endDate: endDate1,
        });
      }
    });

    this.newPolicyForm.controls.startDate.valueChanges.subscribe((result: Date) => {
      const endDate1 = DateUtils.add(
        this.newPolicyForm.controls.startDate.value,
        DateKey.Day,
        this.newPolicyForm.controls.policyLength.value,
      );
      this.matcher = this._dateFormatterService.getDDMMYYYYFormatDate(
        this.newPolicyForm.controls.startDate.value,
      );
      this.labelFlag = this.today == this.matcher;
      this.newPolicyForm.patchValue({
        endDate: endDate1,
      });
    });

    this.newPolicyForm.controls.specialAuthorisationCode.statusChanges.subscribe(
      (result: string) => {
        this.newPolicyForm.controls.specialAuthorisationCode.setValidators([
          Validators.minLength(8),
        ]);

        if (this.newPolicyForm.controls.specialAuthorisationCode.valid) {
          this.authorizationCode = !this.authorizationCode;
          this.newPolicyForm.controls.tariffRate.setValidators([Validators.required]);
          this.newPolicyForm.controls.tariff.setValidators([Validators.required]);
        }
      },
    );
    this.newPolicyForm.controls.specialAuthorisationCode.valueChanges.subscribe((data: string) => {
      if (this.newPolicyForm.controls.specialAuthorisationCode.value == null)
        this.newPolicyForm.controls.specialAuthorisationCode.clearValidators();
      this.newPolicyForm.controls.tariffRate.clearValidators();
      this.newPolicyForm.controls.tariff.clearValidators();
    });
  }

  public initApiForDropDowns() {
    this._mtplApiService.getPolicyLengths().subscribe((data: PolicyLength[]) => {
      this.policyLengthList = data;
    });
    this._mtplApiService.getVehiclePurposes().subscribe((data: VehiclePurpose[]) => {
      this.vehiclePurposeList = data;
    });
    this._mtplApiService.getTariffRates().subscribe((data: TariffRate[]) => {
      this.tariffRateList = data;
    });
    this._mtplApiService.getOffices().subscribe((data: Offices[]) => {
      this.officeList = data;
      if (this.officeList.length == 1) {
        this.newPolicyForm.patchValue({
          officeList: this.officeList[0]?.name,
        });
      }
    });
  }

  public getPolicyLengths(value: string): string | null {
    const val = this.policyLengthList
      ? this.policyLengthList.find((options: any) => options.duration == value)
      : null;
    return val ? val.duration + ' ' + val.dimension : null;
  }

  public getVehiclePurposes(value: number): string | null {
    const val = this.vehiclePurposeList
      ? this.vehiclePurposeList.find((options: VehiclePurpose) => options.id == value)
      : null;
    return val ? val.name : null;
  }

  public getOffices(value: string): string | null {
    const val = this.officeList
      ? this.officeList.find((options: Offices) => options.name == value)
      : null;
    return val ? val.name : null;
  }

  public getTariffRate(value: string): string | null {
    const data = this.tariffRateList
      ? this.tariffRateList.find((options: TariffRate) => options.id == value)
      : null;
    return data ? data.name : null;
  }

  public getAgenyCode(value: number): string | null {
    const data = this.agencyCodeList
      ? this.agencyCodeList.find((options: MockAgencyCode) => options.id == value)
      : null;
    return data ? data.agencyCode : null;
  }

  public onContinue(): void {
    this._router.navigate(['../', MtplRoutePaths.MTPL_INSTALMENTS], {
      relativeTo: this._activatedRoute,
    });
  }

  public openOneSlideIn(): void {
    this._slideInCtrl
      .prepare(
        this.slideInOneFactory,
        this._injector,
        {
          title: 'Slide-in one fitting content',
        },
        {
          withVisibleBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe({error: this._logger.error});
  }
}
