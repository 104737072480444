/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormArray, FormControl, Validators} from '@angular/forms';
import {bgPhoneNumberValidator, localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  PhoneNumberValueController,
} from '@njf-frontend-angular/flow-progress';

import {Language} from '../../../../constants/lang';
import {CONTACT_TYPE} from '../../../../constants/resource-management/contacts';
import {Contact} from '../../../../contracts/contactsInterface';
import {InputPhonenumberComponent, PhoneNumberValue} from '@maia/input-phonenumber';
import {FormData} from './form-data.model';
import {EmailAddress} from '@atlas/businesstypes';
import {TITLE_LENGTH, TITLE_PATTERN} from '../../../../constants/resource-management/constant';

@Component({
  selector: 'nje-add-contact-slide-in',
  templateUrl: './add-contact-slide-in.component.html',
  styleUrls: ['./add-contact-slide-in.component.scss'],
  providers: [FormGroupManagerFactoryService],
})
export class AddContactSlideInComponent
  extends ModalContentComponent<Contact[], any[]>
  implements OnInit
{
  public contactObj: Contact;
  public ContactForm: FormGroupManager<FormData>;
  public lang: string | null;

  public titleLimit: number = TITLE_LENGTH;

  public contactsForm: FormGroupManager<any> = this._formFactory.createFormManager<any>({
    contacts: new FormArray([]),
  });

  public addContactLiteral: string;

  private phoneNumberControllerArray: PhoneNumberValueController[] = [];
  @ViewChildren('phoneInput') phoneNumberComponent: QueryList<InputPhonenumberComponent>;

  public constructor(
    private readonly _formFactory: FormGroupManagerFactoryService,
    public control: ModalControl<Contact[], Contact[]>,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.lang = sessionStorage.getItem('lang');
    const data = this.control.input;
    this.contactObj = data[0];
    this._initializeContactSlideinForm(data[0]);
    this.contacts.push(this.ContactForm.formGroup);
    if (this.ContactType()) {
      this.addContactLiteral = new localeValPipe(new LocalizationService()).transform(
        CONTACT_TYPE.ADD_CONTACT,
        this.lang,
      );
    } else {
      this.addContactLiteral = new localeValPipe(new LocalizationService()).transform(
        CONTACT_TYPE.ADD_INCIDENT,
        this.lang,
      );
    }
  }

  public isLanguageEnglish(): boolean {
    return this.lang === Language.English;
  }

  public get contacts(): FormArray {
    return this.contactsForm.formGroup.controls.contacts as FormArray;
  }

  private _initializeContactSlideinForm(formdata?: Contact): void {
    if (this.isLanguageEnglish()) {
      if (this.control.input[1] == CONTACT_TYPE.CONTACT) {
        this.ContactForm = this._formFactory.createFormManager<FormData>({
          id: new FormControl(formdata?.id),
          title: new FormControl(formdata?.i18n?.en?.title, [
            Validators.required,
            Validators.maxLength(TITLE_LENGTH),
            Validators.pattern(TITLE_PATTERN),
          ]),
          phoneNumber: new FormControl(formdata?.phoneNumber, [
            Validators.required,
            bgPhoneNumberValidator({allowZero: true}),
          ]),
        });
        this._addPhoneInput(formdata?.phoneNumber);
      } else if (this.control.input[1] == CONTACT_TYPE.INCIDENT) {
        this.ContactForm = this._formFactory.createFormManager<FormData>({
          id: new FormControl(formdata?.id),
          title: new FormControl(formdata?.i18n?.en?.title, [
            Validators.required,
            Validators.maxLength(TITLE_LENGTH),
            Validators.pattern(TITLE_PATTERN),
          ]),
          email: new FormControl(formdata?.email, [Validators.required]),
        });
      }
    } else {
      if (this.control.input[1] == CONTACT_TYPE.CONTACT) {
        this.ContactForm = this._formFactory.createFormManager<FormData>({
          id: new FormControl(formdata?.id),
          title: new FormControl(formdata?.i18n?.bg?.title, [
            Validators.required,
            Validators.maxLength(TITLE_LENGTH),
            Validators.pattern(TITLE_PATTERN),
          ]),
          phoneNumber: new FormControl(formdata?.phoneNumber, [
            Validators.required,
            bgPhoneNumberValidator({allowZero: true}),
          ]),
        });
        this._addPhoneInput(formdata?.phoneNumber);
      } else if (this.control.input[1] == CONTACT_TYPE.INCIDENT) {
        this.ContactForm = this._formFactory.createFormManager<FormData>({
          id: new FormControl(formdata?.id),
          title: new FormControl(formdata?.i18n?.bg?.title, [
            Validators.required,
            Validators.maxLength(TITLE_LENGTH),
            Validators.pattern(TITLE_PATTERN),
          ]),
          email: new FormControl(formdata?.email, [Validators.required]),
        });
      }
    }
  }

  public isNewContact(): boolean {
    return this.control.input[0] !== undefined;
  }

  public ContactType(): boolean {
    return this.control.input[1] == CONTACT_TYPE.CONTACT;
  }

  public addContact(): void {
    const updatedContactList = this.contactsForm.formGroup.controls.contacts.value;
    if (updatedContactList[0].id === undefined || updatedContactList[0].id === null) {
      const contactType =
        updatedContactList[0].email != null ? CONTACT_TYPE.INCIDENT : CONTACT_TYPE.CONTACT;
      for (let i = 0; i < updatedContactList.length; i++) {
        const newContactObj: Contact = {
          type: contactType,
          i18n: {
            bg: {
              title: updatedContactList[i].title!,
            },
            en: {
              title: updatedContactList[i].title!,
            },
          },
          phoneNumber: this.phoneNumberControllerArray[i]
            ? this._patchPhoneNumber(this.phoneNumberControllerArray[i])
            : undefined,
          email:
            updatedContactList[i].email instanceof EmailAddress
              ? updatedContactList[i].email.asString()
              : updatedContactList[i].email,
        };
        updatedContactList[i] = newContactObj;
      }
    } else if (updatedContactList[0].id !== undefined || updatedContactList[0].id !== null) {
      if (this.isLanguageEnglish()) {
        this.contactObj.i18n!.en.title = updatedContactList[0].title!;
      } else {
        this.contactObj.i18n!.bg.title = updatedContactList[0].title!;
      }
      this.contactObj.email =
        updatedContactList[0].email instanceof EmailAddress
          ? updatedContactList[0].email!.asString()
          : updatedContactList[0].email;
      updatedContactList[0] = this.contactObj;
    }
    this.control.confirm(updatedContactList);
  }

  public cancelSlideIn(): void {
    this.control.cancel();
  }

  public addAnotherContact(): void {
    this._initializeContactSlideinForm();
    this.contacts.push(this.ContactForm.formGroup);
  }

  public removeAddedContact(i: number): void {
    this.contacts.removeAt(i);
    this.phoneNumberControllerArray.splice(i, 1);
  }

  private _addPhoneInput(phoneNumber?: string): void {
    setTimeout(() => {
      const arrayVal = this.phoneNumberComponent.toArray();
      const index = arrayVal.length - 1;
      if (arrayVal[index] && !this.phoneNumberControllerArray[index]) {
        this.phoneNumberControllerArray[index] = this.ContactForm.createPhoneNumberValue(
          'phoneNumber',
          arrayVal[index],
          {
            disableCountryCode: true,
          },
        );
      }
      if (phoneNumber) {
        this.phoneNumberControllerArray[index].value = this._splitPhoneNumber(phoneNumber);
      }
    });
  }

  private _splitPhoneNumber(phoneNumber: string): PhoneNumberValue {
    const splittedPhoneNumber = phoneNumber.split('-');
    return {
      prefix: splittedPhoneNumber[0],
      number: splittedPhoneNumber[1],
    };
  }

  private _patchPhoneNumber(phoneNumber: PhoneNumberValueController): string {
    return phoneNumber.value.prefix + '-' + phoneNumber.value.number;
  }
}
