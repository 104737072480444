import {Injectable} from '@angular/core';
import {PolicyNumberType} from '../core-helper/constants/PolicyNumberType';

@Injectable({
  providedIn: 'root',
})
export class PolicyNumberValidatorService {
  private readonly INSIS9 = /^\d{15}(\/\d{1,2})?$/;
  private readonly INSIS10 = /^\d{16}(\/\d{1,2})?$/;
  private readonly MTPL = /^BG\/06\/\d{12}$/;
  private readonly MTPL_97 = /^BG\/97\/\d{12}$/;

  public getType(val: string): PolicyNumberType | undefined {
    if (val.replace(/0/g, '').trim() === '') {
      return undefined;
    } else if (val && this.INSIS9.exec(val)) {
      return PolicyNumberType.INSIS9;
    } else if (val && this.INSIS10.exec(val)) {
      return PolicyNumberType.INSIS10;
    } else if (val.length === 18 && (this.MTPL.exec(val) || this.MTPL_97.exec(val))) {
      return PolicyNumberType.MTPL;
    }
    return undefined;
  }
}
