import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TravelRoutePaths} from '@b2b-frontend/core';

@Injectable({
  providedIn: 'root',
})
export class TravelNavigationService {
  constructor(private readonly _router: Router) {}

  public nextScreen(path: TravelRoutePaths): void {
    this._router.navigate([`dashboard/${TravelRoutePaths.TI}/`, path]);
  }
}
