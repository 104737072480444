<maia-price-block amount="95632">
  <svg class="icon-white-color" b2bFrontendPlacesExprHouseInterior maia-price-icon></svg>
  <div maia-price-title>Property Insurance</div>
  <div maia-price-subtitle>Total Premium (Incl. of taxes)</div>
</maia-price-block>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="policyDetailsHeading"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncClock></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Policy Term'"
        >
          <label class="b2b-kdl-font-small title-dark-color">Annual Term</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'Start Date'">
          <label class="b2b-kdl-font-small title-dark-color">27-04-2021</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendarCheck></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="'End Date'">
          <label class="b2b-kdl-font-small title-dark-color">26-04-2022</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="insuringPartyHeading"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Individual 9208086838'"
        >
          <label class="b2b-kdl-font-small title-dark-color">Sithi Nadesh Kumar</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesExprHouseTree></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'51B Nikoa Vaptzarov Blvd Bulgaria'"
        >
          <label class="b2b-kdl-font-small title-dark-color">Sofia 1000</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendCommunicationExprMail></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'+359 8979901245'"
        >
          <label class="b2b-kdl-font-small title-dark-color">sithi@gmail.com</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="propertyDetailsHeading"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-table>
    <maia-table-header>
      <maia-table-row>
        <maia-table-cell
          class="b2b-kdl-font-small subtitle-light-color"
          *ngFor="let column of columnHeaders; let i = index"
        >
          {{ column }}
        </maia-table-cell>
      </maia-table-row>
    </maia-table-header>
    <maia-table-body>
      <maia-table-row class="no-insured-party-added" *ngIf="insurancePartyDetailsList.length <= 0"
        ><maia-table-cell>No insured party added</maia-table-cell>
      </maia-table-row>
      <maia-table-row
        *ngFor="let insurancePartyDetails of insurancePartyDetailsList; let i = index"
      >
        <maia-table-cell>
          <button maiaButton maiaButtonType="tertiary">{{ insurancePartyDetails.idNumber }}</button>
        </maia-table-cell>
        <maia-table-cell>
          {{ insurancePartyDetails.firstNameEn }} {{ insurancePartyDetails.middleNameEn }}
          {{ insurancePartyDetails.lastNameEn }}
        </maia-table-cell>
        <maia-table-cell>
          {{ insurancePartyDetails.streetNumberEn }}, {{ insurancePartyDetails.streetEn }},
          {{ insurancePartyDetails.quarterEn }}, {{ insurancePartyDetails.cityEn.title }}
        </maia-table-cell>
        <maia-table-cell>
          {{ insurancePartyDetails.phoneNumber.number }}
        </maia-table-cell>
        <maia-table-cell>
          {{ insurancePartyDetails.emailId }}
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="coversAndDiscountHeading"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <div *ngFor="let covers_discount of coversDiscountLayoutInfoPassed">
    <nje-property-cover-discount-layout
      [additionalCoverList]="covers_discount.additionalCoverList"
      [additionalCoversInfoList]="covers_discount.additionalCoversInfoList"
      [propertyCoversInfoList]="covers_discount.propertyCoversInfoList"
      [propertyAddress]="covers_discount.propertyAddress"
      [premiumAmountVisibility]="premiumAmountVisibility"
      [currencySelected]="currencySelected"
    >
    </nje-property-cover-discount-layout>
  </div>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="installments"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Total Premium (Incl. of taxes)'"
        >
          <label class="b2b-kdl-font-small title-dark-color">888,70 BGN</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncPercentageCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="'Installments'"
        >
          <label class="b2b-kdl-font-small title-dark-color">2</label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-table>
      <maia-table-header>
        <maia-table-row>
          <maia-table-cell
            class="b2b-kdl-font-small subtitle-light-color"
            *ngFor="let installmentsCol of installmentsColHeaders; let i = index"
          >
            {{ installmentsCol }}
          </maia-table-cell>
        </maia-table-row>
      </maia-table-header>
      <maia-table-body>
        <maia-table-row *ngFor="let installmentsDetails of installmentsDetailsList; let i = index">
          <maia-table-cell>
            {{ installmentsDetails.installments }}
          </maia-table-cell>
          <maia-table-cell>
            {{ installmentsDetails.dueDate }}
          </maia-table-cell>
          <maia-table-cell>
            {{ installmentsDetails.premiumAmount }}
          </maia-table-cell>
        </maia-table-row>
      </maia-table-body>
    </maia-table>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="discountAndLoadings"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <div class="b2b-kdl-font-small title-dark-color">Add Ons</div>
        <div class="b2b-kdl-font-extra-small-light maia-text"
          >Two or more property kinds in 1 policy (-5%) Single Pay Discount (-3%)</div
        >
        <maia-additional-information
          coverlink
          openLinkText="View more"
          closeLinkText="View less"
          maiaContentType="content"
          collapsible
        >
          <div>Single Pay Discount (-5%) </div>
          <div>New Client Discount (-3%)</div>
          <div>Black Friday Discount (-15%)</div>
        </maia-additional-information>
      </div>
    </maia-avatar-display>

    <maia-avatar-display>
      <div maia-avatar-display-body>
        <div class="b2b-kdl-font-small title-dark-color">Loadings</div>
        <div class="b2b-kdl-font-extra-small-light maia-text"
          >11, Deseta Str., Kranso Selo, Kalugerovo Village, 4462</div
        >
        <div class="b2b-kdl-font-extra-small-light maia-text"
          >Loading for Garage as a part of building on limit (+50%)</div
        >
        <maia-additional-information
          coverlink
          openLinkText="View more"
          closeLinkText="View less"
          maiaContentType="content"
          collapsible
        >
          <div>11, Deseta Str., Kranso Selo, Kalugerovo Village, 4462</div>
          <div>Loading for Garage as a part of building on limit (+20%)</div>
        </maia-additional-information>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<maia-divider></maia-divider>

<div>
  <form maiaForm [formGroup]="propertyProposalForm">
    <maia-form-element>
      <maia-fluid-fluid-layout alignContentTop>
        <div class="m-r-16">
          <maia-form-element [hideOptionalIndicator]="true" class="input-column">
            <maia-label>
              <label class="b2b-kdl-font-small title-dark-color">Payment Document Type</label>
            </maia-label>
            <maia-radio-group formControlName="paymentDocumentType" horizontal>
              <njf-radio-button
                *ngFor="let paymentDocumentType of paymentDocumentTypeList"
                class="white-bg-color"
                [value]="paymentDocumentType.value"
                >{{ paymentDocumentType.label }}</njf-radio-button
              >
            </maia-radio-group>
          </maia-form-element>
        </div>
        <div></div>
      </maia-fluid-fluid-layout>
    </maia-form-element>
  </form>
</div>
<maia-divider></maia-divider>
<maia-button-group class="property-proposal-btn-group">
  <button class="m-t-b-24" maiaButton maiaButtonType="primary" (click)="createPolicy()"
    >Create Policy</button
  >
  <button class="m-t-b-24" maiaButton maiaButtonType="secondary">Save Offer</button>
  <button class="m-t-b-24" maiaButton maiaButtonType="secondary">Special Request</button>
</maia-button-group>
