import {NgModule} from '@angular/core';
import {RouterModule, Route, Routes} from '@angular/router';
import {B2bCmsComponent} from './b2b-cms.component';
import {CMS_RESOURCE_MANAGEMENT_ROUTE} from './screens/resource-management/resource-management-routing.module';
import {CMS_STATISTICS_REPORTING_ROUTE} from './screens/statistics-reporting/statistics-reporting-routing.module';

const routes: Routes = [
  {
    path: '',
    component: B2bCmsComponent,
    children: [
      {
        path: 'resource',
        children: [CMS_RESOURCE_MANAGEMENT_ROUTE],
      },
      {
        path: 'statistics',
        children: [CMS_STATISTICS_REPORTING_ROUTE],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class B2bCmsRoutingModule {}

/**
 * The route we export is anempty path with the private module lazy loaded.
 */
export const CMS_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./b2b-cms-routing.module').then(module => module.B2bCmsRoutingModule),
};
