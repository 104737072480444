import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprWalletAccountSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprWalletAccount]',
  template: '<svg:path d="M11.19 14.85H36.9a4.56 4.56 0 0 1 4.57 4.57v17.89a4.56 4.56 0 0 1-4.57 4.58H11.19a4.57 4.57 0 0 1-4.58-4.58V19.42a4.51 4.51 0 0 1 4.58-4.57z" fill="none" stroke-linecap="round" stroke-miterlimit="6.4" stroke-width="1.6"></svg:path><svg:path d="M6.61 19.42s-1.25-6.15 5.74-8.07l19-5.07c.75-.17 3.16-.84 3.16 2.33v2" fill="none" stroke-linecap="round" stroke-miterlimit="6.4" stroke-width="1.6"></svg:path><svg:circle cx="35.82" cy="28.41" r="1.96"></svg:circle>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprWalletAccountSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprWalletAccountSvgComponent,
  ],
  exports: [
    InformationExprWalletAccountSvgComponent,
  ],
})
export class InformationExprWalletAccountSvgModule {}
