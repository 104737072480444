<div>
  <maia-contextual-message
    class="message"
    id="contextual-message-5"
    *ngFor="let message of messageslist"
    >{{ message.supplementaryMessage1 }}
    {{ message.supplementaryMessage2 }}
  </maia-contextual-message>
</div>
<!--For time being-->
<!-- <div #stickyMenu [class.sticky]="sticky">
  <div class="supplementary-list-header">
    <div class="demo-kdl-font-small"> Supplementary List</div>
    <div class="view-toggle">
      <maia-switch-group>
        <maia-switch checked (click)="toggleAccordion()"></maia-switch>
      </maia-switch-group>
    </div>
    <div class="demo-kdl-font-normal"> View Expanded </div>
  </div>
</div> -->
<div class="accordion">
  <maia-accordion-list class="supplementary-list-accordion" *ngFor="let accordion of accordionlist">
    <maia-accordion-list-item withoutDivider>
      <maia-accordion-list-item-header class="accordion-header">
        <div class="accordion-header-text">{{ accordion.header }}</div>
        <div *ngFor="let message of messageslist">
          <maia-tooltip-message
            class="tool-tip"
            id="tooltip-trigger-long"
            [message]="message.tooltipMessage"
          >
            <svg class="alert-icon" b2bFrontendAlertExprExclamationCircle></svg>
          </maia-tooltip-message>
        </div>
      </maia-accordion-list-item-header>
      <maia-accordion-list-item-content>
        <maia-list class="total-cover-table-heading">
          <maia-list-item>
            <div>{{ accordion.propertydesc }}</div>
            <div>{{ accordion.insuredesc }}</div>
          </maia-list-item>
        </maia-list>
        <maia-table>
          <maia-table-header>
            <maia-table-row>
              <maia-table-cell
                class="b2b-kdl-font-small subtitle-light-color"
                *ngFor="let column of columnHeaders; let i = index"
              >
                {{ column.header }}
              </maia-table-cell>
              <maia-table-cell maiaTableHeaderHiddenOnSmall>
                <div> </div>
              </maia-table-cell>
            </maia-table-row>
          </maia-table-header>
          <maia-table-body>
            <maia-table-row *ngFor="let supplementaryListDetails of insuredDetails; let i = index">
              <maia-table-cell>
                <button
                  maiaButton
                  maiaButtonType="tertiary"
                  (click)="editSupplementary(supplementaryListDetails, i)"
                  >{{ supplementaryListDetails.objectName }}</button
                >
              </maia-table-cell>
              <maia-table-cell>
                {{ supplementaryListDetails.objectType }}
              </maia-table-cell>

              <maia-table-cell>
                {{ supplementaryListDetails.insuredValue }}
              </maia-table-cell>

              <maia-table-cell maiaTableHeaderHiddenOnSmall>
                <div class="left">
                  <svg class="delete-icon" b2bFrontendActionFuncBin (click)="removePerson(i)"></svg>
                </div>
              </maia-table-cell>
            </maia-table-row>
            <maia-table-row class="background" *ngIf="insuredDetails.length >= 0"
              ><maia-table-cell>-</maia-table-cell>
              <maia-table-cell>-</maia-table-cell>
              <maia-table-cell>-</maia-table-cell>
              <maia-table-cell maiaTableHeaderHiddenOnSmall>
                <div class="add-new-icon" (click)="addNewSupplementary()">
                  <svg b2bFrontendActionFuncPlusCircle></svg>
                </div>
              </maia-table-cell>
            </maia-table-row>
          </maia-table-body>
        </maia-table>
        <maia-pagination
          [(page)]="page"
          [lastPage]="lastPage()"
          [totalPages]="pages"
          general
        ></maia-pagination>
      </maia-accordion-list-item-content>
    </maia-accordion-list-item>
  </maia-accordion-list>
</div>
<div class="continue">
  <button maiaButton maiaButtonType="primary" [disabled]="!enable" (click)="saveSupplementaryData()"
    >Continue</button
  >
</div>
<div class="screen-margin">
  <router-outlet></router-outlet>
  <njf-covers-accordion [totalInsuranceAmount]="totalAmount" [title]="title"></njf-covers-accordion>
</div>
