import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileIndiDocumentsSwitchSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFileIndiDocumentsSwitch]',
  template: '<svg:path d="M23.8 95.7v2.4c0 5.3-5.9 5.9-5.9 5.9h35.6a5.8 5.8 0 0 0 5.9-5.7v-.2h0v-2.4z" fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M38.1 51.8H12v46.3a5.9 5.9 0 0 0 5.9 5.9h0" fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path stroke-width="2.6" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M21.5 63.7h16.4"></svg:path><svg:path stroke-width="2.6" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M21.5 73.2h16.4"></svg:path><svg:path stroke-width="2.6" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M21.5 82.7h21.3"></svg:path><svg:path d="M69.4 58.7v2.4c0 5.3-5.9 5.9-5.9 5.9h35.6a5.9 5.9 0 0 0 5.9-5.9h0v-2.4z" fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M63.5 67a5.9 5.9 0 0 1-5.9-5.9h0V14.8h38.3v43.4" fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path stroke-width="2.6" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M67 26.6h16.5"></svg:path><svg:path stroke-width="2.6" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M67 36.1h16.5"></svg:path><svg:path stroke-width="2.6" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M67 45.6h21.4"></svg:path><svg:path d="M35.6 13l5.3 5.3-5.9 5.8" fill="none" stroke="#00aeef" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:path d="M20.7 36.3h0a18 18 0 0 1 18-18h0" fill="none" stroke="#00aeef" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path>',

  styles: [
  ],

  host: {
    'viewBox': '0 0 116 116',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileIndiDocumentsSwitchSvgComponent {
}

@NgModule({
  declarations: [
    FileIndiDocumentsSwitchSvgComponent,
  ],
  exports: [
    FileIndiDocumentsSwitchSvgComponent,
  ],
})
export class FileIndiDocumentsSwitchSvgModule {}
