import {
  Component,
  OnInit,
  OnDestroy,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  INSURED_PEOPLE_TABLE,
  InsuredPerson,
  ProposalDto,
  DateFormatterService,
  PAGINATION,
  InsuredPersonDto,
  TripDetailDto,
  TravelRoutePaths,
} from '@b2b-frontend/core';
import {
  ScreenFormComponent,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {TravelInsuredPeopleSlideinComponent} from '../travel-insured-people-slidein/travel-insured-people-slidein.component';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {TravelSessionService} from '../../travel-core/services/travel-session.service';
import * as XLSX from 'xlsx';
import {TravelPolicyService} from '../../travel-core/services/travel-policy.service';
import {
  MOCK_MESSAGES_CLOSE_ICON,
  // MOCK_INSURED_PERSON_LIST, // its commented for timebeing
  MOCK_TABLE_HEADERS,
} from './__tests__/travel-insured-people.mock';
import {TravelInsuredPeoplePopupComponent} from '../travel-insured-people-popup/travel-insured-people-popup.component';
import {PopUpController, PopUpOptions} from '@maia/pop-ups';
import {Logger} from '@atlas/logger';
import {TravelInsuredPeopleDeletePopupComponent} from '../travel-insured-people-delete-popup/travel-insured-people-delete-popup.component';
import {FormArray} from '@angular/forms';
import {TravelSlideInService} from '../../travel-core/services/travel-slide-in.service';

@Component({
  selector: 'travel-insured-people',
  templateUrl: './travel-insured-people.component.html',
  styleUrls: ['./travel-insured-people.component.scss'],
})
@UntilDestroy()
export class TravelInsuredPeopleComponent extends ScreenFormComponent implements OnInit, OnDestroy {
  public readonly slideInOneFactory: ComponentFactory<TravelInsuredPeopleSlideinComponent>;

  public showLoadingIcon: boolean = false;

  // search bar
  public searchValues: string;
  public textSearched: Text;

  public value: string = 'А а то Я я';

  // pagination
  public currentPageSimplified = 1;
  public currentPageGeneral = PAGINATION.DEFAULT_PAGE;
  public pages = PAGINATION.PAGES;
  public pageSize = PAGINATION.PAGE_SIZE;
  public totalRecords: number = 0;

  // table
  public columnHeadings: string[] = MOCK_TABLE_HEADERS;

  public sort: string = 'des';
  public tableRows: number[] = [];
  public tableColumns: number[] = [];
  public addedInsuredPersonArray: InsuredPerson[] = [];
  public fileInsuredPersonArray: InsuredPerson[] = [];

  public isDisable: boolean = false;

  public arrayBuffer: any;
  public file: File;

  public start: number = 0;
  public end: number = 9;
  public initialValue: number = 0;

  public isNumberOfPeopleMismatched: boolean = false;
  public isInvalidFile: boolean = true;
  public disableButtons: boolean;
  private readonly _travelInsuredPeoplePopupFactory: ComponentFactory<TravelInsuredPeoplePopupComponent>;
  //remove popup
  public readonly travelInsuredPeopleDeleteFactory: ComponentFactory<TravelInsuredPeopleDeletePopupComponent>;
  public logger: Logger;
  public tripInusredPeopleCount: number;
  public disableUploadButton: boolean;

  public messagesWithoutCloseIcon = MOCK_MESSAGES_CLOSE_ICON;

  public tripDetailPath: string = `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRIP_DETAILS}`;

  @ViewChild('docInput', {static: false}) public docInput: ElementRef;

  constructor(
    route: ActivatedRoute,
    _loggerFactory: LoggerFactory,
    componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _cdRef: ChangeDetectorRef,
    public travelSessionService: TravelSessionService,
    public dateFormateService: DateFormatterService,
    public travelPolicyService: TravelPolicyService,
    private readonly _popUpCtrl: PopUpController,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _router: Router,
    private readonly _travelSlideInService: TravelSlideInService,
  ) {
    super(route);
    this.logger = _loggerFactory.createLogger('TravelInsuredPeopleComponent');

    this._travelInsuredPeoplePopupFactory = componentFactoryResolver.resolveComponentFactory(
      TravelInsuredPeoplePopupComponent,
    );

    this.travelInsuredPeopleDeleteFactory = componentFactoryResolver.resolveComponentFactory(
      TravelInsuredPeopleDeletePopupComponent,
    );
    this.stepForm = this._formFactory.createFormManager<any>({
      insuredPeopleArray: new FormArray([]),
    });
  }

  public ngOnInit(): void {
    this.travelPolicyService.showPriceAccordion(true);
    this.initializeDefaultTable();
    this.hideUploadFileButton();

    let viaUploadFile: boolean = false;
    const travelData: ProposalDto = this.travelSessionService.getTravelData();
    this.currentPageGeneral = PAGINATION.DEFAULT_PAGE;

    if (travelData.tripDetailDto) {
      const tripDetails: TripDetailDto = travelData.tripDetailDto;
      viaUploadFile = tripDetails.isUploadFile;
      this.tripInusredPeopleCount = tripDetails.travelInsuredPeopleCount;
    }

    if (travelData.insuredPersonDto) {
      const insuredPeopleData: InsuredPersonDto = travelData.insuredPersonDto;
      this.fileInsuredPersonArray = insuredPeopleData.addedInsuredPerson;

      this.addedInsuredPersonArray =
        this.fileInsuredPersonArray != null ? [...this.fileInsuredPersonArray] : [];

      if (viaUploadFile) {
        if (this.fileInsuredPersonArray.length === this.tripInusredPeopleCount) {
          this.isNumberOfPeopleMismatched = false;
          this.validateFileData();
        } else {
          this.isNumberOfPeopleMismatched = true;
          this.updatePagination();
        }
      } else {
        this.updatePagination();
      }
    } else {
      // Time beign its commented
      // this._validateInsuredPeopleData(MOCK_INSURED_PERSON_LIST);
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  // table
  public initializeDefaultTable(): void {
    this._tableArray(this.tableRows, INSURED_PEOPLE_TABLE.DEFAULT_COMPLEX_ROWS);
    this._tableArray(this.tableColumns, INSURED_PEOPLE_TABLE.DEFAULT_COMPLEX_COLUMNS);
  }

  private _tableArray(simple: number[], numElements: number): void {
    for (let i = 0; i < numElements; i++) {
      simple.push(i);
    }
  }

  public removeAllPerson(): void {
    this.addedInsuredPersonArray = [];
  }

  //edit slide in
  public editPerson(insuredPerson: InsuredPerson, index: number): void {
    this.openInsuredPersonSlideIn(insuredPerson, index);
  }

  public updateInsuredPersonDetails(insuredPerson: InsuredPerson, index?: number): void {
    if (insuredPerson && index) {
      index = (this.currentPageGeneral - 1) * 10 + index;
      this.addedInsuredPersonArray[index] = insuredPerson;
    } else {
      this.addedInsuredPersonArray.push(insuredPerson);
      this.pages = this._getTotalPagesCount(this.addedInsuredPersonArray.length);
      this.isNumberOfPeopleMismatched =
        this.addedInsuredPersonArray.length !== this.tripInusredPeopleCount;
    }
  }

  // slideIn
  public openInsuredPersonSlideIn(insuredPerson?: InsuredPerson, index?: number): void {
    this._travelSlideInService
      .openInsuredPeopleSlideIn(insuredPerson)
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<InsuredPerson>) => {
        if (res.resolution === ModalResolution.CONFIRMED) {
          const insuredPersonData: InsuredPerson = res.result;
          const index =
            insuredPersonData != null
              ? this.addedInsuredPersonArray.findIndex(
                  (p: InsuredPerson) => p.idNumber === insuredPerson?.idNumber,
                )
              : -1;
          if (res && index > -1) {
            this.updateInsuredPersonDetails(insuredPersonData, index);
          } else {
            this.updateInsuredPersonDetails(insuredPersonData);
          }
        }
        this._cdRef.detectChanges();
      });
  }

  // search
  public handleSearchedText(text: Text) {
    this.textSearched = text;
  }

  public handleInputText(str: string) {
    if (str === null) {
      this.searchValues = '';
    }
  }

  //popup for delete insured people
  public delPopup(index: number): void {
    this._popUpCtrl
      .prepare(
        this.travelInsuredPeopleDeleteFactory,
        this._injector,
        {title: '', size: 'large'},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          if (null != index && 0 <= index) {
            this.addedInsuredPersonArray.splice(index, 1);
            this.calculateStartAndEndValue();
            this.updateUIAfterDelete();
          }
        }
        this._cdRef.detectChanges();
      });
  }

  public updateUIAfterDelete(): void {
    if (this.addedInsuredPersonArray != null && this.addedInsuredPersonArray.length === 0) {
      this.disableUploadButton = false;
    }
    this.isNumberOfPeopleMismatched =
      this.addedInsuredPersonArray != null
        ? this.addedInsuredPersonArray.length !== this.tripInusredPeopleCount
        : true;
  }

  // session updation
  protected saveMtplData(): void {
    const insuredPersonDto = {
      addedInsuredPerson: this.addedInsuredPersonArray,
    };
    this.travelSessionService.setInsuredPersonData(insuredPersonDto);
  }

  public onFileChange(event: any): void {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>event.target;
    if (target.files.length !== 1) {
      throw new Error('Cannot use multiple files');
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    this.travelPolicyService.setUploadedFile(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary', cellDates: true});

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this._cdRef.markForCheck();

      if (data && data.length > 0) {
        const filteredList = data.filter(
          (book: any) =>
            book["Insured person's PID"] &&
            book["Insured person's PID"] !== undefined &&
            book["Insured person's PID"] != null,
        );
        if (filteredList && filteredList.length > 0) {
          let totalFileRecord: InsuredPerson[] | undefined = this.parseFileData(filteredList);
          if (totalFileRecord != undefined) {
            totalFileRecord =
              this.addedInsuredPersonArray != null
                ? [...this.addedInsuredPersonArray, ...totalFileRecord]
                : totalFileRecord;
            this.fileInsuredPersonArray = totalFileRecord;

            if (totalFileRecord.length === this.tripInusredPeopleCount) {
              this.isNumberOfPeopleMismatched = false;
              this.addedInsuredPersonArray = [...totalFileRecord];
              this.validateFileData();
            } else {
              this.isNumberOfPeopleMismatched = true;
            }
          }
        } else {
          this.isInvalidFile = false;
        }
      } else {
        this.isInvalidFile = false;
      }
    };
  }

  public get questionsList(): FormArray {
    return this.stepForm.formGroup.controls.insuredPeopleArray as FormArray;
  }

  public parseFileData(filteredList?: unknown[]): InsuredPerson[] | undefined {
    return filteredList != null
      ? this.travelPolicyService.parseInusredPeopleFilterList(filteredList)
      : undefined;
  }

  public validateFileData(): boolean {
    this.addedInsuredPersonArray.forEach((element: InsuredPerson) => {
      const formGroup = this.travelPolicyService.updateInsuredPeopleArray(element);
      this.questionsList.push(formGroup);
    });
    this.isInvalidFile = this.stepForm.valid;
    this.updatePagination();

    return false;
  }

  public updatePagination(): void {
    this.pages = this._getTotalPagesCount(this.addedInsuredPersonArray.length);
    this.totalRecords = this.addedInsuredPersonArray.length;
    this._cdRef.markForCheck();
  }

  public lastPageGeneral(): boolean {
    return this.currentPageGeneral >= this.pages;
  }

  private _getTotalPagesCount(totalRecords: number): number {
    return totalRecords % this.pageSize === 0
      ? totalRecords / this.pageSize
      : Math.ceil(totalRecords / this.pageSize);
  }

  public pageChange(event: any): void {
    this.currentPageGeneral = event;
    this.calculateStartAndEndValue();
  }

  public calculateStartAndEndValue(): void {
    this.start = this.currentPageGeneral * this.pageSize - this.pageSize;
    this.end = this.currentPageGeneral * this.pageSize;
  }

  public getNumberOfRecords(): number {
    this.totalRecords = this.addedInsuredPersonArray.length;
    return this.pageSize * this.currentPageGeneral > this.totalRecords
      ? this.totalRecords
      : this.pageSize * this.currentPageGeneral;
  }

  public ascendingOrderFilter(): void {
    this.isDisable = !this.isDisable;

    const newValue = this.isDisable ? 1 : -1;

    this.addedInsuredPersonArray.sort((a: InsuredPerson, b: InsuredPerson) => {
      if (a.firstName < b.firstName) {
        return -1 * newValue;
      } else {
        return 0;
      }
    });
  }

  public descendingOrderFilter(): void {
    this.isDisable = !this.isDisable;

    const oldValue = this.isDisable ? 1 : -1;

    this.addedInsuredPersonArray.sort((a: InsuredPerson, b: InsuredPerson) => {
      if (a.firstName > b.firstName) {
        return 1 * oldValue;
      } else {
        return 0;
      }
    });
  }

  public hideUploadFileButton(): void {
    const data: ProposalDto = this.travelSessionService.data;
    if (data.tripDetailDto) {
      const tripDetail: TripDetailDto = data.tripDetailDto;
      this.disableButtons = tripDetail.isUploadFile;
      this.disableUploadButton = tripDetail.isUploadFile;
      this.tripInusredPeopleCount = tripDetail.travelInsuredPeopleCount;

      this.step.inEdit.subscribe(result => {
        if (result) {
          this.disableButtons = false;
          this.disableUploadButton = true;
          this.travelPolicyService.showPriceAccordion(false);
        }
      });
    }
  }

  public _openPopup(options: PopUpOptions): void {
    this._popUpCtrl
      .prepare(this._travelInsuredPeoplePopupFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
        input: undefined,
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        (response: ModalConfirmedResult<boolean>) => {
          console.log(response);
          if (response.resolution === ModalResolution.CONFIRMED && response.result !== null) {
            response.result === false
              ? this.docInput.nativeElement.click()
              : this.travelPolicyService.downloadSampleXls();
          }
        },
        (error: any) => {
          console.log(error);
        },
      );
  }

  public openPopup(): void {
    this.isInvalidFile = true;
    this._openPopup({title: '', size: 'large'});
  }

  public navigateToTripDeail(): void {
    const fileLength: number =
      this.fileInsuredPersonArray != null ? this.fileInsuredPersonArray.length : 0;
    const addPersonLength: number =
      this.addedInsuredPersonArray != null ? this.addedInsuredPersonArray.length : 0;
    const data: string = JSON.stringify(
      fileLength > addPersonLength ? this.fileInsuredPersonArray : this.addedInsuredPersonArray,
    );

    this._router.navigateByUrl(this.tripDetailPath, {state: {insuredPeople: data}});
  }

  public saveAndContinuBtnStatus(): boolean {
    return !(
      this.addedInsuredPersonArray != null &&
      this.addedInsuredPersonArray.length > 0 &&
      this.addedInsuredPersonArray.length === this.tripInusredPeopleCount
    );
  }

  //Need to Remove
  // private _validateInsuredPeopleData(fileDataList: InsuredPerson[]) {
  //   const validatedList: InsuredPerson[] = this._parseAndValidate(fileDataList);

  //   this.addedInsuredPersonArray = [...validatedList, ...this.addedInsuredPersonArray];
  //   this.addedInsuredPersonArray.sort((a: InsuredPerson, b: InsuredPerson) =>
  //     a.isValidData != null && b.isValidData != null ? b.isValidData - a.isValidData : 0,
  //   );
  //   this.showLoadingIcon = false;
  //   this.pages = this._getTotalPagesCount(this.addedInsuredPersonArray.length);
  //   this.totalRecords = this.addedInsuredPersonArray.length;
  //   this._cdRef.markForCheck();
  // }

  // private _parseAndValidate(fileDataList: InsuredPerson[]): InsuredPerson[] {
  //   fileDataList.forEach((fileData: InsuredPerson) => {
  //     fileData.partyType = fileData.idNumber
  //       ? this._partyIdService.getType(fileData.idNumber + '')
  //       : undefined;
  //     fileData.isValidIdAndPartyType =
  //       (fileData.partyType && fileData.partyType === PartyType.PERSON) ||
  //       fileData.partyType === PartyType.FOREIGNER
  //         ? true
  //         : false;
  //     if (fileData.partyType === PartyType.PERSON) {
  //       fileData.isValidName =
  //         fileData.firstName && fileData.middleName && fileData.lastName ? true : false;
  //       fileData.isValidDOB = true;
  //     } else if (fileData.partyType === PartyType.FOREIGNER) {
  //       fileData.isValidName =
  //         (fileData.firstName && fileData.lastName) || (fileData.firstNameEn && fileData.lastNameEn)
  //           ? true
  //           : false;
  //       fileData.isValidDOB =
  //         fileData.dob !== null && fileData.dob !== undefined
  //           ? this.dateFormateService.isValidDOB(fileData.dob)
  //           : true;
  //     }
  //     fileData.isValidData =
  //       fileData.isValidIdAndPartyType &&
  //       fileData.isValidName !== undefined &&
  //       fileData.isValidName &&
  //       fileData.isValidDOB !== undefined &&
  //       fileData.isValidDOB
  //         ? 0
  //         : 1;
  //   });
  //   return fileDataList;
  // }
}
