import {PremiumOptions} from './premium-screen-mockInterface';

export const MOCK_PREMIUM_SINGLE: PremiumOptions[] = [
  {
    premiumId: 1,
    premiumName: 'With Official Garage',
    amount: '200',
    selectedStatus: false,
  },
  {
    premiumId: 2,
    premiumName: 'With Trusted Garage and Original Parts',
    amount: '210',
    selectedStatus: false,
  },
  {
    premiumId: 3,
    premiumName: 'With Trusted Garage',
    amount: '220',
    selectedStatus: false,
  },
];

export const MOCK_PREMIUM_DOUBLE: PremiumOptions[] = [
  {
    premiumId: 4,
    premiumName: 'With Official Garage',
    amount: '200',
    selectedStatus: false,
  },
  {
    premiumId: 5,
    premiumName: 'With Trusted Garage and Original Parts',
    amount: '220',
    selectedStatus: false,
  },
  {
    premiumId: 6,
    premiumName: 'With Expert Evaluation',
    amount: '240',
    selectedStatus: false,
  },
];

export const MOCK_PREMIUM_FOUR: PremiumOptions[] = [
  {
    premiumId: 7,
    premiumName: 'With Official Garage',
    amount: '200',
    selectedStatus: false,
  },
  {
    premiumId: 8,
    premiumName: 'With Trusted Garage and Original Parts',
    amount: '220',
    selectedStatus: false,
  },
  {
    premiumId: 9,
    premiumName: 'With Expert Evaluation',
    amount: '240',
    selectedStatus: false,
  },
];
