import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-travel-trip-details-slidein',
  templateUrl: './travel-trip-details-slidein.component.html',
  styleUrls: ['./travel-trip-details-slidein.component.scss'],
})
export class TravelTripDetailsSlideinComponent extends ModalContentComponent<void> {
  public constructor(public control: ModalControl<void>) {
    super();
  }
}
