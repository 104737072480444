import {PricePremiumComponent} from './screens/price-premium/price-premium.component';
import {CoversDiscountsComponent} from './screens/covers-discounts/covers-discounts.component';
import {PolicyDetailsComponent} from './screens/policy-details/policy-details.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule, Route} from '@angular/router';
import {StepResolverService} from './casco-core/services/step.resolver';
import {CascoHomeComponent} from './casco-home.component';
import {CascoRoutePaths} from './constants/route-paths';
import {JourneychoiceComponent} from './screens/journeychoice/journeychoice.component';
import {PremiumScreenComponent} from './screens/premium-screen/premium-screen.component';
import {PriceOfferVehicleDetailsComponent} from './screens/price-offer-vehicle-details/price-offer-vehicle-details.component';
import {ProposalComponent} from './screens/proposal/proposal.component';
import {VehicleDetailsComponent} from './screens/vehicle-details/vehicle-details.component';
import {SumInsuredComponent} from './screens/sum-insured/sum-insured.component';
import {PriceCoversAndAddonsComponent} from './screens/price-covers-and-addons/price-covers-and-addons.component';
import {PeopleDetailsComponent} from './screens/people-details/people-details.component';
import {PriceOfferProposalComponent} from './screens/price-offer-proposal/price-offer-proposal.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'casco',
    pathMatch: 'full',
  },

  {
    path: 'casco',
    component: CascoHomeComponent,
    data: {
      njeB2BTitle: 'Casco',
    },
    children: [
      {
        path: '',
        redirectTo: CascoRoutePaths.CI_JOURNEY_CHOICE,
        pathMatch: 'full',
      },
      {
        path: CascoRoutePaths.CI_JOURNEY_CHOICE,
        component: JourneychoiceComponent,
        data: {
          isStepperVisible: false,
          stepId: CascoRoutePaths.CI_JOURNEY_CHOICE,
          maiaFlowProgressId: CascoRoutePaths.CI_JOURNEY_CHOICE,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_VEHICLE_AND_OWNER_DETAILS,
        component: VehicleDetailsComponent,
        data: {
          stepId: CascoRoutePaths.CI_VEHICLE_AND_OWNER_DETAILS,
          maiaFlowProgressId: CascoRoutePaths.CI_VEHICLE_AND_OWNER_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_COVERS,
        component: CoversDiscountsComponent,
        data: {
          stepId: CascoRoutePaths.CI_COVERS,
          maiaFlowProgressId: CascoRoutePaths.CI_COVERS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PROPOSAL,
        component: ProposalComponent,
        data: {
          stepId: CascoRoutePaths.CI_PROPOSAL,
          maiaFlowProgressId: CascoRoutePaths.CI_PROPOSAL,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PREMIUM,
        component: PremiumScreenComponent,
        data: {
          stepId: CascoRoutePaths.CI_PREMIUM,
          maiaFlowProgressId: CascoRoutePaths.CI_PREMIUM,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PRICE_VEHICLE_DETAILS,
        component: PriceOfferVehicleDetailsComponent,
        data: {
          stepId: CascoRoutePaths.CI_PRICE_VEHICLE_DETAILS,
          maiaFlowProgressId: CascoRoutePaths.CI_PRICE_VEHICLE_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PRICE_SUM_INSURED,
        component: SumInsuredComponent,
        data: {
          stepId: CascoRoutePaths.CI_PRICE_SUM_INSURED,
          maiaFlowProgressId: CascoRoutePaths.CI_PRICE_SUM_INSURED,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PRICE_OWNER_AND_INSURER_DETAILS,
        component: PeopleDetailsComponent,
        data: {
          stepId: CascoRoutePaths.CI_PRICE_OWNER_AND_INSURER_DETAILS,
          maiaFlowProgressId: CascoRoutePaths.CI_PRICE_OWNER_AND_INSURER_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PRICE_POLICY_DETAILS,
        component: PolicyDetailsComponent,
        data: {
          stepId: CascoRoutePaths.CI_PRICE_POLICY_DETAILS,
          maiaFlowProgressId: CascoRoutePaths.CI_PRICE_POLICY_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PRICE_COVERS_AND_ADDONS,
        component: PriceCoversAndAddonsComponent,
        data: {
          stepId: CascoRoutePaths.CI_PRICE_COVERS_AND_ADDONS,
          maiaFlowProgressId: CascoRoutePaths.CI_PRICE_COVERS_AND_ADDONS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PRICE_PROPOSAL,
        component: PriceOfferProposalComponent,
        data: {
          stepId: CascoRoutePaths.CI_PRICE_PROPOSAL,
          maiaFlowProgressId: CascoRoutePaths.CI_PRICE_PROPOSAL,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PRICE_PREMIUM,
        component: PricePremiumComponent,
        data: {
          stepId: CascoRoutePaths.CI_PRICE_PREMIUM,
          maiaFlowProgressId: CascoRoutePaths.CI_PRICE_PREMIUM,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: CascoRoutePaths.CI_PRICE_SUM_INSURED,
        component: PricePremiumComponent,
        data: {
          stepId: CascoRoutePaths.CI_PRICE_SUM_INSURED,
          maiaFlowProgressId: CascoRoutePaths.CI_PRICE_SUM_INSURED,
        },
        resolve: {
          step: StepResolverService,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CascoRoutingModule {}

/**
 * The route we export is anempty path with the private module lazy loaded.
 */
export const CASCO_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./casco-routing.module').then(module => module.CascoRoutingModule),
};
