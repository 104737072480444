import {CommonModule} from '@angular/common';
import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {B2bCoreModule} from '@b2b-frontend/core';
import {μExampleSvgModule} from '@b2b-frontend/icons';
import {AdditionalInformationModule as MaiaAdditionalInformationModule} from '@maia/additional-information';
import {
  AdvancedSearchModule as MaiaAdvancedSearchModule,
  AdvancedSearchOptionAmountModule,
  AdvancedSearchOptionDateModule,
  AdvancedSearchOptionFreeModule,
} from '@maia/advanced-search';
import {AvatarsModule as MaiaAvatarsModule} from '@maia/avatars';
import {ButtonsModule as MaiaButtonsModule} from '@maia/buttons';
import {CoreServicesModule as MaiaCoreServicesModule} from '@maia/core';
import {DatePickersModule} from '@maia/datepickers';
import {DisplaysModule as MaiaDisplaysModule} from '@maia/displays';
import {DividerModule as MaiaDividerModule} from '@maia/divider';
import {DropdownsModule} from '@maia/dropdowns';
import {FlexGridModule as MaiaFlexGridModule} from '@maia/flex-grid';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {InputAutocompleteModule as MaiaInputAutocompleteModule} from '@maia/input-autocomplete';
import {InputPhonenumberModule as MaiaInputPhonenumberModule} from '@maia/input-phonenumber';
import {InputSelectModule as MaiaInputSelectModule} from '@maia/input-select';
import {InputStepperModule as MaiaInputStepperModule} from '@maia/input-stepper';
import {LayoutModule as MaiaLayoutModule} from '@maia/layout';
import {MessagesModule as MaiaMessages} from '@maia/messages';
import {ModalsModule as MaiaModalsModule} from '@maia/modals';
import {NameValueCollectionsModule as MaiaNameValueCollectionsModule} from '@maia/name-value-collections';
import {PaginationModule as MaiaPaginationModule} from '@maia/pagination';
import {PanelsModule as MaiaPanelsModule} from '@maia/panels';
import {PopUpsModule as MaiaPopUpsModule} from '@maia/pop-ups';
import {PriceBlockModule as MaiaPriceBlockModule} from '@maia/price-block';
import {RadioButtonsModule as MaiaRadioButtonsModule} from '@maia/radio-buttons';
import {SlideInsModule as MaiaSlideInsModule} from '@maia/slide-ins';
import {SwitchesModule} from '@maia/switches';
import {TablesModule as MaiaTablesModule} from '@maia/tables';
import {TilesModule as MaiaTilesModule} from '@maia/tiles';
import {TimepickersModule as MaiaTimepickersModule} from '@maia/timepickers';

import {BASE_ROUTE} from './services/starter.service';
import {UamRoutingModule} from './uam-routing.module';
import {UamComponent} from './uam.component';

@NgModule({
  declarations: [UamComponent],
  imports: [
    B2bCoreModule,
    CommonModule,
    UamRoutingModule,
    MaiaCoreServicesModule.forRoot(),
    MaiaModalsModule,
    MaiaButtonsModule,
    MaiaFormsModule,
    MaiaRadioButtonsModule,
    MaiaTilesModule,
    MaiaFlexGridModule,
    MaiaDisplaysModule,
    MaiaPanelsModule,
    MaiaSlideInsModule,
    MaiaDividerModule,
    MaiaAdditionalInformationModule,
    MaiaNameValueCollectionsModule,
    DatePickersModule,
    MaiaTimepickersModule,
    MaiaInputSelectModule,
    MaiaAvatarsModule,
    DropdownsModule,
    SwitchesModule,
    MaiaInputStepperModule,
    MaiaLayoutModule,
    MaiaPriceBlockModule,
    MaiaAvatarsModule,
    MaiaPaginationModule,
    MaiaTablesModule,
    MaiaAdvancedSearchModule,
    AdvancedSearchOptionAmountModule,
    AdvancedSearchOptionDateModule,
    AdvancedSearchOptionFreeModule,
    MaiaInputAutocompleteModule,
    MaiaInputPhonenumberModule,
    MaiaPopUpsModule,
    MaiaMessages,
    MaiaTablesModule,
    μExampleSvgModule,
  ],
})
export class UamModule {
  // Static method for injecting the module with a base route provided.
  public static forPath(path: string): ModuleWithProviders<UamModule> {
    return {
      ngModule: UamModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error('Do not import UamModule directly, use UamModule.forPath() instead');
    }
  }
}
