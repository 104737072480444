import {TravelRoutePaths} from '@b2b-frontend/core';

import {TravelWorkflow} from './TravelWorkflow';

export const workflows: {[name: string]: string[]} = {
  [TravelWorkflow.TRAVEL_POLICY]: [
    TravelRoutePaths.TI_TRIP_DETAILS,
    TravelRoutePaths.TI_TRAVEL_COVERS,
    TravelRoutePaths.TI_ADD_ONS,
    TravelRoutePaths.TI_INSURING_PARTY,
    TravelRoutePaths.TI_INSURED_PEOPLE,
    TravelRoutePaths.TI_TRAVEL_PROPOSAL,
  ],
};
