import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TransliterateService {
  private readonly map: {[c: string]: string} = {
    а: 'a',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    е: 'e',
    ж: 'zh',
    з: 'z',
    и: 'i',
    й: 'y',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    о: 'o',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ф: 'f',
    х: 'h',
    ц: 'ts',
    ч: 'ch',
    ш: 'sh',
    щ: 'sht',
    ъ: 'a',
    ь: 'y',
    ю: 'yu',
    я: 'ya',
  };

  private readonly vechicleRegNumberMap: {[c: string]: string} = {
    А: 'A',
    В: 'B',
    Е: 'E',
    К: 'K',
    М: 'M',
    Н: 'H',
    О: 'O',
    Р: 'P',
    С: 'C',
    Т: 'T',
    У: 'Y',
    Х: 'X',
    а: 'a',
    в: 'b',
    е: 'e',
    к: 'k',
    м: 'm',
    н: 'h',
    о: 'o',
    р: 'p',
    с: 'c',
    т: 't',
    у: 'y',
    х: 'x',
  };

  public trans({
    source,
    isVechicleRegNumber,
  }: {
    source: string;
    isVechicleRegNumber?: boolean;
  }): string {
    let result = '';
    let map = this.map;
    if (isVechicleRegNumber) {
      map = this.vechicleRegNumberMap;
    }
    if (source !== undefined && source !== '' && source !== null) {
      for (let i = 0; i < source.length; i++) {
        const c = source.charAt(i);
        const isSecondCharUpperCase = source.charAt(1).toLowerCase() !== source.charAt(1);
        const isUpperCase = c.toLowerCase() !== c;

        if (c.toLowerCase() in map) {
          if (isUpperCase) {
            const s = map[c.toLowerCase()];
            let char2 = s.substr(1);
            if (i === 0) {
              char2 = isSecondCharUpperCase ? char2.toUpperCase() : char2;
            } else {
              char2 = isUpperCase ? char2.toUpperCase() : char2;
            }
            result += s[0].toUpperCase() + char2;
          } else {
            result += map[c];
          }
        } else {
          result += c;
        }
      }
    }

    return isVechicleRegNumber ? result.toUpperCase() : result;
  }
}
