import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncCoinsMinusSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncCoinsMinus]',
  template: '<svg:path stroke-width=".83" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M15.85 4.62h4.3"></svg:path><svg:ellipse cx="8.11" cy="7.79" rx="4.7" ry="2.16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:ellipse><svg:path d="M12.88 7.79v3c0 1.18-2.15 2.15-4.77 2.15s-4.7-1-4.7-2.15v-3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path><svg:path d="M11.12 15.43a8.12 8.12 0 0 1-3 .52c-2.61 0-4.7-1-4.7-2.16v-3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path><svg:path d="M11.12 18.43a7.92 7.92 0 0 1-3 .52c-2.61 0-4.7-1-4.7-2.15v-3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path><svg:ellipse cx="15.89" cy="13.34" rx="4.7" ry="2.16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:ellipse><svg:path d="M20.59 13.34v3c0 1.18-2.09 2.16-4.7 2.16s-4.77-1-4.77-2.16v-3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path><svg:path d="M20.59 16.34v3c0 1.18-2.09 2.16-4.7 2.16s-4.77-1-4.77-2.16v-3" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncCoinsMinusSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncCoinsMinusSvgComponent,
  ],
  exports: [
    InformationFuncCoinsMinusSvgComponent,
  ],
})
export class InformationFuncCoinsMinusSvgModule {}
