import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AUTH_ROUTE} from '@b2b-frontend-flows/auth';
import {DASHBORD_ROUTE} from '@b2b-frontend-flows/dashboard';

import {RoutePaths} from '../constants/route-paths';

// The only route we have by default for the application is a route to redirect to the list path
export const APPLICATION_ROUTES: Routes = [
  {path: RoutePaths.AUTH, children: [AUTH_ROUTE]},
  {path: RoutePaths.DASHBOARD, children: [DASHBORD_ROUTE]},
  {path: '', redirectTo: RoutePaths.AUTH, pathMatch: 'full'},
];

/**
 * We add the application routes through this module.
 * If your environment doesn't support HTML5 location-based route you should alter this in the
 * environment config.
 */
@NgModule({
  imports: [
    RouterModule.forRoot(APPLICATION_ROUTES, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
