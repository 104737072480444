<njf-loader [hidden]="!showLoadingIcon"></njf-loader>
<div *ngIf="isAdminLibrarySectionShown()">
  <maia-label
    class="b2b-kdl-font-small title-dark-color"
    i18n="@@nje-b2b-frontend-resource-library-list-adminlist"
    >List of Admin document libraries</maia-label
  >
  <maia-radio-group ngDefaultControl name="library">
    <maia-tile
      *ngFor="let library of libraryList; let i = index"
      class="m-t-b-24 tile_size"
      [ngClass]="{
        'library-tile-selected': libraryList[i].selectedStatus
      }"
    >
      <maia-radio-button
        class="radio-border"
        [value]="library"
        (click)="selectAdminLibrary(i, library)"
      >
        <div class="box-div">
          <label class="b2b-kdl-font-small cover-title line-clamp-1">
            {{ isLanguageEnglish() ? library.i18n?.en?.title : library.i18n?.bg?.title }}
          </label>
          <label class="b2b-kdl-font-small-light cover-sub-title line-clamp-3">
            <maia-rich-text
              [value]="
                isLanguageEnglish()
                  ? (library.i18n?.en?.description | hyperlink)
                  : (library.i18n.bg.description | hyperlink)
              "
            ></maia-rich-text>
          </label>
          <div>
            <button
              maiaButton
              maiaButtonType="tertiary"
              class="button-allign m-r-16 b2b-kdl-font-small"
              (click)="addEditLibrary(library, i)"
              i18n="@@nje-b2b-frontend-resource-admin-edit"
              >Edit</button
            >
            <button
              maiaButton
              maiaButtonType="tertiary-delete"
              class="button-allign"
              (click)="deleteLibrary(library.id)"
              i18n="@@nje-b2b-frontend-resource-library-list-delete"
              >Delete</button
            >
          </div>
        </div>
      </maia-radio-button>
    </maia-tile>
  </maia-radio-group>

  <div class="action-with-icon" (click)="addEditLibrary()">
    <svg class="action-func-plus-circle" b2bFrontendActionFuncPlusCircle></svg>
    <button
      maiaButton
      maiaButtonType="tertiary"
      class="add-btn"
      i18n="@@nje-b2b-frontend-resource-library-list-newlib"
      >Add New Library</button
    >
  </div>
  <maia-divider></maia-divider>
</div>
