import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
})
export class RelatedPartyDeletePopupComponent extends ModalContentComponent<void> {
  public constructor(public control: ModalControl<void>) {
    super();
  }

  public delete(): void {
    this.control.confirm();
  }

  public cancel(): void {
    this.control.cancel();
  }
}
