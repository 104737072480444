import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
@Component({
  selector: 'nje-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.scss'],
})
export class DeletePopupComponent extends ModalContentComponent<void, string> {
  public heading: string;
  public constructor(public control: ModalControl<void, string>) {
    super();
    this.heading = this.control.input;
  }

  public delete(): void {
    this.control.confirm();
  }
}
