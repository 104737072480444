// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `lang.ts`, but if you do
// provide a language configuration then its environment file will be applied.

export const enum Language {
  Bulgarian = 'bg',
  English = 'en',
}

export const language: Language | null = null;
export const EDIT = 'Edit';
export const SELECT = 'Select';
