/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../../backend/api.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  public language: string = '';
  public fileUploadPath: string[] = ['talon-image-upload'];

  public constructor(public apiService: ApiService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersConfig: any = {};
    this.language = this.getLanguage();
    const URLPath = req.url.substring(req.url.lastIndexOf('/') + 1);
    const fileUploadPathIndex = this.fileUploadPath.findIndex(
      path => path === URLPath.split('?')[0],
    );
    if (fileUploadPathIndex < 0) {
      headersConfig = {
        ...headersConfig,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
        Expires: '0',
        Pragma: 'no-cache',
        language: this.language,
        timeout: 600000,
      };
    }
    return this.handleRequest(req, next, headersConfig);
  }

  public getLanguage(): string {
    const lang = sessionStorage.getItem('lang');
    if (lang != null) {
      return lang;
    }
    return 'en';
  }

  private handleRequest(req: HttpRequest<any>, next: HttpHandler, headersConfig: any) {
    const request = req.clone({headers: new HttpHeaders(headersConfig)});
    return next.handle(request);
  }
}
