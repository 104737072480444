<nje-vehicle-user-input
  (vehicleIdNumber)="searchVehicleByVinNo($event)"
  (vehicleRegNumber)="searchVehicleByRegNo($event)"
  (showFormEmitter)="showFormFunction($event)"
  [screen]="screen"
  [isData]="isData"
></nje-vehicle-user-input>

<form maiaForm [formGroup]="vehicleForm.formGroup">
  <div *ngIf="showForm || (step.inEdit | async)">
    <maia-divider class="m-t-24"></maia-divider>
    <label
      class="b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-label-vehicledetails"
    >
      Vehicle Details
    </label>
    <div class="container">
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child"
        label="Type of Vehicle"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-vehicletype"
      >
        <maia-input-select name="typeOfVehicle" formControlName="typeOfVehicle">
          <input
            maiaInput
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-select"
            placeholder="Select"
            [value]="vehicleForm.controls['typeOfVehicle'].value?.description"
          />
          <maia-option *ngFor="let type of mockVehicleTypes$ | async" [value]="type">{{
            type.description
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-make"
        label="Make"
      >
        <maia-input-select name="make" formControlName="make">
          <input
            maiaInput
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-select"
            placeholder="Select"
            [value]="vehicleForm.controls['make'].value?.id"
          />
          <maia-option *ngFor="let make of mockMake$ | async" [value]="make">{{
            make.id
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>

      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-model"
        label="Model"
      >
        <maia-input-select name="model" formControlName="model">
          <input
            maiaInput
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-select"
            placeholder="Select"
            [value]="vehicleForm.controls['model'].value?.name"
          />
          <maia-option *ngFor="let model of mockModel$ | async" [value]="model">{{
            model.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-regdate"
        label="Date of First Registration"
        class="multi-input-row child"
        hideOptionalIndicator
      >
        <maia-input-date
          formControlName="dofr"
          [minimum]="minDate"
          [maximum]="maxDate"
          name="Date of First Registration"
        ></maia-input-date>
        <maia-validation
          *ngIf="vehicleForm.hasToShowErrors('dofr') && vehicleForm.hasError('dofr', 'required')"
          i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-req"
        >
          Date required
        </maia-validation>
        <maia-validation
          *ngIf="vehicleForm.hasError('dofr', 'invalid')"
          i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-invalid"
        >
          Invalid date
        </maia-validation>
        <maia-validation
          *ngIf="vehicleForm.hasError('dofr', 'atlasBefore')"
          i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-before"
        >
          Date should be before {{ maxDate | atlasDate }}
        </maia-validation>
        <maia-validation
          *ngIf="vehicleForm.hasError('dofr', 'atlasAfter')"
          i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-after"
        >
          Date should be after {{ minDate | atlasDate }}.
        </maia-validation>
      </maia-form-element>

      <maia-form-element
        label="Engine Power"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-enginepower"
        class="multi-input-row child"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="enginePower"
            name="enginePower"
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-select"
          />
          <maia-input-group-suffix>HP</maia-input-group-suffix>
        </maia-input-group>
      </maia-form-element>

      <maia-form-element
        label="Tonnage"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-tonnage"
        class="multi-input-row child"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="tonnage"
            name="tonnage"
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-select"
            placeholder="Select"
          />
          <maia-input-group-suffix
            i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-suffix-tonnes"
            >Tonnes</maia-input-group-suffix
          >
        </maia-input-group>
      </maia-form-element>

      <maia-form-element
        label="Seats"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-seats"
        class="multi-input-row child"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="seats"
            name="seats"
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-select"
            placeholder="Select"
          />
        </maia-input-group>
      </maia-form-element>
    </div>
    <maia-divider class="m-t-24"></maia-divider>
    <label
      class="b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-label-ownerdetails"
    >
      Owner Details
    </label>
    <maia-fluid-fluid-layout alignContentTop>
      <maia-form-element
        class="multi-input-row m-r-16"
        label="EGN / EIK"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-egneik"
        [options]="{validationInstant: true}"
      >
        <maia-input-group>
          <input
            (keyup.enter)="searchOwnerBYIdNumber(vehicleForm.controls['idNumber'].value)"
            formControlName="idNumber"
            maiaInput
            name="EGN / EIK"
            placeholder="EGN / EIK"
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-egneik"
          />
          <svg
            (click)="searchOwnerBYIdNumber(vehicleForm.controls['idNumber'].value)"
            class="icon-size-color"
            b2bFrontendInformationFuncSearch
          ></svg>
        </maia-input-group>
        <div
          *ngIf="
            vehicleForm.controls['idNumber'].invalid &&
            (vehicleForm.controls['idNumber'].dirty || vehicleForm.controls['idNumber'].touched)
          "
        >
          <maia-validation
            *ngIf="vehicleForm.controls['idNumber'].errors?.invalidIdNumber"
            i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-invalidegneik"
            >Invalid EGN or EIK number.
          </maia-validation>
        </div> </maia-form-element
      ><div></div
    ></maia-fluid-fluid-layout>
    <div class="container">
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child"
        label="Type of Owner"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-ownertype"
      >
        <maia-input-select name="typeOfOwner" formControlName="typeOfOwner">
          <input
            maiaInput
            placeholder="Select"
            [value]="vehicleForm.controls['typeOfOwner'].value"
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-select"
          />
          <maia-option *ngFor="let ownerType of mockPersonTypeList" [value]="ownerType.name">{{
            ownerType.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        label="Age of Owner"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-ownerage"
        class="multi-input-row child"
        hideOptionalIndicator
        *ngIf="isAge"
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="age"
            name="age"
            placeholder="Age"
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-age"
          />
        </maia-input-group>
      </maia-form-element>

      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child city-and-postcode"
        label="City & Zip"
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-cityzip"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="city"
            formControlName="city"
            (typeAhead)="cityAutocomplete.filterTypeahead($event)"
          >
            <input
              autocomplete="off"
              maiaInput
              i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-city"
              placeholder="City"
            />

            <maia-option *ngFor="let cityObj of cityArray" [value]="cityObj">
              <maia-option-highlight [label]="cityObj.city"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input
              class="postcode-style"
              maiaInput
              type="text"
              placeholder="Zip"
              formControlName="postCode"
              readonly
            />
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
    <maia-divider class="m-t-24"></maia-divider>
    <button
      type="button"
      *ngIf="!(step.inEdit | async)"
      class="m-t-24 m-b-24"
      i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-btn-continue"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!vehicleForm.valid"
      (click)="saveAndContinue()"
      >Continue</button
    >
    <div *ngIf="step.inEdit | async">
      <button
        type="button"
        class="m-r-24 m-t-b-24"
        [disabled]="!vehicleForm.valid"
        maiaButton
        maiaButtonType="primary"
        (click)="saveEdit()"
        >Save Changes</button
      >
      <button maiaButton type="button" maiaButtonType="secondary" (click)="cancelEdit()"
        >Cancel</button
      >
    </div>
  </div>
</form>
