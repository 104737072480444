import {Injectable} from '@angular/core';
import {MtplVehicleOwnerDetails} from '../../models/MtplVehicleOwnerData';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public vehicleOwnerDetails: MtplVehicleOwnerDetails;
  public certificationNumber: string;
  public registrationNumber: string;
  public isScenarioFour: boolean = false;
  public isScenarioTwo: boolean = false;
}
