<p class="b2b-kdl-font-medium-bold align" i18n="@@nje-b2b-frontend-resource-admin-deletelib"
  >Delete selected library?</p
>
<maia-label i18n="@@nje-b2b-frontend-resource-admin-confirmdelete"
  >Are you sure you want to delete the selected library?</maia-label
>

<div *maiaPopUpFooter>
  <button
    maiaButton
    maiaButtonType="tertiary"
    class="btn-delete"
    (click)="control.cancel()"
    i18n="@@nje-b2b-frontend-resource-library-list-selectcancel"
    >Cancel</button
  >
  <button
    maiaButton
    maiaButtonType="delete"
    class="btn-success"
    (click)="delete()"
    i18n="@@nje-b2b-frontend-resource-contacts-yes"
    >Yes</button
  >
</div>
