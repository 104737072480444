import {Amount} from '../contracts/instalment-payment/instalment';
import {Decimal} from '@atlas/businesstypes';

export const PAYMENTS_OVERVIEW = {
  INSURED_PARTY: 'Insured party/Payer',
};

export const MOCK_PAYMENT_AMOUNT: Amount = {
  value: new Decimal('15.43'),
  currency: 'BGN',
};

export const PAYMENT_AMOUNT_TITLE: string = 'Total amount of payment';

export const INSTALMENT_PAYMENT = {
  BLANK_VALUE: ' ',
  PAYMENT_METHOD: {
    DIRECT_DEBIT: 4,
    UBB: 6,
  },
};

export const PRODUCT_CODE = {
  propertyCode: '8100',
  motorCode: '4600',
};
