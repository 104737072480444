import {SearchBy} from '../models/SearchBy';

export enum AccountStatus {
  DEACTIVATED = 0,
  ACTIVATED = 1,
  SUSPENDED = 2,
}

export enum UserStatus {
  REGISTERED = 'REGISTERED',
  TEMP_PWD_USE = 'TEMP_PWD_USE',
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  DEACTIVATED = 'DEACTIVATED',
}

export enum UserLanguage {
  BG = 'bg',
  EN = 'en',
}

export enum NumberConst {
  ZERO = 0,
  ARRAY_LENGTH_ZERO = 0,
  ARRAY_LENGTH_ONE = 1,
  INDEX_MINUS_ONE = -1,
  INDEX_ZERO = 0,
  INDEX_ONE = 1,
}

export enum AccountDetailsTitles {
  ACCOUNT_NAME = 'Account Name',
  EGN_LNCH_EIK = 'EGN / LNCH / EIK',
  BIRTH_DATE = 'Birth Date',
  CITY = 'City',
  ADDRESS = 'Address',
  EMAIL_ID = 'Email Id',
  PHONE_NUMBER = 'Phone Number',
}

export enum B2BAccountDetailsTitles {
  ACCOUNT_TYPE = 'Account Type',
  ACCOUNT_NAME = 'Account Name',
  EGN_LNCH_EIK = 'EGN / LNCH / EIK',
  AGENCY = 'Agency',
  AGENT_NO = 'Agent No. / Seller Code.',
  EMAIL_ID = 'E mail',
}

export enum APIParams {
  AMPERSAND = '&',
  IS_ACTIVE = 'isActive=',
  LANG = 'lang=',
  LIMIT = 'limit=',
  OFFSET = 'offset=',
  TYPE = 'type=',
  USER_STATUS = 'userStatus=',
  VALUE = 'value=',
}

export const B2BAccountDetails = [
  {
    icon: 'b2bFrontendTransportationIndiVehicleLicensePlate',
    title: 'Account type',
    values: 'Manager Agent',
  },
  {icon: 'b2bFrontendTransportationFuncCar', title: 'Account Name', values: 'Alag Rakthesh'},
  {icon: 'b2bFrontendTransportationFuncCar', title: 'EGN/LNCH/EIK', values: '12345678'},
  {icon: 'b2bFrontendTransportationExprTransporter', title: 'Agency', values: 'ABC Agency'},
  {icon: 'b2bFrontendPlacesFuncFactory', title: 'Agent No. / Seller Code.', values: 'AG3342'},
  {icon: 'b2bFrontendTransportationExprCarNew', title: 'E mail', values: 'Active'},
];

export const B2CAccountDetails = [
  {
    icon: 'b2bFrontendTransportationIndiVehicleLicensePlate',
    title: 'Alag Rakthesh',
    values: 'Account Name',
  },
  {icon: 'b2bFrontendTransportationFuncCar', title: '256357898', values: 'EGN/LNCH/EIK'},
  {icon: 'b2bFrontendTransportationFuncCar', title: '12-21-2021', values: 'Birthdate'},
  {icon: 'b2bFrontendTransportationExprTransporter', title: 'Sofia', values: 'City'},
  {icon: 'b2bFrontendPlacesFuncFactory', title: '12, Sofia street', values: 'Address'},
  {icon: 'b2bFrontendTransportationExprCarNew', title: 'example@gmail.com', values: 'Email-id'},
  {icon: 'b2bFrontendTransportationExprCarNew', title: '7412345678', values: 'Phone number'},
];

export const AccountsList = [
  {
    id: 100001,
    userTypeId: 2,
    firstNameEn: 'Olive1',
    middleNameEn: 'Tom',
    lastNameEn: 'Yew',
    firstName: 'Olive1',
    middleName: 'Tom',
    lastName: 'Yew',
    intermediaryPID: '8632472341',
    intermediaryName: 'I1 Pani',
    pid: '637893931',
    userName: 'olive@gmail.com',
    emailId: 'olive@gmail.com',
    phone: '+91-123456',
    citizenship: 'Bulgaria',
    city: 'Sofia',
    postCode: '1000',
    birthDate: new Date('01-01-1990'),
    status: 'ACTIVE',
    orgId: 2,
  },
  {
    id: 100002,
    userTypeId: 2,
    firstNameEn: 'Olive2',
    middleNameEn: 'Tom',
    lastNameEn: 'Yew',
    firstName: 'Olive2',
    middleName: 'Tom',
    lastName: 'Yew',
    intermediaryPID: '8632472341',
    intermediaryName: 'I1 Pani',
    pid: '637893931',
    userName: 'olive@gmail.com',
    emailId: 'olive@gmail.com',
    phone: '+91-123456',
    citizenship: 'Bulgaria',
    city: 'Sofia',
    postCode: '1000',
    birthDate: new Date('01-01-1990'),
    status: 'ACTIVE',
    orgId: 2,
  },
  {
    id: 100003,
    userTypeId: 2,
    firstNameEn: 'Olive3',
    middleNameEn: 'Tom',
    lastNameEn: 'Yew',
    firstName: 'Olive3',
    middleName: 'Tom',
    lastName: 'Yew',
    intermediaryPID: '8632472341',
    intermediaryName: 'I1 Pani',
    pid: '637893931',
    userName: 'olive@gmail.com',
    emailId: 'olive@gmail.com',
    phone: '+91-123456',
    citizenship: 'Bulgaria',
    city: 'Sofia',
    postCode: '1000',
    birthDate: new Date('01-01-1990'),
    status: 'ACTIVE',
    orgId: 2,
  },
  {
    id: 100004,
    userTypeId: 2,
    firstNameEn: 'Olive4',
    middleNameEn: 'Tom',
    lastNameEn: 'Yew',
    firstName: 'Olive4',
    middleName: 'Tom',
    lastName: 'Yew',
    intermediaryPID: '8632472341',
    intermediaryName: 'I1 Pani',
    pid: '637893931',
    userName: 'olive@gmail.com',
    emailId: 'olive@gmail.com',
    phone: '+91-123456',
    citizenship: 'Bulgaria',
    city: 'Sofia',
    postCode: '1000',
    birthDate: new Date('01-01-1990'),
    status: 'ACTIVE',
    orgId: 2,
  },
];

export const B2BAccountsList = [
  {
    id: 1,
    userTypeId: 2,
    firstNameEn: 'Olive',
    middleNameEn: 'Tom',
    lastNameEn: 'Yew',
    firstName: 'Olive',
    middleName: 'Tom',
    lastName: 'Olive',
    intermediaryPID: '8632472341',
    intermediaryName: 'I1 Pani',
    pid: '637893931',
    userName: 'olive@gmail.com',
    emailId: 'olive@gmail.com',
    phone: '+91-123456',
    citizenship: 'Bulgaria',
    city: 'Sofia',
    postCode: '1000',
    birthDate: new Date('01-01-1990'),
    status: 'ACTIVE',
    orgId: 2,
  },
  {
    id: 1,
    userTypeId: 3,
    firstNameEn: 'Olive2',
    middleNameEn: 'Tom',
    lastNameEn: 'Yew',
    firstName: 'Olive2',
    middleName: 'Tom',
    lastName: 'Olive',
    intermediaryPID: '8632472341',
    intermediaryName: 'I1 Pani',
    pid: '637893931',
    userName: 'olive@gmail.com',
    emailId: 'olive@gmail.com',
    phone: '+91-123456',
    citizenship: 'Bulgaria',
    city: 'Sofia',
    postCode: '1000',
    birthDate: new Date('01-01-1990'),
    status: 'ACTIVE',
    orgId: 2,
  },
  {
    id: 1,
    userTypeId: 4,
    firstNameEn: 'Olive3',
    middleNameEn: 'Tom',
    lastNameEn: 'Yew',
    firstName: 'Olive3',
    middleName: 'Tom',
    lastName: 'Olive',
    intermediaryPID: '8632472341',
    intermediaryName: 'I1 Pani',
    pid: '637893931',
    userName: 'olive@gmail.com',
    emailId: 'olive@gmail.com',
    phone: '+91-123456',
    citizenship: 'Bulgaria',
    city: 'Sofia',
    postCode: '1000',
    birthDate: new Date('01-01-1990'),
    status: 'ACTIVE',
    orgId: 2,
  },
  {
    id: 1,
    userTypeId: 5,
    firstNameEn: 'Olive4',
    middleNameEn: 'Tom',
    lastNameEn: 'Yew',
    firstName: 'Olive4',
    middleName: 'Tom',
    lastName: 'Olive',
    intermediaryPID: '8632472341',
    intermediaryName: 'I1 Pani',
    pid: '637893931',
    userName: 'olive@gmail.com',
    emailId: 'olive@gmail.com',
    phone: '+91-123456',
    citizenship: 'Bulgaria',
    city: 'Sofia',
    postCode: '1000',
    birthDate: new Date('01-01-1990'),
    status: 'ACTIVE',
    orgId: 2,
  },
];

export const B2BSearchParameters = [
  {displayValue: 'Agency Name', id: 'name', isUserSearch: false},
  {displayValue: 'PID of Intermediary', id: 'pid', isUserSearch: false},
  {displayValue: 'PID of User', id: 'pid', isUserSearch: true},
  {displayValue: 'User Name', id: 'email', isUserSearch: true},
];

export const B2CSearchParameters: SearchBy[] = [
  {displayValue: 'Name', id: 'name', isUserSearch: true},
  {displayValue: 'PID of User', id: 'pid', isUserSearch: true},
  {displayValue: 'User Name / Email', id: 'email', isUserSearch: true},
];

export const B2BTableColumnHeadings = [
  'Account Type',
  'Intermediary PID Number',
  'Name of the Intermediary',
  'PID Number of the User',
  'Name of the User',
  'User Name',
];

export const B2CTableColumnHeadings = [
  'Name of the User',
  'PID Number of the User',
  'User Name / Email',
];

export const COLON = ':';

export const SLASH = '/';

export const BLANK_STRING = '';

export const ACTIVATE_ACCOUNT = 'Activate Account';

export const ACTIVATE_ACCOUNTS = 'Activate Accounts';

export const DEACTIVATE_ACCOUNT = 'Deactivate Account';

export const ACCOUNT_ACTIVATED = 'Account Activated';

export const ACCOUNT_DEACTIVATED = 'Account Deactivated';

export const ALL_ACCOUNT_ACTIVATED = 'All Accounts Activated';

export const ACTIVATION_CONFIRMATION_ACCOUNTS_COUNT = '-accounts-count-';

export const ACCOUNTS_ACTIVATION_CONFIRMATION =
  'Confirm to Activate all the -accounts-count- Accounts displayed?';

export const POPUP_SIZE_LARGE = 'large';

export const ACTIVATE = 'Activate';

export const CONFIRM = 'Confirm';

export const PASSWORD_RESET_SUCCESSFULLY = 'Password Reset Successfully';

export const ACCOUNT_SUSPENDED_SUCCESSFULLY = 'Account Suspended Successfully';

export const SEARCH_BY = 'Search by';

export const SEARCH_TERM = 'Search term';

export const USER_MANAGEMENT = 'User management';
