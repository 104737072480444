import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {FindDeviceService} from '../../../talon-scanning/services/find-device.service';
import {CAMERA_FACING_MODE} from '../../../constants/config/config';

@Component({
  selector: 'systemcode-webcam-snapshot',
  templateUrl: './webcam-snapshot.component.html',
  styleUrls: ['./webcam-snapshot.component.scss'],
})
export class WebcamSnapshotComponent implements OnInit, AfterViewInit, OnDestroy {
  WIDTH = 640;
  HEIGHT = 480;

  CANVAS_WIDTH = 640;
  CANVAS_HEIGHT = 480;

  @ViewChild('video', {static: false})
  public video: ElementRef;

  @ViewChild('canvas', {static: false})
  public canvas: ElementRef;

  public isMobileDevice: boolean = false;
  public facingMode: string = CAMERA_FACING_MODE.USER_MODE;

  @Output() public emitCapturedImage: EventEmitter<string> = new EventEmitter<string>();
  @Output() public backToScanOptions: EventEmitter<boolean> = new EventEmitter<boolean>();
  public stream: MediaStream;
  public timeId: any;

  @Input() public set loopCaptureImage(loopCaptureImage: boolean) {
    if (!loopCaptureImage) {
      // this.capture();
      clearInterval(this.timeId);
    }
  }

  captures: string[] = [];
  capturedImage: string = '';
  error: any;
  isCaptured: boolean;
  public showFrame: boolean = false;
  public timeOut: boolean = false;

  public constructor(
    public _findDevice: FindDeviceService,
    private readonly _cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isMobileDevice = this._findDevice.checkIsItMobileDevice();
    this.facingMode = this.isMobileDevice
      ? CAMERA_FACING_MODE.ENVIRONMENT_MODE
      : CAMERA_FACING_MODE.USER_MODE;
  }

  async ngAfterViewInit() {
    await this.setupDevices();
  }

  public async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: this.isMobileDevice ? {facingMode: {exact: this.facingMode}} : true, // faceing mode
        });
        if (this.stream) {
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  public startProcessing(): void {
    this.showFrame = true;
    this.error = '';
    this.timeOut = false;
    // this.capture();
    this.timeId = setInterval(() => {
      this.capture();
    }, 5000);
    setTimeout(() => {
      clearInterval(this.timeId);
      this.showFrame = false;
      this.timeOut = true;
      this._cd.markForCheck();
    }, 60000);
  }

  public capture(): void {
    this.drawImageToCanvas(this.video.nativeElement);
    this.capturedImage = this.canvas.nativeElement.toDataURL('image/png');
    this.captures.push(this.capturedImage);
    this.isCaptured = false;
    this.emitCapturedImage.emit(this.capturedImage);
  }

  public removeCurrent(): void {
    this.isCaptured = false;
  }

  public setPhoto(idx: number): void {
    this.isCaptured = true;
    const image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvas(image);
  }

  public drawImageToCanvas(image: any): void {
    this.canvas.nativeElement.getContext('2d').drawImage(image, 0, 0, this.WIDTH, this.HEIGHT);
  }

  public back(): void {
    this.isCaptured = false;
    this.stopStreamVideoTrack();

    this.backToScanOptions.emit(false);
  }

  public stopStreamVideoTrack(): void {
    this.stream.getVideoTracks().forEach(track => {
      track.stop();
    });
  }

  public ngOnDestroy(): void {
    this.stopStreamVideoTrack();
  }
}
