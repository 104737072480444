import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {RoutePaths} from '../../constants/route-paths';
import {AuthNavigationService} from '../../services/auth-navigation.service';

@Component({
  selector: 'nje-two-fa',
  templateUrl: './two-fa.component.html',
  styleUrls: ['./two-fa.component.scss'],
})
export class TwoFaComponent implements OnInit {
  public twofaForm: FormGroup;

  public constructor(private readonly _AuthNavigationService: AuthNavigationService) {}

  public ngOnInit(): void {
    this.twofaForm = new FormGroup({
      twofaChoiceRadio: new FormControl(undefined, [Validators.required]),
    });
  }

  public onContinue(): void {
    this._AuthNavigationService.nextScreen(RoutePaths.SetupGoogle);
  }
}
