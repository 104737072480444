import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-proposal-slidein',
  templateUrl: './proposal-slidein.component.html',
  styleUrls: ['./proposal-slidein.component.scss'],
})
export class ProposalSlideinComponent extends ModalContentComponent<
  void,
  undefined | {isDirty?: boolean}
> {
  public constructor(public control: ModalControl<void, undefined | {isDirty?: boolean}>) {
    super();
  }
}
