<div class="new-group-wrapper">
  <h3 class="b2b-kdl-font-normal b2b-kdl-color-primary-dark-120"> New Group Creation </h3>
  <form maiaForm>
    <maia-form-group>
      <maia-label class="label-class m-b-8">Group Name</maia-label>
      <maia-form-element class="input-column" hideOptionalIndicator>
        <input maiaInput name="group-name" placeholder="Select" class="input-size m-b-24" />
      </maia-form-element>
      <maia-divider></maia-divider>
      <maia-fluid-fluid-layout>
        <div class="radio-group-wrapper">
          <maia-label class="label-class m-b-8">Search and Add By</maia-label>
          <maia-radio-group name="searcAndAddByRadiobutton" [(ngModel)]="searchAndAddByValues">
            <div *ngFor="let searchByValue of searchByValues" class="input-size m-b-8">
              <njf-radio-button [value]="searchByValue" class="white-bg-color radio-btn-margin">{{
                searchByValue
              }}</njf-radio-button>
            </div>
          </maia-radio-group>
        </div>

        <form [formGroup]="searchForm">
          <div *ngIf="searchAndAddByValues" class="search-bar-wrapper blue-box m-l-12">
            {{ searchAndAddByValues }}
            <input
              maiaInput
              formControlName="searchBox"
              [(ngModel)]="searchBoxValue"
              [placeholder]="getSearchPlaceholder()"
              class="m-t-8 m-b-8"
            />
            <div class="m-t-8 m-b-8">
              <button
                maiaButton
                maiaButtonType="primary"
                [disabled]="searchForm.invalid"
                (click)="isSearchTrue = true"
              >
                Search</button
              >
            </div>
          </div>
        </form>
      </maia-fluid-fluid-layout>
      <maia-divider class="divider-margin"></maia-divider>
    </maia-form-group>
  </form>

  <div *ngIf="isSearchTrue === true" class="result-table-wrapper">
    <nje-modules-table (addModuleEmitter)="addModule($event)"></nje-modules-table>
  </div>

  <maia-divider *ngIf="isShowAddedModules == false && isSearchTrue == true"></maia-divider>
  <div *ngIf="isShowAddedModules" class="m-t-24">
    <nje-added-modules></nje-added-modules>
  </div>

  <div class="button-group-wrapper" class="m-t-b-24">
    <button
      maiaButton
      maiaButtonType="primary"
      [disabled]="isShowAddedModules == false"
      class="m-r-16"
    >
      {{ isShowAddedModules ? 'Create Group' : 'Continue' }}
    </button>
    <button maiaButton maiaButtonType="secondary"> Cancel </button>
  </div>
</div>
