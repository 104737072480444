import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DateFormatterService} from '@b2b-frontend/core';
import {Date as AtlasDate, Text} from '@atlas/businesstypes';
import {NewsEvents, Time} from '../../contracts/NewsEvents';
import {sameDates} from './date.validator';

const dateFormatterService = new DateFormatterService();

export function eventStartTimeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startTime = control.value as Text;
    const parentFormvalues: NewsEvents = control.parent?.value as NewsEvents;
    const eventStartDate = parentFormvalues?.eventStartDate as unknown as AtlasDate;
    if (startTime instanceof Text && sameDates(eventStartDate)) {
      return timeValidator(startTime, dateFormatterService.getHr(), dateFormatterService.getMin());
    }
    return null;
  };
}

export function eventEndTimeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const endTime = control.value as Text;
    const parentFormvalues: NewsEvents = control.parent?.value as NewsEvents;
    const startTime: Text = parentFormvalues?.eventStartTime as unknown as Text;
    const eventStartDate: AtlasDate = parentFormvalues?.eventStartDate as unknown as AtlasDate;
    const eventEndDate: AtlasDate = parentFormvalues?.eventEndDate as unknown as AtlasDate;
    if (
      endTime instanceof Text &&
      startTime instanceof Text &&
      sameDates(eventStartDate, eventEndDate)
    ) {
      const startHour = getHourMinute(startTime, Time.Hour);
      const startMinute = getHourMinute(startTime, Time.Minute);
      if (startHour !== null && startMinute !== null) {
        return timeValidator(endTime, startHour, startMinute);
      }
    }
    return null;
  };
}

export function publishTimeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const timeOfPublishing = control.value as Text;
    const parentFormvalues: NewsEvents = control.parent?.value as NewsEvents;
    const dateOfPublishing = parentFormvalues?.dateOfPublishing as unknown as AtlasDate;
    if (
      dateOfPublishing instanceof AtlasDate &&
      timeOfPublishing instanceof Text &&
      sameDates(dateOfPublishing)
    ) {
      return timeValidator(
        timeOfPublishing,
        dateFormatterService.getHr(),
        dateFormatterService.getMin(),
      );
    }
    return null;
  };
}

function timeValidator(
  time: Text,
  startHour: number = 0,
  startMinute: number = 0,
): ValidationErrors | null {
  const hour = getHourMinute(time, Time.Hour);
  const minute = getHourMinute(time, Time.Minute);
  if (hour !== null && minute !== null) {
    if (hour < startHour) {
      return {invalidTime: true};
    } else if (hour === startHour && minute <= startMinute) {
      return {invalidTime: true};
    }
  }
  return null;
}

export function getHourMinute(time: Text, timeParams: Time): null | number {
  if (time instanceof Text) {
    const val = time.asString();
    switch (timeParams) {
      case Time.Hour:
        return Number(val.slice(0, 2));
      case Time.Minute:
        return Number(val.slice(2, 4));
    }
  }
  return null;
}
