<div class="search-box-wrapper">
  <maia-radio-group name="searchCriteria" [(ngModel)]="searchBy" class="radio-options-wrapper">
    <maia-label class="b2b-kdl-font-normal title-dark-color search-by-txt m-b-8 m-t-24">
      Search By
    </maia-label>
    <div *ngFor="let searchByValue of searchByValues" class="m-b-8">
      <njf-radio-button [value]="searchByValue" (click)="setSearchBy(searchByValue)">
        {{ searchByValue?.displayValue }}
      </njf-radio-button>
    </div>
  </maia-radio-group>

  <form [formGroup]="searchForm" class="search-form-wrapper">
    <div *ngIf="searchBy" class="blue-box m-t-24">
      <maia-label class="b2b-kdl-font-small"> {{ searchBy?.displayValue }} </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column">
        <input
          maiaInput
          formControlName="searchBox"
          [placeholder]="getSearchPlaceholder()"
          class="m-t-8 m-b-8"
        />
      </maia-form-element>
      <div class="m-t-8 m-b-8">
        <button
          maiaButton
          maiaButtonType="primary"
          id="searchButton"
          [disabled]="searchForm.invalid"
          (click)="search()"
        >
          Search
        </button>
      </div>
    </div>
  </form>
</div>
