export enum MtplRoutePaths {
  MTPL = 'mtpl',
  MTPL_PICK_TERMS = 'pick-terms',
  MTPL_VEHICLE_DETAILS = 'vehicle-details',
  MTPL_OWNER_INSURERDETAILS = 'owner-insurerdetails',
  MTPL_POLICY_DETAILS = 'policy-details',
  MTPL_INSTALMENTS = 'instalments',
  MTPL_ADDITIONALCOVERS_RISKS = 'additionalcovers-risks',
  MTPL_PROPOSAL = 'proposal',
}
