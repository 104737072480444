import {Component} from '@angular/core';
import {Library, Product, ProductPortfolio} from '../../../contracts/library.interface';

@Component({
  selector: 'nje-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
})
export class LibraryComponent {
  public selectedLibrary: Library;
  public selectedProductPortfolioTile: ProductPortfolio;
  public selectedProduct: Product;

  public OnSelectedLibraryList(data: Library): void {
    this.selectedLibrary = data;
  }

  public OnSelectedProductPortfolio(data: ProductPortfolio): void {
    this.selectedProductPortfolioTile = data;
  }

  public OnSelectedProduct(data: Product): void {
    this.selectedProduct = data;
  }
}
