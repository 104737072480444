import {NgModule} from '@angular/core';
import {RouterModule, Routes, Route} from '@angular/router';
import {TravelRoutePaths} from '@b2b-frontend/core';

import {TravelAddonsComponent} from './screens/travel-addons/travel-addons.component';
import {TravelCoversComponent} from './screens/travel-covers/travel-covers.component';
import {TravelInsuredPeopleComponent} from './screens/travel-insured-people/travel-insured-people.component';
import {TravelInsuringPartyComponent} from './screens/travel-insuring-party/travel-insuring-party.component';
import {TravelProposalComponent} from './screens/travel-proposal/travel-proposal.component';
import {TravelTripDetailsComponent} from './screens/travel-trip-details/travel-trip-details.component';
import {StepResolverService} from './travel-core/services/step.resolver';
import {TravelInsuranceComponent} from './travel-insurance.component';
import {TravelPolicySuccessComponent} from './screens/travel-policy-success/travel-policy-success.component';
import {TravelPolicyErrorComponent} from './screens/travel-policy-error/travel-policy-error.component';
import {TravelRenewPolicyComponent} from './screens/travel-renew-policy/travel-renew-policy.component';
// import {TravelInsuranceComponent} from './travel-insurance.component';

/**
 * All the routes of the flow should be defined here, we consume this then inside the exported
 * constant `ROUTES` which injects the flow as a lazy loaded route.
 *
 */

const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'travel',
    pathMatch: 'full',
  },
  {
    path: 'travel',
    component: TravelInsuranceComponent,

    children: [
      {
        path: '',
        redirectTo: TravelRoutePaths.TI_RENEW_POLICY,
        pathMatch: 'full',
      },
      {
        path: TravelRoutePaths.TI_TRAVEL_SUCCESS,
        component: TravelPolicySuccessComponent,
        data: {
          isStepperVisible: false,
        },
      },
      {
        path: TravelRoutePaths.TI_TRAVEL_ERROR,
        component: TravelPolicyErrorComponent,
        data: {
          isStepperVisible: false,
        },
      },
      {
        path: TravelRoutePaths.TI_RENEW_POLICY,
        component: TravelRenewPolicyComponent,
        data: {
          isStepperVisible: false,
          stepId: TravelRoutePaths.TI_RENEW_POLICY,
          maiaFlowProgressId: TravelRoutePaths.TI_RENEW_POLICY,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: TravelRoutePaths.TI_TRIP_DETAILS,
        component: TravelTripDetailsComponent,
        data: {
          stepId: TravelRoutePaths.TI_TRIP_DETAILS,
          maiaFlowProgressId: TravelRoutePaths.TI_TRIP_DETAILS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: TravelRoutePaths.TI_TRAVEL_COVERS,
        component: TravelCoversComponent,
        data: {
          stepId: TravelRoutePaths.TI_TRAVEL_COVERS,
          maiaFlowProgressId: TravelRoutePaths.TI_TRAVEL_COVERS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: TravelRoutePaths.TI_ADD_ONS,
        component: TravelAddonsComponent,
        data: {
          stepId: TravelRoutePaths.TI_ADD_ONS,
          maiaFlowProgressId: TravelRoutePaths.TI_ADD_ONS,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: TravelRoutePaths.TI_INSURING_PARTY,
        component: TravelInsuringPartyComponent,
        data: {
          stepId: TravelRoutePaths.TI_INSURING_PARTY,
          maiaFlowProgressId: TravelRoutePaths.TI_INSURING_PARTY,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: TravelRoutePaths.TI_INSURED_PEOPLE,
        component: TravelInsuredPeopleComponent,
        data: {
          stepId: TravelRoutePaths.TI_INSURED_PEOPLE,
          maiaFlowProgressId: TravelRoutePaths.TI_INSURED_PEOPLE,
        },
        resolve: {
          step: StepResolverService,
        },
      },
      {
        path: TravelRoutePaths.TI_TRAVEL_PROPOSAL,
        component: TravelProposalComponent,
        data: {
          stepId: TravelRoutePaths.TI_TRAVEL_PROPOSAL,
          maiaFlowProgressId: TravelRoutePaths.TI_TRAVEL_PROPOSAL,
        },
        resolve: {
          step: StepResolverService,
        },
      },
    ],
  },
];

/**
 * The purpose of this private module is to:
 * - Define the routes once in the package wich will be nested inside an application;
 * - Declaire intern components or consume intern private modules of the package;
 * - Import the dependencies they dashboard requires;
 *
 * The puropose of this class is to be consumed inside the constant `ROUTE` which lazy
 * loads this private module.
 *
 * The class needs to be exported so it can be lazy loaded in the exported constant ROUTE.
 *
 * We don't export the first screen component because it shouldn't be consumed outside this flow.
 *
 * We import the second screen through it's private module for preventing this module to
 * become to bloated. This doesn't mean that the dependencies are lazy loaded.
 *
 */
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class TravelInsuranceRoutingModule {}

/**
 * The route we export is anempty path with the private module lazy loaded.
 */
export const TRAVEL_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./travel-insurance.routing.module').then(
      module => module.TravelInsuranceRoutingModule,
    ),
};
