<div *ngIf="showPropertiesIncluded">
  <div *ngIf="0 < propertyDetailsList.length">
    <h4>Property / Household Property</h4>
    <ul
      maiaTextList
      [iconType]="'checkmark'"
      *ngFor="let propertyDetails of propertyDetailsList; let i = index"
    >
      <li maiaTextListItem> {{ propertyDetails.propertyType }} </li>
    </ul>
  </div>

  <div *ngIf="0 < featureDetailsList.length">
    <h4>Features</h4>
    <ul
      maiaTextList
      [iconType]="'checkmark'"
      *ngFor="let featureDetails of featureDetailsList; let i = index"
    >
      <li maiaTextListItem> {{ featureDetails.propertyType }} </li>
    </ul>
  </div>
</div>

<div *ngIf="!showPropertiesIncluded">
  <form maiaForm [formGroup]="propertySlideinForm.formGroup" class="insurance-party-details">
    <maia-fluid-fluid-layout alignContentTop
      ><div class="fluid">
        <maia-form-group>
          <maia-form-element
            *ngIf="!isFeature"
            [hideOptionalIndicator]="true"
            class="multi-input-row"
            multi
          >
            <maia-label class="b2b-kdl-font-small title-dark-color"> Property Type </maia-label>
            <maia-form-subelement
              [class.p-maia-form-element--validation-error]="getPropertyTypeFieldErrors()"
            >
              <div [class.p-maia-form-element--show-validation]="getPropertyTypeFieldErrors()">
                <maia-input-select
                  name="propertyType"
                  formControlName="propertyType"
                  (ngModelChange)="selectPropertyType()"
                >
                  <input
                    maiaInput
                    placeholder="Select"
                    [value]="propertySlideinForm.controls.propertyType.value"
                  />
                  <maia-option
                    *ngFor="let propertyType of apiPropertyTypeList"
                    [value]="propertyType.objectName"
                    >{{ propertyType.objectName }}</maia-option
                  >
                </maia-input-select>
                <div class="validation-container" *ngIf="getPropertyTypeFieldErrors()">
                  <maia-validation
                    type="error"
                    *ngIf="propertySlideinForm.controls?.propertyType?.errors?.required"
                  >
                    Property Type required
                  </maia-validation>
                </div>
              </div>
            </maia-form-subelement>
          </maia-form-element>

          <maia-form-element
            *ngIf="isFeature"
            [hideOptionalIndicator]="true"
            class="multi-input-row"
            multi
          >
            <maia-label class="b2b-kdl-font-small title-dark-color">Property Type</maia-label>
            <maia-form-subelement
              [class.p-maia-form-element--validation-error]="getPropertyTypeFieldErrors()"
            >
              <div [class.p-maia-form-element--show-validation]="getPropertyTypeFieldErrors()">
                <maia-input-select
                  name="propertyType"
                  formControlName="propertyType"
                  (ngModelChange)="selectPropertyType()"
                >
                  <input
                    maiaInput
                    placeholder="Select"
                    [value]="propertySlideinForm.controls.propertyType.value"
                  />
                  <maia-option
                    *ngFor="let propertyType of apiFeatureList"
                    [value]="propertyType.description"
                    >{{ propertyType.description }}</maia-option
                  >
                </maia-input-select>
                <div
                  class="validation-container"
                  *ngIf="propertySlideinForm?.hasToShowErrors('propertyType')"
                >
                  <maia-validation
                    type="error"
                    *ngIf="propertySlideinForm?.hasError('propertyType', 'required')"
                  >
                    Property Type required
                  </maia-validation>
                </div>
              </div>
            </maia-form-subelement>
          </maia-form-element>

          <maia-form-element
            *ngIf="showSpecifications"
            [hideOptionalIndicator]="true"
            [options]="{labelSize: 'larger'}"
            class="multi-input-row m-t-8"
            multi
          >
            <maia-label class="b2b-kdl-font-small title-dark-color"> Specifications </maia-label>
            <maia-form-subelement
              [class.p-maia-form-element--validation-error]="getSpecificationsFieldErrors()"
            >
              <div [class.p-maia-form-element--show-validation]="getSpecificationsFieldErrors()">
                <maia-text-area-limit maxLength="200">
                  <textarea
                    id="text-area-limit"
                    maiaTextArea
                    formControlName="specifications"
                    atlasText
                    name="specifications"
                    atlasMaxLength="20"
                    placeholder="Specifications"
                    (nbRemainingCharacters)="updateRemainingCharactersLimit($event)"
                  ></textarea>
                </maia-text-area-limit>
                <div class="validation-container" *ngIf="getSpecificationsFieldErrors()">
                  <maia-validation
                    type="error"
                    *ngIf="propertySlideinForm.controls?.specifications?.errors?.required"
                  >
                    Specifications required
                  </maia-validation>
                </div>
              </div>
            </maia-form-subelement>
          </maia-form-element>

          <maia-form-element *ngIf="showImprovements" class="multi-input-row m-t-8" multi>
            <maia-label class="b2b-kdl-font-small title-dark-color">
              Select your improvements
            </maia-label>
            <maia-fluid-fluid-layout class="m-t-8">
              <div>
                <maia-tile
                  class="improvements-checkbox-tile"
                  *ngFor="let improvement of improvementsList; let i = index"
                >
                  <maia-tile-items>
                    <maia-tile-item>
                      <maia-checkbox
                        formControlName="improvements"
                        (onValueChange)="changeImprovementsSelection(i)"
                      >
                        <label>{{ improvement.label }}</label>
                      </maia-checkbox>
                    </maia-tile-item>
                  </maia-tile-items>
                </maia-tile>
                <div class="validation-container" *ngIf="getImprovementsFieldErrors()">
                  <maia-validation
                    type="error"
                    *ngIf="propertySlideinForm.controls?.improvements?.errors?.required"
                  >
                    Improvements required
                  </maia-validation>
                </div>
              </div>
            </maia-fluid-fluid-layout>
          </maia-form-element>

          <maia-form-element
            *ngIf="showAreaOfProperty"
            [hideOptionalIndicator]="true"
            class="multi-input-row"
            multi
          >
            <maia-label class="b2b-kdl-font-small title-dark-color"> Area of Property </maia-label>
            <maia-form-subelement
              [class.p-maia-form-element--validation-error]="getAreaOfPropertyFieldErrors()"
            >
              <div [class.p-maia-form-element--show-validation]="getAreaOfPropertyFieldErrors()">
                <maia-input-group>
                  <input
                    maiaInputNumber
                    atlasMaxLengthFractionPart="2"
                    atlasDecimal
                    formControlName="areaOfProperty"
                    name="areaOfProperty"
                    placeholder="Area of Property"
                  />
                  <maia-input-group-suffix>Sq. M</maia-input-group-suffix>
                </maia-input-group>

                <div *ngIf="getAreaOfPropertyFieldErrors()">
                  <maia-validation
                    id="validation-invalid-characters"
                    *ngIf="propertySlideinForm.controls?.areaOfProperty?.errors?.required"
                  >
                    Area of Property required
                  </maia-validation>
                  <maia-validation
                    class="validation-container"
                    type="error"
                    *ngIf="
                      propertySlideinForm.controls?.areaOfProperty?.errors?.atlasFractionPartTooLong
                    "
                  >
                    Only 2 decimal places allowed.
                  </maia-validation>
                  <maia-validation
                    id="validation-invalid-characters"
                    *ngIf="propertySlideinForm.controls?.areaOfProperty?.errors?.atlasInvalidFormat"
                  >
                    Invalid characters
                  </maia-validation>
                  <maia-validation
                    id="validation-invalid-characters"
                    *ngIf="
                      propertySlideinForm.controls?.areaOfProperty?.errors
                        ?.atlasIncompleteFractionPart
                    "
                  >
                    Incomplete Fraction characters
                  </maia-validation>
                  <maia-validation
                    type="error"
                    *ngIf="propertySlideinForm.controls?.areaOfProperty?.errors?.min"
                  >
                    Area of Property cannot be less than 1 Sq. M.
                  </maia-validation>
                </div>
              </div>
            </maia-form-subelement>
          </maia-form-element>

          <maia-form-element
            *ngIf="showYearOfConstruction"
            [hideOptionalIndicator]="true"
            class="multi-input-row"
            multi
          >
            <maia-label class="b2b-kdl-font-small title-dark-color">
              Year of Construction
            </maia-label>
            <maia-form-subelement
              [class.p-maia-form-element--validation-error]="getYearOfConstructionFieldErrors()"
            >
              <div
                [class.p-maia-form-element--show-validation]="getYearOfConstructionFieldErrors()"
              >
                <input
                  maiaInputNumber
                  placeholder="Year of Construction"
                  formControlName="yearOfConstruction"
                />
                <div class="validation-container" *ngIf="getYearOfConstructionFieldErrors()">
                  <maia-validation
                    id="validation-invalid-characters"
                    *ngIf="propertySlideinForm.controls?.yearOfConstruction?.errors?.required"
                  >
                    Year of Construction required
                  </maia-validation>
                  <maia-validation
                    id="validation-invalid-characters"
                    *ngIf="propertySlideinForm.controls?.yearOfConstruction?.errors?.pattern"
                  >
                    Invalid characters
                  </maia-validation>
                  <maia-validation
                    type="error"
                    *ngIf="propertySlideinForm.controls?.yearOfConstruction?.errors?.min"
                  >
                    Year of Construction cannot be less than 1900 AD
                  </maia-validation>
                </div>
              </div></maia-form-subelement
            >
          </maia-form-element>

          <maia-form-element [hideOptionalIndicator]="true" class="multi-input-row" multi>
            <maia-label class="b2b-kdl-font-small title-dark-color">
              Is this Property Co-own by the Bank?
            </maia-label>
            <maia-form-subelement>
              <maia-radio-group
                formControlName="isPropertyCoOwnedByBank"
                horizontal
                (ngModelChange)="selectPropertyCoownershipType()"
              >
                <njf-radio-button
                  class="radio-btn-margin"
                  *ngFor="let propertyOwnership of propertyOwnershipType"
                  [value]="propertyOwnership.value"
                  >{{ propertyOwnership.label }}</njf-radio-button
                >
              </maia-radio-group>
            </maia-form-subelement>
          </maia-form-element>

          <maia-form-element
            *ngIf="showBank"
            [hideOptionalIndicator]="true"
            class="multi-input-row"
            multi
          >
            <maia-label class="b2b-kdl-font-small title-dark-color"> Bank </maia-label>
            <maia-form-subelement
              [class.p-maia-form-element--validation-error]="getBankFieldErrors()"
            >
              <div [class.p-maia-form-element--show-validation]="getBankFieldErrors()">
                <maia-input-select name="bank" formControlName="bank">
                  <input
                    maiaInput
                    placeholder="Select"
                    [value]="propertySlideinForm.controls.bank.value"
                  />
                  <maia-option *ngFor="let bank of apiBankList" [value]="bank.bankName">{{
                    bank.bankName
                  }}</maia-option>
                </maia-input-select>
                <div class="validation-container" *ngIf="getBankFieldErrors()">
                  <maia-validation
                    type="error"
                    *ngIf="propertySlideinForm.controls?.yearOfConstruction?.errors?.required"
                  >
                    Bank required
                  </maia-validation>
                </div>
              </div>
            </maia-form-subelement>
          </maia-form-element>

          <maia-form-element
            *ngIf="showBank"
            [hideOptionalIndicator]="true"
            class="multi-input-row amount-owned-to-the-bank"
            multi
          >
            <maia-label class="b2b-kdl-font-small title-dark-color">
              Enter amount owed to the Bank
            </maia-label>
            <maia-form-subelement
              [class.p-maia-form-element--validation-error]="getAmountOwedFieldErrors()"
              class="amount-owned"
            >
              <div [class.p-maia-form-element--show-validation]="getAmountOwedFieldErrors()">
                <input
                  maiaInputNumber
                  atlasMaxLengthFractionPart="2"
                  atlasDecimal
                  placeholder="Amount"
                  formControlName="amountOwedToTheBank"
                />
                <div *ngIf="getAmountOwedFieldErrors()">
                  <maia-validation
                    id="validation-invalid-characters"
                    *ngIf="propertySlideinForm.controls?.amountOwedToTheBank?.errors?.required"
                  >
                    Amount required.
                  </maia-validation>
                  <maia-validation
                    class="validation-container"
                    type="error"
                    *ngIf="
                      propertySlideinForm.controls?.amountOwedToTheBank?.errors
                        ?.atlasFractionPartTooLong
                    "
                  >
                    Only 2 decimal places allowed.
                  </maia-validation>
                  <maia-validation
                    id="validation-invalid-characters"
                    *ngIf="
                      propertySlideinForm.controls?.amountOwedToTheBank?.errors?.atlasInvalidFormat
                    "
                  >
                    Invalid characters
                  </maia-validation>
                  <maia-validation
                    id="validation-invalid-characters"
                    *ngIf="
                      propertySlideinForm.controls?.amountOwedToTheBank?.errors
                        ?.atlasIncompleteFractionPart
                    "
                  >
                    Incomplete Fraction characters
                  </maia-validation>
                </div>
              </div>
            </maia-form-subelement>
            <maia-form-subelement>
              <maia-input-select name="currency" formControlName="currency">
                <input
                  maiaInput
                  placeholder="Select"
                  [value]="propertySlideinForm.controls?.currency?.value"
                />
                <maia-option
                  *ngFor="let currency of apiCurrencyList"
                  [value]="currency.description"
                  >{{ currency.description }}</maia-option
                >
              </maia-input-select>
            </maia-form-subelement>
          </maia-form-element>
        </maia-form-group>
      </div>
    </maia-fluid-fluid-layout>
  </form>

  <maia-button-group class="slider-btn-group">
    <button
      *ngIf="isNew"
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      (click)="addOrUpdatePropertyFeature()"
      [disabled]="isPropertyFeatureFormValid()"
      >Add</button
    >
    <button
      *ngIf="!isNew"
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      (click)="addOrUpdatePropertyFeature()"
      [disabled]="isPropertyFeatureFormValid()"
      >Save</button
    >
    <button class="m-t-b-24" maiaButton maiaButtonType="secondary" (click)="cancel()"
      >Cancel</button
    >
  </maia-button-group>
</div>
