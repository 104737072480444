import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {BaseDateValidators} from '@atlas/businesstypes';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    let result: ValidationErrors | null = null;

    if (value !== null && value !== undefined) {
      [
        BaseDateValidators.invalidCharacters(value),
        BaseDateValidators.invalidDay(value),
        BaseDateValidators.invalidFormat(value),
        BaseDateValidators.invalidLength(value),
        BaseDateValidators.invalidMonth(value),
        BaseDateValidators.invalidYear(value),
      ]
        .filter(res => res !== null && res !== undefined)
        .forEach(r => {
          if (result === null) {
            result = {};
          }

          result = {...result, ...r};
        });

      if (result !== null) {
        (result as any).invalid = true;
      }
    }

    return result;
  };
}
