import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {PropertyRoutePaths} from '@b2b-frontend/core';

@Injectable({
  providedIn: 'root',
})
export class PropertyNavigationService {
  constructor(private readonly _router: Router) {}

  public nextScreen(path: PropertyRoutePaths): void {
    // eslint-disable-next-line
    this._router.navigate([`dashboard/policies/${PropertyRoutePaths.PROPERTY}/`, path]);
  }
}
