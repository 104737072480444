/* eslint-disable @typescript-eslint/unbound-method */
import {Component} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';
import {FormGroup, FormBuilder, Validators, FormArray, FormControl} from '@angular/forms';
import {EmailAddress} from '@atlas/businesstypes';
import {ExtraTextControlValidators} from '@atlas-angular/businesstypes';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-travel-proposal-email',
  templateUrl: './travel-proposal-email.component.html',
  styleUrls: ['./travel-proposal-email.component.scss'],
})
export class TravelProposalEmailComponent extends ModalContentComponent<void> {
  public mailForm: FormGroup;
  public email: string;
  public clientEmail: EmailAddress;
  public showEmailContainer: boolean = true;
  public readonly _logger: Logger;

  public constructor(
    public control: ModalControl<void>,
    public loggerFactory: LoggerFactory,
    private readonly formBuilder: FormBuilder,
  ) {
    super();
    this._logger = loggerFactory.createLogger('TravelProposalEmailComponent');

    this.mailForm = this.formBuilder.group({
      email: new FormControl(undefined, [
        Validators.required,
        ExtraTextControlValidators.maxLength(50),
      ]),
      emailsFormArray: this.formBuilder.array([]),
    });
  }

  public get emailsFormArray(): FormArray {
    return this.mailForm.get('emailsFormArray') as FormArray;
  }

  public newEmails(): FormGroup {
    return this.formBuilder.group({
      mails: new FormControl(undefined, [
        Validators.required,
        ExtraTextControlValidators.maxLength(50),
      ]),
    });
  }

  //addEmails method
  public addEmails() {
    return this.emailsFormArray.push(this.newEmails());
  }

  //removeEmails method
  public removeEmail(i: number) {
    return this.emailsFormArray.removeAt(i);
  }

  //opensuccessmessage container
  public openSuccessContainer() {
    return (this.showEmailContainer = false);
  }
}
