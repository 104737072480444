<nje-vehicle-user-input
  (showFormEmitter)="showFormFunction($event)"
  (vehicleIdNumber)="searchVehicleByVinNo($event)"
  (vehicleRegNumber)="searchVehicleByRegNo($event)"
  [screen]="screen"
  [isData]="isData"
></nje-vehicle-user-input>
<form maiaForm [formGroup]="vehicleForm.formGroup">
  <div maiaForm *ngIf="showForm || (step.inEdit | async)">
    <maia-divider class="m-t-24"></maia-divider>
    <label
      class="b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-casco-price-vehicledetails-label-additionalvehicle"
    >
      Additional Vehicle Data
    </label>
    <maia-fluid-fluid-layout>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child m-r-16"
        label="Type of Vehicle"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-vehicletype"
      >
        <maia-input-select name="typeOfVehicle" formControlName="typeOfVehicle">
          <input
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            maiaInput
            placeholder="Select"
            [value]="vehicleForm.controls['typeOfVehicle'].value"
          />
          <maia-option *ngFor="let type of mockTypeOfVehicle" [value]="type.name">{{
            type.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child m-r-16"
        label="Subtype of Vehicle"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-vehiclesubtype"
      >
        <maia-input-select name="subTypeOfVehicle" formControlName="subTypeOfVehicle">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['subTypeOfVehicle'].value"
          />
          <maia-option *ngFor="let subType of mockVehicleSubType" [value]="subType.name">{{
            subType.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child"
        label="Additional Specification"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-addspec"
      >
        <maia-input-select name="additionalSpec" formControlName="additionalSpec">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['additionalSpec'].value"
          />
          <maia-option
            *ngFor="let additionalSpec of mockAdditionalSpec"
            [value]="additionalSpec.name"
            >{{ additionalSpec.name }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child m-r-16"
        label="Make"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-make"
      >
        <maia-input-select name="make" formControlName="make">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['make'].value"
          />
          <maia-option *ngFor="let make of mockMakeList" [value]="make.name">{{
            make.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child m-r-16"
        label="Model"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-model"
      >
        <maia-input-select name="model" formControlName="model">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['model'].value"
          />
          <maia-option *ngFor="let model of mockModelList" [value]="model.name">{{
            model.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element label="Modification" class="multi-input-row child" hideOptionalIndicator>
        <maia-input-group>
          <input
            maiaInput
            formControlName="modification"
            name="modification"
            placeholder="Enter Modification Details"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-modification"
          />
        </maia-input-group>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-divider class="m-t-34"></maia-divider>
    <maia-fluid-fluid-layout>
      <maia-form-element
        label="Date of First Registration"
        class="multi-input-row child m-r-16"
        hideOptionalIndicator
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-regdate"
      >
        <maia-input-date
          formControlName="dateOfFirstRegistration"
          [minimum]="minDate"
          [maximum]="maxDate"
          name="dateOfFirstRegistration"
        ></maia-input-date>
        <maia-validation
          *ngIf="
            vehicleForm.hasToShowErrors('dateOfFirstRegistration') &&
            vehicleForm.hasError('dateOfFirstRegistration', 'required')
          "
          i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-req"
        >
          Date required
        </maia-validation>
        <maia-validation
          *ngIf="vehicleForm.hasError('dateOfFirstRegistration', 'invalid')"
          i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-invalid"
        >
          Invalid date
        </maia-validation>
        <maia-validation
          *ngIf="vehicleForm.hasError('dateOfFirstRegistration', 'atlasBefore')"
          i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-before"
        >
          Date should be before {{ maxDate | atlasDate }}
        </maia-validation>
        <maia-validation
          *ngIf="vehicleForm.hasError('dateOfFirstRegistration', 'atlasAfter')"
          i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-valid-after"
        >
          Date should be after {{ minDate | atlasDate }}.
        </maia-validation>
      </maia-form-element>
      <maia-form-element
        label="Production Year"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-prodyear"
        class="multi-input-row child m-r-16"
        hideOptionalIndicator
      >
        <maia-input-year
          [minimum]="minYear"
          [maximum]="maxYear"
          name="productionYear"
          formControlName="productionYear"
        >
        </maia-input-year>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child"
        label="Registration Type"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-regtype"
      >
        <maia-input-select name="registrationType" formControlName="registrationType">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['registrationType'].value"
          />
          <maia-option
            *ngFor="let registrationType of mockRegisterList"
            [value]="registrationType.name"
            >{{ registrationType.name }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <maia-form-element
        *ngIf="!showHeading"
        hideOptionalIndicator
        class="multi-input-row child m-r-16"
        label="Production Type"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-prodtype"
      >
        <maia-input-select name="productionType" formControlName="productionType">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['productionType'].value"
          />
          <maia-option
            *ngFor="let productionType of mockProductionType"
            [value]="productionType.name"
            >{{ productionType.name }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
      <div></div><div></div>
    </maia-fluid-fluid-layout>
    <maia-divider class="m-t-34"></maia-divider>
    <div class="container">
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child-1"
        label="Vehicle Usage"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-vehicleusage"
      >
        <maia-input-select name="vehicleUsage" formControlName="vehicleUsage">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['vehicleUsage'].value"
          />
          <maia-option *ngFor="let usage of mockUsageList" [value]="usage.name">{{
            usage.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child-1"
        label="Fuel Type"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-fueltype"
      >
        <maia-input-select name="fuelType" formControlName="fuelType">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['fuelType'].value"
          />
          <maia-option *ngFor="let fuelType of mockFuelList" [value]="fuelType.name">{{
            fuelType.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child-1"
        label="Gear box"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-gearbox"
      >
        <maia-input-select name="gearBox" formControlName="gearBox">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['gearBox'].value"
          />
          <maia-option *ngFor="let gearBox of mockGearList" [value]="gearBox.name">{{
            gearBox.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child-1"
        label="Engine Power"
        multi
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-enginepower"
      >
        <maia-form-subelement>
          <maia-input-group>
            <input
              maiaInputNumber
              formControlName="enginePowerhp"
              name="enginePowerhp"
              placeholder="00"
            />
            <maia-input-group-suffix i18n="@@nje-b2b-frontend-casco-price-vehicledetails-suffix-hp"
              >Hp</maia-input-group-suffix
            >
          </maia-input-group>
        </maia-form-subelement>
        <maia-form-subelement>
          <maia-input-group>
            <input
              maiaInputNumber
              formControlName="enginePowerkw"
              name="enginePowerkw"
              placeholder="00"
            />
            <maia-input-group-suffix i18n="@@nje-b2b-frontend-casco-price-vehicledetails-suffix-kw"
              >Kw</maia-input-group-suffix
            >
          </maia-input-group>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        label="CCM"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-ccm"
        class="multi-input-row child-1"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="ccm"
            name="ccm"
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
          />
        </maia-input-group>
      </maia-form-element>
      <maia-form-element
        label="Seats num"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-seatsnum"
        class="multi-input-row child-1"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="seatsNum"
            name="seatsNum"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            placeholder="Select"
          />
        </maia-input-group>
      </maia-form-element>
      <maia-form-element
        label="Tonnage"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-tonnage"
        class="multi-input-row child-1"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="tonnage"
            name="tonnage"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            placeholder="Select"
          />
          <maia-input-group-suffix
            i18n="@@nje-b2b-frontend-casco-price-vehicledetails-suffix-tonnes"
            >Tonnes</maia-input-group-suffix
          >
        </maia-input-group>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child-1"
        label="Color"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-color"
      >
        <maia-input-select name="color" formControlName="color">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['color'].value"
          />
          <maia-option *ngFor="let color of mockColorList" [value]="color.name">{{
            color.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        label="Door num"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-doornum"
        class="multi-input-row child-1"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="doorNum"
            name="doorNum"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            placeholder="Select"
          />
        </maia-input-group>
      </maia-form-element>

      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child-1"
        label="Carbody Type"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-carbodytype"
      >
        <maia-input-select name="carBodyType" formControlName="carBodyType">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['carBodyType'].value"
          />
          <maia-option *ngFor="let carBodyType of mockCarBodyList" [value]="carBodyType.name">{{
            carBodyType.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child-1"
        label="Drive type"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-drivetype"
      >
        <maia-input-select name="driveType" formControlName="driveType">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['driveType'].value"
          />
          <maia-option *ngFor="let driveType of mockDriveTypeList" [value]="driveType.name">{{
            driveType.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        class="multi-input-row child-1"
        label="Engine Type"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-enginetype"
      >
        <maia-input-select name="engineType" formControlName="engineType">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            [value]="vehicleForm.controls['engineType'].value"
          />
          <maia-option *ngFor="let engineType of mockEngineList" [value]="engineType.name">{{
            engineType.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>

      <maia-form-element
        label="Mileage per year"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-mileage"
        class="multi-input-row child-1"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="mileagePerYear"
            name="mileagePerYear"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            placeholder="Select"
          />
        </maia-input-group>
      </maia-form-element>
    </div>
    <maia-fluid-fluid-layout>
      <maia-form-element
        class="multi-input-row m-r-16"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-steering"
        label="Steering Position"
        hideOptionalIndicator
      >
        <maia-radio-group
          name="steeringPositionRadio"
          formControlName="steeringPositionRadio"
          class="m-t--12"
          horizontal
        >
          <njf-radio-button
            value="left"
            i18n="@@nje-b2b-frontend-casco-price-vehicledetails-radio-left"
            >Left</njf-radio-button
          >
          <njf-radio-button
            value="right"
            i18n="@@nje-b2b-frontend-casco-price-vehicledetails-radio-right"
            >Right</njf-radio-button
          >
        </maia-radio-group>
      </maia-form-element>
      <maia-form-element
        class="multi-input-row m-r-16"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-armored"
        label="Armored Vehicles"
        hideOptionalIndicator
      >
        <maia-radio-group
          name="armoredVehicleRadio"
          formControlName="armoredVehicleRadio"
          class="m-t--12"
          horizontal
        >
          <njf-radio-button
            value="yes"
            i18n="@@nje-b2b-frontend-casco-price-vehicledetails-radio-yes"
            >Yes</njf-radio-button
          >
          <njf-radio-button value="no" i18n="@@nje-b2b-frontend-casco-price-vehicledetails-radio-no"
            >No</njf-radio-button
          >
        </maia-radio-group>
      </maia-form-element>
      <maia-form-element
        class="multi-input-row"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-willreg"
        label="Will the vehicle be Registered in 30 days?"
        hideOptionalIndicator
      >
        <maia-radio-group
          name="vehicleRegisterRadio"
          formControlName="vehicleRegisterRadio"
          class="m-t--12"
          horizontal
        >
          <njf-radio-button
            value="yes"
            i18n="@@nje-b2b-frontend-casco-price-vehicledetails-radio-yes"
            >Yes</njf-radio-button
          >
          <njf-radio-button value="no" i18n="@@nje-b2b-frontend-casco-price-vehicledetails-radio-no"
            >No</njf-radio-button
          >
        </maia-radio-group>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-divider class="m-t-6 m-b-0"></maia-divider>
    <maia-fluid-fluid-layout class="m-t-b-custom">
      <maia-form-element
        label="Authorisation Number"
        i18n-label="@@nje-b2b-frontend-casco-price-vehicledetails-label-authnum"
        class="multi-input-row"
        hideOptionalIndicator
      >
        <maia-input-group>
          <input
            maiaInputNumber
            formControlName="authNumber"
            name="authNumber"
            i18n-placeholder="@@nje-b2b-frontend-casco-price-vehicledetails-phd-select"
            placeholder="Select"
          />
        </maia-input-group>
      </maia-form-element>
      <div></div><div></div>
    </maia-fluid-fluid-layout>
    <maia-divider class="m-t-24 m-b-0"></maia-divider>
    <button
      *ngIf="!(step.inEdit | async)"
      class="m-t-48 m-b-24"
      maiaButton
      maiaButtonType="primary"
      (click)="saveAndContinue()"
      [disabled]="!vehicleForm.valid"
      i18n="@@nje-b2b-frontend-casco-price-vehicledetails-btn-continue"
      >Continue</button
    >
    <div *ngIf="step.inEdit | async">
      <button
        class="m-r-24 m-t-b-24"
        maiaButton
        maiaButtonType="primary"
        [disabled]="!vehicleForm.valid"
        (click)="saveEdit()"
        i18n="@@nje-b2b-frontend-casco-price-vehicledetails-btn-savechanges"
        >Save Changes</button
      >
      <button
        maiaButton
        maiaButtonType="secondary"
        (click)="cancelEdit()"
        i18n="@@nje-b2b-frontend-casco-price-vehicledetails-btn-cancel"
        >Cancel</button
      >
    </div>
  </div>
</form>
