export enum CascoRoutePaths {
  CI = 'casco',
  CI_JOURNEY_CHOICE = 'journey-choice',
  CI_VEHICLE_AND_OWNER_DETAILS = 'vehicle-and-owner-details',
  CI_COVERS = 'covers',
  CI_PREMIUM = 'premium',
  CI_PROPOSAL = 'proposal',
  CI_PRICE_VEHICLE_DETAILS = 'vehicle-details',
  CI_PRICE_SUM_INSURED = 'sum-insured',
  CI_PRICE_OWNER_AND_INSURER_DETAILS = 'owner-and-insurer-details',
  CI_PRICE_POLICY_DETAILS = 'policy-details',
  CI_PRICE_COVERS_AND_ADDONS = 'covers-and-addons',
  CI_PRICE_PREMIUM = 'price-premium',
  CI_PRICE_PROPOSAL = 'price-proposal',
}
