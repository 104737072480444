import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Router} from '@angular/router';

export const BASE_ROUTE = new InjectionToken<string>('@b2b-frontend-flows/mtpl base route');

/**
 * This starter service encapsulates the module.
 * You don't need to be aware of the actual path for starting up the flow.
 *
 * The BASE_ROUTE is provided when injecting the flow module into the application by
 * forcing the usage of the forPath method.
 */
@Injectable()
export class MTPLStarterService {
  public constructor(
    @Inject(BASE_ROUTE) private readonly _baseRoute: string,
    private readonly _router: Router,
  ) {}

  public start(): void {
    void this._router.navigate([this._baseRoute]);
  }
}
