import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncHandCoinsSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncHandCoins]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:circle data-color="color-2" fill="none" stroke-miterlimit="10" cx="17.5" cy="9.5" r="2.5"></svg:circle><svg:circle data-color="color-2" fill="none" stroke-miterlimit="10" cx="9.5" cy="3.5" r="2.5"></svg:circle><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M13.476 16H19c2.817 0 3 2 3 2l-11.765 4.525a3 3 0 0 1-2.419-.117L1 19v-7h1c1.105 0 3.906.438 5 2h3a4 4 0 0 1 4 4h0-7"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncHandCoinsSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncHandCoinsSvgComponent,
  ],
  exports: [
    InformationFuncHandCoinsSvgComponent,
  ],
})
export class InformationFuncHandCoinsSvgModule {}
