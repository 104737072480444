<form maiaForm [formGroup]="supplementartyListForm" class="supplementary-list-slidein-form">
  <maia-label class="b2b-kdl-font-small title-dark-color">Object Type </maia-label>
  <maia-form-element hideOptionalIndicator class="input-form">
    <maia-input-select id="objectType" class="search-dropdown" formControlName="objectType">
      <input
        id="input_one1"
        maiaInput
        placeholder="Select"
        [value]="supplementartyListForm.controls['objectType'].value"
      />
      <maia-option *ngFor="let list of objectlist" [value]="list.objectType">{{
        list.objectType
      }}</maia-option>
    </maia-input-select>
  </maia-form-element>
  <div
    class="add-edit-supplementary-object-form"
    *ngIf="supplementartyListForm.controls['objectType'].value === 'Household Property'"
  >
    <maia-label class="b2b-kdl-font-small title-dark-color">Object Name </maia-label>
    <maia-form-element hideOptionalIndicator class="input-form">
      <input
        maiaInput
        name="object name"
        formControlName="objectName"
        placeholder="Enter Object Name"
      />
      <maia-validation
        *ngIf="
          supplementartyListForm.controls['objectName']?.touched &&
          supplementartyListForm.controls['objectName'].errors?.required
        "
        >Object Name required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Insured Value </maia-label>
    <maia-form-element hideOptionalIndicator class="input-form">
      <input
        maiaInput
        name="insured value"
        formControlName="insuredValue"
        placeholder="Enter Insured Value"
      />
      <maia-validation
        *ngIf="
          supplementartyListForm.controls['insuredValue']?.touched &&
          supplementartyListForm.controls['insuredValue'].errors?.required
        "
        >Insured Value required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Brand and Model </maia-label>
    <maia-form-element hideOptionalIndicator class="input-form">
      <input
        maiaInput
        name="brand model"
        formControlName="brandModel"
        placeholder="Enter Brand & Model"
      />
      <maia-validation
        *ngIf="
          supplementartyListForm.controls['brandModel']?.touched &&
          supplementartyListForm.controls['brandModel'].errors?.required
        "
        >Brand & Model required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Other Specifics </maia-label>
    <maia-form-element class="input-form">
      <textarea
        id="text-area"
        maiaTextArea
        name="other specifics"
        formControlName="otherSpecifics"
        atlasText
        placeholder="Please enter other specifics"
      >
      </textarea>
    </maia-form-element>
  </div>
  <div
    class="add-edit-supplementary-object-form"
    *ngIf="supplementartyListForm.controls['objectType'].value === 'Pieces of Art'"
  >
    <maia-label class="b2b-kdl-font-small title-dark-color">Object Name </maia-label>
    <maia-form-element hideOptionalIndicator class="input-form">
      <maia-input-select id="objectName" class="search-dropdown" formControlName="objectName">
        <input
          id="input_one1"
          maiaInput
          placeholder="Select"
          [value]="supplementartyListForm.controls['objectName'].value"
        />
        <maia-option *ngFor="let name of objectNames" [value]="name.objectName">{{
          name.objectName
        }}</maia-option>
      </maia-input-select>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Insured Value </maia-label>
    <maia-form-element hideOptionalIndicator class="input-form">
      <input
        maiaInput
        name="insured value"
        formControlName="insuredValue"
        placeholder="Enter Insured Value"
      />
      <maia-validation
        *ngIf="
          supplementartyListForm.controls['insuredValue']?.touched &&
          supplementartyListForm.controls['insuredValue'].errors?.required
        "
        >Insured Value required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Name of Art </maia-label>
    <maia-form-element hideOptionalIndicator class="input-form">
      <input
        maiaInput
        name="name art"
        formControlName="authorArt"
        placeholder="Enter Name of Art"
      />
      <maia-validation
        *ngIf="
          supplementartyListForm.controls['authorArt']?.touched &&
          supplementartyListForm.controls['authorArt'].errors?.required
        "
        >Name of Art required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Author </maia-label>
    <maia-form-element hideOptionalIndicator class="input-form">
      <input maiaInput name="author" formControlName="author" placeholder="Enter Author" />
      <maia-validation
        *ngIf="
          supplementartyListForm.controls['author']?.touched &&
          supplementartyListForm.controls['author'].errors?.required
        "
        >Author required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Size </maia-label>
    <maia-form-element hideOptionalIndicator class="input-form">
      <input maiaInput name="size" formControlName="size" placeholder="Enter Size" />
      <maia-validation
        *ngIf="
          supplementartyListForm.controls['size']?.touched &&
          supplementartyListForm.controls['size'].errors?.required
        "
        >Size required
      </maia-validation>
    </maia-form-element>
    <maia-label class="b2b-kdl-font-small title-dark-color">Year of Creation </maia-label>
    <maia-form-element class="input-form">
      <maia-input-year id="maia-input-year" name="inputYear"> </maia-input-year>
    </maia-form-element>

    <maia-label class="b2b-kdl-font-small title-dark-color">Other Specifics </maia-label>
    <maia-form-element class="input-form">
      <textarea
        id="text-area"
        maiaTextArea
        name="other specifics"
        formControlName="otherSpecifics"
        atlasText
        placeholder="Please enter other specifics"
      >
      </textarea>
    </maia-form-element>
  </div>
  <div>
    <maia-button-group>
      <button
        class="m-t-b-24"
        type="button"
        maiaButton
        maiaButtonType="primary"
        (click)="saveSupplementaryData()"
        [disabled]="!supplementartyListForm.valid"
        >Save</button
      >
      <button
        class="m-t-b-24"
        maiaButton
        maiaButtonType="secondary"
        type="button"
        (click)="cancel()"
        >Cancel</button
      >
    </maia-button-group>
  </div>
</form>
