/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {ChangeDetectorRef, Component} from '@angular/core';
import {Text} from '@atlas/businesstypes';
import {AdminLibraryService} from '../../../services/admin-library.service';
import {AdminLibraryDocumentDto} from '../../../contracts/admin-documents.interface';
import {AdminLibrary} from '../../../contracts/adminInterface';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {BLANK_VALUE} from '../../../constants/resource-management/admin-documents';

@Component({
  selector: 'nje-admin-documents',
  templateUrl: './admin-documents.component.html',
  styleUrls: ['./admin-documents.component.scss'],
})
@UntilDestroy()
export class AdminDocumentsComponent {
  public searchWord: Text;
  public selectedAdminLibrary: AdminLibrary;
  public searchResult: AdminLibraryDocumentDto[] = [];
  public hasSearchTriggered: boolean = false;
  public searchResultCount: number = 0;
  public searchedText: Text;
  public inputText: Text;
  public library: AdminLibrary;
  public searchBarEnterKeyPressed: boolean = false;

  public constructor(
    private readonly _libraryService: AdminLibraryService,
    private readonly _changeDetection: ChangeDetectorRef,
  ) {}

  public searchDocuments(): void {
    if (
      this.searchWord !== undefined &&
      this.searchWord !== null &&
      this.searchWord !== new Text(BLANK_VALUE) &&
      !this.searchBarEnterKeyPressed
    ) {
      const param: string = this.searchWord.toString();
      this.hasSearchTriggered = true;
      this._libraryService
        .searchAdminLibrariesDocuments(param)
        .subscribe((response: AdminLibraryDocumentDto[]) => {
          this.searchResult = response;
          this.searchResultCount = response.length;
          this._changeDetection.detectChanges();
        });
    }
  }

  public handleSearchedTextApp(text: Text): void {
    this.searchedText = text;
    if (null !== this.inputText) {
      this.searchDocuments();
    }
  }

  public handleInputText(text: Text): void {
    if (text === null) {
      this.searchedText = new Text(BLANK_VALUE);
      this.searchWord = new Text(BLANK_VALUE);
      this.hasSearchTriggered = false;
      this.searchResultCount = 0;
      this.searchResult = [];
    }
  }

  public OnSelectedAdminLibrary(library: AdminLibrary): void {
    this.selectedAdminLibrary = library;
  }
}
