import {Component, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-mtpl-vehicle-details-slidein',
  templateUrl: './mtpl-vehicle-details-slidein.component.html',
  styleUrls: ['./mtpl-vehicle-details-slidein.component.scss'],
})
export class MtplVehicleDetailsSlideinComponent
  extends ModalContentComponent<void, undefined | {isDirty?: boolean}>
  implements OnInit
{
  public constructor(public control: ModalControl<void, undefined | {isDirty?: boolean}>) {
    super();
  }

  public ngOnInit(): void {}
}
