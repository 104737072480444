import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {FormGroupManager} from '@njf-frontend-angular/flow-progress';
import {COLUMN, InsurancePartyDetails, InsurancePartyDetailsComponent} from '@b2b-frontend/core';
import {FALSE} from '../../constants/property-insurance-constants';

@Component({
  selector: 'nje-property-address-edit-slidein',
  templateUrl: './property-address-edit-slidein.component.html',
  styleUrls: ['./property-address-edit-slidein.component.scss'],
})
export class PropertyAddressEditSlideinComponent
  extends ModalContentComponent<InsurancePartyDetails, InsurancePartyDetails>
  implements AfterViewInit
{
  public propertyAddressSlideinForm: FormGroupManager<InsurancePartyDetails>;

  @ViewChild(InsurancePartyDetailsComponent)
  public insurancePartyDetailsComponent: InsurancePartyDetailsComponent;

  public flexDirection: string = COLUMN;

  public constructor(public control: ModalControl<InsurancePartyDetails, InsurancePartyDetails>) {
    super();
  }

  public ngAfterViewInit(): void {
    const data = this.control.input;
    this.insurancePartyDetailsComponent?.setInsurancePartyDetails(data);
    this.insurancePartyDetailsComponent?.stepForm.controls.cityEn.disable();
    this.insurancePartyDetailsComponent?.stepForm.controls.postCodeEn.disable();
  }

  public cancel(): void {
    this.control.cancel();
  }

  public isInsurancePartyInfoValid(): boolean {
    if (null != this.insurancePartyDetailsComponent) {
      this.propertyAddressSlideinForm =
        this.insurancePartyDetailsComponent.getInsurancePartyDetails();
      return this.propertyAddressSlideinForm.valid;
    } else return FALSE;
  }

  public saveInsuranceData(): void {
    this.propertyAddressSlideinForm =
      this.insurancePartyDetailsComponent.getInsurancePartyDetails();
    const propertyAddressDetails: InsurancePartyDetails = this.propertyAddressSlideinForm?.value;
    this.control.confirm(propertyAddressDetails);
  }
}
