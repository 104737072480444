import {Injectable, ComponentFactoryResolver, ComponentFactory, Injector} from '@angular/core';
import {SlideInController} from '@maia/slide-ins';
import {LocalizationService} from '../../flow/services/localization.service';
import {localeValPipe} from '../../flow/pipes/localeVal.pipe';
import {LoggerFactory} from '@atlas-angular/logger';
import {ConfirmationAlertDialogModel} from '../../flow/shared/model/ConfirmationAlertDialogModel';
import {TalonSlideinComponent} from '../component/talon-slidein/talon-slidein.component';
import {TalonScanningModule} from '../talon-scanning.module';
import {Observable} from 'rxjs';
import {VehicleTalonData} from '../dto/VehicleTalonData';
import {ModalResult} from '@maia/modals';

@Injectable({
  providedIn: TalonScanningModule,
})
export class TalonSlideinService {
  public readonly slideInOneFactory: ComponentFactory<TalonSlideinComponent>;

  public confirmationAlertModel: ConfirmationAlertDialogModel = {
    type: 'email',
    alertMessage:
      'Your changes has been made from other devices/browsers and if you would like to proceed further.',
    buttonText: 'Continue',
    buttonType: 'delete',
    navigatePathString: '',
    callbackReq: true,
  };

  public constructor(
    private readonly _slideInCtrl: SlideInController,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    loggerFactory: LoggerFactory,
  ) {
    this.slideInOneFactory =
      componentFactoryResolver.resolveComponentFactory(TalonSlideinComponent);
  }

  public openTalonSlidIn(): Observable<ModalResult<VehicleTalonData>> {
    const lang = sessionStorage.getItem('lang');
    const slideInTitleValue = new localeValPipe(new LocalizationService()).transform(
      'Talon Scanning',
      lang,
    );
    return this._slideInCtrl.prepare(
      this.slideInOneFactory,
      this._injector,
      {
        title: slideInTitleValue,
      },
      {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
      },
    );
  }

  public ngOnDestroy(): void {}
}
