import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Decimal, Boolean} from '@atlas/businesstypes';
import {ScreenFormComponent} from '@njf-frontend-angular/flow-progress';
import {Observable, of} from 'rxjs';
import {
  MOCK_PREMIUM_DOUBLE,
  MOCK_PREMIUM_FOUR,
  MOCK_PREMIUM_SINGLE,
} from './__tests__/premium-screen-mock';
import {PremiumOptions} from './__tests__/premium-screen-mockInterface';
@Component({
  selector: 'nje-premium-screen',
  templateUrl: './premium-screen.component.html',
  styleUrls: ['./premium-screen.component.scss'],
})
@UntilDestroy()
export class PremiumScreenComponent extends ScreenFormComponent implements OnInit {
  public firstSelected: boolean = false;
  public secondSelected: boolean = false;
  public fourSelected: boolean = false;
  public singlePremiumStatus = true;
  public doublePremiumStatus = false;
  public fourPremiumStatus = false;
  public doublePremiumStatusNg = Boolean.FALSE;
  public fourPremiumStatusNg = Boolean.FALSE;
  public disabled = false;
  public isAddedToInsuredPartyDisabled = Boolean.TRUE;
  public isDisabled: boolean = false;
  public continueClicked: boolean;
  public premiumSingleList: PremiumOptions[];
  public premiumDoubleList: PremiumOptions[];
  public premiumFourList: PremiumOptions[];
  public premiumSingleId: number = 0;
  public premiumDoubleId: number = 0;
  public premiumFourId: number = 0;
  public isContinue: boolean = true;
  public constructor(private readonly _changeDetection: ChangeDetectorRef, route: ActivatedRoute) {
    super(route);
  }

  public ngOnInit(): void {
    this.initialiseApiCalls();
  }

  public initialiseApiCalls(): void {
    this.getSinglePremiumList()
      .pipe(takeUntilDestroyed(this))
      .subscribe((data: PremiumOptions[]) => {
        this.premiumSingleList = data;
      });
    this.getDoublePremiumList()
      .pipe(takeUntilDestroyed(this))
      .subscribe((data: PremiumOptions[]) => {
        this.premiumDoubleList = data;
      });
    this.getFourPremiumList()
      .pipe(takeUntilDestroyed(this))
      .subscribe((data: PremiumOptions[]) => {
        this.premiumFourList = data;
      });
  }

  protected saveMtplData(): void {
    this.continueClicked = true;
  }

  public updateSelectedSinglePremium(singleIndex: number): void {
    this.firstSelected = false;
    this.secondSelected = true;
    this.fourSelected = true;
    this.premiumSingleList.forEach(
      (premiumFirst: PremiumOptions) => (premiumFirst.selectedStatus = false),
    );
    this.premiumSingleList[singleIndex].selectedStatus = true;
    this.premiumSingleId = this.premiumSingleList[singleIndex].premiumId;
    this.isContinue = !this.premiumSingleList[singleIndex].selectedStatus;
  }

  public updateSelectedDoublePremium(doubleIndex: number): void {
    this.secondSelected = false;
    this.firstSelected = true;
    this.fourSelected = true;
    this.premiumDoubleList.forEach(
      (premiumDouble: PremiumOptions) => (premiumDouble.selectedStatus = false),
    );
    this.premiumDoubleList[doubleIndex].selectedStatus = true;
    this.premiumDoubleId = this.premiumDoubleList[doubleIndex].premiumId;
    this.isContinue = !this.premiumDoubleList[doubleIndex].selectedStatus;
  }

  public updateSelectedFourPremium(fourIndex: number): void {
    this.fourSelected = false;
    this.firstSelected = true;
    this.secondSelected = true;
    this.premiumFourList.forEach(
      (premiumFour: PremiumOptions) => (premiumFour.selectedStatus = false),
    );
    this.premiumFourList[fourIndex].selectedStatus = true;
    this.premiumFourId = this.premiumFourList[fourIndex].premiumId;
    this.isContinue = !this.premiumFourList[fourIndex].selectedStatus;
  }

  public getAmoutValue(amount: string): Decimal {
    return new Decimal(amount);
  }

  public onClickDouble(): void {
    if (this.disabled === false) {
      this.doublePremiumStatus = !this.doublePremiumStatus;
    }
  }

  public onClickFour(): void {
    this.fourPremiumStatus = !this.fourPremiumStatus;
    this.disabled = this.fourPremiumStatus;
    this._changeDetection.detectChanges();
  }

  public getSinglePremiumList(): Observable<PremiumOptions[]> {
    return of(MOCK_PREMIUM_SINGLE);
  }

  public getDoublePremiumList(): Observable<PremiumOptions[]> {
    return of(MOCK_PREMIUM_DOUBLE);
  }

  public getFourPremiumList(): Observable<PremiumOptions[]> {
    return of(MOCK_PREMIUM_FOUR);
  }
}
