import {Injectable} from '@angular/core';
import {Date as AtlasDate, DateUtils} from '@atlas/businesstypes';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateFormatterService {
  constructor() {}

  public normalizeDate(
    date: Date | AtlasDate | {internalValue: string} | undefined | null,
  ): Date | undefined {
    if (date === undefined || date === null) {
      return undefined;
    }

    let jsDate: Date;

    if (date instanceof AtlasDate) {
      const s = DateUtils.serialize(date);
      jsDate = new Date(s.year, s.month, s.day);
    } else if (date instanceof Date) {
      jsDate = date;
    } else if (typeof date === 'object' && 'internalValue' in date) {
      const s = DateUtils.serialize(new AtlasDate(date.internalValue));
      jsDate = new Date(s.year, s.month, s.day);
    } else {
      throw new Error('Unexpected date format');
    }

    return jsDate;
  }

  dateToISO(
    date: Date | AtlasDate | {internalValue: string} | undefined | null,
  ): string | undefined {
    const jsDate = this.normalizeDate(date);

    if (!jsDate) {
      return undefined;
    }

    return moment(jsDate).format('YYYY-MM-DD');
  }

  dateTimeToISO(date: Date): string | undefined {
    if (date === undefined) {
      return undefined;
    }

    return date.toISOString();
  }

  public getDDMMYYYYFormatDate(
    date: Date | AtlasDate | {internalValue: string} | undefined | null,
  ): string | undefined {
    const jsDate = this.normalizeDate(date);

    if (!jsDate) {
      return undefined;
    }

    return moment(jsDate).format('DD-MM-YYYY');
  }

  public getISO8601FormatDate(startDate: AtlasDate): string {
    const serialisedDate = DateUtils.serialize(startDate);
    const day = serialisedDate.day;
    const month = serialisedDate.month + 1;
    const year = serialisedDate.year;
    return (
      year.toString() +
      '-' +
      (month.toString().length === 1 ? '0' + month : month) +
      '-' +
      (day.toString().length === 1 ? '0' + day : day)
    );
  }

  public isoDateDifference(
    date1: Date | AtlasDate | {internalValue: string} | undefined | null,
    date2: Date | AtlasDate | {internalValue: string} | undefined | null,
  ): number | undefined {
    const jsDate1 = this.normalizeDate(date1);
    const jsDate2 = this.normalizeDate(date2);

    if (!jsDate1 || !jsDate2) {
      return undefined;
    }
    return Math.floor((jsDate2.getTime() - jsDate1.getTime()) / 1000 / 60 / 60 / 24);
  }

  public getDDMMYYYFormatDateString(date: AtlasDate): string {
    const serialisedDate = DateUtils.serialize(date);
    const day = serialisedDate.day;
    const month = serialisedDate.month + 1;
    const year = serialisedDate.year;
    return (
      (day.toString().length === 1 ? '0' + day.toString() : day.toString()) +
      '-' +
      (month.toString().length === 1 ? '0' + month.toString() : month.toString()) +
      '-' +
      year.toString()
    );
  }

  public isValidDOB(dob: AtlasDate | string): boolean {
    let isValid: boolean = false;
    let jsDate: Date | undefined;
    if (dob instanceof AtlasDate) {
      jsDate = this.normalizeDate(dob);
    } else if (typeof dob === 'string') {
      jsDate = moment(dob, 'DD/MM/YYYY').toDate();
    }

    if (!jsDate) {
      isValid = false;
    } else if (jsDate.getTime() < new Date().getTime()) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid;
  }

  public getAtlasDate(dob: string): AtlasDate | undefined {
    if (dob !== '') {
      const mDobString = dob.split('-').join('');
      return new AtlasDate(mDobString);
    }
    return undefined;
  }

  public getHr(): number {
    const jsDate = new Date();
    return moment(jsDate).hours();
  }

  public getMin(): number {
    const jsDate = new Date();
    return moment(jsDate).minutes();
  }
}
