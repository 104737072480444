import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule NavigationFuncOpenInNewSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendNavigationFuncOpenInNew]',
  template: '<svg:path fill="none" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round" d="M12.401 11.715l6.63-6.63"></svg:path><svg:path fill="none" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round" d="M13.824 5.086h5.207v5.206"></svg:path><svg:path fill="none" stroke-width="1.04" stroke-linecap="round" stroke-linejoin="round" d="M19.031 14.883v4.2H5.034V5.085h4.103"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 24 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncOpenInNewSvgComponent {
}

@NgModule({
  declarations: [
    NavigationFuncOpenInNewSvgComponent,
  ],
  exports: [
    NavigationFuncOpenInNewSvgComponent,
  ],
})
export class NavigationFuncOpenInNewSvgModule {}
