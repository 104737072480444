import {Injectable, ComponentFactoryResolver, Injector, ComponentFactory} from '@angular/core';
import {SlideInController} from '@maia/slide-ins';
import {TravelCoverSlideinComponent} from '../../screens/travel-cover-slidein/travel-cover-slidein.component';
import {InsuredPerson, TravelCovers} from '@b2b-frontend/core';
import {Covers, Coverage, TRAVEL_PROPOSAL_SAVE_OFFER} from '../../constants/constant';
import {ModalResult} from '@maia/modals';
import {Observable} from 'rxjs';
import {TravelTripDetailsSlideinComponent} from '../../screens/travel-trip-details-slidein/travel-trip-details-slidein.component';
import {TRAVEL_TYPE_DETAILS} from '../../constants/trip-details';
import {TravelAddonsSlideinComponent} from '../../screens/travel-addons-slidein/travel-addons-slidein.component';
import {GET_QUOTE_SUMMARY} from '../../constants/getQuote';
import {TravelProposalSlideinComponent} from '../../screens/travel-proposal-slidein/travel-proposal-slidein.component';
import {TravelInsuredPeopleSlideinComponent} from '../../screens/travel-insured-people-slidein/travel-insured-people-slidein.component';
import {EDIT_INSURED_PEOPLE, INSURED_PEOPLE_VIEW_LIST} from '../../constants/view-list';

@Injectable({
  providedIn: 'root',
})
export class TravelSlideInService {
  private readonly _coverSlideinFactory: ComponentFactory<TravelCoverSlideinComponent>;
  private readonly _tripDetailsSlideinFactory: ComponentFactory<TravelTripDetailsSlideinComponent>;
  private readonly _addonsSlideinFactory: ComponentFactory<TravelAddonsSlideinComponent>;
  private readonly _proposalSlideinFactory: ComponentFactory<TravelProposalSlideinComponent>;
  private readonly _insuredPeopleSlideinFactory: ComponentFactory<TravelInsuredPeopleSlideinComponent>;

  public constructor(
    private readonly _slideInCtrl: SlideInController,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
  ) {
    this._coverSlideinFactory = componentFactoryResolver.resolveComponentFactory(
      TravelCoverSlideinComponent,
    );

    this._tripDetailsSlideinFactory = componentFactoryResolver.resolveComponentFactory(
      TravelTripDetailsSlideinComponent,
    );

    this._addonsSlideinFactory = componentFactoryResolver.resolveComponentFactory(
      TravelAddonsSlideinComponent,
    );

    this._proposalSlideinFactory = componentFactoryResolver.resolveComponentFactory(
      TravelProposalSlideinComponent,
    );

    this._insuredPeopleSlideinFactory = componentFactoryResolver.resolveComponentFactory(
      TravelInsuredPeopleSlideinComponent,
    );
  }

  public openCoversSlideIn(covers?: TravelCovers): Observable<ModalResult<TravelCovers>> {
    const slideInTitle =
      covers?.coversName === Covers.lite
        ? Coverage.liteCoverage
        : covers?.coversName === Covers.standard
        ? Coverage.standardCoverage
        : covers?.coversName === Covers.visa
        ? Coverage.visaCoverage
        : covers?.coversName === Covers.premium
        ? Coverage.premiumCoverage
        : Coverage.customCoverage;
    return this._slideInCtrl.prepare(
      this._coverSlideinFactory,
      this._injector,
      {title: slideInTitle},
      {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
        input: covers,
      },
    );
  }

  public openTripDetailsSlideIn(): Observable<ModalResult<void>> {
    return this._slideInCtrl.prepare(
      this._tripDetailsSlideinFactory,
      this._injector,
      {title: TRAVEL_TYPE_DETAILS.TITLE},
      {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
      },
    );
  }

  public openAddonsSlideIn(): Observable<ModalResult<void>> {
    return this._slideInCtrl.prepare(
      this._addonsSlideinFactory,
      this._injector,
      {title: GET_QUOTE_SUMMARY.TITLE},
      {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
      },
    );
  }

  public openProposalSlideIn(): Observable<ModalResult<void>> {
    return this._slideInCtrl.prepare(
      this._proposalSlideinFactory,
      this._injector,
      {title: TRAVEL_PROPOSAL_SAVE_OFFER.TITLE},
      {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
      },
    );
  }

  public openInsuredPeopleSlideIn(
    insuredPerson?: InsuredPerson,
  ): Observable<ModalResult<InsuredPerson>> {
    const slideinTitle = insuredPerson ? EDIT_INSURED_PEOPLE.TITLE : INSURED_PEOPLE_VIEW_LIST.TITLE;
    return this._slideInCtrl.prepare(
      this._insuredPeopleSlideinFactory,
      this._injector,
      {
        title: slideinTitle,
      },
      {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
        input: insuredPerson,
      },
    );
  }
}
