import {AddedAgents} from '../models/b2b/AddedAgents';
import {AgentSelectionOption} from '../models/b2b/AgentSelectionOption';
import {CountryPhoneNumberPrefix} from '../models/b2b/CountryPhoneNumberPrefix';
import {SelectedAgentsDetails} from '../models/b2b/SelectedAgentsDetails';
import {IdValue} from '../models/IdValue';

export const CountryPhoneNumberPrefixes: CountryPhoneNumberPrefix[] = [
  {prefix: '+32', name: 'Belgium', flag: 'ivyMaiaFlagUtilBelgium'},
  {prefix: '+36', name: 'Czech Republic', flag: 'ivyMaiaFlagUtilCzechRepublic'},
  {prefix: '+420', name: 'Slovakia', flag: 'ivyMaiaFlagUtilSlovakia'},
  {prefix: '+421', name: 'Hungary', flag: 'ivyMaiaFlagUtilHungary'},
  {prefix: '+49', name: 'Germany', flag: 'ivyMaiaFlagUtilGermany'},
  {prefix: '+33', name: 'France', flag: 'ivyMaiaFlagUtilFrance'},
  {prefix: '+31', name: 'The Netherlands', flag: 'ivyMaiaFlagUtilNetherlands'},
  {prefix: '+93', name: 'Afghanistan', flag: 'ivyMaiaInformationFuncFlag'},
  {prefix: '+355', name: 'Albania', flag: 'ivyMaiaInformationFuncFlag'},
  {prefix: '+213', name: 'Algeria', flag: 'ivyMaiaInformationFuncFlag'},
  {prefix: '+376', name: 'Andorra', flag: 'ivyMaiaInformationFuncFlag'},
  {prefix: '+244', name: 'Angola', flag: 'ivyMaiaInformationFuncFlag'},
  {prefix: '+672', name: 'Antarctica', flag: 'ivyMaiaInformationFuncFlag'},
  {prefix: '+54', name: 'Argentina', flag: 'ivyMaiaInformationFuncFlag'},
];

export const AgentOptions: AgentSelectionOption[] = [
  {
    id: 0,
    title: 'Single Agent Number',
  },
  {
    id: 1,
    title: 'Group of Agent Number',
  },
  {
    id: 2,
    title: 'All Agent Numbers under this PID',
  },
  {
    id: 3,
    title: 'Agent Number of Subordinates',
  },
  {
    id: 4,
    title: 'All Agents under DZI',
  },
];

export const AutoCompleteOptions: IdValue[] = [
  {id: 0, displayValue: 'Belgium', value: 'Belgium'},
  {id: 1, displayValue: 'Germany', value: 'Germany'},
  {id: 2, displayValue: 'France', value: 'France'},
  {id: 3, displayValue: 'Netherlands', value: 'Netherlands'},
  {id: 4, displayValue: 'Albania', value: 'Albania'},
  {id: 5, displayValue: 'Andorra', value: 'Andorra'},
  {id: 6, displayValue: 'Armenia', value: 'Armenia'},
  {id: 7, displayValue: 'Austria', value: 'Austria'},
];

export const AgentNumberOptions: AgentSelectionOption[] = [
  {
    id: 0,
    title: '6463636373',
  },
  {
    id: 1,
    title: '9292837646',
  },
  {
    id: 2,
    title: '4645473833',
  },
  {
    id: 3,
    title: '5857584984',
  },
];

export enum AgentsNumber {
  SINGLE_AGENT_NUMBER = 0,
  GROUP_OF_AGENT_NUMBER = 1,
  ALL_AGENT_NUMBERS_UNDER_THIS_PID = 2,
  AGENT_NUMBER_OF_SUBORDINATES = 3,
}

export const AgentsbyNumberOptions: AgentSelectionOption[] = [
  {
    id: 0,
    title: 'Single Agent Number',
  },
  {
    id: 1,
    title: 'Group of Agent Number',
  },
  {
    id: 2,
    title: 'All Agent Numbers under this PID',
  },
  {
    id: 3,
    title: 'Agent Number of Subordinates',
  },
];

export const AgentsbyAgencyOptions: AgentSelectionOption[] = [
  {
    id: 0,
    title: 'one',
  },
  {
    id: 1,
    title: 'two',
  },
  {
    id: 2,
    title: 'three',
  },
  {
    id: 3,
    title: 'four',
  },
  {
    id: 4,
    title: 'five',
  },
];

export const SimpleNumberArray: number[] = [4, 8, 9, 2, 0, 6, 3, 5, 1, 7];

export const SelectedAgents: SelectedAgentsDetails[] = [
  {
    agencyNumber: '1233872',
    agencyName: 'ABC',
    agentNumber: '6463636373',
    agentName: 'Boris',
  },
  {
    agencyNumber: '7847443',
    agencyName: 'DEF',
    agentNumber: '9292837646',
    agentName: 'Mark',
  },
  {
    agencyNumber: '3333222',
    agencyName: 'GHI',
    agentNumber: '4645473833',
    agentName: 'Millena',
  },
  {
    agencyNumber: '5678888',
    agencyName: 'JKL',
    agentNumber: '5857584984',
    agentName: 'Vessi',
  },
];

export const SelectedAgentsColumnHeadings: string[] = [
  'Agency Number',
  'Agency Name',
  'Agent Number',
  'Agent Name',
];

export const AddedAgentsDetails: AddedAgents[] = [
  {
    agencyName: 'ABC Agency',
    agentNumber: '6463636373',
  },
  {
    agencyName: 'DEF Agency',
    agentNumber: '9292837646',
  },
  {
    agencyName: 'GHI Agency',
    agentNumber: '4645473833',
  },
  {
    agencyName: 'JKL Agency',
    agentNumber: '5857584984',
  },
  {
    agencyName: 'DDD Agency',
    agentNumber: '5857584984',
  },
];
