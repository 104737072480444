<maia-divider class="slidein-top-divider"></maia-divider>
<form maiaForm>
  <ng-container *ngIf="!isEditFilter; else editCategory">
    <maia-form-group>
      <maia-form-element
        label="Category"
        i18n-label="@@nje-b2b-frontend-resource-articles-filter-category"
      >
        <maia-checkbox-group
          *ngFor="let checkBoxPage of checkBoxCategoryList; index as i"
          class="wrapper"
        >
          <maia-checkbox
            name="filterListForm"
            #filterListForm="ngModel"
            [(ngModel)]="checkBoxPage.filterListForm.value"
            (ngModelChange)="onChange($event.internalValue, i, 'Category')"
          >
            <span *ngIf="ifEnglishLang()">{{ existingCategoryList[i]!.i18n!.en?.title }}</span>
            <span *ngIf="!ifEnglishLang()">{{ existingCategoryList[i]!.i18n!.bg?.title }}</span>
          </maia-checkbox>
        </maia-checkbox-group>
      </maia-form-element>
    </maia-form-group>
  </ng-container>
  <ng-template #editCategory>
    <maia-fluid-fluid-layout>
      <div>
        <maia-label
          class="category-label"
          i18n="@@nje-b2b-frontend-resource-articles-filter-category"
          >Category</maia-label
        >
      </div>
      <div class="category-wrapper">
        <form [formGroup]="filterForm">
          <div *ngFor="let category of categoryList; let i = index">
            <maia-tile-document-sign class="padding-class">
              <maia-tile-document-sign-text class="url_text">
                <input
                  [value]="categoryListValues[i]?.category"
                  class="category-input"
                  (ngModelChange)="onKeyUp($event, i, category.id)"
                  #byid
                  (change)="onKeyUp(byid.value, i, category.id)"
                  placeholder="Enter new category"
                />
              </maia-tile-document-sign-text>
              <maia-tile-document-sign-label>
                <button
                  *ngIf="!editButtonVisibility[i]"
                  maiaButton
                  maiaButtonType="tertiary-delete"
                  class="remove_text"
                  i18n="@@nje-b2b-frontend-resource-admin-removeCategory"
                  (click)="removeCategory(category.id)"
                  [disabled]="
                    isGeneralCategory(
                      ifEnglishLang() ? category.i18n?.en?.title : category.i18n?.bg?.title
                    )
                  "
                  >Delete</button
                >
              </maia-tile-document-sign-label>
            </maia-tile-document-sign>
          </div>
          <div> </div>
        </form>
      </div>
    </maia-fluid-fluid-layout>
    <div class="add-contact">
      <div class="add-contact-icon">
        <svg b2bFrontendActionFuncPlusCircle></svg>
      </div>
      <div class="add-contact-button">
        <button
          maiaButton
          maiaButtonType="tertiary"
          (click)="addNewCategory()"
          i18n="@@nje-b2b-frontend-resource-articles-filter-addnew"
          >Add new category</button
        >
      </div>
    </div>
  </ng-template>
  <maia-divider class="maia-divider border-margin"></maia-divider>
  <ng-container *ngIf="!isEditFilter">
    <maia-form-group>
      <maia-form-element
        label="User Type"
        i18n-label="@@nje-b2b-frontend-resource-articles-filter-usertype"
      >
        <maia-checkbox-group
          *ngFor="let checkBoxPage of checkBoxUserTypeList; index as i"
          class="wrapper"
        >
          <maia-checkbox
            *ngIf="ifEnglishLang()"
            name="filterListForm"
            #filterListForm="ngModel"
            [(ngModel)]="checkBoxPage.filterListForm.value"
            (ngModelChange)="onChange($event, i, 'User Type')"
          >
            {{ existingUserTypeList[i]!.i18n!.en!.title }}
          </maia-checkbox>
          <maia-checkbox
            *ngIf="!ifEnglishLang()"
            name="filterListForm"
            #filterListForm="ngModel"
            [(ngModel)]="checkBoxPage.filterListForm.value"
            (ngModelChange)="onChange($event, i, 'User Type')"
          >
            {{ existingUserTypeList[i]!.i18n!.bg!.title }}
          </maia-checkbox>
        </maia-checkbox-group>
      </maia-form-element>
    </maia-form-group>
  </ng-container>
</form>
<div class="filter-footer-wrapper">
  <maia-button-group class="filter-buttons">
    <button
      maiaButton
      *ngIf="!isEditFilter"
      maiaButtonType="primary"
      (click)="applyFilter()"
      i18n="@@nje-b2b-frontend-resource-articles-filter-apply"
      [disabled]="applyFilterDisabledCheck"
      >Apply</button
    >
    <button
      maiaButton
      *ngIf="isEditFilter"
      maiaButtonType="primary"
      (click)="applyFilter()"
      i18n="@@nje-b2b-frontend-resource-articles-filter-apply"
      >Apply Edit</button
    >
    <button
      *ngIf="isEditFilter === false"
      maiaButton
      maiaButtonType="secondary"
      (click)="clearFilter()"
      i18n="@@nje-b2b-frontend-resource-articles-filter-clearfilter"
      >Clear Filter</button
    >
    <button
      *ngIf="isEditFilter === false"
      maiaButton
      maiaButtonType="secondary"
      (click)="editFilter()"
      i18n="@@nje-b2b-frontend-resource-articles-filter-editfilter"
      >Edit Filter</button
    >
    <button
      *ngIf="isEditFilter === true"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancelFilter()"
      i18n="@@nje-b2b-frontend-resource-articles-filter-cancel"
      >Cancel</button
    >
  </maia-button-group>
</div>
