<p class="align" i18n="@@nje-b2b-frontend-resource-contacts-confirmation"
  >Are you sure you want to delete this contact?</p
>
<div *maiaPopUpFooter>
  <button
    maiaButton
    maiaButtonType="tertiary"
    (click)="control.cancel()"
    i18n="@@nje-b2b-frontend-resource-contacts-no"
    >No</button
  >

  <button
    maiaButton
    maiaButtonType="delete"
    (click)="removeContact()"
    i18n="@@nje-b2b-frontend-resource-contacts-yes"
    >Yes</button
  >
</div>
