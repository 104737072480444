<div class="head-auto">
  <div></div>
  <a
    maiaDocumentAction
    rel="noopener noreferrer"
    href="https://www.dzi.bg/attachments/Product/30/download/8104-Singleitrip-IPID-0.pdf"
    target="_blank"
    >Read more</a
  >
</div>
<div *ngIf="isTypeInvoiceSelected">
  <maia-fluid-fluid-layout alignContentTop>
    <maia-contextual-message class="maia-msg">
      <p class="b2b-kdl-font-small"
        >If vehicle has been purchased from official distributor, please select Type of Sum Insured:
        SI by Invoice.</p
      >
    </maia-contextual-message>
  </maia-fluid-fluid-layout>
</div>
<form maiaForm [formGroup]="sumInsuredForm.formGroup">
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <div class="msg-box m-r-16">
      <maia-form-element hideOptionalIndicator class="input-column" label="Sum Insured">
        <maia-input-group>
          <input formControlName="sumInsured" maiaInputNumber name="sumInsuredNumber" />
          <maia-input-group-suffix>BGN</maia-input-group-suffix>
        </maia-input-group>
      </maia-form-element>
      <maia-contextual-message class="box-size">
        <p class="b2b-kdl-font-small"
          ><span class="white-space1"> Actual SI</span><span>: 25 000 BGN</span>
        </p>
        <p class="b2b-kdl-font-small m-t--16"
          ><span class="white-space2">Range SI </span><span>: 10 000 BGN - 25 000 BGN</span>
        </p>
      </maia-contextual-message>
    </div>
    <maia-form-element
      hideOptionalIndicator
      class="input-column m-r-16"
      label="Type of Sum Insured"
    >
      <maia-input-select formControlName="typeOfSumInsured">
        <input
          [value]="sumInsuredForm.controls['typeOfSumInsured'].value"
          maiaInput
          placeholder="Select"
        />
        <maia-option class="option_one" value="SI by Renewal" (click)="isRenewalClicked()"
          >SI by Renewal</maia-option
        >
        <maia-option class="option_one" value="SI by Invoice" (click)="siInvoiceClicked()"
          >SI by Invoice</maia-option
        >
      </maia-input-select>
    </maia-form-element>
    <maia-form-element hideOptionalIndicator class="input-column" label="Policy Currency">
      <maia-input-select formControlName="policyCurrency">
        <input
          [value]="sumInsuredForm.controls['policyCurrency'].value"
          maiaInput
          placeholder="Select"
        />
        <maia-option class="option_one" value="BGN">BGN</maia-option>
      </maia-input-select>
    </maia-form-element>
  </maia-fluid-fluid-layout>

  <div *ngIf="isTypeInvoiceSelected">
    <maia-fluid-fluid-layout>
      <maia-contextual-message class="maia-msg">
        <p class="b2b-kdl-font-small"
          >Selected Type of Sum Determination is CU (Invoice). You must attach a purchase document
          at Summary step.</p
        >
      </maia-contextual-message>
    </maia-fluid-fluid-layout>
  </div>
  <div *ngIf="isTypeInvoiceSelected">
    <maia-form-element
      hideOptionalIndicator
      class="input-column m-r-16"
      label="Invoice Details"
    ></maia-form-element>
    <maia-fluid-fluid-layout>
      <maia-form-element hideOptionalIndicator class="input-column m-r-16" label="Invoice value">
        <maia-input-group>
          <input maiaInputNumber name="sumInsuredNumber" />
          <maia-input-group-suffix>BGN</maia-input-group-suffix>
        </maia-input-group>
      </maia-form-element>
      <maia-form-element hideOptionalIndicator class="input-column m-r-16" label="Date of Invoice">
        <maia-input-select formControlName="dateOfInvoice">
          <input
            [value]="sumInsuredForm.controls['dateOfInvoice'].value"
            maiaInput
            placeholder="Select"
          />
        </maia-input-select>
      </maia-form-element>
      <maia-form-element hideOptionalIndicator class="input-column m-r-16" label="Provider">
        <maia-input-select formControlName="provider">
          <input
            [value]="sumInsuredForm.controls['provider'].value"
            maiaInput
            placeholder="Select"
          />
          <maia-option class="option_one" value="XYZ">XYZ</maia-option>
        </maia-input-select>
      </maia-form-element>
    </maia-fluid-fluid-layout>
  </div>
  <maia-divider class="m-t-16"></maia-divider>
  <maia-accordion-list id="maia-accordion-list-1" class="m-t--16">
    <maia-accordion-list-item id="first-list-item">
      <maia-accordion-list-item-header>Equipments</maia-accordion-list-item-header>
      <maia-accordion-list-item-content id="first-list-item-content">
        <maia-accordion-list>
          <maia-accordion-list-item class="info-msg">
            <maia-accordion-list-item-header
              ><span class="white-space3">Basic Equipments</span></maia-accordion-list-item-header
            >
            <maia-accordion-list-item-content isSubelement>
              <maia-fluid-fluid-layout>
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Alloy Wheels</p>
                </div>
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Fog Lamp</p>
                </div>
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Headlight Washer System</p>
                </div>
              </maia-fluid-fluid-layout>
              <maia-fluid-fluid-layout class="m-t--16">
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Roof Rack</p>
                </div>
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Spare Tyre</p>
                </div>
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Speed Limit Control System</p>
                </div>
              </maia-fluid-fluid-layout>
              <maia-fluid-fluid-layout class="m-t--16">
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Central Locking</p>
                </div>
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Alarm System</p>
                </div>
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Head Up Display</p>
                </div>
              </maia-fluid-fluid-layout>
              <maia-fluid-fluid-layout class="m-t--16">
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Touchscreen</p>
                </div>
                <div class="check-icon"
                  ><svg b2bFrontendAlertFuncCheck></svg>
                  <p>Sound System</p>
                </div>
                <div></div>
              </maia-fluid-fluid-layout>
            </maia-accordion-list-item-content>
          </maia-accordion-list-item>
        </maia-accordion-list>
        <maia-form-element
          hideOptionalIndicator
          class="input-column m-r-16"
          label="Additional Equipments"
        >
        </maia-form-element>
        <maia-table>
          <maia-table-header>
            <maia-table-row>
              <maia-table-cell class="b2b-kdl-font-small-bold table-title-color">
                Type of Equipment
              </maia-table-cell>
              <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
                >Make</maia-table-cell
              >
              <maia-table-cell class="b2b-kdl-font-small-bold table-title-color">
                Model
              </maia-table-cell>
              <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
                >Actual Value</maia-table-cell
              >
              <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
                >Provider</maia-table-cell
              >
              <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
                >Date Of Invoice
              </maia-table-cell>
            </maia-table-row>
          </maia-table-header>
        </maia-table>
        <div class="read-icon"
          ><svg b2bFrontendActionFuncPlusCircle></svg>
          <button maiaButton maiaButtonType="tertiary" (click)="openSlidein()"
            >Add new Equipment</button
          >
        </div>
      </maia-accordion-list-item-content>
    </maia-accordion-list-item>
  </maia-accordion-list>
  <maia-form-element
    hideOptionalIndicator
    class="input-column"
    label="Audio / Video Equipments and Folio"
  >
  </maia-form-element>
  <maia-fluid-fluid-layout>
    <maia-form-element
      hideOptionalIndicator
      class="input-column m-r-16"
      label="Do you have A/V Equipment?"
    >
      <maia-radio-group name="avequRadio" class="radio-group-margins m-t--12" horizontal>
        <njf-radio-button value="yes">Yes</njf-radio-button>
        <njf-radio-button value="no">No</njf-radio-button>
      </maia-radio-group>
    </maia-form-element>
    <div></div>
    <div></div>
  </maia-fluid-fluid-layout>
  <maia-table>
    <maia-table-header>
      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"> Type</maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color">Make</maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"> Model </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
          >Price as new</maia-table-cell
        >
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
          >Provider</maia-table-cell
        >
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
          >Date Of Invoice
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
          >Actual Value
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-title-color"
          >Premium
        </maia-table-cell>
      </maia-table-row>
    </maia-table-header>
  </maia-table>
  <div class="read-icon"
    ><svg b2bFrontendActionFuncPlusCircle></svg>
    <button maiaButton maiaButtonType="tertiary" (click)="openSecondSlidein()"
      >Add new A/V Equipment</button
    >
  </div>
  <maia-divider class="m-t-24"></maia-divider>
  <button class="m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveAndContinue()"
    >Continue</button
  >
</form>
