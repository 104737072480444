import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';

@Component({
  selector: 'property-insurance',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent extends ScreenStepComponent {
  protected saveMtplData(): void {}

  constructor(route: ActivatedRoute) {
    super(route);
  }
}
