import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {RoutePaths} from '../../constants/route-paths';
import {AuthNavigationService} from '../../services/auth-navigation.service';

@Component({
  selector: 'nje-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public logInForm: FormGroup;
  public isInCorrectCredentials: boolean = false;

  public constructor(
    private readonly _AuthNavigationService: AuthNavigationService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _router: Router,
  ) {}

  public ngOnInit(): void {
    this.logInForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  public logInDataChanged(): void {
    this.isInCorrectCredentials = false;
    this._cdr.detectChanges();
  }

  public logIn(): void {
    if (this.logInForm.valid) {
      // TODO: Sign-in API call
      this._router.navigate([RoutePaths.Dashboard]);
    }
  }

  public forgotPassword(): void {
    this._AuthNavigationService.nextScreen(RoutePaths.TwoFa);
  }
}
