import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';

import {
  BANK_LIST,
  CURRENCY_LIST,
  FEATURE_LIST,
  VILLA,
  HOUSE,
  APARTMENT,
  HOUSEHOLD_PROPERTY,
  IMPROVEMENTS_LIST,
  GARAGE_AS_A_PART_OF_THE_BUILDING,
  GARAGE_AS_A_SEPARATE_BUILDING,
  EXTERNAL_POOL,
  FENCE,
  SOLAR_THERMAL_COLLECTOR,
  IMPROVEMENTS,
  PIECES_OF_ART,
  PROPERTY_LIST,
  PROPERTY_OWNERSHIP_TYPE,
  TRUE,
  FALSE,
  YES,
  NO,
  PolicySelectableTypesList,
} from '../../constants/property-insurance-constants';
import {
  BankList,
  FeatureList,
  ImprovementTypes,
  PolicySelectableType,
  PropertyFeatureDetails,
  PropertyTypes,
  SelectableInputType,
} from '../../contracts/property-insurance.interface';
import {PropertyApiService} from '../../property-core/services/property-api.service';

@Component({
  selector: 'property-feature-slidein',
  templateUrl: './property-feature-slidein.component.html',
  styleUrls: ['./property-feature-slidein.component.scss'],
})
export class PropertyFeatureSlideinComponent
  extends ModalContentComponent<PropertyFeatureDetails, PropertyFeatureDetails>
  implements OnInit
{
  public propertySlideinForm: FormGroupManager<PropertyFeatureDetails>;

  public propertyTypeList: SelectableInputType[] = PROPERTY_LIST;
  public bankList: SelectableInputType[] = BANK_LIST;

  public propertyOwnershipType: SelectableInputType[] = PROPERTY_OWNERSHIP_TYPE;

  public currencyList: SelectableInputType[] = CURRENCY_LIST;
  public improvementsList: ImprovementTypes[] = IMPROVEMENTS_LIST;
  public improvementsTileSelcted: Boolean[] = [];

  public propertyDetailsList: PropertyFeatureDetails[] = [];
  public featureDetailsList: PropertyFeatureDetails[] = [];

  public isFeature: boolean = FALSE;
  public isNew: boolean = FALSE;
  public showBank: boolean = FALSE;
  public showAmountOwned: boolean = FALSE;
  public showAreaOfProperty: boolean = FALSE;
  public showYearOfConstruction: boolean = FALSE;
  public showSpecifications: boolean = FALSE;
  public showPropertiesIncluded: boolean = FALSE;
  public showImprovements: boolean = FALSE;
  public reportTypeList: PropertyTypes[] = [];
  public apiPropertyTypeList: PropertyTypes[] = [];
  public apiFeatureList: FeatureList[] = [];
  public apiCurrencyList: PolicySelectableType[] = [];
  public apiBankList: BankList[] = [];

  public nbRemainingCharacters: number;
  public nbRemainingCharactersLimit: number;

  public constructor(
    private readonly _formFactory: FormGroupManagerFactoryService,
    public control: ModalControl<PropertyFeatureDetails, PropertyFeatureDetails>,
    public readonly _propertyApiService: PropertyApiService,
  ) {
    super();
    this.propertySlideinForm = this._initializePropertySlideinForm();
  }

  public ngOnInit(): void {
    const data: PropertyFeatureDetails = this.control.input;
    if (null != data) {
      this.isFeature = data.isFeature || FALSE;
      this.propertyTypeList = this.isFeature ? FEATURE_LIST : PROPERTY_LIST;
      this.isNew = data.isNew || FALSE;
      this.showPropertiesIncluded = data.showPropertiesIncluded ?? FALSE;
      this.showBank = !this.isNew;
      this.showAmountOwned = !this.isNew;
      this.showAreaOfProperty = !this.isNew && this.isFeature;
      this.showYearOfConstruction = !this.isNew && this.isFeature;
      this.propertySlideinForm = this._initializePropertySlideinForm(data);

      this.propertyDetailsList = data.selectedPropertyAddressDetails?.propertyDetailsList ?? [];
      this.featureDetailsList = data.selectedPropertyAddressDetails?.featureDetailsList ?? [];
      this._propertyApiService.getPropertyTypes().subscribe(result => {
        this.apiPropertyTypeList = result;
      });
      this._propertyApiService.getFeatureList().subscribe((res: FeatureList[]) => {
        this.apiFeatureList = res;
      });
      this._propertyApiService
        .getPolicySelectableType(PolicySelectableTypesList.CURRENCY_LIST)
        .subscribe((res: PolicySelectableType[]) => {
          this.apiCurrencyList = res;
        });
      this._propertyApiService.getBankList().subscribe((res: BankList[]) => {
        this.apiBankList = res;
      });
      this._initializePropertyTyeList();
    }
  }

  private _initializePropertyTyeList(): void {
    const isApartmentAdded = this.propertyDetailsList.some(
      propertyDetails => propertyDetails.propertyType === APARTMENT,
    );
    const isVillaOrHouseAdded = this.propertyDetailsList.some(
      propertyDetails =>
        propertyDetails.propertyType === HOUSE || propertyDetails.propertyType === VILLA,
    );
    const isHouseholdPropertyAdded = this.propertyDetailsList.some(
      propertyDetails => propertyDetails.propertyType === HOUSEHOLD_PROPERTY,
    );

    if (isVillaOrHouseAdded) {
      this.propertyTypeList = this.propertyTypeList.filter(
        propertyType => propertyType.value !== APARTMENT,
      );
    } else if (isApartmentAdded) {
      this.propertyTypeList = this.propertyTypeList.filter(
        propertyType => propertyType.value !== (HOUSE || VILLA),
      );
    } else if (isHouseholdPropertyAdded) {
      if (1 === this.propertyDetailsList.length && this.isFeature) {
        this.propertyTypeList = this.propertyTypeList.filter(
          propertyType => propertyType.value === PIECES_OF_ART,
        );
      }
    }
  }

  private _initializePropertySlideinForm(
    formdata?: PropertyFeatureDetails,
  ): FormGroupManager<PropertyFeatureDetails> {
    return (this.propertySlideinForm = this._formFactory.createFormManager<PropertyFeatureDetails>({
      propertyType: new FormControl(
        formdata?.propertyType,
        // eslint-disable-next-line
        [Validators.required],
      ),
      areaOfProperty: new FormControl(
        formdata?.areaOfProperty,
        // eslint-disable-next-line
        [Validators.required, Validators.min(1)],
      ),
      yearOfConstruction: new FormControl(
        formdata?.yearOfConstruction,
        // eslint-disable-next-line
        [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1900)],
      ),
      specifications: new FormControl(
        formdata?.specifications,
        // eslint-disable-next-line
        [Validators.required],
      ),
      improvements: new FormControl(
        formdata?.improvements,
        // eslint-disable-next-line
        [Validators.required],
      ),
      isPropertyCoOwnedByBank: new FormControl(
        formdata?.isPropertyCoOwnedByBank ? YES : NO,
        // eslint-disable-next-line
        [Validators.required],
      ),
      bank: new FormControl(
        formdata?.bank,
        // eslint-disable-next-line
        [Validators.required],
      ),
      amountOwedToTheBank: new FormControl(formdata?.amountOwedToTheBank, [
        // eslint-disable-next-line
        Validators.required,
        // eslint-disable-next-line
        Validators.pattern(/\-?\d*\.?\d{1,2}/),
      ]),
      currency: new FormControl(
        this.currencyList[0].value,
        // eslint-disable-next-line
        Validators.required,
      ),
    }));
  }

  public updateRemainingCharactersLimit($event: number): void {
    this.nbRemainingCharactersLimit = $event;
  }

  public changeImprovementsSelection(i: number): void {
    //TODO
  }

  public addOrUpdatePropertyFeature(): void {
    const propertyDetails: PropertyFeatureDetails = this.propertySlideinForm.value;
    this.control.confirm(propertyDetails);
  }

  public isPropertyFeatureFormValid(): boolean {
    this._initializePropertyTyeList();

    if (
      this.propertySlideinForm.controls.isPropertyCoOwnedByBank.value &&
      NO === this.propertySlideinForm.controls.isPropertyCoOwnedByBank.value
    ) {
      this.propertySlideinForm.controls.bank.setErrors(null);
      this.propertySlideinForm.controls.amountOwedToTheBank.setErrors(null);
      this.propertySlideinForm.controls.currency.setErrors(null);
    }
    if (!this.isFeature) {
      this.propertySlideinForm.controls.specifications.setErrors(null);
      this.propertySlideinForm.controls.improvements.setErrors(null);
    } else if (this.isFeature) {
      if (this.showSpecifications) {
        this.propertySlideinForm.controls.areaOfProperty.setErrors(null);
        this.propertySlideinForm.controls.yearOfConstruction.setErrors(null);
        this.propertySlideinForm.controls.improvements.setErrors(null);
      } else if (this.showImprovements) {
        this.propertySlideinForm.controls.areaOfProperty.setErrors(null);
        this.propertySlideinForm.controls.yearOfConstruction.setErrors(null);
        this.propertySlideinForm.controls.specifications.setErrors(null);
      } else {
        this.propertySlideinForm.controls.specifications.setErrors(null);
        this.propertySlideinForm.controls.improvements.setErrors(null);
      }
    }
    return !this.propertySlideinForm.valid;
  }

  public cancel(): void {
    this.control.cancel();
  }

  public selectPropertyType(): void {
    // eslint-disable-next-line
    const selectedProperty: string = this.propertySlideinForm.controls.propertyType.value;
    if (null != selectedProperty) {
      this.propertySlideinForm.controls.isPropertyCoOwnedByBank.patchValue(NO);
      if (this.isFeature) {
        if (
          GARAGE_AS_A_PART_OF_THE_BUILDING === selectedProperty ||
          GARAGE_AS_A_SEPARATE_BUILDING === selectedProperty ||
          EXTERNAL_POOL === selectedProperty
        ) {
          this.showAreaOfProperty = TRUE;
          this.showYearOfConstruction = TRUE;
        } else {
          this.showAreaOfProperty = FALSE;
          this.showYearOfConstruction = FALSE;
          this.propertySlideinForm.controls.areaOfProperty.setErrors(null);
          this.propertySlideinForm.controls.yearOfConstruction.setErrors(null);

          if (SOLAR_THERMAL_COLLECTOR === selectedProperty) {
            this.showSpecifications = TRUE;
            this.showImprovements = FALSE;
          } else if (IMPROVEMENTS === selectedProperty) {
            this.showImprovements = TRUE;
            this.showSpecifications = FALSE;
          } else if (FENCE === selectedProperty || PIECES_OF_ART === selectedProperty) {
            this.showImprovements = FALSE;
            this.showSpecifications = FALSE;
          } else {
            this.showSpecifications = FALSE;
            this.showImprovements = FALSE;
            this.propertySlideinForm.controls.areaOfProperty.setErrors(null);
            this.propertySlideinForm.controls.yearOfConstruction.setErrors(null);
          }
        }
      } else {
        if (HOUSEHOLD_PROPERTY === selectedProperty) {
          this.showAreaOfProperty = FALSE;
          this.showYearOfConstruction = FALSE;
          this.propertySlideinForm.controls.areaOfProperty.setErrors(null);
          this.propertySlideinForm.controls.yearOfConstruction.setErrors(null);
        } else {
          this.showAreaOfProperty = TRUE;
          this.showYearOfConstruction = TRUE;
        }
      }
    }
  }

  public selectPropertyCoownershipType(): void {
    if (YES === this.propertySlideinForm.controls.isPropertyCoOwnedByBank.value) {
      this.showBank = TRUE;
      this.showAmountOwned = TRUE;
    } else {
      this.showBank = FALSE;
      this.showAmountOwned = FALSE;
      this.propertySlideinForm.controls.bank.setErrors(null);
      this.propertySlideinForm.controls.amountOwedToTheBank.setErrors(null);
      this.propertySlideinForm.controls.currency.setErrors(null);
    }
  }

  public getAmountOwedFieldErrors(): boolean {
    if (
      this.propertySlideinForm?.controls?.amountOwedToTheBank?.dirty &&
      this.propertySlideinForm?.controls?.amountOwedToTheBank?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getYearOfConstructionFieldErrors(): boolean {
    if (
      this.propertySlideinForm?.controls?.yearOfConstruction?.dirty &&
      this.propertySlideinForm?.controls?.yearOfConstruction?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getAreaOfPropertyFieldErrors(): boolean {
    if (
      this.propertySlideinForm?.controls?.areaOfProperty?.errors &&
      (this.propertySlideinForm?.controls?.areaOfProperty?.dirty ||
        this.propertySlideinForm?.controls?.areaOfProperty?.touched)
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getPropertyTypeFieldErrors(): boolean {
    if (
      this.propertySlideinForm?.controls?.propertyType?.dirty &&
      this.propertySlideinForm?.controls?.propertyType?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getBankFieldErrors(): boolean {
    if (
      this.propertySlideinForm?.controls?.bank?.dirty &&
      this.propertySlideinForm?.controls?.bank?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getImprovementsFieldErrors(): boolean {
    if (
      this.propertySlideinForm?.controls?.improvements?.dirty &&
      this.propertySlideinForm?.controls?.improvements?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getSpecificationsFieldErrors(): boolean {
    if (
      this.propertySlideinForm?.controls?.specifications?.dirty &&
      this.propertySlideinForm?.controls?.specifications?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }
}
