/* eslint-disable @typescript-eslint/unbound-method */
import {Component, OnInit} from '@angular/core';
import {ModalControl, ModalContentComponent} from '@maia/modals';
import {FormControl, Validators} from '@angular/forms';
import {FormGroupManagerFactoryService} from '@njf-frontend-angular/flow-progress';
import {Contact} from '../../../../contracts/contactsInterface';
import {FormData} from './form-data.model';

@Component({
  selector: 'nje-url-edit-slidein',
  templateUrl: './url-edit-slidein.component.html',
  styleUrls: ['./url-edit-slidein.component.scss'],
})
export class UrlEditSlideinComponent
  extends ModalContentComponent<Contact, Contact>
  implements OnInit
{
  public urlObj: Contact;

  public urlForm = this._formFactory.createFormManager<FormData>({
    url: new FormControl('', [Validators.required]),
  });

  public constructor(
    public control: ModalControl<Contact, Contact>,
    private readonly _formFactory: FormGroupManagerFactoryService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.urlObj = this.control.input;
  }

  public updateUrl(): void {
    this.urlObj.url = this.urlForm.value.url;
    this.control.confirm(this.urlObj);
  }

  public cancelSlideIn(): void {
    this.control.cancel();
  }
}
