import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

import {TransliterateService} from './translation/transliterate.service';

@Injectable({
  providedIn: 'root',
})
export class FieldPairTranslitControllerService {
  public constructor(private readonly transService: TransliterateService) {}

  public attach(
    source: AbstractControl,
    target: AbstractControl,
    destroyed$: Observable<any>,
    isVechRegNumber: boolean = false,
  ): void {
    target.valueChanges
      .pipe(
        filter(val => val === ''),
        takeUntil(destroyed$),
      )
      .subscribe(value => {
        target.reset();
      });

    source.valueChanges
      .pipe(
        filter(() => !target.dirty || !target.value),
        takeUntil(destroyed$),
      )
      .subscribe(value => {
        target.setValue(
          this.transService.trans({source: value, isVechicleRegNumber: isVechRegNumber}),
        );
      });
  }
}
