<header-with-breadcrumbs></header-with-breadcrumbs>
<div class="max-width-body">
  <maia-expanded-flow-progress class="m-t-24">
    <maia-expanded-flow-progress-step
      *ngFor="let step of steps$ | async"
      [title]="step.title"
      [routeId]="step.id"
      [route]="step.routePath"
    >
    </maia-expanded-flow-progress-step>
  </maia-expanded-flow-progress>

  <div class="screen-margin">
    <router-outlet></router-outlet>
  </div>
</div>
