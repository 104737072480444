<div class="b2b-kdl-font-small text-color m-b-24">Proposal</div>
<maia-price-block amount="200" currency="BGN">
  <svg class="icon-white-color" b2bFrontendTransportationExprCarNew maia-price-icon></svg>
  <div maia-price-title i18n="@@nje-b2b-frontend-casco-quick-proposal-title-casco"
    >Casco Insurance with Official Garage</div
  >
  <div maia-price-subtitle i18n="@@nje-b2b-frontend-casco-quick-proposal-title-tax"
    >Total Premium Amount with Tax</div
  >
</maia-price-block>
<div class="grey-boxes-div p-t-8">
  <label
    class="m-r-16 text-color b2b-kdl-font-small"
    i18n="@@nje-b2b-frontend-casco-quick-proposal-label-vehicleDetails"
  >
    Vehicle Details
  </label>
  <button maiaButton maiaButtonType="tertiary" (click)="editScreen(vehicleDetailsPath)"
    >Edit</button
  >
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationIndiVehicleLicensePlate></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          sublabel="Vehicle Registration Number"
        >
          <label class="b2b-kdl-font-small title-dark-color">
            {{ vehicleRegistrationNumber }}
          </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          sublabel="Vehicle Identification Number"
        >
          <label class="b2b-kdl-font-small title-dark-color">
            {{ vehicleIdentificationNumber }}
          </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <div></div>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprTransporter></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          sublabel="Type of Vehicle"
        >
          <label class="b2b-kdl-font-small title-dark-color"> {{ typeOfVehicle }}</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesFuncFactory></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Make">
          <label class="b2b-kdl-font-small title-dark-color">{{ make }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprCarNew></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Model">
          <label class="b2b-kdl-font-small title-dark-color">{{ model }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          sublabel="First Registration Date"
        >
          <label class="b2b-kdl-font-small title-dark-color">{{
            dateOfFirstRegistration | atlasDate
          }}</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprHayMaker></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          sublabel="Engine Power (in hp)"
        >
          <label class="b2b-kdl-font-small title-dark-color">{{ enginePower }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncClock></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Tonnage">
          <label class="b2b-kdl-font-small title-dark-color">{{ tonnage }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendSocialFuncPeopleDuo></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Seats">
          <label class="b2b-kdl-font-small title-dark-color">{{ seats }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
  <div></div>
  <div></div>
</div>
<div class="grey-boxes-div p-t-8">
  <label
    class="m-r-16 text-color b2b-kdl-font-small"
    i18n="@@nje-b2b-frontend-casco-quick-proposal-label-ownerDetails"
  >
    Owner Details
  </label>
  <button maiaButton maiaButtonType="tertiary" (click)="editScreen(ownerDetailsPath)">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Type of owner">
          <label class="b2b-kdl-font-small title-dark-color"> Person </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesExprHouseTree></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Age">
          <label class="b2b-kdl-font-small title-dark-color"> 32 </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendCommunicationFuncMail></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="City & Zip">
          <label class="b2b-kdl-font-small title-dark-color"> Sofia, 1000 </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-boxes-div p-t-8">
  <label
    class="m-r-16 text-color b2b-kdl-font-small"
    i18n="@@nje-b2b-frontend-casco-quick-proposal-label-covers"
  >
    Covers
  </label>
  <button maiaButton maiaButtonType="tertiary" (click)="onClick()">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncHandCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Sum Insured">
          <label class="b2b-kdl-font-small title-dark-color"> 20 000 BGN </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprTruckShield></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Main Cover">
          <label class="b2b-kdl-font-small title-dark-color"> Full Casco </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationExprTruckShield></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Extension Pack">
          <label class="b2b-kdl-font-small title-dark-color"> Premium + Abroad </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendAlertFuncQuestionCircle></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          sublabel="Are you adding security Devices?"
        >
          <label class="b2b-kdl-font-small title-dark-color"> No Device</label>
        </maia-label>
      </div>
    </maia-avatar-display>
    <div></div><div></div>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-boxes-div p-t-8">
  <label
    class="m-r-16 text-color b2b-kdl-font-small"
    i18n="@@nje-b2b-frontend-casco-quick-proposal-label-instalment"
  >
    Instalment
  </label>
  <button maiaButton maiaButtonType="tertiary" (click)="onClick()">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          sublabel="Instalment Type"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 4 Instalment</label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          sublabel="Total Premium Amount"
        >
          <label class="b2b-kdl-font-small title-dark-color"> 200 BGN </label>
        </maia-label>
      </div>
    </maia-avatar-display>
    <div></div>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-boxes-div p-t-8">
  <label
    class="m-r-16 text-color b2b-kdl-font-small"
    i18n="@@nje-b2b-frontend-casco-quick-proposal-label-discount"
  >
    Discount and Loadings
  </label>
  <button maiaButton maiaButtonType="tertiary" (click)="onClick()">Edit</button>
  <maia-fluid-fluid-layout class="m-t-12" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <label class="b2b-kdl-font-small title-dark-color"> Discount</label>
        <maia-additional-information
          openLinkText="View more"
          closeLinkText="View less"
          maiaContentType="content"
          collapsible
        >
          <div class="m-t--13">
            <p>
              Valid MTPL Discount - 5%<br />
              New insured Vehicle- 3%<br />
              New Registered Vehicle - 8%<br />
              Owner Aged above 45<br />
              Regional Discount<br />
              Bonus Malus<br />
            </p>
          </div>
        </maia-additional-information>
      </div>
    </maia-avatar-display>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color">
          <label class="b2b-kdl-font-small title-dark-color"> Loadings</label>
        </maia-label>
        <maia-additional-information
          openLinkText="View more"
          closeLinkText="View less"
          maiaContentType="content"
          collapsible
        >
          <div class="m-t--13">
            <p>
              Unlimited Written Event Declaration<br />
              Risky Make and Models<br />
              Age of the Owner<br />
              Bonus Malus
            </p>
          </div>
        </maia-additional-information>
      </div>
    </maia-avatar-display>
    <div></div>
  </maia-fluid-fluid-layout>
  <p class="text-2">The sum of discounts is limited to 25%.</p>
</div>

<div class="light-blue-box">
  <p class="b2b-kdl-font-small text-color"
    >The calculated premium is indicative. In order to receive a correct final premium, with more
    detailed data, navigate to price offer.
  </p>
</div>
<maia-divider class="m-t-b-24"></maia-divider>
<div class="button-align m-l-24">
  <button
    maiaButton
    maiaButtonType="primary"
    class="button1-size m-r-24"
    (click)="onClick()"
    i18n="@@nje-b2b-frontend-casco-quick-proposal-btn-createquote"
    >Create Price Offer</button
  >
  <button
    maiaButton
    maiaButtonType="secondary"
    (click)="onClick()"
    class="m-r-24 button2-size"
    i18n="@@nje-b2b-frontend-casco-quick-proposal-btn-downloadquote"
    >Download Quote</button
  >
  <button
    maiaButton
    class="button3-size"
    maiaButtonType="secondary"
    (click)="openEmailPopUp()"
    i18n="@@nje-b2b-frontend-casco-quick-proposal-btn-emailquote"
    >Email Quote</button
  >
</div>
