<header-with-breadcrumbs title="Travel"></header-with-breadcrumbs>
<div class="max-width-body">
  <div class="m-t-24" *ngIf="isStepperVisible">
    <maia-expanded-flow-progress *ngIf="!(isEditing | async)">
      <maia-expanded-flow-progress-step
        *ngFor="let step of steps | async"
        [title]="step.title"
        [routeId]="step.id"
        [route]="step.routePath"
        [done]="travelSessionService.isStepDone(step.id)"
      >
      </maia-expanded-flow-progress-step>
    </maia-expanded-flow-progress>
  </div>
  `
  <div class="screen-margin">
    <router-outlet></router-outlet>
    <njf-covers-accordion
      *ngIf="travel.showHideAccordian | async"
      [totalInsuranceAmount]="insurancePremiumAmount"
      [title]="title"
      [Items]="items"
    ></njf-covers-accordion>
  </div>
</div>
