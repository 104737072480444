<form maiaForm [formGroup]="contactsForm.formGroup">
  <div formArrayName="contacts">
    <div *ngFor="let contact of contacts.controls; let i = index">
      <div [formGroup]="contact">
        <maia-form-group class="blue-box">
          <div *ngIf="ContactType()">
            <maia-label
              i18n="@@nje-b2b-frontend-resource-contacts-contacttitle"
              class="b2b-kdl-font-small contact-alignment"
            >
              Contact title
            </maia-label>
            <maia-form-element class="slidein-input-column add-contact-input">
              <maia-text-area-limit [maxLength]="titleLimit">
                <textarea
                  name="title"
                  maiaTextArea
                  [atlasMaxLength]="titleLimit"
                  placeholder="Enter title here"
                  i18n-placeholder="@@nje-b2b-frontend-resource-contacts-entertitle"
                  formControlName="title"
                  hideOptionalIndicator
                >
                </textarea>
              </maia-text-area-limit>
            </maia-form-element>
          </div>
          <div *ngIf="!ContactType()">
            <maia-label
              i18n="@@nje-b2b-frontend-resource-contacts-incidenttitle"
              class="b2b-kdl-font-small contact-alignment"
              >Incident title
            </maia-label>
            <maia-form-element class="slidein-input-column add-contact-input">
              <maia-text-area-limit [maxLength]="titleLimit">
                <textarea
                  name="title"
                  maiaTextArea
                  [atlasMaxLength]="titleLimit"
                  placeholder="Enter title here"
                  i18n-placeholder="@@nje-b2b-frontend-resource-contacts-entertitle"
                  formControlName="title"
                  hideOptionalIndicator
                ></textarea>
              </maia-text-area-limit>
            </maia-form-element>
          </div>
          <div *ngIf="ContactType()">
            <maia-label
              i18n="@@nje-b2b-frontend-resource-contacts-contactnumber"
              class="b2b-kdl-font-small contact-alignment"
              >Contact number
            </maia-label>
            <maia-form-element
              [options]="{validationInstant: true}"
              class="slidein-input-column add-contact-input"
              hideOptionalIndicator
            >
              <maia-input-phonenumber
                name="phone"
                placeholder="Phone number"
                i18n-placeholder="@@nje-b2b-frontend-resource-contacts-phnum"
                formControlName="phoneNumber"
                #phoneInput
                class="disable-country-code"
              >
                <maia-phonenumber-country prefix="+359" name="Bulgaria">
                  <ng-template #flag>
                    <svg b2bFrontendFlagUtilBulgaria></svg>
                  </ng-template>
                </maia-phonenumber-country>
              </maia-input-phonenumber>
              <div *ngIf="contact.get('phoneNumber').errors && contact.get('phoneNumber').touched">
                <maia-validation
                  *ngIf="contact.get('phoneNumber').hasError('required')"
                  i18n="@@nje-b2b-frontend-resource-contacts-phonerequired"
                  >Phone required</maia-validation
                >
                <maia-validation
                  *ngIf="contact.get('phoneNumber').hasError('invalid')"
                  i18n="@@nje-b2b-frontend-resource-contacts-phoneinvalid"
                  >Phone invalid</maia-validation
                >
              </div>
            </maia-form-element>
          </div>
          <div *ngIf="!ContactType()">
            <maia-label
              i18n="@@nje-b2b-frontend-resource-contacts-reporting"
              class="b2b-kdl-font-small contact-alignment"
            >
              Reporting address
            </maia-label>
            <maia-form-element
              [options]="{validationInstant: true}"
              class="slidein-input-column add-contact-input"
            >
              <input
                maiaInput
                atlasEmailAddress
                name="email"
                placeholder="Enter mail address here"
                i18n-placeholder="@@nje-b2b-frontend-resource-contacts-address"
                formControlName="email"
                hideOptionalIndicator
              />
              <div *ngIf="contact.get('email').errors && contact.get('email').touched">
                <maia-validation
                  *ngIf="contact.get('email').hasError('required')"
                  i18n="@@nje-b2b-frontend-resource-contacts-emailrequired"
                  >Email required</maia-validation
                >
                <maia-validation
                  *ngIf="
                    contact.get('email').hasError('atlasInvalidLocalPart') ||
                    contact.get('email').hasError('atlasInvalidDomain') ||
                    contact.get('email').hasError('atlasInvalidMultipleAt')
                  "
                  i18n="@@nje-b2b-frontend-resource-contacts-emailformat"
                  >Enter your e-mail address in the following format:
                  name@domain.com</maia-validation
                >
              </div>
            </maia-form-element>
          </div>

          <maia-inline-action
            class="remove_text"
            *ngIf="!isNewContact()"
            id="inline-action-2"
            type="button"
            role="button"
            (click)="removeAddedContact(i)"
            i18n="@@nje-b2b-frontend-resource-contacts-remove"
          >
            Remove
          </maia-inline-action>
        </maia-form-group>
      </div>
    </div>
  </div>
</form>
<div class="add-contact" *ngIf="!isNewContact()">
  <div class="add-contact-icon">
    <svg b2bFrontendActionFuncPlusCircle></svg>
  </div>
  <div class="add-contact-button">
    <button maiaButton maiaButtonType="tertiary" (click)="addAnotherContact()">{{
      addContactLiteral | localeVal: lang
    }}</button>
  </div>
</div>
<div class="step-form-button-bar">
  <maia-button-group style="text-align: center">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      (click)="addContact()"
      [disabled]="!contactsForm.valid || contacts.length === 0"
      i18n="@@nje-b2b-frontend-resource-contacts-submit"
      >Submit</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancelSlideIn()"
      i18n="@@nje-b2b-frontend-resource-contacts-cancel"
      >Cancel</button
    >
  </maia-button-group>
</div>
