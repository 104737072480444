/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  ADDITIONAL_COVERS_INFO_TABLE_COL_HEADERS2,
  PROPERTY_COVERS_INFO_TABLE_COL_HEADERS,
} from './__tests__/property-covers-discount-layout.mocks';
import {PopUpController} from '@maia/pop-ups';
import {RemovePopupComponent} from '../remove-popup/remove-popup.component';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {
  AddAdditionalCoversSlideInInput,
  AdditionalCoversAddressLevelInterface,
  AdditionalCoversSlideInInput,
  CoversDiscountResponse,
  PopUpContent,
  PropertyCoversInformation,
} from '../../contracts/property-insurance.interface';
import {
  ADDITIONAL_COVERS,
  ADD_ADDITIONAL_COVER,
  ADD_COVER,
  EDIT_ADDITIONAL_COVER,
  EDIT_COVER,
  PROPERTY_COVERS,
  REMOVE_PROPERTY_COVERS_CONFIRMATION_MSG,
  WARNING,
  FALSE,
  SOS_HOME,
  REMOVE_PROPERTY_COVERS_ADDITIONA_MSG_AFTER_PREMIUM_CALCULATION,
  DEFAULT_PREMIUM_AMOUNT,
} from '../../constants/property-insurance-constants';
import {SlideInController} from '@maia/slide-ins';
import {PropertyCoversAddEditSlideinComponent} from '../property-covers-add-edit-slidein/property-covers-add-edit-slidein.component';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {PropertyAddressEditSlideinComponent} from '../property-address-edit-slidein/property-address-edit-slidein.component';
import {BLANK_VALUE, InsurancePartyDetails} from '@b2b-frontend/core';
import {CoversAddSlideinComponent} from '../covers-add-slidein/covers-add-slidein.component';
import {PROPERTY_COVERS_INFO_LIST2} from '../property-covers-and-discounts/__tests__/property-covers-and-discounts.mocks';

@Component({
  selector: 'nje-property-cover-discount-layout',
  templateUrl: './property-cover-discount-layout.component.html',
  styleUrls: ['./property-cover-discount-layout.component.scss'],
})
@UntilDestroy()
export class PropertyCoverDiscountLayoutComponent implements OnInit {
  public additionalCoversColumnHeaders: string[] = ADDITIONAL_COVERS_INFO_TABLE_COL_HEADERS2;
  public propertyCoversColumnHeaders: string[] = PROPERTY_COVERS_INFO_TABLE_COL_HEADERS;
  public additionalCovers: string = ADDITIONAL_COVERS;
  public propertyCovers: string = PROPERTY_COVERS;
  public removeCoverMsg: PopUpContent = {
    title: WARNING,
    message: REMOVE_PROPERTY_COVERS_CONFIRMATION_MSG,
    footerNote: BLANK_VALUE,
  };

  public sosHomeValue: string = SOS_HOME;

  public addressInfo1: InsurancePartyDetails;
  public existingcoversList: AdditionalCoversAddressLevelInterface[] = [];
  public addAdditionalCoverInput: AddAdditionalCoversSlideInInput = {
    existingPropertyCover: [],
    propertyCoverInfo: {},
    currencySelected: BLANK_VALUE,
    defaultObj: [],
  };

  public coversDiscountResponse: CoversDiscountResponse = {
    additionalCoversInfo: [],
    propertyCoversInfo: [],
  };

  public addAdditionalCoverInput1: AdditionalCoversSlideInInput = {
    existingPropertyCover: [],
    propertyCoverInfo: PROPERTY_COVERS_INFO_LIST2.data[0],
    currencySelected: BLANK_VALUE,
  };

  @Input() public additionalCoverList: AdditionalCoversAddressLevelInterface[] = [];
  @Input() public additionalCoversInfoList: AdditionalCoversAddressLevelInterface[] = [];
  @Input() public propertyCoversInfoList: PropertyCoversInformation[] = [];
  @Input() public propertyAddress: InsurancePartyDetails;
  @Input() public premiumAmountVisibility: boolean = FALSE;
  @Input() public currencySelected: string = BLANK_VALUE;

  @Input() public isProposalScreen: boolean = FALSE;
  @Input() public isEditMode: boolean = FALSE;

  private readonly _popUpDeleteFactory: ComponentFactory<RemovePopupComponent>;
  private readonly _slideInOneFactory: ComponentFactory<PropertyCoversAddEditSlideinComponent>;
  private readonly _slideInEditAddress: ComponentFactory<PropertyAddressEditSlideinComponent>;
  private readonly _slideInAddCovers: ComponentFactory<CoversAddSlideinComponent>;

  public constructor(
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _slideInCtrl: SlideInController,
  ) {
    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(RemovePopupComponent);
    this._slideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      PropertyCoversAddEditSlideinComponent,
    );
    this._slideInEditAddress = this._componentFactoryResolver.resolveComponentFactory(
      PropertyAddressEditSlideinComponent,
    );
    this._slideInAddCovers =
      this._componentFactoryResolver.resolveComponentFactory(CoversAddSlideinComponent);
  }

  // eslint-disable-next-line
  public async ngOnInit(): Promise<void> {
    if (this.premiumAmountVisibility) {
      this.additionalCoversInfoList.forEach(cover => {
        cover.premiumAmount = DEFAULT_PREMIUM_AMOUNT;
      });
      this.propertyCoversInfoList.forEach(cover => {
        cover.premiumAmount = DEFAULT_PREMIUM_AMOUNT;
      });
    }
    this.coversDiscountResponse.additionalCoversInfo = this.additionalCoversInfoList;
    this.coversDiscountResponse.propertyCoversInfo = this.propertyCoversInfoList;
  }

  // eslint-disable-next-line
  public removeCover(index: number, coverType: string): void {
    this.removeCoverMsg.message =
      this.additionalCoversInfoList[index].premiumAmount === undefined
        ? this.removeCoverMsg.message
        : this.removeCoverMsg.message +
          REMOVE_PROPERTY_COVERS_ADDITIONA_MSG_AFTER_PREMIUM_CALCULATION;
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: WARNING, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.removeCoverMsg,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          if (null != index && 0 <= index) {
            if (coverType === this.additionalCovers) {
              if (this.additionalCoversInfoList[index].coverName === this.sosHomeValue) {
                this.additionalCoverList[index].default = false;
              }
              this.additionalCoversInfoList.splice(index, 1);
            } else {
              this.propertyCoversInfoList.splice(index, 1);
            }
          } else {
            if (coverType === this.additionalCovers) {
              this.additionalCoversInfoList = [];
            } else {
              this.propertyCoversInfoList = [];
            }
          }
        }
        this._cdRef.detectChanges();
      });
  }

  public addAdditionalCover(coversInfo?: AdditionalCoversAddressLevelInterface): void {
    const slideinTitle = coversInfo ? EDIT_ADDITIONAL_COVER : ADD_ADDITIONAL_COVER;
    if (this.additionalCoversInfoList.length > 0) {
      this.existingcoversList = [];
      this.additionalCoversInfoList.forEach(element => {
        this.existingcoversList?.push(element);
      });
    }
    this.addAdditionalCoverInput.existingPropertyCover = this.existingcoversList!;
    this.addAdditionalCoverInput.propertyCoverInfo = coversInfo!;
    this.addAdditionalCoverInput.currencySelected = this.currencySelected;
    this.addAdditionalCoverInput.defaultObj = this.additionalCoverList;
    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.addAdditionalCoverInput,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<AdditionalCoversAddressLevelInterface>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          const newCoverAdded = response.result;
          newCoverAdded.aggregateLimit = response.result.aggregateLimit;
          newCoverAdded.limitPerOccurence = response.result.limitPerOccurence;
          if (coversInfo === undefined || coversInfo === null) {
            newCoverAdded.id = this.additionalCoversInfoList.length;
            this.additionalCoversInfoList.push(newCoverAdded);
            if (newCoverAdded.coverName === this.sosHomeValue) {
              this.additionalCoverList.forEach(ele => {
                if (ele.coverName === this.sosHomeValue) {
                  ele.default = true;
                }
              });
            }
          } else {
            this.additionalCoversInfoList.forEach(ele => {
              if (ele.coverName === coversInfo.coverName) {
                ele.aggregateLimit = newCoverAdded.aggregateLimit;
                ele.limitPerOccurence = newCoverAdded.limitPerOccurence;
                ele.coverName = newCoverAdded.coverName;
              }
            });
          }
          this.coversDiscountResponse.additionalCoversInfo = this.additionalCoversInfoList;
          this._cdRef.detectChanges();
        }
      });
  }

  public editPropertyAddress(propertyAddress?: InsurancePartyDetails): void {
    const slideinTitle = EDIT_COVER;
    this._slideInCtrl
      .prepare(
        this._slideInEditAddress,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: propertyAddress,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<InsurancePartyDetails>) => {
        const proprtyAddressReturned: InsurancePartyDetails = response.result;
        proprtyAddressReturned.cityEn = this.propertyAddress.cityEn;
        proprtyAddressReturned.phoneNumber = this.propertyAddress.phoneNumber;
        this.propertyAddress = proprtyAddressReturned;
        this._cdRef.detectChanges();
      });
  }

  public addEditCover(propertyDetails: PropertyCoversInformation, index: number): void {
    this.openPropertySlideIn(propertyDetails, index);
  }

  public openPropertySlideIn(propertyDetails: PropertyCoversInformation, index?: number): void {
    this.addAdditionalCoverInput1.propertyCoverInfo = propertyDetails;
    this.addAdditionalCoverInput1.currencySelected = this.currencySelected;
    const slideinTitle = propertyDetails.basisOfInsurance === undefined ? ADD_COVER : EDIT_COVER;
    this._slideInCtrl
      .prepare(
        this._slideInAddCovers,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.addAdditionalCoverInput1,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<PropertyCoversInformation>) => {
        if (res.resolution === ModalResolution.CONFIRMED) {
          const propertyData: PropertyCoversInformation = res.result;
          if (null != res && null != index) {
            this.updatePropertyDetails(propertyData, index);
          } else {
            this.updatePropertyDetails(propertyData);
          }
          this.coversDiscountResponse.propertyCoversInfo = this.propertyCoversInfoList;
        }
        this._cdRef.detectChanges();
      });
  }

  public deleteIconDisplayOfCovers(): boolean {
    if (this.additionalCoversInfoList.some(covers => covers.coverName === this.sosHomeValue)) {
      return this.additionalCoversInfoList.length === 4 ? false : true;
    } else {
      return true;
    }
  }

  public updatePropertyDetails(propertyDetails: PropertyCoversInformation, index?: number): void {
    if (null != propertyDetails && null != index) {
      this.propertyCoversInfoList[index] = propertyDetails;
    } else {
      this.propertyCoversInfoList.push(propertyDetails);
    }
  }
}
