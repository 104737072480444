import {Component} from '@angular/core';

import {RoutePaths} from '../../constants/route-paths';
import {AuthNavigationService} from '../../services/auth-navigation.service';

@Component({
  selector: 'nje-setup-google',
  templateUrl: './setup-google.component.html',
  styleUrls: ['./setup-google.component.scss'],
})
export class SetupGoogleComponent {
  public constructor(private readonly _AuthNavigationService: AuthNavigationService) {}

  public onContinue(): void {
    this._AuthNavigationService.nextScreen(RoutePaths.VerifyAuth);
  }

  public scanContinue(): void {
    this._AuthNavigationService.nextScreen(RoutePaths.VerifyScan);
  }
}
