import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {Date as AtlasDate, Decimal} from '@atlas/businesstypes';
import {LabelEditDto} from '@b2b-frontend/core';
import {
  BLANK_VALUE,
  COVERS_PATH,
  DISCOUNT_LOADING_PATH,
  INSTALMENT_PATH,
  OWNER_DETAILS_PATH,
  VEHICLE_DETAILS_PATH,
} from '../../constants/proposal-constants';
import {ScreenFormComponent} from '@njf-frontend-angular/flow-progress';
import {ActivatedRoute} from '@angular/router';
import {PopUpController} from '@maia/pop-ups';
import {EmailPopupComponent} from '../email-popup/email-popup.component';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';
import {
  CascoSessionDTO,
  QuickVehicleAndOwnerSessionDto,
} from '../../contracts/casco-session-interface';
import {CascoSessionService} from '../../casco-core/services/casco-session.service';

@Component({
  selector: 'nje-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss'],
})
@UntilDestroy()
export class ProposalComponent extends ScreenFormComponent implements OnInit {
  public clicked: boolean = false;
  private readonly _emailPopupFactory: ComponentFactory<EmailPopupComponent>;

  public vehicleDetailsPath: LabelEditDto = VEHICLE_DETAILS_PATH;
  public ownerDetailsPath: LabelEditDto = OWNER_DETAILS_PATH;
  public coversPath: LabelEditDto = COVERS_PATH;
  public instalmentPath: LabelEditDto = INSTALMENT_PATH;
  public discountAndLoadingsPath: LabelEditDto = DISCOUNT_LOADING_PATH;

  public vehicleRegistrationNumber: string;
  public vehicleIdentificationNumber: string;
  public typeOfVehicle: string;
  public make: string;
  public model: string;
  public dateOfFirstRegistration: AtlasDate;
  public enginePower: string;
  public tonnage: string;
  public seats: string;

  public constructor(
    route: ActivatedRoute,
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _process: CascoProcessService,
    private readonly _cascoSessionService: CascoSessionService,
  ) {
    super(route);
    this._emailPopupFactory =
      this.componentFactoryResolver.resolveComponentFactory(EmailPopupComponent);
  }

  public ngOnInit(): void {
    this.getSessionData();
  }

  private getSessionData(): void {
    const cascoSessionData: CascoSessionDTO = this._cascoSessionService.getCascoSessionData();
    if (cascoSessionData.quickVehicleAndOwnerDto) {
      this.showVehicleDetails(cascoSessionData.quickVehicleAndOwnerDto);
    }
  }

  private showVehicleDetails(vehicleData: QuickVehicleAndOwnerSessionDto): void {
    this.vehicleRegistrationNumber = vehicleData.vehicleRegistrationNumber
      ? vehicleData.vehicleRegistrationNumber
      : BLANK_VALUE;
    this.vehicleIdentificationNumber = vehicleData.vehicleIdentificationNumber
      ? vehicleData.vehicleIdentificationNumber
      : BLANK_VALUE;
    this.typeOfVehicle = vehicleData.typeOfOwner;
    this.make = vehicleData.make;
    this.model = vehicleData.model;
    this.dateOfFirstRegistration = vehicleData.dateOfFirstRegistration;
    this.enginePower = vehicleData.enginePower ? vehicleData.enginePower : BLANK_VALUE;
    this.tonnage = vehicleData.tonnage ? vehicleData.tonnage : BLANK_VALUE;
    this.seats = vehicleData.seats ? vehicleData.seats : BLANK_VALUE;
  }

  public editScreen(screenDetail: LabelEditDto): void {
    const step = this._process.getStepByRoute(screenDetail.navigationPath);
    if (step) {
      step.edit(this.step);
    }
  }

  public getAmoutValue(amount: string): Decimal {
    return new Decimal(amount);
  }

  // To do
  public onClick(): void {
    this.clicked = true;
  }

  public openEmailPopUp(): void {
    this._popUpCtrl
      .prepare(
        this._emailPopupFactory,
        this._injector,
        {title: BLANK_VALUE, size: 'large'},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  protected saveMtplData(): void {
    this.clicked = true;
  }
}
