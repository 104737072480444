import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AfiExprCardStackedSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendAfiExprCardStacked]',
  template: '<svg:path fill="none" stroke="#009C6D" stroke-width="1.6" stroke-linecap="round" stroke-miterlimit="10" d="M16.34 36.77c0 1.1.9 2 2 2h22.68c1.1 0 2-.9 2-2h0v-14.2c0-1.1-.9-2-2-2h0"></svg:path><svg:path fill="none" stroke="#F58220" stroke-width="1.6" stroke-linecap="round" stroke-miterlimit="10" d="M10.7 31.11c0 1.1.9 2 2 2 0 0 0 0 0 0h22.68c1.1 0 2-.9 2-2h0v-14.2c0-1.1-.9-2-2-2h0"></svg:path><svg:path fill="none" stroke="#C30045" stroke-width="1.6" stroke-miterlimit="10" d="M6.98 9.23c-1.1 0-2 .9-2 2h0v14.2c0 1.1.9 2 2 2h22.7c1.1 0 2-.9 2-2h0V11.27c0-1.1-.9-2-2-2h0l-22.7-.04z"></svg:path>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AfiExprCardStackedSvgComponent {
}

@NgModule({
  declarations: [
    AfiExprCardStackedSvgComponent,
  ],
  exports: [
    AfiExprCardStackedSvgComponent,
  ],
})
export class AfiExprCardStackedSvgModule {}
