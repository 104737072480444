import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  ViewChild,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Boolean} from '@atlas/businesstypes';
import {
  InsurancePartyDetails,
  InsurancePartyDetailsComponent,
  InsurancePartyDto,
  InsuringPartyDto,
  PartyType,
  PropertyInsuranceProposalDto,
  PAGINATION,
  TRUE,
} from '@b2b-frontend/core';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {SlideInController} from '@maia/slide-ins';
import {FormGroupManager, ScreenStepComponent} from '@njf-frontend-angular/flow-progress';

import {
  ADD_INSURED_PARTY,
  BLANK_VALUE,
  FALSE,
  REMOVE_INSURED_PARTY_CONFIRMATION_MSG,
  WARNING,
} from '../../constants/property-insurance-constants';
import {InsuredPartyListDetails, PopUpContent} from '../../contracts/property-insurance.interface';
import {PropertySessionService} from '../../property-core/services/property-session.service';
import {PropertyInsuredPartySlideinComponent} from '../property-insured-party-slidein/property-insured-party-slidein.component';
import {RemovePopupComponent} from '../remove-popup/remove-popup.component';
import {
  INSURANCE_PARTY_DETAILS_LIST,
  INSURED_PARTY_INFO_TABLE_COL_HEADERS,
} from './__tests__/property-insuring-party.mock';

@Component({
  selector: 'property-owner',
  templateUrl: './property-insuring-party.component.html',
  styleUrls: ['./property-insuring-party.component.scss'],
})
@UntilDestroy()
export class PropertyInsuringPartyComponent extends ScreenStepComponent implements AfterViewInit {
  public insuringPartyType: PartyType | undefined;
  public insurancePartyDto: InsuringPartyDto;

  public columnHeaders: string[] = INSURED_PARTY_INFO_TABLE_COL_HEADERS;
  public insurancePartyDetailsList: InsurancePartyDetails[] = INSURANCE_PARTY_DETAILS_LIST.data;
  public insurancePartyDetailsForm: FormGroupManager<InsurancePartyDetails>;
  public isAddedToInsuredPartyList = Boolean.FALSE;
  public isAddedToInsuredPartyDisabled = Boolean.TRUE;

  //slideIn
  private readonly _slideInOneFactory: ComponentFactory<PropertyInsuredPartySlideinComponent>;
  private readonly _popUpDeleteFactory: ComponentFactory<RemovePopupComponent>;

  // search bar
  public selection: any;

  // pagination
  public page = PAGINATION.DEFAULT_PAGE;
  public pages = this.insurancePartyDetailsList.length;
  public pageSize = PAGINATION.PAGE_SIZE;

  public lastPage(): boolean {
    return this.page >= this.pages;
  }

  public pageChange(page: number): void {
    console.log('TODO');
    //this.getReportDetails(this.reportFetchMode.PAGINATION, page);
  }

  private _getTotalPagesCount(totalRecords: number): number {
    return totalRecords % this.pageSize === 0
      ? totalRecords / this.pageSize
      : Math.ceil(totalRecords / this.pageSize);
  }

  @ViewChild(InsurancePartyDetailsComponent)
  public insurancePartyDetailsComponent: InsurancePartyDetailsComponent;

  public constructor(
    route: ActivatedRoute,
    private readonly _slideInCtrl: SlideInController,
    private readonly _popUpCtrl: PopUpController,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _propertySessionService: PropertySessionService,
  ) {
    super(route);

    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(RemovePopupComponent);
    this._slideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      PropertyInsuredPartySlideinComponent,
    );
  }

  public ngAfterViewInit(): void {
    this.insurancePartyDetailsForm = this.insurancePartyDetailsComponent.getInsurancePartyDetails();

    // If it is edit mode then fetch data from the session service if any and populate the screen data accordingly
    const proposalDto: PropertyInsuranceProposalDto =
      this._propertySessionService.getPropertyData();
    if (null != proposalDto) {
      if (
        null != proposalDto.insuringPartyDto &&
        null != proposalDto.insuringPartyDto.insurancePartyDetails
      ) {
        this.insurancePartyDetailsComponent.setInsurancePartyDetails(
          proposalDto.insuringPartyDto.insurancePartyDetails,
        );
      }
      if (null != proposalDto.insuredPartyDetailsList) {
        this.insurancePartyDetailsList = proposalDto.insuredPartyDetailsList;
      }
    }
  }

  public QuarterandStreetValid(): boolean {
    if (
      this.insurancePartyDetailsForm?.controls?.quarterEn.value === undefined &&
      this.insurancePartyDetailsForm?.controls?.streetEn.value === undefined &&
      this.insurancePartyDetailsForm?.controls?.streetNumberEn.value === undefined
    ) {
      return true;
    } else return FALSE;
  }

  protected saveMtplData(): void {}

  public isInsurancePartyInfoValid(): boolean {
    if (null != this.insurancePartyDetailsComponent) {
      this.insurancePartyDetailsForm =
        this.insurancePartyDetailsComponent.getInsurancePartyDetails();

      if (null != this.insurancePartyDetailsForm.controls.phoneNumber.value) {
        this.insurancePartyDetailsForm?.controls?.phoneNumber?.setErrors(null);
      }
      return this.insurancePartyDetailsForm.valid;
    } else return FALSE;
  }

  public saveInsuranceData(): void {
    if (null != this.insurancePartyDetailsForm) {
      const insurancePartyDetails: InsurancePartyDetails = this.insurancePartyDetailsForm.value;
      const insuringPartyObj: InsurancePartyDto = {
        partyType: this.insuringPartyType,
        insurancePartyDetails: insurancePartyDetails,
      };
      this._propertySessionService.setInsuringPartyDto(insuringPartyObj);
      this._propertySessionService.setInsuredPartyDetailsList(this.insurancePartyDetailsList);
      this.saveAndContinue();
    }
  }

  public removePerson(index: number): void {
    const removePopupMsgObj: PopUpContent = {
      title: WARNING,
      message: REMOVE_INSURED_PARTY_CONFIRMATION_MSG,
      footerNote: BLANK_VALUE,
    };

    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: 'Warning', footerInScrollArea: true, size: 'large'},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: removePopupMsgObj,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          if (null != index && 0 <= index) {
            const selectedInsuredPartyDetails = this.insurancePartyDetailsList[index];
            this.insurancePartyDetailsList.splice(index, 1);
            selectedInsuredPartyDetails.isInsuringParty
              ? (this.isAddedToInsuredPartyList = Boolean.FALSE)
              : (this.isAddedToInsuredPartyList = Boolean.TRUE);
          } else {
            this.insurancePartyDetailsList = [];
          }
        }
        this._cdRef.detectChanges();
      });
  }

  public addNewPerson(): void {
    const insuredPartyListDetails: InsuredPartyListDetails = {
      insurancePartyDetailsList: this.insurancePartyDetailsList,
      index: -1,
    };
    this.openInsuredPersonSlideIn(insuredPartyListDetails);
  }

  public editPerson(insurancePartyDetails: InsurancePartyDetails, index: number): void {
    const insuredPartyListDetails: InsuredPartyListDetails = {
      insurancePartyDetailsList: this.insurancePartyDetailsList,
      index: index,
    };
    this.openInsuredPersonSlideIn(insuredPartyListDetails);
  }

  public updateInsuredPersonDetails(
    insurancePartyDetails: InsurancePartyDetails,
    index?: number,
  ): void {
    if (null != insurancePartyDetails && null != index) {
      this.insurancePartyDetailsList[index] = insurancePartyDetails;
    } else {
      this.insurancePartyDetailsList.push(insurancePartyDetails);
      this.pages = this._getTotalPagesCount(this.insurancePartyDetailsList.length);
    }
  }

  // slideIn
  public openInsuredPersonSlideIn(insuredPartyListDetails?: InsuredPartyListDetails): void {
    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {title: ADD_INSURED_PARTY},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: insuredPartyListDetails,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<InsuredPartyListDetails>) => {
        if (res.resolution === ModalResolution.CONFIRMED) {
          const insuredPartyListDetails = res.result;
          const index = insuredPartyListDetails.index;
          const insurancePartyDetailsList = insuredPartyListDetails.insurancePartyDetailsList;
          const arrayLength = insurancePartyDetailsList.length;

          if (null != insurancePartyDetailsList && null != index) {
            this.updateInsuredPersonDetails(insurancePartyDetailsList[index], index);
          } else {
            this.updateInsuredPersonDetails(insurancePartyDetailsList[arrayLength - 1]);
          }
        }
        this._cdRef.detectChanges();
      });
  }

  public addToInsuredPartyList(): void {
    console.log(this.isAddedToInsuredPartyList.asBoolean());
    if (this.insurancePartyDetailsForm.valid && this.isAddedToInsuredPartyList.asBoolean()) {
      const insurancePartyDetails: InsurancePartyDetails = this.insurancePartyDetailsForm?.value;
      // Marking this record as Insuring Party and inserting it at the start of the array
      insurancePartyDetails.isInsuringParty = TRUE;
      this.insurancePartyDetailsList.splice(0, 0, insurancePartyDetails);
      this.pages = this._getTotalPagesCount(this.insurancePartyDetailsList.length);
    }
    !this.isAddedToInsuredPartyList.asBoolean()
      ? this.insurancePartyDetailsList.splice(0, 1)
      : BLANK_VALUE;
    this._cdRef.detectChanges();
  }
}
