import {PropertyRoutePaths} from '@b2b-frontend/core';
import {PropertyWorkflow} from './property-workflow';

export const workflows: {[name: string]: string[]} = {
  [PropertyWorkflow.PropertyHomePolicy]: [
    PropertyRoutePaths.PROPERTY_POLICY_DETAILS,
    PropertyRoutePaths.PROPERTY_INSURING_PARTY,
    PropertyRoutePaths.PROPERTY_DETAILS,
    PropertyRoutePaths.PROPERTY_COVERS_AND_DISCOUNTS,
    PropertyRoutePaths.PROPERTY_PROPOSAL,
  ],
  [PropertyWorkflow.PropertySupplementaryPolicy]: [
    PropertyRoutePaths.PROPERTY_POLICY_DETAILS,
    PropertyRoutePaths.PROPERTY_INSURING_PARTY,
    PropertyRoutePaths.PROPERTY_DETAILS,
    PropertyRoutePaths.PROPERTY_COVERS_AND_DISCOUNTS,
    PropertyRoutePaths.PROPERTY_SUPPLEMENTARY_LIST,
    PropertyRoutePaths.PROPERTY_PROPOSAL,
  ],
};
