import {CoverAmount} from '../../../models/AdditionalCover';

export const MOCK_COVER_AMOUNT: CoverAmount[] = [
  {
    name: '1000 BGN',
    value: '1000 BGN',
  },
  {
    name: '2000 BGN',
    value: '2000 BGN',
  },
  {
    name: '3000 BGN',
    value: '3000 BGN',
  },
  {
    name: '5000 BGN',
    value: '5000 BGN',
  },
];
