<form maiaForm [formGroup]="propertyCoversForm.formGroup">
  <div class="supplementary-list-header">
    <div class="b2b-kdl-font-small-bold">
      {{ this.propertyCoversForm.controls.propertyType.value }}
    </div>
    <div class="b2b-kdl-font-medium-bold">
      <span>{{ movableProperty ? movablePropertyValue : immovablePropertyValue }}</span>
    </div>
  </div>
  <maia-divider></maia-divider>
  <div class="insurance-main-div">
    <div class="insurance-sub-div">
      <maia-label class="b2b-kdl-font-small title-dark-color">Basis of Insurance </maia-label>
      <maia-form-element class="slidein-input-column">
        <maia-input-select
          name="basisOfInsurance"
          formControlName="basisOfInsurance"
          (ngModelChange)="selectBasisOfInsurance()"
        >
          <input maiaInput placeholder="Select" [value]="basisOfInsuranceSelected" />
          <maia-option *ngFor="let insurance of InsuranceBasisList" [value]="insurance.id">
            {{ insurance.name }}
          </maia-option>
        </maia-input-select>
      </maia-form-element>
    </div>
    <div class="insurance-sub-div">
      <maia-label
        *ngIf="propertyCoversForm.controls.basisOfInsurance.invalid"
        class="b2b-kdl-font-small title-dark-color"
      >
        <label class="b2b-kdl-font-small title-dark-color">Insured Value</label>
      </maia-label>
      <maia-label
        *ngIf="propertyCoversForm.controls.basisOfInsurance.valid"
        class="b2b-kdl-font-small-light subtitle-light-color"
        sublabel="Minimum Value: {{ minInsuredValue }} {{ currencySelected }}"
      >
        <label class="b2b-kdl-font-small title-dark-color">Insured Value</label>
      </maia-label>
      <maia-form-element
        [class.p-maia-form-element--validation-error]="getInsuredValueFieldErrors()"
        class="slidein-input-column"
      >
        <maia-input-group
          [class.p-maia-form-element--show-validation]="getInsuredValueFieldErrors()"
        >
          <input
            maiaInputNumber
            name="insuredValue"
            atlasMaxLengthFractionPart="2"
            formControlName="insuredValue"
            placeholder="Enter Amount"
            (keyup)="insuaredValueChanged()"
          />
          <maia-input-group-suffix>{{ currencySelected }}</maia-input-group-suffix>
        </maia-input-group>
        <div class="validation-container" *ngIf="getInsuredValueFieldErrors()">
          <maia-validation
            type="error"
            *ngIf="propertyCoversForm.controls?.insuredValue?.errors?.required"
          >
            Insured Value required
          </maia-validation>
          <maia-validation
            type="error"
            *ngIf="propertyCoversForm.controls?.insuredValue?.errors?.min"
          >
            Insured Value cannot be less than {{ minInsuredValue }} {{ currencySelected }}
          </maia-validation>
          <maia-validation
            type="error"
            *ngIf="propertyCoversForm.controls?.insuredValue?.errors?.max"
          >
            Insured Value cannot be more than {{ maxInsuredValue }} {{ currencySelected }}
          </maia-validation>
          <maia-validation
            class="validation-container"
            type="error"
            *ngIf="propertyCoversForm.controls?.insuredValue?.errors?.atlasFractionPartTooLong"
          >
            Only 2 decimal places allowed.
          </maia-validation>
          <maia-validation
            id="validation-invalid-characters"
            *ngIf="propertyCoversForm.controls?.insuredValue?.errors?.atlasInvalidFormat"
          >
            Invalid characters
          </maia-validation>
        </div>
      </maia-form-element>
    </div>
  </div>
  <div>
    <div>
      <div class="b2b-kdl-font-small-bold"> {{ mainCovers }}</div>
    </div>
    <div class="main-covers">
      <maia-radio-group ngDefaultControl name="headers" formControlName="mainCovers">
        <maia-tile
          class="tile-headers"
          *ngFor="let headers of package_headers; let i = index"
          [ngClass]="{'property-tile-selected': package_headers[i].selectedStatus}"
        >
          <maia-radio-button
            [value]="headers.packageValue"
            (click)="selectPropertyList(i, headers)"
            >{{ headers.packageTile }}</maia-radio-button
          >
          <div
            *ngIf="(viewablePackageHeaders[headers.id - 1] | atlasBoolean) === false"
            class="add-flex"
            (click)="includeSelection(headers.id, viewMore)"
          >
            <svg class="add-icon" b2bFrontendNavigationFuncChevron></svg>
            <button id="maia-action-icon-1" maiaActionWithIcon>
              <span>See More</span>
            </button>
          </div>
          <div *ngIf="viewablePackageHeaders[headers.id - 1] | atlasBoolean">
            <h3>{{ include }}</h3>
            <ul maiaTextList id="maia-text-list-4" [iconType]="'checkmark'">
              <li maiaTextListItem>
                <h2 maia-text-list-item-title>FLEXA</h2>
                Fire,Lightning,Explosion,Implosion,Falling aircrafts and /or objcts falling
                therefrom
              </li>
              <li maiaTextListItem>
                <h2 maia-text-list-item-title>Tempest</h2>
                Strom,Hail,Torrential rain,Flood, Snow/Ice weight, Freezing/Frost bite
              </li>
              <li maiaTextListItem> Leakage of water and steam </li>
              <li maiaTextListItem> Vandalism, Malicious damages </li>
              <li maiaTextListItem> Short circuit and/or electrical shock </li>
              <li maiaTextListItem> Landslude, Collapse of earth layers, Action of groundwater</li>
              <li maiaTextListItem>
                Collision with a vehicle and/or animal; Damages as a result of loading and unloading
                machinery
              </li>
            </ul>
            <div class="add-flex" (click)="includeSelection(headers.id, viewLess)">
              <svg class="add-icon" b2bFrontendNavigationFuncChevron></svg>
              <button id="maia-action-icon-1" maiaActionWithIcon>
                <span>See Less</span>
              </button>
            </div>
          </div>
        </maia-tile>
      </maia-radio-group>
    </div>
  </div>
</form>
<maia-divider></maia-divider>
<div class="add-cover">
  <div class="b2b-kdl-font-small"> {{ addCovers }}</div>
</div>
<div class="additional-cover-main-div">
  <div
    class="additional-cover-checkbox"
    *ngFor="let additionalCover of additionalCoversList; let i = index"
  >
    <div class="additional-cover-div" *ngIf="isMovableProperty(additionalCover)">
      <maia-tile
        class="tile-display"
        [ngClass]="{'property-tile-selected': additionalCoversSelected[i] | atlasBoolean}"
      >
        <maia-tile-items>
          <maia-tile-item>
            <maia-checkbox
              [(ngModel)]="additionalCoversSelected[i]"
              (onValueChange)="changeAdditionalCoverSelection(i)"
              class="additional-cover-maia-checkbox"
            >
              <label>{{ additionalCover.additionaCoverName }}</label>
            </maia-checkbox>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
      <div *ngIf="additionalCoversSelected[i] | atlasBoolean" class="aggregate-frame">
        <div class="aggregate-limit-main-div">
          <div class="aggregate-limit-sub-div">
            <maia-label
              class="b2b-kdl-font-small subtitle-light-color aggregate-limit-label"
              [sublabel]="subLabelValue[i]"
            >
              <label class="b2b-kdl-font-small title-dark-color"> Aggregate Limit </label>
            </maia-label>
          </div>
          <maia-form-element hideOptionalIndicator>
            <maia-input-group
              class="aggregate-limit"
              [ngClass]="{
                'invalid-aggregate-limit': aggregateLimtEntered(i)
              }"
            >
              <input
                maiaInputNumber
                [attr.disabled]="subLabelValue[i].length === 0 ? '' : null"
                [(ngModel)]="additionalCoversSelectedAggregateLimitList[i]"
                placeholder="Amount"
                (keyup)="aggregateLimtEntered(i)"
              />
              <maia-input-group-suffix>{{ currencySelected }}</maia-input-group-suffix>
            </maia-input-group>
            <span *ngIf="aggregateLimtEntered(i)" class="discount-error-msg"
              >Please enter Aggregate Limit</span
            >
          </maia-form-element>
        </div>
      </div>
    </div>
  </div>
</div>
<maia-divider></maia-divider>
<div>
  <maia-button-group class="save-button">
    <button
      class="m-t-b-24"
      type="button"
      maiaButton
      maiaButtonType="primary"
      (click)="savePropertyData()"
      [disabled]="!isPropertyCoversFormValid()"
      >Save</button
    >
    <button class="m-t-b-24" maiaButton maiaButtonType="secondary" type="button" (click)="cancel()"
      >Cancel</button
    >
  </maia-button-group>
</div>
