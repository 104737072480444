<h1 class="kdl-font-grand-bold title">Renew Travel Insurance</h1>

<maia-action-group>
  <button
    maiaActionTile
    class="terms-tile"
    [selected]="actionTileSelected === existing"
    (click)="tileClicked(existing)"
  >
    <svg b2bFrontendFileIndiDocumentsSwitch maia-tile-icon class="existing-terms-icon"></svg>
    <span maia-tile-label class="normal-bold">Use existing terms</span>
  </button>
  <button
    maiaActionTile
    class="terms-tile"
    [selected]="actionTileSelected === new"
    (click)="tileClicked(new)"
  >
    <svg b2bFrontendFileExprDocumentAdd maia-tile-icon class="new-terms-icon"></svg>
    <span maia-tile-label class="normal-bold">New terms</span>
  </button>
</maia-action-group>

<button
  maiaButton
  maiaButtonType="primary"
  class="button"
  [disabled]="buttonDisable"
  (click)="navigate()"
  >Continue</button
>
