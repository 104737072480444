import {Injectable} from '@angular/core';
import {API_END_POINT, ApiService} from '@b2b-frontend/core';
import {Observable} from 'rxjs';

import {COLON, NumberConst, SLASH} from '../constants/account-management';
import {ActivateDeactivateAccountsPayload} from '../models/ActivateDeactivateAccountsPayload';
import {SuspendAccount} from '../models/b2b/SuspendAccount';
import {SuspendAccountResponse} from '../models/b2b/SuspendAccountResponse';
import {UsersList as B2BUsersList} from '../models/b2b/UsersList';
import {PasswordResetResponse} from '../models/PasswordResetResponse';
import {UserData} from '../models/UserData';
import {UsersList} from '../models/UsersList';

@Injectable({
  providedIn: 'root',
})
export class AccountManagementService {
  public constructor(private readonly _apiService: ApiService) {}

  public getB2CAccountsToActivateList(requestParams: string): Observable<UsersList> {
    return this._apiService.get(API_END_POINT.UAM_B2C_DEACTIVTAED_USERS + '?' + requestParams);
  }

  public getSearchB2CAccountsToActivateList(requestParams: string): Observable<UsersList> {
    return this._apiService.get(
      API_END_POINT.SEARCH_B2C_UAM_DEACTIVTAED_USERS + '?' + requestParams,
    );
  }

  public activateB2CAccount(id: number | undefined): Observable<UserData> {
    return this._apiService.patch(this._prepareEndPoint(API_END_POINT.ACTIVATE_B2C_USER, [id]));
  }

  public activateAllB2CAccount(payload: ActivateDeactivateAccountsPayload): Observable<UserData> {
    return this._apiService.patch(API_END_POINT.ACTIVATE_B2C_ALL_USERS, payload);
  }

  public getB2CAccountsToDeactivateList(requestParams: string): Observable<UsersList> {
    return this._apiService.get(API_END_POINT.UAM_B2C_ACTIVTAED_USERS + '?' + requestParams);
  }

  public getSearchB2CAccountsToDeactivateList(requestParams: string): Observable<UsersList> {
    return this._apiService.get(API_END_POINT.SEARCH_B2C_UAM_ACTIVTAED_USERS + '?' + requestParams);
  }

  public deactivateB2CAccount(id: number | undefined): Observable<UserData> {
    return this._apiService.patch(this._prepareEndPoint(API_END_POINT.DEACTIVATE_B2C_USER, [id]));
  }

  public resetB2CAccountPassword(id: number | undefined): Observable<PasswordResetResponse> {
    return this._apiService.patch(
      this._prepareEndPoint(API_END_POINT.RESET_B2C_USER_PASSWORD, [id]),
    );
  }

  public resetB2BAccountPassword(id: number | undefined): Observable<PasswordResetResponse> {
    return this._apiService.patch(
      this._prepareEndPoint(API_END_POINT.RESET_B2B_USER_PASSWORD, [id]),
    );
  }
  public getB2BAccountsToActivateList(requestParams: string): Observable<B2BUsersList> {
    return this._apiService.get(API_END_POINT.UAM_B2B_DEACTIVTAED_USERS + '?' + requestParams);
  }

  public getSearchB2BAccountsToActivateList(requestParams: string): Observable<B2BUsersList> {
    return this._apiService.get(
      API_END_POINT.SEARCH_B2B_UAM_DEACTIVTAED_USERS + '?' + requestParams,
    );
  }

  public getOrganizationSearchB2BAccountsToActivateList(
    requestParams: string,
  ): Observable<B2BUsersList> {
    return this._apiService.get(
      API_END_POINT.SEARCH_B2B_UAM_DEACTIVTAED_USERS_BY_ORG + '?' + requestParams,
    );
  }

  public activateB2BAccount(payload: ActivateDeactivateAccountsPayload): Observable<UserData> {
    return this._apiService.patch(API_END_POINT.ACTIVATE_B2B_USER, payload);
  }

  public getSearchB2BAccountsToDeactivateList(requestParams: string): Observable<B2BUsersList> {
    return this._apiService.get(API_END_POINT.SEARCH_B2B_UAM_ACTIVTAED_USERS + '?' + requestParams);
  }

  public getOrganizationSearchB2BAccountsToDeactivateList(
    requestParams: string,
  ): Observable<B2BUsersList> {
    return this._apiService.get(
      API_END_POINT.SEARCH_B2B_UAM_ACTIVTAED_USERS_BY_ORG + '?' + requestParams,
    );
  }

  public getB2BAccountsToDeactivateList(requestParams: string): Observable<B2BUsersList> {
    return this._apiService.get(API_END_POINT.UAM_B2B_ACTIVTAED_USERS + '?' + requestParams);
  }

  public deactivateB2BAccount(payload: ActivateDeactivateAccountsPayload): Observable<UserData> {
    return this._apiService.patch(API_END_POINT.DEACTIVATE_B2B_USER, payload);
  }

  public suspendB2BAccount(suspendData: SuspendAccount): Observable<SuspendAccountResponse> {
    return this._apiService.patch(API_END_POINT.SUSPEND_B2B_USER, suspendData);
  }

  private _prepareEndPoint(endPoint: string, values: (string | number | undefined)[]): string {
    values.forEach(value => {
      let tempEndPoint = endPoint;
      const indexOfColon = endPoint.indexOf(COLON);
      if (indexOfColon) {
        let indexOfSlash = endPoint.indexOf(SLASH, indexOfColon);
        indexOfSlash =
          indexOfSlash === NumberConst.INDEX_MINUS_ONE ? tempEndPoint.length : indexOfSlash;
        tempEndPoint =
          tempEndPoint.substring(NumberConst.INDEX_ZERO, indexOfColon) +
          value +
          tempEndPoint.substring(indexOfSlash, tempEndPoint.length);
        endPoint = tempEndPoint;
      }
    });
    return endPoint;
  }
}
