<maia-panel class="maia-panel-default m-t-24">
  <maia-label class="b2b-kdl-font-small m-b-8"> Account Details </maia-label>
  <div class="details-wrapper" *ngFor="let detail of accountDetails; let i = index">
    <div class="user-account-details">
      <maia-avatar-svg avatarSize="40" maia-avatar-display-icon [ngSwitch]="i">
        <svg *ngSwitchCase="0" b2bFrontendTransportationFuncCar></svg>
        <svg *ngSwitchCase="1" b2bFrontendTransportationFuncCar></svg>
        <svg *ngSwitchCase="2" b2bFrontendTransportationIndiVehicleLicensePlate></svg>
        <svg *ngSwitchCase="3" b2bFrontendTransportationExprTransporter></svg>
        <svg *ngSwitchCase="4" b2bFrontendPlacesFuncFactory></svg>
        <svg *ngSwitchCase="5" b2bFrontendTransportationExprCarNew></svg>
        <svg *ngSwitchCase="6" b2bFrontendCommunicationFuncPhone></svg>
      </maia-avatar-svg>
      <div class="account-details">
        <maia-label class="title-wrapper">
          {{ detail.value ? detail.value : '-' }}
          <maia-label class="values-wrapper b2b-kdl-font-extra-small subtitle-light-color">
            {{ detail.title ? detail.title : '-' }}
          </maia-label>
        </maia-label>
      </div>
    </div>
  </div>
</maia-panel>
