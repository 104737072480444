/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DateKey, DateUtils} from '@atlas/businesstypes';
import {
  bgPhoneNumberValidator,
  BLANK_VALUE,
  City,
  companyName,
  CoreApiService,
  FALSE,
  InsurancePartyDetails,
  NationalityDto,
  PartyIdService,
  PartyType,
  personName,
  ROW,
  TRUE,
} from '@b2b-frontend/core';
import {DatepickerVisibleView} from '@maia/datepickers';
import {InputPhonenumberComponent} from '@maia/input-phonenumber';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  PhoneNumberValueController,
  ScreenFormComponent,
  SHOW_ERROR_CHECK,
} from '@njf-frontend-angular/flow-progress';
import {ServerSideAutocompleteController} from '@njf-frontend-angular/flow-progress/types/form-manage/component/controller/ServerSideAutocompleteController';
import {map, tap} from 'rxjs/operators';

import {CityApiData} from '../../core-helper/dto/property-insurance-dto/insurance-party-details-dto';
import {
  ApiCompanyData,
  ApiPersonalData,
} from '../../core-helper/dto/property-insurance-dto/summary-dto/insurance-party-details-api-dto';

/*eslint-disable @typescript-eslint/strict-boolean-expressions */
@Component({
  selector: 'insurance-party-details',
  templateUrl: './insurance-party-details.component.html',
  styleUrls: ['./insurance-party-details.component.scss'],
})
export class InsurancePartyDetailsComponent extends ScreenFormComponent implements OnInit {
  public iPartyType: PartyType;
  public isEGN: boolean = TRUE;
  public isLNCH: boolean = FALSE;
  public isEIK: boolean = FALSE;
  public isQuarter: boolean = TRUE;
  public isStreet: boolean = TRUE;
  public isStreetNumber: boolean = TRUE;
  public isRepParty: boolean = FALSE;
  public insurancePartyType: PartyType | undefined;

  public repPartyType: PartyType;
  public contactPartyType: PartyType;
  public city: CityApiData[];

  public isRepForeigner: boolean = FALSE;
  public isContactForeigner: boolean = FALSE;
  public cityzip: InsurancePartyDetails[] = [];

  public isIdNumberAdded: boolean = FALSE;

  //ITS mandatory for phone
  public phoneNumberController: PhoneNumberValueController;

  @Input() public flexDirection: string = ROW;

  // This flag value will be used to only show address fields by passing it as input parameter
  @Input() public onlyAddressForm: boolean = FALSE;

  @ViewChild('phoneInput', {static: false})
  protected set phoneInput(val: InputPhonenumberComponent) {
    if (val && !this.phoneNumberController) {
      setTimeout(
        () =>
          (this.phoneNumberController = this.stepForm.createPhoneNumberValue('phoneNumber', val, {
            disableCountryCode: true,
          })),
      );
    }
  }

  public birthDatePicker;
  public cityAutocomplete: ServerSideAutocompleteController<CityApiData>;
  public cityAutocompleteEn: ServerSideAutocompleteController<City>;
  public citizenshipAutocomplete: ServerSideAutocompleteController<NationalityDto>;
  public contactCitizenshipAutocomplete: ServerSideAutocompleteController<NationalityDto>;
  public repCitizenshipAutocomplete: ServerSideAutocompleteController<NationalityDto>;

  public visibleDateView?: DatepickerVisibleView = undefined;
  public disableWeekends: boolean = FALSE;

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Year, -150);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, -1);

  public constructor(
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _coreApiService: CoreApiService,
    private readonly _partyId: PartyIdService,
    route: ActivatedRoute,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    super(route);

    this.stepForm = this._formFactory.createFormManager<InsurancePartyDetails>({
      idNumber: new FormControl(undefined, [
        Validators.required,
        this.idNumberValidator.bind(this),
      ]),

      // eslint-disable-next-line
      cityEn: new FormControl(undefined, [Validators.required]),
      // eslint-disable-next-line
      postCodeEn: new FormControl({value: undefined, disabled: true}, [Validators.required]),
      // eslint-disable-next-line
      quarterEn: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      // eslint-disable-next-line
      streetEn: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      // eslint-disable-next-line
      streetNumberEn: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      // eslint-disable-next-line
      blockEn: new FormControl(undefined),
      // eslint-disable-next-line
      entranceEn: new FormControl(undefined),
      // eslint-disable-next-line
      floorEn: new FormControl(undefined),
      // eslint-disable-next-line
      apartmentEn: new FormControl(undefined),

      phoneNumber: new FormControl(undefined, [
        Validators.required,
        bgPhoneNumberValidator({allowZero: TRUE}),
      ]),
      // eslint-disable-next-line
      emailId: new FormControl(undefined),

      // eslint-disable-next-line
      firstNameEn: new FormControl(undefined),
      // eslint-disable-next-line
      middleNameEn: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      // eslint-disable-next-line
      lastNameEn: new FormControl(undefined),

      // eslint-disable-next-line
      citizenship: new FormControl(undefined, Validators.required),
      // eslint-disable-next-line
      dob: new FormControl(undefined, Validators.required),

      // eslint-disable-next-line
      companyNameEn: new FormControl(undefined, [Validators.required, companyName('bgoren')]),

      // eslint-disable-next-line
      repIdNumber: new FormControl(undefined, [
        Validators.required,
        this._repIdNumberValidator.bind(this),
      ]),
      // eslint-disable-next-line
      repFirstNameEn: new FormControl(undefined, [personName('bgoren')]),
      // eslint-disable-next-line
      repMiddleNameEn: new FormControl(undefined, [Validators.required, personName('bgoren')]),
      // eslint-disable-next-line
      repLastNameEn: new FormControl(undefined, [personName('bgoren')]),

      // eslint-disable-next-line
      repCitizenship: new FormControl(undefined, Validators.required),
      // eslint-disable-next-line
      repDob: new FormControl(undefined, Validators.required),

      contactIdNumber: new FormControl(undefined, [this._contactIdNumberValidator.bind(this)]),
      // eslint-disable-next-line
      contactFirstNameEn: new FormControl(undefined, [personName('bgoren')]),
      // eslint-disable-next-line
      contactMiddleNameEn: new FormControl(undefined, [personName('bgoren')]),
      // eslint-disable-next-line
      contactLastNameEn: new FormControl(undefined, [personName('bgoren')]),

      // eslint-disable-next-line
      contactCitizenship: new FormControl(undefined),
      // eslint-disable-next-line
      contactDob: new FormControl(undefined),
      isInsuringParty: new FormControl(FALSE),
    });
    this.stepForm.setControlShowErrorCheck('phoneNumber', SHOW_ERROR_CHECK.TOUCHED);

    this.citizenshipAutocomplete =
      this.stepForm.createAutocompleteWithServersideFiltering<NationalityDto>(
        'citizenship',
        (q: string) => this._coreApiService.getNationalities(q, TRUE).pipe(map(res => res.data)),
      );

    this.contactCitizenshipAutocomplete =
      this.stepForm.createAutocompleteWithServersideFiltering<NationalityDto>(
        'contactCitizenship',
        (q: string) => this._coreApiService.getNationalities(q, TRUE).pipe(map(res => res.data)),
      );

    this.cityAutocompleteEn = this.stepForm.createAutocompleteWithServersideFiltering<City>(
      'cityEn',
      (query: string) =>
        this._coreApiService.getCityZip(query, TRUE).pipe(
          map((res: City[]) => {
            return res;
          }),
        ),
    );

    this.cityAutocomplete = this.stepForm.createAutocompleteWithServersideFiltering<CityApiData>(
      'cityEn',
      (query: string) =>
        this._coreApiService.getCity(query, TRUE).pipe(
          map((res: CityApiData[]) => {
            return res;
          }),
        ),
    );

    this.birthDatePicker = this.stepForm.createDatePicker('dob');

    this.repCitizenshipAutocomplete =
      this.stepForm.createAutocompleteWithServersideFiltering<NationalityDto>(
        'repCitizenship',
        (q: string) => this._coreApiService.getNationalities(q, TRUE).pipe(map(res => res.data)),
      );
  }

  public ngOnInit(): void {
    this.stepForm.controls.cityEn.valueChanges.subscribe((val: CityApiData) => {
      if (val !== null && val !== undefined) {
        this.stepForm.patchValue({
          postCodeEn: val.zip,
        });
      }
    });
    this._coreApiService
      .getCity('Sofia', true)
      .pipe(
        tap((res: CityApiData[]) => {
          this.city = res;
        }),
      )
      .subscribe();
  }

  public onQuarterValueChange(): void {
    if (this.stepForm.controls.quarterEn.value !== undefined) {
      this.stepForm.controls.quarterEn.setValidators([Validators.required]);
      this.stepForm.controls.streetNumberEn.setValidators(null);
      this.stepForm.controls.streetEn.setValidators(null);
    } else if (this.stepForm.controls.quarterEn.value === undefined) {
      this.stepForm.controls.streetNumberEn.setValidators([Validators.required]);
      this.stepForm.controls.streetEn.setValidators([Validators.required]);
      this.stepForm.controls.quarterEn.setValidators(null);
    } else if (this.isQuarter) {
      this.stepForm.controls.streetEn.setErrors(null);
      this.stepForm.controls.streetNumberEn.setErrors(null);
    }
    this.stepForm.controls.streetNumberEn.updateValueAndValidity();
    this.stepForm.controls.streetEn.updateValueAndValidity();
    this.stepForm.controls.quarterEn.updateValueAndValidity();
    this._cdRef.detectChanges();
  }

  public onStreeValueChange(): void {
    if (
      this.stepForm.controls.streetEn.value === undefined &&
      this.stepForm.controls.streetNumberEn.value === undefined
    ) {
      this.stepForm.controls.quarterEn.setValidators([Validators.required]);
      this.stepForm.controls.streetNumberEn.setValidators(null);
      this.stepForm.controls.streetEn.setValidators(null);
    } else if (
      this.stepForm.controls.streetEn.value !== undefined &&
      this.stepForm.controls.streetNumberEn.value !== undefined
    ) {
      this.stepForm.controls.quarterEn.setValidators(null);
      this.stepForm.controls.streetNumberEn.setValidators([Validators.required]);
      this.stepForm.controls.streetEn.setValidators([Validators.required]);
    } else if (this.isStreet && this.isStreetNumber) {
      this.stepForm.controls.quarterEn.setErrors(null);
    }
    this.stepForm.controls.quarterEn.updateValueAndValidity();
    this.stepForm.controls.streetNumberEn.updateValueAndValidity();
    this.stepForm.controls.streetEn.updateValueAndValidity();

    this._cdRef.detectChanges();
  }

  protected saveMtplData(): void {}

  // eslint-disable @typescript-eslint/no-unsafe-return
  public getInsurancePartyDetails(): FormGroupManager<InsurancePartyDetails> {
    if (null != this.stepForm && null != this.stepForm.controls) {
      if (this.onlyAddressForm) {
        this.stepForm.controls.idNumber?.setErrors(null);
        this.stepForm.controls.firstNameEn?.setErrors(null);
        this.stepForm.controls.middleNameEn?.setErrors(null);
        this.stepForm.controls.lastNameEn?.setErrors(null);
        this.stepForm.controls.citizenship?.setErrors(null);
        this.stepForm.controls.dob?.setErrors(null);
        this.stepForm.controls.phoneNumber?.setErrors(null);
        this.stepForm.controls.emailId?.setErrors(null);
        this.validateLNCHEntityFields();
      } else {
        if (this.isEGN) {
          this.stepForm.controls.citizenship?.setErrors(null);
          this.stepForm.controls.dob?.setErrors(null);
          this.validateLNCHEntityFields();
        } else if (this.isLNCH) {
          this.validateLNCHEntityFields();
        } else if (this.isEIK) {
          this.stepForm.controls.firstNameEn?.setErrors(null);
          this.stepForm.controls.middleNameEn?.setErrors(null);
          this.stepForm.controls.lastNameEn?.setErrors(null);
          this.stepForm.controls.citizenship?.setErrors(null);
          this.stepForm.controls.dob?.setErrors(null);
        }
      }
    }
    return this.stepForm;
  }

  public setInsurancePartyDetails(insurancePartyDetails: InsurancePartyDetails): void {
    this.stepForm.patchValue({
      ...insurancePartyDetails,
      cityEn: insurancePartyDetails.cityEn,
      postCodeEn: insurancePartyDetails.cityEn,
    });

    const phoneValue = {...this.phoneNumberController?.value};
    if (phoneValue.number && phoneValue.number.length === 10 && phoneValue.number[0] === '0') {
      phoneValue.number = phoneValue.number.substr(1);
      this.stepForm.patchValue({
        phoneNumber: phoneValue,
      });
    }
  }

  public validateLNCHEntityFields(): void {
    if (null != this.stepForm && null != this.stepForm.controls) {
      this.stepForm.controls.companyNameEn?.setErrors(null);
      this.stepForm.controls.repIdNumber?.setErrors(null);
      this.stepForm.controls.repFirstNameEn?.setErrors(null);
      this.stepForm.controls.repMiddleNameEn?.setErrors(null);
      this.stepForm.controls.repLastNameEn?.setErrors(null);
      this.stepForm.controls.repCitizenship?.setErrors(null);
      this.stepForm.controls.repDob?.setErrors(null);
      this.stepForm.controls.contactIdNumber?.setErrors(null);
      this.stepForm.controls.contactFirstNameEn?.setErrors(null);
      this.stepForm.controls.contactMiddleNameEn?.setErrors(null);
      this.stepForm.controls.contactLastNameEn?.setErrors(null);
      this.stepForm.controls.contactCitizenship?.setErrors(null);
      this.stepForm.controls.contactDob?.setErrors(null);
    }
  }

  public idNumberValidator(control: FormControl) {
    if (null != control.value && 8 <= control.value.length) {
      this.insurancePartyType = this._partyId.getType(control.value);
      this.isEGN = this.insurancePartyType === PartyType.PERSON;
      this.isEIK = this.insurancePartyType === PartyType.COMPANY;
      this.isLNCH = this.insurancePartyType === PartyType.FOREIGNER;

      // If the user has edited the id number field value, check if it is present in INSIS DB
      if (!control.pristine) {
        if (this.isEGN || this.isLNCH) {
          this._coreApiService.getPersonData(control.value).subscribe((res: ApiPersonalData) => {
            const city_zip: City | string | undefined = res.personalData.addresses
              ? res.personalData.addresses[0].city
              : BLANK_VALUE;
            this.stepForm.patchValue({
              firstNameEn: res.personalData.firstName,
              middleNameEn: res.personalData.middleName,
              lastNameEn: res.personalData.surname,
              cityEn: city_zip,
              blockEn: res.personalData.addresses[0].block,
              entranceEn: res.personalData.addresses[0].entrance,
              floorEn: res.personalData.addresses[0].floor,
              apartmentEn: res.personalData.addresses[0].apartment,
              streetEn: res.personalData.addresses[0].street,
              streetNumberEn: res.personalData.addresses[0].streetNo,
              quarterEn: res.personalData.addresses[0].quarter,
              emailId: res.personalData.addresses[0].email,
              phoneNumber: {prefix: '', number: res.personalData.addresses[0].phoneNumber},
              citizenship: res.personalData.citizenship,
              dob: res.personalData.dob,
            });
          });
        } else if (this.isEIK) {
          this._coreApiService.getCompanyData(control.value).subscribe((result: ApiCompanyData) => {
            this.isRepParty = result.representative != null ? FALSE : TRUE;
            const city_zip: City | string | undefined = result.address
              ? result.address.posCode
              : BLANK_VALUE;
            this.stepForm.patchValue({
              companyNameEn: result.name,
              cityEn: city_zip,
              quarterEn: result.address.quarter,
              streetEn: result.address.street,
              streetNumberEn: result.address.streetNumber,
              blockEn: result.address.block,
              entranceEn: result.address.entrance,
              floorEn: result.address.floor,
              apartmentEn: result.address.apartment,
              emailId: result.email,
              phoneNumber: {prefix: '', number: result.address.phoneNumber},
              repIdNumber: result.representative.indent,
              repFirstNameEn: result.representative.name,
            });
          });
        }
      }
    }
    if (control.value && !this._partyId.getType(control.value)) {
      return {invalidNumber: TRUE};
    }
    return null;
  }

  private _repIdNumberValidator(control: FormControl): ValidationErrors | null {
    if (null != control.value && 8 <= control.value.length) {
      const repPartyType = this._partyId.getType(control.value);
      if (repPartyType) {
        this.repPartyType = repPartyType;
        this.updateRepDynamicValidation();
      }

      if (!repPartyType || repPartyType === PartyType.COMPANY) {
        this.isRepForeigner = FALSE;
        return {repInvalidNumber: TRUE};
      }
    }
    return null;
  }

  private _contactIdNumberValidator(control: FormControl): ValidationErrors | null {
    if (null != control.value && 8 <= control.value.length) {
      const contactPartyType = this._partyId.getType(control.value);
      if (contactPartyType) {
        this.contactPartyType = contactPartyType;
        this.updateContactDynamicValidation();
      }

      if (!contactPartyType || contactPartyType === PartyType.COMPANY) {
        this.isContactForeigner = FALSE;
        return {contactInvalidNumber: TRUE};
      }
    }
    return null;
  }

  public updateRepDynamicValidation(): void {
    this.isRepForeigner = this.repPartyType === PartyType.FOREIGNER;
    if (this.repPartyType === PartyType.PERSON) {
      this.stepForm.controls.repCitizenship.setValidators([]);
      this.stepForm.controls.repDob.setValidators([]);
    } else if (this.repPartyType === PartyType.FOREIGNER) {
      // eslint-disable-next-line
      this.stepForm.controls.repCitizenship.setValidators(Validators.required);
      // eslint-disable-next-line
      this.stepForm.controls.repDob.setValidators(Validators.required);
    }
    this.stepForm.controls.repCitizenship.updateValueAndValidity();
    this.stepForm.controls.repDob.updateValueAndValidity();
  }

  public updateContactDynamicValidation(): void {
    this.isContactForeigner = this.contactPartyType === PartyType.FOREIGNER;
    if (this.contactPartyType === PartyType.PERSON) {
      this.stepForm.controls.contactCitizenship.setValidators([]);
      this.stepForm.controls.contactDob.setValidators([]);
    } else if (this.contactPartyType === PartyType.FOREIGNER) {
      // eslint-disable-next-line
      this.stepForm.controls.contactCitizenship.setValidators(Validators.required);
      // eslint-disable-next-line
      this.stepForm.controls.contactDob.setValidators(Validators.required);
    }
    this.stepForm.controls.contactCitizenship.updateValueAndValidity();
    this.stepForm.controls.contactDob.updateValueAndValidity();
  }
}
