import {Component} from '@angular/core';

import {AutoCompleteOptions} from '../../../../constants/account-creation';
import {IdValue} from '../../../../models/IdValue';

@Component({
  selector: 'nje-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss'],
})
export class AutoCompleteComponent {
  public selectedValue: string;
  public autoCompleteOptions: IdValue[] = AutoCompleteOptions;
  public isDisabled: boolean = false;
}
