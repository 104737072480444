import {VehicleTalonData} from '@b2b-frontend/core';

export const READ_MORE_LINK =
  'https://www.dzi.bg/attachments/Product/30/download/8104-Singleitrip-IPID-0.pdf';

export const MOCK_TALONDATA: VehicleTalonData = {
  regNo: '1234',
  certNo: '12345',
  pid: '1',
  talonImageId: '1',
};

export const MOCK_VEHICLE_ID_NO = 'CBF12345';

export const MOCK_VEHICLE_REG_NO = 'CBF12345';
