import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {localeValPipe} from './pipes/localeVal.pipe';
import {HttpTokenInterceptor} from './helper/http.token.interceptor';

@NgModule({
  declarations: [localeValPipe],
  imports: [CommonModule],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true}],
  exports: [localeValPipe, CommonModule],
  entryComponents: [],
})
export class FlowModule {}
