/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/unbound-method */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  EventEmitter,
  Injector,
  OnInit,
  Output,
} from '@angular/core';
import {
  ArticleFileUploadResponse,
  ArtticleMediaType,
  CategoryInterface,
  FilterInputInterface,
  filterResponseInterface,
  IdInterface,
  ImagePreviewInterface,
  NewsEvents,
  PopUpContent,
  Time,
  UserType,
} from '../../../contracts/NewsEvents';
import {
  NEWS_EVENTS,
  POPUP_NEWS_EVENTS,
  UPLOAD_FILE_SIZE,
} from '../../../constants/resource-management/contacts';
import {Product} from '../../../contracts/library.interface';
import {FilterSlideinComponent} from './filter-slidein/filter-slidein.component';
import {PopUpController} from '@maia/pop-ups';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenStepComponent,
} from '@njf-frontend-angular/flow-progress';
import {ActivatedRoute} from '@angular/router';
import {LoggerFactory} from '@atlas-angular/logger';
import {Boolean, Date as AtlasDate, DateKey, DateUtils, Hidden, Text} from '@atlas/businesstypes';
import {NewsAndEventsPinSlideinComponent} from './news-and-events-pin-slidein/news-and-events-pin-slidein.component';
import {SlideInController} from '@maia/slide-ins';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {Tag} from '@maia/tags';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ArticleService} from '../../../services/article.service';
import {tap} from 'rxjs/operators';
import {UsertypeSelectionSlideinComponent} from '../shared/usertype-selection-slidein/usertype-selection-slidein.component';
import {FileUploaderController, FileUploaderSlideInConfig, Reference} from '@hermes/file-uploader';
import {DeletePopupNewsComponent} from './delete-popup-news/delete-popup-news.component';
import {PinDates, PinSlideOutput} from './news-and-events-pin-slidein/NewsAndEventsPinDates';
import {PopupNewsComponent} from './popup-news/popup-news/popup-news.component';
import {
  ApiService,
  BLANK_VALUE,
  localeValPipe,
  LocalizationService,
  DateFormatterService,
} from '@b2b-frontend/core';

import {DomSanitizer} from '@angular/platform-browser';
import {CMS_FILE_UPLOADER} from '../../../constants/resource-management/file-uploader';
import {
  eventEndTimeValidator,
  eventStartTimeValidator,
  getHourMinute,
  publishTimeValidator,
} from '../../../helper/validator/time.validator';
import {
  DESCRIPTION_LENGTH,
  DESCRIPTION_PATTERN,
  TITLE_PATTERN,
  TITLE_LENGTH,
} from '../../../constants/resource-management/constant';
import {pastDateValidator} from '../../../helper/validator/date.validator';

export type checkboxType = 'checkboxForm';

export interface CheckboxInterface {
  value: Boolean;
}

export interface ITestPageCheckbox {
  checkboxForm: CheckboxInterface;
}

const lang: string | null = sessionStorage.getItem('lang');
const constraintText = new localeValPipe(new LocalizationService()).transform(
  'Only MP4 or JPG. max. 10MB',
  lang,
);
const uploadFileTitle = new localeValPipe(new LocalizationService()).transform(
  'Upload Document',
  lang,
);

@Component({
  selector: 'nje-news-and-events',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
  providers: [FileUploaderController],
})
@UntilDestroy()
export class ArticlesComponent extends ScreenStepComponent implements OnInit {
  public showLoadingIcon: boolean = false;
  public checkBoxUserTypeList: ITestPageCheckbox[] = [];
  public tagsCreated: number = 1;
  public regularTagList: Tag[] = [];
  public news_events_list: NewsEvents[] = [];

  public publishedList: NewsEvents[];
  public draftList: NewsEvents[];
  public scheduledList: NewsEvents[];
  public tabList: NewsEvents[];

  public publishedNewsList: NewsEvents[];
  public publishedEventsList: NewsEvents[];
  public draftNewsList: NewsEvents[];
  public draftEventsList: NewsEvents[];
  public scheduledNewsList: NewsEvents[];
  public scheduledEventsList: NewsEvents[];
  public pinDates: PinDates;
  public articleFrontPage: boolean = true;
  public eventPage: boolean = false;
  public isEditEvent: boolean = false;

  public previewStartDate: string = BLANK_VALUE;
  public previewEndDate: string = BLANK_VALUE;
  public previewStartTime: string = BLANK_VALUE;
  public previewEndTime: string = BLANK_VALUE;

  public pinned: boolean = false;
  public pinnedNews: NewsEvents[];
  public selectedIndex: number | undefined;
  public addEditArticle: boolean = false;
  public editableObj?: NewsEvents;
  public lang = sessionStorage.getItem(NEWS_EVENTS.LANG);
  public switchForm: boolean = false;
  public disabledArticleId: number;

  private readonly _newsAndEventsPinSlideInFactory: ComponentFactory<NewsAndEventsPinSlideinComponent>;
  private readonly _newsAndEventsFilterSlideInFactory: ComponentFactory<FilterSlideinComponent>;
  private readonly _userTypeSelectSlideInFactory: ComponentFactory<UsertypeSelectionSlideinComponent>;

  public categoryList: CategoryInterface[];
  public userTypeList: UserType[];
  public language: string = NEWS_EVENTS.ENGLISH;
  public event_val: string = NEWS_EVENTS.EVENTS;
  public count_of_selected_user_type: number = 0;
  public scheduleLaterValue: boolean;
  public viewPreviewVal: boolean = false;
  public selectedUsers: string | undefined = BLANK_VALUE;
  public fileUploadIdList: number[] = [];
  public editableUserTypeList: any[] = [];
  public newsValue = new localeValPipe(new LocalizationService()).transform('News', this.lang);
  public eventsValue = new localeValPipe(new LocalizationService()).transform('Events', this.lang);
  public newtoOldValue = new localeValPipe(new LocalizationService()).transform(
    'New to Old',
    this.lang,
  );

  public oldtoNewValue = new localeValPipe(new LocalizationService()).transform(
    'Old to New',
    this.lang,
  );

  public newsEventsForm = new FormGroup({
    newsEvents: new FormControl(this.newsValue, Validators.required),
  });

  private readonly _popupnewsandEvents: ComponentFactory<PopupNewsComponent>;
  public publishMsg: PopUpContent = {
    title: new localeValPipe(new LocalizationService()).transform(
      POPUP_NEWS_EVENTS.PUBLISH,
      this.lang,
    ),
    message: new localeValPipe(new LocalizationService()).transform(
      POPUP_NEWS_EVENTS.PUBLISH_NEWS_EVENTS,
      this.lang,
    ),
    footerNote: POPUP_NEWS_EVENTS.BLANK_VALUE,
  };

  public schedulehMsg: PopUpContent = {
    title: new localeValPipe(new LocalizationService()).transform(
      POPUP_NEWS_EVENTS.SCHEDULE,
      this.lang,
    ),
    message: new localeValPipe(new LocalizationService()).transform(
      POPUP_NEWS_EVENTS.SCHEDULE_NEWS_EVENTS,
      this.lang,
    ),
    footerNote: POPUP_NEWS_EVENTS.BLANK_VALUE,
  };

  public saveAsDraftMsg: PopUpContent = {
    title: new localeValPipe(new LocalizationService()).transform(
      POPUP_NEWS_EVENTS.SAVE_AS_DRAFT,
      this.lang,
    ),
    message: new localeValPipe(new LocalizationService()).transform(
      POPUP_NEWS_EVENTS.SAVE_AS_DRAFT_NEWS_EVENTS,
      this.lang,
    ),
    footerNote: POPUP_NEWS_EVENTS.BLANK_VALUE,
  };

  public cancelMsg: PopUpContent = {
    title: new localeValPipe(new LocalizationService()).transform(
      POPUP_NEWS_EVENTS.WARNING,
      this.lang,
    ),
    message: new localeValPipe(new LocalizationService()).transform(
      POPUP_NEWS_EVENTS.CANCEL_NEWS_EVENTS,
      this.lang,
    ),
    footerNote: POPUP_NEWS_EVENTS.BLANK_VALUE,
  };

  public publishedNewsEventsForm: FormGroup;
  public draftNewsEventsForm: FormGroup;
  public scheduledNewsEventsForm: FormGroup;
  public addEditArticleForm: FormGroupManager<NewsEvents>;
  public addEditArticleFormData: NewsEvents = {
    category: {
      id: undefined,
    },
    i18n: {
      en: {
        title: undefined,
        location: undefined,
        description: undefined,
        organizer: undefined,
      },
      bg: {
        title: undefined,
        location: undefined,
        description: undefined,
        organizer: undefined,
      },
    },
    gallery: [],
  };

  public isPublishedTabActive: boolean = true;
  public isDraftTabActive: boolean = false;
  public isScheduledTabActive: boolean = false;

  public disableSwitch = true;
  public draft: any;

  // search bar
  public selection: any;
  public readonly slideInOneFactory: ComponentFactory<FilterSlideinComponent>;
  public textSearched: Text;

  //sort
  private readonly _disabled = Boolean.FALSE;
  public value: string;
  public searchNews: string = BLANK_VALUE;
  public sortDate: string;

  public inputSelectCustomDisabled = false;
  public modalTitle: string;

  public existingUserTypeList: UserType[] = [];
  public selectedUserTypeList: UserType[] = [];

  public editFilter: string = NEWS_EVENTS.EDIT_FILTER;

  public completeArticleData: NewsEvents[];
  public profileImage: ImagePreviewInterface;
  public profileView: boolean = false;

  //event dates
  public minEventStartDate: AtlasDate | undefined = DateUtils.add(
    DateUtils.today(),
    DateKey.Day,
    0,
  );
  public maxEventStartDate = DateUtils.add(DateUtils.today(), DateKey.Year, 1);
  public minEventEndDate: AtlasDate | undefined = DateUtils.add(DateUtils.today(), DateKey.Year, 0);

  public enlargedImage: any;
  public mediaObj: ArtticleMediaType[] = [];
  public uploadedFiles: Reference[] = [];
  public fileUploaderSelector = {disabled: new Boolean(false)};
  public additionalInfo = NEWS_EVENTS.ADD_MORE_INFO;
  public imagePreviewPaths: ImagePreviewInterface[] = [];
  public media: string = CMS_FILE_UPLOADER.ARTICLE_MEDIA;
  public uploadedFilesBasic: Reference[] = [];

  public slideInConfig: FileUploaderSlideInConfig = {
    title: uploadFileTitle,
    mimeTypes: new RegExp('/|^image/|^video$/'),
    maxNumberOfFiles: 5,
    maxTotalFileSize: UPLOAD_FILE_SIZE,
    dropZones: [
      {
        constraintText,
      },
    ],
    afterDownload: (reference: Reference) => {
      console.log('on download', this.slideInConfig.title, reference);
    },
    afterUpload: (references: Reference[]) => {
      if (references && references.length > 0) {
        const idRef: Hidden = references[0].id;
        const nameRef: Text = references[0].name;
        const typeRef: Text = references[0].type;
        const articleFileUploadResponse: ArticleFileUploadResponse = {
          action: CMS_FILE_UPLOADER.UPLOAD,
          imageSide: this.media,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        };
        this.uploadedFiles.push({id: idRef['encryptedValue'], name: nameRef, type: typeRef});
        const imageId = Number(articleFileUploadResponse.id);
        this.fileUploadIdList.push(imageId);
        this.getImageToPreview(this.fileUploadIdList);
      }
    },
    afterDelete: (reference: Reference) => {
      if (reference !== undefined) {
        const idRef: Hidden = reference.id;
        const nameRef: Text = reference.name;
        const typeRef: Text = reference.type;
        const articleFileUploadResponse: ArticleFileUploadResponse = {
          action: CMS_FILE_UPLOADER.DELETE,
          imageSide: this.media,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        };
        const newIdList: number[] = [];
        this.fileUploadIdList.forEach(element => {
          if (articleFileUploadResponse.id) {
            this.removeImageFromPreview(articleFileUploadResponse.id);
          }
          if (element != (articleFileUploadResponse.id as unknown as number)) {
            newIdList.push(element);
          }
        });
        this.fileUploadIdList = newIdList;
      }
    },
  };

  public titleLimit: number = TITLE_LENGTH;
  public descriptionLimit: number = DESCRIPTION_LENGTH;

  @Output()
  public readonly selectedNewsEvents: EventEmitter<NewsEvents> = new EventEmitter<NewsEvents>();

  public readonly emitSelected = new EventEmitter<Product>();
  private readonly _popUpDeleteFactory: ComponentFactory<DeletePopupNewsComponent>;

  public constructor(
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _popUpCtrl: PopUpController,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    public router: ActivatedRoute,
    private readonly _cdRef: ChangeDetectorRef,
    public loggerFactory: LoggerFactory,
    private readonly _articleService: ArticleService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _dateFormatterService: DateFormatterService,
    private readonly _sanitizer: DomSanitizer,
    private readonly _apiService: ApiService,
  ) {
    super(router);
    this.existingUserTypeList.forEach(ele => {
      const temp = {
        checkboxForm: {
          value: Boolean.FALSE,
        } as CheckboxInterface,
      };
      this.checkBoxUserTypeList.push(temp);
    });

    this._popupnewsandEvents =
      this._componentFactoryResolver.resolveComponentFactory(PopupNewsComponent);

    this.slideInOneFactory =
      _componentFactoryResolver.resolveComponentFactory(FilterSlideinComponent);
    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(DeletePopupNewsComponent);
    this._newsAndEventsPinSlideInFactory = this._componentFactoryResolver.resolveComponentFactory(
      NewsAndEventsPinSlideinComponent,
    );
    this._newsAndEventsFilterSlideInFactory =
      this._componentFactoryResolver.resolveComponentFactory(FilterSlideinComponent);
    this._userTypeSelectSlideInFactory = this._componentFactoryResolver.resolveComponentFactory(
      UsertypeSelectionSlideinComponent,
    );

    this.publishedNewsEventsForm = new FormGroup({
      publishedSorting: new FormControl(BLANK_VALUE, Validators.required),
      disableArticle: new FormControl(true),
    });
    this.draftNewsEventsForm = new FormGroup({
      draftSorting: new FormControl(BLANK_VALUE, Validators.required),
    });
    this.scheduledNewsEventsForm = new FormGroup({
      scheduledSorting: new FormControl(BLANK_VALUE, Validators.required),
    });
    this.modalTitle = new localeValPipe(new LocalizationService()).transform(
      NEWS_EVENTS.SELECT_ACCOUNT,
      this.lang,
    );
  }

  public ngOnInit(): void {
    this.showLoadingIcon = true;
    this.getCategoryList();
    this.getUserTypeList();
    this._getNewsEventsFromPortalDB();
    this._cdRef.detectChanges();
  }

  public changeSwitch(newsEvents: NewsEvents): void {
    if (newsEvents.disableArticle === true && newsEvents.disableArticle !== null) {
      newsEvents.disableArticle = false;
    } else {
      newsEvents.disableArticle = true;
    }
    this.disabledArticleId = newsEvents.id!;
    this.publishedList.forEach(element => {
      if (element.id !== newsEvents.id) {
        element.disableArticle = true;
      }
    });
    this._cdRef.detectChanges();
  }

  // time format for prefill form
  public editTimeFormat(date: string) {
    const dateObject: Date = new Date(date);
    const hours = dateObject.getHours().toString();
    const minutes = dateObject.getMinutes().toString();
    const hoursZerosPadded = (hours.length == 1 ? '0' : '') + hours;
    const minutesZerosPadded = (minutes.length == 1 ? '0' : '') + minutes;
    return `${hoursZerosPadded}${minutesZerosPadded}`;
  }

  public getFormatedStartDate(date: any) {
    const newdate = new Date(date);

    const formattedStartDate = this._dateFormatterService.getDDMMYYYYFormatDate(newdate);
    return formattedStartDate
      ? this._dateFormatterService.getAtlasDate(formattedStartDate)
      : undefined;
  }

  private _initializeAddEditArticleForm(formdata?: NewsEvents): void {
    const fs =
      formdata == undefined
        ? BLANK_VALUE
        : this.editTimeFormat(formdata.eventStartDate!) == undefined
        ? BLANK_VALUE
        : this.editTimeFormat(formdata.eventStartDate!);
    const fe =
      formdata == undefined
        ? BLANK_VALUE
        : this.editTimeFormat(formdata.eventEndDate!) == undefined
        ? BLANK_VALUE
        : this.editTimeFormat(formdata.eventEndDate!);

    const formattedStartTime = fs ? new Text(fs.toString()) : null;
    const formattedEndTime = fe ? new Text(fe.toString()) : null;

    const dateOfPublishing =
      formdata?.publishStartDate == undefined
        ? BLANK_VALUE
        : this.getFormatedStartDate(formdata?.publishStartDate);

    const timeOfPublishing =
      formdata?.publishStartDate == undefined
        ? BLANK_VALUE
        : this.editTimeFormat(formdata?.publishStartDate) == undefined
        ? BLANK_VALUE
        : this.editTimeFormat(formdata?.publishStartDate);

    const formattedTimeOfPublishing = timeOfPublishing
      ? new Text(timeOfPublishing.toString())
      : null;

    this.editableUserTypeList = formdata ? formdata.userTypes! : [];
    if (this.newsEventsForm.controls.newsEvents.value == this.newsValue) {
      if (this.ifEnglishLang()) {
        this.addEditArticleForm = this._formFactory.createFormManager<NewsEvents>({
          id: new FormControl(formdata?.id, Validators.required),
          articleCategory: new FormControl(formdata?.category?.id, Validators.required),
          userTypes: new FormControl(formdata?.userTypes, Validators.required),
          location: new FormControl(BLANK_VALUE, [
            Validators.required,
            Validators.pattern(TITLE_PATTERN),
          ]),
          title: new FormControl(formdata?.i18n?.en?.title, [
            Validators.required,
            Validators.maxLength(TITLE_LENGTH),
            Validators.pattern(TITLE_PATTERN),
          ]),
          description: new FormControl(formdata?.i18n?.en?.description, [
            Validators.required,
            Validators.maxLength(DESCRIPTION_LENGTH),
            Validators.pattern(DESCRIPTION_PATTERN),
          ]),
          addMediaFile: new FormControl(),
          scheduleLater: new FormControl(),
          dateOfPublishing: new FormControl(formdata?.published ? undefined : dateOfPublishing),
          timeOfPublishing: new FormControl(
            formdata?.published ? undefined : formattedTimeOfPublishing,
          ),
          gallery: new FormControl([]),
        });
      } else if (!this.ifEnglishLang()) {
        this.addEditArticleForm = this._formFactory.createFormManager<NewsEvents>({
          id: new FormControl(formdata?.id, Validators.required),
          articleCategory: new FormControl(formdata?.category?.id, Validators.required),
          userTypes: new FormControl(formdata?.userTypes, Validators.required),
          location: new FormControl(BLANK_VALUE, [
            Validators.required,
            Validators.pattern(TITLE_PATTERN),
          ]),
          title: new FormControl(formdata?.i18n?.bg?.title, [
            Validators.required,
            Validators.maxLength(TITLE_LENGTH),
            Validators.pattern(TITLE_PATTERN),
          ]),
          description: new FormControl(formdata?.i18n?.bg?.description, [
            Validators.required,
            Validators.maxLength(DESCRIPTION_LENGTH),
            Validators.pattern(DESCRIPTION_PATTERN),
          ]),
          addMediaFile: new FormControl(BLANK_VALUE),
          scheduleLater: new FormControl(),
          dateOfPublishing: new FormControl(formdata?.published ? undefined : dateOfPublishing),
          timeOfPublishing: new FormControl(
            formdata?.published ? undefined : formattedTimeOfPublishing,
          ),
          gallery: new FormControl([]),
        });
      }
    } else if (this.newsEventsForm.controls.newsEvents.value == this.eventsValue) {
      const startDate =
        formdata?.eventStartDate == undefined
          ? BLANK_VALUE
          : this.getFormatedStartDate(formdata?.eventStartDate);
      const endDate =
        formdata?.eventEndDate == undefined
          ? BLANK_VALUE
          : this.getFormatedStartDate(formdata?.eventEndDate);

      if (this.ifEnglishLang()) {
        this.addEditArticleForm = this._formFactory.createFormManager<NewsEvents>({
          id: new FormControl(formdata?.id, Validators.required),
          articleCategory: new FormControl(formdata?.category?.id, Validators.required),
          userTypes: new FormControl(formdata?.userTypes, Validators.required),
          location: new FormControl(formdata?.i18n?.en?.location, [
            Validators.required,
            Validators.pattern(TITLE_PATTERN),
          ]),
          organizer: new FormControl(formdata?.i18n?.en?.organizer, Validators.required),
          eventStartDate: new FormControl(startDate, Validators.required),
          eventEndDate: new FormControl(endDate, [Validators.required, pastDateValidator()]),
          eventStartTime: new FormControl(formattedStartTime, [
            Validators.required,
            eventStartTimeValidator(),
          ]),
          eventEndTime: new FormControl(formattedEndTime, [
            Validators.required,
            eventEndTimeValidator(),
          ]),
          title: new FormControl(formdata?.i18n?.en?.title, [
            Validators.required,
            Validators.maxLength(TITLE_LENGTH),
            Validators.pattern(TITLE_PATTERN),
          ]),
          description: new FormControl(formdata?.i18n?.en?.description, [
            Validators.required,
            Validators.maxLength(DESCRIPTION_LENGTH),
            Validators.pattern(DESCRIPTION_PATTERN),
          ]),
          addMediaFile: new FormControl(BLANK_VALUE),
          scheduleLater: new FormControl(),
          dateOfPublishing: new FormControl(formdata?.published ? undefined : dateOfPublishing),
          timeOfPublishing: new FormControl(
            formdata?.published ? undefined : formattedTimeOfPublishing,
          ),
          gallery: new FormControl([]),
        });
      } else if (!this.ifEnglishLang()) {
        this.addEditArticleForm = this._formFactory.createFormManager<NewsEvents>({
          id: new FormControl(formdata?.id, Validators.required),
          articleCategory: new FormControl(formdata?.category?.id, Validators.required),
          userTypes: new FormControl(formdata?.userTypes, Validators.required),
          location: new FormControl(formdata?.i18n?.en?.location, [
            Validators.required,
            Validators.pattern(TITLE_PATTERN),
          ]),
          organizer: new FormControl(formdata?.i18n?.en?.organizer, Validators.required),
          eventStartDate: new FormControl(BLANK_VALUE, Validators.required),
          eventEndDate: new FormControl(BLANK_VALUE, [Validators.required, pastDateValidator()]),
          eventStartTime: new FormControl(formattedStartTime, [
            Validators.required,
            eventStartTimeValidator(),
          ]),
          eventEndTime: new FormControl(formattedEndTime, [
            Validators.required,
            eventEndTimeValidator(),
          ]),
          title: new FormControl(formdata?.i18n?.bg?.title, [
            Validators.required,
            Validators.maxLength(TITLE_LENGTH),
            Validators.pattern(TITLE_PATTERN),
          ]),
          description: new FormControl(formdata?.i18n?.bg?.description, [
            Validators.required,
            Validators.maxLength(DESCRIPTION_LENGTH),
            Validators.pattern(DESCRIPTION_PATTERN),
          ]),
          addMediaFile: new FormControl(BLANK_VALUE),
          scheduleLater: new FormControl(),
          dateOfPublishing: new FormControl(formdata?.published ? undefined : dateOfPublishing),
          timeOfPublishing: new FormControl(
            formdata?.published ? undefined : formattedTimeOfPublishing,
          ),
          gallery: new FormControl(formdata?.gallery),
        });
      }
    }
    this._updateTimeFieldsOnChanges();
    const addEditFormVal = this.addEditArticleForm.controls.userTypes.value as IdInterface[];
    this.addEditArticleForm.formGroup.controls.scheduleLater.patchValue(
      this._getScheduleLaterValue(),
    );
    const tempUserTypeList: UserType[] = [];
    addEditFormVal?.forEach(element => {
      this.userTypeList.forEach(elem => {
        if (elem.id === element.id) {
          tempUserTypeList.push(elem);
        }
      });
    });
    this.selectedUserTypeList = tempUserTypeList;
    if (this.selectedUserTypeList.length > 0) {
      this.selectedUsers = this.getUserTypeValue();
    }
    this.fileUploadIdList = [];
    this.imagePreviewPaths = [];
    this.uploadedFilesBasic = [];

    // eslint-disable-next-line
    this.mediaObj = formdata?.gallery! as ArtticleMediaType[];
    this.mediaObj?.forEach(element => {
      this.fileUploadIdList.push(element.id!);
    });
    if (this.mediaObj !== undefined) {
      this.mediaObj.forEach(element => {
        const idRef: Hidden = new Hidden(element.id!.toString());
        const nameRef: Text = new Text(element.filename!);
        const typeRef: Text = new Text(element.type!);
        this.uploadedFilesBasic.push({id: idRef, name: nameRef, type: typeRef});
      });
    }
    this.getImageToPreview(this.fileUploadIdList);
    this.count_of_selected_user_type = addEditFormVal?.length!;
  }

  private _getScheduleLaterValue(): Boolean {
    this.scheduleLaterValue = this.isScheduledTabActive;
    return this.isScheduledTabActive ? Boolean.TRUE : Boolean.FALSE;
  }

  private _updateTimeFieldsOnChanges(): void {
    const formControls = this.addEditArticleForm.controls;
    this.addEditArticleForm.controls.eventStartDate?.valueChanges.subscribe(() => {
      this._updateFormControlValueAndValidity(formControls?.eventStartTime);
      this._updateFormControlValueAndValidity(formControls?.dateOfPublishing);
    });
    this.addEditArticleForm.controls.eventEndDate?.valueChanges.subscribe(() => {
      this._updateFormControlValueAndValidity(formControls?.eventEndTime);
      this._updateFormControlValueAndValidity(formControls?.dateOfPublishing);
    });
    this.addEditArticleForm.controls.eventStartTime?.valueChanges.subscribe(() => {
      this._updateFormControlValueAndValidity(formControls?.eventEndTime);
    });
    this.addEditArticleForm.controls.dateOfPublishing?.valueChanges.subscribe(() => {
      this._updateFormControlValueAndValidity(formControls?.timeOfPublishing);
    });
    this.addEditArticleForm.controls.scheduleLater?.valueChanges.subscribe((value: Boolean) => {
      this.addEditArticleForm.controls.timeOfPublishing.setValidators(
        value.asBoolean() ? [Validators.required, publishTimeValidator()] : null,
      );
      this.addEditArticleForm.controls.dateOfPublishing.setValidators(
        value.asBoolean() ? [Validators.required, pastDateValidator()] : null,
      );
    });
  }

  private _updateFormControlValueAndValidity(formControl: AbstractControl): void {
    formControl.setValue(formControl.value);
    formControl.updateValueAndValidity();
  }

  public getImageToPreview(fileUploadIdList: number[]): void {
    fileUploadIdList.forEach(imageId => {
      this._articleService.getMediaFile(imageId).subscribe(
        (res: ArtticleMediaType) => {
          if (res !== undefined) {
            if (res.type !== 'video/mp4') {
              const tempImageObj: ImagePreviewInterface = {
                id: res.id!,
                image: this._sanitizer.bypassSecurityTrustResourceUrl(
                  `data:image/jpg;base64, ${res.content}`,
                ),
                type: res.type!,
              };
              if (
                this.imagePreviewPaths.find(media => media.id === tempImageObj.id) === undefined
              ) {
                this.imagePreviewPaths.push(tempImageObj);
              }
            } else {
              const tempVideoObj: ImagePreviewInterface = {
                id: res.id!,
                image: this._sanitizer.bypassSecurityTrustResourceUrl(
                  `data:video/mp4;base64, ${res.content}`,
                ),
                type: res.type,
              };
              if (
                this.imagePreviewPaths.find(media => media.id === tempVideoObj.id) === undefined
              ) {
                this.imagePreviewPaths.push(tempVideoObj);
              }
            }
          }
        },
        error => {
          console.log('errorMessage: ', error);
        },
      );
    });
  }

  public removeImageFromPreview(id: string): void {
    const removeIndex = this.imagePreviewPaths.findIndex(
      (image: ImagePreviewInterface) => image.id === Number(id),
    );
    if (removeIndex > -1) {
      this.imagePreviewPaths.splice(removeIndex, 1);
    }
  }

  public ifEnglishLang(): boolean {
    if (this.lang === NEWS_EVENTS.ENGLISH) {
      return true;
    } else if (this.lang === NEWS_EVENTS.BULGARIAN) {
      return false;
    }
    return false;
  }

  public getCategoryList(): void {
    this._articleService.getCategoryList().subscribe(
      (res: CategoryInterface[]) => {
        this.categoryList = res;
        this.categoryList = this.categoryList.filter(category => {
          return category.i18n !== undefined;
        });
      },
      () => {
        this.showLoadingIcon = false;
      },
    );
  }

  public getUserTypeList(): void {
    this._articleService.getUserTypeList().subscribe(
      (res: UserType[]) => {
        this.userTypeList = res;
      },
      () => {
        this.showLoadingIcon = false;
      },
    );
  }

  private _getNewsEventsFromPortalDB(): void {
    this.showLoadingIcon = true;
    this._articleService
      .getArticleList()
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: NewsEvents[]) => {
            this.completeArticleData = result;
            this.sagregateArticles(result);
            this.showLoadingIcon = false;
          },
          () => {
            this.showLoadingIcon = false;
          },
        ),
      )
      .subscribe();
  }

  public sagregateArticles(articleData: NewsEvents[]): void {
    this.publishedNewsList = articleData.filter(
      eachEvent =>
        eachEvent.published === true &&
        (eachEvent.eventStartDate === null || eachEvent.eventStartDate === undefined),
    );
    if (this.value === this.newtoOldValue || this.value == undefined) {
      this._sortPublishedNewsListAscending();
    }

    this.publishedEventsList = articleData.filter(
      eachEvent =>
        eachEvent.published === true &&
        eachEvent.eventStartDate !== null &&
        eachEvent.eventStartDate !== undefined,
    );
    if (this.value === this.newtoOldValue || this.value === undefined) {
      this._sortPublishedEventsListAscending();
    }

    this.draftNewsList = articleData.filter(
      eachEvent =>
        eachEvent.published === false &&
        eachEvent.publishStartDate === null &&
        (eachEvent.eventStartDate === null || eachEvent.eventStartDate === undefined),
    );
    this.draftEventsList = articleData.filter(
      eachEvent =>
        eachEvent.published === false &&
        eachEvent.publishStartDate === null &&
        eachEvent.eventStartDate !== null &&
        eachEvent.eventStartDate !== undefined,
    );
    this.scheduledNewsList = articleData.filter(
      eachEvent =>
        eachEvent.published === false &&
        eachEvent.publishStartDate != null &&
        (eachEvent.eventStartDate === null || eachEvent.eventStartDate === undefined),
    );
    this.scheduledEventsList = articleData.filter(
      eachEvent =>
        eachEvent.published === false &&
        eachEvent.publishStartDate != null &&
        eachEvent.eventStartDate !== null &&
        eachEvent.eventStartDate !== undefined,
    );
    if (this.newsEventsForm.controls.newsEvents.value == this.newsValue) {
      this.publishedList = this.publishedNewsList;
      this.draftList = this.draftNewsList;
      this.scheduledList = this.scheduledNewsList;
    } else if (this.newsEventsForm.controls.newsEvents.value == this.eventsValue) {
      this.publishedList = this.publishedEventsList;
      this.draftList = this.draftEventsList;
      this.scheduledList = this.scheduledEventsList;
    }
    this.publishedList.forEach(element => {
      element.disableArticle = true;
      this.profileDisplay(element);
    });
    this.draftList.forEach(element => {
      this.profileDisplay(element);
    });
    this.scheduledList.forEach(element => {
      this.profileDisplay(element);
    });
    this._cdRef.detectChanges();
  }

  public publishedTabActive(): void {
    this.isPublishedTabActive = true;
    this.isDraftTabActive = false;
    this.isScheduledTabActive = false;
  }

  public draftTabActive(): void {
    this.isPublishedTabActive = false;
    this.isDraftTabActive = true;
    this.isScheduledTabActive = false;
  }

  public scheduledTabActive(): void {
    this.isPublishedTabActive = false;
    this.isDraftTabActive = false;
    this.isScheduledTabActive = true;
  }

  public deleteTile(index: any): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: NEWS_EVENTS.BLANK_VALUE, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this._articleService.deleteArticle(index).subscribe(() => {
            this._getNewsEventsFromPortalDB();
            this._cdRef.detectChanges();
          });
        }
      });
  }

  public handleSearchedText(text: Text): void {
    this.textSearched = text;
  }

  public handleInputText(text: Text): void {
    if (text === null) {
      this.textSearched = new Text(BLANK_VALUE);
      this.searchNews = BLANK_VALUE;
    }
  }

  //sort
  public get disabled(): Boolean {
    return this._disabled;
  }

  // ascending
  public publishednew(activeTab: any): void {
    const asc = 'ascending';

    switch (activeTab) {
      case 1:
        this.sortListNew(this.publishedList, asc);
        break;
      case 2:
        this.sortListNew(this.draftList, asc);
        break;
      case 3:
        this.sortListNew(this.scheduledList, asc);
        break;
    }
  }

  // descending
  public publishedold(activeTab: any): void {
    const desc = 'descending';

    switch (activeTab) {
      case 1:
        this.sortListNew(this.publishedList, desc);
        break;
      case 2:
        this.sortListNew(this.draftList, desc);
        break;
      case 3:
        this.sortListNew(this.scheduledList, desc);
        break;
    }
  }

  public sortListNew(list: NewsEvents[], type: any) {
    list.sort((a: NewsEvents, b: NewsEvents) => {
      const aSortKeyword = list == this.draftList ? a.createdAt : a.publishStartDate;
      const bSortKeyword = list == this.draftList ? b.createdAt : b.publishStartDate;

      const valueA = aSortKeyword == null ? 0 : aSortKeyword;
      const valueB = bSortKeyword == null ? 0 : bSortKeyword;

      return type == 'ascending' ? (valueA > valueB ? -1 : 0) : valueA < valueB ? -1 : 0;
    });
    this.sagregateArticles(list);
  }

  public pinNewsAndEvents(newsEvents: NewsEvents, index: number, pinUpdate: boolean): void {
    const slideinTitle = pinUpdate
      ? new localeValPipe(new LocalizationService()).transform(NEWS_EVENTS.UPDATE_PIN, this.lang)
      : new localeValPipe(new LocalizationService()).transform(NEWS_EVENTS.PIN, this.lang);
    this._slideInCtrl
      .prepare(
        this._newsAndEventsPinSlideInFactory,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: {
            isUpdatePin: pinUpdate,
            newsEvents: newsEvents,
          },
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<PinSlideOutput>) => {
        if (response.resolution === ModalResolution.CONFIRMED && newsEvents.id) {
          const pinDates = response.result.pinDates;
          const responseUnpinned = response.result.isUnpinned;
          if (
            pinDates &&
            pinDates.pinStartDate != null &&
            pinDates.pinEndDate != null &&
            !responseUnpinned
          ) {
            this.selectedIndex = newsEvents.id;
            this.pinDates = {
              startDate: pinDates.pinStartDate,
              endDate: pinDates.pinEndDate,
            };
            this._articleService
              .postArticlePin(this.pinDates, this.selectedIndex)
              .subscribe((res: PinDates[]) => {
                newsEvents.pinStartDate = pinDates.pinStartDate;
                newsEvents.pinEndDate = pinDates.pinEndDate;
              });
            this.pinned = false;
            newsEvents.pinned = true;
            for (let i = 0; i < this.publishedList.length; i++) {
              const clickedNewsEventId = this.publishedList[i]?.id;
              if (newsEvents.id !== clickedNewsEventId && clickedNewsEventId) {
                this.unpinNewsEvent(this.publishedList[i]);
              }
            }
          } else if (pinDates && pinDates.pinStartDate == null && pinDates.pinEndDate == null) {
            this.selectedIndex = index;
            this.pinned = false;
          } else if (responseUnpinned) {
            this.unpinNewsEvent(newsEvents);
          }
        }
        this._cdRef.detectChanges();
      });
  }

  public unpinNewsEvent(newsEvent: NewsEvents): void {
    if (newsEvent.id) {
      this._articleService.deletePinArticle(newsEvent.id).subscribe((res: PinDates) => {
        newsEvent.pinStartDate = undefined;
        newsEvent.pinEndDate = undefined;
      });
      newsEvent.pinned = false;
    }
  }

  public filterNewsAndEvents(newsEvents?: NewsEvents[], editFilter1?: string): void {
    const filterInputVal: FilterInputInterface = {
      editFilter: editFilter1,
      newsEvents: newsEvents,
      categoryList: this.categoryList,
      userTypeList: this.userTypeList,
    };

    this.tagsCreated = 1;
    this._getNewsEventsFromPortalDB();
    this.regularTagList = [];
    const filterTitle = editFilter1
      ? new localeValPipe(new LocalizationService()).transform(editFilter1, this.lang)
      : new localeValPipe(new LocalizationService()).transform(NEWS_EVENTS.FILTER, this.lang);
    this._slideInCtrl
      .prepare(
        this._newsAndEventsFilterSlideInFactory,
        this._injector,
        {
          title: filterTitle,
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: filterInputVal,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<filterResponseInterface>) => {
        if (res.result === undefined) {
          this.sagregateArticles(this.completeArticleData);
        } else {
          this.getCategoryList();
          this.onFilterDataUpdate(res.result, newsEvents);
        }
        this._cdRef.detectChanges();
      });
  }

  public onFilterDataUpdate(response?: filterResponseInterface, newsEventObj?: NewsEvents[]): void {
    const filteredDataList: NewsEvents[] = [];
    let filterString1: string = BLANK_VALUE;
    let filteredData1: NewsEvents[] = [];
    const filteredData2: NewsEvents[] = [];
    response?.categoryList.forEach(category => {
      if (this.tagsCreated === 1) {
        if (this.ifEnglishLang()) {
          filterString1 = category.i18n!.en.title!;
        } else if (!this.ifEnglishLang()) {
          filterString1 = category.i18n!.bg.title!;
        }
        this.tagFunc(filterString1, newsEventObj!);
      }
      filteredData1 = newsEventObj!.filter(element => element.category?.id === category.id);
    });
    let filterString2: string = BLANK_VALUE;
    response?.userTypeList.forEach(usertype => {
      if (this.tagsCreated === 1) {
        if (this.ifEnglishLang() && usertype.i18n) {
          filterString2 = usertype.i18n.en!.title!;
        } else if (!this.ifEnglishLang() && usertype.i18n) {
          filterString2 = usertype.i18n.bg!.title!;
        }
        this.tagFunc(filterString2, newsEventObj!);
      }
      newsEventObj!.forEach(element =>
        element.userTypes?.forEach((ele: UserType) => {
          if (ele.id === usertype.id) {
            filteredData2.push(element);
          }
        }),
      );
    });
    if (filteredData1.length > 0 || filteredData2.length > 0) {
      const filterData = [filteredData1, filteredData2];
      filterData.forEach(filterVal => {
        filterVal.forEach(obj => {
          let objExist: number = 0;
          filteredDataList.forEach(ele => {
            if (ele.id == obj.id) {
              objExist = 1;
            }
          });
          if (objExist === 0) {
            filteredDataList.push(obj);
          }
        });
      });
    }
    this.tagsCreated = 0;
    this.sagregateArticles(filteredDataList);
    this._cdRef.detectChanges();
  }

  //filter tags
  public tagFunc(filterRes: string, newsEventObj: NewsEvents[]): void {
    const regularTagOne = new Tag({
      displayedValue: filterRes.toString(),
      value: filterRes,
      close: () => {
        const index: number = this.regularTagList.indexOf(regularTagOne);
        this.regularTagList.splice(index, 1);
        const filterData: any[] = [];
        this.regularTagList.forEach(ele => {
          filterData.push(ele.value);
        });
        const filterDataList: filterResponseInterface = {
          categoryList: [],
          userTypeList: [],
        };
        filterData.forEach(value => {
          this.categoryList.forEach(elem => {
            if (elem.i18n?.en.title === value || elem.i18n?.bg.title === value) {
              filterDataList.categoryList.push(elem);
            }
          });
          this.userTypeList.forEach(elem => {
            if (elem.userTypeId === value) {
              filterDataList.userTypeList.push(elem);
            }
          });
        });
        if (filterDataList.categoryList.length > 0 || filterDataList.userTypeList.length > 0) {
          this.onFilterDataUpdate(filterDataList, newsEventObj);
        }
        if (this.regularTagList.length === 0) {
          this.sagregateArticles(this.completeArticleData);
          this._cdRef.detectChanges();
        }
      },
    });
    this.regularTagList.push(regularTagOne);
    this._cdRef.detectChanges();
  }

  public addEditNewsEvents(news_event?: NewsEvents): void {
    if (news_event === undefined || news_event === null) {
      this.selectedUsers = BLANK_VALUE;
    }

    this._initializeAddEditArticleForm(news_event);
    this.editableObj = news_event;
    this.articleFrontPage = false;
    this.addEditArticle = true;
    this.articleFrontPage = false;
  }

  public cancelEvents(): void {
    this._popUpCtrl
      .prepare(
        this._popupnewsandEvents,
        this._injector,
        {
          title: new localeValPipe(new LocalizationService()).transform(
            POPUP_NEWS_EVENTS.WARNING,
            this.lang,
          ),
          footerInScrollArea: true,
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.cancelMsg,
        },
      )
      .subscribe(() => {
        this.viewPreviewVal = false;
        this.addEditArticle = false;
        this.articleFrontPage = true;
        this._cdRef.detectChanges();
      });
  }

  public closeAllList(): void {
    this.regularTagList = [];
    this._getNewsEventsFromPortalDB();
  }

  public onChangeOfStartDate(): void {
    const startDateValue = this.addEditArticleForm.controls.eventStartDate.value;
    if (startDateValue instanceof AtlasDate) {
      // Get the difference between today's date and the selected fromDate
      const diffBtnFromAndToDates = DateUtils.diff(
        DateUtils.today(),
        this.addEditArticleForm.controls.eventStartDate.value,
        DateKey.Day,
      );
      // For toDate, the maximum selectable date is today's date and the minimum POSSIBLE selectable date is the value of fromDate
      this.minEventEndDate = DateUtils.add(DateUtils.today(), DateKey.Day, diffBtnFromAndToDates);
    }
  }

  protected saveMtplData(): void {
    this.publishedList = this.draftList;
  }

  public ifEventSelected(): boolean {
    if (this.newsEventsForm.controls.newsEvents.value === this.eventsValue) {
      this.eventPage = true;
      return true;
    }
    return false;
  }

  public assignFormValueToObj(): NewsEvents {
    const formValue = this.addEditArticleForm.formGroup.value as NewsEvents;
    this.addEditArticleFormData.id = formValue.id;
    this.addEditArticleFormData.typeId = this.selectedUserTypeList[0].id;
    this.addEditArticleFormData.categoryId = formValue.articleCategory;
    this.addEditArticleFormData.userTypes = this.selectedUserTypeListFunc();
    this.addEditArticleFormData.gallery = this.fileUploadIdList;
    if (this.newsEventsForm.controls.newsEvents.value == this.eventsValue) {
      const timeValue1: Text = this.addEditArticleForm.controls.eventStartTime.value as Text;
      const timeValue2: Text = this.addEditArticleForm.controls.eventEndTime.value as Text;
      this.addEditArticleFormData.eventStartDate = this.getFormatedDateTime(
        formValue.eventStartDate,
        timeValue1,
      );
      this.addEditArticleFormData.eventEndDate = this.getFormatedDateTime(
        formValue.eventEndDate,
        timeValue2,
      );
    }
    this.addEditArticleFormData.published = false;
    this.addEditArticleFormData.scheduleLater = formValue.scheduleLater;
    if (this.ifEnglishLang()) {
      this.addEditArticleFormData.i18n!.en!.title = formValue.title;
      this.addEditArticleFormData.i18n!.en!.description = formValue.description;
      this.addEditArticleFormData.i18n!.en!.location = formValue.location;
      this.addEditArticleFormData.i18n!.en!.organizer = formValue.organizer;
    } else if (!this.ifEnglishLang()) {
      this.addEditArticleFormData.i18n!.bg!.title = formValue.title;
      this.addEditArticleFormData.i18n!.bg!.description = formValue.description;
      this.addEditArticleFormData.i18n!.bg!.location = formValue.location;
      this.addEditArticleFormData.i18n!.bg!.organizer = formValue.organizer;
    }
    return this.addEditArticleFormData;
  }

  public getFormatedDateTime(date: any, time: Text): string {
    const normalizedDate = this._dateFormatterService.normalizeDate(date);
    const hours = getHourMinute(time, Time.Hour);
    const minutes = getHourMinute(time, Time.Minute);
    if (hours !== null && minutes !== null) {
      normalizedDate?.setHours(hours);
      normalizedDate?.setMinutes(minutes);
    }
    return normalizedDate!.toISOString();
  }

  public selectedUserTypeListFunc(): number[] {
    const returnableList: number[] = [];
    this.selectedUserTypeList.forEach(element => {
      returnableList.push(element.id!);
    });
    return returnableList;
  }

  public publishOrSchedule(): void {
    if (!this.scheduleLaterValue) {
      this._popUpCtrl
        .prepare(
          this._popupnewsandEvents,
          this._injector,
          {
            title: new localeValPipe(new LocalizationService()).transform(
              POPUP_NEWS_EVENTS.PUBLISH,
              this.lang,
            ),
            footerInScrollArea: true,
          },
          {
            withVisibleBackdrop: true,
            withClickableBackdrop: true,
            input: this.publishMsg,
          },
        )
        .subscribe(
          (response: ModalConfirmedResult<void>) => {
            if (response.resolution === ModalResolution.CONFIRMED) {
              const articleObj: NewsEvents = this.assignFormValueToObj();
              articleObj.published = true;
              articleObj.publishStartDate = new Date().toISOString();
              this.callAddArticleService(articleObj);
              this.selectedUsers = BLANK_VALUE;
              this.cancelAddEdit();
              this._cdRef.detectChanges();
            }
          },
          () => {
            this.showLoadingIcon = false;
          },
        );
    } else {
      this._popUpCtrl
        .prepare(
          this._popupnewsandEvents,
          this._injector,
          {
            title: new localeValPipe(new LocalizationService()).transform(
              POPUP_NEWS_EVENTS.SCHEDULE,
              this.lang,
            ),
            footerInScrollArea: true,
          },
          {
            withVisibleBackdrop: true,
            withClickableBackdrop: true,
            input: this.schedulehMsg,
          },
        )
        .subscribe((response: ModalConfirmedResult<void>) => {
          if (response.resolution === ModalResolution.CONFIRMED) {
            this.showLoadingIcon = true;
            const articleObj: NewsEvents = this.assignFormValueToObj();
            const timeOfPublishingValue: Text = this.addEditArticleForm.controls.timeOfPublishing
              .value as Text;
            articleObj.publishStartDate = this.getFormatedDateTime(
              this.addEditArticleForm.controls.dateOfPublishing.value,
              timeOfPublishingValue,
            );
            this.callAddArticleService(articleObj);
            this.selectedUsers = BLANK_VALUE;
            this.cancelAddEdit();
            this._cdRef.detectChanges();
            this.showLoadingIcon = false;
          }
        });
    }
  }

  public saveAsDraft(): void {
    this._popUpCtrl
      .prepare(
        this._popupnewsandEvents,
        this._injector,
        {
          title: new localeValPipe(new LocalizationService()).transform(
            POPUP_NEWS_EVENTS.SAVE_AS_DRAFT,
            this.lang,
          ),
          footerInScrollArea: true,
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.saveAsDraftMsg,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this.showLoadingIcon = true;
          const articleObj: NewsEvents = this.assignFormValueToObj();
          articleObj.published = false;
          articleObj.publishStartDate = undefined;
          this.callAddArticleService(articleObj);
          this.selectedUsers = BLANK_VALUE;
          this.cancelAddEdit();
          this.viewPreviewVal = false;
          this.addEditArticle = false;
          this.articleFrontPage = true;
          this.draftTabActive();
          this.showLoadingIcon = false;
          this._cdRef.detectChanges();
        }
      });
  }

  public callAddArticleService(articleObj: NewsEvents): void {
    if (articleObj.id != null || articleObj.id != undefined) {
      this._articleService.editArticle(articleObj).subscribe(
        () => {
          this._getNewsEventsFromPortalDB();
          this._cdRef.detectChanges();
        },
        () => {
          this.showLoadingIcon = false;
        },
      );
    } else {
      this._articleService.addArticle(articleObj).subscribe(
        () => {
          this._getNewsEventsFromPortalDB();
          this._cdRef.detectChanges();
        },
        () => {
          this.showLoadingIcon = false;
        },
      );
    }
  }

  public selectUsertype(): void {
    this._slideInCtrl
      .prepare(
        this._userTypeSelectSlideInFactory,
        this._injector,
        {
          title: new localeValPipe(new LocalizationService()).transform(
            NEWS_EVENTS.SELECT_ACCOUNT,
            this.lang,
          ),
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.selectedUserTypeList,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<UserType[]>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this.selectedUserTypeList = response.result;
          if (response.result?.length !== undefined) {
            this.selectedUsers = this.getUserTypeValue();
          }
          this._cdRef.detectChanges();
        }
      });
  }

  public scheduleLaterFunc(internalVal: boolean): void {
    this.scheduleLaterValue = internalVal;
  }

  public viewPreview(): void {
    this.assignFormValueToObj();
    if (this.imagePreviewPaths.length > 0) {
      this.enlargedImage = this.imagePreviewPaths[0];
    }
    if (this.addEditArticleFormData.eventStartDate !== undefined) {
      this.previewStartDate = this.addEditArticleFormData.eventStartDate.slice(0, 10);
      this.previewEndDate = this.addEditArticleFormData.eventEndDate!.slice(0, 10);
      this.previewStartTime = this.addEditArticleFormData.eventStartDate.slice(11, 16);
      this.previewEndTime = this.addEditArticleFormData.eventEndDate!.slice(11, 16);
    }
    this.viewPreviewVal = true;
    this.addEditArticle = false;
    this.articleFrontPage = false;
  }

  public goBack(): void {
    this.viewPreviewVal = false;
    this.addEditArticle = true;
    this.articleFrontPage = false;
  }

  public cancelAddEdit(): void {
    this.viewPreviewVal = false;
    this.addEditArticle = false;
    this.articleFrontPage = true;
  }

  public onArticleChange(): void {
    this._getNewsEventsFromPortalDB();
    this._cdRef.detectChanges();
  }

  public imageClicked(image: any): void {
    this.enlargedImage = image;
  }

  public profileDisplay(news_events?: NewsEvents): void {
    if (news_events!.gallery!.length > 0) {
      let image: any;
      news_events?.gallery?.forEach(ele => {
        this._articleService.getMediaFile(ele.id).subscribe(res => {
          if (res.type !== 'video/mp4' && image === undefined) {
            image = this._sanitizer.bypassSecurityTrustResourceUrl(
              `data:image/jpg;base64, ${res.content}`,
            );
            news_events.profileImageValue = image;
            this._cdRef.detectChanges();
          }
        });
      });
    }
  }

  public updateFlag(flag: string): void {
    this._apiService.chooseFileSubject.next(flag);
  }

  public getCategoryListValue(value: number): string | undefined {
    const respectiveCategory = this.categoryList
      ? this.categoryList.find(options => options.id == value)
      : null;
    if (respectiveCategory) {
      return this.ifEnglishLang()
        ? respectiveCategory.i18n?.en.title
        : respectiveCategory.i18n?.bg.title;
    }
    return '';
  }

  public getUserTypeValue(): string | undefined {
    return this.selectedUserTypeList.length > 1
      ? `${this.selectedUserTypeList.length} Accounts Selected`
      : this.ifEnglishLang()
      ? this.selectedUserTypeList[0]?.i18n?.en?.title
      : this.selectedUserTypeList[0]?.i18n?.bg?.title;
  }

  public isFormValid(pageType: any) {
    switch (pageType) {
      case 1:
        return this.addEditArticleForm.controls.articleCategory.errors ||
          this.addEditArticleForm.controls.userTypes.errors ||
          this.addEditArticleForm.controls.title.errors ||
          this.addEditArticleForm.controls.description.errors
          ? true
          : false;

      case 2:
        return this.addEditArticleForm.controls.articleCategory.errors ||
          this.addEditArticleForm.controls.userTypes.errors ||
          this.addEditArticleForm.controls.title.errors ||
          this.addEditArticleForm.controls.description.errors ||
          this.addEditArticleForm.controls.location.errors ||
          this.addEditArticleForm.controls.organizer.errors ||
          this.addEditArticleForm.controls.eventStartDate.errors ||
          this.addEditArticleForm.controls.eventEndDate.errors ||
          this.addEditArticleForm.controls.eventStartTime.errors ||
          this.addEditArticleForm.controls.eventEndTime.errors
          ? true
          : false;

      case 3:
        return this.addEditArticleForm.controls.articleCategory.errors ||
          this.addEditArticleForm.controls.userTypes.errors ||
          this.addEditArticleForm.controls.title.errors ||
          this.addEditArticleForm.controls.description.errors ||
          this.addEditArticleForm.controls.dateOfPublishing.errors ||
          this.addEditArticleForm.controls.timeOfPublishing.errors
          ? true
          : false;

      case 4:
        return this.addEditArticleForm.controls.articleCategory.errors ||
          this.addEditArticleForm.controls.userTypes.errors ||
          this.addEditArticleForm.controls.location.errors ||
          this.addEditArticleForm.controls.organizer.errors ||
          this.addEditArticleForm.controls.eventStartDate.errors ||
          this.addEditArticleForm.controls.eventEndDate.errors ||
          this.addEditArticleForm.controls.eventStartTime.value == BLANK_VALUE ||
          this.addEditArticleForm.controls.eventEndTime.value == BLANK_VALUE ||
          this.addEditArticleForm.controls.eventStartTime.errors != null ||
          this.addEditArticleForm.controls.eventEndTime.errors != null ||
          this.addEditArticleForm.controls.title.errors ||
          this.addEditArticleForm.controls.description.errors ||
          this.addEditArticleForm.controls.dateOfPublishing.value == BLANK_VALUE ||
          this.addEditArticleForm.controls.timeOfPublishing.value == BLANK_VALUE ||
          this.addEditArticleForm.controls.dateOfPublishing.errors != null ||
          this.addEditArticleForm.controls.timeOfPublishing.errors != null ||
          this.addEditArticleForm.controls.eventStartTime.errors ||
          this.addEditArticleForm.controls.eventEndTime.errors
          ? true
          : false;
    }
    return false;
  }

  private _sortPublishedNewsListAscending(): void {
    this.publishedNewsList = this.publishedNewsList.sort(function (a: NewsEvents, b: NewsEvents) {
      return +new Date(b.publishStartDate!) - +new Date(a.publishStartDate!);
    });
  }

  private _sortPublishedEventsListAscending(): void {
    this.publishedEventsList = this.publishedEventsList.sort(function (
      a: NewsEvents,
      b: NewsEvents,
    ) {
      return +new Date(b.publishStartDate!) - +new Date(a.publishStartDate!);
    });
  }
}
