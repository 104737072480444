import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncPercentageCoinsSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncPercentageCoins]',
  template: '<svg:g><svg:path d="M2.64 4.84v2.61c0 1.07 1.75 1.94 3.91 1.94s3.9-.87 3.9-1.94V4.84" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M2.64 7.45v2.6c0 1.08 1.75 1.95 3.91 1.95s3.9-.88 3.9-1.95v-2.6" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M10.45 12.26v-2.21"></svg:path><svg:path d="M2.64 10.05v2.6c0 1.08 1.75 1.95 3.91 1.95a6.79 6.79 0 0 0 2-.26" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M2.64 12.65v2.61c0 1.07 1.75 2 3.91 2a7.17 7.17 0 0 0 2-.27" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:ellipse cx="6.55" cy="4.84" rx="3.91" ry="1.95" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:ellipse><svg:path d="M8.5 14v2.61c0 1.08 1.75 2 3.91 2s3.9-.88 3.9-2V14" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M8.5 16.56v2.6c0 1.08 1.75 1.95 3.91 1.95s3.9-.87 3.9-1.95v-2.6" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:ellipse cx="12.41" cy="13.95" rx="3.91" ry="1.95" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:ellipse><svg:circle cx="16.07" cy="4.11" r="1.22" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle><svg:circle cx="20.14" cy="8.18" r="1.22" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M15.66 8.58l4.88-4.88"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncPercentageCoinsSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncPercentageCoinsSvgComponent,
  ],
  exports: [
    InformationFuncPercentageCoinsSvgComponent,
  ],
})
export class InformationFuncPercentageCoinsSvgModule {}
