import {Component, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {Observable, of} from 'rxjs';
import {MOCK_DEDUCTIBLE_OPTIONS} from './__tests__/price-covers-deductible-slidein-mock-data';
import {
  DeductibleRadio,
  DeductibleSlideInOutput,
} from './__tests__/price-covers-deductible-slidein-mock-interface';

@Component({
  selector: 'nje-price-covers-deductible-slidein',
  templateUrl: './price-covers-deductible-slidein.component.html',
  styleUrls: ['./price-covers-deductible-slidein.component.scss'],
})
export class PriceCoversDeductibleSlideinComponent
  extends ModalContentComponent<DeductibleSlideInOutput, number>
  implements OnInit
{
  public deductibleAmount: DeductibleRadio;
  public otherAmount: string;
  public selectedAmount: number;
  public deductibleOptions$: Observable<DeductibleRadio[]>;

  public constructor(public control: ModalControl<DeductibleSlideInOutput, number>) {
    super();
  }

  public ngOnInit(): void {
    this.initialiseApiCalls();
  }

  public initialiseApiCalls(): void {
    this.deductibleOptions$ = this.getOptionsList();
  }

  public sendOption(): void {
    if (this.deductibleAmount) {
      switch (this.deductibleAmount.id) {
        case 0:
          this.selectedAmount = 150;
          break;
        case 1:
          this.selectedAmount = 300;
          break;
        case 2:
          this.selectedAmount = parseFloat(this.otherAmount);
          break;
      }
    }
    const id: number = this.control.input;
    const output: DeductibleSlideInOutput = {
      id: id,
      amount: this.selectedAmount,
    };
    this.control.confirm(output);
  }

  public cancelSlideIn(): void {
    this.control.cancel();
  }

  public isAddDesabled(): boolean {
    if (this.deductibleAmount) {
      if (this.deductibleAmount.id === 0 || this.deductibleAmount.id === 1) {
        return false;
      } else if (this.deductibleAmount.id === 2 && !isNaN(parseFloat(this.otherAmount))) {
        return false;
      }
    }
    return true;
  }

  public getOptionsList(): Observable<DeductibleRadio[]> {
    return of(MOCK_DEDUCTIBLE_OPTIONS);
  }
}
