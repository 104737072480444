import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialFuncPeopleDuoSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendSocialFuncPeopleDuo]',
  template: '<svg:path d="M7.38 11.25C5.51 11.25 4 9 4 7.12a3.38 3.38 0 0 1 6.76 0c0 1.88-1.51 4.13-3.38 4.13z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M9.36 20.26H2.5V16a2.22 2.22 0 0 1 1-1.89 7.06 7.06 0 0 1 3.86-.95 9 9 0 0 1 2.63.36" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M16.46 11.25c-1.87 0-3.38-2.26-3.38-4.13a3.38 3.38 0 1 1 6.76 0c0 1.88-1.52 4.13-3.38 4.13z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M16.46 20.26h-4.88V16a2.22 2.22 0 0 1 1-1.89A7.48 7.48 0 0 1 16.5 13" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M16.5 13a7.77 7.77 0 0 1 4 1.08 2.22 2.22 0 0 1 1 1.89v4.29H12" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialFuncPeopleDuoSvgComponent {
}

@NgModule({
  declarations: [
    SocialFuncPeopleDuoSvgComponent,
  ],
  exports: [
    SocialFuncPeopleDuoSvgComponent,
  ],
})
export class SocialFuncPeopleDuoSvgModule {}
