<div class="scan-form-wrapper form">
  <div class="scan-form">
    <svg b2bFrontendInformationExprQr class="icon-adjust"></svg>
    <maia-label class="b2b-kdl-font-grand-serif title-dark-color step-in-txt"
      >Setup Google Authenticator</maia-label
    >
    <maia-label class="b2b-kdl-font-normal verify-in-txt"
      >1 Tap <span class="b2b-kdl-font-normal-bold">Menu</span> Then
      <span class="b2b-kdl-font-normal-bold">Set up account</span></maia-label
    >
    <maia-label class="b2b-kdl-font-normal verify-in-txt"
      >2 Tap <span class="b2b-kdl-font-normal-bold">Enter provided key</span></maia-label
    >
    <maia-label class="b2b-kdl-font-normal verify-in-txt"
      >3 Enter your email address and :<span class="b2b-kdl-font-normal-bold"
        >this key</span
      ></maia-label
    >
    <div class="barcode">
      <maia-label class="b2b-kdl-font-normal scan-text-code"
        >s7ol 2ffw 6xf3 35vo pneo pvkw mcaw vwzc</maia-label
      >
      <maia-label class="b2b-kdl-font-extra-small-light scan-text-space"
        >(spaces don’t matter)</maia-label
      >
    </div>
    <maia-button-group class="m-t-16 m-b-8 button-wrapper">
      <button maiaButton maiaButtonType="primary" id="scanForm" (click)="onContinue()">
        Continue
      </button>
    </maia-button-group>
  </div>
</div>
