import {Component, Input, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {POPUP_NEWS_EVENTS} from '../../../../../constants/resource-management/contacts';
import {PopUpContent} from '../../../../../contracts/NewsEvents';

@Component({
  selector: 'nje-popup-news',
  templateUrl: './popup-news.component.html',
  styleUrls: ['./popup-news.component.scss'],
})
export class PopupNewsComponent
  extends ModalContentComponent<void, PopUpContent>
  implements OnInit
{
  @Input() public title = POPUP_NEWS_EVENTS.PUBLISH;
  @Input() public message = POPUP_NEWS_EVENTS.PUBLISH_NEWS_EVENTS;
  @Input() public footerNote = POPUP_NEWS_EVENTS.BLANK_VALUE;

  public constructor(public control: ModalControl<void, PopUpContent>) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this._initializePopUpContent(data);
  }

  private _initializePopUpContent(popUpContent?: PopUpContent): void {
    this.title = popUpContent?.title ?? POPUP_NEWS_EVENTS.BLANK_VALUE;
    this.message = popUpContent?.message ?? POPUP_NEWS_EVENTS.BLANK_VALUE;
    this.footerNote = popUpContent?.footerNote ?? POPUP_NEWS_EVENTS.BLANK_VALUE;
  }

  public confirm(): void {
    this.control.confirm();
  }

  public cancel(): void {
    this.control.cancel();
  }
}
