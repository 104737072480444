<div class="account-status-wrapper">
  <div class="title-wrapper m-t-b-24">
    <h3 class="b2b-kdl-font-medium-bold b2b-kdl-color-primary-dark-120">Account Statuses</h3>
  </div>
  <div class="searchbox">
    <nje-search-box (searchData)="searchAccounts($event)"> </nje-search-box>
  </div>
  <div class="tabs-wrapper">
    <maia-tab-nav id="tab-nav2" class="b2b-kdl-color-primary-dark-120 b2b-kdl-font-small-bold">
      <maia-tab title="Active Accounts" [active]="tabIndex === 0" (click)="tabIndex = 0"></maia-tab>
      <maia-tab
        title="Suspended Accounts"
        [active]="tabIndex === 1"
        (click)="tabIndex = 1"
        class="b2b-kdl-color-primary-dark-120"
      ></maia-tab>
      <maia-tab
        title="Deactive Accounts"
        [active]="tabIndex === 2"
        (click)="tabIndex = 2"
      ></maia-tab>
    </maia-tab-nav>
  </div>
</div>
