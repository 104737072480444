<div class="blue-box b2b-kdl-font-small m-b-24" *ngIf="step.inEdit | async">
  Changing values will result in premium recalulation
</div>
<njf-loader [hidden]="!showLoadingIcon"></njf-loader>
<div class="title-panel-error" *ngIf="!isInvalidFile">
  <svg b2bFrontendAlertFuncExclamationCircle class="ex-icon"></svg>
  <div>
    <maia-label class="b2b-kdl-font-small">
      The file is invalid. Please fix the errors to proceed.
    </maia-label>
  </div>
</div>

<maia-title-panel *ngIf="isNumberOfPeopleMismatched" class="title-panel-warning">
  <svg b2bFrontendAlertFuncExclamationTriangle maia-title-panel-icon></svg>
  <div>
    <maia-label class="b2b-kdl-font-small">
      The number of insured people does not match the number entered in trip detail screen. Kindly
      update the table below or update the number of insured people in trip detail step to proceed
      further.
    </maia-label>

    <button maiaButton class="adjust-button" (click)="navigateToTripDeail()"
      >Update trip details</button
    >
  </div>
</maia-title-panel>

<div class="page-info b2b-kdl-font-small title-dark-color m-t-24">
  Showing {{ getNumberOfRecords() }} of {{ totalRecords }}
</div>
<div class="header-info">
  <div class="add-new-icon" *ngIf="!disableButtons">
    <svg b2bFrontendActionFuncPlusCircle (click)="openInsuredPersonSlideIn()"></svg>
  </div>
  <!-- disabling for uploading file option-->
  <div class="add-new-icon-disable" *ngIf="disableButtons">
    <svg b2bFrontendActionFuncPlusCircle (click)="openInsuredPersonSlideIn()"></svg>
  </div>

  <div class="add-new-button">
    <button
      maiaButton
      maiaButtonType="tertiary"
      (click)="openInsuredPersonSlideIn()"
      [disabled]="disableButtons"
      >Add person</button
    >
  </div>

  <div class="upload-icon" *ngIf="!disableUploadButton">
    <svg b2bFrontendFileExprDocumentUpload></svg>
  </div>
  <!-- disabling for uploading file option-->
  <div class="upload-icon-disable" *ngIf="disableUploadButton">
    <svg b2bFrontendFileExprDocumentUpload></svg>
  </div>

  <div class="upload-button">
    <button
      maiaButton
      maiaButtonType="tertiary"
      [disabled]="disableUploadButton"
      (click)="openPopup()"
    >
      Upload file
    </button>
    <input
      #docInput
      type="file"
      class="hide-input-file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      (change)="onFileChange($event)"
      [disabled]="disableButtons"
    />
  </div>
</div>

<div class="search-row">
  <maia-search-bar
    (searchText)="handleSearchedText($event)"
    (inputText)="handleInputText($event)"
    [(ngModel)]="searchValues"
    ngDefaultControl
    [ngModelOptions]="{standalone: true}"
    placeholder="Search"
    class="search-box"
  >
  </maia-search-bar>

  <maia-input-select
    id="select_one"
    name="simple"
    slideInTitle="Select simple value"
    class="search-dropdown"
    [(ngModel)]="value"
  >
    <svg b2bFrontendActionFuncSort class="func-sort"></svg>
    <input id="input_one" maiaInput class="b2b-kdl-font-small-bold" [value]="value || null" />

    <maia-option
      id="optionOne0"
      class="option_one"
      value="А а tо Я я"
      (click)="ascendingOrderFilter()"
      >А а то Я я</maia-option
    >
    <maia-option
      id="optionOne1"
      class="option_one"
      value="Я я tо А а"
      (click)="descendingOrderFilter()"
      >Я я то А а</maia-option
    >
  </maia-input-select>
</div>

<maia-divider *ngIf="!(step.inEdit | async)"></maia-divider>

<div>
  <maia-table id="tableCustomCss">
    <maia-table-header id="tableHeader3">
      <maia-table-row id="tableRowHeader3">
        <maia-table-cell
          class="b2b-kdl-font-small subtitle-light-color"
          *ngFor="let column of tableColumns; let i = index"
        >
          {{ columnHeadings[column] }}
        </maia-table-cell>
        <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall>
          <div class="left" *ngIf="!disableButtons">
            <svg class="delete-icon" b2bFrontendActionFuncBin (click)="removeAllPerson()"></svg>
          </div>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-header>

    <maia-table-body>
      <maia-table-row *ngIf="addedInsuredPersonArray.length == 0">
        <maia-contextual-message class="table-content b2b-kdl-font-small title-dark-color">
          No insured people records available
        </maia-contextual-message>
      </maia-table-row>
      <maia-table-row
        [id]="'tableRow' + '3'"
        *ngFor="
          let person of addedInsuredPersonArray.slice(start, end)
            | TravelInsuredPeopleSearchPipe: searchValues;
          let i = index
        "
        blueOnSmall
      >
        <maia-table-cell>
          <button maiaButton maiaButtonType="tertiary" (click)="editPerson(person, i)">{{
            person.idNumber
          }}</button>
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ person.firstName }} {{ person.middleName }} {{ person.lastName }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ person.firstNameEn }} {{ person.middleNameEn }} {{ person.lastNameEn }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ travelPolicyService.getPhoneNumber(person) }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ travelPolicyService.getCitizenship(person) }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ travelPolicyService.getDOB(person) }}
        </maia-table-cell>
        <maia-table-cell
          class="b2b-kdl-font-small title-dark-color"
          class="icon-container"
          maiaTableHeaderHiddenOnSmall
        >
          <div class="left">
            <svg
              class="delete-icon"
              b2bFrontendActionFuncBin
              (click)="delPopup(i)"
              *ngIf="!disableButtons"
            ></svg>
            <div *ngIf="person.isValidData === 1" class="tooltip">
              <svg class="alert-icon" b2bFrontendAlertExprExclamationCircle></svg>
              <span class="tooltiptext b2b-kdl-font-extra-small">The data is invalid</span>
            </div>
          </div>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
</div>

<maia-divider></maia-divider>

<div *ngIf="addedInsuredPersonArray.length > 0">
  <maia-pagination
    id="pagination-general"
    general
    [(page)]="currentPageGeneral"
    [lastPage]="lastPageGeneral()"
    [totalPages]="pages"
    (pageChange)="pageChange($event)"
  ></maia-pagination>
</div>

<div class="step-form-button-bar">
  <maia-button-group *ngIf="!(step.inEdit | async)">
    <button
      maiaButton
      maiaButtonType="primary"
      [disabled]="saveAndContinuBtnStatus()"
      (click)="saveAndContinue()"
      >Continue</button
    >
  </maia-button-group>

  <div *ngIf="step.inEdit | async">
    <button
      class="m-r-24 m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="saveAndContinuBtnStatus()"
      (click)="saveEdit()"
      >Save</button
    >
    <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
  </div>
</div>
