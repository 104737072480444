<div class="header-info">
  <div>
    <maia-label
      class="label-font"
      i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-date"
      >Policy Issue Date :
    </maia-label>
  </div>
  <div>
    <maia-label class="label-font-date">{{ todayDate | atlasDate }}</maia-label>
  </div>

  <div class="head-auto">
    <div class=".readmore-icon">
      <a
        id="maia-action-icon-4"
        maiaDocumentAction
        rel="noopener noreferrer"
        href="https://www.dzi.bg/attachments/Product/30/download/8104-Singleitrip-IPID-0.pdf"
        target="_blank"
        i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-hyperlink-readmore"
        >Read more</a
      >
    </div>
  </div>
</div>
<form maiaForm [formGroup]="policyForm.formGroup">
  <div class="m-t-24 row-warp">
    <div class="m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column child"
        i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-policylength"
        label="Policy Length"
      >
        <maia-input-select name="policyLength" formControlName="policyLength">
          <input
            maiaInput
            placeholder="Select"
            [value]="policyForm.controls['policyLength'].value"
          />
          <maia-option *ngFor="let mockItem of policyLenMock" [value]="mockItem.name">{{
            mockItem.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
    </div>
    <div class="m-r-16">
      <maia-form-element *ngIf="!strtDt" class="input-column child" hideOptionalIndicator>
        <maia-label
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-srtdt"
          i18n-sublabel="@@nje-b2b-frontend-casco-priceoffer-policydetails-sublabel-srtdt1"
          sublabel="Start time of Policy is 00:00 on the selected date."
          >Start Date</maia-label
        >
        <maia-input-date
          formControlName="srtdt"
          [minimum]="minStartDate"
          [maximum]="maxStartDate"
          name="Start Date"
          (ngModelChange)="changeEnd()"
        ></maia-input-date>
      </maia-form-element>
      <maia-form-element *ngIf="strtDt" class="input-column child" hideOptionalIndicator>
        <maia-label
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-srtdt"
          i18n-sublabel="@@nje-b2b-frontend-casco-priceoffer-policydetails-sublabel-srtdt2"
          sublabel="Start time of Policy is 30 minutes ahead from now."
          >Start Date</maia-label
        >
        <maia-input-date
          formControlName="srtdt"
          [minimum]="minStartDate"
          [maximum]="maxStartDate"
          name="Start Date"
          (ngModelChange)="changeEnd()"
        ></maia-input-date>
      </maia-form-element>
    </div>
    <div class="m-r-16 column-warp">
      <maia-form-element disabled="true" class="input-column child" hideOptionalIndicator
        ><maia-label
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-enddt"
          i18n-sublabel="@@nje-b2b-frontend-casco-priceoffer-policydetails-sublabel-enddt"
          sublabel="Policy will be ended at 23:59 on selected date"
          class="end-color"
          >End Date</maia-label
        >
        <maia-input-date
          formControlName="enddt"
          [minimum]="minEndDate"
          [maximum]="maxEndDate"
          [(ngModel)]="endDate"
          name="End Date"
        ></maia-input-date>
      </maia-form-element>
    </div>
  </div>

  <div *ngIf="intrptCovrg">
    <maia-fluid-fluid-layout alignContentTop>
      <maia-contextual-message class="maia-msg-1">
        <p
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-warning-intrptCovrg"
          class="b2b-kdl-font-small"
          >Please check the begin date of the policy – there is interruption between the
          coverages</p
        >
      </maia-contextual-message>
    </maia-fluid-fluid-layout>
  </div>

  <div *ngIf="infoMsg">
    <maia-fluid-fluid-layout alignContentTop>
      <maia-contextual-message class="maia-msg">
        <p
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-warning-infoMsg"
          class="b2b-kdl-font-small"
          >The selected option is only for short term policies</p
        >
      </maia-contextual-message>
    </maia-fluid-fluid-layout>
  </div>

  <div *ngIf="plcyOvrlp">
    <maia-fluid-fluid-layout alignContentTop>
      <maia-contextual-message class="maia-msg-1 error-color-background">
        <p
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-warning-plcyOvrlp"
          class="b2b-kdl-font-small error-color-text"
          >Please check the begin date - there is overlapping period with the current Casco
          policy.</p
        >
      </maia-contextual-message>
    </maia-fluid-fluid-layout>
  </div>

  <div class="row-warp instal-div-margin">
    <maia-form-element
      [hideOptionalIndicator]="true"
      class="input-column child select-top-space"
      i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-instlmnOptn"
      label="Installment Option"
    >
      <maia-input-select name="instlmnOptn" formControlName="instlmnOptn">
        <input maiaInput placeholder="Select" [value]="policyForm.controls['instlmnOptn'].value" />
        <maia-option
          (click)="instalOptSelection(mockItem.id)"
          *ngFor="let mockItem of installmentsMock"
          [value]="mockItem.name"
          >{{ mockItem.name }}</maia-option
        >
      </maia-input-select>
    </maia-form-element>
  </div>
  <maia-divider></maia-divider>
  <div class="m-t-16 m-b-24">
    <maia-form-element
      [hideOptionalIndicator]="true"
      class="input-column child select-top-space"
      i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-secuDvc"
      label="Please select security device to be installed or there is installed device already?"
    >
      <maia-input-select name="secuDvc" formControlName="secuDvc">
        <input maiaInput placeholder="Select" [value]="policyForm.controls['secuDvc'].value" />
        <maia-option *ngFor="let mockItem of secureDeviceMock" [value]="mockItem.name">{{
          mockItem.name
        }}</maia-option>
      </maia-input-select>
    </maia-form-element>
  </div>

  <maia-divider></maia-divider>
  <div class="row-warp select-top-space">
    <div class="m-r-16 form-ques-div-width">
      <maia-label
        i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-vehicleAbroad60Radio"
        class="m-b-8 label-font"
        >Will you drive this vehicle abroad for more than 60 days?</maia-label
      >
      <maia-radio-group
        name="vehicleAbroad60Radio"
        formControlName="vehicleAbroad60Radio"
        horizontal
      >
        <njf-radio-button
          value="yes"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-radio-vehicleAbroad60Radio-yes"
        >
          Yes</njf-radio-button
        >
        <njf-radio-button
          value="no"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-radio-vehicleAbroad60Radio-no"
        >
          No</njf-radio-button
        >
      </maia-radio-group>
    </div>
    <div class="m-r-16 form-ques-div-width">
      <maia-label
        i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-unlawfulRadio"
        class="m-b-8 label-font"
        >Is the vehicle known to be unlawfully taken away or attempted to be stolen?</maia-label
      >
      <maia-radio-group name="unlawfulRadio" formControlName="unlawfulRadio" horizontal>
        <njf-radio-button
          value="yes"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-radio-unlawfulRadio-yes"
        >
          Yes</njf-radio-button
        >
        <njf-radio-button
          value="no"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-radio-unlawfulRadio-no"
        >
          No</njf-radio-button
        >
      </maia-radio-group>
    </div>
    <div>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column child"
        i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-vhclClaims"
        label="What type of claims the vehicle had during the last 12 months?"
      >
        <maia-input-select name="vhclClaims" formControlName="vhclClaims">
          <input maiaInput placeholder="Select" [value]="policyForm.controls['vhclClaims'].value" />
          <maia-option *ngFor="let mockItem of claimTypeMock" [value]="mockItem.name">{{
            mockItem.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
    </div>
  </div>
  <div>
    <maia-form-element
      [hideOptionalIndicator]="true"
      class="input-column child select-top-space-1"
      i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-importCountry"
      label="Country of import"
    >
      <maia-input-select name="importCountry" formControlName="importCountry">
        <input
          maiaInput
          placeholder="Select"
          [value]="policyForm.controls['importCountry'].value"
        />
        <maia-option *ngFor="let mockItem of originCountryMock" [value]="mockItem.name">{{
          mockItem.name
        }}</maia-option>
        <maia-option value="Other">Other</maia-option>
      </maia-input-select>
    </maia-form-element>
    <maia-tile
      *ngIf="policyForm.controls['importCountry'].value === 'Other'"
      class="countries-other-box-wrapper m-t-16"
    >
      <div>
        <maia-label
          class="m-b-8 label-font"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-otherCountry"
          >Enter Country Name</maia-label
        >
        <maia-input-group name="otherCountry">
          <input formControlName="otherCountry" class="other-country-inputbox" maiaInput />
        </maia-input-group>
      </div>
    </maia-tile>
  </div>
  <maia-divider></maia-divider>
  <div class="row-warp select-top-space">
    <div class="m-r-16 form-ques-div-width">
      <maia-label
        i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-activeCascoRadio"
        class="m-b-8 label-font"
        >Is there another active Casco insurance currently available for the same
        vehicle?</maia-label
      >
      <maia-radio-group name="activeCascoRadio" formControlName="activeCascoRadio" horizontal>
        <njf-radio-button
          value="yes"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-radio-activeCascoRadio-yes"
        >
          Yes</njf-radio-button
        >
        <njf-radio-button
          value="no"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-radio-activeCascoRadio-no"
        >
          No</njf-radio-button
        >
      </maia-radio-group>
    </div>
    <div class="form-ques-div-width">
      <div class="immobilizer-width">
        <maia-label
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-immobilzerRadio"
          class="m-b-8 label-font"
          >Is there a working alarm, system or immobilzer?</maia-label
        ></div
      >
      <maia-radio-group name="immobilzerRadio" formControlName="immobilzerRadio" horizontal>
        <njf-radio-button
          value="yes"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-radio-immobilzerRadio-yes"
        >
          Yes</njf-radio-button
        >
        <njf-radio-button
          value="no"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-radio-immobilzerRadio-no"
        >
          No</njf-radio-button
        >
      </maia-radio-group>
    </div>
  </div>
  <div class="row-warp m-b-24">
    <div class="m-r-16 form-main-div">
      <div>
        <maia-label
          class="label-font"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-keyOrignl"
          >Number of Original Keys?</maia-label
        >
      </div>

      <div class="form-ques-div-width m-t-5">
        <maia-input-group>
          <input formControlName="keyOrignl" maiaInputNumber />
        </maia-input-group>
      </div>
    </div>
    <div class="m-r-16 form-main-div">
      <div>
        <maia-label
          class="label-font"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-rmtCntrl"
          >Number of remote controls?</maia-label
        >
      </div>

      <div class="form-ques-div-width m-t-5">
        <maia-input-group>
          <input formControlName="rmtCntrl" maiaInputNumber />
        </maia-input-group>
      </div>
    </div>
    <div class="m-r-16 form-main-div">
      <div>
        <maia-label
          class="label-font"
          i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-keyDup"
          >Number of duplicate keys?</maia-label
        >
      </div>

      <div class="form-ques-div-width m-t-5">
        <maia-input-group>
          <input formControlName="keyDup" maiaInputNumber />
        </maia-input-group>
      </div>
    </div>
  </div>

  <maia-divider></maia-divider>
  <div class="row-warp m-b-24">
    <div class="m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column child"
        i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-agencyCode"
        label="Agency"
      >
        <maia-input-select name="agencyCode" formControlName="agencyCode">
          <input maiaInput placeholder="Select" [value]="policyForm.controls['agencyCode'].value" />
          <maia-option *ngFor="let mockItem of agencyMock" [value]="mockItem.name">{{
            mockItem.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
    </div>
    <div>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column child"
        i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-label-sellerCode"
        label="Seller Code"
      >
        <maia-input-select name="sellerCode" formControlName="sellerCode">
          <input maiaInput placeholder="Select" [value]="policyForm.controls['sellerCode'].value" />
          <maia-option *ngFor="let mockItem of sellerCodeMock" [value]="mockItem.name">{{
            mockItem.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
    </div>
  </div>
  <maia-divider></maia-divider>
</form>
<div class="continue-top-space m-b-24">
  <button
    [disabled]="!policyForm.valid"
    i18n="@@nje-b2b-frontend-casco-priceoffer-policydetails-button-continue"
    maiaButton
    maiaButtonType="primary"
    (click)="saveAndContinue()"
    >Continue</button
  >
</div>
