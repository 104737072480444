import {Injectable} from '@angular/core';
import {ApiService, API_END_POINT} from '@b2b-frontend/core';
import {Observable, Subject} from 'rxjs';
import {Contact} from '../contracts/contactsInterface';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  public emitter = new Subject<boolean>();

  public constructor(private readonly _apiService: ApiService) {}

  public getContactsList(): Observable<Contact[]> {
    return this._apiService.get(API_END_POINT.CONTACT_API);
  }

  public deleteContact(id: number): Observable<any> {
    return this._apiService.delete(`${API_END_POINT.CONTACT_API}/${id}`);
  }

  public updateEditedUrl(urlObj: Contact, index?: number): Observable<Contact> {
    return this._apiService.patch(`${API_END_POINT.CONTACT_API}/${index}`, urlObj);
  }

  public addContacts(contactObj: Contact): Observable<Contact> {
    return this._apiService.postFiles(API_END_POINT.CONTACT_API, contactObj);
  }

  public editContact(contactObj: Contact): Observable<Contact> {
    return this._apiService.patch(`${API_END_POINT.CONTACT_API}/${contactObj.id}`, contactObj);
  }
}
