<div class="video-container">
  <div class="green-frame-relative">
    <div class="content animate-flicker" [ngStyle]="{display: showFrame ? 'inherit' : 'none'}"
      ><p></p
    ></div>
    <video id="talonVideo" #video [height]="HEIGHT" class="video-width" autoplay muted> </video>
    <!-- [class.show]="!isCaptured" [class.hide]="isCaptured" -->
  </div>
  <canvas
    style="display: none"
    [class.show]="isCaptured"
    [class.hide]="!isCaptured"
    #canvas
    id="canvas"
    [width]="CANVAS_WIDTH"
    [height]="CANVAS_HEIGHT"
  ></canvas>
</div>

<div *ngIf="error || timeOut" class="text-danger mt-4">
  <maia-contextual-message
    class="contextual-message mt-20"
    type="error"
    i18n="@@systemcode-talonscan-req-timeout"
  >
    Request Timeout. Please try again.
  </maia-contextual-message>
</div>

<maia-button-group class="btn-group mt-20">
  <button
    maiaButton
    maiaButtonType="primary"
    [disabled]="showFrame"
    (click)="startProcessing()"
    i18n="@@systemcode-talonscan-button-startprocessing"
    >Start Processing</button
  >
  <button
    maiaButton
    maiaButtonType="secondary"
    (click)="back()"
    i18n="@@systemcode-talonscan-button-back"
    >Back</button
  >
</maia-button-group>
