import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprCarCrashSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationExprCarCrash]',
  template: '<svg:path d="M31.37 37.34H35v-9a1.81 1.81 0 0 0-1.8-1.8h-9L21 17.83a1.81 1.81 0 0 0-1.69-1.19H5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M7.97 37.34h14.4"></svg:path><svg:path d="M5.22 33.2a4.5 4.5 0 0 1 2.4 5.89 4.54 4.54 0 0 1-2.4 2.4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:circle cx="26.87" cy="37.34" r="4.5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5 26.54h19.17"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M12.47 16.64v9.9"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M43 5.64v36.72"></svg:path><svg:path d="M32.48 26.46l-4.11-5.14h6.17L28.37 11l9.25 7.2 1-7.2L43 19.35" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M43 36.06l-5 5.78v-7.49l-3 1.37" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprCarCrashSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprCarCrashSvgComponent,
  ],
  exports: [
    TransportationExprCarCrashSvgComponent,
  ],
})
export class TransportationExprCarCrashSvgModule {}
