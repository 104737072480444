export interface LocaleConfig {
  [key: string]: {
    en: string;
    bg: string;
  };
}

export const Config = {
  Talon: {
    en: 'Talon',
    bg: 'Талон',
  },
  'Talon Annual Inspection': {
    en: 'Talon Annual Inspection',
    bg: 'Талон ГТП',
  },
  'Talon Scanning': {
    en: 'Talon Scanning',
    bg: 'Сканиране на талон',
  },
  'Edit Document': {
    en: 'Edit Document',
    bg: 'Редактирайте документа',
  },
  'Add New Document': {
    en: 'Add New Document',
    bg: '[BG]Add New Document',
  },
  'Update Library List': {
    en: 'Update Library List',
    bg: 'Обновете библютеката ',
  },
  'Update Library': {
    en: 'Update Library',
    bg: 'Обновете Библютека',
  },
  'Add New Library': {
    en: 'Add New Library',
    bg: 'Добавете нова библютека',
  },
  'Select Account': {
    en: 'Select Account',
    bg: 'Избетере акаунт ',
  },
  'Maximum file size should be 10 MB': {
    en: 'Maximum file size should be 10 MB',
    bg: 'Максимален размер на файл до 10MB',
  },
  'Only JPG. max. 10MB': {
    en: 'Only JPG. max. 10MB',
    bg: '[BG]Only JPG. max. 10MB',
  },
  'Upload Document': {
    en: 'Upload Document',
    bg: '[BG]Upload Document',
  },
  'Only MP4 or JPG. max. 10MB': {
    en: 'Only MP4 or JPG. max. 10MB',
    bg: '[BG]Only MP4 or JPG. max. 10MB',
  },
  'Upload Image': {
    en: 'Upload Image',
    bg: 'Прикачете снимка ',
  },
  'Upload File': {
    en: 'Upload File',
    bg: 'Прикачете файл',
  },
  'Edit Incident': {
    en: 'Edit Incident',
    bg: 'Редактирайте инцидента',
  },
  'Add Incident': {
    en: 'Add Incident',
    bg: 'Добавете изцидент',
  },
  'Edit Contact': {
    en: 'Edit Contact',
    bg: 'Редактирайте контакт',
  },
  'Add Contact': {
    en: 'Add Contact',
    bg: 'Добавете контакт',
  },
  'Edit url': {
    en: 'Edit url',
    bg: 'Редактирайте url',
  },
  Edit: {
    en: 'Edit',
    bg: 'Редактирайте',
  },
  Select: {
    en: 'Select',
    bg: 'Изберете',
  },
  News: {
    en: 'News',
    bg: 'Новини',
  },
  Events: {
    en: 'Events',
    bg: 'Събития',
  },
  'New to Old': {
    en: 'New to Old',
    bg: 'най-нови към Най-стари',
  },
  'Old to New': {
    en: 'Old to New',
    bg: 'Най-стари към най-нови',
  },
  'Edit Library': {
    en: 'Edit Library',
    bg: 'BG[Edit Library]',
  },
  ALL: {
    en: 'ALL',
    bg: 'BG[ALL]',
  },
  'Search by Policy Number/Email/Phone': {
    en: 'Search by Policy Number/Email/Phone',
    bg: 'BG[Search by Policy Number/Email/Phone]',
  },
  'Search by Offer Id/ Policy number': {
    en: 'Search by Offer Id/ Policy number',
    bg: 'BG[Search by Offer Id/ Policy number]',
  },
  'Search by Claim Id/ Name/ Email/Phone': {
    en: 'Search by Claim Id/ Name/ Email/Phone',
    bg: 'BG[Search by Claim Id/ Name/ Email/Phone]',
  },
  'Search by FirstName/Email/Phone/EIK/LNCH/City Name': {
    en: 'Search by FirstName/Email/Phone/EIK/LNCH/City Name',
    bg: 'BG[Search by FirstName/Email/Phone/EIK/LNCH/City Name]',
  },
  'Policies created : ': {
    en: 'Policies created : ',
    bg: 'BG[Policies created : ]',
  },
  'User count : ': {
    en: 'User count : ',
    bg: 'BG[User count : ]',
  },
  'Offers created : ': {
    en: 'Offers created : ',
    bg: 'BG[Offers created : ]',
  },
  'Payments created : ': {
    en: 'Payments created : ',
    bg: 'BG[Payments created :] ',
  },
  'Claims check : ': {
    en: 'Claims check : ',
    bg: 'BG[Claims check : ]',
  },
  PolicyActivity: {
    en: 'PolicyActivity',
    bg: 'BG[PolicyActivity]',
  },
  Warning: {
    en: 'Warning',
    bg: 'BG[Warning]',
  },
  'Do you confirm to delete the library?': {
    en: 'Do you confirm to delete the library?',
    bg: 'BG[Do you confirm to delete the library?]',
  },
  'Do you confirm to delete the portfolio?': {
    en: 'Do you confirm to delete the portfolio?',
    bg: 'BG[Do you confirm to delete the portfolio?]',
  },
  'Do you confirm to delete the product?': {
    en: 'Do you confirm to delete the product?',
    bg: 'BG[Do you confirm to delete the product?]',
  },
  'Add New Product Portfolio': {
    en: 'Add New Product Portfolio',
    bg: 'BG[Add New Product Portfolio]',
  },
  'Update Product Portfolio': {
    en: 'Update Product Portfolio',
    bg: 'BG[Update Product Portfolio]',
  },
  'Edit Filters': {
    en: 'Edit Filters',
    bg: 'BG[Edit Filters]',
  },
  Filter: {
    en: 'Filter',
    bg: 'BG[Filter]',
  },
  Pin: {
    en: 'Pin',
    bg: 'На фокус',
  },
  'Are you sure you want to cancel this?': {
    en: 'Are you sure you want to cancel this?',
    bg: 'BG[Are you sure you want to cancel this?]',
  },
  false: {
    en: 'false',
    bg: 'BG[false]',
  },
  true: {
    en: 'true',
    bg: 'BG[true]',
  },
  Epay: {
    en: 'Epay',
    bg: 'BG[Epay]',
  },
  VPOS: {
    en: 'VPOS',
    bg: 'BG[VPOS]',
  },
  PAID: {
    en: 'PAID',
    bg: 'BG[PAID]',
  },
  RESERVED: {
    en: 'RESERVED',
    bg: 'BG[RESERVED]',
  },
  'POLICY CREATION PAYMENT': {
    en: 'POLICY CREATION PAYMENT',
    bg: 'BG[POLICY CREATION PAYMENT]',
  },
  'INSTALMENT PAYMENT': {
    en: 'INSTALMENT PAYMENT',
    bg: 'BG[INSTALMENT PAYMENT]',
  },
  INSIS10: {
    en: 'INSIS10',
    bg: 'BG[INSIS10]',
  },
  INSIS9: {
    en: 'INSIS9',
    bg: 'BG[INSIS9]',
  },
  No: {
    en: 'No',
    bg: 'BG[No]',
  },
  Yes: {
    en: 'Yes',
    bg: 'BG[Yes]',
  },
  Contacts: {
    en: 'Contacts',
    bg: 'Контакти',
  },
  'Statistics reporting': {
    en: 'Statistics reporting',
    bg: 'BG[Statistics reporting]',
  },
  'Resource management': {
    en: 'Resource management',
    bg: 'BG[Resource management]',
  },
  'Admin documents': {
    en: 'Admin documents',
    bg: 'Адмистраторски документи',
  },
  Library: {
    en: 'Library',
    bg: 'Библютека',
  },
  'News & Events': {
    en: 'News & Events',
    bg: 'Новини & Събития',
  },
};
