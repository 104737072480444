<maia-fluid-fluid-layout class="change-password-wrapper">
  <div class="change-password-container">
    <svg b2bFrontendInformationExprShieldLock class="icon-adjust m-b-24"></svg>
    <maia-label>
      <label class="b2b-kdl-font-grand-serif title-dark-color">Let's change your password </label>
    </maia-label>
    <div class="subtitle-light-color b2b-kdl-font-small m-t-8">
      In order to complete your account, please add your own password.
      <maia-label>
        <label>Your new password must include: </label>
      </maia-label>
      <ul class="conditions-list">
        <li> At least 8 characters </li>
        <li> One uppercase letter </li>
        <li> One lowercase letter </li>
        <li> One numeric character </li>
        <li> One special character (!"#$%&\'()*+,-./:;<=>?@[\\]^_{{ '{' }}|{{ '}' }}~)</li>
      </ul>
    </div>
    <div class="password-wrapper m-t-24">
      <maia-label><label class="b2b-kdl-font-small">New Password</label></maia-label>
      <maia-form-element hideOptionalIndicator class="input-column">
        <input maiaInput type="password" class="m-t-8" />
      </maia-form-element>
    </div>
    <div class="repeat-password-wrapper" class="m-t-16">
      <maia-label>
        <label class="b2b-kdl-font-small">Repeat New Password</label>
      </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column">
        <input maiaInput type="password" class="m-t-8" />
      </maia-form-element>
    </div>

    <div class="button-wrapper m-t-24">
      <button maiaButton maiaButtonType="primary" (click)="onSubmit()">Submit </button>
    </div>
  </div>
</maia-fluid-fluid-layout>
