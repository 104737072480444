import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionExprPdfSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendActionExprPdf]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 4.8 4.8)"><svg:path class="cls-1" d="M30.09 1.15v12.19h12.19" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path class="cls-1" d="M42.28 19.43v-6.09L30.09 1.15H5.72v18.28" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path class="cls-1" d="M5.72 40.76v6.09h36.56v-6.09" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path class="cls-1" d="M1.15 19.43h45.7v21.32H1.15z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:g class="cls-2"><svg:g class="cls-2"><svg:path class="cls-3" d="M11.47 25.55h4a3.06 3.06 0 0 1 3.11 3.28 3.07 3.07 0 0 1-3.11 3.3H13.8v2.95h-2.33zM15 30.13a1.15 1.15 0 0 0 1.21-1.3A1.13 1.13 0 0 0 15 27.55h-1.2v2.58z"></svg:path><svg:path class="cls-3" d="M20.18 25.55h3.39c3 0 4.92 1.74 4.92 4.74s-1.92 4.79-4.92 4.79h-3.39zm3.3 7.53c1.61 0 2.6-1 2.6-2.79a2.44 2.44 0 0 0-2.6-2.74h-1v5.53z"></svg:path><svg:path class="cls-3" d="M30.39 25.55h5.84v2h-3.5v2h2.9v2h-2.9v3.53h-2.34z"></svg:path></svg:g></svg:g></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionExprPdfSvgComponent {
}

@NgModule({
  declarations: [
    ActionExprPdfSvgComponent,
  ],
  exports: [
    ActionExprPdfSvgComponent,
  ],
})
export class ActionExprPdfSvgModule {}
