<div *ngIf="showEmailContainer; else successMessageContainer">
  <ng-container>
    <form maiaForm [formGroup]="mailForm" class="form">
      <div>
        <maia-label class="adding-label b2b-kdl-font-normal title-dark-color"
          >Send to email</maia-label
        >
        <div class="add-email-button">
          <button maiaButton maiaButtonType="tertiary" (click)="addEmails()">Add email</button>
        </div>
      </div>

      <maia-form-element [options]="{validationInstant: true}" class="adding-input">
        <input
          type="email"
          name="email"
          maiaInput
          atlasEmailAddress
          formControlName="email"
          placeholder="yoursmail@gmail.com"
        />

        <maia-validation
          type="error"
          alignRight
          *ngIf="
            mailForm.controls['email'].hasError('atlasInvalidLocalPart') ||
            mailForm.controls['email'].hasError('atlasInvalidDomain') ||
            mailForm.controls['email'].hasError('atlasInvalidMultipleAt')
          "
        >
          Enter your e-mail address in the following format: name@domain.com
        </maia-validation>
        <maia-validation
          type="error"
          alignRight
          *ngIf="mailForm.controls['email'].hasError('atlasMaxLength')"
        >
          Email is too Long!
        </maia-validation>
        <maia-validation
          alignRight
          type="error"
          *ngIf="
            mailForm.controls['email'].hasError('required') && mailForm.controls['email']?.touched
          "
        >
          Email is Required
        </maia-validation>
      </maia-form-element>

      <!--formarray  -->
      <div formArrayName="emailsFormArray" class="adding-first-div-container">
        <div
          *ngFor="let multipleEmailInputs of emailsFormArray.controls; let i = index"
          [formGroupName]="i"
          class="adding-second-div-container"
        >
          <maia-form-element
            class="form-element adding-multiple-email-input"
            [options]="{validationInstant: true}"
          >
            <input
              maiaInput
              type="email"
              placeholder="user@gmail.com"
              atlasEmailAddress
              formControlName="mails"
            />

            <maia-validation
              type="error"
              *ngIf="
                multipleEmailInputs.get('mails')?.errors?.required &&
                multipleEmailInputs.get('mails')?.touched
              "
            >
              Email is Required
            </maia-validation>
            <maia-validation
              type="error"
              *ngIf="
                multipleEmailInputs.get('mails').hasError('atlasInvalidLocalPart') ||
                multipleEmailInputs.get('mails').hasError('atlasInvalidDomain') ||
                multipleEmailInputs.get('mails').hasError('atlasInvalidMultipleAt')
              "
            >
              Enter your e-mail address in the following format: name@domain.com
            </maia-validation>
            <maia-validation
              type="error"
              *ngIf="multipleEmailInputs.get('mails').hasError('atlasMaxLength')"
            >
              Email is too long!</maia-validation
            >
          </maia-form-element>
          <svg
            b2bFrontendNavigationFuncCross
            class="adding-cross-icon"
            (click)="removeEmail(i)"
          ></svg>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *maiaPopUpFooter>
    <button maiaButton maiaButtonType="tertiary" (click)="control.cancel()">Cancel</button>

    <button
      maiaButton
      maiaButtonType="primary"
      (click)="openSuccessContainer()"
      [disabled]="!mailForm.valid"
      >Send</button
    >
  </ng-container>
</div>

<ng-template #successMessageContainer>
  <div class="adding-message">
    <div class="adding-icon">
      <svg b2bFrontendAlertFuncCheckCircle></svg>
    </div>

    <maia-label class="b2b-kdl-font-normal title-dark-color">Email Sent Successfully</maia-label>
  </div>
  <ng-container *maiaPopUpFooter>
    <button maiaButton maiaButtonType="primary" (click)="control.cancel()">Close</button>
  </ng-container>
</ng-template>
