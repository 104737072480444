import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import {StepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {Observable, ReplaySubject} from 'rxjs';
import {takeUntil, filter, startWith, map} from 'rxjs/operators';
import {MtplProcessService} from './mtpl-core/services/mtpl-process.service';
import {SharedService} from './mtpl-core/services/shared.service';

@Component({
  selector: 'nje-mtpl',
  templateUrl: './mtpl.component.html',
  styleUrls: ['./mtpl.component.scss'],
  providers: [SharedService],
})
export class MtplComponent implements OnInit {
  public steps: Observable<StepperProcessStep[]> = this.process.onStepperWorkflowSteps;
  public isEditing: Observable<boolean> = this.process.stepEditing;
  private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isStepperVisible: boolean = true;

  public constructor(
    public process: MtplProcessService,
    private readonly _router: Router,
    public readonly activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this._router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: any) => event instanceof NavigationEnd),
        startWith(null),
        map(() => {
          let currentRoute = this.activatedRoute;
          while (currentRoute.firstChild != null) {
            currentRoute = currentRoute.firstChild;
          }
          let currentRouteS = currentRoute.snapshot;
          while (currentRouteS.parent != null) {
            if ('isStepperVisible' in currentRouteS.data) {
              return currentRouteS.data.isStepperVisible as boolean;
            }
            currentRouteS = currentRouteS.parent;
          }
          return true;
        }),
      )
      .subscribe((val: any) => (this.isStepperVisible = val));
  }
}
