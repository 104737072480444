import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NameAddressParsingService {
  public nameNullCheck(first_name: string, middle_name: string, last_name: string): string {
    let name: string = '';
    if (middle_name) {
      name = first_name + ' ' + middle_name + ' ' + last_name;
    } else {
      name = first_name + ' ' + last_name;
    }
    return name;
  }
}
