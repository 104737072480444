import {
  FilterControlLabel,
  ProductType,
  RegisteredUser,
  DirectMarketing,
  DisplayUniqueRecords,
  TableHeaders,
} from '../../contracts/statistics-reporting.interface';

export const PRODUCT_TYPE: ProductType[] = [
  {
    title: 'All',
    titlebg: '[TR]All',
    id: '0',
  },
  {
    title: 'Travel',
    titlebg: '[TR]Travel',
    id: '8100',
  },
  {
    title: 'MTPL',
    titlebg: '[TR]MTPL',
    id: '4600',
  },
  {
    title: 'Property Home',
    titlebg: '[TR]Property Home',
    id: '1112',
  },
];

export const CLAIM_PRODUCT_TYPE: ProductType[] = [
  {
    title: 'All',
    titlebg: '[TR]All',
    id: '0',
  },
  {
    title: 'Casco',
    titlebg: '[TR]Casco',
    id: '4700',
  },
  {
    title: 'Property',
    titlebg: '[TR]Property',
    id: '1112',
  },
];

export const REGISTERED_USER: RegisteredUser[] = [
  {
    label: 'Yes',
    labelbg: '[TR]Yes',
    value: true,
  },
  {
    label: 'No',
    labelbg: '[TR]No',
    value: false,
  },
];

export const DIRECT_MARKETING: DirectMarketing[] = [
  {
    label: 'Yes',
    labelbg: '[TR]Yes',
    value: true,
  },
  {
    label: 'No',
    labelbg: '[TR]No',
    value: false,
  },
];

export const DISPLAY_UNIQUE_RECORDS: DisplayUniqueRecords[] = [
  {
    label: 'Yes',
    labelbg: '[TR]Yes',
    value: true,
  },
  {
    label: 'No',
    labelbg: '[TR]No',
    value: false,
  },
];

export const PAYMENT_PROVIDER: FilterControlLabel[] = [
  {
    label: 'VPOS',
    labelbg: '[TR]VPOS',
    value: 'VPOS',
  },
  {
    label: 'Epay',
    labelbg: '[TR]Epay',
    value: 'Epay',
  },
];

export const INSIS_SOURCE: FilterControlLabel[] = [
  {
    label: 'INSIS9',
    labelbg: '[TR]INSIS9',
    value: 'INSIS9',
  },
  {
    label: 'INSIS10',
    labelbg: '[TR]INSIS10',
    value: 'INSIS10',
  },
];

export const PAYMENT_STATUS: FilterControlLabel[] = [
  {
    label: 'PAID',
    labelbg: '[TR]PAID',
    value: 'PAID',
  },
  {
    label: 'RESERVED',
    labelbg: '[TR]RESERVED',
    value: 'RESERVED',
  },
];

export const PAYMENT_TYPE: FilterControlLabel[] = [
  {
    label: 'INSTALMENT PAYMENT',
    labelbg: '[TR]INSTALMENT PAYMENT',
    value: 'INSTALMENT PAYMENT',
  },
  {
    label: 'POLICY CREATION PAYMENT',
    labelbg: '[TR]POLICY CREATION PAYMENT',
    value: 'POLICY CREATION PAYMENT',
  },
];

export const POLICY_ACTIVITY_TABLE_COL_HEADERS: TableHeaders[] = [
  {
    headeren: 'Policy No.',
    headerbg: '[TR]Policy No.',
  },
  {
    headeren: 'Policy creation date',
    headerbg: '[TR]Policy creation date',
  },
  {
    headeren: 'Product code',
    headerbg: '[TR]Product code',
  },
  {
    headeren: 'Transaction no',
    headerbg: '[TR]Transaction no',
  },

  {
    headeren: 'Invoice no',
    headerbg: '[TR]Invoice no',
  },
  {
    headeren: 'Amount',
    headerbg: '[TR]Amount',
  },

  {
    headeren: 'Seller code',
    headerbg: '[TR]Seller code',
  },
  {
    headeren: 'Policy holder name',
    headerbg: '[TR]Policy holder name',
  },
  {
    headeren: 'Email',
    headerbg: '[TR]Email',
  },
  {
    headeren: 'Delivery address',
    headerbg: '[TR]Delivery address',
  },
  {
    headeren: 'Phone',
    headerbg: '[TR]Phone',
  },
  {
    headeren: 'Registered User',
    headerbg: '[TR]Registered User',
  },
];

export const USER_ACTIVITY_TABLE_COL_HEADERS: TableHeaders[] = [
  {
    headeren: 'Name',
    headerbg: '[TR]Name',
  },
  {
    headeren: 'Email',
    headerbg: '[TR]Email',
  },
  {
    headeren: 'EIK/LNCH',
    headerbg: '[TR]EIK/LNCH',
  },
  {
    headeren: 'Phone',
    headerbg: '[TR]Phone',
  },
  {
    headeren: 'Postal Code',
    headerbg: '[TR]Postal Code',
  },
  {
    headeren: 'City Name',
    headerbg: '[TR]City Name',
  },
  {
    headeren: 'Created Date',
    headerbg: '[TR]Created Date',
  },
  {
    headeren: 'Last Login Date',
    headerbg: '[TR]Last Login Date',
  },
  {
    headeren: 'Registered user',
    headerbg: '[TR]Registered user',
  },
  {
    headeren: 'Direct marketing',
    headerbg: '[TR]Direct marketing',
  },
];

export const OFFER_ACTIVITY_TABLE_COL_HEADERS: TableHeaders[] = [
  {
    headeren: 'Offer Id',
    headerbg: '[TR]Offer Id',
  },
  {
    headeren: 'Offer creation date',
    headerbg: '[TR]Offer creation date',
  },
  {
    headeren: 'Product Code',
    headerbg: '[TR]Product Code',
  },
  {
    headeren: 'Registered user',
    headerbg: '[TR]Registered user',
  },
  {
    headeren: 'Policy created',
    headerbg: '[TR]Policy created',
  },
  {
    headeren: 'Policy Number',
    headerbg: '[TR]Policy Number',
  },
];

export const CLAIM_ACTIVITY_TABLE_COL_HEADERS: TableHeaders[] = [
  {
    headeren: 'Claim Id',
    headerbg: '[TR]Claim Id',
  },
  {
    headeren: 'Claim creation date',
    headerbg: '[TR]Claim creation date',
  },
  {
    headeren: 'Policy Number',
    headerbg: '[TR]Policy Number',
  },
  {
    headeren: 'Product type',
    headerbg: '[TR]Product type',
  },
  {
    headeren: 'Claim Raised by',
    headerbg: '[TR]Claim Raised by',
  },
  {
    headeren: 'Email',
    headerbg: '[TR]Email',
  },
  {
    headeren: 'Phone',
    headerbg: '[TR]Phone',
  },
  {
    headeren: 'Vehicle Reg. Number',
    headerbg: '[TR]Vehicle Reg. Number',
  },
];

export const PAYMENTS_ACTIVITY_TABLE_COL_HEADERS: TableHeaders[] = [
  {
    headeren: 'Policy number',
    headerbg: '[TR]Policy number',
  },
  {
    headeren: 'Installment Number',
    headerbg: '[TR]Installment Number',
  },
  {
    headeren: 'Installment Amount',
    headerbg: '[TR]Installment Amount',
  },
  {
    headeren: 'Product code',
    headerbg: '[TR]Product code',
  },
  {
    headeren: 'Email',
    headerbg: '[TR]Email',
  },
  {
    headeren: 'Recipient Name',
    headerbg: '[TR]Recipient Name',
  },
  {
    headeren: 'Delivery Address',
    headerbg: '[TR]Delivery Address',
  },
  {
    headeren: 'Phone',
    headerbg: '[TR]Phone',
  },
  {
    headeren: 'Registered User',
    headerbg: '[TR]Registered User',
  },
  {
    headeren: 'Payment Provider',
    headerbg: '[TR]Payment Provider',
  },
  {
    headeren: 'Transaction Number',
    headerbg: '[TR]Transaction Number',
  },
  {
    headeren: 'Invoice Number',
    headerbg: '[TR]Invoice Number',
  },
  {
    headeren: 'Payment Date',
    headerbg: '[TR]Payment Date',
  },
  {
    headeren: 'Status',
    headerbg: '[TR]Status',
  },
  {
    headeren: 'Payment Type',
    headerbg: '[TR]Payment Type',
  },
  {
    headeren: 'INSIS Source',
    headerbg: '[TR]INSIS Source',
  },
  {
    headeren: 'Policy Covered till',
    headerbg: '[TR]Policy Covered till',
  },
  {
    headeren: 'Seller Code',
    headerbg: '[TR]Seller Code',
  },
  {
    headeren: 'Head agency code',
    headerbg: '[TR]Head agency code',
  },
  {
    headeren: 'Green Card N',
    headerbg: '[TR]Green Card N',
  },
  {
    headeren: 'Sticker N',
    headerbg: '[TR]Sticker N',
  },
];

export const SORTING_DIRECTION: FilterControlLabel[] = [
  {
    label: 'New to old',
    labelbg: '[TR]New to old',
    value: 'desc',
  },
  {
    label: 'Old to new',
    labelbg: '[TR]Old to new',
    value: 'asc',
  },
];

export const BLANK_VALUE = '';
export const ALL = 'All';
export const ASC = 'asc';
export const DESC = 'desc';
export const YES = 'Yes';
export const NO = 'No';

export enum ReportTypes {
  PolicyActivity = '1',
  OfferActivity = '2',
  ClaimActivity = '3',
  UserActivity = '4',
  PaymentActivity = '5',
}

export const REPORT_COUNT_LABEL = {
  POLICIES_CREATED: 'Policies created : ',
  CLAIMS_CHECK: 'Claims check : ',
  USER_COUNT: 'User count : ',
  OFFER_ACTIVITY: 'Offers created : ',
  PAYMENT_ACTIVITY: 'Payments created : ',
};

export enum REPORT_FETCH_MODE {
  VIA_SUBMIT_BUTTON = 'VIA_SUBMIT_BUTTON',
  FILTER = 'FILTER',
  SEARCH = 'SEARCH',
  SORT = 'SORT',
  PAGINATION = 'PAGINATION',
  XLS_DOWNLOAD = 'Xls Download',
}

export const PAGINATION = {
  PAGE_SIZE: 10,
  DEFAULT_PAGE: 1,
  PAGES: 0,
};

export const SEARCHBAR_PLACEHOLDER = {
  ALL: 'Search',
  POLICY_ACTIVITY: 'Search by Policy Number/Email/Phone',
  OFFER_ACTIVITY: 'Search by Offer Id/ Policy number',
  CLAIMS_ACTIVITY: 'Search by Claim Id/ Name/ Email/Phone',
  USER_ACTIVITY: 'Search by FirstName/Email/Phone/EIK/LNCH/City Name',
  PAYMENTS: 'Search by Policy Number/Email/Phone',
};
