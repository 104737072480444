<div class="auth-wrapper">
  <div class="auth-left-side-wrapper">
    <div class="uam-logo">
      <img src="assets/img/DZI-White.svg" />
    </div>
  </div>

  <div class="auth-right-side-wrapper">
    <router-outlet></router-outlet>
  </div>
</div>
