import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BLANK_VALUE} from '@b2b-frontend/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {COVERS_USER_PID} from '../../constants/property-insurance-constants';
import {CoversLimits} from '../../contracts/property-insurance.interface';
import {PropertyApiService} from '../../property-core/services/property-api.service';

@Component({
  selector: 'nje-underwriting-limits-slidein',
  templateUrl: './underwriting-limits-slidein.component.html',
  styleUrls: ['./underwriting-limits-slidein.component.scss'],
})
export class UnderwritingLimitsSlideinComponent
  extends ModalContentComponent<void, string>
  implements OnInit
{
  public limitslist: CoversLimits[] = [];
  public currencySelected: string = BLANK_VALUE;
  public constructor(
    private readonly _coversService: PropertyApiService,
    private readonly _cdRef: ChangeDetectorRef,
    public control: ModalControl<void, string>,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.currencySelected = this.control.input;
    this.coversAddress();
  }

  public coversAddress(): void {
    this._coversService.getCoversAddress(COVERS_USER_PID).subscribe(res => {
      this.limitslist = res;
      this._cdRef.detectChanges();
    });
  }
}
