import {PathIdentifier as AtlasPathIdentifier} from '@atlas/convertor';

export const NJE_AUTHENTICATED_PATH_IDENTIFIER_TYPE = 'nje';

export abstract class NJEPathIdentifier implements AtlasPathIdentifier {
  public type: string;
  public constructor(public service: string, public version?: string) {}
}

export class AuthenticatedPathIdentifier extends NJEPathIdentifier {
  public type = NJE_AUTHENTICATED_PATH_IDENTIFIER_TYPE;
}

export function isNJECall(
  identifier: AtlasPathIdentifier,
): identifier is AuthenticatedPathIdentifier {
  return identifier.type === NJE_AUTHENTICATED_PATH_IDENTIFIER_TYPE;
}
