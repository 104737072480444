import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'nje-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss'],
})
export class SuccessScreenComponent implements OnInit {
  public constructor() {}

  public ngOnInit(): void {}
}
