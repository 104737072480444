import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  InstalmentFilterSlideInFormData,
  InsuranceType,
} from '../../../../contracts/instalment-payment/instalment';
import {DateKey, DateUtils} from '@atlas/businesstypes';
import {PaymentsService} from '../../../../payments-core/services/payments.service';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {DateFormatterService} from '@b2b-frontend/core';

@Component({
  selector: 'nje-b2b-instalment-filter-slidein',
  templateUrl: './instalment-filter-slidein.component.html',
  styleUrls: ['./instalment-filter-slidein.component.scss'],
})
@UntilDestroy()
export class InstalmentFilterSlideinComponent
  extends ModalContentComponent<InstalmentFilterSlideInFormData, void>
  implements OnInit
{
  public instalmentFilterSlideinForm: FormGroup;
  public insuranceTypeList: InsuranceType[] = [];
  public maxFromDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxToDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);

  public constructor(
    private readonly _formBuilder: FormBuilder,
    public control: ModalControl<InstalmentFilterSlideInFormData, void>,
    private readonly _paymentsService: PaymentsService,
    private readonly _dateFormatterService: DateFormatterService,
  ) {
    super();
  }

  public ngOnInit(): void {
    this._paymentsService.getInsuranceTypes().subscribe((response: InsuranceType[]) => {
      this.insuranceTypeList = response;
    });
    this.instalmentFilterSlideinForm = this._initializeInstalmentFilterSlideinForm();
  }

  private _initializeInstalmentFilterSlideinForm(): FormGroup {
    return this._formBuilder.group({
      insuranceType: new FormControl(undefined),
      policyIssuedFrom: new FormControl(undefined),
      policyIssuedTo: new FormControl(undefined),
    });
  }

  public cancel(): void {
    this.instalmentFilterSlideinForm.reset();
  }

  public search(): void {
    const data: InstalmentFilterSlideInFormData = {
      insuranceType: this.instalmentFilterSlideinForm.controls.insuranceType.value as InsuranceType,
      policyIssuedFrom: this._dateFormatterService.getDDMMYYYYFormatDate(
        this.instalmentFilterSlideinForm.controls.policyIssuedFrom.value,
      ),
      policyIssuedTo: this._dateFormatterService.getDDMMYYYYFormatDate(
        this.instalmentFilterSlideinForm.controls.policyIssuedTo.value,
      ),
    };
    this.control.confirm(data);
  }
}
