import {Component, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Decimal} from '@atlas/businesstypes';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  MOCK_ADDITIONAL_RISKS_OPTIONS,
  MOCK_ADDITIONAL_RISKS_TICKS_OPTIONS,
} from './__tests__/price-covers-additional-risks-mock-data';
import {
  AdditonalRisksCheck,
  AdditonalRisksTicks,
  AdditonalRisksSlideInOutput,
} from './__tests__/price-covers-additional-risks-mock-interface';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

@Component({
  selector: 'nje-price-covers-additional-risks-slidein',
  templateUrl: './price-covers-additional-risks-slidein.component.html',
  styleUrls: ['./price-covers-additional-risks-slidein.component.scss'],
})
@UntilDestroy()
export class PriceCoversAdditionalRisksSlideinComponent
  extends ModalContentComponent<AdditonalRisksSlideInOutput>
  implements OnInit
{
  public additionalRisksOptions: Observable<AdditonalRisksCheck[]>;
  public additionalRisksTicksOptions: Observable<AdditonalRisksTicks[]>;
  public additionalRisksList: AdditonalRisksCheck[];

  public chkPrice: number = 0;

  public constructor(public control: ModalControl<AdditonalRisksSlideInOutput>) {
    super();
  }

  public ngOnInit(): void {
    this.initialiseApiCalls();
  }

  public initialiseApiCalls(): void {
    this.additionalRisksOptions = this.getChecksList();
    this.additionalRisksTicksOptions = this.getTicksList();

    this.getChecksList()
      .pipe(takeUntilDestroyed(this))
      .subscribe((data: AdditonalRisksCheck[]) => {
        this.additionalRisksList = data;
      });
  }

  public chkSelected(index: number, status: boolean, amount: string): void {
    if (!status) {
      this.chkPrice = this.chkPrice + parseFloat(amount);
    } else {
      this.chkPrice = this.chkPrice - parseFloat(amount);
    }
    this.additionalRisksList[index].selectedStatus =
      !this.additionalRisksList[index].selectedStatus;
  }

  public onSave(): void {
    const output: AdditonalRisksSlideInOutput = {
      amount: this.chkPrice,
    };
    this.control.confirm(output);
    this.additionalRisksList.forEach(
      (additionalRisks: AdditonalRisksCheck) => (additionalRisks.selectedStatus = false),
    );
  }

  public cancelSlideIn(): void {
    this.control.cancel();
    this.additionalRisksList.forEach(
      (additionalRisks: AdditonalRisksCheck) => (additionalRisks.selectedStatus = false),
    );
  }

  public getAmountValue(amount: string): Decimal {
    return new Decimal(amount);
  }

  public getChecksList(): Observable<AdditonalRisksCheck[]> {
    return of(MOCK_ADDITIONAL_RISKS_OPTIONS);
  }

  public getTicksList(): Observable<AdditonalRisksTicks[]> {
    return of(MOCK_ADDITIONAL_RISKS_TICKS_OPTIONS);
  }
}
