import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'nje-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public constructor(private readonly _router: Router) {}

  public ngOnInit(): void {}

  public onSubmit(): void {
    this._router.navigate(['/success']);
  }
}
