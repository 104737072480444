import {Injectable} from '@angular/core';
import {ApiService, API_END_POINT} from '@b2b-frontend/core';
import {Observable} from 'rxjs';
import {
  VehicleMake,
  VehicleModel,
  VehicleSubType,
  VehicleType,
} from '../../contracts/quick-vehicle-interface';

@Injectable({
  providedIn: 'root',
})
export class CascoNomenclatureApiServiceService {
  public constructor(private readonly _apiService: ApiService) {}

  public getVehicleType(): Observable<VehicleType[]> {
    return this._apiService.get<VehicleType[]>(API_END_POINT.VEHICLE_TYPES);
  }

  public getVehicleSubType(carType: number): Observable<VehicleSubType[]> {
    return this._apiService.get(`${API_END_POINT.VEHICLE_TYPES}/${carType}/car-subtypes`);
  }

  public getVehicleMake(carType: number): Observable<VehicleMake[]> {
    return this._apiService.get(`${API_END_POINT.VEHICLE_TYPES}/${carType}/car-makes`);
  }

  public getVehicleModel(carType: string, carMake: string): Observable<VehicleModel[]> {
    return this._apiService.get(
      `${API_END_POINT.VEHICLE_TYPES}/${carType}/car-makes/${carMake}/car-models`,
    );
  }
}
