import {Component, EventEmitter, Input, Output} from '@angular/core';
import {READ_MORE_LINK} from './__tests__/vehicle-user-input.component.mock';
import {
  FormGroupManagerFactoryService,
  FormGroupManager,
} from '@njf-frontend-angular/flow-progress';
import {FormControl} from '@angular/forms';
import {TalonSlideinService, VehicleTalonData} from '@b2b-frontend/core';
import {ModalConfirmedResult} from '@maia/modals';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {CascoWorkflow} from '../../../casco-core/casco-workflow/CascoWorkflow';

@Component({
  selector: 'nje-vehicle-user-input',
  templateUrl: './vehicle-user-input.component.html',
  styleUrls: ['./vehicle-user-input.component.scss'],
})
@UntilDestroy()
export class VehicleUserInputComponent {
  public readMoreLink = READ_MORE_LINK;
  public showForm: boolean = false;
  public showHeading: boolean = true;
  public vehicleTalonData: VehicleTalonData;
  public quickQuote: string = CascoWorkflow.QUICK_QUOTE;
  public priceOffer: string = CascoWorkflow.PRICE_OFFER;

  @Output()
  public readonly vehicleIdNumber: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public readonly vehicleRegNumber: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public readonly showFormEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  public screen: string;

  @Input()
  public isData: boolean;

  public vehicleSearchForm: FormGroupManager<any>;

  public constructor(
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _talonSlideInService: TalonSlideinService,
  ) {
    this.vehicleSearchForm = this._formFactory.createFormManager<any>({
      vehRegNo: new FormControl(undefined),
      vehIdNo: new FormControl(undefined),
    });
  }

  public openReadMore(): void {
    window.open(this.readMoreLink);
  }

  public openTalonSlideIn(): void {
    this._talonSlideInService
      .openTalonSlidIn()
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        (res: ModalConfirmedResult<VehicleTalonData>) => {
          const talonData: VehicleTalonData = res.result;
          this.patchTalonData(talonData);
        },
        (error: any) => {
          console.log('Vehicle Talon Scanning', error);
        },
      );
  }

  public patchTalonData(talonData: VehicleTalonData): void {
    if (talonData) {
      this.vehicleTalonData = Object.assign({}, talonData);
      this.vehicleSearchForm.patchValue({
        vehRegNo: talonData.regNo,
        vehIdNo: talonData.certNo,
      });
    }
  }

  public searchByIdNumber(value: string): void {
    if (value && value.length > 1) {
      this.showForm = true;
      this.vehicleIdNumber.emit(value);
    }
  }

  public searchByRegNumber(value: string): void {
    if (value && value.length > 1) {
      this.showForm = true;
      this.vehicleRegNumber.emit(value);
    }
  }

  public emitShowForm(): void {
    this.showForm = true;
    this.showFormEmitter.emit(true);
  }
}
