import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AlertFuncQuestionCircleSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendAlertFuncQuestionCircle]',
  template: '<svg:circle cx="12" cy="12" r="9.5" stroke-width="1.06" stroke-linecap="round" stroke-linejoin="round" fill="none"></svg:circle><svg:circle cx="11.96" cy="17.18" r=".86" stroke="none"></svg:circle><svg:path d="M10.11 7.3c1.71-.75 3.92-.66 4.68.56s.21 2.64-1.06 3.74S12 13.29 12 14.16" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.06"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertFuncQuestionCircleSvgComponent {
}

@NgModule({
  declarations: [
    AlertFuncQuestionCircleSvgComponent,
  ],
  exports: [
    AlertFuncQuestionCircleSvgComponent,
  ],
})
export class AlertFuncQuestionCircleSvgModule {}
