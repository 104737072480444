import {Injectable} from '@angular/core';

import {IPartyIdValidator} from '../IPartyIdValidator';

import {InvalidEgnError} from './InvalidEgnError';

@Injectable({
  providedIn: 'root',
})
export class EgnValidatorService implements IPartyIdValidator {
  private readonly pattern = /^\d{10}$/;

  private readonly EGN_WEIGHTS = [2, 4, 8, 5, 10, 9, 7, 3, 6];

  // The EGN can have three different formats depending on the century. It can
  // be determined by examining the month.
  // The rules are as follows:
  // * For people born in 1900..1999 the month does not change
  // * For people born in 1800..1899 the month is increased by 20 (e.g January is 21)
  // * For people born in 2000..2099 the month is increased by 40 (e.g December is 52)
  public getDateFromEGN(egn: string): Date {
    const year = parseInt(egn.substr(0, 2), 10);
    const month = parseInt(egn.substr(2, 2), 10);
    const day = parseInt(egn.substr(4, 2), 10);

    let realMonth: number;
    let realYear: number;

    if (1 <= month && month <= 12) {
      realMonth = month;
      realYear = 1900 + year;
    } else if (21 <= month && month <= 32) {
      realMonth = month - 20;
      realYear = 1800 + year;
    } else if (41 <= month && month <= 52) {
      realMonth = month - 40;
      realYear = 2000 + year;
    } else {
      throw new InvalidEgnError();
    }

    // tslint:disable-next-line: atlas-no-javascript-date
    const presumedDate = new Date(realYear, realMonth - 1, day);

    if (presumedDate.getDate() !== day) {
      // if day is 32 for example, the day will be overflowed to the next month in presumedDate
      // if it is valid then it will stay the same
      throw new InvalidEgnError();
    }

    return presumedDate;
  }

  private isDateValid(egn: string): boolean {
    const year = parseInt(egn.substr(0, 2), 10);
    const month = parseInt(egn.substr(2, 2), 10);
    const day = parseInt(egn.substr(4, 2), 10);

    let realMonth: number;
    let realYear: number;

    if (1 <= month && month <= 12) {
      realMonth = month;
      realYear = 1900 + year;
    } else if (21 <= month && month <= 32) {
      realMonth = month - 20;
      realYear = 1800 + year;
    } else if (41 <= month && month <= 52) {
      realMonth = month - 40;
      realYear = 2000 + year;
    } else {
      // not valid month
      return false;
    }

    // tslint:disable-next-line: atlas-no-javascript-date
    const presumedDate = new Date(realYear, realMonth - 1, day);

    if (presumedDate.getDate() !== day) {
      // if day is 32 for example, the day will be overflowed to the next month in presumedDate
      // if it is valid then it will stay the same
      return false;
    }

    return true;
  }

  private isValidControl(egn: string): boolean {
    const digits = Array.from(egn)
      .map(char => parseInt(char, 10))
      .slice(0, 9);
    const controlDigit = parseInt(Array.from(egn)[9], 10);
    const weightedDigits = digits.map((d, index) => d * this.EGN_WEIGHTS[index]);
    const weightedSum = weightedDigits.reduce((sum, d) => sum + d, 0);

    let calculatedControlDigit = weightedSum % 11;

    if (calculatedControlDigit === 10) {
      calculatedControlDigit = 0;
    }

    return calculatedControlDigit === controlDigit;
  }

  public isValidFormat(val: string): boolean {
    return this.pattern.exec(val) !== null;
  }

  public isValid(val: string): boolean {
    if (!this.isValidFormat(val)) {
      return false;
    }

    if (!this.isDateValid(val)) {
      return false;
    }

    if (!this.isValidControl(val)) {
      return false;
    }

    return true;
  }
}
