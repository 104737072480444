import {Component} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';

import {ModalContentComponent, ModalControl} from '@maia/modals';
import {ContactsService} from '../../../../services/contact.service';

@Component({
  selector: 'nje-remove-popup',
  templateUrl: './remove-popup.component.html',
  styleUrls: ['./remove-popup.component.scss'],
})
export class RemovePopupComponent extends ModalContentComponent<void> {
  public constructor(
    public control: ModalControl<void>,
    public loggerFactory: LoggerFactory,
    private readonly _contactService: ContactsService,
  ) {
    super();
  }

  public removeContact(): void {
    this._contactService.emitter.next(true);
    this.control.cancel();
  }
}
