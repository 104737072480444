<div class="deactivate-account-wrapper">
  <ng-container>
    <div class="grey-box-div">
      <maia-fluid-fluid-layout alignContentTop>
        <maia-avatar-display>
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendSocialExprPerson></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <maia-label
              class="b2b-kdl-font-small title-dark-color"
              [sublabel]="getUserPID(accountsData?.accounts[0])"
            >
              {{ getUserFullname(accountsData?.accounts[0]) }}
            </maia-label>

            <maia-label
              class="b2b-kdl-font-small title-dark-color account-type-label"
              [sublabel]="getUserName(accountsData?.accounts[0])"
            >
              {{ getAccountType(accountsData?.accounts[0]) }}
            </maia-label>
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>
    </div>

    <div class="grey-box-div deactivate-warning-txt">
      <maia-fluid-fluid-layout alignContentTop>
        Once deactivated, account cannot be activated again.
      </maia-fluid-fluid-layout>
    </div>

    <div class="calendar-wrapper">
      <maia-label
        class="b2b-kdl-font-medium title-dark-color calendar-label"
        *ngIf="accountsData?.individualAccount"
      >
        Date of Deactivation
      </maia-label>
      <maia-input-date
        name="startDate"
        [(ngModel)]="deactivationDate"
        [minimum]="minDate"
        [maximum]="maxDate"
        i18n-placeholder="@@nje-b2b-frontend-resource-articles-pin-date"
      >
      </maia-input-date>
    </div>
  </ng-container>

  <ng-container *maiaPopUpFooter>
    <button maiaButton maiaButtonType="tertiary" (click)="closePopup()">Cancel</button>
    <button maiaButton maiaButtonType="primary" (click)="deactivateAccount()">Deactivate</button>
  </ng-container>
</div>
