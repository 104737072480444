<maia-contextual-message type="success" class="b2b-kdl-font-small-bold">
  Offer has been saved, you application no. is 122346566
</maia-contextual-message>

<div class="adjust-label m-t-b-24">
  <maia-label class="b2b-kdl-font-medium-bold title-dark-color">Print Documnets</maia-label>
</div>

<div class="tile-shadow">
  <maia-fluid-fluid-layout>
    <maia-form-element class="input-column" hideOptionalIndicator>
      <maia-tile *ngFor="let document of documents" class="m-t-b-24 tile_size">
        <maia-tile-items>
          <maia-tile-item>
            <div class="document-details">
              <maia-avatar-display>
                <maia-avatar-svg class="avatar-background" avatarSize="48" maia-avatar-display-icon>
                  <svg b2bFrontendFileExprDocument class="tile-icon"></svg>
                </maia-avatar-svg>
              </maia-avatar-display>
              <div>
                <label class="text b2b-kdl-font-small">{{ document }}</label>
              </div>
            </div>
            <maia-divider></maia-divider>
            <div>
              <maia-button-group>
                <button maiaButton maiaButtonType="tertiary">Print</button>
                <button maiaButton maiaButtonType="tertiary">Download</button>
              </maia-button-group>
            </div>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
    </maia-form-element>
  </maia-fluid-fluid-layout>
</div>

<div class="button-adjust">
  <maia-button-group>
    <button maiaButton maiaButtonType="primary">Print All</button>
    <button maiaButton maiaButtonType="secondary">Download All</button>
  </maia-button-group>
</div>
