import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprInvoiceSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprInvoice]',
  template: '<svg:path fill="none" stroke-width="1.6" stroke-miterlimit="10" d="M9 5h30v38H9z"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-miterlimit="10" d="M14 10h20"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-miterlimit="10" d="M14 15.89h20v15.97H14z"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-miterlimit="10" d="M14.3 20.8h20"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-miterlimit="10" d="M14.3 26.95h20"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-miterlimit="10" d="M26 39h8"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-miterlimit="10" d="M17.63 15.89v15.97"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-miterlimit="10" d="M30 15.89v15.97"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprInvoiceSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprInvoiceSvgComponent,
  ],
  exports: [
    InformationExprInvoiceSvgComponent,
  ],
})
export class InformationExprInvoiceSvgModule {}
