export const INSURED_PARTY_INFO_TABLE_COL_HEADERS = [
  'EGN / LNCH / EIK',
  'Full Name',
  'Address',
  'Phone Number',
  'Email ID',
];

export const INSTALLMENTS_TABLE_COL_HEADERS = [
  'Installments',
  'Due Date',
  'Premium Amount (Incl. of taxes)',
];
export const INSTALLMENTS_DETAILS_LIST = {
  data: [
    {
      installments: '1 Installment',
      dueDate: '10/09/2022',
      premiumAmount: '444,36',
    },
    {
      installments: '2 Installment',
      dueDate: '18/12/2022',
      premiumAmount: '345,67',
    },
  ],
};

export const INSURED_PARTY_INFO_LIST = {
  data: [
    {
      id_number: '28974878237',
      full_name: 'Sithi Nadesh Kumar',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: 'sithi@gmail.com',
      phone: '8979901245',
      address: '51B, Nikoa Vaptzarov Blvd, Quarter, Sofia',
    },
    {
      id_number: '28974878238',
      full_name: 'Borish Andreev',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: 'boris@gmail.com',
      phone: '9799901245',
      address: '49D, Dolni Bogrov, Quarter, Sofia',
    },
    {
      id_number: '28974878239',
      full_name: 'Abc Company',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: 'abc@gmail.com',
      phone: '8939901245',
      address: '25C, Svetovrachene, Quarter, Sofia',
    },
    {
      id_number: '28974878240',
      full_name: 'Aditya Sahoo',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: 'aditya@gmail.com',
      phone: '8079901245',
      address: '44B, Malo Buchino, Quarter, Sofia',
    },
    {
      id_number: '28974878241',
      full_name: 'Inaoba Moirangthem',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: 'inaoba@gmail.com',
      phone: '8279901245',
      address: '34A, Dolni Bogrov, Quarter, Sofia',
    },
    {
      id_number: '28974878241',
      full_name: 'Inaoba Moirangthem',
      first_name: '',
      middle_name: '',
      last_name: '',
      email: 'inaoba@gmail.com',
      phone: '8279901245',
      address: '34A, Dolni Bogrov, Quarter, Sofia',
    },
  ],
};

export const INSURANCE_PARTY_DETAILS_LIST = {
  data: [
    {
      idNumber: '28974878237',

      firstNameEn: 'Sithi',
      middleNameEn: 'Nadesh',
      lastNameEn: 'Kumar',
      cityEn: {
        zip: '1000',
        city: 'Sofia',
      },
      postCodeEn: '1000',
      quarterEn: 'Kranso Solo',
      streetEn: 'Nikoa Vaptzarov Blvd',
      streetNumberEn: '51B',
      blockEn: '12',
      entranceEn: '8',
      floorEn: '22',
      apartmentEn: 'XYZ',

      citizenship: '',
      dob: '',

      phoneNumber: {
        prefix: '391',
        number: '9799901245',
      },
      emailId: 'sithi@gmail.com',

      companyNameEn: '',
      repIdNumber: '',
      repFirstNameEn: '',
      repMiddleNameEn: '',
      repLastNameEn: '',

      repCitizenship: '',
      repDob: '',

      contactIdNumber: '',
      contactFirstNameEn: '',
      contactMiddleNameEn: '',
      contactLastNameEn: '',

      contactCitizenship: '',
      contactDob: '',
      isInsuringParty: false,
    },
    {
      idNumber: '28974878238',

      firstNameEn: 'Borish',
      middleNameEn: '',
      lastNameEn: 'Andreev',
      cityEn: {
        zip: '1000',
        city: 'Sofia',
      },
      postCodeEn: '1000',
      quarterEn: 'Kranso Solo',
      streetEn: 'Dolni Bogrov',
      streetNumberEn: '49D',
      blockEn: '12',
      entranceEn: '8',
      floorEn: '22',
      apartmentEn: 'XYZ',

      citizenship: '',
      dob: '',

      phoneNumber: {
        prefix: '391',
        number: '9799901245',
      },
      emailId: 'boris@gmail.com',

      companyNameEn: '',
      repIdNumber: '',
      repFirstNameEn: '',
      repMiddleNameEn: '',
      repLastNameEn: '',

      repCitizenship: '',
      repDob: '',

      contactIdNumber: '',
      contactFirstNameEn: '',
      contactMiddleNameEn: '',
      contactLastNameEn: '',

      contactCitizenship: '',
      contactDob: '',
      isInsuringParty: false,
    },
  ],
};
