<njf-loader [hidden]="!showLoadingIcon"></njf-loader>
<form maiaForm>
  <maia-label
    class="b2b-kdl-font-medium title-dark-color m-t-32 library-heading-style"
    i18n="@@nje-b2b-frontend-resource-library-list-liblist"
    >List of Libraries</maia-label
  >
  <maia-radio-group
    ngDefaultControl
    name="list"
    class="tile-grid m-t-b-24"
    [(ngModel)]="libraryList"
  >
    <maia-tile
      *ngFor="let list of librarylistData; let i = index"
      [ngClass]="{'library-tile-selected': librarylistData[i].selectedStatus}"
      value="list"
      class="list-styling"
    >
      <njf-radio-button
        [value]="list"
        (click)="selectLibraryList(i, list)"
        class="radio-button-wrapper"
      >
        <div class="box-div">
          <div class="title-sub-div">
            <label class="b2b-kdl-font-small list-title">{{ list.i18n.en.title }}</label>
            <label class="b2b-kdl-font-small-light list-sub-title">
              <maia-rich-text [value]="list.i18n.en.description | hyperlink"></maia-rich-text>
            </label>
          </div>
          <maia-button-group class="align-left">
            <button
              maiaButton
              maiaButtonType="tertiary"
              (click)="addEditLibraryList(list, i)"
              i18n="@@nje-b2b-frontend-resource-library-list-edit"
              >Edit</button
            >
            <button
              maiaButton
              maiaButtonType="tertiary-delete"
              (click)="deleteLibraryList(i, list.id)"
              i18n="@@nje-b2b-frontend-resource-library-list-delete"
              >Delete</button
            >
          </maia-button-group>
        </div>
      </njf-radio-button>

      <!-- <njf-radio-button [value]="list" (click)="selectLibraryList(i, list)" class="radio-button-wrapper">
        <div class="box-div">
          <div class="title-sub-div">
            <label class="b2b-kdl-font-small list-title">{{ list.i18n.en.title }}</label>
            <label class="b2b-kdl-font-small-light list-sub-title">
              <maia-rich-text [value]="list.i18n.en.description | hyperlink"></maia-rich-text>
            </label>
          </div>
          <maia-button-group class="align-left">
            <button
              maiaButton
              maiaButtonType="tertiary"
              (click)="addEditLibraryList(list, i)"
              i18n="@@nje-b2b-frontend-resource-library-list-edit"
              >Edit</button
            >
            <button
              maiaButton
              maiaButtonType="tertiary-delete"
              (click)="deleteLibraryList(i, list.id)"
              i18n="@@nje-b2b-frontend-resource-library-list-delete"
              >Delete</button
            >
          </maia-button-group>
        </div>
      </njf-radio-button> -->
    </maia-tile>
  </maia-radio-group>
  <div class="action-with-icon" (click)="addEditLibraryList()">
    <svg class="action-func-plus-circle" b2bFrontendActionFuncPlusCircle></svg>
    <button
      maiaButton
      maiaButtonType="tertiary"
      class="add-btn"
      i18n="@@nje-b2b-frontend-resource-library-list-newlib"
      >Add New Library</button
    >
  </div>
  <maia-divider class="maia-divider border-margin"></maia-divider>
</form>
