import {Component, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {
  FilterControlLabel,
  RegisteredUser,
  DirectMarketing,
  DisplayUniqueRecords,
  StatisticsReportingSlideInFormFilter,
  FilterClaimActivity,
  FilterOfferActivity,
  FilterUserActivity,
  FilterPolicyActivity,
  FilterPaymentActivity,
} from '../../../contracts/statistics-reporting.interface';
import {
  YES,
  BLANK_VALUE,
  INSIS_SOURCE,
  PAYMENT_PROVIDER,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  REGISTERED_USER,
  DIRECT_MARKETING,
  DISPLAY_UNIQUE_RECORDS,
  ReportTypes,
} from '../../../constants/statistics-reporting/statistics-reporting-constants';
import {StatisticsReportingSessionService} from '../../../services/statistics-reporting-session.service';
import {Language} from '../../../constants/lang';

@Component({
  selector: 'nje-report-filter-slidein',
  templateUrl: './report-filter-slidein.component.html',
  styleUrls: ['./report-filter-slidein.component.scss'],
})
export class ReportFilterSlideinComponent
  extends ModalContentComponent<any, string>
  implements OnInit
{
  public tableFilterForm: FormGroup;
  public isRegisteredUserList: RegisteredUser[] = REGISTERED_USER;
  public isDirectMarketingUserList: DirectMarketing[] = DIRECT_MARKETING;
  public isDisplayUniqueRecordsList: DisplayUniqueRecords[] = DISPLAY_UNIQUE_RECORDS;
  public isPolicyCreatedList: RegisteredUser[] = REGISTERED_USER;
  public paymentProviderList: FilterControlLabel[] = PAYMENT_PROVIDER;
  public insisSourceList: FilterControlLabel[] = INSIS_SOURCE;
  public paymentStatusList: FilterControlLabel[] = PAYMENT_STATUS;
  public paymentTypeList: FilterControlLabel[] = PAYMENT_TYPE;
  public lang: string | null = sessionStorage.getItem('lang');

  public isFilterPolicyActivity: boolean = false;
  public isFilterOfferActivity: boolean = false;
  public isFilterClaimActivity: boolean = false;
  public isFilterUserActivity: boolean = false;
  public isFilterPaymentActivity: boolean = false;

  public reportType: string;
  public formDataObj: StatisticsReportingSlideInFormFilter;

  public constructor(
    public control: ModalControl<any, string>,
    private readonly _formBuilder: FormBuilder,
    private readonly _sessionService: StatisticsReportingSessionService,
  ) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this.reportType = data;
    this._showHideFilterControlsFlags(data);
    this._setFilterFormControls();
  }

  public applyFilter(): void {
    this._buildStatisticsReportingSlideInFormFilter();
    this._sessionService.setFilterData(this.formDataObj);
    this.control.confirm(this.tableFilterForm.value);
  }

  private _buildStatisticsReportingSlideInFormFilter(): void {
    switch (this.reportType) {
      case ReportTypes.PolicyActivity:
        this.formDataObj = {
          policyActivityFilter: this.tableFilterForm.value as FilterPolicyActivity,
        } as StatisticsReportingSlideInFormFilter;
        break;
      case ReportTypes.OfferActivity:
        this.formDataObj = {
          offerActivityFilter: this.tableFilterForm.value as FilterOfferActivity,
        } as StatisticsReportingSlideInFormFilter;
        break;
      case ReportTypes.ClaimActivity:
        this.formDataObj = {
          claimActivityFilter: this.tableFilterForm.value as FilterClaimActivity,
        } as StatisticsReportingSlideInFormFilter;
        break;
      case ReportTypes.UserActivity:
        this.formDataObj = {
          userActivityFilter: this.tableFilterForm.value as FilterUserActivity,
        } as StatisticsReportingSlideInFormFilter;
        break;
      case ReportTypes.PaymentActivity:
        this.formDataObj = {
          paymentActivityFilter: this.tableFilterForm.value as FilterPaymentActivity,
        } as StatisticsReportingSlideInFormFilter;
        break;
    }
  }

  public isLanguageEnglish(): boolean {
    return this.lang === Language.English;
  }

  public getRegisteredUser(user: RegisteredUser): string {
    return this.isLanguageEnglish() ? user.label : user.labelbg;
  }

  public getDirectMarketing(direct: DirectMarketing): string {
    return this.isLanguageEnglish() ? direct.label : direct.labelbg;
  }

  public getUniqueRecords(records: DisplayUniqueRecords): string {
    return this.isLanguageEnglish() ? records.label : records.labelbg;
  }

  public getFilterDetails(details: FilterControlLabel): string {
    return this.isLanguageEnglish() ? details.label : details.labelbg;
  }

  public clearFilter(): void {
    this.tableFilterForm.reset();
    this._sessionService.clearFilterData();
    if (this.isFilterUserActivity) {
      this.tableFilterForm = this._formBuilder.group({
        registeredUser: new FormControl(BLANK_VALUE),
        isDirectMarketingUser: new FormControl(BLANK_VALUE),
        displayUniqueRecords: new FormControl(YES),
      });
    }
  }

  private _showHideFilterControlsFlags(reportType: string): void {
    switch (reportType) {
      case ReportTypes.PolicyActivity:
        this.isFilterPolicyActivity = true;
        break;
      case ReportTypes.OfferActivity:
        this.isFilterOfferActivity = true;
        break;
      case ReportTypes.ClaimActivity:
        this.isFilterClaimActivity = true;
        break;
      case ReportTypes.UserActivity:
        this.isFilterUserActivity = true;
        break;
      case ReportTypes.PaymentActivity:
        this.isFilterPaymentActivity = true;
        break;
    }
  }

  private _setFilterFormControls(): void {
    const data: StatisticsReportingSlideInFormFilter = this._sessionService.getData();

    if (this.isFilterPolicyActivity) {
      this.tableFilterForm = this._formBuilder.group({
        registeredUser: new FormControl(data.policyActivityFilter?.registeredUser),
      });
    } else if (this.isFilterUserActivity) {
      this.tableFilterForm = this._formBuilder.group({
        registeredUser: new FormControl(data.userActivityFilter?.registeredUser),
        isDirectMarketingUser: new FormControl(data.userActivityFilter?.isDirectMarketingUser),
        displayUniqueRecords: new FormControl(data.userActivityFilter?.displayUniqueRecords),
      });
    } else if (this.isFilterOfferActivity) {
      this.tableFilterForm = this._formBuilder.group({
        registeredUser: new FormControl(data.offerActivityFilter?.registeredUser),
        policyCreated: new FormControl(data.offerActivityFilter?.policyCreated),
      });
    } else if (this.isFilterClaimActivity) {
      this.tableFilterForm = this._formBuilder.group({
        insisSource: new FormControl(data.claimActivityFilter?.insisSource),
      });
    } else if (this.isFilterPaymentActivity) {
      this.tableFilterForm = this._formBuilder.group({
        registeredUser: new FormControl(data.paymentActivityFilter?.registeredUser),
        paymentProvider: new FormControl(data.paymentActivityFilter?.paymentProvider),
        insisSource: new FormControl(data.paymentActivityFilter?.insisSource),
        paymentStatus: new FormControl(data.paymentActivityFilter?.paymentStatus),
        paymentType: new FormControl(data.paymentActivityFilter?.paymentType),
      });
    }
  }
}
