import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialExprPersonSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendSocialExprPerson]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path fill="none" stroke-miterlimit="10" d="M24 24h0a8 8 0 0 1-8-8v-1.6a8 8 0 0 1 8-8h0a8 8 0 0 1 8 8V16a8 8 0 0 1-8 8z" stroke-width="1.6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M40 35.885a5.598 5.598 0 0 0-4.006-5.37C33.182 29.678 29.03 28.8 24 28.8s-9.182.878-11.994 1.715A5.598 5.598 0 0 0 8 35.885V41.6h32v-5.715z" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialExprPersonSvgComponent {
}

@NgModule({
  declarations: [
    SocialExprPersonSvgComponent,
  ],
  exports: [
    SocialExprPersonSvgComponent,
  ],
})
export class SocialExprPersonSvgModule {}
