export interface EnvConfig {
  API_BASE_URL: string;
  FE_BASE_URL: string;
  API_URL: string;
  TALONE_SCANNING: {
    enableTalonScanning: boolean;
  };
}

export const TALON_ACTION = {
  UPLOAD: 'upload',
  DELETE: 'delete',
  FRONT_SIDE: 'front',
  BACK_SIDE: 'back',
  TALON: 'talon',
  IMAGE_TYPE: 'image/jpeg',
  BACK_SIDE_IMAGE_NAME: 'talon_back_side.jpg',
  BG_TALON: 'back_side',
  TALON_SOURCE: 'b2c',
  TALON_IMAGE_PATH: 'api/v1/mtpl/talon-image-download',
  DOCU_PATH: 'api/v1/claims/documents',
};

export const CAMERA_FACING_MODE: Record<string, string> = {
  USER_MODE: 'user',
  ENVIRONMENT_MODE: 'environment',
};
