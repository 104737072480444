<div class="activate-account-wrapper">
  <ng-container>
    <div class="grey-box-div" *ngIf="accountsData?.individualAccount">
      <maia-fluid-fluid-layout alignContentTop>
        <maia-avatar-display>
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendSocialExprPerson></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <maia-label
              class="b2b-kdl-font-small title-dark-color"
              [sublabel]="getUserPID(accountsData?.accounts[0])"
            >
              {{ getUserFullname(accountsData?.accounts[0]) }}
            </maia-label>

            <maia-label
              class="b2b-kdl-font-small title-dark-color account-type-label"
              [sublabel]="getUserName(accountsData?.accounts[0])"
            >
              {{ getAccountType(accountsData?.accounts[0]) }}
            </maia-label>
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>
    </div>

    <div *ngIf="!accountsData?.individualAccount" class="popup-body">
      {{ getConfirmationMessage() }}
    </div>
  </ng-container>

  <ng-container *maiaPopUpFooter>
    <button maiaButton maiaButtonType="tertiary" (click)="closePopup()">Cancel</button>
    <button maiaButton maiaButtonType="primary" (click)="activateAccount()">
      {{ getConfirmButtonText() }}</button
    >
  </ng-container>
</div>
