<maia-fluid-fluid-layout alignContentTop>
  <maia-contextual-message class="p-16" *ngIf="!showForm">
    Search Vehicle Data by using <span class="bold">Vehicle Identification Number</span> or
    <span class="bold">Vehicle registration number</span> or <span class="bold">Talon</span> or fill
    vehicle data
  </maia-contextual-message>
  <maia-contextual-message class="p-16" *ngIf="showForm && screen === quickQuote">
    Please enter vehicle details manually. If you want to create a MTPL policy

    <a [routerLink]="" class="a-color">Click here </a>
  </maia-contextual-message>
  <maia-contextual-message class="p-custom" *ngIf="showForm && screen === priceOffer">
    For MTPL offer/policy to be issued please

    <a class="a-color" [routerLink]="">Click here</a>. For Casco and MTPL together

    <a class="a-color" [routerLink]="">Click here</a>.
  </maia-contextual-message>
</maia-fluid-fluid-layout>
<div alignContentTop class="icon-align">
  <button maiaActionWithIcon class="btn-container m-l--10" (click)="openReadMore()">
    <svg b2bFrontendFileFuncDocument class="file-func-doc-size" maia-action-icon></svg>
    <span class="b2b-kdl-font-small" i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-a-ipid"
      >IPID Document</span
    >
  </button>
  <button maiaActionWithIcon class="btn-container m-r--10" (click)="openReadMore()">
    <svg b2bFrontendFileFuncDocument class="file-func-doc-size" maia-action-icon></svg>
    <span class="b2b-kdl-font-small" i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-a-readmore"
      >Read More</span
    >
  </button>
</div>
<form maiaForm [formGroup]="vehicleSearchForm.formGroup">
  <div class="vrc-vertical-div">
    <div class="vrc-div">
      <maia-form-element
        i18n-label="@@nje-b2b-frontend-casco-quick-vehicledetails-label-regno"
        class="multi-input-row"
        hideOptionalIndicator
        label="Vehicle Registration Number"
      >
        <maia-input-group>
          <input
            (keyup.enter)="searchByRegNumber(vehicleSearchForm.controls.vehRegNo.value)"
            maiaInput
            formControlName="vehRegNo"
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-regno"
            placeholder="Search by Registration Number"
          />
          <svg
            (click)="searchByRegNumber(vehicleSearchForm.controls.vehRegNo.value)"
            *ngIf="vehicleSearchForm.controls.vehRegNo.value?.length > 1"
            class="icon-size-color"
            b2bFrontendInformationFuncSearch
          ></svg>
        </maia-input-group>
      </maia-form-element>
      <maia-form-element
        class="multi-input-row m-t--14"
        hideOptionalIndicator
        label="Vehicle Identification Number"
      >
        <maia-input-group>
          <input
            (keyup.enter)="searchByIdNumber(vehicleSearchForm.controls.vehIdNo.value)"
            maiaInput
            formControlName="vehIdNo"
            placeholder="Search by Identification Number"
            i18n-placeholder="@@nje-b2b-frontend-casco-quick-vehicledetails-phd-idno"
          />
          <svg
            (click)="searchByIdNumber(vehicleSearchForm.controls.vehIdNo.value)"
            *ngIf="vehicleSearchForm.controls.vehIdNo.value?.length > 1"
            class="icon-size-color"
            b2bFrontendInformationFuncSearch
          ></svg>
        </maia-input-group>
      </maia-form-element>
    </div>
    <div class="div-or">
      <span class="vertical-divider"></span>
      <span i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-span-or">Or</span>
      <span class="vertical-divider"></span>
    </div>
    <div class="scan-talon-div">
      <maia-tile class="scan-talon-tile" (click)="openTalonSlideIn()">
        <svg b2bFrontendDevicesIndiQrScan maia-tile-icon class="scan-icon"></svg>
        <span
          maia-tile-label
          i18n="@@systemcode-casco-vehicledata-scantalon"
          class="p-t-20px b2b-kdl-font-normal"
          >Scan Talon</span
        >
      </maia-tile>
    </div>
  </div>
</form>
<div class="traffic-align" *ngIf="!showForm && screen === quickQuote">
  <p class="title-dark-color">To enter Vehicle data manually,</p>
  <a [routerLink]="" class="a-color" (click)="emitShowForm()">Click here.</a>
</div>
<div class="traffic-align" *ngIf="!showHeading">
  <p class="title-dark-color" i18n="@@nje-b2b-frontend-casco-quick-vehicledetails-p-trafficpolice"
    >If the vehicle is not registered in the traffic police,
  </p>
  <a [routerLink]="" class="a-color">Click here.</a>
</div>
<div class="traffic-align" *ngIf="!showForm && screen === priceOffer">
  <p
    class="title-dark-color"
    *ngIf="showHeading"
    i18n="@@nje-b2b-frontend-casco-price-vehicledetails-p-continueenter"
    >To continue entering data,</p
  >
  <a [routerLink]="" class="a-color" (click)="emitShowForm()">Click here.</a>
</div>
<maia-fluid-fluid-layout class="m-t-24" *ngIf="!isData">
  <maia-contextual-message
    class="p-16"
    i18n="@@nje-b2b-frontend-casco-price-vehicledetails-notretrieved"
  >
    Vehicle not retrieved by VIN / Reg No. You can proceed with manual vehicle data entry.
  </maia-contextual-message>
</maia-fluid-fluid-layout>
