import {
  Component,
  ChangeDetectorRef,
  OnInit,
  AfterViewInit,
  ViewChild,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ScreenStepComponent, FormGroupManager} from '@njf-frontend-angular/flow-progress';
import {SlideInController} from '@maia/slide-ins';
import {PopUpController} from '@maia/pop-ups';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {
  InsurancePartyDetails,
  InsurancePartyDetailsComponent,
  PropertyInsuranceProposalDto,
  PropertyAddressDetailsDto,
  PropertyFeatureDetails,
} from '@b2b-frontend/core';
import {PopUpContent} from '../../contracts/property-insurance.interface';
import {
  PROPERTY_DETAILS_TABLE_COL_HEADERS,
  PROPERTY_DETAILS_LIST,
  FEATURE_DETAILS_LIST,
} from './__tests__/property-details.mock';
import {PropertySessionService} from '../../property-core/services/property-session.service';
import {PropertyFeatureSlideinComponent} from '../property-feature-slidein/property-feature-slidein.component';
import {RemovePopupComponent} from '../remove-popup/remove-popup.component';
import {
  BLANK_VALUE,
  WARNING,
  REMOVE_PROPERTY_CONFIRMATION_MSG,
  REMOVE_PROPERTY_WITH_FEATURES_FOOTER_NOTE,
  ADD_PROPERTY_HOUSEHOLD,
  EDIT_PROPERTY_HOUSEHOLD,
  ADD_FEATURES,
  EDIT_FEATURES,
  PROPERTIES_INCLUDED,
  HOUSEHOLD_PROPERTY,
  VILLA,
  TRUE,
  FALSE,
  HOUSE,
  APARTMENT,
} from '../../constants/property-insurance-constants';

@Component({
  selector: 'property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss'],
})
@UntilDestroy()
export class PropertyDetailsComponent extends ScreenStepComponent implements OnInit, AfterViewInit {
  @ViewChild(InsurancePartyDetailsComponent)
  public insurancePartyDetailsComponent: InsurancePartyDetailsComponent;

  public insurancePartyDetails: InsurancePartyDetails;
  public insurancePartyDetailsForm: FormGroupManager<InsurancePartyDetails>;
  public insurancePartyDetailsList: InsurancePartyDetails[] = [];
  public propertyDetailsList: PropertyFeatureDetails[] = PROPERTY_DETAILS_LIST.data;
  public featureDetailsList: PropertyFeatureDetails[] = FEATURE_DETAILS_LIST.data;
  public insurancePartyList: string[] = [];
  public showPropertiesIncluded: boolean = FALSE;
  public isFeature: boolean = FALSE;
  public isNew: boolean = FALSE;
  public isAddressSaved: boolean = FALSE;
  public isAddressDetailsEditable: boolean = TRUE;
  public enableAddProperty: boolean = FALSE;
  public isImmovableProperty: boolean = FALSE;

  public insuredPartyForm: FormGroup;
  public mainPropertyType: string = BLANK_VALUE;

  public propertyAddressDetailsList: PropertyAddressDetailsDto[] = [];
  public selectedPropertyAddressDetails: PropertyAddressDetailsDto;

  //slideIn
  private readonly _slideInOneFactory: ComponentFactory<PropertyFeatureSlideinComponent>;
  private readonly _popUpDeleteFactory: ComponentFactory<RemovePopupComponent>;

  public columnHeaders: string[] = PROPERTY_DETAILS_TABLE_COL_HEADERS;

  public constructor(
    private readonly _formBuilder: FormBuilder,
    route: ActivatedRoute,
    private readonly _slideInCtrl: SlideInController,
    private readonly _popUpCtrl: PopUpController,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _propertySessionService: PropertySessionService,
  ) {
    super(route);

    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(RemovePopupComponent);
    this._slideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      PropertyFeatureSlideinComponent,
    );
  }

  public ngOnInit(): void {
    this._initilaizeInsuredPartyForm();
    const propertyServiceData: PropertyInsuranceProposalDto | undefined =
      this._propertySessionService.getPropertyData();

    if (
      propertyServiceData?.insuredPartyDetailsList &&
      null != propertyServiceData.insuredPartyDetailsList
    ) {
      this.insurancePartyDetailsList = propertyServiceData.insuredPartyDetailsList;
    } else {
      this.insurancePartyDetailsList = [];
    }

    let insuranceParty = BLANK_VALUE;
    for (const insurancePartyDetails of this.insurancePartyDetailsList) {
      insuranceParty =
        insurancePartyDetails.firstNameEn +
        ' ' +
        insurancePartyDetails.middleNameEn +
        ' ' +
        insurancePartyDetails.lastNameEn +
        ' (' +
        insurancePartyDetails.idNumber +
        ')';
      this.insurancePartyList.push(insuranceParty);
    }
  }

  public ngAfterViewInit(): void {
    // If there is only one insured party then it will be selected by default and there won't be any dropdown
    if (null != this.insurancePartyList && 1 === this.insurancePartyList.length) {
      this.insuredPartyForm?.controls?.insuredParty.patchValue(this.insurancePartyList[0]);
      this.onChangeOfInsuranceParty();
    }
    this.insurancePartyDetailsForm = this.insurancePartyDetailsComponent.getInsurancePartyDetails();
  }

  private _initilaizeInsuredPartyForm(): void {
    this.insuredPartyForm = this._formBuilder.group({
      // eslint-disable-next-line
      insuredParty: new FormControl(BLANK_VALUE, Validators.required),
    });
  }

  protected saveMtplData(): void {
    this._propertySessionService.setPropertyAddressDetailsDto(this.propertyAddressDetailsList);
  }

  public onChangeOfInsuranceParty(): void {
    if (null != this.insuredPartyForm.controls.insuredParty.value) {
      this.enableAddProperty = TRUE;
      const selectedInsurancePartyIndex = this.insurancePartyList.indexOf(
        this.insuredPartyForm.controls.insuredParty.value,
      );
      const selectedInsurancePartyDetails: InsurancePartyDetails =
        this.insurancePartyDetailsList[selectedInsurancePartyIndex];
      this.insurancePartyDetailsComponent.setInsurancePartyDetails(selectedInsurancePartyDetails);
      this.insurancePartyDetailsForm =
        this.insurancePartyDetailsComponent.getInsurancePartyDetails();
    }
  }

  public addEditProperty(propertyFeatureDetails?: PropertyFeatureDetails, index?: number): void {
    this.isFeature = FALSE;
    this.showPropertiesIncluded = FALSE;
    this.addEditPropertyFeature(propertyFeatureDetails, index);
  }

  public addEditFeature(propertyFeatureDetails?: PropertyFeatureDetails, index?: number): void {
    this.isFeature = TRUE;
    this.showPropertiesIncluded = FALSE;
    this.addEditPropertyFeature(propertyFeatureDetails, index);
  }

  // slideIn
  public addEditPropertyFeature(
    propertyFeatureDetails?: PropertyFeatureDetails,
    index?: number,
  ): void {
    !this.showPropertiesIncluded
      ? (this.selectedPropertyAddressDetails = {
          insurancePartyDetails: this.insurancePartyDetails,
          propertyDetailsList: this.propertyDetailsList,
          featureDetailsList: this.featureDetailsList,
          isAddressDetailsEditable: TRUE,
        })
      : BLANK_VALUE;

    // If propertyFeatureDetails is empty it means its a new property or a feature
    if (null == propertyFeatureDetails) {
      this.isNew = TRUE;
      propertyFeatureDetails = {
        isFeature: this.isFeature,
        isNew: this.isNew,
        showPropertiesIncluded: this.showPropertiesIncluded,
        selectedPropertyAddressDetails: this.selectedPropertyAddressDetails,
      } as PropertyFeatureDetails;
    } else {
      this.isNew = FALSE;
      propertyFeatureDetails.isFeature = this.isFeature;
      propertyFeatureDetails.isNew = FALSE;
      propertyFeatureDetails.showPropertiesIncluded = this.showPropertiesIncluded;
      propertyFeatureDetails.selectedPropertyAddressDetails = this.selectedPropertyAddressDetails;
    }

    let sliderTitle =
      TRUE === propertyFeatureDetails.isFeature
        ? TRUE === propertyFeatureDetails.isNew
          ? ADD_FEATURES
          : EDIT_FEATURES
        : TRUE === propertyFeatureDetails.isNew
        ? ADD_PROPERTY_HOUSEHOLD
        : EDIT_PROPERTY_HOUSEHOLD;

    if (TRUE === this.showPropertiesIncluded) {
      sliderTitle = PROPERTIES_INCLUDED;
    }

    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {title: sliderTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: propertyFeatureDetails,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<PropertyFeatureDetails>) => {
        if (res.resolution === ModalResolution.CONFIRMED) {
          const insuredPersonData = res.result;
          if (null != res && null != index) {
            this.updatePropertyFeature(insuredPersonData, index);
          } else {
            this.updatePropertyFeature(insuredPersonData);
          }
        }
      });
  }

  public updatePropertyFeature(
    propertyFeatureDetails: PropertyFeatureDetails,
    index?: number,
  ): void {
    if (null != propertyFeatureDetails && null != index) {
      if (null != this.isFeature && TRUE === this.isFeature) {
        this.featureDetailsList[index] = propertyFeatureDetails;
      } else {
        this.propertyDetailsList[index] = propertyFeatureDetails;
      }
    } else {
      if (null != this.isFeature && TRUE === this.isFeature) {
        this.featureDetailsList.push(propertyFeatureDetails);
      } else {
        this.propertyDetailsList.push(propertyFeatureDetails);
      }
    }
    this._cdRef.detectChanges();
  }

  // eslint-disable-next-line
  public removeProperty(index: number, isFeature?: boolean, removeSavedAddress?: boolean): void {
    const removePopupMsgObj: PopUpContent = {
      title: WARNING,
      message: REMOVE_PROPERTY_CONFIRMATION_MSG,
      footerNote: BLANK_VALUE,
    };
    if (0 < this.propertyDetailsList.length) {
      if (0 == this.featureDetailsList.length) {
        removePopupMsgObj.message = REMOVE_PROPERTY_CONFIRMATION_MSG;
        removePopupMsgObj.footerNote = BLANK_VALUE;
      } else if (0 < this.featureDetailsList.length || TRUE === removeSavedAddress) {
        removePopupMsgObj.message = REMOVE_PROPERTY_CONFIRMATION_MSG;
        removePopupMsgObj.footerNote = isFeature
          ? BLANK_VALUE
          : REMOVE_PROPERTY_WITH_FEATURES_FOOTER_NOTE;
      }
    }

    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: WARNING, footerInScrollArea: true, size: 'large'},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: removePopupMsgObj,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          if (null != index && 0 <= index) {
            if (TRUE === removeSavedAddress) {
              this.propertyDetailsList = [];
              this.featureDetailsList = [];
              this.propertyAddressDetailsList = [];
              this._initilaizeInsuredPartyForm();
            } else {
              if (null != isFeature) {
                this.featureDetailsList.splice(index, 1);
              } else {
                this.propertyDetailsList.splice(index, 1);
              }
            }
          } else {
            if (null != isFeature) {
              this.featureDetailsList = [];
            } else {
              this.propertyDetailsList = [];
            }
          }

          if (0 === this.propertyDetailsList.length) {
            this.featureDetailsList = [];
          }
        }
        this._cdRef.detectChanges();
      });
  }

  public isSaveAddressBtnDisabled(): boolean {
    return 0 < this.propertyDetailsList.length &&
      this.insuredPartyForm?.valid &&
      this.insurancePartyDetailsForm?.valid
      ? FALSE
      : TRUE;
  }

  public saveAddressDetails(index: number): void {
    const insurancePartyDetails: InsurancePartyDetails = this.insurancePartyDetailsForm?.value;
    const propertyAddressDetails: PropertyAddressDetailsDto = {
      insurancePartyDetails: insurancePartyDetails,
      propertyDetailsList: this.propertyDetailsList,
      featureDetailsList: this.featureDetailsList,
      isAddressDetailsEditable: 0 <= index ? FALSE : TRUE,
    };

    // If user has clicked on teh Save button on the Save Address Details card then updat theat Card after edit.
    if (propertyAddressDetails.isAddressDetailsEditable) {
      this.propertyAddressDetailsList.push(propertyAddressDetails);
    } else {
      0 <= index ? (this.propertyAddressDetailsList[index] = propertyAddressDetails) : BLANK_VALUE;
    }

    this.isImmovableProperty = this.propertyDetailsList.some(
      propertyDetails =>
        propertyDetails.propertyType === VILLA ||
        propertyDetails.propertyType === HOUSE ||
        propertyDetails.propertyType === APARTMENT,
    );
    const isVillaAdded: boolean = this.propertyDetailsList.some(
      propertyDetails => propertyDetails.propertyType === VILLA,
    );
    this.mainPropertyType = isVillaAdded ? VILLA : HOUSEHOLD_PROPERTY;
    this.isAddressSaved = TRUE;
    // After address is saved, clear the insured party form, property and feature details tables array.slice(0,array.length-1);
    this.insurancePartyDetailsForm.reset();
    this.insuredPartyForm.reset();
    this.propertyDetailsList = [];
    this.featureDetailsList = [];
  }

  public reloadPropertyDetails(propertyAddressDetails: PropertyAddressDetailsDto): void {
    // Retrieve and set Insured Party Value in the Insured Party Form
    const insurancePartyDetails = this.insurancePartyList.filter(insuranceParty =>
      insuranceParty.includes(propertyAddressDetails.insurancePartyDetails.idNumber),
    );
    this.insuredPartyForm.controls.insuredParty.patchValue(insurancePartyDetails[0]);

    // Retrieve and set remaining Insured Party field values in the Insured Party Form
    this.insurancePartyDetailsComponent.setInsurancePartyDetails(
      propertyAddressDetails.insurancePartyDetails,
    );
    this.insurancePartyDetailsForm = this.insurancePartyDetailsComponent.getInsurancePartyDetails();

    // Retrieve and set propertyDetailsList and featureDetailsList in the corrresponding tables
    this.propertyDetailsList = propertyAddressDetails.propertyDetailsList;
    this.featureDetailsList = propertyAddressDetails.featureDetailsList;
  }

  public removeAddressDetails(propertyAddressDetails: PropertyAddressDetailsDto): void {
    this.reloadPropertyDetails(propertyAddressDetails);
    this.propertyAddressDetailsList = this.propertyAddressDetailsList.filter(
      propertyAddressDetails =>
        propertyAddressDetails.insurancePartyDetails.idNumber !==
        propertyAddressDetails.insurancePartyDetails.idNumber,
    );
  }

  public editAddressDetails(propertyAddressDetails: PropertyAddressDetailsDto): void {
    this.isAddressDetailsEditable = FALSE;
    this.reloadPropertyDetails(propertyAddressDetails);
  }

  public seePropertiesIncluded(propertyAddressDetails: PropertyAddressDetailsDto): void {
    this.selectedPropertyAddressDetails = propertyAddressDetails;
    this.showPropertiesIncluded = TRUE;
    this.addEditPropertyFeature();
  }

  public isInsuredPartyFormValid(): boolean {
    return this.insurancePartyDetailsForm?.valid;
  }
}
