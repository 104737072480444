<div class="set-up-form-wrapper form">
  <div class="set-up-form">
    <svg b2bFrontendInformationExprQr class="icon-adjust"></svg>
    <maia-label class="b2b-kdl-font-grand-serif title-dark-color step-in-txt"
      >Setup Google Authenticator</maia-label
    >
    <maia-label class="b2b-kdl-font-normal verify-in-txt"
      >1 Get the <span class="b2b-kdl-font-normal-bold">Google Authenticator App</span> from the
      Play Store</maia-label
    >
    <maia-label class="b2b-kdl-font-normal verify-in-txt"
      >2 In the app select <span class="b2b-kdl-font-normal-bold">Set up account</span></maia-label
    >
    <maia-label class="b2b-kdl-font-normal verify-in-txt"
      >3 Choose <span class="b2b-kdl-font-normal-bold">Scan barcode</span></maia-label
    >
    <div class="barcode">
      <img src="assets/img/scan.svg" class="scan-icon-adjust" width="156" height="156" />
      <maia-label class="b2b-kdl-font-normal scan-text" (click)="scanContinue()"
        >Can’t scan it?</maia-label
      >
    </div>
    <maia-button-group class="m-t-16 m-b-8 button-wrapper">
      <button maiaButton maiaButtonType="primary" id="setupGoogleForm" (click)="onContinue()">
        Continue
      </button>
    </maia-button-group>
  </div>
</div>
