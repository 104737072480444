<maia-divider class="m-t-b-24"></maia-divider>

<form maiaForm [formGroup]="tableFilterForm">
  <maia-form-group>
    <maia-fluid-fluid-layout>
      <maia-form-element *ngIf="isFilterUserActivity" hideOptionalIndicator class="m-r-16">
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-uniquerecords"
          >Display only unique records</label
        >
        <maia-input-select formControlName="displayUniqueRecords">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-uniqueselect"
            [value]="tableFilterForm.controls.displayUniqueRecords.value"
          />
          <maia-option
            *ngFor="let isDirectMarketingUser of isDirectMarketingUserList"
            [value]="getDirectMarketing(isDirectMarketingUser)"
            >{{ this.getDirectMarketing(isDirectMarketingUser) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout>
      <maia-form-element
        *ngIf="isFilterPolicyActivity || isFilterUserActivity || isFilterOfferActivity"
        hideOptionalIndicator
        class="m-r-16"
      >
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-reguser"
          >Registered User</label
        >
        <maia-input-select formControlName="registeredUser">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-regselect"
            [value]="tableFilterForm.controls.registeredUser.value"
          />
          <maia-option
            *ngFor="let isRegisteredUser of isRegisteredUserList"
            [value]="getRegisteredUser(isRegisteredUser)"
            >{{ this.getRegisteredUser(isRegisteredUser) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>

      <maia-form-element *ngIf="isFilterUserActivity" hideOptionalIndicator class="m-r-16">
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-directmarketing"
          >Direct marketing</label
        >
        <maia-input-select formControlName="isDirectMarketingUser">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-directselect"
            [value]="tableFilterForm.controls.isDirectMarketingUser.value"
          />
          <maia-option
            *ngFor="let isDirectMarketingUser of isDirectMarketingUserList"
            [value]="getDirectMarketing(isDirectMarketingUser)"
            >{{ this.getDirectMarketing(isDirectMarketingUser) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>

      <maia-form-element *ngIf="isFilterOfferActivity" hideOptionalIndicator>
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-policycreated"
          >Policy Created</label
        >
        <maia-input-select formControlName="policyCreated">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-policyselect"
            [value]="tableFilterForm.controls.policyCreated.value"
          />
          <maia-option
            *ngFor="let isPolicyCreated of isPolicyCreatedList"
            [value]="getRegisteredUser(isPolicyCreated)"
            >{{ this.getRegisteredUser(isPolicyCreated) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <maia-form-element *ngIf="isFilterPaymentActivity" hideOptionalIndicator class="m-r-16">
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-registeruser"
          >Registered User</label
        >
        <maia-input-select formControlName="registeredUser">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-userselect"
            [value]="tableFilterForm.controls.registeredUser.value"
          />
          <maia-option
            *ngFor="let isRegisteredUser of isRegisteredUserList"
            [value]="getRegisteredUser(isRegisteredUser)"
            >{{ this.getRegisteredUser(isRegisteredUser) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
      <maia-form-element *ngIf="isFilterPaymentActivity" hideOptionalIndicator class="m-r-16">
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-paymentselect"
          >Payment provider</label
        >
        <maia-input-select formControlName="paymentProvider">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-paymentselect"
            [value]="tableFilterForm.controls.paymentProvider.value"
          />
          <maia-option
            *ngFor="let provider of paymentProviderList"
            [value]="getFilterDetails(provider)"
            >{{ this.getFilterDetails(provider) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <maia-form-element
        *ngIf="isFilterPaymentActivity || isFilterClaimActivity"
        hideOptionalIndicator
      >
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-insis"
          >Insis source</label
        >
        <maia-input-select formControlName="insisSource">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-insisselect"
            [value]="tableFilterForm.controls.insisSource.value"
          />
          <maia-option
            *ngFor="let insisSource of insisSourceList"
            [value]="getFilterDetails(insisSource)"
            >{{ this.getFilterDetails(insisSource) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
      <maia-form-element *ngIf="isFilterPaymentActivity" hideOptionalIndicator class="m-r-16">
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-status"
          >Payment status</label
        >
        <maia-input-select formControlName="paymentStatus">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-statusselect"
            [value]="tableFilterForm.controls.paymentStatus.value"
          />
          <maia-option
            *ngFor="let paymentStatus of paymentStatusList"
            [value]="getFilterDetails(paymentStatus)"
            >{{ this.getFilterDetails(paymentStatus) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <maia-form-element *ngIf="isFilterPaymentActivity" hideOptionalIndicator>
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-statistics-filter-paymenttype"
          >Payment type</label
        >
        <maia-input-select formControlName="paymentType">
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="@@nje-b2b-frontend-statistics-filter-typeselect"
            [value]="tableFilterForm.controls.paymentType.value"
          />
          <maia-option
            *ngFor="let paymentType of paymentTypeList"
            [value]="getFilterDetails(paymentType)"
            >{{ this.getFilterDetails(paymentType) }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
      <maia-form-element hideOptionalIndicator> </maia-form-element>
    </maia-fluid-fluid-layout>
  </maia-form-group>
</form>

<ng-container *maiaSlideInFooter>
  <maia-button-group>
    <button
      maiaButton
      maiaButtonType="primary"
      (click)="applyFilter()"
      i18n="@@nje-b2b-frontend-statistics-filter-applyfilter"
      >Apply Filter</button
    >
    <button
      maiaButton
      maiaButtonType="secondary"
      (click)="clearFilter()"
      i18n="@@nje-b2b-frontend-statistics-filter-clearfilter"
      >Clear Filter</button
    >
  </maia-button-group>
</ng-container>
