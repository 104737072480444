import {AbstractControl, ValidationErrors} from '@angular/forms';

export function nonCyrillicLetters(control: AbstractControl): ValidationErrors | null {
  const isValid =
    /[а-яА-Я]/.test(control.value === null || control.value === undefined ? '' : control.value) ===
    false;
  if (!isValid) {
    return {
      nonCyrillicLetters: true,
    };
  } else {
    return null;
  }
}
