import {Injectable} from '@angular/core';
import {ApiService} from '@b2b-frontend/core';
import {Observable} from 'rxjs';

import {ApiEndpoints} from '../constants/resource-management/admin-documents';
import {
  AdminLibraryDocumentDto,
  CreateOrUpdateAdminLibraryDocumentInput,
  UserType,
} from '../contracts/admin-documents.interface';
import {AdminLibrary} from '../contracts/adminInterface';

@Injectable({
  providedIn: 'root',
})
export class AdminLibraryService {
  public constructor(private readonly _apiService: ApiService) {}

  public getAdminLibraryList(): Observable<AdminLibrary[]> {
    return this._apiService.get(ApiEndpoints.AdminLibrary);
  }

  public createAdminLibrary(library: AdminLibrary): Observable<AdminLibrary> {
    return this._apiService.postFiles(ApiEndpoints.AdminLibrary, library);
  }

  public deleteAdminLibrary(id: number): Observable<AdminLibrary> {
    return this._apiService.delete(`${ApiEndpoints.AdminLibrary}/${id}`);
  }

  public updateAdminLibrary(library: AdminLibrary): Observable<AdminLibrary> {
    return this._apiService.patch(`${ApiEndpoints.AdminLibrary}/${library.id}`, library);
  }

  public getAdminLibraryDocumentsForSelectedLibrary(
    id: number,
  ): Observable<AdminLibraryDocumentDto[]> {
    return this._apiService.get(`${ApiEndpoints.AdminLibrary}/${id}/document`);
  }

  public updateDocumentList(
    adminLibraryid: number,
    id: number,
    createAdminLibraryDocumentObj: CreateOrUpdateAdminLibraryDocumentInput,
  ): Observable<AdminLibraryDocumentDto> {
    return this._apiService.patch(
      `${ApiEndpoints.AdminLibrary}/${adminLibraryid}/document/${id}`,
      createAdminLibraryDocumentObj,
    );
  }

  public AddDocumentList(
    id: number,
    updateAdminLibraryObj: CreateOrUpdateAdminLibraryDocumentInput,
  ): Observable<AdminLibraryDocumentDto> {
    return this._apiService.postFiles(
      `${ApiEndpoints.AdminLibrary}/${id}/${'document'}`,
      updateAdminLibraryObj,
    );
  }

  public DeleteDocumentList(
    adminLibraryid: number,
    id: number,
  ): Observable<AdminLibraryDocumentDto> {
    return this._apiService.delete(`${ApiEndpoints.AdminLibrary}/${adminLibraryid}/document/${id}`);
  }

  public searchAdminLibrariesDocuments(searchText: string): Observable<AdminLibraryDocumentDto[]> {
    return this._apiService.get(`${ApiEndpoints.AdminLibraryDocumentSearch}?search=${searchText}`);
  }

  public getUserTypeList(): Observable<UserType[]> {
    return this._apiService.get(`${ApiEndpoints.UserTypes}`);
  }
}
