import {Component, OnInit} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {StepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {CascoProcessService} from './casco-core/services/casco-process.service';
import {CascoPolicyService} from './casco-core/services/casco-policy.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CascoSessionService} from './casco-core/services/casco-session.service';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'nje-casco-home',
  templateUrl: './casco-home.component.html',
  styleUrls: ['./casco-home.component.scss'],
})
export class CascoHomeComponent implements OnInit {
  public steps: Observable<StepperProcessStep[]> = this.process.onStepperWorkflowSteps;
  public isEditing: Observable<boolean> = this.process.stepEditing;
  private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isStepperVisible: boolean = true;

  public constructor(
    public process: CascoProcessService,
    public casco: CascoPolicyService,
    private readonly router: Router,
    public route: ActivatedRoute,
    public cascoSessionService: CascoSessionService,
  ) {}

  public ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: any) => event instanceof NavigationEnd),
        startWith(null),
        map(() => {
          let currentRoute = this.route;
          while (currentRoute.firstChild != null) {
            currentRoute = currentRoute.firstChild;
          }
          let currentRouteS = currentRoute.snapshot;
          while (currentRouteS.parent != null) {
            if ('isStepperVisible' in currentRouteS.data) {
              return currentRouteS.data.isStepperVisible as boolean;
            }
            currentRouteS = currentRouteS.parent;
          }
          return true;
        }),
      )
      .subscribe((val: boolean) => (this.isStepperVisible = val));
  }
}
