<ng-container>
  <div class="adjust-label">
    <maia-label class="b2b-kdl-font-medium-bold">Remove Person</maia-label>
    <p class="b2b-kdl-font-normal-light">Removing insured people will change your premium amount</p>
  </div>

  <div *maiaPopUpFooter>
    <button maiaButton maiaButtonType="tertiary" (click)="control.cancel()">Cancel</button>
    <button maiaButton maiaButtonType="delete" (click)="delete()">Remove</button>
  </div>
</ng-container>
