<njf-loader [hidden]="!showLoadingIcon"></njf-loader>
<div>
  <form maiaForm [formGroup]="coversDiscountsDetailsForm">
    <maia-contextual-message
      *ngIf="currencyChanged | atlasBoolean"
      class="banner"
      id="contextual-message-2"
      type="informative"
    >
      Please calculate premium to proceed!
    </maia-contextual-message>
    <maia-fluid-fluid-layout>
      <div>
        <maia-form-element class="input-column currency">
          <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
            <label class="b2b-kdl-font-small title-dark-color"> Select currency </label>
          </maia-label>
          <maia-input-select
            name="currency"
            formControlName="currency"
            (ngModelChange)="changeCurrenySelection()"
          >
            <input
              maiaInput
              placeholder="Select"
              [value]="coversDiscountsDetailsForm.controls.currency.value"
            />
            <maia-option *ngFor="let currency of currencyList" [value]="currency.value">{{
              currency.label
            }}</maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-price-block class="price-block">
      <svg class="icon-white-color" b2bFrontendAlertFuncInfoCircle maia-price-icon></svg>
      <div maia-price-title>
        <maia-label class="b2b-kdl-font-small-light"
          >As a HA employee, you have additional underwritings limits eligible. Click to check your
          underwriting limits</maia-label
        >
        <button
          maiaButton
          maiaButtonType="primary"
          class="underwriting-button"
          (click)="underwritingLimitsSlidein()"
          >Check underwritings limits</button
        >
      </div>
    </maia-price-block>
    <div *ngFor="let covers_discount of coversDiscountLayoutInfoPassed">
      <nje-property-cover-discount-layout
        [additionalCoverList]="covers_discount.additionalCoverList"
        [additionalCoversInfoList]="covers_discount.additionalCoversInfoList"
        [propertyCoversInfoList]="covers_discount.propertyCoversInfoList"
        [propertyAddress]="covers_discount.propertyAddress"
        [premiumAmountVisibility]="premiumAmountVisibility"
        [currencySelected]="currencySelected"
      >
      </nje-property-cover-discount-layout>
    </div>
    <maia-divider class="m-t-b-24"></maia-divider>
    <maia-fluid-fluid-layout>
      <div>
        <maia-form-element class="input-column installment-option" hideOptionalIndicator>
          <label class="b2b-kdl-font-small title-dark-color"> Installment Option </label>
          <maia-input-select
            name="installment_option"
            formControlName="installment_option"
            (ngModelChange)="onChangeOfInstallmentOption()"
          >
            <input
              maiaInput
              placeholder="Installment Option"
              [value]="coversDiscountsDetailsForm.controls.installment_option.value"
            />
            <maia-option
              *ngFor="let installment_option of installmentslist"
              [value]="installment_option.name"
              >{{ installment_option.name }}</maia-option
            >
          </maia-input-select>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
  </form>
  <div *ngIf="discountVisible">
    <maia-divider class="m-t-b-24"></maia-divider>
    <maia-fluid-fluid-layout>
      <maia-highlight-panel class="maia-highlight-panel" *ngIf="!ViewMore" coversDiscounts>
        <svg b2bFrontendAlertExprCheckCircle maia-highlight-panel-icon class="maia-icon"></svg>
        <h4 class="highlight-content">Automatic loadings and discounts are applied.</h4>
        <button
          maiaButton
          maiaButtonType="tertiary"
          maia-highlight-panel-footer
          (click)="view_more()"
          class="view-more"
          >View more</button
        >
      </maia-highlight-panel>
      <maia-panel *ngIf="ViewMore" class="maia-panel" id="maia-panel-default">
        <maia-panel-title> Automatic loadings and discounts are applied. </maia-panel-title>
        <div>
          <p>Loadings</p>
          <ul maiaTextList *ngFor="let loading_val of loadingList" id="maia-text-list">
            <li maiaTextListItem>{{ loading_val.name }}</li>
            <ul
              maiaTextList
              *ngFor="let list_val of loading_val.list"
              id="maia-text-list-4"
              [iconType]="'checkmark'"
            >
              <li maiaTextListItem>
                {{ list_val }}
              </li>
            </ul>
          </ul>
          <p>Discounts</p>
          <ul maiaTextList id="maia-text-list-4" [iconType]="'checkmark'">
            <li maiaTextListItem> Black Friday Discount (-15%) </li>
          </ul>
        </div>
        <maia-inline-action class="view-less" type="button" (click)="view_more()">
          View less
        </maia-inline-action>
      </maia-panel>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>
    <h3>Discounts</h3>
    <maia-contextual-message class="banner" id="contextual-message-2" type="informative">
      New Discounts are now available! Your sum of discounts is limited to 25% ( Including automatic
      discounts)
    </maia-contextual-message>
    <div class="checkbox-group">
      <div class="additional-cover-checkbox" *ngFor="let cover of discounts; let i = index">
        <div class="additional-cover-div">
          <maia-tile
            [ngClass]="{
              'addons-tile-selected': this.manualDiscountSelected[i] | atlasBoolean,
              'discount-tile-invalid': displayErrorMsg(cover)
            }"
            class="tile-display"
            [disabled]="disableTile(cover)"
          >
            <maia-tile-items>
              <maia-tile-item>
                <maia-checkbox
                  class="discount-checkbox"
                  [(ngModel)]="this.manualDiscountSelected[i]"
                  (onValueChange)="changeDiscountSelection(i)"
                >
                  <label>{{ cover.discountType }}</label>
                </maia-checkbox>
              </maia-tile-item>
            </maia-tile-items>
          </maia-tile>
          <maia-form-element
            *ngIf="this.manualDiscountSelected[i] | atlasBoolean"
            class="aggregate-frame"
            hideOptionalIndicator
          >
            <label class="b2b-kdl-font-small title-dark-color"> Type 0-5 % </label>
            <maia-input-group class="aggregate-limit">
              <input
                maiaInputNumber
                [(ngModel)]="cover.discountValue"
                (keyup)="onDiscountValueEntered()"
              />
              <maia-input-group-suffix>%</maia-input-group-suffix>
            </maia-input-group>
            <span *ngIf="displayErrorMsg(cover)" class="discount-error-msg"
              >Total discount should be less than {{ maxDiscountApplied }}%</span
            >
          </maia-form-element>
        </div>
      </div>
    </div>
    <maia-divider></maia-divider>
    <maia-contextual-message
      *ngIf="enableContinueButton === true"
      class="banner"
      id="contextual-message-2"
      type="success"
    >
      The Premium amount has been calculated!
    </maia-contextual-message>
  </div>
</div>

<div class="cover-discount-buttons">
  <maia-button-group>
    <button
      maiaButton
      maiaButtonType="primary"
      [disabled]="enableContinueButton === false"
      (click)="saveCoversDiscountsData()"
      >Continue</button
    >
    <button
      maiaButton
      maiaButtonType="secondary"
      (click)="calculatePremium()"
      [disabled]="calculatePremiumEnable === false"
      >Calculate Premium</button
    >
  </maia-button-group>
</div>
<njf-covers-accordion
  *ngIf="property.showHideAccordian | async"
  [totalInsuranceAmount]="insurancePremiumAmount"
  [title]="title"
  [Items]="items"
></njf-covers-accordion>
