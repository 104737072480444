import {IStepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {CascoRoutePaths} from '../../constants/route-paths';

export const steps: IStepperProcessStep[] = [
  {
    id: CascoRoutePaths.CI_JOURNEY_CHOICE,
    title: 'Pick Terms',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_JOURNEY_CHOICE}`,
  },
  {
    id: CascoRoutePaths.CI_VEHICLE_AND_OWNER_DETAILS,
    title: 'Vehicle & Owner Details',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_VEHICLE_AND_OWNER_DETAILS}`,
  },
  {
    id: CascoRoutePaths.CI_COVERS,
    title: 'Covers & Discounts',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_COVERS}`,
  },
  {
    id: CascoRoutePaths.CI_PREMIUM,
    title: 'Premium',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PREMIUM}`,
  },
  {
    id: CascoRoutePaths.CI_PROPOSAL,
    title: 'Proposal',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PROPOSAL}`,
  },
  {
    id: CascoRoutePaths.CI_PRICE_VEHICLE_DETAILS,
    title: 'Vehicle Details',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PRICE_VEHICLE_DETAILS}`,
  },
  {
    id: CascoRoutePaths.CI_PRICE_SUM_INSURED,
    title: 'Sum Insured',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PRICE_SUM_INSURED}`,
  },
  {
    id: CascoRoutePaths.CI_PRICE_OWNER_AND_INSURER_DETAILS,
    title: 'Owner & Insurer Details',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PRICE_OWNER_AND_INSURER_DETAILS}`,
  },
  {
    id: CascoRoutePaths.CI_PRICE_POLICY_DETAILS,
    title: 'Policy Details',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PRICE_POLICY_DETAILS}`,
  },
  {
    id: CascoRoutePaths.CI_PRICE_COVERS_AND_ADDONS,
    title: 'Covers & Add-ons',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PRICE_COVERS_AND_ADDONS}`,
  },
  {
    id: CascoRoutePaths.CI_PRICE_PREMIUM,
    title: 'Premium',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PRICE_PREMIUM}`,
  },
  {
    id: CascoRoutePaths.CI_PRICE_PROPOSAL,
    title: 'Proposal',
    routePath: `/dashboard/policies/${CascoRoutePaths.CI}/${CascoRoutePaths.CI_PRICE_PROPOSAL}`,
  },
];
