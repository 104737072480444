<form maiaForm [formGroup]="stepForm.formGroup">
  <maia-form-group>
    <maia-label>
      <label class="b2b-kdl-font-small title-dark-color">EGN / LNCH</label>
    </maia-label>
    <maia-form-element [options]="{validationInstant: true}" class="slidein-input-column">
      <input maiaInput name="idNumber" placeholder="EGN/LNCH" formControlName="idNumber" />
      <maia-validation
        *ngIf="stepForm.hasToShowErrors('idNumber') && stepForm.hasError('idNumber', 'required')"
      >
        EGN/LNCH required
      </maia-validation>
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('idNumber') && stepForm.hasError('idNumber', 'invalidNumber')
        "
      >
        Invalid EGN or LNCH number.
      </maia-validation>
    </maia-form-element>

    <maia-label>
      <label class="b2b-kdl-font-small title-dark-color">Full Name </label>
    </maia-label>
    <maia-form-element [options]="{validationInstant: true}" multi class="slidein-input-column">
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Първо име" formControlName="firstName" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Презиме" formControlName="middleName" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Фамилия" formControlName="lastName" />
      </maia-form-subelement>
      <maia-validation
        *ngIf="stepForm.hasToShowErrors('firstName') && stepForm.hasError('firstName', 'required')"
      >
        First name required
      </maia-validation>
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('middleName') && stepForm.hasError('middleName', 'required')
        "
      >
        Middle name required
      </maia-validation>
      <maia-validation
        *ngIf="stepForm.hasToShowErrors('lastName') && stepForm.hasError('lastName', 'required')"
      >
        Last name required
      </maia-validation>
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('firstName') && stepForm.hasError('firstName', 'nonLatinLetters')
        "
        >First name - only cyrillic letters allowed</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('middleName') &&
          stepForm.hasError('middleName', 'nonLatinLetters')
        "
        >Middle name - only cyrillic letters allowed</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('lastName') && stepForm.hasError('lastName', 'nonLatinLetters')
        "
        >Last name - only cyrillic letters allowed</maia-validation
      >
      <maia-validation
        *ngIf="stepForm.hasToShowErrors('firstName') && stepForm.hasError('firstName', 'nonBlank')"
        >Invalid first name</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('middleName') && stepForm.hasError('middleName', 'nonBlank')
        "
        >Invalid middle name</maia-validation
      >
      <maia-validation
        *ngIf="stepForm.hasToShowErrors('lastName') && stepForm.hasError('lastName', 'nonBlank')"
        >Invalid last name</maia-validation
      >
      <maia-validation
        *ngIf="stepForm.hasToShowErrors('firstName') && stepForm.hasError('firstName', 'maxlength')"
        >First name is too long (maximum is
        {{ stepForm.controls['firstName'].errors?.maxlength.requiredLength }}
        characters)</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('middleName') && stepForm.hasError('middleName', 'maxlength')
        "
        >Middle name is too long (maximum is
        {{ stepForm.controls['middleName'].errors?.maxlength.requiredLength }}
        characters)</maia-validation
      >
      <maia-validation
        *ngIf="stepForm.hasToShowErrors('lastName') && stepForm.hasError('lastName', 'maxlength')"
        >Last name is too long (maximum is
        {{ stepForm.controls['lastName'].errors?.maxlength.requiredLength }}
        characters)</maia-validation
      >
    </maia-form-element>

    <maia-label>
      <label class="b2b-kdl-font-small title-dark-color">Full name with latin letters </label>
    </maia-label>
    <maia-form-element [options]="{validationInstant: true}" multi class="slidein-input-column">
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="First Name" formControlName="firstNameEn" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Middle Name" formControlName="middleNameEn" />
      </maia-form-subelement>
      <maia-form-subelement>
        <input maiaInput type="text" placeholder="Last Name" formControlName="lastNameEn" />
      </maia-form-subelement>
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('firstNameEn') && stepForm.hasError('firstNameEn', 'required')
        "
      >
        First name in latin required
      </maia-validation>
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('middleNameEn') && stepForm.hasError('middleNameEn', 'required')
        "
      >
        Middle name in latin required
      </maia-validation>
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('lastNameEn') && stepForm.hasError('lastNameEn', 'required')
        "
      >
        Last name in latin required
      </maia-validation>
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('firstNameEn') &&
          stepForm.hasError('firstNameEn', 'nonCyrillicLetters')
        "
        >First name - only latin letters allowed</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('middleNameEn') &&
          stepForm.hasError('middleNameEn', 'nonCyrillicLetters')
        "
        >Middle name - only latin letters allowed</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('lastNameEn') &&
          stepForm.hasError('lastNameEn', 'nonCyrillicLetters')
        "
        >last name - only latin letters allowed</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('firstNameEn') && stepForm.hasError('firstNameEn', 'nonBlank')
        "
        >Invalid first name</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('middleNameEn') && stepForm.hasError('middleNameEn', 'nonBlank')
        "
        >Invalid middle name</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('lastNameEn') && stepForm.hasError('lastNameEn', 'nonBlank')
        "
        >Invalid last name</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('firstNameEn') && stepForm.hasError('firstNameEn', 'maxlength')
        "
        >First name is too long (maximum is
        {{ stepForm.controls['firstNameEn'].errors?.maxlength.requiredLength }}
        characters)</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('middleNameEn') && stepForm.hasError('middleNameEn', 'maxlength')
        "
        >Middle name is too long (maximum is
        {{ stepForm.controls['middleNameEn'].errors?.maxlength.requiredLength }}
        characters)</maia-validation
      >
      <maia-validation
        *ngIf="
          stepForm.hasToShowErrors('lastNameEn') && stepForm.hasError('lastNameEn', 'maxlength')
        "
        >Last name is too long (maximum is
        {{ stepForm.controls['lastNameEn'].errors?.maxlength.requiredLength }}
        characters)</maia-validation
      >
    </maia-form-element>

    <maia-label>
      <label class="b2b-kdl-font-small title-dark-color">Phone Number</label>
    </maia-label>
    <maia-form-element
      [hideOptionalIndicator]="true"
      [options]="{validationInstant: true}"
      class="slidein-input-column"
    >
      <maia-input-phonenumber
        name="phone"
        formControlName="phone"
        placeholder="0000000000"
        #phoneInput
        class="disable-country-code"
      >
        <maia-phonenumber-country prefix="+359" name="Bulgaria">
          <ng-template #flag>
            <svg b2bFrontendFlagUtilBulgaria></svg>
          </ng-template>
        </maia-phonenumber-country>
      </maia-input-phonenumber>
      <maia-validation
        *ngIf="stepForm.hasToShowErrors('phone') && stepForm.hasError('phone', 'required')"
      >
        Phone number required
      </maia-validation>
    </maia-form-element>
    <div *ngIf="isForeigner">
      <maia-label>
        <label class="b2b-kdl-font-small title-dark-color">Citizenship </label>
      </maia-label>
      <maia-form-element
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="slidein-input-column"
      >
        <maia-input-autocomplete
          name="citizenship"
          formControlName="citizenship"
          (typeAhead)="citizenshipAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="dzi-off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let c of citizenshipAutocomplete.list | async" [value]="c.id">
            <maia-option-highlight [label]="c.title"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>
        <maia-validation
          *ngIf="
            stepForm.hasToShowErrors('citizenship') && stepForm.hasError('citizenship', 'required')
          "
        >
          Citizenship required
        </maia-validation>
      </maia-form-element>

      <maia-label>
        <label class="b2b-kdl-font-small title-dark-color">Date of Birth </label>
      </maia-label>
      <maia-form-element
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="slidein-input-column"
      >
        <maia-input-date
          name="dob"
          [(ngModel)]="birthDatePicker.value"
          (ngModelChange)="birthDatePicker.change($event)"
          (blur)="birthDatePicker.touch()"
          [minimum]="minDate"
          [maximum]="maxDate"
          [visibleView]="visibleDateView"
          formControlName="dob"
        ></maia-input-date>
        <div class="validation-container" *ngIf="stepForm.hasToShowErrors('dob')">
          <maia-validation *ngIf="stepForm.hasError('dob', 'required')">
            Date of birth required
          </maia-validation>
          <maia-validation *ngIf="stepForm.hasError('dob', 'invalid')">
            Date of birth invalid
          </maia-validation>
          <maia-validation *ngIf="stepForm.hasError('dob', 'atlasBefore')">
            Birth date should not be future date.
          </maia-validation>
          <maia-validation *ngIf="stepForm.hasError('dob', 'atlasAfter')">
            Birth date should not be less than 150 years.
          </maia-validation>
        </div>
      </maia-form-element>
    </div>

    <maia-divider class="m-t-b-24"></maia-divider>
  </maia-form-group>
</form>

<div class="step-form-button-bar">
  <maia-button-group *ngIf="isNewPerson">
    <button class="m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveMtplData()"
      >Add</button
    >
    <button class="m-t-b-24" maiaButton maiaButtonType="secondary" (click)="cancelSlideIn()"
      >Close</button
    >
  </maia-button-group>

  <maia-button-group *ngIf="!isNewPerson">
    <button class="m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveMtplData()"
      >Save</button
    >
    <button class="m-t-b-24" maiaButton maiaButtonType="secondary" (click)="cancelSlideIn()"
      >Close</button
    >
  </maia-button-group>
</div>
