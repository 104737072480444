<form maiaForm [formGroup]="instalmentsForm" id="instalments-page">
  <div class="read-more-slidein">
    <button maiaDocumentAction (click)="openSlideIn()" class="font-s">Read More</button>
  </div>
  <maia-radio-group name="policyPaymentModeGroup">
    <maia-label class="b2b-kdl-font-small label-title"> Instalment Options </maia-label>
    <div class="instalment-tile">
      <maia-tile
        *ngFor="let policyInstalmentMode of policyInstalmentModes"
        class="m-t-b-24 tile-box"
        [ngClass]="{
          'addons-tile-selected': policyInstalmentMode.selected
        }"
      >
        <maia-radio-button
          [value]="policyInstalmentMode.id"
          (click)="updateSelectedPaymentMode(policyInstalmentMode.id)"
          [active]="policyInstalmentMode.selected"
        >
          <div class="payment-box">
            <label class="b2b-kdl-font-small radio-tile">{{
              policyInstalmentMode.paymentMode
            }}</label>
            <maia-amount-display
              class="b2b-kdl-font-small-bold radio-tile"
              [value]="getAmoutValue(policyInstalmentMode.amount)"
              size="medium-bold"
              currency="{{ policyInstalmentMode.currency }}"
              [decimals]="2"
            ></maia-amount-display>
            <label class="b2b-kdl-font-small-light instalment-label">{{
              policyInstalmentMode.instalmentText
            }}</label>
            <maia-amount-display
              [value]="getAmoutValue(policyInstalmentMode.firstInstalmentAmount)"
              size="extra-small"
              currency="{{ policyInstalmentMode.currency }}"
              [decimals]="2"
            ></maia-amount-display>
          </div>
        </maia-radio-button>
      </maia-tile>
    </div>
  </maia-radio-group>
  <maia-divider></maia-divider>
  <div class="flex-div">
    <maia-contextual-message id="contextual-message-5" class="banner-style">
      <div *ngIf="!displayDiscounts">
        Automatic loading and discounts are applied.
        <button maiaButton maiaButtonType="tertiary" (click)="toogleTag()">Click here</button>
        to view more.
      </div>
      <div *ngIf="displayDiscounts">
        <h4>Automatic loadings & discounts applied. </h4>
        <h4 class="b2b-kdl-font-small-bold">Discounts</h4>
        <ul class="list-no-bulletpoints">
          <li
            ><svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>Discounts for loyal DZI
            client <span class="b2b-kdl-font-small-bold">{{ discount1 }}</span></li
          >
          <li
            ><svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>Main activity for owner's
            company <span class="b2b-kdl-font-small-bold">{{ discount2 }}</span></li
          >
          <li
            ><svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>Discounts for riskless
            client profile <span class="b2b-kdl-font-small-bold">{{ discount3 }}</span></li
          >
          <li
            ><svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>Discounts for driven km
            <span class="b2b-kdl-font-small-bold">{{ discount2 }}</span></li
          >
        </ul>
        <h4 class="b2b-kdl-font-small-bold">Loadings</h4>
        <ul class="list-no-bulletpoints">
          <li
            ><svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>Loading for make/ model
            of vehicle <span class="b2b-kdl-font-small-bold">{{ val1 }}</span></li
          >
          <li
            ><svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>Loading fot taxi
            <span class="b2b-kdl-font-small-bold">{{ val2 }}</span></li
          >
          <li
            ><svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>Loading for motor vehicle
            leased under operating lease <span class="b2b-kdl-font-small-bold">{{ val3 }}</span></li
          >
          <li
            ><svg b2bFrontendAlertFuncCheck class="alert-func-check"></svg>Loading for rental
            <span class="b2b-kdl-font-small-bold">{{ val3 }}</span></li
          >
        </ul>
        <button
          maiaButton
          maiaButtonType="tertiary"
          class="b2b-kdl-font-extra-small-light"
          (click)="toogleTag()"
          >View Less</button
        >
      </div>
    </maia-contextual-message>
  </div>
  <maia-divider></maia-divider>
  <maia-label class="b2b-kdl-font-small label-title"> Discounts </maia-label>
  <div class="flex-div">
    <maia-contextual-message
      id="contextual-message-4"
      type="warning"
      class="banner-style"
      *ngIf="displayDiscountsWarning"
      >The selected discount cannot be more than 15%. Maximum discount of 15% will be applied during
      premium calculation.</maia-contextual-message
    >
  </div>
  <br />
  <maia-checkbox-group class="b2b-kdl-font-small-bold">
    <maia-checkbox
      id="checkbox-disable"
      (onValueChange)="onDeclareDiscounts($event)"
      formControlName="declareDiscounts"
      [(ngModel)]="disabledcheckbox"
      (ngModelChange)="stylingdisabledCheck()"
    >
      I declare that i will purchase Casco policy for same vehicle in next 6 months
    </maia-checkbox>
  </maia-checkbox-group>
  <div>
    <maia-tile
      class="check-tile"
      [ngClass]="[disabledCheckStyle]"
      [disabled]="enableCascoInsurance"
    >
      <maia-tile-items>
        <maia-checkbox formControlName="cascoInsurance">
          <maia-tile-item class="b2b-kdl-font-small">
            Casco selling CASCO Insurance
          </maia-tile-item>
          <maia-tile-item><label class="b2b-kdl-font-small-light">discount</label> </maia-tile-item>
        </maia-checkbox>
      </maia-tile-items>
    </maia-tile>
    <maia-tile class="check-tile" [ngClass]="[checkStyle]">
      <maia-tile-items>
        <maia-checkbox
          (onValueChange)="onInputPercentageCheck($event)"
          formControlName="discountPercentageCheck"
          [(ngModel)]="checkboxx"
          (ngModelChange)="stylingCheck()"
        >
          <maia-tile-item class="b2b-kdl-font-small"> For a number of vehicles </maia-tile-item>
          <maia-tile-item
            ><maia-label class="b2b-kdl-font-small-light">From 0 to 5%</maia-label>
          </maia-tile-item>
        </maia-checkbox>
      </maia-tile-items>
    </maia-tile>
  </div>
  <div id="hiddencontent">
    <div class="discount-percent">
      <maia-tile class="hide-tile" *ngIf="displayDiscountsWarning">
        <maia-tile-items>
          <maia-tile-item class="b2b-kdl-font-small"> Discount </maia-tile-item>
          <maia-tile-item
            ><maia-label class="b2b-kdl-font-small-light">{{ discountPercentageVal }}%</maia-label>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
    </div>
    <div class="discount-percent">
      <maia-tile class="hide-tile" *ngIf="displayInputPercentage">
        <maia-tile-items>
          <maia-tile-item>
            <maia-input-group>
              <input
                maiaInputNumber
                class="input-discount"
                #inputbox
                (keyup)="updateDiscountPercentage($event)"
                formControlName="discountPercentage"
              />
              <maia-input-group-suffix>%</maia-input-group-suffix>
            </maia-input-group>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
    </div>
  </div>
  <maia-divider style="clear: left"></maia-divider>
  <maia-button-group class="install-button">
    <button
      maiaButton
      maiaButtonType="primary"
      (click)="onContinue()"
      [disabled]="disableContinueButton"
      >Continue</button
    >
    <button maiaButton maiaButtonType="secondary" (click)="onCalculatePremium()"
      >Calculate Premium</button
    >
  </maia-button-group>
</form>
