<form maiaForm [formGroup]="newsAndEventsPinForm" ngDefaultControl>
  <maia-form-group>
    <maia-form-element>
      <maia-label
        class="b2b-kdl-font-medium title-dark-color"
        i18n="@@nje-b2b-frontend-resource-articles-pin-startdate"
        >Start Date</maia-label
      >
      <maia-input-date
        name="startDate"
        [minimum]="minDate"
        [maximum]="maxDate"
        placeholder="DD-MM-YYYY"
        i18n-placeholder="@@nje-b2b-frontend-resource-articles-pin-date"
        formControlName="pinStartDate"
      >
      </maia-input-date>
      <div class="validation-container">
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-articles-pin-startdatereq"
          type="error"
          *ngIf="
            newsAndEventsPinForm.controls['pinStartDate'].errors &&
            newsAndEventsPinForm.controls['pinStartDate'].errors.required
          "
          >Start Date is required</maia-validation
        >
      </div>
    </maia-form-element>
    <maia-form-element>
      <maia-label
        class="b2b-kdl-font-medium title-dark-color"
        i18n="@@nje-b2b-frontend-resource-articles-pin-enddate"
        >End Date</maia-label
      >
      <maia-input-date
        name="endDate"
        [minimum]="newsAndEventsPinForm.controls['pinStartDate'].value"
        [maximum]="maxDate"
        placeholder="DD-MM-YYYY"
        i18n-placeholder="@@nje-b2b-frontend-resource-articles-pin-date"
        formControlName="pinEndDate"
      >
      </maia-input-date>
      <div class="validation-container">
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-articles-pin-enddatereq"
          type="error"
          *ngIf="
            newsAndEventsPinForm.controls['pinEndDate'].errors &&
            newsAndEventsPinForm.controls['pinEndDate'].errors.required
          "
        >
          End Date is required
        </maia-validation>
      </div>
    </maia-form-element>
  </maia-form-group>
</form>
<div class="step-form-button-bar">
  <maia-button-group *ngIf="!isUpdatePin">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!newsAndEventsPinForm.valid"
      (click)="update()"
      i18n="@@nje-b2b-frontend-resource-articles-pin"
      >Pin</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-product-cancel"
      >Cancel</button
    >
  </maia-button-group>
  <maia-button-group *ngIf="isUpdatePin">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!canUpdate()"
      (click)="update()"
      i18n="@@nje-b2b-frontend-resource-admin-update"
      >Update</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="unpin()"
      i18n="@@nje-b2b-frontend-resource-articles-pin-unpin"
      >Unpin</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-product-cancel"
      >Cancel</button
    >
  </maia-button-group>
</div>
