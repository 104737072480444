export const API_END_POINT = {
  TEST_API: 'type-of-travel',
  CITY_API_URL: 'mtpl/cities',
  CONTACT_API: 'cms/contact',
  LIBRARY_API: 'cms/library',
  PORTFOLIO_API: 'cms/library/:id/portfolio',
  ARTICLE_API: 'cms/article',
  PRODUCT_API: 'cms/library/:id/portfolio/:id/product',
  PRODUCT_CATEGORY: 'cms/product-category',
  ARTICLE_CATEGORY: 'cms/article-category',
  USER_TYPE: 'cms/user-type',
  UAM_B2C_DEACTIVTAED_USERS: 'uam/b2c-users',
  SEARCH_B2C_UAM_DEACTIVTAED_USERS: 'uam/b2c-users/search',
  ACTIVATE_B2C_USER: 'uam/b2c-users/:id/activate',
  ACTIVATE_B2C_ALL_USERS: 'uam/b2c-users/activate',
  UAM_B2C_ACTIVTAED_USERS: 'uam/b2c-users',
  SEARCH_B2C_UAM_ACTIVTAED_USERS: 'uam/b2c-users/search',
  DEACTIVATE_B2C_USER: 'uam/b2c-users/:id/deactivate',
  RESET_B2C_USER_PASSWORD: 'uam/b2c-users/:id/resetpassword',
  MEDIA: 'cms/media',
  COVERS_LIMITS: 'insurance/nomenclatures/underwriting-limits/products',
  IV_BASE_OPTIONS: 'insurance/nomenclatures/products',
  CITY_ZIP: 'insurance/nomenclatures/city-zipcodes',
  CITYZIP: 'insurance/nomenclatures/cities',
  DOCUMENT: 'cms/document',
  POLICY_DETAILS_API: 'insurance/nomenclatures/policy-lengths',
  VEHCILE_PURPOSES: 'insurance/nomenclatures/vehicle-purposes',
  OFFICES: 'insurance/nomenclatures/offices',
  STARTDATE: 'insurance/mtpl/vehicles/vehicle/active-policy',
  TARIFF_RATES: 'insurance/nomenclatures/tariffrates',
  VEHICLE_DETAILS: 'insurance/mtpl/vehicles/vehicle',
  PEOPLE_API_URL: 'insurance/people/person',
  MTPL_COMPANY_API_URL: 'apis/company',
  RELATED_PARTIES_URL: 'insurance/nomenclatures/related-party-roles',
  COVERS_ON_ADDRESS_LEVEL: 'insurance/property/address-covers',
  PEOPLE_DATA: 'insurance/people/person',
  COMPANY_DATA: 'apis/company',
  PROPERTY_TYPE: 'insurance/property/property-types',
  INSTALLMENT_OPTIONS: 'insurance/nomenclatures/installment-options',
  VEHICLE_TYPES: 'insurance/nomenclatures/car-types',
  VEHILCE_REGISTRATION_TYPES: 'insurance/nomenclatures/registration-types',
  VEHICLE_COLORS: 'insurance/nomenclatures/vehicle-colors',
  VEHICLE_FUEL_TYPE: 'insurance/nomenclatures/fuel-types',
  UAM_B2B_DEACTIVTAED_USERS: 'uam/b2b/users',
  SEARCH_B2B_UAM_DEACTIVTAED_USERS: 'uam/b2b/users/search',
  SEARCH_B2B_UAM_DEACTIVTAED_USERS_BY_ORG: 'uam/b2b/users/search-by-org',
  ACTIVATE_B2B_USER: 'uam/b2b/users/activate',
  UAM_B2B_ACTIVTAED_USERS: 'uam/b2b/users',
  SEARCH_B2B_UAM_ACTIVTAED_USERS: 'uam/b2b/users/search',
  SEARCH_B2B_UAM_ACTIVTAED_USERS_BY_ORG: 'uam/b2b/users/search-by-org',
  DEACTIVATE_B2B_USER: 'uam/b2b/users/deactivate',
  FEATURE_LIST: 'insurance/property/property-features',
  SUSPEND_B2B_USER: 'uam/b2b/users/suspend',
  INHABITANCY_TYPES: 'insurance/property/building-inhabitancy-types',
  FIRE_PROTECTION_TYPES: 'insurance/property/fire-safety-protection-types',
  SECURITY_MEASURES_TYPES: 'insurance/property/security-measure-types',
  CURRENCY_LIST: 'insurance/nomenclatures/currencies',
  BANK_LIST: 'insurance/nomenclatures/banks',
  RESET_B2B_USER_PASSWORD: 'uam/b2b/users/:userId/resetPassword',
  CURRENCY_RATE_CONVERSION: 'insurance/common/currency-rates',
  CITIZENSHIP_API: 'insurance/people/citizenship',
};
