import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {StepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {Observable} from 'rxjs';

import {PropertyWorkflow} from './property-core/property-workflow/property-workflow';
import {PropertyPolicyService} from './property-core/services/property-policy.service';
import {PropertyProcessService} from './property-core/services/property-process.service';

@Component({
  templateUrl: './property-insurance.component.html',
  styles: [':host { display: block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyInsuranceComponent implements OnInit {
  public lang: string | null;
  public steps$: Observable<StepperProcessStep[]> = this.process.onStepperWorkflowSteps;
  public isEditing: Observable<boolean> = this.process.stepEditing;

  public constructor(
    public process: PropertyProcessService,
    public property: PropertyPolicyService,
  ) {
    //  this.lang = sessionStorage.getItem('lang');
    this.process.currentWorkflow = PropertyWorkflow.PropertyHomePolicy;
  }

  public ngOnInit(): void {
    this.lang = sessionStorage.getItem('lang');
  }
}
