<header-with-breadcrumbs title="Home"></header-with-breadcrumbs>
<div class="page-align">
  <h3 class="header-style">Policies</h3>
  <maia-tab-nav id="tab-nav2">
    <maia-tab title="New policy" routerLink="./newpolicy" active replaceUrl></maia-tab>
    <maia-tab title="Renewal" routerLink="./renewal" replaceUrl></maia-tab>
    <maia-tab title="Annexation" routerLink="./annexation" replaceUrl></maia-tab>
    <maia-tab title="Cancellation" routerLink="./cancellation" replaceUrl></maia-tab>
    <maia-tab title="Duplicates" routerLink="./duplicates" replaceUrl></maia-tab>
  </maia-tab-nav>
  <maia-tab-container>
    <router-outlet></router-outlet>
  </maia-tab-container>
</div>
