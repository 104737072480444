import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionExprDoorInSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendActionExprDoorIn]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M27.09 30.84v3.32h-9.72"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5 5.88l12.37 7.96v28.28L5 34.16V5.88h22.09v5.27"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M43 20.91H23.56"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M30.63 27.98l-7.07-7.07 7.07-7.07"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionExprDoorInSvgComponent {
}

@NgModule({
  declarations: [
    ActionExprDoorInSvgComponent,
  ],
  exports: [
    ActionExprDoorInSvgComponent,
  ],
})
export class ActionExprDoorInSvgModule {}
