<njf-loader [hidden]="!showLoadingIcon"></njf-loader>
<ng-container *ngIf="articleFrontPage">
  <form [formGroup]="newsEventsForm">
    <div class="add-news-events">
      <maia-form-element [hideOptionalIndicator]="true" class="news-event input-column">
        <maia-input-select
          name="newsEvents"
          formControlName="newsEvents"
          (ngModelChange)="onArticleChange()"
        >
          <input
            autocomplete="off"
            maiaInput
            i18n-placeholder="@@nje-b2b-frontend-resource-library-product-select"
            placeholder="Select"
            [value]="newsEventsForm.controls['newsEvents'].value"
          />
          <maia-option
            id="news"
            class="option_one"
            [value]="newsValue"
            i18n="@@nje-b2b-frontend-resource-articles-news"
            >News</maia-option
          >
          <maia-option
            id="events"
            class="option_one"
            [value]="eventsValue"
            i18n="@@nje-b2b-frontend-resource-articles-events"
            >Events</maia-option
          >
        </maia-input-select>
      </maia-form-element>

      <div class="add-news-events-button">
        <button
          maiaButton
          maiaButtonType="primary"
          type="button"
          (click)="addEditNewsEvents()"
          i18n="@@nje-b2b-frontend-resource-library-product-add"
          >Add
        </button>
      </div>
    </div>
  </form>
  <maia-tab-nav>
    <maia-tab
      title="Published"
      i18n-title="@@nje-b2b-frontend-resource-admin-publishedtitle"
      [active]="isPublishedTabActive"
      (click)="publishedTabActive()"
    >
    </maia-tab>
    <maia-tab
      title="Draft"
      i18n-title="@@nje-b2b-frontend-resource-admin-drafttitle"
      [active]="isDraftTabActive"
      (click)="draftTabActive()"
    ></maia-tab>
    <maia-tab
      title="Scheduled"
      i18n-title="@@nje-b2b-frontend-resource-admin-scheduledtitle"
      [active]="isScheduledTabActive"
      (click)="scheduledTabActive()"
    ></maia-tab>
  </maia-tab-nav>
  <maia-tab-container>
    <ng-container *ngIf="isPublishedTabActive">
      <form [formGroup]="publishedNewsEventsForm">
        <div class="search-row">
          <maia-search-bar
            class="search-box"
            placeholder="Search"
            (searchText)="handleSearchedText($event)"
            (inputText)="handleInputText($event)"
            [(ngModel)]="searchNews"
            ngDefaultControl
            [ngModelOptions]="{standalone: true}"
            i18n-placeholder="@@nje-b2b-frontend-resource-admin-search"
          >
          </maia-search-bar>
          <maia-input-select
            class="search-dropdown"
            name="simple"
            [(ngModel)]="value"
            formControlName="publishedSorting"
          >
            <svg class="sort-icon" b2bFrontendActionFuncSort></svg>
            <input
              maiaInput
              placeholder="Select value"
              i18n-placeholder="@@nje-b2b-frontend-resource-articles-selectvalue"
              [value]="value || null"
            />
            <maia-option
              id="optionOne0"
              class="option_one"
              [value]="newtoOldValue"
              (click)="publishednew(1)"
              i18n="@@nje-b2b-frontend-resource-articles-newold"
              selected
              >New to old
            </maia-option>
            <maia-option
              id="optionOne1"
              class="option_one"
              [value]="oldtoNewValue"
              (click)="publishedold(1)"
              i18n="@@nje-b2b-frontend-resource-articles-oldnew"
              >Old to new
            </maia-option>
          </maia-input-select>
          <div (click)="filterNewsAndEvents(publishedList)" class="filter-icon-wrapper">
            <svg class="filter-icon-svg" b2bFrontendActionFuncFilterCircle></svg>
          </div>
        </div>
        <div>
          <maia-tag-container (closeAll)="closeAllList()">
            <maia-tag
              *ngFor="let tag of regularTagList"
              [displayedValue]="tag.displayedValue"
              (close)="tag.close()"
            >
            </maia-tag>
          </maia-tag-container>
        </div>

        <div class="tile-grid">
          <maia-tile
            *ngFor="let news_event of publishedList | SearchPipe: searchNews; let i = index"
            class="grid-tile"
          >
            <div class="profile-icon">
              <maia-avatar-image *ngIf="news_event.profileImageValue !== undefined">
                <img [src]="news_event.profileImageValue" alt="image" class="profile-img-pos" />
              </maia-avatar-image>
              <maia-avatar-svg
                *ngIf="news_event.profileImageValue === undefined"
                class="avatar-background"
                maia-avatar-display-icon
              >
                <svg class="icon-size-color" b2bFrontendCommunicationFuncImage></svg>
              </maia-avatar-svg>
            </div>
            <maia-tile-items class="tile-items">
              <maia-tile-item class="maia-tile-item">
                <div class="title-sub-title-div">
                  <div>
                    <label
                      i18n="@@nje-b2b-frontend-resource-articles-published"
                      *ngIf="news_event.id != selectedIndex && pinned == false"
                      class="extra-small"
                      >{{ news_event.publishStartDate | date: 'dd/MM/yy' }} | Published</label
                    >
                    <label
                      i18n="@@nje-b2b-frontend-resource-articles-pinned"
                      *ngIf="news_event.id == selectedIndex && pinned == false"
                      class="pinned-news"
                      >{{ news_event.eventStartDate }} | Published | Pinned</label
                    >
                  </div>
                  <div
                    ><label class="contacts_ref title-wrapper">{{
                      news_event.i18n?.en ? news_event.i18n.en?.title : news_event.i18n.bg?.title
                    }}</label>
                  </div>
                </div>
                <div class="news-events-published">
                  <maia-switch
                    name="switchForm"
                    (click)="changeSwitch(news_event)"
                    formControlName="disableArticle"
                    [disabled]="!news_event.disableArticle && news_event.id === disabledArticleId"
                  ></maia-switch>
                  <span
                    *ngIf="news_event.disableArticle"
                    class="switch-enabled"
                    i18n="@@nje-b2b-frontend-resource-articles-enabled"
                    >Enabled</span
                  >
                  <span
                    *ngIf="!news_event.disableArticle && news_event.id === disabledArticleId"
                    class="switch-enabled"
                    i18n="@@nje-b2b-frontend-resource-articles-disabled"
                    >Disabled</span
                  >
                </div>
              </maia-tile-item>
              <maia-divider class="m-0"></maia-divider>
              <maia-tile-item>
                <p *ngIf="!news_event.pinned">
                  <button
                    maiaButton
                    maiaButtonType="primary"
                    class="button-pin"
                    (click)="pinNewsAndEvents(news_event, news_event.id, false)"
                    i18n="@@nje-b2b-frontend-resource-articles-pin-pinarticle"
                    [disabled]="!news_event.disableArticle && news_event.id === disabledArticleId"
                    >Pin
                  </button>
                  <button
                    maiaButton
                    maiaButtonType="secondary"
                    (click)="addEditNewsEvents(news_event)"
                    i18n="@@nje-b2b-frontend-resource-contacts-edit"
                    [disabled]="!news_event.disableArticle && news_event.id === disabledArticleId"
                    >Edit</button
                  >
                </p>

                <p *ngIf="news_event.pinned">
                  <button
                    maiaButton
                    maiaButtonType="primary"
                    class="button-pin"
                    (click)="pinNewsAndEvents(news_event, i, true)"
                    i18n="@@nje-b2b-frontend-resource-articles-updatepin"
                    [disabled]="!news_event.disableArticle && news_event.id === disabledArticleId"
                    >Update pin
                  </button>
                  <button
                    maiaButton
                    maiaButtonType="secondary"
                    (click)="addEditNewsEvents(news_event)"
                    i18n="@@nje-b2b-frontend-resource-contacts-edit"
                    [disabled]="!news_event.disableArticle && news_event.id === disabledArticleId"
                    >Edit</button
                  >
                </p>
              </maia-tile-item>
            </maia-tile-items>
          </maia-tile>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="isDraftTabActive">
      <form [formGroup]="draftNewsEventsForm">
        <div class="search-row">
          <maia-search-bar
            class="search-box"
            placeholder="Search"
            i18n-placeholder="@@nje-b2b-frontend-resource-admin-search"
            (searchText)="handleSearchedText($event)"
            (inputText)="handleInputText($event)"
            [(ngModel)]="searchNews"
            ngDefaultControl
            [ngModelOptions]="{standalone: true}"
          >
          </maia-search-bar>
          <maia-input-select
            class="search-dropdown"
            name="simple"
            [(ngModel)]="value"
            formControlName="draftSorting"
          >
            <svg class="sort-icon" b2bFrontendActionFuncSort></svg>
            <input
              maiaInput
              placeholder="Select value"
              i18n-placeholder="@@nje-b2b-frontend-resource-articles-selectvalue"
              [value]="value || null"
            />
            <maia-option
              id="optionOne0"
              class="option_one"
              [value]="newtoOldValue"
              (click)="publishednew(2)"
              i18n="@@nje-b2b-frontend-resource-articles-newold"
              >New to old
            </maia-option>
            <maia-option
              id="optionOne1"
              class="option_one"
              [value]="oldtoNewValue"
              (click)="publishedold(2)"
              i18n="@@nje-b2b-frontend-resource-articles-oldnew"
              >Old to new
            </maia-option>
          </maia-input-select>
          <div (click)="filterNewsAndEvents(draftList)" class="filter-icon-wrapper">
            <svg class="filter-icon-svg" b2bFrontendActionFuncFilterCircle></svg>
          </div>
        </div>
        <div>
          <maia-tag-container (closeAll)="closeAllList()">
            <maia-tag
              *ngFor="let tag of regularTagList"
              [displayedValue]="tag.displayedValue"
              (close)="tag.close()"
            >
            </maia-tag>
          </maia-tag-container>
        </div>
        <maia-tile
          class="m-t-b-24 tile_size"
          *ngFor="let news_event of draftList | SearchPipe: searchNews; let i = index"
        >
          <div class="profile-icon">
            <maia-avatar-image *ngIf="news_event.profileImageValue !== undefined">
              <img [src]="news_event.profileImageValue" alt="image" class="profile-img-pos" />
            </maia-avatar-image>
            <maia-avatar-svg
              *ngIf="news_event.profileImageValue === undefined"
              class="avatar-background"
              maia-avatar-display-icon
            >
              <svg class="icon-size-color" b2bFrontendCommunicationFuncImage></svg>
            </maia-avatar-svg>
          </div>
          <maia-tile-items>
            <maia-tile-item>
              <div class="news-events-tile">
                <div>
                  <div
                    ><label class="contacts_name" i18n="@@nje-b2b-frontend-resource-articles-draft"
                      >{{ news_event.createdAt | date: 'dd/MM/yy' }} | Draft</label
                    >
                  </div>
                  <div
                    ><label class="contacts_ref title-wrapper">{{
                      news_event.i18n?.en ? news_event.i18n.en?.title : news_event.i18n.bg?.title
                    }}</label></div
                  >
                </div>
              </div>
            </maia-tile-item>
            <maia-divider></maia-divider>
            <maia-tile-item>
              <p>
                <button
                  maiaButton
                  maiaButtonType="delete"
                  class="button-remove"
                  (click)="deleteTile(news_event.id)"
                  i18n="@@nje-b2b-frontend-resource-admin-remove"
                  >Remove
                </button>
                <button
                  maiaButton
                  maiaButtonType="secondary"
                  (click)="addEditNewsEvents(news_event)"
                  i18n="@@nje-b2b-frontend-resource-contacts-edit"
                  >Edit</button
                >
              </p>
            </maia-tile-item>
          </maia-tile-items>
        </maia-tile>
      </form>
    </ng-container>
    <ng-container *ngIf="isScheduledTabActive">
      <form [formGroup]="scheduledNewsEventsForm">
        <div class="search-row">
          <maia-search-bar
            class="search-box"
            placeholder="Search"
            i18n-placeholder="@@nje-b2b-frontend-resource-admin-search"
            (searchText)="handleSearchedText($event)"
            (inputText)="handleInputText($event)"
            [(ngModel)]="searchNews"
            ngDefaultControl
            [ngModelOptions]="{standalone: true}"
          >
          </maia-search-bar>
          <maia-input-select
            class="search-dropdown"
            name="simple"
            [(ngModel)]="value"
            slideInTitle="Select simple value"
            formControlName="scheduledSorting"
          >
            <svg class="sort-icon" b2bFrontendActionFuncSort></svg>
            <input
              maiaInput
              placeholder="Select value"
              i18n-placeholder="@@nje-b2b-frontend-resource-articles-selectvalue"
              [value]="value || null"
            />
            <maia-option
              id="optionOne0"
              class="option_one"
              [value]="newtoOldValue"
              (click)="publishednew(3)"
              i18n="@@nje-b2b-frontend-resource-articles-newold"
              >New to old
            </maia-option>
            <maia-option
              id="optionOne1"
              class="option_one"
              [value]="oldtoNewValue"
              (click)="publishedold(3)"
              i18n="@@nje-b2b-frontend-resource-articles-oldnew"
              >Old to new
            </maia-option>
          </maia-input-select>
          <div class="filter-icon-wrapper" (click)="filterNewsAndEvents(scheduledList)">
            <svg class="filter-icon-svg" b2bFrontendActionFuncFilterCircle></svg>
          </div>
        </div>
        <div>
          <maia-tag-container (closeAll)="closeAllList()">
            <maia-tag
              *ngFor="let tag of regularTagList"
              [displayedValue]="tag.displayedValue"
              (close)="tag.close()"
            >
            </maia-tag>
          </maia-tag-container>
        </div>
        <maia-tile
          class="m-t-b-24 tile_size"
          *ngFor="let news_event of scheduledList | SearchPipe: searchNews; let i = index"
        >
          <div class="profile-icon">
            <maia-avatar-image *ngIf="news_event.profileImageValue !== undefined">
              <img [src]="news_event.profileImageValue" alt="image" class="profile-img-pos" />
            </maia-avatar-image>
            <maia-avatar-svg
              *ngIf="news_event.profileImageValue === undefined"
              class="avatar-background"
              maia-avatar-display-icon
            >
              <svg class="icon-size-color" b2bFrontendCommunicationFuncImage></svg>
            </maia-avatar-svg>
          </div>
          <maia-tile-items>
            <maia-tile-item>
              <div class="news-events-tile">
                <div>
                  <div
                    ><label
                      class="contacts_name"
                      i18n="@@nje-b2b-frontend-resource-articles-scheduled"
                      >{{ news_event.publishStartDate | date: 'dd/MM/yy, HH:mm' }}| Scheduled</label
                    >
                  </div>
                  <div
                    ><label class="contacts_ref title-wrapper">{{
                      news_event.i18n?.en ? news_event.i18n.en?.title : news_event.i18n.bg?.title
                    }}</label></div
                  >
                </div>
              </div>
            </maia-tile-item>
            <maia-divider></maia-divider>
            <maia-tile-item>
              <p>
                <button
                  maiaButton
                  maiaButtonType="delete"
                  class="button-remove"
                  (click)="deleteTile(news_event.id)"
                  i18n="@@nje-b2b-frontend-resource-admin-remove"
                  >Remove
                </button>
                <button
                  maiaButton
                  maiaButtonType="secondary"
                  (click)="addEditNewsEvents(news_event)"
                  i18n="@@nje-b2b-frontend-resource-contacts-edit"
                  >Edit</button
                >
              </p>
            </maia-tile-item>
          </maia-tile-items>
        </maia-tile>
      </form>
    </ng-container>
  </maia-tab-container>
</ng-container>

<form [formGroup]="addEditArticleForm.formGroup" *ngIf="addEditArticle" class="add-edit-form-style">
  <maia-fluid-fluid-layout>
    <maia-form-element class="input-column category">
      <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-resource-articles-category"
        >
          Category
        </label>
      </maia-label>
      <maia-input-select name="articleCategory" formControlName="articleCategory">
        <input
          maiaInput
          placeholder="Select"
          i18n-placeholder="@@nje-b2b-frontend-resource-library-product-titleselect"
          [value]="getCategoryListValue(addEditArticleForm.controls['articleCategory'].value)"
        />
        <maia-option *ngFor="let category of categoryList" [value]="category.id">
          <span *ngIf="ifEnglishLang()"> {{ category?.i18n?.en?.title }}</span>
          <span *ngIf="!ifEnglishLang()">{{ category?.i18n?.bg?.title }}</span>
        </maia-option>
        <maia-option [value]="addEditArticleForm.controls['articleCategory'].value">
          <button maiaActionWithIcon (click)="filterNewsAndEvents(completeArticleData, editFilter)">
            <span i18n="@@nje-b2b-frontend-resource-article-addedit">Add or edit categories</span>
          </button>
        </maia-option>
      </maia-input-select>
      <maia-validation
        i18n="@@nje-b2b-frontend-resource-article-categoryselect"
        *ngIf="
          addEditArticleForm.controls['articleCategory'].touched &&
          addEditArticleForm.controls['articleCategory'].hasError('required')
        "
        >Please select a category</maia-validation
      >
    </maia-form-element>
    <maia-form-element class="input-column" hideOptionalIndicator>
      <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-resource-article-usertype"
        >
          User type
        </label>
      </maia-label>
      <maia-fake-input>
        <input
          class="box-size"
          maiaInput
          name="type"
          [(ngModel)]="selectedUsers"
          placeholder="Select user type"
          i18n-placeholder="@@nje-b2b-frontend-resource-article-select-user-type"
          formControlName="userTypes"
        />
        <button
          maiaButton
          *ngIf="!selectedUsers"
          class="selectBtn"
          maiaButtonType="tertiary"
          (click)="selectUsertype()"
          >{{ 'Select' | localeVal: lang }}</button
        >
        <button
          maiaButton
          *ngIf="selectedUsers"
          class="selectBtn"
          maiaButtonType="tertiary"
          (click)="selectUsertype()"
          >{{ 'Edit' | localeVal: lang }}</button
        >
      </maia-fake-input>

      <maia-validation
        i18n="@@nje-b2b-frontend-resource-article-usertypereq"
        *ngIf="
          addEditArticleForm.controls['userTypes'].touched &&
          addEditArticleForm.controls['userTypes'].hasError('required')
        "
        >Please select a usertype</maia-validation
      >
    </maia-form-element>
  </maia-fluid-fluid-layout>
  <maia-divider></maia-divider>
  <div *ngIf="ifEventSelected()">
    <maia-fluid-fluid-layout>
      <maia-form-element class="input-column category">
        <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
          <label
            class="b2b-kdl-font-small title-dark-color"
            i18n="@@nje-b2b-frontend-resource-article-location"
            >Location</label
          >
        </maia-label>
        <input
          name="location"
          maiaInput
          type="text"
          placeholder="Enter location"
          i18n-placeholder="@@nje-b2b-frontend-resource-article-enterlocation"
          required
          formControlName="location"
        />
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-locationreq"
          *ngIf="
            addEditArticleForm.controls['location'].touched &&
            addEditArticleForm.controls['location'].hasError('required')
          "
          >Please enter the location</maia-validation
        >
        <maia-validation *ngIf="addEditArticleForm.hasError('location', 'pattern')"
          >Location is invalid
        </maia-validation>
      </maia-form-element>
      <maia-form-element class="input-column category">
        <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
          <label
            class="b2b-kdl-font-small title-dark-color"
            i18n="@@nje-b2b-frontend-resource-article-organiser"
            >Organiser</label
          >
        </maia-label>
        <input
          name="organizer"
          maiaInput
          type="text"
          placeholder="Enter organiser"
          i18n-placeholder="@@nje-b2b-frontend-resource-article-enterorganiser"
          required
          formControlName="organizer"
        />
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-organiserreq"
          *ngIf="
            addEditArticleForm.controls['organizer'].touched &&
            addEditArticleForm.controls['organizer'].hasError('required')
          "
          >Please enter the organiser</maia-validation
        >
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <maia-form-element class="input-column category" [hideOptionalIndicator]="true">
        <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
          <label
            class="b2b-kdl-font-small title-dark-color"
            i18n="@@nje-b2b-frontend-resource-articles-startdate"
            >Start date</label
          >
        </maia-label>
        <maia-input-date
          name="eventStartDate"
          [minimum]="minEventStartDate"
          [maximum]="maxEventStartDate"
          formControlName="eventStartDate"
          (ngModelChange)="onChangeOfStartDate()"
        ></maia-input-date>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-startendreq"
          *ngIf="
            addEditArticleForm.controls['eventStartDate'].touched &&
            addEditArticleForm.controls['eventStartDate'].hasError('required')
          "
          >Please enter a start and end date</maia-validation
        >
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-validstartdate"
          *ngIf="
            (addEditArticleForm.controls['eventStartDate'].dirty &&
              (addEditArticleForm.controls['eventStartDate'].hasError('atlasInvalidCharacters') ||
                addEditArticleForm.controls['eventStartDate'].hasError('atlasInvalidLength'))) ||
            addEditArticleForm.controls['eventStartDate'].hasError('atlasInvalidYear') ||
            addEditArticleForm.controls['eventStartDate'].hasError('atlasInvalidMonth') ||
            addEditArticleForm.controls['eventStartDate'].hasError('atlasInvalidDay')
          "
          >Please enter a valid start date</maia-validation
        >
        <maia-validation
          type="error"
          *ngIf="addEditArticleForm.hasError('eventStartDate', 'atlasAfter')"
        >
          Start date cannot be a past date
        </maia-validation>
        <maia-validation
          type="error"
          *ngIf="addEditArticleForm.hasError('eventStartDate', 'atlasBefore')"
        >
          Event Start Date should be before
          {{
            addEditArticleForm.controls.eventStartDate.errors?.atlasBefore.limit
              | toAtlasDate
              | atlasDate
          }}.
        </maia-validation>
      </maia-form-element>
      <maia-form-element class="input-column category" [hideOptionalIndicator]="true">
        <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
          <label
            class="b2b-kdl-font-small title-dark-color"
            i18n="@@nje-b2b-frontend-resource-articles-enddate"
            >End date</label
          >
        </maia-label>
        <maia-input-date
          name="eventEndDate"
          [minimum]="minEventEndDate"
          [maximum]="maxEventStartDate"
          formControlName="eventEndDate"
        ></maia-input-date>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-endreq"
          *ngIf="
            addEditArticleForm.controls['eventEndDate'].touched &&
            addEditArticleForm.controls['eventEndDate'].hasError('required')
          "
          >Please enter a end date</maia-validation
        >
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-validenddate"
          *ngIf="
            (addEditArticleForm.controls['eventEndDate'].dirty &&
              (addEditArticleForm.controls['eventEndDate'].hasError('atlasInvalidCharacters') ||
                addEditArticleForm.controls['eventEndDate'].hasError('atlasInvalidLength'))) ||
            addEditArticleForm.controls['eventEndDate'].hasError('atlasInvalidYear') ||
            addEditArticleForm.controls['eventEndDate'].hasError('atlasInvalidMonth') ||
            addEditArticleForm.controls['eventEndDate'].hasError('atlasInvalidDay')
          "
          >Please enter a valid end date</maia-validation
        >
        <maia-validation
          type="error"
          *ngIf="
            addEditArticleForm.hasError('eventEndDate', 'atlasAfter') ||
            (addEditArticleForm.controls['eventEndDate'].dirty &&
              addEditArticleForm.hasError('eventEndDate', 'pastDate'))
          "
        >
          End date cannot be a past date
        </maia-validation>
        <maia-validation
          type="error"
          *ngIf="addEditArticleForm.hasError('eventEndDate', 'atlasBefore')"
        >
          Event End Date should be before
          {{
            addEditArticleForm.controls.eventEndDate.errors?.atlasBefore.limit
              | toAtlasDate
              | atlasDate
          }}.
        </maia-validation>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <maia-form-element
        class="input-column category"
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
      >
        <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
          <label
            class="b2b-kdl-font-small title-dark-color"
            i18n="@@nje-b2b-frontend-resource-article-starttime"
            >Start time</label
          >
        </maia-label>
        <maia-timepicker name="eventStartTime" formControlName="eventStartTime"></maia-timepicker>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-starttimereq"
          *ngIf="
            addEditArticleForm.controls['eventStartTime'].dirty &&
            addEditArticleForm.controls['eventStartTime'].hasError('required')
          "
          >Please enter a start time</maia-validation
        >
        <maia-validation
          *ngIf="addEditArticleForm.controls['eventStartTime'].hasError('invalidTime')"
          >Start time cannot be a past time</maia-validation
        >
      </maia-form-element>
      <maia-form-element class="input-column category" [hideOptionalIndicator]="true">
        <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
          <label
            class="b2b-kdl-font-small title-dark-color"
            i18n="@@nje-b2b-frontend-resource-article-endtime"
            >End time</label
          >
        </maia-label>
        <maia-timepicker name="eventEndTime" formControlName="eventEndTime"></maia-timepicker>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-endtimereq"
          *ngIf="
            addEditArticleForm.controls['eventEndTime'].dirty &&
            addEditArticleForm.controls['eventEndTime'].hasError('required')
          "
          >Please enter a end time</maia-validation
        >
        <maia-validation *ngIf="addEditArticleForm.controls['eventEndTime'].hasError('invalidTime')"
          >End time cannot be a past time</maia-validation
        >
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>
  </div>
  <maia-form-element id="form-element-11" class="input-column" hideOptionalIndicator>
    <maia-label i18n="@@nje-b2b-frontend-resource-article-title"> Title</maia-label>
    <maia-text-area-limit [maxLength]="titleLimit">
      <textarea
        name="title"
        id="title"
        maiaTextArea
        [atlasMaxLength]="titleLimit"
        placeholder="Enter title here"
        i18n-placeholder="@@nje-b2b-frontend-resource-article-entertitle"
        required
        formControlName="title"
      >
      </textarea>
      <maia-validation
        i18n="@@nje-b2b-frontend-resource-article-titlereq"
        *ngIf="
          addEditArticleForm.controls['title'].touched &&
          addEditArticleForm.controls['title'].hasError('required')
        "
        >Please enter a title</maia-validation
      >
      <maia-validation *ngIf="addEditArticleForm.hasError('title', 'maxlength')"
        >Title is too long (maximum is
        {{ addEditArticleForm.controls['title'].errors?.maxlength.requiredLength }}
        characters)
      </maia-validation>
      <maia-validation *ngIf="addEditArticleForm.hasError('title', 'pattern')"
        >Title is invalid
      </maia-validation>
    </maia-text-area-limit>
  </maia-form-element>

  <maia-form-element id="form-element-11" class="input-column" hideOptionalIndicator>
    <maia-label
      i18n="@@nje-b2b-frontend-resource-article-desc"
      class="b2b-kdl-font-small title-dark-color"
      >Description</maia-label
    >
    <maia-text-area-limit
      [maxLength]="descriptionLimit"
      label="Description"
      i18n-label="@@nje-b2b-frontend-resource-article-desc"
    >
      <textarea
        maiaTextArea
        name="textAreaWideValidation"
        class="textarea-wide"
        [atlasMaxLength]="descriptionLimit"
        required
        placeholder="Brief description about the news"
        i18n-placeholder="@@nje-b2b-frontend-resource-article-newsdesc"
        formControlName="description"
      ></textarea>
      <maia-validation
        i18n="@@nje-b2b-frontend-resource-article-descreq"
        *ngIf="
          addEditArticleForm.controls['description'].touched &&
          addEditArticleForm.controls['description'].hasError('required')
        "
        >Please enter the description</maia-validation
      >
      <maia-validation *ngIf="addEditArticleForm.hasError('description', 'maxlength')"
        >Description is too long (maximum is
        {{ addEditArticleForm.controls['description'].errors?.maxlength.requiredLength }}
        characters)
      </maia-validation>
      <maia-validation
        *ngIf="addEditArticleForm.hasError('description', 'pattern')"
        i18n="@@nje-b2b-frontend-resource-article-descriptioninvalid"
        >Description is invalid</maia-validation
      >
    </maia-text-area-limit>
  </maia-form-element>

  <maia-form-element class="input-column" hideOptionalIndicator>
    <label
      class="b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-resource-article-addmedia"
      >Add Media</label
    >
    <hermes-file-uploader-tile
      class="upload-button"
      name="file-uploader-basic"
      [slideInConfig]="slideInConfig"
      formControlName="addMediaFile"
      (click)="updateFlag(media)"
      [(ngModel)]="uploadedFilesBasic"
    >
    </hermes-file-uploader-tile>
  </maia-form-element>

  <div class="article">
    <div *ngFor="let media of imagePreviewPaths">
      <video *ngIf="media.type === 'video/mp4'" controls autoplay class="artical-image">
        Video
        <source [src]="media.image" />
      </video>
      <span>
        <img *ngIf="media.type !== 'video/mp4'" [src]="media.image" class="artical-image" />
      </span>
    </div>
  </div>

  <maia-divider></maia-divider>
  <maia-form-element>
    <maia-checkbox-group>
      <maia-checkbox
        name="scheduleLater"
        (ngModelChange)="scheduleLaterFunc($event.internalValue)"
        formControlName="scheduleLater"
      >
        <label
          class="b2b-kdl-font-small title-dark-color"
          i18n="@@nje-b2b-frontend-resource-article-schedule"
          >Schedule for later</label
        >
        <p
          sublabel
          class="schedule-paragraph"
          i18n="@@nje-b2b-frontend-resource-article-schedulelater"
          >You can schedule this news to be automatically published at a later time
        </p>
      </maia-checkbox>
    </maia-checkbox-group>
  </maia-form-element>
  <div *ngIf="scheduleLaterValue">
    <maia-fluid-fluid-layout>
      <maia-form-element
        class="input-column category"
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
      >
        <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
          <label
            class="b2b-kdl-font-small title-dark-color"
            i18n="@@nje-b2b-frontend-resource-article-dateofpublishing"
            >Date of publishing</label
          >
        </maia-label>
        <maia-input-date
          name="dateOfPublishing"
          [minimum]="addEditArticleForm.formGroup.value.eventStartDate || minEventStartDate"
          [maximum]="addEditArticleForm.formGroup.value.eventEndDate || maxEventStartDate"
          formControlName="dateOfPublishing"
        ></maia-input-date>
        <maia-validation-container
          class="validation-container"
          *ngIf="addEditArticleForm.hasToShowErrors('dateOfPublishing')"
        >
          <maia-validation
            i18n="@@nje-b2b-frontend-resource-article-dateofpublishinginvalid"
            type="error"
            *ngIf="
              addEditArticleForm.hasError('dateOfPublishing', 'invalid') ||
              addEditArticleForm.controls['dateOfPublishing'].hasError('atlasInvalidCharacters') ||
              addEditArticleForm.controls['dateOfPublishing'].hasError('atlasInvalidLength') ||
              addEditArticleForm.controls['dateOfPublishing'].hasError('atlasInvalidYear') ||
              addEditArticleForm.controls['dateOfPublishing'].hasError('atlasInvalidMonth') ||
              addEditArticleForm.controls['dateOfPublishing'].hasError('atlasInvalidDay')
            "
            >Date of publishing invalid</maia-validation
          >
          <maia-validation
            i18n="@@nje-b2b-frontend-resource-article-dateofpublishingrequired"
            type="error"
            *ngIf="addEditArticleForm.hasError('dateOfPublishing', 'required')"
            >Date of publishing required</maia-validation
          >
          <maia-validation
            type="error"
            *ngIf="addEditArticleForm.hasError('dateOfPublishing', 'atlasBefore')"
          >
            Date of publishing should be before
            {{
              addEditArticleForm.controls.dateOfPublishing.errors?.atlasBefore.limit
                | toAtlasDate
                | atlasDate
            }}.
          </maia-validation>
          <maia-validation
            type="error"
            *ngIf="addEditArticleForm.hasError('dateOfPublishing', 'atlasAfter')"
          >
            Date of publishing should be after
            {{
              addEditArticleForm.controls.dateOfPublishing.errors?.atlasAfter.limit
                | toAtlasDate
                | atlasDate
            }}.
          </maia-validation>
        </maia-validation-container>
      </maia-form-element>
      <maia-form-element class="input-column category" [hideOptionalIndicator]="true">
        <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-24">
          <label
            class="b2b-kdl-font-small title-dark-color"
            i18n="@@nje-b2b-frontend-resource-article-timeofpublishing"
            >Time of publishing</label
          >
        </maia-label>
        <maia-timepicker
          name="timeOfPublishing"
          formControlName="timeOfPublishing"
        ></maia-timepicker>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-enterapublishingtime"
          *ngIf="
            addEditArticleForm.controls['timeOfPublishing'].dirty &&
            addEditArticleForm.controls['timeOfPublishing'].hasError('required')
          "
          >Please enter a Publishing time</maia-validation
        >
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-article-validpublishing"
          *ngIf="addEditArticleForm.controls['timeOfPublishing'].hasError('invalidTime')"
          >Please enter a valid Publishing time</maia-validation
        >
      </maia-form-element>
    </maia-fluid-fluid-layout>
  </div>
  <maia-divider></maia-divider>
  <div class="add-edit-article-buttons">
    <maia-button-group>
      <button
        *ngIf="!scheduleLaterValue && !eventPage"
        maiaButton
        maiaButtonType="primary"
        (click)="publishOrSchedule()"
        i18n="@@nje-b2b-frontend-resource-article-publish"
        [disabled]="isFormValid(1)"
        >Publish</button
      >

      <button
        *ngIf="!scheduleLaterValue && eventPage"
        maiaButton
        maiaButtonType="primary"
        (click)="publishOrSchedule()"
        i18n="@@nje-b2b-frontend-resource-article-publish"
        [disabled]="isFormValid(2)"
        >Publish</button
      >

      <button
        *ngIf="scheduleLaterValue && !eventPage"
        maiaButton
        maiaButtonType="primary"
        (click)="publishOrSchedule()"
        i18n="@@nje-b2b-frontend-resource-article-schedle"
        [disabled]="isFormValid(3)"
        >Schedule
      </button>

      <button
        *ngIf="scheduleLaterValue && eventPage"
        maiaButton
        maiaButtonType="primary"
        (click)="publishOrSchedule()"
        i18n="@@nje-b2b-frontend-resource-article-schedle"
        [disabled]="isFormValid(4)"
        >Schedule
      </button>

      <button
        maiaButton
        maiaButtonType="secondary"
        *ngIf="!eventPage"
        (click)="viewPreview()"
        i18n="@@nje-b2b-frontend-resource-article-preview"
        [disabled]="isFormValid(1)"
        >Preview
      </button>

      <button
        maiaButton
        maiaButtonType="secondary"
        *ngIf="eventPage"
        (click)="viewPreview()"
        i18n="@@nje-b2b-frontend-resource-article-preview"
        [disabled]="isFormValid(2)"
        >Preview
      </button>

      <button
        maiaButton
        maiaButtonType="secondary"
        (click)="saveAsDraft()"
        *ngIf="!eventPage"
        i18n="@@nje-b2b-frontend-resource-article-savedraft"
        [disabled]="scheduleLaterValue || isFormValid(1)"
        >Save as draft
      </button>

      <button
        maiaButton
        maiaButtonType="secondary"
        (click)="saveAsDraft()"
        *ngIf="eventPage"
        i18n="@@nje-b2b-frontend-resource-article-savedraft"
        [disabled]="scheduleLaterValue || isFormValid(2)"
        >Save as draft
      </button>

      <button
        maiaButton
        maiaButtonType="secondary"
        (click)="cancelEvents()"
        i18n="@@nje-b2b-frontend-resource-article-cancel"
        >Cancel
      </button>
    </maia-button-group>
  </div>
</form>

<div *ngIf="viewPreviewVal">
  <h3 *ngIf="ifEnglishLang()">{{ addEditArticleFormData!.i18n!.en!.title }}</h3>
  <h3 *ngIf="!ifEnglishLang()">{{ addEditArticleFormData!.i18n!.bg!.title }}</h3>
  <div *ngIf="imagePreviewPaths.length > 0" class="image-div">
    <div class="enlarged-image-div">
      <video *ngIf="enlargedImage.type === 'video/mp4'" controls autoplay>
        <source [src]="enlargedImage.image" />
      </video>
      <img
        *ngIf="enlargedImage.type !== 'video/mp4'"
        [src]="enlargedImage.image"
        class="enlarged-image"
      />
    </div>
    <div class="image-vertical-container">
      <div *ngFor="let media of imagePreviewPaths">
        <video *ngIf="media.type === 'video/mp4'" controls autoplay class="image-vertical">
          Video
          <source [src]="media.image" />
        </video>
        <img
          *ngIf="media.type !== 'video/mp4'"
          [src]="media.image"
          class="image-vertical"
          (click)="imageClicked(media)"
        />
      </div>
    </div>
  </div>
  <p *ngIf="ifEnglishLang()" class="preview-desc">
    <maia-rich-text
      [value]="addEditArticleFormData!.i18n!.en!.description | hyperlink"
    ></maia-rich-text>
  </p>
  <p *ngIf="!ifEnglishLang()" class="preview-desc">
    <maia-rich-text
      [value]="addEditArticleFormData!.i18n!.bg!.description | hyperlink"
    ></maia-rich-text>
  </p>
  <span *ngIf="ifEventSelected()">
    <div class="align-date-venue-time-wrapper"
      ><svg class="preview-icon" b2bFrontendActionIndiVehicleDeliveryDate></svg>
      <maia-label class="align-date-venue-time-label"
        >Date: {{ previewStartDate | date: 'dd/MM/yy' }} -
        {{ previewEndDate | date: 'dd/MM/yy' }}</maia-label
      >
    </div>
    <div class="align-date-venue-time-wrapper"
      ><svg class="preview-icon" b2bFrontendInformationFuncPin></svg>
      <maia-label *ngIf="ifEnglishLang()" class="align-date-venue-time-label">
        Venue: {{ addEditArticleFormData!.i18n!.en!.location }}</maia-label
      >
      <maia-label *ngIf="!ifEnglishLang()" class="align-date-venue-time-label">
        Venue: {{ addEditArticleFormData!.i18n!.bg!.location }}</maia-label
      >
    </div>
    <div class="align-date-venue-time-wrapper"
      ><svg class="preview-icon" b2bFrontendActionFuncClock></svg>
      <maia-label class="align-date-venue-time-label"
        >Time: {{ previewStartTime }} - {{ previewEndTime }}</maia-label
      >
    </div>
  </span>
  <maia-divider class="divider-margin-t-20-b-30"></maia-divider>
  <button maiaButton maiaButtonType="primary" (click)="goBack()">Go Back</button>
</div>
