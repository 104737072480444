/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {BLANK_VALUE, FALSE, TRUE} from '@b2b-frontend/core';
import {
  INSR_TYPE,
  OBJECT_TYPE,
  MOCK_ADDITIONAL_COVERS_DATA,
} from '../../constants/property-insurance-constants';
import {
  AdditionalCoversDataInterface,
  AdditionalCoversSlideInInput,
  IvBaseInterface,
  PropertyCoversInformation,
  PropertyPackage,
} from '../../contracts/property-insurance.interface';
import {
  ADD_COVERS,
  ADD_VILLA,
  IMMOVABLE_PROPERTY,
  INCLUDED,
  MAIN_COVERS,
  PROPERTY_PACKAGE_HEADERS,
  AGGREGATE_LIMIT_VALUE,
  MOVABLE_PROPERTY,
  HOUSEHOLD_PROPERTY,
  PIECES_OF_ART,
  THEFT,
  EARTHQUAKE,
  VIEW_LESS,
  VIEW_MORE,
  PACKAGE_VALUE,
  MIN_VALUE,
} from '../property-cover-discount-layout/__tests__/property-covers-discount-layout.mocks';
import {Boolean} from '@atlas/businesstypes';
import {PropertyApiService} from '../../property-core/services/property-api.service';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';

@Component({
  selector: 'nje-covers-add-slidein',
  templateUrl: './covers-add-slidein.component.html',
  styleUrls: ['./covers-add-slidein.component.scss'],
})
export class CoversAddSlideinComponent
  extends ModalContentComponent<PropertyCoversInformation, AdditionalCoversSlideInInput>
  implements OnInit
{
  public InsuranceBasisList: IvBaseInterface[] = [];
  public propertyCoversForm: FormGroupManager<PropertyCoversInformation>;
  public villa = ADD_VILLA;
  public immovablePropertyValue = IMMOVABLE_PROPERTY;
  public movablePropertyValue = MOVABLE_PROPERTY;
  public mainCovers = MAIN_COVERS;
  public addCovers = ADD_COVERS;
  public package_headers: PropertyPackage[] = [...PROPERTY_PACKAGE_HEADERS];
  public activateLimit: boolean = false;
  public included: boolean = false;
  public less: boolean = false;
  public include = INCLUDED;
  public propertyListData: PropertyPackage[] = [];
  public propertyType: string;

  public aggregateLimitValue: number = AGGREGATE_LIMIT_VALUE;
  public additionalCoversList: AdditionalCoversDataInterface[];
  public currencySelected: string = BLANK_VALUE;
  public additionalCoversSelected: Boolean[] = [];
  public additionalCoversSelectedAggregateLimitList: number[] = [];
  public selectedAggregateLimitList: number[] = [];
  public selectedAddtionalCoverList: string[] = [];
  public propertyCoverReceived: PropertyCoversInformation;
  public movableProperty: boolean = false;
  public earthQuakeAggregateDisabled: Boolean = Boolean.TRUE;
  public earthQuakeValue: string = EARTHQUAKE;
  public viewablePackageHeaders: Boolean[] = [];
  public viewLess: string = VIEW_LESS;
  public viewMore: string = VIEW_MORE;
  public subLabelValue: string[] = [];
  public minInsuredValue: number = 0;
  public maxInsuredValue: number = 10000;
  public basisOfInsuranceSelected: string = BLANK_VALUE;
  public invalidAggregateLimitEntered: boolean = false;

  public constructor(
    public control: ModalControl<PropertyCoversInformation, AdditionalCoversSlideInInput>,
    private readonly _propertyApiService: PropertyApiService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    super();
    this.additionalCoversList = MOCK_ADDITIONAL_COVERS_DATA;
    this.additionalCoversSelected = Array<Boolean>(this.additionalCoversList.length).fill(
      Boolean.FALSE,
    );
    this.additionalCoversSelectedAggregateLimitList = Array<number>(
      this.additionalCoversList.length,
    ).fill(0);
    this.selectedAggregateLimitList = Array<number>(this.additionalCoversList.length).fill(0);
    this.selectedAddtionalCoverList = Array<string>(this.additionalCoversList.length).fill(
      BLANK_VALUE,
    );
    this.viewablePackageHeaders = Array<Boolean>(this.package_headers.length).fill(Boolean.FALSE);
    this.subLabelValue = Array<string>(this.additionalCoversList.length).fill(BLANK_VALUE);
    this._propertyApiService
      .getIvBaseOptions(INSR_TYPE, OBJECT_TYPE)
      .subscribe((res: IvBaseInterface[]) => {
        this.InsuranceBasisList = res;
      });
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this.propertyCoverReceived = data.propertyCoverInfo;
    this.package_headers.map((propertyList: PropertyPackage) => {
      if (
        propertyList.packageTile ===
        PACKAGE_VALUE + this.propertyCoverReceived?.mainCovers?.toString()
      ) {
        propertyList.selectedStatus = true;
      } else {
        propertyList.selectedStatus = false;
      }
    });
    this.currencySelected = data.currencySelected;
    if (this.propertyCoverReceived?.basisOfInsurance !== undefined) {
      this.basisOfInsuranceSelected = this.propertyCoverReceived.basisOfInsurance!;
    }
    this.propertyCoversForm = this._initializePropertyCoversSlideinForm(this.propertyCoverReceived);
    if (
      this.propertyCoverReceived.propertyType === HOUSEHOLD_PROPERTY ||
      this.propertyCoverReceived.propertyType === PIECES_OF_ART
    ) {
      this.movableProperty = true;
    }

    if (this.propertyCoverReceived.basisOfInsurance !== undefined) {
      this.prePopulateAdditionalCovers();
    }
  }

  private _initializePropertyCoversSlideinForm(
    formdata?: PropertyCoversInformation,
  ): FormGroupManager<PropertyCoversInformation> {
    return (this.propertyCoversForm =
      this._formFactory.createFormManager<PropertyCoversInformation>({
        basisOfInsurance: new FormControl(this.basisOfInsuranceSelected, [Validators.required]),
        insuredValue: new FormControl(formdata?.insuredValue, [
          Validators.required,
          Validators.min(this.minInsuredValue),
        ]),
        id: new FormControl(formdata?.id),
        covers: new FormControl(formdata?.covers),
        propertyType: new FormControl(formdata?.propertyType),
        mainCovers: new FormControl(formdata?.mainCovers, [Validators.required]),
        additionalCovers: new FormControl(formdata?.additionalCovers),
        aggregateLimit: new FormControl(formdata?.aggregateLimit),
        limitPerOccurance: new FormControl(formdata?.limitPerOccurance),
        premiumAmount: new FormControl(formdata?.premiumAmount),
      }));
  }

  public getInsuredValueFieldErrors(): boolean {
    if (
      this.propertyCoversForm.controls?.insuredValue?.dirty &&
      this.propertyCoversForm.controls?.insuredValue?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public prePopulateAdditionalCovers(): void {
    this.selectedAddtionalCoverList =
      // eslint-disable-next-line
      this.propertyCoverReceived?.additionalCovers! !== undefined &&
      this.propertyCoverReceived?.additionalCovers !== null
        ? this.propertyCoverReceived?.additionalCovers
        : Array<string>(this.additionalCoversList.length).fill(BLANK_VALUE);
    this.selectedAggregateLimitList =
      // eslint-disable-next-line
      this.propertyCoverReceived?.aggregateLimit! !== undefined &&
      this.propertyCoverReceived?.aggregateLimit !== null
        ? this.propertyCoverReceived?.aggregateLimit
        : Array<number>(this.additionalCoversList.length).fill(0);
    if (this.propertyCoverReceived !== undefined) {
      this.additionalCoversSelectedAggregateLimitList = Array<number>(
        this.additionalCoversList.length,
      ).fill(parseInt(this.propertyCoverReceived.insuredValue!));
    }
    this.selectedAddtionalCoverList?.forEach(ele => {
      if (ele !== BLANK_VALUE) {
        const index: number = this.selectedAddtionalCoverList.indexOf(ele);
        this.additionalCoversSelected[index] = Boolean.TRUE;
        this.subLabelValue[index] =
          this.movableProperty &&
          this.additionalCoversList[index].additionaCoverName !== this.earthQuakeValue
            ? MIN_VALUE + this.currencySelected
            : BLANK_VALUE;
        if (this.additionalCoversSelectedAggregateLimitList[index] === 0) {
          this.additionalCoversSelectedAggregateLimitList[index] = parseInt(
            this.propertyCoverReceived?.insuredValue!,
          );
        }
      }
    });
  }

  public isMovableProperty(additionalCover: AdditionalCoversDataInterface): boolean {
    if (additionalCover.additionaCoverName === THEFT) {
      return this.movableProperty ? true : false;
    }
    return true;
  }

  public insuaredValueChanged(): void {
    const insuredVal = this.propertyCoversForm.controls.insuredValue.value as string;
    if (insuredVal !== null) {
      this.additionalCoversSelectedAggregateLimitList = Array<number>(
        this.additionalCoversList.length,
      ).fill(parseInt(insuredVal));
    }
  }

  public changeAdditionalCoverSelection(index: number): void {
    this.propertyCoversForm.controls.aggregateLimit.setValue(
      this.propertyCoversForm.controls.insuredValue.value,
    );
    this.subLabelValue[index] =
      this.movableProperty &&
      this.additionalCoversList[index].additionaCoverName !== this.earthQuakeValue
        ? MIN_VALUE + this.currencySelected
        : BLANK_VALUE;
    if (this.additionalCoversSelected[index].asBoolean() === true) {
      this.selectedAggregateLimitList[index] =
        this.additionalCoversSelectedAggregateLimitList[index];
      this.selectedAddtionalCoverList[index] =
        this.additionalCoversList[index]!.additionaCoverName!;
    } else {
      this.invalidAggregateLimitEntered = false;
    }
    this.propertyCoversForm.controls.aggregateLimit.setValue(this.selectedAggregateLimitList);
    this.propertyCoversForm.controls.additionalCovers.setValue(this.selectedAddtionalCoverList);
  }

  public includeSelection(id: number, viewType: string): void {
    if (viewType === this.viewLess) {
      this.viewablePackageHeaders[id - 1] = Boolean.FALSE;
    } else if (viewType === this.viewMore) {
      this.viewablePackageHeaders[id - 1] = Boolean.TRUE;
    }
  }

  public cancel(): void {
    this.control.cancel();
  }

  public savePropertyData(): void {
    const propertyData: PropertyCoversInformation = this.propertyCoversForm
      ?.value as PropertyCoversInformation;
    propertyData.basisOfInsurance = this.basisOfInsuranceSelected!;
    propertyData.insuredValue = this.propertyCoversForm.controls.insuredValue.value;
    this.control.confirm(propertyData);
  }

  public selectPropertyList(index: number, property: PropertyPackage): void {
    this.package_headers.map(
      (propertyList: PropertyPackage) => (propertyList.selectedStatus = false),
    );
    this.package_headers[index].selectedStatus = true;
  }

  public getValueBasisOfInsurance(value: string): string | null {
    // eslint-disable-next-line
    const o: IvBaseInterface | undefined | null = this.InsuranceBasisList
      ? this.InsuranceBasisList.find((options: IvBaseInterface) => options.id == value)
      : null;
    return o ? o.name : null;
  }

  public selectBasisOfInsurance(): void {
    this.InsuranceBasisList.forEach(ele => {
      if (ele.id === this.propertyCoversForm.controls.basisOfInsurance.value) {
        this.minInsuredValue = ele.minIv;
        this.maxInsuredValue = ele.maxIv;
        this.basisOfInsuranceSelected = ele.name;
        this.propertyCoversForm.controls.insuredValue.setValidators([
          Validators.required,
          Validators.min(this.minInsuredValue),
        ]);
        this.propertyCoversForm.controls.insuredValue.updateValueAndValidity();
      }
      this._cdRef.detectChanges();
    });
  }

  public aggregateLimtEntered(index: number): boolean {
    if (this.additionalCoversSelectedAggregateLimitList[index].toString().length === 0) {
      this.invalidAggregateLimitEntered = true;
      return true;
    } else {
      this.invalidAggregateLimitEntered = false;
      return false;
    }
  }

  public isPropertyCoversFormValid(): boolean {
    return this.propertyCoversForm.valid && !this.invalidAggregateLimitEntered;
  }
}
