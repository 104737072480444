<form maiaForm [formGroup]="agentForm">
  <maia-label class="icon-primary-color m-b-8 m-t-16 b2b-kdl-font-small-light text-label">
    Agent Number:</maia-label
  >
  <maia-input-select formControlName="agentNumber">
    <input maiaInput placeholder="Select" [value]="agentForm?.controls['agentNumber']?.value" />
    <maia-option *ngFor="let option of options" [value]="option.title">
      {{ option.title }}
    </maia-option>
  </maia-input-select>
</form>
