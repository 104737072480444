import {IStepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {MtplRoutePaths} from '../../helper/mtpl-route-paths';

export const steps: IStepperProcessStep[] = [
  {
    id: MtplRoutePaths.MTPL_PICK_TERMS,
    title: 'Pick Terms',
    routePath: `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_PICK_TERMS}`,
  },
  {
    id: MtplRoutePaths.MTPL_OWNER_INSURERDETAILS,
    title: 'Owner & Insurer Details',
    routePath: `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_OWNER_INSURERDETAILS}`,
  },
  {
    id: MtplRoutePaths.MTPL_POLICY_DETAILS,
    title: 'Policy Details',
    routePath: `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_POLICY_DETAILS}`,
  },
  {
    id: MtplRoutePaths.MTPL_VEHICLE_DETAILS,
    title: 'Vehicle',
    routePath: `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_VEHICLE_DETAILS}`,
  },
  {
    id: MtplRoutePaths.MTPL_INSTALMENTS,
    title: 'Instalments',
    routePath: `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_INSTALMENTS}`,
  },
  {
    id: MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS,
    title: 'Additional Covers & Risks',
    routePath: `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS}`,
  },
  {
    id: MtplRoutePaths.MTPL_PROPOSAL,
    title: 'Proposal',
    routePath: `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_PROPOSAL}`,
  },
];
