<h3
  >As a HA employee, you have the following limits. Extending the below limits will require
  authorization.</h3
>

<ul maiaTextList id="maia-text-list-4" [iconType]="'checkmark'">
  <li maiaTextListItem *ngFor="let list of limitslist"> {{ list.objectName }}</li>
</ul>

<h4
  >*N.B. Please, be advised that if you have some specific request for insurance, you can write it
  down in the mailing box at the end of the proposal form registration by clicking "Special Request"
  button.
</h4>
