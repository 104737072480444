import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';
import {CascoWorkflow} from '../../casco-core/casco-workflow/CascoWorkflow';
import {DateKey, DateUtils} from '@atlas/businesstypes';

import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';

import {mockData} from './__tests__/data-mockInterface';
import {
  agencyMock,
  claimTypeMock,
  installmentsMock,
  originCountryMock,
  policyLenMock,
  secureDeviceMock,
  sellerCodeMock,
  RadioDefaults,
  PolicyLengths,
} from './__tests__/list-select-mock';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {QQ_POLICY_DETAILS} from '../../constants/price-policy-details-constants';

@Component({
  selector: 'nje-policy-details',
  templateUrl: './policy-details.component.html',
  styleUrls: ['./policy-details.component.scss'],
})
@UntilDestroy()
export class PolicyDetailsComponent extends ScreenFormComponent implements OnInit {
  // dates control
  public todayDate = DateUtils.today();
  public endDate = DateUtils.add(DateUtils.today(), DateKey.Day, 365);
  public minStartDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxStartDate = DateUtils.add(DateUtils.today(), DateKey.Day, 60);
  public minEndDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxEndDate = DateUtils.add(DateUtils.today(), DateKey.Day, 365);
  // mock data initialze
  public policyLenMock: mockData[] = policyLenMock;
  public installmentsMock: mockData[] = installmentsMock;
  public secureDeviceMock: mockData[] = secureDeviceMock;
  public originCountryMock: mockData[] = originCountryMock;
  public claimTypeMock: mockData[] = claimTypeMock;
  public agencyMock: mockData[] = agencyMock;
  public sellerCodeMock: mockData[] = sellerCodeMock;

  // flags
  public intrptCovrg: boolean = false;
  public plcyOvrlp: boolean = false;
  public strtDt: boolean = false;
  public infoMsg: boolean = false;

  public option: string;
  public length: number = 12;
  public isCurrDt: number;
  public continueClicked: boolean;

  public policyForm: FormGroupManager<any> = this._formFactory.createFormManager<any>({
    // date form control
    srtdt: new FormControl(DateUtils.add(DateUtils.today(), DateKey.Day, 1)),
    enddt: new FormControl({value: undefined, disabled: true}, [Validators.required]),

    policyLength: new FormControl('12 Months'),
    instlmnOptn: new FormControl('Single Payment'),
    secuDvc: new FormControl(undefined),
    vhclClaims: new FormControl(undefined, [Validators.required]),
    importCountry: new FormControl(undefined, [Validators.required]),
    otherCountry: new FormControl(undefined),
    keyOrignl: new FormControl(undefined, [Validators.required]),
    rmtCntrl: new FormControl(undefined, [Validators.required]),
    keyDup: new FormControl(undefined, [Validators.required]),
    agencyCode: new FormControl(undefined, [Validators.required]),
    sellerCode: new FormControl(undefined, [Validators.required]),

    // radio form control
    vehicleAbroad60Radio: new FormControl(undefined, [Validators.required]),
    unlawfulRadio: new FormControl(undefined, [Validators.required]),
    activeCascoRadio: new FormControl(RadioDefaults.NO),
    immobilzerRadio: new FormControl(undefined, [Validators.required]),
  });

  public constructor(
    route: ActivatedRoute,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _process: CascoProcessService,
  ) {
    super(route);
    this._process.currentWorkflow = CascoWorkflow.PRICE_OFFER;
  }

  public ngOnInit(): void {
    this.policyLengthChanger();
    this.autoAssingmt();
    sessionStorage.setItem(QQ_POLICY_DETAILS.INSTALMENT, QQ_POLICY_DETAILS.INSTALMENT_DEFAULT);
  }

  // end date changing function
  public endDateChanger(len: number): void {
    switch (len) {
      case 12:
        this.endDate = DateUtils.add(this.policyForm.controls.srtdt.value, DateKey.Day, 364);
        break;
      case 24:
        this.endDate = DateUtils.add(this.policyForm.controls.srtdt.value, DateKey.Day, 729);
        break;
      case 36:
        this.endDate = DateUtils.add(this.policyForm.controls.srtdt.value, DateKey.Day, 1095);
        break;
      case 48:
        this.endDate = DateUtils.add(this.policyForm.controls.srtdt.value, DateKey.Day, 1460);
        break;
      case 60:
        this.endDate = DateUtils.add(this.policyForm.controls.srtdt.value, DateKey.Day, 1825);
        break;
      case 72:
        this.endDate = DateUtils.add(this.policyForm.controls.srtdt.value, DateKey.Day, 2190);
        break;
    }
  }

  // end date field customizer based on policy length selection
  public policyLengthChanger(): void {
    this.policyForm.controls.policyLength.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: string) => {
        if (value) {
          this.infoMsg = false;
          this.policyForm.controls.enddt.disable();

          switch (value) {
            case PolicyLengths.CUSTOM:
              this.policyForm.controls.enddt.enable();
              this.endDateChanger((this.length = 12));
              this.infoMsg = true;
              break;
            case PolicyLengths.TWELVE:
              this.endDateChanger((this.length = 12));
              break;
            case PolicyLengths.TWENTY4:
              this.endDateChanger((this.length = 24));
              break;
            case PolicyLengths.THIRTY6:
              this.endDateChanger((this.length = 36));
              break;
            case PolicyLengths.FOURTY8:
              this.endDateChanger((this.length = 48));
              break;
            case PolicyLengths.SIXTY:
              this.endDateChanger((this.length = 60));
              break;
            case PolicyLengths.SEVENTY2:
              this.endDateChanger((this.length = 72));
              break;
          }
        }
      });
  }

  // change end date according to start date and control for change of start date label
  public changeEnd() {
    this.isCurrDt = DateUtils.diff(
      DateUtils.today(),
      this.policyForm.controls.srtdt.value,
      DateKey.Day,
    );

    if (this.isCurrDt == 0) {
      this.strtDt = true;
    }
    if (this.isCurrDt != 0) {
      this.strtDt = false;
    }
    this.endDateChanger(this.length);
  }

  // assign same value for ther other two field according to original key field
  public autoAssingmt(): void {
    this.policyForm.controls.keyOrignl.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: number) => {
        this.policyForm.controls.rmtCntrl.setValue(value);
        this.policyForm.controls.keyDup.setValue(value);
      });
  }

  public instalOptSelection(id: string): void {
    sessionStorage.setItem(QQ_POLICY_DETAILS.INSTALMENT, id);
  }

  protected saveMtplData(): void {
    this.continueClicked = true;
  }
}
