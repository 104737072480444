import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {ZakrilaComponent} from './zakrila.component';
import {RoutePaths} from './constants/route-paths';
import {InsuredPartyComponent} from './screens/insured-party/insured-party.component';
import {CoversAndPremiumComponent} from './screens/covers-and-premium/covers-and-premium.component';
import {ConfirmationComponent} from './screens/confirmation/confirmation.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: RoutePaths.Zakrila,
    pathMatch: 'full',
  },
  {
    path: RoutePaths.Zakrila,
    component: ZakrilaComponent,
    data: {
      njeB2BTitle: 'Zakrila',
    },
    children: [
      {
        path: '',
        redirectTo: RoutePaths.InsuringPartyDetails,
      },
      {
        path: RoutePaths.InsuringPartyDetails,
        component: InsuredPartyComponent,
      },
      {
        path: RoutePaths.InsuredPartyDetails,
        component: InsuredPartyComponent,
      },
      {
        path: RoutePaths.CoversAndPremium,
        component: CoversAndPremiumComponent,
      },
      {
        path: RoutePaths.Confirmation,
        component: ConfirmationComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class ZakrilaRoutingModule {}

/**
 * The route we export is an empty path with the private module lazy loaded.
 */
export const ZAKRILA_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./zakrila.routing.module').then(module => module.ZakrilaRoutingModule),
};
