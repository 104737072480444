import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'nje-cms-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public constructor(private readonly _router: Router, private readonly _route: ActivatedRoute) {}

  public toNewsAndEvents(): void {
    void this._router.navigate(['news-and-events'], {relativeTo: this._route});
  }

  public toLibrary(): void {
    void this._router.navigate(['library'], {relativeTo: this._route});
  }

  public toAdminDocuments(): void {
    void this._router.navigate(['admin-documents'], {relativeTo: this._route});
  }

  public toContact(): void {
    void this._router.navigate(['contacts'], {relativeTo: this._route});
  }
}
