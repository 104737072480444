import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {AddedAgentsDetails, AgentOptions} from '../../../../constants/account-creation';
import {AddedAgents} from '../../../../models/b2b/AddedAgents';

@Component({
  selector: 'nje-account-creation-details',
  templateUrl: './account-creation-details.component.html',
  styleUrls: ['./account-creation-details.component.scss'],
})
export class AccountCreationDetailsComponent implements OnInit {
  public value: string;
  public agentOptions = AgentOptions;
  public accoutDetailsForm: FormGroup;

  public addedAgents: AddedAgents[] = [];
  public getAgentsList(): void {
    this.prepareAgentsList();
  }

  public prepareAgentsList(): void {
    this.addedAgents = AddedAgentsDetails;
  }

  public constructor(private readonly _fb: FormBuilder) {}

  public ngOnInit(): void {
    this.getAgentsList();
    this.accoutDetailsForm = this._fb.group({
      firstNameBg: new FormControl(undefined, [Validators.required]),
      egnLnchEik: new FormControl(undefined, [Validators.required]),
      middleNameBg: new FormControl(undefined, [Validators.required]),
      lastNameBg: new FormControl(undefined, [Validators.required]),
      firstNameEn: new FormControl(undefined, [Validators.required]),
      middleNameEn: new FormControl(undefined, [Validators.required]),
      lastNameEn: new FormControl(undefined, [Validators.required]),
      corporateEmailId: new FormControl(undefined, [Validators.required]),
      phoneNumber: new FormControl(undefined, [Validators.required]),
      username: new FormControl(undefined, [Validators.required]),
      password: new FormControl(undefined, [Validators.required]),
    });
  }
}
