<form maiaForm [formGroup]="instalmentFilterSlideinForm">
  <maia-form-group>
    <maia-fluid-fluid-layout>
      <maia-form-element hideOptionalIndicator class="m-r-16">
        <label
          class="b2b-kdl-font-small title-dark-color m-b-8 m-t-16"
          i18n="@@nje-b2b-frontend-payments-instalmentFilterSlidein-typeOfInsurance"
          >Type of Insurance</label
        >
        <maia-input-select formControlName="insuranceType" required>
          <input
            maiaInput
            placeholder="Select"
            i18n-placeholder="
              @@nje-b2b-frontend-payments-instalmentFilterSlidein-insuranceTypePlaceholder"
            [value]="instalmentFilterSlideinForm.controls.insuranceType.value?.name"
          />
          <maia-option *ngFor="let insuranceType of insuranceTypeList" [value]="insuranceType">{{
            insuranceType.name
          }}</maia-option>
        </maia-input-select>
      </maia-form-element>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <maia-form-element hideOptionalIndicator class="m-r-16">
        <label
          class="b2b-kdl-font-small title-dark-color m-b-8 m-t-16"
          i18n="@@nje-b2b-frontend-payments-instalmentFilterSlidein-policyIssuedFrom"
          >Policy issued from</label
        >
        <maia-input-date
          formControlName="policyIssuedFrom"
          required
          [minimum]="undefined"
          [maximum]="maxFromDate"
        ></maia-input-date>
      </maia-form-element>
      <maia-form-element hideOptionalIndicator class="m-r-16">
        <label
          class="b2b-kdl-font-small title-dark-color m-b-8 m-t-16"
          i18n="@@nje-b2b-frontend-payments-instalmentFilterSlidein-policyIssuedTo"
        >
          Policy issued to</label
        >
        <maia-input-date
          formControlName="policyIssuedTo"
          required
          [minimum]="undefined"
          [maximum]="maxToDate"
        ></maia-input-date>
      </maia-form-element>
    </maia-fluid-fluid-layout>
  </maia-form-group>
</form>
<maia-button-group class="button-group">
  <button
    maiaButton
    maiaButtonType="primary"
    (click)="search()"
    [disabled]="instalmentFilterSlideinForm.invalid"
    i18n="@@nje-b2b-frontend-payments-instalmentFilterSlidein-searchButton"
    >Search</button
  >
  <button
    maiaButton
    maiaButtonType="secondary"
    (click)="cancel()"
    i18n="@@nje-b2b-frontend-payments-instalmentFilterSlidein-cancelButton"
    >Cancel</button
  >
</maia-button-group>
