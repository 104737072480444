import {Injectable} from '@angular/core';
import {Resolve} from '@angular/router';
import {
  StepperProcessStep,
  StepResolverService as StepResolverServiceBase,
} from '@njf-frontend-angular/flow-progress';

import {PaymentsProcessService} from './payments-process.service';

@Injectable()
export class StepResolverService
  extends StepResolverServiceBase
  implements Resolve<StepperProcessStep | undefined>
{
  public constructor(process: PaymentsProcessService) {
    super(process);
  }
}
