<form class="log-in-form-wrapper form" [formGroup]="logInForm">
  <maia-form-group class="log-in-form">
    <svg b2bFrontendActionExprDoorIn class="icon-adjust"></svg>
    <maia-label class="b2b-kdl-font-grand-serif title-dark-color sign-in-txt"> Sign in</maia-label>
    <maia-form-element
      hideOptionalIndicator
      class="input-column login-in-form-element"
      label="Username"
      multi
    >
      <maia-form-subelement>
        <input
          maiaInput
          type="text"
          formControlName="userName"
          placeholder="Enter Username"
          (keyup)="logInDataChanged()"
        />
      </maia-form-subelement>
    </maia-form-element>

    <maia-form-element
      hideOptionalIndicator
      class="input-column login-in-form-element"
      label="Password"
      multi
    >
      <maia-form-subelement>
        <input
          maiaInput
          type="password"
          formControlName="password"
          placeholder="Enter Password"
          (keyup)="logInDataChanged()"
        />
      </maia-form-subelement>
    </maia-form-element>

    <div class="validation-container">
      <maia-validation *ngIf="isInCorrectCredentials"> Invalid credentials </maia-validation>
    </div>

    <div>
      <maia-button-group class="m-t-24 m-b-8 button-wrapper">
        <button
          maiaButton
          maiaButtonType="primary"
          id="logIn"
          [disabled]="logInForm.invalid"
          (click)="logIn()"
        >
          Sign in
        </button>
        <button
          maiaButton
          maiaButtonType="tertiary"
          class="forgot-password"
          (click)="forgotPassword()"
        >
          Forgot Password?
        </button>
      </maia-button-group>
    </div>
  </maia-form-group>
</form>
