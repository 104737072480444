import {DecimalUtils} from '@atlas/businesstypes';
import {
  SingleTripFormData,
  TravelTripTypeDto,
  TravelReasonDto,
  RadioButtonDto,
  TravelWorkType,
  TravelInsuredPeople,
} from '@b2b-frontend/core';

export const MAX_HR = 23;
export const MAX_MIN = 59;

export const MOCK_TRAVEL_TRIP_DETAILS_FORM = {
  SINGLE_TRIP_ID: 1,
  MULTI_TRIP_ID: 2,
  TRIP_REASON_ID: '8104.3',
  TRIP_WORK_TYPE_ID: '1',
};

export const MOCK_SINGLE_TRIP_DATA: SingleTripFormData = {
  startEndData: {
    start: '01-08-2021',
    end: '01-09-2021',
  },
  time: '11:30',
  travelAbroadRadioGroup: 1,
};

export const MOCK_TEMP_WORK_ABORD_IDS = ['8109.4', '8105.4', '8104.4', '8102.4'];

export const MOCK_TRAVEL_INSURED_PEOPLE_TYPE_LIST: TravelInsuredPeople[] = [
  {
    insuredPeopleId: 1,
    insuredPeopleType: 'Children',
    ageLimit: 'under 16 years',
    numberOfPeople: DecimalUtils.ZERO,
    start: 0,
    end: 16,
  },
  {
    insuredPeopleId: 2,
    insuredPeopleType: 'Adults',
    ageLimit: '16 to 65 years',
    numberOfPeople: DecimalUtils.ZERO,
    start: 16,
    end: 65,
  },
  {
    insuredPeopleId: 3,
    insuredPeopleType: 'Elders',
    ageLimit: '65 to 70 years',
    numberOfPeople: DecimalUtils.ZERO,
    start: 65,
    end: 70,
  },
  {
    insuredPeopleId: 4,
    insuredPeopleType: 'Senior Elders',
    ageLimit: '70 to 80 years',
    numberOfPeople: DecimalUtils.ZERO,
    start: 70,
    end: 80,
  },
];

export const MOCK_TRAVELUS_CANADA_OPTION_LIST: RadioButtonDto[] = [
  {
    id: 1,
    name: 'Yes',
  },
  {
    id: 2,
    name: 'No',
  },
];

export const MOCK_TRIP_LIST: TravelTripTypeDto[] = [
  {
    tripId: 1,
    tripName: 'Single Trip',
  },
  {
    tripId: 2,
    tripName: 'Multi Trip',
  },
];

export const MOCK_DAYSIN_ABROAD_LIST: RadioButtonDto[] = [
  {
    id: 31,
    name: 'Up to 31 days',
  },
  {
    id: 62,
    name: 'Up to 62 days',
  },
  {
    id: 92,
    name: 'Up to 92 days',
  },
];
export const MOCK_TRAVEL_REASON: TravelReasonDto[] = [
  {
    ID: '8104.1',
    objectKind: '8104',
    objectName: 'Tourism/ Business Trip',
    objectType: 8182,
  },
  {
    ID: '8104.3',
    objectKind: '8104',
    objectName: 'Education Abroad',
    objectType: 8182,
  },
  {
    ID: '8104.4',
    objectKind: '8104',
    objectName: 'Temporary Work Abroad',
    objectType: 8182,
  },
];

export const MOC_WORK_TYPE: TravelWorkType[] = [
  {
    fieldGrpName: 'OAIP4',
    fieldName: 'OAIP5',
    fieldValue: '1',
    fieldValueName: 'White collars',
    objectType: 8182,
  },
  {
    fieldGrpName: 'OAIP4',
    fieldName: 'OAIP5',
    fieldValue: '2',
    fieldValueName: 'Drivers',
    objectType: 8182,
  },
  {
    fieldGrpName: 'OAIP4',
    fieldName: 'OAIP5',
    fieldValue: '3',
    fieldValueName: 'Blue collars',
    objectType: 8182,
  },
  {
    fieldGrpName: 'OAIP4',
    fieldName: 'OAIP5',
    fieldValue: '4',
    fieldValueName: 'Marines',
    objectType: 8182,
  },
];

export enum TripNumber {
  singleTrips = 1,
  multipleTrips = 2,
}
