import {DashboardOption} from '../models/DashboardOption';
import {RoutePaths} from './route-paths';

export const DashboardOptions: DashboardOption[] = [
  {
    id: `${RoutePaths.B2B}-${RoutePaths.GROUPS_AND_MODULES}`,
    title: 'Groups And Modules',
    routePath: `./dashboard/admin/uam/${RoutePaths.B2B}/${RoutePaths.GROUPS_AND_MODULES}`,
  },
  {
    id: `${RoutePaths.B2B}-${RoutePaths.CREATE_ACCOUNT}`,
    title: 'Create B2B Account',
    routePath: ``,
  },
  {
    id: `${RoutePaths.B2B}-${RoutePaths.RESET_PASSWORD}`,
    title: 'Reset B2B Account Password',
    routePath: `./dashboard/admin/uam/${RoutePaths.B2B}/${RoutePaths.RESET_PASSWORD}`,
  },
  {
    id: `${RoutePaths.B2B}-${RoutePaths.EDIT_ACCOUNT}`,
    title: 'Edit B2B Account',
    routePath: ``,
  },
  {
    id: `${RoutePaths.B2B}-${RoutePaths.ACTIVATE_ACCOUNT}`,
    title: 'Activate B2B Account',
    routePath: `./dashboard/admin/uam/${RoutePaths.B2B}/${RoutePaths.ACTIVATE_ACCOUNT}`,
  },
  {
    id: `${RoutePaths.B2B}-${RoutePaths.DEACTIVATE_ACCOUNT}`,
    title: 'Deactivate B2B Account',
    routePath: `./dashboard/admin/uam/${RoutePaths.B2B}/${RoutePaths.DEACTIVATE_ACCOUNT}`,
  },
  {
    id: `${RoutePaths.B2B}-${RoutePaths.SUSPEND_ACCOUNT}`,
    title: 'Suspend B2B Account',
    routePath: `./dashboard/admin/uam/${RoutePaths.B2B}/${RoutePaths.SUSPEND_ACCOUNT}`,
  },
  {
    id: `${RoutePaths.B2C}-${RoutePaths.ACTIVATE_ACCOUNT}`,
    title: 'Activate B2C Account',
    routePath: `./dashboard/admin/uam/${RoutePaths.B2C}/${RoutePaths.ACTIVATE_ACCOUNT}`,
  },
  {
    id: `${RoutePaths.B2C}-${RoutePaths.DEACTIVATE_ACCOUNT}`,
    title: 'Deactivate B2C Account',
    routePath: `./dashboard/admin/uam/${RoutePaths.B2C}/${RoutePaths.DEACTIVATE_ACCOUNT}`,
  },
  {
    id: `${RoutePaths.B2C}-${RoutePaths.RESET_PASSWORD}`,
    title: 'Reset B2C Account Password',
    routePath: `./dashboard/admin/uam/${RoutePaths.B2C}/${RoutePaths.RESET_PASSWORD}`,
  },
  {
    id: `${RoutePaths.B2C}-${RoutePaths.ACCOUNT_STATUS}`,
    title: 'View Account Status',
    routePath: ``,
  },
];
