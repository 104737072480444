import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Logger} from '@atlas/logger';
import {PAGINATION} from '@b2b-frontend/core';
import {PopUpController, PopUpOptions} from '@maia/pop-ups';
import {tap} from 'rxjs/operators';

import {
  B2BAccountsList,
  BLANK_STRING,
  PASSWORD_RESET_SUCCESSFULLY,
  POPUP_SIZE_LARGE,
  UserLanguage,
  UserStatus,
} from '../../../constants/account-management';
import {UserAccountDetails} from '../../../models/b2b/UserAccountDetails';
import {UsersList} from '../../../models/b2b/UsersList';
import {CommonMessage} from '../../../models/CommonMessage';
import {PasswordResetResponse} from '../../../models/PasswordResetResponse';
import {SearchedParameters} from '../../../models/SearchBy';
import {AccountManagementService} from '../../../services/account-management.service';
import {CommonService} from '../../../services/common.service';
import {CommonMessagePopupComponent} from '../common-message-popup/common-message-popup.component';

@UntilDestroy()
@Component({
  selector: 'nje-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public commonMessagePopupFactory: ComponentFactory<CommonMessagePopupComponent>;
  // @ts-ignore
  private _logger: Logger;
  public showAccountDetails: boolean = false;
  public userAccountDetails: UserAccountDetails = B2BAccountsList[0];
  public searchedTerm: string;
  public isDataLoaded: boolean;

  public totalPages: number;
  public currentPageNumber: number;
  public accountsList: never[];

  public constructor(
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    private readonly _commonService: CommonService,
    private readonly _accountManagementService: AccountManagementService,
    private readonly _cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this._logger = this.loggerFactory.createLogger('CommmonMessagePopupComponent');
    this.commonMessagePopupFactory = this.componentFactoryResolver.resolveComponentFactory(
      CommonMessagePopupComponent,
    );
  }

  public searchAccounts(event: SearchedParameters): void {
    this.searchedTerm = event.searchTerm;
    this.isDataLoaded = false;
    let queryParameters;
    queryParameters = event.isUserSearch
      ? `type=${event.searchBy}&userStatus=${UserStatus.ACTIVE}&value=${event.searchTerm}&lang=${UserLanguage.EN}`
      : `type=${event.searchBy}&userStatus=${UserStatus.ACTIVE}&value=${event.searchTerm}&lang=${UserLanguage.EN}&offset=${PAGINATION.PAGES}&limit=${PAGINATION.PAGE_SIZE}`;

    event.isUserSearch
      ? this._userSearch(queryParameters)
      : this._organizationSearch(queryParameters);
  }

  private _userSearch(queryParameters: string): void {
    this._accountManagementService
      .getSearchB2BAccountsToDeactivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this.userAccountDetails = result.users[0];
            if (this.userAccountDetails.id) {
              this.showAccountDetails = true;
            }
          },
          (error: any) => {
            this.isDataLoaded = true;
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.accountsList = [];
            this.showAccountDetails = true;
            this.userAccountDetails = B2BAccountsList[0];

            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  private _organizationSearch(queryParameters: string): void {
    this._accountManagementService
      .getOrganizationSearchB2BAccountsToDeactivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this.userAccountDetails = result.users[0];
            if (this.userAccountDetails.id) {
              this.showAccountDetails = true;
            }
          },
          (error: any) => {
            this.isDataLoaded = true;
            this.currentPageNumber = PAGINATION.DEFAULT_PAGE;
            this.accountsList = [];
            this.showAccountDetails = true;
            this.userAccountDetails = B2BAccountsList[0];
            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  public resetPassword(): void {
    this._accountManagementService
      .resetB2BAccountPassword(this.userAccountDetails.id)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: PasswordResetResponse) => {
            this.openSuccessContainer();
          },
          (error: any) => {},
        ),
      )
      .subscribe();
  }

  public openSuccessContainer(): void {
    const commonMessage: CommonMessage = {
      messageBody: PASSWORD_RESET_SUCCESSFULLY,
    };
    this._commonService.setcommonMessage(commonMessage);

    this.openPopup({title: BLANK_STRING, size: POPUP_SIZE_LARGE});
  }

  public openPopup(options: PopUpOptions): void {
    this._popUpCtrl
      .prepare(this.commonMessagePopupFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: false,
        input: [],
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }
}
