import {Injectable} from '@angular/core';
import {
  PolicyDetailsDto,
  InsurancePartyDto,
  InsurancePartyDetails,
  PropertyAddressDetailsDto,
  PropertyInsuranceProposalDto,
  SupplementaryPartyDetails,
  CoversDiscountsListDto,
  coversDiscountCompleteData,
} from '@b2b-frontend/core';

@Injectable({
  providedIn: 'root',
})
export class PropertySessionService {
  public data: PropertyInsuranceProposalDto;
  public coversData: CoversDiscountsListDto = {};

  public constructor() {
    this.restoreFromGlobalStorage();
  }

  public setInsuringPartyDto(insuringPartyDto: InsurancePartyDto): void {
    this.data.insuringPartyDto = insuringPartyDto;
    this.updateGlobalStorage();
  }

  public setInsuredPartyDetailsList(insuredPartyDetailsList: InsurancePartyDetails[]): void {
    this.data.insuredPartyDetailsList = insuredPartyDetailsList;
    this.updateGlobalStorage();
  }

  public setSupplementaryDto(supplementaryPartyDto: SupplementaryPartyDetails[]): void {
    this.data.supplementaryPartyDetailsList = supplementaryPartyDto;
    this.updateGlobalStorage();
  }

  public setPolicyDetailsDto(policyDetailsDto: PolicyDetailsDto): void {
    this.data.policyDetailsDto = policyDetailsDto;
    this.updateGlobalStorage();
  }

  public setPropertyAddressDetailsDto(
    propertyAddressDetailsDtoList: PropertyAddressDetailsDto[],
  ): void {
    this.data.propertyAddressDetailsDtoList = propertyAddressDetailsDtoList;
    this.updateGlobalStorage();
  }

  public restoreFromGlobalStorage(): PropertyInsuranceProposalDto {
    const propertyJson = sessionStorage.getItem('property');
    if (propertyJson) {
      // ToDo add logic here and change type of data
      this.data = JSON.parse(propertyJson) as PropertyInsuranceProposalDto;
    }
    return this.data;
  }

  public updateGlobalStorage(): void {
    sessionStorage.setItem('property', JSON.stringify(this.data));
  }

  public getPropertyData(): PropertyInsuranceProposalDto {
    return this.restoreFromGlobalStorage();
  }

  public setCoversDiscountDto(coversDiscountsDto?: coversDiscountCompleteData): void {
    this.data.coversDiscountsListDto = coversDiscountsDto!;
    this.updateGlobalStorage();
  }

  public getCoversDiscountData(): CoversDiscountsListDto {
    return this.coversData;
  }
}
