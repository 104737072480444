import {DateKey, DateUtils} from '@atlas/businesstypes';

export const BLANK_VALUE = '';

export const Travel = {
  SINGLE_TRIP: 1,
  MULTI_TRIP: 2,
};

export const CONFIGS = {
  QUERY_LIMIT: 10,
};

export const INSURED_PEOPLE_PAGINATION = {
  MAX_PAGES: 3,
  MAX_PAGES_GENERAL: 11,
};

export const INSURED_PEOPLE_TABLE = {
  DEFAULT_COMPLEX_COLUMNS: 6,
  DEFAULT_COMPLEX_ROWS: 1,
};

export const PAGINATION = {
  PAGE_SIZE: 10,
  DEFAULT_PAGE: 1,
  PAGES: 0,
};

export const B2C_ACTIVATE_ACCOUNT_TABLE = {
  DEFAULT_COMPLEX_COLUMNS: 3,
  DEFAULT_COMPLEX_ROWS: 1,
};

export const B2C_DEACTIVATE_ACCOUNT_TABLE = {
  DEFAULT_COMPLEX_COLUMNS: 3,
  DEFAULT_COMPLEX_ROWS: 1,
};

export const B2B_DEACTIVATE_ACCOUNT_TABLE = {
  DEFAULT_COMPLEX_COLUMNS: 6,
  DEFAULT_COMPLEX_ROWS: 1,
};

export const B2B_ACTIVATE_ACCOUNT_TABLE = {
  DEFAULT_COMPLEX_COLUMNS: 6,
  DEFAULT_COMPLEX_ROWS: 1,
};

export const ROW = 'row';
export const COLUMN = 'column';
export const LOADER = {
  LOADING_MSG: 'Loading',
  LOADER_SIZE_NORMAL: 'normal',
  LOADER_SHOW_CSS: 'app-loader',
};
export const TRUE = true;
export const FALSE = false;

export const RadioDefaults = {
  YES: 'yes',
  NO: 'no',
};

export const MAX_PERSON_YEARS = 150;

export const MIN_BIRTH_DATE = DateUtils.add(DateUtils.today(), DateKey.Year, -MAX_PERSON_YEARS);
export const MAX_BIRTH_DATE = DateUtils.add(DateUtils.today(), DateKey.Day, -1);

export const MIN_BIRTH_DATE_AFTER = DateUtils.add(MIN_BIRTH_DATE, DateKey.Day, -1);
export const MAX_BIRTH_DATE_BEFORE = DateUtils.add(MAX_BIRTH_DATE, DateKey.Day, 1);
