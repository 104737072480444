<form maiaForm [formGroup]="productPortfolioSlideinForm">
  <maia-form-group>
    <maia-label>
      <label
        class="b2b-kdl-font-large title-dark-color slidein-heading"
        i18n="@@nje-b2b-frontend-resource-library-product-title"
      >
        Title
      </label>
    </maia-label>
    <maia-form-element [options]="{validationInstant: true}" class="slidein-input-column">
      <maia-text-area-limit [maxLength]="titleLimit">
        <textarea
          name="title"
          id="title"
          maiaTextArea
          [atlasMaxLength]="titleLimit"
          name="portfolioName"
          type="text"
          i18n-placeholder="@@nje-b2b-frontend-resource-library-product-productportfolioplaceholder"
          placeholder="Enter product portfolio title"
          formControlName="portfolioName"
          class="textarea-style"
        >
        </textarea>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-library-product-producttitlereq"
          *ngIf="
            productPortfolioSlideinForm.controls['portfolioName'].touched &&
            productPortfolioSlideinForm.controls['portfolioName'].hasError('required')
          "
        >
          Title required
        </maia-validation>
      </maia-text-area-limit>
    </maia-form-element>
    <maia-label>
      <label
        class="b2b-kdl-font-large title-dark-color slidein-heading"
        i18n="@@nje-b2b-frontend-resource-library-product-desc"
      >
        Description
      </label>
    </maia-label>
    <maia-form-element [options]="{validationInstant: true}" class="slidein-input-column">
      <maia-text-area-limit [maxLength]="titleLimit">
        <textarea
          name="title"
          id="title"
          maiaTextArea
          [atlasMaxLength]="titleLimit"
          name="portfolioSubtitle"
          type="text"
          i18n-placeholder="
            @@nje-b2b-frontend-resource-library-product-productportfoliodescriptionplaceholder"
          placeholder="Enter product portfolio description"
          formControlName="portfolioSubtitle"
          class="textarea-style"
        >
        </textarea>
        <maia-validation
          i18n="@@nje-b2b-frontend-resource-library-product-descreq"
          *ngIf="
            productPortfolioSlideinForm.controls['portfolioSubtitle'].touched &&
            productPortfolioSlideinForm.controls['portfolioSubtitle'].hasError('required')
          "
        >
          Description required
        </maia-validation>
      </maia-text-area-limit>
    </maia-form-element>
  </maia-form-group>
</form>
<div class="step-form-button-bar">
  <maia-button-group *ngIf="isNewProductPortfolio()">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!productPortfolioSlideinForm.valid"
      (click)="addOrUpdateProductPortfolio()"
      i18n="@@nje-b2b-frontend-resource-library-product-save"
      >Save</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-product-cancel"
      >Cancel</button
    >
  </maia-button-group>

  <maia-button-group *ngIf="!isNewProductPortfolio()">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!productPortfolioSlideinForm.valid"
      (click)="addOrUpdateProductPortfolio()"
      i18n="@@nje-b2b-frontend-resource-library-product-add"
      >Add</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-product-cancel"
      >Cancel</button
    >
  </maia-button-group>
</div>
