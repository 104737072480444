/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {Injectable} from '@angular/core';
import {
  B2BPaymentsData,
  SearchPolicyScreenStep,
  InstalmentsScreenStep,
  InsuredPartyScreenStep,
} from '../../contracts/instalment-payment/instalment';

@Injectable({
  providedIn: 'root',
})
export class PaymentsSessionService {
  private _data: B2BPaymentsData = {};
  private readonly _sessionKey: string = 'payments';

  public constructor() {
    this.restoreFromGlobalStorage();
  }

  public restoreFromGlobalStorage(): void {
    const paymentsJson = sessionStorage.getItem(this._sessionKey);
    if (paymentsJson) {
      const serializedData = JSON.parse(paymentsJson) as B2BPaymentsData;
      this._data = serializedData;
    }
  }

  public updateGlobalStorage(): void {
    sessionStorage.setItem(this._sessionKey, JSON.stringify(this._data));
  }

  public setSearchPolicyScreenData(data: SearchPolicyScreenStep): void {
    this._data.searchPolicyStep = data;
    this.updateGlobalStorage();
  }

  public getData(): B2BPaymentsData | null {
    const jsonData = sessionStorage.getItem(this._sessionKey);
    if (jsonData !== null) {
      this._data = JSON.parse(jsonData);
      return this._data;
    }
    return null;
  }

  public setInsuredPartyScreenData(data: InsuredPartyScreenStep): void {
    this._data.insuredPartyStep = data;
    this.updateGlobalStorage();
  }

  public setInstalmentsScreenData(data: InstalmentsScreenStep): void {
    this._data.instalmentsStep = data;
    this.updateGlobalStorage();
  }
}
