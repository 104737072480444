export enum RoutePaths {
  InstalmentPayment = 'instalment-payment',
  Instalment = 'instalment',
  InsuredParty = 'insured-party',
  Overview = 'overview',
  Payment = 'payment',
  PaymentResult = 'payment-result',
  SearchPolicy = 'search-policy',
  PaymentDebitNote = 'payment-debit-note',
  DirectDebitDetails = 'direct-debit-details',
  Payments = 'payments',
  DebitPaymentSuccess = 'debit-payment-success',
}
