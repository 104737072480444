import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationIndiVehicleLicensePlateSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationIndiVehicleLicensePlate]',
  template: '<svg:path d="M15.38 41.5h85.25a3.87 3.87 0 0 1 3.87 3.87h0v25.31a3.88 3.88 0 0 1-3.88 3.88H15.38a3.88 3.88 0 0 1-3.88-3.88V45.37a3.87 3.87 0 0 1 3.87-3.87z" fill="none" stroke-linecap="round" stroke-width="2.6"></svg:path><svg:path d="M34 52.39h.25l1.21 6.3h-2.65zm-.72-1l-2.65 11.9h1.21l.73-3.39h3.15l.73 3.39h1.21L35 51.43zm6.54 11.86h2.42c2.42 0 3.63-1 3.63-3.15A2.9 2.9 0 0 0 44 57a2.19 2.19 0 0 0 1.72-2.56v-.11a2.43 2.43 0 0 0-1.89-2.86 2.35 2.35 0 0 0-.53 0h-3.46zM41 57.68h1.21c1.69 0 2.42.73 2.42 2.42s-.73 2.18-2.42 2.18h-1.16zm0-5.33h1.21c1.45 0 2.18.73 2.18 1.94s-.73 2.18-2.67 2.18H41zM55 55c-.24-2.67-1.45-3.88-3.14-3.88s-3.4 1.7-3.4 6.3 1.22 6.05 3.4 6.05S55 62 55 59.38h-1.12c0 2.18-.72 2.9-1.93 2.9s-2.18-1.21-2.18-4.84.72-5.09 2.18-5.09 1.69.73 1.69 2.67zm6.54 4.6v-1.15h-4v1.21zm7.27 3.63v-1h-1.36V51.18h-.73l-1.94 1v.73h1.7v9.44H65v1zm8.72 0v-1h-4.3c1.69-4.11 4.36-5.08 4.36-8s-1-3.15-2.67-3.15-2.66 1-2.66 3.64h1c.24-1.94.72-2.67 1.69-2.67s1.46.73 1.46 1.94-3.15 4.84-4.36 8.23v1zm3.87-9.44a1.71 1.71 0 0 1 1.6-1.64c1 0 1.45.73 1.45 2.18 0 1.7-.73 2.18-2.42 2.18v1c1.69 0 2.66.48 2.66 2.66S84 62.6 83 62.6s-1.46-.73-1.7-2.18h-1a2.66 2.66 0 0 0 2.13 3.11H83c1.45 0 2.9-1.21 2.9-3.63a3.12 3.12 0 0 0-2-2.9c1-.25 1.7-1.22 1.7-2.91a2.92 2.92 0 0 0-2.42-2.91H83a2.43 2.43 0 0 0-2.43 2.43h1z"></svg:path><svg:circle cx="17.55" cy="47.55" r="1.21"></svg:circle><svg:circle cx="17.55" cy="68.38" r="1.21"></svg:circle><svg:circle cx="98.45" cy="47.55" r="1.21"></svg:circle><svg:circle cx="98.45" cy="68.38" r="1.21"></svg:circle>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 116 116',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationIndiVehicleLicensePlateSvgComponent {
}

@NgModule({
  declarations: [
    TransportationIndiVehicleLicensePlateSvgComponent,
  ],
  exports: [
    TransportationIndiVehicleLicensePlateSvgComponent,
  ],
})
export class TransportationIndiVehicleLicensePlateSvgModule {}
