import {TravelRoutePaths} from '@b2b-frontend/core';
import {IStepperProcessStep} from '@njf-frontend-angular/flow-progress';

export const steps: IStepperProcessStep[] = [
  {
    id: TravelRoutePaths.TI_TRIP_DETAILS,
    title: 'Trip Details',
    routePath: `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRIP_DETAILS}`,
  },
  {
    id: TravelRoutePaths.TI_TRAVEL_COVERS,
    title: 'Covers',
    routePath: `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRAVEL_COVERS}`,
  },
  {
    id: TravelRoutePaths.TI_ADD_ONS,
    title: 'Add Ons & Discounts',
    routePath: `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_ADD_ONS}`,
  },
  {
    id: TravelRoutePaths.TI_INSURING_PARTY,
    title: 'Insuring Party',
    routePath: `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_INSURING_PARTY}`,
  },
  {
    id: TravelRoutePaths.TI_INSURED_PEOPLE,
    title: 'Insured People',
    routePath: `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_INSURED_PEOPLE}`,
  },
  {
    id: TravelRoutePaths.TI_TRAVEL_PROPOSAL,
    title: 'Proposal',
    routePath: `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRAVEL_PROPOSAL}`,
  },
];
