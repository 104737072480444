import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Boolean, Decimal} from '@atlas/businesstypes';
import {
  AddonsDiscountsDto,
  CoversStep,
  TravelRoutePaths,
  ProposalDto,
  EmitAddOnData,
} from '@b2b-frontend/core';
import {TileHeight, TileWidth} from '@maia/tiles';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';

import {TravelNavigationService} from '../../travel-core/services/travel-navigation.service';
import {TravelSessionService} from '../../travel-core/services/travel-session.service';
import {
  MOCK_ADDONS_AND_DISCOUNT_SCREEN,
  MOCK_COVER_AMOUNT,
  MOCK_COVERS_ADDONS,
  MOCK_COVERS_DISCOUNTS,
  MOCK_ITEMS,
  MOCK_PREMIUM_PRICE,
} from './__tests__/travel-addons.mocks';
import {TravelPolicyService} from '../../travel-core/services/travel-policy.service';
import {MinMaxValue} from '../../constants/constant';
import {TravelSlideInService} from '../../travel-core/services/travel-slide-in.service';
import {ModalConfirmedResult} from '@maia/modals';

export interface CoverAmount {
  name: string;
  value: string;
}
export interface Amount {
  value: Decimal;
  currency: Text | string;
}
export interface Item {
  name: string;
  value: string;
}

export const COVER_TITLE = {
  THIRD_PARTY_LIABILITY: 'THIRD PARTY LIABILITY',
  LEGAL_ASSISTANCE: 'LEGAL ASSISTANCE',
  ACCIDENT: 'ACCIDENT',
  GROUP_DISCOUNT: 'FOR A GROUP OF 10 TO 20 PERSONS',
};

@Component({
  selector: 'travel-addons',
  templateUrl: './travel-addons.component.html',
  styleUrls: ['./travel-addons.component.scss'],
})
export class TravelAddonsComponent extends ScreenStepComponent implements OnInit {
  public additionalCoversSelected: Boolean[] = [];
  public discountTileSelcted: Boolean[] = [];
  public currentWidth2: TileWidth = 'large';
  public currentHeight2: TileHeight = 'auto';
  public noOfPeople = MOCK_ADDONS_AND_DISCOUNT_SCREEN.NUMBER_OF_PEOPLE; //change this at later stage
  public noOfDays = MOCK_ADDONS_AND_DISCOUNT_SCREEN.NUMBER_OF_DAYS; //change this at later stage
  public additionalCovers: CoversStep[];
  public discounts: CoversStep[];
  public showAccidentAmount = Boolean.FALSE;
  public showLegalAssistanceAmount = Boolean.FALSE;
  public showThirdPartyLiabilityAmount = Boolean.FALSE;
  public showGroupDiscountManualEnterBox = false;
  public masterSelected = false;
  public items: Item[] = MOCK_ITEMS;
  public insurancePremiumAmount: Amount = MOCK_PREMIUM_PRICE;
  public title = 'Covers Insurance';
  public addonDiscountDto: AddonsDiscountsDto;
  public valueCoverAmountAccident: string;
  public valueCoverAmountLegalAssitance: string;
  public valueCoverAmountTPL: string;
  public coverAmountAccident = MOCK_COVER_AMOUNT;
  public coverAmountLegalAssitance = MOCK_COVER_AMOUNT;
  public coverAmountThirdPartyLiability = MOCK_COVER_AMOUNT;
  public travelAddonsList: CoversStep[] = [...MOCK_COVERS_ADDONS];
  public showAddonDiv: boolean = true;
  public showDiscountDiv: boolean = true;
  public inEditMode: boolean = true;
  public premiumButton: boolean = true;
  public enableBut: boolean = true;
  public minValue: number = MinMaxValue.min;
  public maxValue: number = MinMaxValue.max;
  public showDiscount: boolean;

  @Output() public emitSelectedAddons = new EventEmitter<EmitAddOnData>();

  public constructor(
    private readonly _travelNavigationService: TravelNavigationService,
    route: ActivatedRoute,
    public travelSessionService: TravelSessionService,
    private readonly _travelPolicyService: TravelPolicyService,
    private readonly _travelSlideInService: TravelSlideInService,
  ) {
    super(route);
    this.additionalCovers = MOCK_COVERS_ADDONS;
    this.discounts = MOCK_COVERS_DISCOUNTS;
    this.additionalCoversSelected = Array<Boolean>(this.additionalCovers.length).fill(
      Boolean.FALSE,
    );
    this.discountTileSelcted = Array<Boolean>(this.discounts.length).fill(Boolean.FALSE);
  }

  public ngOnInit(): void {
    this.enableBut = false;
    this.premiumButton = true;
    const data: ProposalDto | undefined = this.travelSessionService.getTravelData();
    if (data.addonDiscountDto) {
      this.addonDiscountDto = data.addonDiscountDto;
      this.showAddonDiv = this.addonDiscountDto.isEditAddon;
      this.inEditMode = this.addonDiscountDto.isEditAddon;
      this.showDiscountDiv = this.addonDiscountDto.isEditDiscount;
      this.prePopulateAddons();
      this.prePopulateDiscounts();
    }
    this.saveDiscountData();
  }

  public prePopulateDiscounts(): void {
    const selectedDiscountsList: CoversStep[] | undefined = this.addonDiscountDto.discounts;
    if (selectedDiscountsList && selectedDiscountsList.length > 0) {
      selectedDiscountsList.forEach(element => {
        const index = this.discounts.findIndex((aCover: CoversStep) => aCover.id === element.id);
        if (index > -1) {
          this.discountTileSelcted[index] = Boolean.TRUE;
          this.changeDiscountSelection(index, element);
        }
      });
    }
  }

  public prePopulateAddons(): void {
    const selectedAddOnsList: CoversStep[] | undefined = this.addonDiscountDto.addons;
    if (selectedAddOnsList && selectedAddOnsList.length > 0) {
      selectedAddOnsList.forEach(element => {
        const index = this.additionalCovers.findIndex(
          (aCover: CoversStep) => aCover.id === element.id,
        );
        if (index > -1) {
          this.additionalCoversSelected[index] = Boolean.TRUE;
          this.changeAddonsSelection(index, element);
        }
      });
    }
  }

  public checkUncheckAllAddOns(checkEvent: Boolean): void {
    this.additionalCoversSelected = Array(this.additionalCovers.length).fill(checkEvent);
    this._showHideCoverAmountBoxes(checkEvent);
    this.emitAddOnsData();
  }

  public navigateTo(): void {
    this._travelNavigationService.nextScreen(TravelRoutePaths.TI_INSURING_PARTY);
  }

  public changeAddonsSelection(i: number, cover: CoversStep): void {
    //check if every cover is selected or not
    this.masterSelected = this.additionalCoversSelected.every(e => Boolean.TRUE.equals(e));
    if (cover.title.toUpperCase() === COVER_TITLE.THIRD_PARTY_LIABILITY) {
      this.showThirdPartyLiabilityAmount = this.additionalCoversSelected[i];
      this.additionalCovers[i].showCoverageAmountBox = this.additionalCoversSelected[i];
    } else if (cover.title.toUpperCase() === COVER_TITLE.LEGAL_ASSISTANCE) {
      this.showLegalAssistanceAmount = this.additionalCoversSelected[i];
      this.additionalCovers[i].showCoverageAmountBox = this.additionalCoversSelected[i];
    } else if (cover.title.toUpperCase() === COVER_TITLE.ACCIDENT) {
      this.showAccidentAmount = this.additionalCoversSelected[i];
      this.additionalCovers[i].showCoverageAmountBox = this.additionalCoversSelected[i];
    }
    this.emitAddOnsData();

    this.premiumButton = false;
    this.enableBut = true;
    this._travelPolicyService.showPriceAccordion(false);
  }

  public emitAddOnsData() {
    if (this.inEditMode) {
      this.emitSelectedAddons.emit({
        addons: this.additionalCovers,
        status: this.additionalCoversSelected,
      });
    }
  }

  public changeDiscountSelection(i: number, cover: CoversStep): void {
    if (cover.title.toUpperCase() === COVER_TITLE.GROUP_DISCOUNT) {
      this.showGroupDiscountManualEnterBox = !this.showGroupDiscountManualEnterBox;
      this.discounts[i].showCoverageAmountBox = new Boolean(this.showGroupDiscountManualEnterBox);
    }
    this.premiumButton = false;
    this.enableBut = true;
    this._travelPolicyService.showPriceAccordion(false);
  }

  public convertAmount(cover: CoversStep): Decimal {
    const price = MOCK_ADDONS_AND_DISCOUNT_SCREEN.MOCK_PRICE;
    return new Decimal(price + '');
  }

  private _showHideCoverAmountBoxes(checkEvent: Boolean): void {
    this.showAccidentAmount = checkEvent;
    this.showLegalAssistanceAmount = checkEvent;
    this.showThirdPartyLiabilityAmount = checkEvent;
  }

  public getQuote(): void {
    this.saveMtplData();
    this._travelSlideInService.openAddonsSlideIn().subscribe((res: ModalConfirmedResult<void>) => {
      // logic
    });
  }

  public showAccordian(): void {
    this.enableBut = false;
    this.premiumButton = true;
    this._travelPolicyService.showPriceAccordion(true);
  }

  protected saveMtplData(): void {
    const selecetedAddons: CoversStep[] = [];
    const selecetedDiscouts: CoversStep[] = [];
    this.additionalCoversSelected.forEach((element: Boolean, index: number) => {
      element.equals(Boolean.TRUE) ? selecetedAddons.push(this.additionalCovers[index]) : undefined;
    });
    this.discountTileSelcted.forEach((element: Boolean, index: number) => {
      if (element.equals(Boolean.TRUE)) {
        selecetedDiscouts.push(this.discounts[index]);
      }
    });
    const addonsDiscountData: AddonsDiscountsDto = {
      addons: selecetedAddons,
      discounts: selecetedDiscouts,
      isEditAddon: false,
      isEditDiscount: false,
    };
    this.travelSessionService.setAddonsAndDiscountData(addonsDiscountData);
  }

  public saveDiscountData(): void {
    const data: ProposalDto = this.travelSessionService.data;
    if (data.addonDiscountDto?.isEditDiscount) {
      this.showDiscount = data.addonDiscountDto.isEditDiscount === true ? true : false;
    }
  }
}
