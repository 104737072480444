import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule NavigationFuncBriefcaseSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendNavigationFuncBriefcase]',
  template: '<svg:path d="M9.23 6.68V5a1.12 1.12 0 0 1 1.12-1.12h3.3A1.12 1.12 0 0 1 14.77 5v1.68" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M3.28 18.85V8.12A1.42 1.42 0 0 1 4.7 6.71h14.6a1.42 1.42 0 0 1 1.42 1.41v10.73a1.32 1.32 0 0 1-1.32 1.32H4.6a1.32 1.32 0 0 1-1.32-1.32z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:rect x="10.5" y="12.25" width="3" height="2" rx=".66" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:rect><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M3.28 13.25H10"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M14 13.25h6.72"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncBriefcaseSvgComponent {
}

@NgModule({
  declarations: [
    NavigationFuncBriefcaseSvgComponent,
  ],
  exports: [
    NavigationFuncBriefcaseSvgComponent,
  ],
})
export class NavigationFuncBriefcaseSvgModule {}
