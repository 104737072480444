import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprStarListSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprStarList]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M42.24 40.9H28"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M42.24 35.57H28"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M42.24 30.23H28"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M22.66 35.61l-10.1 5.31 2.1-12.24L5.76 20l12.3-1.78 5.49-11.14 5.5 11.14L41.35 20l-5.34 5.21"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprStarListSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprStarListSvgComponent,
  ],
  exports: [
    InformationExprStarListSvgComponent,
  ],
})
export class InformationExprStarListSvgModule {}
