import {Injectable} from '@angular/core';
import {Config, LocaleConfig} from '../constants/localization-config';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private readonly _localeConfigs: LocaleConfig;

  public get localeConfigs(): LocaleConfig {
    return this._localeConfigs;
  }

  public constructor() {
    this._localeConfigs = Config;
  }
}
