import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DateKey, DateUtils, EmailAddress, ExtraDateValidators} from '@atlas/businesstypes';
import {DatepickerVisibleView} from '@maia/datepickers';
import {
  ScreenFormComponent,
  FormGroupManagerFactoryService,
  PhoneNumberValueController,
  CityAndPostCodeController,
  DatePickerController,
  SHOW_ERROR_CHECK,
} from '@njf-frontend-angular/flow-progress';
import {map} from 'rxjs/operators';

import {PartyType} from '../../core-helper/constants/PartyType';
import {
  EgnLnchData,
  InsuringPartyDto,
} from '../../core-helper/dto/travel-dto/summary-dto/InsuringPartyDto';
import {personName} from '../../core-helper/validator/insureparty-type/party/person-name.validator';
import {bgPhoneNumberValidator} from '../../core-helper/validator/phone/bg-phone-number.validator';
import {CityDto} from '../../form/model/dto/CityDto';
import {NationalityDto} from '../../form/model/dto/NationalityDto';
import {CoreApiService} from '../../services/core-api.service';
import {NameAddressTranslationMapService} from '../../services/name-address-translation-map.service';

import {FormData} from './form-data.model';
import {CityApiResponse} from '../../core-helper/dto/travel-dto/CityApiResponse';
import {InputPhonenumberComponent, PhoneNumberValue} from '@maia/input-phonenumber';
import {RadioDefaults} from './_tests_/egn-lnch-layout-mock';
import {dateValidator} from '../../core-helper/validator/date.validator';
import {ServerSideAutocompleteController} from '@njf-frontend-angular/flow-progress/types/form-manage/component/controller/ServerSideAutocompleteController';
import {ApiPersonalData} from '../../core-helper/dto/property-insurance-dto/summary-dto/insurance-party-details-api-dto';
import {
  BLANK_VALUE,
  MAX_BIRTH_DATE,
  MAX_BIRTH_DATE_BEFORE,
  MIN_BIRTH_DATE,
  MIN_BIRTH_DATE_AFTER,
} from '../../core-helper/constants/Config';

@Component({
  selector: 'egn-lnch-layout',
  templateUrl: './egn-lnch-layout.component.html',
  styleUrls: ['./egn-lnch-layout.component.scss'],
})
export class EgnLnchLayoutComponent extends ScreenFormComponent implements OnInit {
  public iPartyType: PartyType;
  public isLNCH: boolean = false;
  public isEGN: boolean = false;

  public minBirthDate = MIN_BIRTH_DATE;
  public maxBirthDate = MAX_BIRTH_DATE;

  public cpcController: CityAndPostCodeController;
  public cityAutocomplete: ServerSideAutocompleteController<CityDto>;
  public cityAutocompleteEn: ServerSideAutocompleteController<CityDto>;

  public citizenshipAutocomplete: ServerSideAutocompleteController<NationalityDto>;

  private phoneNumberController: PhoneNumberValueController;
  public birthDatePicker: DatePickerController;

  @ViewChild('phoneInput', {static: false})
  protected set phoneInput(val: InputPhonenumberComponent) {
    if (val && !this.phoneNumberController) {
      setTimeout(
        () =>
          (this.phoneNumberController = this.stepForm.createPhoneNumberValue('phoneNumber', val, {
            disableCountryCode: true,
          })),
      );
    }
  }

  public visibleDateView?: DatepickerVisibleView = undefined;
  public disableWeekends: boolean = false;

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Year, -150);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, -1);

  public isInsuringPartyIncluded: boolean = false;

  @Output()
  public readonly saveEgnData: EventEmitter<EgnLnchData> = new EventEmitter<EgnLnchData>();

  @Input()
  public set insuringPartyType(insuringPartyType: PartyType) {
    this.iPartyType = insuringPartyType;
    this.isLNCH = this.iPartyType === PartyType.FOREIGNER ? true : false;
    this.isEGN = this.iPartyType === PartyType.PERSON ? true : false;
  }

  @Input() public insuringPartyDto: InsuringPartyDto;

  @Input()
  public set userData(userData: ApiPersonalData | undefined) {
    this.stepForm.reset();
    if (userData != null && userData != undefined) {
      this.populatePersonDataDetailFromApi(userData);
    } else {
      this.parseAndPrePopulateData();
    }
  }

  constructor(
    private readonly _nameAddressTrasMapService: NameAddressTranslationMapService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _coreApiService: CoreApiService,
    route: ActivatedRoute,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    super(route);

    this.stepForm = this._formFactory.createFormManager<FormData>({
      firstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      middleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      lastName: new FormControl(undefined, [Validators.required, personName('bg')]),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
      quarter: new FormControl(undefined, [Validators.required, personName('bg')]),
      street: new FormControl(undefined, [Validators.required, personName('bg')]),
      streetNumber: new FormControl(undefined, [Validators.required, personName('bg')]),
      block: new FormControl(undefined, [personName('bg')]),
      entrance: new FormControl(undefined, [personName('bg')]),
      floor: new FormControl(undefined, [personName('bg')]),
      apartment: new FormControl(undefined, [personName('bg')]),

      firstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      middleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      lastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      cityEn: new FormControl(undefined, [Validators.required]),
      postCodeEn: new FormControl(undefined, [Validators.required]),
      quarterEn: new FormControl(undefined, [Validators.required, personName('en')]),
      streetEn: new FormControl(undefined, [Validators.required, personName('en')]),
      streetNumberEn: new FormControl(undefined, [Validators.required, personName('en')]),
      blockEn: new FormControl(undefined, [personName('en')]),
      entranceEn: new FormControl(undefined, [personName('en')]),
      floorEn: new FormControl(undefined, [personName('en')]),
      apartmentEn: new FormControl(undefined, [personName('en')]),

      citizenship: new FormControl(undefined, [Validators.required]),
      dob: new FormControl(undefined, [
        Validators.required,
        dateValidator(),
        control => ExtraDateValidators.before(MAX_BIRTH_DATE_BEFORE)(control.value),
        control => ExtraDateValidators.after(MIN_BIRTH_DATE_AFTER)(control.value),
      ]),
      phoneNumber: new FormControl(undefined, [
        Validators.required,
        bgPhoneNumberValidator({allowZero: true}),
      ]),
      emailId: new FormControl(undefined, [Validators.required]),
      insuringPartyRadio: new FormControl(RadioDefaults.YES),
    });

    this.cpcController = this.stepForm.createCityAndPostCode('city', 'postCode');
    this.cityAutocomplete = this.stepForm.createAutocompleteWithServersideFiltering<CityDto>(
      'city',
      (q: string) =>
        this._coreApiService
          .getPlaceOfResidence(q, true)
          .pipe(map((res: CityApiResponse) => res.data)),
    );

    this.cityAutocompleteEn = this.stepForm.createAutocompleteWithServersideFiltering<CityDto>(
      'cityEn',
      (query: string) =>
        this._coreApiService
          .getPlaceOfResidence(query, true)
          .pipe(map((res: CityApiResponse) => res.data)),
    );

    this.citizenshipAutocomplete =
      this.stepForm.createAutocompleteWithServersideFiltering<NationalityDto>(
        'citizenship',
        (q: string) => this._coreApiService.getNationalities(q, true).pipe(map(res => res.data)),
      );

    this.birthDatePicker = this.stepForm.createDatePicker('dob');
    this.stepForm.setControlShowErrorCheck('phoneNumber', SHOW_ERROR_CHECK.TOUCHED);
  }

  public ngOnInit(): void {
    this.parseAndPrePopulateData();
    this._nameAddressTrasMapService.updateNameFieldTranslation(this.stepForm.formGroup);
    this._nameAddressTrasMapService.updateAddressFieldTraslation(this.stepForm.formGroup);
  }

  public parseAndPrePopulateData(): void {
    if (
      this.insuringPartyDto != undefined &&
      this.insuringPartyDto != null &&
      this.insuringPartyDto.insuringPartyEgnData
    ) {
      const egnData: EgnLnchData = this.insuringPartyDto.insuringPartyEgnData;
      const phone: PhoneNumberValue = {
        prefix: egnData.phoneNumber.prefix,
        number: egnData.phoneNumber.number,
      };

      this.stepForm.patchValue({
        ...egnData,
        emailId: egnData.emailId ? new EmailAddress(egnData.emailId) : null,
        phoneNumber: phone ? phone : BLANK_VALUE,
      });
    } else {
      this.stepForm.patchValue({
        insuringPartyRadio: RadioDefaults.YES,
      });
    }
  }

  public populatePersonDataDetailFromApi(res: ApiPersonalData): void {
    const phone: PhoneNumberValue = {
      prefix: this.phoneNumberController.value.prefix,
      number: res.personalData.addresses[0].phoneNumber,
    };
    this.stepForm.patchValue({
      firstNameEn: res.personalData.firstName,
      middleNameEn: res.personalData.middleName,
      lastNameEn: res.personalData.surname,
      postCodeEn: res.personalData.addresses[0].zip,
      blockEn: res.personalData.addresses[0].block,
      entranceEn: res.personalData.addresses[0].entrance,
      floorEn: res.personalData.addresses[0].floor,
      apartmentEn: res.personalData.addresses[0].apartment,
      streetEn: res.personalData.addresses[0].street,
      streetNumberEn: res.personalData.addresses[0].streetNo,
      quarterEn: res.personalData.addresses[0].quarter,
      emailId: res.personalData.addresses[0].email,
      phoneNumber: phone,
    });
    this._cdRef.detectChanges();
  }

  public save(): void {
    const val = this.stepForm.value;
    const egnLnchUserData: EgnLnchData = {
      ...val,
      isInsuringPartyIncluded: this.isInsuringPartyIncluded,
      phoneNumber: this.phoneNumberController.value,
      emailId: val.emailId instanceof EmailAddress ? val.emailId.asString() : val.emailId,
    };

    this.saveEgnData.emit(egnLnchUserData);
  }

  protected saveMtplData(): void {
    this.save();
  }

  public getCityTitle(cityObj: CityDto): string {
    return cityObj != null && cityObj.title != null ? cityObj.title : '';
  }

  public getCitizenshipTitle(citizen: NationalityDto): string {
    return citizen != null && citizen.title != null ? citizen.title : '';
  }

  public getCitizenshipId(citizen: NationalityDto): number {
    return citizen != null && citizen.id != null ? citizen.id : -1;
  }
}
