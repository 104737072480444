<form maiaForm [formGroup]="insuringPartyForm.formGroup">
  <maia-fluid-fluid-layout alignContentTop>
    <div class="fluid m-r-16">
      <maia-label class="b2b-kdl-font-small title-dark-color"> EGN / LNCH / EIK </maia-label>
      <maia-search-bar
        class="search"
        [placeholder]="'EGN / LNCH / EIK'"
        (inputText)="handleInsuringParty($event)"
        (searchText)="handleSearchedText($event)"
      >
      </maia-search-bar>
      <maia-form-element
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="input-column"
      >
        <maia-validation *ngIf="personNotFound" class="error-message">
          Person is not retrieved by PID / EIK. You can proceed with manual data entry.
        </maia-validation>
      </maia-form-element>
    </div>
    <div class="fluid m-r-16"></div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout alignContentTop>
    <div class="fluid m-r-16">
      <maia-form-element
        *ngIf="isInsuringPartyEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Company Name"
      >
        <input maiaInput type="text" placeholder="Company Name" formControlName="companyName" />
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyName') &&
            insuringPartyForm.hasError('companyName', 'required')
          "
        >
          Company name required
        </maia-validation>
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyName') &&
            insuringPartyForm.hasError('companyName', 'nonLatinLetters')
          "
          >Company name - only cyrillic letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyName') &&
            insuringPartyForm.hasError('companyName', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyName') &&
            insuringPartyForm.hasError('companyName', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ insuringPartyForm.controls['companyName'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
      <maia-form-element
        *ngIf="!isInsuringPartyEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Full Name"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('firstName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('firstName')
            "
          >
            <input maiaInput type="text" placeholder="First Name" formControlName="firstName" />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('firstName')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstName', 'required')"
                >First name required</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstName', 'nonLatinLetters')"
                >First name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstName', 'nonBlank')"
                >Invalid first name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstName', 'maxlength')"
                >First name is too long (maximum is
                {{ insuringPartyForm.controls['firstName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('middleName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('middleName')
            "
          >
            <input maiaInput type="text" placeholder="Middle Name" formControlName="middleName" />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('middleName')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleName', 'required')"
                >Middle name required</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleName', 'nonLatinLetters')"
                >Middle name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleName', 'nonBlank')"
                >Invalid middle name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleName', 'maxlength')"
                >Middle name is too long (maximum is
                {{ insuringPartyForm.controls['middleName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('lastName')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('lastName')
            "
          >
            <input maiaInput type="text" placeholder="Surname" formControlName="lastName" />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('lastName')">
              <maia-validation *ngIf="insuringPartyForm.hasError('lastName', 'required')"
                >Last name required</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastName', 'nonLatinLetters')"
                >Last name - only cyrillic letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastName', 'nonBlank')"
                >Invalid last name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastName', 'maxlength')"
                >Last name is too long (maximum is
                {{ insuringPartyForm.controls['lastName'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row city-and-postcode"
        label="City & Zip"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="city"
            formControlName="city"
            ngDefaultControl
            (typeAhead)="cityAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="City" />
            <maia-option *ngFor="let cityObj of cityAutocomplete.list | async" [value]="cityObj">
              <maia-option-highlight [label]="cityObj.i18n.bg.name"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('city')">
            <maia-validation *ngIf="insuringPartyForm.hasError('city', 'required')"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input
              maiaInput
              type="text"
              class="zip-style"
              placeholder="Zip"
              formControlName="postCode"
              readonly
            />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('postCode')">
              <maia-validation *ngIf="insuringPartyForm.hasError('postCode', 'required')"
                >Post Code required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Quarter" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('quarter')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('quarter')
            "
          >
            <input maiaInput type="text" placeholder="Quarter" formControlName="quarter" />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('quarter')">
              <maia-validation *ngIf="insuringPartyForm.hasError('quarter', 'required')"
                >Quarter is required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row city-and-postcode" label="Street & Number" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('street')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('street')
            "
          >
            <input maiaInput type="text" placeholder="Street" formControlName="street" />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('street')">
              <maia-validation *ngIf="insuringPartyForm.hasError('street', 'required')"
                >Street required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('streetNumber')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('streetNumber')
            "
          >
            <input maiaInput type="text" placeholder="Number" formControlName="streetNumber" />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('streetNumber')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('streetNumber', 'required')"
                >Street number required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Block, Entrance, Floor & Apartment" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="insuringPartyForm.hasToShowErrors('block')"
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('block')
            "
          >
            <input maiaInput type="text" name="block" placeholder="Block" formControlName="block" />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('block')">
              <maia-validation *ngIf="insuringPartyForm.hasError('block', 'required')"
                >Block required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('entrance')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('entrance')
            "
          >
            <input
              maiaInput
              type="text"
              name="entrance"
              placeholder="Ent.."
              formControlName="entrance"
            />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('entrance')">
              <maia-validation *ngIf="insuringPartyForm.hasError('entrance', 'required')"
                >Entrance required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="insuringPartyForm.hasToShowErrors('floor')"
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('floor')
            "
          >
            <input maiaInput type="text" name="floor" placeholder="Floor" formControlName="floor" />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('floor')">
              <maia-validation *ngIf="insuringPartyForm.hasError('floor', 'required')"
                >Floor required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('apartment')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('apartment')
            "
          >
            <input
              maiaInput
              type="text"
              name="apartment"
              placeholder="Apart.."
              formControlName="apartment"
            />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('apartment')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('apartment', 'required')"
                >Apartment required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        *ngIf="isInsuringPartyLNCH"
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Citizenship"
      >
        <maia-input-autocomplete
          name="citizenship"
          formControlName="citizenship"
          (typeAhead)="citizenshipAutocomplete.filterTypeahead($event)"
        >
          <input autocomplete="dzi-off" maiaInput placeholder="Select" />

          <maia-option *ngFor="let c of citizenshipAutocomplete.list | async" [value]="c.id">
            <maia-option-highlight [label]="c.i18n.en.title"></maia-option-highlight>
          </maia-option>
        </maia-input-autocomplete>

        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('citizenship') &&
            insuringPartyForm.hasError('citizenship', 'required')
          "
        >
          Citizenship required
        </maia-validation>
      </maia-form-element>
      <nje-input-phone-number
        [retrievedPhone]="retrievedPersonDetails"
        [retrievedCompanyPhone]="retrievedCompanyDetails"
        [inputPhoneNumber]="phone"
        (selectPhoneNumber)="selectedPhoneNumber($event)"
      ></nje-input-phone-number> </div
    ><div class="fluid">
      <maia-form-element
        *ngIf="isInsuringPartyEIK"
        [options]="{validationInstant: true}"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Company Name with latin letters"
      >
        <input maiaInput placeholder="Company Name" formControlName="companyNameEn" />
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyNameEn') &&
            insuringPartyForm.hasError('companyNameEn', 'required')
          "
        >
          Company name in latin required
        </maia-validation>
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyNameEn') &&
            insuringPartyForm.hasError('companyNameEn', 'nonCyrillicLetters')
          "
          >Company name - only latin letters allowed</maia-validation
        >
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyNameEn') &&
            insuringPartyForm.hasError('companyNameEn', 'nonBlank')
          "
          >Invalid company name</maia-validation
        >
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('companyNameEn') &&
            insuringPartyForm.hasError('companyNameEn', 'maxlength')
          "
          >Company name is too long (maximum is
          {{ insuringPartyForm.controls['companyNameEn'].errors?.maxlength.requiredLength }}
          characters)</maia-validation
        >
      </maia-form-element>
      <maia-form-element
        *ngIf="!isInsuringPartyEIK"
        [hideOptionalIndicator]="true"
        class="multi-input-row"
        label="Full Name with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('firstNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('firstNameEn')
            "
          >
            <input maiaInput type="text" placeholder="First Name" formControlName="firstNameEn" />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('firstNameEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstNameEn', 'required')"
                >First name required</maia-validation
              >
              <maia-validation
                *ngIf="insuringPartyForm.hasError('firstNameEn', 'nonCyrillicLetters')"
                >First name - only latin letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstNameEn', 'nonBlank')"
                >Invalid first name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('firstNameEn', 'maxlength')"
                >First name is too long (maximum is
                {{ insuringPartyForm.controls['firstNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('middleNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('middleNameEn')
            "
          >
            <input maiaInput type="text" placeholder="Middle Name" formControlName="middleNameEn" />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('middleNameEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleNameEn', 'required')"
                >Middle name required</maia-validation
              >
              <maia-validation
                *ngIf="insuringPartyForm.hasError('middleNameEn', 'nonCyrillicLetters')"
                >Middle name - only latin letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleNameEn', 'nonBlank')"
                >Invalid middle name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('middleNameEn', 'maxlength')"
                >Middle name is too long (maximum is
                {{ insuringPartyForm.controls['middleNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('lastNameEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('lastNameEn')
            "
          >
            <input maiaInput type="text" placeholder="Surname" formControlName="lastNameEn" />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('lastNameEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastNameEn', 'required')"
                >Last name required</maia-validation
              >
              <maia-validation
                *ngIf="insuringPartyForm.hasError('lastNameEn', 'nonCyrillicLetters')"
                >Last name - only latin letters allowed</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastNameEn', 'nonBlank')"
                >Invalid last name</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('lastNameEn', 'maxlength')"
                >Last name is too long (maximum is
                {{ insuringPartyForm.controls['lastNameEn'].errors?.maxlength.requiredLength }}
                characters)</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="multi-input-row city-and-postcode"
        label="City & Zip with latin letters"
        multi
      >
        <maia-form-subelement>
          <maia-input-autocomplete
            name="cityEn"
            formControlName="cityEn"
            ngDefaultControl
            (typeAhead)="cityAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="off" maiaInput placeholder="City" />
            <maia-option *ngFor="let cityObj of cityAutocomplete.list | async" [value]="cityObj">
              <maia-option-highlight [label]="cityObj.i18n.en.name"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>
          <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('cityEn')">
            <maia-validation *ngIf="insuringPartyForm.hasError('cityEn', 'required')"
              >City required</maia-validation
            >
          </div>
        </maia-form-subelement>
        <maia-form-subelement>
          <div>
            <input
              maiaInput
              type="text"
              class="zip-style"
              placeholder="Zip"
              formControlName="postCodeEn"
              readonly
            />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('postCodeEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('postCodeEn', 'required')"
                >Post Code required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Quartile with latin letters" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('quarterEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('quarterEn')
            "
          >
            <input maiaInput placeholder="Quarter" formControlName="quarterEn" />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('quarterEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('quarterEn', 'required')"
                >Quarter is required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        class="multi-input-row city-and-postcode"
        label="Street & Number with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('streetEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('streetEn')
            "
          >
            <input maiaInput type="text" placeholder="Street" formControlName="streetEn" />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('streetEn')">
              <maia-validation *ngIf="insuringPartyForm.hasError('streetEn', 'required')"
                >Street required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('streetNumberEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('streetNumberEn')
            "
          >
            <input maiaInput type="text" placeholder="Number" formControlName="streetNumberEn" />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('streetNumberEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('streetNumberEn', 'required')"
                >Street number required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        class="multi-input-row"
        label="Block, Entrance, Floor & Apartment with latin letters"
        multi
      >
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('blockEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('blockEn')
            "
          >
            <input
              maiaInput
              type="text"
              name="block"
              placeholder="Block"
              formControlName="blockEn"
            />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('blockEn')">
              <maia-validation *ngIf="insuringPartyForm.hasError('blockEn', 'required')"
                >Block required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('entranceEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('entranceEn')
            "
          >
            <input
              maiaInput
              type="text"
              name="entrance"
              placeholder="Ent.."
              formControlName="entranceEn"
            />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('entranceEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('entranceEn', 'required')"
                >Entrance required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('floorEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('floorEn')
            "
          >
            <input
              maiaInput
              type="text"
              name="floor"
              placeholder="Floor"
              formControlName="floorEn"
            />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('floorEn')">
              <maia-validation *ngIf="insuringPartyForm.hasError('floorEn', 'required')"
                >Floor required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('apartmentEn')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('apartmentEn')
            "
          >
            <input
              maiaInput
              type="text"
              name="apartment"
              placeholder="Apart.."
              formControlName="apartmentEn"
            />
            <div
              class="validation-container"
              *ngIf="insuringPartyForm.hasToShowErrors('apartmentEn')"
            >
              <maia-validation *ngIf="insuringPartyForm.hasError('apartmentEn', 'required')"
                >Apartment required</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element
        *ngIf="isInsuringPartyLNCH"
        [hideOptionalIndicator]="true"
        [options]="{validationInstant: true}"
        class="multi-input-row"
        label="Date of Birth"
      >
        <maia-input-date
          name="dob"
          [(ngModel)]="birthDatePicker.value"
          (ngModelChange)="birthDatePicker.change($event)"
          (blur)="birthDatePicker.touch()"
          [minimum]="minDate"
          [maximum]="maxDate"
          [ngModelOptions]="{standalone: true}"
          [visibleView]="visibleDateView"
        ></maia-input-date>
        <maia-validation
          *ngIf="
            insuringPartyForm.hasToShowErrors('dob') &&
            insuringPartyForm.hasError('dob', 'required')
          "
        >
          Date of birth required
        </maia-validation>
      </maia-form-element>
      <maia-form-element class="multi-input-row" label="Email ID" multi>
        <maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            insuringPartyForm.hasToShowErrors('emailId')
          "
        >
          <div
            [class.p-maia-form-element--show-validation]="
              insuringPartyForm.hasToShowErrors('emailId')
            "
          >
            <input
              type="email"
              maiaInput
              atlasEmailAddress
              placeholder="Email ID"
              formControlName="emailId"
            />
            <div class="validation-container" *ngIf="insuringPartyForm.hasToShowErrors('emailId')">
              <maia-validation *ngIf="insuringPartyForm.hasError('emailId', 'required')"
                >Email required</maia-validation
              >
              <maia-validation
                *ngIf="
                  insuringPartyForm.hasError('emailId', 'atlasInvalidLocalPart') ||
                  insuringPartyForm.hasError('emailId', 'atlasInvalidDomain') ||
                  insuringPartyForm.hasError('emailId', 'atlasInvalidMultipleAt')
                "
                >Enter your e-mail address in the following format: name@domain.com</maia-validation
              >
              <maia-validation *ngIf="insuringPartyForm.hasError('emailId', 'atlasMaxLength')"
                >Email too long</maia-validation
              >
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>
  <maia-divider></maia-divider>

  <div *ngIf="isInsuringPartyEIK">
    <maia-label class="b2b-kdl-font-normal title-dark-color"> Representative </maia-label>
    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="EGN / LNCH"
        >
          <div *ngIf="eikRepDetails">
            <input
              maiaInput
              name="EGN / LNCH"
              placeholder="EGN / LNCH"
              formControlName="repIdNumber"
            />
          </div>
        </maia-form-element>
        <div>
          <maia-search-bar
            *ngIf="!eikRepDetails"
            class="search-box"
            [placeholder]="'EGN / LNCH'"
            (inputText)="handleRepresentative($event)"
            (searchText)="handleSearchedRepDetails($event)"
            [(ngModel)]="searchEgn"
            ngDefaultControl
            [ngModelOptions]="{standalone: true}"
          >
          </maia-search-bar>
        </div>
      </div>
      <div></div>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="multi-input-row"
          label="Full Name"
          multi
        >
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repFirstName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repFirstName')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="First Name"
                formControlName="repFirstName"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repFirstName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstName', 'required')"
                  >First name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repFirstName', 'nonLatinLetters')"
                  >First name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstName', 'nonBlank')"
                  >Invalid first name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstName', 'maxlength')"
                  >First name is too long (maximum is
                  {{ insuringPartyForm.controls['repFirstName'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repMiddleName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repMiddleName')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Middle Name"
                formControlName="repMiddleName"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repMiddleName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleName', 'required')"
                  >Middle name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repMiddleName', 'nonLatinLetters')"
                  >Middle name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleName', 'nonBlank')"
                  >Invalid middle name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleName', 'maxlength')"
                  >Middle name is too long (maximum is
                  {{ insuringPartyForm.controls['repMiddleName'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repLastName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repLastName')
              "
            >
              <input maiaInput type="text" placeholder="Last Name" formControlName="repLastName" />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repLastName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastName', 'required')"
                  >Last name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repLastName', 'nonLatinLetters')"
                  >Last name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastName', 'nonBlank')"
                  >Invalid last name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastName', 'maxlength')"
                  >Last name is too long (maximum is
                  {{ insuringPartyForm.controls['repLastName'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
        </maia-form-element>
        <maia-form-element
          *ngIf="isrepLNCH"
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="multi-input-row"
          label="Citizenship"
        >
          <maia-input-autocomplete
            name="repCitizenship"
            formControlName="repCitizenship"
            (typeAhead)="repCitizenshipAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="dzi-off" maiaInput placeholder="Select" />

            <maia-option *ngFor="let c of repCitizenshipAutocomplete.list | async" [value]="c.id">
              <maia-option-highlight [label]="c.i18n.en.title"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>

          <maia-validation
            *ngIf="
              insuringPartyForm.hasToShowErrors('repCitizenship') &&
              insuringPartyForm.hasError('repCitizenship', 'required')
            "
          >
            Citizenship required
          </maia-validation>
        </maia-form-element>
      </div>
      <div class="fluid">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="multi-input-row"
          label="Full Name with latin letters"
          multi
        >
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repFirstNameEn')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repFirstNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="First Name"
                formControlName="repFirstNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repFirstNameEn')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstNameEn', 'required')"
                  >First name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repFirstNameEn', 'nonCyrillicLetters')"
                  >First name - only latin letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstNameEn', 'nonBlank')"
                  >Invalid first name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repFirstNameEn', 'maxlength')"
                  >First name is too long (maximum is
                  {{
                    insuringPartyForm.controls['repFirstNameEn'].errors?.maxlength.requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repMiddleNameEn')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repMiddleNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Middle Name"
                formControlName="repMiddleNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repMiddleNameEn')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleNameEn', 'required')"
                  >Middle name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repMiddleNameEn', 'nonCyrillicLetters')"
                  >Middle name - only latin letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleNameEn', 'nonBlank')"
                  >Invalid middle name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repMiddleNameEn', 'maxlength')"
                  >Middle name is too long (maximum is
                  {{
                    insuringPartyForm.controls['repMiddleNameEn'].errors?.maxlength.requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('repLastNameEn')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('repLastNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Last Name"
                formControlName="repLastNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('repLastNameEn')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastNameEn', 'required')"
                  >Last name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('repLastNameEn', 'nonCyrillicLetters')"
                  >Last name - only latin letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastNameEn', 'nonBlank')"
                  >Invalid last name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('repLastNameEn', 'maxlength')"
                  >Last name is too long (maximum is
                  {{ insuringPartyForm.controls['repLastNameEn'].errors?.maxlength.requiredLength }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
        </maia-form-element>
        <maia-form-element
          *ngIf="isrepLNCH"
          [hideOptionalIndicator]="true"
          [options]="{validationInstant: true}"
          class="multi-input-row"
          label="Date of Birth"
        >
          <maia-input-date
            name="repDob"
            [(ngModel)]="repBirthDatePicker.value"
            (ngModelChange)="repBirthDatePicker.change($event)"
            (blur)="repBirthDatePicker.touch()"
            [minimum]="minDate"
            [maximum]="maxDate"
            [ngModelOptions]="{standalone: true}"
            [visibleView]="visibleDateView"
          ></maia-input-date>
          <maia-validation
            *ngIf="
              insuringPartyForm.hasToShowErrors('repDob') &&
              insuringPartyForm.hasError('repDob', 'required')
            "
          >
            Date of birth required
          </maia-validation>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>

    <maia-label class="b2b-kdl-font-normal title-dark-color"> Contact Person </maia-label>
    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-form-element
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="input-column"
          label="EGN / LNCH"
        >
        </maia-form-element>
        <div>
          <maia-search-bar
            class="search-box"
            [placeholder]="'EGN / LNCH'"
            (inputText)="handleContactPerson($event)"
            (searchText)="handleSearchedContactDetails($event)"
            [(ngModel)]="searchEgn"
            ngDefaultControl
            [ngModelOptions]="{standalone: true}"
          >
          </maia-search-bar>
        </div>
      </div>
      <div></div>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="multi-input-row"
          label="Full Name"
          multi
        >
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('contactFirstName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('contactFirstName')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="First Name"
                formControlName="contactFirstName"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('contactFirstName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactFirstName', 'required')"
                  >First name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactFirstName', 'nonLatinLetters')"
                  >First name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactFirstName', 'nonBlank')"
                  >Invalid first name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactFirstName', 'maxlength')"
                  >First name is too long (maximum is
                  {{
                    insuringPartyForm.controls['contactFirstName'].errors?.maxlength.requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('contactMiddleName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('contactMiddleName')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Middle Name"
                formControlName="contactMiddleName"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('contactMiddleName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactMiddleName', 'required')"
                  >Middle name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactMiddleName', 'nonLatinLetters')"
                  >Middle name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactMiddleName', 'nonBlank')"
                  >Invalid middle name</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactMiddleName', 'maxlength')"
                  >Middle name is too long (maximum is
                  {{
                    insuringPartyForm.controls['contactMiddleName'].errors?.maxlength.requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('contactLastName')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('contactLastName')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Last Name"
                formControlName="contactLastName"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('contactLastName')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactLastName', 'required')"
                  >Last name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactLastName', 'nonLatinLetters')"
                  >Last name - only cyrillic letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactLastName', 'nonBlank')"
                  >Invalid last name</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactLastName', 'maxlength')"
                  >Last name is too long (maximum is
                  {{
                    insuringPartyForm.controls['contactLastName'].errors?.maxlength.requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
        </maia-form-element>
        <maia-form-element
          *ngIf="isContactPersonLNCH"
          [options]="{validationInstant: true}"
          [hideOptionalIndicator]="true"
          class="multi-input-row"
          label="Citizenship"
        >
          <maia-input-autocomplete
            name="contactCitizenship"
            formControlName="contactCitizenship"
            (typeAhead)="contactCitizenshipAutocomplete.filterTypeahead($event)"
          >
            <input autocomplete="dzi-off" maiaInput placeholder="Select" />

            <maia-option
              *ngFor="let c of contactCitizenshipAutocomplete.list | async"
              [value]="c.id"
            >
              <maia-option-highlight [label]="c.i18n.en.title"></maia-option-highlight>
            </maia-option>
          </maia-input-autocomplete>

          <maia-validation
            *ngIf="
              insuringPartyForm.hasToShowErrors('contactCitizenship') &&
              insuringPartyForm.hasError('contactCitizenship', 'required')
            "
          >
            Citizenship required
          </maia-validation>
        </maia-form-element>
      </div>
      <div class="fluid">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="multi-input-row"
          label="Full Name with latin letters"
          multi
        >
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('contactFirstNameEn')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('contactFirstNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="First Name"
                formControlName="contactFirstNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('contactFirstNameEn')"
              >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactFirstNameEn', 'required')"
                  >First name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactFirstNameEn', 'nonCyrillicLetters')"
                  >First name - only latin letters allowed</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactFirstNameEn', 'nonBlank')"
                  >Invalid first name</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactFirstNameEn', 'maxlength')"
                  >First name is too long (maximum is
                  {{
                    insuringPartyForm.controls['contactFirstNameEn'].errors?.maxlength
                      .requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('contactMiddleNameEn')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('contactMiddleNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Middle Name"
                formControlName="contactMiddleNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('contactMiddleNameEn')"
              >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactMiddleNameEn', 'required')"
                  >Middle name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactMiddleNameEn', 'nonCyrillicLetters')"
                  >Middle name - only latin letters allowed</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactMiddleNameEn', 'nonBlank')"
                  >Invalid middle name</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactMiddleNameEn', 'maxlength')"
                  >Middle name is too long (maximum is
                  {{
                    insuringPartyForm.controls['contactMiddleNameEn'].errors?.maxlength
                      .requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="
              insuringPartyForm.hasToShowErrors('contactLastNameEn')
            "
          >
            <div
              [class.p-maia-form-element--show-validation]="
                insuringPartyForm.hasToShowErrors('contactLastNameEn')
              "
            >
              <input
                maiaInput
                type="text"
                placeholder="Last Name"
                formControlName="contactLastNameEn"
              />
              <div
                class="validation-container"
                *ngIf="insuringPartyForm.hasToShowErrors('contactLastNameEn')"
              >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactLastNameEn', 'required')"
                  >Last name required</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactLastNameEn', 'nonCyrillicLetters')"
                  >Last name - only latin letters allowed</maia-validation
                >
                <maia-validation *ngIf="insuringPartyForm.hasError('contactLastNameEn', 'nonBlank')"
                  >Invalid last name</maia-validation
                >
                <maia-validation
                  *ngIf="insuringPartyForm.hasError('contactLastNameEn', 'maxlength')"
                  >Last name is too long (maximum is
                  {{
                    insuringPartyForm.controls['contactLastNameEn'].errors?.maxlength.requiredLength
                  }}
                  characters)</maia-validation
                >
              </div>
            </div>
          </maia-form-subelement>
        </maia-form-element>
        <maia-form-element
          *ngIf="isContactPersonLNCH"
          [hideOptionalIndicator]="true"
          [options]="{validationInstant: true}"
          class="multi-input-row"
          label="Date of Birth"
        >
          <maia-input-date
            name="contactDob"
            [(ngModel)]="contactBirthDatePicker.value"
            (ngModelChange)="contactBirthDatePicker.change($event)"
            (blur)="contactBirthDatePicker.touch()"
            [minimum]="minDate"
            [maximum]="maxDate"
            [ngModelOptions]="{standalone: true}"
            [visibleView]="visibleDateView"
          ></maia-input-date>
          <maia-validation
            *ngIf="
              insuringPartyForm.hasToShowErrors('contactDob') &&
              insuringPartyForm.hasError('contactDob', 'required')
            "
          >
            Date of birth required
          </maia-validation>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>
  </div>
  <maia-label class="b2b-kdl-font-normal-bold title-dark-color"> Vehicle Owner Details </maia-label>
  <div class="fluid m-r-16 checkbox">
    <maia-checkbox
      [(ngModel)]="isSame"
      class="tile-checkbox"
      [ngModelOptions]="{standalone: true}"
      (ngModelChange)="vehicleOwnerDetails()"
    >
      <maia-label class="b2b-kdl-font-normal-light"
        >The vehicle owner is same as the insuring party</maia-label
      >
    </maia-checkbox>
  </div>
</form>
