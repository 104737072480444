import {Injectable, OnDestroy} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ReplaySubject} from 'rxjs';

import {FieldPairTranslitControllerService} from './field-pair-translit-controller.service';

@Injectable({
  providedIn: 'root',
})
export class NameAddressTranslationMapService implements OnDestroy {
  protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private readonly fieldPairTranslitController: FieldPairTranslitControllerService) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public updateNameFieldTranslation(stepForm: FormGroup) {
    this.fieldPairTranslitController.attach(
      stepForm.controls.firstName,
      stepForm.controls.firstNameEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.middleName,
      stepForm.controls.middleNameEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.lastName,
      stepForm.controls.lastNameEn,
      this.destroyed$,
    );
  }

  public updateVehicleIdentificationNumber(stepForm: FormGroup): void {
    this.fieldPairTranslitController.attach(
      stepForm.controls.vehicleIdentificationNumber,
      stepForm.controls.vehicleIdentificationNumberEn,
      this.destroyed$,
    );
  }

  public updateVehicleRegisterationNumber(stepForm: FormGroup): void {
    this.fieldPairTranslitController.attach(
      stepForm.controls.vehicleRegistrationNumber,
      stepForm.controls.vehicleRegistrationNumberEn,
      this.destroyed$,
    );
  }

  public updateAddressFieldTraslation(stepForm: FormGroup) {
    this.fieldPairTranslitController.attach(
      stepForm.controls.quarter,
      stepForm.controls.quarterEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.street,
      stepForm.controls.streetEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.streetNumber,
      stepForm.controls.streetNumberEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.block,
      stepForm.controls.blockEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.entrance,
      stepForm.controls.entranceEn,
      this.destroyed$,
    );

    this.fieldPairTranslitController.attach(
      stepForm.controls.apartment,
      stepForm.controls.apartmentEn,
      this.destroyed$,
    );

    this.fieldPairTranslitController.attach(
      stepForm.controls.floor,
      stepForm.controls.floorEn,
      this.destroyed$,
    );
  }

  public updateCompanyFieldTranslation(stepForm: FormGroup) {
    this.fieldPairTranslitController.attach(
      stepForm.controls.companyName,
      stepForm.controls.companyNameEn,
      this.destroyed$,
    );
  }

  public updateRepNameFieldTranslation(stepForm: FormGroup) {
    this.fieldPairTranslitController.attach(
      stepForm.controls.repFirstName,
      stepForm.controls.repFirstNameEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.repMiddleName,
      stepForm.controls.repMiddleNameEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.repLastName,
      stepForm.controls.repLastNameEn,
      this.destroyed$,
    );
  }

  public updateContactPersonNameFieldTranslation(stepForm: FormGroup) {
    this.fieldPairTranslitController.attach(
      stepForm.controls.contactFirstName,
      stepForm.controls.contactFirstNameEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.contactMiddleName,
      stepForm.controls.contactMiddleNameEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.contactLastName,
      stepForm.controls.contactLastNameEn,
      this.destroyed$,
    );
  }

  public updatePayerNameFieldTranslation(stepForm: FormGroup): void {
    this.fieldPairTranslitController.attach(
      stepForm.controls.payerFirstName,
      stepForm.controls.payerFirstNameEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.payerMiddleName,
      stepForm.controls.payerMiddleNameEn,
      this.destroyed$,
    );
    this.fieldPairTranslitController.attach(
      stepForm.controls.payerLastName,
      stepForm.controls.payerLastNameEn,
      this.destroyed$,
    );
  }
}
