import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprHandsCrossSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprHandsCross]',
  template: '<svg:path d="M35.81 43v-3.37l6-7.51a3.39 3.39 0 0 0 .75-2.12v-9.78A2.53 2.53 0 0 0 40 17.69h0a2.53 2.53 0 0 0-2.53 2.53v5.91" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M27.37 43v-8.73a3.38 3.38 0 0 1 1-2.38l5.76-5.76a2.39 2.39 0 0 1 3.38 0h0a2.38 2.38 0 0 1 0 3.37h0l-5.06 5.06" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M12.19 43v-3.37l-6-7.51A3.39 3.39 0 0 1 5.44 30v-9.78A2.53 2.53 0 0 1 8 17.69h0a2.53 2.53 0 0 1 2.53 2.53v5.91" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M20.63 43v-8.73a3.38 3.38 0 0 0-1-2.38l-5.76-5.76a2.39 2.39 0 0 0-3.38 0h0a2.38 2.38 0 0 0 0 3.37h0l5.06 5.06" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M32.93 10.68h-5.68V5h-6.5v5.68h-5.68v6.5h5.68v5.68h6.5v-5.68h5.68v-6.5z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprHandsCrossSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprHandsCrossSvgComponent,
  ],
  exports: [
    InformationExprHandsCrossSvgComponent,
  ],
})
export class InformationExprHandsCrossSvgModule {}
