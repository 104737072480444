import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialFuncPersonTieSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendSocialFuncPersonTie]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M11.37 16.12h1.26"></svg:path><svg:path d="M12.63 16.12l1.9 2.21-1.08 1.36" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M12.63 16.12l.64-1.27h-2.54l.64 1.27-.95 4.11L12 21.5l1.58-1.27z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M12 10.86h0a3.8 3.8 0 0 1-3.8-3.8h0V6.3A3.8 3.8 0 0 1 12 2.5h0a3.8 3.8 0 0 1 3.8 3.8h0v.76a3.8 3.8 0 0 1-3.8 3.8z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M15.84 19.21h3.76v-1.58a3 3 0 0 0-2.23-2.93 20.77 20.77 0 0 0-5.37-.8 20.77 20.77 0 0 0-5.37.8 3 3 0 0 0-2.23 2.93v1.59h4.46" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialFuncPersonTieSvgComponent {
}

@NgModule({
  declarations: [
    SocialFuncPersonTieSvgComponent,
  ],
  exports: [
    SocialFuncPersonTieSvgComponent,
  ],
})
export class SocialFuncPersonTieSvgModule {}
