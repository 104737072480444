import {Component} from '@angular/core';

@Component({
  selector: 'nje-groups-and-modules',
  templateUrl: './groups-and-modules.component.html',
  styleUrls: ['./groups-and-modules.component.scss'],
})
export class GroupsAndModulesComponent {
  public editExistingGroup(): void {
    //TODO
  }
}
