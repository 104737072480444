<div id="mtplproposal" *ngFor="let input of mockInstal">
  <div class="icons-display">
    <div class="b2b-kdl-font-medium-bold title-dark-color icon-offer m-t-8"
      >OFFER NUMBER {{ input.offerNo }}</div
    >
    <div class="icon-row">
      <maia-avatar-display id="avatar-display-svg">
        <maia-avatar-svg class="icon-display" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendInformationFuncFaxPrinter></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <div><button maiaButton maiaButtonType="tertiary" class="font-s">Print</button></div>
        </div>
      </maia-avatar-display>
    </div>

    <div class="icon-row">
      <maia-avatar-display id="avatar-display-svg">
        <maia-avatar-svg class="icon-display" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendCommunicationExprMail></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <div
            ><button maiaButton maiaButtonType="tertiary" (click)="openPopup()" class="font-s"
              >Send to Mail</button
            ></div
          >
        </div>
      </maia-avatar-display>
    </div>
    <div class="icon-row">
      <maia-avatar-display id="avatar-display-svg">
        <maia-avatar-svg class="icon-display" maia-avatar-display-icon>
          <svg class="icon-primary-color" b2bFrontendFileFuncDocument></svg>
        </maia-avatar-svg>
        <div maia-avatar-display-body>
          <div>
            <button maiaButton maiaButtonType="tertiary" (click)="openSlideIn()" class="font-s"
              >Read More</button
            >
          </div>
        </div>
      </maia-avatar-display>
    </div>
  </div>
  <maia-price-block amount="{{ input.amount }}" currency="BGN" class="price-block">
    <svg class="icon-white-color" b2bFrontendTransportationExprCarNew maia-price-icon></svg>
    <div maia-price-title>Motor Third Party Liability Policy</div>
    <div maia-price-subtitle>First Installment {{ input.instalment1 }} BGN</div>
  </maia-price-block>

  <maia-panel>
    <label-with-edit
      [nameEditPath]="instalmentsDetailsHeading"
      (editScreen)="editScreen($event)"
    ></label-with-edit>
    <maia-fluid-fluid-layout>
      <maia-table>
        <maia-table-header>
          <maia-table-row>
            <maia-table-cell
              class="b2b-kdl-font-small-bold subtitle-light-color"
              *ngFor="let column of mockPremiumHeading"
            >
              {{ column.premiumHeading }}
            </maia-table-cell>
          </maia-table-row>
        </maia-table-header>
        <maia-table-body>
          <maia-table-row>
            <maia-table-cell
              *ngFor="let premiumdata of mockPremiumDataList"
              class="b2b-kdl-font-small title-dark-color"
            >
              {{ premiumdata.row1 }}</maia-table-cell
            >
          </maia-table-row>
          <maia-table-row>
            <maia-table-cell
              *ngFor="let premiumdata of mockPremiumDataList"
              class="b2b-kdl-font-small title-dark-color"
            >
              {{ premiumdata.row2 }}
            </maia-table-cell>
          </maia-table-row>
          <maia-table-row>
            <maia-table-cell
              *ngFor="let premiumdata of mockPremiumDataList"
              class="b2b-kdl-font-small title-dark-color"
            >
              {{ premiumdata.row3 }}
            </maia-table-cell>
          </maia-table-row>
          <maia-table-row>
            <maia-table-cell
              *ngFor="let premiumdata of mockPremiumDataList"
              class="b2b-kdl-font-small title-dark-color"
            >
              {{ premiumdata.row4 }}
            </maia-table-cell>
          </maia-table-row>
          <maia-table-row>
            <maia-table-cell
              *ngFor="let premiumdata of mockPremiumDataList"
              class="b2b-kdl-font-small title-dark-color"
            >
              {{ premiumdata.row5 }}
            </maia-table-cell>
          </maia-table-row>
        </maia-table-body>
      </maia-table>
    </maia-fluid-fluid-layout>
    <div *ngIf="showmore" class="panel-navigate b2b-kdl-font-small b2b-kdl-color-primary-dark"
      ><span class="navigate-heading">Instalments</span>
      <button
        maiaButton
        maiaButtonType="tertiary"
        class="edit-btn b2b-kdl-extra-font-small"
        (click)="showmoreTable()"
        >View less
      </button>
      <svg b2bFrontendNavigationFuncChevron up class="navigate"></svg>
    </div>
    <div *ngIf="!showmore" class="panel-navigate b2b-kdl-font-small b2b-kdl-color-primary-dark">
      <span class="navigate-heading">Instalments</span>
      <button
        maiaButton
        maiaButtonType="tertiary"
        class="edit-btn b2b-kdl-extra-font-small"
        (click)="showmoreTable()"
        >View More</button
      >
      <svg b2bFrontendNavigationFuncChevron down class="navigate"></svg>
    </div>
    <div *ngIf="showmore">
      <maia-fluid-fluid-layout>
        <maia-table>
          <maia-table-header>
            <maia-table-row>
              <maia-table-cell
                class="b2b-kdl-font-small-bold subtitle-light-color"
                *ngFor="let heading of mockInstalmentHeading"
              >
                {{ heading.instalmentHeading }}
              </maia-table-cell>
            </maia-table-row>
          </maia-table-header>
          <maia-table-body>
            <maia-table-row>
              <maia-table-cell
                *ngFor="let col of mockInstalmentList"
                class="b2b-kdl-font-small title-dark-color"
              >
                {{ col.Instalmentrow }}</maia-table-cell
              >
            </maia-table-row>
            <maia-table-row>
              <maia-table-cell
                *ngFor="let col of mockInstalmentList"
                class="b2b-kdl-font-small title-dark-color"
              >
                {{ col.Instalmentsrow }}
              </maia-table-cell>
            </maia-table-row>
          </maia-table-body>
        </maia-table>
      </maia-fluid-fluid-layout>
    </div>
  </maia-panel>

  <maia-panel class="panel-block">
    <label-with-edit
      [nameEditPath]="coversDetailsHeading"
      (editScreen)="editScreen($event)"
    ></label-with-edit>

    <maia-avatar-display id="avatar-display-svg" class="m-t-24">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoinsPlus></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <div class="b2b-kdl-font-small title-dark-color">VIP Package</div>
        <div class="b2b-kdl-font-extra-small-light"
          >Lorem ipsum dolor sit amet, consectetur adipiscing elt veniam , quis nostrud
          exercitation</div
        >
      </div>
    </maia-avatar-display>
  </maia-panel>

  <div *ngFor="let data of mockPolicyDetails">
    <maia-panel class="panel-block">
      <label-with-edit
        [nameEditPath]="vehicleDetailsHeading"
        (editScreen)="editScreen($event)"
      ></label-with-edit>

      <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendActionFuncCalendarCheck></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.startDate }}</div>
            <div class="b2b-kdl-font-extra-small-light">Start date</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendActionFuncCalendarCheck></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.endDate }}</div>
            <div class="b2b-kdl-font-extra-small-light">End date</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendActionFuncCalendar></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color">{{ data.length }}</div>
            <div class="b2b-kdl-font-extra-small-light">Policy Length</div>
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>

      <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendTransportationIndiVehicleLicensePlate></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.use }}</div>
            <div class="b2b-kdl-font-extra-small-light">Vehicle Purpose</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendTransportationExprWheel></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color">{{ data.side }}</div>
            <div class="b2b-kdl-font-extra-small-light">Side of steering wheel </div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.driven }}</div>
            <div class="b2b-kdl-font-extra-small-light"
              >Driven outside Bulgaria for more than 60 days</div
            >
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>
      <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
        <maia-avatar-display>
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color">{{ data.acquired }}</div>
            <div class="b2b-kdl-font-extra-small-light">Was the vehcile new when acquired</div>
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>
    </maia-panel>

    <maia-panel class="panel-block">
      <label-with-edit
        [nameEditPath]="policyDetailsHeading"
        (editScreen)="editScreen($event)"
      ></label-with-edit>
      <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendTransportationIndiVehicleLicensePlate></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.regNo }}</div>
            <div class="b2b-kdl-font-extra-small-light">Vehicle Registartion Number</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color">{{ data.identificationNo }} </div>
            <div class="b2b-kdl-font-extra-small-light">Vehicle Certificate Number</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color">{{ data.identificationNo }} </div>
            <div class="b2b-kdl-font-extra-small-light">Vehicle Identification</div>
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>

      <div
        *ngIf="additionalvehicle"
        class="panel-navigate m-t-24 b2b-kdl-font-small b2b-kdl-color-primary-dark"
        ><span class="navigate-heading">Additional Vehicle Details</span>
        <button maiaButton maiaButtonType="tertiary" class="edit-btn" (click)="showadditionaldata()"
          >View less
        </button>
        <svg b2bFrontendNavigationFuncChevron up class="navigate"></svg>
      </div>
      <div
        *ngIf="!additionalvehicle"
        class="panel-navigate m-t-24 b2b-kdl-font-small b2b-kdl-color-primary-dark"
      >
        <span class="navigate-heading"> Additional Vehicle Details</span>
        <button maiaButton maiaButtonType="tertiary" class="edit-btn" (click)="showadditionaldata()"
          >View More</button
        >
        <svg b2bFrontendNavigationFuncChevron down class="navigate"></svg>
      </div>
      <div *ngIf="additionalvehicle">
        <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color"> {{ data.vehicleType }}</div>
              <div class="b2b-kdl-font-extra-small-light">Vehicle type</div>
            </div>
          </maia-avatar-display>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color">{{ data.vehicleSubType }} </div>
              <div class="b2b-kdl-font-extra-small-light">Vehicle Subtype</div>
            </div>
          </maia-avatar-display>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color">{{ data.make }}</div>
              <div class="b2b-kdl-font-extra-small-light">Make</div>
            </div>
          </maia-avatar-display>
        </maia-fluid-fluid-layout>

        <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color">{{ data.model }}</div>
              <div class="b2b-kdl-font-extra-small-light">Model</div>
            </div>
          </maia-avatar-display>
        </maia-fluid-fluid-layout>
        <maia-divider></maia-divider>
        <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color"> {{ data.dateOfReg }}</div>
              <div class="b2b-kdl-font-extra-small-light">Date of first registartion/purchase</div>
            </div>
          </maia-avatar-display>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color">{{ data.regType }} </div>
              <div class="b2b-kdl-font-extra-small-light">Vehicle Registration Type</div>
            </div>
          </maia-avatar-display>
        </maia-fluid-fluid-layout>
        <maia-divider></maia-divider>
        <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color"> {{ data.weight }}</div>
              <div class="b2b-kdl-font-extra-small-light">Total Weight</div>
            </div>
          </maia-avatar-display>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color">{{ data.volume }} </div>
              <div class="b2b-kdl-font-extra-small-light">Volume (ccm)</div>
            </div>
          </maia-avatar-display>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color">{{ data.fuelType }}</div>
              <div class="b2b-kdl-font-extra-small-light">Fuel Type</div>
            </div>
          </maia-avatar-display>
        </maia-fluid-fluid-layout>
        <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color"> {{ data.enginePower }}</div>
              <div class="b2b-kdl-font-extra-small-light">Engine Power</div>
            </div>
          </maia-avatar-display>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color">{{ data.seatNo }} </div>
              <div class="b2b-kdl-font-extra-small-light">Seat Number</div>
            </div>
          </maia-avatar-display>
          <maia-avatar-display class="panel-item">
            <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
              <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
            </maia-avatar-svg>
            <div maia-avatar-display-body>
              <div class="b2b-kdl-font-small title-dark-color">{{ data.colour }}</div>
              <div class="b2b-kdl-font-extra-small-light">Colour</div>
            </div>
          </maia-avatar-display>
        </maia-fluid-fluid-layout>
      </div>
    </maia-panel>

    <maia-panel class="panel-block">
      <label-with-edit
        [nameEditPath]="ownerInsurerHeading"
        (editScreen)="editScreen($event)"
      ></label-with-edit>

      <div class="panel-heading b2b-kdl-font-small b2b-kdl-color-primary-dark">
        Insuring Party
      </div>
      <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.physicalPerson }}</div>
            <div class="b2b-kdl-font-extra-small-light">Physical Person</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendPlacesExprHouseTree></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body class="m-l-8">
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.address }} </div>
            <div class="b2b-kdl-font-extra-small-light"
              >Sofia 1000 51B Nikoa Vaptzarov Blvd Bulgaria</div
            >
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendCommunicationFuncMail></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.email }}</div>
            <div class="b2b-kdl-font-extra-small-light">{{ data.phoneno }}</div>
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>

      <div class="panel-heading b2b-kdl-font-small b2b-kdl-color-primary-dark"> Vehicle Owner </div>
      <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.physicalPerson }}</div>
            <div class="b2b-kdl-font-extra-small-light">Physical Person</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendPlacesExprHouseTree></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body class="m-l-8">
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.address }}</div>
            <div class="b2b-kdl-font-extra-small-light"
              >Sofia 1000 51B Nikoa Vaptzarov Blvd Bulgaria</div
            >
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendCommunicationFuncMail></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color">{{ data.noemail }}</div>
            <div class="b2b-kdl-font-extra-small-light">{{ data.nophone }}</div>
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>
      <div class="panel-heading b2b-kdl-font-small b2b-kdl-color-primary-dark">
        Related Parties
      </div>
      <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.physicalPerson }}</div>
            <div class="b2b-kdl-font-extra-small-light">Lessee</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendPlacesExprHouseTree></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body class="m-l-8">
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.address }}</div>
          </div>
        </maia-avatar-display>
        <maia-avatar-display class="panel-item">
          <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
            <svg class="icon-primary-color" b2bFrontendCommunicationFuncMail></svg>
          </maia-avatar-svg>
          <div maia-avatar-display-body>
            <div class="b2b-kdl-font-small title-dark-color"> {{ data.email }}</div>
            <div class="b2b-kdl-font-extra-small-light">{{ data.phoneno }}</div>
          </div>
        </maia-avatar-display>
      </maia-fluid-fluid-layout>
    </maia-panel>
  </div>

  <maia-divider></maia-divider>
  <form maiaForm [formGroup]="proposalForm">
    <maia-fluid-fluid-layout alignContentTop>
      <div class="fluid m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="multi-input-row" label="Issue">
          <maia-radio-group horizontal formControlName="issuesAdded">
            <njf-radio-button *ngFor="let option of mockRadio" [value]="option.id">
              {{ option.name }}</njf-radio-button
            >
          </maia-radio-group>
        </maia-form-element>
      </div>
      <div class="fluid"></div>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>

    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column"
          [options]="{validationInstant: true}"
          label="Green Card Series"
        >
          <input maiaInput placeholder="0X" formControlname="greenCardSeries" />
        </maia-form-element>
      </div>
      <div class="fluid m-r-16">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column"
          [options]="{validationInstant: true}"
          label="Green Card Number"
        >
          <input maiaInput placeholder="000000" formControlname="greenCardNumber" />
        </maia-form-element>
      </div>
      <div class="fluid m-r-16">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column"
          [options]="{validationInstant: true}"
          label="Sticker Series"
        >
          <input maiaInput placeholder="0X" formControlname="stickerSeries" />
        </maia-form-element>
      </div>
      <div class="fluid m-r-16">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column"
          [options]="{validationInstant: true}"
          label="Sticker Number"
        >
          <input maiaInput placeholder="000000000" formControlname="stickerNumber" />
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>
    <maia-label class="b2b-kdl-font-small m-b-8 title-dark-color"> Upload Documents </maia-label>
    <maia-contextual-message id="contextual-message-5" class="banner-style">
      You need to attach a vehicle coupon, invoice or certificate of ownership.
    </maia-contextual-message>
    <maia-fluid-fluid-layout class="upload-documents">
      <div>
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column upload-input"
          label="Define Documents"
        >
          <maia-input-select name="definedocument" formControlName="definedocument">
            <input
              maiaInput
              placeholder="Define"
              [value]="getDocument(proposalForm.controls.definedocument.value)"
            />
            <maia-option *ngFor="let doc of mockDefineDoc" [value]="doc.defineDoc">{{
              doc.defineDoc
            }}</maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>
      <div class="upload-doc">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Upload Documents"
        ></maia-form-element>
        <input
          class="hide-input-file m-t-8"
          #fileInput
          type="file"
          (change)="uploadFile($event, 'upload')"
        />
        <maia-fluid-fluid-layout>
          <maia-tile
            class="show-hand upload-tile m-t-b-16"
            (click)="fileInput.click()"
            class="upload-tile"
          >
            <maia-tile-items horizontal>
              <span class="b2b-kdl-font-small-light show-hand">{{ uploadTile }}</span>
              <maia-tile-item class="icon-upload m-b-24" contentAlign="right">
                <maia-tile-button-action-icon>
                  <svg class="icon-color" b2bFrontendFileFuncCloudUpload></svg>
                </maia-tile-button-action-icon>
              </maia-tile-item>
            </maia-tile-items>
          </maia-tile>
        </maia-fluid-fluid-layout>
      </div>
      <div class="fluid m-l-24">
        <svg
          class="delete-icon"
          [style.color]="deleteColour"
          b2bFrontendActionFuncBin
          (click)="resetUpload()"
        ></svg>
      </div>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout class="upload-documents" *ngIf="addmoredocument">
      <div class="input-define-doc">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column upload-input"
          label="Define Documents"
        >
          <maia-input-select name="definedocument" formControlName="definedocument1">
            <input
              maiaInput
              placeholder="Define"
              [value]="getDocument(proposalForm.controls.definedocument1.value)"
            />
            <maia-option *ngFor="let doc of mockDefineDoc" [value]="doc.defineDoc">{{
              doc.defineDoc
            }}</maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>
      <div class="upload-doc">
        <maia-form-element
          [hideOptionalIndicator]="true"
          class="input-column"
          label="Upload Documents"
        ></maia-form-element>
        <input
          class="hide-input-file m-t-8"
          #fileInput
          type="file"
          (change)="uploadDoc($event, 'upload')"
        />
        <maia-fluid-fluid-layout>
          <maia-tile
            class="show-hand upload-tile m-t-b-16"
            (click)="fileInput.click()"
            class="upload-tile"
          >
            <maia-tile-items style="justify-content: center" horizontal>
              <span class="show-hand b2b-kdl-font-small-light">{{ uploadTile1 }}</span>
              <maia-tile-item class="icon-upload m-b-24" contentAlign="right">
                <maia-tile-button-action-icon>
                  <svg class="icon-color" b2bFrontendFileFuncCloudUpload></svg>
                </maia-tile-button-action-icon>
              </maia-tile-item>
            </maia-tile-items>
          </maia-tile>
        </maia-fluid-fluid-layout>
      </div>
      <div class="fluid m-l-24">
        <svg
          class="delete-icon"
          [style.color]="deleteColour"
          b2bFrontendActionFuncBin
          (click)="resetDoc()"
        ></svg>
      </div>
    </maia-fluid-fluid-layout>

    <div class="add-new-icon">
      <svg b2bFrontendActionFuncPlusCircle></svg>
      <button maiaButton maiaButtonType="tertiary" class="add-doc" (click)="addDocument()">
        Add Document</button
      >
    </div>
    <maia-divider></maia-divider>
  </form>

  <button
    md-button
    maiaButton
    maiaButtonType="primary"
    class="m-t-b-24"
    [disabled]="!proposalForm.valid"
    (click)="onContinue()"
    >Create Policy</button
  >
</div>
