<form class="two-fa-form-wrapper form" [formGroup]="twofaForm">
  <maia-form-group class="two-fa-form">
    <svg b2bFrontendInformationExprPhoneLock class="icon-adjust"></svg>
    <maia-label class="b2b-kdl-font-grand-serif title-dark-color step-in-txt">
      2-Step Verification
    </maia-label>
    <maia-label class="b2b-kdl-font-small-light verify-in-txt">
      With 2-Step Verification, you add a second layer of protection to your account. So even if
      somebody manages to steal your password, it’s not enough to go into your account.
    </maia-label>
    <maia-form-element
      [hideOptionalIndicator]="true"
      class="multi-input-row"
      label="What kind of phone do you have?"
    >
      <maia-radio-group class="radio-group-margins" formControlName="twofaChoiceRadio">
        <njf-radio-button value="yes">Android</njf-radio-button>
        <njf-radio-button value="no">iPhone</njf-radio-button>
      </maia-radio-group>
    </maia-form-element>
    <maia-button-group class="m-t-16 m-b-8 button-wrapper">
      <button
        maiaButton
        maiaButtonType="primary"
        id="twofaCheck"
        [disabled]="twofaForm.invalid"
        (click)="onContinue()"
      >
        Continue
      </button>
    </maia-button-group>
  </maia-form-group>
</form>
