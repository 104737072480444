/* eslint-disable @typescript-eslint/unbound-method */
import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {DateKey, DateUtils, Date as AtlasDate} from '@atlas/businesstypes';
import {NewsEvents} from '../../../../contracts/NewsEvents';
import {DateFormatterService} from '@b2b-frontend/core';
import {PinSlideFormData, PinSlideInput, PinSlideOutput} from './NewsAndEventsPinDates';

@Component({
  selector: 'nje-news-and-events-pin-slidein',
  templateUrl: './news-and-events-pin-slidein.component.html',
  styleUrls: ['./news-and-events-pin-slidein.component.scss'],
})
export class NewsAndEventsPinSlideinComponent
  extends ModalContentComponent<PinSlideOutput, PinSlideInput>
  implements OnInit
{
  public newsAndEventsPinForm: FormGroup = new FormGroup({
    pinStartDate: new FormControl(undefined, [Validators.required]),
    pinEndDate: new FormControl(undefined, [Validators.required]),
  });
  public minDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 365);
  public validDate: boolean;
  public isUpdatePin: boolean;
  public preselectedStartDate: AtlasDate;
  public preselectedEndDate: AtlasDate;

  public constructor(
    public control: ModalControl<PinSlideOutput, PinSlideInput>,
    private readonly _dateFormatterService: DateFormatterService,
  ) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    if (data && data.newsEvents) {
      this._initializeData(data.newsEvents);
      this.isUpdatePin = data.isUpdatePin;
    }
  }

  private _initializeData(pinDates: NewsEvents): void {
    if (pinDates.pinStartDate && pinDates.pinEndDate) {
      const formattedDateString = {
        pinStartDate: this._dateFormatterService.getDDMMYYYYFormatDate(
          new Date(pinDates.pinStartDate),
        ),
        pinEndDate: this._dateFormatterService.getDDMMYYYYFormatDate(new Date(pinDates.pinEndDate)),
      } as PinSlideFormData;
      this.newsAndEventsPinForm.patchValue({
        pinStartDate: this._dateFormatterService.getAtlasDate(formattedDateString.pinStartDate),
        pinEndDate: this._dateFormatterService.getAtlasDate(formattedDateString.pinEndDate),
      });
      this.preselectedStartDate = this.newsAndEventsPinForm.value.pinStartDate;
      this.preselectedEndDate = this.newsAndEventsPinForm.value.pinEndDate;
    }
  }

  public update(): void {
    const datePins = this.newsAndEventsPinForm.value;
    const pinnedDate = {
      pinStartDate: this._dateFormatterService.dateToISO(datePins.pinStartDate),
      pinEndDate: this._dateFormatterService.dateToISO(datePins.pinEndDate),
    } as PinSlideFormData;
    const pinDatesOutput: PinSlideOutput = {
      isUnpinned: false,
      pinDates: pinnedDate,
    };
    this.control.confirm(pinDatesOutput);
  }

  public unpin(): void {
    const pinDatesOutput: PinSlideOutput = {
      isUnpinned: true,
    };
    this.control.confirm(pinDatesOutput);
  }

  public cancel(): void {
    this.control.cancel();
  }

  public canUpdate(): boolean {
    const formValue = this.newsAndEventsPinForm.value;
    const startDateChanged = !this.preselectedStartDate.equals(formValue.pinStartDate);
    const endDateChanged = !this.preselectedEndDate.equals(formValue.pinEndDate);
    return (startDateChanged || endDateChanged) && this.newsAndEventsPinForm.valid;
  }
}
