<p class="align">{{ message }}</p>
<p class="align">{{ footerNote }}</p>

<div *maiaPopUpFooter>
  <button
    maiaButton
    maiaButtonType="primary"
    type="button"
    (click)="cancel()"
    i18n="@@nje-b2b-frontend-resource-contacts-no"
    >No</button
  >
  <button
    maiaButton
    maiaButtonType="delete"
    (click)="confirm()"
    i18n="@@nje-b2b-frontend-resource-contacts-yes"
    >Yes</button
  >
</div>
