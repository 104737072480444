import {Component, OnInit} from '@angular/core';
import {ModalControl, ModalContentComponent} from '@maia/modals';
import {InsuredPerson} from '@b2b-frontend/core';

@Component({
  selector: 'travel-insured-people-slidein',
  templateUrl: './travel-insured-people-slidein.component.html',
  styleUrls: ['./travel-insured-people-slidein.component.scss'],
})
export class TravelInsuredPeopleSlideinComponent
  extends ModalContentComponent<InsuredPerson, InsuredPerson>
  implements OnInit
{
  public personData: InsuredPerson;
  public constructor(public control: ModalControl<InsuredPerson, InsuredPerson>) {
    super();
  }

  public ngOnInit(): void {
    this.personData = this.control.input;
  }

  public updateInsuredPerson(event: InsuredPerson): void {
    this.control.confirm(event);
  }

  public cancel(): void {
    this.control.cancel();
  }
}
