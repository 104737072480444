import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {DateFormatterService} from '@b2b-frontend/core';
import {
  StepperProcessService as StepperProcessServiceBase,
  StepperProcessStep,
} from '@njf-frontend-angular/flow-progress';
import {MtplB2BWorkFlow} from '../workflow/MtplB2BWorkFlow';
import {steps} from '../workflow/steps';
import {workflows} from '../workflow/workflows';

const MTPL_SESSION_KEY = 'mtpl-process';

@Injectable({
  providedIn: 'root',
})
export class MtplProcessService
  extends StepperProcessServiceBase<MtplB2BWorkFlow>
  implements OnDestroy
{
  public constructor(router: Router, public dateFormaterService: DateFormatterService) {
    super(router, steps, workflows, MtplB2BWorkFlow.MTPL_POLICY, MTPL_SESSION_KEY);
  }

  protected getWorkflowStepsFromWorkflowAndStep(
    wf: MtplB2BWorkFlow,
    step: StepperProcessStep,
  ): StepperProcessStep[] {
    return this.getWorkflowSteps(wf);
  }

  protected getCurrentWorkflowStepsList(): string[] {
    return this.workflows[this.currentWorkflow];
  }
}
