import {
  Amount,
  CoversLimits,
  InstallmentOptions,
  Item,
  IvBaseInterface,
} from '../../../contracts/property-insurance.interface';
import {Decimal} from '@atlas/businesstypes';
import {ModalResolution} from '@maia/modals';
import {AdditionalCoversAddressLevelInterface} from '@b2b-frontend/core';

const replacement_value: string = 'Replacement Value';
const limit_first_risk: string = 'Limit/First Risk';
const earthquake: string = 'Earthquake';
const earthquake_theft: string = 'Earthquake Theft';
const sos_for_home: string = 'SOS for Home';
export const BGN: string = 'BGN';
export const ACCORDIAN_TITLE: string = 'Total Amount (Incl. of taxes)';

export const PROPERTY_COVERS_INFO_LIST1 = {
  data: [
    {
      id: 1,
      propertyType: 'Villa',
      basis_of_insurance: replacement_value,
      insured_value: '56 000,00',
      main_covers: 'Platinum',
      additional_covers: [earthquake],
      aggregate_limit: [5600000],
      premium_account: '140,01',
    },
    {
      id: 2,
      propertyType: 'Household Property',
      basis_of_insurance: limit_first_risk,
      insured_value: '28 500,00',
      main_covers: 'Platinum',
      additional_covers: [earthquake_theft],
      aggregate_limit: [2850000, 2800000],
      premium_account: '100,80',
    },
    {
      id: 3,
      propertyType: 'Garage as a part of a building',
      basis_of_insurance: limit_first_risk,
      insured_value: '28 500,00',
      main_covers: 'Gold',
      additional_covers: [earthquake],
      aggregate_limit: [2850000],
      premium_account: '70,00',
    },
    {
      id: 4,
      propertyType: 'External Pool',
      basis_of_insurance: replacement_value,
      insured_value: '56 000,00',
      main_covers: 'Platinum',
      additional_covers: [earthquake],
      aggregate_limit: [5600000],
      premium_account: '140,23',
    },
    {
      id: 5,
      propertyType: 'Solar Thermal Collector',
      basis_of_insurance: replacement_value,
      insured_value: '56 000,00',
      main_covers: 'Gold',
      additional_covers: [earthquake],
      aggregate_limit: [5600000],
      premium_account: '140,43',
    },
    {
      id: 6,
      propertyType: 'Overall Improvement',
      basis_of_insurance: replacement_value,
      insured_value: '56 000,00',
      main_covers: 'Silver',
      additional_covers: [earthquake],
      aggregate_limit: [5600000],
      premium_account: '140,12',
    },
    {
      id: 7,
      propertyType: 'Jacuzzi',
      basis_of_insurance: replacement_value,
      insured_value: '56 000,00',
      main_covers: 'Silver',
      additional_covers: [earthquake],
      aggregate_limit: [5600000],
      premium_account: '140,12',
    },
  ],
};

export const ADDITIONAL_COVERS_SOS_INFO_LIST1: AdditionalCoversAddressLevelInterface[] = [
  {
    id: 1,
    coverName: sos_for_home,
    aggregateLimit: {
      min: 1000,
      max: 1000,
      default: 2840,
    },
    limitPerOccurence: {
      min: 1000,
      max: 1000,
      default: undefined,
    },
  },
];

export const ADDITIONAL_COVERS_SOS_INFO_LIST2 = {
  data: [
    {
      id: 1,
      additional_covers: [sos_for_home],
      aggregate_limit: [2840],
      limit_per_occurance: undefined,
    },
  ],
};

export const ADDITIONAL_COVERS_INFO_LIST1 = {
  data: [
    {
      id: 1,
      additional_covers: [sos_for_home],
      aggregate_limit: [284000],
      limit_per_occurance: undefined,
      premium_account: '9,01',
    },
    {
      id: 2,
      additional_covers: ['TPL'],
      aggregate_limit: [100000],
      limit_per_occurance: 50000,
      premium_account: '2,02',
    },
    {
      id: 3,
      additional_covers: ['Glass Breakage'],
      aggregate_limit: [100000],
      limit_per_occurance: 50000,
      premium_account: '8,10',
    },
    {
      id: 4,
      additional_covers: ['Sound Waves'],
      aggregate_limit: [200000],
      limit_per_occurance: undefined,
      premium_account: '4,00',
    },
    {
      id: 5,
      additional_covers: ['Portable Electronics'],
      aggregate_limit: [100000],
      limit_per_occurance: undefined,
      premium_account: '6,03',
    },
    {
      id: 6,
      additional_covers: ['Accidents'],
      aggregate_limit: [200000],
      limit_per_occurance: undefined,
      premium_account: '10,00',
    },
  ],
};

export const PROPERTY_COVERS_INFO_LIST2 = {
  data: [
    {
      id: 1,
      propertyType: 'Household Property',
      basis_of_insurance: limit_first_risk,
      insured_value: '82 500,00',
      main_covers: 'Platinum',
      additional_covers: [earthquake_theft],
      aggregate_limit: [8250000, 15000000],
      premium_account: '1250,80',
    },
    {
      id: 2,
      propertyType: 'Pieces of Art',
      basis_of_insurance: replacement_value,
      insured_value: '99 000,00',
      main_covers: 'Silver',
      additional_covers: [earthquake_theft],
      aggregate_limit: [9900000, 15000000],
      premium_account: '320,00 BGN',
    },
  ],
};

export const ADDITIONAL_COVERS_INFO_LIST2 = {
  data: [
    {
      id: 1,
      additional_covers: [sos_for_home],
      aggregate_limit: [284000],
      limit_per_occurance: undefined,
    },
    {
      id: 2,
      additional_covers: ['TPL'],
      aggregate_limit: [200000],
      limit_per_occurance: 70000,
    },
    {
      id: 3,
      additional_covers: ['Portable Electronics'],
      aggregate_limit: [100000],
      limit_per_occurance: undefined,
    },
    {
      id: 6,
      additional_covers: ['Accidents'],
      aggregate_limit: [200000],
      limit_per_occurance: undefined,
    },
  ],
};
export const LOADING_LIST = [
  {
    id: 1,
    name: '11, Deseta Str., Kranso Selo, Kalugerovo Village, 4462',
    list: ['Loading for Garage as a part of building on limit (+20%)'],
  },
  {
    id: 2,
    name: '51B, Kranso Street, Sofia, 1000',
    list: ['Loading  for household property on limit (+20%)'],
  },
];

export const DISCOUNT_LIST = ['Black Friday Discount (-15%)'];

export const MOCK_PREMIUM_PRICE: Amount = {
  value: new Decimal('1241.98'),
  currency: 'BGN',
};

export const MOCK_ITEMS: Item[] = [{name: 'First Installment Amount', value: '1241,98 BGN'}];

export const MOCK_PROPERTY_TYPE_LIST1 = [
  {
    id: 1,
    propertyType: 'Villa',
  },
  {
    id: 2,
    propertyType: 'Household Property',
  },
];

export const MOCK_PROPERTY_TYPE_LIST2 = [
  {
    id: 1,
    propertyType: 'Household Property',
  },
  {
    id: 2,
    propertyType: 'Pieces of Art',
  },
];

export const MOCK_INSTALLMENTS: InstallmentOptions[] = [
  {
    id: '1',
    name: 'one installment',
  },
  {
    id: '2',
    name: 'two installment',
  },
  {
    id: '3',
    name: 'three installment',
  },
  {
    id: '4',
    name: 'four installment',
  },
];

export const MOCK_COVERS_LIMITS: CoversLimits[] = [
  {
    id: 1,
    objectName: 'Limit 1',
  },
  {
    id: 2,
    objectName: 'Limit 2',
  },
  {
    id: 3,
    objectName: 'Limit 3',
  },
  {
    id: 4,
    objectName: 'Limit 4',
  },
  {
    id: 5,
    objectName: 'Limit 5',
  },
];

export const MOCK_MODAL_RESULT = {
  resolution: ModalResolution.DISMISSED,
};

export const MOCK_IV_BASE_OPTIONS: IvBaseInterface[] = [
  {
    id: '1',
    name: 'Replacement Value',
    minIv: 1000,
    maxIv: 20000,
  },
  {
    id: '2',
    name: 'Limit',
    minIv: 100,
    maxIv: 1000,
  },
];
