<form [formGroup]="accoutDetailsForm" class="account-creation-details-wrapper">
  <maia-label class="title-dark-color account-details m-t-b-24"> Account Details </maia-label>

  <div class="egn-lnch-eik-wrapper">
    <div class="egn-lnch-eik">
      <maia-label class="title-dark-color account-creation-title-label m-b-8">
        EGN / LNCH / EIK
      </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column">
        <nje-auto-complete></nje-auto-complete>
      </maia-form-element>
    </div>
  </div>

  <div class="full-name-wrapper m-t-b-24">
    <div class="left-input-wrapper">
      <maia-label class="title-dark-color account-creation-title-label"> Full Name </maia-label>
      <maia-form-element hideOptionalIndicator multi class="input-column multi-input-row m-t-8">
        <maia-form-subelement class="name-data first-name">
          <input
            maiaInput
            maiaInputType="text"
            class="placeholder-txt"
            placeholder="Първо име"
            formControlName="firstNameBg"
          />
        </maia-form-subelement>
        <maia-form-subelement class="name-data middle-name">
          <input
            maiaInput
            maiaInputType="text"
            class="placeholder-txt"
            placeholder="Презиме"
            formControlName="middleNameBg"
          />
        </maia-form-subelement>
        <maia-form-subelement class="name-data last-name">
          <input
            maiaInput
            maiaInputType="text"
            class="placeholder-txt"
            placeholder="Фамилия"
            formControlName="lastNameBg"
          />
        </maia-form-subelement>
      </maia-form-element>
    </div>
    <div class="right-input-wrapper">
      <maia-label class="title-dark-color account-creation-title-label">
        Full Name in English
      </maia-label>
      <maia-form-element hideOptionalIndicator multi class="input-column multi-input-row m-t-8">
        <maia-form-subelement class="name-data first-name">
          <input
            maiaInput
            maiaInputType="text"
            class="placeholder-txt"
            placeholder="First Name"
            formControlName="firstNameEn"
          />
        </maia-form-subelement>
        <maia-form-subelement class="name-data middle-name">
          <input
            maiaInput
            maiaInputType="text"
            class="placeholder-txt"
            placeholder="Middle Name"
            formControlName="middleNameEn"
          />
        </maia-form-subelement>
        <maia-form-subelement class="name-data last-name">
          <input
            maiaInput
            maiaInputType="text"
            class="placeholder-txt"
            placeholder="Last Name"
            formControlName="lastNameEn"
          />
        </maia-form-subelement>
      </maia-form-element>
    </div>
  </div>

  <div class="phone-email-wrapper">
    <div class="left-input-wrapper">
      <maia-label class="title-dark-color account-creation-title-label"> Email ID </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <input
          maiaInput
          maiaInputType="text"
          placeholder="Corporate Email ID"
          class="placeholder-txt"
          formControlName="corporateEmailId"
        />
      </maia-form-element>
    </div>
    <div class="right-input-wrapper">
      <maia-label class="title-dark-color account-creation-title-label"> Phone Number </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <nje-phone-number-input></nje-phone-number-input>
      </maia-form-element>
    </div>
  </div>

  <maia-panel class="m-t-24 b2b-kdl-color-background-light border-color">
    <div class="details-wrapper">
      <maia-label class="b2b-kdl-color-primary-dark b2b-kdl-font-small-bold">
        Added Agent Number
      </maia-label>
      <maia-additional-information
        class="m-l-12"
        openLinkText="Edit"
        slideInTitle="Add Agent Number"
        slideIn
        coverlink
      >
        <maia-label class="b2b-kdl-color-primary-dark b2b-kdl-font-small m-b-8"
          >Add agents by agent number</maia-label
        >
        <nje-agent-by-agent-number></nje-agent-by-agent-number>

        <maia-label class="b2b-kdl-color-primary-dark b2b-kdl-font-small m-t-16 m-b-8"
          >Add agents by agency</maia-label
        >
        <nje-input-select-by-agency></nje-input-select-by-agency>

        <maia-divider></maia-divider>
        <nje-selected-agents></nje-selected-agents>

        <maia-button-group class="m-t-16 buttons-wrapper">
          <button maiaButton class="m-b-16" maiaButtonType="primary">Add Agents</button>
          <button maiaButton class="m-b-16">Cancel</button>
        </maia-button-group>
      </maia-additional-information>
    </div>
    <div>
      <div class="details-wrapper" *ngFor="let detail of addedAgents">
        <div class="user-account-details m-t-8">
          <div class="details-wrapper">
            <div>
              <svg
                class="circle-check-image b2b-kdl-color-success m-r-16"
                b2bFrontendAlertFuncCheck
              ></svg>
            </div>
            <div>
              <maia-label class="b2b-kdl-color-primary-dark b2b-kdl-font-small">
                {{ detail.agentNumber }}
                <maia-label class="b2b-kdl-color-primary-dark b2b-kdl-font-extra-small-light">
                  {{ detail.agencyName }}
                </maia-label>
              </maia-label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </maia-panel>

  <div class="username-password-wrapper m-t-24">
    <div class="left-input-wrapper">
      <maia-label class="title-dark-color account-creation-title-label"> User Name </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <input
          maiaInput
          maiaInputType="text"
          placeholder="Select"
          class="placeholder-txt"
          formControlName="username"
        />
      </maia-form-element>
    </div>
    <div class="right-input-wrapper">
      <maia-label class="title-dark-color account-creation-title-label"> Password </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column m-t-8">
        <input
          maiaInput
          maiaInputType="text"
          placeholder="Select"
          class="placeholder-txt"
          formControlName="password"
        />
      </maia-form-element>
    </div>
  </div>

  <maia-divider></maia-divider>
</form>
