import {Injectable} from '@angular/core';
import {ApiService} from '@b2b-frontend/core';
import {Observable} from 'rxjs';
import {ReportExecuteResponseDto, ReportType} from '../contracts/statistics-reporting.interface';

@Injectable({
  providedIn: 'root',
})
export class CmsStatisticsReportingService {
  public constructor(private readonly _apiService: ApiService) {}

  public getReportTypeList(): Observable<ReportType[]> {
    return this._apiService.get('reporting/report');
  }

  public getReportData(id: string, q: string): Observable<ReportExecuteResponseDto> {
    return this._apiService.get(`reporting/report/${id}/execute?q=${q}`);
  }

  public getDownloadReportURL(selectedReportType: string, q: string): string {
    return this._apiService.API_HOST_URL + `reporting/report/${selectedReportType}/export?q=${q}`;
  }
}
