import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.css'],
})
export class RenewalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
