import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {B2CSearchParameters, SEARCH_BY, SEARCH_TERM} from '../../../constants/account-management';
import {SearchBy, SearchedParameters} from '../../../models/SearchBy';

@Component({
  selector: 'nje-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
  public searchByValues: SearchBy[] = B2CSearchParameters;
  public searchBy: SearchBy;
  public searchForm: FormGroup;

  @Output() searchData = new EventEmitter<SearchedParameters>();

  public constructor(private readonly _fb: FormBuilder, private readonly _cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.searchForm = this._fb.group({
      searchBox: [[], [Validators.required]],
    });
  }

  public search(): void {
    if (this.searchForm.valid) {
      const searchParameters: SearchedParameters = {
        searchBy: this.searchBy.id,
        searchTerm: this.searchForm.get('searchBox')?.value,
      };
      this.searchData.emit(searchParameters);
    }
  }

  public setSearchBy(searchByValue: SearchBy): void {
    this.searchBy = searchByValue;
    this._cdr.detectChanges();
  }

  public getSearchPlaceholder(): string {
    return this.searchBy ? `${SEARCH_BY} ${this.searchBy.displayValue}` : `${SEARCH_TERM}`;
  }
}
