import {IStepperProcessStep} from '@njf-frontend-angular/flow-progress';
import {PropertyRoutePaths} from '@b2b-frontend/core';

export const steps: IStepperProcessStep[] = [
  {
    id: PropertyRoutePaths.PROPERTY_POLICY_DETAILS,
    title: 'Policy Details',
    routePath: `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_POLICY_DETAILS}`,
  },
  {
    id: PropertyRoutePaths.PROPERTY_INSURING_PARTY,
    title: 'Insuring Party & Insured Party',
    routePath: `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_INSURING_PARTY}`,
  },
  {
    id: PropertyRoutePaths.PROPERTY_DETAILS,
    title: 'Property Details',
    routePath: `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_DETAILS}`,
  },
  {
    id: PropertyRoutePaths.PROPERTY_COVERS_AND_DISCOUNTS,
    title: 'Covers & Discounts',
    routePath: `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_COVERS_AND_DISCOUNTS}`,
  },
  {
    id: PropertyRoutePaths.PROPERTY_SUPPLEMENTARY_LIST,
    title: 'Supplementary List',
    routePath: `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_SUPPLEMENTARY_LIST}`,
  },
  {
    id: PropertyRoutePaths.PROPERTY_PROPOSAL,
    title: 'Proposal',
    routePath: `/dashboard/policies/${PropertyRoutePaths.PROPERTY}/${PropertyRoutePaths.PROPERTY_PROPOSAL}`,
  },
];
