import {Injectable} from '@angular/core';
import {
  ProposalDto,
  TripDetailDto,
  TravelCoversDto,
  AddonsDiscountsDto,
  InsuringPartyDto,
  InsuredPersonDto,
  ProposalSerializedDto,
} from '@b2b-frontend/core';
import {DateRange} from '@maia/datepickers';
import {DateUtils} from '@atlas/businesstypes';
import {TravelRenewPolicyDto} from '@b2b-frontend/core/src/core-helper/dto/travel-dto/summary-dto/TravelRenewPolicyDto';

@Injectable({
  providedIn: 'root',
})
export class TravelSessionService {
  public data: ProposalDto = {};

  public constructor() {
    this.restoreFromGlobalStorage();
  }

  public setTripDetailDto(tripDetail: TripDetailDto): void {
    this.data.tripDetailDto = tripDetail;
    this.updateGlobalStorage();
  }

  public setTravelCovers(traveleCoverDto: TravelCoversDto): void {
    this.data.travelCoverDto = traveleCoverDto;
    this.updateGlobalStorage();
  }

  public updateAddonDiscountObj(id: number): void {
    if (this.data.addonDiscountDto) {
      if (id === 4) {
        this.data.addonDiscountDto.isEditAddon = true;
        this.data.addonDiscountDto.isEditDiscount = false;
      } else if (id === 5) {
        this.data.addonDiscountDto.isEditAddon = false;
        this.data.addonDiscountDto.isEditDiscount = true;
      }
      this.updateGlobalStorage();
    }
  }

  public setAddonsAndDiscountData(addonsDiscountData: AddonsDiscountsDto): void {
    this.data.addonDiscountDto = addonsDiscountData;
    this.updateGlobalStorage();
  }

  public setInsuringPartDto(insuringPartyObj: InsuringPartyDto): void {
    this.data.insuringPartyDto = insuringPartyObj;
    this.updateGlobalStorage();
  }

  public setInsuredPersonData(InsuredPersonData: InsuredPersonDto): void {
    this.data.insuredPersonDto = InsuredPersonData;
    this.updateGlobalStorage();
  }

  public setTravelRenewPolicyData(traveleRenewPolicyDto: TravelRenewPolicyDto): void {
    this.data.travelRenewPolicyDto = traveleRenewPolicyDto;
    this.updateGlobalStorage();
  }

  public restoreFromGlobalStorage(): ProposalDto {
    const travelJson = sessionStorage.getItem('travel');
    if (travelJson) {
      const serializedData = JSON.parse(travelJson) as ProposalSerializedDto;
      this.data = this.unserializeTravelData(serializedData);
    }
    return this.data;
  }

  public updateGlobalStorage(): void {
    const serialized = this.serialize(this.data);
    sessionStorage.setItem('travel', JSON.stringify(serialized));
  }

  public getTravelData(): ProposalDto {
    const travelJson = sessionStorage.getItem('travel');
    if (travelJson) {
      return this.data;
    } else {
      this.data = {};
      this.updateGlobalStorage();
      return this.data;
    }
  }

  public isStepDone(step: number | string): boolean {
    return typeof step === 'number' ? `step${step}` in this.data : step in this.data;
  }

  private serialize(data: ProposalDto): ProposalSerializedDto {
    let serializedTripDetailStep: any;
    if (data.tripDetailDto) {
      if (data.tripDetailDto.singleTrip) {
        serializedTripDetailStep = {
          ...data.tripDetailDto,
          singleTrip: {
            startEndData: {
              start: DateUtils.serialize(data.tripDetailDto.singleTrip.startEndData.start),
              end: DateUtils.serialize(data.tripDetailDto.singleTrip.startEndData.end),
            },
            time: data.tripDetailDto.singleTrip.time,
            travelAbroadRadioGroup: data.tripDetailDto.singleTrip.travelAbroadRadioGroup,
          },
        };
      } else if (data.tripDetailDto.multiTrip) {
        serializedTripDetailStep = {
          ...data.tripDetailDto,
          multiTrip: {
            multipleStartDate: DateUtils.serialize(data.tripDetailDto.multiTrip.multipleStartDate),
            multipleEndDate: DateUtils.serialize(data.tripDetailDto.multiTrip.multipleEndDate),
            daysAbroad: data.tripDetailDto.multiTrip.daysAbroad,
          },
        };
      }
    }

    return {
      ...data,
      tripDetailDto: serializedTripDetailStep,
    };
  }

  private unserializeTravelData(serializedData: ProposalSerializedDto): ProposalDto {
    let unSerializedTripDetailDto: TripDetailDto | undefined;
    if (serializedData.tripDetailDto) {
      if (serializedData.tripDetailDto.singleTrip) {
        unSerializedTripDetailDto = {
          ...serializedData.tripDetailDto,
          singleTrip: {
            startEndData: new DateRange(
              DateUtils.unserialize(serializedData.tripDetailDto.singleTrip.startEndData.start),
              DateUtils.unserialize(serializedData.tripDetailDto.singleTrip.startEndData.end),
            ),
            time: serializedData.tripDetailDto.singleTrip.time,
            travelAbroadRadioGroup: serializedData.tripDetailDto.singleTrip.travelAbroadRadioGroup,
          },
        };
      } else if (serializedData.tripDetailDto.multiTrip) {
        unSerializedTripDetailDto = {
          ...serializedData.tripDetailDto,
          multiTrip: {
            multipleStartDate: DateUtils.unserialize(
              serializedData.tripDetailDto.multiTrip.multipleStartDate,
            ),
            multipleEndDate: DateUtils.unserialize(
              serializedData.tripDetailDto.multiTrip.multipleEndDate,
            ),
            daysAbroad: serializedData.tripDetailDto.multiTrip.daysAbroad,
          },
        };
      }
    }

    const unSerialized: ProposalDto = {
      ...serializedData,
      tripDetailDto: unSerializedTripDetailDto,
    };

    const serialized: ProposalDto = {
      ...serializedData,
    };

    return unSerializedTripDetailDto ? unSerialized : serialized;
  }
}
