import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {PhoneNumber} from '@atlas/businesstypes';
import {PhoneNumberValue} from '@maia/input-phonenumber';

export interface BgPhoneNumberValidatorOptions {
  allowZero: boolean;
}

export function bgPhoneNumberValidator(opts?: BgPhoneNumberValidatorOptions): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as PhoneNumber | PhoneNumberValue;
    let result: ValidationErrors | null = null;
    const allowZero = opts ? opts.allowZero : false;
    let pattern = /^(\+359)[89](\d{8})$/;
    if (allowZero) {
      pattern = /^(\+359)0?[89](\d{8})$/;
    }

    if (value !== null && value !== undefined) {
      control.markAsTouched();
      let phoneString: string;
      if (value instanceof PhoneNumber) {
        phoneString = value.asString();
      } else {
        phoneString = value.prefix + value.number;
      }

      if (phoneString !== '') {
        if (pattern.test(phoneString)) {
          result = null;
        } else {
          result = {invalid: true};
        }
      }
    }

    return result;
  };
}
