import {Decimal} from '@atlas/businesstypes';
import {Amount, InstalmentData, Columns} from '../../../../contracts/instalment-payment/instalment';

export const MOCK_PREMIUM_PRICE: Amount = {
  value: new Decimal('000.00'),
  currency: 'BGN',
};

export const MOCK_INSTALMENT_DATA: InstalmentData[] = [
  {
    policyName: 'Property Insurance',
    policyNo: '85420000001238',
    instalmentList: [
      {
        id: 1,
        instalmentNumber: 2,
        instalment: 'instalment2',
        dueDate: '06/12/2021',
        status: 'due',
        amount: 324.02,
        currency: 'BGN',
        visible: false,
      },
      {
        id: 2,
        instalmentNumber: 3,
        instalment: 'instalment3',
        dueDate: '07/12/2021',
        status: 'upcoming',
        amount: 324.02,
        currency: 'BGN',
        visible: false,
      },
      {
        id: 3,
        instalmentNumber: 4,
        instalment: 'instalment4',
        dueDate: '08/12/2021',
        status: 'upcoming',
        amount: 324.02,
        currency: 'BGN',
        visible: false,
      },
    ],
  },
  {
    policyName: 'Motor Thrid Party Liability Insurance',
    policyNo: 'BG/06/120000001238',
    instalmentList: [
      {
        id: 1,
        instalmentNumber: 2,
        instalment: 'instalment2',
        dueDate: '06/12/2021',
        status: 'pending',
        amount: 324.02,
        currency: 'BGN',
        visible: false,
      },
      {
        id: 2,
        instalmentNumber: 3,
        instalment: 'instalment3',
        dueDate: '07/11/2022',
        status: 'upcoming',
        amount: 324.02,
        currency: 'BGN',
        visible: false,
      },
      {
        id: 3,
        instalmentNumber: 4,
        instalment: 'instalment4',
        dueDate: '08/11/2022',
        status: 'upcoming',
        amount: 324.02,
        currency: 'BGN',
        visible: false,
      },
    ],
  },
  {
    policyName: 'Motor Thrid Party Liability Insurance',
    policyNo: 'BG/06/120000001345',
    instalmentList: [
      {
        id: 1,
        instalmentNumber: 2,
        instalment: 'instalment2',
        dueDate: '12/12/2022',
        status: 'due',
        amount: 324.02,
        currency: 'BGN',
        visible: false,
      },
      {
        id: 2,
        instalmentNumber: 3,
        instalment: 'instalment3',
        dueDate: '15/12/2022',
        status: 'upcoming',
        amount: 324.02,
        currency: 'BGN',
        visible: false,
      },
    ],
  },
];

export const MOCK_COLUMN_DATA: Columns[] = [
  {id: 11, headerName: 'Instalment', headerValue: 'instalment'},
  {id: 21, headerName: 'Due date', headerValue: 'dueDate'},
  {id: 31, headerName: 'Status', headerValue: 'status'},
  {id: 41, headerName: 'Amount', headerValue: 'amount'},
  {id: 51, headerName: 'Currency', headerValue: 'currency'},
];
