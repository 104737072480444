import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AgentsbyNumberOptions, AgentsNumber} from '../../../../constants/account-creation';
import {AgentSelectionOption} from '../../../../models/b2b/AgentSelectionOption';

@Component({
  selector: 'nje-agent-by-agent-number',
  templateUrl: './agent-by-agent-number.component.html',
  styleUrls: ['./agent-by-agent-number.component.scss'],
})
export class AgentByAgentNumberComponent implements OnInit {
  public value: string;
  public agentsNumber = AgentsNumber;
  public agentsbyNumberOptions = AgentsbyNumberOptions;
  public agentForm: FormGroup;
  public selectedAgentType: AgentSelectionOption | undefined;

  public constructor(private readonly _fb: FormBuilder) {}

  public ngOnInit(): void {
    this.agentForm = this._fb.group({
      agentOption: [[], [Validators.required]],
    });
    this.agentForm.controls.agentOption.valueChanges.subscribe(value => {
      this.selectedAgentType = this.agentsbyNumberOptions.find(
        agentOption => agentOption.title === value,
      );
    });
  }
}
