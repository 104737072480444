/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';

import {AdminLibrary, AdminLibrarySlideinInput} from '../../../../contracts/adminInterface';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {
  DESCRIPTION_LENGTH,
  DESCRIPTION_PATTERN,
  TITLE_LENGTH,
  TITLE_PATTERN,
} from '../../../../constants/resource-management/constant';

@Component({
  selector: 'nje-library-slidein',
  templateUrl: './library-slidein.component.html',
  styleUrls: ['./library-slidein.component.scss'],
})
@UntilDestroy()
export class LibrarySlideinComponent
  extends ModalContentComponent<AdminLibrary, AdminLibrarySlideinInput>
  implements OnInit
{
  public LibrarySlideinForm: FormGroup;
  public libraryObj: AdminLibrary;
  public existingLibraryTitles: string[] = [];

  public titleLimit: number = TITLE_LENGTH;
  public descriptionLimit: number = DESCRIPTION_LENGTH;

  public constructor(
    private readonly _formBuilder: FormBuilder,
    public control: ModalControl<AdminLibrary, AdminLibrarySlideinInput>,
  ) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    if (data && data.selectedLibrary && data.existingLibraryListTitles) {
      this.libraryObj = data.selectedLibrary;
      this.existingLibraryTitles = data.existingLibraryListTitles;
    }
    this.LibrarySlideinForm = this._initializeLibrarySlideinForm(this.libraryObj);
  }

  public isNewLibraryList(): boolean {
    return this.control.input !== undefined;
  }

  private _initializeLibrarySlideinForm(formdata?: AdminLibrary): FormGroup {
    return (this.LibrarySlideinForm = this._formBuilder.group({
      id: new FormControl(formdata?.id),
      title: new FormControl(formdata?.i18n?.en?.title, [
        Validators.required,
        this.uniqueTitleValidator.bind(this),
        Validators.maxLength(TITLE_LENGTH),
        Validators.pattern(TITLE_PATTERN),
      ]),
      description: new FormControl(formdata?.i18n?.en?.description, [
        Validators.required,
        Validators.maxLength(DESCRIPTION_LENGTH),
        Validators.pattern(DESCRIPTION_PATTERN),
      ]),
      selectedStatus: new FormControl(formdata?.selectedStatus),
    }));
  }

  private uniqueTitleValidator(control: FormControl) {
    if (control.value && this.existingLibraryTitles.length > 0) {
      const alreadyExists = this.existingLibraryTitles.indexOf(control.value);
      if (alreadyExists !== -1) {
        return {duplicate: true};
      }
    }
    return null;
  }

  public addOrUpdateLibraryList(): void {
    let libraryData = this.LibrarySlideinForm.value as AdminLibrary;
    if (libraryData.id === undefined || libraryData.id === null) {
      libraryData = {
        title: libraryData.title,
        description: libraryData.description,
        i18n: {
          bg: {
            title: libraryData.title!,
            description: libraryData.description!,
          },
          en: {
            title: libraryData.title!,
            description: libraryData.description!,
          },
        },
      } as AdminLibrary;
    } else {
      this.libraryObj.title = libraryData.title;
      this.libraryObj.description = libraryData.description;
      this.libraryObj.i18n!.en.title = libraryData.title!;
      this.libraryObj.i18n!.en.description = libraryData.description!;
      this.libraryObj.id = libraryData.id;
      libraryData = this.libraryObj;
    }
    this.control.confirm(libraryData);
  }

  public cancel(): void {
    this.control.cancel();
  }
}
