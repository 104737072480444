import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {searchParameters} from '../../../../constants/groups-and-modules';
import {ModulesDetails} from '../../../../models/b2b/ModulesDetails';

@Component({
  selector: 'nje-new-group',
  templateUrl: './new-group.component.html',
  styleUrls: ['./new-group.component.scss'],
})
export class NewGroupComponent implements OnInit {
  public searchByValues: string[] = searchParameters;
  public searchAndAddByValues: string;
  public searchBoxValue: string = '';
  public isSearchTrue: boolean = false;
  public isShowAddedModules: boolean = false;
  public module: ModulesDetails;
  public searchForm: FormGroup;

  public ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchBox: new FormControl('', [Validators.required]),
    });
  }

  public addModule(module: ModulesDetails): void {
    this.module = module;
    if (this.module) {
      this.isShowAddedModules = true;
    }
  }

  public getSearchPlaceholder(): string {
    return this.searchAndAddByValues;
  }
}
