import {CommonModule} from '@angular/common';
import {Inject, ModuleWithProviders, NgModule, Optional} from '@angular/core';
import {FormsModule as AngularFormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {B2bCoreModule} from '@b2b-frontend/core';
import {μExampleSvgModule} from '@b2b-frontend/icons';
import {LayoutModule as MaiaLayoutModule} from '@maia/layout';
import {TextAreaModule as MaiaTextAreaModule} from '@maia/text-area';
import {ButtonsModule} from '@maia/buttons';
import {RadioButtonsModule as MaiaRadioButtonsModule} from '@maia/radio-buttons';
import {SlideInsModule as MaiaSlideInsModule} from '@maia/slide-ins';
import {PopUpsModule as MaiaPopUpsModule} from '@maia/pop-ups';
import {SwitchesModule} from '@maia/switches';
import {NjfRadioButtonsModule} from '@njf-frontend-angular/radio-buttons';
import {DatePickersModule} from '@maia/datepickers';
import {DividerModule as MaiaDividerModule} from '@maia/divider';
import {DropdownsModule} from '@maia/dropdowns';
import {InputSelectModule as MaiaInputSelectModule} from '@maia/input-select';
import {FlexGridModule as MaiaFlexGridModule} from '@maia/flex-grid';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {PanelsModule as MaiaPanelsModule} from '@maia/panels';
import {TilesModule as MaiaTilesModule} from '@maia/tiles';
import {StampsModule as MaiaStampsModule} from '@maia/stamps';
import {TextListsModule as MaiaTextListsModule} from '@maia/text-lists';
import {
  ExpandedFlowProgressWithRoutingModule,
  FlowProgressModule,
} from '@njf-frontend-angular/flow-progress';
import {PaginationModule as MaiaPaginationModule} from '@maia/pagination';
import {TablesModule as MaiaTablesModule} from '@maia/tables';
import {InputAutocompleteModule as MaiaInputAutocompleteModule} from '@maia/input-autocomplete';
import {AdditionalInformationModule as MaiaAdditionalInformationModule} from '@maia/additional-information';
import {NameValueCollectionsModule as MaiaNameValueCollectionsModule} from '@maia/name-value-collections';

import {PropertyPolicyService} from './property-core/services/property-policy.service';
import {PropertyProcessService} from './property-core/services/property-process.service';
import {
  BASE_ROUTE,
  PropertyInsuranceStarterService,
} from './property-core/services/starter.service';
import {StepResolverService} from './property-core/services/step.resolver';
import {PropertyInsuranceComponent} from './property-insurance.component';
import {ConfirmationComponent} from './screens/confirmation/confirmation.component';
import {PropertyCoversAndDiscountsComponent} from './screens/property-covers-and-discounts/property-covers-and-discounts.component';
import {PropertyDetailsComponent} from './screens/property-details/property-details.component';
import {PropertyInsuringPartyComponent} from './screens/property-insuring-party/property-insuring-party.component';
import {PropertyPolicyDetailsComponent} from './screens/property-policy-details/property-policy-details.component';
import {PropertyProposalComponent} from './screens/property-proposal/property-proposal.component';
import {PropertySupplementaryListComponent} from './screens/property-supplementary-list/property-supplementary-list.component';
import {PropertyInsuredPartySlideinComponent} from './screens/property-insured-party-slidein/property-insured-party-slidein.component';
import {RemovePopupComponent} from './screens/remove-popup/remove-popup.component';
import {PropertyFeatureSlideinComponent} from './screens/property-feature-slidein/property-feature-slidein.component';
import {PropertyPolicySuccessComponent} from './screens/property-policy-success/property-policy-success.component';
import {PropertyPolicyErrorComponent} from './screens/property-policy-error/property-policy-error.component';
import {MessagesModule as MaiaMessagesModule} from '@maia/messages';
import {AccordionListsModule as MaiaAccordionListsModule} from '@maia/accordion-lists';
import {PropertySupplementaryListSlideinComponent} from './screens/property-details/property-supplementary-list-slidein/property-supplementary-list-slidein.component';
import {ListsModule} from '@maia/lists';
import {CoversAccordionModule} from '@njf-frontend-angular/covers-accordion';
import {PriceBlockModule as MaiaPriceBlockModule} from '@maia/price-block';
import {PropertyCoverDiscountLayoutComponent} from './screens/property-cover-discount-layout/property-cover-discount-layout.component';
import {ActionsModule} from '@maia/actions';
import {SelectModule} from '@maia/select';
import {PropertyCoversAddEditSlideinComponent} from './screens/property-covers-add-edit-slidein/property-covers-add-edit-slidein.component';
import {UnderwritingLimitsSlideinComponent} from './screens/underwriting-limits-slidein/underwriting-limits-slidein.component';
import {PropertyAddressEditSlideinComponent} from './screens/property-address-edit-slidein/property-address-edit-slidein.component';
import {CoversAddSlideinComponent} from './screens/covers-add-slidein/covers-add-slidein.component';

import {AvatarsModule as MaiaAvatarsModule} from '@maia/avatars';
import {DisplaysModule as MaiaDisplaysModule} from '@maia/displays';
import {BadgeModule as MaiaBadgeModule} from '@maia/badge';
import {HighlightPanelModule as MaiaHighlightPanelModule} from '@maia/highlight-panel';
/**
 * This is the public module of the property-insurance flow.
 *
 * It provides a starter service to encapsulate the flow and start the route.
 *
 * An important note is that we don't inject the dependencies of the flow here but instead do this
 * inside a private module which is defined in the `property-insurance-flow.routing.module.ts` so these
 * dependencies are by default lazy loaded when the flow is injected into an application.
 *
 */
@NgModule({
  providers: [
    PropertyInsuranceStarterService,
    StepResolverService,
    PropertyPolicyService,
    PropertyProcessService,
  ],
  declarations: [
    PropertyInsuranceComponent,
    ConfirmationComponent,
    PropertyDetailsComponent,
    PropertyProposalComponent,
    PropertyPolicyDetailsComponent,
    PropertySupplementaryListComponent,
    PropertyInsuringPartyComponent,
    PropertyCoversAndDiscountsComponent,
    PropertyInsuredPartySlideinComponent,
    RemovePopupComponent,
    PropertyFeatureSlideinComponent,
    PropertySupplementaryListSlideinComponent,
    PropertyPolicySuccessComponent,
    PropertyPolicyErrorComponent,
    PropertyCoverDiscountLayoutComponent,
    PropertyCoversAddEditSlideinComponent,
    UnderwritingLimitsSlideinComponent,
    PropertyAddressEditSlideinComponent,
    CoversAddSlideinComponent,
  ],
  imports: [
    //ANGULAR
    AngularFormsModule,
    ReactiveFormsModule,
    RouterModule,
    CommonModule,
    //KDL ANGULAR COMPONENTS
    FlowProgressModule,
    ExpandedFlowProgressWithRoutingModule,
    //MAIA
    MaiaLayoutModule,
    MaiaTextAreaModule,
    ButtonsModule,
    MaiaRadioButtonsModule,
    MaiaSlideInsModule,
    MaiaPopUpsModule,
    SwitchesModule,
    NjfRadioButtonsModule,
    DatePickersModule,
    MaiaDividerModule,
    DropdownsModule,
    MaiaInputSelectModule,
    MaiaFlexGridModule,
    MaiaFormsModule,
    MaiaPanelsModule,
    //APP PACKAGES
    B2bCoreModule,
    μExampleSvgModule,
    MaiaPaginationModule,
    MaiaTablesModule,
    MaiaInputAutocompleteModule,
    MaiaTilesModule,
    MaiaStampsModule,
    MaiaMessagesModule,
    MaiaAccordionListsModule,
    ListsModule,
    MaiaTextListsModule,
    MaiaPriceBlockModule,
    CoversAccordionModule,
    ButtonsModule,
    MaiaFlexGridModule,
    MaiaFormsModule,
    MaiaInputSelectModule,
    DropdownsModule,
    MaiaPriceBlockModule,
    MaiaDividerModule,
    MaiaTablesModule,
    MaiaPanelsModule,
    MaiaTextListsModule,
    ActionsModule,
    SelectModule,
    SwitchesModule,
    MaiaTilesModule,
    MaiaHighlightPanelModule,
    //APP PACKAGES
    B2bCoreModule,
    MaiaAvatarsModule,
    MaiaDisplaysModule,
    MaiaBadgeModule,
    MaiaAdditionalInformationModule,
    MaiaNameValueCollectionsModule,
  ],
})
export class PropertyInsuranceModule {
  // Static method for injecting the module with a base route provided.
  public static forPath(path: string): ModuleWithProviders<PropertyInsuranceModule> {
    return {
      ngModule: PropertyInsuranceModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error(
        'Do not import PropertyInsuranceModule directly, use PropertyInsuranceModule.forPath() instead',
      );
    }
  }
}
