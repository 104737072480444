import {SellerId} from '../contracts/instalment-payment/instalment';

export const LOGGED_IN_USER: SellerId = {
  id: '77989125',
  name: 'Hristo Tunev',
};
export const USER_ID: string = 'Manu_Vijayan';
export const DIRECT_DEBIT: string = 'Direct Debit';
export const SELLER_UBB: string = 'Seller Id / UBB Agent Number';
export const SELLER: string = 'Seller Id ';
