/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateKey, DateUtils, Text, TextUtils, ExtraDateValidators} from '@atlas/businesstypes';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {DateFormatterService, localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {ReportExecutionConfig} from '@dzi/b2b-ng-bff';
import {OpenUrlService, Target} from '@hermes/open-resources';
import {PopUpController} from '@maia/pop-ups';
import {SlideInController} from '@maia/slide-ins';

import {DownloadReportPopupComponent} from './download-report-popup/download-report-popup.component';
import {ReportFilterSlideinComponent} from './report-filter-slidein/report-filter-slidein.component';
import {CmsStatisticsReportingService} from '../../services/cms-statistics-reporting.service';
import {
  ClaimActivity,
  FilterControlLabel,
  OfferActivity,
  PaymentActivity,
  PolicyActivity,
  ProductType,
  ReportExecuteResponseDto,
  ReportType,
  TableHeaders,
  UserActivity,
} from '../../contracts/statistics-reporting.interface';
import {
  ALL,
  ASC,
  BLANK_VALUE,
  CLAIM_ACTIVITY_TABLE_COL_HEADERS,
  CLAIM_PRODUCT_TYPE,
  DESC,
  OFFER_ACTIVITY_TABLE_COL_HEADERS,
  PAGINATION,
  PAYMENTS_ACTIVITY_TABLE_COL_HEADERS,
  POLICY_ACTIVITY_TABLE_COL_HEADERS,
  PRODUCT_TYPE,
  REPORT_COUNT_LABEL,
  REPORT_FETCH_MODE,
  ReportTypes,
  SEARCHBAR_PLACEHOLDER,
  SORTING_DIRECTION,
  USER_ACTIVITY_TABLE_COL_HEADERS,
  YES,
} from '../../constants/statistics-reporting/statistics-reporting-constants';
import {ModalResolution} from '@maia/modals';
import {map} from 'rxjs/operators';
import {StatisticsReportingSessionService} from '../../services/statistics-reporting-session.service';
import {Language} from '../../constants/lang';

@Component({
  selector: 'nje-statistics-reporting',
  templateUrl: './statistics-reporting.component.html',
  styleUrls: ['./statistics-reporting.component.scss'],
})
@UntilDestroy()
export class StatisticsReportingComponent implements OnInit {
  public showLoadingIcon: boolean = false;
  public lang: string | null = sessionStorage.getItem('lang');
  // Policy Filter Form
  public policyFilterForm: FormGroup;
  public reportTypeList: ReportType[] = [];
  public productTypeList: ProductType[] = PRODUCT_TYPE;
  public maxFromDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxToDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public minToDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public reportCountLabel: string = BLANK_VALUE;
  public sortingDirectionList: FilterControlLabel[] = SORTING_DIRECTION;
  public sortingDirection: FilterControlLabel = {label: '', value: '', labelbg: ''};
  public readonly reportTypes = ReportTypes;
  public readonly reportFetchMode = REPORT_FETCH_MODE;
  public selectedReportType: string;
  public page = PAGINATION.DEFAULT_PAGE;
  public pages = PAGINATION.PAGES;
  public pageSize = PAGINATION.PAGE_SIZE;
  public inputText: Text;
  public searchInputValue: string;
  public isFilterButtonShown: boolean = true;

  private readonly _downloadReportPopupFactory: ComponentFactory<DownloadReportPopupComponent>;
  private readonly _reportFilterSlideInFactory: ComponentFactory<ReportFilterSlideinComponent>;

  public columnHeaders: TableHeaders[];
  public tableRows: number[] = [];
  public tableColumns: number[] = [];
  public policyActivityReportData: PolicyActivity[] = [];
  public offerActivityReportData: OfferActivity[] = [];
  public userActivityReportData: UserActivity[] = [];
  public claimActivityReportData: ClaimActivity[] = [];
  public paymentActivityReportData: PaymentActivity[] = [];

  public showProductTypeFilter: boolean = true;
  public showReportTableSection: boolean = false;
  private _filterFormData: any;
  public searchBarPlaceholder = new localeValPipe(new LocalizationService()).transform(
    SEARCHBAR_PLACEHOLDER.ALL,
    this.lang,
  );
  public filterConfigObject: ReportExecutionConfig;
  public isSearchFilled: boolean = false;

  public constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _dateFormatterService: DateFormatterService,
    private readonly _popUpCtrl: PopUpController,
    private readonly _statisticsReportingService: CmsStatisticsReportingService,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _sessionService: StatisticsReportingSessionService,
    private readonly _openUrlService: OpenUrlService,
  ) {
    this._downloadReportPopupFactory = this._componentFactoryResolver.resolveComponentFactory(
      DownloadReportPopupComponent,
    );
    this._reportFilterSlideInFactory = this._componentFactoryResolver.resolveComponentFactory(
      ReportFilterSlideinComponent,
    );
  }

  public ngOnInit(): void {
    this._statisticsReportingService.getReportTypeList().subscribe(result => {
      this.reportTypeList = result;
    });
    this._initializePolicyFilterForm();
  }

  public lastPage(): boolean {
    return this.page >= this.pages;
  }

  public pageChange(page: number): void {
    this.page = page;
    return this.isSearchFilled
      ? this.getReportDetails(this.reportFetchMode.SEARCH, page)
      : this.getReportDetails(this.reportFetchMode.PAGINATION, page);
  }

  public getReportDetails(reportFetchMode?: string, page?: number): void {
    this._setTableColumnHeaders();
    this.showReportTableSection = true;
    if (reportFetchMode == this.reportFetchMode.VIA_SUBMIT_BUTTON) {
      this._sessionService.clearFilterData();
      this.searchInputValue = BLANK_VALUE;
    }
    if (
      reportFetchMode === this.reportFetchMode.VIA_SUBMIT_BUTTON ||
      reportFetchMode === this.reportFetchMode.FILTER ||
      reportFetchMode === this.reportFetchMode.SEARCH
    ) {
      this.page = page != null ? page : PAGINATION.DEFAULT_PAGE;
      this.pages = PAGINATION.PAGES;
    }
    const filterConfig = this._createFilterConfig(reportFetchMode, page);
    const config = encodeURIComponent(JSON.stringify(filterConfig));

    this.showLoadingIcon = true;
    this._statisticsReportingService
      .getReportData(this.selectedReportType, config)
      .pipe(
        takeUntilDestroyed(this),
        map((result: ReportExecuteResponseDto) => {
          this.reportCountLabel = this.reportCountLabel + result.totalResults.toString();
          this.pages = this._getTotalPagesCount(result.totalResults);
          this._setReportResponseData(result);
          this.showLoadingIcon = false;
          this._cdRef.detectChanges();
        }),
      )
      .subscribe(
        () => {
          this.showLoadingIcon = false;
        },
        (error: any) => {
          this.showLoadingIcon = false;
        },
      );
    this._cdRef.detectChanges();
  }

  public handleInputText(text: Text): void {
    this.inputText = text;
    if (text === null) {
      this.searchInputValue = BLANK_VALUE;
      this.searchTable();
    }
  }

  public handleSearchText(text: Text): void {
    this.inputText = text;
    this.searchInputValue = TextUtils.getValueTrimmed(text);
    this.searchTable();
  }

  public searchTable(): void {
    this.isSearchFilled = true;
    this.getReportDetails(REPORT_FETCH_MODE.SEARCH);
  }

  public sortTable(): void {
    this.getReportDetails(REPORT_FETCH_MODE.SORT, this.page);
  }

  public downloadReport(): void {
    const filterConfig = this._createFilterConfig(REPORT_FETCH_MODE.XLS_DOWNLOAD);
    const config = encodeURIComponent(JSON.stringify(filterConfig));
    const xlsDownloadURL = this._statisticsReportingService.getDownloadReportURL(
      this.selectedReportType,
      config,
    );
    this._openUrlService.open(xlsDownloadURL, undefined, Target.SELF);

    this._popUpCtrl
      .prepare(
        this._downloadReportPopupFactory,
        this._injector,
        {title: BLANK_VALUE, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: BLANK_VALUE,
        },
      )
      .subscribe(() => {
        this._cdRef.detectChanges();
      });
  }

  public isSubmitButtonDisabled(): boolean {
    if (this.showProductTypeFilter) {
      return (
        this.policyFilterForm.invalid ||
        this.policyFilterForm.controls.reportType.value === null ||
        this.policyFilterForm.controls.productType.value === null
      );
    } else {
      return (
        this.policyFilterForm.invalid || this.policyFilterForm.controls.reportType.value === null
      );
    }
  }

  public applyFilter(): void {
    this._slideInCtrl
      .prepare(
        this._reportFilterSlideInFactory,
        this._injector,
        {title: new localeValPipe(new LocalizationService()).transform('Filter', this.lang)},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: this.selectedReportType,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe(response => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          this._filterFormData = response.result;
          this.getReportDetails(REPORT_FETCH_MODE.FILTER);
        }
        this._cdRef.detectChanges();
      });
  }

  private _initializePolicyFilterForm(): void {
    this.policyFilterForm = this._formBuilder.group({
      reportType: new FormControl(undefined),
      productType: new FormControl(this.productTypeList[0]),
      fromDate: new FormControl(this.maxFromDate, [
        Validators.required,
        control =>
          ExtraDateValidators.after(DateUtils.add(this.maxFromDate, DateKey.Year, -30))(
            control.value,
          ),
        control =>
          ExtraDateValidators.before(DateUtils.add(this.maxFromDate, DateKey.Day, 1))(
            control.value,
          ),
      ]),
      toDate: new FormControl(this.maxToDate, [
        Validators.required,
        control =>
          ExtraDateValidators.after(DateUtils.add(this.minToDate, DateKey.Day, -1))(control.value),
        control =>
          ExtraDateValidators.before(DateUtils.add(this.maxToDate, DateKey.Day, 1))(control.value),
      ]),
    });

    this.policyFilterForm.controls.reportType.valueChanges.subscribe((report: ReportType) => {
      this.showProductTypeFilter = report.id != ReportTypes.UserActivity;
      this.isFilterButtonShown = report.id != ReportTypes.ClaimActivity;
      this.policyFilterForm.controls.productType.reset(this.productTypeList[0]);
      this.policyFilterForm.controls.fromDate.reset(DateUtils.today());
      this.policyFilterForm.controls.toDate.reset(DateUtils.today());
      this.showReportTableSection = false;
      this.productTypeList =
        report.id != ReportTypes.ClaimActivity ? PRODUCT_TYPE : CLAIM_PRODUCT_TYPE;
    });
  }

  private _createFilterConfig(reportFetchMode?: string, page?: number): ReportExecutionConfig {
    const productType = this.showProductTypeFilter
      ? (this.policyFilterForm.controls.productType.value as ProductType).id
      : BLANK_VALUE;
    const filterValueLowerCase = ASC === this.sortingDirection.value.toLowerCase() ? ASC : DESC;
    this.filterConfigObject = {
      sort: [
        {
          field: 'created_date',
          dir: filterValueLowerCase,
        },
      ],
      filter: {
        product_code: productType,
        start_date: this._dateFormatterService.dateToISO(
          this.policyFilterForm.controls.fromDate.value,
        ),
        end_date: this._dateFormatterService.dateToISO(this.policyFilterForm.controls.toDate.value),
      },
      pagination: {
        page: page !== undefined ? page : PAGINATION.DEFAULT_PAGE,
        size: this.pageSize,
      },
      search: this.searchInputValue ? this.searchInputValue : BLANK_VALUE,
    };

    if (REPORT_FETCH_MODE.XLS_DOWNLOAD === reportFetchMode) {
      delete this.filterConfigObject?.pagination;
    }

    if (
      ReportTypes.ClaimActivity === this.selectedReportType &&
      ALL !== (this.policyFilterForm.controls.productType.value as ProductType).title
    ) {
      delete this.filterConfigObject?.filter?.product_code;
      Object.assign(this.filterConfigObject?.filter, {
        product_type: productType,
      });
    }
    if (
      ReportTypes.ClaimActivity === this.selectedReportType &&
      ALL === (this.policyFilterForm.controls.productType.value as ProductType).title
    ) {
      delete this.filterConfigObject?.filter?.product_type;
      Object.assign(this.filterConfigObject?.filter, {
        product_type: [CLAIM_PRODUCT_TYPE[1].id, CLAIM_PRODUCT_TYPE[2].id],
      });
    }

    if (
      ReportTypes.UserActivity === this.selectedReportType ||
      ALL === (this.policyFilterForm.controls.productType.value as ProductType).title
    ) {
      delete this.filterConfigObject?.filter?.product_code;
    }
    this._createFilterConfigForFilter(reportFetchMode, this.filterConfigObject);
    this._createFilterConfigForPagination(reportFetchMode, this.filterConfigObject);
    return this.filterConfigObject;
  }

  private _createFilterConfigForFilter(
    reportFetchMode?: string,
    filterConfigObject?: ReportExecutionConfig,
  ): void {
    if (REPORT_FETCH_MODE.FILTER === reportFetchMode) {
      switch (this.selectedReportType) {
        case ReportTypes.PolicyActivity:
          if (this._filterFormData.registeredUser !== null) {
            Object.assign(this.filterConfigObject.filter, {
              registered_user: YES === this._filterFormData.registeredUser,
            });
          } else {
            delete this.filterConfigObject?.filter?.registered_user;
          }
          break;
        case ReportTypes.UserActivity:
          if (this._filterFormData.registeredUser !== null) {
            Object.assign(this.filterConfigObject.filter, {
              registered_user: YES === this._filterFormData.registeredUser,
            });
          }
          if (this._filterFormData.isDirectMarketingUser !== null) {
            Object.assign(this.filterConfigObject.filter, {
              has_direct_marketing_consent: YES === this._filterFormData.isDirectMarketingUser,
            });
          }
          if (this._filterFormData.displayUniqueRecords !== null) {
            Object.assign(this.filterConfigObject.filter, {
              unique_only: YES === this._filterFormData.displayUniqueRecords,
            });
          }
          break;
        case ReportTypes.OfferActivity:
          if (this._filterFormData.registeredUser !== null) {
            Object.assign(this.filterConfigObject.filter, {
              registered_user: YES === this._filterFormData.registeredUser,
            });
          }
          if (this._filterFormData.policyCreated !== null) {
            Object.assign(this.filterConfigObject.filter, {
              policy_created: YES === this._filterFormData.policyCreated,
            });
          }
          break;
        case ReportTypes.ClaimActivity:
          if (this._filterFormData.insisSource !== null) {
            Object.assign(this.filterConfigObject.filter, {
              insis_source: this._filterFormData.insisSource,
            });
          }
          break;
        case ReportTypes.PaymentActivity:
          if (this._filterFormData.registeredUser !== null) {
            Object.assign(this.filterConfigObject.filter, {
              registered_user: YES === this._filterFormData.registeredUser,
            });
          }
          if (this._filterFormData.paymentProvider !== null) {
            Object.assign(this.filterConfigObject.filter, {
              payment_provider: this._filterFormData.paymentProvider,
            });
          }
          if (this._filterFormData.insisSource !== null) {
            Object.assign(this.filterConfigObject.filter, {
              insis_source: this._filterFormData.insisSource,
            });
          }
          if (this._filterFormData.paymentStatus !== null) {
            Object.assign(this.filterConfigObject.filter, {
              status: this._filterFormData.paymentStatus,
            });
          }
          if (this._filterFormData.paymentType !== null) {
            Object.assign(this.filterConfigObject.filter, {
              payment_type: this._filterFormData.paymentType,
            });
          }
          break;
      }
    }
  }

  private _createFilterConfigForPagination(
    reportFetchMode?: string,
    filterConfigObject?: ReportExecutionConfig,
  ): void {
    if (
      REPORT_FETCH_MODE.PAGINATION === reportFetchMode ||
      REPORT_FETCH_MODE.SEARCH === reportFetchMode ||
      REPORT_FETCH_MODE.SORT
    ) {
      const data = this._sessionService.getData();
      switch (this.selectedReportType) {
        case ReportTypes.PolicyActivity:
          if (data.policyActivityFilter && data.policyActivityFilter.registeredUser !== null) {
            Object.assign(filterConfigObject?.filter, {
              registered_user: YES === data.policyActivityFilter.registeredUser,
            });
          }
          break;
        case ReportTypes.UserActivity:
          if (data.userActivityFilter) {
            if (data.userActivityFilter.registeredUser !== null) {
              Object.assign(filterConfigObject?.filter, {
                registered_user: YES === data.userActivityFilter.registeredUser,
              });
            }
            if (data.userActivityFilter.isDirectMarketingUser !== null) {
              Object.assign(filterConfigObject?.filter, {
                has_direct_marketing_consent: YES === data.userActivityFilter.isDirectMarketingUser,
              });
            }
            if (data.userActivityFilter.displayUniqueRecords !== null) {
              Object.assign(filterConfigObject?.filter, {
                unique_only: YES === data.userActivityFilter.displayUniqueRecords,
              });
            }
          }
          break;
        case ReportTypes.OfferActivity:
          if (data.offerActivityFilter) {
            if (data.offerActivityFilter.registeredUser !== null) {
              Object.assign(filterConfigObject?.filter, {
                registered_user: YES === data.offerActivityFilter.registeredUser,
              });
            }
            if (data.offerActivityFilter.policyCreated !== null) {
              Object.assign(filterConfigObject?.filter, {
                policy_created: YES === data.offerActivityFilter.policyCreated,
              });
            }
          }
          break;
        case ReportTypes.ClaimActivity:
          if (data.claimActivityFilter && data.claimActivityFilter.insisSource !== null) {
            Object.assign(filterConfigObject?.filter, {
              insis_source: data.claimActivityFilter.insisSource,
            });
          }
          break;
        case ReportTypes.PaymentActivity:
          if (data.paymentActivityFilter) {
            if (data.paymentActivityFilter.registeredUser !== null) {
              Object.assign(filterConfigObject?.filter, {
                registered_user: YES === data.paymentActivityFilter.registeredUser,
              });
            }
            if (data.paymentActivityFilter.paymentProvider !== null) {
              Object.assign(filterConfigObject?.filter, {
                payment_provider: data.paymentActivityFilter.paymentProvider,
              });
            }
            if (data.paymentActivityFilter.insisSource !== null) {
              Object.assign(filterConfigObject?.filter, {
                insis_source: data.paymentActivityFilter.insisSource,
              });
            }
            if (data.paymentActivityFilter.paymentStatus !== null) {
              Object.assign(filterConfigObject?.filter, {
                status: data.paymentActivityFilter.paymentStatus,
              });
            }
            if (data.paymentActivityFilter.paymentType !== null) {
              Object.assign(filterConfigObject?.filter, {
                payment_type: data.paymentActivityFilter.paymentType,
              });
            }
          }
          break;
      }
    }
  }

  private _setReportResponseData(result: ReportExecuteResponseDto): void {
    switch (this.selectedReportType) {
      case ReportTypes.PolicyActivity:
        this.policyActivityReportData = result.data as PolicyActivity[];
        break;
      case ReportTypes.OfferActivity:
        this.offerActivityReportData = result.data as OfferActivity[];
        break;
      case ReportTypes.ClaimActivity:
        this.claimActivityReportData = result.data as ClaimActivity[];
        break;
      case ReportTypes.UserActivity:
        this.userActivityReportData = result.data as UserActivity[];
        break;
      case ReportTypes.PaymentActivity:
        this.paymentActivityReportData = result.data as PaymentActivity[];
        break;
    }
    this._cdRef.detectChanges();
  }

  private _getTotalPagesCount(totalRecords: number): number {
    return totalRecords % this.pageSize === 0
      ? totalRecords / this.pageSize
      : Math.ceil(totalRecords / this.pageSize);
  }

  private _setTableColumnHeaders(): void {
    switch ((this.policyFilterForm.controls.reportType.value as ReportType).id.toString()) {
      case ReportTypes.PolicyActivity:
        this.columnHeaders = POLICY_ACTIVITY_TABLE_COL_HEADERS;
        this.selectedReportType = this.reportTypes.PolicyActivity;
        this.reportCountLabel = new localeValPipe(new LocalizationService()).transform(
          REPORT_COUNT_LABEL.POLICIES_CREATED,
          this.lang,
        );
        this.searchBarPlaceholder = new localeValPipe(new LocalizationService()).transform(
          SEARCHBAR_PLACEHOLDER.POLICY_ACTIVITY,
          this.lang,
        );
        break;
      case ReportTypes.OfferActivity:
        this.columnHeaders = OFFER_ACTIVITY_TABLE_COL_HEADERS;
        this.selectedReportType = this.reportTypes.OfferActivity;
        this.searchBarPlaceholder = new localeValPipe(new LocalizationService()).transform(
          SEARCHBAR_PLACEHOLDER.OFFER_ACTIVITY,
          this.lang,
        );
        this.reportCountLabel = this.searchBarPlaceholder = new localeValPipe(
          new LocalizationService(),
        ).transform(REPORT_COUNT_LABEL.OFFER_ACTIVITY, this.lang);
        break;
      case ReportTypes.ClaimActivity:
        this.columnHeaders = CLAIM_ACTIVITY_TABLE_COL_HEADERS;
        this.selectedReportType = this.reportTypes.ClaimActivity;
        this.reportCountLabel = new localeValPipe(new LocalizationService()).transform(
          REPORT_COUNT_LABEL.CLAIMS_CHECK,
          this.lang,
        );
        this.searchBarPlaceholder = new localeValPipe(new LocalizationService()).transform(
          SEARCHBAR_PLACEHOLDER.CLAIMS_ACTIVITY,
          this.lang,
        );
        break;
      case ReportTypes.UserActivity:
        this.columnHeaders = USER_ACTIVITY_TABLE_COL_HEADERS;
        this.selectedReportType = this.reportTypes.UserActivity;
        this.reportCountLabel = new localeValPipe(new LocalizationService()).transform(
          REPORT_COUNT_LABEL.USER_COUNT,
          this.lang,
        );
        this.searchBarPlaceholder = new localeValPipe(new LocalizationService()).transform(
          SEARCHBAR_PLACEHOLDER.USER_ACTIVITY,
          this.lang,
        );
        break;
      case ReportTypes.PaymentActivity:
        this.columnHeaders = PAYMENTS_ACTIVITY_TABLE_COL_HEADERS;
        this.selectedReportType = this.reportTypes.PaymentActivity;
        this.searchBarPlaceholder = new localeValPipe(new LocalizationService()).transform(
          SEARCHBAR_PLACEHOLDER.PAYMENTS,
          this.lang,
        );
        this.reportCountLabel = new localeValPipe(new LocalizationService()).transform(
          REPORT_COUNT_LABEL.PAYMENT_ACTIVITY,
          this.lang,
        );
        break;
    }
  }

  public isLanguageEnglish(): boolean {
    return this.lang === Language.English;
  }

  public getProductType(product: ProductType): string {
    return this.isLanguageEnglish() ? product.title : product.titlebg;
  }

  public getSortingList(filter: FilterControlLabel): string {
    return this.isLanguageEnglish() ? filter.label : filter.labelbg;
  }

  public getTableHeaders(header: TableHeaders): string {
    return this.isLanguageEnglish() ? header.headeren : header.headerbg;
  }

  public onChangeOfFromDate(): void {
    // Get the difference between today's date and the selected fromDate
    if (!this.policyFilterForm.invalid) {
      const diffBtnFromAndToDates = DateUtils.diff(
        DateUtils.today(),
        this.policyFilterForm.controls.fromDate.value,
        DateKey.Day,
      );

      // For toDate, the maximum selectable date is today's date and the minimum POSSIBLE selectable date is the value of fromDate
      this.minToDate = DateUtils.add(DateUtils.today(), DateKey.Day, diffBtnFromAndToDates);
    }
  }
}
