import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileExprDocumentSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFileExprDocument]',
  template: '<svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M29.2 5v10.4h10.3"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M29.2 5H8.5v38h31V15.4L29.2 5z"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M15.2 22.1h17.2"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M15.2 35.4h17.2"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M15.2 13.4h6.9"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M15.2 28.7h17.2"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileExprDocumentSvgComponent {
}

@NgModule({
  declarations: [
    FileExprDocumentSvgComponent,
  ],
  exports: [
    FileExprDocumentSvgComponent,
  ],
})
export class FileExprDocumentSvgModule {}
