<div class="ng-container">
  <maia-label class="b2b-kdl-font-grand-bold title-dark-color"> Search The Policy</maia-label>
  <div class="user-id">
    <maia-label class="b2b-kdl-font-small title-dark-color"> User ID:</maia-label>
    <maia-label class="logged-user b2b-kdl-font-small-bold title-dark-color">{{
      loggedInUser.name
    }}</maia-label>
  </div>
  <form maiaForm [formGroup]="searchForm">
    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element hideOptionalIndicator class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color">Payment Methods</maia-label>
          <maia-input-select formControlName="paymentMethods">
            <input
              maiaInput
              placeholder="select"
              [value]="searchForm.controls.paymentMethods.value?.name"
            />
            <maia-option *ngFor="let paymentMethod of paymentMethodList" [value]="paymentMethod">
              {{ paymentMethod.name }}
            </maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>

      <div class="fluid m-r-16"></div>
    </maia-fluid-fluid-layout>
    <div *ngIf="directDebit">
      <maia-contextual-message type="informative" class="contextual-message-direct-debit">
        Direct Debit payment is used only for Accident /Insurance type 7124 and property,ins.type
        1112. For your information - in this cases there is no need to issue new policy after one
        year,this policies are multiyear and they renew automatically
      </maia-contextual-message>
    </div>

    <maia-divider></maia-divider>
    <maia-contextual-message type="informative" class="contextual-message"
      >Kindly enter any two details for searching policies not issued by your seller ID
    </maia-contextual-message>

    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element class="input-column" label="EGN / LNCH / EIK" hideOptionalIndicator>
          <input maiaInput placeholder="EGN / LNCH / EIK" formControlName="uniqueId" />
        </maia-form-element>
      </div>
      <div class="fluid m-r-18">
        <maia-form-element
          class="input-column"
          label="Vehicle Registration Number"
          hideOptionalIndicator
        >
          <input
            maiaInput
            placeholder="Vehicle Registration Number"
            formControlName="vehicleRegistrationNumber"
          />
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16" [hidden]="directDebit">
        <maia-form-element class="input-column" label="Policy Number" hideOptionalIndicator>
          <input maiaInput formControlName="policyNumber" placeholder="Policy Number" />
        </maia-form-element>
      </div>
      <div class="fluid m-r-18"></div>
    </maia-fluid-fluid-layout>
    <div *ngIf="!searchForm.controls['policyNumber'].valid" class="banner-style">
      <maia-contextual-message type="error" class="contextual-message"
        >plese enter valid policy number
      </maia-contextual-message>
    </div>
    <maia-divider></maia-divider>
    <maia-fluid-fluid-layout>
      <div class="fluid m-r-16">
        <maia-form-element hideOptionalIndicator class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color">{{ sellerIdLabel }} </maia-label>
          <maia-input-select formControlName="sellerId">
            <input
              maiaInput
              placeholder="select"
              [value]="getSellerIdAndName(searchForm.controls.sellerId.value)"
            />
            <maia-option *ngFor="let obj of sellerIdList" [value]="obj">
              {{ getSellerIdAndName(obj) }}
            </maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>
      <div class="fluid m-r-18"></div>
    </maia-fluid-fluid-layout>

    <maia-divider></maia-divider>

    <button
      maiaButton
      maiaButtonType="primary"
      (click)="navigateToNext()"
      [disabled]="!isFormValid() || !searchForm.valid"
      >Search
    </button>
  </form>
</div>
