import {Component, OnInit} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'read-more-slidein',
  templateUrl: './read-more-slidein.component.html',
  styleUrls: ['./read-more-slidein.component.scss'],
})
export class ReadMoreSlideinComponent
  extends ModalContentComponent<void, undefined | {isDirty?: boolean}>
  implements OnInit
{
  public constructor(public control: ModalControl<void, undefined | {isDirty?: boolean}>) {
    super();
  }

  public ngOnInit(): void {}
}
