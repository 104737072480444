/*eslint-disable @typescript-eslint/no-unsafe-assignment*/
/*eslint-disable @typescript-eslint/no-unsafe-member-access*/
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Boolean, DateKey, DateUtils} from '@atlas/businesstypes';
import {PolicyDetailsDto, PropertyRoutePaths} from '@b2b-frontend/core';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';

import {
  ANNUAL,
  BLANK_VALUE,
  BUILDING_INHABITANCY_TYPE_LIST,
  CURRENCY_LIST,
  DOCS_REQUIRED_WARNING,
  FALSE,
  FIRE_SAFETY_TYPE_LIST,
  FIRESAFETY_AVAILABILITY_TYPE_LIST,
  INSURED_BUILDING_NOT_MASSIVE_WARNING,
  INSURED_BUILDING_TYPE_LIST,
  NO,
  POLICY_TYPE_LIST,
  PolicySelectableTypesList,
  PROPERTY_DAMAGE_TYPE_LIST,
  REQUIRED_DOCS_AVAILABILITY_LIST,
  SECURITY_MEASURES_LIST,
  TERMLESS,
  TRUE,
  YES,
} from '../../constants/property-insurance-constants';
import {
  PolicySelectableType,
  SelectableInputType,
} from '../../contracts/property-insurance.interface';
import {PropertyApiService} from '../../property-core/services/property-api.service';
import {PropertyNavigationService} from '../../property-core/services/property-navigation.service';
import {PropertySessionService} from '../../property-core/services/property-session.service';

@Component({
  selector: 'property-policy-details',
  templateUrl: './property-policy-details.component.html',
  styleUrls: ['./property-policy-details.component.scss'],
})
export class PropertyPolicyDetailsComponent extends ScreenFormComponent implements OnInit {
  public policyTypeList: SelectableInputType[] = [...POLICY_TYPE_LIST];
  public insuredBuildingTypeList: SelectableInputType[] = [...INSURED_BUILDING_TYPE_LIST];
  public requiredDocsAvailabilityList: SelectableInputType[] = [...REQUIRED_DOCS_AVAILABILITY_LIST];
  public propertyDamageTypeList: SelectableInputType[] = [...PROPERTY_DAMAGE_TYPE_LIST];
  public buildingInhabitancyTypeList: SelectableInputType[] = [...BUILDING_INHABITANCY_TYPE_LIST];
  public fireSafetyAvailabilityList: SelectableInputType[] = [...FIRESAFETY_AVAILABILITY_TYPE_LIST];
  public fireSafetyTypeList: SelectableInputType[] = [...FIRE_SAFETY_TYPE_LIST];
  public securityMeasuresList: SelectableInputType[] = [...SECURITY_MEASURES_LIST];
  public currencyList: SelectableInputType[] = [...CURRENCY_LIST];
  public apiInhabitancyTypes: PolicySelectableType[] = [];
  public apiFireProtectionTypes: PolicySelectableType[] = [];
  public apiSecurityMeasureTypes: PolicySelectableType[] = [];
  public apiCurrencyList: PolicySelectableType[] = [];

  public nbRemainingCharacters: number;
  public nbRemainingCharactersLimit: number;
  public riskQuestionsWarningMessage: string = BLANK_VALUE;
  public arePolicyDatesSelected: boolean = FALSE;
  public isPolicyTermless: boolean = FALSE;
  public disabledField = Boolean.TRUE;
  public isPolicySelected: boolean = FALSE;
  public isAbleToCompleteRiskQuestions: boolean = FALSE;

  public minPolicyStartDate = DateUtils.add(DateUtils.today(), DateKey.Day, 1);
  public maxPolicyStartDate = DateUtils.add(DateUtils.today(), DateKey.Day, 66);
  // eslint-disable-next-line
  public maxPolicyEndDate = DateUtils.add(
    DateUtils.add(DateUtils.today(), DateKey.Day, 0),
    DateKey.Year,
    1,
  );

  public minContractStartDate = DateUtils.add(DateUtils.today(), DateKey.Year, -2);
  public maxContractStartDate = DateUtils.add(DateUtils.today(), DateKey.Year, 0);
  public maxContractEndDate = DateUtils.add(DateUtils.today(), DateKey.Year, 0);

  public policyDetailsForm: FormGroupManager<PolicyDetailsDto> =
    this._formFactory.createFormManager<PolicyDetailsDto>({
      // eslint-disable-next-line
      policyType: new FormControl(undefined, Validators.required),
      // eslint-disable-next-line
      policyStartDate: new FormControl(this.minPolicyStartDate, [Validators.required]),
      // eslint-disable-next-line
      policyEndDate: new FormControl({value: this.maxPolicyEndDate, disabled: true}, [
        Validators.required,
      ]),
      // eslint-disable-next-line
      isInsuredBuildingMassive: new FormControl(undefined, Validators.required),
      // eslint-disable-next-line
      areRequiredDocsAvailable: new FormControl(undefined, Validators.required),
      // eslint-disable-next-line
      isPropertyDamaged: new FormControl(undefined, Validators.required),
      // eslint-disable-next-line
      buildingInhabitancyType: new FormControl(undefined, Validators.required),
      insurerDetails: this._formBuilder.group({
        // eslint-disable-next-line
        insurerName: new FormControl(undefined, Validators.required),
        // eslint-disable-next-line
        contractStartDate: new FormControl(undefined, Validators.required),
        // eslint-disable-next-line
        contractEndDate: new FormControl({value: undefined, disabled: true}, [Validators.required]),
        claimAmount: new FormControl(undefined, [
          // eslint-disable-next-line
          Validators.required,
          // eslint-disable-next-line
          Validators.pattern(/\-?\d*\.?\d{1,2}/),
        ]),
        // eslint-disable-next-line
        currency: new FormControl(this.currencyList[0].label, Validators.required),
        // eslint-disable-next-line
        typeOfEvent: new FormControl(undefined, Validators.required),
      }),
      // eslint-disable-next-line
      groundsForOtherPropertyInsurance: new FormControl(undefined),
      // eslint-disable-next-line
      isFireSafetyAvailable: new FormControl(undefined, Validators.required),
      // eslint-disable-next-line
      fireSafetyType: new FormControl(undefined, Validators.required),
      // eslint-disable-next-line
      securityMeasures: new FormControl(undefined, Validators.required),
    });

  public constructor(
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _propertyNavigationService: PropertyNavigationService,
    private readonly _formBuilder: FormBuilder,
    private readonly _propertySessionService: PropertySessionService,
    private readonly _propertyApiService: PropertyApiService,
    route: ActivatedRoute,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    this._propertyApiService
      .getPolicySelectableType(PolicySelectableTypesList.INHABITANCY_TYPES)
      .subscribe((res: PolicySelectableType[]) => {
        this.apiInhabitancyTypes = res;
      });

    this._propertyApiService
      .getPolicySelectableType(PolicySelectableTypesList.FIRE_PROTECTION_TYPES)
      .subscribe((res: PolicySelectableType[]) => {
        this.apiFireProtectionTypes = res;
      });

    this._propertyApiService
      .getPolicySelectableType(PolicySelectableTypesList.SECURITY_MEASURES_TYPES)
      .subscribe((res: PolicySelectableType[]) => {
        this.apiSecurityMeasureTypes = res;
      });

    this._propertyApiService
      .getPolicySelectableType(PolicySelectableTypesList.CURRENCY_LIST)
      .subscribe((res: PolicySelectableType[]) => {
        this.apiCurrencyList = res;
      });

    this.policyDetailsForm?.controls?.contractEndDate?.disable();

    // Fetch data from the session service if any and populate the screen data accordingly
    // const proposalDto: PropertyInsuranceProposalDto =
    //   this._propertySessionService.getPropertyData();
    // if (proposalDto && proposalDto.policyDetailsDto) {
    //   const policyDetailsDto: PolicyDetailsDto = proposalDto.policyDetailsDto;
    //   this.populatePolicyForm(policyDetailsDto);
    // }
  }

  public getClaimAmount(): void {
    this._propertyApiService.getCurrencyRate().subscribe((res: any) => {
      let amount: number =
        this.policyDetailsForm?.controls.insurerDetails.get('claimAmount')?.value;
      amount = amount * res.rate;
      this.policyDetailsForm?.controls.insurerDetails.patchValue({
        claimAmount: amount,
      });
    });
  }

  public populatePolicyForm(policyDetailsDto: PolicyDetailsDto): void {
    this.policyDetailsForm.patchValue(policyDetailsDto);
    this.isPolicySelected = TRUE;
    this.enablePolicyForm();
  }

  public onChangeOfPolicyStartDate(): void {
    if (ANNUAL === this.policyDetailsForm.controls.policyType.value) {
      // policyEndDate will be calculated by adding 1 Year to the selected policyStartDate for Annual Policy arePolicyDatesSelected
      this.maxPolicyEndDate = DateUtils.add(
        this.policyDetailsForm?.controls?.policyStartDate?.value,
        DateKey.Year,
        1,
      );
      this.maxPolicyEndDate = DateUtils.add(this.maxPolicyEndDate, DateKey.Day, -1);
      this.policyDetailsForm?.controls?.policyEndDate?.patchValue(this.maxPolicyEndDate);
    } else {
      // eslint-disable-next-line
      this.maxPolicyEndDate = DateUtils.apply(undefined);
      this.policyDetailsForm?.controls?.policyEndDate?.patchValue(undefined);
    }
    this.arePolicyDatesSelected = TRUE;
  }

  public onChangeOfContractStartDate(): void {
    // contractEndDate will be calculated by adding 1 Year to the selected contractStartDate
    this.maxContractEndDate = DateUtils.add(
      this.policyDetailsForm?.controls?.insurerDetails.get('contractStartDate')?.value,
      DateKey.Year,
      1,
    );

    this.policyDetailsForm?.controls?.insurerDetails
      .get('contractEndDate')
      ?.setValue(this.maxContractEndDate);
  }

  public updateRemainingCharacters($event: number): void {
    this.nbRemainingCharacters = $event;
  }

  public updateRemainingCharactersLimit($event: number): void {
    this.nbRemainingCharactersLimit = $event;
  }

  public ableToCompleteRiskQuestions(): boolean {
    if (
      (this.policyDetailsForm.controls.isInsuredBuildingMassive.pristine &&
        this.policyDetailsForm.controls.isInsuredBuildingMassive.pristine) ||
      (YES === this.policyDetailsForm.controls.isInsuredBuildingMassive.value &&
        YES === this.policyDetailsForm.controls.areRequiredDocsAvailable.value)
    ) {
      this.disableRiskQuestionFields(this.isPolicySelected);
      return TRUE;
    } else {
      this.riskQuestionsWarningMessage =
        this.policyDetailsForm.controls.isInsuredBuildingMassive.dirty &&
        NO === this.policyDetailsForm.controls.isInsuredBuildingMassive.value
          ? INSURED_BUILDING_NOT_MASSIVE_WARNING
          : this.policyDetailsForm.controls.areRequiredDocsAvailable.dirty &&
            NO === this.policyDetailsForm.controls.areRequiredDocsAvailable.value
          ? DOCS_REQUIRED_WARNING
          : BLANK_VALUE;
      this.disableRiskQuestionFields(FALSE);
      return FALSE;
    }
  }

  public isPropertyDamaged(): boolean {
    return YES === this.policyDetailsForm.controls.isPropertyDamaged.value ? TRUE : FALSE;
  }

  public isPropertyFireProtected(): boolean {
    return YES === this.policyDetailsForm.controls.isFireSafetyAvailable.value ? TRUE : FALSE;
  }

  public isContinueButtonEnabled(): boolean {
    if (TERMLESS === this.policyDetailsForm.controls.policyType.value) {
      this.policyDetailsForm?.controls?.policyEndDate?.patchValue(undefined);
      this.policyDetailsForm?.controls?.policyEndDate?.setErrors(null);
    }
    if (!this.isPropertyFireProtected()) {
      this.policyDetailsForm?.controls?.fireSafetyType?.setErrors(null);
    }
    if (!this.isPropertyDamaged()) {
      this.policyDetailsForm?.controls?.insurerDetails.get('insurerDetails')?.setErrors(null);
      this.policyDetailsForm?.controls?.insurerDetails.get('insurerName')?.setErrors(null);
      this.policyDetailsForm?.controls?.insurerDetails.get('contractStartDate')?.setErrors(null);
      this.policyDetailsForm?.controls?.insurerDetails.get('contractEndDate')?.setErrors(null);
      this.policyDetailsForm?.controls?.insurerDetails.get('claimAmount')?.setErrors(null);
      this.policyDetailsForm?.controls?.insurerDetails.get('currency')?.setErrors(null);
      this.policyDetailsForm?.controls?.insurerDetails.get('typeOfEvent')?.setErrors(null);
    }
    return this.policyDetailsForm.valid && this.ableToCompleteRiskQuestions();
  }

  public onChangeOfPolicyType(selectedPolicyType: string): void {
    this.isPolicySelected = TRUE;
    this.isAbleToCompleteRiskQuestions = TRUE;
    this.isPolicyTermless = TERMLESS === selectedPolicyType ? TRUE : FALSE;

    // Initialize Policy Start Date
    this.policyDetailsForm?.controls?.policyStartDate?.patchValue(this.minPolicyStartDate);

    if (!this.isPolicyTermless) {
      // Initialize Policy End Date
      this.policyDetailsForm?.controls?.policyEndDate?.patchValue(this.maxPolicyEndDate);
    }
    this.policyDetailsForm?.controls?.isInsuredBuildingMassive?.patchValue(
      this.requiredDocsAvailabilityList[0].value,
    );
    this.policyDetailsForm?.controls?.areRequiredDocsAvailable?.patchValue(
      this.propertyDamageTypeList[0].value,
    );
  }

  public navigateTo(): void {
    this._propertyNavigationService.nextScreen(PropertyRoutePaths.PROPERTY_POLICY_DETAILS);
  }

  protected saveMtplData(): void {
    const policyDetailsDto: PolicyDetailsDto = this.policyDetailsForm.value;
    this._propertySessionService.setPolicyDetailsDto(policyDetailsDto);
    this.navigateTo();
  }

  public getInsurerNameFieldErrors(): boolean {
    const insurerName = this.policyDetailsForm.controls?.insurerDetails?.get('insurerName')?.value;
    if (
      this.policyDetailsForm.controls?.insurerDetails?.get('insurerName')?.dirty &&
      (this.policyDetailsForm.controls?.insurerDetails?.get('insurerName')?.errors ||
        BLANK_VALUE === insurerName.trim())
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getTypeOfEventFieldErrors(): boolean {
    const typeOfEvent = this.policyDetailsForm.controls?.insurerDetails?.get('typeOfEvent')?.value;
    if (
      this.policyDetailsForm.controls?.insurerDetails?.get('typeOfEvent')?.dirty &&
      (this.policyDetailsForm.controls?.insurerDetails?.get('typeOfEvent')?.errors ||
        BLANK_VALUE === typeOfEvent.trim())
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public getClaimAmountFieldErrors(): boolean {
    if (
      this.policyDetailsForm.controls?.insurerDetails?.get('claimAmount')?.dirty &&
      this.policyDetailsForm.controls?.insurerDetails?.get('claimAmount')?.errors
    ) {
      return TRUE;
    } else {
      return FALSE;
    }
  }

  public disableRiskQuestionFields(isPolicySelected: boolean): void {
    if (isPolicySelected) {
      this.policyDetailsForm.controls.buildingInhabitancyType.enable();
      this.policyDetailsForm.controls.groundsForOtherPropertyInsurance.enable();
      this.policyDetailsForm.controls.fireSafetyType.enable();
      this.policyDetailsForm.controls.securityMeasures.enable();
      this.policyDetailsForm.controls?.insurerDetails.enable();
    } else {
      this.policyDetailsForm.controls.buildingInhabitancyType.disable();
      this.policyDetailsForm.controls.groundsForOtherPropertyInsurance.disable();
      this.policyDetailsForm.controls.fireSafetyType.disable();
      this.policyDetailsForm.controls.securityMeasures.disable();
      this.policyDetailsForm.controls?.insurerDetails.disable();
    }
  }

  public enablePolicyForm(): void {
    this.policyDetailsForm.controls.policyType.enable();
    this.policyDetailsForm.controls.policyStartDate.enable();
    this.policyDetailsForm.controls.policyEndDate.enable();
    this.policyDetailsForm.controls.isInsuredBuildingMassive.enable();
    this.policyDetailsForm.controls.areRequiredDocsAvailable.enable();
    this.policyDetailsForm.controls.buildingInhabitancyType.enable();
    this.policyDetailsForm.controls?.isPropertyDamaged.enable();
    this.policyDetailsForm.controls.groundsForOtherPropertyInsurance.enable();
    this.policyDetailsForm.controls?.insurerDetails.enable();
    this.policyDetailsForm.controls.isFireSafetyAvailable.enable();
    this.policyDetailsForm.controls.fireSafetyType.enable();
    this.policyDetailsForm.controls.securityMeasures.enable();
  }
}
