import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RoutePaths} from '../constants/route-paths';

@Injectable({
  providedIn: 'root',
})
export class CmsNavigationService {
  public constructor(public readonly _router: Router) {}

  public async nextScreen(path: RoutePaths, routeParams?: string): Promise<void> {
    if (routeParams) {
      await this._router.navigate([`dashboard/admin/${RoutePaths.Cms}/`, path, routeParams]);
    } else {
      await this._router.navigate([`dashboard/admin/${RoutePaths.Cms}/`, path]);
    }
  }
}
