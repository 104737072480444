import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-travel-insured-people-delete-popup',
  templateUrl: './travel-insured-people-delete-popup.component.html',
  styleUrls: ['./travel-insured-people-delete-popup.component.scss'],
})
export class TravelInsuredPeopleDeletePopupComponent extends ModalContentComponent<void> {
  public constructor(public control: ModalControl<void>) {
    super();
  }

  public delete(): void {
    this.control.confirm();
  }
}
