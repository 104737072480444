import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FlagUtilFranceSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFlagUtilFrance]',
  template: '<svg:defs><svg:path id="svgWK/qiAQSf7MVLfwClizV5opqO1FQgnkwALCO0yEMZ2Q=" d="M0 0h48v48H0z"></svg:path></svg:defs><svg:clipPath id="svgwquUc6ND1UpPGQRHHKCjYs3hC6n+tSCNChpa2bpjS0s="><svg:use href="#svgWK/qiAQSf7MVLfwClizV5opqO1FQgnkwALCO0yEMZ2Q=" overflow="visible"></svg:use></svg:clipPath><svg:g clip-path="url(#svgwquUc6ND1UpPGQRHHKCjYs3hC6n+tSCNChpa2bpjS0s=)"><svg:path fill="#FFFFFF" d="M0 0h48v48H0z"></svg:path><svg:path fill="#F0F0F0" d="M0 0h48v48H0z"></svg:path><svg:path fill="#0052B4" d="M0 0h16v48H0z"></svg:path><svg:path fill="#D80027" d="M32 0h16v48H32z"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'xlink': 'http://www.w3.org/1999/xlink',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagUtilFranceSvgComponent {
}

@NgModule({
  declarations: [
    FlagUtilFranceSvgComponent,
  ],
  exports: [
    FlagUtilFranceSvgComponent,
  ],
})
export class FlagUtilFranceSvgModule {}
