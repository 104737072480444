<ng-container *ngIf="!(step.inEdit | async)">
  <maia-title-panel
    class="title-panel-new"
    *ngIf="addedInsuredPersonArray.length === 0 && isValidFile"
  >
    <div class="m-l-24">
      <maia-label class="b2b-kdl-font-small-bold">
        If you have a file for Insured people you can upload it here.
        <button
          maiaButton
          maiaButtonType="tertiary"
          (click)="travelPolicyService.downloadSampleXls()"
          >Click here</button
        >
        to download the template.
      </maia-label>

      <button maiaButton maiaButtonType="primary"
        >Upload File
        <input
          type="file"
          class="upload show-hand"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          (change)="onFileChange($event)"
        />
      </button>
    </div>
  </maia-title-panel>

  <maia-title-panel class="title-panel-error" *ngIf="!isValidFile">
    <svg b2bFrontendAlertFuncExclamationCircle maia-title-panel-icon></svg>
    <div>
      <maia-label class="b2b-kdl-font-small-bold">
        The file is invalid. Please fix the errors and re-upload valid file.
        <button
          maiaButton
          maiaButtonType="tertiary"
          (click)="travelPolicyService.downloadSampleXls()"
          >Click here</button
        >
        to download the sample template.
      </maia-label>

      <button maiaButton class="adjust-button"
        >Re-Upload File
        <input
          type="file"
          class="upload show-hand"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          (change)="onFileChange($event)"
      /></button>
    </div>
  </maia-title-panel>

  <div *ngIf="insuredPeopleForm.valid && addedInsuredPersonArray.length > 0">
    <maia-title-panel class="title-panel-new">
      <div class="m-l-24">
        <maia-label class="b2b-kdl-font-small-bold">
          Insured People file has been successfully uploaded.
          <button
            maiaButton
            maiaButtonType="tertiary"
            (click)="travelPolicyService.downloadSampleXls()"
            >Click here</button
          >
          to download the file.
        </maia-label>

        <button maiaButton maiaButtonType="secondary" (click)="removeFile()">Remove File</button>
      </div>
    </maia-title-panel>
    <maia-contextual-message type="success" class="m-t-16 b2b-kdl-font-small">
      The number of people has been auto updated as per the uploaded file.
    </maia-contextual-message>
  </div>
</ng-container>

<div class="m-t-24">
  <form maiaForm [formGroup]="stepForm1.formGroup">
    <div class="blue-box b2b-kdl-font-small m-b-24" *ngIf="step.inEdit | async">
      Changing values will result in premium recalulation
    </div>
    <div class="insured-people-parent-div">
      <ng-container
        *ngFor="let iPeople of travelInsuredPeopleTypeList; let i = index"
        class="insured-people-container"
      >
        <div class="insured-people-box">
          <label class="b2b-kdl-font-small title-dark-color">
            {{ iPeople.insuredPeopleType }}
          </label>
          <label class="b2b-kdl-font-small-light insured-people-age-sub-label">
            {{ iPeople.ageLimit }}
          </label>
          <maia-input-stepper
            name="selectedFormDateRange"
            [(ngModel)]="iPeople.numberOfPeople"
            [max]="getMax(i)"
            [min]="minimumDecimal"
            class="input-stepper"
            [ngModelOptions]="{standalone: true}"
            [disabled]="fileUploaded"
          ></maia-input-stepper>
        </div>
      </ng-container>
    </div>

    <maia-contextual-message
      id="contextual-message-4"
      class="m-t-24"
      type="warning"
      *ngIf="isMax()"
    >
      <span>
        You have reached maximum number of people to be covered in a single policy (150 people).
        Please contact with your nearest DZI Office.</span
      >
      <a href="https://www.dzi.bg/locations" target="_blank" rel="noopener noreferrer"
        >Click Here</a
      >
    </maia-contextual-message>

    <maia-divider class="m-t-b-24"></maia-divider>
    <maia-fluid-fluid-layout alignContentTop
      ><div class="m-r-16">
        <maia-label>
          <label class="b2b-kdl-font-small title-dark-color"> Type of trip </label>
        </maia-label>
        <button
          maiaButton
          maiaButtonType="tertiary"
          class="view-button b2b-kdl-font-extra-small"
          size="small"
          (click)="viewDetails()"
          >Need help to choose?</button
        >

        <maia-radio-group formControlName="tripRadioGroup">
          <njf-radio-button
            class="radio-btn-margin"
            *ngFor="let trip of travelTripList; let i = index"
            (click)="showHideSingleMultipleTripBox(trip.tripId)"
            [value]="trip.tripId"
            [disabled]="setDisabled(i)"
            >{{ trip.tripName }}</njf-radio-button
          >
        </maia-radio-group>
      </div>
      <div formArrayName="tripFormArray">
        <ng-container *ngIf="showHideDiv && showHideBlueDiv">
          <div
            class="trip-blue-box"
            *ngFor="let singleTrip of tripFormArray.controls; let i = index"
            [formGroupName]="i"
          >
            <maia-form-element [hideOptionalIndicator]="true" class="input-column">
              <maia-label
                class="b2b-kdl-font-small-light subtitle-light-color m-b-8"
                sublabel="Please note that your Policy would be active within 3 hours of successful policy
              payment."
              >
                <label class="b2b-kdl-font-small title-dark-color"> Start & End Date </label>
              </maia-label>
              <maia-input-date-range
                name="startEndData"
                [disableWeekends]="disableWeekends"
                [visibleView]="visibleDateView"
                formControlName="startEndData"
                [minimum]="minDate"
                [maximum]="maxDate"
                (ngModelChange)="changeEvent($event)"
              >
              </maia-input-date-range>

              <div class="validation-container">
                <maia-validation
                  type="error"
                  *ngIf="singleTrip.get('startEndData')?.errors?.required"
                >
                  Start and End date required
                </maia-validation>

                <maia-validation type="error" *ngIf="isInvalidDate(singleTrip.get('startEndData'))">
                  Please enter start date with allowed limit e.g: Tomorrow’s date till 60 days from
                  today.
                </maia-validation>
              </div>
            </maia-form-element>

            <maia-form-element
              [hideOptionalIndicator]="true"
              class="input-column"
              *ngIf="!disableTime"
            >
              <maia-label>
                <label class="b2b-kdl-font-small title-dark-color"> Time </label>
              </maia-label>
              <maia-timepicker name="time" formControlName="time"></maia-timepicker>
              <div class="validation-container">
                <maia-validation type="error" *ngIf="singleTrip.get('time')?.errors?.required">
                  Time is required
                </maia-validation>
              </div>
            </maia-form-element>

            <maia-form-element [hideOptionalIndicator]="true" class="input-column">
              <maia-label>
                <label class="b2b-kdl-font-small title-dark-color">
                  Are you travelling to the US or Canada?
                </label>
              </maia-label>
              <maia-radio-group formControlName="travelAbroadRadioGroup" horizontal>
                <njf-radio-button
                  *ngFor="let option of travelUsCanadOptionList"
                  class="white-bg-color"
                  [value]="option.id"
                  >{{ option.name }}</njf-radio-button
                >
              </maia-radio-group>
            </maia-form-element>
          </div>
        </ng-container>
        <ng-container *ngIf="!showHideDiv && showHideBlueDiv">
          <div
            class="trip-blue-box"
            *ngFor="let multiTrip of tripFormArray.controls; let i = index"
            [formGroupName]="i"
          >
            <maia-form-element [hideOptionalIndicator]="true" class="input-column">
              <maia-label
                class="b2b-kdl-font-small-light subtitle-light-color m-b-8"
                sublabel="Please note that your Policy would be active within 3 hours of successful policy payment."
              >
                <label class="b2b-kdl-font-small title-dark-color"> Policy Start Date </label>
              </maia-label>
              <maia-input-date
                name="multipleStartDate"
                [disableWeekends]="disableWeekends"
                [visibleView]="visibleDateView"
                [minimum]="minDateMultiple"
                [maximum]="maxDateMultiple"
                formControlName="multipleStartDate"
              ></maia-input-date>

              <div class="validation-container">
                <maia-validation
                  type="error"
                  *ngIf="multiTrip.get('multipleStartDate').errors?.required"
                >
                  Start date required
                </maia-validation>
              </div>
            </maia-form-element>

            <maia-form-element [hideOptionalIndicator]="true" class="input-column">
              <maia-label
                sublabel="12 months from start date"
                class="b2b-kdl-font-small-light subtitle-light-color m-t-16 m-b-8"
              >
                <label class="b2b-kdl-font-small title-dark-color"> Policy End Date </label>
              </maia-label>
              <maia-input-date
                name="multipleEndDate"
                [disableWeekends]="disableWeekends"
                [visibleView]="visibleDateView"
                [minimum]="minDateMultiple"
                [maximum]="maxDateMultiple"
                formControlName="multipleEndDate"
              ></maia-input-date>
            </maia-form-element>

            <maia-form-element [hideOptionalIndicator]="true" class="input-column">
              <maia-label
                class="b2b-kdl-font-small-light subtitle-light-color m-t-16 m-b-8"
                sublabel="Please pick the maximum days you plan to spend on your trips, to help us provide the best coverage plan for you"
              >
                <label class="b2b-kdl-font-small title-dark-color"> Maximum days abroad </label>
              </maia-label>
              <maia-radio-group
                name="daysAbroad"
                formControlName="daysAbroad"
                class="radio-group-margins"
              >
                <njf-radio-button
                  *ngFor="let days of daysInAbroadList"
                  class="white-bg-color radio-btn-margin"
                  [value]="days.id"
                  >{{ days.name }}</njf-radio-button
                >
              </maia-radio-group>
            </maia-form-element>
          </div>
        </ng-container>
      </div>
    </maia-fluid-fluid-layout>
    <maia-divider></maia-divider>
    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small-light m-b-8 m-t-16">
            <label class="b2b-kdl-font-small title-dark-color">Reason for travel </label>
          </maia-label>
          <maia-input-select name="travelReason" formControlName="travelReason">
            <input
              maiaInput
              placeholder="Select"
              [value]="getValueTravelReason(stepForm1.controls['travelReason'].value)"
            />
            <maia-option *ngFor="let c of mockTravelReason" [value]="c.ID">{{
              c.objectName
            }}</maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>

      <div
        [ngClass]="{
          'trip-blue-box': showWorkType,
          'block-invisible': !showWorkType
        }"
      >
        <maia-form-element [hideOptionalIndicator]="true" class="input-column">
          <maia-label class="b2b-kdl-font-small-light m-b-8">
            <label class="b2b-kdl-font-small title-dark-color">Type of Work </label>
          </maia-label>
          <maia-input-select name="workType" formControlName="workType">
            <input
              maiaInput
              placeholder="Select"
              [value]="getValueWorkType(stepForm1.controls['workType'].value)"
            />
            <maia-option *ngFor="let c of mockWorkType" [value]="c.fieldValue">{{
              c.fieldValueName
            }}</maia-option>
          </maia-input-select>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-divider class="m-t-b-24"></maia-divider>
    <button
      *ngIf="!(step.inEdit | async)"
      class="m-t-b-24"
      id="continueBtn"
      maiaButton
      maiaButtonType="primary"
      [disabled]="enableContinueBtn()"
      (click)="saveAndContinue()"
    >
      Continue</button
    >
    <div *ngIf="step.inEdit | async">
      <button
        class="m-r-24 m-t-b-24"
        maiaButton
        maiaButtonType="primary"
        [disabled]="enableContinueBtn()"
        (click)="saveEdit()"
        >Save</button
      >
      <button maiaButton maiaButtonType="secondary" (click)="cancelEdit()">Cancel</button>
    </div>
  </form>
</div>
