import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesFuncFactorySvgModule
 */
@Component({
  selector: 'svg[b2bFrontendPlacesFuncFactory]',
  template: '<svg:path d="M3 9.24V21.5h18v-19h-3.3l-2.44 10.83-6.52-4.09v4.09z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M5.44 16.6h2.47v2.46H5.44z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M10.47 16.6H13v2.46h-2.53z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M15.46 16.6h2.47v2.46h-2.47z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesFuncFactorySvgComponent {
}

@NgModule({
  declarations: [
    PlacesFuncFactorySvgComponent,
  ],
  exports: [
    PlacesFuncFactorySvgComponent,
  ],
})
export class PlacesFuncFactorySvgModule {}
