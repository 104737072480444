/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/*eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  Input,
  OnChanges,
} from '@angular/core';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Text} from '@atlas/businesstypes';
import {localeValPipe, LocalizationService} from '@b2b-frontend/core';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {SlideInController} from '@maia/slide-ins';

import {Language} from '../../../../constants/lang';
import {
  ADMIN_DOCUMENT_UPLOAD_TYPE,
  BLANK_VALUE,
} from '../../../../constants/resource-management/admin-documents';
import {
  AdminLibraryDocumentDto,
  CreateOrUpdateAdminLibraryDocumentInput,
} from '../../../../contracts/admin-documents.interface';
import {AdminLibrary} from '../../../../contracts/adminInterface';
import {AdminLibraryService} from '../../../../services/admin-library.service';
import {DeleteAdminLibraryPopupComponent} from '../delete-popup/delete-popup.component';
import {DocumentSlideinComponent} from '../document-slidein/document-slidein.component';

@Component({
  selector: 'nje-admin-document-documents',
  templateUrl: './admin-document-documents.component.html',
  styleUrls: ['./admin-document-documents.component.scss'],
})
@UntilDestroy()
export class AdminDocumentDocumentsComponent implements OnChanges {
  public lang: string | null = sessionStorage.getItem('lang');
  public showLoadingIcon: boolean = false;
  public adminLibraryDocumentsList: AdminLibraryDocumentDto[] = [];

  public imageDocumentsList: AdminLibraryDocumentDto[] = [];
  public pdfDocumentsList: AdminLibraryDocumentDto[] = [];
  public videoDocumentsList: AdminLibraryDocumentDto[] = [];
  public excelDocumentsList: AdminLibraryDocumentDto[] = [];
  public pptDocumentsList: AdminLibraryDocumentDto[] = [];
  public docsDocumentsList: AdminLibraryDocumentDto[] = [];

  public showVideoDocuments: boolean = false;
  public showImageDocuments: boolean = false;
  public showPdfDocuments: boolean = false;
  public showExcelDocuments: boolean = false;
  public showPptDocuments: boolean = false;
  public showDocxDocuments: boolean = false;

  private readonly _documentSlideinFactory: ComponentFactory<DocumentSlideinComponent>;
  private readonly _popUpDeleteFactory: ComponentFactory<DeleteAdminLibraryPopupComponent>;

  @Input()
  public selectedLibrary: AdminLibrary;

  @Input()
  public searchWord: Text;

  @Input()
  public searchedText: Text;

  @Input()
  public hasSearchTriggered: boolean;

  @Input()
  public libraryDocumentsSearchResult: AdminLibraryDocumentDto[];

  public constructor(
    private readonly _libraryService: AdminLibraryService,
    private readonly _popUpCtrl: PopUpController,
    private readonly _slideInCtrl: SlideInController,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
  ) {
    this._documentSlideinFactory =
      this._componentFactoryResolver.resolveComponentFactory(DocumentSlideinComponent);
    this._popUpDeleteFactory = this._componentFactoryResolver.resolveComponentFactory(
      DeleteAdminLibraryPopupComponent,
    );
  }

  public isLibraryDocumentSearchResult(): boolean {
    return this.libraryDocumentsSearchResult.length > 0;
  }

  public showFileNotFound(): boolean {
    return (
      !this.isLibraryDocumentSearchResult() &&
      this.searchWord !== undefined &&
      this.hasSearchTriggered
    );
  }

  public isLanguageEnglish(): boolean {
    return this.lang === Language.English;
  }

  public openAddDocumentSlidein(
    document?: AdminLibraryDocumentDto,
    index?: number,
    adminLibraryId?: number,
  ): void {
    const slideinTitle = document
      ? new localeValPipe(new LocalizationService()).transform('Edit Document', this.lang)
      : new localeValPipe(new LocalizationService()).transform('Add New Document', this.lang);
    this._slideInCtrl
      .prepare(
        this._documentSlideinFactory,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: document,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        (response: ModalConfirmedResult<CreateOrUpdateAdminLibraryDocumentInput>) => {
          if (
            response.resolution === ModalResolution.CONFIRMED &&
            (response.result.id !== null || response.result.id === !undefined)
          ) {
            this.showLoadingIcon = true;
            this._updateLibraryDocument(
              document?.adminLibraryId || 0,
              response.result.id!,
              response.result,
            );
          } else if (
            response.resolution === ModalResolution.CONFIRMED &&
            (response.result.id === null || response.result.id === undefined)
          ) {
            this.showLoadingIcon = true;
            this._addLibraryDocument(this.selectedLibrary.id!, response.result);
          }
        },
        () => {
          this.showLoadingIcon = false;
        },
      );
  }

  public deleteDocumentList(id: number, adminLibraryId: number): void {
    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: BLANK_VALUE, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .subscribe(
        (response: ModalConfirmedResult<void>) => {
          if (response.resolution === ModalResolution.CONFIRMED) {
            this.showLoadingIcon = true;
            this._deleteAdminLibrary(adminLibraryId, id);
          }
        },
        () => {
          this.showLoadingIcon = false;
        },
      );
  }

  public isLibraryDocumentsSectionShown(): boolean {
    return this.selectedLibrary !== undefined && !this.hasSearchTriggered;
  }

  public ngOnChanges(): void {
    if (
      this.selectedLibrary !== undefined &&
      (this.searchedText === undefined || this.searchedText === null)
    ) {
      this._getAdminLibraryDocumentsFromDB();
    }
  }

  private _getAdminLibraryDocumentsFromDB(): void {
    this.showLoadingIcon = true;
    this._libraryService
      .getAdminLibraryDocumentsForSelectedLibrary(this.selectedLibrary.id!)
      .subscribe(
        (response: AdminLibraryDocumentDto[]) => {
          this.adminLibraryDocumentsList = response;
          this._setImageDocumentTypesListObject(response);
          this._setPdfDocumentTypesListObject(response);
          this._setExcelDocumentTypesListObject(response);
          this._setDocxDocumentTypesListObject(response);
          this._setPptDocumentTypesListObject(response);
          this._setVideoDocumentTypesListObject(response);
          this.showLoadingIcon = false;
          this._cdRef.detectChanges();
        },
        () => {
          this.showLoadingIcon = false;
        },
      );
  }

  private _addLibraryDocument(
    adminLibraryId: number,
    createAdminLibraryObj: CreateOrUpdateAdminLibraryDocumentInput,
  ): void {
    this._libraryService.AddDocumentList(adminLibraryId, createAdminLibraryObj).subscribe(
      () => {
        this._getAdminLibraryDocumentsFromDB();
      },
      () => {
        this.showLoadingIcon = false;
      },
    );
    this._cdRef.detectChanges();
  }

  private _updateLibraryDocument(
    adminLibraryId: number,
    id: number,
    updateAdminLibraryObj: CreateOrUpdateAdminLibraryDocumentInput,
  ): void {
    this._libraryService.updateDocumentList(adminLibraryId, id, updateAdminLibraryObj).subscribe(
      () => {
        this._getAdminLibraryDocumentsFromDB();
      },
      () => {
        this.showLoadingIcon = false;
      },
    );
    this._cdRef.detectChanges();
  }

  private _deleteAdminLibrary(adminLibraryId: number, id: number): void {
    void this._libraryService.DeleteDocumentList(adminLibraryId, id).subscribe(
      () => {
        this._getAdminLibraryDocumentsFromDB();
      },
      () => {
        this.showLoadingIcon = false;
      },
    );
    this._cdRef.detectChanges();
  }

  private _setImageDocumentTypesListObject(input: AdminLibraryDocumentDto[]): void {
    this.imageDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.IMAGE),
    );
    this.showImageDocuments = this.imageDocumentsList.length > 0;
  }

  private _setPdfDocumentTypesListObject(input: AdminLibraryDocumentDto[]): void {
    this.pdfDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.PDF),
    );
    this.showPdfDocuments = this.pdfDocumentsList.length > 0;
  }

  private _setExcelDocumentTypesListObject(input: AdminLibraryDocumentDto[]): void {
    this.excelDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.XLS),
    );
    const xlsxDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.XLSX),
    );
    this.excelDocumentsList = this.excelDocumentsList.concat(xlsxDocumentsList);
    this.showExcelDocuments = this.excelDocumentsList.length > 0;
  }

  private _setDocxDocumentTypesListObject(input: AdminLibraryDocumentDto[]): void {
    this.docsDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.DOC),
    );
    const docxDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.DOCX),
    );
    this.docsDocumentsList = this.docsDocumentsList.concat(docxDocumentsList);
    this.showDocxDocuments = this.docsDocumentsList.length > 0;
  }

  private _setPptDocumentTypesListObject(input: AdminLibraryDocumentDto[]): void {
    this.pptDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.PPT),
    );
    const pptxDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.PPTX),
    );
    this.pptDocumentsList = this.pptDocumentsList.concat(pptxDocumentsList);
    this.showPptDocuments = this.pptDocumentsList.length > 0;
  }

  private _setVideoDocumentTypesListObject(input: AdminLibraryDocumentDto[]): void {
    this.videoDocumentsList = input.filter(item =>
      item.document?.type?.includes(ADMIN_DOCUMENT_UPLOAD_TYPE.VIDEO),
    );
    this.showVideoDocuments = this.videoDocumentsList.length > 0;
  }
}
