import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationExprBookOpenSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendCommunicationExprBookOpen]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M36.8 12h4.8v26.4H6.4V12h4.8" stroke-width="1.6"></svg:path><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M24 12v12.8" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M24 12c-1.634-1.566-4.866-3.2-12.8-3.2v22.4c7.966 0 11.2 1.6 12.8 3.2 1.6-1.566 4.766-3.2 12.8-3.2V8.8c-8 0-11.2 1.6-12.8 3.2z" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationExprBookOpenSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationExprBookOpenSvgComponent,
  ],
  exports: [
    CommunicationExprBookOpenSvgComponent,
  ],
})
export class CommunicationExprBookOpenSvgModule {}
