import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {
  StepperProcessService as StepperProcessServiceBase,
  StepperProcessStep,
} from '@njf-frontend-angular/flow-progress';

import {PaymentWorkflow} from '../payments-workflow/payment-workflow';
import {steps} from '../payments-workflow/steps';
import {workflows} from '../payments-workflow/workflows';

const PAYMENTS_SESSION_KEY = 'payments-process';

@Injectable({
  providedIn: 'root',
})
export class PaymentsProcessService
  extends StepperProcessServiceBase<PaymentWorkflow>
  implements OnDestroy
{
  public constructor(router: Router) {
    super(router, steps, workflows, PaymentWorkflow.POLICY_PAYMENT_E_RECEIPT, PAYMENTS_SESSION_KEY);
  }

  protected getWorkflowStepsFromWorkflowAndStep(
    workflow: PaymentWorkflow,
    step: StepperProcessStep,
  ): StepperProcessStep[] {
    return this.getWorkflowSteps(workflow);
  }

  protected getCurrentWorkflowStepsList(): string[] {
    return this.workflows[this.currentWorkflow];
  }
}
