<a
  class="ipid-doc"
  id="maia-action-icon-4"
  maiaDocumentAction
  rel="noopener noreferrer"
  href=""
  (click)="openPDFFile()"
  target="_blank"
  >IPID Document</a
>

<maia-price-block amount="15.43" currency="BGN" type="custom" class="maia-custom">
  <svg class="icon-white-color" b2bFrontendInformationExprFastlane maia-price-icon></svg>
  <div maia-price-title>Total Insurance Premium(Incl.Taxes)</div>
  <div maia-price-subtitle>{{ totalNoOfPeople }} people for {{ noOfTripDays }}</div>
</maia-price-block>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="policyDescHeading"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationExprFastlane></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="tripTypeName">
          <label class="b2b-kdl-font-small title-dark-color"> {{ totalNoOfPeople }} People </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendActionFuncClock></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="tripStartEndDate"
        >
          <label class="b2b-kdl-font-small title-dark-color"> {{ noOfTripDays }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationFuncPlane></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="travelAbroadSubTitle"
        >
          <label class="b2b-kdl-font-small title-dark-color"> {{ travelAbroadTitle }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendSocialFuncPersonTie></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" [sublabel]="reasonType">
          <label class="b2b-kdl-font-small title-dark-color"> {{ workType }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="insuringPartyHeading"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationExprHandPerson></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="insuringPartyTypeNoDisplayValue"
        >
          <label class="b2b-kdl-font-small title-dark-color">
            {{ insuringPartyTypeIndividalName }}
          </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesExprHouseTree></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small-light subtitle-light-color" sublabel="Address">
          <label class="b2b-kdl-font-small title-dark-color"> Sofia 1000 </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendCommunicationFuncMail></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="this.insurPartyPhone"
        >
          <label class="b2b-kdl-font-small title-dark-color"> {{ insuringPartyEmail }} </label>
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="insuredPeopleHeading"
    (editScreen)="editScreen(this.insuredPeopleHeading)"
  ></label-with-edit>
  <maia-table *ngIf="insurePeopleList && insurePeopleList.length > 0">
    <maia-table-header>
      <maia-table-row>
        <maia-table-cell class="b2b-kdl-font-small-bold table-cell-color">
          EGN/LNCH
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-cell-color"> Name </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-cell-color">
          Phone Number
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-cell-color">
          Citizenship
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small-bold table-cell-color">
          Date of Birth
        </maia-table-cell>
      </maia-table-row>
    </maia-table-header>
    <maia-table-body>
      <maia-table-row blueOnSmall *ngFor="let person of insurePeopleList | slice: 0:3">
        <maia-table-cell maiaTableHeaderHiddenOnSmall>
          <div class="maia-demo-cell-title">
            {{ this.travelPolicyService.getEGNEIKLNCHType(person.partyType) }} -
            {{ person.idNumber }}
          </div>
        </maia-table-cell>
        <maia-table-cell>
          <div class="maia-demo-cell-title">
            {{ person.firstName }} {{ person.middleName }} {{ person.lastName }}
          </div>
        </maia-table-cell>
        <maia-table-cell>
          <div class="maia-demo-cell-title">
            {{ this.travelPolicyService.getPhoneNumber(person) }}
          </div>
        </maia-table-cell>
        <maia-table-cell>
          <div class="maia-demo-cell-title">
            {{ travelPolicyService.getCitizenship(person) }}
          </div>
        </maia-table-cell>
        <maia-table-cell>
          <div class="maia-demo-cell-title"> {{ this.travelPolicyService.getDOB(person) }} </div>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
  <maia-additional-information
    *ngIf="insurePeopleList && insurePeopleList.length > 3"
    coverlink
    openLinkText="View more"
    closeLinkText="View less"
    maiaContentType="content"
    collapsible
  >
    <ng-container *ngIf="insurePeopleList">
      <div *ngFor="let person of insurePeopleList | slice: 3:insurePeopleList.length">
        <maia-table>
          <maia-table-body>
            <maia-table-row blueOnSmall>
              <maia-table-cell maiaTableHeaderHiddenOnSmall>
                <div class="maia-demo-cell-title">
                  {{ this.travelPolicyService.getEGNEIKLNCHType(person.partyType) }} -
                  {{ person.idNumber }}
                </div>
              </maia-table-cell>
              <maia-table-cell>
                <div class="maia-demo-cell-title">
                  {{ person.firstName }} {{ person.middleName }} {{ person.lastName }}
                </div>
              </maia-table-cell>
              <maia-table-cell>
                <div class="maia-demo-cell-title">
                  {{ this.travelPolicyService.getPhoneNumber(person) }}
                </div>
              </maia-table-cell>
              <maia-table-cell>
                <div class="maia-demo-cell-title">
                  {{ person?.citizenship ? person?.citizenship : '-' }}
                </div>
              </maia-table-cell>
              <maia-table-cell>
                <div class="maia-demo-cell-title">
                  {{ this.travelPolicyService.getDOB(person) }}
                </div>
              </maia-table-cell>
            </maia-table-row>
          </maia-table-body>
        </maia-table>
      </div>
    </ng-container>
  </maia-additional-information>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="addonsHeading"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCoins></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small-light subtitle-light-color"
          [sublabel]="coverSubtitle"
        >
          <label class="b2b-kdl-font-small title-dark-color"> {{ coversName }} Coverage </label>
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg
          *ngIf="!noSelectedAddons"
          class="icon-primary-color"
          b2bFrontendInformationFuncCoinsPlus
        ></svg>
        <svg
          *ngIf="noSelectedAddons"
          class="icon-disable-color"
          b2bFrontendInformationFuncCoinsPlus
        ></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <div *ngIf="!noSelectedAddons" class="b2b-kdl-font-small title-dark-color">Add Ons</div>
        <div *ngIf="noSelectedAddons">
          <maia-label class="b2b-kdl-font-small subtitle-light-color"> Add Ons </maia-label>
          <div *ngIf="noSelectedAddons" class="b2b-kdl-font-extra-small-light subtitle-light-color">
            (nothing is been selected)
          </div>
        </div>
        <ng-container *ngIf="this.selectedAddon">
          <div
            maiaTextListItem
            *ngFor="let aConditions of this.selectedAddon | slice: 0:2; let i = index"
          >
            <div class="b2b-kdl-font-extra-small-light subtitle-light-color"
              >{{ aConditions.title }} -
              <maia-number-display
                [value]="amountInDecimal"
                size="extra-small"
                currency="BGN"
              ></maia-number-display>
            </div>
          </div>
        </ng-container>
        <maia-additional-information
          coverlink
          *ngIf="selectedAddon && selectedAddon.length > 2"
          openLinkText="View more"
          closeLinkText="View less"
          maiaContentType="content"
          collapsible
        >
          <ng-container *ngIf="this.selectedAddon">
            <div
              maiaTextListItem
              *ngFor="
                let aConditions of this.selectedAddon | slice: 2:this.selectedAddon.length;
                let i = index
              "
            >
              <div class="b2b-kdl-font-extra-small-light subtitle-light-color"
                >{{ aConditions.title }} -
                <maia-number-display
                  [value]="amountInDecimal"
                  size="extra-small"
                  currency="BGN"
                ></maia-number-display>
              </div>
            </div>
          </ng-container>
        </maia-additional-information>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-box-div">
  <label-with-edit
    [nameEditPath]="discountHeading"
    (editScreen)="editScreen($event)"
  ></label-with-edit>
  <maia-avatar-display id="avatar-display-svg" class="m-t-24">
    <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
      <svg
        *ngIf="!noSelectedDiscounts"
        class="icon-primary-color"
        b2bFrontendInformationFuncCoinsMinus
      ></svg>
      <svg
        *ngIf="noSelectedDiscounts"
        class="icon-disable-color"
        b2bFrontendInformationFuncCoinsMinus
      ></svg>
    </maia-avatar-svg>
    <div maia-avatar-display-body>
      <div *ngIf="!noSelectedDiscounts" class="b2b-kdl-font-small title-dark-color">Discounts</div>
      <div *ngIf="noSelectedDiscounts">
        <maia-label class="b2b-kdl-font-small subtitle-light-color"> Discounts </maia-label>
        <div class="b2b-kdl-font-extra-small-light subtitle-light-color">
          (nothing is been selected)
        </div>
      </div>
      <ng-container *ngIf="this.selectedDiscounts">
        <div
          maiaTextListItem
          *ngFor="let discount of this.selectedDiscounts | slice: 0:2; let i = index"
        >
          <div class="b2b-kdl-font-extra-small-light subtitle-light-color"
            >{{ discount.title }} -
            <maia-number-display
              [value]="amountInDecimal"
              size="extra-small"
              currency="BGN"
            ></maia-number-display>
          </div>
        </div>
      </ng-container>
      <maia-additional-information
        coverlink
        *ngIf="selectedDiscounts && selectedDiscounts.length > 2"
        openLinkText="View more"
        closeLinkText="View less"
        maiaContentType="content"
        collapsible
      >
        <ng-container *ngIf="this.selectedDiscounts">
          <div
            maiaTextListItem
            *ngFor="
              let discount of this.selectedDiscounts | slice: 2:this.selectedDiscounts.length;
              let i = index
            "
          >
            <div class="b2b-kdl-font-extra-small-light subtitle-light-color"
              >{{ discount.title }} -
              <maia-number-display
                [value]="amountInDecimal"
                size="extra-small"
                currency="BGN"
              ></maia-number-display>
            </div>
          </div>
        </ng-container>
      </maia-additional-information>
    </div>
  </maia-avatar-display>
</div>

<maia-divider class="m-t-b-24"></maia-divider>
<div class="m-t-b-24 m-l-24">
  <button maiaButton maiaButtonType="primary" (click)="saveAndContinue()">Create Policy</button>
  <button maiaButton maiaButtonType="secondary" class="m-l-b-24" (click)="openSlideInList()"
    >Save Offer</button
  >
  <button maiaButton maiaButtonType="secondary">Special Request</button>
</div>
