import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Date, DateKey, DateUtils} from '@atlas/businesstypes';
import {dateValidator, VehicleTalonData} from '@b2b-frontend/core';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {CascoWorkflow} from '../../casco-core/casco-workflow/CascoWorkflow';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';
import {MIN_DATE_STRING} from '../../constants/quick-vehicle-details-constants';
import {
  BMW_LIST,
  CAR_BODY_LIST,
  COLOR_LIST,
  DRIVE_TYPE_LIST,
  ENGINE_LIST,
  FORD_LIST,
  FUEL_LIST,
  GEAR_LIST,
  MAKE_LIST,
  Manufactures,
  MAX_YEAR,
  MIN_YEAR,
  MOCK_VEHICLE_SUBTYPES,
  MOCK_VEHICLE_TYPES,
  NISSAN_LIST,
  PowerConverionFactors,
  RadioDefaults,
  REGISTER_TYPE_LIST,
  USAGE_LIST,
} from './__tests__/vehicle-details-mock';
import {MockVehicle, PriceOfferVechicleDTO} from './__tests__/vehicle-details-mockInterface';

@Component({
  selector: 'nje-price-offer-vehicle-details',
  templateUrl: './price-offer-vehicle-details.component.html',
  styleUrls: ['./price-offer-vehicle-details.component.scss'],
})
@UntilDestroy()
export class PriceOfferVehicleDetailsComponent extends ScreenFormComponent implements OnInit {
  public continueClicked: boolean;
  public showHeading: boolean = true;
  public showForm: boolean = false;
  public vehicleTalonData: VehicleTalonData;
  public screen: string = CascoWorkflow.PRICE_OFFER;
  public isData: boolean = true;

  public mockTypeOfVehicle: MockVehicle[] = [...MOCK_VEHICLE_TYPES];
  public mockVehicleSubType: MockVehicle[] = [...MOCK_VEHICLE_SUBTYPES];
  public mockAdditionalSpec: MockVehicle[] = [];
  public mockMakeList: MockVehicle[] = [...MAKE_LIST];
  public mockModelList: MockVehicle[] = [];
  public mockBmwList: MockVehicle[] = [...BMW_LIST];
  public mockFordList: MockVehicle[] = [...FORD_LIST];
  public mockNissanList: MockVehicle[] = [...NISSAN_LIST];
  public mockRegisterList: MockVehicle[] = [...REGISTER_TYPE_LIST];
  public mockProductionType: MockVehicle[] = [];
  public mockUsageList: MockVehicle[] = [...USAGE_LIST];
  public mockColorList: MockVehicle[] = [...COLOR_LIST];
  public mockCarBodyList: MockVehicle[] = [...CAR_BODY_LIST];
  public mockFuelList: MockVehicle[] = [...FUEL_LIST];
  public mockEngineList: MockVehicle[] = [...ENGINE_LIST];
  public mockGearList: MockVehicle[] = [...GEAR_LIST];
  public mockDriveTypeList: MockVehicle[] = [...DRIVE_TYPE_LIST];

  public minDate: Date = new Date(MIN_DATE_STRING);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public minYear = MIN_YEAR;
  public maxYear = MAX_YEAR;

  public vehicleForm: FormGroupManager<PriceOfferVechicleDTO> =
    this._formFactory.createFormManager<PriceOfferVechicleDTO>({
      typeOfVehicle: new FormControl(undefined, [Validators.required]),
      subTypeOfVehicle: new FormControl(undefined, [Validators.required]),
      additionalSpec: new FormControl(undefined),
      make: new FormControl(undefined, [Validators.required]),
      model: new FormControl(undefined, [Validators.required]),
      modification: new FormControl(undefined),

      dateOfFirstRegistration: new FormControl(undefined, [Validators.required, dateValidator()]),
      productionYear: new FormControl(undefined),
      registrationType: new FormControl(undefined),
      productionType: new FormControl(undefined),

      vehicleUsage: new FormControl(undefined),
      ccm: new FormControl(undefined),
      tonnage: new FormControl(undefined),
      enginePowerhp: new FormControl(undefined),
      enginePowerkw: new FormControl(undefined),
      seatsNum: new FormControl(undefined),
      doorNum: new FormControl(undefined),
      color: new FormControl(undefined),
      carBodyType: new FormControl(undefined),
      fuelType: new FormControl(undefined),
      engineType: new FormControl(undefined),
      gearBox: new FormControl(undefined),
      driveType: new FormControl(undefined),
      mileagePerYear: new FormControl(undefined),

      steeringPositionRadio: new FormControl(RadioDefaults.LEFT, [Validators.required]),
      armoredVehicleRadio: new FormControl(RadioDefaults.NO, [Validators.required]),
      vehicleRegisterRadio: new FormControl(RadioDefaults.YES),

      authNumber: new FormControl(undefined),
    });

  public constructor(
    route: ActivatedRoute,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _process: CascoProcessService,
  ) {
    super(route);
    this._process.currentWorkflow = CascoWorkflow.PRICE_OFFER;
  }

  public ngOnInit(): void {
    this.fillModelList();
    this.convertHpTokw();
  }

  protected saveMtplData(): void {
    this.continueClicked = true;
  }

  public fillModelList(): void {
    this.vehicleForm.controls.make.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: any) => {
        this.vehicleForm.controls.model.reset();
        if (value) {
          if (value === Manufactures.BMW) {
            this.mockModelList = this.mockBmwList;
          } else if (value === Manufactures.FORD) {
            this.mockModelList = this.mockFordList;
          } else if (value === Manufactures.NISSAN) {
            this.mockModelList = this.mockNissanList;
          }
        }
      });
  }

  public convertHpTokw(): void {
    this.vehicleForm.controls.enginePowerhp.valueChanges
      .pipe(takeUntilDestroyed(this), distinctUntilChanged(), debounceTime(500))
      .subscribe((value: any) => {
        value
          ? this.vehicleForm.formGroup.patchValue(
              {
                enginePowerkw: value / PowerConverionFactors.UNIT_FACTOR,
              },
              {emitEvent: false},
            )
          : this.vehicleForm.formGroup.patchValue(
              {
                enginePowerkw: PowerConverionFactors.ZERO,
              },
              {emitEvent: false},
            );
      });
    this.vehicleForm.controls.enginePowerkw.valueChanges
      .pipe(takeUntilDestroyed(this), distinctUntilChanged(), debounceTime(500))
      .subscribe((value: any) => {
        value
          ? this.vehicleForm.formGroup.patchValue(
              {
                enginePowerhp: value * PowerConverionFactors.UNIT_FACTOR,
              },
              {emitEvent: false},
            )
          : this.vehicleForm.formGroup.patchValue(
              {
                enginePowerhp: PowerConverionFactors.ZERO,
              },
              {emitEvent: false},
            );
      });
  }

  public showFormFunction(value: boolean): void {
    this.showForm = value;
  }

  public searchVehicleByVinNo(vin: string): void {
    this.showForm = true;
    this.isData = false;
  }

  public searchVehicleByRegNo(vin: string): void {
    this.showForm = true;
    this.isData = false;
  }
}
