import {MtplRoutePaths} from '../../helper/mtpl-route-paths';
import {MtplB2BWorkFlow} from './MtplB2BWorkFlow';

export const workflows: {[name: string]: string[]} = {
  [MtplB2BWorkFlow.MTPL_POLICY]: [
    MtplRoutePaths.MTPL_PICK_TERMS,
    MtplRoutePaths.MTPL_OWNER_INSURERDETAILS,
    MtplRoutePaths.MTPL_POLICY_DETAILS,
    MtplRoutePaths.MTPL_INSTALMENTS,
    MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS,
    MtplRoutePaths.MTPL_PROPOSAL,
  ],
  [MtplB2BWorkFlow.CALCULATOR]: [
    MtplRoutePaths.MTPL_PICK_TERMS,
    MtplRoutePaths.MTPL_VEHICLE_DETAILS,
    MtplRoutePaths.MTPL_OWNER_INSURERDETAILS,
    MtplRoutePaths.MTPL_POLICY_DETAILS,
    MtplRoutePaths.MTPL_INSTALMENTS,
    MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS,
    MtplRoutePaths.MTPL_PROPOSAL,
  ],
};
