/* eslint-disable @typescript-eslint/unbound-method */
/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  @typescript-eslint/prefer-readonly */
import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Logger} from '@atlas/logger';
import {SlideInController} from '@maia/slide-ins';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenStepComponent,
} from '@njf-frontend-angular/flow-progress';
import {takeUntil} from 'rxjs/operators';
import {MtplRoutePaths} from '../../helper/mtpl-route-paths';
import {
  RegistrationType,
  VehcileDetailsModelForm,
  VehicleColor,
  VehicleFuelTypes,
  VehicleMake,
  VehicleModel,
  VehicleSubType,
  VehicleType,
} from '../../models/VehicleDetailsModelForm';
import {Text} from '@atlas/businesstypes';
import {MtplApiService} from '../../mtpl-core/services/mtpl-api.service';
import {MtplVehicleDetailsSlideinComponent} from './mtpl-vehicle-details-slidein/mtpl-vehicle-details-slidein.component';
import {NameAddressTranslationMapService} from '@b2b-frontend/core';
import {SharedService} from '../../mtpl-core/services/shared.service';
import {MtplVehicleOwnerDetails} from '../../models/MtplVehicleOwnerData';
import {MtplProcessService} from '../../mtpl-core/services/mtpl-process.service';
import {MtplB2BWorkFlow} from '../../mtpl-core/workflow/MtplB2BWorkFlow';

@Component({
  selector: 'nje-mtpl-vehicle-details',
  templateUrl: './mtpl-vehicle-details.component.html',
  styleUrls: ['./mtpl-vehicle-details.component.scss'],
})
@UntilDestroy()
export class MtplVehicleDetailsComponent extends ScreenStepComponent implements OnInit, OnDestroy {
  public readonly slideInOneFactory: ComponentFactory<MtplVehicleDetailsSlideinComponent>;
  private readonly _logger: Logger;
  public isScenarioThree: boolean = false;
  public isVehicleIdentificationNoVisible: boolean = false;
  public isBannerForScenarioThreeVisible: boolean = false;
  public isCertificationField: boolean = false;
  public vehcileRegNo: Text;
  public vehicleCertificationNo: Text;
  public vehicleType: VehicleType[];
  public vehicleSubType: VehicleSubType[];
  public vehicleMakeList: VehicleMake[];
  public vehicleModeList: VehicleModel[];
  public vehicleRegistrationTypeList: RegistrationType[];
  public vehicleFuelTypeList: VehicleFuelTypes[];
  public vehicleColorList: VehicleColor[];
  public invalidIdentificationNumber: boolean = false;
  public response: MtplVehicleOwnerDetails;
  public totalWeightNull: boolean = false;
  public volumeNull: boolean = false;
  public fuelTypeNull: boolean = false;
  public engineNull: boolean = false;
  public seatNumberNull: boolean = false;
  public colorNull: boolean = false;

  public vehicleDetailsForm: FormGroupManager<VehcileDetailsModelForm> =
    this._formFactory.createFormManager<VehcileDetailsModelForm>({
      vehicleRegistrationNumber: new FormControl(undefined),
      vehicleRegistrationNumberEn: new FormControl(undefined),
      vehicleIdentificationNumber: new FormControl(undefined, [
        Validators.maxLength(17),
        Validators.minLength(17),
      ]),
      vehicleIdentificationNumberEn: new FormControl(undefined),
      vehicleCertificateNumber: new FormControl(undefined),
      vehicleType: new FormControl(6, [Validators.required]),
      subTypeVehicleType: new FormControl(1, [Validators.required]),
      makeOfVehicle: new FormControl(undefined, [Validators.required]),
      modelOfVehicle: new FormControl('', [Validators.required]),
      dateOfFirstRegistration: new FormControl(undefined, [Validators.required]),
      vehicleRegType: new FormControl(undefined, [Validators.required]),
      totalWeight: new FormControl(undefined, [Validators.required]),
      volume: new FormControl(undefined, [Validators.required]),
      fuelType: new FormControl(undefined, [Validators.required]),
      enginePower: new FormControl(undefined, [Validators.required]),
      enginePowerKw: new FormControl(undefined, [Validators.required]),
      seatNumber: new FormControl(undefined, [Validators.required]),
      vehicleColor: new FormControl(undefined, [Validators.required]),
    });

  public constructor(
    private _sharedServices: SharedService,
    private readonly _process: MtplProcessService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _nameAddressTrasMapService: NameAddressTranslationMapService,
    public _activatedRoute: ActivatedRoute,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    public loggerFactory: LoggerFactory,
    private readonly _router: Router,
    private readonly _mtplApiService: MtplApiService,
    public componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(_activatedRoute);
    this.slideInOneFactory = componentFactoryResolver.resolveComponentFactory(
      MtplVehicleDetailsSlideinComponent,
    );
    this._logger = loggerFactory.createLogger('VehicleDetailsComponent');
    this._process.currentWorkflow = MtplB2BWorkFlow.CALCULATOR;
  }

  protected saveMtplData(): void {
    //TODO: it should be added at later stage
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ngOnInit(): void {
    this.initApiForDropDowns();
    this.response = this._sharedServices.vehicleOwnerDetails;
    console.log(this._sharedServices.vehicleOwnerDetails);
    if (this.response !== undefined) {
      if (this.response.vehicle.dataSource == 2) {
        this.isBannerForScenarioThreeVisible = true;
      }
      if (
        (this.response.vehicle.dataSource == 1 && this.response.vehicle.totalWeight != null) ||
        this.response.vehicle.dataSource == 2
      ) {
        this.isCertificationField = true;
      }
      if (this.response.vehicle.dataSource == 1 && this.response.vehicle.totalWeight == null) {
        this.isScenarioThree = true;
        this.isVehicleIdentificationNoVisible = true;
        this.vehicleDetailsForm.controls.vehicleCertificateNumber.disable();
        this.vehicleDetailsForm.controls.vehicleRegistrationNumber.disable();
        this.isCertificationField = true;
        this.vehicleDetailsForm.patchValue({
          vehicleCertificateNumber: this._sharedServices.certificationNumber,
          vehicleRegistrationNumber: this._sharedServices.registrationNumber,
        });
      }
    }

    this._sharedServices.isScenarioFour = this.isScenarioThree;
    this._sharedServices.isScenarioTwo = this.isBannerForScenarioThreeVisible;
    if (this.isScenarioThree) this.checkNullValuesForScenarioFour();

    this._nameAddressTrasMapService.updateVehicleIdentificationNumber(
      this.vehicleDetailsForm.formGroup,
    );
    this._nameAddressTrasMapService.updateVehicleRegisterationNumber(
      this.vehicleDetailsForm.formGroup,
    );
    this.regNumberTransliteration();
  }

  public regNumberTransliteration(): void {
    this.vehicleDetailsForm.controls.vehicleRegistrationNumber.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: string) => {
        const data: string =
          this.vehicleDetailsForm.controls.vehicleRegistrationNumberEn.value.toUpperCase();
        this.vehicleDetailsForm.formGroup.patchValue(
          {
            vehicleRegistrationNumber: data,
          },
          {emitEvent: false},
        );
      });
  }

  public searchByRegistrationNumber(value: string): void {
    console.log(value);
  }

  public vehicleIdentificationNoValidator(): void {
    this._translateIdentificationNumber();
  }

  public searchByIdentificationNumber(value: string): void {
    this._translateIdentificationNumber();
  }

  private _translateIdentificationNumber(): void {
    const pattern: RegExp = /^([A-Za-z\d?]{17})$/;
    if (this.vehicleDetailsForm.controls.vehicleIdentificationNumber.valid) {
      this.vehicleDetailsForm.controls.vehicleIdentificationNumber.setValue(
        this.vehicleDetailsForm.controls.vehicleIdentificationNumberEn.value,
      );
      this.invalidIdentificationNumber = !pattern.test(
        this.vehicleDetailsForm.controls.vehicleIdentificationNumber.value,
      );
    }
  }

  //TODO: it should be added at later stage
  public vehicleCertificationNoSearch(text: Text): void {
    this.vehicleCertificationNo = text;
  }

  public checkNullValuesForScenarioFour(): void {
    if (this.isVehicleIdentificationNoVisible) {
      this.vehicleDetailsForm.controls.vehicleType.clearValidators();
      this.vehicleDetailsForm.controls.subTypeVehicleType.clearValidators();
      this.vehicleDetailsForm.controls.modelOfVehicle.clearValidators();
      this.vehicleDetailsForm.controls.makeOfVehicle.clearValidators();
      this.vehicleDetailsForm.controls.dateOfFirstRegistration.clearValidators();
      this.vehicleDetailsForm.controls.vehicleRegType.clearValidators();
      this.vehicleDetailsForm.controls.totalWeight.clearValidators();
      this.vehicleDetailsForm.controls.volume.clearValidators();
      this.vehicleDetailsForm.controls.fuelType.clearValidators();
      this.vehicleDetailsForm.controls.enginePower.clearValidators();
      this.vehicleDetailsForm.controls.enginePowerKw.clearValidators();
      this.vehicleDetailsForm.controls.seatNumber.clearValidators();
      this.vehicleDetailsForm.controls.vehicleColor.clearValidators();
    }

    if (this.response.vehicle.totalWeight === null && this.isVehicleIdentificationNoVisible) {
      this.totalWeightNull = true;
      this.vehicleDetailsForm.controls.totalWeight.setValidators([Validators.required]);
    }
    if (this.response.vehicle.fuelType === null && this.isVehicleIdentificationNoVisible) {
      this.colorNull = true;
      this.vehicleDetailsForm.controls.fuelType.setValidators([Validators.required]);
    }
    if (this.response.vehicle.volume === null && this.isVehicleIdentificationNoVisible) {
      this.volumeNull = true;
      this.vehicleDetailsForm.controls.volume.setValidators([Validators.required]);
    }
    if (this.response.vehicle.engineCapacity === null && this.isVehicleIdentificationNoVisible) {
      this.engineNull = true;
      this.vehicleDetailsForm.controls.enginePower.setValidators([Validators.required]);
      this.vehicleDetailsForm.controls.enginePowerKw.setValidators([Validators.required]);
    }
    if (this.response.vehicle.seatsNum === null && this.isVehicleIdentificationNoVisible) {
      this.seatNumberNull = true;
      this.vehicleDetailsForm.controls.seatNumber.setValidators([Validators.required]);
    }
  }

  public initApiForDropDowns(): void {
    this._mtplApiService
      .getVehicleType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: VehicleType[]) => {
        this.vehicleType = data;
      });

    this._mtplApiService
      .getVehicleSubType(this.vehicleDetailsForm.controls.vehicleType.value)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: VehicleSubType[]) => {
        this.vehicleSubType = data;
      });

    this._mtplApiService
      .getVehicleMake(this.vehicleDetailsForm.controls.vehicleType.value)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: VehicleMake[]) => {
        this.vehicleMakeList = data;
      });

    this._mtplApiService
      .getRegistrationTypes()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: RegistrationType[]) => {
        this.vehicleRegistrationTypeList = data;
      });

    this._mtplApiService
      .getVehcileFuelType()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: VehicleFuelTypes[]) => {
        this.vehicleFuelTypeList = data;
      });

    this._mtplApiService
      .getVehicleColors()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: VehicleColor[]) => {
        this.vehicleColorList = data;
      });

    this.vehicleDetailsForm.controls.makeOfVehicle.valueChanges.subscribe(() => {
      this._mtplApiService
        .getVehicleModel('1', this.vehicleDetailsForm.controls.makeOfVehicle.value)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((data: VehicleModel[]) => {
          this.vehicleModeList = data;
          this.vehicleDetailsForm.controls.modelOfVehicle.reset();
        });
    });
  }

  public getTypeOfVehicle(value: number): string | null {
    const data = this.vehicleType
      ? this.vehicleType.find((options: VehicleType) => options.id == value)
      : null;
    return data ? data.description : null;
  }

  public getSubTypeOfVehicle(value: number): string | null {
    const data = this.vehicleSubType
      ? this.vehicleSubType.find((options: VehicleSubType) => options.id == value)
      : null;
    return data ? data.description : null;
  }

  public getMakeOfVehicle(value: string): string | null {
    const datas = this.vehicleMakeList
      ? this.vehicleMakeList.find((options: VehicleMake) => options.id == value)
      : null;
    return datas ? datas.name : null;
  }

  public getModelOfVehicle(value: string): string | null {
    const data = this.vehicleModeList
      ? this.vehicleModeList.find((options: VehicleModel) => options.id == value)
      : null;
    return data ? data.name : null;
  }

  public getVehicleRegisterType(value: number): string | null {
    const data = this.vehicleRegistrationTypeList
      ? this.vehicleRegistrationTypeList.find((options: RegistrationType) => options.id == value)
      : null;
    return data ? data.name : null;
  }

  public getFuelType(value: string): string | null {
    const data = this.vehicleFuelTypeList
      ? this.vehicleFuelTypeList.find((options: VehicleFuelTypes) => options.id == value)
      : null;
    return data ? data.name : null;
  }

  public getVehicleColor(value: string): string | null {
    const data = this.vehicleColorList
      ? this.vehicleColorList.find((options: VehicleColor) => options.id == value)
      : null;
    return data ? data.name : null;
  }

  public onContinue(): void {
    this._router.navigate(['../', MtplRoutePaths.MTPL_OWNER_INSURERDETAILS], {
      relativeTo: this._activatedRoute,
    });
  }

  public openOneSlideIn(): void {
    const slideInTitle = 'Slide-in one fitting content';
    this._slideInCtrl
      .prepare(
        this.slideInOneFactory,
        this._injector,
        {
          title: slideInTitle,
        },
        {
          withVisibleBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe({error: this._logger.error});
  }
}
