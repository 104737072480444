import {Component} from '@angular/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';

@Component({
  selector: 'nje-travel-insured-people-popup',
  templateUrl: './travel-insured-people-popup.component.html',
  styleUrls: ['./travel-insured-people-popup.component.scss'],
})
export class TravelInsuredPeoplePopupComponent extends ModalContentComponent<boolean> {
  public showTemplate: boolean = true;

  public constructor(public control: ModalControl<boolean>) {
    super();
  }

  public showDownloadTemplate(): void {
    this.showTemplate = false;
  }

  public confirmAndClose(status: boolean): void {
    this.control.confirm(status);
  }
}
