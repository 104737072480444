import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialFuncPersonReturnArrowSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendSocialFuncPersonReturnArrow]',
  template: '<svg:path d="M18.26 17.86l-1.7-1.6 1.7-1.7" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M16.56 16.26h1.3a2.6 2.6 0 1 1 0 5.2" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M11.62 13.9A20.43 20.43 0 0 0 5 15a2.86 2.86 0 0 0-1.9 2.66v3.8h9.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M11.62 13.89c-2.66 0-4.75-3-4.75-5.69v-.95a4.75 4.75 0 0 1 9.5 0h0v.95c0 2.66-2.09 5.69-4.75 5.69z" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialFuncPersonReturnArrowSvgComponent {
}

@NgModule({
  declarations: [
    SocialFuncPersonReturnArrowSvgComponent,
  ],
  exports: [
    SocialFuncPersonReturnArrowSvgComponent,
  ],
})
export class SocialFuncPersonReturnArrowSvgModule {}
