import {Injectable} from '@angular/core';
import {ApiService, API_END_POINT} from '@b2b-frontend/core';
import {Observable} from 'rxjs';

import {QuickVehicleAndOwnerDTO} from '../../contracts/quick-vehicle-interface';

@Injectable({
  providedIn: 'root',
})
export class CascoApiService {
  public constructor(private readonly _apiService: ApiService) {}

  public getVehicleDetails(
    registrationNo: string,
    certificateNumber: string,
  ): Observable<QuickVehicleAndOwnerDTO> {
    return this._apiService.get(
      `${API_END_POINT.VEHICLE_DETAILS}?regNo=${registrationNo}&certNo=${certificateNumber}`,
    );
  }
}
