import {Inject, Injectable, InjectionToken} from '@angular/core';
import {Router} from '@angular/router';

export const BASE_ROUTE = new InjectionToken<string>('@b2b-frontend-flows/payments base route');

@Injectable()
export class InstalmentPaymentsStarterService {
  public constructor(
    @Inject(BASE_ROUTE) private readonly _baseRoute: string,
    private readonly _router: Router,
  ) {}

  public start(): void {
    void this._router.navigate([this._baseRoute]);
  }
}
