<div class="m-t-24">
  <div class="flex-div" *ngIf="isBannerForScenarioThreeVisible">
    <maia-contextual-message type="warning" class="banner-style">
      Connection with the traffic police is lost. Please check the data.
    </maia-contextual-message>
  </div>

  <div class="flex-div" *ngIf="isScenarioThree">
    <maia-contextual-message type="error" class="banner-style">
      Please fill the missing data in the fields provided below
    </maia-contextual-message>
  </div>

  <div class="readmore-slidein-style">
    <button maiaDocumentAction (click)="openOneSlideIn()" class="font-size">Read More</button>
  </div>

  <div class="flex-div">
    <maia-contextual-message type="informative" class="banner-style"
      >If the policy will be converted in INSIS, an annex may need to be issues first
    </maia-contextual-message>
  </div>

  <div *ngIf="!isScenarioThree">
    <form maiaForm [formGroup]="vehicleDetailsForm.formGroup">
      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16">
          <maia-form-element
            hideOptionalIndicator
            class="input-column"
            label="Vehicle Registration Number"
          >
            <maia-input-group>
              <input maiaInput placeholder="XX0000XX" formControlName="vehicleRegistrationNumber" />
              <svg
                *ngIf="!isVehicleIdentificationNoVisible"
                (click)="
                  searchByRegistrationNumber(
                    vehicleDetailsForm.controls.vehicleRegistrationNumber.value
                  )
                "
                class="icon-size-color"
                b2bFrontendInformationFuncSearch
              ></svg>
            </maia-input-group>
          </maia-form-element>
        </div>

        <div class="fluid m-r-16" *ngIf="!isVehicleIdentificationNoVisible">
          <maia-form-element
            hideOptionalIndicator
            class="input-column"
            label="Vehicle Identification Number"
          >
            <maia-input-group>
              <input
                maiaInput
                placeholder="0000000000"
                formControlName="vehicleIdentificationNumber"
                (change)="vehicleIdentificationNoValidator()"
              />
              <svg
                (click)="
                  searchByIdentificationNumber(
                    vehicleDetailsForm.controls.vehicleIdentificationNumber.value
                  )
                "
                class="icon-size-color"
                b2bFrontendInformationFuncSearch
              ></svg>
            </maia-input-group>
            <maia-validation
              *ngIf="
                vehicleDetailsForm.hasError('vehicleIdentificationNumber', 'minlength') ||
                vehicleDetailsForm.hasError('vehicleIdentificationNumber', 'maxlength')
              "
            >
              Number of characters is different than 17
            </maia-validation>
            <maia-validation *ngIf="invalidIdentificationNumber">
              Please check VIN number again. Some of the filled symbols was in Cyrillic
            </maia-validation>
          </maia-form-element>
        </div>

        <div class="fluid m-r-16" *ngIf="isCertificationField">
          <maia-form-element
            hideOptionalIndicator
            class="input-column"
            label="Vehicle Certification Number"
          >
            <maia-input-group>
              <input
                maiaInput
                placeholder="0000000000"
                formControlName="vehicleCertificateNumber"
              />
              <svg
                *ngIf="!isVehicleIdentificationNoVisible"
                (click)="vehicleCertificationNoSearch($event)"
                class="icon-size-color"
                b2bFrontendInformationFuncSearch
              ></svg>
            </maia-input-group>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16" *ngIf="!isCertificationField"> </div>
        <div class="fluid m-r-16" *ngIf="isVehicleIdentificationNoVisible"> </div>
      </maia-fluid-fluid-layout>

      <maia-divider></maia-divider>

      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16">
          <maia-form-element
            hideOptionalIndicator
            label="Additional Vehicle Data"
            class="input-column"
          >
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>

      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column" label="Type of Vehicle">
            <maia-input-select name="vehicleType" formControlName="vehicleType">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getTypeOfVehicle(vehicleDetailsForm.controls.vehicleType.value)"
              />
              <maia-option *ngFor="let vehicle of vehicleType" [value]="vehicle.id">{{
                vehicle.description
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>

        <div class="fluid m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column" label="Sub-Type of Vehicle">
            <maia-input-select name="subTypeVehicleType" formControlName="subTypeVehicleType">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getSubTypeOfVehicle(vehicleDetailsForm.controls.subTypeVehicleType.value)"
              />
              <maia-option *ngFor="let vehicle of vehicleSubType" [value]="vehicle.id">{{
                vehicle.description
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column" label="Make">
            <maia-input-select name="makeOfVehicle" formControlName="makeOfVehicle">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getMakeOfVehicle(vehicleDetailsForm.controls.makeOfVehicle.value)"
              />
              <maia-option *ngFor="let vehicle of vehicleMakeList" [value]="vehicle.id">{{
                vehicle.name
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>
      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column" label="Model">
            <maia-input-select name="modelOfVehicle" formControlName="modelOfVehicle">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getModelOfVehicle(vehicleDetailsForm.controls.modelOfVehicle.value)"
              />
              <maia-option *ngFor="let vehicle of vehicleModeList" [value]="vehicle.id">{{
                vehicle.name
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16"> </div>
        <div class="fluid m-r-16"> </div>
      </maia-fluid-fluid-layout>
      <maia-divider></maia-divider>
      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16">
          <maia-form-element
            hideOptionalIndicator
            class="input-column"
            label="Date of first registration / Purchase"
          >
            <maia-input-date
              name="dateOfFirstRegistration"
              formControlName="dateOfFirstRegistration"
            ></maia-input-date>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column" label="Vehicle Reg. Type">
            <maia-input-select name="vehicleRegType" formControlName="vehicleRegType">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getVehicleRegisterType(vehicleDetailsForm.controls.vehicleRegType.value)"
              />
              <maia-option
                *ngFor="let vehicle of vehicleRegistrationTypeList"
                [value]="vehicle.id"
                >{{ vehicle.name }}</maia-option
              >
            </maia-input-select>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16"> </div>
      </maia-fluid-fluid-layout>
      <maia-divider></maia-divider>
      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16" *ngIf="!totalWeightNull">
          <maia-form-element hideOptionalIndicator class="input-column" label="Total Weight">
            <input
              maiaInput
              name="totalWeight"
              placeholder="Total Weight"
              formControlName="totalWeight"
            />
          </maia-form-element>
        </div>
        <div class="fluid m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column" label="Volume (ccm)">
            <input maiaInput name="volume" placeholder="Volume (ccm)" formControlName="volume" />
          </maia-form-element>
        </div>
        <div class="fluid m-r-16">
          <maia-form-element [hideOptionalIndicator]="true" class="input-column" label="Fuel Type">
            <maia-input-select name="fuelType" formControlName="fuelType">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getFuelType(vehicleDetailsForm.controls.fuelType.value)"
              />
              <maia-option *ngFor="let vehicle of vehicleFuelTypeList" [value]="vehicle.id">{{
                vehicle.name
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>
      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16">
          <maia-form-element
            [hideOptionalIndicator]="true"
            class="input-column"
            label="Engine Power / Engine Power (KW)"
            multi
          >
            <maia-form-subelement>
              <maia-input-group>
                <input
                  maiaInputNumber
                  atlasDecimal
                  placeholder="00"
                  formControlName="enginePower"
                />
                <maia-input-group-suffix>Hp</maia-input-group-suffix>
              </maia-input-group>
            </maia-form-subelement>
            <maia-form-subelement>
              <maia-input-group>
                <input
                  maiaInputNumber
                  atlasDecimal
                  placeholder="00"
                  formControlName="enginePowerKw"
                />
                <maia-input-group-suffix>Kw</maia-input-group-suffix>
              </maia-input-group>
            </maia-form-subelement>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column" label="Seat Number">
            <input
              maiaInput
              name="seatNumber"
              placeholder="Seat Number"
              formControlName="seatNumber"
            />
          </maia-form-element>
        </div>
        <div class="fluid m-r-16">
          <maia-form-element hideOptionalIndicator class="input-column" label="Colour">
            <maia-input-select name="vehicleColor" formControlName="vehicleColor">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getVehicleColor(vehicleDetailsForm.controls.vehicleColor.value)"
              />
              <maia-option *ngFor="let vehicle of vehicleColorList" [value]="vehicle.id">{{
                vehicle.name
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>
      <maia-divider></maia-divider>
      <button
        class="m-t-b-24"
        maiaButton
        maiaButtonType="primary"
        [disabled]="!vehicleDetailsForm.valid"
        (click)="onContinue()"
      >
        Continue
      </button>
    </form>
  </div>

  <div *ngIf="isScenarioThree">
    <form maiaForm [formGroup]="vehicleDetailsForm.formGroup">
      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16">
          <maia-form-element
            hideOptionalIndicator
            class="input-column"
            label="Vehicle Registration Number"
          >
            <maia-input-group>
              <input maiaInput placeholder="XX0000XX" formControlName="vehicleRegistrationNumber" />
              <svg
                *ngIf="!isVehicleIdentificationNoVisible"
                (click)="
                  searchByRegistrationNumber(
                    vehicleDetailsForm.controls.vehicleRegistrationNumber.value
                  )
                "
                class="icon-size-color"
                b2bFrontendInformationFuncSearch
              ></svg>
            </maia-input-group>
          </maia-form-element>
        </div>

        <div class="fluid m-r-16" *ngIf="isCertificationField">
          <maia-form-element
            hideOptionalIndicator
            class="input-column"
            label="Vehicle Certification Number"
          >
            <maia-input-group>
              <input
                maiaInput
                placeholder="0000000000"
                formControlName="vehicleCertificateNumber"
              />
              <svg
                *ngIf="!isVehicleIdentificationNoVisible"
                (click)="vehicleCertificationNoSearch($event)"
                class="icon-size-color"
                b2bFrontendInformationFuncSearch
              ></svg>
            </maia-input-group>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16" *ngIf="!isCertificationField"> </div>
        <div class="fluid m-r-16" *ngIf="isVehicleIdentificationNoVisible"> </div>
      </maia-fluid-fluid-layout>

      <maia-divider></maia-divider>

      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16">
          <maia-form-element
            hideOptionalIndicator
            label="Additional Vehicle Data"
            class="input-column"
          >
          </maia-form-element>
        </div>
      </maia-fluid-fluid-layout>
      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16" *ngIf="totalWeightNull">
          <maia-form-element hideOptionalIndicator class="input-column" label="Total Weight">
            <input
              maiaInput
              name="totalWeight"
              placeholder="Total Weight"
              formControlName="totalWeight"
            />
          </maia-form-element>
        </div>
        <div class="fluid m-r-16" *ngIf="!totalWeightNull"></div>
        <div class="fluid m-r-16" *ngIf="volumeNull">
          <maia-form-element hideOptionalIndicator class="input-column" label="Volume (ccm)">
            <input maiaInput name="volume" placeholder="Volume (ccm)" formControlName="volume" />
          </maia-form-element>
        </div>
        <div class="fluid m-r-16" *ngIf="fuelTypeNull">
          <maia-form-element [hideOptionalIndicator]="true" class="input-column" label="Fuel Type">
            <maia-input-select name="fuelType" formControlName="fuelType">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getFuelType(vehicleDetailsForm.controls.fuelType.value)"
              />
              <maia-option *ngFor="let vehicle of vehicleFuelTypeList" [value]="vehicle.id">{{
                vehicle.name
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16" *ngIf="!totalWeightNull"></div>
        <div class="fluid m-r-16" *ngIf="!volumeNull"></div>
        <div class="fluid m-r-16" *ngIf="!fuelTypeNull"></div>
      </maia-fluid-fluid-layout>
      <maia-fluid-fluid-layout>
        <div class="fluid m-r-16" *ngIf="engineNull">
          <maia-form-element
            [hideOptionalIndicator]="true"
            class="input-column"
            label="Engine Power / Engine Power (KW)"
            multi
          >
            <maia-form-subelement>
              <maia-input-group>
                <input
                  maiaInputNumber
                  atlasDecimal
                  placeholder="00"
                  formControlName="enginePower"
                />
                <maia-input-group-suffix>Hp</maia-input-group-suffix>
              </maia-input-group>
            </maia-form-subelement>
            <maia-form-subelement>
              <maia-input-group>
                <input
                  maiaInputNumber
                  atlasDecimal
                  placeholder="00"
                  formControlName="enginePowerKw"
                />
                <maia-input-group-suffix>Kw</maia-input-group-suffix>
              </maia-input-group>
            </maia-form-subelement>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16" *ngIf="seatNumberNull">
          <maia-form-element hideOptionalIndicator class="input-column" label="Seat Number">
            <input
              maiaInput
              name="seatNumber"
              placeholder="Seat Number"
              formControlName="seatNumber"
            />
          </maia-form-element>
        </div>
        <div class="fluid m-r-16" *ngIf="colorNull">
          <maia-form-element hideOptionalIndicator class="input-column" label="Colour">
            <maia-input-select name="vehicleColor" formControlName="vehicleColor">
              <input
                maiaInput
                ngDefaultControl
                placeholder="Select"
                [value]="getVehicleColor(vehicleDetailsForm.controls.vehicleColor.value)"
              />
              <maia-option *ngFor="let vehicle of vehicleColorList" [value]="vehicle.id">{{
                vehicle.name
              }}</maia-option>
            </maia-input-select>
          </maia-form-element>
        </div>
        <div class="fluid m-r-16" *ngIf="!engineNull"></div>
        <div class="fluid m-r-16" *ngIf="!seatNumberNull"></div>
        <div class="fluid m-r-16" *ngIf="!colorNull"></div>
      </maia-fluid-fluid-layout>
      <maia-divider></maia-divider>
      <button
        class="m-t-b-24"
        maiaButton
        maiaButtonType="primary"
        [disabled]="!vehicleDetailsForm.valid"
        (click)="onContinue()"
      >
        Continue
      </button>
    </form>
  </div>
</div>
