<div class="rightBox">
  <button maiaDocumentAction (click)="openSlideIn()" class="font-size">Read More</button>
</div>
<maia-label class="title b2b-kdl-font-normal-bold title-dark-color">
  Insuring Party Details
</maia-label>
<nje-insuring-party-layout></nje-insuring-party-layout>
<nje-vehicle-owner-layout></nje-vehicle-owner-layout>
<div class="list">
  <maia-label class="b2b-kdl-font-normal-bold title-dark-color"> Related Parties List </maia-label>
  <div class="rightbtn">
    <svg
      class="action-func-plus-circle"
      b2bFrontendActionFuncPlusCircle
      (click)="addNewDetails()"
    ></svg>
    <button maiaButton maiaButtonType="tertiary" class="add-btn" (click)="addNewDetails()"
      >Add New</button
    >
  </div>
</div>
<div *ngIf="isSame | atlasBoolean">
  <maia-contextual-message class="b2b-kdl-font-normal message-content"
    >Leesee details are mandatory. Kindly add data to proceed.
  </maia-contextual-message>
</div>
<maia-fluid-fluid-layout alignContentTop class="table">
  <maia-table>
    <maia-table-header>
      <maia-table-row>
        <maia-table-cell
          class="b2b-kdl-font-small subtitle-light-color"
          *ngFor="let column of columnHeaders; let i = index"
        >
          {{ column }}
        </maia-table-cell>
        <maia-table-cell></maia-table-cell>
      </maia-table-row>
    </maia-table-header>

    <maia-table-body>
      <maia-table-row *ngIf="addedInsuringPartyArray.length <= 0">
        <maia-contextual-message class="b2b-kdl-font-normal table-content">
          No related party records available
        </maia-contextual-message>
      </maia-table-row>
      <maia-table-row *ngFor="let data of addedInsuringPartyArray; let i = index">
        <maia-table-cell>
          <button
            maiaButton
            maiaButtonType="tertiary"
            class="edit-btn"
            (click)="editInsuringPartyDetails(data, i)"
            >{{ data.idNumber }}</button
          >
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ data?.firstName ? data?.firstName : '-' }} {{ data.middleName }} {{ data.lastName }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ data?.city ? data?.city.city : '-' }}
        </maia-table-cell>
        <maia-table-cell class="b2b-kdl-font-small title-dark-color">
          {{ data.relatedParty }}
        </maia-table-cell>
        <maia-table-cell>
          <div class="left">
            <svg
              class="delete-icon"
              b2bFrontendActionFuncBin
              (click)="removeInsuringParty(i)"
            ></svg>
          </div>
        </maia-table-cell>
      </maia-table-row>
    </maia-table-body>
  </maia-table>
</maia-fluid-fluid-layout>
<maia-divider></maia-divider>

<div>
  <button
    class="m-t-b-24"
    maiaButton
    maiaButtonType="primary"
    [disabled]="!isInsuringPartyDetailsValid()"
    (click)="saveInsuringPartyData()"
  >
    Continue</button
  >
</div>
