import {Decimal, Boolean} from '@atlas/businesstypes';
import {CoversStep} from '@b2b-frontend/core';

import {Amount, CoverAmount, Item} from '../travel-addons.component';

export const MOCK_COVER_AMOUNT: CoverAmount[] = [
  {
    name: '5 000 BGN',
    value: '5000',
  },
  {
    name: '10 000 BGN',
    value: '10000',
  },
  {
    name: '15 000 BGN',
    value: '15000',
  },
  {
    name: '20 000 BGN',
    value: '20000',
  },
  {
    name: '30 000 BGN',
    value: '30000',
  },
  {
    name: '50 000 BGN',
    value: '50000',
  },
];

export const MOCK_COVERS_ADDONS: CoversStep[] = [
  {
    id: 1,
    title: 'Unforeseen return to Bulgaria',
    insuredAmount: 3.35,
    insuredAmountCurrency: 'BGN',
    subtitle: ' ',
  },
  {
    id: 2,
    title: 'Flight delay',
    insuredAmount: 5.35,
    insuredAmountCurrency: 'BGN',
    subtitle: ' ',
  },
  {
    id: 3,
    title: 'Documents and luggage',
    insuredAmount: 5.35,
    insuredAmountCurrency: 'BGN',
    subtitle: ' ',
  },
  {
    id: 4,
    title: 'Accident',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: ' ',
    showCoverageAmountBox: new Boolean(false),
    coverageDetail: {
      title: 'Coverage Amount - Accident',
      amountArray: MOCK_COVER_AMOUNT,
      selectedCoverage: MOCK_COVER_AMOUNT[2],
    },
  },
  {
    id: 5,
    title: 'Legal Assistance',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: ' ',
    showCoverageAmountBox: new Boolean(false),
    coverageDetail: {
      title: 'Coverage Amount - Legal Assistance',
      amountArray: MOCK_COVER_AMOUNT,
      selectedCoverage: MOCK_COVER_AMOUNT[2],
    },
  },
  {
    id: 6,
    title: 'Third party liability',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: ' ',
    showCoverageAmountBox: new Boolean(false),
    coverageDetail: {
      title: 'Coverage Amount - TPL',
      amountArray: MOCK_COVER_AMOUNT,
      selectedCoverage: MOCK_COVER_AMOUNT[2],
    },
  },
  {
    id: 7,
    title: 'Theft',
    insuredAmount: 5.35,
    insuredAmountCurrency: 'BGN',
    subtitle: ' ',
  },
];

export const MOCK_COVERS_DISCOUNTS: CoversStep[] = [
  {
    id: 2,
    title: 'For a group of 10 to 20 persons',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: 'Get 0-5% offer on your total premium amount',
    showCoverageAmountBox: new Boolean(false),
  },
  {
    id: 3,
    title: 'For a group of 21 to 50 persons',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: 'Get 5-10% offer on your total premium amount',
  },
  {
    id: 4,
    title: 'For a group of 51 to 100 persons',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: 'Get 10-15% offer on your total premium amount',
  },
  {
    id: 5,
    title: 'For a group more than 100 persons',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: 'Get 15-20% offer on your total premium amount',
  },
  {
    id: 6,
    title: 'Comprehensive Insurance',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: '0-10%',
  },
  {
    id: 1,
    title: 'Promotional discounts',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: 'Get 10% offer on your total premium amount',
  },
  {
    id: 7,
    title: 'Promotional Discount',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: 'Visible for a certain period',
  },
  {
    id: 8,
    title: 'KBC Employee Discount',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: 'Fixed 30% (NB! Application only for Direct and UBB Sales Channel',
  },
  {
    id: 9,
    title: 'Loyal Client Discount',
    insuredAmount: 0.0,
    insuredAmountCurrency: 'BGN',
    subtitle: 'upto 50% (This cannot be combined with other discounts)',
  },
];

export const MOCK_ITEMS: Item[] = [
  {name: 'Coverage (3 people for 5 days)', value: '0.80 BGN'},
  {name: 'Addons', value: '14.80 BGN'},
  {name: 'Discount', value: '-10%'},
];

export const MOCK_ADDONS_AND_DISCOUNT_SCREEN = {
  MOCK_PRICE: '2.2',
  NUMBER_OF_PEOPLE: 2,
  NUMBER_OF_DAYS: 5,
};

export const MOCK_PREMIUM_PRICE: Amount = {
  value: new Decimal('15.43'),
  currency: 'BGN',
};

export const DEFAULT_VALUE = {
  VALUE: '15000',
};

export const MOCK_TITLE = {
  TITLE: 'Travel Insurance Premium (Incl.Taxes)',
};

export const MOCK_SELECT_ALL_CHECKBOX = {
  CHECKED_EVENT: Boolean.TRUE,
  UNCHECKED_EVENT: Boolean.FALSE,
};
