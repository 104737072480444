<div class="account-creation-wrapper">
  <maia-label class="title-dark-color account-creation-txt m-t-b-24">
    B2B Account Creation
  </maia-label>

  <div class="account-type-selection-wrapper">
    <div class="account-type-selection">
      <maia-label class="title-dark-color account-type-txt m-b-8"> New Account Type </maia-label>
      <maia-form-element hideOptionalIndicator class="input-column">
        <maia-input-select class="" id="select_one" [(ngModel)]="accountType">
          <input id="input_one" maiaInput placeholder="Select" [value]="accountType || null" />
          <maia-option id="optionOne" class="option_one" value="DZI Employee">
            DZI Employee
          </maia-option>
          <maia-option id="optionTwo" class="option_one" value="Manager Agent">
            Manager Agent
          </maia-option>
          <maia-option id="optionThree" class="option_one" value="Option Qux">
            Option Qux
          </maia-option>
          <maia-option id="optionFour" class="option_one" value="Option Quux">
            Option Quux
          </maia-option>
          <maia-option id="optionFive" class="option_one" value="Option Quuux">
            Option Quuux
          </maia-option>
        </maia-input-select>
      </maia-form-element>
    </div>
  </div>

  <div class="account-details" *ngIf="accountType === 'DZI Employee'">
    <nje-account-creation-details></nje-account-creation-details>
  </div>

  <div class="account-details" *ngIf="accountType === 'Manager Agent'">
    <nje-common-info></nje-common-info>
    <maia-divider></maia-divider>
    <nje-account-creation-details></nje-account-creation-details>
  </div>

  <button class="button-wrapper" maiaButton maiaButtonType="primary"> Continue </button>
</div>
