import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Logger} from '@atlas/logger';
import {PAGINATION} from '@b2b-frontend/core';
import {PopUpController, PopUpOptions} from '@maia/pop-ups';
import {tap} from 'rxjs/operators';

import {
  ACCOUNT_SUSPENDED_SUCCESSFULLY,
  APIParams,
  BLANK_STRING,
  POPUP_SIZE_LARGE,
  UserLanguage,
  UserStatus,
} from '../../../constants/account-management';
import {SuspendAccount} from '../../../models/b2b/SuspendAccount';
import {SuspendAccountResponse} from '../../../models/b2b/SuspendAccountResponse';
import {UserAccountDetails} from '../../../models/b2b/UserAccountDetails';
import {UsersList} from '../../../models/b2b/UsersList';
import {CommonMessage} from '../../../models/CommonMessage';
import {SearchedParameters} from '../../../models/SearchBy';
import {AccountManagementService} from '../../../services/account-management.service';
import {CommonService} from '../../../services/common.service';
import {CommonMessagePopupComponent} from '../common-message-popup/common-message-popup.component';
import {AccountSuspensionFormComponent} from './account-suspension-form/account-suspension-form.component';

@UntilDestroy()
@Component({
  selector: 'nje-account-suspension',
  templateUrl: './account-suspension.component.html',
  styleUrls: ['./account-suspension.component.scss'],
})
export class AccountSuspensionComponent implements OnInit {
  public commonMessagePopupFactory: ComponentFactory<CommonMessagePopupComponent>;
  public _logger: Logger;
  public isDataLoaded: boolean = false;
  public userAccountDetails: UserAccountDetails;
  public isFormValid: boolean = false;

  @ViewChild(AccountSuspensionFormComponent) accountSuspendForm: AccountSuspensionFormComponent;

  public constructor(
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    private readonly _popUpCtrl: PopUpController,
    private readonly _injector: Injector,
    private readonly _commonService: CommonService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _accountManagementService: AccountManagementService,
  ) {}

  public ngOnInit(): void {
    this._logger = this.loggerFactory.createLogger('CommmonMessagePopupComponent');
    this.commonMessagePopupFactory = this.componentFactoryResolver.resolveComponentFactory(
      CommonMessagePopupComponent,
    );
  }

  public searchAccounts(event: SearchedParameters): void {
    this.isDataLoaded = false;
    let queryParameters: string = `${APIParams.TYPE}${event.searchBy}${APIParams.AMPERSAND}${APIParams.USER_STATUS}${UserStatus.ACTIVE}${APIParams.AMPERSAND}${APIParams.VALUE}${event.searchTerm}${APIParams.AMPERSAND}${APIParams.LANG}${UserLanguage.EN}`;
    queryParameters = event.isUserSearch
      ? `${queryParameters}`
      : `${queryParameters}${APIParams.AMPERSAND}${APIParams.OFFSET}${PAGINATION.PAGES}${APIParams.AMPERSAND}${APIParams.LIMIT}${PAGINATION.PAGE_SIZE}`;

    event.isUserSearch
      ? this._userSearch(queryParameters)
      : this._organizationSearch(queryParameters);
  }

  private _userSearch(queryParameters: string): void {
    this.isDataLoaded = false;
    this._accountManagementService
      .getSearchB2BAccountsToActivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this.userAccountDetails = result.users[0];
            this._cdr.detectChanges();
          },
          (error: any) => {
            this.isDataLoaded = true;
            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  private _organizationSearch(queryParameters: string): void {
    this.isDataLoaded = false;
    this._accountManagementService
      .getOrganizationSearchB2BAccountsToActivateList(queryParameters)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: UsersList) => {
            this.isDataLoaded = true;
            this.userAccountDetails = result.users[0];
            this._cdr.detectChanges();
          },
          (error: any) => {
            this.isDataLoaded = true;
            this._cdr.detectChanges();
          },
        ),
      )
      .subscribe();
  }

  public suspendAccount(): void {
    const formData: SuspendAccount = this.accountSuspendForm.getAccountSuspensionData();
    formData.userIds = [this.userAccountDetails.id];
    this._accountManagementService
      .suspendB2BAccount(formData)
      .pipe(
        takeUntilDestroyed(this),
        tap(
          (result: SuspendAccountResponse) => {
            this._openSuccessContainer();
          },
          (error: any) => {},
        ),
      )
      .subscribe();
  }

  private _openSuccessContainer(): void {
    const commonMessage: CommonMessage = {
      messageBody: ACCOUNT_SUSPENDED_SUCCESSFULLY,
    };
    this._commonService.setcommonMessage(commonMessage);
    this.openSuccessPopup({title: BLANK_STRING, size: POPUP_SIZE_LARGE});
  }

  public openSuccessPopup(options: PopUpOptions): void {
    this._popUpCtrl
      .prepare(this.commonMessagePopupFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: false,
        input: [],
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  public cancelSuspension(): void {
    // TODO - route to fallback screen
  }
}
