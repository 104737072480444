import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'duplicates',
  templateUrl: './duplicates.component.html',
  styleUrls: ['./duplicates.component.css'],
})
export class DuplicatesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
