<form maiaForm [formGroup]="policyDetailsForm.formGroup">
  <maia-fluid-fluid-layout alignContentTop
    ><div class="m-r-16 m-t-16">
      <maia-label class="b2b-kdl-font-small title-dark-color"> Policy Type </maia-label>

      <maia-radio-group formControlName="policyType">
        <njf-radio-button
          class="radio-btn-margin"
          *ngFor="let policyType of policyTypeList"
          [value]="policyType.value"
          (click)="onChangeOfPolicyType(policyType.value)"
          >{{ policyType.label }}</njf-radio-button
        >
      </maia-radio-group>
    </div>

    <div *ngIf="!isPolicySelected">&nbsp;</div>
    <div class="policy-blue-box" *ngIf="isPolicySelected">
      <maia-form-element [hideOptionalIndicator]="true" class="multi-input-row" multi>
        <maia-label class="b2b-kdl-font-small title-dark-color m-b-8"> Start Date </maia-label
        ><maia-form-subelement
          [class.p-maia-form-element--validation-error]="
            policyDetailsForm.controls?.policyStartDate?.dirty &&
            policyDetailsForm.controls?.policyStartDate?.errors
          "
          class="claim-amount-field"
        >
          <div
            [class.p-maia-form-element--show-validation]="
              policyDetailsForm.controls?.policyStartDate?.errors
            "
          >
            <maia-input-date
              name="policyStartDate"
              formControlName="policyStartDate"
              [minimum]="minPolicyStartDate"
              [maximum]="maxPolicyStartDate"
              (ngModelChange)="onChangeOfPolicyStartDate()"
            ></maia-input-date>
            <div *ngIf="policyDetailsForm.controls?.policyStartDate?.dirty">
              <maia-validation
                id="validation-invalid-characters"
                *ngIf="policyDetailsForm.controls?.policyStartDate?.errors?.atlasInvalidCharacters"
              >
                Invalid Characters
              </maia-validation>
              <maia-validation
                id="validation-invalid-characters"
                *ngIf="policyDetailsForm.controls?.policyStartDate?.errors?.atlasInvalidLength"
              >
                Please enter a valid date value
              </maia-validation>
              <maia-validation
                id="validation-invalid-characters"
                *ngIf="policyDetailsForm.controls?.policyStartDate?.errors?.atlasBefore"
              >
                Start Date cannot be more than 65 days from tomorrow
              </maia-validation>
              <maia-validation
                id="validation-invalid-characters"
                *ngIf="policyDetailsForm.controls?.policyStartDate?.errors?.atlasAfter"
              >
                Start Date cannot be before tomorrow
              </maia-validation>
            </div>
          </div>
        </maia-form-subelement>
      </maia-form-element>
      <maia-form-element hideOptionalIndicator class="input-column" *ngIf="!isPolicyTermless">
        <maia-label class="b2b-kdl-font-small title-dark-color m-b-8 m-t-16"> End Date </maia-label>
        <maia-input-date
          name="policyEndDate"
          formControlName="policyEndDate"
          [minimum]="maxPolicyEndDate"
          [maximum]="maxPolicyEndDate"
          [disabled]="disabledField | atlasBoolean"
        ></maia-input-date>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>
  <maia-divider></maia-divider>

  <div class="m-b-24">
    <maia-label class="b2b-kdl-font-small title-dark-color"> Risk Questions </maia-label>
  </div>

  <maia-panel class="panel-warning" *ngIf="!ableToCompleteRiskQuestions()">
    <div> {{ riskQuestionsWarningMessage }} </div>
  </maia-panel>

  <maia-fluid-fluid-layout alignContentTop>
    <div class="m-r-16">
      <maia-form-element [hideOptionalIndicator]="true" class="input-column">
        <maia-label class="b2b-kdl-font-small title-dark-color m-t-16">
          The insured building is Semi-massive or Massive
        </maia-label>
        <maia-radio-group
          formControlName="isInsuredBuildingMassive"
          (ngModelChange)="ableToCompleteRiskQuestions()"
          horizontal
        >
          <njf-radio-button
            *ngFor="let insuredBuildingType of insuredBuildingTypeList"
            class="white-bg-color"
            [value]="insuredBuildingType.value"
            [disabled]="!isPolicySelected"
            >{{ insuredBuildingType.label }}</njf-radio-button
          >
        </maia-radio-group>
      </maia-form-element>
    </div>
    <div>
      <maia-form-element [hideOptionalIndicator]="true" class="input-column">
        <maia-label class="b2b-kdl-font-small title-dark-color">
          The required documents issued by the technical authorities for using the immovable
          property is available
        </maia-label>
        <maia-radio-group formControlName="areRequiredDocsAvailable" horizontal>
          <njf-radio-button
            *ngFor="let requiredDocsAvailability of requiredDocsAvailabilityList"
            class="white-bg-color"
            [value]="requiredDocsAvailability.value"
            [disabled]="!isPolicySelected"
            >{{ requiredDocsAvailability.label }}</njf-radio-button
          >
        </maia-radio-group>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout alignContentTop>
    <div class="m-r-16">
      <maia-form-element [hideOptionalIndicator]="true" class="input-column">
        <maia-label class="b2b-kdl-font-small title-dark-color">
          Did you suffer damage for the property that is subject matter of the insurance, in the
          last 36 months?
        </maia-label>
        <maia-radio-group formControlName="isPropertyDamaged" horizontal>
          <njf-radio-button
            *ngFor="let propertyDamageType of propertyDamageTypeList"
            class="white-bg-color"
            [value]="propertyDamageType.value"
            [disabled]="!(isPolicySelected && ableToCompleteRiskQuestions())"
            >{{ propertyDamageType.label }}</njf-radio-button
          >
        </maia-radio-group>
      </maia-form-element>
    </div>
    <div>
      <maia-form-element [hideOptionalIndicator]="true" class="input-column">
        <maia-label class="b2b-kdl-font-small title-dark-color m-b-16 m-t-16">
          Inhabitancy of the building/premise:
        </maia-label>
        <maia-input-select name="buildingInhabitancyType" formControlName="buildingInhabitancyType">
          <input
            maiaInput
            placeholder="Select"
            [value]="policyDetailsForm.controls.buildingInhabitancyType.value"
          />
          <maia-option
            *ngFor="let buildingInhabitancyType of apiInhabitancyTypes"
            [value]="buildingInhabitancyType.description"
            >{{ buildingInhabitancyType.description }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <div class="policy-blue-box" formGroupName="insurerDetails" *ngIf="isPropertyDamaged()">
    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="multi-input-row" multi>
          <maia-label class="b2b-kdl-font-small title-dark-color"> Insurer </maia-label>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="getInsurerNameFieldErrors()"
          >
            <div [class.p-maia-form-element--show-validation]="getInsurerNameFieldErrors()">
              <input
                maiaInput
                type="text"
                placeholder="Insurer Name"
                formControlName="insurerName"
              />
              <div *ngIf="getInsurerNameFieldErrors()">
                <maia-validation
                  id="validation-invalid-characters"
                  *ngIf="
                    policyDetailsForm.controls?.insurerDetails?.get('insurerName')?.errors
                      ?.required ||
                    '' ===
                      policyDetailsForm.controls?.insurerDetails?.get('insurerName')?.value?.trim()
                  "
                >
                  Insurer Name required
                </maia-validation>
              </div>
            </div>
          </maia-form-subelement>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-form-element hideOptionalIndicator class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color m-t-24 m-b-8">
            Start Date of Contract
          </maia-label>
          <maia-input-month
            name="contractStartDate"
            [minimum]="minContractStartDate"
            [maximum]="maxContractStartDate"
            formControlName="contractStartDate"
            (ngModelChange)="onChangeOfContractStartDate()"
            placeholder="Select Month/Year"
          >
          </maia-input-month>
        </maia-form-element>
      </div>

      <div class="m-r-16">
        <maia-form-element hideOptionalIndicator class="input-column">
          <maia-label class="b2b-kdl-font-small title-dark-color m-t-24 m-b-8">
            End Date of Contract
          </maia-label>
          <maia-input-month
            name="contractEndDate"
            [minimum]="maxContractEndDate"
            [maximum]="maxContractEndDate"
            formControlName="contractEndDate"
            placeholder="Select Month/Year"
          >
          </maia-input-month>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-fluid-fluid-layout>
      <div class="m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="multi-input-row" multi>
          <maia-label
            class="b2b-kdl-font-small title-dark-color"
            sublabel="If an amount higher than 1000 BGN is  entered then the request for insurance will be  subjected to additional approval "
          >
            Claim Amount
          </maia-label>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="getClaimAmountFieldErrors()"
            class="claim-amount-field"
          >
            <div [class.p-maia-form-element--show-validation]="getClaimAmountFieldErrors()">
              <input
                maiaInputNumber
                atlasMaxLengthFractionPart="2"
                atlasDecimal
                placeholder="Amount"
                formControlName="claimAmount"
                (change)="getClaimAmount()"
              />
              <div *ngIf="getClaimAmountFieldErrors()">
                <maia-validation
                  id="validation-invalid-characters"
                  *ngIf="
                    policyDetailsForm.controls?.insurerDetails?.get('claimAmount')?.errors?.required
                  "
                >
                  Claims Amount required
                </maia-validation>
                <maia-validation
                  class="validation-container"
                  type="error"
                  *ngIf="
                    policyDetailsForm.controls?.insurerDetails?.get('claimAmount')?.errors
                      ?.atlasFractionPartTooLong
                  "
                >
                  Only 2 decimal places allowed.
                </maia-validation>
                <maia-validation
                  id="validation-invalid-characters"
                  *ngIf="
                    policyDetailsForm.controls?.insurerDetails?.get('claimAmount')?.errors
                      ?.atlasInvalidFormat
                  "
                >
                  Invalid characters
                </maia-validation>
                <maia-validation
                  id="validation-invalid-characters"
                  *ngIf="
                    policyDetailsForm.controls?.insurerDetails?.get('claimAmount')?.errors
                      ?.atlasIncompleteFractionPart
                  "
                >
                  Incomplete Fraction characters
                </maia-validation>
              </div>
            </div>
          </maia-form-subelement>
          <maia-form-subelement>
            <maia-input-select class="m-t-8" name="currency" formControlName="currency">
              <input
                maiaInput
                [value]="policyDetailsForm.controls?.insurerDetails?.get('currency')?.value"
              />
              <maia-option
                *ngFor="let currency of apiCurrencyList"
                [value]="currency.description"
                >{{ currency.description }}</maia-option
              >
            </maia-input-select>
          </maia-form-subelement>
        </maia-form-element>
      </div>
      <div class="m-r-16">
        <maia-form-element [hideOptionalIndicator]="true" class="m-t-16 multi-input-row" multi>
          <maia-label class="b2b-kdl-font-small title-dark-color"> Type of event </maia-label>
          <maia-form-subelement
            [class.p-maia-form-element--validation-error]="getTypeOfEventFieldErrors()"
          >
            <div [class.p-maia-form-element--show-validation]="getTypeOfEventFieldErrors()">
              <input
                maiaInput
                type="text"
                placeholder="Type of event"
                formControlName="typeOfEvent"
              />
              <div *ngIf="getTypeOfEventFieldErrors()">
                <maia-validation
                  id="validation-invalid-characters"
                  *ngIf="
                    policyDetailsForm.controls?.insurerDetails?.get('typeOfEvent')?.errors
                      ?.required ||
                    '' ===
                      policyDetailsForm.controls?.insurerDetails?.get('typeOfEvent')?.value?.trim()
                  "
                >
                  Type of event required
                </maia-validation>
              </div>
            </div>
          </maia-form-subelement>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>
  </div>

  <maia-fluid-fluid-layout alignContentTop>
    <div class="m-r-16">
      <maia-form-element [options]="{labelSize: 'larger'}" class="input-column">
        <maia-label class="b2b-kdl-font-small title-dark-color">
          If you are insuring others property, please define the grounds
        </maia-label>
        <maia-text-area-limit maxLength="200">
          <textarea
            id="text-area-limit"
            maiaTextArea
            formControlName="groundsForOtherPropertyInsurance"
            atlasText
            name="textAreaLimit"
            atlasMaxLength="200"
            (nbRemainingCharacters)="updateRemainingCharactersLimit($event)"
          ></textarea>
        </maia-text-area-limit>
      </maia-form-element>
    </div>
    <div>
      <maia-form-element [hideOptionalIndicator]="true" class="input-column fire-safety-field">
        <maia-label class="b2b-kdl-font-small title-dark-color">
          Fire safety protection for Property
        </maia-label>
        <maia-radio-group formControlName="isFireSafetyAvailable" horizontal>
          <njf-radio-button
            *ngFor="let fireSafetyAvailability of fireSafetyAvailabilityList"
            class="white-bg-color"
            [value]="fireSafetyAvailability.value"
            [disabled]="!(isPolicySelected && ableToCompleteRiskQuestions())"
            >{{ fireSafetyAvailability.label }}</njf-radio-button
          >
        </maia-radio-group>
      </maia-form-element>
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column"
        *ngIf="isPropertyFireProtected()"
      >
        <maia-label class="b2b-kdl-font-small title-dark-color">
          If "YES", please, specify:
        </maia-label>
        <maia-input-select name="fireSafetyType" formControlName="fireSafetyType">
          <input
            maiaInput
            placeholder="Select"
            [value]="policyDetailsForm.controls.fireSafetyType.value"
          />
          <maia-option
            *ngFor="let fireSafetyType of apiFireProtectionTypes"
            [value]="fireSafetyType.description"
            >{{ fireSafetyType.description }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout>
    <div>
      <maia-form-element [hideOptionalIndicator]="true" class="input-column securityMeasures">
        <maia-label class="b2b-kdl-font-small title-dark-color m-b-8 m-t-24">
          Security measures:
        </maia-label>
        <maia-input-select name="securityMeasures" formControlName="securityMeasures">
          <input
            maiaInput
            placeholder="Select"
            [value]="policyDetailsForm.controls.securityMeasures.value"
          />
          <maia-option
            *ngFor="let securityMeasures of apiSecurityMeasureTypes"
            [value]="securityMeasures.description"
            >{{ securityMeasures.description }}</maia-option
          >
        </maia-input-select>
      </maia-form-element>
    </div>
  </maia-fluid-fluid-layout>

  <maia-divider></maia-divider>
</form>

<button
  class="m-b-24"
  maiaButton
  maiaButtonType="primary"
  [disabled]="!isContinueButtonEnabled()"
  (click)="saveAndContinue()"
  >Continue</button
>
