<div class="deactivate-b2c-account-wrapper">
  <p class="b2b-kdl-font-normal b2b-kdl-color-primary-dark-120 deactivate-account-header"
    >Deactivate B2C Account</p
  >
  <nje-search-box (searchData)="searchAccounts($event)"></nje-search-box>
  <maia-divider></maia-divider>

  <div class="table-wrapper">
    <maia-table id="tableCustomCss" *ngIf="accountsList?.length && isDataLoaded">
      <maia-table-header id="tableHeader3">
        <maia-table-row id="tableRowHeader3">
          <maia-table-cell
            class="b2b-kdl-font-small subtitle-light-color"
            *ngFor="let column of tableColumns"
          >
            {{ columnHeadings[column] }}
          </maia-table-cell>
          <maia-table-cell class="icon-container" maiaTableHeaderHiddenOnSmall></maia-table-cell>
        </maia-table-row>
      </maia-table-header>

      <maia-table-body id="tableBody3">
        <maia-table-row
          [id]="'tableRow' + '3'"
          *ngFor="let account of accountsList; let i = index"
          blueOnSmall
        >
          <maia-table-cell
            class="b2b-kdl-font-small title-dark-color"
            [maiaTableHeaderValue]="'Header'"
          >
            {{ account?.firstNameEn }} {{ account?.middleNameEn }} {{ account?.lastNameEn }}
          </maia-table-cell>
          <maia-table-cell
            class="b2b-kdl-font-small title-dark-color"
            [maiaTableHeaderValue]="'Header'"
          >
            {{ account?.pin }}
          </maia-table-cell>
          <maia-table-cell
            class="b2b-kdl-font-small title-dark-color"
            [maiaTableHeaderValue]="'Header'"
          >
            {{ account?.userName ? account?.userName : account?.emailId }}
          </maia-table-cell>
          <maia-table-cell [maiaTableHeaderValue]="'Header'">
            <button maiaButton maiaButtonType="tertiary" (click)="deactivateAccount(account)">
              Deactivate
            </button>
          </maia-table-cell>
        </maia-table-row>
      </maia-table-body>
    </maia-table>
    <div class="no-account-present" *ngIf="!accountsList?.length && !isDataLoaded">
      Loading accounts.
    </div>
    <div class="no-account-present" *ngIf="!accountsList?.length && isDataLoaded && !searchedTerm">
      No account available.
    </div>
    <div class="no-account-present" *ngIf="!accountsList?.length && isDataLoaded && searchedTerm">
      No such Account exists in the Directory
    </div>
  </div>

  <maia-divider></maia-divider>

  <div class="pagination-wrapper" *ngIf="totalPages > 1">
    <maia-pagination
      id="pagination-general"
      general
      [(page)]="currentPageNumber"
      [totalPages]="totalPages"
      [lastPage]="lastPage()"
      (pageChange)="pageChange($event)"
    ></maia-pagination>
  </div>
</div>
