<div class="account-password-reset-wrapper">
  <div class="title-wrapper">
    <maia-label class="b2b-kdl-font-medium-bold title-dark-color">
      B2C Account Password Reset
    </maia-label>
    <nje-search-box (searchData)="searchAccounts($event)"></nje-search-box>
  </div>

  <div class="account-details-wrapper" *ngIf="isDataLoaded">
    <maia-divider class="divider"></maia-divider>
    <nje-account-details [userDetails]="userDetails" *ngIf="isDataAvailable"> </nje-account-details>
    <maia-label class="no-account-present" *ngIf="!isDataAvailable">
      No such account exists. Kindly fill in the correct data.
    </maia-label>
    <maia-button-group class="button-wrapper" *ngIf="isDataAvailable">
      <button maiaButton maiaButtonType="primary" (click)="resetPassword()">
        Reset Password
      </button>
      <button maiaButton maiaButtonType="secondary">Cancel</button>
    </maia-button-group>
  </div>
</div>
