/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {CommonModule} from '@angular/common';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule as AngularFormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {BusinessTypesModule} from '@atlas-angular/businesstypes';
import {μExampleSvgModule} from '@b2b-frontend/icons';
import {ActionsModule} from '@maia/actions';
import {AdditionalInformationModule as MaiaAdditionalInformationModule} from '@maia/additional-information';
import {ButtonsModule as MaiaButtonsModule} from '@maia/buttons';
import {DatePickersModule} from '@maia/datepickers';
import {DividerModule as MaiaDividerModule} from '@maia/divider';
import {DropdownsModule} from '@maia/dropdowns';
import {FormsModule as MaiaFormsModule} from '@maia/forms';
import {InputAutocompleteModule as MaiaInputAutocompleteModule} from '@maia/input-autocomplete';
import {InputPhonenumberModule as MaiaInputPhonenumberModule} from '@maia/input-phonenumber';
import {InputSelectModule as MaiaInputSelectModule} from '@maia/input-select';
import {LayoutModule as MaiaLayoutModule} from '@maia/layout';
import {RadioButtonsModule as MaiaRadioButtonsModule} from '@maia/radio-buttons';
import {TilesModule as MaiaTilesModule} from '@maia/tiles';
import {
  FlowProgressModule,
  AutocompleteFactoryService,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';
import {NjfRadioButtonsModule} from '@njf-frontend-angular/radio-buttons';
import {HttpResponseInterceptor} from './backend/interceptors/http.response.interceptor';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {EgnLnchLayoutComponent} from './components/egn-layout/egn-lnch-layout.component';
import {EikLayoutComponent} from './components/eik-layout/eik-layout.component';
import {HeaderWithBreadcrumbsComponent} from './components/header-with-breadcrumbs/header-with-breadcrumbs.component';
import {LabelWithEditComponent} from './components/label-with-edit/label-with-edit.component';
import {EgnValidatorService} from './core-helper/validator/insureparty-type/egn/egn-validator.service';
import {EikValidatorService} from './core-helper/validator/insureparty-type/eik/eik-validator.service';
import {LnchValidatorService} from './core-helper/validator/insureparty-type/lnch/lnch-validator.service';
import {FieldPairTranslitControllerService} from './services/field-pair-translit-controller.service';
import {FocusManagerService} from './services/focus-manager.service';
import {PartyIdService} from './services/party-id.service';
import {TransliterateService} from './services/translation/transliterate.service';
import {ENV} from './constants/env.di';
import {InsurancePartyDetailsComponent} from './components/insurance-party-details/insurance-party-details.component';
import {MessagesModule} from '@maia/messages';
import {SearchBarModule as MaiaSearchBarModule} from '@maia/search-bar';
import {
  AdvancedSearchModule as MaiaAdvancedSearchModule,
  AdvancedSearchOptionAmountModule,
  AdvancedSearchOptionDateModule,
  AdvancedSearchOptionFreeModule,
} from '@maia/advanced-search';
import {SwitchesModule} from '@maia/switches';
import {LoaderComponent} from './components/loader/loader.component';
import {SpinnersModule as MaiaSpinnersModule} from '@maia/spinners';
import {DataService} from './services/data.service';
import {localeValPipe} from './pipes/localeVal.pipe';
import {LocalizationService} from './services/localization.service';
import {PolicyNumberValidatorService} from './services/policy-number.service';

@NgModule({
  declarations: [
    EgnLnchLayoutComponent,
    EikLayoutComponent,
    BreadcrumbsComponent,
    HeaderWithBreadcrumbsComponent,
    LabelWithEditComponent,
    InsurancePartyDetailsComponent,
    LoaderComponent,
    localeValPipe,
  ],
  imports: [
    CommonModule,
    BusinessTypesModule,
    AngularFormsModule,
    ReactiveFormsModule,
    MaiaButtonsModule,
    MaiaFormsModule,
    MaiaRadioButtonsModule,
    MaiaTilesModule,
    MaiaDividerModule,
    MaiaAdditionalInformationModule,
    ActionsModule,
    DatePickersModule,
    MaiaInputSelectModule,
    DropdownsModule,
    NjfRadioButtonsModule,
    MaiaLayoutModule,
    MaiaInputPhonenumberModule,
    FlowProgressModule,
    MaiaInputAutocompleteModule,
    HttpClientModule,
    RouterModule,
    μExampleSvgModule,
    MessagesModule,
    SwitchesModule,
    MaiaSearchBarModule,
    MaiaAdvancedSearchModule,
    AdvancedSearchOptionAmountModule,
    AdvancedSearchOptionDateModule,
    AdvancedSearchOptionFreeModule,
    MaiaSpinnersModule,
  ],
  exports: [
    EgnLnchLayoutComponent,
    EikLayoutComponent,
    BreadcrumbsComponent,
    HeaderWithBreadcrumbsComponent,
    LabelWithEditComponent,
    InsurancePartyDetailsComponent,
    LoaderComponent,
    localeValPipe,
  ],
  providers: [
    AutocompleteFactoryService,
    FormGroupManagerFactoryService,
    FieldPairTranslitControllerService,
    FocusManagerService,
    PartyIdService,
    TransliterateService,
    EikValidatorService,
    EgnValidatorService,
    DataService,
    LnchValidatorService,
    LocalizationService,
    PolicyNumberValidatorService,
    {provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true},
  ],
})
export class B2bCoreModule {
  public static forRoot(environment: any): ModuleWithProviders<B2bCoreModule> {
    return {
      ngModule: B2bCoreModule,
      providers: [
        {
          provide: ENV,
          useValue: environment,
        },
      ],
    };
  }
}
