import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FlagUtilHungarySvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFlagUtilHungary]',
  template: '<svg:defs><svg:path id="svgiJjWfLyJf8iMY9Oc0m2AhGnn5FjREk3ggSrHz5qvz1g=" d="M0 0h48v48H0z"></svg:path></svg:defs><svg:clipPath id="svgneCI7EDlf5fJb+t4yl7Dblo2f9/dtv8w3SrNfpoLuqg="><svg:use href="#svgiJjWfLyJf8iMY9Oc0m2AhGnn5FjREk3ggSrHz5qvz1g=" overflow="visible"></svg:use></svg:clipPath><svg:g clip-path="url(#svgneCI7EDlf5fJb+t4yl7Dblo2f9/dtv8w3SrNfpoLuqg=)"><svg:path fill="#FFFFFF" d="M0 0h48v48H0z"></svg:path><svg:path fill="#F0F0F0" d="M0 0h48v48H0z"></svg:path><svg:path fill="#D80027" d="M0 0h48v16H0z"></svg:path><svg:path fill="#6DA544" d="M0 32h48v16H0z"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'xlink': 'http://www.w3.org/1999/xlink',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagUtilHungarySvgComponent {
}

@NgModule({
  declarations: [
    FlagUtilHungarySvgComponent,
  ],
  exports: [
    FlagUtilHungarySvgComponent,
  ],
})
export class FlagUtilHungarySvgModule {}
