import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {
  StepperProcessService as StepperProcessServiceBase,
  StepperProcessStep,
} from '@njf-frontend-angular/flow-progress';

import {TravelWorkflow} from '../travel-workflow/TravelWorkflow';
import {steps} from '../travel-workflow/steps';
import {workflows} from '../travel-workflow/workflows';

const TRAVEL_SESSION_KEY = 'travel-process';

@Injectable({
  providedIn: 'root',
})
export class TravelProcessService
  extends StepperProcessServiceBase<TravelWorkflow>
  implements OnDestroy
{
  public constructor(router: Router) {
    super(router, steps, workflows, TravelWorkflow.CALCULATOR, TRAVEL_SESSION_KEY);
  }

  protected getWorkflowStepsFromWorkflowAndStep(
    wf: TravelWorkflow,
    step: StepperProcessStep,
  ): StepperProcessStep[] {
    return this.getWorkflowSteps(wf);
  }

  protected getCurrentWorkflowStepsList(): string[] {
    return this.workflows[this.currentWorkflow];
  }
}
