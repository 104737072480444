import {Component, ComponentFactoryResolver, Injector, OnInit} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {UntilDestroy} from '@atlas-angular/rxjs';
import {DateKey, DateUtils} from '@atlas/businesstypes';
import {Logger} from '@atlas/logger';
import {DateFormatterService} from '@b2b-frontend/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {Subscription} from 'rxjs';

import {ACTIVATE, CONFIRM} from '../../../constants/account-management';
import {AccountActivationDeactivation} from '../../../models/b2b/AccountActivationDeactivation';
import {UserAccountDetails} from '../../../models/b2b/UserAccountDetails';
import {UserActivateDeactivatePopup} from '../../../models/b2b/UserActivateDeactivatePopup';
import {AccountActivateDeactivateService} from '../../../services/account-activate-deactivate.service';

/* eslint-disable @typescript-eslint/unbound-method */
@UntilDestroy()
@Component({
  selector: 'nje-activate-account-popup',
  templateUrl: './activate-account-popup.component.html',
  styleUrls: ['./activate-account-popup.component.scss'],
})
export class ActivateAccountPopupComponent extends ModalContentComponent<void> implements OnInit {
  public accountsData: UserActivateDeactivatePopup;
  public minDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, 365);
  public activationDate: Date;
  public popupDetailsSubscription: Subscription;
  public _logger: Logger;

  public constructor(
    public control: ModalControl<any>,
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    public readonly _injector: Injector,
    private readonly _accountActivateDeactivateService: AccountActivateDeactivateService,
    private readonly _dateFormatterService: DateFormatterService,
  ) {
    super();
    this.popupDetailsSubscription = this._accountActivateDeactivateService
      .getActivatePopupDetails()
      .subscribe((data: UserActivateDeactivatePopup) => {
        this.accountsData = data;
      });
  }

  public ngOnInit() {}

  public getUserFullname(account: UserAccountDetails): string {
    return `${account.firstNameEn} ${account.lastNameEn} ${account.middleNameEn}`;
  }

  public getUserPID(account: UserAccountDetails): string {
    return `${account.pid}`;
  }

  public getAccountType(account: UserAccountDetails): string {
    return `${account.userTypeId}`;
  }

  public getUserName(account: UserAccountDetails): string {
    return account.userName ? `${account.userName}` : `${account.emailId}`;
  }

  public getConfirmButtonText(): string {
    return this.accountsData.individualAccount ? ACTIVATE : CONFIRM;
  }

  public activateAccount(): void {
    const activationDate = this._dateFormatterService.dateToISO(this.activationDate);
    const activateData: AccountActivationDeactivation = {
      userData: this.accountsData,
      actionDate: activationDate,
    };
    this.control.confirm(activateData);
  }

  public closePopup(): void {
    this._clearSubscriptions();
    this.control.cancel();
  }

  private _clearSubscriptions(): void {
    if (this.popupDetailsSubscription) {
      this.popupDetailsSubscription.unsubscribe();
    }
  }
}
