import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TravelRoutePaths} from '@b2b-frontend/core';
import {TravelRenewDetails} from '@b2b-frontend/core/src/core-helper/dto/travel-dto/TravelRenewPolicy';
import {ScreenFormComponent} from '@njf-frontend-angular/flow-progress';
import {TravelSessionService} from '../../travel-core/services/travel-session.service';
import {MOCK_RENEW_POLICY_DETAILS} from './__tests__/travel-renew-policy.mock';

@Component({
  selector: 'travel-renew-policy',
  templateUrl: './travel-renew-policy.component.html',
  styleUrls: ['./travel-renew-policy.component.scss'],
})
export class TravelRenewPolicyComponent extends ScreenFormComponent implements OnInit, OnDestroy {
  public newTermsPath: string = `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRIP_DETAILS}`;
  public existingTermsPath: string = `/dashboard/policies/${TravelRoutePaths.TI}/${TravelRoutePaths.TI_TRAVEL_PROPOSAL}`;
  public actionTileSelected: string;
  public buttonDisable: boolean = true;
  public new = MOCK_RENEW_POLICY_DETAILS[0].value;
  public existing = MOCK_RENEW_POLICY_DETAILS[1].value;
  public renewDetailsArray: TravelRenewDetails[] = [...MOCK_RENEW_POLICY_DETAILS];
  public actionSelectable: Promise<boolean>;

  public constructor(
    private readonly _router: Router,
    public travelSessionService: TravelSessionService,
    route: ActivatedRoute,
  ) {
    super(route);
  }

  public ngOnInit(): void {}

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public tileClicked(currentTile: string): void {
    this.actionTileSelected = currentTile;
    if (currentTile === this.new) {
      this.actionTileSelected = this.new;
    } else if (currentTile === this.existing) {
      this.actionTileSelected = this.existing;
    }
    this.buttonDisable = false;
  }

  //navigate to trip details and proposal
  public navigate(): void {
    this.saveMtplData();
    this.actionSelectable =
      this.actionTileSelected === this.new
        ? this._router.navigateByUrl(this.newTermsPath)
        : this._router.navigateByUrl(this.existingTermsPath);
  }

  //session updation for renew screen
  protected saveMtplData(): void {
    const travelRenewDetailsObj: TravelRenewDetails | undefined = this.renewDetailsArray.find(
      (tTile: TravelRenewDetails) => tTile.value,
    );
    if (travelRenewDetailsObj) {
      const traveleRenewPolicyDto = {
        travelRenewDetails: this.renewDetailsArray,
      };
      this.travelSessionService.setTravelRenewPolicyData(traveleRenewPolicyDto);
    }
  }
}
