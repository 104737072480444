import {
  Component,
  OnInit,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
} from '@angular/core';
import {LoggerFactory} from '@atlas-angular/logger';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {MtplRoutePaths} from '../../helper/mtpl-route-paths';
import {SlideInController} from '@maia/slide-ins';
import {Logger} from '@atlas/logger';

import {AdditionalCoversRisksSlideinComponent} from './additional-covers-risks-slidein/additional-covers-risks-slidein.component';

import {Decimal} from '@atlas/businesstypes';
import {AdditionalCover, CheckBox} from '../../models/AdditionalCover';
import {MOCK_COVER_AMOUNT} from './__tests__/addtional-covers-risks-mock';
import {ReadMoreSlideinComponent} from './read-more-slidein/read-more-slidein.component';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';

@Component({
  selector: 'nje-additional-covers-risks',
  templateUrl: './additional-covers-risks.component.html',
  styleUrls: ['./additional-covers-risks.component.scss'],
})
@UntilDestroy()
export class AdditionalCoversRisksComponent extends ScreenStepComponent implements OnInit {
  private readonly _logger: Logger;
  private readonly _slideInOneFactory: ComponentFactory<AdditionalCoversRisksSlideinComponent>;
  private readonly _injector: Injector;
  public premiumAmount: number = 608.59;
  public addPrem: number;
  public installment1 = '338.66';
  public currency = 'BGN';
  public showHideCustomAmout: boolean = false;
  public showHideCustomAmoutDiv: boolean = false;
  public showHideCustom: boolean = false;
  public addsOneGroup1: AdditionalCover[];
  public addsOneGroup2: AdditionalCover[];
  public addsOneGroup3: AdditionalCover[];
  public checkGroup: CheckBox[];
  public valueCoverAmount: string;
  public coverAmount = MOCK_COVER_AMOUNT;
  public select: boolean = false;
  public slideInReadMoreFactory: any;

  public constructor(
    public loggerFactory: LoggerFactory,
    private readonly _slideInCtrl: SlideInController,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super(activatedRoute);
    this._slideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      AdditionalCoversRisksSlideinComponent,
    );
    this.slideInReadMoreFactory =
      _componentFactoryResolver.resolveComponentFactory(ReadMoreSlideinComponent);
    this._logger = loggerFactory.createLogger('AdditionalCoversRisksComponent');
  }

  protected saveMtplData(): void {
    // ToDo:added at later stage
  }

  public ngOnInit(): void {
    this.addsOneGroup1 = [
      {
        id: '1',
        additionalCoverRisk: 'Road Assistance Accident Support',
        amount: '25.00',
        currency: 'BGN',
        selected: true,
        additional: '',
      },
      {
        id: '2',
        additionalCoverRisk: 'Legal Aid',
        amount: '30.00',
        currency: 'BGN',
        selected: false,
        additional: '',
      },
    ];

    this.addsOneGroup2 = [
      {
        id: '3',
        additionalCoverRisk: 'Standard',
        amount: '25.00',
        currency: 'BGN',
        selected: false,
        additional: 'Add Additional Risks',
      },
      {
        id: '4',
        additionalCoverRisk: 'Premium',
        amount: '30.00',
        currency: 'BGN',
        selected: false,
        additional: 'Add Additional Risks',
      },
      {
        id: '5',
        additionalCoverRisk: 'VIP',
        amount: '32.00',
        currency: 'BGN',
        selected: false,
        additional: '',
      },
    ];

    this.addsOneGroup3 = [
      {
        id: '6',
        additionalCoverRisk: 'Accident',
        amount: '0.00',
        currency: 'BGN',
        selected: false,
        additional: '',
      },
    ];

    this.checkGroup = [
      {
        price: '429.00',
        currency: 'BGN',
        checked: false,
      },
    ];
  }

  public onContinue(): void {
    this.router.navigate(['../', MtplRoutePaths.MTPL_PROPOSAL], {
      relativeTo: this.activatedRoute,
    });
  }

  public openOneSlideIn(): void {
    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {
          title: '',
        },
        {
          withVisibleBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((response: ModalConfirmedResult<number>) => {
        if (response.resolution == ModalResolution.CONFIRMED) {
          console.log(response.result);
          this.addPrem = response.result;
        }
      });
  }

  public openReadMoreSlideIn(): void {
    this._slideInCtrl
      .prepare(
        this.slideInReadMoreFactory,
        this._injector,
        {
          title: 'Read More!!!',
        },
        {
          withVisibleBackdrop: true,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe({error: this._logger.error});
  }

  public updateSelectedCover(data: AdditionalCover): void {
    this.addsOneGroup1.forEach(cover => {
      cover.id === data.id ? (cover.selected = true) : (cover.selected = false);
    });
    this.premiumAmount = parseFloat(data.amount) + this.premiumAmount;
  }

  public getAmoutValue(amount: string): Decimal {
    return new Decimal(amount);
  }

  public reset_filter_group1(): void {
    this.select = true;

    for (let i = 0; i < this.addsOneGroup1.length; i++) {
      if (this.addsOneGroup1[i].selected) {
        this.addsOneGroup1[i].selected = false;
        this.premiumAmount = this.premiumAmount - parseFloat(this.addsOneGroup1[i].amount);
      }
    }
  }

  public reset_filter_group2(): void {
    for (let i = 0; i < this.addsOneGroup2.length; i++) {
      if (this.addsOneGroup2[i].selected) {
        this.addsOneGroup2[i].selected = false;
      }
      if (!this.addsOneGroup2[i].selected) {
        this.showHideCustomAmoutDiv = false;
        this.premiumAmount = this.premiumAmount - parseFloat(this.addsOneGroup2[i].amount);
      }
    }
  }

  public reset_filter_group3(): void {
    this.select = true;

    for (let i = 0; i < this.addsOneGroup3.length; i++) {
      console.log(this.addsOneGroup3[i].selected);
      if (this.addsOneGroup3[i].selected) {
        this.addsOneGroup3[i].selected = false;
      }
      if (!this.addsOneGroup3[i].selected) {
        this.showHideCustomAmout = false;
        this.premiumAmount = this.premiumAmount - parseFloat(this.addsOneGroup3[i].amount);
      }
    }
  }

  public selectTile(data: AdditionalCover) {
    console.log(data, this.select);
    this.premiumAmount = parseFloat(data.amount) + this.premiumAmount;
    if (data != undefined) {
      data.selected = true;
      console.log(data);
    }
  }

  public selectCheckTile(data: CheckBox): void {
    this.premiumAmount = parseFloat(data.price) + this.premiumAmount;
    if (data != undefined) {
      data.checked = true;
    }
  }

  public calPrem(): void {
    for (let i = 0; i < this.addsOneGroup3.length; i++) {
      this.premiumAmount = this.premiumAmount + parseFloat(this.coverAmount[i].value);
    }
  }
}
