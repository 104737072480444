/* eslint-disable @typescript-eslint/unbound-method */
/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  TablePremiumHead,
  TablePremiumList,
  MOCK_PREMIUM_DATA_LIST,
  MOCK_HEAD_PREMIUM,
  MOCK_HEAD_INSTALMENT,
  TableInstalmentHead,
  TableInstalmentList,
  MOCK_INSTALMENT_LIST,
  RadiooptionList,
  MOCK_OPTION_LIST,
  MOCK_INSTALMENT,
  instalment,
  MOCK_POLICYDETAILS_LIST,
  policyDetailsList,
  defineDocumentList,
  MOCK_DEFINE_DOCUMENT_LIST,
} from './__tests__/proposal.mock';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MtplRoutePaths} from '../../helper/mtpl-route-paths';
import {SlideInController} from '@maia/slide-ins';
import {LoggerFactory} from '@atlas-angular/logger';
import {Logger} from '@atlas/logger';
import {ProposalSlideinComponent} from './proposal-slidein/proposal-slidein.component';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {PopUpController, PopUpOptions} from '@maia/pop-ups';
import {EmailPopupComponent} from './email-popup/email-popup.component';
import {LabelEditDto, TalonScanUploadService} from '@b2b-frontend/core';
import {MTPL_PROPOSAL} from '../../mtpl-constants.ts/constant';
import {MtplProcessService} from '../../mtpl-core/services/mtpl-process.service';

@Component({
  selector: 'nje-proposal',
  templateUrl: './proposal.component.html',
  styleUrls: ['./proposal.component.scss'],
})
@UntilDestroy()
export class ProposalComponent extends ScreenStepComponent implements OnInit {
  public instalmentsPath: string = `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_INSTALMENTS}`;
  public coversPath: string = `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_ADDITIONALCOVERS_RISKS}`;
  public policyDetailsPath: string = `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_POLICY_DETAILS}`;
  public vehicleDetailsPath: string = `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_VEHICLE_DETAILS}`;
  public ownerInsurerPath: string = `/dashboard/policies/${MtplRoutePaths.MTPL}/${MtplRoutePaths.MTPL_OWNER_INSURERDETAILS}`;

  public showmore: boolean = false;
  public proposalForm: FormGroup;
  public disablePolicybutton = false;
  private readonly _logger: Logger;
  public showErroMsg: boolean = false;
  public addmoredocument: boolean = false;
  public additionalvehicle: boolean = false;
  public deleteColour = 'grey';
  public disableProposal: boolean = false;

  public fileName: string = '';
  public fileNames: string = '';
  public modeOfUploading: string;
  public uploadTile: string = 'Select';
  public uploadTile1: string = 'Select';

  public mockPremiumHeading: TablePremiumHead[] = [...MOCK_HEAD_PREMIUM];
  public mockPremiumDataList: TablePremiumList[] = [...MOCK_PREMIUM_DATA_LIST];
  public mockInstalmentHeading: TableInstalmentHead[] = [...MOCK_HEAD_INSTALMENT];
  public mockInstalmentList: TableInstalmentList[] = [...MOCK_INSTALMENT_LIST];
  public mockRadio: RadiooptionList[] = [...MOCK_OPTION_LIST];
  public mockInstal: instalment[] = [...MOCK_INSTALMENT];
  public mockPolicyDetails: policyDetailsList[] = [...MOCK_POLICYDETAILS_LIST];
  public mockDefineDoc: defineDocumentList[] = [...MOCK_DEFINE_DOCUMENT_LIST];

  private readonly _slideInOneFactory: ComponentFactory<ProposalSlideinComponent>;
  public readonly _logging: Logger;
  private readonly _ProposalEmailFactory: ComponentFactory<EmailPopupComponent>;

  public instalmentsDetailsHeading: LabelEditDto = {
    id: 1,
    name: MTPL_PROPOSAL.POLICY_INSTALMENT,
    navigationPath: this.instalmentsPath,
  };

  public coversDetailsHeading: LabelEditDto = {
    id: 2,
    name: MTPL_PROPOSAL.ADDITIONAL_COVERS,
    navigationPath: this.coversPath,
  };

  public policyDetailsHeading: LabelEditDto = {
    id: 3,
    name: MTPL_PROPOSAL.POLICY_DETAILS,
    navigationPath: this.policyDetailsPath,
  };

  public vehicleDetailsHeading: LabelEditDto = {
    id: 4,
    name: MTPL_PROPOSAL.VEHICLE_DETAILS,
    navigationPath: this.vehicleDetailsPath,
  };

  public ownerInsurerHeading: LabelEditDto = {
    id: 5,
    name: MTPL_PROPOSAL.OWNER_INSURER_DETAILS,
    navigationPath: this.ownerInsurerPath,
  };

  public constructor(
    private readonly _slideInCtrl: SlideInController,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    public activatedRoute: ActivatedRoute,
    private readonly _popUpCtrl: PopUpController,
    public loggerFactory: LoggerFactory,
    public componentFactoryResolver: ComponentFactoryResolver,
    public readonly _injector: Injector,
    public router: Router,
    private readonly _talonScanUploadService: TalonScanUploadService,
    private readonly formBuilder: FormBuilder,
    public process: MtplProcessService,
  ) {
    super(activatedRoute);
    this._logger = loggerFactory.createLogger('ProposalSlideinComponent');
    this.process.exitStepEdit();

    this._slideInOneFactory =
      this._componentFactoryResolver.resolveComponentFactory(ProposalSlideinComponent);
    this._logger = loggerFactory.createLogger('ProposalComponent');
    this._ProposalEmailFactory =
      componentFactoryResolver.resolveComponentFactory(EmailPopupComponent);
    this._talonScanUploadService.scanResultSubject.next('');
  }

  public openSlideIn(): void {
    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {
          title: 'Lorem ipsum',
        },
        {
          withVisibleBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe({error: this._logger.error});
  }

  private _openPoup(options: PopUpOptions) {
    return this._popUpCtrl
      .prepare(this._ProposalEmailFactory, this._injector, options, {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
        input: undefined,
      })
      .pipe(takeUntilDestroyed(this))
      .subscribe({error: this._logger.error});
  }

  public openPopup(): void {
    this._openPoup({title: '', size: 'large'});
  }

  public showmoreTable(): void {
    this.showmore = !this.showmore;
  }

  public showadditionaldata(): void {
    this.additionalvehicle = !this.additionalvehicle;
  }

  public addDocument(): void {
    this.addmoredocument = true;
    this.deleteColour = 'red';
  }

  public async onContinue(): Promise<void> {
    await this.router.navigate(['../', MtplRoutePaths.MTPL_PICK_TERMS], {
      relativeTo: this.activatedRoute,
    });
  }

  public getDocument(value: string): string | null {
    const data = this.mockDefineDoc
      ? this.mockDefineDoc.find((options: defineDocumentList) => options.defineDoc == value)
      : null;
    return data ? data.defineDoc : null;
  }

  public uploadFile(fileInput: any, mode: string): void {
    const file = fileInput.target.files[0];
    this.fileName = file.name;
    if (this.fileName == '') {
      this.uploadTile = 'Select';
    } else {
      this.uploadTile = this.fileName;
    }
  }

  public uploadDoc(fileInputs: any, mode: string): void {
    const file = fileInputs.target.files[0];
    this.fileNames = file.name;
    this.modeOfUploading = mode;
    if (this.fileNames == '') {
      this.uploadTile1 = 'Select';
    } else {
      this.uploadTile1 = this.fileNames;
    }
  }

  public resetUpload(): void {
    this.fileName = '';
    if (this.fileName == '') {
      this.uploadTile = 'Select';
    }
  }

  public resetDoc(): void {
    this.fileNames = '';
    if (this.fileNames == '') {
      this.uploadTile1 = 'Select';
    }
  }

  protected saveMtplData(): void {}

  public editScreen(screenDetail: LabelEditDto): void {
    const step = this.process.getStepByRoute(screenDetail.navigationPath);
    if (step) {
      step.edit(this.step);
    }
  }

  public ngOnInit(): void {
    this.proposalForm = this.formBuilder.group({
      issuesAdded: new FormControl(1, [Validators.required]),
      definedocument: new FormControl(''),
      definedocument1: new FormControl(''),
      greenCardSeries: new FormControl('', [Validators.required]),
      greenCardNumber: new FormControl('', [Validators.required]),
      stickerSeries: new FormControl('', [Validators.required]),
      stickerNumber: new FormControl('', [Validators.required]),
    });
  }
}
