import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlowModule} from '../flow/flow.module';
import {WebcamSnapshotComponent} from './component/webcam-snapshot/webcam-snapshot.component';
import {TalonScanUploadComponent} from './component/talon-scan-upload/talon-scan-upload.component';
import {TalonSlideinComponent} from './component/talon-slidein/talon-slidein.component';
import {ModalsModule as MaiaModalsModule} from '@maia/modals';
import {MessagesModule} from '@maia/messages';
import {ButtonsModule} from '@maia/buttons';
import {FormsModule} from '@maia/forms';
import {TilesModule} from '@maia/tiles';
import {μExampleSvgModule} from '@b2b-frontend/icons';
import {LayoutModule as MaiaLayoutModule} from '@maia/layout';
import {DividerModule as MaiaDividerModule} from '@maia/divider';

@NgModule({
  imports: [
    CommonModule,
    FlowModule,
    MaiaModalsModule,
    MessagesModule,
    ButtonsModule,
    FormsModule,
    MaiaDividerModule,
    TilesModule,
    MaiaLayoutModule,
    μExampleSvgModule,
  ],
  declarations: [TalonScanUploadComponent, WebcamSnapshotComponent, TalonSlideinComponent],
  providers: [],
  exports: [TalonScanUploadComponent, WebcamSnapshotComponent, TalonSlideinComponent],
  entryComponents: [TalonSlideinComponent],
})
export class TalonScanningModule {}
