<form maiaForm>
  <div class="container">
    <label class="b2b-kdl-font-normal-bold cover-title m-b-16">Full Casco</label>
    <label class="b2b-kdl-font-small title-dark-color">Deductible</label>
  </div>
  <maia-radio-group [(ngModel)]="deductibleAmount" name="deductible">
    <maia-tile
      *ngFor="let option of deductibleOptions$ | async"
      class="maia-radio-button"
      [ngClass]="{
        'radio-tile-selected': deductibleAmount?.id === option.id
      }"
    >
      <maia-radio-button [value]="option" class="border-none">
        <label class="radio-text b2b-kdl-font-small-light">{{ option.desc_en }}</label>
      </maia-radio-button>
    </maia-tile>

    <div class="blue-box" *ngIf="deductibleAmount?.id === 2">
      <div class="container">
        <maia-label class="b2b-kdl-font-small cover-title m-b-8"> Deductible Amount</maia-label>
        <maia-fluid-fluid-layout>
          <maia-input-group>
            <input
              [(ngModel)]="otherAmount"
              name="otherAmount"
              placeholder="Select"
              maiaInputNumber
            />
            <maia-input-group-suffix>BGN</maia-input-group-suffix>
          </maia-input-group>
        </maia-fluid-fluid-layout>
      </div>
    </div>
  </maia-radio-group>
  <maia-button-group>
    <button
      class="button"
      type="button"
      maiaButton
      [disabled]="isAddDesabled()"
      maiaButtonType="primary"
      (click)="sendOption()"
      >Add</button
    >
    <button
      class="button"
      type="button"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancelSlideIn()"
      >Cancel</button
    >
  </maia-button-group>
</form>
