export enum RoutePaths {
  Cms = 'cms/resource',
  AdminDocuments = 'admin-documents',
  Contacts = 'contacts',
  Articles = 'news-and-events',
  Library = 'library',
  Product = 'product',
  AddProduct = 'addproduct',
  EditProduct = 'editproduct',
}
