import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialExprPersonTieSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendSocialExprPersonTie]',
  template: '<svg:path fill="none" stroke="#00AEEF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M22.73 32.24h2.54"></svg:path><svg:path fill="none" stroke="#00AEEF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M25.27 32.24l3.8 4.43-2.17 2.72"></svg:path><svg:path fill="none" stroke="#00AEEF" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M25.27 32.24l1.26-2.54h-5.06l1.26 2.54-1.9 8.23L24 43l3.17-2.53z"></svg:path><svg:path fill="none" stroke="#003665" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M24 21.72h0c-4.2 0-7.6-3.4-7.6-7.6 0 0 0 0 0 0V12.6C16.4 8.4 19.8 5 24 5h0c4.2 0 7.6 3.4 7.6 7.6 0 0 0 0 0 0v1.52c0 4.19-3.4 7.6-7.6 7.6z"></svg:path><svg:path fill="none" stroke="#003665" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M31.68 38.43h7.52v-3.18a6.05 6.05 0 0 0-4.46-5.86c-3.5-.99-7.11-1.53-10.74-1.6-3.63.07-7.24.61-10.74 1.6a6.05 6.05 0 0 0-4.46 5.86v3.18h8.92"></svg:path>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialExprPersonTieSvgComponent {
}

@NgModule({
  declarations: [
    SocialExprPersonTieSvgComponent,
  ],
  exports: [
    SocialExprPersonTieSvgComponent,
  ],
})
export class SocialExprPersonTieSvgModule {}
