<div class="success-wrapper">
  <svg b2bFrontendAlertExprCheckCircle class="icon-adjust"></svg>
  <maia-label>
    <label class="title-wrapper b2b-kdl-font-grand-serif title-dark-color">You're all set! </label>
  </maia-label>
  <maia-label>
    <label class="subtitle-light-color b2b-kdl-font-small m-b-16">
      From now on you'll sign in the portal using your new password and code generated from Google
      Authenticator app.
    </label>
  </maia-label>
  <button maiaButton maiaButtonType="primary" class="finish-button m-t-16">Finish</button>
</div>
