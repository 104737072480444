import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {ENV} from '../constants/env.di';
import {SkipLoaderInterceptorHeader} from '../core-helper/constants/common-constants';
import {CONFIGS} from '../core-helper/constants/Config';
import {EnvConfig} from './interface/env-config';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public chooseFileSubject = new BehaviorSubject({});

  public API_HOST_URL = this._env.API_BASE_URL + this._env.API_URL;

  public constructor(
    private readonly http: HttpClient,
    @Inject(ENV) private readonly _env: EnvConfig,
  ) {}

  public formatErrors(error: any) {
    return throwError(error);
  }

  public getJSON(localPath: any): Observable<any> {
    const headers: {[header: string]: string} = {};
    headers[SkipLoaderInterceptorHeader] = '';
    return this.http.get(localPath, {headers: headers}).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError(this.formatErrors.bind(this)),
    );
  }

  public get<Result = any>(
    path: string,
    params?: any,
    pagination: boolean = false,
    skipLoader: boolean = false,
  ): Observable<Result> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });

      if (pagination) {
        queryParams = queryParams.append('itemsPerPage', `${CONFIGS.QUERY_LIMIT}`);
      }
    }

    const headers: {[header: string]: string} = {};
    if (skipLoader) {
      headers[SkipLoaderInterceptorHeader] = '';
    }

    return this.http.get(`${this.API_HOST_URL}${path}`, {params: queryParams, headers}).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError(this.formatErrors.bind(this)),
    );
  }

  public getWithParams<Result = any>(
    path: string,
    params?: any,
    pagination: boolean = false,
    skipLoader: boolean = false,
  ): Observable<Result> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any) => {
        queryParams = queryParams.append(value[0], value[1]);
      });

      if (pagination) {
        queryParams = queryParams.append('itemsPerPage', `${CONFIGS.QUERY_LIMIT}`);
      }
    }

    const headers: {[header: string]: string} = {};
    if (skipLoader) {
      headers[SkipLoaderInterceptorHeader] = '';
    }

    return this.http.get(`${this.API_HOST_URL}${path}`, {params: queryParams, headers}).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError(this.formatErrors.bind(this)),
    );
  }

  public put(path: string, body: object = {}, params: object = {}): Observable<any> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }

    return this.http
      .put(`${this.API_HOST_URL}${path}`, JSON.stringify(body), {params: queryParams})
      .pipe(
        map((res: Response) => {
          return res;
        }),
        catchError(this.formatErrors.bind(this)),
      );
  }

  public post<Result = any>(
    path: string,
    body: object = {},
    header?: any,
    params: object = {},
    skipLoader: boolean = false,
  ): Observable<Result> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }

    const headers: {[header: string]: string} = header ? {...header} : {};
    if (skipLoader && !(SkipLoaderInterceptorHeader in headers)) {
      headers[SkipLoaderInterceptorHeader] = '';
    }
    return this.http
      .post(`${this.API_HOST_URL}${path}`, JSON.stringify(body), {
        params: queryParams,
        headers: header,
      })
      .pipe(
        map((res: Response) => {
          return res;
        }),
        catchError(this.formatErrors.bind(this)),
      );
  }

  public generatePDF(path: string, body: object = {}, opts: object = {}): Observable<any> {
    return this.http.post(`${this.API_HOST_URL}${path}`, JSON.stringify(body), {...opts}).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError(this.formatErrors.bind(this)),
    );
  }

  public postFiles<Result = any>(
    path: string,
    body: any,
    header?: any,
    params: any = {},
  ): Observable<Result> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }
    return this.http
      .post(`${this.API_HOST_URL}${path}`, body, {headers: header, params: queryParams})
      .pipe(
        map((res: Response) => {
          return res;
        }),
        catchError(this.formatErrors.bind(this)),
      );
  }

  public delete(path: string, params: object = {}): Observable<any> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }
    return this.http.delete(`${this.API_HOST_URL}${path}`, {params: queryParams}).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError(this.formatErrors.bind(this)),
    );
  }

  public buildUrlWithParameters(baseUrl: string, params: (string | number)[]): string {
    if (params.length === 0) {
      return baseUrl;
    } else {
      return baseUrl + '/' + params.map(p => encodeURIComponent(p)).join('/');
    }
  }

  public patch<Result = any>(path: string, body?: any, params: any = {}): Observable<Result> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }
    return this.http.patch(`${this.API_HOST_URL}${path}`, body, {params: queryParams}).pipe(
      map((res: Response) => {
        return res;
      }),
      catchError(this.formatErrors.bind(this)),
    );
  }
}
