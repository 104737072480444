/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  Injector,
  ComponentFactoryResolver,
  ComponentFactory,
  ChangeDetectorRef,
} from '@angular/core';

import {Boolean, Decimal, DecimalUtils} from '@atlas/businesstypes';
import {MOCK_PREMIUM_PRICE, MOCK_COLUMN_DATA} from './__tests__/Instalment-mock-data';
import moment from 'moment';
import {
  Amount,
  Columns,
  DetailedData,
  InstalmentFilterSlideInFormData,
  RowData,
  installment,
  Header,
  InstalmentData,
} from '../../../contracts/instalment-payment/instalment';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {PaymentsSessionService} from '../../../payments-core/services/payments-session.service';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {ModalResolution} from '@maia/modals';
import {SlideInController} from '@maia/slide-ins';
import {InstalmentFilterSlideinComponent} from './instalment-filter-slidein/instalment-filter-slidein.component';
import {Tag} from '@maia/tags';
import {PRODUCT_CODE} from '../../../constants/constant';

@Component({
  selector: 'nje-b2b-instalment',
  templateUrl: './instalment.component.html',
  styleUrls: ['./instalment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class InstalmentComponent extends ScreenStepComponent implements OnInit {
  public tableRows: number[] = [];
  public tableColumns: Columns[] = [];
  public totalInstalmentAmount: Amount = MOCK_PREMIUM_PRICE;
  public rowsSelected: RowData[][] = [];
  public productCode = PRODUCT_CODE;
  public instalmentsData: DetailedData[] | undefined = [];
  public totalValueCummulative: Decimal = DecimalUtils.ZERO;
  public currency: string = 'BGN';
  public selectedInstalData: RowData[] = [];
  public headerSelected: Header[] = [];
  public isSelectAllDue: boolean = false;
  public itsFor: string;
  public title = 'Total amount of payable';
  public overallDueCount: number = 0;
  public regularTagList: Tag[] = [];

  private readonly _instalmentFilterSlideInFactory: ComponentFactory<InstalmentFilterSlideinComponent>;

  @Output() public callNavigationFunction = new EventEmitter();

  public constructor(
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _paymentSessionService: PaymentsSessionService,
    private readonly _slideInCtrl: SlideInController,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    route: ActivatedRoute,
  ) {
    super(route);
    this._instalmentFilterSlideInFactory = this._componentFactoryResolver.resolveComponentFactory(
      InstalmentFilterSlideinComponent,
    );
  }

  public ngOnInit(): void {
    this.tableColumns = MOCK_COLUMN_DATA;
    // get policy instalment data
    const data = this._paymentSessionService.getData();
    if (data !== null && data.searchPolicyStep) {
      this.instalmentsData = data.searchPolicyStep.searchPolicyOutput?.data;
    }

    this.instalmentsData?.forEach((data: InstalmentData, headerIndex: number) => {
      this.headerSelected.push({tableIndexId: headerIndex, headerSelected: false});
      this.rowsSelected[headerIndex] = [];
      data.instalmentList.forEach((instal: installment, instalIndex: number) => {
        if (instal.status) {
          this.rowsSelected[headerIndex].push({
            tableIndexId: headerIndex,
            rowIndexId: instalIndex,
            rowSelected: false,
            dueStatus: instal.status.toLowerCase(),
            policyData: data,
            selectedRowData: instal,
            enableStatus: instalIndex !== 0,
          });
        }
      });
    });
    this.getDueCount();
  }

  // get total due counts
  public getDueCount(): void {
    this.instalmentsData?.forEach((data: InstalmentData, policyIndex: number) => {
      data.instalmentList.forEach((row: installment, index: number) => {
        if (row.status?.toLowerCase() === 'due') {
          this.overallDueCount = this.overallDueCount + 1;
        }
      });
    });
  }

  public checkDisableStatus(policyIndex: number, rowIndex: number): boolean {
    let enable: boolean = this.rowsSelected[policyIndex][rowIndex].enableStatus as boolean;

    const instalmentNumber =
      this.rowsSelected[policyIndex][rowIndex].selectedRowData.instalmentNumber;
    if (instalmentNumber == 2) {
      enable = true;
      this.rowsSelected[policyIndex][rowIndex].enableStatus = enable;
    }
    return enable;
  }

  public getCellValue(row: any, column: any, rowIndex: number): string {
    const count = rowIndex + 1;
    let cellValue = row[column.headerValue] + '';

    cellValue =
      column.headerValue === 'instalment' && cellValue === '' ? 'Instalment ' + count : cellValue;
    cellValue = column.headerValue === 'status' ? cellValue : cellValue;

    if (column.headerValue === 'duedate') {
      const momentVariable = moment(cellValue, 'YYYY-MM-DD');
      cellValue = momentVariable.format('DD/MM/YYYY');
    }
    cellValue = column.headerValue === 'currency' && cellValue === 'BGL' ? 'BGN' : cellValue;

    return cellValue;
  }

  public getSelectedRow(isRowSelelcted: any): Boolean | boolean {
    return isRowSelelcted instanceof Boolean && isRowSelelcted.hasOwnProperty('internalValue')
      ? // eslint-disable-next-line
        isRowSelelcted['internalValue']
      : isRowSelelcted;
  }

  public selectRow(tableIndexId: number, rowIndexId: number): void {
    if (this.rowsSelected[tableIndexId][rowIndexId].enableStatus === false) {
      const isRowSelected = this.rowsSelected[tableIndexId][rowIndexId].rowSelected;
      const dueStatus = this.rowsSelected[tableIndexId][rowIndexId].dueStatus;

      // remove exsisting
      const deseletInstalDataPos = this.selectedInstalData.findIndex(
        (sData: RowData) => sData.tableIndexId === tableIndexId && sData.rowIndexId === rowIndexId,
      );
      const removedData =
        deseletInstalDataPos > -1 ? this.selectedInstalData.splice(deseletInstalDataPos, 1) : null;
      console.log('removedData', removedData, deseletInstalDataPos);

      // push into the array and checkbox validate
      if (isRowSelected instanceof Boolean && isRowSelected['internalValue'] === true) {
        this.selectedInstalData.push(this.rowsSelected[tableIndexId][rowIndexId]);

        if (rowIndexId + 1 < this.rowsSelected[tableIndexId].length) {
          this.rowsSelected[tableIndexId][rowIndexId + 1].enableStatus = false;
        }
      } else if (
        isRowSelected instanceof Boolean &&
        isRowSelected['internalValue'] === false &&
        (dueStatus === 'due' || dueStatus === 'upcoming')
      ) {
        this.isSelectAllDue = false;
        this.headerSelected[tableIndexId].headerSelected = isRowSelected['internalValue'];
        const sliceIndex = rowIndexId + 1;
        this.rowsSelected[tableIndexId]
          .slice(sliceIndex, this.rowsSelected[tableIndexId].length)
          .forEach((row: RowData, index: number) => {
            const modifyIndex = index + sliceIndex;
            this.rowsSelected[tableIndexId][modifyIndex].rowSelected = isRowSelected;
            this.rowsSelected[tableIndexId][modifyIndex].enableStatus = true;

            const deselect = this.selectedInstalData.findIndex(
              (sData: RowData) =>
                sData.tableIndexId === tableIndexId && sData.rowIndexId === modifyIndex,
            );
            const rData = deselect > -1 ? this.selectedInstalData.splice(deselect, 1) : null;
            console.log('remove', rData, deselect);
          });
      }
      this.calculateTotalCumulative();
    }
  }

  // select all due
  public selectAllDue(selectAllDue: boolean): void {
    // update status
    this.instalmentsData?.forEach((data: InstalmentData, policyIndex: number) => {
      data.instalmentList.forEach((row: installment, index: number) => {
        const selectedRow = row.status?.toLowerCase() === 'due' ? row : null;
        this.rowsSelected[policyIndex][index].rowSelected =
          selectedRow != null
            ? this.isSelectAllDue
            : this.rowsSelected[policyIndex][index].rowSelected;

        this.rowsSelected[policyIndex][index].enableStatus =
          index === 0 || (selectAllDue === true && row.status?.toLowerCase() === 'due')
            ? false
            : true;
      });
    });

    // remove existing
    let findOneDueInstalmentCountPerPolicy = -1;
    this.instalmentsData?.forEach((data: InstalmentData, policyIndex: number) => {
      findOneDueInstalmentCountPerPolicy = -1;
      this.rowsSelected[policyIndex].forEach((row: RowData, index: number) => {
        const deseletInstalDataPos = this.selectedInstalData.findIndex(
          (sData: RowData) =>
            sData.tableIndexId === row.tableIndexId &&
            sData.rowIndexId === row.rowIndexId &&
            sData.dueStatus.toLowerCase() === 'due',
        );
        deseletInstalDataPos > -1 ? this.selectedInstalData.splice(deseletInstalDataPos, 1) : null;

        if (findOneDueInstalmentCountPerPolicy > -1 && row.dueStatus.toLowerCase() !== 'due') {
          const deseletOtherthanDue = this.selectedInstalData.findIndex(
            (sData: RowData) =>
              sData.tableIndexId === row.tableIndexId && sData.rowIndexId === row.rowIndexId,
          );
          deseletOtherthanDue > -1 ? this.selectedInstalData.splice(deseletOtherthanDue, 1) : null;

          this.rowsSelected[policyIndex][index].rowSelected =
            selectAllDue === true ? !selectAllDue : selectAllDue;
          this.rowsSelected[policyIndex][index].enableStatus = true;
        }
        if (deseletInstalDataPos > -1) {
          findOneDueInstalmentCountPerPolicy = deseletInstalDataPos;
        }
      });
    });

    // push into the array and checkbox validate
    if (selectAllDue && selectAllDue === true) {
      this.instalmentsData?.forEach((data: InstalmentData, policyIndex: number) => {
        this.rowsSelected[policyIndex].forEach((row: RowData, index: number) => {
          if (row.dueStatus.toLowerCase() === 'due') {
            this.selectedInstalData.push(row);
          }
        });
      });
    }

    this.calculateTotalCumulative();
  }

  public calculateTotalCumulative(): void {
    this.totalValueCummulative = DecimalUtils.ZERO;
    let totalPayment: number = 0;
    this.selectedInstalData.forEach((element: any) => {
      totalPayment = totalPayment + element.selectedRowData.amount;
    });

    this.totalValueCummulative = new Decimal(totalPayment.toString());
    this.totalInstalmentAmount.value = this.totalValueCummulative;
    this.totalInstalmentAmount.currency = 'BGN';
  }

  public selectAll(policyIndex: number): void {
    let dueCount = 0;
    const selectedHeader = this.headerSelected[policyIndex].headerSelected;
    // update status

    this.rowsSelected[policyIndex].forEach((row: RowData, index: number) => {
      const selectedRow = row.tableIndexId === policyIndex ? row : null;
      this.rowsSelected[policyIndex][index].rowSelected =
        selectedRow != null ? selectedHeader : row.rowSelected;
      this.rowsSelected[policyIndex][index].enableStatus =
        index === 0 || selectedHeader instanceof Boolean === true ? false : true;
      row.dueStatus === 'due' ? dueCount++ : dueCount;
    });
    // remove exsisting
    this.rowsSelected[policyIndex].forEach((row: RowData, index: number) => {
      const deseletInstalDataPos = this.selectedInstalData.findIndex(
        (sData: RowData) => sData.tableIndexId === policyIndex,
      );
      deseletInstalDataPos > -1 ? this.selectedInstalData.splice(deseletInstalDataPos, 1) : null;
    });

    // push into the array and checkbox validate
    if (selectedHeader instanceof Boolean) {
      if (selectedHeader['internalValue'] === true) {
        this.rowsSelected[policyIndex].forEach((row: RowData, index: number) => {
          this.selectedInstalData.push(row);
        });
        // eslint-disable-next-line
      } else if (selectedHeader['internalValue'] === false && dueCount > 0) {
        this.isSelectAllDue = false;
      }
    }
    this.calculateTotalCumulative();
  }

  public applyFilter(): void {
    this._slideInCtrl
      .prepare(
        this._instalmentFilterSlideInFactory,
        this._injector,
        {title: 'Filter'},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe(response => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          console.log('filter response', response.result);
          this._handleTags(response.result);
        }
        this._cdRef.detectChanges();
      });
  }

  private _handleTags(input: InstalmentFilterSlideInFormData): void {
    if (input.insuranceType !== undefined) {
      const insuranceTypeTagValue = input.insuranceType.id;
      const insuranceTypeTag = new Tag({
        value: insuranceTypeTagValue,
        displayedValue: input.insuranceType.name,
        close: () => {
          const index: number = this.regularTagList.indexOf(insuranceTypeTag);
          this.regularTagList.splice(index, 1);
        },
      });
      this.regularTagList.push(insuranceTypeTag);
    }
    if (input.policyIssuedTo !== undefined && input.policyIssuedFrom !== undefined) {
      const policyIssueFromAndToDateTag = new Tag({
        displayedValue: input.policyIssuedFrom + ' ' + input.policyIssuedTo,
        value: input.policyIssuedFrom + input.policyIssuedTo,
        close: () => {
          const index: number = this.regularTagList.indexOf(policyIssueFromAndToDateTag);
          this.regularTagList.splice(index, 1);
        },
      });
      this.regularTagList.push(policyIssueFromAndToDateTag);
    }
  }

  public handleClearAll(): void {
    this.regularTagList = [];
  }

  public saveMtplData(): void {
    const instalmentStepData = {
      totalCumlativeAmt: this.totalValueCummulative,
      isAllDue: this.isSelectAllDue,
      selectedDueList: this.selectedInstalData,
    };
    this._paymentSessionService.setInstalmentsScreenData(instalmentStepData);
  }
}
