<form maiaForm [formGroup]="LibrarySlideinForm">
  <maia-form-group>
    <maia-label>
      <label
        class="b2b-kdl-font-small title-dark-color"
        i18n="@@nje-b2b-frontend-resource-library-list-libtitle"
      >
        Library Title
      </label>
    </maia-label>
    <maia-form-element
      [options]="{validationInstant: true}"
      class="slidein-input-column"
      hideOptionalIndicator
    >
      <maia-text-area-limit [maxLength]="titleLimit">
        <textarea
          name="title"
          id="title"
          maiaTextArea
          [atlasMaxLength]="titleLimit"
          name="title"
          i18n-placeholder="@@nje-b2b-frontend-resource-library-entertitle"
          placeholder="Enter library title"
          formControlName="title"
        >
        </textarea>
        <div
          *ngIf="
            LibrarySlideinForm.controls['title'].invalid &&
            (LibrarySlideinForm.controls['title'].dirty ||
              LibrarySlideinForm.controls['title'].touched)
          "
        >
          <maia-validation
            i18n="@@nje-b2b-frontend-resource-library-product-titlereq"
            *ngIf="LibrarySlideinForm.controls['title'].hasError('required')"
            >Title required
          </maia-validation>
          <maia-validation *ngIf="LibrarySlideinForm.controls['title'].hasError('duplicate')">
            Library Title already exists
          </maia-validation>
        </div>
      </maia-text-area-limit>
    </maia-form-element>
    <maia-label>
      <label
        class="b2b-kdl-font-small title-dark-color"
        i18n="@@nje-b2b-frontend-resource-library-slidein-libdesc"
      >
        Library Description
      </label>
    </maia-label>
    <maia-form-element
      [options]="{validationInstant: true}"
      class="slidein-input-column"
      hideOptionalIndicator
    >
      <maia-text-area-limit [maxLength]="descriptionLimit">
        <textarea
          id="description"
          maiaTextArea
          [atlasMaxLength]="descriptionLimit"
          name="description"
          class="b2b-kdl-font-small"
          i18n-placeholder="@@nje-b2b-frontend-resource-library-enterdesc"
          placeholder="Enter library description"
          formControlName="description"
        >
        </textarea>
        <div
          *ngIf="
            LibrarySlideinForm.controls['description'].invalid &&
            (LibrarySlideinForm.controls['description'].dirty ||
              LibrarySlideinForm.controls['description'].touched)
          "
        >
          <maia-validation
            i18n="@@nje-b2b-frontend-resource-library-product-descreq"
            *ngIf="
              LibrarySlideinForm.controls['description'].touched &&
              LibrarySlideinForm.controls['description'].hasError('required')
            "
          >
            Description required
          </maia-validation>
        </div>
      </maia-text-area-limit>
    </maia-form-element>
  </maia-form-group>
</form>
<div class="step-form-button-bar">
  <maia-button-group *ngIf="isNewLibraryList()">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!LibrarySlideinForm.valid"
      (click)="addOrUpdateLibraryList()"
      i18n="@@nje-b2b-frontend-resource-admin-update"
      >Update</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-product-cancel"
      >Cancel</button
    >
  </maia-button-group>

  <maia-button-group *ngIf="!isNewLibraryList()">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!LibrarySlideinForm.valid"
      (click)="addOrUpdateLibraryList()"
      i18n="@@nje-b2b-frontend-resource-library-product-add"
      >Add</button
    >
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="secondary"
      (click)="cancel()"
      i18n="@@nje-b2b-frontend-resource-library-product-cancel"
      >Cancel</button
    >
  </maia-button-group>
</div>
