export const INSURING_DETAILS = {
  ROW: 'row',
  COLUMN: 'column',
  DELETE_DATA: 'Delete the Data?',
  TEMPORARY_DATA: 'Lorum ipsum',
  ADD_NEW_DEATILS: 'Add New Related Party',
  EDIT_DETAILS: 'Edit Related Party',
  CITY_NAME: 'Sofia',
  LESSEE: 'Lessee',
};

export const BLANK_VALUE = '';
export const CITY = 'гр.София';
export const COUNTRY_CODE = '+359';

export const MTPL_PROPOSAL = {
  POLICY_INSTALMENT: 'Instalments',
  ADDITIONAL_COVERS: 'Addditional Covers',
  POLICY_DETAILS: 'Policy Details',
  VEHICLE_DETAILS: 'Vehicle Details',
  OWNER_INSURER_DETAILS: 'Owner and Insurer Details',
};
