import {ModalResolution} from '@maia/modals';
import {Product} from '../../../../../contracts/library.interface';

export const MOCK_PRODUCT_LIST: Product[] = [
  {
    id: 1,
    productName: 'Product 1',
    productSubtitle:
      'DZI develops and offers full range insurance products tailoredito Insurance products tailoredito',
    selectedStatus: false,
  },
  {
    id: 2,
    productName: 'Product 2',
    productSubtitle: 'lorem ipsum ko they hdghsj jhww always be there',
    selectedStatus: false,
  },
  {
    id: 3,
    productName: 'Product 3',
    productSubtitle: 'lorem ipsum ko they',
    selectedStatus: false,
  },
];

export const MOCK_MODAL_RESULT = {
  resolution: ModalResolution.DISMISSED,
};

export const MOCK_PRODUCT_LIST_DELETE: Product[] = [
  {
    id: 1,
    productName: 'Product 1',
    productSubtitle:
      'DZI develops and offers full range insurance products tailoredito Insurance products tailoredito',
    selectedStatus: false,
  },
  {
    id: 2,
    productName: 'Product 2',
    productSubtitle: 'lorem ipsum ko they hdghsj jhww always be there',
    selectedStatus: false,
  },
];

export const MOCK_BLANK_VALUE = '';
