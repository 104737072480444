import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';

import {AuthComponent} from './auth.component';
import {RoutePaths} from './constants/route-paths';
import {ChangePasswordComponent} from './screens/change-password/change-password.component';
import {LoginComponent} from './screens/login/login.component';
import {SetupGoogleComponent} from './screens/setup-google/setup-google.component';
import {SuccessScreenComponent} from './screens/success-screen/success-screen.component';
import {TwoFaComponent} from './screens/two-fa/two-fa.component';
import {VerifyAuthComponent} from './screens/verify-auth/verify-auth.component';
import {ScanComponent} from './screens/scan/scan.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {path: RoutePaths.Login, component: LoginComponent},
      {path: RoutePaths.Success, component: SuccessScreenComponent},
      {path: RoutePaths.ChangePassword, component: ChangePasswordComponent},
      {path: RoutePaths.TwoFa, component: TwoFaComponent},
      {path: RoutePaths.SetupGoogle, component: SetupGoogleComponent},
      {path: RoutePaths.VerifyAuth, component: VerifyAuthComponent},
      {path: RoutePaths.VerifyScan, component: ScanComponent},
      {path: '', redirectTo: RoutePaths.Login, pathMatch: 'full'},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}

/**
 * The route we export is anempty path with the private module lazy loaded.
 */
export const AUTH_ROUTE: Route = {
  path: '',
  loadChildren: async () =>
    await import('./auth-routing.module').then(module => module.AuthRoutingModule),
};
