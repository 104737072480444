<div *ngIf="showEmailContainer; else successMessageContainer">
  <ng-container class="addingContainer">
    <form maiaForm [formGroup]="mailForm" class="form">
      <div class="container">
        <div></div>
        <label
          class="addingLabel kdl-font-normal"
          i18n="@@nje-b2b-frontend-casco-quick-proposalpopup-label-send"
          >Send to email</label
        >
        <button
          maiaButton
          maiaButtonType="tertiary"
          class="addEmailButton"
          (click)="addEmails()"
          i18n="@@nje-b2b-frontend-casco-quick-proposalpopup-btn-add"
          >Add email</button
        >
      </div>

      <maia-form-element [options]="{validationInstant: true}">
        <input
          type="email"
          name="email"
          maiaInput
          atlasEmailAddress
          formControlName="email"
          placeholder="yoursmail@gmail.com"
          class="addingInput"
        />

        <maia-validation
          type="error"
          alignRight
          *ngIf="mailForm.controls['email']?.invalid && mailForm.controls['email']?.dirty"
        >
          Enter your e-mail address in the following format: name@domain.com
        </maia-validation>
        <maia-validation
          type="error"
          alignRight
          *ngIf="mailForm.controls['email'].hasError('atlasMaxLength')"
        >
          Email is too Long!
        </maia-validation>
        <maia-validation
          alignRight
          type="error"
          *ngIf="
            mailForm.controls['email'].hasError('required') && mailForm.controls['email']?.touched
          "
        >
          Email is Required
        </maia-validation>
      </maia-form-element>

      <div formArrayName="emailsFormArray" class="adding-first-div-container m-b-16">
        <div
          *ngFor="let multipleEmailInputs of emailsFormArray.controls; let i = index"
          [formGroupName]="i"
          class="adding-second-div-container"
        >
          <maia-form-element class="adding-multiple-email-input">
            <input
              maiaInput
              type="email"
              atlasEmailAddress
              placeholder="user@gmail.com"
              formControlName="mails"
              class="addInput"
            />

            <maia-validation
              type="error"
              *ngIf="
                multipleEmailInputs.controls['mails']?.hasError('required') &&
                multipleEmailInputs.controls['mails']?.touched
              "
            >
              Email is Required
            </maia-validation>
            <maia-validation
              type="error"
              *ngIf="
                multipleEmailInputs.get('mails')?.invalid && multipleEmailInputs.get('mails')?.dirty
              "
            >
              Enter your e-mail address in the following format: name@domain.com
            </maia-validation>
            <maia-validation
              type="error"
              *ngIf="multipleEmailInputs.get('mails').hasError('atlasMaxLength')"
            >
              Email is too long!</maia-validation
            >
          </maia-form-element>
          <svg
            b2bFrontendNavigationFuncCross
            class="adding-cross-icon"
            (click)="removeEmail(i)"
          ></svg>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *maiaPopUpFooter>
    <button
      maiaButton
      maiaButtonType="tertiary"
      (click)="control.cancel()"
      i18n="@@nje-b2b-frontend-casco-quick-proposalpopup-btn-cancel"
      >Cancel</button
    >

    <button
      maiaButton
      maiaButtonType="primary"
      (click)="openSuccessContainer()"
      [disabled]="!mailForm.valid"
      i18n="@@nje-b2b-frontend-casco-quick-proposalpopup-btn-send"
      >Send</button
    >
  </ng-container>
</div>

<ng-template #successMessageContainer>
  <ng-container class="addingSuccessMessageContainer">
    <div class="success-container">
      <svg class="addingIcon" b2bFrontendAlertFuncCheckCircle></svg>
    </div>
    <div class="success-container">
      <label
        class="addingSuccessMessage kdl-font-normal"
        i18n="@@nje-b2b-frontend-casco-quick-proposalpopup-label-emailsent"
        >Email Sent Successfully</label
      >
    </div>
  </ng-container>
  <ng-container *maiaPopUpFooter>
    <button
      maiaButton
      maiaButtonType="primary"
      (click)="control.cancel()"
      i18n="@@nje-b2b-frontend-casco-quick-proposalpopup-btn-close"
      >Close</button
    >
  </ng-container>
</ng-template>
