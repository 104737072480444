/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable  @typescript-eslint/prefer-readonly */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-empty-function */
import {Component, ChangeDetectionStrategy, Injectable, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {MtplApiService} from '../../mtpl-core/services/mtpl-api.service';
import {takeUntil} from 'rxjs/operators';
import {ModalConfirmedResult} from '@maia/modals';
import {
  FindDeviceService,
  NameAddressTranslationMapService,
  TalonScanUploadService,
  TalonSlideinService,
  VehicleTalonData,
} from '@b2b-frontend/core';
import {MtplVehicleOwnerDetails} from '../../models/MtplVehicleOwnerData';
import {MtplProcessService} from '../../mtpl-core/services/mtpl-process.service';
import {MtplB2BWorkFlow} from '../../mtpl-core/workflow/MtplB2BWorkFlow';
import {SharedService} from '../../mtpl-core/services/shared.service';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';

function vehicleRegistrationNumberValidator(control: FormControl) {
  if (
    control.value &&
    notAllowedPatterns.findIndex((exp: RegExp) => control.value.match(exp)) >= 0
  ) {
    return {invalidNumber: true};
  }
  return null;
}

function registrationNumberPatternValidator(control: FormControl) {
  if (
    control.value &&
    allowedPatterns.findIndex((exp: RegExp) => control.value.match(exp)) === -1
  ) {
    return {valid: true};
  }
  return null;
}

const notAllowedPatterns: RegExp[] = [
  /^\d{3}\s?[THMthm]\s?\d{3}$/,
  /^\d{3}\s?[Bb]\s?\d{3}$/,
  /^(C|CC|CT|c|cc|ct)\s?\d{4}$/,
  /^(CP|cp)\s?\d{2}\s?\d{3}$/,
  /^(BA|ba)\s?\d{6}$/,
  /^(XX|xx)\s?\d{4}$/,
];

const allowedPatterns: RegExp[] = [
  /^[A-Za-z]{2}\s?\d{4}\s?[A-Za-z]{2}$/,
  /^[A-Za-z]{2}\s?\d{5}$/, //Bulgarian registration
  /^[A-Za-z]{2}\s?\d{5}\s?[A-Za-z]{1}$/, //Bulgarian registration
  /^\d{5}\s?(TRAN|tran)$/, //Transit
];

@Component({
  selector: 'nje-mtpl-home',
  styles: [':host { display: block; }'],
  templateUrl: './mtpl-home.component.html',
  styleUrls: ['./mtpl-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@Injectable()
@UntilDestroy()
export class MTPLHomeComponent extends ScreenStepComponent implements OnInit, OnDestroy {
  form: FormGroup;
  public vehicleTalonData: VehicleTalonData;
  public isMobileDevice: boolean = false;
  public talonGridCell: number = 6;
  public showTalonErroMsg: boolean = false;
  public showAlertScreen: boolean = false;
  public vehicleData: MtplVehicleOwnerDetails;
  public mismatchData: boolean = false;
  public errorMessage: string;

  public constructor(
    private _sharedService: SharedService,
    private _process: MtplProcessService,
    public activatedRoute: ActivatedRoute,
    private readonly _formBuilder: FormBuilder,
    private readonly _mtplApiService: MtplApiService,
    public findDeviceService: FindDeviceService,
    public talonSlideInService: TalonSlideinService,
    private readonly _talonScanUploadService: TalonScanUploadService,
    private readonly _nameAddressTrasMapService: NameAddressTranslationMapService,
  ) {
    super(activatedRoute);

    this.isMobileDevice = this.findDeviceService.checkIsItMobileDevice();

    if (this.isMobileDevice) {
      this._talonScanUploadService.scanResultObserable
        .pipe(takeUntil(this.destroyed$))
        .subscribe((result: any) => {
          if (result && Object.keys(result).length > 0) {
            const talonResultData: any = result.data;
            if (talonResultData) {
              const talonData: VehicleTalonData = {
                regNo: talonResultData.VRN,
                certNo: talonResultData['Document number'],
                pid: talonResultData['PIN\\UIC'],
                talonImageId: '0',
              };
              this.patchTalonData(talonData);
            } else {
              this.showTalonErroMsg = true;
            }
          }
        });
    }
  }

  public openTalonSlideIn(): void {
    this.talonSlideInService
      .openTalonSlidIn()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: ModalConfirmedResult<VehicleTalonData>) => {
          const talonData: VehicleTalonData = res.result;
          this.patchTalonData(talonData);
        },
        (error: any) => {
          console.log('Vehicle Talon Scanning', error);
        },
      );
  }

  public patchTalonData(talonData: VehicleTalonData): void {
    if (talonData) {
      this.vehicleTalonData = Object.assign({}, talonData);
      this.form.patchValue({
        vehicleRegistrationNumber: talonData.regNo,
        certificateNumber: talonData.certNo,
      });
    }
  }

  protected saveMtplData(): void {}

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ngOnInit(): void {
    this.showAlertScreen = false;
    // this._sharedService;
    this.form = this._formBuilder.group({
      vehicleRegistrationNumber: [
        '',
        [
          Validators.required,
          vehicleRegistrationNumberValidator,
          registrationNumberPatternValidator,
        ],
      ],
      vehicleRegistrationNumberEn: [undefined],
      certificateNumber: ['', Validators.required],
    });
    this._nameAddressTrasMapService.updateVehicleRegisterationNumber(this.form);
    this.regNumberTransliteration();
  }

  public regNumberTransliteration(): void {
    this.form
      .get('vehicleRegistrationNumber')
      ?.valueChanges.pipe(takeUntilDestroyed(this))
      .subscribe((value: string) => {
        const data: string = this.form.get('vehicleRegistrationNumberEn')?.value.toUpperCase();
        this.form.patchValue(
          {
            vehicleRegistrationNumber: data,
          },
          {emitEvent: false},
        );
      });
  }

  public isFormValid(): boolean {
    if (
      this.form.get('vehicleRegistrationNumber')?.value &&
      this.form.get('certificateNumber')?.value
    )
      return true;
    return false;
  }

  public navigateToVehicle(): void {
    this._process.currentWorkflow = MtplB2BWorkFlow.CALCULATOR;
    this.saveAndContinue();
  }

  public onContinue(): void {
    this._mtplApiService
      .getVehicleDetails(
        this.form.get('vehicleRegistrationNumber')?.value,
        this.form.get('certificateNumber')?.value,
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (data: MtplVehicleOwnerDetails) => {
          this.vehicleData = data;
          this._sharedService.vehicleOwnerDetails = data;
          this.mismatchData = false;
          if (data.vehicle.dataSource == 1 && data.vehicle.totalWeight === undefined) {
            if (data.vehicle.totalWeight === null) {
              this._process.currentWorkflow = MtplB2BWorkFlow.CALCULATOR;
              this.saveAndContinue();
            } else {
              this._process.currentWorkflow = MtplB2BWorkFlow.MTPL_POLICY;
              this.saveAndContinue();
            }
          } else if (this.vehicleData.vehicle.dataSource == 2) {
            this._process.currentWorkflow = MtplB2BWorkFlow.CALCULATOR;
            this.saveAndContinue();
          } else {
            this._process.currentWorkflow = MtplB2BWorkFlow.CALCULATOR;
            this.saveAndContinue();
          }
        },
        error => {
          this.mismatchData = true;
          this.errorMessage = error;
        },
      );
    this._sharedService.registrationNumber = this.form.get('vehicleRegistrationNumber')?.value;
    this._sharedService.certificationNumber = this.form.get('certificateNumber')?.value;
  }
}
