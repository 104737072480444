/* eslint-disable @typescript-eslint/no-empty-function */
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {LabelEditDto} from '@b2b-frontend/core';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {PAYMENTS_OVERVIEW} from '../../../constants/constant';
import {RoutePaths} from '../../../constants/route-paths';
import {PaymentsProcessService} from '../../../payments-core/services/payments-process.service';
import {
  PAYMENTS_OVERVIEW_TABLE_HEADERS,
  PAYMENTS_OVERVIEW_TABLE_LIST,
} from './__tests__/overview.mock';

@Component({
  selector: 'nje-b2b-instalment-payment-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewComponent extends ScreenStepComponent {
  public insuredPartyPath: string = `/dashboard/payments/${RoutePaths.InstalmentPayment}/${RoutePaths.InsuredParty}`;

  public insuredPartyEditPath: LabelEditDto = {
    id: 1,
    name: PAYMENTS_OVERVIEW.INSURED_PARTY,
    navigationPath: this.insuredPartyPath,
  };

  public columnHeaders: string[] = PAYMENTS_OVERVIEW_TABLE_HEADERS;
  public overviewTableList = PAYMENTS_OVERVIEW_TABLE_LIST.data;

  public constructor(
    public activatedRoute: ActivatedRoute,
    public process: PaymentsProcessService,
  ) {
    super(activatedRoute);
  }

  protected saveMtplData(): void {}

  public editScreen(screenDetail: LabelEditDto): void {
    const step = this.process.getStepByRoute(screenDetail.navigationPath);
    if (step) {
      step.edit(this.step);
    }
  }
}
