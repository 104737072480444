<form maiaForm [formGroup]="stepForm">
  <div class="blue-box b2b-kdl-font-small m-b-24" *ngIf="step.inEdit | async">
    Changing values will result in premium recalulation
  </div>
  <maia-label>
    <label class="b2b-kdl-font-small title-dark-color"> Insuring Party </label>
  </maia-label>
  <maia-fluid-fluid-layout alignContentTop
    ><div class="m-r-16">
      <maia-form-element
        [hideOptionalIndicator]="true"
        class="input-column"
        label="EGN / LNCH / EIK"
        [options]="{validationInstant: true}"
      >
        <input
          maiaInput
          name="EGN / LNCH / EIK"
          formControlName="idNumber"
          placeholder="EGN / LNCH / EIK"
        /> </maia-form-element></div
    ><div></div
  ></maia-fluid-fluid-layout>

  <egn-lnch-layout
    *ngIf="!isEIK"
    [insuringPartyType]="insuringPartyType"
    (saveEgnData)="saveEgnData($event)"
    [insuringPartyDto]="insuringPartyDto"
    [userData]="userData"
  ></egn-lnch-layout>
  <eik-layout
    *ngIf="isEIK"
    [insuringPartyType]="insuringPartyType"
    [insuringPartyDto]="insuringPartyDto"
    (saveEikData)="saveEikData($event)"
    [companyData]="companyData"
  ></eik-layout>
</form>
