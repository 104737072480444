import {Pipe, PipeTransform} from '@angular/core';
import {LocalizationService} from '../services/localization.service';

@Pipe({name: 'localeVal'})
export class localeValPipe implements PipeTransform {
  public constructor(private readonly localizationService: LocalizationService) {}

  public transform(value: string, lang: string | null): string {
    if (this.localizationService.localeConfigs[value]) {
      if (lang == 'bg') {
        return this.localizationService.localeConfigs[value].bg;
      }
      return this.localizationService.localeConfigs[value].en;
    }
    return value;
  }
}
