import {Injectable} from '@angular/core';
import {StatisticsReportingSlideInFormFilter} from '../contracts/statistics-reporting.interface';

@Injectable({
  providedIn: 'root',
})
export class StatisticsReportingSessionService {
  public data: StatisticsReportingSlideInFormFilter;
  public readonly sessionKey: string = 'statisticReporting';

  public setFilterData(input: any): void {
    this.data = input as StatisticsReportingSlideInFormFilter;
    this.updateGlobalStorage();
  }

  public updateGlobalStorage(): void {
    sessionStorage.setItem('statisticReporting', JSON.stringify(this.data));
  }

  public getData(): StatisticsReportingSlideInFormFilter {
    const jsonData = sessionStorage.getItem(this.sessionKey);
    if (jsonData !== null) {
      this.data = JSON.parse(jsonData) as StatisticsReportingSlideInFormFilter;
      return this.data;
    } else {
      this.data = {
        policyActivityFilter: undefined,
        userActivityFilter: undefined,
        offerActivityFilter: undefined,
        claimActivityFilter: undefined,
        paymentActivityFilter: undefined,
      } as StatisticsReportingSlideInFormFilter;
      this.updateGlobalStorage();
      return this.data;
    }
  }

  public clearFilterData(): void {
    sessionStorage.removeItem(this.sessionKey);
  }
}
