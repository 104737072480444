import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'annexation',
  templateUrl: './annexation.component.html',
  styleUrls: ['./annexation.component.css'],
})
export class AnnexationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
