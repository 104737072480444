import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.css'],
})
export class CancellationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
