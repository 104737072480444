<div class="header-info">
  <div class="head-auto">
    <a
      maiaDocumentAction
      rel="noopener noreferrer"
      [href]="readMore"
      target="_blank"
      i18n="@@nje-b2b-frontend-casco-priceoffer-premium-hyperlink-readmore"
      >Read more</a
    >
  </div>
</div>

<form maiaForm [formGroup]="premiumForm.formGroup">
  <div class="installment-div">
    <maia-label
      i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-instlmnt"
      class="m-b-16 label-font"
      >Installment Options</maia-label
    >

    <maia-radio-group
      [ngClass]="{'njf-radio-size-autoprocess': authorization}"
      name="instlmntOpt"
      formControlName="instlmntOpt"
    >
      <maia-fluid-fluid-layout>
        <njf-radio-button class="njf-radio-btn m-r-16" value="Single Payment">
          <maia-label
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-radiobtn-snglPy"
            class="njf-radio-label"
            >Single Payment</maia-label
          ></njf-radio-button
        >
        <njf-radio-button class="njf-radio-btn m-r-16" value="Double Payment">
          <maia-label
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-radiobtn-dblPy"
            class="njf-radio-label"
            >Double Payment</maia-label
          ></njf-radio-button
        >
        <njf-radio-button class="njf-radio-btn m-r-16" value="Four Payment">
          <maia-label
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-radiobtn-fourPy"
            class="njf-radio-label"
            >Four Payment</maia-label
          ></njf-radio-button
        >

        <njf-radio-button class="njf-radio-btn" *ngIf="authorization" value="Twelve Payment">
          <maia-label
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-radiobtn-twlPy"
            class="njf-radio-label"
            >Twelve Payment</maia-label
          ></njf-radio-button
        >
        <div class="m-l-16" *ngIf="!authorization"></div>
      </maia-fluid-fluid-layout>
    </maia-radio-group>
  </div>
  <maia-label
    i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-head-premium"
    class="label-font"
  >
    Premium
  </maia-label>

  <maia-radio-group [(ngModel)]="premium" [ngModelOptions]="{standalone: true}">
    <maia-fluid-fluid-layout>
      <maia-tile
        class="m-t-b-24 radio-tile-unselected m-r-16"
        [ngClass]="{
          'radio-tile-selected': premium === 1,
          'radio-tile-selected-unselected': premium != 1 && premium != 0,
          'radio-tile-opt3-unselected': premium === 3
        }"
      >
        <maia-radio-button
          class="radio-btn-margin"
          [ngClass]="{
            'radio-btn-margin-on': premium != 1 && premium != 0,
            'radio-btn-selected': premium === 1
          }"
          [value]="1"
          ><div class="column-warp"
            ><maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-radio-premium-ofclGarg"
              class="radio-label label-font"
              >With Official Garage</maia-label
            >
            <maia-amount-display
              *ngIf="premium === 0 || premium === 1"
              [value]="getAmountValue('200.00')"
              size="large-bold"
              currency="BGN"
            ></maia-amount-display></div
        ></maia-radio-button>
      </maia-tile>

      <maia-tile
        class="m-t-b-24 radio-tile-unselected m-r-16"
        [ngClass]="{
          'radio-tile-selected': premium === 2,
          'radio-tile-selected-unselected': premium != 2 && premium != 0,
          'radio-tile-opt3-unselected': premium === 3
        }"
      >
        <maia-radio-button
          class="radio-btn-margin"
          [ngClass]="{
            'radio-btn-margin-on': premium != 2 && premium != 0,
            'radio-btn-selected': premium === 2
          }"
          [value]="2"
          ><div class="column-warp"
            ><maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-radio-premium-trstGarg"
              class="radio-label label-font"
              >With Trusted Garage</maia-label
            >
            <maia-amount-display
              *ngIf="premium === 0 || premium === 2"
              [value]="getAmountValue('210.00')"
              size="large-bold"
              currency="BGN"
            ></maia-amount-display></div
        ></maia-radio-button>
      </maia-tile>

      <maia-tile
        class="m-t-b-24 radio-tile-unselected m-r-16"
        [ngClass]="{
          'radio-tile-opt3-selected': premium === 3,
          'radio-tile-selected-unselected': premium != 3 && premium != 0
        }"
      >
        <maia-radio-button
          class="radio-btn-margin-1"
          [ngClass]="{
            'radio-btn-margin-on-1': premium != 3 && premium != 0,
            'radio-btn-selected': premium === 3
          }"
          [value]="3"
          ><div class="column-warp"
            ><maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-radio-premium-trstOrig"
              class="radio-label label-font"
              >With Trusted Garage and Original Parts</maia-label
            >
            <maia-amount-display
              *ngIf="premium === 0 || premium === 3"
              [value]="getAmountValue('220.00')"
              size="large-bold"
              currency="BGN"
            ></maia-amount-display></div
        ></maia-radio-button>
      </maia-tile>

      <maia-tile
        class="m-t-b-24 radio-tile-unselected"
        [ngClass]="{
          'radio-tile-selected': premium === 4,
          'radio-tile-selected-unselected': premium != 4 && premium != 0,
          'radio-tile-opt3-unselected': premium === 3
        }"
      >
        <maia-radio-button
          class="radio-btn-margin"
          [ngClass]="{
            'radio-btn-margin-on': premium != 4 && premium != 0,
            'radio-btn-selected': premium === 4
          }"
          [value]="4"
          ><div class="column-warp"
            ><maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-radio-premium-exprt"
              class="radio-label label-font"
              >With Expert Evalution</maia-label
            >
            <maia-amount-display
              *ngIf="premium === 0 || premium === 4"
              [value]="getAmountValue('240.00')"
              size="large-bold"
              currency="BGN"
            ></maia-amount-display></div
        ></maia-radio-button>
      </maia-tile>
    </maia-fluid-fluid-layout>
  </maia-radio-group>
  <maia-divider></maia-divider>

  <div *ngIf="autoLoadViewMore" class="autoload-box-wrapper">
    <maia-fluid-fluid-layout alignContentTop>
      <maia-contextual-message>
        <maia-label
          class="label-font"
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info1-clickHere"
          >Automatic loadings and discounts are applied.
          <button (click)="clickOnHere()" maiaButton maiaButtonType="tertiary">Click here</button>
          to view more.</maia-label
        >
      </maia-contextual-message>
    </maia-fluid-fluid-layout>
  </div>

  <div *ngIf="!autoLoadViewMore" class="autoload-box-wrapper">
    <maia-fluid-fluid-layout alignContentTop>
      <maia-contextual-message class="big-box-content">
        <div class="info-msg-border">
          <maia-label
            class="b2b-kdl-font-normal"
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info2-Auto"
            >Automatic loadings and discounts are applied.</maia-label
          >
          <maia-label
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-head1-loading"
            class="info-msg-head label-head-font-info-box"
            >Loading</maia-label
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-ins3veh"
              class="label-text-font-info-box info-msg-text"
              >Insurance of more than 3 vehicles - 5%</maia-label
            ></div
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-sp10"
              class="label-text-font-info-box info-msg-text"
              >Single Payment - 10%</maia-label
            ></div
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-valid10"
              class="label-text-font-info-box"
              >Valid Insurance fire and other perils of Industrial fire. - 10%</maia-label
            ></div
          >
          <maia-label
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-head2-discounts"
            class="info-msg-head label-head-font-info-box"
            >Discounts</maia-label
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-garg5"
              class="label-text-font-info-box info-msg-text"
              >Official garage - 5%</maia-label
            ></div
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-trustOrig5"
              class="label-text-font-info-box info-msg-text"
              >Trust Service- Original Autoparts - 5%</maia-label
            ></div
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-limit5"
              class="label-text-font-info-box"
              >No limitation for written event declaration for Trusted Service Settlement. -
              5%</maia-label
            ></div
          >
          <maia-label
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-head3-bonusmalus"
            class="info-msg-head label-head-font-info-box"
            >Bonus Malus</maia-label
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-bonustypes"
              class="label-text-font-info-box info-msg-text"
              >Bonus Malus Type - <span class="bonus-font">Bonus Malus Types</span></maia-label
            ></div
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-bonuslevel"
              class="label-text-font-info-box info-msg-text"
              >Bonus Malus Level - <span class="bonus-font">Bonus Malus Level</span></maia-label
            ></div
          >
          <div class="row-warp tick-align"
            ><svg b2bFrontendAlertFuncCheck maia-tile-icon class="info-msg-chk svg-grn-col"></svg>
            <maia-label
              i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-bonuscorrection10"
              class="label-text-font-info-box info-msg-text"
              >Bonus Malus Correction - 10%</maia-label
            ></div
          >
          <button
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-info-button-viewLess"
            (click)="clickOnHere()"
            maiaButton
            maiaButtonType="tertiary"
            >View Less</button
          >
        </div>
      </maia-contextual-message>
    </maia-fluid-fluid-layout>
  </div>

  <maia-fluid-fluid-layout class="m-b-24">
    <maia-form-element
      [hideOptionalIndicator]="true"
      class="multi-input-row m-t-n24 m-r-16"
      i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-select-tariffRate"
      label="Tariff Rate"
    >
      <maia-input-select name="tariffRate" formControlName="tariffRate">
        <input maiaInput placeholder="Select" [value]="premiumForm.controls['tariffRate'].value" />
        <maia-option *ngFor="let mockItem of tariffRateMock" [value]="mockItem.name">{{
          mockItem.name
        }}</maia-option>
      </maia-input-select>
    </maia-form-element>

    <maia-form-element
      *ngIf="!tariffPremium"
      [hideOptionalIndicator]="true"
      class="multi-input-row m-t-n24"
      label="Tariff Rate Value"
    >
      <maia-input-group>
        <input maiaInputNumber name="inputNumber 1" formControlName="tariffRateValue" />
        <maia-input-group-suffix>BGN</maia-input-group-suffix>
      </maia-input-group>
    </maia-form-element>

    <maia-form-element
      *ngIf="tariffPremium"
      [hideOptionalIndicator]="true"
      class="multi-input-row m-t-n24"
      label="Tariff Rate Value"
    >
      <maia-input-group>
        <input maiaInputNumber name="inputNumber 1" formControlName="tariffRateValue" />
        <maia-input-group-suffix>%</maia-input-group-suffix>
      </maia-input-group>
    </maia-form-element>
  </maia-fluid-fluid-layout>
  <maia-divider></maia-divider>
  <div class="m-b-16">
    <maia-label
      i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-head-discounts"
      class="label-font"
    >
      Discounts
    </maia-label>
  </div>
  <maia-checkbox-group>
    <div class="discount-box">
      <div class="m-r-16 m-b-16 column-warp width-32">
        <maia-tile
          class="checkbox-unselected height-44 m-b-8 p-custom"
          [ngClass]="{'checkbox-selected ': mtplPreChk | atlasBoolean}"
        >
          <maia-checkbox
            (onValueChange)="enableExtensionBox(1)"
            [(ngModel)]="mtplPreChk"
            [ngModelOptions]="{standalone: true}"
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check1-mtpl"
            >Valid MTPL insurance</maia-checkbox
          >
        </maia-tile>

        <maia-tile *ngIf="activatePreMTPLPercBox" class="checkbox-extension-tile-wrapper">
          <maia-label i18n="@@nje-b2b-frontend-casco-priceoffer-premium-check1-mtpl-extensionbox1"
            >Type 0-5%</maia-label
          >
          <maia-input-group>
            <input maiaInput class="input-box-perc" />
            <maia-input-group-suffix>%</maia-input-group-suffix>
          </maia-input-group>
        </maia-tile>
      </div>
      <maia-tile
        class="checkbox-unselected width-32 m-r-16 m-b-16 height-44 p-custom"
        [ngClass]="{'checkbox-selected ': insPreChk | atlasBoolean}"
      >
        <maia-checkbox
          [(ngModel)]="insPreChk"
          [ngModelOptions]="{standalone: true}"
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check2-newInsVeh"
          >New Insured Vehicle</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-unselected width-32 m-b-16 height-44 p-custom"
        [ngClass]="{'checkbox-selected ': regPreChk | atlasBoolean}"
      >
        <maia-checkbox
          [(ngModel)]="regPreChk"
          [ngModelOptions]="{standalone: true}"
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check3-newRegVeh"
          >New Register Vehicle</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-unselected width-32 m-r-16 height-64 p-custom"
        [ngClass]="{'checkbox-selected ': veh45Chk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check4-veh45chk"
          [(ngModel)]="veh45Chk"
          [ngModelOptions]="{standalone: true}"
          >Motor vehicles owned by physical persons aged over 45 years</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-unselected width-32 m-r-16 height-64 p-custom"
        [ngClass]="{
          'checkbox-selected ': expEvalPreChk | atlasBoolean
        }"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check5-expEvalPreChk"
          [(ngModel)]="expEvalPreChk"
          [ngModelOptions]="{standalone: true}"
          >Expert Evaluation</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-unselected width-32 height-64 p-custom"
        [ngClass]="{'checkbox-selected ': drctSaleChk | atlasBoolean}"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check6-drctSaleChk"
          [(ngModel)]="drctSaleChk"
          [ngModelOptions]="{standalone: true}"
          >Direct Sales</maia-checkbox
        >
      </maia-tile>
    </div>
  </maia-checkbox-group>
  <maia-divider></maia-divider>

  <maia-label
    i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-head-loadings"
    class="label-font m-b-16"
  >
    Loadings
  </maia-label>

  <maia-checkbox-group>
    <div class="discount-box">
      <maia-tile
        class="checkbox-unselected m-r-16 height-44 width-32 m-b-16 p-custom"
        [ngClass]="{'checkbox-selected': rskMkMdlChk | atlasBoolean}"
      >
        <maia-checkbox
          [(ngModel)]="rskMkMdlChk"
          [ngModelOptions]="{standalone: true}"
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check7-rskMkMdlChk"
          >Risky Makes and Models</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-unselected m-r-16 height-44 width-32 m-b-16 p-custom"
        [ngClass]="{'checkbox-selected ': vehUsgChk | atlasBoolean}"
      >
        <maia-checkbox
          [(ngModel)]="vehUsgChk"
          [ngModelOptions]="{standalone: true}"
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check8-rvehUsgChk"
          >Vehicle Usage</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-unselected height-44 width-32 m-b-16 p-custom"
        [ngClass]="{
          'checkbox-selected ': nrtAmrcaVehCh | atlasBoolean
        }"
      >
        <maia-checkbox
          [(ngModel)]="nrtAmrcaVehCh"
          [ngModelOptions]="{standalone: true}"
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check9-nrtAmrcaVehCh"
          >North America vehicles</maia-checkbox
        >
      </maia-tile>

      <maia-tile
        class="checkbox-unselected m-r-16 height-64 width-32 p-custom"
        [ngClass]="{
          'checkbox-selected ': agriForstChk | atlasBoolean
        }"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check10-agriForstChk"
          [(ngModel)]="agriForstChk"
          [ngModelOptions]="{standalone: true}"
          >Special agreement for construction agricultural and forestry machines
        </maia-checkbox>
      </maia-tile>

      <maia-tile
        class="checkbox-unselected m-r-16 height-64 width-32 p-custom"
        [ngClass]="{
          'checkbox-selected ': rprAbrdPreChk | atlasBoolean
        }"
      >
        <maia-checkbox
          i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check11-rprAbrdPreChk"
          [(ngModel)]="rprAbrdPreChk"
          [ngModelOptions]="{standalone: true}"
          >Repair Abroad</maia-checkbox
        >
      </maia-tile>

      <div class="column-warp width-32">
        <maia-tile
          class="checkbox-unselected height-64 m-b-8 p-custom"
          [ngClass]="{
            'checkbox-selected ': erthqcCvrgChk | atlasBoolean
          }"
        >
          <maia-checkbox
            (onValueChange)="enableExtensionBox(2)"
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-label-check12-erthqcCvrgChk"
            [(ngModel)]="erthqcCvrgChk"
            [ngModelOptions]="{standalone: true}"
            >Coverage of the earthquake risk and its consequences</maia-checkbox
          >
        </maia-tile>
        <maia-tile *ngIf="activateErthqcPercBox" class="checkbox-extension-tile-wrapper">
          <maia-label
            i18n="@@nje-b2b-frontend-casco-priceoffer-premium-check1-erthqcCvrgChk-extensionbox2"
            >Type 0-5%</maia-label
          >
          <maia-input-group>
            <input maiaInput class="input-box-perc" />
            <maia-input-group-suffix>%</maia-input-group-suffix>
          </maia-input-group>
        </maia-tile>
      </div>
    </div>
  </maia-checkbox-group>
</form>
<maia-divider></maia-divider>
<div class="m-t-48 bottom-space row-warp">
  <button
    i18n="@@nje-b2b-frontend-casco-priceoffer-premium--button-continue"
    maiaButton
    class="m-r-10"
    maiaButtonType="primary"
    (click)="saveAndContinue()"
    >Continue</button
  >
  <button
    maiaButton
    *ngIf="authorization"
    class="calculate-button"
    maiaButtonType="secondary"
    i18n="@@nje-b2b-frontend-casco-priceoffer-premium-button-calculate"
    >Calculate</button
  >
  <button
    *ngIf="calPre"
    maiaButton
    class="calculate-premium-button"
    maiaButtonType="secondary"
    i18n="@@nje-b2b-frontend-casco-priceoffer-premium-button-calpremium"
    >Calculate Premium</button
  >
</div>
<div class="night-box">
  <maia-label
    i18n="@@nje-b2b-frontend-casco-priceoffer-premium-nigthbox-label-totalpremium"
    class="premium-title"
    >Total Insurance Premium</maia-label
  >
  <maia-amount-display
    class="b2b-kdl-font-medium-bold premium-amount-1"
    [value]="getAmountValue('372.80')"
    size="medium-bold"
    currency="BGN"
  ></maia-amount-display>
  <maia-label
    i18n="@@nje-b2b-frontend-casco-priceoffer-premium-nigthbox-label-vehpremium"
    class="premium-title m-t-16"
    >Vehicle Premium</maia-label
  >
  <maia-amount-display
    class="b2b-kdl-font-medium-bold premium-amount-1"
    [value]="getAmountValue('100.60')"
    size="medium-bold"
    currency="BGN"
  ></maia-amount-display>
  <maia-label
    i18n="@@nje-b2b-frontend-casco-priceoffer-premium-nigthbox-label-Addpremium"
    class="premium-title"
    >Additional Equipment Premium</maia-label
  >
  <maia-amount-display
    class="b2b-kdl-font-medium-bold premium-amount-1"
    [value]="getAmountValue('272.20')"
    size="medium-bold"
    currency="BGN"
  ></maia-amount-display>
</div>
<div>
  <maia-tile class="day-tile column-warp">
    <maia-label
      i18n="@@nje-b2b-frontend-casco-priceoffer-premium-daynigthbox-label-totalpremium"
      class="premium-title"
      >Total Insurance Premium</maia-label
    >
    <maia-amount-display
      class="b2b-kdl-font-medium-bold premium-amount-1"
      [value]="getAmountValue('434.80')"
      size="medium-bold"
      currency="BGN"
    ></maia-amount-display
  ></maia-tile>
  <maia-tile class="day-night-box column-warp">
    <div class="row-warp">
      <maia-label
        i18n="@@nje-b2b-frontend-casco-priceoffer-premium-daynigthbox-label-addCover"
        class="day-box-text"
        >Addtional cover</maia-label
      >
      <maia-label class="day-box-price day-box-label-wrapping-1">0.00 BGN</maia-label>
    </div>
    <div class="row-warp">
      <maia-label
        i18n="@@nje-b2b-frontend-casco-priceoffer-premium-daynigthbox-label-loadings"
        class="day-box-text"
        >Loadings</maia-label
      >
      <maia-label class="day-box-price day-box-label-wrapping-2">+40 %</maia-label>
    </div>
    <div class="row-warp">
      <maia-label
        i18n="@@nje-b2b-frontend-casco-priceoffer-premium-daynigthbox-label-discounts"
        class="day-box-text"
        >Discounts</maia-label
      >
      <maia-label class="day-box-price day-box-label-wrapping-3">-10 %</maia-label>
    </div>
    <div class="row-warp">
      <maia-label
        i18n="@@nje-b2b-frontend-casco-priceoffer-premium-daynigthbox-label-addeqip"
        class="day-box-text"
        >Additional Equipments</maia-label
      >
      <maia-label class="day-box-price day-box-label-wrapping-4">+10 %</maia-label>
    </div>
  </maia-tile>
</div>
