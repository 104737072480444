import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncFaxPrinterSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncFaxPrinter]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M6.4 4.8V3.2h11.2v1.6" stroke-width="1.04"></svg:path><svg:path d="M6.4 16.8H3.2V9.6A1.6 1.6 0 0 1 4.8 8h14.4a1.6 1.6 0 0 1 1.6 1.6v7.2h-3.2" fill="none" stroke-miterlimit="10" stroke-width="1.04"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M6.4 12h11.2v8.8H6.4z" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncFaxPrinterSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncFaxPrinterSvgComponent,
  ],
  exports: [
    InformationFuncFaxPrinterSvgComponent,
  ],
})
export class InformationFuncFaxPrinterSvgModule {}
