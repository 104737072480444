/* eslint-disable @typescript-eslint/unbound-method */
import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ROW} from '@b2b-frontend/core';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  InsuredDetails,
  ObjectName,
  SupplementaryDetails,
} from '../../../contracts/property-insurance.interface';
import {
  OBJECT_NAME,
  OBJECT_TYPE,
} from '../../property-supplementary-list/__tests__/supplementary-list.mocks';

@Component({
  selector: 'nje-b2b-property-supplementary-list-slidein',
  templateUrl: './property-supplementary-list-slidein.component.html',
  styleUrls: ['./property-supplementary-list-slidein.component.scss'],
})
export class PropertySupplementaryListSlideinComponent
  extends ModalContentComponent<SupplementaryDetails, SupplementaryDetails>
  implements OnInit
{
  @Input() public flexDirection: string = ROW;

  public value: string;
  public enableObjectType: boolean = false;
  public objectlist: SupplementaryDetails[] = [...OBJECT_TYPE];
  public objectNames: ObjectName[] = [...OBJECT_NAME];

  public supplementartyListForm: FormGroup = new FormGroup({
    objectType: new FormControl(undefined, [Validators.required]),
    objectName: new FormControl(undefined, [Validators.required]),
    insuredValue: new FormControl(undefined, [Validators.required]),
    brandModel: new FormControl(undefined, [Validators.required]),
    otherSpecifics: new FormControl(undefined),
    authorArt: new FormControl(undefined, [Validators.required]),
    author: new FormControl(undefined, [Validators.required]),
    size: new FormControl(undefined, [Validators.required]),
    yearOfCreation: new FormControl(undefined),
  });

  public constructor(
    public control: ModalControl<InsuredDetails, InsuredDetails>,
    private readonly _formBuilder: FormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    this.supplementartyListForm = this._initializeSupplementarySlideinForm(data);
  }

  private _initializeSupplementarySlideinForm(formdata?: InsuredDetails): FormGroup {
    return (this.supplementartyListForm = this._formBuilder.group({
      objectType: new FormControl(formdata?.objectType),
      objectName: new FormControl(formdata?.objectName, [Validators.required]),
      insuredValue: new FormControl(formdata?.insuredValue, [Validators.required]),
      brandModel: new FormControl(formdata?.brandModel),
      otherSpecifics: new FormControl(formdata?.otherSpecifics),
      authorArt: new FormControl(formdata?.authorArt),
      author: new FormControl(formdata?.author),
      size: new FormControl(formdata?.size),
      yearOfCreation: new FormControl(formdata?.yearOfCreation),
    }));
  }

  public cancel(): void {
    this.control.cancel();
  }

  public saveSupplementaryData(): void {
    const supplementaryData: InsuredDetails = this.supplementartyListForm?.value;
    this.control.confirm(supplementaryData);
  }
}
