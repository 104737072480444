/* eslint-disable @typescript-eslint/no-empty-function */
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {ActivatedRoute} from '@angular/router';
import {PAYMENT, MOCK_AGENT_ID} from '../../../constants/payment-constant';
import {PaymentsService} from '../../../payments-core/services/payments.service';
import {FindIntermediaryAccountOutput} from '../../../contracts/instalment-payment/instalment';

@Component({
  selector: 'nje-b2b-instalment-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentComponent extends ScreenStepComponent implements OnInit {
  public isCashPaymentSelected: boolean = true;
  public isBankTransferSelected: boolean = false;
  public payment = PAYMENT;

  public isIntermediaryAccountPresent: boolean = true;
  public disableCash: boolean = false;
  public disableBankTransfer: boolean = false;

  public constructor(route: ActivatedRoute, private readonly _paymentService: PaymentsService) {
    super(route);
  }

  public ngOnInit(): void {
    this._initializePaymentChecks();
  }

  private _initializePaymentChecks(): void {
    this._checkForBankTransfer();
  }

  private _checkForBankTransfer(): void {
    this._checkIntermediaryBankAccount();
  }

  private _checkIntermediaryBankAccount(): void {
    this._paymentService
      .getAgentBankAccountDetails(MOCK_AGENT_ID)
      .subscribe((response: FindIntermediaryAccountOutput) => {
        if (response.bankAccount) {
          this.disableBankTransfer = false;
          this.isIntermediaryAccountPresent = true;
        } else {
          this.disableBankTransfer = true;
          this.isIntermediaryAccountPresent = false;
        }
      });
  }

  protected saveMtplData(): void {}

  public paymentSelected(input: number): void {
    if (input === PAYMENT.SELECTED_PAYMENT_MODE.CASH) {
      this.isCashPaymentSelected = true;
      this.isBankTransferSelected = false;
    } else if (input === PAYMENT.SELECTED_PAYMENT_MODE.BANK_TRANSFER) {
      this.isCashPaymentSelected = false;
      this.isBankTransferSelected = true;
    }
  }
}
