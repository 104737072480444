import {Component} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
} from '@njf-frontend-angular/flow-progress';
import {SumInsuredSlideinForm} from './_test_/sum-insured-slidein-interface';

@Component({
  selector: 'nje-sum-insured-slidein',
  templateUrl: './sum-insured-slidein.component.html',
  styleUrls: ['./sum-insured-slidein.component.scss'],
})
export class SumInsuredSlideinComponent extends ModalContentComponent<void> {
  public sumInsuredSlideinForm: FormGroupManager<SumInsuredSlideinForm> =
    this._formFactory.createFormManager<SumInsuredSlideinForm>({
      typeOfEquipment: new FormControl(undefined),
      make: new FormControl(undefined),
      model: new FormControl(undefined),
      priceAsNew: new FormControl(undefined),
      provider: new FormControl(undefined),
      dateOfInvioce: new FormControl(undefined),
    });

  public constructor(
    private readonly _formFactory: FormGroupManagerFactoryService,
    public control: ModalControl<void>,
  ) {
    super();
  }

  public cancel(): void {
    this.control.cancel();
  }

  public saveEquipment(): void {
    this.control.confirm();
  }
}
