/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Injector,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {PAGINATION, SupplementaryPartyDetails} from '@b2b-frontend/core';
import {ModalConfirmedResult, ModalResolution} from '@maia/modals';
import {PopUpController} from '@maia/pop-ups';
import {SlideInController} from '@maia/slide-ins';

import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {
  ADD_SUPPLEMENTARY_OBJECT,
  WARNING,
  REMOVE_FROM_SUPPLEMENTARY_LIST_CONFIRMATION_MSG,
  EDIT_SUPPLEMENTARY_OBJECT,
} from '../../constants/property-insurance-constants';
import {
  SupplementaryMessage,
  AccordionList,
  InsuredDetails,
  SupplementaryDetails,
  Amount,
  InsuredHeaders,
  PopUpContent,
} from '../../contracts/property-insurance.interface';
import {
  MOCK_SUPPLEMENTARY_MESSAGE,
  MOCK_ACCORDION_LIST,
  INSURANCE_PARTY_DETAILS_LIST,
  INSURED_PARTY_COL_HEADERS,
  PROPERTY_PREMIUM_PRICE,
  WIDGET_TITLE,
} from './__tests__/supplementary-list.mocks';
import {PropertySupplementaryListSlideinComponent} from '../property-details/property-supplementary-list-slidein/property-supplementary-list-slidein.component';

import {RemovePopupComponent} from '../remove-popup/remove-popup.component';
import {PropertySessionService} from '../../property-core/services/property-session.service';
import {PropertyProcessService} from '../../property-core/services/property-process.service';
import {PropertyWorkflow} from '../../property-core/property-workflow/property-workflow';

@Component({
  selector: 'nje-b2b-property-supplementary-list',
  templateUrl: './property-supplementary-list.component.html',
  styleUrls: ['./property-supplementary-list.component.scss'],
})
@UntilDestroy()
export class PropertySupplementaryListComponent extends ScreenStepComponent {
  public messageslist: SupplementaryMessage[] = [...MOCK_SUPPLEMENTARY_MESSAGE];
  public accordionlist: AccordionList[] = [...MOCK_ACCORDION_LIST];
  public columnHeaders: InsuredHeaders[] = INSURED_PARTY_COL_HEADERS;
  public insuredDetails: InsuredDetails[] = INSURANCE_PARTY_DETAILS_LIST;
  public totalPropertyValue: number;
  public totalAmount: Amount = PROPERTY_PREMIUM_PRICE;
  public title = WIDGET_TITLE.title;

  private readonly _popUpDeleteFactory: ComponentFactory<RemovePopupComponent>;
  private readonly _slideInOneFactory: ComponentFactory<PropertySupplementaryListSlideinComponent>;
  public supplementaryPartyDetailsList: SupplementaryPartyDetails[] = INSURANCE_PARTY_DETAILS_LIST;
  public enable: boolean = false;
  //For time being
  //public viewExpanded: boolean = FALSE;
  // public sticky: boolean = false;
  // public stickyPosition: number;
  // @ViewChild('stickyMenu')
  // public stickyElement: ElementRef;

  //pagination
  public page = PAGINATION.DEFAULT_PAGE;
  public pages = this.insuredDetails.length;
  public pageSize = PAGINATION.PAGE_SIZE;

  public lastPage(): boolean {
    return this.page >= this.pages;
  }

  private _getTotalPagesCount(totalRecords: number): number {
    return totalRecords % this.pageSize === 0
      ? totalRecords / this.pageSize
      : Math.ceil(totalRecords / this.pageSize);
  }

  public constructor(
    route: ActivatedRoute,
    private readonly _slideInCtrl: SlideInController,
    private readonly _popUpCtrl: PopUpController,
    private readonly _cdRef: ChangeDetectorRef,
    private readonly _componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _propertySessionService: PropertySessionService,
    public process: PropertyProcessService,
  ) {
    super(route);
    this._popUpDeleteFactory =
      this._componentFactoryResolver.resolveComponentFactory(RemovePopupComponent);
    this._slideInOneFactory = this._componentFactoryResolver.resolveComponentFactory(
      PropertySupplementaryListSlideinComponent,
    );
    this.process.currentWorkflow = PropertyWorkflow.PropertySupplementaryPolicy;
  }

  public addNewSupplementary(): void {
    this.openSupplementarySlideIn();
  }
  //For time being
  // public ngAfterViewInit(): void {
  //   this.stickyPosition = this.stickyElement?.nativeElement.offsetTop;
  // }

  // @HostListener('window:scroll')
  // public handleScroll(): void {
  //   const windowScroll = window.pageYOffset;
  //   if (windowScroll >= this.stickyPosition) {
  //     this.sticky = windowScroll >= this.stickyPosition;
  //   } else {
  //     this.sticky = windowScroll == this.stickyPosition;
  //   }
  //   this._cdRef.detectChanges();
  // }

  // public toggleAccordion(): void {
  //   this.viewExpanded ? (this.viewExpanded = FALSE) : (this.viewExpanded = TRUE);
  // }

  public updateSupplementaryDetails(supplementaryDetails: InsuredDetails, index?: number): void {
    if (null != supplementaryDetails && null != index) {
      this.insuredDetails[index] = supplementaryDetails;
    } else {
      this.insuredDetails.push(supplementaryDetails);
      this.pages = this._getTotalPagesCount(this.insuredDetails.length);
    }
  }

  public editSupplementary(supplementaryDetails: SupplementaryDetails, index: number): void {
    this.openSupplementarySlideIn(supplementaryDetails, index);
  }

  public openSupplementarySlideIn(
    supplementaryDetails?: SupplementaryDetails,
    index?: number,
  ): void {
    const slideinTitle = index == undefined ? ADD_SUPPLEMENTARY_OBJECT : EDIT_SUPPLEMENTARY_OBJECT;
    this._slideInCtrl
      .prepare(
        this._slideInOneFactory,
        this._injector,
        {title: slideinTitle},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: supplementaryDetails,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe((res: ModalConfirmedResult<InsuredDetails>) => {
        if (res.resolution === ModalResolution.CONFIRMED) {
          const supplementaryData = res.result;
          if (null != res && null != index) {
            this.updateSupplementaryDetails(supplementaryData, index);
          } else {
            this.updateSupplementaryDetails(supplementaryData);
          }
        }
        this._cdRef.detectChanges();
        this.enable = true;
      });
  }

  public saveSupplementaryData(): void {
    this._propertySessionService.setSupplementaryDto(this.supplementaryPartyDetailsList);
    this.saveAndContinue();
  }

  public removePerson(index: number): void {
    const removePopupMsgObj: PopUpContent = {
      title: WARNING,
      message: REMOVE_FROM_SUPPLEMENTARY_LIST_CONFIRMATION_MSG,
      footerNote: '',
    };

    this._popUpCtrl
      .prepare(
        this._popUpDeleteFactory,
        this._injector,
        {title: WARNING, footerInScrollArea: true},
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
          input: removePopupMsgObj,
        },
      )
      .subscribe((response: ModalConfirmedResult<void>) => {
        if (response.resolution === ModalResolution.CONFIRMED) {
          if (null != index && 0 <= index) {
            this.insuredDetails.splice(index, 1);
          } else {
            this.insuredDetails = [];
          }
        }
        this._cdRef.detectChanges();
      });
  }

  protected saveMtplData(): void {
    console.log(this.messageslist);
  }
}
