<!--This container is for search functionality-->
<div *ngIf="isLibraryDocumentSearchResult()">
  <div class="m-t-24">
    <div class="message-align">
      <div *ngFor="let item of libraryDocumentsSearchResult; let i = index">
        <maia-label
          *ngIf="isLanguageEnglish()"
          class="b2b-kdl-font-medium title-dark-color"
          i18n="@@nje-b2b-frontend-resource-admin-library"
        >
          Library: {{ item.adminLibrary.i18n?.en?.title }}
        </maia-label>
        <maia-label
          *ngIf="!isLanguageEnglish()"
          class="b2b-kdl-font-medium title-dark-color"
          i18n="@@nje-b2b-frontend-resource-admin-library"
        >
          Library: {{ item.adminLibrary.i18n?.bg?.title }}
        </maia-label>
        <p class="m-b-16 size4" *ngIf="isLanguageEnglish()">
          <maia-rich-text
            [value]="item.adminLibrary.i18n?.en?.description | hyperlink"
          ></maia-rich-text>
        </p>
        <p class="m-b-16 size4" *ngIf="!isLanguageEnglish()">
          <maia-rich-text
            [value]="item.adminLibrary.i18n?.bg?.description | hyperlink"
          ></maia-rich-text>
        </p>
        <maia-tile class="m-t-b-24 tile-box">
          <maia-tile-items>
            <div class="user-align">
              {{ item.userTypes | userType }}
            </div>
            <maia-tile-item class="size2" *ngIf="isLanguageEnglish()">
              {{ item.i18n?.en?.title }}
            </maia-tile-item>
            <maia-tile-item class="size2" *ngIf="!isLanguageEnglish()">
              {{ item.i18n?.bg?.title }}
            </maia-tile-item>
            <div class="size3" *ngIf="isLanguageEnglish()">
              <maia-rich-text [value]="item.i18n?.en?.description | hyperlink"></maia-rich-text>
            </div>
            <div class="size3" *ngIf="!isLanguageEnglish()">
              <maia-rich-text
                [value]="item.adminLibrary.i18n?.bg?.description | hyperlink"
              ></maia-rich-text>
            </div>
            <maia-tile-item>
              <div class="button-align m-t-8">
                <button
                  maiaButton
                  class="m-r-16"
                  maiaButtonType="secondary"
                  i18n="@@nje-b2b-frontend-resource-admin-edit"
                  (click)="openAddDocumentSlidein(item, i)"
                  >Edit</button
                >
                <button
                  maiaButton
                  maiaButtonType="tertiary-delete"
                  i18n="@@nje-b2b-frontend-resource-admin-remove"
                  (click)="deleteDocumentList(item.id, item.adminLibrary.id)"
                  >Remove</button
                ></div
              >
            </maia-tile-item>
          </maia-tile-items>
          <maia-avatar-svg class="avatar-background avatar-size" maia-avatar-display-icon>
            <svg class="icon-size-color" b2bFrontendActionExprPdf></svg>
          </maia-avatar-svg>
        </maia-tile>
      </div>
    </div>
  </div>
</div>

<!--this section is when user selects on admin library-->
<div *ngIf="isLibraryDocumentsSectionShown()">
  <div class="m-t-24">
    <div class="message-align" *ngIf="isLanguageEnglish()">
      <maia-label
        class="b2b-kdl-font-medium title-dark-color"
        i18n="@@nje-b2b-frontend-resource-admin-library"
      >
        Library: {{ selectedLibrary.i18n?.en?.title }}
      </maia-label>
      <p class="m-b-16 size4">
        <maia-rich-text
          [value]="selectedLibrary.i18n?.en?.description | hyperlink"
        ></maia-rich-text>
      </p>
    </div>
    <div class="message-align" *ngIf="!isLanguageEnglish()">
      <maia-label
        class="b2b-kdl-font-medium title-dark-color"
        i18n="@@nje-b2b-frontend-resource-admin-library"
      >
        Library: {{ selectedLibrary.i18n?.bg?.title }}
      </maia-label>
      <p class="m-b-16 size4">
        <maia-rich-text
          [value]="selectedLibrary.i18n?.bg?.description | hyperlink"
        ></maia-rich-text>
      </p>
    </div>

    <!--    PDF Documents-->
    <ng-container *ngIf="showPdfDocuments">
      <div>
        <maia-label i18n="@@nje-b2b-frontend-resource-admin-pdf"> PDF </maia-label>
      </div>
      <maia-tile *ngFor="let item of pdfDocumentsList; let i = index" class="m-t-b-24 tile-box">
        <maia-tile-items>
          <div class="user-align"> {{ item.userTypes | userType }} </div>
          <maia-tile-item class="size2" *ngIf="isLanguageEnglish()">
            {{ item.i18n?.en?.title }}
          </maia-tile-item>
          <maia-tile-item class="size2" *ngIf="!isLanguageEnglish()">
            {{ item.i18n?.bg?.title }}
          </maia-tile-item>
          <div class="size3" *ngIf="isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.en?.description | hyperlink"></maia-rich-text>
          </div>
          <div class="size3" *ngIf="!isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.bg?.description | hyperlink"></maia-rich-text>
          </div>
          <maia-tile-item>
            <div class="button-align m-t-8">
              <button
                maiaButton
                class="m-r-16"
                maiaButtonType="secondary"
                (click)="openAddDocumentSlidein(item, i)"
                i18n="@@nje-b2b-frontend-resource-admin-edit"
                >Edit</button
              >
              <button
                maiaButton
                maiaButtonType="tertiary-delete"
                (click)="deleteDocumentList(item.id, item.adminLibrary.id)"
                i18n="@@nje-b2b-frontend-resource-admin-remove"
                >Remove</button
              ></div
            >
          </maia-tile-item>
        </maia-tile-items>
        <maia-avatar-svg class="avatar-background avatar-size" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendActionExprPdf></svg>
        </maia-avatar-svg>
      </maia-tile>
    </ng-container>

    <!--    Image Documents-->
    <ng-container *ngIf="showImageDocuments">
      <div>
        <maia-label i18n="@@nje-b2b-frontend-resource-admin-image"> Image </maia-label>
      </div>
      <maia-tile *ngFor="let item of imageDocumentsList; let i = index" class="m-t-b-24 tile-box">
        <maia-tile-items>
          <div class="user-align"> {{ item.userTypes | userType }} </div>
          <maia-tile-item class="size2" *ngIf="isLanguageEnglish()">
            {{ item.i18n?.en?.title }}
          </maia-tile-item>
          <maia-tile-item class="size2" *ngIf="!isLanguageEnglish()">
            {{ item.i18n?.bg?.title }}
          </maia-tile-item>
          <div class="size3" *ngIf="isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.en?.description | hyperlink"></maia-rich-text>
          </div>
          <div class="size3" *ngIf="!isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.bg?.description | hyperlink"></maia-rich-text>
          </div>
          <maia-tile-item>
            <div class="button-align m-t-8">
              <button
                maiaButton
                class="m-r-16"
                maiaButtonType="secondary"
                (click)="openAddDocumentSlidein(item, i)"
                i18n="@@nje-b2b-frontend-resource-admin-edit"
                >Edit</button
              >
              <button
                maiaButton
                maiaButtonType="tertiary-delete"
                (click)="deleteDocumentList(item.id, item.adminLibrary.id)"
                i18n="@@nje-b2b-frontend-resource-admin-remove"
                >Remove</button
              ></div
            >
          </maia-tile-item>
        </maia-tile-items>
        <maia-avatar-svg class="avatar-background avatar-size" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendFileFuncDocument></svg>
        </maia-avatar-svg>
      </maia-tile>
    </ng-container>

    <!--    Excel Documents-->
    <ng-container *ngIf="showExcelDocuments">
      <div>
        <maia-label i18n="@@nje-b2b-frontend-resource-admin-excel"> Excel </maia-label>
      </div>
      <maia-tile *ngFor="let item of excelDocumentsList; let i = index" class="m-t-b-24 tile-box">
        <maia-tile-items>
          <div class="user-align"> {{ item.userTypes | userType }} </div>
          <maia-tile-item class="size2" *ngIf="isLanguageEnglish()">
            {{ item.i18n?.en?.title }}
          </maia-tile-item>
          <maia-tile-item class="size2" *ngIf="!isLanguageEnglish()">
            {{ item.i18n?.bg?.title }}
          </maia-tile-item>
          <div class="size3" *ngIf="isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.en?.description | hyperlink"></maia-rich-text>
          </div>
          <div class="size3" *ngIf="!isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.bg?.description | hyperlink"></maia-rich-text>
          </div>
          <maia-tile-item>
            <div class="button-align m-t-8">
              <button
                maiaButton
                class="m-r-16"
                maiaButtonType="secondary"
                (click)="openAddDocumentSlidein(item, i)"
                i18n="@@nje-b2b-frontend-resource-admin-edit"
                >Edit</button
              >
              <button
                maiaButton
                maiaButtonType="tertiary-delete"
                (click)="deleteDocumentList(item.id, item.adminLibrary.id)"
                i18n="@@nje-b2b-frontend-resource-admin-remove"
                >Remove</button
              ></div
            >
          </maia-tile-item>
        </maia-tile-items>
        <maia-avatar-svg class="avatar-background avatar-size" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendFileFuncDocument></svg>
        </maia-avatar-svg>
      </maia-tile>
    </ng-container>

    <!--    PPT Documents-->
    <ng-container *ngIf="showPptDocuments">
      <div>
        <maia-label> PPT </maia-label>
      </div>
      <maia-tile *ngFor="let item of pptDocumentsList; let i = index" class="m-t-b-24 tile-box">
        <maia-tile-items>
          <div class="user-align"> {{ item.userTypes | userType }} </div>
          <maia-tile-item class="size2" *ngIf="isLanguageEnglish()">
            {{ item.i18n?.en?.title }}
          </maia-tile-item>
          <maia-tile-item class="size2" *ngIf="!isLanguageEnglish()">
            {{ item.i18n?.bg?.title }}
          </maia-tile-item>
          <div class="size3" *ngIf="isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.en?.description | hyperlink"></maia-rich-text>
          </div>
          <div class="size3" *ngIf="!isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.bg?.description | hyperlink"></maia-rich-text>
          </div>
          <maia-tile-item>
            <div class="button-align m-t-8">
              <button
                maiaButton
                class="m-r-16"
                maiaButtonType="secondary"
                (click)="openAddDocumentSlidein(item, i)"
                i18n="@@nje-b2b-frontend-resource-admin-edit"
                >Edit</button
              >
              <button
                maiaButton
                maiaButtonType="tertiary-delete"
                (click)="deleteDocumentList(item.id, item.adminLibrary.id)"
                i18n="@@nje-b2b-frontend-resource-admin-remove"
                >Remove</button
              ></div
            >
          </maia-tile-item>
        </maia-tile-items>
        <maia-avatar-svg class="avatar-background avatar-size" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendFileFuncDocument></svg>
        </maia-avatar-svg>
      </maia-tile>
    </ng-container>

    <!--    Docs Documents-->
    <ng-container *ngIf="showDocxDocuments">
      <div>
        <maia-label i18n="@@nje-b2b-frontend-resource-admin-docs"> Docs </maia-label>
      </div>
      <maia-tile *ngFor="let item of docsDocumentsList; let i = index" class="m-t-b-24 tile-box">
        <maia-tile-items>
          <div class="user-align"> {{ item.userTypes | userType }} </div>
          <maia-tile-item class="size2" *ngIf="isLanguageEnglish()">
            {{ item.i18n?.en?.title }}
          </maia-tile-item>
          <maia-tile-item class="size2" *ngIf="!isLanguageEnglish()">
            {{ item.i18n?.bg?.title }}
          </maia-tile-item>
          <div class="size3" *ngIf="isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.en?.description | hyperlink"></maia-rich-text>
          </div>
          <div class="size3" *ngIf="!isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.bg?.description | hyperlink"></maia-rich-text>
          </div>
          <maia-tile-item>
            <div class="button-align m-t-8">
              <button
                maiaButton
                class="m-r-16"
                maiaButtonType="secondary"
                (click)="openAddDocumentSlidein(item, i)"
                i18n="@@nje-b2b-frontend-resource-admin-edit"
                >Edit</button
              >
              <button
                maiaButton
                maiaButtonType="tertiary-delete"
                (click)="deleteDocumentList(item.id, item.adminLibrary.id)"
                i18n="@@nje-b2b-frontend-resource-admin-remove"
                >Remove</button
              ></div
            >
          </maia-tile-item>
        </maia-tile-items>
        <maia-avatar-svg class="avatar-background avatar-size" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendFileFuncDocument></svg>
        </maia-avatar-svg>
      </maia-tile>
    </ng-container>

    <!--    Video Documents-->
    <ng-container *ngIf="showVideoDocuments">
      <div>
        <maia-label> Video </maia-label>
      </div>
      <maia-tile *ngFor="let item of videoDocumentsList; let i = index" class="m-t-b-24 tile-box">
        <maia-tile-items>
          <div class="user-align"> {{ item.userTypes | userType }} </div>
          <maia-tile-item class="size2" *ngIf="isLanguageEnglish()">
            {{ item.i18n?.en?.title }}
          </maia-tile-item>
          <maia-tile-item class="size2" *ngIf="!isLanguageEnglish()">
            {{ item.i18n?.bg?.title }}
          </maia-tile-item>
          <div class="size3" *ngIf="isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.en?.description | hyperlink"></maia-rich-text>
          </div>
          <div class="size3" *ngIf="!isLanguageEnglish()">
            <maia-rich-text [value]="item.i18n?.bg?.description | hyperlink"></maia-rich-text>
          </div>
          <maia-tile-item>
            <div class="button-align m-t-8">
              <button
                maiaButton
                class="m-r-16"
                maiaButtonType="secondary"
                (click)="openAddDocumentSlidein(item, i)"
                i18n="@@nje-b2b-frontend-resource-admin-edit"
                >Edit</button
              >
              <button
                maiaButton
                maiaButtonType="tertiary-delete"
                (click)="deleteDocumentList(item.id, item.adminLibrary.id)"
                i18n="@@nje-b2b-frontend-resource-admin-remove"
                >Remove</button
              ></div
            >
          </maia-tile-item>
        </maia-tile-items>
        <maia-avatar-svg class="avatar-background avatar-size" maia-avatar-display-icon>
          <svg class="icon-size-color" b2bFrontendFileFuncDocument></svg>
        </maia-avatar-svg>
      </maia-tile>
    </ng-container>

    <div class="action-with-icon">
      <svg class="action-func-plus-circle" b2bFrontendActionFuncPlusCircle></svg>
      <button
        maiaButton
        maiaButtonType="tertiary"
        class="add-btn"
        (click)="openAddDocumentSlidein()"
        i18n="@@nje-b2b-frontend-resource-admin-addnew"
        >Add documents</button
      >
    </div>
    <maia-divider class="divider-m-b-48"></maia-divider>
  </div>
</div>

<div *ngIf="showFileNotFound()" class="not-found" i18n="@@nje-b2b-frontend-resource-admin-nofile">
  File could not be found</div
>
