import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Date as AtlasDate, DateKey, DateUtils} from '@atlas/businesstypes';
import {
  CoreApiService,
  VehicleTalonData,
  PartyIdService,
  PartyType,
  dateValidator,
  CityApiData,
} from '@b2b-frontend/core';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';

import {distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {
  PERSON_TYPE_LIST,
  CITY_AUOTOCOMPLETE_LIST,
  MOCK_EGN_OWNER_DATA,
  MOCK_EIK_OWNER_DATA,
  MOCK_VIN_REG_NUMBERS,
  MockTypeMake,
  MOCK_NUMBER,
  MOCK_STRING,
  SOFIA,
} from './__tests__/vehicle-details-mock';
import {MockCityPostCode, MockVehicle, VehicleDTO} from './__tests__/vehicle-details-mockInterface';
import {Observable, of} from 'rxjs';
import {
  Owner,
  QuickVehicleAndOwnerDTO,
  VehicleMake,
  VehicleModel,
  VehicleType,
} from '../../contracts/quick-vehicle-interface';
import {CascoWorkflow} from '../../casco-core/casco-workflow/CascoWorkflow';
import {MIN_DATE_STRING} from '../../constants/quick-vehicle-details-constants';
import {CascoApiService} from '../../casco-core/services/casco-api-service.service';
import {CascoNomenclatureApiServiceService} from '../../casco-core/services/casco-nomenclature-api-service.service';
import {QuickVehicleAndOwnerSessionDto} from '../../contracts/casco-session-interface';
import {CascoSessionService} from '../../casco-core/services/casco-session.service';

@Component({
  selector: 'nje-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class VehicleDetailsComponent extends ScreenFormComponent implements OnInit {
  public mockPersonTypeList: MockVehicle[] = [...PERSON_TYPE_LIST];
  public mockCityPostcodeList: MockCityPostCode[] = [...CITY_AUOTOCOMPLETE_LIST];
  public screen: string = CascoWorkflow.QUICK_QUOTE;
  public cityArray: CityApiData[];
  public vehicleRegistrationNumber: string;
  public vehicleIdentificationNumber: string;

  public showHeading: boolean = true;
  public showForm: boolean = false;
  public isData: boolean = true;
  public isAge: boolean = false;

  public vehicleTalonData: VehicleTalonData;

  public mockVehicleTypes$: Observable<VehicleType[]>;
  public mockMake$: Observable<VehicleMake[]>;
  public mockModel$: Observable<VehicleModel[]>;

  public vehicleForm: FormGroupManager<VehicleDTO> =
    this._formFactory.createFormManager<VehicleDTO>({
      typeOfVehicle: new FormControl(undefined, [Validators.required]),
      make: new FormControl(undefined, [Validators.required]),
      model: new FormControl(undefined, [Validators.required]),
      dofr: new FormControl(undefined, [Validators.required, dateValidator()]),
      enginePower: new FormControl(undefined),
      tonnage: new FormControl(undefined),
      seats: new FormControl(undefined),

      idNumber: new FormControl(undefined, [this._idNumberValidator.bind(this)]),
      typeOfOwner: new FormControl(undefined, [Validators.required]),
      age: new FormControl(undefined),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
    });

  public minDate: AtlasDate = new AtlasDate(MIN_DATE_STRING);
  public maxDate: AtlasDate = DateUtils.add(DateUtils.today(), DateKey.Day, 0);

  public cpcController = this.vehicleForm.createCityAndPostCode('city', 'postCode');
  public cityAutocomplete = this.vehicleForm.createAutocompleteWithServersideFiltering<CityApiData>(
    'city',
    (query: string) =>
      this._coreApiService.getCity(query, true).pipe(
        map((res: CityApiData[]) => {
          this.cityArray = res;
          return res;
        }),
      ),
  );

  public constructor(
    route: ActivatedRoute,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _coreApiService: CoreApiService,
    private readonly _partyIdService: PartyIdService,
    private readonly _cascoApiService: CascoApiService,
    private readonly _changeDetection: ChangeDetectorRef,
    private readonly _cascoNomenclatureService: CascoNomenclatureApiServiceService,
    private readonly _cascoSessionService: CascoSessionService,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    this._prepopulateCityField();
    this.getVehicleTypes();
    this.getVehicleMakes();
    this.getVehicleModels();
    this.fillPostCode();
    this.enableAge();
  }

  private _prepopulateCityField(): void {
    this._coreApiService
      .getCity(SOFIA)
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        (response: CityApiData[]) => {
          this.cityArray = response;
        },
        error => {
          console.log('Api Error', error);
        },
      );
  }

  public getVehicleTypes(): void {
    this.mockVehicleTypes$ = this._cascoNomenclatureService.getVehicleType();
  }

  public getVehicleMakes(): void {
    this.mockMake$ = this.vehicleForm.controls.typeOfVehicle.valueChanges.pipe(
      switchMap((value: VehicleType) => {
        return this._cascoNomenclatureService.getVehicleMake(value.id);
      }),
    );
  }

  public getVehicleModels(): void {
    this.mockModel$ = this.vehicleForm.controls.make.valueChanges.pipe(
      switchMap((value: VehicleMake) => {
        return this._cascoNomenclatureService.getVehicleModel(MockTypeMake.Type, MockTypeMake.Make);
      }),
    );
  }

  protected saveMtplData(): void {
    const formValue: VehicleDTO = this.vehicleForm.value;
    const typeOfVehicle: string = formValue.typeOfVehicle.description;
    const make: string = formValue.make.id;
    const model: string = formValue.model.name;
    const vehicleSessionData: QuickVehicleAndOwnerSessionDto = {
      vehicleRegistrationNumber: this.vehicleRegistrationNumber,
      vehicleIdentificationNumber: this.vehicleIdentificationNumber,
      typeOfVehicle: typeOfVehicle,
      make: make,
      model: model,
      dateOfFirstRegistration: this.vehicleForm.controls.dofr.value,
      enginePower: this.vehicleForm.controls.enginePower.value,
      tonnage: this.vehicleForm.controls.tonnage.value,
      seats: this.vehicleForm.controls.seats.value,
      idNumber: this.vehicleForm.controls.idNumber.value,
      typeOfOwner: this.vehicleForm.controls.typeOfOwner.value,
      age: this.vehicleForm.controls.age.value,
      cityAndZip: this.vehicleForm.controls.city.value as CityApiData,
    };
    this._cascoSessionService.setQuickVehicleAndOwnerData(vehicleSessionData);
  }

  private _idNumberValidator(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const idNumberType: string | undefined = this._partyIdService.getType(control.value);
      if (idNumberType === PartyType.PERSON || idNumberType === PartyType.COMPANY) {
        return null;
      } else {
        return {invalidIdNumber: true};
      }
    }
    return null;
  }

  public fillPostCode(): void {
    this.vehicleForm.controls.city.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: CityApiData) => {
        this.vehicleForm.controls.postCode.patchValue(value?.zip);
      });
  }

  public searchVehicleByVinNo(vin: string): void {
    this.vehicleIdentificationNumber = vin;
    let regNo: string = MOCK_STRING;
    switch (vin) {
      case MOCK_VIN_REG_NUMBERS[0].idNo: {
        regNo = MOCK_VIN_REG_NUMBERS[0].regNo;
        break;
      }
      case MOCK_VIN_REG_NUMBERS[1].idNo: {
        regNo = MOCK_VIN_REG_NUMBERS[1].regNo;
        break;
      }
      case MOCK_VIN_REG_NUMBERS[2].idNo: {
        regNo = MOCK_VIN_REG_NUMBERS[2].regNo;
        break;
      }
      case MOCK_VIN_REG_NUMBERS[3].idNo: {
        regNo = MOCK_VIN_REG_NUMBERS[3].regNo;
        break;
      }
    }
    this._cascoApiService
      .getVehicleDetails(regNo, vin)
      .pipe(takeUntilDestroyed(this), distinctUntilChanged())
      .subscribe(
        (data: QuickVehicleAndOwnerDTO) => {
          this.patchVehicleForm(data);
        },
        () => {
          this.isData = false;
          this.showForm = true;
          this._changeDetection.markForCheck();
        },
      );
  }

  public searchVehicleByRegNo(regNo: string): void {
    this.vehicleRegistrationNumber = regNo;
    let vin: string = MOCK_STRING;
    switch (regNo) {
      case MOCK_VIN_REG_NUMBERS[0].regNo: {
        vin = MOCK_VIN_REG_NUMBERS[0].idNo;
        break;
      }
      case MOCK_VIN_REG_NUMBERS[1].regNo: {
        vin = MOCK_VIN_REG_NUMBERS[1].idNo;
        break;
      }
      case MOCK_VIN_REG_NUMBERS[2].regNo: {
        vin = MOCK_VIN_REG_NUMBERS[2].idNo;
        break;
      }
      case MOCK_VIN_REG_NUMBERS[3].regNo: {
        vin = MOCK_VIN_REG_NUMBERS[3].idNo;
        break;
      }
    }
    this._cascoApiService
      .getVehicleDetails(regNo, vin)
      .pipe(takeUntilDestroyed(this), distinctUntilChanged())
      .subscribe(
        (data: QuickVehicleAndOwnerDTO) => {
          this.patchVehicleForm(data);
        },
        () => {
          this.isData = false;
          this.showForm = true;
          this._changeDetection.markForCheck();
        },
      );
  }

  public patchVehicleForm(data: QuickVehicleAndOwnerDTO): void {
    this.showForm = true;
    const cityName: string = data.owner.personalData.address.city;
    const cityData: CityApiData | undefined = this.cityArray.find(
      element => element.city === cityName,
    );
    this.vehicleForm.patchValue({
      typeOfVehicle: {id: MOCK_NUMBER, description: data.vehicle.carType},
      make: {name: MOCK_STRING, id: data.vehicle.make},
      model: {name: data.vehicle.model, id: MOCK_STRING},
      dofr: this.toAtlasDate(data.vehicle.firstRegDate),
      enginePower: data.vehicle.enginePower,
      seats: data.vehicle.seatsNum,
      idNumber: data.owner.personalData.pin,
      typeOfOwner: data.owner.personalData.personType,
      age: data.owner.personalData.age,
      city: cityData,
    });

    this._changeDetection.markForCheck();
  }

  public toAtlasDate(val: string): AtlasDate {
    const arr: string[] = val.split('-');
    const dateString: string = `${arr[2]}${arr[1]}${arr[0]}`;
    return new AtlasDate(dateString);
  }

  public searchOwnerBYIdNumber(value: string): void {
    const idNumberType: string | undefined = this._partyIdService.getType(value);
    if (idNumberType === PartyType.PERSON) {
      this.getOwnerDataBYEgnNumber(value)
        .pipe(
          takeUntilDestroyed(this),
          tap((data: Owner) => {
            this.patchOwnerDetails(data);
          }),
        )
        .subscribe();
    } else if (idNumberType === PartyType.COMPANY) {
      this.getOwnerDataBYEikNumber(value)
        .pipe(
          takeUntilDestroyed(this),
          tap((data: Owner) => {
            this.patchOwnerDetails(data);
          }),
        )
        .subscribe();
    }
  }

  public patchOwnerDetails(data: Owner): void {
    const cityName: string = data.personalData.address.city;
    const cityData: MockCityPostCode | undefined = this.mockCityPostcodeList.find(
      element => element.title === cityName,
    );
    this.vehicleForm.patchValue({
      typeOfOwner: data.personalData.personType,
      age: data.personalData.age,
      city: cityData,
    });
  }

  public showFormFunction(value: boolean) {
    this.showForm = value;
  }

  public enableAge(): void {
    this.vehicleForm.controls.typeOfOwner.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: string) => {
        if (value && value === this.mockPersonTypeList[0].name) {
          this.isAge = true;
        } else {
          this.isAge = false;
        }
      });

    this.vehicleForm.controls.idNumber.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: string) => {
        if (value) {
          this.isAge = this._partyIdService.getType(value) === PartyType.PERSON;
        }
      });
  }

  public getOwnerDataBYEgnNumber(number: string): Observable<Owner> {
    return of(MOCK_EGN_OWNER_DATA);
  }

  public getOwnerDataBYEikNumber(number: string): Observable<Owner> {
    return of(MOCK_EIK_OWNER_DATA);
  }
}
