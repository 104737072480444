import {Injectable} from '@angular/core';
import {DateUtils} from '@atlas/businesstypes';
import {
  CascoSessionDTO,
  QuickVehicleAndOwnerSessionDto,
  SerializedCascoSessionDTO,
  SerializedQuickVehicleAndOwnerSessionDto,
} from '../../contracts/casco-session-interface';

@Injectable({
  providedIn: 'root',
})
export class CascoSessionService {
  public data: CascoSessionDTO = {};

  public constructor() {
    this.restoreFromGlobalStorage();
  }

  public restoreFromGlobalStorage() {
    const cascoJson = sessionStorage.getItem('casco');
    if (cascoJson) {
      const serializedData = JSON.parse(cascoJson) as SerializedCascoSessionDTO;
      this.data = this.unserialize(serializedData);
    }
    return this.data;
  }

  public updateGlobalStorage() {
    const serialized = this.serialize(this.data);
    sessionStorage.setItem('casco', JSON.stringify(serialized));
  }

  public serialize(unserializedData: CascoSessionDTO): SerializedCascoSessionDTO {
    let serializedVehicleData: SerializedQuickVehicleAndOwnerSessionDto | undefined;
    if (unserializedData.quickVehicleAndOwnerDto) {
      serializedVehicleData = {
        ...unserializedData.quickVehicleAndOwnerDto,
        dateOfFirstRegistration: DateUtils.serialize(
          unserializedData.quickVehicleAndOwnerDto.dateOfFirstRegistration,
        ),
      };
    }
    return {
      ...unserializedData,
      quickVehicleAndOwnerDto: serializedVehicleData,
    };
  }

  public isStepDone(step: number | string): boolean {
    return typeof step === 'number' ? `step${step}` in this.data : step in this.data;
  }

  public unserialize(serializedData: SerializedCascoSessionDTO): CascoSessionDTO {
    let unserializedVehicleData: QuickVehicleAndOwnerSessionDto | undefined;
    if (serializedData.quickVehicleAndOwnerDto) {
      unserializedVehicleData = {
        ...serializedData.quickVehicleAndOwnerDto,
        dateOfFirstRegistration: DateUtils.unserialize(
          serializedData.quickVehicleAndOwnerDto.dateOfFirstRegistration,
        ),
      };
    }
    return {
      ...serializedData,
      quickVehicleAndOwnerDto: unserializedVehicleData,
    };
  }

  public getCascoSessionData(): CascoSessionDTO {
    const cascoJson = sessionStorage.getItem('casco');
    if (cascoJson) {
      return this.data;
    } else {
      this.data = {};
      this.updateGlobalStorage();
      return this.data;
    }
  }

  public setQuickVehicleAndOwnerData(quickVehicleData: QuickVehicleAndOwnerSessionDto): void {
    this.data.quickVehicleAndOwnerDto = quickVehicleData;
    this.updateGlobalStorage();
  }
}
