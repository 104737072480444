import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FlagUtilSlovakiaSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendFlagUtilSlovakia]',
  template: '<svg:defs><svg:path id="svg4eESer83JtGtSrf2UW2zrvCq3xBXcNd3MRkv7CYVbZA=" d="M0 0h48v48H0z"></svg:path></svg:defs><svg:clipPath id="svg99F5OpEp+nBnjun295Fnw+NfLEse19HS6+LrJcp7jZU="><svg:use href="#svg4eESer83JtGtSrf2UW2zrvCq3xBXcNd3MRkv7CYVbZA=" overflow="visible"></svg:use></svg:clipPath><svg:g clip-path="url(#svg99F5OpEp+nBnjun295Fnw+NfLEse19HS6+LrJcp7jZU=)"><svg:path fill="#FFFFFF" d="M0 0h48v48H0z"></svg:path><svg:path fill="#F0F0F0" d="M0 0h48v48H0z"></svg:path><svg:path fill="#0052B4" d="M0 16h48v16H0z"></svg:path><svg:path fill="#D80027" d="M0 32h48v16H0z"></svg:path><svg:path fill="#F0F0F0" d="M7.8 13.2V27C7.8 34.8 18 37.2 18 37.2S28.3 34.8 28.3 27V13.2H7.8z"></svg:path><svg:path fill="#D80027" d="M10.2 13.2V27c0 .9.2 1.8.6 2.6h14.4c.4-.8.6-1.7.6-2.6V13.2H10.2z"></svg:path><svg:path fill="#F0F0F0" d="M22.9 22.8h-3.6v-2.4h2.4V18h-2.4v-2.4h-2.4V18h-2.4v2.4h2.4v2.4h-3.6v2.4h3.6v2.4h2.4v-2.4h3.6v-2.4z"></svg:path><svg:path fill="#0052B4" d="M14.1 33c1.2.8 2.6 1.4 3.9 1.8 1.4-.4 2.7-1 3.9-1.8 1.6-1 2.7-2.1 3.3-3.4-1-.7-2.2-.9-3.4-.7-.9-2.1-3.4-3.1-5.5-2.2-1 .4-1.7 1.2-2.2 2.2-1.2-.3-2.4 0-3.4.7.8 1.3 1.9 2.4 3.4 3.4z"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'xlink': 'http://www.w3.org/1999/xlink',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagUtilSlovakiaSvgComponent {
}

@NgModule({
  declarations: [
    FlagUtilSlovakiaSvgComponent,
  ],
  exports: [
    FlagUtilSlovakiaSvgComponent,
  ],
})
export class FlagUtilSlovakiaSvgModule {}
