<maia-fluid-fluid-layout alignContentTop>
  <insurance-party-details
    [flexDirection]="flexDirection"
    [onlyAddressForm]="true"
    class="insured-party-details-slider"
  ></insurance-party-details>
</maia-fluid-fluid-layout>
<maia-button-group class="slidein-buttons">
  <button class="m-t-b-24" maiaButton maiaButtonType="primary" (click)="saveInsuranceData()"
    >Save</button
  >
  <button class="m-t-b-24" maiaButton maiaButtonType="secondary" (click)="cancel()">Cancel</button>
</maia-button-group>
