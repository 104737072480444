import {Component} from '@angular/core';
import {Router} from '@angular/router';

import {DashboardOptions} from '../../../constants/dashboardOptions';
import {DashboardOption} from '../../../models/DashboardOption';

@Component({
  selector: 'nje-account-operations',
  templateUrl: './account-operations.component.html',
  styleUrls: ['./account-operations.component.scss'],
})
export class AccountOperationsComponent {
  public cards: DashboardOption[] = DashboardOptions;

  public constructor(private readonly _router: Router) {}

  public routeToPage(card: DashboardOption): void {
    if (card.routePath) {
      this._router.navigate([card.routePath]);
    }
  }
}
