import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators, FormGroup, ValidationErrors} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {
  PartyIdService,
  PartyType,
  EgnLnchData,
  EikData,
  InsuringPartyDto,
  ProposalDto,
  // CoreApiService, // For Timebeing
  ApiPersonalData,
  ApiCompanyData,
  InsuredPerson,
  InsuredPersonDto,
  BLANK_VALUE,
  RadioDefaults,
  EgnValidatorService,
  DateFormatterService,
} from '@b2b-frontend/core';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';

import {TravelSessionService} from '../../travel-core/services/travel-session.service';
import {TravelPolicyService} from '../../travel-core/services/travel-policy.service';
import {TEMP_CITIZIENHIP} from '../../constants/constant';

@Component({
  selector: 'travel-insuring-party',
  templateUrl: './travel-insuring-party.component.html',
  styleUrls: ['./travel-insuring-party.component.scss'],
})
export class TravelInsuringPartyComponent extends ScreenStepComponent implements OnInit {
  public isEIK: boolean = false;

  public stepForm: FormGroup = this.formBuilder.group({
    idNumber: new FormControl(undefined, [Validators.required, this.idNumberValidator.bind(this)]),
  });

  public insuringPartyType: PartyType | undefined;
  public insuringPartyDto: InsuringPartyDto;
  public userData: ApiPersonalData | undefined;
  public companyData: ApiCompanyData | undefined;
  public savedIdNumber: string = '';
  public addedInsuredPersonArray: InsuredPerson[];
  public insuredPeopleFileUpload: boolean;

  public constructor(
    route: ActivatedRoute,
    public formBuilder: FormBuilder,
    private readonly partyId: PartyIdService,
    public travelSessionService: TravelSessionService,
    private readonly _travelPolicyService: TravelPolicyService, // private readonly _coreApiService: CoreApiService, // For Timebeing
    private readonly _egnAgeService: EgnValidatorService,
    private readonly _dateFormatService: DateFormatterService,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    this._travelPolicyService.showPriceAccordion(true);
    this.hideAccordion();
    if (this.travelSessionService.data != undefined) {
      const data: ProposalDto = this.travelSessionService.data;
      if (data.insuringPartyDto) {
        this.insuringPartyDto = data.insuringPartyDto;
        this.savedIdNumber = this.insuringPartyDto.idNumber;
        this.stepForm.patchValue({
          idNumber: data.insuringPartyDto.idNumber,
        });
      }

      this.addedInsuredPersonArray = data.insuredPersonDto
        ? data.insuredPersonDto.addedInsuredPerson
        : [];

      this.insuredPeopleFileUpload = data.tripDetailDto ? data.tripDetailDto.isUploadFile : false;
    }
  }

  protected saveMtplData(): void {}

  public idNumberValidator(control: FormControl): ValidationErrors | null {
    if (control.value != null) {
      this.insuringPartyType = this.partyId.getType(control.value);
      this.isEIK = this.insuringPartyType === PartyType.COMPANY ? true : false;
    }
    if (control.value && !this.partyId.getType(control.value)) {
      return {invalidNumber: true};
    }
    //Time being
    // if (this.savedIdNumber !== control.value) {
    //   if (control.value != null && !this.isEIK) {
    //     this._fetchUserData(control.value);
    //   } else if (control.value != null && this.isEIK) {
    //     this._fetchCompanyData(control.value);
    //   }
    // } else {
    //   this.companyData = undefined;
    //   this.userData = undefined;
    // }
    return null;
  }

  // For Time being
  // private _fetchUserData(idNumber: string): void {
  //   this._coreApiService.getPersonData(idNumber).subscribe((result: ApiPersonalData) => {
  //     this.userData = {...result};
  //     this.userData = undefined;
  //   });
  // }

  // private _fetchCompanyData(idNumber: string): void {
  //   this._coreApiService.getCompanyData(idNumber).subscribe((result: ApiCompanyData) => {
  //     this.companyData = {...result};
  //   });
  // }

  public saveEgnData(data: EgnLnchData): void {
    if (this.stepForm.valid) {
      const idNumber: string = this.stepForm.controls.idNumber.value as string;
      const insuringPartyObj: InsuringPartyDto = {
        idNumber: idNumber,
        partyType: this.insuringPartyType,
        insuringPartyEgnData: data,
      };
      this.travelSessionService.setInsuringPartDto(insuringPartyObj);
      this.updateInsuredPersonSession(data, idNumber);

      this.saveAndContinue();
    }
  }

  public updateInsuredPersonSession(data: EgnLnchData, idNumber: string): void {
    if (data.insuringPartyRadio != null) {
      if (data.insuringPartyRadio === RadioDefaults.YES) {
        let age: number = 0;
        let dobString: string | undefined = BLANK_VALUE;
        if (this.insuringPartyType === PartyType.PERSON) {
          const birthdate = this._egnAgeService.getDateFromEGN(idNumber);
          dobString = this._dateFormatService.getDDMMYYYYFormatDate(birthdate);
          age = this._travelPolicyService.calculateAge(birthdate);
        } else if (typeof data.dob !== 'string' && data.dob != null) {
          data.citizenship = TEMP_CITIZIENHIP;
          const birthdate = this._dateFormatService.normalizeDate(data.dob);
          console.log(birthdate);
          if (birthdate) {
            dobString = this._dateFormatService.getDDMMYYYYFormatDate(birthdate);
            age = this._travelPolicyService.calculateAge(birthdate);
          }
        }

        const insurePerson: InsuredPerson = {
          idNumber: idNumber,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          firstNameEn: data.firstNameEn,
          middleNameEn: data.middleNameEn,
          lastNameEn: data.lastNameEn,
          phoneNumber: data.phoneNumber ? data.phoneNumber : BLANK_VALUE,
          citizenship: data.citizenship ? data.citizenship : undefined,
          partyType: this.insuringPartyType,
          age: age,
          dob: dobString,
          isSameAsInsuringParty: true,
        };
        const index: number = this.addedInsuredPersonArray.findIndex(
          (iPerson: InsuredPerson) =>
            iPerson.isSameAsInsuringParty && iPerson.isSameAsInsuringParty === true,
        );
        index === -1
          ? this.addedInsuredPersonArray.push(insurePerson)
          : (this.addedInsuredPersonArray[index] = {...insurePerson});
      } else if (data.insuringPartyRadio === RadioDefaults.NO) {
        const index = this.addedInsuredPersonArray.findIndex(
          (person: InsuredPerson) => person.idNumber === idNumber,
        );
        index > -1 ? this.addedInsuredPersonArray.splice(index, 1) : undefined;
      }
      const insurePersonDto: InsuredPersonDto = {
        addedInsuredPerson: this.addedInsuredPersonArray,
      };

      this.travelSessionService.setInsuredPersonData(insurePersonDto);
    }
  }

  //disable accordion on edit
  public hideAccordion(): void {
    this.step.inEdit.subscribe(result => {
      if (result) {
        this._travelPolicyService.showPriceAccordion(false);
      }
    });
  }

  public saveEikData(data: EikData): void {
    if (this.stepForm.valid) {
      const idNumber: string = this.stepForm.controls.idNumber.value as string;
      const insuringPartyObj: InsuringPartyDto = {
        idNumber: idNumber,
        partyType: this.insuringPartyType,
        insuringPartyEikData: data,
      };
      this.travelSessionService.setInsuringPartDto(insuringPartyObj);
      this.saveAndContinue();
    }
  }
}
