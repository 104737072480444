import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprHayMakerSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendTransportationExprHayMaker]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M19.97 19.85l8.06 8.06"></svg:path><svg:circle cx="16.05" cy="15.93" r="5.54" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M14.12 10.19V5"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M21.81 13.85h6.36"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M12.13 19.85H5"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M10.63 14.78l-4.41-4.41"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M18.51 10.52l4.6-4.61"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M15.91 21.47l-5.87 5.87"></svg:path><svg:circle cx="32.07" cy="31.95" r="5.54" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:circle><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M37.81 33.88H43"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M34.15 26.19v-6.36"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M28.15 35.87V43"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M33.22 37.37l4.41 4.41"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M37.48 29.49l4.61-4.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M26.53 32.09l-5.87 5.87"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprHayMakerSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprHayMakerSvgComponent,
  ],
  exports: [
    TransportationExprHayMakerSvgComponent,
  ],
})
export class TransportationExprHayMakerSvgModule {}
