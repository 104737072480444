import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncCardStackedSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationFuncCardStacked]',
  template: '<svg:path d="M8.17 18.38a1 1 0 0 0 1 1H20.5a1 1 0 0 0 1-1h0v-7.09a1 1 0 0 0-1-1h0" fill="none" stroke-linecap="round" stroke-miterlimit="10"></svg:path><svg:path d="M5.36 15.55a1 1 0 0 0 1 1h11.32a1 1 0 0 0 1-1h0V8.46a1 1 0 0 0-1-1h0" fill="none" stroke-linecap="round" stroke-miterlimit="10"></svg:path><svg:path d="M3.5 4.62a1 1 0 0 0-1 1h0v7.09a1 1 0 0 0 1 1h11.34a1 1 0 0 0 1-1h0V5.64a1 1 0 0 0-1-1h0z" fill="none" stroke-miterlimit="10"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncCardStackedSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncCardStackedSvgComponent,
  ],
  exports: [
    InformationFuncCardStackedSvgComponent,
  ],
})
export class InformationFuncCardStackedSvgModule {}
