import {Component} from '@angular/core';

import {AgentsbyAgencyOptions} from '../../../../constants/account-creation';

@Component({
  selector: 'nje-input-select-by-agency',
  templateUrl: './input-select-by-agency.component.html',
  styleUrls: ['./input-select-by-agency.component.scss'],
})
export class InputSelectByAgencyComponent {
  public value: string;

  public options = AgentsbyAgencyOptions;
}
