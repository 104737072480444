import {Component} from '@angular/core';
import {PhoneNumber} from '@atlas/businesstypes';

import {CountryPhoneNumberPrefixes} from '../../../../constants/account-creation';
import {CountryPhoneNumberPrefix} from '../../../../models/b2b/CountryPhoneNumberPrefix';

@Component({
  selector: 'nje-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
})
export class PhoneNumberInputComponent {
  public phonenumber: PhoneNumber;
  public countryPhoneNumberPrefix: CountryPhoneNumberPrefix[] = CountryPhoneNumberPrefixes;
}
