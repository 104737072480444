import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesExprFactorySvgModule
 */
@Component({
  selector: 'svg[b2bFrontendPlacesExprFactory]',
  template: '<svg:path d="M6.85 18.75V42.1h34.3V5.9h-6.26L30.2 26.53l-12.4-7.78v7.78z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M11.53 32.73h4.69v4.69h-4.69z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M21.09 32.73h4.68v4.69h-4.68z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M30.58 32.73h4.68v4.69h-4.68z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesExprFactorySvgComponent {
}

@NgModule({
  declarations: [
    PlacesExprFactorySvgComponent,
  ],
  exports: [
    PlacesExprFactorySvgComponent,
  ],
})
export class PlacesExprFactorySvgModule {}
