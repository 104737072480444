<form maiaForm [formGroup]="newProductForm" class="form">
  <maia-form-group withoutDividers>
    <!-- Library & Product Portfolio -->

    <div
      class="b2b-kdl-font-medium title-dark-color left-mar m-b-8"
      i18n="@@nje-b2b-frontend-resource-library-addproduct-library"
      >Library:
      <span class="b2b-kdl-font-medium-bold description-styling">
        {{ selectedLibrary?.i18n?.en?.title }}
      </span>
    </div>
    <div
      class="b2b-kdl-font-medium title-dark-color mb-24"
      i18n="@@nje-b2b-frontend-resource-library-addproduct-productportfolio"
      >Product Portfolio:
      <span class="b2b-kdl-font-medium-bold description-styling">
        {{ selectedProductPortfolio?.i18n?.en?.title }}
      </span>
    </div>

    <maia-fluid-fluid-layout alignContentTop>
      <div class="fluid">
        <maia-form-element hideOptionalIndicator>
          <label
            for="userType"
            class="b2b-kdl-font-small cover-title m-b-8"
            i18n="@@nje-b2b-frontend-resource-library-addproduct-usertype"
            >User Type</label
          >
          <maia-fake-input
            maiaLabelTarget
            class="add-news-events"
            id="userType"
            formControlName="userType"
          >
            <ng-container [ngSwitch]="newProductForm?.value.userType?.length === 0">
              <ng-container *ngSwitchCase="true">
                <span
                  class="select-span subtitle-light-color"
                  i18n="@@nje-b2b-frontend-resource-library-addproduct-selectusertype"
                >
                  Select user type</span
                >
              </ng-container>
              <ng-container *ngSwitchCase="false">
                <span class="select-span">
                  {{ selectedUsers }}
                </span>
              </ng-container>
              <button
                id="maia-action-icon-1"
                maiaActionWithIcon
                class="select-button"
                (click)="selectUsertype()"
              >
                <span
                  *ngSwitchCase="true"
                  i18n="@@nje-b2b-frontend-resource-library-addproduct-select"
                  >Select</span
                >
                <span
                  *ngSwitchCase="false"
                  i18n="@@nje-b2b-frontend-resource-library-addproduct-edit"
                  >Edit</span
                >
              </button>
            </ng-container>
          </maia-fake-input>
          <maia-validation
            *ngIf="
              newProductForm.controls['userType'].touched &&
              newProductForm.controls['userType'].hasError('required')
            "
            i18n="@@nje-b2b-frontend-resource-library-addproduct-pleaseselectusertype"
          >
            Please select a usertype
          </maia-validation>
        </maia-form-element>

        <maia-form-element hideOptionalIndicator hideValidationIcon>
          <label
            class="b2b-kdl-font-small cover-title m-b-8"
            i18n="@@nje-b2b-frontend-resource-library-addproduct-producttitle"
            >Product Title</label
          >
          <maia-text-area-limit [maxLength]="titleLimit">
            <textarea
              name="productTitle"
              id="title"
              maiaTextArea
              [atlasMaxLength]="titleLimit"
              formControlName="productTitle"
              placeholder="Enter product title"
              i18n-placeholder="@@nje-b2b-frontend-resource-library-addproduct-titleproduct"
            >
            </textarea>
            <maia-validation
              i18n="@@nje-b2b-frontend-resource-library-addproduct-enterproducttitle"
              *ngIf="
                (newProductForm.controls['productTitle'].touched ||
                  newProductForm.controls['productTitle'].dirty) &&
                newProductForm.controls['productTitle'].hasError('required')
              "
              >Please enter product title</maia-validation
            >
          </maia-text-area-limit>
        </maia-form-element>
      </div>

      <div class="fluid">
        <maia-form-element hideOptionalIndicator hideValidationIcon class="adjust-maia-remaining">
          <label
            for="productDescription"
            class="b2b-kdl-font-small cover-title m-b-8"
            i18n="@@nje-b2b-frontend-resource-library-addproduct-productdesc"
            >Product Description</label
          >
          <maia-text-area-limit [maxLength]="descriptionLimit">
            <textarea
              name="productDescription"
              maiaTextArea
              [atlasMaxLength]="descriptionLimit"
              id="productDescription"
              placeholder="Enter product description"
              i18n-placeholder="@@nje-b2b-frontend-resource-library-addproduct-descproduct"
              formControlName="productDescription"
              [value]="newProductForm.value.productDescription || null"
            >
            </textarea>
            <div class="relative-container">
              <maia-validation
                class="absolute-container"
                i18n="@@nje-b2b-frontend-resource-library-addproduct-enterproductdesc"
                *ngIf="
                  (newProductForm.controls['productDescription'].touched ||
                    newProductForm.controls['productDescription'].dirty) &&
                  newProductForm.controls['productDescription'].hasError('required')
                "
                >Please enter product description</maia-validation
              >
            </div>
          </maia-text-area-limit>
        </maia-form-element>
        <maia-form-element hideOptionalIndicator>
          <label
            for="productImage1"
            class="b2b-kdl-font-small cover-title m-b-8"
            i18n="@@nje-b2b-frontend-resource-library-addproduct-productimage"
            >Product Image</label
          >
          <hermes-file-uploader-tile
            id="productImage"
            class="upload-button"
            name="file-uploader-basic"
            [slideInConfig]="imageSlideInConfig"
            [disabled]="false"
            formControlName="productImage"
            (click)="updateFlag(productMedia)"
          >
          </hermes-file-uploader-tile>
          <div
            *ngIf="
              (newProductForm.controls['productImage'].touched ||
                newProductForm.controls['productImage'].dirty) &&
              newProductForm.controls['productImage'].hasError('required')
            "
            class="b2b-kdl-font-small error-text"
            i18n="@@nje-b2b-frontend-resource-library-addproduct-imageforthisproduct"
          >
            Please upload an image for this product
          </div>
        </maia-form-element>
      </div>
    </maia-fluid-fluid-layout>

    <maia-divider></maia-divider>

    <!-- Product category -->

    <maia-form-group subtitle="Product Category">
      <div *ngFor="let categoryForm of productCategory.controls; let i = index">
        <div [formGroup]="categoryForm">
          <maia-fluid-fixed-layout class="blue-box">
            <div class="fixed">
              <div class="form-element-custom-width">
                <maia-form-element hideOptionalIndicator>
                  <label
                    class="b2b-kdl-font-small cover-title m-b-8"
                    i18n="@@nje-b2b-frontend-resource-library-addproduct-categoryname"
                    >Category Name</label
                  >
                  <maia-input-select formControlName="categoryId">
                    <input
                      maiaInput
                      placeholder="Select"
                      i18n-placeholder="
                        @@nje-b2b-frontend-resource-library-addproduct-selectproduct"
                      [value]="getValue(categoryForm.controls['categoryId'].value)"
                    />
                    <maia-option
                      *ngFor="let category of filteredCategoryList"
                      [value]="category.id"
                    >
                      <span *ngIf="ifEnglishLang()"> {{ category?.i18n?.en?.title }}</span>
                      <span *ngIf="!ifEnglishLang()">{{ category?.i18n?.bg?.title }}</span>
                    </maia-option>
                    <maia-option [value]="categoryForm.controls['categoryId'].value">
                      <button maiaActionWithIcon (click)="editCategories()">
                        <span
                          i18n="@@nje-b2b-frontend-resource-library-addproduct-addoreditcategories"
                        >
                          Add or edit categories</span
                        >
                      </button>
                    </maia-option>
                  </maia-input-select>
                </maia-form-element>

                <maia-form-element hideOptionalIndicator hideValidationIcon>
                  <label
                    for="categoryDescription"
                    class="b2b-kdl-font-small cover-title m-b-8"
                    i18n="@@nje-b2b-frontend-resource-library-addproduct-categorydesc"
                    >Category Description</label
                  >
                  <maia-text-area-limit [maxLength]="descriptionLimit">
                    <textarea
                      name="categoryDescription"
                      maiaTextArea
                      [atlasMaxLength]="descriptionLimit"
                      id="categoryDescription"
                      placeholder="Enter category description"
                      i18n-placeholder="@@nje-b2b-frontend-resource-library-addproduct-desccategory"
                      formControlName="categoryDescription"
                      [value]="newProductForm.value.categoryDescription || null"
                    >
                    </textarea>
                    <maia-validation
                      i18n="@@nje-b2b-frontend-resource-library-addproduct-entercategorydesc"
                      *ngIf="
                        (categoryForm.controls['categoryDescription'].touched ||
                          categoryForm.controls['categoryDescription'].dirty) &&
                        categoryForm.controls['categoryDescription'].hasError('required')
                      "
                      >Please enter category description</maia-validation
                    >
                  </maia-text-area-limit>
                </maia-form-element>

                <maia-form-element hideOptionalIndicator>
                  <label
                    class="b2b-kdl-font-small cover-title m-b-8"
                    i18n="@@nje-b2b-frontend-resource-library-addproduct-documents"
                    >Documents</label
                  >
                  <hermes-file-uploader-tile
                    class="upload-button"
                    name="file-uploader-basic"
                    [slideInConfig]="documentSlideInConfig"
                    [disabled]="false"
                    formControlName="documents"
                    (click)="updateFlag(productDocuments)"
                  >
                  </hermes-file-uploader-tile>
                </maia-form-element>
              </div>
            </div>
            <div class="fixed">
              <button
                maiaButton
                maiaButtonType="tertiary-delete"
                (click)="removeProductCategory(i)"
                class="button-allign"
                i18n="@@nje-b2b-frontend-resource-admin-remove"
                >Remove</button
              >
            </div>
          </maia-fluid-fixed-layout>
        </div>
      </div>
    </maia-form-group>
    <div *ngIf="checkProductCategoryLimit()">
      <button maiaActionWithIcon [size]="size" (click)="addProductCategory()">
        <svg b2bFrontendActionFuncPlusCircle maia-action-icon></svg>
        <span
          rel="noopener noreferrer"
          i18n="@@nje-b2b-frontend-resource-library-addproduct-addnewproductcategory"
          >Add New Product Category</span
        >
      </button>
    </div>
    <maia-divider></maia-divider>

    <maia-button-group class="align-left">
      <button
        maiaButton
        maiaButtonType="primary"
        (click)="saveProductData()"
        [disabled]="newProductForm.invalid"
        i18n="nje-b2b-frontend-resource-library-list-add"
        >Add</button
      >
      <button
        maiaButton
        maiaButtonType="secondary"
        (click)="navigateToAddProduct()"
        i18n="@@nje-b2b-frontend-resource-library-addproduct-cancel"
        >Cancel</button
      >
    </maia-button-group>
  </maia-form-group>
</form>
