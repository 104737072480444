import {Injectable} from '@angular/core';

import {TravelWorkflow} from '../travel-workflow/TravelWorkflow';
import {Date as Date_2, ValidationErrors} from '@atlas/businesstypes';
import {TravelProcessService} from './travel-process.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  InsuredPerson,
  DateFormatterService,
  PartyType,
  PartyTypeValue,
  BLANK_VALUE,
  PartyIdService,
  personName,
  ASSET_PATH,
  EgnValidatorService,
} from '@b2b-frontend/core';
import {PhoneNumberValue} from '@maia/input-phonenumber';
import {FormGroup, FormControl, Validators, ValidatorFn, AbstractControl} from '@angular/forms';
import * as XLSX from 'xlsx';
import {HttpClient} from '@angular/common/http';
import {FileData} from '@b2b-frontend/core/src/core-helper/dto/travel-dto/TravelInsuredPersonDto';

@Injectable()
export class TravelPolicyService {
  public showHideAccordianSubject = new BehaviorSubject<boolean>(false);
  public showHideAccordian: Observable<boolean> = this.showHideAccordianSubject;
  public file: File;

  public constructor(
    public process: TravelProcessService,
    public dateFormateService: DateFormatterService,
    private readonly _partyIdService: PartyIdService,
    private readonly _httpClient: HttpClient,
    private readonly _ageCalcService: EgnValidatorService,
  ) {
    this.process.currentWorkflow = TravelWorkflow.TRAVEL_POLICY;
  }

  public showPriceAccordion(show: boolean): void {
    this.showHideAccordianSubject.next(show);
  }

  public getPhoneNumber(person: InsuredPerson): string {
    if (typeof person.phoneNumber === 'string') {
      return person.phoneNumber;
    } else if (typeof person.phoneNumber === 'number') {
      return person.phoneNumber;
    } else {
      const phone: PhoneNumberValue = person.phoneNumber;
      return phone.number;
    }
  }

  public getDOB(person: InsuredPerson): string | undefined {
    if (person.partyType === PartyType.FOREIGNER) {
      return person.dob !== null && person.dob !== undefined && person.dob instanceof Date_2
        ? this.dateFormateService.getDDMMYYYFormatDateString(person.dob)
        : person.dob;
    }
    return BLANK_VALUE;
  }

  public getCitizenship(person: InsuredPerson): string {
    return person && person.partyType === PartyType.FOREIGNER && person.citizenship
      ? person.citizenship
      : '-';
  }

  public calculateAge(birthday: Date): number {
    const ageDif = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDif);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  public getEGNEIKLNCHType(type: any) {
    let partyType = BLANK_VALUE;
    switch (type) {
      case PartyType.PERSON:
        partyType = PartyTypeValue.EGN;
        break;
      case PartyType.COMPANY:
        partyType = PartyTypeValue.EIK;
        break;
      case PartyType.FOREIGNER:
        partyType = PartyTypeValue.LNCH;
        break;
    }
    return partyType;
  }

  public setUploadedFile(arg0: File): void {
    this.file = arg0;
  }

  public getUploadedFile(): File {
    return this.file;
  }

  public onDownloadFile(): void {
    const targetFile = this.getUploadedFile();
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(targetFile);

    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}

      if (data && data.length > 0) {
        const filteredList = data.filter(
          (book: any) =>
            book["Insured person's PID"] &&
            book["Insured person's PID"] !== undefined &&
            book["Insured person's PID"] != null,
        );
        const js = XLSX.utils.json_to_sheet(filteredList);
        XLSX.utils.book_append_sheet(wb, js, '');
        const fileName: string = targetFile ? targetFile.name : 'sample';
        XLSX.writeFile(wb, `./downloads/${fileName}.xlsx`);
      }
    };
  }

  public parseInusredPeopleFilterList(filteredList: unknown[]): InsuredPerson[] {
    const insurePersonList: InsuredPerson[] = [];
    filteredList.forEach((data: FileData) => {
      const idNumber =
        data["Insured person's PID"] != null
          ? `${data["Insured person's PID"]}${BLANK_VALUE}`
          : BLANK_VALUE;
      const insurePerson: InsuredPerson = {
        idNumber: idNumber,
        firstName: data.Cyrillic != null ? data.Cyrillic : BLANK_VALUE,
        middleName: data.__EMPTY_2 != null ? data.__EMPTY_2 : BLANK_VALUE,
        lastName: data.__EMPTY_3 != null ? data.__EMPTY_3 : BLANK_VALUE,
        firstNameEn: data.Latin != null ? data.Latin : BLANK_VALUE,
        middleNameEn: data.__EMPTY != null ? data.__EMPTY : BLANK_VALUE,
        lastNameEn: data.__EMPTY_1 != null ? data.__EMPTY_1 : BLANK_VALUE,
        phoneNumber: data.Contact != null ? `${data.Contact}${BLANK_VALUE}` : '',
        citizenship:
          data['Citizenship (If different from BG)'] != null
            ? data['Citizenship (If different from BG)']
            : BLANK_VALUE,
        partyType:
          data["Insured person's PID"] != null
            ? this._partyIdService.getType(`${data["Insured person's PID"]}${BLANK_VALUE}`)
            : '',
        dob:
          data['DOB (For non BG citizens in DD.MM.YY format)'] != null
            ? this.dateFormateService.getDDMMYYYYFormatDate(
                data['DOB (For non BG citizens in DD.MM.YY format)'],
              )
            : '',
        age:
          data['DOB (For non BG citizens in DD.MM.YY format)'] != null
            ? this.ageCalculation(data['DOB (For non BG citizens in DD.MM.YY format)'])
            : this.findEGNAge(idNumber),
      };
      insurePersonList.push(insurePerson);
    });
    return insurePersonList;
  }

  public findEGNAge(idNumber: string): number {
    if (idNumber != null) {
      const birthdate = this._ageCalcService.getDateFromEGN(idNumber);
      return birthdate != null ? this.calculateAge(birthdate) : 0;
    }
    return 0;
  }

  public ageCalculation(birthdate: Date): number {
    const timeDiff = Math.abs(Date.now() - birthdate.getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  }

  public updateInsuredPeopleArray(element: InsuredPerson): FormGroup {
    const partyType = this._partyIdService.getType(element.idNumber);
    return new FormGroup({
      idNumber: new FormControl(element.idNumber, [
        Validators.required,
        this.idNumberValidator.bind(this),
      ]),
      firstName: new FormControl(
        element.firstName,
        partyType === PartyType.FOREIGNER
          ? [Validators.required, personName('bgoren')]
          : [Validators.required, personName('bg')],
      ),
      middleName: new FormControl(
        element.middleName,
        partyType === PartyType.FOREIGNER
          ? [Validators.required, personName('bgoren')]
          : [Validators.required, personName('bg')],
      ),
      lastName: new FormControl(
        element.lastName,
        partyType === PartyType.FOREIGNER
          ? [Validators.required, personName('bgoren')]
          : [Validators.required, personName('bg')],
      ),
      firstNameEn: new FormControl(
        element.firstNameEn,
        partyType === PartyType.FOREIGNER
          ? [Validators.required, personName('bgoren')]
          : [Validators.required, personName('en')],
      ),
      middleNameEn: new FormControl(
        element.middleNameEn,
        partyType === PartyType.FOREIGNER
          ? [Validators.required, personName('bgoren')]
          : [Validators.required, personName('en')],
      ),
      lastNameEn: new FormControl(
        element.lastNameEn,
        partyType === PartyType.FOREIGNER
          ? [Validators.required, personName('bgoren')]
          : [Validators.required, personName('en')],
      ),
      phoneNumber: new FormControl(element.phoneNumber, [Validators.required]),
      citizenship: new FormControl(
        element.citizenship,
        partyType === PartyType.FOREIGNER ? [Validators.required] : null,
      ),
      dob: new FormControl(element.dob, [this.dobValidator(element.idNumber)]),
    });
  }

  private idNumberValidator(control: FormControl) {
    const egnEikLnchFrmCtrl = this._partyIdService.getType(control.value);
    if (!egnEikLnchFrmCtrl || egnEikLnchFrmCtrl === PartyType.COMPANY) {
      return {invalidEgnLnchNumber: true};
    }
    return null;
  }

  public dobValidator(idNumber: string): ValidatorFn {
    const partyType = this._partyIdService.getType(idNumber);
    return (control: AbstractControl): ValidationErrors | null => {
      if (partyType === PartyType.PERSON && !control.value) {
        return null;
      }

      if (partyType === PartyType.FOREIGNER && !control.value) {
        return {isRequired: true};
      }

      const isValid = this.dateFormateService.isValidDOB(
        control.value.split('-').reverse().join('/'),
      );

      if (partyType === PartyType.FOREIGNER && !isValid) {
        return {inValidDob: true};
      }
      return null;
    };
  }

  public downloadSampleXls(): void {
    this._httpClient
      .get(ASSET_PATH.INSURED_PEOPLE_LIST_DOC_PDF, {responseType: 'blob'})
      .subscribe((fileData: any) => {
        const reader: FileReader = new FileReader();
        let dataJson1;
        reader.readAsBinaryString(fileData);
        reader.onload = (e: any) => {
          const bstr: string = e.target.result;
          const wb: XLSX.WorkBook = XLSX.read(bstr, {type: 'binary'});

          /* grab first sheet */
          const wsname1: string = wb.SheetNames[0];
          const ws1: XLSX.WorkSheet = wb.Sheets[wsname1];

          /* save data */
          dataJson1 = XLSX.utils.sheet_to_json(ws1);

          const js = XLSX.utils.json_to_sheet(dataJson1);
          XLSX.utils.book_append_sheet(wb, js, '');
          XLSX.writeFile(wb, './downloads/sample.xlsx');
        };
      });
  }
}
