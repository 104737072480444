import {Decimal} from '@atlas/businesstypes';
import {ModalResolution} from '@maia/modals';
import {
  AccordionList,
  Amount,
  InsuredDetails,
  InsuredHeaders,
  ObjectName,
  ObjectType,
  SupplementaryMessage,
  Widget,
} from '../../../contracts/property-insurance.interface';

const description = 'Total Theft Cover Limit Household Property';
const insuredescription = 'Total Insured Value for Pieces of Art';
const value = '28500,00 BGN';
const objtype = 'Pieces of Art';

export const MOCK_SUPPLEMENTARY_MESSAGE: SupplementaryMessage[] = [
  {
    supplementaryMessage1:
      '1. Supplementary items for your household property with a theft cover limit of more than 20 000 BGN are obligatory, kindly fill it to proceed.',
    supplementaryMessage2:
      '\n2. Supplementary items for insured pieces of art with insured value of more than 2 000 BGN are obligatory, kindly fill it to proceed.',
    tooltipMessage:
      'Please add supplementary items for your Household Property with total Theft Cover limit of 28500,00 BGN' +
      '.' +
      '\n' +
      'Please add supplementary items for your Pieces of Art up to the insured value of 48000,00 BGN',
  },
];

export const MOCK_ACCORDION_LIST: AccordionList[] = [
  {
    id: 1,
    header: '11, Deseta Str., Kranso Selo, 4462',
    propertydesc: description + '\xa0\xa0\xa0\xa0\xa0\xa0' + value,
    insuredesc: insuredescription + '\xa0\xa0\xa0\xa0\xa0\xa0' + value,
  },
  {
    id: 2,
    header: '25C, Svetovrachene, Quarter, Sofia',
    propertydesc: description + '\xa0\xa0\xa0\xa0\xa0\xa0' + value,
    insuredesc: insuredescription + '\xa0\xa0\xa0\xa0\xa0\xa0' + value,
  },
  {
    id: 3,
    header: '49D, Dolni Bogrov, Quarter, Sofia',
    propertydesc: description + '\xa0\xa0\xa0\xa0\xa0\xa0' + value,
    insuredesc: insuredescription + '\xa0\xa0\xa0\xa0\xa0\xa0' + value,
  },
  {
    id: 4,
    header: '44B, Malo Buchino, Quarter, Sofia',
    propertydesc: description + '\xa0\xa0\xa0\xa0\xa0\xa0' + value,
    insuredesc: insuredescription + '\xa0\xa0\xa0\xa0\xa0\xa0' + value,
  },
];
export const INSURED_PARTY_COL_HEADERS: InsuredHeaders[] = [
  {
    header: 'Object Name',
  },
  {
    header: 'Object Type',
  },
  {
    header: 'Insured Value',
  },
];
export const INSURANCE_PARTY_DETAILS_LIST: InsuredDetails[] = [
  {
    objectName: 'TV',
    objectType: 'Household Property',
    insuredValue: '28500,00 BGN',
    brandModel: '',
    otherSpecifics: '',
    authorArt: '',
    author: '',
    size: '',
    yearOfCreation: '',
  },
  {
    objectName: 'Painting',
    objectType: objtype,
    insuredValue: '26000,00 BGN',
    brandModel: '',
    otherSpecifics: '',
    authorArt: '',
    author: '',
    size: '',
    yearOfCreation: '',
  },
  {
    objectName: 'WoodWork',
    objectType: objtype,
    insuredValue: '24000,00 BGN',
    brandModel: '',
    otherSpecifics: '',
    authorArt: '',
    author: '',
    size: '',
    yearOfCreation: '',
  },
];

export const OBJECT_TYPE: ObjectType[] = [
  {
    objectType: 'Household Property',
  },
  {
    objectType: 'Pieces of Art',
  },
];

export const OBJECT_NAME: ObjectName[] = [
  {
    objectName: 'Paintings',
  },
  {
    objectName: 'Statuettes',
  },
  {
    objectName: 'Woodwork',
  },
  {
    objectName: 'Others',
  },
];

export const PROPERTY_PREMIUM_PRICE: Amount = {
  value: new Decimal('880.50'),
  currency: 'BGN',
};

export const WIDGET_TITLE: Widget = {
  title: 'Total Amount(Incl. of taxes)',
};

export const MOCK_MODAL_RESULT = {
  resolution: ModalResolution.CONFIRMED,
};
