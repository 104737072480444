import {Component, OnInit} from '@angular/core';
import {Decimal, Boolean} from '@atlas/businesstypes';
import {ModalContentComponent, ModalControl} from '@maia/modals';
import {SlideIn} from '../../../models/AdditionalCover';

@Component({
  selector: 'nje-additional-covers-risks-slidein',
  templateUrl: './additional-covers-risks-slidein.component.html',
  styleUrls: ['./additional-covers-risks-slidein.component.scss'],
})
export class AdditionalCoversRisksSlideinComponent
  extends ModalContentComponent<number, undefined | {isDirty?: boolean}>
  implements OnInit
{
  public transferAmount = new Decimal('5.00');
  public slideInGroup: SlideIn[];
  public sum = 0;
  public checkNum: Boolean;
  public checkNum1: Boolean;
  public checkNum2: Boolean;
  public checkNum3: Boolean;
  public checkNum4: Boolean;
  public checkNum5: Boolean;
  public checkNum6: Boolean;
  public constructor(public control: ModalControl<number, undefined | {isDirty?: boolean}>) {
    super();
  }

  public ngOnInit(): void {
    const data = this.control.input;
    console.log(data);

    // this.slideInGroup =[
    //   {
    //     description:' Emergency unlocking of the vehicle ',
    //     amount:new Decimal('5.00'),
    //     currency:'BGN',
    //   },
    //   {
    //     description:' Emergency unlocking  ',
    //     amount:new Decimal('5.00'),
    //     currency:'BGN',
    //   },
    //   {
    //     description:' unlocking of the vehicle ',
    //     amount:new Decimal('5.00'),
    //     currency:'BGN',
    //   },
    //   {
    //     description:'  vehicle ',
    //     amount:new Decimal('5.00'),
    //     currency:'BGN',
    //   },
    // ]
  }

  public saveRisk(): Boolean {
    if (Boolean.TRUE.equals(this.checkNum)) {
      this.totalAmount();
    }
    if (Boolean.FALSE.equals(this.checkNum)) {
      this.Amount();
    }
    return this.checkNum;
  }

  public saveRisk1(): Boolean {
    if (Boolean.TRUE.equals(this.checkNum1)) {
      this.totalAmount();
    }
    if (Boolean.FALSE.equals(this.checkNum1)) {
      this.Amount();
    }
    return this.checkNum1;
  }

  public saveRisk2(): Boolean {
    if (Boolean.TRUE.equals(this.checkNum2)) {
      this.totalAmount();
    }
    if (Boolean.FALSE.equals(this.checkNum2)) {
      this.Amount();
    }
    return this.checkNum2;
  }

  public saveRisk3(): Boolean {
    if (Boolean.TRUE.equals(this.checkNum3)) {
      this.totalAmount();
    }
    if (Boolean.FALSE.equals(this.checkNum3)) {
      this.Amount();
    }
    return this.checkNum3;
  }

  public saveRisk4(): Boolean {
    if (Boolean.TRUE.equals(this.checkNum4)) {
      this.totalAmount();
    }
    if (Boolean.FALSE.equals(this.checkNum4)) {
      this.Amount();
    }

    return this.checkNum4;
  }

  public saveRisk5(): Boolean {
    if (Boolean.TRUE.equals(this.checkNum5)) {
      this.totalAmount();
    }
    if (Boolean.FALSE.equals(this.checkNum5)) {
      this.Amount();
    }

    return this.checkNum5;
  }

  public saveRisk6(): Boolean {
    if (Boolean.TRUE.equals(this.checkNum6)) {
      this.totalAmount();
    }
    if (Boolean.FALSE.equals(this.checkNum6)) {
      this.Amount();
    }
    return this.checkNum6;
  }

  public totalAmount(): void {
    this.sum = this.sum + 5;
  }

  public Amount(): void {
    this.sum = this.sum - 5;
  }

  public save(): void {
    const data = this.sum;
    this.control.confirm(data);
  }
}
