<div>
  <form maiaForm [formGroup]="additionalCoversDetailsForm.formGroup">
    <div class="cover-changes-msg background-box" *ngIf="existingCover"
      >You have already selected the same Additonal Cover for this address!</div
    >
    <maia-form-group>
      <maia-form-element>
        <label class="b2b-kdl-font-small title-dark-color"> Additional Cover </label>
        <maia-input-select
          name="coverName"
          formControlName="coverName"
          (ngModelChange)="changeCoversType()"
        >
          <input
            maiaInput
            placeholder="Select"
            [value]="additionalCoversDetailsForm.controls.coverName.value"
          />
          <maia-option *ngFor="let cover of additional_cover_list" [value]="cover.coverName">
            {{ cover.coverName }}
          </maia-option>
        </maia-input-select>
      </maia-form-element>
      <maia-form-element
        hideOptionalIndicator
        [class.p-maia-form-element--validation-error]="getAggregateLimitFieldErrors()"
      >
        <label class="b2b-kdl-font-small title-dark-color"> Aggregate Limit </label>
        <p
          class="min-amount-p b2b-kdl-font-small-light"
          *ngIf="additionalCoversDetailsForm.controls.coverName.valid"
          >Min amount {{ defaultObj!.aggregateLimit!.min! }} {{ currencySelected }}</p
        >
        <maia-input-group
          [class.p-maia-form-element--show-validation]="getAggregateLimitFieldErrors()"
        >
          <input
            maiaInputNumber
            formControlName="aggregateLimit"
            name="aggregateLimit"
            placeholder="Amount"
          />
          <maia-input-group-suffix>{{ currencySelected }}</maia-input-group-suffix>
        </maia-input-group>
        <div class="validation-container" *ngIf="getAggregateLimitFieldErrors()">
          <maia-validation
            type="error"
            *ngIf="additionalCoversDetailsForm.controls?.aggregateLimit?.errors?.required"
          >
            Insured Value required
          </maia-validation>
          <maia-validation
            type="error"
            *ngIf="additionalCoversDetailsForm.controls?.aggregateLimit?.errors?.min"
          >
            Insured Value cannot be less than {{ defaultObj!.aggregateLimit!.min! }}
            {{ currencySelected }}
          </maia-validation>
          <maia-validation
            type="error"
            *ngIf="additionalCoversDetailsForm.controls?.aggregateLimit?.errors?.max"
          >
            Insured Value cannot be more than {{ defaultObj!.aggregateLimit!.max! }}
            {{ currencySelected }}
          </maia-validation>
        </div>
      </maia-form-element>

      <maia-form-element
        hideOptionalIndicator
        [class.p-maia-form-element--validation-error]="getLimitPerOccurenceFieldErrors()"
      >
        <label class="b2b-kdl-font-small title-dark-color">Limit Per Occurance </label>
        <p class="min-amount-p b2b-kdl-font-small-light" *ngIf="this.defaultObj !== undefined"
          >Min amount {{ defaultObj!.limitPerOccurence!.min! }} {{ currencySelected }}</p
        >
        <maia-input-group
          [class.p-maia-form-element--show-validation]="getLimitPerOccurenceFieldErrors()"
        >
          <input
            maiaInputNumber
            formControlName="limitPerOccurence"
            name="limitPerOccurence"
            placeholder="Limit"
          />
          <maia-input-group-suffix>{{ currencySelected }}</maia-input-group-suffix>
        </maia-input-group>
        <div class="validation-container" *ngIf="getLimitPerOccurenceFieldErrors()">
          <maia-validation
            type="error"
            *ngIf="additionalCoversDetailsForm.controls?.limitPerOccurence?.errors?.required"
          >
            Insured Value required
          </maia-validation>
          <maia-validation
            type="error"
            *ngIf="additionalCoversDetailsForm.controls?.limitPerOccurence?.errors?.min"
          >
            Insured Value cannot be less than {{ defaultObj!.limitPerOccurence!.min! }}
            {{ currencySelected }}
          </maia-validation>
          <maia-validation
            type="error"
            *ngIf="additionalCoversDetailsForm.controls?.limitPerOccurence?.errors?.max"
          >
            Insured Value cannot be more than {{ defaultObj!.limitPerOccurence!.max! }}
            {{ currencySelected }}
          </maia-validation>
        </div>
      </maia-form-element>
    </maia-form-group>
  </form>
  <maia-button-group class="add-button">
    <button
      class="m-t-b-24"
      maiaButton
      maiaButtonType="primary"
      [disabled]="!additionalCoversDetailsForm.valid"
      (click)="addOrUpdatePropertyCovers()"
    >
      <span>{{ propertyCoversInfo === undefined ? addButton : saveButton }}</span>
    </button>
    <button class="m-t-b-24" maiaButton maiaButtonType="secondary" (click)="cancelSlideIn()"
      >Cancel</button
    >
  </maia-button-group>
</div>
