<div class="account-suspension-wrapper">
  <maia-label class="b2b-kdl-font-medium-bold title-dark-color"> Account Suspension </maia-label>

  <nje-search-box (searchData)="searchAccounts($event)"> </nje-search-box>

  <div *ngIf="isDataLoaded && userAccountDetails?.id">
    <maia-divider class="m-t-b-24"></maia-divider>
    <nje-account-details [userDetails]="userAccountDetails"> </nje-account-details>
  </div>

  <div class="m-t-24" *ngIf="isDataLoaded && userAccountDetails?.id">
    <nje-account-suspension-form
      #suspendAccountForm
      (isAccountSuspensionFormValid)="isFormValid = $event"
    ></nje-account-suspension-form>
  </div>

  <maia-label class="no-account-present m-t-24" *ngIf="isDataLoaded && !userAccountDetails?.id">
    No such account exists. Kindly fill in the correct data.
  </maia-label>

  <maia-button-group class="m-t-b-24 suspend-button" *ngIf="isDataLoaded && userAccountDetails?.id">
    <button
      maiaButton
      maiaButtonType="primary"
      [disabled]="!isFormValid"
      (click)="suspendAccount()"
    >
      Suspend Account
    </button>
    <button maiaButton maiaButtonType="secondary" (click)="cancelSuspension()">Cancel</button>
  </maia-button-group>
</div>
