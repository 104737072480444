import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprQrSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendInformationExprQr]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path fill="none" stroke-miterlimit="10" d="M19.2 19.2H6.4V6.4h12.8z" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M41.6 35.2v-6.4h-8v-2.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M41.6 41.6H28.8" stroke-width="1.6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M14.4 14.4h-3.2v-3.2h3.2v3.2z" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M41.6 19.2H28.8V6.4h12.8z" stroke-width="1.6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M36.8 14.4h-3.2v-3.2h3.2v3.2z" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M19.2 41.6H6.4V28.8h12.8z" stroke-width="1.6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M14.4 36.8h-3.2v-3.2h3.2v3.2z" stroke-width="1.6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M36.8 36.8h-3.2v-3.2h3.2v3.2z" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M24.8 37.6v-3.2H28v-8" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M18.4 24.8v-1.6H24v3.2" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M22.4 13.6h2.4v-4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M39.2 24v4.8" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M6.4 22.4V24h4.8" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprQrSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprQrSvgComponent,
  ],
  exports: [
    InformationExprQrSvgComponent,
  ],
})
export class InformationExprQrSvgModule {}
