import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public constructor() {}

  private readonly data: Subject<any> = new Subject<any>();

  public setDetails(details: any) {
    this.data.next(details);
  }

  public getDetails() {
    return this.data.asObservable();
  }
}
