import {NgModule, ModuleWithProviders, Inject, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';

import {
  InstalmentPaymentsStarterService,
  BASE_ROUTE,
} from './payments-core/services/starter.service';
import {PaymentsComponent} from './payments.component';
import {PaymentsRoutingModule} from './payments.routing.module';

@NgModule({
  declarations: [PaymentsComponent],
  imports: [CommonModule, PaymentsRoutingModule],
  providers: [InstalmentPaymentsStarterService],
})
export class InstalmentPaymentsModule {
  public static forPath(path: string): ModuleWithProviders<InstalmentPaymentsModule> {
    return {
      ngModule: InstalmentPaymentsModule,
      providers: [{provide: BASE_ROUTE, useValue: path}],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string[]) {
    if (baseRoute == null) {
      throw new Error(
        'Do not import InstalmentPaymentsModule directly, use InstalmentPaymentsModule.forPath() instead',
      );
    }
  }
}
