import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {PolicyNumberValidatorService} from '../../services/policy-number.service';

export function policyNumberValidator(partyId: PolicyNumberValidatorService): ValidatorFn {
  return function (control: AbstractControl): ValidationErrors | null {
    if (control.value && !partyId.getType(control.value)) {
      return {invalidNumber: true};
    }
    return null;
  };
}
