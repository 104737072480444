import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';

import {USER_MANAGEMENT} from '../../constants/account-management';
import {RoutePaths} from '../../constants/route-paths';
import {AccountCreationComponent} from './account-creation/account-creation.component';
import {AccountStatusesComponent} from './account-statuses/account-statuses.component';
import {AccountSuspensionComponent} from './account-suspension/account-suspension.component';
import {ActivateAccountComponent} from './activate-account/activate-account.component';
import {DeactivateAccountComponent} from './deactivate-account/deactivate-account.component';
import {GroupsAndModulesComponent} from './groups-and-modules/groups-and-modules.component';
import {NewGroupComponent} from './groups-and-modules/new-group/new-group.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: RoutePaths.ACTIVATE_ACCOUNT,
        component: ActivateAccountComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: RoutePaths.DEACTIVATE_ACCOUNT,
        component: DeactivateAccountComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: RoutePaths.SUSPEND_ACCOUNT,
        component: AccountSuspensionComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: RoutePaths.RESET_PASSWORD,
        component: ResetPasswordComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: RoutePaths.CREATE_ACCOUNT,
        component: AccountCreationComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: 'groups-and-modules',
        component: GroupsAndModulesComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: 'new-group',
        component: NewGroupComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: RoutePaths.ACCOUNT_STATUS,
        component: AccountStatusesComponent,
        data: {
          njeB2CTitle: USER_MANAGEMENT,
        },
      },
      {
        path: '',
        redirectTo: RoutePaths.ACTIVATE_ACCOUNT,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class B2bRoutingModule {}

export const B2B_ROUTE: Route = {
  path: '',
  loadChildren: async () => await import('./b2b.module').then(module => module.B2bModule),
};
