/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Boolean, PhoneNumber} from '@atlas/businesstypes';
import {bgPhoneNumberValidator, CompanyDataDto, MtplPersonalData} from '@b2b-frontend/core';
import {InputPhonenumberComponent, PhoneNumberValue} from '@maia/input-phonenumber';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  PhoneNumberValueController,
} from '@njf-frontend-angular/flow-progress';
import {BLANK_VALUE, COUNTRY_CODE, INSURING_DETAILS} from '../../../mtpl-constants.ts/constant';
import {PhoneNumberModel} from './_tests_/phone-number-model';

@Component({
  selector: 'nje-input-phone-number',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./input-phone-number.component.scss'],
})
export class InputPhoneNumberComponent implements OnInit {
  public phone: PhoneNumberValue;
  private _phoneNumberController: PhoneNumberValueController;
  public bgValidation: boolean = false;

  public phoneNumberForm: FormGroupManager<PhoneNumberModel> =
    this._formFactory.createFormManager<PhoneNumberModel>({
      phoneNumber: new FormControl(undefined, [bgPhoneNumberValidator({allowZero: true})]),
    });

  @ViewChild('phoneInput', {static: false})
  protected set phoneInput(val: InputPhonenumberComponent) {
    setTimeout(
      () =>
        (this._phoneNumberController = this.phoneNumberForm.createPhoneNumberValue(
          'phoneNumber',
          val,
        )),
    );
  }

  @Input() public sameDetails: Boolean;
  @Input() public flexDirection: string = INSURING_DETAILS.ROW;
  @Input()
  public set phoneNumber(data: PhoneNumberValue) {
    this.editSlideIn(data);
  }

  @Input()
  public set retrievedAddInsuringPhone(data: MtplPersonalData) {
    this.populateRetrievedAddInsuringForm(data);
  }

  @Input()
  public set retrievedVehicleOwnerPhone(data: MtplPersonalData) {
    this.populateRetrievedData(data);
  }

  @Input()
  public set retrievedPhone(data: MtplPersonalData) {
    this.populatePhoneNumber(data);
  }

  @Input()
  public set retrievedCompanyPhone(data: CompanyDataDto) {
    this.populateCompanyPhoneNumber(data);
  }

  @Input()
  public set retrievedSlideInPhone(data: CompanyDataDto) {
    this.populateCompanyPhoneNumber(data);
  }

  @Input()
  public set retrievedVehicleCompanyPhone(data: CompanyDataDto) {
    this.populateCompanyPhoneNumber(data);
  }

  @Input()
  public set inputPhoneNumber(data: PhoneNumberValue) {
    this.phone = data;
    this._editPhoneNumber();
  }

  @Output() public readonly selectPhoneNumber = new EventEmitter();

  public constructor(private readonly _formFactory: FormGroupManagerFactoryService) {}

  public ngOnInit(): void {
    this.phoneNumberForm.controls.phoneNumber.valueChanges.subscribe((data: any) => {
      if (data instanceof PhoneNumber) {
        const s: string = data.asString().substring(0, 4);
        this.bgValidation = s === COUNTRY_CODE;
      }
      this.updatePhoneNumber(data);
    });
  }

  private _editPhoneNumber(): void {
    const data = this.phone;
    if (Boolean.TRUE.equals(this.sameDetails)) {
      this.phoneNumberForm.patchValue({
        phoneNumber: data,
      });
      this.phoneNumberForm.controls.phoneNumber.disable();
    } else {
      this.phoneNumberForm.controls.phoneNumber.enable();
      if (this._phoneNumberController) {
        this._phoneNumberController.value = {number: BLANK_VALUE, prefix: COUNTRY_CODE};
      }
    }
  }

  public editSlideIn(data: PhoneNumberValue): void {
    if (data) {
      this.phoneNumberForm.patchValue({
        phoneNumber: data,
      });
    }
  }

  public populatePhoneNumber(data: MtplPersonalData): void {
    this.populateForm(data);
  }

  public populateRetrievedData(data: MtplPersonalData): void {
    this.populateForm(data);
  }

  public populateRetrievedAddInsuringForm(data: MtplPersonalData): void {
    this.populateForm(data);
  }

  public populateForm(data: MtplPersonalData): void {
    if (data) {
      this.phoneNumberForm.patchValue({
        phoneNumber: data.personalData.addresses
          ? data.personalData.addresses[0].phoneNumber
          : BLANK_VALUE,
      });
    }
  }

  public populateCompanyPhoneNumber(data: CompanyDataDto): void {
    if (data) {
      this.phoneNumberForm.patchValue({
        phoneNumber: data.phone ? data.phone : BLANK_VALUE,
      });
    }
  }

  public updatePhoneNumber(value: PhoneNumberValue): void {
    this.selectPhoneNumber.emit(value);
  }
}
