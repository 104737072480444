export enum TravelRoutePaths {
  TI = 'travel',
  TI_TRIP_DETAILS = 'trip-details',
  TI_TRAVEL_COVERS = 'travel-covers',
  TI_ADD_ONS = 'travel-add-ons',
  TI_INSURING_PARTY = 'travel-insuring-party',
  TI_INSURED_PEOPLE = 'insured-people',
  TI_TRAVEL_PROPOSAL = 'travel-proposal',
  TI_TRAVEL_SUCCESS = 'travel-policy-success',
  TI_TRAVEL_ERROR = 'travel-policy-error',
  TI_RENEW_POLICY = 'travel-renew-policy',
}
