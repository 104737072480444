<njf-loader [hidden]="!showLoadingIcon"></njf-loader>
<form maiaForm>
  <maia-fluid-fluid-layout allignContentTop>
    <maia-tile-document-sign id="documentSignTileOne" class="document-tile-wrapper">
      <maia-tile-document-sign-icon>
        <svg class="maia-icon url_field_color" b2bFrontendNavigationFuncLiveHelp></svg>
      </maia-tile-document-sign-icon>
      <maia-tile-document-sign-text class="url_text b2b-kdl-font-normal-bold">{{
        url
      }}</maia-tile-document-sign-text>
      <maia-tile-document-sign-label>
        <button
          maiaButton
          class="edit-font"
          (click)="editUrl()"
          i18n="@@nje-b2b-frontend-resource-contacts-edit"
          >Edit</button
        >
      </maia-tile-document-sign-label>
    </maia-tile-document-sign>
  </maia-fluid-fluid-layout>
  <div class="add-contact">
    <div
      class="page-info b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-resource-contacts-contact"
    >
      Contacts
    </div>
    <div class="btn-align">
      <div class="add-contact-icon">
        <svg b2bFrontendActionFuncPlusCircle></svg>
      </div>

      <button
        maiaButton
        maiaButtonType="tertiary"
        (click)="addContact('contact')"
        i18n="@@nje-b2b-frontend-resource-contacts-addcontact"
        ><span class="b2b-kdl-font-small"> Add contact</span>
      </button>
    </div>
  </div>
  <maia-fluid-fluid-layout>
    <maia-form-element class="input-column" hideOptionalIndicator>
      <maia-tile
        class="m-t-b-24 tile-size-contact"
        *ngFor="let each_contact of contacts; let i = index"
      >
        <maia-tile-items>
          <maia-tile-item>
            <div class="contact-communication">
              <div>
                <div *ngIf="isLanguageEnglish()"
                  ><label class="contacts_name b2b-kdl-font-small">{{
                    each_contact?.i18n?.en?.title
                  }}</label></div
                >
                <div *ngIf="!isLanguageEnglish()"
                  ><label class="contacts_name b2b-kdl-font-small">{{
                    each_contact?.i18n?.bg?.title
                  }}</label></div
                >
                <div
                  ><label class="contacts_ref b2b-kdl-font-small-light">
                    {{ each_contact.phoneNumber }}</label
                  ></div
                >
              </div>
              <div class="communication-icon">
                <maia-avatar-display>
                  <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
                    <svg class="icon-primary-color" b2bFrontendCommunicationFuncPhone></svg>
                  </maia-avatar-svg>
                </maia-avatar-display>
              </div>
            </div>
          </maia-tile-item>
          <maia-tile-item class="btn-wrapper">
            <maia-inline-action
              class="edit_text"
              id="inline-action-1"
              type="button"
              role="button"
              (click)="addContact('contact', each_contact)"
              i18n="@@nje-b2b-frontend-resource-contacts-contactedit"
            >
              Edit
            </maia-inline-action>
            <maia-inline-action
              class="remove_text"
              id="inline-action-2"
              type="button"
              role="button"
              (click)="openRemovePopup(i, each_contact.id, 'contact')"
              i18n="@@nje-b2b-frontend-resource-contacts-remove"
            >
              Remove
            </maia-inline-action>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
    </maia-form-element>
  </maia-fluid-fluid-layout>
  <div class="add-contact">
    <div
      class="page-info b2b-kdl-font-small title-dark-color"
      i18n="@@nje-b2b-frontend-resource-contacts-support"
    >
      Technical Support
    </div>
    <div class="btn-align">
      <div class="add-contact-icon">
        <svg b2bFrontendActionFuncPlusCircle></svg>
      </div>
      <div class="add-contact-button">
        <button
          maiaButton
          maiaButtonType="tertiary"
          (click)="addContact('incident')"
          i18n="@@nje-b2b-frontend-resource-contacts-addincident"
          ><span class="b2b-kdl-font-small"> Add Incident</span></button
        >
      </div>
    </div>
  </div>
  <maia-fluid-fluid-layout>
    <maia-form-element class="input-column" hideOptionalIndicator>
      <maia-tile
        class="m-t-b-24 tile-size-incident"
        *ngFor="let support of technicalSupports; let i = index"
      >
        <maia-tile-items>
          <maia-tile-item>
            <label *ngIf="isLanguageEnglish()" class="contacts_name b2b-kdl-font-small">{{
              support?.i18n?.en?.title
            }}</label>
            <label *ngIf="!isLanguageEnglish()" class="contacts_name b2b-kdl-font-small">{{
              support?.i18n?.bg?.title
            }}</label>
            <label class="contacts_ref incident-col b2b-kdl-font-small-light">
              {{ support.email }}</label
            >
          </maia-tile-item>
          <maia-tile-item class="btn-wrapper">
            <maia-inline-action
              class="edit_text"
              id="inline-action-3"
              type="button"
              role="button"
              (click)="addContact('incident', support, i)"
              i18n="@@nje-b2b-frontend-resource-contacts-incidentedit"
            >
              Edit
            </maia-inline-action>
            <maia-inline-action
              class="remove_text"
              id="inline-action-4"
              type="button"
              role="button"
              (click)="openRemovePopup(i, support.id, 'incident')"
              i18n="@@nje-b2b-frontend-resource-contacts-remove"
            >
              Remove
            </maia-inline-action>
          </maia-tile-item>
        </maia-tile-items>
      </maia-tile>
    </maia-form-element>
  </maia-fluid-fluid-layout>
</form>
