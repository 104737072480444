import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {PropertyWorkflow} from '../property-workflow/property-workflow';

import {PropertyProcessService} from './property-process.service';

@Injectable()
export class PropertyPolicyService {
  public showHideAccordianSubject = new BehaviorSubject<boolean>(false);
  public showHideAccordian: Observable<boolean> = this.showHideAccordianSubject;

  constructor(public process: PropertyProcessService) {
    this.process.currentWorkflow = PropertyWorkflow.PropertyHomePolicy;
  }

  public showPriceAccordion(show: boolean): void {
    this.showHideAccordianSubject.next(show);
  }
}
