import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {FormControl, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DateKey, DateUtils, ExtraDateValidators} from '@atlas/businesstypes';
import {DatepickerVisibleView} from '@maia/datepickers';
import {InputPhonenumberComponent, PhoneNumberValue} from '@maia/input-phonenumber';
import {
  CityAndPostCodeController,
  DatePickerController,
  FormGroupManagerFactoryService,
  PhoneNumberValueController,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';
import {ServerSideAutocompleteController} from '@njf-frontend-angular/flow-progress/types/form-manage/component/controller/ServerSideAutocompleteController';
import {map} from 'rxjs/operators';
import {
  MAX_BIRTH_DATE,
  MAX_BIRTH_DATE_BEFORE,
  MIN_BIRTH_DATE,
  MIN_BIRTH_DATE_AFTER,
} from '../../core-helper/constants/Config';

import {PartyType} from '../../core-helper/constants/PartyType';
import {ApiCompanyData} from '../../core-helper/dto/property-insurance-dto/summary-dto/insurance-party-details-api-dto';
import {
  EikData,
  InsuringPartyDto,
} from '../../core-helper/dto/travel-dto/summary-dto/InsuringPartyDto';
import {dateValidator} from '../../core-helper/validator/date.validator';
import {companyName} from '../../core-helper/validator/insureparty-type/party/company-name.validator';
import {personName} from '../../core-helper/validator/insureparty-type/party/person-name.validator';
import {bgPhoneNumberValidator} from '../../core-helper/validator/phone/bg-phone-number.validator';
import {CityDto} from '../../form/model/dto/CityDto';
import {NationalityDto} from '../../form/model/dto/NationalityDto';
import {CoreApiService} from '../../services/core-api.service';
import {NameAddressTranslationMapService} from '../../services/name-address-translation-map.service';
import {PartyIdService} from '../../services/party-id.service';
import {FormData} from './form-data.model';

@Component({
  selector: 'eik-layout',
  templateUrl: './eik-layout.component.html',
  styleUrls: ['./eik-layout.component.scss'],
})
export class EikLayoutComponent extends ScreenFormComponent implements OnInit {
  public cpcController: CityAndPostCodeController;
  public cityAutocomplete: ServerSideAutocompleteController<CityDto>;

  private phoneNumberController: PhoneNumberValueController;
  @ViewChild('phoneInput', {static: false})
  protected set phoneInput(val: InputPhonenumberComponent) {
    if (val && !this.phoneNumberController) {
      setTimeout(
        () =>
          (this.phoneNumberController = this.stepForm.createPhoneNumberValue('phoneNumber', val, {
            disableCountryCode: true,
          })),
      );
    }
  }

  @Output() public readonly saveEikData: EventEmitter<EikData> = new EventEmitter<EikData>();

  public iPartyType: PartyType;
  @Input()
  public set insuringPartyType(insuringPartyType: PartyType) {
    this.iPartyType = insuringPartyType;
  }

  @Input() public insuringPartyDto: InsuringPartyDto;

  public visibleDateView?: DatepickerVisibleView = undefined;
  public disableWeekends: boolean = false;

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Year, -150);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, -1);

  public repPartyType: PartyType;
  public contactPartyType: PartyType;

  public minBirthDate = MIN_BIRTH_DATE;
  public maxBirthDate = MAX_BIRTH_DATE;

  public isRepForeigner: boolean = false;
  public isContactForeigner: boolean = false;

  public repBirthDatePicker: DatePickerController;
  public contactBirthDatePicker: DatePickerController;

  public repCitizenshipAutocomplete: ServerSideAutocompleteController<NationalityDto>;

  public contactCitizenshipAutocomplete: ServerSideAutocompleteController<NationalityDto>;

  @Input()
  public set companyData(companyData: ApiCompanyData | undefined) {
    if (companyData != null && companyData != undefined) {
      this.populateCompanyDataDetailFromApi(companyData);
    } else {
      this.parseAndPrePopulateData();
    }
  }

  constructor(
    private readonly _nameAddressTrasMapService: NameAddressTranslationMapService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    route: ActivatedRoute,
    private readonly _coreApiService: CoreApiService,
    private readonly _partyIdService: PartyIdService,
    private readonly _cdRef: ChangeDetectorRef,
  ) {
    super(route);
    this.stepForm = this._formFactory.createFormManager<FormData>({
      companyName: new FormControl(undefined, [Validators.required, companyName('bg')]),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
      quarter: new FormControl(undefined, [Validators.required, companyName('bg')]),
      street: new FormControl(undefined, [Validators.required, companyName('bg')]),
      streetNumber: new FormControl(undefined, [Validators.required, companyName('bg')]),
      block: new FormControl(undefined, [companyName('bg')]),
      entrance: new FormControl(undefined, [companyName('bg')]),
      floor: new FormControl(undefined, [companyName('bg')]),
      apartment: new FormControl(undefined, [companyName('bg')]),

      companyNameEn: new FormControl(undefined, [Validators.required, companyName('en')]),
      cityEn: new FormControl(undefined, [Validators.required]),
      postCodeEn: new FormControl(undefined, [Validators.required]),
      quarterEn: new FormControl(undefined, [Validators.required, companyName('en')]),
      streetEn: new FormControl(undefined, [Validators.required, companyName('en')]),
      streetNumberEn: new FormControl(undefined, [Validators.required, companyName('en')]),
      blockEn: new FormControl(undefined, [companyName('en')]),
      entranceEn: new FormControl(undefined, [companyName('en')]),
      floorEn: new FormControl(undefined, [companyName('en')]),
      apartmentEn: new FormControl(undefined, [companyName('en')]),

      phoneNumber: new FormControl(undefined, [
        Validators.required,
        bgPhoneNumberValidator({allowZero: true}),
      ]),
      emailId: new FormControl(undefined, [Validators.required]),

      repIdNumber: new FormControl(undefined, [
        Validators.required,
        this._repIdNumberValidator.bind(this),
      ]),
      repFirstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      repMiddleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      repLastName: new FormControl(undefined, [Validators.required, personName('bg')]),

      repFirstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      repMiddleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      repLastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),

      repCitizenship: new FormControl(undefined, [Validators.required]),
      repDob: new FormControl(undefined, [Validators.required]),

      contactIdNumber: new FormControl(undefined, [this._contactIdNumberValidator.bind(this)]),
      contactFirstName: new FormControl(undefined, [personName('bg')]),
      contactMiddleName: new FormControl(undefined, [personName('bg')]),
      contactLastName: new FormControl(undefined, [personName('bg')]),

      contactFirstNameEn: new FormControl(undefined, [personName('en')]),
      contactMiddleNameEn: new FormControl(undefined, [personName('en')]),
      contactLastNameEn: new FormControl(undefined, [personName('en')]),

      contactCitizenship: new FormControl(undefined, [Validators.required]),
      contactDob: new FormControl(undefined, [Validators.required]),
    });

    this.cpcController = this.stepForm.createCityAndPostCode('city', 'postCode');
    this.cityAutocomplete = this.stepForm.createAutocompleteWithServersideFiltering<CityDto>(
      'city',
      (q: string) => this._coreApiService.getPlaceOfResidence(q, true).pipe(map(res => res.data)),
    );
    this.repBirthDatePicker = this.stepForm.createDatePicker('repDob');
    this.contactBirthDatePicker = this.stepForm.createDatePicker('contactDob');

    this.repCitizenshipAutocomplete =
      this.stepForm.createAutocompleteWithServersideFiltering<NationalityDto>(
        'repCitizenship',
        (q: string) => this._coreApiService.getNationalities(q, true).pipe(map(res => res.data)),
      );

    this.contactCitizenshipAutocomplete =
      this.stepForm.createAutocompleteWithServersideFiltering<NationalityDto>(
        'contactCitizenship',
        (q: string) => this._coreApiService.getNationalities(q, true).pipe(map(res => res.data)),
      );
  }

  public ngOnInit(): void {
    this.parseAndPrePopulateData();
    this._nameAddressTrasMapService.updateCompanyFieldTranslation(this.stepForm.formGroup);
    this._nameAddressTrasMapService.updateAddressFieldTraslation(this.stepForm.formGroup);
    this._nameAddressTrasMapService.updateRepNameFieldTranslation(this.stepForm.formGroup);
    this._nameAddressTrasMapService.updateContactPersonNameFieldTranslation(
      this.stepForm.formGroup,
    );
  }

  public parseAndPrePopulateData(): void {
    if (this.insuringPartyDto != null && this.insuringPartyDto.insuringPartyEikData) {
      const eikData: EikData = this.insuringPartyDto.insuringPartyEikData;
      this.stepForm.patchValue(eikData);
    }
  }

  private _repIdNumberValidator(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const repPartyType = this._partyIdService.getType(control.value);
      if (repPartyType) {
        this.repPartyType = repPartyType;
        this.updateRepDynamicValidation();
      }

      if (!repPartyType || repPartyType === PartyType.COMPANY) {
        this.isRepForeigner = false;
        return {repInvalidNumber: true};
      }
    }
    return null;
  }

  private _contactIdNumberValidator(control: FormControl): ValidationErrors | null {
    if (control.value) {
      const contactPartyType = this._partyIdService.getType(control.value);
      if (contactPartyType) {
        this.contactPartyType = contactPartyType;
        this.updateContactDynamicValidation();
      }

      if (!contactPartyType || contactPartyType === PartyType.COMPANY) {
        this.isContactForeigner = false;
        return {contactInvalidNumber: true};
      }
    }
    return null;
  }

  public populateCompanyDataDetailFromApi(result: ApiCompanyData): void {
    const phone: PhoneNumberValue = {
      prefix: this.phoneNumberController.value.prefix,
      number: result.address.phoneNumber,
    };

    this.stepForm.patchValue({
      companyName: result.name,
      postCode: result.address.posCode,
      quarter: result.address.quarter,
      street: result.address.street,
      streetNumber: result.address.streetNumber,
      block: result.address.block,
      entrance: result.address.entrance,
      floor: result.address.floor,
      apartmtEn: result.address.apartment,
      emailId: result.email,
      phoneNumber: phone,
      repIdNumber: result.representative.indent,
      repFirstName: result.representative.name,
    });
    this._cdRef.detectChanges();
  }
  public updateRepDynamicValidation(): void {
    this.isRepForeigner = this.repPartyType === PartyType.FOREIGNER ? true : false;
    if (this.repPartyType === PartyType.PERSON) {
      this.stepForm.controls.repCitizenship.setValidators([]);
      this.stepForm.controls.repDob.setValidators([]);
    } else if (this.repPartyType === PartyType.FOREIGNER) {
      this.stepForm.controls.repCitizenship.setValidators([Validators.required]);
      this.stepForm.controls.repDob.setValidators([
        Validators.required,
        dateValidator(),
        control => ExtraDateValidators.before(MAX_BIRTH_DATE_BEFORE)(control.value),
        control => ExtraDateValidators.after(MIN_BIRTH_DATE_AFTER)(control.value),
      ]);
    }
    this.stepForm.controls.repCitizenship.updateValueAndValidity();
    this.stepForm.controls.repDob.updateValueAndValidity();
  }

  public updateContactDynamicValidation(): void {
    this.isContactForeigner = this.contactPartyType === PartyType.FOREIGNER ? true : false;
    if (this.contactPartyType === PartyType.PERSON) {
      this.stepForm.controls.contactCitizenship.setValidators([]);
      this.stepForm.controls.contactDob.setValidators([]);
    } else if (this.contactPartyType === PartyType.FOREIGNER) {
      this.stepForm.controls.contactCitizenship.setValidators([]);
      this.stepForm.controls.contactDob.setValidators([
        Validators.required,
        dateValidator(),
        control => ExtraDateValidators.before(MAX_BIRTH_DATE_BEFORE)(control.value),
        control => ExtraDateValidators.after(MIN_BIRTH_DATE_AFTER)(control.value),
      ]);
    }
    this.stepForm.controls.contactCitizenship.updateValueAndValidity();
    this.stepForm.controls.contactDob.updateValueAndValidity();
  }

  public save() {
    const val = this.stepForm.value;
    const eikData: EikData = {
      ...val,
      phoneNumber: this.phoneNumberController.value,
    };

    this.saveEikData.emit(eikData);
  }

  protected saveMtplData(): void {
    this.save();
  }

  public getCityTitle(cityObj: CityDto): string {
    return cityObj != null && cityObj.title != null ? cityObj.title : '';
  }

  public getCitizenshipTitle(citizen: NationalityDto): string {
    return citizen != null && citizen.title != null ? citizen.title : '';
  }
}
