import {Component, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Decimal} from '@atlas/businesstypes';
import {
  TravelCovers,
  TravelCoversDto,
  ProposalDto,
  TravelRoutePaths,
  CoversStep,
  EmitAddOnData,
  AddonsDiscountsDto,
  DateFormatterService,
  TripDetailDto,
  TwelveMonths,
} from '@b2b-frontend/core';
import {ScreenStepComponent} from '@njf-frontend-angular/flow-progress';
import {TravelNavigationService} from '../../travel-core/services/travel-navigation.service';
import {TravelPolicyService} from '../../travel-core/services/travel-policy.service';
import {TravelSessionService} from '../../travel-core/services/travel-session.service';
import {DEFAULT_VALUE, MOCK_COVER_AMOUNT} from '../travel-addons/__tests__/travel-addons.mocks';
import {
  MOCK_TRAVEL_COVER_LIST,
  CUSTOM_COVER_ID,
  CUSTOM_COVER_DATA,
} from './__tests__/travel-covers.mocks';
import {Boolean} from '@atlas/businesstypes';
import {CoverAmount} from '../travel-addons/travel-addons.component';
import {TravelSlideInService} from '../../travel-core/services/travel-slide-in.service';
import {ModalConfirmedResult} from '@maia/modals';

@Component({
  selector: 'travel-covers',
  templateUrl: './travel-covers.component.html',
  styleUrls: ['./travel-covers.component.scss'],
})
export class TravelCoversComponent extends ScreenStepComponent implements OnInit, OnDestroy {
  public inputAmount: Decimal = new Decimal('0');
  public inputAmountDecimals = 2;
  public inputShowPositiveSymbol = false;
  public inputStrikethrough = false;
  public showHideCustomAmoutDiv: boolean = false;
  public travelCoversList: TravelCovers[] = [...MOCK_TRAVEL_COVER_LIST];
  public coverAmount = MOCK_COVER_AMOUNT;
  public valueCoverAmount: string = DEFAULT_VALUE.VALUE;
  public valueAccidentCoverAmount: string;
  public selectedCoversId: number = 0;
  public inEditMode: boolean = false;
  public additionalCovers: CoversStep[] = [];
  public additionalCoversSelected: Boolean[] = [];
  public premiumButton: boolean = true;
  public enableButton: boolean = true;
  public customCoverAmount: string = '0';
  public customCoverId: number = CUSTOM_COVER_ID;
  public customCoverData: TravelCovers = CUSTOM_COVER_DATA;

  public addOnDiscountData: AddonsDiscountsDto;
  public noOfTripDays: string;
  public travelInsuredPeopleCount: number;

  constructor(
    private readonly _travelNavigationService: TravelNavigationService,
    route: ActivatedRoute,
    public travelSessionService: TravelSessionService,
    private readonly _travelPolicyService: TravelPolicyService,
    private readonly _travelSlideInService: TravelSlideInService,
    private readonly _dateFormatterService: DateFormatterService,
  ) {
    super(route);
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ngOnInit(): void {
    this.prePopulateSessionData();
  }

  public prePopulateSessionData(): void {
    if (this.travelSessionService.data) {
      const data: ProposalDto = this.travelSessionService.data;
      if (data.travelCoverDto) {
        const selectedCover = data.travelCoverDto.selectedCover;
        if (selectedCover.coverId !== this.customCoverId) {
          const index = this.travelCoversList.findIndex(t => t.coverId === selectedCover.coverId);
          this.updateSelectedCover(index);
        } else {
          this.customCoverData = {...selectedCover};
          this.selectedCoversId = this.customCoverData.coverId;
          this.customCoverAmount = this.customCoverData.amount;
          this.showHideCustomAmoutDiv = true;
        }
      }
      if (data.addonDiscountDto) {
        this.addOnDiscountData = data.addonDiscountDto;
        this.inEditMode = this.addOnDiscountData.isEditAddon;
      }

      if (data.tripDetailDto) {
        const tripDetailData: TripDetailDto = data.tripDetailDto;
        this.noOfTripDays = tripDetailData.singleTrip
          ? `${this._dateFormatterService.isoDateDifference(
              tripDetailData.singleTrip.startEndData.start,
              tripDetailData.singleTrip.startEndData.end,
            )} Days`
          : TwelveMonths;
        this.travelInsuredPeopleCount = tripDetailData.travelInsuredPeopleCount;
      }
    }
  }

  public getAmoutValue(amount: string): Decimal {
    return new Decimal(amount);
  }

  public getAmountForDay(amountForDay: string): string {
    const noDayForPeople: string =
      this.noOfTripDays != null && this.travelInsuredPeopleCount != null
        ? `${this.travelInsuredPeopleCount} people for ${this.noOfTripDays}`
        : amountForDay;
    this.customCoverData.amountForDay = noDayForPeople;
    return noDayForPeople;
  }

  public openSlideIn(covers?: TravelCovers): void {
    this._travelSlideInService
      .openCoversSlideIn(covers)
      .subscribe((res: ModalConfirmedResult<TravelCovers>) => {
        // write a logic inside this loop
      });
  }

  public updateSelectedCover(coverIndex: number): void {
    this.showHideCustomAmoutDiv = false;
    this.travelCoversList.map((cover: TravelCovers) => (cover.selectedStatus = false));
    this.travelCoversList[coverIndex].selectedStatus = true;
    this.selectedCoversId = this.travelCoversList[coverIndex].coverId;
    this._travelPolicyService.showPriceAccordion(false);
    this.premiumButton = false;
    this.enableButton = true;
    this.customCoverAmount = '0';
    this.valueCoverAmount = '';
  }

  public selectCustom(): void {
    this._travelPolicyService.showPriceAccordion(false);
    this.showHideCustomAmoutDiv = true;
    this.premiumButton = true;
    this.enableButton = true;
  }

  public emitSelectedAddons(emitDate: EmitAddOnData): void {
    this.additionalCovers = emitDate.addons;
    this.additionalCoversSelected = emitDate.status;
  }

  public navigateTo(): void {
    this._travelNavigationService.nextScreen(TravelRoutePaths.TI_ADD_ONS);
  }

  public enablePremium(): void {
    this.customCoverAmount = this.valueCoverAmount;
    this.customCoverData.amount = this.valueCoverAmount;
    this.premiumButton = false;
  }

  public getCustomCoverAmount(): string {
    const cObject: CoverAmount | undefined = this.coverAmount.find(
      (cAmount: CoverAmount) => cAmount.value === this.valueCoverAmount,
    );
    return cObject != null && cObject != undefined ? cObject.name : this.valueCoverAmount;
  }

  public showAccordian(): void {
    this.premiumButton = true;
    this.enableButton = false;
    this._travelPolicyService.showPriceAccordion(true);
  }

  protected saveMtplData(): void {
    const selectedCoverObj: TravelCovers | undefined = this.travelCoversList.find(
      (tCover: TravelCovers) => tCover.selectedStatus,
    );
    if (selectedCoverObj) {
      const traveleCoverDto: TravelCoversDto = {
        selectedCover: selectedCoverObj,
      };
      this.travelSessionService.setTravelCovers(traveleCoverDto);
    }

    if (this.selectedCoversId === this.customCoverId) {
      const traveleCoverDto: TravelCoversDto = {
        selectedCover: this.customCoverData,
      };
      this.travelSessionService.setTravelCovers(traveleCoverDto);
    }

    if (
      this.inEditMode &&
      this.additionalCoversSelected &&
      this.additionalCovers &&
      this.addOnDiscountData
    ) {
      const selecetedAddons: CoversStep[] = [];
      this.additionalCoversSelected.forEach((element: Boolean, index: number) => {
        element.equals(Boolean.TRUE) && this.additionalCovers[index]
          ? selecetedAddons.push(this.additionalCovers[index])
          : undefined;
      });

      const addonsDiscountData: AddonsDiscountsDto = {
        ...this.addOnDiscountData,
        addons: selecetedAddons,
        isEditAddon: false,
      };
      this.travelSessionService.setAddonsAndDiscountData(addonsDiscountData);
    }
  }
}
