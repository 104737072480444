import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ScreenFormComponent} from '@njf-frontend-angular/flow-progress';
import {RoutePaths} from '../../../constants/route-paths';

@Component({
  selector: 'nje-b2b-instalment-debit-payment-success',
  templateUrl: './debit-payment-success.component.html',
  styleUrls: ['./debit-payment-success.component.scss'],
})
export class DebitPaymentSuccessComponent extends ScreenFormComponent {
  public searchPolicyPath: string = `/dashboard/payments/${RoutePaths.InstalmentPayment}/${RoutePaths.SearchPolicy}`;
  constructor(route: ActivatedRoute, private readonly _route: Router) {
    super(route);
  }

  public navigateToHome(): void {
    this._route.navigateByUrl(this.searchPolicyPath);
  }

  protected saveMtplData(): void {}
}
