<div class="ng-container">
  <maia-label class="b2b-kdl-font-small title-dark-color"> Issuing e-receipt </maia-label>

  <maia-price-block m-l-24 amount="15.43" currency="BGN">
    <svg class="icon-white-color" b2bFrontendInformationExprPayment maia-price-icon></svg>
    <div maia-price-title>Total amount of payment</div>
    <div maia-price-subtitle>For selected instalments</div>
  </maia-price-block>

  <maia-form-group id="form-group-0">
    <maia-form-element id="form-element-7" class="form-element-custom-width">
      <div class="b2b-kdl-font-small title-dark-color form-element-custom-div-margin">
        <label> Client email address to send payment documents</label>
      </div>
      <input
        maiaInput
        atlasText
        name="input1"
        type="email"
        placeholder="john@gmail.com"
        atlasMaxLength="5"
      />
    </maia-form-element>
  </maia-form-group>

  <maia-divider></maia-divider>

  <maia-label class="b2b-kdl-font-small title-dark-color"> Select Payment method </maia-label>

  <maia-action-group>
    <button
      maiaActionTile
      [selected]="isCashPaymentSelected"
      class="terms-tile"
      (click)="paymentSelected(payment.SELECTED_PAYMENT_MODE.CASH)"
      [disabled]="disableCash"
    >
      <svg b2bFrontendInformationExprHandCoins maia-tile-icon></svg>
      <span maia-tile-label>Cash</span>
    </button>
    <button
      maiaActionTile
      [selected]="isBankTransferSelected"
      class="terms-tile"
      (click)="paymentSelected(payment.SELECTED_PAYMENT_MODE.BANK_TRANSFER)"
      [disabled]="disableBankTransfer"
    >
      <svg b2bFrontendInformationExprCardsArrows maia-tile-icon></svg>
      <span maia-tile-label>Bank Transfer</span>
      <span maia-tile-sub-label>To bank account of the intermediary</span>
    </button>
  </maia-action-group>

  <div *ngIf="!isIntermediaryAccountPresent">
    <maia-contextual-message type="informative" class="contextual-message"
      >Clients bank account need to be recorded in the core system. Please, contact DZI
    </maia-contextual-message>
  </div>

  <maia-divider></maia-divider>

  <button maiaButton class="button" maiaButtonType="primary" (click)="saveAndContinue()"
    >Issue e-receipt</button
  >
</div>
