import {
  AdditonalRisksCheck,
  AdditonalRisksTicks,
} from './price-covers-additional-risks-mock-interface';

export const MOCK_ADDITIONAL_RISKS_OPTIONS: AdditonalRisksCheck[] = [
  {
    id: 0,
    risks: 'Costs for pulling of motor vehicle',
    price: '5.00',
    selectedStatus: false,
  },
  {
    id: 1,
    risks: 'Costs for parking after traffic accident',
    price: '5.00',
    selectedStatus: false,
  },
  {
    id: 2,
    risks: 'Costs for stay in hotel of the driver after traffic accident',
    price: '5.00',
    selectedStatus: false,
  },
  {
    id: 3,
    risks: 'Costs for transportation of passengers and baggage',
    price: '5.00',
    selectedStatus: false,
  },
  {
    id: 4,
    risks: 'Costs for medical transportation of the persons',
    price: '5.00',
    selectedStatus: false,
  },
  {
    id: 5,
    risks: 'Costs for medical examinations, investigations and manipulations',
    price: '5.00',
    selectedStatus: false,
  },
  {
    id: 6,
    risks: 'Emergency unlocking of the vehicle',
    price: '5.00',
    selectedStatus: false,
  },
  {
    id: 7,
    risks: 'Additional limit for transportation of vehicle in case of insurance event ',
    price: '5.00',
    selectedStatus: false,
  },
  {
    id: 8,
    risks: 'Additional limit for transportation of vehicle in case of technical failure',
    price: '5.00',
    selectedStatus: false,
  },
];

export const MOCK_ADDITIONAL_RISKS_TICKS_OPTIONS: AdditonalRisksTicks[] = [
  {
    id: 0,
    ticks: 'Transport of motor vehicle after insurance event - for  R Bulgaria',
  },
  {
    id: 1,
    ticks:
      'Transport of motor vehicle in case of technical failure, incl. in case of wrong fuel – for R Bulgaria',
  },
  {
    id: 2,
    ticks: 'Organization of pulling-out of vehicle – for R Bulgaria',
  },
  {
    id: 3,
    ticks: 'Technical services of vehicle – for R Bulgaria ',
  },
  {
    id: 4,
    ticks: 'Information Services',
  },
  {
    id: 5,
    ticks: 'Transport of motor vehicle after insurance Casco event',
  },
];
