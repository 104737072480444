import {Component} from '@angular/core';
import {TRAVEL_POLICY_ERROR} from '../../constants/constant';

@Component({
  selector: 'nje-travel-policy-error',
  templateUrl: './travel-policy-error.component.html',
  styleUrls: ['./travel-policy-error.component.scss'],
})
export class TravelPolicyErrorComponent {
  public paymentErrorMsg: string = TRAVEL_POLICY_ERROR.PAYMENT_ERROR_MSG;
}
