import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesExprHouseSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendPlacesExprHouse]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path fill="none" stroke-miterlimit="10" d="M20.8 18.4h6.4v6.4h-6.4z" stroke-width="1.6"></svg:path><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M6.4 21.6L24 7.2l17.6 14.4" stroke-width="1.6"></svg:path><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M20 40.8v-9.6h8v9.6" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M10.4 24v16.8h27.2V24" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesExprHouseSvgComponent {
}

@NgModule({
  declarations: [
    PlacesExprHouseSvgComponent,
  ],
  exports: [
    PlacesExprHouseSvgComponent,
  ],
})
export class PlacesExprHouseSvgModule {}
