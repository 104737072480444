import {Component, ComponentFactory, ComponentFactoryResolver, Injector} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {SlideInController} from '@maia/slide-ins';
import {
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';
import {CascoWorkflow} from '../../casco-core/casco-workflow/CascoWorkflow';
import {CascoProcessService} from '../../casco-core/services/casco-process.service';
import {SumInsuredSecondSlideinComponent} from './sum-insured-second-slidein/sum-insured-second-slidein.component';
import {SumInsuredSlideinComponent} from './sum-insured-slidein/sum-insured-slidein.component';

@Component({
  selector: 'nje-sum-insured',
  templateUrl: './sum-insured.component.html',
  styleUrls: ['./sum-insured.component.scss'],
})
@UntilDestroy()
export class SumInsuredComponent extends ScreenFormComponent {
  public autoprocess: boolean = false;
  public continueClicked: boolean;
  public readonly slideInOneFactory: ComponentFactory<SumInsuredSlideinComponent>;
  public readonly slideInTwoFactory: ComponentFactory<SumInsuredSecondSlideinComponent>;
  public isTypeInvoiceSelected = false;
  public isRenewalSelected = false;
  public sumInsuredForm = this._formFactory.createFormManager<any>({
    sumInsured: new FormControl(undefined),
    typeOfSumInsured: new FormControl('SI by Renewal'),
    policyCurrency: new FormControl(undefined),
    invoiceValue: new FormControl(undefined),
    dateOfInvoice: new FormControl(undefined),
    provider: new FormControl(undefined),
  });

  public siInvoiceClicked() {
    this.isTypeInvoiceSelected = true;
    this.isRenewalSelected = false;
  }

  public isRenewalClicked() {
    this.isTypeInvoiceSelected = false;
    this.isRenewalSelected = true;
  }

  public constructor(
    private readonly _formFactory: FormGroupManagerFactoryService,
    route: ActivatedRoute,
    private readonly _slideInCtrl: SlideInController,
    componentFactoryResolver: ComponentFactoryResolver,
    private readonly _injector: Injector,
    private readonly _process: CascoProcessService,
  ) {
    super(route);
    this._process.currentWorkflow = CascoWorkflow.PRICE_OFFER;
    this.slideInOneFactory = componentFactoryResolver.resolveComponentFactory(
      SumInsuredSlideinComponent,
    );
    this.slideInTwoFactory = componentFactoryResolver.resolveComponentFactory(
      SumInsuredSecondSlideinComponent,
    );
  }
  protected saveMtplData(): void {
    this.continueClicked = true;
  }

  public openSlidein(): void {
    this._slideInCtrl
      .prepare(
        this.slideInOneFactory,
        this._injector,
        {
          title: 'Add New Equipment',
        },
        {
          withClickableBackdrop: true,
          withVisibleBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  public openSecondSlidein(): void {
    this._slideInCtrl
      .prepare(
        this.slideInTwoFactory,
        this._injector,
        {
          title: 'Add New AV Equipment',
        },
        {
          withClickableBackdrop: true,
          withVisibleBackdrop: true,
          input: undefined,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }
}
