/* eslint-disable @typescript-eslint/unbound-method */
/*eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DateKey, DateUtils, Text} from '@atlas/businesstypes';
import {DatepickerVisibleView} from '@maia/datepickers';
import {
  ScreenFormComponent,
  FormGroupManagerFactoryService,
  FormGroupManager,
} from '@njf-frontend-angular/flow-progress';
import {
  PartyType,
  companyName,
  personName,
  CitizenshipDto,
  CoreApiService,
  NameAddressTranslationMapService,
  PartyIdService,
  DataService,
  CompanyDataDto,
  MtplPersonalData,
  CityApiData,
  City,
} from '@b2b-frontend/core';
import {map} from 'rxjs/operators';
import {InsuringFormData} from '../../../models/InsuringFormData';
import {MtplApiService} from '../../../mtpl-core/services/mtpl-api.service';
import {PhoneNumberValue} from '@maia/input-phonenumber';
import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {
  BLANK_VALUE,
  CITY,
  COUNTRY_CODE,
  INSURING_DETAILS,
} from '../../../mtpl-constants.ts/constant';

@Component({
  selector: 'nje-insuring-party-layout',
  templateUrl: './insuring-party-layout.component.html',
  styleUrls: ['./insuring-party-layout.component.scss'],
})
@UntilDestroy()
export class InsuringPartyLayoutComponent extends ScreenFormComponent implements OnInit {
  public isInsuringPartyLNCH: boolean = false;
  public isInsuringPartyEGN: boolean = false;
  public isInsuringPartyEIK: boolean = false;
  public isVehicleOwnerLNCH: boolean = false;
  public isVehicleOwnerEGN: boolean = true;
  public isVehicleOwnerEIK: boolean = false;
  public isContactPersonLNCH: boolean = false;
  public isContactPersonEGN: boolean = true;
  public isrepLNCH: boolean = false;
  public isrepEGN: boolean = true;
  public isSame = false;
  public insuringPartyType: PartyType | undefined;
  public searchEgn: Text;
  public insuringPartyId: Text;
  public vehicleOwnerId: Text;
  public contactPersonId: Text;
  public repId: Text;
  public sessionedFormValue: any;
  public textSearched: Text;
  public retrievedPersonDetails: MtplPersonalData;
  public retrievedCompanyDetails: CompanyDataDto;
  public cityArray: CityApiData[] = [];
  public eikRepDetails: boolean = false;
  public eikRepName: string[];
  public personNotFound: boolean = false;
  public phone: PhoneNumberValue;
  public pattern: RegExp = /[a-zA-Z]/;

  public insuringPartyForm: FormGroupManager<InsuringFormData> =
    this._formFactory.createFormManager<InsuringFormData>({
      insuringIdNumber: new FormControl(undefined, [Validators.required]),
      companyName: new FormControl(undefined, [Validators.required, companyName('bg')]),
      firstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      middleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      lastName: new FormControl(undefined, [Validators.required, personName('bg')]),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
      quarter: new FormControl(undefined, [personName('bg')]),
      street: new FormControl(undefined, [personName('bg')]),
      streetNumber: new FormControl(undefined),
      block: new FormControl(undefined, [personName('bg')]),
      entrance: new FormControl(undefined, [personName('bg')]),
      floor: new FormControl(undefined, [personName('bg')]),
      apartment: new FormControl(undefined, [personName('bg')]),

      companyNameEn: new FormControl(undefined, [Validators.required, companyName('en')]),
      firstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      middleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      lastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      cityEn: new FormControl(undefined, [Validators.required]),
      postCodeEn: new FormControl(undefined, [Validators.required]),
      quarterEn: new FormControl(undefined, [personName('en')]),
      streetEn: new FormControl(undefined, [personName('en')]),
      streetNumberEn: new FormControl(undefined),
      blockEn: new FormControl(undefined, [personName('en')]),
      entranceEn: new FormControl(undefined, [personName('en')]),
      floorEn: new FormControl(undefined, [personName('en')]),
      apartmentEn: new FormControl(undefined, [personName('en')]),

      citizenship: new FormControl(undefined, [Validators.required]),
      dob: new FormControl(undefined, [Validators.required]),
      phoneNumber: new FormControl(undefined),
      emailId: new FormControl(undefined),

      contactIdNumber: new FormControl(undefined, [Validators.required]),
      contactFirstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      contactMiddleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      contactLastName: new FormControl(undefined, [Validators.required, personName('bg')]),
      contactFirstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      contactMiddleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      contactLastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      contactCitizenship: new FormControl(undefined, [Validators.required]),
      contactDob: new FormControl(undefined, [Validators.required]),

      repIdNumber: new FormControl(undefined, [Validators.required]),
      repFirstName: new FormControl(undefined, [Validators.required, personName('bg')]),
      repMiddleName: new FormControl(undefined, [Validators.required, personName('bg')]),
      repLastName: new FormControl(undefined, [Validators.required, personName('bg')]),
      repFirstNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      repMiddleNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      repLastNameEn: new FormControl(undefined, [Validators.required, personName('en')]),
      repCitizenship: new FormControl(undefined, [Validators.required]),
      repDob: new FormControl(undefined, [Validators.required]),
    });

  public cpcController = this.insuringPartyForm.createCityAndPostCode('city', 'postCode');

  public cityAutocomplete = this.insuringPartyForm.createAutocompleteWithServersideFiltering<City>(
    'city',
    (query: string) =>
      this._coreApiService.getCityZip(query, true).pipe(
        map((response: City[]) => {
          return response;
        }),
      ),
  );

  public citizenshipAutocomplete =
    this.insuringPartyForm.createAutocompleteWithServersideFiltering<CitizenshipDto>(
      'citizenship',
      () => this._coreApiService.getCitizenship().pipe(map((res: CitizenshipDto[]) => res)),
    );

  public contactCitizenshipAutocomplete =
    this.insuringPartyForm.createAutocompleteWithServersideFiltering<CitizenshipDto>(
      'contactCitizenship',
      () => this._coreApiService.getCitizenship().pipe(map((res: CitizenshipDto[]) => res)),
    );

  public repCitizenshipAutocomplete =
    this.insuringPartyForm.createAutocompleteWithServersideFiltering<CitizenshipDto>(
      'contactCitizenship',
      () => this._coreApiService.getCitizenship().pipe(map((res: CitizenshipDto[]) => res)),
    );

  public visibleDateView?: DatepickerVisibleView = undefined;
  public disableWeekends: boolean = false;
  public contactPartyType: PartyType;

  public minDate = DateUtils.add(DateUtils.today(), DateKey.Year, -150);
  public maxDate = DateUtils.add(DateUtils.today(), DateKey.Day, -1);

  public birthDatePicker = this.insuringPartyForm.createDatePicker('dob');
  public contactBirthDatePicker = this.insuringPartyForm.createDatePicker('contactDob');
  public repBirthDatePicker = this.insuringPartyForm.createDatePicker('repDob');

  public constructor(
    private readonly _nameAddressTrasMapService: NameAddressTranslationMapService,
    private readonly _formFactory: FormGroupManagerFactoryService,
    private readonly _coreApiService: CoreApiService,
    route: ActivatedRoute,
    private readonly _partyId: PartyIdService,
    private readonly _dataService: DataService,
    private readonly _mtplApiService: MtplApiService,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    this._prepopulateCityField();

    this._nameAddressTrasMapService.updateNameFieldTranslation(this.insuringPartyForm.formGroup);
    this._nameAddressTrasMapService.updateAddressFieldTraslation(this.insuringPartyForm.formGroup);
    this._nameAddressTrasMapService.updateRepNameFieldTranslation(this.insuringPartyForm.formGroup);
    this._nameAddressTrasMapService.updateContactPersonNameFieldTranslation(
      this.insuringPartyForm.formGroup,
    );
    this._nameAddressTrasMapService.updateCompanyFieldTranslation(this.insuringPartyForm.formGroup);

    this.insuringPartyForm.controls.city.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((val: City) => {
        const city: any = val;
        if (val != undefined) {
          this.insuringPartyForm.patchValue({
            cityEn: val,
            postCode: city.postCode,
            postCodeEn: city.postCode,
          });
        }
      });

    this.insuringPartyForm.controls.cityEn.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((val: City) => {
        const city: any = val;
        if (val !== null && val !== undefined) {
          this.insuringPartyForm.patchValue({
            postCodeEn: city.postCode,
          });
        }
      });
  }

  private _prepopulateCityField(): void {
    this._coreApiService
      .getCity(INSURING_DETAILS.CITY_NAME)
      .pipe(takeUntilDestroyed(this))
      .subscribe(
        (response: CityApiData[]) => {
          this.cityArray = response;
        },
        error => {
          console.log('Api Error', error);
        },
      );
  }

  public handleInsuringParty(insuringPartyId: Text) {
    this.insuringPartyId = insuringPartyId;
    if (null != insuringPartyId && 8 <= insuringPartyId.asString().length) {
      this.insuringPartyType = this._partyId.getType(insuringPartyId.asString());
      this.isInsuringPartyEGN = this.insuringPartyType === PartyType.PERSON;
      this.isInsuringPartyEIK = this.insuringPartyType === PartyType.COMPANY;
      this.isInsuringPartyLNCH = this.insuringPartyType === PartyType.FOREIGNER;
    } else if (insuringPartyId == null) {
      this.insuringPartyForm.reset();
      this.phone = {number: BLANK_VALUE, prefix: COUNTRY_CODE};
    }
    this.insuringPartyForm.controls.insuringIdNumber.setValue(insuringPartyId.asString());
  }

  public selectedPhoneNumber(data: PhoneNumberValue): void {
    this.insuringPartyForm.controls.phoneNumber.setValue(data);
  }

  public handleSearchedText(text: Text): void {
    this.textSearched = text;
    if (this.isInsuringPartyEGN || this.isInsuringPartyLNCH) {
      this._mtplApiService
        .getPersonDetails(text.asString())
        .pipe(takeUntilDestroyed(this))
        .subscribe(
          (response: MtplPersonalData) => {
            this.retrievedPersonDetails = response;
            this.populateRetrievedEGNLNCHDetails(response);
          },
          error => {
            this.personNotFound = true;
            console.log('Api Error', error);
          },
        );
    } else if (this.isInsuringPartyEIK) {
      this._mtplApiService
        .getCompanyDetails(text.asString())
        .pipe(takeUntilDestroyed(this))
        .subscribe(
          (response: CompanyDataDto) => {
            this.retrievedCompanyDetails = response;
            this.populateRetrievedEIKDetails(response);
          },
          error => {
            this.personNotFound = true;
            console.log('Api Error', error);
          },
        );
    }
  }

  public handleSearchedRepDetails(text: Text): void {
    this.textSearched = text;
    if (this.isrepEGN || this.isrepLNCH) {
      this._mtplApiService
        .getPersonDetails(text.asString())
        .pipe(takeUntilDestroyed(this))
        .subscribe(
          (response: MtplPersonalData) => {
            this.retrievedPersonDetails = response;
            this.populateRepDetails(response);
          },
          error => {
            console.log('Api Error', error);
          },
        );
    }
  }

  public handleSearchedContactDetails(text: Text): void {
    this.textSearched = text;
    if (this.isContactPersonEGN || this.isContactPersonLNCH) {
      this._mtplApiService
        .getPersonDetails(text.asString())
        .pipe(takeUntilDestroyed(this))
        .subscribe(
          (response: MtplPersonalData) => {
            this.retrievedPersonDetails = response;
            this.populateContactDetails(response);
          },
          error => {
            console.log('Api Error', error);
          },
        );
    }
  }

  public populateRepDetails(data: MtplPersonalData): void {
    this.insuringPartyForm.patchValue({
      repFirstNameEn: data.personalData.firstName,
      repMiddleNameEn: data.personalData.middleName,
      repLastNameEn: data.personalData.surname,
    });
  }

  public populateContactDetails(data: MtplPersonalData): void {
    this.insuringPartyForm.patchValue({
      contactFirstNameEn: data.personalData.firstName,
      contactMiddleNameEn: data.personalData.middleName,
      contactLastNameEn: data.personalData.surname,
    });
  }

  public populateRetrievedEGNLNCHDetails(data: MtplPersonalData): void {
    const phone: PhoneNumberValue = data.personalData.addresses
      ? typeof data.personalData.addresses[0].phoneNumber === 'string'
        ? {number: data.personalData.addresses[0].phoneNumber, prefix: COUNTRY_CODE}
        : BLANK_VALUE
      : this.insuringPartyForm.controls.phoneNumber.value;
    let city: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].city
      : BLANK_VALUE;
    if (city === CITY) {
      city = INSURING_DETAILS.CITY_NAME;
    }
    const cityData: CityApiData | undefined = this.cityArray.find(element => element.city === city);
    if (
      data.personalData.firstName?.match(this.pattern) ||
      data.personalData.middleName?.match(this.pattern) ||
      data.personalData.surname?.match(this.pattern)
    ) {
      this.insuringPartyForm.patchValue({
        firstNameEn: data.personalData.firstName,
        middleNameEn: data.personalData.middleName,
        lastNameEn: data.personalData.surname,
      });
    } else {
      this.insuringPartyForm.patchValue({
        firstName: data.personalData.firstName,
        middleName: data.personalData.middleName,
        lastName: data.personalData.surname,
      });
    }
    this.populateAddressDetails(data);
    this.insuringPartyForm.patchValue({
      city: cityData,
      emailId: data.personalData.addresses ? data.personalData.addresses[0].email : BLANK_VALUE,
      phoneNumber: phone,
    });
  }

  public populateAddressDetails(data: MtplPersonalData): void {
    const block: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].block
      : BLANK_VALUE;
    const entrance: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].entrance
      : BLANK_VALUE;
    const floor: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].floor
      : BLANK_VALUE;
    const apartment: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].apartment
      : BLANK_VALUE;
    const street: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].street
      : BLANK_VALUE;
    const streetNumber: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].streetNo
      : BLANK_VALUE;
    const quarter: string | undefined = data.personalData.addresses
      ? data.personalData.addresses[0].quarter
      : BLANK_VALUE;
    if (
      block?.match(this.pattern) ||
      entrance?.match(this.pattern) ||
      floor?.match(this.pattern) ||
      street?.match(this.pattern) ||
      streetNumber?.match(this.pattern) ||
      quarter?.match(this.pattern)
    ) {
      this.insuringPartyForm.patchValue({
        blockEn: block,
        entranceEn: entrance,
        floorEn: floor,
        apartmentEn: apartment,
        streetEn: street,
        streetNumberEn: streetNumber,
        quarterEn: quarter,
      });
    } else {
      this.insuringPartyForm.patchValue({
        block: block,
        entrance: entrance,
        floor: floor,
        apartment: apartment,
        street: street,
        streetNumber: streetNumber,
        quarter: quarter,
      });
    }
  }

  public populateRetrievedEIKDetails(data: CompanyDataDto): void {
    this.eikRepDetails = data.representative.indent != undefined;
    let city: string | undefined = data.address?.settlement;
    let firstName: string = BLANK_VALUE;
    let middleName: string = BLANK_VALUE;
    let lastName: string = BLANK_VALUE;
    if (data.address?.settlement === CITY) {
      city = INSURING_DETAILS.CITY_NAME;
    }
    if (data.representative.name) {
      this.eikRepName = data.representative.name?.split(' ');
      for (let i = 0; i < this.eikRepName?.length; i++) {
        if (
          this.pattern.exec(this.eikRepName[0]) ||
          this.pattern.exec(this.eikRepName[1]) ||
          this.pattern.exec(this.eikRepName[2])
        ) {
          this.insuringPartyForm.patchValue({
            repFirstNameEn: firstName,
            repMiddleNameEn: middleName,
            repLastNameEn: lastName,
          });
        } else {
          this.insuringPartyForm.patchValue({
            repFirstName: firstName,
            repMiddleName: middleName,
            repLastName: lastName,
          });
        }
        firstName = this.eikRepName[0];
        middleName = this.eikRepName[1];
        lastName = this.eikRepName[2];
      }
    }
    const cityData: CityApiData | undefined = this.cityArray.find(element => element.city === city);
    this.populateCompanyAddress(data);
    this.insuringPartyForm.patchValue({
      city: cityData,
      emailId: data.email,
      repIdNumber: data.representative.indent,
    });
  }

  public populateCompanyAddress(data: CompanyDataDto): void {
    const companyName: string | undefined = data.name;
    const block: string | undefined = data.address?.block;
    const entrance: string | undefined = data.address?.entrance;
    const floor: string | undefined = data.address?.floor;
    const apartment: string | undefined = data.address?.apartment;
    const street: string | undefined = data.address?.street;
    const streetNumber: string | undefined = data.address?.streetNumber;
    if (
      block?.match(this.pattern) ||
      entrance?.match(this.pattern) ||
      floor?.match(this.pattern) ||
      companyName?.match(this.pattern) ||
      apartment?.match(this.pattern) ||
      street?.match(this.pattern) ||
      streetNumber?.match(this.pattern)
    ) {
      this.insuringPartyForm.patchValue({
        companyNameEn: companyName,
        streetEn: street,
        streetNumberEn: streetNumber,
        blockEn: block,
        entranceEn: entrance,
        floorEn: floor,
        apartmentEn: apartment,
      });
    } else {
      this.insuringPartyForm.patchValue({
        companyName: companyName,
        street: street,
        streetNumber: streetNumber,
        block: block,
        entrance: entrance,
        floor: floor,
        apartment: apartment,
      });
    }
  }

  public handleContactPerson(contactPersonId: Text) {
    this.contactPersonId = contactPersonId;
    if (null != contactPersonId && 8 <= contactPersonId.asString().length) {
      this.insuringPartyType = this._partyId.getType(contactPersonId.asString());
      this.isContactPersonEGN = this.insuringPartyType === PartyType.PERSON;
      this.isContactPersonLNCH = this.insuringPartyType === PartyType.FOREIGNER;
    }
    this.insuringPartyForm.controls.contactIdNumber.setValue(contactPersonId.asString());
  }

  public handleRepresentative(repPersonId: Text) {
    this.repId = repPersonId;
    if (null != repPersonId && 8 <= repPersonId.asString().length) {
      this.insuringPartyType = this._partyId.getType(repPersonId.asString());
      this.isrepEGN = this.insuringPartyType === PartyType.PERSON;
      this.isrepLNCH = this.insuringPartyType === PartyType.FOREIGNER;
    }
    this.insuringPartyForm.controls.repIdNumber.setValue(repPersonId.asString());
  }

  public getInsuringPartyDetails(): FormGroupManager<InsuringFormData> {
    if (this.insuringPartyForm != null) {
      if (this.isInsuringPartyEIK) {
        this.insuringPartyForm.controls.firstName?.setErrors(null);
        this.insuringPartyForm.controls.firstNameEn?.setErrors(null);
        this.insuringPartyForm.controls.middleName?.setErrors(null);
        this.insuringPartyForm.controls.middleNameEn?.setErrors(null);
        this.insuringPartyForm.controls.lastName?.setErrors(null);
        this.insuringPartyForm.controls.lastNameEn?.setErrors(null);
        this.insuringPartyForm.controls.citizenship?.setErrors(null);
        this.insuringPartyForm.controls.dob?.setErrors(null);
        this.validateRepContactDetails();
      } else if (this.isInsuringPartyEGN) {
        this.insuringPartyForm.controls.citizenship?.setErrors(null);
        this.insuringPartyForm.controls.dob?.setErrors(null);
        this.insuringPartyForm.controls.companyName?.setErrors(null);
        this.insuringPartyForm.controls.companyNameEn?.setErrors(null);
        this.validateOtherDetails();
      } else if (this.isInsuringPartyLNCH) {
        this.insuringPartyForm.controls.companyName?.setErrors(null);
        this.insuringPartyForm.controls.companyNameEn?.setErrors(null);
        this.validateOtherDetails();
      }
    }
    return this.insuringPartyForm;
  }

  public validateRepContactDetails(): void {
    if (this.isInsuringPartyEIK) {
      if (this.isrepEGN) {
        this.insuringPartyForm.controls.repCitizenship?.setErrors(null);
        this.insuringPartyForm.controls.repDob?.setErrors(null);
      }
      if (this.isContactPersonEGN) {
        this.insuringPartyForm.controls.contactCitizenship?.setErrors(null);
        this.insuringPartyForm.controls.contactDob?.setErrors(null);
      }
    }
  }

  public validateOtherDetails(): void {
    if (this.insuringPartyForm != null) {
      this.insuringPartyForm.controls.repIdNumber?.setErrors(null);
      this.insuringPartyForm.controls.repFirstName?.setErrors(null);
      this.insuringPartyForm.controls.repFirstNameEn?.setErrors(null);
      this.insuringPartyForm.controls.repMiddleName?.setErrors(null);
      this.insuringPartyForm.controls.repMiddleNameEn?.setErrors(null);
      this.insuringPartyForm.controls.repLastName?.setErrors(null);
      this.insuringPartyForm.controls.repLastNameEn?.setErrors(null);
      this.insuringPartyForm.controls.repCitizenship?.setErrors(null);
      this.insuringPartyForm.controls.repDob?.setErrors(null);
      this.insuringPartyForm.controls.contactIdNumber?.setErrors(null);
      this.insuringPartyForm.controls.contactFirstName?.setErrors(null);
      this.insuringPartyForm.controls.contactFirstNameEn?.setErrors(null);
      this.insuringPartyForm.controls.contactMiddleName?.setErrors(null);
      this.insuringPartyForm.controls.contactMiddleNameEn?.setErrors(null);
      this.insuringPartyForm.controls.contactLastName?.setErrors(null);
      this.insuringPartyForm.controls.contactLastNameEn?.setErrors(null);
      this.insuringPartyForm.controls.contactCitizenship?.setErrors(null);
      this.insuringPartyForm.controls.contactDob?.setErrors(null);
    }
  }

  public setInsuringPartyDetails(formData: InsuringFormData): void {
    this.insuringPartyForm.patchValue(formData);
    if (null != this.insuringPartyForm && null != this.insuringPartyForm.controls) {
      this.insuringPartyForm.controls.phoneNumber.patchValue(formData?.phoneNumber.number);
      this.insuringPartyForm.controls.cityEn.patchValue(formData?.cityEn);
    }
  }

  protected saveMtplData(): void {}

  public vehicleOwnerDetails(): boolean {
    this._dataService.setDetails({
      formValidation: this.insuringPartyForm.valid,
      sameDetails: this.isSame,
      address: this.insuringPartyForm.value,
    });
    return this.isSame;
  }
}
