import {Injectable} from '@angular/core';
import {
  Library,
  ProdProductDto,
  ProductDto,
  ProductPortfolio,
} from '../contracts/library.interface';

@Injectable({
  providedIn: 'root',
})
export class LibrarySessionService {
  public data: ProdProductDto = {};

  public setProduct(productDto: ProductDto): void {
    this.data.productDto = productDto;
    this.updateGlobalStorage();
  }

  public setLibrary(library: Library): void {
    this.data.library = library;
    this.updateGlobalStorage();
  }

  public setPortFolio(portfolio: ProductPortfolio): void {
    this.data.portfolio = portfolio;
    this.updateGlobalStorage();
  }

  public updateGlobalStorage(): void {
    sessionStorage.setItem('product', JSON.stringify(this.data));
  }

  public getData(): ProdProductDto {
    const jsonData = sessionStorage.getItem('product');
    if (jsonData) {
      this.data = JSON.parse(jsonData);
      return this.data;
    } else {
      this.data = {};
      this.updateGlobalStorage();
      return this.data;
    }
  }
}
