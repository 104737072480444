import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionExprXlsSvgModule
 */
@Component({
  selector: 'svg[b2bFrontendActionExprXls]',
  template: '<svg:path d="M22.19 33.6h-1.96L19 31.64l-1.21 1.96h-1.92l2.08-3.21L16 27.32h1.88l1.13 1.94 1.09-1.94h1.94l-1.99 3.2 2.14 3.08z"></svg:path><svg:path d="M22.9 33.6v-6.28h1.7v4.91h2.42v1.37H22.9z"></svg:path><svg:path d="M32.13 31.69c0 .39-.1.74-.3 1.04-.2.3-.48.54-.86.7-.37.17-.81.25-1.31.25-.42 0-.77-.03-1.05-.09s-.58-.16-.88-.31v-1.51c.32.17.66.3 1.01.39.35.09.67.14.96.14.25 0 .44-.04.55-.13.12-.09.18-.2.18-.34a.37.37 0 0 0-.07-.23.963.963 0 0 0-.23-.2c-.1-.07-.38-.2-.84-.4-.41-.19-.72-.37-.92-.54-.2-.18-.36-.38-.46-.6-.1-.23-.15-.49-.15-.8 0-.58.21-1.03.63-1.35.42-.32 1-.48 1.74-.48.65 0 1.31.15 1.99.45l-.52 1.31c-.59-.27-1.09-.4-1.52-.4-.22 0-.38.04-.48.12-.1.08-.15.17-.15.29 0 .12.06.23.19.33s.47.28 1.04.53c.54.24.92.5 1.13.78.21.28.32.63.32 1.05z"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="8" d="M28 6.4V16h9.6"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="8" d="M37.6 23.2V16L28 6.4H10.4v16.8"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="8" d="M10.4 37.6v4h27.2v-4"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="8" d="M6.4 23.2h35.2v14.4H6.4z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionExprXlsSvgComponent {
}

@NgModule({
  declarations: [
    ActionExprXlsSvgComponent,
  ],
  exports: [
    ActionExprXlsSvgComponent,
  ],
})
export class ActionExprXlsSvgModule {}
