import {takeUntilDestroyed, UntilDestroy} from '@atlas-angular/rxjs';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormControl, Validators} from '@angular/forms';
import {
  FormGroupManager,
  FormGroupManagerFactoryService,
  ScreenFormComponent,
} from '@njf-frontend-angular/flow-progress';
import {RadioDefaults} from './__tests__/default-mock';

@Component({
  selector: 'nje-covers-discounts',
  templateUrl: './covers-discounts.component.html',
  styleUrls: ['./covers-discounts.component.scss'],
})
@UntilDestroy()
export class CoversDiscountsComponent extends ScreenFormComponent implements OnInit {
  // extension box flag for 3 discount
  public activateMTPLPercBox: boolean = false;
  public activateNewRegisterVehiclePercBox: boolean = false;
  public activateNewInsuredVehiclePercBox: boolean = false;

  //radio button counter
  public casco: number = 0;
  public extension: number = 0;

  //flag for autoproces. put true to deactivate autoprocess
  public autoprocess: boolean = false;
  public warning: boolean = false;

  //related to stepper control
  public continueClicked: boolean;

  public covers: FormGroupManager<any> = this._formFactory.createFormManager<any>({
    sumInsured: new FormControl(undefined, [Validators.required, Validators.pattern('^[0-9]*$')]),
    fullRadio: new FormControl(undefined),
    secRadio: new FormControl(RadioDefaults.NO_DEVICE),
  });

  //check box flag for selection highlight status
  public mtplChk: boolean = false;
  public insChk: boolean = false;
  public regChk: boolean = false;
  public ins3VehChk: boolean = false;
  public sPayChk: boolean = false;
  public insZAKChk: boolean = false;
  public expEvalChk: boolean = false;
  public own45Chk: boolean = true;
  public rgnlDisChk: boolean = true;
  public bonMal1Chk: boolean = true;
  public wrtEvntDecChk: boolean = false;
  public rprAbrdChk: boolean = false;
  public rskMkMdlChk: boolean = true;
  public ownAgeChk: boolean = true;
  public bonMal2Chk: boolean = true;

  public constructor(
    route: ActivatedRoute,
    private readonly _formFactory: FormGroupManagerFactoryService,
  ) {
    super(route);
  }

  public ngOnInit(): void {
    this.defaultSet();
    this.rangeWarn();
  }

  public defaultSet(): void {
    this.covers.controls.sumInsured.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: number) => {
        if (value) {
          this.casco = 1;
          this.extension = value < 20000 ? 1 : 2;
        } else {
          this.casco = 0;
          this.extension = 0;
        }
      });
  }

  public rangeWarn(): void {
    this.covers.controls.sumInsured.valueChanges
      .pipe(takeUntilDestroyed(this))
      .subscribe((value: number) => {
        if (value) {
          if (value < 10000 || value > 25000) {
            this.warning = true;
          } else {
            this.warning = false;
          }
        } else {
          this.warning = false;
        }
      });
  }

  //activator for extension box in discounts
  public changeSelection(choice: number): void {
    if (choice == 1) {
      this.activateMTPLPercBox = !this.activateMTPLPercBox;
    } else if (choice == 2) {
      this.activateNewInsuredVehiclePercBox = !this.activateNewInsuredVehiclePercBox;
    } else {
      this.activateNewRegisterVehiclePercBox = !this.activateNewRegisterVehiclePercBox;
    }
  }

  protected saveMtplData(): void {
    this.continueClicked = true;
  }
}
