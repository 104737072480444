import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {CommonMessage} from '../models/CommonMessage';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public commonMessage = new BehaviorSubject<CommonMessage>({});

  public constructor() {}

  public setcommonMessage(commonMessage: CommonMessage): void {
    this.commonMessage.next(commonMessage);
  }

  public getcommonMessage(): Observable<CommonMessage> {
    return this.commonMessage.asObservable();
  }
}
