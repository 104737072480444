<maia-price-block amount="15.43" currency="BGN" type="custom" class="maia-custom">
  <svg b2bFrontendInformationExprPayment maia-price-icon></svg>
  <div maia-price-title>Total amount of payment</div>
  <div maia-price-subtitle>For selected installments</div>
</maia-price-block>

<div class="grey-box-div">
  <maia-fluid-fluid-layout alignContentTop>
    <maia-label class="b2b-kdl-font-small-bold title-dark-color" sublabel="85420000001238"
      >Property Insurance</maia-label
    >

    <div>
      <maia-avatar-display class="home-car-icon-adjust">
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg b2bFrontendPlacesExprHouse class="home-icon"></svg>
        </maia-avatar-svg>
      </maia-avatar-display>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesFuncHouse></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small title-dark-color" [sublabel]="'Property Type'"
          >Villa
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesExprLandTrees></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small title-dark-color" [sublabel]="'Area of Property'"
          >1500 Sq.m.
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendPlacesExprHouseTree></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small title-dark-color" [sublabel]="'Property Address'"
          >K51B Nikoa Vaptzarov Blvd Bulgaria
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>
  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-table>
      <maia-table-header>
        <maia-table-row>
          <maia-table-cell
            class="b2b-kdl-font-small title-dark-color"
            *ngFor="let colHeaders of columnHeaders; let i = index"
          >
            {{ colHeaders }}
          </maia-table-cell>
        </maia-table-row>
      </maia-table-header>
      <maia-table-body>
        <maia-table-row *ngFor="let data of overviewTableList; let i = index">
          <maia-table-cell>
            {{ data.instalment }}
          </maia-table-cell>
          <maia-table-cell>
            {{ data.dueDate }}
          </maia-table-cell>
          <maia-table-cell>
            {{ data.status }}
          </maia-table-cell>
          <maia-table-cell>
            {{ data.amount }}
          </maia-table-cell>
          <maia-table-cell>
            {{ data.currency }}
          </maia-table-cell>
        </maia-table-row>
      </maia-table-body>
    </maia-table>
  </maia-fluid-fluid-layout>
</div>

<div class="grey-box-div">
  <maia-fluid-fluid-layout alignContentTop>
    <maia-label class="b2b-kdl-font-small-bold title-dark-color" [sublabel]="'BG/06/120000001238'">
      Motor Third Party Liability Insurance
    </maia-label>

    <div>
      <maia-avatar-display class="home-car-icon-adjust">
        <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
          <svg b2bFrontendTransportationExprCarNew class="new-car-icon"></svg>
        </maia-avatar-svg>
      </maia-avatar-display>
    </div>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display>
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationIndiVehicleLicensePlate></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small title-dark-color"
          [sublabel]="'Vehicle Registration Number'"
          >H9357BC
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendTransportationFuncCar></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small title-dark-color" [sublabel]="'Vehicle purpose'"
          >Personal Use
        </maia-label>
      </div>
    </maia-avatar-display>

    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendInformationFuncCardStacked></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small title-dark-color"
          [sublabel]="'Green Card,Valid till 29-06-2021,Blank No. 8159518,Blank Serial No. 8159518'"
          >AA000000
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-avatar-display id="avatar-display-svg">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendAfiExprCardStacked></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label
          class="b2b-kdl-font-small title-dark-color"
          [sublabel]="'Sticker,Valid till 29-06-2021,Blank No. 8159518,Blank Serial No. 8159518'"
          >8159518
        </maia-label>
      </div>
    </maia-avatar-display>
  </maia-fluid-fluid-layout>

  <maia-fluid-fluid-layout class="m-t-24" alignContentTop>
    <maia-table>
      <maia-table-header>
        <maia-table-row>
          <maia-table-cell
            class="b2b-kdl-font-small title-dark-color"
            *ngFor="let colHeaders of columnHeaders; let i = index"
          >
            {{ colHeaders }}
          </maia-table-cell>
        </maia-table-row>
      </maia-table-header>
      <maia-table-body>
        <maia-table-row *ngFor="let data of overviewTableList; let i = index">
          <maia-table-cell>
            {{ data.instalment }}
          </maia-table-cell>
          <maia-table-cell>
            {{ data.dueDate }}
          </maia-table-cell>
          <maia-table-cell>
            {{ data.status }}
          </maia-table-cell>
          <maia-table-cell>
            {{ data.amount }}
          </maia-table-cell>
          <maia-table-cell>
            {{ data.currency }}
          </maia-table-cell>
        </maia-table-row>
      </maia-table-body>
    </maia-table>
  </maia-fluid-fluid-layout>
</div>

<maia-fluid-fluid-layout class="m-t-24" alignContentTop>
  <div class="grey-box-div">
    <label-with-edit
      [nameEditPath]="insuredPartyEditPath"
      (editScreen)="editScreen($event)"
    ></label-with-edit>
    <maia-avatar-display id="avatar-display-svg" class="m-t-24">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendSocialExprPerson></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small title-dark-color" [sublabel]="'Boris Petrov Borisov'"
          >Boris Petrov Borisov
        </maia-label>
      </div>
    </maia-avatar-display>
  </div>

  <div class="grey-box-div">
    <label class="b2b-kdl-font-small title-dark-color">Seller ID</label>

    <maia-avatar-display id="avatar-display-svg" class="m-t-24">
      <maia-avatar-svg class="icon-adjust" maia-avatar-display-icon>
        <svg class="icon-primary-color" b2bFrontendSocialExprPersonTie></svg>
      </maia-avatar-svg>
      <div maia-avatar-display-body>
        <maia-label class="b2b-kdl-font-small title-dark-color" [sublabel]="'Seller ID'"
          >Manu Vijayan (28159520)
        </maia-label>
      </div>
    </maia-avatar-display>
  </div>
</maia-fluid-fluid-layout>

<maia-divider></maia-divider>

<button class="m-20" maiaButton maiaButtonType="primary" (click)="saveAndContinue()"
  >Continue</button
>
