<maia-radio-group [(ngModel)]="premiumSingleId" name="premiumFirst">
  <div class="header-info">
    <label class="b2b-kdl-font-small title-dark-color"> Premium</label>

    <div class="read-icon"
      ><svg b2bFrontendNavigationFuncLiveHelp></svg>
      <button maiaButton maiaButtonType="tertiary">Help</button></div
    >
  </div>

  <div class="select-box">
    <maia-tile [ngClass]="!singlePremiumStatus ? 'not-selected' : 'tile-style'">
      <maia-checkbox [(ngModel)]="singlePremiumStatus" [disabled]="true"
        ><span class="span-test">Single Payment</span></maia-checkbox
      ></maia-tile
    >
    <maia-tile [ngClass]="!doublePremiumStatus ? 'not-selected' : 'tile-style'"
      ><maia-checkbox
        name="disabled"
        (click)="onClickDouble()"
        [(ngModel)]="doublePremiumStatusNg"
        [disabled]="disabled"
        ><span class="span-test">Double Payment</span></maia-checkbox
      ></maia-tile
    >
    <maia-tile [ngClass]="!fourPremiumStatus ? 'not-selected' : 'tile-style'">
      <maia-checkbox
        name="disabled"
        (click)="onClickFour()"
        [(ngModel)]="fourPremiumStatusNg"
        [disabled]="false"
        ><span class="span-test">Four Payment</span></maia-checkbox
      ></maia-tile
    >
  </div>
  <div class="payment-style">
    <div *ngIf="singlePremiumStatus">
      <label class="b2b-kdl-font-small title-dark-color"> Single Payment</label>
      <div class="container">
        <maia-tile
          *ngFor="let premiumFirst of premiumSingleList; let i = index"
          class="m-t-b-24 tile-box"
          [ngClass]="{
            'premium-tile-selected': premiumSingleList[i].selectedStatus && !firstSelected
          }"
        >
          <maia-radio-button
            [value]="premiumFirst.premiumId"
            (click)="updateSelectedSinglePremium(i)"
          >
            <div class="box-div">
              <label class="b2b-kdl-font-small-light premium-sub-title">{{
                premiumFirst.premiumName
              }}</label>
              <maia-amount-display
                class="b2b-kdl-font-medium-bold premium-amount-title"
                [value]="getAmoutValue(premiumFirst.amount)"
                size="medium-bold"
                currency="BGN"
              ></maia-amount-display>
            </div>
          </maia-radio-button>
        </maia-tile>
      </div>
    </div>

    <div *ngIf="doublePremiumStatus">
      <label class="b2b-kdl-font-small title-dark-color"> Double Payment</label>

      <div class="container">
        <maia-tile
          *ngFor="let premiumDouble of premiumDoubleList; let i = index"
          class="m-t-b-24 tile-box"
          [ngClass]="{
            'premium-tile-selected': premiumDoubleList[i].selectedStatus && !secondSelected
          }"
        >
          <maia-radio-button
            [value]="premiumDouble.premiumId"
            (click)="updateSelectedDoublePremium(i)"
          >
            <div class="box-div">
              <label class="b2b-kdl-font-small-light premium-sub-title">{{
                premiumDouble.premiumName
              }}</label>
              <maia-amount-display
                class="b2b-kdl-font-medium-bold premium-amount-title"
                [value]="getAmoutValue(premiumDouble.amount)"
                size="medium-bold"
                currency="BGN"
              ></maia-amount-display>
            </div>
          </maia-radio-button> </maia-tile></div
    ></div>

    <div *ngIf="fourPremiumStatus">
      <label class="b2b-kdl-font-small title-dark-color"> Four Payment</label>
      <div class="container">
        <maia-tile
          *ngFor="let premiumFour of premiumFourList; let i = index"
          class="m-t-b-24 tile-box"
          [ngClass]="{
            'premium-tile-selected': premiumFourList[i].selectedStatus && !fourSelected
          }"
        >
          <maia-radio-button [value]="premiumFour.premiumId" (click)="updateSelectedFourPremium(i)">
            <div class="box-div">
              <label class="b2b-kdl-font-small-light premium-sub-title">{{
                premiumFour.premiumName
              }}</label>
              <maia-amount-display
                class="b2b-kdl-font-medium-bold premium-amount-title"
                [value]="getAmoutValue(premiumFour.amount)"
                size="medium-bold"
                currency="BGN"
              ></maia-amount-display>
            </div>
          </maia-radio-button>
        </maia-tile> </div
    ></div>
  </div>
</maia-radio-group>
<maia-divider></maia-divider>
<button
  class="spacing"
  maiaButton
  maiaButtonType="primary"
  [disabled]="isContinue"
  (click)="saveAndContinue()"
  >Continue
</button>
