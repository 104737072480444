<form
  maiaForm
  *ngIf="selectLibrary"
  [(ngModel)]="selectLibrary.i18n?.en.title"
  name="library"
  ngDefaultControl
>
  <p class="break-title"
    >Library:
    <span class="b2b-kdl-font-medium-bold library-name title-dark-color">{{
      selectLibrary.i18n?.en.title
    }}</span></p
  >

  <label class="left-mar lib-desc break-title">
    <maia-rich-text [value]="selectLibrary.i18n?.en.description | hyperlink"></maia-rich-text>
  </label>
  <maia-label class="n-t-24">
    <label
      class="b2b-kdl-font-medium title-dark-color left-mar library-name"
      i18n="@@nje-b2b-frontend-resource-library-portfolio-librarylist"
      >List of Product Portfolio</label
    >
  </maia-label>
  <maia-radio-group
    ngDefaultControl
    name="productPortfolio"
    class="tile-grid m-t-b-24"
    [(ngModel)]="productPortfolio"
  >
    <maia-tile
      *ngFor="let productPortfolio of productPortfolioList; let i = index"
      [ngClass]="{'portfolio-tile-selected': productPortfolioList[i].selectedStatus}"
    >
      <maia-radio-button [value]="productPortfolio" (click)="selectProductPortfolio(i)">
        <div class="box-div">
          <div class="title-sub-div">
            <label class="b2b-kdl-font-small productPortfolio-title">{{
              productPortfolio.i18n?.en.title
            }}</label>
            <label class="b2b-kdl-font-small-light productPortfolio-sub-title module line-clamp">
              <maia-rich-text
                [value]="productPortfolio.i18n?.en.description | hyperlink"
              ></maia-rich-text>
            </label>
          </div>
          <maia-button-group class="align-left">
            <button
              maiaButton
              maiaButtonType="tertiary"
              (click)="addEditProductPortfolio(productPortfolio, i)"
              i18n="@@nje-b2b-frontend-resource-library-portfolio-edit"
              >Edit</button
            >
            <button
              maiaButton
              maiaButtonType="tertiary-delete"
              (click)="deleteProductPortfolio(i, productPortfolio.id)"
              i18n="@@nje-b2b-frontend-resource-library-portfolio-delete"
              >Delete</button
            >
          </maia-button-group>
        </div>
      </maia-radio-button>
    </maia-tile>
  </maia-radio-group>
  <div class="action-with-icon show-hand" (click)="addEditProductPortfolio()">
    <svg class="action-func-plus-circle" b2bFrontendActionFuncPlusCircle></svg>
    <label class="underline-text" i18n="@@nje-b2b-frontend-resource-library-portfolio-addnew"
      >Add New Product Portfolio</label
    >
  </div>
  <maia-divider class="maia-divider border-margin"></maia-divider>
</form>
