<maia-divider class="maia-divider border-margin"></maia-divider>
<maia-fluid-fluid-layout>
  <div>
    <maia-label class="category-label" i18n="@@nje-b2b-frontend-resource-articles-filter-category">
      Category
    </maia-label>
  </div>
  <div class="category-wrapper">
    <form [formGroup]="filterForm">
      <div *ngFor="let category of categoryList; let i = index">
        <maia-tile-document-sign class="padding-class">
          <maia-tile-document-sign-text class="url_text">
            <input
              [value]="getCategoryTitle(category)"
              class="category-input"
              #byId
              (change)="onKeyUp(category, byId.value, i, category.id)"
              placeholder="Enter new category"
            />
          </maia-tile-document-sign-text>
        </maia-tile-document-sign>
      </div>
      <div></div>
    </form>
  </div>
</maia-fluid-fluid-layout>

<div class="add-contact">
  <div class="add-contact-icon">
    <svg b2bFrontendActionFuncPlusCircle></svg>
  </div>
  <div class="add-contact-button">
    <button
      maiaButton
      maiaButtonType="tertiary"
      (click)="addNewCategory()"
      i18n="@@nje-b2b-frontend-resource-articles-filter-addnew"
      >Add new category</button
    >
  </div>
  <form [formGroup]="filterForm">
    <maia-form-element id="addCategory" *ngIf="addCategory" hideOptionalIndicator>
      <input
        maiaInput
        name="newCategory"
        [(ngModel)]="newCategory"
        formControlName="newCategory"
        i18n-placeholder="@@nje-b2b-frontend-resource-articles-filter-entercategoryname"
        placeholder="Enter Category Name"
      />
    </maia-form-element>
  </form>
  <button
    *ngIf="addCategory"
    maiaButton
    maiaButtonType="primary"
    class="addCategory"
    [disabled]="filterForm.invalid"
    (click)="addNewCategoryToList()"
    i18n="@@nje-b2b-frontend-resource-articles-filter-add"
    >ADD</button
  >
</div>
<div class="filter-footer-wrapper">
  <maia-button-group class="filter-buttons">
    <button
      maiaButton
      maiaButtonType="secondary"
      (click)="cancelFilter()"
      i18n="@@nje-b2b-frontend-resource-articles-filter-cancel"
      >Cancel</button
    >
    <button
      maiaButton
      maiaButtonType="primary"
      (click)="applyFilter()"
      i18n="@@nje-b2b-frontend-resource-articles-filter-apply"
      >Apply</button
    >
  </maia-button-group>
</div>
