import {TravelCoversSlideIn} from '@b2b-frontend/core';

export const MOCK_TRAVEL_SLIDE_IN: TravelCoversSlideIn[] = [
  {
    id: 1,
    title: ' Medical expenses - Up to 0 EUR',
    subTitle: ' Avail our cashless treatment facility during a medical emergency.',
  },
  {
    id: 2,
    title: 'Urgent Dental Treatment',
    subTitle: 'Treat your dental worries with your cashless treatment.',
  },
  {
    id: 3,
    title: '  Search and rescue expenses',
    subTitle: ' If caught in an unfortunate event, we will compensate for the distress.',
  },
  {
    id: 4,
    title: ' Accident Cover - Up to 0 EUR',
    subTitle: '  In case of an unfortunate event overseas we can compensate your Family/Nominee.',
  },
];
